
export class AssignModel {

  public id = null;
  public departmentId = "";
  public projectId = "";
  public noOfResources = "";
  public isQA= false;
  public skillSets: Array<skillSets> = [];
  public resources: Array<resources> = [];

  constructor(
    _skillSets: Array<skillSets>,
    _resources: Array<resources>
  ) {
    this.skillSets = _skillSets;
    this.resources = _resources;
  }

}


export class skillSets {
  public skillId: string = "";
  public name: string = "";
  public level: string = "";
}
export class resources {
  public resourceId: string = "";
  public name: string = "";
  public assignedOn: string = "";
}