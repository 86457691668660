import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { projectPortfolioService } from "../../service/project-portfolio-service";
import { AddIcon } from "../../assests/icons/icons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUpload2 from "../../components/styledComponent/FileUpload2";
import ClearIcon from "@mui/icons-material/Clear";
import { IFiles } from "../users/userModel";
import { projectManagementServive } from "../../service/project-management-service";
import { useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';

const AddDomainPortfolio = () => {
  let IfileObj: IFiles = {
    itemType: "",
    name: "",
    fileURL: "",
    fileName: "",
    file: null,
  };
  let objDocument: IFiles[] = [];
  const { domainId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [filePaths, setFilePaths] = useState<{ wbsPath: string }[]>([]);

  const [documentName, setDocumentName] = React.useState("");
  const [documentFile, setDocumentFile] = React.useState<any>(null);
  const [documentFileURL, setDocumentFileURL] = React.useState<any>("");
  ;
  const [documentFileName, setDocumentFileName] = React.useState<any>("");


  const [keywords, setKeywords] = useState<{ keyword: string }[]>([{ keyword: '' }]);
  const [validationErrors, setValidationErrors] = useState<any>([]);
  const [titleError, setTitleError] = useState<any>();
  const [descriptionError, setDescriptionError] = useState<any>();

  const [formValues, setFormValues] = useState<IFiles[]>(objDocument);

  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);

  const navigate = useNavigate();
  const _permission = useSelector((state: any) => state.DomainPortfolioApp.DomainPortfolioPermission);

  useEffect(() => {
    if (domainId) {
      debugger
      if (_permission.tab_ManageDomainPortfolioEdit_Access !== true) {
        navigate("/dashboard");
      }
      getDomainPortfolioDocumentsById(domainId);
    }

    else {
      if (_permission.tab_ManageDomainPortfolioAdd_Access !== true) {
        navigate("/dashboard");
      }}

  }, []);


  const getDomainPortfolioDocumentsById = async (id: any) => {
    try {
      debugger
      await projectPortfolioService
        .getDomainPortfolioDocumentsById(id)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            setTitle(response?.data?.title)
            setDescription(response?.data?.description)
            setFilePaths(response?.data?.files)
            setKeywords(response?.data?.keywords)

          }
        });
    }
    catch (ex: any) {
      console.error("An error occurred:", ex);
      if (ex?.response?.data?.Error) {
        ex.response.data.Error.map((err: any) => {
          toast.warning(err);
        });
      }

    }

  };

  const isKeywordsEmpty = (index: any) => {
    return keywords[index].keyword.trim() === '';
  };

  const validateFields = () => {


    // const errors = keywords.map((item, index) => {
    //     return isKeywordsEmpty(index);
    // });
    // setValidationErrors(errors);
    // return !errors.some((error) => error);


    const keywordErrors = keywords.map((item, index) => isKeywordsEmpty(index));
    const isTitleEmpty = !title.trim();
    const isDescriptionEmpty = !description.trim();

    setValidationErrors(keywordErrors);
    setTitleError(isTitleEmpty ? "Title is required" : null);
    setDescriptionError(isDescriptionEmpty ? "Description is required" : null);

    return (
      !keywordErrors.some((error) => error) &&
      !isTitleEmpty &&
      !isDescriptionEmpty
    );
  };


  const handleTitleBlur = () => {
    if (!title.trim()) {
      setTitleError("Title is required");
    } else {
      setTitleError(null);
    }
  };

  const handleDescriptionBlur = () => {
    if (!description.trim()) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError(null);
    }
  };

  const addDomainPortfolioDocuments = async (values: any) => {

    try {
      debugger
      await projectPortfolioService
        .addDomainPortfolioDocuments(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while adding data");
          } else {

            toast.success("Data Added successfully")
            navigate("/Domain-portfolio")

          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const updateDomainPorfolio = async (values: any) => {
    debugger
    try {
      await projectPortfolioService
        .updateDomainPorfolio(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            toast.success("Data Updated successfully")
            navigate("/Domain-portfolio")

          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  // const fieldValidationSchema = Yup.object().shape({
  //   title: Yup.string().required("Title is required").nullable(),
  //   description: Yup.string().required("Description is required").nullable(),


  // });

  const handleKeywordChange = (index: number, value: string) => {
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = false;
    setValidationErrors(updatedValidationErrors);
    const updatedKeywords = [...keywords];
    updatedKeywords[index] = { keyword: value };
    setKeywords(updatedKeywords);
  };

  const handleAddKeyword = () => {
    setKeywords([...keywords, { keyword: '' }]);
  };

  const initialValues = {
    id: domainId,
    title: title,
    description: description,
    files: filePaths || [],
    keywords: keywords || [],
  };

  // upload file

  const triggerDocumentFile = (fileList: FileList, index: number) => {
    debugger
    const file = fileList[0];
    const fileURL = URL.createObjectURL(file);
    IfileObj.itemType = "";
    IfileObj.name = file.name;
    IfileObj.fileURL = fileURL;
    IfileObj.fileName = fileList[0].name;
    IfileObj.file = fileList[0];

    let fObj = [...formValues];
    fObj.push(IfileObj);
    setFormValues([...formValues, IfileObj]);

    setDocumentName("");
    setDocumentFile(null);
    setDocumentFileURL("");
    setDocumentFileName("");

  };


  let removeFormFields = (i: any) => {
    
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let removeUploadedFiles = (i: any) => {
    debugger
    let newFormValues = [...filePaths];
    newFormValues.splice(i, 1);
    setFilePaths(newFormValues);
  };

  const uploadFiles = async () => {
    const uploadedFiles: { wbsPath: string }[] = [...filePaths];;

    for (const item of formValues) {
      if (item.file) {
        const formData = new FormData();
        formData.append("File", item.file);
        formData.append("FileFor", item.fileName);

        try {
          const response = await projectManagementServive.uploadProfilePic(formData);
          if (response.data.isError) {
            toast.error(`Issue while uploading file: ${item.name}`);
          } else {
            uploadedFiles.push({ wbsPath: response.data });

          }
        } catch (error) {
          console.error(error);
          toast.warning(`Error uploading file: ${item.fileName}`);
        }
      }
    }

    setFilePaths(uploadedFiles);
    return uploadedFiles;
  };



  let removeKeywordFields = (i: any) => {

    let newKeywordValues = [...keywords];
    newKeywordValues.splice(i, 1);
    setKeywords(newKeywordValues);
  };;

  const handleSubmit1 = (e: any, values: any) => {
    debugger
    e.preventDefault();
    const isValid = validateFields();
    if (isValid) {
      if (domainId == "") {
        addDomainPortfolioDocuments(values)
      }

    }
    else {

    }
  };

  const getUnEncrypedFiles = async (fileName: any) => {
    try {
      await projectManagementServive
        .getUnEncrypetDocuments(fileName)
        .then(async (response: any) => {
          if (response.data.type === "application/json") {
            const data = await response.data.text();
            toast.warning(JSON.parse(data).listErrors[0]);
          }
          else {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            a.href = URL.createObjectURL(response.data);
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(a.href);
          }
        });
    } catch (ex: any) {
      (ex.data?.Error || ex.Error).map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  }



  // const updateDocument = async () => {
  //  debugger
  //   //setUpdateButtonDisable(true)
  //   try {

  //     formValues.forEach(async function (item, index, array) {
  //       if (item.file !== null) {
  //         let formData = new FormData();
  //         formData.append("File", item.file);
  //         formData.append("FileFor", item.fileName);
  //         await projectManagementServive
  //           .uploadProfilePic(formData)
  //           .then(async (response: any) => {
  //             if (response.data.isError) {
  //               toast.error(
  //                 "facing issue while Document Upload" + item.name
  //               );
  //             } else {
  //               toast.success(" file uploaded");
  //             }
  //           });
  //       } else {
  //         toast.warning("Select a file to upload");

  //       }
  //     });

  //   } catch (ex: any) {
  //     toast.warning("Error while updating data, check console.");
  //   }
  // };


  // let addDocumentValues = () => {
  //   debugger
  //   if (documentFile == null) {
  //     setUpdateButtonDisable(true)
  //     toast.warning("Please choose Document file.");
  //     return;
  //   }
  //   else{
  //     setUpdateButtonDisable(false)

  //   }

  // };

  // const cancelDocumentFile = () => {
  //  // setTempDocumentFileURL("");
  //   setDocumentFile(null);
  //   setDocumentFileURL("");
  //   setDocumentFileName("")
  // };


  // const getProjectDetails = async () => {

  //   try {
  //     await projectManagementServive
  //       .getProjectDetailsById(id)
  //       .then(async (response: any) => {
  //         if (response.data.isError) {
  //           toast.warning("Error while fetching data");
  //         } else {
  //           debugger
  //           setProjectDetailHook(response.data);
  //           if(response.data?.projectDetails?.projectDocument.length > 0){
  //           let documentData = response.data?.projectDetails?.projectDocument;
  //           console.log(documentData, "documentData")
  //           var document_array: any[] = [];
  //           documentData.map((item: any) => {
  //             document_array.push({
  //               name: item?.fileName,
  //               fileURL: item?.wbsPath,
  //               fileName: item?.fileName,
  //               file: null,
  //               itemType: "",
  //               uploadedBy:item?.uploadedBy,
  //             });
  //           });
  //           setDocumentFormValues(document_array);
  //           setFormValues(document_array);
  //         }}
  //       });
  //   } catch (ex: any) {
  //     ex.data.Error.map((err: any, idx: any) => {
  //       toast.warning(err);
  //     });
  //   }
  // };



  return (
    <>
      <Box sx={{ padding: "24px", background: "#fff" }}>

        <Box
          sx={{
            display: 'flex', justifyContent: 'space-between',
            alignItems: 'center', width: '100%', marginTop: '0'
          }}>
          <h3
            style={{
              marginTop: '0', color: '#4B4B5A', fontSize: '24px', fontWeight: '500',
            }}>
            {domainId && domainId.length > 13 ? 'Edit Domain Portfolio' : 'Add Domain Portfolio'}
          </h3>

        </Box>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          //  validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              debugger
              const isValid = validateFields();
              if (isValid) {
                const uploadedFilePaths = await uploadFiles();
                values.files = uploadedFilePaths;

                if (domainId && domainId?.length > 23) {
                  updateDomainPorfolio(values)
                }
                else {
                  delete values?.id;
                  addDomainPortfolioDocuments(values)
                }
              }

            } catch (err: any) {
              console.error(err);
              if (err.status === 400) {
                err.data.Error.forEach((error: any) => toast.warn(error));
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
          }) => (
            // <form noValidate onSubmit={handleSubmit}>
            <form noValidate onSubmit={(e) => { handleSubmit(); handleSubmit1(e, values); }}>
              <Grid container spacing={3} >
                <Grid item md={6}>
                  <Box
                    sx={{ display: "flex", paddingTop: domainId && domainId?.length > 23 ? "20px" : "0px", flexDirection: "column", gap: "22px" }}
                  >
                    <TextField
                      id="title"

                      required
                      label="Title"
                      variant="outlined"
                      fullWidth
                      name="Title"
                      value={title}
                      onBlur={handleTitleBlur}

                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.title = value;
                        setTitle(value);
                      }}
                      error={!!titleError}
                      helperText={titleError} />

                    <TextField label="Description"
                      sx={{
                        '& .MuiInputBase-input': {
                          lineHeight: '1.5',
                        }
                      }}

                      multiline rows={6}
                      onBlur={handleDescriptionBlur}
                      id="description"
                      value={description}
                      onChange={(e) => {
                        let value = e.currentTarget.value;
                        values.description = value;
                        setDescription(value);
                      }}
                      error={!!descriptionError}
                      helperText={descriptionError} />

                  </Box>


                </Grid>
                <Grid item md={6}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: "14px" }}
                  >
                    <Grid container spacing={2}>
                      <Grid item
                        sm={6}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                          <Button
                            variant="text"
                            endIcon={<AddIcon />}
                            onClick={handleAddKeyword}
                            sx={{ display: "fit-content" }}
                          >
                            Add More
                          </Button>
                        </Box>
                      </Grid>
                      {keywords.map((keywordObj, index) => (
                        <Grid
                          key={index}
                          item
                          sm={6}
                          sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}
                        >
                          <TextField
                            label={`Keyword`}
                            sx={{ width: "90%" }}
                            name={`keywords[${index}]`}
                            value={keywordObj.keyword}
                            onChange={(e) => handleKeywordChange(index, e.target.value)}
                            error={validationErrors[index]}
                            helperText={validationErrors[index] && 'Keyword is required'}
                          />
                          <Box>
                            {index ? (
                              <Box sx={{ cursor: "pointer" }} onClick={() => removeKeywordFields(index)}>
                                <DeleteIcon sx={{ color: "#ff5858" }} />
                              </Box>
                            ) : null}
                          </Box>
                        </Grid>

                      ))}
                    </Grid>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", wordBreak: "break-word" }}>
                        {domainId && domainId?.length > 23 && filePaths?.length > 0 && (
                          filePaths.map((fileItem: any, idx: number) => (
                            <Box
                              sx={{
                                border: "1px solid #F6F6F6",
                                padding: "6px 8px",
                                borderRadius: "6px",
                                background: "#F9F9F9",
                                display:"flex",
                                gap:"12px",
                                alignItems:"center"
                              }}
                            >

                              <Typography
                                onClick={() => { getUnEncrypedFiles(fileItem?.wbsPath) }}
                                style={{ cursor: 'pointer', color: "#1DA7FF", textDecoration: "underline" }}
                              >
                                <Box>
                                  {fileItem.wbsPath}

                                </Box>
                              </Typography>
                              <Tooltip title="Delete File">
                                <IconButton
                                  sx={{ border: "1px solid #ff4747", padding: "0" }}
                                  onClick={async () => {
                                    debugger
                                    removeUploadedFiles(idx)

                                    // const updatedFiles = filePaths.filter((item: string) => item == fileItem);
                                    // const updatedRoadmapItem = { ...roadmapItem, file: updatedFiles };
                                    // await handleUploadFile(ratingForm.id, roadmapItem.checkPoint, roadmapItem.comment, updatedRoadmapItem, true);
                                  }}
                                >
                                  <ClearIcon sx={{ color: "#ff4747", fontSize: "18px", fontWeight: "700" }} />
                                </IconButton>
                              </Tooltip>
                            </Box>

                          )))
                        }
                      </Box>  
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}
                    >
                    
                    {_permission.tab_ManageDomainPortfolioUploadDocument_Access && (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <FileUpload2
                          selectFile={triggerDocumentFile}
                          index={1}

                        />
                      </Box>)}

                    

                      <Box sx={{ mt: 2, display: "flex", gap: "12px", flexWrap: "wrap" }}>
                        {formValues?.map((element: any, i: any) => (
                          <Chip
                            label={element?.fileName}
                            variant="outlined"
                            onDelete={() => removeFormFields(i)}
                            size="medium"

                          />
                        ))}

                      </Box>


                    </Box>

                  </Box>
                </Grid>
              </Grid>
              <Button sx={{ marginTop: "40px" }} variant="contained" type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </form>
          )}
        </Formik>
      </Box>






    </>
  );
};

export default AddDomainPortfolio;
