import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SourceIcon from "@mui/icons-material/Source";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { projectPortfolioService } from "../../service/project-portfolio-service";
import moment from "moment";
import { toast } from "material-react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as Icons from "../../assests/icons/icons";
import { useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import { getCode } from 'country-list';
import getSymbolFromCurrency from 'currency-symbol-map'; 

const PortfolioView = () => {
  const _permission = useSelector((state: any) => state.ProjectPortfolioApp.ProjectPortfolioPermission);
  const [portfolioData , setPortfolioData] = React.useState<any>([]);
  const [membersRole , setMembersRole] = React.useState<any>([]);
  const [oneDriveFolderList, setOneDriveFolderList] = React.useState<any>([]);
  const [toggleLoader, setToggleLoader] = React.useState(false);
  const [oneDriveItemOfFolders, setOneDriveItemOfFolders] = React.useState<any>([]);
  const [oneDriveFolderItemOfCaseStudy, setOneDriveFolderItemOfCaseStudy] = React.useState<any>(null);
  const [selectedFolder, setSelectedFolder] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [toggleLoaderForCaseStudy, setToggleLoaderCaseStudy] = React.useState(false);
  const [roleList, setRoleList] = React.useState<any>([]);

  const { pId } = useParams();
 
  const navigate = useNavigate();

  useEffect(() => {
    if(_permission.tab_ManagePortfolioView_Access !== true){
      navigate("/dashboard");
    }
   getProjectPortfolioView(pId);
   GetOneDriveFolderByProjectId(pId);
   getAllRolesOfUser();
  },[])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GetOneDriveFileViewByItemId = async (fid: any) => {
    try {
      await projectPortfolioService
        .getOneDriveFileViewByItemId(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            debugger
            window.open(response?.data?.result, '_blank');
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  const GetOneDriveFolderByProjectId = async (pid: any) => {
    try {
      await projectPortfolioService
        .getOneDriveFolderByProjectId(pid)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            let abc = response?.data?.value.find((item: any) => (item.name == "Case Study"))
            console.log(abc, "abcabcabcabcabc")
            if (abc) {
              GetOneDriveFolderDataByProjectId(abc.id, abc.name)
            }
            let filteredData = response?.data?.value.filter((item: any) => (item.name != "Case Study"))
            console.log(filteredData, "filteredData")
            setOneDriveFolderList(filteredData)
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const GetOneDriveFolderDataByProjectId = async (fid: any, name: any) => {
    setToggleLoader(true);
    //setToggleLoaderCaseStudy(true);
    try {
      await projectPortfolioService
        .getOneDriveFolderDataByProjectId(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            if (name) {
              if (response?.data?.value) {
                debugger
                if(response?.data?.value.length > 0){
                  GetOneDriveFileViewByItemIdForCaseStudy(response?.data?.value[0]?.id)
                }
                else {
                  //setToggleLoaderCaseStudy(false);
                }
                
              }
            }
            else {
              setOneDriveItemOfFolders(response?.data?.value)
            }
          }
          setToggleLoader(false)
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false)
        toast.warning(err);
      });
    }
  };

  // const GetOneDriveFileViewByItemIdForCaseStudy = async (fid: any) => {
  //   debugger
  //   try {
  //     await projectPortfolioService
  //       .getOneDriveFileViewByItemIdForCaseStudy(fid)
  //       .then(async (response: any) => {
  //         if (response.data.isError) {
  //           toast.warning("Error while fetching data");
  //         } else {
  //           debugger
  //           const byteCharacters = atob(response?.data?.result?.content)
  //           const byteNumbers = new Array(byteCharacters.length);
  //           for (let i = 0; i < byteCharacters.length; i++) {
  //             byteNumbers[i] = byteCharacters?.charCodeAt(i);
  //           }
  //           const byteArray = new Uint8Array(byteNumbers);
  //           const blob = new Blob([byteArray], { type: response?.data?.result?.contentType });
  //           const fileURL = URL.createObjectURL(blob);
  //           console.log(fileURL, "GetOneDriveFileViewByItemId")
  //           setOneDriveFolderItemOfCaseStudy(fileURL)
  //         }
  //       });
  //   } catch (ex: any) {
  //     ex.data.Error.map((err: any, idx: any) => {
  //       toast.warning(err);
  //     });
  //   }
  // };

  const GetOneDriveFileViewByItemIdForCaseStudy = async (fid: any) => {
    try {
      setToggleLoaderCaseStudy(true);
      await projectPortfolioService
        .getOneDriveFileViewByItemIdForCaseStudy(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
           setToggleLoaderCaseStudy(false);
            toast.warning("Error while fetching data");
          } else {
            debugger
            const byteCharacters = atob(response?.data?.result?.content)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters?.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: response?.data?.result?.contentType });
            const fileURL = URL.createObjectURL(blob);
            console.log(fileURL, "GetOneDriveFileViewByItemId")
            setOneDriveFolderItemOfCaseStudy(fileURL)
            setToggleLoaderCaseStudy(false);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoaderCaseStudy(false);
        toast.warning(err);
      });
    }
  };

  function getFileExtension(url: string): any {
    const splitUrl = url?.split(".");
    return splitUrl?.at(-1) || "";
  }


  var getProjectPortfolioView = async (id : any) => {
    debugger
    await projectPortfolioService.getPortfolioDetailsByProjectId(id).then((response : any) => {
      if(response.data.isError){}
      else{
        console.log(response.data, "Samurai");
        setPortfolioData([response.data]);
      }
    })
  }

  const getAllRolesOfUser = async () => {
    try {
      await projectPortfolioService
        .getAllRoleForUser()
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            setRoleList(response?.data);
            console.log(response?.data , "I got the roles");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getRoleNameFromRoleID = (roleId : string) => {
    debugger
    const roleName = roleList.find((item : any) => item.id == roleId);
    console.log("Role of User", roleName);
    return roleName?.name;
  }


  return (
    <div>
      <Box>
        <Grid container spacing={2} >
          {/* <Grid item xs={2}>
            <div
              style={{
                background: "#fff",
                position: "sticky",
                top: "104px",
                height: "80vh",
                overflowY: "auto",
              }}
            >
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Technology</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Java" />
                    <FormControlLabel control={<Checkbox />} label="Dotnet" />
                    <FormControlLabel control={<Checkbox />} label="PHP" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="React Native"
                    />
                    <FormControlLabel control={<Checkbox />} label="Angular" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Java script"
                    />
                    <FormControlLabel control={<Checkbox />} label="Magento" />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Domain</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Contact </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Environment</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Department</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            {/* <Box sx={{ marginBottom: "24px" }}>
              <TextField
                variant="outlined"
                placeholder="Search project name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "100px",
                    padding: "10px",
                    background: "#fff",
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                  },
                }}
                style={{
                  width: "100%",
                  maxWidth: "400px",
                }}
              />
            </Box> */}
            {portfolioData.map((item : any, idx : any) => {
              return (
                <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      padding: "28px",
                      background: "#fff",
                      border: "1px solid #F3F3F3",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: "30px",
                        borderBottom: "1px solid #E9E9E9",
                        flexWrap: "wrap",
                        gap: "16px",
                      }}
                    >
                      <div>
                        <h2 style={{ margin: "0" }}>{item?.projectName || "--"}</h2>
                        <h5 style={{ margin: "0", fontWeight: "400" }}>
                          {item?.clientName || "--"} &nbsp;
                          {(() => {
                            if (!item?.country) {
                              return "--";
                            }
                          
                           
                            const countryCode = getCode(item.country);
                          
                         
                            if (!countryCode) {
                              return "--"; 
                            }
                          
                            return <ReactCountryFlag countryCode={countryCode} svg />;
                          })()}

                        </h5>
                      </div>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              color: "#068DF2",
                              fontSize: "18px",
                              fontWeight: "500",
                              alignItems: "center",
                            }}
                          >
                            NDA
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #F6F6F6",
                              padding: "6px 8px",
                              borderRadius: "6px",
                              background: "#F9F9F9",
                            }}
                          >
                            {item?.canWeShowPubliclyOrUnderNDA ? "Yes" : "No"}
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #F6F6F6",
                              padding: "6px 8px",
                              borderRadius: "6px",
                              background: "#F9F9F9",
                              color: "#26B144",
                            }}
                          >
                            {(() => {
                              switch (item.status){
                                case 1:
                                  return "New";
                                  break;
                                case 2:
                                  return "In Progress";
                                  break;
                                case 3:
                                  return "Completed";
                                  break;
                                case 4:
                                  return "On Hold";
                                  break;
                                case 5:
                                  return "New & In-Progress";
                                  break;
                                default:
                                  return "--";   
                              }
                              return `${item?.status}`
                            })()}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            color: "#8B8B8B",
                            fontSize: "12px",
                            marginTop: "18px",
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                         
                          <span>

                            {item?.startDate && item?.startDate == "0001-01-01T00:00:00Z" ? (
                              <>
                                {" "}
                              </>
                            )  : (
                              <>
                               <CalendarTodayIcon
                                  sx={{ color: "#4B4B5A", fontSize: "18px" }}
                                />{" "}

                                Date: <span>{moment(item?.startDate).format("DD-MM-YYYY")}</span>
                              </>
                            ) }

                            {/* Date: <span>{moment(item?.startDate).format("DD-MM-YYYY")} </span>  */}
                            
                            {item?.endDate && item?.endDate == "0001-01-01T00:00:00Z" ? (
                              <>
                              {" "}
                             
                             </>
                            ) : (
                              <>
                              to{" "}
                              <span>{moment(item?.endDate).format("DD-MM-YYYY")}</span>
                              </>
                            )}
                            
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        paddingBlock: "20px",
                        borderBottom: "1px solid #E9E9E9",
                        wordWrap: "break-word"
                      }}
                    >
                      <h3 style={{ fontSize: "18px", marginTop: "0" }}>
                        Project Description
                      </h3>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#666666",
                          margin: "0",
                        }}
                      >
                        {item?.projectDescription}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: "16px",
                      }}
                    >
                      <Box>
                        <small
                          style={{
                            color: "#ACACAC",
                            fontSize: "14px",
                            margin: "0",
                          }}
                        >
                          Project Type
                        </small>
                        <h6
                          style={{
                            margin: "0",
                            color: "#4B4B5A",
                            fontSize: "18px",
                          }}
                        >
                          {item?.projectType == 1 ? "Time And Material" : "Fixed Cost"}
                        </h6>
                      </Box>
                      <Box>
                        <small
                          style={{
                            color: "#ACACAC",
                            fontSize: "14px",
                            margin: "0",
                          }}
                        >
                          Engagement Type
                        </small>
                        <h6
                          style={{
                            margin: "0",
                            color: "#4B4B5A",
                            fontSize: "18px",
                          }}
                        >
                          {(() => {
                            switch(item.engagementType){
                              case 1:
                                return "Direct";
                                break;
                              case 2:
                                return "Via Vendor";
                                break;
                              case 3:
                                return "Via Middleman";  
                                break;
                            }
                          })()}
                        </h6>
                      </Box>
                      <Box>
                        <small
                          style={{
                            color: "#ACACAC",
                            fontSize: "14px",
                            margin: "0",
                          }}
                        >
                          Financial
                        </small>
                        <h6
                          style={{
                            margin: "0",
                            color: "#4B4B5A",
                            fontSize: "18px",
                          }}
                        >
                          Cost: <span style={{ color: "#17951C" }}> {getSymbolFromCurrency(item?.currency)} {item?.totalCost || "0"}</span>
                          &nbsp; Received Cost:{" "}
                          <span style={{ color: "#17951C" }}>{getSymbolFromCurrency(item?.currency)} {item?.totalReceivedCost || "0"}</span>
                        </h6>
                      </Box>
                    </Box>
                  </Box>
                  
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                      marginTop: "18px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        paddingBottom: "24px",
                        borderBottom: "1px solid #E9E9EA",
                      }}
                    >
                      <span
                        style={{
                          height: "14px",
                          width: "14px",
                          borderRadius: "50%",
                          backgroundColor: "#199DF1",
                        }}
                      ></span>
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "#4B4B5A",
                        }}
                      >
                        Live Link
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: "16px",
                      }}
                    >
                      {item?.webUrl != null ? (
                      <Link to="" style={{ color: "#068DF2", fontSize: "14px" }} onClick={() => {
                        window.open(item?.webUrl, '_blank');
                      }}>
                        {item?.webUrl}
                      </Link> ) : ( "--" )}
                      {/* <Box
                        sx={{
                          border: "1px solid #F6F6F6",
                          padding: "6px 8px",
                          borderRadius: "6px",
                          background: "#F9F9F9",
                        }}
                      >
                        5/12/2016
                      </Box> */}
                    </Box>
                  </Box> 
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                      marginTop: "18px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "36px",
                      wordWrap: "break-word"
                    }}
                  >
                    <Box>
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        Project Objective
                      </h4>
                      <p
                        style={{
                          margin: "0",
                          color: "#4B4B5A",
                          fontSize: "14px",
                          fontWeight: "300",
                        }}
                      >
                        {item?.objectives || "--"}
                      </p>
                    </Box>
                    <Box>
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        Challenges
                      </h4>
                      <p
                        style={{
                          margin: "0",
                          color: "#4B4B5A",
                          fontSize: "14px",
                          fontWeight: "300",
                        }}
                      >
                       {item?.challenges || "--"}
                      </p>
                    </Box>
                    <Box>
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        KeyWords
                      </h4>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" ,wordBreak: "break-word"}}>
                        <Box
                          sx={{
                            border: "1px solid #F6F6F6",
                            padding: "6px 8px",
                            borderRadius: "6px",
                            background: "#F9F9F9",
                          }}
                        >
                          {item?.keyWords}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                      marginTop: "18px",
                      wordWrap: "break-word"
                    }}
                  >
                    <Box>
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        Client Feedback
                      </h4>
                      <p
                        style={{
                          margin: "0",
                          color: "#4B4B5A",
                          fontSize: "14px",
                          fontWeight: "300",
                        }}
                      >
                        {item?.clientFeedback || "--"}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "#4B4B5A",
                        }}
                      >
                        Case Study
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "12px",
                      }}
                    >
                      {/* <Link to="" style={{ color: "#068DF2", fontSize: "14px" }}>
                        https://www.figma.com/file/86wFinIopoFwE8slOzm6XD/DEVFORGE-
                      </Link> */}
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: "16px 24px",
                        textAlign: "center",
                        width: "100%",
                        height: "40vh",
                        margin: "auto",
                        position: "relative"
                      }}
                    >
                       {toggleLoaderForCaseStudy && (
                                    <Box sx={{position:'absolute', top:'0', right:'0', bottom:'0', left:'0', display:'flex', justifyContent:'center', alignItems:'center'}} >
                                      <CircularProgress size={50} color="secondary" sx={{}} />
                                    </Box>
                                    )}

                      {oneDriveFolderItemOfCaseStudy && !toggleLoaderForCaseStudy ? (
                        <video
                        src={oneDriveFolderItemOfCaseStudy}
                        poster={oneDriveFolderItemOfCaseStudy}
                        width='100%'
                        height='100%'
                        controls
                      // loop
                      />
                      ) : (
                        (!oneDriveFolderItemOfCaseStudy && !toggleLoaderForCaseStudy ) && (
                        <Typography>Video unavailable</Typography>
                       ) 
                     
                      ) }
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                      marginTop: "18px",
                    }}
                  >
                    <Box
                      sx={{
                        paddingBottom: "24px",
                        borderBottom: "1px solid #E9E9EA",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "#4B4B5A",
                        }}
                      >
                        Document
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
  
                        "& > :first-of-type": {
                          paddingRight: "20px",
                        },
                        "& > :last-of-type": {
                          paddingLeft: "20px",
                        },
                        "& > :not(:first-of-type):not(:last-of-type)": {
                          padding: "0 20px",
                        },
                        "& > :not(:last-child)": {
                          borderRight: "1px solid #C8C8C8",
                        },
                      }}
                    >
                      {oneDriveFolderList.map((item: any, idx: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOneDriveItemOfFolders([])
                          GetOneDriveFolderDataByProjectId(item?.id, "")
                          //openFileInNewTab()
                          handleClickOpen();
                          setSelectedFolder(item.id)
                        }}
                      >
                        <SourceIcon sx={{ fontSize: "34px", color: selectedFolder === item.id ? "#d9d9d9" : "#FCD462" }} />
                        <Box
                          sx={{
                            color: "#4B4B5A",
                            fontSize: "14px",
                            display: "inline-block",
                            marginTop: "4px",
                          }}
                        >
                           {item?.name}
                        </Box>
                      </Box> ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                      marginTop: "18px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#4B4B5A",
                        fontSize: "18px",
                        fontWeight: "600",
                        marginTop: "0",
                        marginBottom: "8px",
                      }}
                    >
                      Tech Stack
                    </h4>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" , wordBreak: "break-word" }}>
                      
                        {/* {item?.techStack || "--"} */}
                       {item?.skillSets && item?.skillSets.length > 0 ? (
                          item?.skillSets.map((obj : any , idx : number) => (
                            <Box
                        sx={{
                          border: "1px solid #F6F6F6",
                          padding: "6px 8px",
                          borderRadius: "6px",
                          background: "#F9F9F9",
                        }}
                      >
                            <span key={idx} >
                            {obj?.name || "--"}
                          </span>
                          </Box>
                           ))
                       ) : (
                          "--"
                       )}
                       
                    
                    </Box>
                  </Box>
                 
                      <Box
                      sx={{
                        padding: "24px",
                        border: "1px solid #F3F3F3",
                        backgroundColor: "#fff",
                        marginTop: "18px",
                      }}
                    >
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        Team Members
                      </h4>
                      <TableContainer>
                        <Table sx={{ minWidth: 200 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Role</TableCell>
                            </TableRow>
                          </TableHead>
                          {item?.teamMembers?.map((item : any) => {
                            return (
<TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderInline: "1px solid #E9E9EA !important",
                                }}
                              >
                                 {item?.name ? item?.name : "--"}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderInline: "1px solid #E9E9EA !important",
                                }}
                              >
                                { item?.roleId ? getRoleNameFromRoleID(item?.roleId) : "--"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                            )
                          })}
                          
                        </Table>
                      </TableContainer>
                    </Box>
                   
                 
                  <Box
                    sx={{
                      padding: "24px",
                      border: "1px solid #F3F3F3",
                      backgroundColor: "#fff",
                      marginTop: "18px",
                    }}
                  >
                    <h4
                      style={{
                        color: "#4B4B5A",
                        fontSize: "18px",
                        fontWeight: "600",
                        marginTop: "0",
                        marginBottom: "8px",
                      }}
                    >
                      Complacence Followed
                    </h4>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" , wordBreak: "break-word" }}>
                      <Box
                        sx={{
                          border: "1px solid #F6F6F6",
                          padding: "6px 8px",
                          borderRadius: "6px",
                          background: "#F9F9F9",
                        }}
                      >
                        {item?.complacenceFollowed || "--"}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              )
            })}
           
          </Grid>
        </Grid>
      </Box>

      <Dialog onClose={Button} open={open} maxWidth="sm"
        fullWidth>
        {toggleLoader && (<CircularProgress size={120} sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
        <DialogTitle id="customized-dialog-title" >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: '24px', color: '#232360', fontWeight: '500' }}>
              Documents
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

        </DialogTitle>
        <DialogContent dividers>
          <React.Fragment>
            <Box sx={{ padding: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#3A3A49",
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <AttachmentIcon sx={{ color: "#068DF2" }} />
                  Attached Link
                </Box>
              </Box>
              {oneDriveItemOfFolders.map((row: any) => (
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    GetOneDriveFileViewByItemId(row?.id)
                  }}
                >
                  {getFileExtension(row?.name) == "pdf" && (
                    <Icons.Pdfdocs />
                  )}
                  {getFileExtension(row?.name) == "docx" && (
                    <Icons.docxicon />
                  )}
                  {getFileExtension(row?.name) == "png" && (
                    <Icons.pngicon />
                  )}
                  {getFileExtension(row?.name) == "jpeg" && (
                    <Icons.jpegicon />
                  )}
                  {getFileExtension(row?.name) == "jpg" && (
                    <Icons.jpegicon />
                  )}
                  {getFileExtension(row?.name) == "txt" && (
                    <Icons.txticon />
                  )}
                  {getFileExtension(row?.name) == "xlsm" && (
                    <Icons.xlsmicon />
                  )}
                  {getFileExtension(row?.name) == "xlsx" && (
                    <Icons.xlsxicon />
                  )}
                  {getFileExtension(row?.name) == "xls" && (
                    <Icons.xlsicon />
                  )}
                  {getFileExtension(row?.name) == "docm" && (
                    <Icons.docxicon />
                  )}
                  <Box sx={{ ml: 1 }}>
                    {row?.name}
                  </Box>
                </Box>))}

              {/* <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  padding: "16px",
                  textAlign: "center",
                  width: "150px",
                  margin: "20px auto 0 auto",
                  borderRadius: "14px",
                  backgroundColor: "#FBFBFB"
                }}
              >
                <input
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.txt,.rtf,.html,.htm"
                  style={{ display: "none" }}
                  id="upload-document"
                  type="file"
                />
                <label htmlFor="upload-document">
                  <IconButton
                    color="primary"
                    aria-label="upload document"
                    component="span"
                  >
                    <CloudUploadIcon
                      sx={{ fontSize: 40, color: "#03A9F4" }}
                    />
                  </IconButton>
                </label>
                <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#4B4B5A" }} variant="h6">
                  Upload Document
                </Typography>
              </Box> */}
            </Box>
          </React.Fragment>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>     

    </div>
  );
};

export default PortfolioView;
