interface CustomCheckboxProps {
    label: string;
    checked: boolean;
    onChange: (isChecked: boolean) => void;
  }
  
  const styles: any = {
    customCheckbox: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    hiddenCheckbox: {
      position: 'absolute',
      opacity: 0,
    },
    checkboxIcon: {
      width: '20px',
      height: '20px',
      border: '1px solid #000',
      marginRight: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      fontSize: '14px',
    },
  };
  const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ label, checked, onChange }) => {
    const handleChange = () => {
      onChange(!checked);
    };
  
    return (
      <label className={styles.customCheckbox}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className={styles.hiddenCheckbox}
        />
        <span className={styles.checkboxIcon}>{checked}</span>
        <span className={styles.label}>{label}</span>
      </label>
    );
  };
  
  export default CustomCheckbox;