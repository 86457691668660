import React, { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Paper from "@mui/material/Paper";
import Stack, { StackProps } from "@mui/material/Stack";
import { Badge, Button, CircularProgress, Menu, MenuItem, TextField, Typography } from "@mui/material";
import SearchBar from "../../components/styledComponent/SearchBar";
import Box, { BoxProps } from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import * as Icons from "../../assests/icons/icons";
import theme from "../../theme/theme";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import { projectManagementServive } from "../../service/project-management-service";
import AssignResource from "./Controls/AssignResources";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import NotFound from "../../components/styledComponent/NotFound";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { commonFunctions } from '../../service/common-services/common-functions';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import InfoPopover from "../../components/CommonInfoComponent";
import { toast } from "material-react-toastify";
const dummy = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

const styles = {
  toggleloaders: {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
  },
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1060,
};
const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  height: "100%",
  transition: "all 0.3s ease ",
  "&:hover": {
    boxShadow: " 0px 0px 40px rgba(135, 135, 135, 0.25)",
    border: "none",
    "& .MuiPaper-root": {
      borderColor: "transparent",
    },
  },
  // paddingLeft: theme.spacing(0.5),
  // paddingRight: theme.spacing(0.5),
  // paddingBottom: theme.spacing(0.5),

  // marginRight: theme.spacing(2),
  // marginBottom: theme.spacing(2),
  // maxWidth: 312,
  // flex: 1,
  // [theme.breakpoints.up("lg")]: {

  // },
}));

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  alignItems: "stretch",
  height: "100%",
  // maxWidth: 408,
}));

const StyledToggleButton = styled(ToggleButtonGroup)(({ theme }) => ({

  "&.MuiToggleButtonGroup-root": {
    [theme.breakpoints.down('lg')]: {
      flexDirection: "column",
      display: "flex",
      alignItems: 'flex-start'
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    marginRight: theme.spacing(3),
    border: 0,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.dark,
    textTransform: "capitalize",
    padding: "4px 6px",
    height: " 28px",

    "&.Mui-selected": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
    "&:last-of-type": {
      marginRight: theme.spacing(0),
    },
  },
}));

const styleCross = {
  position: "absolute" as "absolute",
  right: "-20px",
};

const New = {
  fontWeight: 400,
  color: "#16B5BF",
  m: 0,
};

const Completed = {
  fontWeight: 400,
  color: theme.palette.success.dark,
  m: 0,
};

const Onhold = {
  fontWeight: 400,
  color: theme.palette.error.dark,
  m: 0,
};

const Inprogress = {
  fontWeight: 400,
  color: theme.palette.primary.main,
  m: 0,
};
interface Data {
  projectDescription: string;
  projectName: string;
  projectOwnerName: string;
  keyWords: string;
  clientName: string;
  departmentId: string;
  departmentName: string;
  costingType: string;
  status: string;
  id: string;
  pin: string;
  createdDate: string;
  projectKey: string;
  projectOwnerId: string;
  srNo: string;
}



export default function OtherScreenManager() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const [projects, setProjects] = useState<any[]>([]);
  const [projectss, setProjectss] = useState<Data[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const navigation = useNavigate();

  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [selectedDepartmentIndex, setselectedDepartmentIndex] = React.useState(0);
  const [departmentHook, setDepartmentHook] = React.useState("");
  const [toggleLoader, setToggleLoader] = useState(false);

  const [projectId, setProjectId] = React.useState("");
  const [alignment, setAlignment] = React.useState("0");
  const [projectStatus, setProjectStatus] = React.useState({});
  const [statusDataHook, setStatusDataHook] = useState("0");
  const [localUserId, setLocalUserId] = useState(
    localStorage.getItem("userId")
  );

  const [currentLoggedInRole, setLoggedInRole] = useState(0);
  const [currentLoggedInDepartmentId, setLoggedInDepartmentId] = useState("");
  useEffect(() => {
    if (_authUser.typeOfUser) {
      let curreUserRole = _authUser.typeOfUser;
      let curreUserDepartMentId = _authUser.employeeInfo.departmentId;
      curreUserRole = curreUserRole ? curreUserRole : "";
      curreUserDepartMentId = curreUserDepartMentId
        ? curreUserDepartMentId
        : "";
      setLoggedInDepartmentId(curreUserDepartMentId);
      setLoggedInRole(curreUserRole);
    }

    if (_authUser.employeeInfo.departmentId) {
      let curreUserDepartMentId = _authUser.employeeInfo.departmentId;
      curreUserDepartMentId = curreUserDepartMentId
        ? curreUserDepartMentId
        : "";
      setLoggedInDepartmentId(curreUserDepartMentId);
    }
    const LoadMasterData = async () => {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      getProjectDetails("");
    };
    LoadMasterData();

  }, []);

  const [searchValue, setSearchValue] = useState("");

  const handleChange = async (
    event: any,
    // newAlignment: string,
    index: any
  ) => {


    setStatusDataHook(event.target.value)

    setProjectStatus(getProjectStatus(event.target.value));
    setDepartmentHook(departments[event.target.value].id);
    // await setSelectedIndex("index");
    await setAnchorEl(null);
    await getProjectDetails(searchValue, event.target.value);
    // getProjectDetails(index);
  };

  var departmentData = { id: "", name: "All Projects" };
  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await projectManagementServive
      .getDropdownData(prm)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          let data = []; //response.data;//[departmentData];
          data.push(departmentData);
          response.data.map((dep: any) => {
            data.push(dep);
          });
          setDepartments(data);
        }
      });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (id: any) => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const openStatus = Boolean(anchorEl);
  const handleCloseStatus = () => {
    setAnchorEl(null);
  };

  const getProjectDetails = async (search: any, status = 0) => {
    setToggleLoader(true);
    let values = {
      // department: departmentId ?? "",
      status: status,
      search: search ?? "",
      take: 1000,
      skip: 0,
      isManageProject: false,
      isMyProject: false,
    };
    try {
      await projectManagementServive
        .geOthersProjects(values)
        .then((response: any) => {
          if (response.data.isError) {
            setToggleLoader(false);
          } else {
            setProjectss(response?.data);
            setProjects(response.data);
            setToggleLoader(false);
          }
        });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err);
      });
    }
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const triggerSearch1 = async (obj: any, search: any) => {
    getProjectDetails(search);
  };

  const getProjectStatus = (currentStatus: any) => {

    switch (currentStatus) {
      case "1":
        return New;
      case "2":
        return Inprogress;
      case "3":
        return Completed;
      case "4":
        return Onhold;
      default:
        return New;
    }
  };
  const [openManModal, setModalManOpen] = React.useState(false);
  const [projectIdForModel, setprojectIdForModel] = useState("");
  const [projectOwnerId, setProjectOwnerId] = useState("");
  const [projectOwnerDepartMentId, setProjectOwnerDepartMentId] = useState("");
  const handleManModalOpen = (
    id: any,
    projectOwnerDepartMentId: string,
    projectOwnerId: string
  ) => {
    setModalManOpen(true);
    setprojectIdForModel(id);
    setProjectOwnerDepartMentId(projectOwnerDepartMentId);
    setProjectOwnerId(projectOwnerId);
  };

  const handleManModalClose = () => setModalManOpen(false);

  // const handleParentClick = () => {
  //   console.log('handleParentClick');
  // };
  // const handleChildClick = (e: any) => {
  //    e.stopPropagation(); 
  //    //enable to stop the click event from bubbling up(up abive all the parent elements).
  //   console.log('handleChildClick');
  // };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Projects</Typography>
        <Typography color="text.primary">My Projects</Typography>
        <Typography color="text.primary">Others Projects</Typography>
      </Breadcrumbs>
      {toggleLoader && (<CircularProgress sx={styles.toggleloaders} />)}
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
          <InfoPopover onInput={"Others Project"} />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: '"space-between"' }} alignItems={{ xs: "flex-start", sm: "center" }}
          spacing={2}
        >

          <Button
            id="basic-button"
            aria-controls={openStatus ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openStatus ? "true" : undefined}
            onClick={handleClick}
            sx={{ justifyContent: "space-between", fontSize: "24px", fontWeight: 600, }}
            variant="selectSmall"
          >
            {"Others Projects"} <ArrowDropDownIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openStatus}
            onClose={handleCloseStatus}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >

            <MenuItem
              onClick={() => {
                handleCloseStatus();
                navigation("/my-projects");
              }}
            >
              My Projects
            </MenuItem>
          </Menu>
          <Box></Box>


          <StyledToggleButton

            color="primary"
            value={statusDataHook}
            exclusive
            onChange={handleChange}
            aria-label="Platform"

          >
            <ToggleButton value="0">All </ToggleButton>
            <ToggleButton value="1">New</ToggleButton>
            <ToggleButton value="2">In Progress</ToggleButton>
            <ToggleButton value="3">Completed</ToggleButton>
            <ToggleButton value="4">On Hold</ToggleButton>
          </StyledToggleButton>
          <Box sx={{ flexGrow: 1 }} />
          <SearchBar
            setSearchValue={setSearchValue}
            obj={{ departmentId: departmentHook }}
            callBackFunction={triggerSearch1}
          />
        </Stack>


      </Paper>

      <Grid
        container
        spacing={2}
      // direction={{ xs: "column", sm: "row" }}
      // justifyContent="flex-start"
      // sx={{ flexWrap: "wrap" }}
      >
        {projects.map((project, idx) => (
          <Grid item xs={12} md={4} xl={3}>
            <StyledBox key={"peoject_" + idx}>
              <StyledCard variant="outlined">
                <CardContent  >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={projectStatus}
                  >
                    <Typography justifyContent="space-between" component="p">
                      {(() => {
                        switch (project.status) {
                          case 1:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={New}
                                style={{ color: "#16B5BF" }}
                              >
                                <Icons.dots /> New
                              </Typography>
                            );
                          case 2:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Inprogress}
                                style={{ color: theme.palette.primary.main }}
                              >
                                <Icons.dots /> In Progress
                              </Typography>
                            );
                          case 3:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Completed}
                                style={{ color: theme.palette.success.dark }}
                              >
                                <Icons.CompletedPro /> Completed
                              </Typography>
                            );
                          case 4:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Onhold}
                                style={{ color: theme.palette.error.dark }}
                              >
                                <Icons.dots
                                  style={{ verticalAlign: "middle" }}
                                />{" "}
                                On Hold
                              </Typography>
                            );
                          default:
                            return " Testing";
                        }
                      })()}
                    </Typography>
                    {/* <IconButton
                      key={"elipses_" + idx}
                      aria-label=""
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      id={project.id}
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <MoreHorizIcon />
                    </IconButton> */}
                    {/* {idx == 0 && (
                      <Menu
                        key={"menu_" + idx}
                        id={"basic-menu_" + idx}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => editProject()}>Edit</MenuItem>
                        <MenuItem onClick={handleClose}>View</MenuItem>
                      </Menu>
                    )} */}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 1 }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ flexWrap: "wrap" }}
                    >
                      {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ mr: 1, mb: 0 }}
                      /> */}
                      <Tooltip title={project.projectOwnerName} >
                        <Avatar
                          {...commonFunctions.stringAvatar(project.projectOwnerName)}
                          sx={{ mr: 1, mb: 0 }}
                        />
                      </Tooltip>
                      <Typography component="p" sx={{ fontWeight: 500 }}>
                        {project.projectName}{" "}
                      </Typography>
                    </Stack>
                    <Box>
                      <Typography variant="caption" display="block">
                        Start Date
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ fontWeight: 500, fontSize: 12 }}
                      >
                        {project.createdDate}
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      mt: 2,
                      color: alpha(theme.palette.grey[700], 0.7),
                      minHeight: "60px",
                    }}
                  >
                    {project.projectDescription}
                  </Typography>
                  {[1, 2, 3].includes(currentLoggedInRole) ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: 4 }}
                    >
                      <Stack direction="row" alignItems="center" sx={{}}>
                        <AvatarGroup max={3}>
                          {project?.resources?.length > 0 &&
                            project?.resources.map((y: any) => (
                              <Tooltip title={y.name} >
                                <Avatar
                                  {...commonFunctions.stringAvatar(y.name)}
                                />
                              </Tooltip>
                            ))}
                        </AvatarGroup>
                        {_permission.action_MyProjectsListing_AllocateResource_Access && (
                          <Tooltip arrow title="Allocate Resource">
                            <span>
                              <IconButton
                                color="primary"
                             disabled={project?.status === 3}
                                onClick={(event) => {
                                  handleManModalOpen(
                                    project.id,
                                    project?.departmentId,
                                    project.projectOwnerId
                                  );
                                }}
                                sx={{ ml: -2 }}
                              >
                                <Icons.AllocateResource />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </Stack>

                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing="1"
                      >

                        {/* {_permission.action_MyProjectsListing_LogSheet_Access && (
                          <Tooltip arrow title="Logsheet">
                            <span>
                              <IconButton
                                color="primary"
                                //  startIcon={<AddCircleIcon />}
                                component={Link}
                                to={`/project/logsheet/${project?.id}`}
                              >
                                <Stack >
                                  <Badge color="secondary" badgeContent={project.unApproveStatusCount}>
                                  <Icons.logisticks />
                                  </Badge>
                                </Stack>
                              </IconButton>
                            </span>
                          </Tooltip>
                        )} */}

                        {(() => {
                          switch (project.status) {
                            case 1:
                              return (
                                <>

                                  {_permission.action_MyProjectsListing_ProjectSettings_Access && (
                                    <Tooltip arrow title="Set up">
                                      <span>
                                        <IconButton
                                          color="primary"
                                          //  startIcon={<AddCircleIcon />}
                                          component={Link}
                                          to={`/board/` + project.id}
                                        >
                                          <AddCircleOutlineOutlinedIcon />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  )}


                                </>
                              );

                            case 2:
                              return (
                                <>
                                  {_permission.action_MyProjectsListing_ProjectSettings_Access && (
                                    <Tooltip arrow title="Board">
                                      <span>
                                        <IconButton
                                          color="primary"
                                          //  startIcon={<AddCircleIcon />}
                                          component={Link}
                                          to={`/board/` + project.id}
                                        >
                                          <Icons.BoardProject />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  )
                                  }
                                </>
                              );
                          }
                        })()}
                      </Stack>
                    </Stack>
                  ) : (
                    ""
                  )}
                </CardContent>
              </StyledCard>
            </StyledBox>
          </Grid>
        ))}
      </Grid>
      {projects.length == 0 && <NotFound NotfoundText="No Project Found" />}

      <Dialog
        open={openManModal}
        onClose={handleManModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ mb: 3 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Typography
              id="modal-modal-title"
              align="left"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 600 }}
            >
              Allocate Resource
            </Typography>

            <IconButton
              aria-label="delete"
              color="primary"
              sx={styleCross}
              onClick={handleManModalClose}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <AssignResource
            open={setModalManOpen}
            getProjectDetails={getProjectDetails}
            projectId={projectIdForModel}
            projectOwnerDepartMentId={projectOwnerDepartMentId}
            projectOwnerId={projectOwnerId}
            loginDepartmentId={currentLoggedInDepartmentId}
            currentLoggedInRole={currentLoggedInRole}
            handleManModalClose={handleManModalClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
