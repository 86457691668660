import React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { DebounceInput } from "react-debounce-input";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.light, 0.15),

  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.25),
  },

  marginLeft: 0,

  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(1),
    width: "auto",
  },
  "& .searcg-field": {
    padding: "10px 0 10px 38px",
    border: "1px solid #EEEEEE;",
    bordeRadius: "4px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(2, 1, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // height: '0.20em',
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      // '&:focus': {
      //     width: '24ch',
      // },
    },
  },
}));

export default function SearchAppBar(props: {
  setSearchTicket?: any;                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
  setSearchValue?: any;
  setsearchvalue? : any ;
  callBackFunction?: any;
  obj?: any;
  value?: any;
  placeholderText?: string;
}) {
  const handleChange = (query: string) => {
    if (props.setSearchTicket) {
      props.setSearchTicket(query);
    }
    if (props.callBackFunction) {
      if (props.obj) {
        props.callBackFunction(props.obj, query);
      } else {
        props.callBackFunction(query);
      }
    }
  };

  const removeExtraSpace = (s: any) => s.trim().split(/ +/).join(" ");

  // React.useEffect(() => {
  //     if (props.callBackFunction) {
  //         props.callBackFunction();
  //     }
  // }, []);

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <DebounceInput
          className={"searcg-field"}
          placeholder={props.placeholderText || "Search here..."} 
          minLength={1}
          debounceTimeout={2000}
          // onChange={event => (handleChange(removeExtraSpace(event.target.value)))}
          onChange={(event) => {
            let abc = removeExtraSpace(event.target.value);
            handleChange(abc); 
          }}
          value={props.value}
        />
        {/* <StyledInputBase placeholder="Search" onChange={(e: any) => handleChange(e.currentTarget.value)} /> */}
      </Search>
    </>
  );
}
