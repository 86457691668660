import { http } from "../http-common";

class meetingService {

  
addMOM = async (data: any): Promise<any> => {
    return await http.post<any>("/Meeting/CreateMOM/CreateMOM", data);
    };
     
    getAllMoMList = async (data: any): Promise<any> => {
        return await http.post<any>(`/Meeting/GetAllMOMInvitedMeetings/GetAllMOMInvitedMeetings`, data);
    };

    getAssigneByMeetingId= async (id:any): Promise<any> => {
        return await http.get<any>(`/Meeting/GetAssigneByMeetingId/GetAssigneByMeetingId?MeetingId=${id}`);
    }
   
    UploadMoMDocument = async (data : any): Promise<any> => {
        return await http.post<any>("/Meeting/UploadFile/UploadFile", data);
    };
    updateMomDetails= async (param:any): Promise<any> => {
        return await http.post<any>(`/Meeting/UpdateMOM/UpdateMOM`, param);
    }

    downloadFile = async (fileName: any,id:any): Promise<any> => {
        return await http.get<any>(`/Meeting/DownloadFile/DownloadProjectFile/${fileName}?MOMId=${id}`,{responseType:'blob'});
      };

      getMeetingById= async (id:any): Promise<any> => {
        return await http.get<any>(`/Meeting/GetMeetingById/GetMeetingById?id=${id}`);
    };

    deleteMoM = async (id: any): Promise<any> => {
        return await http.delete<any>(`/Meeting/DeleteMOM/DeleteMOM?momId=${id}`) ;
    }

}


export const meetingServices = new meetingService();