import moment from "moment";

export  function generateYearLabel(monthIndex: number,year: number) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const currentMonth = months[monthIndex % 12];
    return currentMonth +" "+ year.toString() ;
}

export  function generateYearRangeObjects(startYear: number, startMonth: number) {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 2;
    const startMonthIndex = (new Date(Date.parse(startMonth + " 1, 2000"))).getMonth();
    const yearRangeObjects: any = [];
    for (let year = startYear; year <= endYear; year += 1) {
        const endMonthIndex = startMonthIndex=== 0 ?11 : startMonthIndex - 1;
        yearRangeObjects.push({ label: generateYearLabel(startMonthIndex, year) + " to " + generateYearLabel(endMonthIndex, year + 1), id: year.toString() });
    }
    return yearRangeObjects;
}

export  function generateYearRangeObjectsWithoutMonth(startYear: number) {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 2;
    const yearRangeObjects: any = [];
    for (let year = startYear; year <= endYear; year += 1) {
        yearRangeObjects.push({ label: (year) + " to " + (year + 1), id: year.toString() });
    }
    return yearRangeObjects;
}

const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export function getCurrentYearId(dateRanges: any) {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
    var currMonthName = moment().format('MMMM').substring(0, 3);
    console.log(currMonthName, "currMonthName")
    for (const range of dateRanges) {
        const years = range.label.split(' to ')
        const startYear = parseInt(years[0].substr(-4));
        const startMonth = years[0].slice(0, -4).substring(0, 3);
        const endYear = parseInt(years[1].substr(-4));
        const endMonth = years[1].slice(0, -4).substring(0, 3);
        const endMonthNo= months.indexOf(endMonth)+1
        const startMonthNo= months.indexOf(startMonth)+1
        console.log(startYear, "startYear", startMonth)
        console.log(endYear, "endYear", endMonth)
        console.log(startMonthNo,endMonthNo, ";;;")
       
        const endDateValue = Date.parse(moment(new Date(endYear, endMonthNo - 1, 30)).format(
            "DD-MMM-YYYY"
          ));
        const startDateValue = Date.parse(moment(new Date(startYear, startMonthNo - 1, 1)).format(
            "DD-MMM-YYYY"
          ));
        const currentDateValue = Date.parse(moment(new Date()).format(
            "DD-MMM-YYYY"
          ));
        console.log(startDateValue,endDateValue,currentDateValue, ";;;")
        if (((currentDateValue <= startDateValue || (currentDateValue >= startDateValue)) && currentDateValue<=endDateValue)) {
            return range.id
        }


    }
    return null;
}
