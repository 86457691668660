import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams, } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Chart } from "react-google-charts";
import theme from '../../../theme/darkTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import { HrReportEmpoloyeeDetailFilter } from '../../../Interfaces/IReport';
import { reportService } from '../../../service/Reports/reports';
import { Chip } from '@mui/material';

const styles = {
    titleLabel: {
        fontWeight: ' 300',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#000000',
    },
    titles: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        color: '#232360',
    },
    EmpStats: {
        fontWeight: 700,
        fontSize: ' 36px',
    },
}


export default function AttendanceReportTable(props:{tableData:any[]}) {

    const getCurrentMonth = () => {
        const currentMonth = new Date().getMonth() + 1; 
        return currentMonth;
        };
        let currentYear = new Date().getFullYear();
        var currentYearString = currentYear;

    const [monthStatus, setMonthStatus] = useState<number | undefined>(getCurrentMonth());
   

    return (
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{position: "sticky", background: "#F5F5F5", zIndex: "999", left: "0"}}>Emp ID</TableCell>
                        <TableCell sx={{position: "sticky", background: "#F5F5F5", zIndex: "999", left: "98px"}}>Name</TableCell>
                        <TableCell>Department</TableCell>
                        <TableCell>In time (First Login)</TableCell>
                        <TableCell>Exit Time (Last Logout)</TableCell>
                        <TableCell>Total Hours</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.tableData.map((row, index) => (
                    <TableRow hover style={{ textDecoration: 'none' }}
                    component={Link} 
                    //to={"/attendance-detail/"+row.userId} 
                   // to={`/hr-monthly/${row?.userId}/${row.isLoggedInTracker == true ? 1 : 2}/${monthStatus}`}
                   to={`/hr-monthly`}
                    state={{
                        id:row?.userId,
                        type:row.isLoggedInTracker == true ? 1 : 2,
                        month:monthStatus,
                        year:currentYearString
                    }}
                    target="_blank"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell  sx={{position: "sticky", background: "#fff", zIndex: "99", left: "0"}} component="th" scope="row">{row.employeeId}</TableCell>
                        <TableCell  sx={{position: "sticky", background: "#fff", zIndex: "99", left: "96px"}}>{row.name}</TableCell>
                        <TableCell>{row.department}</TableCell>
                        <TableCell>{row.loginTime}</TableCell>
                        <TableCell>{row.logoutTime}</TableCell>
                        <TableCell>{row.totalHours == null || row.totalHours === "00" ? "" : ` ${row.totalHours}`}</TableCell>
                        <TableCell>
                            {row.status == "Present" && row.isLoggedInTracker == true && <Chip variant="approved" label="Tracker" />}
                            {row.status == "Present" && row.isLoggedInTracker == false && <Chip variant="progress" label="Portal" />}
                        </TableCell>
                        <TableCell>
                            {row.status == "Absent" && <Chip variant="hold" label="Absent" />}
                            {row.status == "Present" && <Chip variant="approved" label="Present" />}
                            {row.status == "On Leave" && <Chip variant="review" label="On Leave" />}
                        </TableCell>
                    </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
