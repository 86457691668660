import { http } from "../http-common";
class Domainservices {

    updateDomain = async (desig: any): Promise<any> => {
        
        return await http.put<any>("/v1/Master/UpdateDomain", desig);
    };

    addDomain = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/Master/AddDomain", desig);
    };

    GetAllDomainData = async (desig: any): Promise<any> => {
        
        return await http.post<any>("/v1/Master/GetAllDomainList", desig);
    };

   deleteDomain = async (id:any): Promise<any> => {
        return await http.delete<any>(`v1/Master/DeleteDomainById?Id=${id}`);
    }

    getDomainById = async (id:any): Promise<any> => {
        debugger
        return await http.get<any>(`/v1/Master/GetDomainById?id=${id}`);
    }

}

export const Domainservice = new Domainservices();