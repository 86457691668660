import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as Icons from "../assests/icons/icons";
import Achievers from "../assests/images/achievers.png";
import { deepOrange, deepPurple } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function ProjectsTasklist() {
  const [AdHashingSpace, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const styles = {
    card: {
      title: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        color: "#1DA7FF",
        marginBottom: "7px",
      },
      subTitle: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#212121",
      },
      opacity7: {
        opacity: "0.7",
      },
      rowTitleTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#616161",
        marginTop: "5px",
      },
      cardStyle: {
        borderRadius: "10px",
        border: "1px solid #EEEEEE",
        transition: ".3s",
        "&:hover": {
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.06)",
        },
      },
      tableHead: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#232360",
        border: "0",
        padding: "6px 0",
      },
      tableRow: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#424242",
        border: "0",
        padding: "11.5px 0",
      },
      tableRow2: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#424242",
        padding: "11.5px 0",
        borderBottom: "1px solid #EEEEEE",
      },
    },
  };
  return (
    <>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBottom: "18px", borderBottom: "1px solid #EEEEEE" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box>
              <Avatar sx={{ bgcolor: deepOrange[500] }}>NM</Avatar>
            </Box>
            <Box sx={{ marginLeft: "18px" }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#1da7ff",
                }}
              >
                Welcome
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "20px",
                  color: "#232368",
                }}
              >
                Nancy Martino
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Icons.FilledTime />
            </Box>
            <Typography
              variant="h3"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                color: "#212121",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              Logged in 10:00am
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Projects
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid sm={12} md={6} lg={3} item>
            <Box sx={[styles.card.cardStyle]}>
              <Box sx={{ marginBottom: "20px", padding: "18px 18px 0 14px" }}>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={[styles.card.title]}
                >
                  Seasia Connect
                </Typography>
                <Typography variant="h6" sx={[styles.card.subTitle]}>
                  Project Owner: Amardeep Pundir
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  backgroundColor: "#F0F9FF",
                  padding: "18px 14px 18px 14px",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Milestone
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Milestone 2
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Sprint
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Sprint 6
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    My Tasks
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    10
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Box sx={[styles.card.cardStyle]}>
              <Box sx={{ marginBottom: "20px", padding: "18px 18px 0 14px" }}>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={[styles.card.title]}
                >
                  Seasia Connect
                </Typography>
                <Typography variant="h6" sx={[styles.card.subTitle]}>
                  Project Owner: Amardeep Pundir
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  backgroundColor: "#F0F9FF",
                  padding: "18px 14px 18px 14px",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Milestone
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Milestone 2
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Sprint
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Sprint 6
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    My Tasks
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    10
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Box sx={[styles.card.cardStyle]}>
              <Box sx={{ marginBottom: "20px", padding: "18px 18px 0 14px" }}>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={[styles.card.title]}
                >
                  Seasia Connect
                </Typography>
                <Typography variant="h6" sx={[styles.card.subTitle]}>
                  Project Owner: Amardeep Pundir
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  backgroundColor: "#F0F9FF",
                  padding: "18px 14px 18px 14px",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Milestone
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Milestone 2
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Sprint
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Sprint 6
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    My Tasks
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    10
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Box sx={[styles.card.cardStyle]}>
              <Box sx={{ marginBottom: "20px", padding: "18px 18px 0 14px" }}>
                <Typography
                  variant="h5"
                  component="h2"
                  sx={[styles.card.title]}
                >
                  Seasia Connect
                </Typography>
                <Typography variant="h6" sx={[styles.card.subTitle]}>
                  Project Owner: Amardeep Pundir
                </Typography>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  backgroundColor: "#F0F9FF",
                  padding: "18px 14px 18px 14px",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Milestone
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Milestone 2
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    Sprint
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    Sprint 6
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={[styles.card.subTitle]}
                  >
                    My Tasks
                  </Typography>
                  <Typography variant="h6" sx={[styles.card.rowTitleTwo]}>
                    10
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ pb: 2, borderBottom: "1px solid #EEEEEE" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Task List
                </Typography>
                <Box sx={{ width: "175px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Ad Hashing Space
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={AdHashingSpace}
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650, mt: 2 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={[styles.card.tableHead]}>Sr No.</TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Task Summary
                      </TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Status
                      </TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Estimated Hour’s
                      </TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Actual hours
                      </TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Stats
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow]}>01</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>02</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>03</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>04</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>05</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>06</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>07</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>08</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>09</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ padding: "0 16px" }}>
                      <TableCell sx={[styles.card.tableRow]}>10</TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        Login Screen API
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        <Chip label="Closed" variant="new" />
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        6Hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow]} align="left">
                        100% complete
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            marginTop: "6px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#2DD36F",
                              height: "5px",
                              borderRadius: "100px",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper elevation={0} sx={{ p: 3, mt: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  pb: 2,
                  borderBottom: "1px solid #EEEEEE",
                }}
              >
                Recent Activity
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  pb: 2,
                  fontSize: "16px",
                  mt: 1,
                }}
              >
                Weekly Activity{" "}
                <Box sx={{ fontSize: "12px", display: "inline-block" }}>
                  (This Week)
                </Box>
              </Typography>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650, mt: 1 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={[styles.card.tableHead]}>Date</TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Total Time
                      </TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Active Time
                      </TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Idle Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        22 April, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8.5hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        30min
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        22 April, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8.5hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        30min
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        22 April, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8.5hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        30min
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        22 April, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8.5hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        30min
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        22 April, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8.5hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        8hr
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        30min
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  paddingBottom: "12px",
                  borderBottom: "1px solid #EEEEEE",
                }}
              >
                <Icons.Leaderboard />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, marginLeft: "20px" }}
                >
                  Leaderboard
                </Typography>
              </Stack>
              <Box component="ol" sx={{ p: 0, listStylePosition: "inside" }}>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
                <Box
                  component="li"
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#424242",
                      display: "inline-block",
                    }}
                  >
                    Jawad (Designing)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#1DA7FF",
                      display: "inline-block",
                      float: "right",
                      paddingTop: "4px",
                    }}
                  >
                    35 Points
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Paper elevation={0} sx={{ p: 3, mt: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  paddingBottom: "12px",
                  borderBottom: "1px solid #EEEEEE",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Icons.BrowniePointsLogo />
                  <Box sx={{ marginLeft: "20px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      Brownie Points
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 300,
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#232360",
                        textAlign: "left",
                      }}
                    >
                      Total Brownie Points
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "23px",
                    color: "#232360",
                  }}
                >
                  100
                </Typography>
              </Stack>
              <TableContainer>
                <Table
                  sx={{ minWidth: 150, mt: 1 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={[styles.card.tableHead]}>Date</TableCell>
                      <TableCell sx={[styles.card.tableHead]} align="left">
                        Points Earned
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        28Feb, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        35
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        27Jan, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        30
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={[styles.card.tableRow2]}>
                        01Jan, 2023
                      </TableCell>
                      <TableCell sx={[styles.card.tableRow2]} align="left">
                        25
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper elevation={0} sx={{ p: 3, mt: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  paddingBottom: "12px",
                  borderBottom: "1px solid #EEEEEE",
                }}
              >
                <Icons.Badge />
                <Box sx={{ marginLeft: "20px" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Badge
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 300,
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#232360",
                      textAlign: "left",
                    }}
                  >
                    You got Achievers
                  </Typography>
                </Box>
              </Stack>
              <Box>
                <img src={Achievers} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
