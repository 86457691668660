import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { CircularProgress, IconButton, Modal, Pagination, TablePagination, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { inventoryService } from "../../../src/service/Inventory/inventoryService";
import { toast } from "material-react-toastify";
import PaginationControls from "../../components/PaginationComponent";
import NotFound from "../../components/styledComponent/NotFound";
import { apiUrl } from "../../service/common-services/apiURL";
import SearchIcon from "@mui/icons-material/Search";
import SearchBar from "../../components/styledComponent/SearchBar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const  toggleloaders ={
  position: "fixed",
   left: "50%",
   right: "50%",
   top: "50%", 
  bottom: "50%", 
  transform: "translate(-50%, -50%)" 
 }
 const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};


export default function InventoryManagement() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.InventoryPermissionApp.InventoryPermission);
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [toggleLoader, setToggleLoader] = React.useState(false);
  const [categories, Setcategories] = React.useState<any[]>([]);
  const [searchString, setsearchString] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleIconClick = (selectedCategory: any) => {
    // debugger;
    console.log(selectedCategory);
    navigate("/inventory-list/" + selectedCategory);
  };

  useEffect(() => {
    if (_permission.page_ManageCategoryManagement_Access != true) {
      navigate("/dashboard");
    }
    getAllCategories(searchString);
  }, []);


  const handleDeleteClick = () => {
    //handleClose();
    deleteCategorybyId(currentId);
    setOpenDelete(false);
    setAnchorEl(null);
  };
  const [searchValue, setSearchValue] = useState("");
  const triggerSearch = async (newValue: string) => {
    getAllCategories(newValue);
    setPage(0);
  };
  //Get All Inventory Categories --Api call

  const getAllCategories = async (searchString: any) => {
    setToggleLoader(true);
    let values = {
      search: searchString,
      take: 1000,
      skip: 0,
    };
    try {
    inventoryService.getCategories(values).then((response: any) => {
        if (response?.data.isError) {
          setToggleLoader(false);

        } else {
          var data = response?.data;
          data.forEach((element: any) => {
            element.isExpand = false;
          });
          Setcategories(data);
          setToggleLoader(false);
          console.log(data);
        }
    });
  }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err);
      });
    }
  }



  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (index: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
    // debugger;
    if (!event) {
      return;
    }
    console.log("index", index);
    let data = [...categories];
    console.log("data", data);
    data.forEach((element: any) => {
      element.isExpand = false;
    });
    console.log("data[index]", data[index]);
    data[index].isExpand = true;
    Setcategories(data);
    //setAnchorEl(event.currentTarget);

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    let data = [...categories];
    data.forEach((element: any) => {
      element.isExpand = false;
    });
    Setcategories(data);
  };

  const deleteCategorybyId = async (id: any) => {
    // debugger;
    console.log("id", id)
    try {
    await inventoryService.deleteCategorybyId(id).then((response: any) => {
      if (response?.data.isError) {
      }
      else {
        toast.success('Category Deleted successfully.');
        getAllCategories("");
      }
    })}
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err);
      });
    }
  }
 
  

  // const handleSearchChange =  (e: any) => {
  //   debugger;
  //   let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g, '');
  //   // setsearchString(value);
  //   if (value!=="") {
  //     getAllCategories(value);
  //   } else {
  //     getAllCategories("");
  //   }
  // } 
   
  const dummy = [
    { value: 8 },
    { value: 12 },
    { value: 16 },
    { value: 20 },
    { value: 24 },
    // Add more options if needed
  ];
  const [rowsPerPage, setRowsPerPage] = React.useState(16);
  const [page, setPage] = React.useState(0);
  const handleChangeRowsPerPage = (event: any) => {

    debugger;
    const selectedValue = parseInt(event.target.value, 10);
    setRowsPerPage(selectedValue);
    setPage(0);
  };

  const handleDecrement = () => {
    debugger;
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
};


  const handleIncrement = () => {
    debugger;
    setPage((prevPage) => Math.min(prevPage + 1, numCount - 1));
  };
  const numCount = Math.ceil(categories.length / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageCount = Math.ceil(categories.length / rowsPerPage);
  const visibleCategories = categories.slice(startIndex, endIndex);
  return (
    <>
      {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Typography>
          Inventory Management
        </Typography>
        <Paper>
          <Stack direction="row" p={2} justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: "1px solid #EEEEEE" }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
              Categories
            </Typography>
            <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} spacing={2}>

            <Box sx={{
                "& .searcg-field": {
                  width: { xs: "100%", md: "auto" },
                },
                width: { xs: "100%", md: "auto" },
              }}>
                <SearchBar
                  setSearchValue={setSearchValue}
                  callBackFunction={triggerSearch}
                />
              </Box>

              {/* <Grid item xs={12} sm={6}>
                <TextField
                  label="Search"
                  fullWidth
                  value={searchString}
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g, "")
                    // handleSearchChange(e);
                    setsearchString(value);
                
                  }}
                  InputProps={{
                    endAdornment: searchString != "" && (
                      <IconButton
                      onClick={() => getAllCategories(searchString)}
                      >
                        <SearchIcon style={{ color: '#1DA7FF' }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid> */}
                {_permission.action_ManageCategoryListing_Add_Access == true && (
              <Button
                variant="contained"
                component={Link}
                to="/inventory-new-category"
                color="primary"
                startIcon={<AddIcon />}  >
                Add Category
              </Button>)}
            </Stack>


          </Stack>
          <Box padding={2} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} sx={{ display: "flex" }}>
              {visibleCategories.length > 0 ? (
                visibleCategories.map((item: any, index: any) => (
                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                    {/* */}
                    <Card sx={{ border: "1px solid #eee", borderRadius: "5px", height: "100%", boxShadow: "none", position: "relative", paddingRight: '44px' }}>
                      <CardContent sx={{ paddingRight: '0px' }}>
                        <Stack direction="row" >
                          <Box onClick={(event: any) => handleIconClick(item.id)}>
                            <img width="63" height="55" src={apiUrl.getImageURL() + "/" + item.categoryImage} />
                          </Box>
                          <Box marginLeft={2} sx={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", width: 'calc(100% - 78px)' }}>
                            <Typography fontWeight={300} lineHeight={1} marginBottom={0.5} sx={{ width: '100%', wordWrap: 'break-word', fontSize: { xs: '16px', xl: '20px' } }}
                            >
                             {item.category}
                            </Typography>
                            <Typography variant="h6" fontWeight={700} lineHeight={1}>
                             {item.count}
                            </Typography>
                            {((_permission.action_ManageCategoryListing_Delete_Access == true) || (_permission.action_ManageCategoryListing_Edit_Access == true)) &&(
                            <Button
                              id="basic-button"
                              sx={{ position: "absolute", right: "0", top: "0", padding: '0px', minWidth: '40px', height: '32px' }}
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleClick(index)}
                            >
                              <MoreHorizIcon />
                            </Button>)}
                            <Menu
                              id="basic-menu"
                              // anchorEl={anchorEl}
                              anchorEl={anchorEl}
                              // getContentAnchorEl={null}
                              open={item.isExpand}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              {_permission.action_ManageCategoryListing_Edit_Access == true&& (
                              <MenuItem onClick={handleClose} component={Link} to={"/inventory-new-category/" + item.id}>
                                <ListItemIcon sx={{ color: "#1DA7FF" }}>
                                  <EditOutlinedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#1DA7FF" }}
                                > Edit</ListItemText>
                              </MenuItem>)}
                              {_permission.action_ManageCategoryListing_Delete_Access == true&& (
                              <MenuItem key={item.id} onClick={() => {setOpenDelete(true); setCurrentId(item?.id);handleClose();}}>
                                <ListItemIcon sx={{ color: "#1DA7FF" }}>
                                  <DeleteOutlineIcon fontSize="small" />
                                </ListItemIcon >
                                <ListItemText sx={{ color: "#1DA7FF" }} >Delete</ListItemText>
                              </MenuItem>)}
                            </Menu>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: '100vh' }}
                >
                  <Grid sx={{ textAlign: 'center' }}>
                    <NotFound NotfoundText="No Record Found" />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Stack>
              {/* <PaginationControls
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                dummy={dummy}
                page={page}
                numCount={numCount}
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
              /> */}
                <TablePagination
                                        rowsPerPageOptions={[16, 24, 28, 32, 36]}
                                        component="div"
                                        count={categories.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={() => `Total: ${categories?.length}  Page ${page + 1} of ${pageCount}`}
                                    />
            </Stack>
          </Box>
        </Paper>
      </Box>
      <Modal
        open={openDelete}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Category
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Do you really want to Delete this, <br/>  Continue?
          
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDeleteClick}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  )
}
