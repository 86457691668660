import React from "react";

const Userlisttop = {
    topbar: {
        width:"100%",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: { xs: "flex-start", lg: "space-between" },
        alignItems: { xs: "strech", lg: "center" },
        pb: 3,
        mt: 2,
        flexWrap:'wrap',

    },
    searchbar: {
        "& .searcg-field": {
            padding: "10px 0 10px 38px",
            border: "1px solid #EEEEEE;",
            bordeRadius: "4px",
            width: { xs: "100%", md: "90%" },
        },
    
    },
    actionpopup: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
    },
    topright:{
        marginBottom:"30px",
        marginTop: "20px",
        flexDirection:{ xs: "column", md: "row" },
        justifyContent:{xs: "flex-start", md: "flex-start" },
        alignItems:{ xs: "strech", md: "center" },
         width:'auto',
         "& .css-sf5568-MuiStack-root":{
            width:'auto'
          }
    }
}
export default Userlisttop;