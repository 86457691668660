import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import { Autocomplete, TablePagination, TableSortLabel } from "@mui/material";
import theme from "../../../theme/theme";
import { Department } from "../../../Interfaces/IDepartment";
import Notes from "../../../components/styledComponent/Note";
import { IResource } from "../../../Interfaces/IResource";
import { clsDepartmentList } from "../../../service/common-services/department";
import { clsResourcesList } from "../../../service/common-services/resources";
import { masterreasonServices } from "../../../service/BrowniePoints/masterReason-services";
import { HrRewardMonth, HrRewardSatus } from "../../../Enum/eBrowines";
import AppConstants from "../../../config/AppConstants";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import InfoPopover from "../../../components/CommonInfoComponent";
import { Monts1 } from "../../../Enum/eMonths";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../../components/GetDateWithSimpletime";
import NotFound from "../../../components/styledComponent/NotFound";
// import { getDate } from "date-fns";


const options = ["All Department", "All Manager ", "All Months"];

interface Data {
  srNo: string;
  id: string;
  employeeId: string;
  name: string;
  manager: string;
  department: string;
  pointType: string;
  totalEarnedPoint: string;
  userId: string;
  totalUnredeemPoint: string;
  pointToRedeem: string;
  valueOfPointToRedeem: string;
  requestedOn: string;
  status: number;
  paidOn: string;
  valueOfUnredeemPoints: string;
}

export default function RewardRequest() {
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const hrRewardSatus = Object.keys(HrRewardSatus);
  const hrRewardMonth = Monts1;

  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // -----------------------------

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: any | any },
    b: { [key in Key]: any | any }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [GoBtnDisable, setgoBtnDisable] = React.useState(false);
  const [valueDate, setValueDate] = React.useState<Dayjs | null>(null);
  let DepartmentId = "";
  const [departments, setDepartments] = React.useState<Department[]>([]);

  const [departmentHook, setDepartmentHook] = React.useState("");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedsave, setSelectedSave] = React.useState<readonly string[]>([]);

  const departmentsList = new clsDepartmentList();

  const [resources, setresources] = React.useState<IResource[]>([]);
  const [rewardStatusesHook, setRewardStatusesHook] =
    React.useState("All Status");
  const [rewardStatusesValueHook, setRewardStatusesValueHook] =
    React.useState<any>(1);
  const [rewardStatusesEl, setRewardStatusesEl] =
    React.useState<null | HTMLElement>(null);
  const openRewardStatusesEl = Boolean(rewardStatusesEl);
  const handleRewardStatusesClick = (event: React.MouseEvent<HTMLElement>) => {

    setRewardStatusesEl(event.currentTarget);
    var i = 1;
    if (event.currentTarget.innerHTML == "Request") {
      i = 1;
    } else if (event.currentTarget.innerHTML == "Paid") {
      i = 2;
    }
    var month = rewardMonthHook;
    if (month == "All Months") {
      month = "";
    }

    // getAllRewardRequest(departmentHook, resourceHook, month, i, "", "");
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      var toggle = rewardList.map((n) => n.id);
      var list = rewardList.filter((n) => n.status == 1);
      const newSelected = list.map((n) => n.id);
      setSelected(toggle);
      setSelectedSave(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleRewardStatusesClose = () => {
    setRewardStatusesEl(null);
  };


  const [statusOption, setStatusOption] = React.useState<any>(1);
  const statusList = [
    
    { label: 'Requested', statusId: "1" },
    { label: 'Paid', statusId: "2" },]


  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1)
  const dateString = currentMonth.toString();

  var monthName = currentDate.toLocaleString('default', { month: 'long' });

  const [endDate, setEndDate] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [rewardMonthHook, setRewardMonthHook] = React.useState(monthName);
  const [rewardMonthValueHook, setRewardMonthValueHook] = React.useState<any>(currentMonth);
  const [rewardMonthEl, setRewardMonthEl] = React.useState<null | HTMLElement>(
    null
  );
  console.log(rewardMonthValueHook, "rewardMonthValueHook")
  const openRewardMonthEl = Boolean(rewardMonthEl);
  const handleRewardMonthClick = (

    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRewardMonthEl(event.currentTarget);
    var status = rewardStatusesHook;
    if (status == "All Status") {
      status = "";
    }
    // getAllRewardRequest(
    //   departmentHook,
    //   resourceHook,
    //   event.currentTarget,
    //   status,
    //   "",
    //   ""
    // );
  };
  const handleRewardMonthClose = () => {
    setRewardMonthEl(null);
  };
  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("asc");
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rewardList, setRewardList] = useState<Data[]>([]);
  const pageCount = Math.ceil(rewardList.length / rowsPerPage);
  const [departmentsId, setdepartmentsId] = useState("");

  console.log(departmentsId, "departmentsId");
  const [resourceId, setResourceId] = useState("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  console.log(resourceId, "resourceId");
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const getAllRewardRequest = async (
    
    department: any,
    resource: any,
    months: any,
    status: any,
    startDate: any,
    endDate: any
  ) => {
    let values = {
      department: department,
      status: status,
      months: months,
      resource: resource,
      startDate: startDate,
      endDate: endDate,
      userTypeEmployee: false,
    };
    //setgoBtnDisable(true)
    await masterreasonServices
      .getAllRewardRequest(values)
      .then((response: any) => {
        if (response.data.isError) {
        } 
       
        else {
          //response.data
          setRewardList(response?.data);
        }
      });
  };

  const ApprovePaid = async () => {
    if (selectedsave.length <= 0) {
      return toast.warning("Please select before update the status");
    }
    let value = {
      redeemIds: selectedsave,
    };

    await masterreasonServices.ApprovePaid(value).then((response: any) => {
      if (response.data.isError) {
      } else {
        // var month = rewardMonthHook;
        // if (month == "All Months") {
        //   month = "";
        // }
        toast.success("Successfully paid"); 
        var status = rewardStatusesHook;
        if (status == "All Status") {
          status = "1";
        }
        getAllRewardRequest(
          departmentsId,
          resourceId,
          rewardMonthValueHook,
          status,
          startDate,
          endDate
        );
      }
    });
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rewardList.length) : 0;
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }
  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: "employeeId",
      numeric: false,
      disablePadding: false,
      label: "Emp ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "Manager",
    },
    {
      id: "department",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },
    // {
    //   id: "pointType",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Point Type",
    // },
    {
      id: "totalEarnedPoint",
      numeric: false,
      disablePadding: false,
      label: "Total Earned Points",
    },
    {
      id: "totalUnredeemPoint",
      numeric: false,
      disablePadding: false,
      label: "Unredemmed Points",
    },
    // {
    //   id: 'valueOfPointToRedeem',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'value Of PointToRedeem',
    // },
    {
      id: "pointToRedeem",
      numeric: false,
      disablePadding: false,
      label: "Redeemption Request",
    },
    {
      id: "valueOfPointToRedeem",
      numeric: false,
      disablePadding: false,
      label: "Value Of Redeemed Points",
    },
    {
      id: "requestedOn",
      numeric: false,
      disablePadding: false,
      label: "Requested On",
    },
  
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      order,
      orderBy,
      onSelectAllClick,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (

      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell>Paid On</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    status: number
  ) => {
    let newSelected: readonly string[] = [];
    if (status === 1) {
      const selectedIndex = selected.indexOf(name);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setSelectedSave(newSelected);
    }
  };

  const hanldemonthvalue = (value: any) => { };

  React.useEffect(() => {
    if (_permission.page_Reward_Request_Access !== true) {
      navigate("/dashboard");
    }
    const departmentsData = async () => {

      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setresources(await clsResourcesList.getResourcesDropdownList(""));
    };
    departmentsData();
    getAllRewardRequest("", "", rewardMonthValueHook, 1, "", "");
  }, []);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Points</Typography>
        <Typography color="text.primary">Reward Request</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack>

          <Typography variant="h5" component="h2" sx={{ fontWeight: 600, display: "flex" }}>

            Reward Request
            <InfoPopover onInput={"Reward Request"} />

          </Typography>


        </Stack>

        <Stack
          direction="row"
          spacing={2}
          //justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 ,ml:"-18px"}}
        >



          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Stack 
          direction="row" 
          alignItems="flex-end" 
          spacing={2} 
          sx={{flexWrap: "wrap", gap: "8px"}}
        
         
          >
            <Box>
             
              <Autocomplete
                value={
                  departments?.find(
                    (option) => option.id == departmentsId
                  ) ?? null
                }
                fullWidth
                sx={{ ml: "15px" }}
                id="department-drop"
                options={departments}
                getOptionLabel={(option) => option.name}
                onChange={async (event, value) => {
                  setdepartmentsId(value?.id ?? "")
                  setresources(
                    await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                  );
                  setResourceId("")
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="department-drop"
                    name="departmentsId"
                    //label="Departments"
                    variant="outlined"
                    value={departmentsId}
                    fullWidth
                    sx={{ minWidth: 215 }}
                  />
                )}
              />
            </Box>
            <Box> 
              {/* <Button
                id="reward-Department"
                aria-controls={
                  openRewardResourcesEl ? "reward-resource-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openRewardResourcesEl ? "true" : undefined}
                onClick={handleRewardResourcesClick}
                variant="selectSmall"
              >
                {
                  resources.find((a) => a.userId == resourceHook ?? "Resources")
                    ?.userName
                }{" "}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="reward-resource-menu"
                anchorEl={rewardResourcesEl}
                open={openRewardResourcesEl}
                onClose={handleRewardResourcesClose}
                MenuListProps={{
                  "aria-labelledby": "eports-period",
                }}
              >
                {resources.map((resource, index) => (
                  <MenuItem
                    key={resource.userId}
                    selected={index === selectedResourceIndex}
                    onClick={(event) => {
                      handleResourceItemClick(event, index);
                      setRewardResourcesEl(null);
                      setfiltersSelected(true);
                    }}
                  >
                    {resource.userName}
                  </MenuItem>
                ))}
              </Menu> */}
              <Autocomplete
                disabled={!departmentsId || departmentsId === ""}
                value={
                  resources?.find(
                    (option) => option.userId == resourceId
                  ) ?? null
                }
                fullWidth
                sx={{ ml: "8px" }}
                id="resources-drop"
                options={resources}
                getOptionLabel={(option) => option.userName}
                onChange={async (event, value) => {
                  setResourceId(value?.userId ??"")
                  setfiltersSelected(true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="resources-drop"
                    name="resourceId"
                    //label="Departments"
                    variant="outlined"
                    value={resourceId}
                    fullWidth
                    sx={{ minWidth: 200 }}
                  />
                )}
              />
            </Box>

            <Box>
              {/* <Button
                id="reward-Month"
                variant="selectSmall"
                aria-controls={
                  openRewardMonthEl ? "reward-Month-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openRewardMonthEl ? "true" : undefined}
                onClick={handleRewardMonthClick}
              >
                {rewardMonthHook} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="reward-Month-menu"
                anchorEl={rewardMonthEl}
                open={openRewardMonthEl}
                onClose={handleRewardMonthClose}
                MenuListProps={{
                  "aria-labelledby": "eports-period",
                }}
              >
                {hrRewardMonth.map((key, index) => (
                  <MenuItem
                    onClick={() => {
                      var v = key;
                      if (key == "All Months") {
                        v = "";
                      }
                      handleRewardMonthClose();
                      setRewardMonthHook(key);
                      setRewardMonthValueHook(v);
                      setfiltersSelected(true);
                    }}
                  >
                    {key}
                  </MenuItem>
                ))}
              </Menu> */}
              <Autocomplete
                value={
                  hrRewardMonth?.find(
                    (option: any) => option.label == rewardMonthHook
                  ) ?? null
                }
                sx={{
                  width: "150px",
                  mr: 1,
                  ml: "3px",
                  lineHeight: "0.80em",
                }}
                fullWidth
                id="outlined-basic"
                options={hrRewardMonth}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  setPage(0);
                  setfiltersSelected(true);
                  // setmonthsHook(value?.id ?? "");
                  setRewardMonthHook(value?.label ?? monthName);
                      setRewardMonthValueHook(value?.id ?? "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    name="selectedResourceId"
                    variant="outlined"
                    value={rewardMonthHook}
                    fullWidth
                  />
                )}
              />
            </Box>

            <Box>
              {/* <Button
                id="reward-Statuses"
                variant="selectSmall"
                aria-controls={
                  openRewardStatusesEl ? "reward-Statuses-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openRewardStatusesEl ? "true" : undefined}
                onClick={handleRewardStatusesClick}
              >
                {rewardStatusesHook} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="reward-Statuses-menu"
                anchorEl={rewardStatusesEl}
                open={openRewardStatusesEl}
                onClose={handleRewardStatusesClose}
                MenuListProps={{
                  "aria-labelledby": "eports-period",
                }}
              >
                {hrRewardSatus.map((key, index) => (
                  <MenuItem
                    onClick={(e: any) => {

                      var v = key;
                      if (key == "All Status") {
                        v = "";
                      }
                      handleRewardStatusesClick(e);
                      handleRewardStatusesClose();
                      setRewardStatusesHook(key);
                      if (key == "Request") {
                        setRewardStatusesValueHook(1);
                      }
                      else {
                        setRewardStatusesValueHook(2);
                      }


                      setfiltersSelected(true);
                    }}
                  >
                    {key}
                  </MenuItem>
                ))}
              </Menu> */}
               <Autocomplete
                disableClearable={statusOption && statusOption != "" ? false : true}
                value={
                  statusList?.find(
                    (option) => option.statusId == statusOption
                  ) ?? null
                }
                sx={{
                  width: "160px",
                 
                  lineHeight: "0.80em",
                }}
                fullWidth
                id="reports-period"
                options={statusList}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  setPage(0);
                  setStatusOption(value?.statusId ?? 1);
                  setRewardStatusesValueHook(value?.statusId ?? 1);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    name="selectedResourceId"
                    variant="outlined"
                    value={statusOption}
                    fullWidth
                  />
                )}
              />
            </Box>

           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  //disablePast
                  // disableFuture
                  label="From"
                  borderRadius="semi-square"
                  style={{ width: '150px'}}
                  // openTo="year"
                  // views={["year", "month", "day"]}
                  value={startDate ||new Date()}
                  onChange={(newValue: any) => {
                    let abc = getDate(newValue);
                    setfiltersSelected(true);
                    setStartDate(abc);
                  }}
                  // renderInput={(params: any) => (
                  //   <TextField
                  //     {...params}
                  //     readOnly={true} // Disable user input
                  //     autoComplete="off"
                  //     sx={{
                  //       "& .MuiSvgIcon-root": {
                  //         fill: theme.palette.primary.main,
                  //       },
                  //     }}
                  //   />
                  // )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  borderRadius="semi-square"
                  style={{ width: '150px'}}
                  // openTo="year"
                  // views={["year", "month", "day"]}
                  value={endDate ||new Date()}
                  onChange={(newValue: any) => {
                    let abc = getDate(newValue)
                    setfiltersSelected(true);
                    setEndDate(abc);
                  }}
                  // renderInput={(params: any) => (
                  //   <TextField
                  //     {...params}
                  //     readOnly={true} // Disable user input
                  //     autoComplete="off"
                  //     sx={{
                  //       "& .MuiSvgIcon-root": {
                  //         fill: theme.palette.primary.main,
                  //       },
                  //     }}
                  //   />
                  // )}
                />
              </LocalizationProvider>
           
          </Stack>

        </Stack>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Button variant="outlined" onClick={ApprovePaid}>
              Mark As Paid
            </Button>
            <Stack direction={'row'}>
              <Button
                onClick={() => {
                  debugger
                  if (!departmentsId){
                    //toast.warn("Please select Department First")
                    toast.warn("Please select Department First", {
                      onOpen: () => setgoBtnDisable(true),
                      onClose: () => setgoBtnDisable(false),
            
                    });
                  }
                  else{
                  setfiltersSelected(true);
                  getAllRewardRequest(departmentsId, resourceId, rewardMonthValueHook, rewardStatusesValueHook, startDate, endDate);
                  }
                }}
                color="primary"
                variant="contained"
                sx={{ ml: "auto" }}
                disabled={GoBtnDisable}
              >
                Go
              </Button>
              {filtersSelected && (
                <Button
                  onClick={() => {
                    setfiltersSelected(false);
                    setResourceId("");
                    setdepartmentsId("");
                    setEndDate("");
                    setStartDate("");
                    setRewardStatusesValueHook(1);
                    setRewardMonthValueHook(currentMonth);
                    getAllRewardRequest("", "", currentMonth, 1, "", "");
                    setRewardMonthHook(monthName);
                    setStatusOption(1);
                  }}
                  color="primary"
                  variant="contained"
                  sx={{ ml: 0.5 }}
                >
                  Clear
                </Button>)}
            </Stack>
          </Stack>



          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rewardList.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rewardList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId: any = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.id, row.status)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={false}
                      >
                        {/* {(AppConstants.BrowniePointRedeemStatus.Request === row.status &&
                                        <TableCell><Checkbox /></TableCell>                        
                                                            )}
                                      {(AppConstants.BrowniePointRedeemStatus.Paid === row.status &&
                                       <TableCell><Checkbox disabled checked={true}/></TableCell>                      
                                                            )}                       */}
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={row.status === 1 ? isItemSelected : true}
                            disabled={row.status === 1 ? false : true}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>

                        <TableCell>{row.employeeId}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.manager}</TableCell>
                        {/* <TableCell>{"Manager Dummy Data"}</TableCell> */}
                        <TableCell>{row.department}</TableCell>
                        {/* <TableCell>{"Department Dummy Data"}</TableCell> */}
                        {/* <TableCell>{row.pointType}</TableCell> */}
                        <TableCell>{row.totalEarnedPoint}</TableCell>
                        <TableCell>{row.totalUnredeemPoint}</TableCell>
                        <TableCell>{row.pointToRedeem}</TableCell>
                        <TableCell>{row.valueOfPointToRedeem}</TableCell>

                        <TableCell>
                          {moment(row.requestedOn).format("DD-MMM-YYYY")}
                        </TableCell>
                      
                        {AppConstants.BrowniePointRedeemStatus.Request ===
                          row.status && <TableCell>Request</TableCell>}
                        {AppConstants.BrowniePointRedeemStatus.Paid ===
                          row.status && <TableCell>Paid</TableCell>}

                        {row.paidOn === null && <TableCell>-</TableCell>}
                        {row.paidOn !== null && (
                          <TableCell>
                            {moment(row.paidOn).format("DD-MMM-YYYY")}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
           
            </Table>
            {rewardList?.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
          </TableContainer>
      
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25]}
            component="div"
            count={rewardList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         
            labelDisplayedRows={() => `Total: ${rewardList?.length}  Page ${page + 1} of ${pageCount}`}
          />
        </Box>
      </Paper>
    </>
  );
}
