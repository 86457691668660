import { http } from "./http-common";
class BacklogService {
  
  GetAllTicketForBacklogFiltersAsync = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/GetAllTicketForBacklogFiltersAsync", data);
};

  
}

export const backlogService = new BacklogService();
