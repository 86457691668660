import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { eTypeOfUser } from '../Enum/eTypeOfUser';
import { commoninfoservive } from '../service/common-services/CommonInfoservice';

interface InfoPopoverProps {

  onInput?: any;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({ onInput }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [details, setDetails] = React.useState("");
  useEffect(() => {
    getDetails(onInput);
  }, []);
  const getDetails = async (onInput: any) => {

    let abc = {
      roleId: _authUser.employeeInfo.roleId,
      pageName: onInput,
    }
    await commoninfoservive.getPageInfoByPageName(abc).then((response: any) => {

      if (response.data.isError) {
      } else {

        setDetails(response.data[0]?.summary);
        // outputFunction(response?.data);
      }
    });
  };

  const open = Boolean(anchorEl);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const shouldShowPopover = _authUser.typeOfUser === eTypeOfUser.Admin;
  return (
    <Box
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{ ml: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >{shouldShowPopover && (
      <><InfoOutlinedIcon sx={{ display: 'inline-block', color: '#ffb046' }} /><Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          width: "80%"
        }}
        open={open}
        anchorEl={anchorEl}
        elevation={0}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {/* <Typography sx={{ p: 1, background: '#fff9ec' }}>{content}</Typography> */}
        {details && (
          <Typography sx={{ p: 1, background: '#fff9ec' }}>{details}</Typography>
        )}
      </Popover></>)}
    </Box>
  );
};

export default InfoPopover;