import { http } from "../http-common";

class CalenderService {
  addCalender = async (param: any): Promise<any> => {
   
    return await http.post<any>(`/v1/LeaveManagement/AddYearlyLeaves`, param);
  };

  getAllCalenderDetails = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/LeaveManagement/GetAllYearlyLeaves", req);
  };

  GetCalenderWorkById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/LeaveManagement/GetYearlyLeavesById=${id}`);
  };

  deleteCalender = async (id: any): Promise<any> => {
    return await http.delete<any>(
      `/v1/LeaveManagement/DeleteYearlyLeaves?id=${id}`
    );
  };
}

export const calenderService = new CalenderService();
