import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import SearchBar from "../../../components/styledComponent/SearchBar";
import moment from "moment";
import { toast } from "material-react-toastify";
import Notes from "../../../components/styledComponent/Note";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Formik } from "formik";
import TableHead from "@mui/material/TableHead";
import * as Yup from "yup";
import TableRow from "@mui/material/TableRow";
import * as Icons from "../../../assests/icons/icons";
import { clsBrowniePointsList } from "../../../service/common-services/browniePoints";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Avatar, Chip, Divider, Tooltip } from "@mui/material";
import theme from "../../../theme/darkTheme";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { clsDepartmentList } from "../../../service/common-services/department";
import FormControlLabel from "@mui/material/FormControlLabel";
import { brownieemployeeservice } from "../../../service/master-service/brownieEmployee-service";
import NotFound from "../../../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import InfoPopover from "../../../components/CommonInfoComponent";
import { meetingService } from "../../../service/Meeting-service";

const styleCross = {
  position: "absolute" as "absolute",
  right: "15px",
  top: "15px",
};

interface Data {
  department: string;
  designation: string;
  emailId: string;
  employeeId: string;
  id: string;
  name: string;
  serialNumber: string;
  status: string;
  srNo: string;
}

const reason = [
  {
    id: "1",
    label: "reason-1",
  },
  {
    id: "2",
    label: "reason-2",
  },
  {
    id: "3",
    label: "reason-3",
  },
  {
    id: "4",
    label: "reason-4",
  },
];

const options = ["All Department", "All Manager ", "All Months"];

interface IBrowniePointsReason {
  id: string;
  reasonName: string;
  description: string;
  pointsForReason: number;
}

const styles = {
  view: {
    label: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#1DA7FF",
    },
    value: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      color: theme.palette.primary.dark,
    },
  },
};

interface reqrewrdsPoints {
  departmentId: string;
  userId: string;
  referedUserName: string;
  reason: string;
  comment:string;
}

interface reasonRewardsPoints {
  id: any;
  reasonName: string;
  description: string;
  pointsForReason: string;
  comment:string;
}

interface copyUserVale {
  userId: any;
  userName: string;
}

interface getPointInter {
  // //id: string;
  // pointType: string;
  // totalEarnedPoint: string;
  // allocatedPointDate: string;
  // userId: string;
  // referedUserName: string;
  // status: string;
  // totalUnredeemPoint: string;
  // unredeemPoint: string;
  // pointToRedeem: string;
  // valueOfPointToRedeem: string;
  pointType: string;
  allocatedPointDate: string;
  userId: string;
  managerName: string;
  employeeName: string;
  brownieReasonId: string;
  pointAssigned: string;
  pointValueAmount: string;
  referedUserName: string;
  status: string;
  rejectReason: string;
  comment: string;
}

interface Department {
  id: string;
  name: string;
}

export default function ReferPoints() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // -----------------------------
  const [browniePointsReasonList, setbrowniePointsReasonList] = React.useState<
    IBrowniePointsReason[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // -----------------------------------------

  const [openRequestBrowniePoints, setOpenRequestBrowniePoints] =
    React.useState(false);
  const [openRedeemPoints, setOpenRedeemPoints] = React.useState(false);

  const handleClickOpenRequestBrowniePoints = () => {
    setOpenRequestBrowniePoints(true);
    GetResourcesbyDepartmentId();
  };

  const handleClickOpenRedeemPoints = () => {
    setOpenRedeemPoints(true);
  };

  const handleCloseRedeemPoints = () => {
    setOpenRedeemPoints(false);
  };

  const handleCloseRequestBrowniePoints = () => {
    setOpenRequestBrowniePoints(false);
    setReferedUserNameForm("");
    setSelectedReason("");
    setReasonForm("");
    setReferUsernameForm("");
    setUserId("");
    setcurrentComment("");
    
  };

  // -----------------------------------------

  const [resource, setResource] = React.useState("");

  const handleChangeResource = (event: SelectChangeEvent) => {
    setResource(event.target.value as string);
  };

  //-----------------------------------------------

  const [openView, setOpenView] = React.useState(false);

  const handleClickOpenView = () => {
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  //---------------
  const [cycle, setcycle] = React.useState("");

  const handleChangeCycle = (event: SelectChangeEvent) => {
    setcycle(event.target.value as string);
  };

  const [getPoints, setGetPoint] = useState<any[]>([]);
  const [getPointss, setGetPointss] = useState<getPointInter[]>([]);
  const [reasonRewardsPoints, setReasonRewardsPoints] = useState<
    reasonRewardsPoints[]
  >([]);
  const [userId, setUserId] = useState<string>("");
  const [departId, setDepartId] = useState<string>("");
  const [selectedReason, setSelectedReason] = React.useState("");
  const [checkReasonId, setCheckReasonId] = React.useState("");
  const [usersValue, setUsersValue] = React.useState<any>([]);
  const [copyUsersValue, setCopyUsersValue] = React.useState<getPointInter[]>(
    []
  );
  const [disable, setDisable] = useState(true);
  const [managerNameForm, setMangerNameForm] = React.useState("");
  const [pointTypeNumber, setpointTypeNumber] = React.useState("");
  const [referLocalUsernamee, setReferLocalUsername] = React.useState("");

  const [referedUserNameForm, setReferedUserNameForm] = React.useState("");
  const [users, setUsers] = useState<Data[]>([]);
  const [currentmanagerNameForm, setcurrentMangerNameForm] = React.useState("");
  const [currentComment, setcurrentComment] = React.useState("");
  let rquestobj: reqrewrdsPoints = {
    departmentId: departId,
    userId: userId,
    referedUserName: referedUserNameForm,
    reason: "",
    comment:currentComment,
  };

  const [formRwardValues, setFormRewardValues] =
    React.useState<reqrewrdsPoints>(rquestobj);
  const [reasonForm, setReasonForm] = React.useState(formRwardValues?.reason);
  const [referUsername, setReferUsernameForm] = React.useState(
    formRwardValues?.referedUserName
  );
  const [departmentIdSetting, setdepartmentIdSetting] = React.useState(
    formRwardValues?.departmentId ?? ""
  );

  useEffect(() => {
    if (_permission.page_Emplyoyee_Refer_Access !== true) {
      navigate("/dashboard");
    }
    var curretName: any = _authUser.employeeInfo.currentUserName;
    setMangerNameForm(curretName);

    var referLocalName: any = _authUser.userName;
    setReferLocalUsername(referLocalName);

    const GetAsyncData = async () => {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setbrowniePointsReasonList(
        await clsBrowniePointsList.getBrowniePointsList()
      );
    };
    GetAsyncData();
    getReasonDetails();

    // setIssuelabel(labels.find((x: any) => x.id == id)?.name);
  }, []);

  useEffect(() => {
    var userIdd: any = "";
    if (_authUser.id) userIdd = _authUser.id;
    //setUserId(userIdd);
    // getPointDetails();
  });

  useEffect(() => {
    var departIdd: any = _authUser.employeeInfo.departmentId;
    setDepartId(departIdd);
    //getPointttDetails();
    // setIssuelabel(labels.find((x: any) => x.id == id)?.name);
  });

  useEffect(() => {
    getPointttDetails();
    GetResourcesbyDepartmentId();
    getProjDetails();
    getDepartments();
    getUsersDetails("", "");
    GetManagerbyDepartmentId();
  }, []);

  async function AddRequestRewardsPoints(data: any) {
    
    try {
      let rquestobj: reqrewrdsPoints = {
        departmentId: "",
        userId: userId,
        referedUserName: _authUser.employeeInfo.firstName+" "+_authUser.employeeInfo.lastName,
        reason: selectedReason,
        comment:currentComment,
      };
      await brownieemployeeservice
        .addRequestRewardsPoints(rquestobj)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            toast.success("Refer Rewards added successfully.");
            getPointttDetails();
            setOpenRequestBrowniePoints(false);
            setReferedUserNameForm("");
            setSelectedReason("");
            setReasonForm("");
            setReferUsernameForm("");
            setUserId("");
            setcurrentComment("");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  }

  const getSearchDetails = async (newValue: string) => {
    // {
    //   "pointType": 1,
    //   "resources": "",
    //   "months": ""
    // }

    var input = {
      // YourKey For Search: newValue,
      pointType: 1,
      resources: "",
      search: newValue,
      months: "",
      isReferScreen: true,
    };

    await brownieemployeeservice
      .getAllReferPointWithStatus(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          let responser = response?.data;
          setGetPoint(responser);
          setGetPointss(responser);
          if (response.data[0]?.pointType == 0) {
            setpointTypeNumber("Brownie Point");
          }
          if (response.data[0]?.pointType == 1) {
            setpointTypeNumber("Muffin Point");
          }
        }
      });
  };

  const GetManagerbyDepartmentId = async () => {
    await brownieemployeeservice
      .GetManagerByDepartmentId(_authUser.employeeInfo.departmentId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setcurrentMangerNameForm(response?.data.managerName);
          
        }
      });
  };

  const getPointttDetails = async () => {
    var input = {
      pointType: 0,
      resources: "",
      months: "",
      isReferScreen: true,
    };

    await brownieemployeeservice
      .getAllReferPointWithStatus(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          let responser = response?.data;
          setGetPoint(responser);
          setGetPointss(responser);

          if (response.data[0]?.pointType == 0) {
            setpointTypeNumber("Brownie Point");
          }
          if (response.data[0]?.pointType == 1) {
            setpointTypeNumber("Muffin Point");
          }
          setReferUsernameForm(response.data[0]?.referedUserName);
          setCheckReasonId(response.data[0]?.brownieReasonId);

          setCopyUsersValue(response?.data);
        }
      });
  };

  const getReasonDetails = async () => {
    await brownieemployeeservice
      .getAllReasonPointsData()
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          let responser = response?.data;
          setReasonRewardsPoints(responser);
        }
      });
  };

  const GetResourcesbyDepartmentId = async () => {
    var payload = {
      department: "",
      workLocationStatus: 0,
      status: "",
      search: "",
      take: 50000,
      skip: 0,
    };

    await meetingService
      .getUserForMeeting(payload)
    // await brownieemployeeservice
    //   .GetResourcesbyDepartmentId(departId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setUsersValue(response?.data?.result);
        }
      });
  };

  const getProjDetails = async () => {
    await brownieemployeeservice.getAllProject(0).then((response: any) => {
      if (response.data.isError) {
      } else {
        // setProjectIdSetting(response.data.id ?? "");
        //     setprojectNameId(response.data.projectName ?? "");
        setDepartId(response?.data.id ?? "");
        setdepartmentIdSetting(response.data[0]?.departmentName ?? "");
      }
    });
  };

  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await brownieemployeeservice.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something went wrong...");
      } else {
        // setDepartments(response.data);
        setdepartmentIdSetting(response?.data.name ?? "");
        // setprojectNameId(response.data.projectName ?? "");
        // setDepartId(response?.data.name)
      }
    });
  };

  const getUsersDetails = async (departmentId: any, search: any) => {
    let values = {
      department: departmentId ?? "",
      status: "",
      search: search ?? "",
      take: 1000,
      skip: 0,
    };
    await brownieemployeeservice.getUsers(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        setUsers(response?.data);
        // if(response.data.length==0){
        //     setNoUser(true);
        // }
      }
    });
  };

  // ----------------------------------------------------------------------------
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof getPointInter>("allocatedPointDate");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(getPointss.length / rowsPerPage);
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - getPointss.length) : 0;

  type Order = "asc" | "desc";

  const triggerSearch = async (newValue: string) => {
    getSearchDetails(newValue);
    // getUsersDetails("",newValue);
    setPage(0);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof getPointInter
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const fieldValidationReason = Yup.object().shape({
    reason: Yup.string().required("Reason is required"),
    referedUserName: Yup.string().required("Refer Username is required"),
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Point</Typography>
        <Typography color="text.primary">Refer Employee</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
      <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
            <InfoPopover  onInput={"ReferPoints"}/>
            </Stack>
        {/* <SearchBar setSearchValue={setSearchValue} callBackFunction={triggerSearch} /> */}

        <Stack
          direction={{xs:'column', sm:'row'}}
          spacing={2}
          justifyContent="space-between"
          alignItems={{xs:'strech', sm:"center"}}
          sx={{ pb: 3, mt: 2 }}
        >
           
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Refer Points
          </Typography>
          <Box
            sx={{
              flexGrow: {xs:0, sm:1},
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          />
          <Box sx={{
              "& .searcg-field": {
                width: { xs: "100%", sm: "auto" },
              },
              width: { xs: "100%", sm: "auto" },
          }}>
          <SearchBar
            setSearchValue={setSearchValue}
            callBackFunction={triggerSearch}
          />
          </Box>
        

            <Button
              variant="contained"
              onClick={handleClickOpenRequestBrowniePoints}
            >
              Refer Points
            </Button>

        </Stack>
        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TableContainer component={Paper} elevation={0}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell>Point Type </TableCell> */}
                  {/* <TableCell>Points Earned</TableCell> */}
                  <TableCell>Date</TableCell>
                  <TableCell>Recommendation For</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Comments</TableCell>
                  {/* <TableCell>Username</TableCell> */}
                  {/* <TableCell>Value(Rs)</TableCell>
                      <TableCell>Refered By View</TableCell> */}
                  <TableCell>Status</TableCell>
                  {/* <TableCell align="right"> Action &nbsp;</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getPointss?.map((detail, idx) => ( */}
                {stableSort(getPointss, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((detail, idx) => {
                    // const isItemSelected = isSelected(detail?.status);
                    return (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>{pointTypeNumber}</TableCell> */}
                        {/* <TableCell>{detail?.pointAssigned}</TableCell> */}
                        <TableCell>
                          {/* {format(
                            pointdetail?.allocatedPointDate,
                            "yyyy-mm-dd"
                          )} */}
                          {`${moment(detail?.allocatedPointDate).format(
                            "DD-MMM-YYYY"
                          )}`}
                        </TableCell>
                        <TableCell>{detail?.employeeName}</TableCell>
                        <TableCell>
                          {
                            reasonRewardsPoints.find(
                              (a) => a.id == detail?.brownieReasonId
                            )?.reasonName
                          }
                        </TableCell>
                        <TableCell>
                          {detail?.comment ||"--"}
                        </TableCell>
                        {/* <TableCell>
                          {users.find((a) => a.id == detail?.userId)?.name}
                        
                        </TableCell> */}
                        {/* {referLocalUsernamee} */}

                        <TableCell>{detail?.status==="Reject" && 
                               <Tooltip title={detail?.rejectReason}> 
                               <Chip variant="hold" label={detail?.status} />
                                </Tooltip>}
                              {detail?.status==="Pending" && <Chip variant="review" label={detail?.status} />}
                              {detail?.status==="Approved" && <Chip variant="approved" label={detail?.status} />}</TableCell>
                        {/* <TableCell align="right">
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={2}
                          > 
                            <Tooltip title="View">
                              <IconButton
                                aria-label="delete"
                                color="error"
                                onClick={handleClickOpenView}
                              >
                                <Icons.View />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell> */}
                      </TableRow>
                    );
                    // ))}
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {getPointss.length == 0 && (
              <NotFound NotfoundText="No Result Found" />
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={getPointss.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() => `Total: ${getPointss?.length}  Page ${page + 1} of ${pageCount}`}

          />
        </Box>
      </Paper>

      <Dialog
        open={openRequestBrowniePoints}
        onClose={setOpenRequestBrowniePoints}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseRequestBrowniePoints}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Request Rewards Points
        </DialogTitle>
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              departmentId: departId,
              userId: userId,
              referedUserName: referedUserNameForm,
              reason: reasonForm,
            //  currentmanagerNameForm: currentmanagerNameForm,
              comment:currentComment,
            }}
            validationSchema={fieldValidationReason}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                await AddRequestRewardsPoints(values);
              } catch (err) {
                console.error(err);
              }
              // try {
              //   setErrorMessage(true);

              //   await
              //   addCalenderr(values);
              // } catch (ex: any) {
              //   if (ex.status == 400) {
              //     console.error("ex:", ex);
              //     ex.data.Error.map((error: any) => {
              //       toast.error(error);
              //     });
              //   }
              // }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    {/* <Grid item xs sm={6}>
                      <TextField
                        id="outlined-basic"
                        disabled={disable}
                        label="Department"
                        value={
                          departments.find(
                            (a) =>
                              a.id ==
                              (_authUser.employeeInfo.departmentId != ""
                                ? _authUser.employeeInfo.departmentId
                                : "All Departments")
                          )?.name
                        }
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          values.departmentId = e.currentTarget.value;
                          GetResourcesbyDepartmentId();
                        }}
                      />
                    </Grid> */}
                    {/* <Grid item xs sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic"
                          label="Manager"
                          disabled={disable}
                          value={currentmanagerNameForm}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            e.currentTarget.value =
                              values.currentmanagerNameForm;
                            setcurrentMangerNameForm(e.currentTarget.value);
                          }}
                        />
                    
                      </FormControl>
                    </Grid> */}

                    <Grid item xs sm={6} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        {/* <TextField
                          error={Boolean(
                            touched.reason && errors.referedUserName
                          )}
                          helperText={touched.reason && errors.referedUserName}
                          required
                          onBlur={handleBlur}
                          id="outlined-basic"
                          label="Refer Username"
                          variant="outlined"
                          value={values.referedUserName}
                          select
                          fullWidth
                        >
                          {usersValue.map((option: any, idx: any) => (
                            <MenuItem
                              key={idx}
                              value={option.userName}
                              onClick={(event) => {
                                // values.paymentDetails.currency = dummy[idx].label;
                                setUserId(option.userId);
                                values.referedUserName = option.userName;
                                setReferedUserNameForm(option.userName);
                                setReferUsernameForm(option.userName);
                                GetResourcesbyDepartmentId();
                                // setReasonForm(reason[idx].label);
                              }}
                            >
                              {option.userName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                         <Autocomplete
                          value={
                            usersValue?.filter((x:any) => x.id !==  _authUser.id && x.id !== _authUser.employeeInfo.hodId).find(
                              (option:any) => option.id == values.userId
                            ) ?? null
                          }
                          fullWidth
                          id="selectedUserId"
                          options={usersValue.filter((x:any) => x.id !==  _authUser.id && x.id !== _authUser.employeeInfo.hodId)}
                          getOptionLabel={(option) => option.employeeId+" - "+option.name}
                          onChange={(event, value) => {
                            setUserId(value.id);
                                values.referedUserName = value.name ?? "";
                                setReferedUserNameForm(value.name ?? "");
                                setReferUsernameForm(value.name ?? "");
                                
                               // GetResourcesbyDepartmentId();
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.reason && errors.referedUserName
                              )}
                              helperText={touched.reason && errors.referedUserName}
                              required
                              onBlur={handleBlur}
                              id="outlined-basic"
                              label="Refer Username"
                              variant="outlined"
                              value={values.referedUserName}
                              fullWidth
                            />
                          )}
                        />
                        {/* <InputLabel id="demo-simple-select-label">
                          Select Reason
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={resource}
                          label="Select Reason"
                          onChange={handleChangeResource}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs sm={6} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        {/* <TextField
                          error={Boolean(touched.reason && errors.reason)}
                          helperText={touched.reason && errors.reason}
                          id="outlined-basic"
                          label="Reason"
                          required
                          onBlur={handleBlur}
                          variant="outlined"
                          value={values.reason}
                          select
                          fullWidth
                        >
                          {browniePointsReasonList.map((option, idx) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              onClick={(event) => {
                                // values.paymentDetails.currency = dummy[idx].label;
                                values.reason = option.id;
                                setSelectedReason(option.id);
                                setReasonForm(option.id);
                              }}
                            >
                              {option.reasonName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                         <Autocomplete
                          value={
                            browniePointsReasonList?.find(
                              (option) => option.id == values.reason
                            ) ?? null
                          }
                          fullWidth
                          id="selectedUserId"
                          options={browniePointsReasonList}
                          getOptionLabel={(option) => `${option?.reasonName} (${option?.pointsForReason})`}
                          onChange={(event, value) => {
                            values.reason = value?.id ?? "";
                            setSelectedReason(value?.id ?? "");
                            setReasonForm(value?.id ?? "");
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                              {`${option?.reasonName} (${option?.pointsForReason})`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.reason && errors.reason)}
                          helperText={touched.reason && errors.reason}
                          id="outlined-basic"
                          label="Reason"
                          required
                          onBlur={handleBlur}
                          variant="outlined"
                          value={values.reason}
                          fullWidth
                            />
                          )}
                        />

                          
                      </FormControl>
                    </Grid>
                    <Grid item xs >
                     
                      <TextField 
                          sx={{ mt: 2}}
                              //{...params}
                              // error={Boolean(
                              //   touched.reason && errors.referedUserName
                              // )}
                             // helperText={touched.reason && errors.referedUserName}
                             // required
                              onBlur={handleBlur}
                              id="outlined-basic"
                              label="Comments"
                              variant="outlined"
                              onChange={(e) => {
                                let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,\s]$/g, "").trimStart().slice(0, 149);
                                value = value.replace(/\s{3,}$/g, "");
                                values.comment = value;
                                setcurrentComment(value);
                                //setTitle(value);
                                // setErrorMessage(false);
                              }}
                              fullWidth
                            />
                      
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button
                    onClick={handleCloseRequestBrowniePoints}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" autoFocus variant="contained">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      </Dialog>
      {/* //----------------------------------------------------------- */}

      <Dialog
        open={openView}
        onClose={setOpenView}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton sx={styleCross} onClick={handleCloseView}>
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Recommend Points
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs sm={2}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 92, height: 92 }}
              />
            </Grid>
            <Grid item xs>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Emp Id 1025
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Nancy Martino
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Total Earned Points
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    100 points
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Value
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Rs. 1000
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Redeemed
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    50 Points
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
