import React, {
  useState,
  useImperativeHandle,
} from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import theme from "../../theme/theme";
import { styled } from "@mui/material/styles";
import Paper, { PaperProps as MuiPaperProps } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import * as Icons from "../../assests/icons/icons";
import Button from "@mui/material/Button";
import { boardDashboardServive } from "../../service/BoardDashboardServive";
import NotFound from "../../components/styledComponent/NotFound";
import ImageListItem from "@mui/material/ImageListItem";
import { toast } from "material-react-toastify";
// interface Props {
//     color: 'ProductBacklog' | 'SprintBacklog' | 'inProgress' | 'review' | 'hold'
// }
import { issueManagementService } from "../../service/issues-management-service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { commonFunctions } from "../../service/common-services/common-functions";
import { apiUrl } from "../../service/common-services/apiURL";
import { masterDataService } from "../../service/master-service/master-data-service";
import getSprintDetailBySprintId from "../../service/common-services/sprintDetailBysprintId";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "red",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? theme.palette.primary.light : "#fff",
  padding: 0,
  paddingTop: theme.spacing(1),
});

const styles: any = {
  lightBackground: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 500,
    marginTop: "0.6rem",
    color: theme.palette.primary.dark,
  },
  innerSpace: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  ProductBacklog: {
    backgroundColor: theme.palette.primary.light,
    "&.MuiPaper-root": {
      color: theme.palette.primary.main,
    },
  },
  SprintBacklog: {
    backgroundColor: "#D9D9D9",
    "&.MuiPaper-root": {
      color: theme.palette.grey[600],
    },
  },
  InProgress: {
    backgroundColor: theme.palette.success.light,
    "&.MuiPaper-root": {
      color: theme.palette.success.main,
    },
  },
  Review: {
    backgroundColor: theme.palette.warning.light,
    "&.MuiPaper-root": {
      color: theme.palette.warning.main,
    },
  },
  Hold: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  QA: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  Resolved: {
    backgroundColor: theme.palette.success.light,
    "&.MuiPaper-root": {
      color: theme.palette.success.main,
    },
  },
  Blocked: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  Closed: {
    backgroundColor: theme.palette.success.light,
    "&.MuiPaper-root": {
      color: theme.palette.success.main,
    },
  },

  userAvatar: {
    justifyContent: "flex-end",
    ml: 'auto',
    "& .MuiAvatar-root": {
      textTransform: "Uppercase",
      // fontFamily: "sans-serif",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text,
      borderColor: "#fff",
      fontWeight: 500,
      width: "32px",
      height: "32px",
      cursor: "pointer",
      fontSize: 13,
      lineHeight: " 16px",
      ml: 'auto'
    },
  },
};

const Loader = {
  position: "fixed",
  width: " 100%",
  height: " 100%",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.dark,
  zIndex: 9999,
  marginRight: "-50px",
};

const TicketLabel = styled(Chip)({
  border: "none",
  background: "none",
  padding: 0,
  marginTop: theme.spacing(1),
  color: theme.palette.primary.main,
  "& .MuiChip-label": {
    fontWeight: 500,
    fontSize: "15px",
  },
});
const KanbanWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  // [theme.breakpoints.not('xl')]: {

  //     overflowX: 'scroll'
  // },
  display: "flex",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
  // marginTop: theme.spacing(1),
  // paddingTop: theme.spacing(1),
  // paddingBottom: theme.spacing(1),
  // scrollbarWidth: 'thin',
  // // height: ' 100%',
  // '&::-webkit-scrollbar': {
  //     width: '0.4em'
  // },
  // '&::-webkit-scrollbar-track': {
  //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
  // },
  // '&::-webkit-scrollbar-thumb': {
  //     backgroundColor: 'rgba(0,0,0,.1)',
  //     outline: '1px solid slategrey'
  // }
}));

const KanbanCol = {
  flex: 1,
  marginRight: theme.spacing(2),

  "&:last-of-type": {
    marginRight: theme.spacing(0),
  },
  width: "250px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
};

const removeFromList = (list: any, index: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list: any, index: any, element: any) => {
  const result = Array.from(list || []);
  result.splice(index, 0, element);
  return result;
};

interface ChildProps {
  getAllUserListByProjectId: Function;
  TicketInfo: Function;
  // issueList: any;
  projectId: any;
  projectType: any;
  sprintId: any;
  searchTicket: any;
  userId: any;
  kanboardstatus: boolean;
  Isunassignee: boolean;
  // boardWorkList:any;
  elements: any;
  elementsLength: any;
  toggleLoader: boolean;
  setElements: any;
  isMilestoneOver:boolean
}
const KanbanBoard = React.forwardRef((props: ChildProps, ref: any) => {
  // export default function KanbanBoard(props: ChildProps) {

  const {
    projectId,
    sprintId,
    userId,
    searchTicket,
    projectType,
    getAllUserListByProjectId,
    kanboardstatus,
    Isunassignee,
    // boardWorkList,
    elements,
    elementsLength,
    toggleLoader,
    setElements,
    isMilestoneOver
  } = props;
  //  Object.entries(elements).forEach(
  //   ([key, value]) => console.log(key, value)
  // );
  const [buttontext, setButtontext] = useState<string>("Refresh");
  const [novisible, setNoVisible] = useState(false);

  // const [state, setState] = useState<any>([]);



  // useEffect(() => {
  //   getProjectBoardWork();
  // }, [getProjectBoardWork]);

  // useEffect(() => {
  //   getAllTicketForBoardFilters();
  // }, [getAllTicketForBoardFilters]);

 const refresh = () => {
        boardDashboardServive
      .getProjectBoardWorkById(projectId)
      .then((response: any) => {
        if (response?.data?.isError) {
        } else {
          //setBoardWorkList(response?.data?.workflow || []);

          boardDashboardServive
            .getAllTicketForBoardFiltersAsync({
              projectId: projectId,
              sprintId: sprintId,
              search: searchTicket,
              userIds:userId,
              issueType: projectType,
              isUnassignee:Isunassignee
            })
            .then((response: any) => {
              if (response?.data?.isError) {
                setButtontext("Refresh");
              } else {
                setButtontext("Refresh");
                 setElements(response?.data?.ticketOutputModels);
              }
            });
          // setUsers(response?.data ? response?.data : []);
        }
      });
  };
  useImperativeHandle(ref, () => ({
    doRefresh() {
      refresh();
    },
  }));
  const onDragEnd = async (result: any) => {
    if(isMilestoneOver){
      toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
      }
      else if(await getSprintDetailBySprintId(sprintId) == 3){
        toast.warn("Oops! Sprint is already over you are not able to move tickets.")
      }
      else{
    if (!result.destination) {
      return;
    }
    masterDataService
      .getAllActiveSummary(result?.draggableId)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          let IssueDetail = response.data;
          boardDashboardServive
            .getAllTicketUpdateStatus({
              ticketId: result?.draggableId,
              status: result.destination.droppableId,
            })
            .then((response: any) => {
              console.log("response", response);
              if (response?.data?.isError) {
              } else {

                // const listCopy: any = { ...elements };
                // const sourceList = listCopy[result.source.droppableId];
                // const [removedElement, newSourceList] = removeFromList(
                //   sourceList,
                //   result.source.index
                // );
                // listCopy[result.source.droppableId] = newSourceList;
                // const destinationList = listCopy[result.destination.droppableId];
                // listCopy[result.destination.droppableId] = addToList(
                //   destinationList,
                //   result.destination.index,
                //   removedElement
                // );
                //  setElements(listCopy);
                // getAllTicketForBoardFilters();
                let arr = [...elements];
                var objBoardStatus = arr.find((a: any) => a.status === IssueDetail.status);
                var ModifiedTicket = objBoardStatus.tickets?.find((t: any) => t.id == IssueDetail.id) ?? IssueDetail;
                if (sprintId === IssueDetail.sprintId) {
                  var AddInNewStatus = arr.find((a: any) => a.status === result.destination.droppableId);
                  AddInNewStatus.tickets.push(ModifiedTicket)
                }
                const index1 = objBoardStatus.tickets?.indexOf(ModifiedTicket);
                objBoardStatus.tickets?.splice(index1, 1);
                setElements(arr);

              }
            }).catch((error) => {
              error.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
              });
            });
        }
      });}
  };

  const CombineEstimatedHours = (devHours : any , qaHours : any) => {
    const combineHours = parseInt(devHours) + parseInt(qaHours);
    return combineHours.toString();
  }

  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const DeleteTicket = async () => {
    setOpenDelete(false);
    try {
      await issueManagementService
        .deleteTicket(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Ticket Deleted successfully.");
            //props.getAllUserListByProjectId();
            // getAllTicketForBoardFilters();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          overflow: "scroll",
          position: "relative ",
          // height: "calc(100vh - 200px)",
          minWidth: "0",
          backgroundColor: "#fff",
          p: 2,
          pt: 0,
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            height: "calc(100vh - 262px)",
          },
        }}
      >
        {elements && elements.length > 0 && elements.filter((a: any) => a.tickets != null && a.tickets.length > 0).length > 0 ? (
          <KanbanWrapper>
            {toggleLoader && (
              <Box sx={Loader}>
                <FontAwesomeIcon icon={faSpinner} spinPulse size={"3x"} />
                </Box>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              {elements?.filter((a: any) => a.status !== "Product Backlog").map((element: any, index: any) => (
                <Box key={index} sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      position: "sticky",
                      top: "0px",
                      zIndex: "999",
                      mr: 0.5,
                    }}
                  >
                    <Box sx={[KanbanCol]}>
                      <Paper
                        elevation={0}
                        sx={[
                          styles[`${element.status.replace(" ", "").replace("-", "")}`],
                          styles.innerSpace,
                        ]}
                      >
                        {element.status + " "}
                        {element.tickets && element.tickets.length > 0
                          ? " " + element.tickets.length + " Issues"
                          : " "}
                      </Paper>
                    </Box>
                  </Box>
                  <Droppable key={element.status} droppableId={`${element.status}`}>
                    {(provided, snapshot) => (
                      <Box
                        sx={KanbanCol}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {element?.tickets != null && element?.tickets?.map((item: any, index: number) => (
                          // <ListItem key={item.id} item={item} index={index} />

                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <Paper
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (isMilestoneOver) {
                                      toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                    }
                                    else {
                                      props.TicketInfo(item)
                                    }
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{ p: 1, mb: 0.5 }}
                                  variant="outlined"
                                >

                                  {item?.attachment !== "" &&
                                    item.attachment ? (
                                    <ImageListItem
                                      sx={{ mb: 1 }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (isMilestoneOver) {
                                          toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                        }
                                        else {
                                          props.TicketInfo(item)
                                        }
                                      }}
                                    >
                                      <img
                                        src={
                                          apiUrl.getImageURL() +
                                          `/${item?.attachment}`
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (isMilestoneOver) {
                                            toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                          }
                                          else {
                                            props.TicketInfo(item)
                                          }
                                        }}
                                      />
                                    </ImageListItem>
                                  ) : (
                                    ""
                                  )}
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Tooltip title={item.issueType}>
                                      <Box
                                        sx={{
                                          color: theme.palette.primary.main,
                                          display: "flex",
                                          alignItems: "center",
                                          lineHeight: 1,
                                          wordBreak:"break-all",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (isMilestoneOver) {
                                            toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                          }
                                          else {
                                            props.TicketInfo(item)
                                          }
                                        }}
                                      >
                                        {item.issueType === "Bug" && (
                                          <Icons.Bug />
                                        )}
                                        {item.issueType === "Story" && (
                                          <Icons.Story />
                                        )}
                                        {item.issueType === "Epic" && (
                                          <Icons.Epic />
                                        )}
                                        {item.issueType === "Task" && (
                                          <Icons.Task />
                                        )}
                                        {item.issueType === "Child" && (
                                          <Icons.copy />
                                        )}
                                        &nbsp; {item?.ticketNumber}
                                      </Box>
                                    </Tooltip>

                                  </Stack>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      mt: 1,
                                      fontWeight: 700,
                                      whiteSpace: "normal",
                                      wordBreak:"break-all",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (isMilestoneOver) {
                                        toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                      }
                                      else {
                                        props.TicketInfo(item)
                                      }
                                    }}
                                    gutterBottom
                                  >
                                    {item.summary?.length > 100
                                      ? item?.summary.slice(0, 80 - 1) + "..."
                                      : item?.summary}
                                  </Typography>

                                  <Chip
                                    label={
                                      item?.labelName ? item?.labelName : "None"
                                    }
                                    sx={styles.SprintBacklog}
                                  />
                                  {item?.epicsummary && (
                                    <Chip
                                      label={
                                        item?.epicsummary ? item?.epicsummary : "None"
                                      }
                                      sx={styles.lightBackground}
                                    />)}
                                  {/* <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ mt: 2 }}
                                    onClick={() => props.TicketInfo(item)}
                                  >
                                    {item.priority === "Highest" && (
                                      <Tooltip
                                        title={
                                          item?.storyPoint &&
                                            item?.storyPoint != null &&
                                            item?.storyPoint.length > 0
                                            ? "Priority: Highest / Story Points: " +
                                            item?.storyPoint
                                            : "Priority: Highest"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Highest />}
                                          label={item?.storyPoint}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "High" && (
                                      <Tooltip
                                        title={
                                          item?.storyPoint &&
                                            item?.storyPoint != null &&
                                            item?.storyPoint.length > 0
                                            ? "Priority: High / Story Points: " +
                                            item?.storyPoint
                                            : "Priority: High"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.High />}
                                          label={item?.storyPoint}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "Medium" && (
                                      <Tooltip
                                        title={
                                          item?.storyPoint &&
                                            item?.storyPoint != null &&
                                            item?.storyPoint.length > 0
                                            ? "Priority: Medium / Story Points: " +
                                            item?.storyPoint
                                            : "Priority: Medium"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Medium />}
                                          label={item?.storyPoint}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "Low" && (
                                      <Tooltip
                                        title={
                                          item?.storyPoint &&
                                            item?.storyPoint != null &&
                                            item?.storyPoint.length > 0
                                            ? "Priority: Low / Story Points: " +
                                            item?.storyPoint
                                            : "Priority: Low"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Low />}
                                          label={item?.storyPoint}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "Lowest" && (
                                      <Tooltip
                                        title={
                                          item?.storyPoint &&
                                            item?.storyPoint != null &&
                                            item?.storyPoint.length > 0
                                            ? "Priority: Lowest / Story Points: " +
                                            item?.storyPoint
                                            : "Priority: Lowest"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Lowest />}
                                          label={item?.storyPoint}
                                        />
                                      </Tooltip>
                                    )}

                                    <AvatarGroup sx={[styles.userAvatar]}>
                                      <Tooltip
                                        title={
                                          item?.assigneeUserName ?? "Un-Assigned"
                                        }
                                      >
                                        <Avatar
                                          {...commonFunctions.stringAvatar(
                                            item?.assigneeUserName
                                          )}
                                          // alt={item?.assignUsername}
                                          // src={`${process.env.REACT_APP_SERVER_URL}${item?.assignResourceImage}`}
                                          onClick={() => props.TicketInfo(item)}
                                        />
                                      </Tooltip>
                                    </AvatarGroup>
                                  </Stack> */}
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ mt: 2 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (isMilestoneOver) {
                                        toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                      }
                                      else {
                                        props.TicketInfo(item)
                                      }
                                    }}
                                  >
                                    {item.priority === "Highest" && (
                                      <Tooltip
                                        title={
                                          item?.estimatedHours &&
                                            item?.estimatedHours != null &&
                                            item?.estimatedHours.length > 0
                                            ? "Priority: Highest / Estimated Hours: " +
                                            item?.estimatedHours
                                            : "Priority: Highest"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Highest />}
                                          label={item?.estimatedHours}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "High" && (
                                      <Tooltip
                                        title={
                                          item?.estimatedHours &&
                                            item?.estimatedHours != null &&
                                            item?.estimatedHours.length > 0
                                            ? "Priority: High / Estimated Hours: " +
                                            item?.estimatedHours
                                            : "Priority: High"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.High />}
                                          label={item?.estimatedHours}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "Medium" && (
                                      <Tooltip
                                        title={
                                          item?.estimatedHours &&
                                            item?.estimatedHours != null &&
                                            item?.estimatedHours.length > 0
                                            ? "Priority: Medium / Estimated Hours: " +
                                            CombineEstimatedHours(item?.estimatedHours, item?.estimatedHoursQA) 
                                            : "Priority: Medium"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Medium />}
                                          label={item?.estimatedHours && item?.estimatedHours != null && item?.estimatedHoursQA 
                                            && item?.estimatedHoursQA != null ? CombineEstimatedHours(item?.estimatedHours, item?.estimatedHoursQA) : item?.estimatedHours
                                          }
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "Low" && (
                                      <Tooltip
                                        title={
                                          item?.estimatedHours &&
                                            item?.estimatedHours != null &&
                                            item?.estimatedHours.length > 0
                                            ? "Priority: Low / Estimated Hours: " +
                                            item?.estimatedHours
                                            : "Priority: Low"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Low />}
                                          label={item?.estimatedHours}
                                        />
                                      </Tooltip>
                                    )}

                                    {item.priority === "Lowest" && (
                                      <Tooltip
                                        title={
                                          item?.estimatedHours &&
                                            item?.estimatedHours != null &&
                                            item?.estimatedHours.length > 0
                                            ? "Priority: Lowest / Estimated Hours: " +
                                            item?.estimatedHours
                                            : "Priority: Lowest"
                                        }
                                      >
                                        <TicketLabel
                                          icon={<Icons.Lowest />}
                                          label={item?.estimatedHours}
                                        />
                                      </Tooltip>
                                    )}

                                    <AvatarGroup sx={[styles.userAvatar]}>
                                      <Tooltip
                                        title={
                                          item?.assigneeUserName ?? "Un-Assigned"
                                        }
                                      >
                                        <Avatar
                                          {...commonFunctions.stringAvatar(
                                            item?.assigneeUserName
                                          )}
                                          // alt={item?.assignUsername}
                                          // src={`${process.env.REACT_APP_SERVER_URL}${item?.assignResourceImage}`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (isMilestoneOver) {
                                              toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                            }
                                            else {
                                              props.TicketInfo(item)
                                            }
                                          }}
                                        />
                                      </Tooltip>
                                    </AvatarGroup>
                                  </Stack>
                                </Paper>
                              );
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>

              ))}
            </DragDropContext>
          </KanbanWrapper>
        ) : (
          <NotFound NotfoundText="No Ticket Found" />
        )}
      </Box>
      <Modal
        open={openDelete}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Ticket
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Do you really want to Delete this, Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteTicket}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
});
export default KanbanBoard;
