import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import theme from "../../theme/theme";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { Department } from "../../Interfaces/IDepartment";
import { IResource } from "../../Interfaces/IResource";
import { useSelector } from "react-redux";
import { clsDepartmentList } from "../../service/common-services/department";
import { toast } from "material-react-toastify";
import { Autocomplete,  CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import HrReporttop from "./HrReportsstyle";
import InfoPopover from "../../components/CommonInfoComponent";
import NotFound from "../../components/styledComponent/NotFound";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "react-rainbow-components";
import { clsResourcesList } from "../../service/common-services/resources";
import { Iproject } from "../../Interfaces/IProject";
import { qaReportService } from "../../service/QaReports-service";
import { userTimeSpentService } from "../../service/Reports/UserTimeSpent-service";




export default function UserConsumedHours() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const [reports, setreports] = React.useState<any[]>([])
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const calculateData = (timeTaken: any) => {
        const hours = Math.floor(timeTaken);
        const minutes = Math.round((timeTaken - hours) * 60);
        if (hours === 0) {
          return `${minutes} mins`;
        }
        const abc = hours > 1 ? " hrs" : " hr";
        if (minutes > 0) {
          return `${hours}${abc} ${minutes} mins`;
        } else {
          return `${hours}${abc}`;
        }
      };
    
   
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = React.useState("");
    const [fromDate, setfromDate] = React.useState<any>(null);
    const [toDate, settoDate] = React.useState<any>(null);
    const [selectedProjectId, setSelectedProjectId] = React.useState("");
    const getReport = async (
        depId: string = "", projtId: string, resId: string = "", type: string,sDate:string,eDate:string
    ) => {
        try {
            setToggleLoader(true);
         let param =
            {
                departmentId: depId,
                projectId: projtId,
                resourceId: resId,
                status: "",
                type: type,
                startDate: sDate,
                endDate: eDate
              
         }
            await userTimeSpentService.getReport(param)
                .then((response: any) => {
                    if (response.data.isError) {
                        setreports([]);
                        setToggleLoader(false);
                    }
                    else {
                        setreports(response.data);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    var projectData = { id: "0", projectName: "Select Projects" };
    const [projects, setProjects] = React.useState<any[]>([])
    const getActiveProjectsOnBaseOfUserIdAndDepId = async (id: any,userId:any) => {
        try{
        await qaReportService.getActiveProjectsOnBaseOfUserIdAndDepId({departId:id ?id:"", userId:userId?userId:""}).then(async (response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                setProjects(response?.data ? response?.data : []);
            }
        });
    }
    catch (ex: any) {
        ex.data.Error.map((err: any, idx: any) => {
            toast.warning(err);
        });
    }
};
    React.useEffect(() => {
        const departmentsData = async () => {
            switch (_authUser.typeOfUser) {
                case eTypeOfUser.Manager:
                    setdisabledDepartments(true);
                    setDepartmentId(_authUser.employeeInfo.departmentId);
                    setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                    getActiveProjectsOnBaseOfUserIdAndDepId(_authUser.employeeInfo.departmentId,"")
                    getReport(_authUser.employeeInfo.departmentId,"","","Manager","","")
                    break;
                case eTypeOfUser.User || eTypeOfUser.Client:
                    await setDepartmentId(_authUser.employeeInfo.departmentId);
                    getReport("","",_authUser?.id,"User","","")
                    break;
                case eTypeOfUser.Admin:
                    await setDepartmentId("")
                    setdisabledDepartments(false);
                     await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                    // getReport("","","","","","")
                    break;
                case 0:
                    await setDepartmentId(_authUser.employeeInfo.departmentId);
                    getReport("","",_authUser?.id,"User","","")
                    break;

                default:
                    return null;
            }
        };
        departmentsData();
    }, []);
    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders1} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    sx={HrReporttop.topbar}
                >
                    {/* <Box sx={{ position: "absolute", inset: "6px auto auto 0" }}>
                        <InfoPopover onInput={"Reports"} />
                    </Box> */}
                    {/* <Typography component="h5" sx={{ fontWeight: 500, ml: 6, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }}>
                        Working Days: {reports && reports.length > 0 && <>{reports[0].totalWorkingDay}</>}
                    </Typography>
                    <Typography component="h5" sx={{ fontWeight: 500, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }}>
                        Total Working Hours: {reports && reports.length > 0 && <>{reports[0].totalWorkingDay * 8}</>}
                    </Typography> */}
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 0, sm: 2 } }}>
                        <Stack sx={HrReporttop.rightside} alignItems="flex-end">
                            {_authUser.typeOfUser == eTypeOfUser.Admin&&(
                            <Box sx={{ marginRight: "20px", width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, }}>
                                <Autocomplete
                                    disabled={disabledDepartments}
                                    disableClearable={departmentId && departmentId != "" ? false : true}
                                    value={
                                        departments.find(
                                            (option: any) => option.id == departmentId
                                        ) ?? null
                                    }
                                    // fullWidth
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setDepartmentId(value?.id ?? "")
                                        setResourceId("");
                                        setfiltersSelected(true);
                                        if(value){
                                            await getActiveProjectsOnBaseOfUserIdAndDepId(value?.id ?? "","")
                                            setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                                        }
                                       
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Department"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={departmentId}
                                            fullWidth
                                            sx={{ minWidth: 200 }}
                                        />
                                    )}
                                    sx={{
                                        "&.MuiAutocomplete-root": {
                                            margin: 0,
                                        }
                                    }}
                                />
                            </Box>)}

                            {(_authUser.typeOfUser == eTypeOfUser.Admin || _authUser.typeOfUser == eTypeOfUser.Manager )&&(
                            <><Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}>
                                    <Autocomplete
                                        //  disabled={selectedRadioValue != "Project"}
                                        value={projects?.find(
                                            (option) => option.projectId == selectedProjectId
                                        ) ?? projectData}
                                        //fullWidth
                                        sx={{
                                            lineHeight: "0.80em",
                                        }}
                                        id="outlined-basic"
                                        options={projects}
                                        getOptionLabel={(option: any) => option.projectName}
                                        onChange={async (event, value) => {
                                            setSelectedProjectId(value?.projectId ?? "");
                                            setfiltersSelected(true);
                                        } }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                //fullWidth
                                                id="outlined-basic"
                                                name="element.name"
                                                variant="outlined"
                                                value={selectedProjectId}
                                                sx={{ minWidth: 200 }} />
                                        )} />
                                </Box><Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}>
                                        <Autocomplete
                                            disabled={!departmentId || departmentId === ""}
                                            disableClearable={resourceId && resourceId != "" ? false : true}
                                            value={resources.find(
                                                (option: any) => option.userId == resourceId ?? ""
                                            ) ?? null}
                                            id="outlined-basic"
                                            options={resources}
                                            getOptionLabel={(option) => option.userName}
                                            onChange={async (event, value) => {
                                                setfiltersSelected(true);
                                                setResourceId(value?.userId ?? "");
                                            } }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Resource"
                                                    id="outlined-basic-departmentsId"
                                                    name="element.name"
                                                    variant="outlined"
                                                    value={resourceId}
                                                    fullWidth
                                                    sx={{ minWidth: 220 }} />
                                            )} />
                                    </Box></>)}
                            <Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}><LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    placeholder="From"
                                    labelAlignment="left"
                                    borderRadius="semi-square"
                                    maxDate={dayjs().toDate()}
                                    value={fromDate}
                                    onChange={(newValue: any) => {
                                        let abc = dayjs(newValue).toDate();
                                        setfromDate(abc);
                                        setfiltersSelected(true);
                                        if (dayjs(toDate) < dayjs(newValue)) {
                                            settoDate(
                                                dayjs(newValue) < dayjs()
                                                    ? dayjs(newValue?.add(1, "day"))
                                                    : newValue
                                            );
                                        }
                                    }}

                                />
                            </LocalizationProvider>
                            </Box>
                            <Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}><LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disabled={!fromDate}
                                    placeholder="To"
                                    labelAlignment="left"
                                    borderRadius="semi-square"
                                    maxDate={dayjs().toDate()}
                                    //minDate={toDate}
                                    value={toDate}
                                    onChange={(newValue) => {
                                        let abc = dayjs(newValue).toDate();
                                        settoDate(abc);
                                        setfiltersSelected(true);
                                    }}

                                />
                            </LocalizationProvider></Box>

                            <Button
                                onClick={() => {
                                    if (!!!departmentId) {
                                        toast.warning("Please select Department First ")
                                    }
                                  else  if (toDate<fromDate) {
                                        toast.warning("End date should be greater than Start date")
                                    }
                                    else {
                                        const departmentsData = async () => {
                                            switch (_authUser.typeOfUser) {
                                                case eTypeOfUser.Manager:
                                                    getReport(_authUser.employeeInfo.departmentId,selectedProjectId,resourceId,"Manager",fromDate,toDate)
                                                    break;
                                                case eTypeOfUser.User || eTypeOfUser.Client:
                                                    await setDepartmentId(_authUser.employeeInfo.departmentId);
                                                    getReport("","",_authUser?.id,"User",fromDate,toDate)
                                                    break;
                                                case eTypeOfUser.Admin:
                                                    setdisabledDepartments(false);
                                                    await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                                                    getReport(departmentId,selectedProjectId,resourceId,"Admin",fromDate,toDate)
                                                    break;
                                                case 0:
                                                    await setDepartmentId(_authUser.employeeInfo.departmentId);
                                                    getReport("","",_authUser?.id,"User",fromDate,toDate)
                                                    break;
                                
                                                default:
                                                    return null;
                                            }
                                        };
                                        departmentsData();
                                    }
                                }}
                                color="primary"
                                variant="contained"
                                sx={{ ml: 0.5, mb: { xs: "20px", md: "0px" }, }}
                            >
                                Apply
                            </Button>
                            {filtersSelected && <Button
                                onClick={async () => {
                                    setfiltersSelected(false);
                                    const departmentsData = async () => {
                                        switch (_authUser.typeOfUser) {
                                            case eTypeOfUser.Manager:
                                                setdisabledDepartments(true);
                                                setDepartmentId(_authUser.employeeInfo.departmentId);
                                                setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                                                getActiveProjectsOnBaseOfUserIdAndDepId(_authUser.employeeInfo.departmentId,"")
                                                getReport(_authUser.employeeInfo.departmentId,"","","Manager","","")
                                                setSelectedProjectId("")
                                                setResourceId("")
                                                setfromDate("")
                                                settoDate("")
                                                break;
                                            case eTypeOfUser.User || eTypeOfUser.Client:
                                                await setDepartmentId(_authUser.employeeInfo.departmentId);
                                                getReport("","",_authUser?.id,"User","","")
                                                setSelectedProjectId("")
                                                setResourceId("")
                                                setfromDate("")
                                                settoDate("")
                                                break;
                                            case eTypeOfUser.Admin:
                                                await setDepartmentId("")
                                                setdisabledDepartments(false);
                                                 await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                                                // getReport("","","","","","")
                                                setSelectedProjectId("")
                                                setResourceId("")
                                                setfromDate("")
                                                settoDate("")
                                                setDepartmentId("")
                                                break;
                                            case 0:
                                                await setDepartmentId(_authUser.employeeInfo.departmentId);
                                                getReport("","",_authUser?.id,"User","","")
                                                setSelectedProjectId("")
                                                setResourceId("")
                                                setfromDate("")
                                                settoDate("")
                                                break;
                            
                                            default:
                                                return null;
                                        }
                                    };
                                    departmentsData();

                                }}
                                color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                        </Stack>
                        <Grid direction="column" item xs={12} md={6}>
                            <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={1}
                                alignItems={{ xs: "flex-start", sm: "center" }}
                                justifyContent="flex-start"
                                sx={{ mt: 1 }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: theme.palette.success.main }}
                                    />{" "}
                                    Online
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        mx: 2,
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: theme.palette.error.main }}
                                    />{" "}
                                    Offline
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* <FiberManualRecordIcon
                    sx={{ color: theme.palette.warning.main }}
                  />{" "}
                  Both */}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Box>

                </Stack>
                <Box sx={{ mt: 2 }}>
                    {reports && reports.length > 0 &&

                        <TableContainer component={Paper} elevation={0} sx={{
                            mt: 0.5,
                            maxHeight: "calc(100vh - 215px)",
                            [theme.breakpoints.down("sm")]: {
                                maxWidth: "400px",
                            },
                        }}>
                            <Table stickyHeader aria-label="simple table" size="small" sx={{
                                borderCollapse: 'separate',
                                padding: '5px'
                            }}>
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={{ position: 'sticky', left: '0', width: '300px', zIndex: "10" }} >Project Name</TableCell>
                                        <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', zIndex: "10" }}>Task</TableCell>
                                        <TableCell>Estimated Hours (Dev)</TableCell>
                                        <TableCell>Estimated Hours (QA)</TableCell>
                                        <TableCell>Resource</TableCell>
                                        <TableCell>Consumed Hours</TableCell>
                                        {/* <TableCell>Offline Hours</TableCell>
                                        <TableCell>Total Hours</TableCell> */}


                                    </TableRow>
                                </TableHead>
                                {reports?.map((row1: any, index) => (
                                    <>
                                        <TableBody style={{ textDecoration: 'none', boxShadow: ' RGBA(0, 0, 0, 0.16) 0px 1px 4px', borderRadius: "4px", marginBottom: '15px' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <Box sx={{ padding: "10px", whiteSpace: "nowrap", color: theme.palette.grey[700] }}> {`${moment(row1?.date).format("DD-MMM-YYYY")}`}{" "} </Box>
                                            {row1?.task?.map((task: any) => (
                                                task?.map((row: any) => (
                                                    <TableRow
                                                    >
                                                        <TableCell sx={{ position: 'sticky', left: '0', width: '300px', background: "#fff" }}>
                                                            <Tooltip title={row?.projectName}>
                                                                <span>
                                                                {row.projectName}
                                                                </span>
                                                            </Tooltip>
                                                            </TableCell>
                                                        <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', background: "#fff", whiteSpace: 'nowrap' }}>
                                                            <Tooltip title={row?.task}>
                                                                <span>
                                                            {row?.task
                                                            ? row?.task?.substring(0, 20) +
                                                            (row?.task?.length > 20 ? "..." : "")
                                                            : ""}</span>
                                                            </Tooltip>
                                                            </TableCell>
                                                               <TableCell>{row?.totalAllocatedHours}</TableCell>
                                                               <TableCell>{row?.qaEstimatedHours}</TableCell>
                                                        <TableCell>{row?.resource}</TableCell>
                                                        {row.taskType == "Online" && (
                                                            <TableCell sx={{ color: theme.palette.success.main, width: "200px" }}>
                                                                {calculateData(row?.actualHours)}
                                                            </TableCell>
                                                        )}
                                                        {row.taskType == "Offline" && (
                                                            <TableCell sx={{ color: theme.palette.error.main, width: "200px" }}>
                                                                {calculateData(row?.actualHours)}

                                                            </TableCell>
                                                        )}
                                                        {/* <TableCell>{row?.offlineHours}</TableCell>
                                                    <TableCell>{row?.taskType}</TableCell> */}
                                                        {/* <TableCell><Chip variant="approved" label={row.status} /></TableCell> */}
                                                    </TableRow>))

                                            ))
                                            }
                                        </TableBody>
                                        <TableRow>
                                            &nbsp;
                                        </TableRow>
                                    </>
                                ))}
                            </Table>
                        </TableContainer>
                    }
                    {reports.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                </Box>
            </Paper>
        </>
    );
}