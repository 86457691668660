import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
import {
  Typography,
  Paper,
  Stack,
  TextField,
  Box,
  MenuItem,
  Button,
  Grid,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Chip from "@mui/material/Chip";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Notes from "../.././components/styledComponent/Note";
import { masterDataService } from "../../service/master-service/master-data-service";
import { userManagementServive } from "../../service/user-management-service";
import { milestoneManagementService } from "../../service/milestone-management-service";
import { toast } from "material-react-toastify";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { useSelector } from "react-redux";
import { Iproject } from "../../Interfaces/IProject";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import InfoPopover from "../../components/CommonInfoComponent";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import HrReporttop from "./HrReportsstyle";

const styles = {
  filterLeft: {
    float: "left",
  },
  filterRight: {
    float: "right",
  },
  clear: {
    content: "",
    display: "block",
    clear: "both",
    float: "none",
  },
  // filter: {
  //   minWidth: "32%",
  //   marginRight: theme.spacing(0.5),
  //   [theme.breakpoints.down('xl')]: {
  //     mb: 0.5,
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     mb: 0.5, minWidth: "18%",
  //   },
  // },
  // dateFilter: {
  //   lineHeight: '0.80em',
  //   "& .MuiSvgIcon-root": {
  //     fill: theme.palette.primary.main
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     width: "100%",
  //     margin: "0"
  //   },

  // }
};
// const statusLog: any = (status: any) => {
//   switch (status) {
//     case 1:
//       return {
//         color: "review",
//         status: "Start"
//       };
//     case 2:
//       return {
//         color: "hold",
//         status: "Stop"
//       };

//     case 2:
//       return {
//         color: "review",
//         status: "Pause"
//       };
//     case 4:
//       return {
//         color: "progress",
//         status: "In-Progress"
//       };
//     case 5:
//       return {
//         color: "approved",
//         status: "Complete"
//       };
//     case 6:
//       return {
//         color: "review",
//         status: "Un-Approved"
//       };
//     case 7:
//       return {
//         color: "hold",
//         status: "Rejected"
//       };
//     default:
//       return {
//         color: "review",
//         status: "Un-Approved"
//       };
//   }
// };
enum Status {
  New = 1,
  InProgress = 2,
  Completed = 3,
  OnHold = 4,
}


const staticStatus = [
  {
    value: "1",
    label: "New",
  },
  {
    value: "2",
    label: "In Progress",
  },
  {
    value: "3",
    label: "Completed",
  },
  {
    value: "4",
    label: "OnHold",
  },
];

const MilestoneGrid = (props: { row: any; getSprintDetail: any }) => {
  const { row, getSprintDetail } = props;
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState(Status);

  const [innerRows, setinnerRows] = React.useState([]);
  const getSprintByMilestoneId = async (taskId: string) => {
    await milestoneManagementService
      .getSprintByMilestoneId({
        MilestoneId: taskId,
      })
      .then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
          setinnerRows([]);
        } else {
          setinnerRows(response.data);
        }
      });
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "& th, & td": { whiteSpace: "nowrap" },
        }}
      >
        <TableCell sx={{ position: "sticky", left: "0", zIndex: "99", background: "#fff" }}>
          <IconButton
            color="primary"
            aria-label="expand row"
            size="small"
            onClick={() => {
              getSprintByMilestoneId(row.id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ position: "sticky", left: "66px", zIndex: "99", background: "#fff" }} component="th" scope="row">
          {row.milestoneName}
        </TableCell>
        <TableCell >{row.projectName}</TableCell>
        <TableCell >{row.projectOwner}</TableCell>
        <TableCell  >
          {`${moment(row.startDate).format("DD-MMM-YYYY")}`}
        </TableCell>
        <TableCell>{`${moment(row.endDate).format("DD-MMM-YYYY")}`}</TableCell>
        <TableCell align="center">
          {row.plannedEfforts || 0}{" "}
          {row.plannedEfforts && parseInt(row.plannedEfforts) > 1
            ? " Hrs"
            : " Hr"}
        </TableCell>

        <TableCell align="center">
          {/* {row.actualEfforts || 0} */}
          {row.actualEfforts
            ? (parseFloat(row.actualEfforts) > 0
              ? parseFloat(row.actualEfforts).toFixed(3) + " Hrs"
              : "0 Hr")
            : "0 Hr"}

        </TableCell>
        <TableCell align="center">$ {row.costing || 0}</TableCell>
        <TableCell>
          {1 === row?.status && <Chip variant="review" label="New" />}
          {2 === row?.status && <Chip variant="progress" label="InProgress " />}
          {3 === row?.status && <Chip variant="approved" label="Completed" />}
          {4 === row?.status && <Chip variant="hold" label="OnHold" />}
        </TableCell>
        <TableCell>
          {/* {row.paymentStatus} */}
          {2 === row?.paymentStatus && <Chip variant="approved" label="Received" />}
          {1 === row?.paymentStatus && <Chip variant="hold" label="Pending" />}
          {null === row?.paymentStatus && <Chip variant="hold" label="Pending" />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          sx={{ pl: 0, backgroundColor: "#EEEEEE" }}
          colSpan={11}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ "&:last-child td,": { border: 0 } }}>
                  <TableCell sx={{ position: "sticky", left: "0", zIndex: "1", background: "#eeeeee" }} />
                  <TableCell sx={{ position: "sticky", left: "66px", zIndex: "1", background: "#eeeeee" }}  >Sprint</TableCell>
                  <TableCell >Start Date</TableCell>
                  <TableCell >End Date</TableCell>
                  <TableCell >Total Estimated Hour</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {innerRows.length > 0 ? (
                  innerRows.map((sprint: any) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ position: "sticky", left: "0", zIndex: "1", background: "#eeeeee", width: "66px" }}>
                       
                      </TableCell>

                      <TableCell sx={{ position: "sticky", left: "66px", zIndex: "1", background: "#eeeeee" }} >{sprint?.sprintName}</TableCell>
                      <TableCell >
                        {`${moment(sprint.startDate).format(
                          "DD-MMM-YYYY"
                        )}`}
                      </TableCell>
                      <TableCell>
                        {`${moment(sprint.endDate).format(
                          "DD-MMM-YYYY"
                        )}`}
                      </TableCell>
                      <TableCell>{sprint?.estimatedHours || 0} Hrs</TableCell>
                      <TableCell>
                        {sprint?.status === 1 && (
                          <Chip variant="review" label="To do" />
                        )}
                        {sprint?.status === 2 && (
                          <Chip variant="progress" label="InProgress " />
                        )}
                        {sprint?.status === 3 && (
                          <Chip variant="approved" label="Completed" />
                        )}
                        {/* {sprint?.status === 4 && (
                          <Chip variant="hold" label="OnHold" />
                        )} */}
                      </TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
        {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            <Table size="small" aria-label="purchases">

                                <TableBody>

                                    <TableRow >
                                        <TableCell>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpen(!open)}
                                            >
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell> */}
      </TableRow>
    </React.Fragment>
  );
};

// getDropdownData

export default function MilestoneReport() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ReportPermissionApp.ReportPermission
  );
  const [selectedProjectId, setProjectId] = useState("");
  const [projects, setProjects] = React.useState<Iproject[]>([]);
  const [selectedDepartmentId, setDepartmentId] = useState("");
  const [StaticStatusId, setStaticStatusId] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);




  const [departmentList, setDepartmentList] = React.useState<Department[]>([]);
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(0);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [fromDate, setfromDate] = React.useState<any>(null);
  const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);
  console.log(fromDate, "fromDate")
  const [toDate, settoDate] = React.useState<any>(null);
  const [milestoneReportList, setMilestoneReport] = React.useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  // accordian
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [isDataSearched, setisDataSearched] = React.useState(false);

  var projectData = { id: "0", projectName: "Select Projects" };
  const getActiveProjects = () => {
    masterDataService.getActiveProjects().then(async (response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let data = [];
        data.push(projectData);
        response.data.map((dep: any) => {
          data.push(dep);
        });
        setProjects(data);
      }
    });
  };

  const getActiveDepartment = () => {
    userManagementServive
      .getDropdownData({ module: 1, status: "active" })
      .then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          setDepartmentList(response.data);
        }
      });
  };

  const getMilestoneReport = (
    skip: number,
    selectedProjectId: string,
    selectedDepartmentId: string,
    startDate: any,
    take: number
  ) => {
    setLoading(true);

    milestoneManagementService
      .getMilestoneReport({
        projectId: selectedProjectId,
        departmentId: selectedDepartmentId,
        startDate: fromDate,
        endDate: toDate,
        skip: skip,
        take: take,
      })
      .then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          setMilestoneReport(
            response.data?.map((milestone: any) => ({
              ...milestone,
              sprintList: [],
            }))
          );
          setLoading(false);
        }
      });
  };


  const getMilestoneReportList = (skip: any, take: any) => {
    debugger;
    setLoading(true);
    setToggleLoader(true);
    setisDataSearched(true);
    try {
      milestoneManagementService
        .getMilestoneReport({
          projectId: selectedProjectId,
          departmentId: selectedDepartmentId,
          status: StaticStatusId ? StaticStatusId : "0",
          startDate: fromDate ? dayjs(fromDate).format("DD-MMM-YYYY") : "",
          endDate: toDate ? dayjs(toDate).format("DD-MMM-YYYY") : "",
          skip: skip,
          take: take,
        })
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something Went Wrong, Please try again.");
            setToggleLoader(false);

          } else {
            setMilestoneReport(
              response.data?.map((milestone: any) => ({
                ...milestone,
                sprintList: [],
              }))
            );
            setLoading(false);
            setToggleLoader(false);
          }
        });

    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setLoading(false);
        setToggleLoader(false);
      });
    }

  };
  useEffect(() => {
    //getMilestoneReportList(skip, take);
  }, [skip, take]);

  useEffect(() => {
    if (_permission.page_ManageMilestoneReport_Access !== true) {
      navigation("/dashboard");
    }
    getActiveProjects();
    //getActiveDepartment();

    const departmentsData = async () => {
      if (_authUser.typeOfUser != eTypeOfUser.Admin) {
        if (
          _permission.action_MilestoneReport_ReportListing_AllDepartments_Access ==
          true
        ) {
          setdisabledDepartments(false);
        } else {
          setdisabledDepartments(true);
        }
        setDepartmentId(_authUser.employeeInfo.departmentId);
        setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
      } else {
        setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
        //getReport(departmentHook, resourceHook, reportStatusesValueHook);
      }
    };
    departmentsData();
  }, []);

  const getSprintDetail = (open: boolean, row: any) => {
    // milestoneManagementService
    setLoading(true);
    if (!open) {
      milestoneManagementService
        .getSprintByMilestoneId({
          MilestoneId: row?.id,
        })
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something Went Wrong, Please try again.");
          } else {
            setMilestoneReport(
              milestoneReportList?.map((milestone: any) => {
                let returnValue = { ...milestone };
                if (milestone?.id === row?.id) {
                  returnValue["sprintList"] = [response?.data];
                }
                return returnValue;
              })
            );
            setLoading(false);
          }
        });
    }
  };

  return (
    <>
      {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}




      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Report Management</Typography>
        <Typography color="text.primary">Milestone Report</Typography>

      </Breadcrumbs>

      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          position: "sticky",
          top: "64px ",
          zIndex: 99,
          gap: 0.5,
        }}
      >
        <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
          <InfoPopover onInput={"Reports"} />
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'strech', sm: 'flex-end' },
              gap: 1,
              width: "55%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
          >

            <Autocomplete
              fullWidth
              value={
                projects?.find((option) => option.id == selectedProjectId) ??
                projectData
              }
              //fullWidth

              sx={{
                lineHeight: "0.80em",
              }}
              id="outlined-basic"
              options={projects}
              getOptionLabel={(option) => option.projectName}
              onChange={async (event, value) => {
                setProjectId(value?.id ?? "");
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //fullWidth
                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  value={selectedProjectId}
                />
              )}
            />

            <Autocomplete
              fullWidth
              value={
                departmentList?.find(
                  (option) => option.id == selectedDepartmentId
                ) ?? null
              }
              id="outlined-basic"
              options={departmentList}
              getOptionLabel={(option) => option.name}
              disabled={disabledDepartments}
              onChange={async (event, value) => {
                setDepartmentId(value?.id ?? "");
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  value={selectedDepartmentId}
                />
              )}
            />

            <TextField
              fullWidth
              label="Select Status"
              id="outlined-basic"
              select
              value={StaticStatusId}
              variant="outlined"
            >

              {staticStatus.map((option, idx) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={(event) => {
                    setStaticStatusId(option.value)
                    setfiltersSelected(true);
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>


          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'strech', sm: 'flex-end' },
              gap: 1,
              width: "44%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
              mt: { xs: 2, md: 0 }
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                placeholder="From"
                labelAlignment="left"

                borderRadius="semi-square"

                // openTo="year"
                // views={["year", "month", "day"]}
                // inputFormat="DD-MMM-YYYY"
                value={fromDate}
                onChange={(newValue) => {
                  setfromDateOrgl(newValue)
                  let abc = dayjs(newValue)
                  // let abc = getDate(newValue)
                  console.log(newValue, "jfdhdj")
                  setfromDate(abc);
                  setfiltersSelected(true);
                  if (dayjs(toDate) < dayjs(abc)) {
                    settoDate(
                      dayjs(newValue).add(1, "day").toDate()
                    );
                  }
                }}
              // renderInput={(params: any) => (
              //   <TextField

              //     {...params}
              //     autoComplete="off" // Disable suggestions
              //     readOnly={true} // Disable user input
              //     onKeyDown={(e: any) => {
              //       e.preventDefault();
              //     }}
              //     {...params}
              //     fullWidth
              //   />
              // )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={!fromDate}
                label="To"
                placeholder="To"
                labelAlignment="left"
                borderRadius="semi-square"

                // openTo="year"
                // views={["year", "month", "day"]}
                // inputFormat="DD-MMM-YYYY"
                minDate={fromDateOrgl}
                value={toDate}
                onChange={(newValue: any) => {
                  let nValue = dayjs(newValue);
                  settoDate(nValue);
                  setfiltersSelected(true);

                }}
              // renderInput={(params: any) => (
              //   <TextField
              //     {...params}
              //     autoComplete="off" // Disable suggestions
              //     readOnly={true} // Disable user input
              //     onKeyDown={(e: any) => {
              //       e.preventDefault();
              //     }}
              //     {...params}
              //     fullWidth
              //   />
              // )}
              />
            </LocalizationProvider>
            <Button
              color="primary"
              sx={{ ml: 0.5 }}
              onClick={() => {
                getMilestoneReportList(skip, take);
              }}
              variant="contained"
            >
              Apply
            </Button>
            {filtersSelected && (
              <Button
                onClick={() => {
                  setfiltersSelected(false);
                  setProjectId("");
                  setfromDate(null);
                  setfromDateOrgl(null)
                  settoDate(null);
                  setStaticStatusId("");

                  setMilestoneReport([]);
                  if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                    setDepartmentId(_authUser.employeeInfo.departmentId);
                  } else {
                    setDepartmentId("");
                  }
                  //getMilestoneReportList(skip, take);
                }}
                color="warning"
                variant="contained"
                sx={{ ml: 0.5 }}
              >
                Clear
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
      {milestoneReportList?.length > 0 && (
        <Paper
          elevation={0}
          sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99 }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                mt: 0.5,
                maxHeight: "calc(100vh - 280px)",
                [theme.breakpoints.down("sm")]: {
                  maxWidth: "400px",
                },
              }}
            >
              <Table aria-label="collapsible table" size="small" stickyHeader>
                <TableHead>
                  <TableRow sx={{ "& th, & td": { whiteSpace: "nowrap" } }}>
                    <TableCell sx={{ position: "sticky", left: "0", zIndex: "999" }} />
                    <TableCell sx={{ position: "sticky", left: "66px", zIndex: "999" }} >Milestone</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell >Project Owner</TableCell>
                    <TableCell >Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Planned Efforts(Hrs.)</TableCell>
                    <TableCell>Actual Efforts(Hrs.)</TableCell>
                    <TableCell>Cost($)</TableCell>
                    <TableCell>Status </TableCell>
                    <TableCell>Payment </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {milestoneReportList.map((row: any) => (
                    <MilestoneGrid
                      row={row}
                      getSprintDetail={getSprintDetail}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      )}
      {milestoneReportList?.length === 0 && isDataSearched === true && (
        <Paper elevation={0} sx={{ mb: 2 }}>
          <Grid container justifyContent="space-between" sx={{ mb: 0.5 }}>
            <Grid item xs={12} sm>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                alignItems={{ xs: "flex-start", sm: "center" }}
                justifyContent="space-between"
                sx={{ mt: 1, px: 2 }}
              >
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={1}
                  sx={{ mr: 2 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 500, color: theme.palette.primary.dark }}
                  >
                    No Record Found
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
