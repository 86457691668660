import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import { grey } from '@mui/material/colors'
import FormGroup from '@mui/material/FormGroup';
import { Link } from "react-router-dom";
// import CustomTreeItem, { CustomTreeItemProps, CustomTreeItemClasses } from '@mui/lab/CustomTreeItem';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import theme from '../../theme/theme';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Breadcrumbs from "@mui/material/Breadcrumbs";

// import clsx from 'clsx';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { borderColor } from '@mui/system';
import CustomTreeItem from '../../components/styledComponent/CustomTreeItem'
import StepsRoles from './StepsRoles'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',

}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: "0.6rem",
    '& .MuiListItemButton-root': {
        borderBottom: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? '#2c2c2c' : ' #D1D1D1',
    },
}));

const modules = [
    {
        text: 'Admin'
    },
    {
        text: 'Staff'
    },
    {
        text: 'Client'
    },
    {
        text: 'Employee'
    },
    {
        text: 'Worker'
    },

];

const steps = [
    'Create Role',
    'Grant Permissions',
  ];
export default function AddRole() {
    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                <Link color="inherit" to="/">
                    Dashboard
                </Link>

                <Typography color="text.primary">Role Management</Typography>
            </Breadcrumbs>
            <Paper elevation={0} sx={{ p: 3 }}>

                <StepsRoles steps={steps} />

                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2, border: '1px solid', borderColor: '#bdbdbd', p: 3, borderRadius: '10px' }}>

                    <Avatar sx={{ width: 52, height: 52 }}>H</Avatar>
                    <Stack spacing={0}>
                        <Typography variant="subtitle1" component="h5"  >
                            Role
                        </Typography>
                        <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 600 }}>
                            Design Manager
                        </Typography>
                    </Stack>
                </Stack>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>
                        <Item elevation={0}>
                            <Typography variant="h6" align="left" noWrap component="div" sx={{ fontWeight: 500 }}>
                                Modules
                            </Typography>
                            <List >
                                {[0, 1, 2, 3, 4, 5].map((value) => {
                                    const labelId = `checkbox-list-label-${value}`;

                                    return (
                                        <CustomListItem
                                            key={value}
                                            disablePadding
                                        // secondaryAction={
                                        //     <IconButton edge="end" aria-label="comments">
                                        //         <ArrowForwardIosIcon />
                                        //     </IconButton>
                                        // }
                                        >
                                            <ListItemButton role={undefined} onClick={handleToggle(value)} >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}

                                                    />
                                                </ListItemIcon>
                                                {/* {modules.map(item =>(
                                                    <ListItem
                                                    key={item.text}
                                                    > </ListItem>
                                                ))} */}

                                                <ListItemText id={labelId} primary={`Module ${value + 1}`} />
                                                {/* <ListItemText {modules}> </ListItemText> */}
                                            </ListItemButton>
                                        </CustomListItem>
                                    );
                                })}
                            </List>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Item elevation={0}>
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start" sx={{ mb: 3 }}>
                                <Typography variant="h6" align="left" component="h2" sx={{ fontWeight: 500 }}>
                                    Permissions
                                </Typography>
                                <Button variant="contained" color="primary">
                                    Save
                                </Button>
                            </Stack>


                            <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                defaultExpanded={['1']}
                            >

                                <CustomTreeItem nodeId="1" label={<Typography variant="h6" sx={{ fontWeight: 600 }}>Module 1</Typography>}>
                                    <CustomTreeItem nodeId="2" label={
                                        <Stack direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start">
                                            <Grid container spacing={0} justifyContent="flex-start">
                                                <Grid item xs={5}>
                                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Screen 1" />
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <FormGroup row sx={{ justifyContent: ' space-between', }}>
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Edit" />
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Write" />
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="View" />
                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Print" />

                                                    </FormGroup>
                                                </Grid>

                                            </Grid>
                                        </Stack>

                                    }>

                                        <TableContainer sx={{ pl: 5 }}>
                                            <Table size="small" aria-label="dense table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Tab Dummy Heading" />
                                                        </TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox defaultChecked />} label="Edit" /></TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox defaultChecked />} label="Write" /></TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox defaultChecked />} label="View" /></TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox defaultChecked />} label="Print" /></TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </CustomTreeItem>
                                    <CustomTreeItem nodeId="4"
                                        label={
                                            <Stack direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start">
                                                <Grid container spacing={0} justifyContent="flex-start">
                                                    <Grid item xs={5}>
                                                        <FormControlLabel control={<Checkbox />} label="Screen 2" />
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <FormGroup row sx={{ justifyContent: ' space-between', }}>
                                                            <FormControlLabel control={<Checkbox />} label="Edit" />
                                                            <FormControlLabel control={<Checkbox />} label="Write" />

                                                            <FormControlLabel control={<Checkbox />} label="View" />
                                                            <FormControlLabel control={<Checkbox />} label="Read" />
                                                        </FormGroup>
                                                    </Grid>

                                                </Grid>
                                            </Stack>

                                        }
                                    >
                                        <TableContainer sx={{ pl: 5 }} >
                                            <Table size="small" aria-label="dense table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel control={<Checkbox />} label=" Tab Dummy Heading" />
                                                        </TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox />} label="Edit" /></TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox />} label="Write" /></TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox />} label="View" /></TableCell>
                                                        <TableCell align="right"><FormControlLabel control={<Checkbox />} label="Read" /></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CustomTreeItem>
                                </CustomTreeItem>
                            </TreeView>


                        </Item>
                    </Grid>
                </Grid>
            </Paper>


        </>
    );
}
