import { http } from "./http-common";

class ProjectTrailorService {

    addProjectArtifactsRequest = async (data:any): Promise<any> => {
        return await http.post<any>("/v1/Project/ProjectArtifactsRequest", data);
    }; 
    approveorRejectProjectArtifactsRequest = async (data:any): Promise<any> => {
        return await http.post<any>("/v1/Project/ProjectArtifactsRequestApproveOrReject", data);
    }; 
    getRaisedProjectArtifactsRequestByProId = async (id:any): Promise<any> => {
        return await http.post<any>(`/v1/Project/GetProjectArtifactsRequestByProjectId?id=${id}`);
    }; 
    getAllPreDefinedArtifacts = async (): Promise<any> => {
        return await http.post<any>("/v1/Master/GetProjectArtifacts");
    }; 
    getAllArtifactsRequest = async (data:any): Promise<any> => {
        return await http.post<any>("/v1/Project/GetAllProjectArtifactsRequest",data);
    }; 
}

export const projectTrailorService = new ProjectTrailorService();
