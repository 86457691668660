import { http } from "../http-common";

class NotificationDetailsService{
    getNotificationDetails = async (): Promise<any> => {
        return await http.get<any>("/v1/NoticeBoard/GetAllNotificationByUser");
    };
    readNotificationbyId = async (data:any): Promise<any> => {
        return await http.get<any>(`/v1/NoticeBoard/ReadNotification?notificationId=${data.notificationId}&userId=${data.userId}`);
    };

    gettimelinepost = async (): Promise<any> => {

        return await http.get<any>("/v1/NoticeBoard/GetAllTimelinePost");
        
    }; 
    addcommentnotification = async (data: any): Promise<any> => {

        return await http.post<any>("/v1/NoticeBoard/AddCommentInNotification",data);
    }; 
   // deletecommentbyid = async (data: any): Promise<any> => {

      //  return await http.delete<any>("/v1/NoticeBoard/DeleteCommentById",data);
   // }; 
    deletecommentbyid = async (data: any): Promise<any> => {
        return await http.delete<any>(`/v1/NoticeBoard/DeleteCommentById?id=${data.id}&CommentNumber=${data.commentNumber}`);
    };
    editcomment = async (data: any): Promise<any> => {

        return await http.put<any>("/v1/NoticeBoard/EditComment",data);
    };
    addClientFeedBack = async (data: any): Promise<any> => {

        return await http.post<any>("/v1/Feedback/AddFeedBackByClient",data);
    };
    GetAllClientFeedback = async (data : any ): Promise<any> => {

        return await http.post<any>("/v1/Feedback/GetAllClientFeedback", data );
        
    }; 
    GetClientFeedbackById = async (id: any): Promise<any> => {

        return await http.get<any>(`/v1/Feedback/GetClientFeedbackById?id=${id}`);
        
    }; 
    GetClientFeedbackReport = async (data:any ): Promise<any> => {

        return await http.post<any>("/v1/Feedback/GetClientFeedbackReport" , data);
        
    }; 
    GetProjectsByClientId = async (data:any ): Promise<any> => {

        return await http.post<any>(`/v1/Project/GetAllProjectsByClientId?clientId=${data}`);
        
    }; 
    
    GetClientFeedBackAverageReport = async (data:any ): Promise<any> => {

        return await http.post<any>("/v1/Feedback/GetClientFeedbackAverageReport" , data);
        
    }; 

    
    readAllUserNotifications = async (): Promise<any> => {

        return await http.get<any>("/v1/NoticeBoard/ReadAllNotificationByUserId");
        
    }; 
    

} 

export const notificationDetailsServices = new NotificationDetailsService();