import React, { useRef } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import StyledTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hodServices } from "../../../service/master-service/hod-service";
import Chip, { ChipProps } from "@mui/material/Chip";
import * as Icons from "../../../assests/icons/icons";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Notes from "../../../components/styledComponent/Note";
import SearchBar from "../../../components/styledComponent/SearchBar";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
// import { Transition } from '@react-spring/web';
import {
  Autocomplete,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { departmentService } from "../../../service/master-service/department-service";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotFound from "../../../components/styledComponent/NotFound";
import AppConstants from "../../../config/AppConstants";
import { masterDataService } from "../../../service/master-service/master-data-service";
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import RoleView from "../../role/RoleView";
import { Error } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Masteralltop from "../mastersetupstyle";
import InfoPopover from "../../../components/CommonInfoComponent";
import Multiselect from "multiselect-react-dropdown";
import { coHodServices } from "../../../service/master-service/cohod-service";
import { IOServices } from "../../../service/master-service/io-master-service";
import { clsResourcesList } from "../../../service/common-services/resources";
import { clsDepartmentList } from "../../../service/common-services/department";
import { eTypeOfUser } from "../../../Enum/eTypeOfUser";
import { Department } from "../../../Interfaces/IDepartment";
import { IResource } from "../../../Interfaces/IResource";



var dropdown_syle = {
  searchBox: {
    lineHeight: "31px",
    borderColor: "#EEEEEE",
    minWidth: "320px",
    maxWidth: "100%",
    height: "44px",

  },
};

interface Skills {
  id: string;
  departmentName: string;
  userName: string;
  employeeId: string;
  department: string;
  io: string;
  manager: string;
  departmentId: string;
  // coHodId: string;
  coHodName: string;
  srNo: string;
  assignedUsers: AssigneeUsers[]; // Update this line
}

interface AssigneeUsers {
  userId: string;
  name: string;
  assignedDate: string;
}

interface User {
  userId: string;
  userName: string;
  id: string;
  name: string;
  DepartmentId: string;
}

const initialvalues = {
  departmentId: "",
  departmentName: "",

  assignedUsers: [
    {
      userId: "",
      name: "",
      assignedDate: ""
    }
  ],
  id: ""
};

const optionsStatus = ["Active", "De-Active", "Null", ,];

function AddIO() {
  const _permission = useSelector((state: any) => state.MasterPermissionApp.MasterPermission);
  const navigate = useNavigate();
  const [actionAllowed, setAllowdAction] = React.useState<any>([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userName, setUserName] = React.useState("");
  const selectedIOIdForDropdown = useRef('');
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [departmentId, setDepartmentId] = React.useState("");
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const [resources, setresources] = React.useState<IResource[]>([]);
  const [resourceId, setResourceId] = React.useState("");

  const [project, setProject] = useState<any>();
  const pageCount = Math.ceil(project?.length / rowsPerPage);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); setSelectedUser(""); selectedIOIdForDropdown.current = ""; };
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);



  const isValidUser = async () => {
    await masterDataService
      .GetActionbypageId(
        AppConstants.ScreenInfo.Master_Configuration.Manage_HOD.pageId
      )
      .then((response: any) => {
        if (response.data.isError) {
          navigate("/login");
        } else {
          var data = response?.data;
          var screenInfo = masterDataService.isAuthorizeUser(
            data,
            AppConstants.ScreenInfo.Master_Configuration.Manage_HOD
          );
          var allowedAction = {
            edit: masterDataService.isActionAlowed(
              screenInfo,
              AppConstants.ScreenInfo.Master_Configuration.Manage_HOD.actionIds
                .edit
            ),
          };
          setAllowdAction(allowedAction);
        }
      })
      .catch((error) => {
        navigate(AppConstants.Redirection.Unauthorize_Access);
      });
  };

  /////search programming
  const [searchValue, setSearchValue] = useState("");

  //////department data
  const [dropdownrecords, setDropDownRecords] = React.useState<any>();
  const [userIDdata, setUserIDData] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState("");
  console.log(selectedUser, 'Demosss');



  const [users, setUser] = useState<User[]>([]);



  const createsHodIO = async (values: any) => {
    debugger
    values.userId = userIDdata;

    await IOServices.createIO(values)
      .then((response: any) => {
        if (response.data.isError) {

        }
        else {
          toast.success(" Saved successfully.")
          handleClose();
          if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
            getAllUserWithIO(_authUser.employeeInfo.departmentId, "", "");
          } else {
            getAllUserWithIO(departmentId, "", "");
          }
          setSelectedUser("")
          selectedIOIdForDropdown.current = "";
          // getHodDetails();

        }
      });
  }

  const updateHodIO = async (values: any) => {
    debugger
    values.userId = userIDdata;
    values.ioUserId = selectedUser;
    await IOServices.updateIO(values)
      .then((response: any) => {
        if (response.data.isError) {

        }
        else {
          toast.success("Saved successfully.")
          handleClose();
          if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
            getAllUserWithIO(_authUser.employeeInfo.departmentId, "", "");
          } else {
            getAllUserWithIO(departmentId, "", "");
          }

          setSelectedUser("")
          selectedIOIdForDropdown.current = "";
          // getHodDetails();

        }
      });
  }

  const deleteIO = async (values: any) => {
    await IOServices.deleteIOById(values)
      .then((response: any) => {
        if (response.data.isError) {

        }
        else {
          toast.success("Updated successfully.")
          handleClose();
          if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
            getAllUserWithIO(_authUser.employeeInfo.departmentId, "", "");
          } else {
            getAllUserWithIO(departmentId, "", "");
          }
          setSelectedUser("")
          selectedIOIdForDropdown.current = "";
        }
      });
  }


  //////Dropdown over

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // , HodId:any, UserId: string, UserName: any
  const handleClickOpen = async (department: any) => {
    debugger
    setUserName(department.userName);
    setOpen(true);
    //getDropDownUsers(department.departmentId);
    let abc = await clsResourcesList.getResourcesDropdownList(department.departmentId);
    let xyz = abc.filter((item: any) => item?.userId !== "");
    console.log(xyz, "ABCCC")
    setDropDownRecords(xyz);
    setUserIDData(department.userId);
    selectedIOIdForDropdown.current = department.ioUserId;
    setSelectedUser(department?.ioUserId)
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (_permission.page_ManageIO_Access !== true) {
      navigate("/dashboard");
    }

    const departmentsData = async () => {
      if (_authUser.typeOfUser != eTypeOfUser.Admin) {
        debugger
        setDepartmentId(_authUser.employeeInfo.departmentId);
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setdisabledDepartments(true);
        setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
        getAllUserWithIO(_authUser.employeeInfo.departmentId, "", "");
      }
      else {
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setresources(await clsResourcesList.getResourcesDropdownList(''));
        setDepartmentId("");
        setdisabledDepartments(false);
      }
    };
    departmentsData();
    // getCoHodDetails();
    //getAllUserWithIO();
  }, []);


  const getAllUserWithIO = async (departId: string, userID: string, searchName: any) => {
    debugger

    var input = {
      departmentId: departId,
      userId: userID,
      search: searchName,
    };

    await IOServices.GetAllUserWithIO(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        const reversedData = response?.data.reverse();
        console.log(response.data);
        setProject(reversedData)
      }
    })
  };


  const triggerSearch = async (newValue: string) => {
    getSearchDetails(newValue);
    setPage(0);
  };
  /// search programming
  const getSearchDetails = async (newValue: string) => {

    var input = {
      departmentId: _authUser.employeeInfo.departmentId,
      userId: "",
      search: newValue,
    };
    await IOServices.GetAllUserWithIO(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        console.log(response.data);
        setProject(response?.data)
      }
    })
  };

  // const getDropDownUsers = async(id: string) => {
  //   debugger
  //   var payload = {
  //     department: id,
  //     workLocationStatus: 0,
  //     status: "",
  //     search: "",
  //     take: 500,
  //     skip: 0
  //   }
  //   await IOServices.dropdownUsers(payload).then((response: any) => {
  //     if(response.data.isError){
  //     } else {
  //       console.log(response.data);
  //       setDropDownRecords(response.data.result);
  //       console.log(response.data.result, "Data from Dropdown");
  //     }
  //   })

  // }

  //Sorting

  const [dense, setDense] = React.useState(false);
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (orderBy === "employeeId") {
      return Number(b[orderBy]) < Number(a[orderBy]) ? -1 : Number(b[orderBy]) > Number(a[orderBy]) ? 1 : 0;
    }
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Skills;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [


    {
      id: "employeeId",
      numeric: false,
      disablePadding: false,
      label: "Employee Id",
    },
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "department",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },
    {
      id: "io",
      numeric: false,
      disablePadding: false,
      label: "IO",
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: false,
      label: "Manager",
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Skills>("srNo");

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Skills
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Skills) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align-items="center" align="center">Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Skills
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 1 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
  const [noUser, setNoUser] = useState(false);

  ///////page change and dropdown programming over

  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const fieldValidationSchema = Yup.object().shape({
  //   ioUserId: Yup.string().required("Department IO is required.")    
  // });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Master Setup</Typography>
        <Typography color="text.primary"> Department Head</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>

        <Stack
          sx={Masteralltop.topbar}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Department IO
          </Typography>
          <Box>

            <InfoPopover onInput={"Manage HOD"} />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, }}>
              <Autocomplete
                disabled={disabledDepartments}
                disableClearable={departmentId && departmentId != "" ? false : true}
                value={
                  departments.find((option: any) => option.id == departmentId) ?? null
                }
                id="outlined-basic"
                options={departments}
                getOptionLabel={(option) => option.name}
                onChange={async (event, value) => {
                  debugger
                  setDepartmentId(value?.id ?? "")
                  setResourceId("");
                  if (value?.id) {
                    getAllUserWithIO(value?.id, "", "");
                    setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                  }
                  else {
                    setProject([])
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Department"
                    id="outlined-basic-departmentsId"
                    name="element.name"
                    variant="outlined"
                    value={departmentId}
                    fullWidth
                    sx={{ minWidth: 220 }}
                  />
                )}
                sx={{
                  "&.MuiAutocomplete-root": {
                    margin: 0,
                  }
                }}
              />
            </Box>
            <Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}>
              <Autocomplete
                disabled={!departmentId || departmentId === ""}
                disableClearable={resourceId && resourceId != "" ? false : true}
                value={
                  resources.find(
                    (option: any) => option.userId == resourceId ?? ""
                  ) ?? null
                }
                id="outlined-basic"
                options={resources}
                getOptionLabel={(option) => option.userName}
                onChange={async (event, value) => {
                  debugger
                  setResourceId(value?.userId ?? "");
                  getAllUserWithIO(departmentId, value?.userId ?? "", "");
                  setPage(0);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Resource"
                    id="outlined-basic-departmentsId"
                    name="element.name"
                    variant="outlined"
                    value={resourceId}
                    fullWidth
                    sx={{ minWidth: 220 }}
                  />
                )}
              />
            </Box>
          </Box>
        </Stack>

        <Divider />

        <TableContainer sx={{ mt: 1 }}>
          <Table aria-label="simple table" size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={project?.length}
            />
            <TableBody>

              {stableSort(project, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.coHodName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick1(event, row.coHodName)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}

                    >
                      <StyledTableCell align="left">
                        {row.employeeId}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.userName}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.department}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.io}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.manager}
                      </StyledTableCell>

                      <StyledTableCell align="left" width={100}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"

                        >


                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              size="medium"
                              onClick={() => handleClickOpen(row)}
                            >

                              <Icons.Edit />
                            </IconButton>
                          </Tooltip>


                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: dense ? 33 : 53,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {project?.length == 0 && <NotFound NotfoundText="No Result Found" />}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={project?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

          labelDisplayedRows={() => `Total: ${project?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>

      <Dialog sx={{ "& .MuiDialog-paper": { overflow: "visible", height: "unset" } }} open={open} fullWidth maxWidth="sm">
        <Formik
          initialValues={{
            ioUserId: selectedUser ?? "",
            userId: ""
          }}
          enableReinitialize={true}
          //validationSchema={fieldValidationSchema}
          onSubmit={async (values) => {
            debugger
            try {
              debugger
              // if (!selectedIOIdForDropdown.current) {
              //   await createsHodIO(values);
              // }
               if (selectedUser == "") {
                deleteIO(userIDdata);
              }
              else {
                await updateHodIO(values);
              }
            } catch (ex: any) {
              ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Assign IO Department Head</DialogTitle>
              <DialogContent sx={{ overflow: "visible !important" }}>
                <TextField
                  autoFocus
                  disabled
                  margin="dense"
                  id="department-name"
                  //onChange={(e) =>values.DepartmentId = e.target.value }
                  name="name"
                  // label="Department Name"
                  type="name"
                  fullWidth
                  value={userName}
                  variant="outlined"

                />

                <Autocomplete
                  value={
                    dropdownrecords?.find((option: any) => option?.userId == selectedUser) ?? null
                  }
                  fullWidth
                  options={dropdownrecords}
                  getOptionLabel={(option: any) => option.userName}
                  onChange={(event, value) => {
                    debugger
                    values.ioUserId = value?.userId || "";
                    setSelectedUser(value?.userId || "")
                  }
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="ioUserId"
                      label="Select IO"
                      variant="outlined"
                      fullWidth
                      value={selectedUser ?? ""}
                      // error={Boolean(
                      //   getIn(touched, "ioUserId") && getIn(errors, "ioUserId")
                      // )}
                      // helperText={
                      //   getIn(touched, "ioUserId") && getIn(errors, "ioUserId")
                      // }
                      // required
                      sx={{ mt: 3 }}
                    />
                  )}
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

    </>
  );
}

export default AddIO;

