import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
  Chip,
  Modal,
  TableCell,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchBar from "../../components/styledComponent/SearchBar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Notes from "../.././components/styledComponent/Note";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "material-react-toastify";
import { InputAdornment } from "@mui/material";
import { eventManagementService } from "../../service/event-management-service";
// import { bool } from "yup";
// import { boolean } from "yup/lib/locale";
import * as Yup from "yup";
import { Formik, getIn } from "formik";
import ReactQuill from "react-quill";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import NotFound from "../../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import Notificationstyle from "./Notificationstyle";
import InfoPopover from "../../../src/components/CommonInfoComponent";
import { DatePicker } from 'react-rainbow-components';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const styles: any = {
  EventInfoCard: {
    border: "1px solid #EEEEEE",
    padding: "18px 14px",
    bordeRadius: "5px",
    cursor: "pointer",
    height: "100%",
    "&:hover": {
      boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
    },
  },
  innerSpace: {
    // padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },

  EventName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.primary.dark,
  },
  EventId: {
    letterSpacing: "0.1px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    color: "#232360",
  },
  EventDesc: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginTop: "8px",
  },
  EventDate: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginLeft: "10px",
  },
  LineClamp: {
    marginTop: "15px",
    whiteSpace: "nowrap",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  DialogTitle: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: " 39px",
    color: theme.palette.primary.dark,
  },
  Schedulefor: {
    fontWeight: 400,
    fontSize: "16.2314px",
    lineHeight: "19px",
    color: " #212121",
    margin: 0,
  },
  InnerHtml: {
    marginBottom: "12px",
    "& p": {
      margin: 0,
      textAlign: "center",
      fontSize: "24px",
      color: theme.palette.primary.dark,
    },
  },
  TitleLabel: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#232360",
  },
  TitleValue: {
    fontWeight: 600,
    fontSize: " 18px",
    lineHeight: "21px",
    color: " #424242",
    marginLeft: "8px",
  },
  Active: {
    fontWeight: 400,
    color: theme.palette.success.dark,
    m: 0,
  },

  Schedule: {
    fontWeight: 400,
    color: "#5F6388",
    m: 0,
  },

  Expired: {
    fontWeight: 400,
    color: theme.palette.error.dark,
    m: 0,
  },
};

const styleCross = {
  position: "absolute" as "absolute",
  right: "12px",
  top: "16px",
};

const Active = {
  fontWeight: 400,
  color: theme.palette.success.dark,
  m: 0,
};

const Scheduled = {
  fontWeight: 400,
  color: "#5F6388",
  m: 0,
};

const Expired = {
  fontWeight: 400,
  color: "#BDBDBD",
  m: 0,
};
interface Event {
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  startDate: Date;
  endDate: Date;
}

interface EventView {
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  status: string;
  startDate: Date;
  endDate: Date;
  id: string;
}

const initialvalues = {
  id: "",
  titleName: "",
  description: "",
  visibility: "",
  notice: "",
  startDate: "",
  endDate: "",
};

enum Status {
  All = 0,
  Active = "1",
  Scheduled = "2",
  Expired = "3",
}
type StatusList = keyof typeof Status;

export default function Notice(modalOpen: any) {
  const _permission = useSelector(
    (state: any) => state.NotificationPermissionApp.NotificationPermission
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openStatus = Boolean(anchorEl);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [events, setEvents] = useState<any>([]);
  const [status, setStatus] = useState("Status");
  const navigate = useNavigate();

  let obj: Event = {
    title: "",
    description: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    notice: false,
  };

  const [id, setId] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formValues, setFormValues] = React.useState<Event>(obj);
  const [title, setTitle] = React.useState(formValues.title);
  const [description, setDescription] = React.useState(formValues?.description);
  const [visibility, setVisibility] = React.useState(formValues?.visibility);
  const [startDate, setStartDate] = React.useState("");
  

  const [endDate, setEndDate] = useState("");
  const [notice, setNotice] = React.useState(formValues?.notice);
  const [visible, setVisible] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [eventOpen, seteventOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  ////////Pagination Programming
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const pageCount = Math.ceil(events.length / rowsPerPage);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  ////////over

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseStatus = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
    setId("");
  };
  function removeTagsAndAmp(str: any) {
    // Remove HTML tags
    str = str.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str.replace(/&amp;/g, '&');
    str = str.replace(/(&nbsp;)/g, '');

    return str;
  }

  const handleClickOpen = () => {
    setSubmit(false);
    setOpen(true);
    setErrorMessage(false);
    if (id == "" && id.length == 0) {
      setEndDate("");
      setStartDate("");
      setDescription("");
      setVisibility("");
      setTitle("");
      setVisible(false);
      setshowHelpText(false);
      
    }
  };
  let statusValue = "All";
  useEffect(() => {
    if (_permission.page_ManageEventManagement_Access !== true) {
      navigate("/dashboard");
    }
    seteventOpen(modalOpen);
    getEventDetails(statusValue);
  }, []);

  const getEventDetails = async (status: any, searchValue: string = "") => {
    var input = {
      status: status == "All" ? 0 : status,
      search: searchValue,
    };
    await eventManagementService.getEvents(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setEvents(response?.data);
      }
    });
  };
  const [buttonDisable, setButtonDisable] = useState(false);
  const createEvent = async (values: any) => {
    try {
      let event = values;
      if (visible === false) {
        let dateT = new Date().toISOString();
        dateT = dateT.slice(0, 10) + 'T00:00:00Z';
        event.startDate = dateT;
      }
      event.event = !visible;
      event.visibility = isChecked;
      await eventManagementService.createEvent(event).then((response: any) => {
        if (response.data.isError) {
        } else setOpen(false);

        toast.success("Event added successfully.", {
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),
        });
        //  handleClickOpen1();
        getEventDetails(statusValue);
        setButtonDisable(false)
        //   handleClose();
        // navigate('/editmanage-channel')
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const deleteEventData = async (id: any, e: any) => {
    e.stopPropagation();
    setOpenDelete(true);
    setCurrentId(id);
  };
  const startRef = useState();

  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const DeleteEvents = async () => {
    setOpenDelete(false);
    try {
      await eventManagementService
        .deleteEvent(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Event Deleted successfully.");

            getEventDetails(statusValue);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const getDateString = (dateObject: Date) => {
    if (dateObject == null) {
      return null;
    }
    let date = new Date(dateObject);

    // date.setDate(date.getDate() + 20);
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  const [showHelpText , setshowHelpText]= React.useState(false);

  


  const [formSubmitted, setFormSubmitted] = useState(false);

  const getDate = (dateObject: Date) => {
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00Z`;
  }


  /////update programming
  const getEventDatabyId = async (id: any, e: any) => {
    e.stopPropagation();
    await eventManagementService.getEventById(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setId(response?.data.id);
        setNotice(response?.data);
        setTitle(response?.data.title);
        setVisibility(response?.data.visibility);
        if (response?.data.visibility) {
          setIsChecked(true);
        }

        setEndDate(response?.data.endDate);
        setStartDate(response?.data.startDate);
        setNotice(response?.data.notice);
        setDescription(response?.data.description);
        setVisible(response?.data.status == "Scheduled");
        setOpen(true);
      }
    });
  };
  const [user, setUser] = useState(initialvalues);

  const editEventDetails = async (values: any) => {
    try {
      let notice = values;
      if (visible === false) {
        let dateT = new Date().toISOString();
        dateT = dateT.slice(0, 10) + 'T00:00:00Z';
        notice.startDate = dateT;
      }
      notice.notice = !visible;

      await eventManagementService.updateEvent(values).then((response: any) => {
        if (response.data.isError) {
        } else toast.success("Event Updated successfully", {
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),
        });
        setId("");
        getEventDetails(statusValue);
        setOpen(false);
      });

      getEventDetails(statusValue);
    } catch (ex: any) { }
  };

  const fieldValidationSchema = Yup.object().shape({
    title: Yup.string()
      // .matches(
      //   // /^[A-Za-z0-9 ]+$/,
      //   "Title can only contain Alphabets and Numbers"
      // )
      //.max(150, "Title can only contain 150 Alphabets")
      .required("Title Name is required"),
    // startDate: Yup.string().required('Start date  is required'),
    startDate:
      visible == true
        ? Yup.string().required("Start date  is required")
        : Yup.string(),

    endDate: Yup.string().required("End date is Required"),
    //   description: Yup.date().required('Description is required'),
    //   description: Yup.object().shape({description: Yup.string().required("My message")})
  });

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  //Searching

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  var eventSearch = { id: "" };
  const [eventHook, setEventHook] = useState(eventSearch);
  const [statusDataHook, setStatusDataHook] = useState(statusValue);

  const triggerSearch = async (newValue: string) => {
    getEventDetails(statusDataHook, newValue);
    setPage(0);
  };
  const getSearchDetails = async (newValue: string) => {
    let values = {
      title: eventHook.id,
      search: newValue,
      take: 1000,
      skip: 0,
    };
    await eventManagementService.getSearch(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        setEvents(response?.data);
        if (response.data.length == 0) {
        }
      }
    });
  };


  const filterEventbyStatus = (status: any) => {
    statusValue = status;
    getEventDetails(status);
    setStatusDataHook(status);
  };

  //View
  const [openView, setOpenView] = React.useState(false);
  const [eventView, setEventView] = React.useState<EventView | null>(null);
  const handleClickOpenView = async (id: any) => {
    await eventManagementService.getEventViewId(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setEventView(response?.data);
        setOpenView(true);
      }
    });
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Notification</Typography>
        <Typography color="text.primary">Event </Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        {/* <Notes
          NoteText="Here, admin will create and post the event details on Seasia Connect wall. The events post added by admin will be shown to employees."
        /> */}
        <Stack
              sx={Notificationstyle.topbar}
              spacing={{xs:0,sm:2}}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            All Events
          </Typography>
          <Box>
                
              <InfoPopover  onInput={"Events"}/>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
          <Stack  direction={{ xs: 'column', sm: "row" }}   sx={Notificationstyle.topright} spacing={2} >
            <Stack  direction="row" alignItems="center" spacing={2}>
              <Button
                id="basic-button"
                aria-controls={openStatus ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openStatus ? "true" : undefined}
                onClick={handleClick}
                sx={{ justifyContent: "space-between" }}
                variant="selectSmall"
              >
                {statusDataHook} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openStatus}
                onClose={handleCloseStatus}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterEventbyStatus("All");
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterEventbyStatus("Active");
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterEventbyStatus("Expired");
                  }}
                >
                  Expired
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterEventbyStatus("Scheduled");
                  }}
                >
                  Scheduled
                </MenuItem>
              </Menu>
              <Box sx={{
                "& .searcg-field": {
                  width: { xs: "100%", md: "auto" },
                },
                width: { xs: "100%", md: "auto" },
              }}>
                <SearchBar
                  setSearchValue={setSearchValue}
                  callBackFunction={triggerSearch}
                />
              </Box>

            </Stack>
            {_permission.action_ManageEventsListing_Add_Access && (
              <Button
                variant="contained"
                onClick={handleClickOpen}
                color="primary"
                startIcon={<AddIcon />}
              >
                Add Event
              </Button>
            )}
          </Stack>
        </Stack>
        {events && events.length > 0 ? (
          <Grid container spacing={2}>
            {events
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event: any) => (
                <Grid item xs={12} lg={3}>
                  <Paper
                    elevation={0}
                    sx={[styles.EventInfoCard]}
                    onClick={() => handleClickOpenView(event.id)}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        component="p"
                        sx={[styles.EventId]}
                      ></Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          justifyContent="space-between"
                          component="p"
                          sx={[styles[`${event.status}`], styles.innerSpace]}
                        >
                          <Icons.dots />
                          &nbsp;{event.status}
                        </Typography>
                        {_permission.action_ManageEventsListing_Edit_Access && event?.status !== "Expired" && (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={(e) => {
                                getEventDatabyId(event.id, e);
                              }}
                            >
                              <Icons.Edit />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Stack>

                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Typography
                        variant="body1"
                        component="h4"
                        sx={[styles.EventName]}
                      >
                        {event?.title?.length > 30
                          ? event?.title.slice(0, 30 - 1) + "..."
                          : event?.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        sx={[styles.EventDesc]}
                      >
                        {/* {event.formattedHtml?.length > 52
                          ? event.formattedHtml.slice(0, 52 - 1) + "..."
                          : event.formattedHtml} */}
                        {removeTagsAndAmp(event?.description)?.length > 30
                          ? removeTagsAndAmp(event?.description).slice(0, 30 - 1) + "..."
                          : removeTagsAndAmp(event?.description)}
                      </Typography>
                    </Box>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"

                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography component="p" sx={[styles.EventId]}>
                          Created
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={[styles.EventDate]}
                        >
                          {/* {event.createdDate} */}
                          {`${moment(event?.createdDate).format(
                            "DD MMM, YYYY"
                          )}`}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {_permission.action_ManageEventsListing_Delete_Access && (
                          <Tooltip title="Delete">
                            <IconButton
                              sx={{ ml: 1 }}
                              onClick={(e) => deleteEventData(event.id, e)}
                            >
                              <Icons.Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        ) : (
          <NotFound NotfoundText="No Result Found" />
        )}
        <TablePagination
          labelRowsPerPage="Cards per page"
          rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
          component="div"
          count={events.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          
          labelDisplayedRows={() => `Total: ${events?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>

      <Dialog
        onClose={Button}
        fullScreen={fullScreen}
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        scroll="body"
      >
        <Formik
          initialValues={{
            title: title,
            description: description,
            visibility: visibility,
            notice: false,
            startDate: startDate,
            endDate: endDate,
            id: id,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);

              if (
                values.endDate &&
                values.startDate &&
                values.endDate < values.startDate
              ) {
                toast.warning("Start date must be less than end date", {
                  onOpen: () => setButtonDisable(true),
                  onClose: () => setButtonDisable(false),
                });
                return false;
              }
              if (id && id.length > 0) {
                await editEventDetails(values);
              } else {
                await createEvent(values);
              }
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={[styles.DialogTitle]}
                >
                  New Events
                </DialogTitle>
                <IconButton
                  aria-label="delete"
                  sx={styleCross}
                  onClick={handleClose}
                >
                  <ClearIcon />
                </IconButton>
                <DialogContent>
                  <Box sx={{ pt: 0.5 }}>
                    <Box>
                      <TextField
                        id="outlined-basic"
                        placeholder="Title "
                        variant="outlined"
                        error={Boolean(
                          getIn(touched, "title") && getIn(errors, "title")
                        )}
                        fullWidth
                        helperText={
                          getIn(touched, "title") && getIn(errors, "title")
                        }
                        label="Title"
                        name="title"
                        value={values.title}
                        onBlur={handleBlur}
                        // onChange={handleChange}
                        onChange={(e) => {
                         // let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,-\s]$/g, "").trimStart().slice(0, 149);
                          //value = value.replace(/\s{3,}$/g, "");
                         let value = e.currentTarget.value
                         value = value.replace(/\s{3,}$/g, "").trimStart().slice(0, 149);
                         
                          values.title = value
                          setTitle(value);
                        }}
                        inputProps={{ maxLength: 150 }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      />
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "right", color: "#757575" }}
                      >
                        Max 150 words
                      </Typography>
                    </Box>

                    <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
                      <Typography
                        component="p"
                        sx={[styles.Schedulefor]}
                        gutterBottom
                      >
                        Scheduled For
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ ml: 3 }}
                        // defaultValue="now"
                        defaultValue={visible ? "future" : "now"}
                      >
                        <FormControlLabel
                          value="now"
                          control={<Radio />}
                          label="Now "
                          onClick={() => {
                            setVisible(false);
                          }}
                          defaultChecked
                        />
                        <FormControlLabel
                          value="future"
                          control={<Radio />}
                          label="Scheduled for future"
                          onClick={() => setVisible(true)}
                        />
                      </RadioGroup>
                    </Stack>
                    <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
                      {visible && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="Start Date"
                           label = "Start Date"
                            style={{ width: '30%'}}
                            borderRadius="semi-square"
                           
                            labelAlignment="left"
                            // disablePast
                            minDate={moment().subtract(0, "years").toDate()}
                            maxDate={moment().add(7, "years").toDate()}
                            // openTo="year"
                            // className="error"
                            // views={["year", "month", "day"]}
                            value={values.startDate}
                            onChange={(newValue: any) => {
                              let dateT = getDate(newValue);
                              values.startDate = dateT;
                              setStartDate(dateT);
                              setErrorMessage(false);
                              setSubmit(true);
                              setshowHelpText(false);

                            }}
                            
                            
                            error={showHelpText && !values.startDate ? "Start Date is required" : ""}

                            // renderInput={(params: any) => (
                            //   <TextField
                            //     onChange={() => { values.startDate = startDate }}
                            //     onKeyDown={(e: any) => {
                            //       e.preventDefault();
                            //     }}
                            //     {...params}
                            //     autoComplete="off" // Disable suggestions
                            //     readOnly={true} // Disable user input
                            //     onBlur={handleBlur}
                            //     error={
                            //       getIn(touched, "startDate") &&
                            //       getIn(errors, "startDate")
                            //     }
                            //     helperText={
                            //       getIn(touched, "startDate") &&
                            //       getIn(errors, "startDate")
                            //     }
                            //     sx={{
                            //       "& .MuiSvgIcon-root": {
                            //         fill: theme.palette.primary.main,
                            //       },
                            //     }}
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      )}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        
                         placeholder="End Date" 
                         label = "End Date"
                          labelAlignment="left"
                          style={{ width: '30%'}}
                          borderRadius="semi-square"
                          // openTo="year"
                          // disablePast
                          minDate={moment().subtract(0, "years").toDate()}
                          maxDate={moment().add(7, "years").toDate()}
                          className="error"
                          // views={["year", "month", "day"]}
                          value={values.endDate}
                          onChange={(newValue: any) => {
                            let abc = getDate(newValue);
                            values.endDate = abc;
                            setEndDate(abc);
                            setErrorMessage(false);
                            setSubmit(true);
                            setshowHelpText(false);

                          }}

                          error={showHelpText && !values.endDate ? "End Date is required" : ""}
                          // renderInput={(params: any) => (
                          //   <TextField
                          //     onKeyDown={(e: any) => {
                          //       e.preventDefault();
                          //     }}
                          //     {...params}
                          //     readOnly={true} // Disable user input
                          //     autoComplete="off" 
                          //     onBlur={handleBlur}
                          //     error={
                          //       getIn(touched, "endDate") &&
                          //       getIn(errors, "endDate")
                          //     }
                          //     helperText={
                          //       getIn(touched, "endDate") &&
                          //       getIn(errors, "endDate")
                          //     }
                          //     sx={{
                          //       "& .MuiSvgIcon-root": {
                          //         fill: theme.palette.primary.main,
                          //       },
                          //     }}
                          //   />
                          // )}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack direction="row" alignItems="start" sx={{ mt: 3 }}>
                      <Checkbox
                        defaultChecked
                        sx={{ p: 0, mr: 2 }}
                        id="visibility"
                        name="visibility"
                        value="visibility"
                        checked={isChecked}
                        onChange={handleOnChange}
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "18px",

                          /* Grayscale-Shades/Grey-300 */

                          color: "#616161",
                        }}
                      >
                        By default, event will be display and sort based on the
                        start date. Selecting this option will show this event
                        at top in event lists.
                      </Typography>
                    </Stack>

                    <Box sx={{ mt: 3 }}>
                      <Typography
                        component="p"
                        sx={{
                          mb: 2,
                          fontWeight: "400",
                          fontSize: " 16.2314px",
                          lineHeight: "19px",
                          color: "#212121",
                        }}
                        gutterBottom
                      >
                        Description
                      </Typography>

                      <ReactQuill
                        // modules={AppConstants.EditorSetting}
                        theme="snow"
                        value={values.description}
                        onChange={(newValue) => {
                          values.description = newValue;

                          setDescription(newValue);
                          //setErrorMessage(true);
                        }}
                        modules={{
                          toolbar: [
                            [{ header: "1" }, { header: "2" }, { font: [] }],
                            [{ size: [] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image", "video"],
                            ["clean"],
                          ],
                        }}
                      />
                    </Box>
                  </Box>
                </DialogContent>

                <DialogActions sx={{ p: 3, pt: 0 }}>
                  <Button autoFocus onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                    onClick={() => {
                      setErrorMessage(true);
                      setSubmit(true);
                      setshowHelpText(!endDate || !startDate);
                      setshowHelpText(!endDate || !startDate);
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openView}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          View Event
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleCloseView}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Title:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {eventView?.title}
              </Typography>
            </Stack>
            <Typography
              justifyContent="space-between"
              component="p"
              sx={[styles[`${eventView?.status}`], styles.innerSpace]}
            >
              <Icons.dots />
              &nbsp;
              {eventView?.status}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Scheduled For:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {`${moment(eventView?.startDate).format("DD MMM, YYYY")}`} to
                {`${moment(eventView?.endDate).format("DD MMM, YYYY")}`}
              </Typography>
            </Stack>
          </Stack>
          <Paper variant="outlined" sx={{ p: 2, mt: 3, textAlign: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                "& img": {
                  maxWidth: "100%",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: eventView?.description as string,
              }}
            ></Typography>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleCloseView}
          >
            Back to List
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openDelete}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Event
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Do you really want to Delete this, Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteEvents}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}
