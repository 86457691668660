import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Breadcrumbs, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import * as Yup from "yup";
import { useEffect } from "react";
import { inventoryService } from "../../service/Inventory/inventoryService";
import NotFound from "../../components/styledComponent/NotFound";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function InventoryMyAssets1(props: any) {
    const [openReturnDialog, setOpenReturnDialog] = React.useState(false);
    const [myAssetsDetails, setMyAssetsDetails] = React.useState([]);
    const [itemId, setItemId] = React.useState("");
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector(
        (state: any) => state.InventoryPermissionApp.InventoryPermission
    );
    console.log(props?.userId)
    const navigate = useNavigate();
    const fieldValidationSchema = Yup.object().shape({
        comment: Yup.string()
            .max(150, "Comment can only contain 150 Alphabets")
            .required("Comment is required"),
    });
    useEffect(() => {
        if (_permission.page_ManageMyAssets_Access != true) {
            navigate("/dashboard");
        }
        getUserAssets(props?.userId)
    }, []);


    const getUserAssets = async (id: any) => {
        // let abc = {
        //     userId: id,
        //     empId: "",
        //     status: null
        // }
        let abc = _authUser.employeeInfo.employeeId;
        try {
            await inventoryService
                .getAssignedDevicedetailByEmpId(abc)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setMyAssetsDetails(response?.data)
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    const createReturnRequest = async (values: any) => {
        debugger
        setOpenReturnDialog(false);
        let abc = {
            id: itemId,
            itComment: "",
            userComment: values?.comment,
            status: 3
        }
        try {
            await inventoryService.returnRequestOfAsset(abc).then((response: any) => {
                if (response.data.isError) {
                } else {
                    toast.success("Request Added successfully.");
                    setItemId("")
                    getUserAssets(props?.userId);
                }
            });
        } catch (ex: any) {

            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', height: '100%', }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Typography  >
                        My Assets
                    </Typography>

                </Breadcrumbs>
                <Paper sx={{ padding: '0px 0px', marginTop: '11px', "& .css-19kzrtu": { padding: '0px' } }}>

                    <TableContainer sx={{ mt: 1 }}>
                        <Table aria-label="simple table" size="small" sx={{
                            // "& .css-6asl74-MuiTableCell-root.MuiTableCell-head": {
                            //     background: 'none',
                            // },
                            // "& .css-6asl74-MuiTableCell-root ": {
                            //     borderBottom: 'none'
                            // }
                        }}>
                            <TableHead >
                                <TableRow>
                                    <StyledTableCell align="left" >Sr. No</StyledTableCell>
                                    <StyledTableCell align="left" >Category</StyledTableCell>
                                    <StyledTableCell align="left" >Item Name </StyledTableCell>
                                    <StyledTableCell align="left" >Brand </StyledTableCell>
                                    <StyledTableCell align="left">Date of Issuance</StyledTableCell>
                                    <StyledTableCell align="left">Requested to Return</StyledTableCell>
                                    <StyledTableCell align="left">Status</StyledTableCell>
                                    <StyledTableCell align="left">Comment</StyledTableCell>
                                    <StyledTableCell align="left">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {myAssetsDetails?.length > 0 && myAssetsDetails?.map((item: any, idx: any) => (
                                    <TableRow>
                                        <StyledTableCell align="left">{idx + 1}</StyledTableCell>
                                        <StyledTableCell align="left">{item?.category}</StyledTableCell>
                                        <StyledTableCell align="left">{item?.itemName}</StyledTableCell>
                                        {item.brand.map((value: any) => (
                                            <StyledTableCell align="left">
                                                {value?.brandName !== "" ? value?.brandName : "--"}
                                            </StyledTableCell>
                                        ))}
                                        <StyledTableCell align="left">{item?.issueDate ? moment(item.issueDate).format("DD-MMM-YYYY") : '--'}</StyledTableCell>
                                        <StyledTableCell align="left">{item?.requestedOn ? moment(item.requestedOn).format("DD-MMM-YYYY") : '--'}</StyledTableCell>
                                        <StyledTableCell align="left">{item?.status == 3 ? "Return Initiative" : item?.status == 2 ? "Assigned To You" : "--"}</StyledTableCell>
                                        <StyledTableCell align="left">{item?.userComment}</StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Button size="small" variant="outlined" disabled={item?.status == 3}
                                                color="primary" sx={{ padding: '8px 20px', }}
                                                onClick={() => { setOpenReturnDialog(true); setItemId(item?.id) }}>{item?.status == 3 ? "Pending" : "Request To Return"}</Button>
                                        </StyledTableCell>
                                    </TableRow>))}
                            </TableBody>
                        </Table>
                        {myAssetsDetails.length == 0 && <NotFound NotfoundText="No Device Assigned Yet" />}
                    </TableContainer>
                </Paper >
            </Box>

            <Dialog
                open={openReturnDialog}
                onClose={Button}
                aria-labelledby="responsive-dialog-title"
                maxWidth="sm"
                fullWidth
                scroll="body"
                sx={{
                    "& .css-23vkr6-MuiPaper-root-MuiDialog-paper": {
                        maxWidth: '763px',
                        maxHeight: '85%',
                    }
                }}
            >
                <Formik
                    initialValues={{
                        comment: "",
                        id: itemId,
                    }}
                    enableReinitialize
                    validationSchema={fieldValidationSchema}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            await createReturnRequest(values);

                        } catch (err: any) {
                            console.error(err);
                            err = JSON.parse(JSON.stringify(err));
                            if (err.status == 400) {
                                {
                                    err.data.Error.map((error: any, idx: any) =>
                                        toast.warn(error)
                                    );
                                }
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <>
                                <DialogTitle
                                    id="responsive-dialog-title"
                                    sx={{ padding: '28px 36px 30px 36px' }}
                                >
                                    <Stack direction={'row'} justifyContent={'space-between'} sx={{ position: 'relative', overflow: 'hidden' }}>
                                        <Typography sx={{ fontSize: "26px", fontWeight: 600, }}> Return Request</Typography>
                                        <IconButton
                                            aria-label="delete"
                                            //sx={styleCross}
                                            onClick={() => { setOpenReturnDialog(false); setItemId("") }}
                                            sx={{ marginLeft: 'auto', position: 'absolute', top: '0', right: '0' }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Stack>


                                </DialogTitle>
                                <DialogContent sx={{ padding: '10px 40px 30px 40px' }}>
                                    <Grid container spacing={2} sx={{ flexGrow: 1, margin: '0px', maxWidth: '100%' }}>
                                        <Grid xs={12}>
                                            <Box padding={'10px 0px'}>
                                                <Typography variant="body1" fontSize={'14px'} gutterBottom>
                                                    Add Comment
                                                </Typography>
                                                <TextField
                                                    minRows={3}
                                                    sx={{
                                                        "& .css-r2i9ug-MuiInputBase-input-MuiOutlinedInput-input": {
                                                            padding: '16px 10px',
                                                            minHeight: '90px'
                                                        }
                                                    }}
                                                    placeholder=""
                                                    variant="outlined"
                                                    name="comment"
                                                    id={"comment"}
                                                    size="small"
                                                    fullWidth
                                                    value={values?.comment}
                                                    onChange={handleChange}
                                                    error={Boolean(
                                                        getIn(touched, "comment") && getIn(errors, "comment")
                                                    )}
                                                    helperText={
                                                        getIn(touched, "comment") && getIn(errors, "comment")
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </DialogContent>

                                <DialogActions sx={{ padding: '28px 40px 30px 40px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </DialogActions>
                            </>
                        </form>
                    )}
                </Formik>
            </Dialog>

        </>
    )
}