import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoutes from "../components/ProtectedRoutes";
import PublicRoutes from "../components/PublicRoutes";
import InnerContent from "./InnerContent";
import Dashboard from "../pages/Dashboard";
import UserDashboard from "../pages/UserDashboard";
import Login from "../pages/Login";
import LoginType from "../pages/login/LoginType";
import ChangePassword from "../pages/login/ChangePassword";
import RecoverUsername from "../pages/login/RecoverUsername";

import ReportManagement from "../pages/reports/ReportManagement";
import MilestoneReport from "../pages/reports/MilestoneReport";
import OtherScreenManager from "../pages/projectmanagement/ManagerOthers";
import Reserve from "../pages/reports/Reserve";
import Rewards from "../pages/Rewards";
import InventoryManagement  from "../pages/Inventory/InventoryManagement";
import InventoryList  from "../pages/Inventory/InventoryList";
import Inventorynewcategory from "../pages/Inventory/newcategory";
import Inventorynewcategoryupload from "../pages/Inventory/newcategoryupload";
import Inventoryadditems from "../pages/Inventory/additems";
import InventoryViewItem from "../pages/Inventory/viewitem";
import IssueDevice  from "../pages/Inventory/IssueDevice";
import Inventoryreturndevice from "../pages/Inventory/Inventoryreturndevice";
import CeoDashboard from "../pages/CeoDashboard";

// import ForgotPassword from "../pages/login/ForgotPassword";
// import RoleManagement from "../pages/role/RoleManagement";
// import Board from "../pages/Board";
// import MasterManagement from "../pages/MasterManagement";
// // import NotificationManagement from "../pages/NotificationManagement";
// import ScreenManagement from "../pages/ScreenManagement";
// import UserManagement from "../pages/users/UserManagement";
// import ProjectManagement from "../pages/ProjectManagement";
// import DepartmentData from "../pages/MasterData/DepartmentData";
// import ManageDepartmentData from "../pages/MasterData/ManageDepartmentData";
// import EditManageDepartmentData from "../pages/MasterData/EditManageDepartmentData";
// import AddRole from "../pages/role/AddRole";
// import AddNewRole from "../pages/role/AddNewRole";
// import AddDepartment from "../pages/MasterData/department/AddDepartment";
// import ManageDepatment from "../pages/MasterData/department/ManageDepatment";
// import EditDepartment from "../pages/MasterData/department/EditDepartment";
// import AddDesignation from "../pages/MasterData/designation/AddDesignation";
// import ManageDesignation from "../pages/MasterData/designation/ManageDesignation";
// import EditDesignation from "../pages/MasterData/designation/EditDesignation";

import ForgotPassword from "../pages/login/ForgotPassword";
import RoleManagement from "../pages/role/RoleManagement";
import Board from "../pages/Board";
import IssueDetailLarge from "../pages/boardscreens/IssueDetailLarge";
import MasterManagement from "../pages/MasterManagement";
import NotificationManagement from "../pages/NotificationManagement";
import ScreenManagement from "../pages/ScreenManagement";
import UserManagement from "../pages/users/UserManagement";
import ProjectManagement from "../pages/ProjectManagement";
import MyProjectsManager from "../pages/projectmanagement/MyProjectsManager";
import CreateProject from "../pages/users/CreateProject";

import AddRole from "../pages/role/AddRole";
import ManageRolePermission from "../pages/role/ManagePermission";
import UpdateRolePermission from "../pages/role/EditPermission";
import ViewRolePermissions from "../pages/role/ViewPermissions";
import AddNewRole from "../pages/role/AddNewRole";
import RoleView from "../pages/role/RoleView";

import AddDepartment from "../pages/MasterData/department/AddDepartment";
import ManageDepatment from "../pages/MasterData/department/ManageDepatment";
import EditDepartment from "../pages/MasterData/department/EditDepartment";
import AddDesignation from "../pages/MasterData/designation/AddDesignation";
import ManageDesignation from "../pages/MasterData/designation/ManageDesignation";
import EditDesignation from "../pages/MasterData/designation/EditDesignation";

import LabelData from "../pages/MasterData/labels/AddLabel";
import ManageLabelData from "../pages/MasterData/labels/ManageLabel";
import EditLabelData from "../pages/MasterData/labels/EditLabel";

import ManageTechnicalSkillData from "../pages/MasterData/technicalskils/ManageTechnicalSkillData";
import TechnicalSkillData from "../pages/MasterData/technicalskils/TechnicalSkillData";
import EditManageTechnicalSkillData from "../pages/MasterData/technicalskils/EditManageTechnicalSkillData";
import AchievementData from "../pages/MasterData/acheivement/AcheivementData";
import EditAchievementData from "../pages/MasterData/acheivement/EditAchievement";
import EditRoleManagementData from "../pages/role/editRoleManagement";
import ManageAchievementData from "../pages/MasterData/acheivement/ManageAcheivementData";

import AddChannelData from "../pages/MasterData/channel/AddChannelData";
import EditManageChannelData from "../pages/MasterData/channel/EditManageChannelData";
import ManageChannelData from "../pages/MasterData/channel/ManageChannelData";

import AddDocumentList from "../pages/MasterData/documentlist/AddDocumentList";
import EditManageDocumentListData from "../pages/MasterData/documentlist/EditManageDocumentListData";
import ManageDocumentListData from "../pages/MasterData/documentlist/ManageDocumentListData";

import AddHodData from "../pages/MasterData/headofdepartment/AddHOdData";
import ManageHodData from "../pages/MasterData/headofdepartment/ManageHOdData";
import EditHodData from "../pages/MasterData/headofdepartment/EditManageHOdData";

import AddUser from "../pages/users/AddUser";
import UserTemplate from "../pages/users/UserTemplate";
import UserImportedData from "../pages/users/UserImportedData";
import UserProfile from "../pages/users/UserProfile";
import DepartmentHead from "../pages/departmenthead/DepartmentHead";
import Manager from "../pages/projectmanagement/Manager";
import LogSheet from "../pages/projectmanagement/LogSheet";
import ProjectList from "../pages/projectmanagement/ProjectList";
import AddProjectPage from "../pages/projectmanagement/AddProjectPage";
import EditUser from "../pages/users/EditUser";
import ColumnManagement from "../pages/boardscreens/ColumnManagement";
import EventBoard from "../pages/event/EventBoard";
import EventOnlyView from "../pages/event/EventOnlyView";
import NoticeBoard from "../pages/event/Notice";
import AssignResource from "../pages/projectmanagement/Controls/AssignResources";
import NoticeBoardView from "../pages/event/Noticesonlyview";
import Leaves from "../pages/leavemanagement/Leaves";
import HolidayCalender from "../pages/leavemanagement/HolidayCalender";
import SetupLeaves from "../pages/leavemanagement/SetupLeaves";
import DailyAttendanceReport from "../pages/reports/attendancereport/DailyAttendanceReport";
import AttendanceReportFilter from "../pages/reports/attendancereport/AttendanceReportFilter";
import AttendanceReportDetail from "../pages/reports/attendancereport/AttendanceReportDetail";
import Reasons from "../pages/BrowniePoints/MasterReasonData/Reasons";
import RewardManagement from "../pages/BrowniePoints/MasterReasonData/RewardManagement";
import BrowniePoint from "../pages/BrowniePoints/MasterReasonData/BrowniePoint";
import RewardSetting from "../pages/BrowniePoints/MasterReasonData/RewardSetting";
import BrowniePointManager from "../pages/BrowniePoints/BrowniePointsManager/BrowniePointManager";
import BrowniePointEmployee from "../pages/BrowniePoints/BrownieEmployee/BrowniePointEmployee";
import AdminBrowniePointview from "../pages/BrowniePoints/BrowniePointsManager/BrowniePointview";
import ReferPoints from "../pages/BrowniePoints/BrownieEmployee/ReferPoints";
import RewardRequest from "../pages/BrowniePoints/MasterReasonData/RewardRequest";
import Escalation from "../pages/escalationProcess/Escalation";
import RiskRais from "../pages/riskProcess/Risk";
import OrgChart from "../pages/chart/OrgChart";
import Milestones from "../pages/projectmanagement/Milestones";
import WorkDairy from "../pages/reports/WorkDairy";
import Backlogs from "../pages/boardscreens/BackLogs";
import HrReport from "../pages/reports/HrReport";
import HrMonthlyReport from "../pages/reports/attendancereport/HrMonthlyReport";
import ProjectChat from "../pages/ProjectChat/ProjectChat";
import ProjectsTasklist from '../pages/ProjectsTasklist'
import PayReward from "../pages/BrowniePoints/MasterReasonData/PayReward";
import AdvanceSearch from "../pages/AdvanceSearch";
import TeamLeaveCard from "../pages/leavemanagement/TeamLeaveCard";
import ReportManagementCTO from "../pages/reports/ReportManagementCTO";
import HelpDesk from "../pages/HelpDesk";
import InventoryMyAssets from "../pages/Inventory/InventoryMyAssets";
import VirtualOffice from "../pages/VirtualOffice";
import LeadPortal from "../pages/LeadPortal";
import InventoryReport from "../pages/reports/InventoryReport";
import InventoryMyAssets1 from "../pages/Inventory/InventoryMyAsets1";
import AddAllowance from "../pages/MasterData/Allowence/AddAllowance";
import ManageAllowance from "../pages/MasterData/Allowence/ManageAllowance";
import EditAllowance from "../pages/MasterData/Allowence/EditAllowance";
import SkillSetReport from "../pages/reports/SkillSetReport";
import PayRollList from "../pages/PayRole/Payrolelist";
import SlabView from "../pages/PayRole/PayrolelistView";


import Payrolesalarydetail from "../pages/PayRole/Payrolesalarydetail";
import AddSlab from "../pages/PayRole/AddSlab";
import HelpDeskreport from "../pages/reports/HelpDeskReport";
import HelpDeskReportView from "../pages/reports/HelpDeskReportView";
import HrWishing from "../pages/HrWishing";
import PollingSystem from "../pages/PollingSystem/PollingSystemAdmin";
import Result from "../pages/PayRole/Result";
import PollingSystemUser from "../pages/PollingSystem/PollingSystemUser";
import GalaEventImg from "../pages/PollingSystem/SeasiaGalaEvent";
import NoVoteList from "../pages/PollingSystem/NoofVoteCount";
import SecretSantaParticipate from "../pages/SecretSanta/SecretSantaParticipants";
import AttendanceReportSheet from "../pages/reports/attendancereport/AttendanceReportSheet";
import UserSpecificAttendance from "../pages/reports/UserSpecificAttendance";
import BannerUpload from "../pages/bannerUpload/BannerUpload";
import MilestonetreeReport from "../pages/reports/MilestoneReportTree";
import ClientReview from "../pages/reports/ClientReview";
import ClientFeedbackReport from "../pages/reports/ClientFeedbackReport";



import ChessParticipants from "../pages/ChessBoard/ChessParticipants";
import ChessMatches from "../pages/ChessBoard/ChessMatch";
import ProjectRevenueReport from "../pages/reports/ProjectRevenueReport";
import ProjectMonthlyRevenueReport from "../pages/reports/ProjectMonthlyRevenueReport";
import LeakageReport from "../pages/reports/LeakageReport";
import ResourceBillingReport from "../pages/ResourceBilling";
import EmployeeFeedback from "../pages/reports/EmployeeFeedback";


import Seasiacafeteria from "../pages/SeasisCafeteria";
import EffortVarianceReport from "../pages/reports/EffortVarianceReport";
import ScheduleVarrianceReport from "../pages/reports/ScheduleVarianceReport";
import DefectLikageReport from "../pages/reports/DefectLikageReport";
import EffortVarianceReportNew from "../pages/reports/EffortVarianceReportnew";
import ResourceReports from "../pages/newResourceBillingReport";
import UserFeedbackReport from "../pages/reports/UserFeedbackReport";
import WildLifePhotoGraphy from "../pages/WildLifePhotoGraphy";
import WildLifeParticipantsList from "../pages/WildLifePhotographyReport";
import SprintReport from "../pages/reports/SprintReport";
import TrackerAndPortalReport from "../pages/reports/TrackerAndPortalReport";
import AddKra from "../pages/KRA/AddKra";
import RateKra from "../pages/KRA/RatingKra";
import KraReport from "../pages/KraReport";
import ClientDashboard from "../pages/ClientDashboard";
import MyMeetings from "../pages/Meetings/MyMeetings";
import MeetingsView from "../pages/Meetings/meetingsView";
import AddCoHod from "../pages/MasterData/coHod/AddCoHod";
import KraData from "../pages/MasterData/PredefinedKRA";
import IO from "../pages/MasterData/IO/IO";
// import Domains from "../pages/MasterData/domainPage/Domain";
import ManageDomain from "../pages/MasterData/domainPage/ManageDomain";
import MilestoneDateExtend from "../pages/milestoneDateExtend/milestoneDateExtend";
import QaReports from "../pages/QaReports";
import NewMilestoneReport from "../pages/reports/NewMilestoneReport";
import ProjectPortfolio from "../pages/project/ProjectPortfolio";
import EmployeeTaskReport from "../pages/reports/EmployeeTaskReport";
import PortfolioView from "../pages/project/PortfolioView";
import UserConsumedHours from "../pages/reports/UserTimeSpent";
import PortfolioEdit from "../pages/project/PortfolioEdit";
import NotFound from "./styledComponent/NotFound";
import LearningSession from "../pages/LearingSession/LearningSession";
import ProjectTrailer from "../pages/ProjectArtifacts";
import ProjectArtifact from "../pages/ProjectArtifacts";
import WebsiteVisitorsData from "../pages/MasterData/WebsiteVisitor/WebsiteVisitors";
import ProjectDocument from "../pages/ProjectDocuments";
import BrownieReports from "../pages/BrownieReports";
import DomainPortfolio from "../pages/project/DomainPortfolioList";
import AddDomainPortfolio from "../pages/project/AddDomainPortfolio";
import DomainPortfolioView from "../pages/project/ViewDomainPortfolio";
// import EditDomain from "../pages/MasterData/domainPage/EditDomain";
// import { Domain } from "@mui/icons-material";

const MainRoutes = (props: any) => (
  <Routes>
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/" element={<Navigate replace to="login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/facing-issue" element={<LoginType />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/recover-username" element={<RecoverUsername />} />
      {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
      {/* <Route path="/otp" element={<OneTimePassword />} /> */}
    </Route>
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<InnerContent />}>
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="/userdashboard" element={<UserDashboard />} />
        <Route path="/role-management" element={<RoleManagement />} />
        <Route path="/board" element={<Board />} />
        <Route path="/board/:id" element={<Board />} />
        <Route path="/board/:id/:id1" element={<Board />} />
        <Route path="/master-management" element={<MasterManagement />} />
        <Route
          path="/notification-management"
          element={<NotificationManagement />}
        />
        {/* <Route path="/editDomain/:id" element={<EditDomain />} />
         */}
        <Route path="/editDepartment/:id" element={<EditDepartment />} />
        <Route
          path="/editRoleManagementData/:id"
          element={<EditRoleManagementData />}
        />
        <Route path="/assign-resource" element={<AssignResource />} />
        <Route path="/screen-management" element={<ScreenManagement />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/project-management" element={<ProjectManagement />} />
        <Route path="/my-projects" element={<MyProjectsManager />} />
        <Route path="/create-project" element={<CreateProject />} />
        <Route path="/role-management/addRole" element={<AddRole />} />
        <Route path="/role/role-view" element={<RoleView />} />
        <Route
          path="/role-management/role-permission/:id"
          element={<ManageRolePermission />}
        />
        <Route path="/role-management/add-new-role" element={<AddNewRole />} />
        <Route
          path="/role-management/role-permission/:id"
          element={<ManageRolePermission />}
        />
        <Route
          path="/role-management/update-permission/:id"
          element={<UpdateRolePermission />}
        />
        <Route
          path="/role-management/view-permission/:id"
          element={<ViewRolePermissions />}
        />
        <Route path="/add-department" element={<AddDepartment />} />
        <Route path="/manage-department" element={<ManageDepatment />} />
        <Route path="/manage-domain" element={<ManageDomain />} />
        <Route path="/website-visitors" element={<WebsiteVisitorsData />} />
        <Route path="/add-designation" element={<AddDesignation />} />
        <Route path="/manage-designation" element={<ManageDesignation />} />

        <Route path="/add-label" element={<LabelData />} />
        <Route path="/edit-label/:id" element={<EditLabelData />} />
        <Route path="/manage-label" element={<ManageLabelData />} />

        <Route path="/manage-department" element={<ManageDepatment />} />
        <Route path="/add-designation" element={<AddDesignation />} />
        <Route path="/manage-designation" element={<ManageDesignation />} />

        <Route
          path="/manage-technicalskill"
          element={<ManageTechnicalSkillData />}
        />
        <Route path="/add-technicalskill" element={<TechnicalSkillData />} />
        <Route
          path="/edit-technicalskill/:id"
          element={<EditManageTechnicalSkillData />}
        />
        <Route path="/issue-detail/:id" element={<IssueDetailLarge />} />

        <Route
          path="/manage-documentlist/:id"
          element={<EditManageDocumentListData />}
        />
        <Route path="/add-documentlist" element={<AddDocumentList />} />
        <Route
          path="/editmanage-documentlist"
          element={<ManageDocumentListData />}
        />
        {/* 
        <Route path="/add-HodData/" element={<AddHodData />} /> */}
        <Route path="/manage-HodData/" element={<ManageHodData />} />
        <Route path="/edit-HodData/:id" element={<EditHodData />} />

        <Route path="/add-coHod/" element={<AddCoHod/>} />
        <Route path="/add-IO/" element={<IO/>} />
        {/* <Route path="/add-Domain/" element={<Domains/>} /> */}

        <Route path="/manage-channel/:id" element={<EditManageChannelData />} />
        <Route path="/edit-channel/:id" element={<EditManageChannelData />} />
        <Route path="/add-channel" element={<AddChannelData />} />
        <Route path="/editmanage-channel" element={<ManageChannelData />} />

        <Route path="/add-allowance" element={<AddAllowance />} />
        <Route path="/attendancereport-sheet" element={<AttendanceReportSheet />} />
        <Route path="/userspecific-attendance" element={<UserSpecificAttendance />} />

        <Route path="/manage-allowance" element={<ManageAllowance />} />
        <Route path="/edit-allowance/:id" element={<EditAllowance />} />

        <Route path="/add-achievement" element={<AchievementData />} />
        <Route path="/editachievement/:id" element={<EditAchievementData />} />
        <Route path="/edit-Designation/:id" element={<EditDesignation />} />
        <Route path="/manageachievement" element={<ManageAchievementData />} />
        <Route path="/users/add-user" element={<AddUser />} />
        <Route path="/users/edit-user/:id" element={<AddUser />} />
        <Route path="/users/user-template" element={<UserTemplate />} />
        <Route
          path="/users/user-Imported-data"
          element={<UserImportedData />}
        />
        <Route path="/users/user-profile/:id" element={<UserProfile />} />
        <Route path="/users/department-head" element={<DepartmentHead />} />
        <Route path="/project/manager" element={<Manager />} />
        <Route path="/project/logsheet/:id" element={<LogSheet />} />
        <Route path="/project/logsheet" element={<LogSheet />} />
        <Route path="/project/logsheet/:id/:id1" element={<LogSheet />} />
        <Route path="/project/projectlist" element={<ProjectList />} />
        <Route path="/project/add-project" element={<Milestones />} />
        {/* <Route path="/project/add-project" element={<AddProjectPage />} />
        <Route path="/project/edit-project/:id" element={<AddProjectPage />} /> */}

        <Route path="/project/edit-project/:id" element={<Milestones />} />
        {/* <Route path="/project/add-project" element={<AddProject />} /> */}
        {/* <Route path="/column-management" element={<ColumnManagement />} /> */}
        <Route path="/column-management/:id" element={<ColumnManagement />} />
        <Route path="/report-management" element={<ReportManagement />} />
        <Route path="/milestone-report" element={<MilestoneReport />} />
        <Route path="/event-board" element={<EventBoard />} />
        <Route path="/leave-management" element={<Leaves />} />
        <Route path="/leave-management/:tabValue" element={<Leaves />} />
        <Route path="/notice" element={<NoticeBoard />} />
        <Route path="/event-only-view" element={<EventOnlyView />} />
        <Route path="/noticeview" element={<NoticeBoardView />} />
        <Route path="/holiday-calendar" element={<HolidayCalender />} />
        <Route path="/setup-leaves" element={<SetupLeaves />} />
        <Route
          path="/daily-attendance-report"
          element={<DailyAttendanceReport />}
        />
        <Route path="/attendance-report" element={<AttendanceReportFilter />} />
        <Route
          path="/attendance-detail/:id"
          element={<AttendanceReportDetail />}
        />
        <Route path="/reasons" element={<Reasons />} />
        <Route path="/admin-brownie-point" element={<BrowniePoint />} />
        <Route path="/reward-setting" element={<RewardSetting />} />
        <Route
          path="/brownie-point-manager"
          element={<BrowniePointManager />}
        />
        <Route path="/reward-request" element={<RewardRequest />} />
        <Route
          path="/brownie-point-employee"
          element={<BrowniePointEmployee />}
        />
        <Route path="/refer-point-employee" element={<ReferPoints />} />
        <Route path="/escalation" element={<Escalation />} />
        <Route path="/risks" element={<RiskRais />} />
        <Route path="/org" element={<OrgChart />} />
        <Route path="/milestone" element={<Milestones />} />
        <Route path="/otherscreenmanager" element={<OtherScreenManager />} />
        <Route path="/work-dairy" element={<WorkDairy />} />
        <Route path="/hr-report" element={<HrReport />} />
        <Route path="/admin-brownie-view/:id/:id1" element={<AdminBrowniePointview />} />
        <Route path="/hr-monthly/:id/:type/:month/:year" element={<HrMonthlyReport />} />
        <Route path="/project-chat" element={<ProjectChat />} />
        <Route path="/projects-tasklist" element={<ProjectsTasklist />} />
        <Route path="/reward-management" element={<RewardManagement />} />
        <Route path="/pay-reward" element={<PayReward />} />
        <Route path="/reserve/:depId" element={<Reserve />} />
        <Route path="/reserve" element={<Reserve />} />
        <Route path="/AdvanceSearch" element={<AdvanceSearch />} />
        <Route path="/TeamLeaveCard" element={<TeamLeaveCard />} />
        <Route path="/ReportManagementCTO" element={<ReportManagementCTO />} />
        <Route path="/rewards" element={<Rewards/>} />        
        <Route path="/inventory-managemnt" element={<InventoryManagement/>} />
        <Route path="/inventory-list" element={<InventoryList/>} />
        <Route path="/inventory-list/:Id" element={<InventoryList/>} />
        <Route path="/inventory-new-category" element={<Inventorynewcategory/>} /> 
        <Route path= "/payrolelist" element={<PayRollList/>} />
        <Route path= "/result" element={<Result/>}/>
        <Route path= "/view-slab/:id" element={<SlabView/>} />
        <Route path= "/add-slab" element={<AddSlab/>} />
        <Route path= "/edit-slab/:id" element={<AddSlab/>} />
        <Route path= "/payrolesalarydetail" element={<Payrolesalarydetail/>} />
        <Route path="/inventory-new-category/:categoryId" element={<Inventorynewcategory/>} />
        <Route path="/inventory-new-category-upload" element={<Inventorynewcategoryupload/>} />
        <Route path="/inventory-edititem/:itemid/:catId" element={<Inventoryadditems/>} />
        <Route path="/inventory-additem" element={<Inventoryadditems/>} />
        <Route path="/inventory-viewitem/:itemid" element={<InventoryViewItem/>} />
        <Route path="/inventory-returndevice" element={<Inventoryreturndevice/>} />
        <Route path="/Issue-device" element={<IssueDevice/>} />
        <Route path="/inventory-add-user" element={<InventoryMyAssets/>} />
        <Route path="/my-assets" element={<InventoryMyAssets1/>} />
        <Route path="/help-desk" element={<HelpDesk/>} />
        <Route path="/virtual-office" element={<VirtualOffice/>} />
        <Route path="/lead-portal" element={<LeadPortal/>} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/inventory-managemnt" element={<InventoryManagement />} />
        <Route path="/inventory-list" element={<InventoryList />} />
        <Route path="/inventory-list/:Id" element={<InventoryList />} />
        <Route path="/inventory-new-category" element={<Inventorynewcategory />} />
        <Route path="/payrolelist" element={<PayRollList />} />
        <Route path="/result" element={<Result />} />
        <Route path="/view-slab/:id" element={<SlabView />} />
        <Route path="/add-slab" element={<AddSlab />} />
        <Route path="/edit-slab/:id" element={<AddSlab />} />
        <Route path="/payrolesalarydetail" element={<Payrolesalarydetail />} />
        <Route path="/inventory-new-category/:categoryId" element={<Inventorynewcategory />} />
        <Route path="/inventory-new-category-upload" element={<Inventorynewcategoryupload />} />
        <Route path="/inventory-edititem/:itemid/:catId" element={<Inventoryadditems />} />
        <Route path="/inventory-additem" element={<Inventoryadditems />} />
        <Route path="/inventory-viewitem/:itemid" element={<InventoryViewItem />} />
        <Route path="/inventory-returndevice" element={<Inventoryreturndevice />} />
        <Route path="/Issue-device" element={<IssueDevice />} />
        <Route path="/inventory-add-user" element={<InventoryMyAssets />} />
        <Route path="/my-assets" element={<InventoryMyAssets1 />} />
        <Route path="/help-desk" element={<HelpDesk />} />
        <Route path="/virtual-office" element={<VirtualOffice />} />
        <Route path="/lead-portal" element={<LeadPortal />} />
        <Route path="/inventory-report" element={<InventoryReport />} />
        {/* <Route path="/ProjectDetailReport" element={<ProjectDetailReport />} /> */}
        {/* <Route path="/inventoryCategoriesList/:catName" element={<InventoryManagement/>} /> */}
        <Route path="/users/edit-user/:id" element={<AddUser />} />
        {/* <Route path="/ProjectDetailReport" element={<ProjectDetailReport />} /> */}   
        <Route path="/skillset-report" element={<SkillSetReport />} />
        <Route path="/CeoDashboard" element={<CeoDashboard />} />
       


        <Route path="/helpdesk-report" element={<HelpDeskreport />} />
        <Route path="/helpdesk-reportView/:id" element={<HelpDeskReportView />} />

        <Route path="/hr-wishing" element={<HrWishing/>} />
        <Route path="/hr-wishing/:id" element={<HrWishing/>} />
        <Route path="/polling-system" element={<PollingSystem/>} />
        <Route path="/polling-systemUser" element={<PollingSystemUser/>} />
        <Route path="/galaImg" element={<GalaEventImg/>} />
        <Route path="/nolist" element={<NoVoteList/>} />
        <Route path="/SecretSantaParticipate" element={<SecretSantaParticipate/>} />
        <Route path="/upload-banner" element={<BannerUpload/>} />
        <Route path="/project-revenue-report" element={<ProjectRevenueReport/>} />
        <Route path="/project-monthly-revenue-report" element={<ProjectMonthlyRevenueReport/>} />
        <Route path="/miletone-schedulevariance" element={<ScheduleVarrianceReport/>} />
        <Route path="/ChessParticipants" element={<ChessParticipants/>} />
        <Route path="/ChessBoard" element={<ChessMatches/>} />
        <Route path="/leakageReport" element={<DefectLikageReport/>} />
        <Route path="/clientReview" element={<ClientReview/>} />
        <Route path="/ResourceBillingReport" element={<ResourceBillingReport/>} />
        <Route path="/ClientFeedbackReport" element={<ClientFeedbackReport/>} />
        <Route path="/EmployeeFeedback" element={<EmployeeFeedback/>} />
        <Route path="/seasia-cafeteria" element={<Seasiacafeteria/>} />
        <Route path="/EffortVarianceReport" element={<EffortVarianceReportNew/>} />
        <Route path="/ResourceReports" element={<ResourceReports/>} />
        <Route path="/UserFeedbackReport" element={<UserFeedbackReport/>}/>
        <Route path="/WildLifePhotoGraphy" element={<WildLifePhotoGraphy/>}/>
        <Route path="/WildLifeParticipantsList" element={<WildLifeParticipantsList/>}/>
        <Route path="/SprintReport" element={<SprintReport/>}/>        
        <Route path="/trackerPortalReport" element={<TrackerAndPortalReport/>}/>
        <Route path="/kraReport" element={<KraReport/>}/>
        <Route path="/addKRA/:id" element={<AddKra />} />
        <Route path="/KRARating/:id" element={<RateKra />} />
        <Route path="/clientDashboard" element={<ClientDashboard />} />
        <Route path="/mymeetings" element={<MyMeetings />} />
        <Route path="/mymeetings/:tabId" element={<MyMeetings />} />
        <Route path="/meetingsView/:id/:tabId" element={<MeetingsView />} />
        <Route path="/KRA-View" element={<KraData/>}/>
        <Route path="/milestoneDateExtend" element={<MilestoneDateExtend />} />
        <Route path="/qaReports" element={<QaReports/>}/>
        <Route path="/userTimeSpend" element={<UserConsumedHours/>}/>
        <Route path="/ExceededMilestoneReport" element={<NewMilestoneReport/>}/>
        <Route path="/EmployeeTaskReport" element={<EmployeeTaskReport/>}/>
         <Route path="/project-portfolio" element={<ProjectPortfolio />} />
        <Route path="/portfolio-view/:pId" element={<PortfolioView />} />
        <Route path="/portfolio-edit/:projId" element={<PortfolioEdit />} />
        <Route path="/add-portfolio" element={<PortfolioEdit />} />
        <Route path="*" element={<NotFound NotfoundText="Oops, no screen found according to this url."/>} />
        <Route path="/learning-session" element={<LearningSession />} />
        <Route path="/projectArtifacts" element={<ProjectArtifact />} />
        <Route path="/project-process/:pId" element={<ProjectDocument />} />
        <Route path="/BrownieReports" element={<BrownieReports />} />
        <Route path="/Domain-portfolio" element={<DomainPortfolio />} />
        <Route path="/Add-Domain-portfolio" element={<AddDomainPortfolio />} />
        <Route path="/Edit-Domain-portfolio/:domainId" element={<AddDomainPortfolio />} />
        <Route path="/View-Domain-portfolio/:domainId" element={<DomainPortfolioView />} />
      </Route>
    </Route>
  </Routes>
);

export default MainRoutes;
