import * as React from 'react';
import { Typography } from "@mui/material";
import { Box, Breadcrumbs, Divider, Modal, Grid, Button, TablePagination } from "@mui/material";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as Icons from "../assests/icons/icons";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import theme from "../theme/theme";





const resources = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
];

const month = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
];

export default function Rewards() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <Paper
            elevation={0}
            sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99, background: 'white', height: 'auto' }}
        >
            <Box>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: 1.5 }}>

                    <Grid item xs={12} lg={2}>
                        <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                            Rewards
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} spacing={2}>
                            <Box>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={resources}
                                    sx={{ width: 200, }}
                                    renderInput={(params) => <TextField {...params} label="All Resources" sx={{
                                        "& .css-9l522a-MuiFormLabel-root-MuiInputLabel-root": {
                                            color: theme.palette.primary.main
                                        }
                                    }} />}
                                />
                            </Box>
                            <Box>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={month}
                                    sx={{ width: 200 }}
                                    renderInput={(params) => <TextField {...params} label="Select Month"
                                    sx={{
                                        "& .css-9l522a-MuiFormLabel-root-MuiInputLabel-root": {
                                            color: theme.palette.primary.main
                                        }
                                    }}  />}
                                />
                            </Box>

                            <Box>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{ ml: 0.5 }}
                                >
                                    Request Brownie Points
                                </Button>
                            </Box>
                        </Stack>

                    </Grid>

                </Grid>
            </Box>
            <Paper
                elevation={0}
                sx={{ p: 2, my: 2, background: '#F0F9FF', height: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item sm={6} md={3}>
                        <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                            <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icons.Peopleicon />
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography>
                                    Total Recourses
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }}>
                                    08
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                            <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icons.Moneyconverticon />
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography>
                                    Monthly Allotment
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }}>
                                    1000
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                            <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icons.Totalpointsicon />
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography>
                                    Total assigned points
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }}>
                                    500
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                            <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icons.Balancepointsicon />
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography>
                                    Balance Points
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }}>
                                    08
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
            <Stack direction={'row'} justifyContent={'end'}>
                <Button
                    color="primary"
                    variant="contained"
                    sx={{ ml: 0.5 }}
                >
                    Assign Points
                </Button>
            </Stack>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Alloted Points" value="1" />
                            <Tab label="Recommendation Request" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box>
                            <TableContainer component={Paper} elevation={0}>
                                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="left">Employee</TableCell>
                                            <TableCell align="left">Points Assigned</TableCell>
                                            <TableCell align="left">Points Value(Rs)</TableCell>
                                            <TableCell align="left"> Refered By</TableCell>
                                            <TableCell align="center">Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                07/11/2022
                                            </TableCell>
                                            <TableCell align="left">  Richard</TableCell>
                                            <TableCell align="left">20</TableCell>
                                            <TableCell align="left">200 </TableCell>
                                            <TableCell align="left"> Raju</TableCell>
                                            <TableCell align="center">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={month}
                                                    sx={{ width: '120px', mx:'auto' }}
                                                    renderInput={(params) => <TextField {...params} label="Approve" />}
                                                /></TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box>
                            <TableContainer component={Paper} elevation={0}>
                                <Table sx={{ minWidth: 700,  
                                }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="left">Employee</TableCell>
                                            <TableCell align="left">Points Assigned</TableCell>
                                            <TableCell align="left">Points Value(Rs)</TableCell>
                                            <TableCell align="left"> Refered By</TableCell>
                                            <TableCell align="center">Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                                        >
                                            <TableCell component="th" scope="row">
                                                07/11/2022
                                            </TableCell>
                                            <TableCell align="left">  Richard</TableCell>
                                            <TableCell align="left">20</TableCell>
                                            <TableCell align="left">200 </TableCell>
                                            <TableCell align="left"> Raju</TableCell>
                                            <TableCell align="center">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={month}
                                                    sx={{ width: '120px', mx:'auto' }}
                                                    renderInput={(params) => <TextField {...params} label="Approve" />}
                                                /></TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Paper >

    )
}