import * as React from "react";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import { Paper, Box, DialogTitle, DialogContent, Dialog, DialogActions, Tooltip, Autocomplete } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import CreateIcon from '@mui/icons-material/Create';
import { childIssueServices } from "../../service/ChildIssue-service";
import { toast } from "material-react-toastify";
import { useEffect } from "react";
import CustomField from "./CustomField";
import { id } from "date-fns/locale";
import EditIcon from '@mui/icons-material/Edit';
import { masterDataService } from "../../service/master-service/master-data-service";
import AppConstants from "../../config/AppConstants";
import { commonFunctions } from "../../service/common-services/common-functions";
import { issueManagementService } from "../../service/issues-management-service";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const styles : any = {
    customField:{
      " & .MuiSelect-select":{
        paddingTop: "8px",
        paddingBottom: "8px",}
    },
    Inprogress: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main
    },
    Hold: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main
    },
    Review: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.main
    },
    Resolved: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.warning.main
    },
    QA: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main
    },
    Blocked:  {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main
    },
    Closed: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main
    },
    valuetext: {
        "&:hover > button": {
            visibility: "visible",
        }
    },
    reporter: {
        width: 30,
        height: 30,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}

const ItemTicket = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "14px",

 // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
}));



export default function ChildIssue(props: any,) {
    const { childFieldVisible, setChildFieldVisible, ticketId,
         childIssueDetails, projectId, ticketData, refreshData, projectWiseAssigneeUsers} = props;
         const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
    const [childissueList, setchildissueList] = React.useState<any>(childIssueDetails);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [buttonDisable, setButtonDisable] = React.useState(false);
    const [addChildName, setAddChildName] = React.useState("");
    const [users, setUsers] =  React.useState<any>(projectWiseAssigneeUsers);
    useEffect(() => {
      
        let arr = [...childIssueDetails ?? "" ?? null];
        arr.forEach(function (value) {
            if(value){
                value.isEdit = false;
                value.isNameFocused = false;
            }
        });
        setchildissueList(arr);
    }, [childIssueDetails]);

    const deleteLinkIssueData = async (id: any,) => {
        setOpenDelete(true);
        setCurrentId(id);
    };

    const handleEdit = (id: any) => {
        let arr = [...childissueList];
        arr.forEach(function (value) {
            value.isEdit = false;
        });
        arr.find((a: any) => a.id === id).isEdit = true;
        setchildissueList(arr);
    };
    const save = (val: any) => {
        let arr = [...childissueList];
        arr.forEach(function (value) {
            value.isEdit = false;
        });
        setchildissueList(arr);
        editChildDetails(val);
    };
    const close = () => {
        let arr = [...childissueList];
        arr.forEach(function (value) {
            value.isEdit = false;
        });
        setchildissueList(arr);
    };


    const handleEditUserName = (id: any) => {
        let arr = [...childissueList];
        arr.forEach(function (value) {
            value.isNameFocused = false;
        });
        arr.find((a: any) => a.id === id).isNameFocused = true;
        setchildissueList(arr);
    };
    const saveUsername = (tcktId: any) => {
        let arr = [...childissueList];
        arr.forEach(function (value) {
            value.isNameFocused = false;
        });
        setchildissueList(arr);
    };

    

    const getChildIssueDetails = async () => {
        if(refreshData){
            refreshData(ticketData);

        }
    }

    const createChildIssue = async () => {
        var input = {
            childIssueSummary: addChildName,
            ticketId: ticketId,
            projectId: projectId,
            isChildIssue:true,
            issueType:"Child",
        };
        await childIssueServices.createChildIssue(input)
            .then(async (response: any) => {
                if (response.data.isError) {

                }
                else {
                    setChildFieldVisible(false);
                    toast.success("Child Issue Added successfully");
                    setAddChildName("");
                    ticketData.isAdded = true;
                    refreshData(ticketData);
                }
            });
    }

    const editChildDetails = async (val: any) => {
        try {
            await issueManagementService.UpdateSummary(val).then((response: any) => {
                if (response.data.isError) {
                } else toast.success("Child Issue  Updated successfully");
                getChildIssueDetails();
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

   
    const deleteChildIssue = async () => {
        setButtonDisable(true);
        try {
            await childIssueServices
                .deleteChildIssue(currentId,ticketId)
                .then((response: any) => {
                    if (response.data.isError) {
                    } else {
                         setOpenDelete(false);
                        toast.success("Issue Unlinked successfully.",{
                             //  onOpen: () => setButtonDisable(true),
                              onClose: () => setButtonDisable(false),
                         });
                        getChildIssueDetails();
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setButtonDisable(false);
            });
        }
    };
    const handleStatusChange = async (status: any, TicketId:any) => {
        var req = {
            ticketId: TicketId,
            status: status,
        };
        try{
        await issueManagementService.UpdateIssueStatus(req).then((response: any) => {
            if (response.data.Error) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                getChildIssueDetails();
            }
        });
    } catch (ex: any) {
        ex.data.Error.map((err: any, idx: any) => {
            toast.warning(err);
        });
    }
    };

      const handleUserChange = (TicketId:any,id: any) => {
        var req = {
           ticketId: TicketId,
          userId: id,
        };
        issueManagementService.UpdateResourceId(req).then((response: any) => {
          if (response.data.isError) {
            toast.error("Something Went Wrong, Please try again.");
          } else {
            getChildIssueDetails();
          }
        });
    };


    return (
        <>
            <Typography variant="h6" color={theme.palette.primary.dark} sx={{ fontSize: "1rem" }}>Child issues</Typography>


            {childFieldVisible != true && (
                 <Tooltip title="Add Child Issue" arrow>
                <IconButton color="primary" onClick={() => { setChildFieldVisible(true) }}><AddIcon /></IconButton>
                </Tooltip>
                )}

            { childissueList!=null && childissueList?.map((issue: any, index: any) => (
                  <Paper component='div' variant="outlined" sx={{  mb:0.5, padding:"4px 12px 4px 12px"}}>
                   
                <Stack direction='row' alignItems='center' >
               <Box component={Link} 
               to={`/issue-detail/${issue?.id}`} 
               target="_blank"
               sx={{ textDecoration: "none", color: theme.palette.primary.main }}>
               <ItemTicket sx={{ mr: 4 }} >
                                <Icons.copy />&nbsp;<span>{issue?.ticketNumber}</span>
                            </ItemTicket>
                            </Box>
                            <Typography component='p' sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
                                {!issue?.isEdit ? (
                                           <Stack alignItems='center' direction='row' component='div' sx={styles.valuetext} > 
                                        <Typography component={Link} 
                                            to={`/issue-detail/${issue?.id}`} 
                                            target="_blank"
                                            sx={{ textDecoration: "none", color: theme.palette.primary.main }}> {issue?.summary}</Typography>
                                        <IconButton onClick={() => { handleEdit(issue?.id);}}
                                            sx={{ fontSize: "16px", ml: 1, visibility: 'hidden' }}><EditIcon sx={{ fontSize: "16px" }} /></IconButton>
                                    </Stack>
                                ) : (
                                    <CustomField
                                        defaultValue={issue?.summary}
                                        saveText={save}
                                        cancelEdit={close}
                                        ticketId={issue?.id}
                                    />
                                )}
                            </Typography>
              
                            

                        <Box sx={{ flexGrow: 1 }} component="span"></Box>
                          <Box sx={{mx:1}}>
                            {!issue?.isNameFocused ?(
                            <Tooltip title={issue?.assignUsername}>
                                <Avatar
                                    {...commonFunctions.stringAvatar(issue?.assignUsername)}
                                    sx={[styles.reporter]}
                                    src={`${process.env.REACT_APP_SERVER_URL}${issue?.assignUsername}`}
                                    onClick={() => { handleEditUserName(issue?.id);}}
                                /></Tooltip>
                                ): (
                                <Autocomplete
                                value={
                                  users?.find(
                                    (option: any) => option.id == issue?.userId
                                  ) ?? null
                                }
                                fullWidth
                                id="selectedAssigneId"
                                options={users}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                  
                                    handleUserChange(issue?.id, value?.id ?? "");
                                }}
                                componentsProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: 'flip',
                                          enabled: true,
                                          options: {
                                            altBoundary: true,
                                            flipVariations: true,
                                            altAxis: true,
                                            padding: 8,
                                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    autoFocus
                                    id="selectedAssigneId"
                                    variant="outlined"
                                    size="small"
                                    name="IssueAssigneeId"
                                    sx={{ width: '20ch'}}
                                    value={issue?.assignUsername}
                                    onBlur={saveUsername}
                                  />
                                )}
                            />)}
                        </Box>

                        <Box>
                                <FormControl sx={{ m: 0, minWidth: 72, p:0, }} size="small"  >
                               <Select
                                    name="status"
                                    value={issue?.status ?? "Sprint Backlog"}
                                    onChange={handleStatusChange}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                    // sx={[styles.customField, styles.Inprogress]}
                                    sx={[styles?.customField,styles[`${issue?.status}`]]}
                              
                                >
                                    {AppConstants.MasterData.Issue.IssueStatus.map(
                                        (status: any, idx: any) => (
                                            <MenuItem key={"status_" + idx} value={status.label}
                                             onClick={()=>{ handleStatusChange(status?.label ?? "", issue?.id );}}>
                                                {status.label}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            </Box>

                            {_permission.action_Issue_Delete_Access == true && ( <Tooltip title="Unlink Issue">
                                <IconButton onClick={(e) => {
                                    deleteLinkIssueData(issue?.id,);
                                }} sx={{ p: 0, ml: 1, fontSize: 16 }}><CloseIcon sx={{fontSize: "1rem"}}/></IconButton></Tooltip>
                            )}
                        </Stack>
            
                        
                </Paper>
            ))}

            {childFieldVisible != false && (<Stack sx={{ mt: 2 }}>
                <TextField id="outlined-basicr" placeholder="What needs to be done?" variant="outlined" fullWidth value={addChildName}
                    onChange={(e) => {
                        let value = e.currentTarget.value
                        value = value.replace(/\s{2,}$/g, "").trimStart()
                        setAddChildName(value)
                    }} />
                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 1 }}>
                    <Button variant="contained" size="small" sx={{ height: 30 }} disabled={!addChildName} onClick={() => { createChildIssue() }}>Create</Button>
                    <Button variant="outlined" size="small" sx={{ height: 30 }} onClick={() => { setChildFieldVisible(false); setAddChildName(""); }}>Cancel</Button>
                </Stack></Stack>
            )}

            <Dialog
                open={openDelete}
                onClose={setOpenDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                    Delete
                </DialogTitle>
                <DialogContent sx={{ pt: 1 }}>
                    Are you sure you want to unlink this Ticket {childIssueDetails?.ticketNumber}{" "}
                    {childIssueDetails?.issueType}
                </DialogContent>
                <DialogActions
                    sx={{
                        p: 3,
                        pt: 0,
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button variant="outlined" onClick={() => {
                        setOpenDelete(false);
                    }}>
                        No
                    </Button>
                    <Button variant="contained"disabled={buttonDisable} onClick={deleteChildIssue} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )

}