
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Seasiacafeteria() {
    debugger
    const navigate = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    useEffect(() => {
      const url = `${process.env.REACT_APP_Seasia_Cafeteria}?token=${_authUser?.token}`; 
      window.open(url, '_blank');
      navigate("/dashboard");
    }, []);
    return null;
  }