import { http } from "../http-common";
class BrowniePointsManagerServices {
    GetAllEmployeeReward = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/GetAllEmployeeReward", data);
    };
    GetAllRecommendRewards = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/GetAllRecommendRewards", data);
    };
    getManagerName = async (data: any): Promise<any> => {
        return await http.post<any>(
            `/v1/Master/ManagerName/GetByDepartmentId?departmentId=` + data
        );
    };
    RequestRewardsPointHR = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/RequestRewardsPointHR", data);
    };
    AddRequestRewardPoint = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/AddRequestRewardPoint", data);
    };
    ApproveRecomendation = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/ApproveRecomendation", data);
    };
    GetAllEmployeeDetailRewardPoints = async (data: any): Promise<any> => {
        return await http.get<any>("/v1/BrownieReason/GetAllEmployeeDetailRewardPoints?UserId="+data);
    };
    GetAllList = async (data: any): Promise<any> => {
        return await http.get<any>("/v1/BrownieReason/GetAllEmployeeDetailRewardPoints?UserId="+data);
    };
}
export const browniePointsManagerServices = new BrowniePointsManagerServices();