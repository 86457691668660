import React from 'react';
import ItemPaper from '../../components/styledComponent/ItemPaper';
import AddUser from '../../assests/images/AddUser.svg'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';


export default function NoUser() {
    return (
        <>
            <ItemPaper elevation={0} sx={{height: "calc(100vh - 100px)"}}>
             
                <Stack spacing={2} justifyContent="center" alignItems='center' sx={{height: '100%'}}>
                    <Box sx={{width: '200px', textAlign: 'center'}}>
                    <img src={AddUser}  />
                    </Box>
                    <Button variant="contained" component={Link} to='/users/add-user'  startIcon={<AddIcon />}>Add User</Button>
                </Stack>
            </ItemPaper>
        </>
    );
}