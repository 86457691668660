import React, { useState } from "react";
import clsx from "clsx";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/material";
function CheckDefaultIcon() {
  return (
    <span style={{ marginLeft: "5px", color: "green", cursor: "pointer" }}>
      &#10004;
    </span>
  );
}

function CloseDefaultIcon() {
  return (
    <span style={{ marginLeft: "5px", color: "red", cursor: "pointer" }}>
      &#10008;
    </span>
  );
}

export default function CustomField(props: any) {
  const {
    defaultValue,
    saveText,
    cancelEdit,
    disabled,
    ticketId,
    width,
    className,
    checkIcon = <CheckDefaultIcon />,
    closeIcon = <CloseDefaultIcon />,
    customStyles = {
      border: "1px solid black",
      borderRadius: "0.2rem",
      padding: "0.4rem"
    },
    ...others
  } = props;
  const [value, setValue] = useState(defaultValue);

  const updateText = () => {
    let obj = {
      summary: value,
      ticketId: ticketId,
    }
    saveText(obj);
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems='center'>

      <TextField id="outlined-basic" variant="outlined"
        style={{ width: width?width:"" }}
        onChange={(e) => {
          let value = e.currentTarget.value
          value = value.replace(/\s{2,}$/g, "").trimStart()
          setValue(value);
        }}
        value={value}
        className={clsx(className)}
        size="small"
        InputProps={{
          inputProps: {
            style: {
              width: "100%"
            }
          }
        }}
      />
      <Stack direction="row" justifyContent="center" spacing={1} >
        {value&&(
        <Box onClick={updateText}>{checkIcon}</Box>)}
        <Box onClick={cancelEdit}>{closeIcon}</Box></Stack>
    </Stack>
  );
}
