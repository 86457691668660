import { http } from "./http-common";

class projectProcessServices {

  addProjectDocument = async (data : any): Promise<any> => {
    return await http.post<any>("/v1/Project/ProjectDocumentUpdate", data);
  }

  getProjectDocuments = async (id : any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetProjectDocumentsById?projectId=${id}`);
  }
  
}

export const projectProcessService = new projectProcessServices();
