import { data } from "../../pages/reports/attendancereport/AttendanceReportFilter";
import { IFiles } from "../../pages/users/userModel";
import { http } from "../http-common";

class InventoryService {
    

    getCategories = async (values: any): Promise<any> => {
        return await http.post<any>("/Inventory/GetAllCatogaries/GetAllCatogaries", values);
    };

    AddCategories = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/AddCategory/AddCategory", data);
    };

    getCategorybyId = async (id: any): Promise<any> => {
        return await http.get<any>("/Inventory/GetCategoryById/GetCategoryById?id=" + id);
    };
    getBrandCategorybId = async (id: any): Promise<any> => {
        return await http.get<any>("/Inventory/GetBrandByCategoryID/GetBrandByCategoryID?id="+ id);
    };
    deleteCategorybyId = async (id: any): Promise<any> => {
        return await http.delete<any>("/Inventory/DeleteCategoryById/DeleteCategoryById?id=" + id);
    };

    updateCategories = async (data: any): Promise<any> => {
        return await http.put<any>("/Inventory/UpdateCategory/UpdateCategory", data);
    };

    // getAllInventoryItems = async (): Promise<any> => {
    //     // debugger;
    //     return await http.get<any>("Inventory/GetAllInventoryItem/GetAll");
    // };

    deleteItembyId = async (id: any): Promise<any> => {
        return await http.delete<any>("/Inventory/DeleteItemById/DeleteById?id=" + id);
    };

    getOverviewListById= async (id:any): Promise<any> => {
        return await http.get<any>(`/Inventory/GetItemById/GetItemById?id=${id}`);
    };
    getMaintenanceDetailsId= async (id:any): Promise<any> => {
        return await http.get<any>(`Inventory/GetMaintenanceDetailsId/GetMaintenanceDetailsId?itemId=${id}`);
    };
    createmaintenance = async (values:any): Promise<any> => {
        return await http.post<any>("/Inventory/AddMaintenanceDetails/AddMaintenanceDetails",values);
    };
    getQR = async (id:any): Promise<any> => {
        return await http.get<any>(`/Inventory/GetDetailsByQRCode/GetDetailByQRCode?id=${id}`);
    };
    Updatemaintenance = async (depart: any): Promise<any> => {
        return await http.post<any>("/Inventory/UpdateMaintenanceDetails/UpdateMaintenanceDetails", depart);
    };
    getallmaintenance= async (): Promise<any> => {
        return await http.get<any>("/Inventory/GetAllMaintenanceDetails/GetAllMaintenanceDetails");
    };
  getEmployeeDetails = async (empId: any): Promise<any> => {
        return await http.post<any>("/Inventory/GetEmployeeDetailByEmpId/GetEmployeeDetailByEmpId", empId);
    }
    
    getAllInventoryItems = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/GetAllInventoryItem/GetAllInventoryItem", data);
    }
    
    assignDevice = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/AssignDevice/AssignDevice", data);
    };
    getHistoryInventory = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/GetHistoryInventory/GetHistoryInventory", data);
    };
    returnRequestOfAsset = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/ReturnDevice/ReturnDevice", data);
    };
    UploadCategoryImage = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/UploadProjectImage/UploadInventoryImage", data);
    }
    addInventoryItem = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/AddInventoryItem/AddInventoryItem", data);
    }
    getDeviceHistory = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/DeviceHistory/DeviceHistory", data);
    }
    Updateitem = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/UpdateItem/UpdateItem", data);
    };
    uploadMultipleImages= async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/UploadMultipleInventoryImages/UploadMultipleInventoryImages", data);
    };
    getAssignedDevicedetailByEmpId = async (id:any): Promise<any> => {
        return await http.get<any>(`/Inventory/GetAssignDevicesListByEmpId/GetAssignDevicesListByEmpId?id=${id}`);
    };
    getAllInventoryItemsForreport = async (data: any): Promise<any> => {
        return await http.post<any>("/Inventory/GetAllItemByDepartmentIdAndUserId/GetAllItemByDepartmentIdAndUserId", data);
    }
    
    
}

export const inventoryService = new InventoryService();