import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { CircularProgress, TableCell, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Chip, { ChipProps } from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import StepsRoles from "./StepsRoles";
import * as Icons from "../../assests/icons/icons";
import TablePagination from "@mui/material/TablePagination";
import NotFound from "../../components/styledComponent/NotFound";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SearchBar from "../../components/styledComponent/SearchBar";
import Notes from "../../components/styledComponent/Note";
import { roleManagementServive } from "../../service/role-management-service";
import StyledTableRow from "@mui/material/TableRow";
import { toast } from 'material-react-toastify';
import { masterDataService } from '../../service/master-service/master-data-service';
import { AppConstants } from '../../config/AppConstants'
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import RoleView from "../role/RoleView";
import theme from "../../theme/theme";
import { useSelector } from "react-redux";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import Roletop from "../role/Rolestyle";
import InfoPopover from "../../components/CommonInfoComponent";

// const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
//   color: theme.palette.success.dark,
//   backgroundColor: theme.palette.success.light,
//   fontWeight: 400,
//   fontSize: 14,
// }));


interface Role {
  id: string;
  name: string;
  module: string;
  srNo: string;
}
const LongText = (props: {
  content?: any;
  limit?: any;
}) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  if (props.content.length <= props.limit) {
    // there is nothing more to show
    return <div>{props.content}</div>
  }
  if (showAll) {
    // We show the extended text and a link to reduce it
    return <Box sx={{ whiteSpace: "break-spaces" }}>
      {props.content}
      <Tooltip title="Show Less">
        <Button variant="text" size="small" onClick={showLess}>less...</Button></Tooltip>
    </Box>
  }
  // In the final case, we show a text with ellipsis and a `Read more` button
  const toShow = props.content ? props.content.substring(0, props.limit) : "";
  return <Box sx={{ whiteSpace: "break-spaces", width: "200px" }}>
    {toShow}
    <Tooltip title="Show More">
      <Button variant="text" size="small" onClick={showMore}>more...</Button></Tooltip>
  </Box>
}

function createData(

  id: string, name: string, module: string, srNo: string): Role {
  return { name, id, module, srNo };
}

const initialvalues = {
  id: "",
  name: "",
  module: ""
};

export default function AllRole() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [users, setUsers] = useState<Role[]>([]);
  const [user, setUser] = useState(initialvalues);
  const navigate = useNavigate();
  const [toggleLoader, setToggleLoader] = React.useState(false);
  // const [page, setPage] = React.useState(2);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);


  var channelSearch = { id: "", name: "All Channel" };
  const [channelHook, setChannelHook] = useState(channelSearch);
  const [statusDataHook, setStatusDataHook] = useState("Active");
  const [searchValue, setSearchValue] = useState("");


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(users.length / rowsPerPage);

  
  const [actionAllowed, setAllowdAction] = React.useState<any>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
      navigate("/dashboard");
    }

    var allowedAction = {
      add: true,
      edit: true,
      view: true,
      delete: true,
    }
    setAllowdAction(allowedAction);
    // isValidUser();
    getRoleDetails();
  }, []);
  const getRoleDetails = async () => {
    setToggleLoader(true);
    await roleManagementServive.getRole().then((response: any) => {
      if(response?.data?.isError){setToggleLoader(false);}
      if (response?.data)
        setUsers(response?.data ? response?.data : []);
      setToggleLoader(false);
        console.log("Data from the API ", response?.data);

    });
  };
  const deleteRoleData = async (id: any) => {
    setOpen(true);
    setCurrentId(id);
  };

  const [open, setOpen] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);
  const DeleteRole = async () => {
    setOpen(false);
    await roleManagementServive.deleteRole(currentId).then((response: any) => {
      if (response.data.isError) {
      } else {
        toast.success("Role Deleted Successfully");

        getRoleDetails();
      }
    }).catch((error) => {
      if (error.data.Error.length > 0)
        toast.error(error.data.Error[0]);
      return error;
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  //////search functionality

  const getSearchDetails = async (newValue: string) => {
    let values = {
      department: channelHook.id,
      status: statusDataHook,
      search: newValue,
      take: 1000,
      skip: 0
    }
    await roleManagementServive.getSearch(values)
      .then((response: any) => {

        if (response.data.isError) {

        }
        else {
          setUsers(response?.data);
          setPage(0);
        }
      });
  }

  //Sorting

  const [dense, setDense] = React.useState(false);
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  type Order = 'asc' | 'desc';

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Role;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [

    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Roles',
    },


  ];


  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Role>('srNo');

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Role) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Role) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <>
      {toggleLoader && (<CircularProgress size={120} sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
          >
            Module
          </TableCell>
          <TableCell
          >
            Status
          </TableCell>
          <TableCell align-items="center" align="center"
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
      </>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }


  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Role,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const [noUser, setNoUser] = useState(false);


  ///////page change and dropdown programming over 



  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Role Management</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
       
        <Stack
          sx={Roletop.topbar}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
            Role Management
          </Typography>
          <InfoPopover  onInput={"Role Management"}/>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={Roletop.searchbar}>
            <SearchBar setSearchValue={setSearchValue} callBackFunction={getSearchDetails} />
          </Box>
          {/* {(actionAllowed.add && */}
          <Button
            variant="contained"
            size="large"
            component={Link}
            to="/role-management/add-new-role"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Role
          </Button>
          {/* )} */}

        </Stack>

        {/* <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" sx={{mb: 3 }}>
                <SearchBar />
                </Stack> */}

        <Divider />

        <TableContainer sx={{
          mt: 1, [theme.breakpoints.down('sm')]: {
            maxWidth: 320,
          },
        }}>
          <Table aria-label="simple table" size="small">

            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />


            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(users, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick1(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}

                    >
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">
                        <LongText content={row?.module ?? ""} limit={35} /></StyledTableCell>
                      <StyledTableCell align="left">

                        <Chip variant='approved' label='Active' color="default" />
                        {/* <Chip variant='review' label='Active' color="default" />
                      <Chip variant='submited' label='Active' color="default" />
                      <Chip variant='hold' label='Active' color="default" /> */}
                      </StyledTableCell>

                      {/* <StyledTableCell align="left"> */}
                      {/* {(() => {
                        
                        switch (row.status.toString()) {
                          case "1":
                            return  <StatusChip label={<span><Icons.CircleFilled /> Active</span>}/>;

                          case "0":
                            return  <StatusChip label={<span><Icons.CircleFilled /> In Active</span>}/>;
                          default:
                            return row.status
                        }
                      })()} */}


                      {/* </StyledTableCell> */}
                      <StyledTableCell align="left" width={100}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                          {/* {(actionAllowed.edit && */}
                          <Tooltip title="Edit">
                            <IconButton aria-label="edit" component={Link} to={`/editRoleManagementData/${row.id}`} size="medium">
                              <Icons.Edit />
                            </IconButton></Tooltip>
                          {/* )} */}
                          {/* {(actionAllowed.view &&
                                                                        <IconButton aria-label="view" component={Link} to={`/project/edit-project/${row.id}`}>
                                                                            <Icons.View />
                                                                        </IconButton>
                                                                    )} */}
                          {/* {(actionAllowed.delete && */}
                          <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => deleteRoleData(row.id)}>
                              <Icons.Delete />
                            </IconButton></Tooltip>
                          {/* )} */}
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>

              )}
            </TableBody>
          </Table>
          {users.length == 0 &&
            (<NotFound NotfoundText="No Result Found" />
            )}
        </TableContainer>

        <Divider sx={{ mt: 3 }} />

        <Stack >
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() => `Total: ${users?.length}  Page ${page + 1} of ${pageCount}`}

            
          />
        </Stack>
      </Paper>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Roletop.actionpopup}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Role
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              If the Role is assigned to any user, it cannot be deleted. If the
              Role is not assigned to any user, it will be deleted. Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={DeleteRole}>Confirm</Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}