import { http } from "./http-common";

class MilestoneDateExtendService {

    getMilestoneExtendDates = async (req: any): Promise<any> => {
        return await http.post<any>("v1/Milestone/GetAllMilestoneExtendDateRequests", req);
      };

      updateExtendMilestoneDate = async (req: any): Promise<any> => {
        return await http.post<any>("/v1/Milestone/UpdateStatusForExtendMileStoneDate", req);
      };
}
export const milestoneDateExtendService = new MilestoneDateExtendService();