import { combineReducers } from 'redux';
const Fill_Brownie_Permission = 'Fill_Brownie_Permission';
const Empty_Brownie_Permission = 'Empty_Brownie_Permission';

const defaultPermissions = 
  {
    access: false,
    module_BrowniePoint_Access:false,

  page_Reasons_Access:false,
  page_PayReward_Access:false,
  tab_BrownieReasonListing_Access:false,
  tab_PayRewardListing_Access:false,
  action_Reasons_Add_Access:false,
  action_Reasons_Edit_Access:false,
  action_Reasons_Delete_Access:false,

  page_Emplyoyee_Brownie_Access:false,
  page_Emplyoyee_Refer_Access:false,
  page_Manager_BrowniePoint_Access:false,
  page_Admin_Brownie_Point_Access:false,
  page_Reward_Request_Access:false,
  page_Reward_Setting_Access:false,
  }

export function FillBrowniePermission(module) {
  return {
    type: Fill_Brownie_Permission,
    module,
  }
}
export function EmptyBrowniePermission() {
  return {
    type: Empty_Brownie_Permission,defaultPermissions
  }
}

function BrowniePermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Brownie_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
              permissions.module_BrowniePoint_Access = true;
              if (data.listPageMaster && data.listPageMaster.length > 0) {
                data.listPageMaster.forEach((page) => {
                  if (page.pageName === "Reasons") {
                    permissions.page_Reasons_Access = true;
                    if (page.listTabMaster && page.listTabMaster.length > 0) {
                      page.listTabMaster.forEach((tabs) => {
                        if (tabs.tabName === "Brownie Reason Listing") {
                          permissions.tab_BrownieReasonListing_Access = true;
                          if (
                            tabs.listActionMaster &&
                            tabs.listActionMaster.length > 0
                          ) {
                            tabs.listActionMaster.forEach((actions) => {
                              if (actions.actionName === "Add") {
                                permissions.action_Reasons_Add_Access = true;
                              }
                              if (actions.actionName === "Edit") {
                                permissions.action_Reasons_Edit_Access = true;
                              }
                              if (actions.actionName === "Delete") {
                                permissions.action_Reasons_Delete_Access = true;
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                  if (page.pageName === "Employee Brownie") {
                    permissions.page_Emplyoyee_Brownie_Access = true;
                  }
                  if (page.pageName === "Refer Employee") {
                    permissions.page_Emplyoyee_Refer_Access = true;
                  }
                  if (page.pageName === "Manager Brownie Point") {
                    permissions.page_Manager_BrowniePoint_Access = true;
                  }
                  if (page.pageName === "Admin Brownie Point") {
                    permissions.page_Admin_Brownie_Point_Access = true;
                  }
                  if (page.pageName === "Reward Request") {
                    permissions.page_Reward_Request_Access = true;
                  }
                  if (page.pageName === "Reward Setting") {
                    permissions.page_Reward_Setting_Access = true;
                  }
                
                  if (page.pageName === "Pay Reward") {
                    permissions.page_PayReward_Access = true;
                    if (page.listTabMaster && page.listTabMaster.length > 0) {
                      page.listTabMaster.forEach((tabs) => {
                        if (tabs.tabName === "Pay Reward Listing") {
                          permissions.tab_PayRewardListing_Access = true;
                        }
                      });
                    }
                  }
                });
              }
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_Brownie_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const BrowniePermissionApp = combineReducers({
    BrowniePermission
});

export default BrowniePermissionApp;