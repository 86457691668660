// src/NotFound.tsx
import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../assests/images/hand-drawn-404-error.png';
// import './NotFound.css'; // Optional: For custom styles

const FileNotFound: React.FC = () => {
  return (
    <div className="not-found">
         <Box
        component="img"
        src={image}
        alt="Page Not Found"
        sx={{
          height: "auto",
          maxWidth: "400px",
          display: "flex",
          cursor: 'pointer',
          transition: 'all 0.3s ease-in-out',
          marginTop: 0,
          textAlign: "center",
          marginBottom: 0,
          margin: "0 auto",
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      />
      <h1 style={{textAlign: "center"}}>404 Page Not Found</h1>
      <p style={{textAlign: "center"}}>Oops! The page you're looking for doesn't exist.</p>
      {/* <Link to="/" style={{textAlign: "center", width: "100%", display: "inline-block", marginBottom: "40px"}}>Go To Dashboard </Link> */}
    </div>
  );
};

export default FileNotFound;
