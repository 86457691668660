import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams, } from "react-router-dom";
import Box from '@mui/material/Box';
import theme from "../../../theme/theme";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { reportService } from "../../../service/Reports/reports";
import {CircularProgress, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import { eTypeOfUser } from "../../../Enum/eTypeOfUser";
import { toast } from "material-react-toastify";
import HrReporttop from "../HrReportsstyle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";



interface AttendanceReport {
    employeeName: any;
    totalWorkingHours: any;
    totalWorkingDays:any
    actualHours: any;
    idealTime:any;
    totalHours:any;
    totalOfflineHours: any;
    lstAttandanceDetails: [
        {
            userId: any,
            workingDay: any,
            workingHours: any,
            actualHours: any,
            idealTime: any,
            totalHours:any,
            total:any,
            loginTime:any,
            logoutTime:any,
            offlineHours: any,
            status:any,
            compOffFor:any
        }
    ]
}



export default function HrMonthlyReport() {
    const navigation = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [reports, setreports] = React.useState<AttendanceReport>()
    const { id ,type,month,year} = useParams();

    let today = dayjs();
    const [fromDate, setfromDate] = React.useState<Dayjs | null>(setDateWithoutTime(today));
    const [toDate, settoDate] = React.useState<Dayjs | null>(setIncreaseDateWithoutTime(today));
    const [toggleLoader, setToggleLoader] = useState(false);
   const getCurrentMonth = () => {
    const currentMonth = new Date().getMonth() + 1; 
    return currentMonth;
    };

    const [monthStatus, setMonthStatus] = useState<number | undefined>(getCurrentMonth());
    const options = [
        // {
        //   value: 0,
        //   label: "All Months",
        // },
        {
          value: 1,
          label: "January",
        },
        {
          value: 2,
          label: "February",
        },
        {
          value: 3,
          label: "March",
        },
        {
          value: 4,
          label: "April",
        },
        {
          value: 5,
          label: "May",
        },
        {
          value: 6,
          label: "June",
        },
        {
          value: 7,
          label: "July",
        },
        {
          value: 8,
          label: "August",
        },
        {
          value: 9,
          label: "September",
        },
        {
          value: 10,
          label: "October",
        },
        {
          value: 11,
          label: "November",
        },
        {
          value: 12,
          label: "December",
        },
      ];

    const handleMonthChangeTracker = async (event: React.ChangeEvent<{ value: unknown }>) => {
        debugger;
        const selectedMonthStatus = event.target.value as number;
        await setMonthStatus(selectedMonthStatus);
        await getReport(selectedMonthStatus);
    };

    const handleMonthChangePortal = async (event: React.ChangeEvent<{ value: unknown }>) => {
        debugger;
        const selectedMonthStatus = event.target.value as number;
        await setMonthStatus(selectedMonthStatus);
      
        console.log(selectedMonthStatus,selectedMonthStatus,"selectedMonthStatus")
        await getReport(selectedMonthStatus);
    };
    

    function setDateWithoutTime(fdate: any) {
        return dayjs(fdate.format('YYYY-MM-DD'));
    }
    function setIncreaseDateWithoutTime(fdate: any) {
        let date: any = fdate.format('YYYY-MM-DD');
        let tdate: any = dayjs(date).add(1, 'day')
        return tdate;
    }
    const setdatePeriod = (fdate: any) => {
        let date: any = fdate.format('YYYY-MM-DD');
        setfromDate(dayjs(date));
        let tdate: any = dayjs(date).add(1, 'day')
        settoDate(tdate);
    }

    const getDate = (dateObject: Date) => {
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00Z`;
    };

    const convertTotalHours = (totalHours: any, status:any) => {
        if (status ==="On Leave" || status === "On Holiday") {
            return '';
        }
        else if(status==="Absent" || !totalHours){
         return "#EB445A";
        }
        else{
        if (!totalHours || typeof totalHours !== 'string' || totalHours===null) {
            return '';
        }
        const [hours, minutes] = totalHours.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) {
            return '';
        }
        const totalInMinutes = hours * 60 + minutes;
        let abc = totalInMinutes >= 360 && totalInMinutes < 480 ? '#FF8C00'  // Orange( if value is less than 8 shortleave)
        : totalInMinutes < 240 ? '#EB445A' // Red(If value is less then 4 hrs  Absent)
        :totalInMinutes >= 240 && totalInMinutes < 360 ?"#ffc107" // Yellow(If value is greater then 6 hrs and less then 8 hrs shortleave) 
        : totalInMinutes >= 480 ? '#2DD36F' : "";// Green Present
        return abc;}
    };

    const convertTotalHoursPortal = (totalHours: any, status: any) => {
        if (status === "On Leave" || status === "On Holiday") {
            return '';
        } else if (status === "Absent" || !totalHours) {
            return "#EB445A";
        } else {
            if (!totalHours || typeof totalHours !== 'string' || totalHours === null) {
                return '';
            }
    
            // Check if the totalHours is only in hours format (e.g., "13")
            if (totalHours.length <= 2) {
                const hours = parseInt(totalHours, 10);
                if (isNaN(hours)) {
                    return '';
                }
                const totalInMinutes = hours * 60;
                let abc = totalInMinutes >= 360 && totalInMinutes < 480 ? "#FF8C00" // Orange( if value is less than 8 shortleave)
        : totalInMinutes < 240 ? '#EB445A' // Red(If value is less then 4 hrs  Absent)
        :totalInMinutes >= 240 && totalInMinutes < 360 ?'#ffc107' // Yellow(If value is greater then 4 hrs and less then 6 hrs halfday) 
        : totalInMinutes >= 480 ? '#2DD36F' : "";// Green Present
                return abc;
            }
    
            const [hours, minutes] = totalHours.split(':').map(Number);
            if (isNaN(hours) || isNaN(minutes)) {
                return '';
            }
            const totalInMinutes = hours * 60 + minutes;
            let abc = totalInMinutes >= 360 && totalInMinutes < 480 ? "#FF8C00" // Orange( if value is less than 8 shortleave)
            : totalInMinutes < 240 ? '#EB445A' // Red(If value is less then 4 hrs  Absent)
            :totalInMinutes >= 240 && totalInMinutes < 360 ?'#ffc107' // Yellow(If value is greater then 4 hrs and less then 6 hrs halfday) 
            : totalInMinutes >= 480 ? '#2DD36F' : "";// Green Present
            return abc;
        }
    };

    // const getReport = async (data: any) => {
    //     try{
    //         setToggleLoader(true);
    //         // var monthNumber = new Date(date).getMonth() + 1;
    //         var hrReportFilter = {
    //             monthNumber:  monthStatus !== undefined ? monthStatus : 0,
    //             resourceId: id,
    //             loginType: type==="1"?1:2
    //         };
    //         await reportService.GetResourceWiseMontlyReport(hrReportFilter)
    //             .then((response: any) => {
    //                 if (response.data.isError) {
                       
    //                     setToggleLoader(false);
    //                 }
    //                 else {
    //                     setreports(response?.data);
    //                     setToggleLoader(false);
    //                 }
    //             });
    //     }
    //     catch (ex: any) {
    //         ex.data.Error.map((err: any) => {
    //             toast.warning(err);
    //             setToggleLoader(false);
    //         });
    //     }

    // }


    const getReport = async (selectedMonthStatus: number) => {
        try {
            setToggleLoader(true);
    
            var hrReportFilter = {
                monthNumber: selectedMonthStatus !== undefined ? selectedMonthStatus : 0,
                resourceId: id,
                loginType: type === "1" ? 1 : 2,
                year:year
            };
    
            await reportService.GetResourceWiseMontlyReport(hrReportFilter)
                .then((response: any) => {
                    if (response.data.isError) {
                        setToggleLoader(false);
                    } else {
                        setreports(response?.data);
                        setToggleLoader(false);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    

  
    React.useEffect(() => {
        if (_permission.page_HrReport_Access !== true) {
            navigation('/dashboard');
        }
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                if (_permission.action_HrReport_ReportListing_AllDepartments_Access == false) {
                    // setdisabledDepartments(true);
                }
                (month!=null || month !==undefined)? getReport(parseInt(month)): getReport(monthStatus !== undefined ? monthStatus : 0);
            }
            else {
                (month!=null || month !==undefined)? getReport(parseInt(month)): getReport(monthStatus !== undefined ? monthStatus : 0);
            }
        };
        departmentsData();
        if(month){
           let abc = parseInt(month)  
        setMonthStatus(abc)}
      
    }, []);
    return (
        <>
          {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 1 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                     { type=== "2"?"Portal report":"Tracker Report"}
                    </Typography>
                </Stack>

                {type === '1' ? (
                    <Stack>
                        <Stack direction="row" justifyContent='space-between' alignItems="flex-end" spacing={2} sx={{ mt: 3 }} >
                           

                            <Box sx={{ width: 170, wordWrap: 'break-word', background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px"  }}>
                                <Typography variant="h6" sx={{fontSize: "16px"}}>
                                    {reports?.employeeName}
                                </Typography>
                            </Box>

                            <Box sx={{ width: 300, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px"  }}>
                                <Typography variant="h6"   sx={{fontSize: "16px"}}>
                                    Total Working Hours:{reports?.totalWorkingHours}
                                </Typography>
                            </Box>

                            <Box sx={{ width: 230, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px"  }}>
                                <Typography variant="h6"   sx={{fontSize: "16px"}}>
                                    Active Hours:{reports?.actualHours || 0}
                                </Typography>
                            </Box>

                            <Box sx={{ width: 250, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px"  }}>
                                <Typography variant="h6"   sx={{fontSize: "16px"}}>
                                    Total Offline Hours:{reports?.totalOfflineHours || 0}
                                </Typography>
                            </Box>
                            <Box>
    
                                <TextField
                                    sx={{ minWidth: 220 }}

                                    id="outlined-basic"
                                    select
                                    value={monthStatus}
                                    variant="outlined"
                                    onChange={ handleMonthChangeTracker}
  
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Stack>
                        <Box>
                            <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={1}
                            alignItems={{ xs: "flex-end", sm: "center" }}
                            justifyContent="flex-end"
                            sx={{ mt: 1, ml: 'auto' }}
                        >
                                 <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.success.main }}
                                />{" "}
                                Present
                            </Typography>
                                <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: '#FF8C00'}}
                                />{" "}
                                Short Leave
                            </Typography>
                       
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.warning.main }}
                                />{" "}
                                Half Day
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.error.main }}
                                />{" "}
                                Absent
                            </Typography>
                        </Stack>
                            </Box>
                        <Box sx={{ mt: 3 }}>
                            {reports && reports != null &&

                                <TableContainer component={Paper} elevation={0} sx={{
                                    mt: 0.5,
                                    maxHeight: "calc(100vh - 215px)",
                                    [theme.breakpoints.down("sm")]: {
                                        maxWidth: "400px",
                                    },
                                }}>
                                    <Table stickyHeader aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >Date</TableCell>
                                                <TableCell>Active Hours</TableCell>
                                                <TableCell>Offline Hours</TableCell>
                                                <TableCell>Total Hours</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reports?.lstAttandanceDetails?.map((row, index) => (
                                                <TableRow style={{ textDecoration: 'none' }}

                                                sx={{
                                                    backgroundColor: convertTotalHours(row.totalHours,row?.status),
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                  }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {`${moment(row?.workingDay).format("DD MMM, YYYY")}`}</TableCell>
                                                        <TableCell>{row?.actualHours ? row.actualHours !== "00" ? ` ${row.actualHours} hrs` : '--' : '--'}</TableCell>
                                                        <TableCell>{row?.offlineHours ? row.offlineHours !== "00" ? ` ${row.offlineHours} hrs` : '--' : '--'}</TableCell>
                                                        <TableCell>{row?.totalHours ? row.totalHours !== "00" ? ` ${row.totalHours} hrs` : '--' : '--'}</TableCell>
                                                        {row?.compOffFor ? (
                                                    <TableCell sx={{ cursor: "pointer" }}>
                                                          <Tooltip arrow  title={`Worked On ${moment(row.compOffFor).format("DD-MMM-YYYY")}`}>
                                                        <span>{row?.status ||"--"}</span>
                                                        </Tooltip>
                                                        </TableCell>
                                                   
                                            ) : (   <TableCell>{row?.status ||"--"}</TableCell>)}
                                                    {/* <TableCell>{row?.actualHours == null || row?.actualHours === "00" ? "" : ` ${row?.actualHours} hrs`}</TableCell>
                                                    <TableCell>{row?.offlineHours == null || row?.offlineHours === "00" ? "" : ` ${row?.offlineHours}`}</TableCell>
                                                    <TableCell>{row?.totalHours == null || row?.totalHours === "00" ? "" : ` ${row?.totalHours} hrs`}</TableCell> */}
                                                </TableRow>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Box>
                    </Stack>
                ) : (
                    <Stack>
                        <Stack direction="row" justifyContent='space-between' alignItems="flex-end" spacing={2} sx={{ mt: 3 }} >

                            <Box sx={{ width: 170, wordWrap: 'break-word', background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }} >
                                <Typography variant="h6"  sx={{fontSize: "16px"}}>
                                    {reports?.employeeName}
                                </Typography>
                            </Box>

                            <Box sx={{ width: 300 }}>
                                <Typography variant="h6"  sx={{fontSize: "16px", background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }}>
                                    Total Working Time:{reports?.totalWorkingHours}
                                </Typography>
                            </Box>

                            <Box sx={{ width: 230 }}>
                                <Typography variant="h6"  sx={{fontSize: "16px", background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }}>
                                    Total Time:{reports?.actualHours || 0}
                                </Typography>
                            </Box>


                            <Box>
                                <TextField
                                    sx={{ minWidth: 220 }}

                                    id="outlined-basic"
                                    select
                                    value={monthStatus}
                                    variant="outlined"
                                    onChange={                                     
                                        handleMonthChangePortal
                                    }
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Stack>
                        <Box>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={1}
                            alignItems={{ xs: "flex-end", sm: "center" }}
                            justifyContent="flex-end"
                            sx={{ mt: 1, ml: 'auto' }}
                        >
                               <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.success.main }}
                                />{" "}
                                Present
                            </Typography>
                                <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: '#FF8C00'}}
                                />{" "}
                                Short Leave
                            </Typography>
                         
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.warning.main }}
                                />{" "}
                                Half Day
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.error.main }}
                                />{" "}
                                Absent
                            </Typography>
                        </Stack>
                        </Box>
                       
                        <Box sx={{ mt: 3 }}>
                            {reports && reports != null &&

                                <TableContainer component={Paper} elevation={0} sx={{
                                    mt: 0.5,
                                    maxHeight: "calc(100vh - 215px)",
                                    [theme.breakpoints.down("sm")]: {
                                        maxWidth: "400px",
                                    },
                                }}>
                                    <Table stickyHeader aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >Date</TableCell>
                                                <TableCell>Login Time</TableCell>
                                                <TableCell>Logout Time</TableCell>
                                                <TableCell>Total Time</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reports?.lstAttandanceDetails?.map((row, index) => (
                                                <TableRow style={{ textDecoration: 'none' }}
                                                sx={{
                                                    backgroundColor: convertTotalHoursPortal(row.total,row?.status),
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                  }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                    {`${moment(row?.workingDay).format("DD MMM, YYYY")}`}</TableCell>              
                                             <TableCell>{row?.loginTime ? row.loginTime !== "00" ? ` ${row.loginTime}` : '--' : '--'}</TableCell>
                                             <TableCell>{row?.logoutTime ? row.logoutTime !== "00" ? ` ${row.logoutTime}` : '--' : '--'}</TableCell>
                                             <TableCell>{row?.total ? row.total !== "00" ? ` ${row.total} hrs` : '--' : '--'}</TableCell>
                                             {row?.compOffFor ? (
                                                    <TableCell sx={{ cursor: "pointer" }}>
                                                      <Tooltip arrow  title={`Worked On ${moment(row.compOffFor).format("DD-MMM-YYYY")}`}>
                                                        <span>{row?.status ||"--"}</span>
                                                        </Tooltip>
                                                        </TableCell>
                                                   
                                            ) : (   <TableCell>{row?.status ||"--"}</TableCell>)}
                                            
                                                 {/* <TableCell>{row?.logoutTime == null || row?.logoutTime === "00" ? "" : ` ${row?.logoutTime}`|| '--'}</TableCell> */}
                                                 {/* <TableCell>{row?.total == null || row?.total === "00" ? "" : ` ${row?.total} hrs`}</TableCell> */}
                                                </TableRow>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Box>
                    </Stack>
                )}
            </Paper>
        </>
    );
}