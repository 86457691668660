import { combineReducers } from "redux";
const Fill_Seasiagala_Permission = 'Fill_Seasiagala_Permission';
const Empty_Seasiagala_Permission = 'Empty_Seasiagala_Permission';

const defaultPermissions = {
    access: false,
    module_SeasiaGalaEvent_Access: false,
    page_WildLife_Access: false,
    page_Nominee_Setup_Access: false,
    page_Poll_Access: false,
    page_Poll_Result: false,
}
export function FillSeasiagalaPermission(module) {
    return {
        type: Fill_Seasiagala_Permission,
        module,
    }
}
export function EmptySeasiagalaPermission() {
    return {
        type: Empty_Seasiagala_Permission, defaultPermissions
    }
}

function SeasiagalaPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Seasiagala_Permission:
            var data = action.module;
            var permissions = defaultPermissions;
            // if (data){
            //   permissions.module_SeasiaGalaEvent_Access=true;

            //   if (page.pageName === "Nominee Setup") {
            //     permissions.page_Nominee_Setup_Access = true;
            //   }
            //   if (page.pageName === "Poll") {
            //     permissions.page_Poll_Access = true;
            //   }
            //   if (page.pageName === "Poll Result") {
            //     permissions.page_Poll_Access = true;
            //   }
            // }
            if (data) {
                permissions.module_SeasiaGalaEvent_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                    data.listPageMaster.forEach((page) => {
                        if (page.pageName === "Nominee Setup") {
                            permissions.page_Nominee_Setup_Access = true;
                        }
                        if (page.pageName === "Poll") {
                            permissions.page_Poll_Access = true;
                        }
                        if (page.pageName === "Poll Result") {
                            permissions.page_Poll_Result = true;
                        }
                        if (page.pageName === "Wildlife Photography") {
                            permissions.page_WildLife_Access = true;
                        }
                    });
                }
            }
            else {
                return state = defaultPermissions;
            }
        case Empty_Seasiagala_Permission:
            return state = defaultPermissions;

        default:
            return state;
    }
}
const SeasiagalaPermissionApp = combineReducers({
    SeasiagalaPermission
})
export default SeasiagalaPermissionApp;