import { http } from "../http-common";

class FeedbackService {
   
    addFeedback = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Feedback/AddFeedBackByUser", data);
    };

    getFeedbackById = async (id : any): Promise<any>  =>{
        return await http.get<any>(`/v1/Feedback/GetUserFeedbackById?id=${id}`)

    };

    getAllFeedbackReport = async (req : any): Promise<any> => {
      return await http.post<any>("/v1/Feedback/GetAllUserFeedbackReport", req)
    };
  
    getAllUserFeedback = async (req : any): Promise<any> => {
     return await http.post<any>("/v1/Feedback/GetAllUserFeedback", req)
    };
}

export const feedbackService = new FeedbackService();