import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import theme from "../../../theme/theme";
import SearchBar from "../../../components/styledComponent/SearchBar";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Grid, Stack, TablePagination, Typography } from "@mui/material";
import moment from 'moment';
import { masterreasonServices } from '../../../service/BrowniePoints/masterReason-services';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { apiUrl } from '../../../service/common-services/apiURL';
import { useSelector } from 'react-redux';
import NotFound from '../../../components/styledComponent/NotFound';

const styles = {
  filterSection: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 0.5,
    marginBottom: theme.spacing(2),
  },
  textPage: {
    fontWeight: 500,
    fontSize: " 24px",
    lineHeight: "27px",
    /* Primary Color/Primary_dark shade */
    color: theme.palette.primary.dark,
  },
  textProps: {
    "& .MuiListItemText-primary": {
      fontWeight: 450,
      fontSize: " 14px",
      lineHeight: "20px",
      color: theme.palette.primary.dark,
    },
    "& .MuiListItemText-secondary": {
      fontWeight: 550,
      fontSize: "15px",
      lineHeight: "20px",

      color: "#1DA7FF",
    },
  },
  metainfo: {
    marginBottom: theme.spacing(1),
    "& .MuiListItem-root": {
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiListItemText-root": {
      flexDirection: "column-reverse",
      display: "flex",
      flexBasis: "50%",
    },
  },
};

interface Brownie {
  addOnRequestId:string;
  pointType: string;
  allocatedPointDate : string;
  userId : string;
  employeeName:string
  brownieReasonId : string;
  pointAssigned : number;
  pointValueAmount : number;
  referedUserName  : string;
  rejectReason:string;
  status  : string;
  reasonName  : string;
}

interface Data {
  id: string;
  departmentName: string;
  managerName: string;
  resourcesCount: number;
  monthlyAllotment: number;
  month: string;
  addOnPoint: number;
  totalPoint: number;
  pointAssignedToTeam: number;
  srNo: string;
}
export default function AdminBrowniePointview() {
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { additionalVar, selectedMonth } = location.state || {};

  var   {  id, id1} = useParams();
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [resourceData, setresourceData] = React.useState<Brownie[]>([]);
  const pageCount = Math.ceil(resourceData.length / rowsPerPage);
  const [mgrDetails, setmgrDetails] = React.useState<Data>();
  const [profileImage, setProfileImage] = useState<any>(null);

  useEffect(() => {
    if (_permission.page_Admin_Brownie_Point_Access !== true) {
      navigate("/dashboard");
    }
     getAllMonthlyReward("");
  }, []);


  
  const getAllMonthlyReward = async (value:any) => {
    debugger
    let values = {
        managerId: id1,
        departmentId: id,
        month: selectedMonth,
        year: additionalVar,
        search:value,
     };
    await masterreasonServices
      .GetManagerDetailsByDepartmentId(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setmgrDetails(response?.data.managerDetails);
          setresourceData(response?.data?.resourcesList);
          if (response?.data?.managerDetails?.imagePath) {
            setProfileImage(
                apiUrl.getImageURL() +
                "/" +
                response?.data?.managerDetails?.imagePath
            );
        }
        }
      });
  };
  const [searchValue, setSearchValue] = useState("");

  const triggerSearch = async (newValue: string) => {
    getAllMonthlyReward(newValue);
    setPage(0);
  };

  // const total = resourceData.reduce((sum: any, item: any) => sum + item?.pointAssigned, 0);
  // const totalResources =  resourceData.length

  return (
    <>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box sx={[styles.filterSection]}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "40%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
          >
            <Typography sx={[styles.textPage]}>Brownie Points View</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "50%",
              justifyContent: "flex-end",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
          >
            <SearchBar setSearchValue={setSearchValue}
              callBackFunction={triggerSearch} />
          </Box>
        </Box>
        <Divider sx={{ mb: 0.1 }} />

        <Grid container>
          <Grid
            item
            xs
            md={4}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              [theme.breakpoints.down("sm")]: {
                borderRight: "none",
              },
            }}
          >
            <ListItem sx={{ p: 0, mb: 2, pt: 2 }}>
              <ListItemAvatar>
              <Avatar alt="Remy Sharp" src={profileImage}
              sx={{ mr: 1, width: 60, height: 60 }}
              />
              </ListItemAvatar>
              <ListItemText
                primary={mgrDetails?.managerName}
                secondary="Manager"
                sx={{ "& .MuiListItemText-primary": {
                  fontWeight: 500,
                  fontSize: " 16px",
                  lineHeight: "20px",
                  color: theme.palette.primary.dark,
                },
                "& .MuiListItemText-secondary": {
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
            
                  color: "#1DA7FF",
                },}}
              />
            </ListItem>

            <Stack direction="row" alignItems="flex-start" sx={[styles.metainfo]}>
              <ListItemText
                primary={mgrDetails?.departmentName}
                secondary="Department"
                sx={[styles.textProps]}
              />
              <ListItemText
                primary={mgrDetails?.resourcesCount}
                secondary="Resources"
                sx={[styles.textProps]}
              />
            </Stack>
            <Stack direction="row" alignItems="flex-start" sx={[styles.metainfo]}>
              <ListItemText
                primary={mgrDetails?.monthlyAllotment}
                secondary="Monthly Allotment"
                sx={[styles.textProps]}
              />
              <ListItemText
                primary={mgrDetails?.month}
                secondary="Month "
                sx={[styles.textProps]}
              />
            </Stack>
            <Stack direction="row" alignItems="flex-start" sx={[styles.metainfo]}>
              <ListItemText
                primary={mgrDetails?.addOnPoint}
                secondary="Add-On Points"
                sx={[styles.textProps]}
              />
              <ListItemText
                primary={mgrDetails?.totalPoint}
                secondary="Total Points Allotted "
                sx={[styles.textProps]}
              />
            </Stack>
            <Stack direction="row" alignItems="flex-start" sx={[styles.metainfo]}>
              <ListItemText
                primary={mgrDetails?.pointAssignedToTeam}
                secondary="Points Assigined to team"
                sx={[styles.textProps]}
              />
            </Stack>
          </Grid>
          <Grid item xs md={8}>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Resources</TableCell>
                    <TableCell align="right">Month</TableCell>
                    {/* <TableCell align="right">Point Type</TableCell> */}
                    <TableCell align="right">Points Earned</TableCell>
                    <TableCell align="right">Value</TableCell>
                    <TableCell align="right">Referred By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {resourceData.length > 0 &&
                      resourceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.employeeName}
                      </TableCell>
                      <TableCell align="right">{`${moment(row.allocatedPointDate).format("DD-MMM-YYYY")}`}</TableCell>
                      {/* <TableCell align="right">{row?.pointType}</TableCell> */}
                      <TableCell align="right">{row?.pointAssigned}</TableCell>
                      <TableCell align="right">{row?.pointValueAmount}</TableCell>
                      <TableCell align="right">{row?.referedUserName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

             
                    
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={resourceData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                labelDisplayedRows={() => `Total: ${resourceData?.length}  Page ${page + 1} of ${pageCount}`}
              />
            </TableContainer>
           
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
