import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, Typography, Autocomplete, TextField, CircularProgress } from '@mui/material';
import * as Icons from "../../assests/icons/icons";
import { feedbackService } from '../../service/Feedback/EmployeeFeedbackService';
import { toast } from 'material-react-toastify';
import { clsDepartmentList } from '../../service/common-services/department';
import HrReporttop from './HrReportsstyle';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { eTypeOfUser } from '../../Enum/eTypeOfUser';



interface Department {
    id: string,
    name: string
}

export default function UserFeedbackReport() {


    const [userFeedbackReport, setUserFeedbackReport] = useState<any>([]);
    const [departmentList, setDepartmentList] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [filterSelected, setFilterSelected] = useState(false);
    const [selectedYear, setSelectedYear] = React.useState<any>();
    const [toggleLoader, setToggleLoader] = useState(false);
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector(
        (state: any) => state.ReportPermissionApp.ReportPermission
    );
    const navigate = useNavigate();
    const MonthsName = (item: number): string => {
        switch (item) {
            case 1:
                return "January";
            case 2:
                return "Febuary";
            case 3:
                return "March";
            case 4:
                return "April";
            case 5:
                return "May";
            case 6:
                return "June";
            case 7:
                return "July";
            case 8:
                return "August";
            case 9:
                return "September";
            case 10:
                return "October";
            case 11:
                return "November";
            case 12:
                return "December";
            default:
                return "Invalid month";
        }
    }
    useEffect(() => {
        if (_permission.page_UserFeedbackReport_Access !== true) {
            navigate("/dashboard");
        }
        const getDepartmentDropdown = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                setSelectedDepartment(_authUser.employeeInfo.departmentId);               
                setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
                setSelectedYear(currentYear);
                getAllFeedbackReport(_authUser.employeeInfo.departmentId, currentYear);
            }
            else {
                setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());               
                setSelectedDepartment("");
                setSelectedYear(currentYear);
                getAllFeedbackReport('', currentYear);
            }
        };      
        getDepartmentDropdown();
       // getAllFeedbackReport('', currentYear);
    }, [])

    const getDepartmentDropdown = async () => {
        setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
    }

    const getAllFeedbackReport = async (departmentId: any, year: any) => {
        let values = {
            departmentId: departmentId,
            year: year
        }
        setToggleLoader(true);
        try {
            await feedbackService.getAllFeedbackReport(values).then(async (response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong...")
                    setToggleLoader(false);
                }
                else {

                    const filteredData = response?.data.filter((name: any) => {
                        return name.month !== null && name.month !== "";
                    });
                    const sortedMonths = filteredData.sort((a: any, b: any) => {
                        if (a.month < b.month) {
                            return -1;
                        }
                        if (a.month > b.month) {
                            return 1;
                        }
                        return 0;
                    });

                    console.log("sortedMonths ==", sortedMonths)

                    setUserFeedbackReport(sortedMonths);

                    // setUserFeedbackReport(response?.data ? response?.data : [])
                    setToggleLoader(false);
                }
            });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }

    };

    const handleDepartmentSelection = async (event: any, value: any) => {
        setFilterSelected(true);
        setSelectedDepartment(value?.id ?? "");
        await getAllFeedbackReport(value?.id ?? "", selectedYear);
    }

    let currentYear = new Date().getFullYear();
    var currentYearString = currentYear;
    const Years = [];
    for (let i = 0; i < 4; i++) {
        Years.push({
            id: currentYear - i,
            label: (currentYear - i)
        });
    }

    return (
        <>
            {toggleLoader && (< CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, pb: 0 }}>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
                                User Feedback Report</Typography>
                            <Stack direction="row" justifyContent="right" paddingTop={0} paddingBottom={3} spacing={3}>
                                <Autocomplete
                                    value={departmentList?.find(
                                        (option) => option?.id === selectedDepartment
                                    ) ?? null}
                                    disabled={_authUser.typeOfUser == eTypeOfUser.Manager}
                                    id="Departments"
                                    options={departmentList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDepartmentSelection}
                                    sx={{ width: 230 }}
                                    renderInput={(params) => <TextField {...params} value={selectedDepartment} />}
                                />
                                <Autocomplete
                                    value={Years?.find((option) => option?.id === selectedYear) ?? null}
                                    id="Years"
                                    options={Years}
                                    getOptionLabel={(option: any) => option.label}
                                    onChange={async (event, value) => {
                                        setSelectedYear(value?.label ?? currentYearString);
                                        setFilterSelected(true);
                                        await getAllFeedbackReport(selectedDepartment, value?.label ?? currentYearString);
                                    }

                                    }
                                    sx={{ width: 150 }}
                                    renderInput={(params) => <TextField {...params} value={selectedYear} />}
                                />

                            </Stack>

                        </Grid>
                        <TableContainer component={Paper} sx={{ maxHeight: "300px", height: "100%", overflowY: "auto", background: "transparent", boxShadow: "none" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ position: "sticky", top: "0" }}>
                                    <TableRow>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Sr. No</TableCell>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Month</TableCell>
                                        {[...Array(8)].map((_, index) => (
                                            <TableCell sx={{ background: "rgb(245, 245, 245)" }} key={index}>Question {index + 1}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody >

                                    {userFeedbackReport?.length > 0 ? (
                                        userFeedbackReport?.map((item: any, index: any) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{MonthsName(item.month)}</TableCell>
                                                {[...Array(8)].map((_, questionIndex) => {
                                                    const rating = item.ratingEnum?.find((ratingItem: any) => ratingItem?.userFeedback === questionIndex + 1);
                                                    return (
                                                        <TableCell key={questionIndex}>
                                                            <Box sx={{ backgroundColor: '#E7FFF0', borderRadius: '5px', padding: '5px 10px', width: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                                                {rating ? `${parseFloat(rating.averageRating).toFixed(1)}`
                                                                    : '0'}
                                                                <Icons.StarFilled />
                                                                {/* {rating ? <Icons.StarFilled /> : null} */}
                                                            </Box>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={10} align="center">
                                                No records found
                                            </TableCell>
                                        </TableRow>
                                    )}


                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                </Box>
            </Paper>
        </>

    );
}
