import { http } from "../http-common";
class ReportService {
  
  getReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Reports/GetProjectReport`, data
    );
  };
  GetDailyAttendance = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Reports/GetDailyAttendance`, data
    );
  };
  GetAttendanceReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Reports/GetAttendanceReport`, data
    );
  };
  GetUserAttendance = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Reports/GetUserAttendance`, data
    );
  };
  GetReportWorkLogsByTaskId = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Reports/GetReportWorkLogsByTaskId`, data
    );
  };
  getTrackerScreenshotReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `v1/Reports/TrackerScreenshot`,data
    );
  };
  getDepartmentWiseHrReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `v1/Reports/GetDepartmentWiseMontlyReport`,data
    );
  };
  getResourcesByDepartment = async (data: any): Promise<any> => {
  
    return await http.post<any>(
      `/UserManagement/GetResourcesbyDepartmentId?departmentId=${data}`
    );
  };
  GetResourceWiseMontlyReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `v1/Reports/GetResourceWiseMontlyReport`,data
    );
  };
  getDownloadCSVReport = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/DownloadProjectReport`, data
    );
  };
  getUserSpecificAttendance = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/GetUserAttendanceList`, data
    );
  };
  addAttendanceRequest = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/UserRequestForAttendance`, data
    );
  };
  allUsersAttendanceRequestData = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/GetUserListOfAttendanceRequest`, data
    );
  };
  approveAndRejectUsersAttendance = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/ApproveAndRejectUserAttendance`, data
    );
  };
  getResourcesByDepartmentOrType = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/GerResourcesByTypeAndDepartment`, data
    );
  };

  getWildLifePhotographyReport = async (data: any): Promise<any> => {
    return await http.post<any>(`/UserManagement/GetImagesLinkForNewEvent/GetImagesLinkForNewEvent`, data
    );
  };

 addWildLifePhotography = async (data: any): Promise<any> => {
    return await http.post<any>(`/UserManagement/AddImageLinkForNewEvent/AddImageLinkForNewEvent`, data
    );
  };
  getPortalAndTrackerReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `v1/Reports/PortalAndTrackerAttendanceReport`,data
    );
  };
  getMilestoneData = async (id: any) : Promise<any> => {
    return await http.post<any>(`/v1/Milestone/GetAllMilestoneWithoutSprint?projectId=${id}`);
  }

  getEmployeesProjectDailyReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `v1/Reports/GetResourceMappingReport`,data
    );
  };
  updateResourceDataSchedular = async (data: any) : Promise<any> => {
    return await http.post<any>("/UserManagement/UpdateTrackerDataforAttendence/UpdateTrackerDataforAttendence",data);
  }
}
export const reportService = new ReportService();