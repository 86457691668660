import React, { useState } from 'react'
import Box from '@mui/material/Box';
import ReactApexChart from 'react-apexcharts';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { toast } from 'material-react-toastify';
import { reportmanagementCTO } from '../service/reportManagementCto';
import * as Icons from "../assests/icons/icons";
import { CircularProgress, IconButton, Stack } from '@mui/material';
import { useSelector } from "react-redux";
import { milestoneTreeService } from '../service/Reports/milestonereportTree';
import { inventoryService } from '../service/Inventory/inventoryService';
import { projectGrowthRevenueService } from '../service/ProjectRevenueGrowth/projectRevenueService';
import { reserveServices } from '../service/Reserve-services';
import { helpDeskReport } from '../service/hepldeskreport-service';
const styles = {
    wrapper: {
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
        padding: '15px',
        borderBottom: "1px solid #C2C2C2",
        display: "flex",
        gap: "9px"
    },
    wrapperBottom: {
        borderTopLeftRadius: ' 0',
        borderTopRightRadius: ' 0',
        borderBottomRightRadius: "16px",
        borderBottomLeftRadius: "16px",
        padding: '15px',
        marginBottom: "16px "
    },
    cardTitle: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        color: "#202020"
    },
    valueText: {
        fontSize: " 24px",
        fontWeight: 700,
        color: "#1DA7FF"

    }
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h6,
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: " 0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
}));
const ItemBoxWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: "10px",
    rowGap: "10px",
    marginTop: "10px"
}));
const ItemBox = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    fontWeight: 600,
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: " 0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    textAlign: 'center',
    flexGrow: '1',
    flexBasis: "48%"
}));
const ItemBoxLeads = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    fontWeight: 600,
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: " 0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    textAlign: 'center',
    flexGrow: '1',
    flexBasis: "48%",
    borderBottom: "4px solid "
}));

export default function CeoDashboard() {
    const baseUrl =  window.location.protocol==="https:"?process.env.REACT_APP_FE_Secure_URL :window.location.hostname === "localhost"?window.location.origin : process.env.REACT_APP_FE_URL;
    const [toggleLoader, setToggleLoader] = useState(false);
    const [reportHook, setReportHook] = React.useState<any>();
    const [leadLoading, setLeadLoading] = React.useState(false);
    const [attendanceloading, setAttendanceloading] = React.useState(false);
    const [projectMatricesLoading, setProjectMatricesLoading] = React.useState(false);
    const [resourceloading, setResourceloading] = React.useState(false);
    const [defectCount, setDefectCount] = useState<any>();
    const [scheduleVariance, setScheduleVariance] = useState<any>([]);
    const [effortVariance, setEffortVariance] = useState<any>([]);
    const [assignedList, setassignedList] = React.useState<any>("");
    const [inStockList, setinStockList] = React.useState<any>("");
    const [projectRevenueMonthGrowth, setProjectRevenueMonthGrowth] = useState<any>([]);
    const [reports, setReports] = React.useState<any>([]);
    const [helpDeskReportCount, setHelpDeskReportCount] = useState<any>([]);
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const url = `https://stgphys.appsndevs.com/leadsportal/prodackerPerDayLeads/?token=${_authUser?.token}`;
    const optionsForDailyReport: any = {
        series: [reports?.allDepartmentBillingResourcesCount || 0,
        reports?.allDepartmentNonBillingResourcesCount || 0],
        labels: ['Client Project', 'In-House/Bench'],
        colors: ['#2DD36F', '#FFC409'], // Change the color values
        chart: {
            width: 300,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            offsetY: 10,
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 220,
                            label: "Resource Occupency",
                            color: '#040404',
                            formatter: function (w: any) {
                                return '';
                            }
                        }
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'bottom',
            offsetY: 10,
            formatter: function (val: any, opts: any) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex]
            }
        },
        dataLabels: {
            style: {
                colors: ['#000000'] // Set data label color to black
            }
        },
    };

    const optionsForWeekelyReport: any = {
        series: [reportHook?.weeklyReportOutputModel?.hardwareRequisition?.pending || 0,
        reportHook?.weeklyReportOutputModel?.hardwareRequisition?.provided || 0],
        labels: ['Pending', 'Approved'],
        colors: ['#2DD36F', '#FFC409'],
        chart: {
            width: 300,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            offsetY: 10,
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: '11px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 220,
                            label: "Total Request",
                            color: '#040404',
                        }
                    }
                }
            }
        },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex]
            },
            style: {
                colors: ['#000000'] // Set data label color to black
            }
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'bottom',
            offsetY: 10,
            formatter: function (val: any, opts: any) {
                return val + "  " + opts.w.globals.series[opts.seriesIndex]
            }
        },
    };
    // const optionsForMonthlyReport: any = {
    //     series: [assignedList || 0, inStockList || 0],
    //     labels: ['Assigned', 'In-Stock'],
    //     colors: ['#1DA7FF', '#8B9193'],
    //     chart: {
    //         width: 300,
    //         type: 'donut',
    //     },
    //     plotOptions: {
    //         pie: {
    //             donut: {
    //                 labels: {
    //                     show: true,
    //                     name: {
    //                         offsetY: 10,
    //                     },
    //                     total: {
    //                         show: true,
    //                         showAlways: true,
    //                         fontSize: '11px',
    //                         fontFamily: 'Helvetica, Arial, sans-serif',
    //                         fontWeight: 220,
    //                         label: "Inventory Status",
    //                         color: '#040404',
    //                     }
    //                 }
    //             }
    //         }
    //     },
    //     dataLabels: {
    //         formatter: function (val: any, opts: any) {
    //             return opts.w.config.series[opts.seriesIndex]
    //         },
    //         style: {
    //             colors: ['#000000'] // Set data label color to black
    //         }
    //     },
    //     fill: {
    //         type: 'gradient',
    //     },
    //     legend: {
    //         position: 'bottom',
    //         offsetY: 10,
    //         formatter: function (val: any, opts: any) {
    //             return val + "  " + opts.w.globals.series[opts.seriesIndex]
    //         }
    //     },
    // };




    const optionsForMonthlyReportBarChart: any = {
        series: [{
            name: 'Bounce Rate',
            data: reportHook?.monthlyReportOutputModel?.websiteBounceRate?.map((item: any) => item?.bounceRate)
        }],
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            },
            events: {
                click: function () {
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        yaxis: {
            title: {
                text: 'Bounce Rate'
            }
        },
        xaxis: {
            categories: reportHook?.monthlyReportOutputModel?.websiteBounceRate?.map((item: any) => item?.months),
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
    };

    const optionsForMonthlyReportMultipleBarChart: any = {
        series: [{
            name: 'Seasia',
            data: [
                  6470,6553,6360,5323,5134,4928,5681,6473,14783,13589,4780,4488
            ]
        }, {
            name: 'MoogleLabs',
            data: [
                 824,1633,1353,1125,1325,1116,1171,1147,1342,1092,1058,1056
            ]
        },
        {
            name: 'BugRaptors',
            data: [
               3915,3769,4446,3614,3566,2342,3242,2305,2966,2583,2137,1921
            ]
        }],
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [
             'Jan-24',"Feb-24","Mar-24","April-24","May-24","June-24","July-24","Aug-24","Sep-24","Oct-24","Nov-24","Dec-24"
            ],
            // title:{
            //     text:'Months'
            // }
        },
        yaxis: {
            title: {
                text: 'Visitors',
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return val
                }
            }
        },
    };

    const optionsForMonthlyReport: any = {
        series: [{
            name: 'Assigned',
            data: [357, 78, 139, 28, 29]
        }, {
            name: 'In-Stock',
            data: [17, 40, 124, 21, 15]
        }],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
        },
        xaxis: {
            categories: ['Laptops', 'Mac Mini', 'Desktop', 'Android Devices', 'Iphone Devices']
        },
        fill: {
            opacity: 1
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        yaxis: {
            min: 0, // start y-axis from 20
            tickAmount: 10,
            title: {
                text: 'Items Count',
            }

        },
    };


    React.useEffect(() => {
        setToggleLoader(true);
        const fetchData = async () => {
            try {
                await Promise.all([
                    getReports(false, false, false),
                    getDefectLeakageCount(),
                    getScheduleVarianceDetails(),
                    getEffortVarianceReport(),
                    getAllInventoryItems(),
                    getProjectRevenueMonthReport(),
                    getResourceBillingReport(),
                ]);
                setToggleLoader(false);
                getHelpDeskCounts();
            } catch (error) {
                console.error('Error fetching data:', error);
                setToggleLoader(false);
            }
        };

        fetchData();
    }, []);

    const getReports = async (isAttandanceReport: boolean, isLeadReport: boolean, isResourceOccupancy: boolean) => {
        let abc = {
            isAttandanceReport: isAttandanceReport,
            isLeadReport: isLeadReport,
            isResourceOccupancy: isResourceOccupancy
        }
        try {
            const response = await reportmanagementCTO.getCeoDashboardReport(abc);
            if (response.data.isError) {
                toast.error("Something went wrong...");
                setLeadLoading(false);
            } else {
                setReportHook(response?.data)
                setLeadLoading(false);
                setAttendanceloading(false);
                setResourceloading(false);
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setLeadLoading(false);
            });
        }
    };
    const projectMatricesReportsScheduler = async () => {
        try {
            const response = await reportmanagementCTO.matricesScheduler();
            if (response.data.isError) {
                toast.error("Something went wrong...");
                setProjectMatricesLoading(false)
            } else {
                setProjectMatricesLoading(false)
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setProjectMatricesLoading(false)
            });
        }
    };
    const getDefectLeakageCount = async () => {
        let values = {
            projectId: "",
            departmentId: "",
            status: 0,
            duration: 1
        }
        try {
            await milestoneTreeService
                .geLeakageReport(values)
                .then(async (response: any) => {
                    if (response.data.type === "application/json") {
                        const data = await response.data.text();
                        toast.warning(JSON.parse(data).listErrors[0]);
                    } else {
                        setDefectCount(response?.data)
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const getScheduleVarianceDetails = async () => {
        let values = {
            departmentId: "",
            duration: 1,
            mileStoneStatus: 0,
            projectStatus: 0,
        };
        try {
            await milestoneTreeService
                .getScheduleVarianceReport(values)
                .then(async (response: any) => {
                    if (response.data.type === "application/json") {
                        const data = await response.data.text();
                        toast.warning(JSON.parse(data).listErrors[0]);

                    } else {
                        setScheduleVariance(response?.data)
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const getEffortVarianceReport = async () => {
        let values = {
            departmentId: "",
            duration: 1,
            status: 0,
            projectId: "",
        };
        try {
            await milestoneTreeService
                .getEffortVarianceReport(values)
                .then(async (response: any) => {
                    if (response.data.type === "application/json") {
                        const data = await response.data.text();
                        toast.warning(JSON.parse(data).listErrors[0]);
                    } else {
                        setEffortVariance(response?.data)
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const getAllInventoryItems = async () => {
        try {
            let input = {
                categoryId: "",
                brand: [],
                setConfiguration: [],
            }
            await inventoryService
                .getAllInventoryItems(input).then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        const inStockItems = response?.data?.filter((item: any) => item?.status == 1)?.length;
                        const assignedItems = response?.data?.filter((item: any) => item?.status == 2 || item.status == 3)?.length;
                        setinStockList(inStockItems ? inStockItems : 0);
                        setassignedList(assignedItems ? assignedItems : 0);
                        console.log(inStockItems, "inStockList");
                        console.log(assignedItems, "assignedList");
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth();

    const getProjectRevenueMonthReport = async () => {
        let values ={
            "year": currentYear,
            "month": currentMonth,
            "departmentId": "",
            "projectId": "",
            "paymentStatus": 0
        }
        setToggleLoader(true);
        try {
            await projectGrowthRevenueService.getProjectRevenueGrowth(values).then(async (response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong...");
                    setToggleLoader(false);
                } else {
                    setProjectRevenueMonthGrowth(response?.data ? response?.data : []);
                    setToggleLoader(false);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };
    const getResourceBillingReport = async () => {
        try {
            await reserveServices
                .getBillingResourceReport("")
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setReports(response?.data ? response?.data[0] : []);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };
    const getHelpDeskCounts = async () => {
        let abc = {
            field: "status",
            start: Date.now(),
            end: Date.now()
        }
        try {
            await helpDeskReport
                .getHelpDeskCount(abc)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setHelpDeskReportCount(response?.data ? response.data : []);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };
    console.log(helpDeskReportCount, "helpDeskReportCounthelpDeskReportCount")
    return (
        <>
            {toggleLoader && (<CircularProgress size={120} sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
            <>
                <Paper elevation={0} sx={[styles.wrapper]}>
                    <Typography variant="h6" sx={[styles.cardTitle]}>
                        Daily Report
                    </Typography>
                </Paper>
                <Paper elevation={0} sx={[styles.wrapperBottom]}>
                    <Grid container spacing={2}>
                        <Grid item sm={3} >
                            <Item sx={{ height: "100%" }} >
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box onClick={() => { window.open(baseUrl + "/daily-attendance-report", "_blank") }} style={{ cursor: "pointer" }}><Icons.Attendance /> Attendance</Box> {!attendanceloading ? (
                                        <IconButton onClick={(e) => { getReports(true, false, false); setAttendanceloading(true); }}> <Icons.RefreshBlue /></IconButton>
                                    ) : (
                                        <CircularProgress />)}
                                </Box>
                                <ItemBoxWrapper elevation={0} onClick={() => { window.open(baseUrl + "/daily-attendance-report", "_blank") }} style={{ cursor: "pointer" }}>
                                    <ItemBox>
                                        Total
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.dailyReportOutputModel?.attandanceDetails?.total || "0"}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox>
                                        Present
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.dailyReportOutputModel?.attandanceDetails?.present || "0"}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox>
                                        Absent
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.dailyReportOutputModel?.attandanceDetails?.absent || "0"}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox>
                                        On Leave
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.dailyReportOutputModel?.attandanceDetails?.onLeave || "0"}
                                        </Box>
                                    </ItemBox>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>

                        <Grid item sm={3}>
                            <Item sx={{ height: "100%" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box onClick={() => { window.open(baseUrl + "/ResourceReports", "_blank") }} style={{ cursor: "pointer", justifyContent: "center" }}>
                                        <Icons.Resources /> Resource Occupancy</Box>
                                    {!resourceloading ? (
                                        <IconButton onClick={(e) => { getReports(false, false, true); setResourceloading(true); }}> <Icons.RefreshBlue /></IconButton>
                                    ) : (
                                        <CircularProgress />)}
                                </Box>

                                <ItemBoxWrapper elevation={0} style={{ cursor: "pointer", justifyContent: "center" }} onClick={() => { window.open(baseUrl + "/ResourceReports", "_blank"); }}>
                                    <ReactApexChart options={optionsForDailyReport} series={optionsForDailyReport?.series} type="donut" width={285} />
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>

                        <Grid item sm={3}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }} onClick={() => { window.open(baseUrl + "/helpdesk-report", "_blank") }}>
                                <Icons.HelpDesk />  HelpDesk
                                <ItemBoxWrapper elevation={0}>
                                    <ItemBox>
                                        New
                                        <Box sx={styles.valueText}>
                                            {helpDeskReportCount?.filter((a: any) => a.term === "ToDo")?.length > 0 ? (
                                                helpDeskReportCount?.filter((a: any) => a.term === "ToDo")?.map((filteredItem: any) => (
                                                    <div>{filteredItem.doc_Count}</div>
                                                ))
                                            ) : (
                                                <div>--</div>
                                            )}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox>
                                        Pending
                                        <Box sx={styles.valueText}>
                                            {helpDeskReportCount?.filter((a: any) => a.term === "Verification Pending")?.length > 0 ? (
                                                helpDeskReportCount?.filter((a: any) => a.term === "Verification Pending")?.map((filteredItem: any) => (
                                                    <div>{filteredItem.doc_Count}</div>
                                                ))
                                            ) : (
                                                <div>--</div>
                                            )}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox>
                                        Resolved
                                        <Box sx={styles.valueText}>
                                            {helpDeskReportCount?.filter((a: any) => a.term === "Resolved")?.length > 0 ? (
                                                helpDeskReportCount?.filter((a: any) => a.term === "Resolved")?.map((filteredItem: any) => (
                                                    <div>{filteredItem.doc_Count}</div>
                                                ))
                                            ) : (
                                                <div>--</div>
                                            )}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox>
                                        Escalated
                                        <Box sx={styles.valueText}>
                                            {helpDeskReportCount?.filter((a: any) => a.term === "Escalated")?.length > 0 ? (
                                                helpDeskReportCount?.filter((a: any) => a.term === "Escalated")?.map((filteredItem: any) => (
                                                    <div>{filteredItem.doc_Count}</div>
                                                ))
                                            ) : (
                                                <div>--</div>
                                            )}
                                        </Box>
                                    </ItemBox>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>


                        <Grid item sm={3}>
                            <Item sx={{ height: "100%" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box onClick={() => { window.open(url, '_blank') }} style={{ cursor: "pointer" }}><Icons.Leads />  Leads</Box>
                                    {!leadLoading ? (
                                        <IconButton onClick={(e) => { getReports(false, true, false); setLeadLoading(true); }}> <Icons.RefreshBlue /></IconButton>
                                    ) : (
                                        <CircularProgress />)}
                                </Box>

                                <ItemBoxWrapper elevation={0} onClick={() => { window.open(url, '_blank') }} style={{ cursor: "pointer" }}>
                                    <ItemBoxLeads sx={{ borderColor: "#07BC58", backgroundColor: "#F1FFF7" }}>
                                        New
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#07BC58" }}>
                                                {reportHook?.dailyReportOutputModel?.leadPortal?.newLead || "0"}
                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                    <ItemBoxLeads sx={{ borderColor: "#FF6767", backgroundColor: "#FFF8F8" }}>
                                        Hot
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#FF6767" }}>
                                                {reportHook?.dailyReportOutputModel?.leadPortal?.hotLead || "0"}
                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                    <ItemBoxLeads sx={{ borderColor: "#FF8D24", backgroundColor: "#FFFBF6" }}>
                                        Warm
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#FF8D24" }}>
                                                {reportHook?.dailyReportOutputModel?.leadPortal?.warmLead || "0"}
                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                    <ItemBoxLeads sx={{ borderColor: "#1DA7FF", backgroundColor: "#EFF9FF" }}>
                                        Channel
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#1DA7FF" }}>
                                                {reportHook?.dailyReportOutputModel?.leadPortal?.channels || "0"}
                                            </Box>

                                        </Box>
                                    </ItemBoxLeads>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>
                    </Grid>
                </Paper>
            </>

            <>
                <Paper elevation={0} sx={[styles.wrapper]}>

                    <Typography variant="h6" sx={[styles.cardTitle]}>
                        Project Metrics
                    </Typography>
                    {/* <Stack >
                        {!projectMatricesLoading ? (
                            <IconButton onClick={(e) => { projectMatricesReportsScheduler(); setProjectMatricesLoading(true); }}> <Icons.RefreshBlue /></IconButton>
                        ) : (
                            <CircularProgress />)}</Stack> */}
                </Paper>
                <Paper elevation={0} sx={[styles.wrapperBottom]}>
                    <Item sx={{ cursor: "pointer", backgroundColor: "#f0f9ff", boxShadow: "none", borderRadius: "0", padding: "30px" }}>
                        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Icons.ProjectMatrices />
                            Project Matrices
                        </Box> */}
                        <Grid container spacing={2} sx={{ marginTop: '16px' }}>
                            <Grid lg={4} item>
                                <Item sx={{ height: "100%", padding: "30px 10px", alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: "column", textAlign: "center" }} onClick={() => { window.open(baseUrl + "/miletone-schedulevariance", "_blank") }}>
                                    <Typography variant='h6' sx={{ fontWeight: '600' }}>Schedule Variance</Typography>
                                    <Box sx={[styles.valueText]} >
                                        {`${parseFloat(scheduleVariance?.allScheduleVariance).toFixed(2)}%` || "0"}
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid lg={4} item> <Item sx={{ height: "100%", padding: "30px 10px", textAlign: "center", alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: "column", }} onClick={() => { window.open(baseUrl + "/EffortVarianceReport", "_blank") }}>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>Effort Variance</Typography>
                                <Box sx={[styles.valueText]}>
                                    {`${parseFloat(effortVariance?.effortsVariance).toFixed(2)}%` || "0"}
                                </Box>
                            </Item></Grid>
                            <Grid lg={4} item> <Item sx={{ height: "100%", padding: "30px 10px", textAlign: "center", alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: "column", }} onClick={() => { window.open(baseUrl + "/leakageReport", "_blank") }}>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>Defect Density</Typography>
                                <Box sx={[styles.valueText]}>
                                    {`${parseFloat(defectCount?.defectDensity).toFixed(3)}%` || "--"}
                                </Box>
                            </Item></Grid>
                            <Grid lg={4} item><Item sx={{ height: "100%", padding: "30px 10px", textAlign: "center", alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: "column", }} onClick={() => { window.open(baseUrl + "/leakageReport", "_blank") }}>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>Defect Leakage</Typography>
                                <Box sx={[styles.valueText]}>
                                    {`${parseFloat(defectCount?.defectLeakageAvg).toFixed(2)}` || "0"}
                                </Box>
                            </Item></Grid>
                            <Grid lg={4} item>   <Item sx={{ height: "100%", padding: "30px 10px", textAlign: "center", alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: "column", }} onClick={() => { window.open(baseUrl + "/leakageReport", "_blank") }}>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>Defect Removal Effectiveness</Typography>
                                <Box sx={[styles.valueText]}>
                                {`${parseFloat(defectCount?.defectRemovalAvg).toFixed(2)}` || "0"}
                                </Box>
                            </Item></Grid>
                            <Grid lg={4} item>
                                <Item sx={{ height: "100%", padding: "30px 10px", textAlign: "center", alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: "column", }} onClick={() => { window.open(baseUrl + "/project-revenue-report", "_blank") }}>
                                    <Typography variant='h6' sx={{ fontWeight: '600' }}>Pending Invoices</Typography>

                                    <Box sx={[styles.valueText]}>
                                        <Stack sx={{ display: 'flex', justifyContent: 'center' }} direction={"row"}>
                                            <Box>
                                                {/* <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Pending Invoices</Typography> */}
                                                <Typography sx={{ fontWeight: '600' }} variant='h5' px={2} pt={1}> {projectRevenueMonthGrowth?.paymentPendingCount || 0}</Typography>
                                            </Box>
                                            {/* <Box>
                                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Received</Typography>
                                                <Typography sx={{ fontWeight: '600' }} variant='h5' px={2} pt={1}>{projectRevenueMonthGrowth?.totalRecievedCosting || 0}</Typography>
                                            </Box>                                           */}
                                        </Stack>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>

                    </Item>
                </Paper>
                <Paper elevation={0} sx={[styles.wrapper]}>

                    <Typography variant="h6" sx={[styles.cardTitle]}>
                        Weekly Report
                    </Typography>
                </Paper>
                <Paper elevation={0} sx={[styles.wrapperBottom]}>
                    <Grid container spacing={2}>
                        {/* <Grid item sm={3}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.NewItem /> New Projects
                                <ItemBoxWrapper elevation={0} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <ItemBox sx={{ py: 2 }}>
                                        Client Projects
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.weeklyReportOutputModel?.newProjects?.clientProject || "0"}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox sx={{ py: 2 }}>
                                        InHouse Projects
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.weeklyReportOutputModel?.newProjects?.inHouseProject || "0"}
                                        </Box>
                                    </ItemBox>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid> */}

                        <Grid item sm={4}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.Resources />  Employee Requisition
                                <ItemBoxWrapper elevation={0}>
                                    <ItemBoxLeads sx={{ borderColor: "#07BC58", backgroundColor: "#F1FFF7" }}>
                                        Dev
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#07BC58" }}>
                                                {"12" || "0"}
                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                    <ItemBoxLeads sx={{ borderColor: "#FF6767", backgroundColor: "#FFF8F8" }}>
                                        QA
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#FF6767" }}>
                                                {reportHook?.weeklyReportOutputModel?.employeeRequisition?.qa || "0"}
                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                    <ItemBoxLeads sx={{ borderColor: "#FF8D24", backgroundColor: "#FFFBF6" }}>
                                        Sales/Marketing
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#FF8D24" }}>
                                                {"3" || "0"}
                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                    <ItemBoxLeads sx={{ borderColor: "#1DA7FF", backgroundColor: "#EFF9FF" }}>
                                        HR/IT
                                        <Box sx={[styles.valueText]}>
                                            <Box sx={{ color: "#1DA7FF" }}>
                                                {"1" || "0"}
                                            </Box>

                                        </Box>
                                    </ItemBoxLeads>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>
                        <Grid item sm={4}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.Attrition />  Attrition
                                <ItemBoxWrapper elevation={0} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <ItemBox sx={{ py: 2 }}>
                                        New Joinees
                                        <Box sx={[styles.valueText]}>
                                            {"2" || "0"}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox sx={{ py: 2 }}>
                                        Left Employees
                                        <Box sx={[styles.valueText]}>
                                            {"1" || "0"}
                                        </Box>
                                    </ItemBox>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>
                        <Grid item sm={4} >
                            <Item sx={{ height: "100%" }}>
                                <Icons.Sales />Sales
                                <Box sx={{ px: 5, height: "100%" }}>
                                    <ItemBoxLeads sx={{ borderColor: "#1DA7FF", backgroundColor: "#EFF9FF", height: "70%" }} style={{ cursor: "pointer" }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "100%" }}>
                                            Client Acquisition
                                            <Box sx={[styles.valueText]}>
                                                <Box sx={{ color: "#1DA7FF" }}>
                                                    {"8" || "0"}
                                                </Box>

                                            </Box>
                                        </Box>
                                    </ItemBoxLeads>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Paper>
            </>
            <>
                <Paper elevation={0} sx={[styles.wrapper]}>
                    <Typography variant="h6" sx={[styles.cardTitle]}>
                        Monthly Report
                    </Typography>
                </Paper>
                <Paper elevation={0} sx={[styles.wrapperBottom]}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>


                        </Grid>



                        <Grid item sm={12}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.InventoryStatus />  Inventory Status
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "132px" }}>
                                    <ItemBoxWrapper elevation={0} style={{ cursor: "pointer", justifyContent: "center" }}>
                                        <ReactApexChart options={optionsForMonthlyReport} series={optionsForMonthlyReport?.series} type="bar" width={550} />
                                    </ItemBoxWrapper>
                                    {/* <Box>
                                        <Typography >
                                            Total Laptops : {"374"}
                                        </Typography>
                                        <Typography >
                                            Total Mac Mini : {"118"}
                                        </Typography>
                                        <Typography >
                                            Total Desktop : {"263"}
                                        </Typography>
                                        <Typography >
                                            Total Android : {"28"}
                                        </Typography>
                                        <Typography >
                                            Total Iphone : {"29"}
                                        </Typography>
                                    </Box> */}
                                </Box>
                            </Item>

                        </Grid>
                        {/* 
                        <Grid item sm={4}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.LoudSpeaker /> Website Bounce Rate
                                <ItemBoxWrapper elevation={0}>
                                    <ReactApexChart options={optionsForMonthlyReportBarChart} series={optionsForMonthlyReportBarChart?.series} type="bar" width={350} />
                                </ItemBoxWrapper>
                            </Item>
                        </Grid> */}
                        {/* <Grid item sm={4}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.HealthIcon /> Project Health
                                <ItemBoxWrapper elevation={0} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <ItemBox sx={{ py: 2 }}>
                                        Total Milestones
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.monthlyReportOutputModel?.projectHealth?.totalMilestone || "0"}
                                        </Box>
                                    </ItemBox>
                                    <ItemBox sx={{ py: 2 }}>
                                        Delayed Milestones
                                        <Box sx={[styles.valueText]}>
                                            {reportHook?.monthlyReportOutputModel?.projectHealth?.delayedMilestone || "0"}
                                        </Box>
                                    </ItemBox>
                                </ItemBoxWrapper>
                            </Item>
                        </Grid> */}
                        <Grid item sm={12}>
                            <Item sx={{ height: "100%" }} style={{ cursor: "pointer" }}>
                                <Icons.LoudSpeaker /> Website Visitors
                                <ItemBoxWrapper elevation={0} style={{ cursor: "pointer", justifyContent: "center" }}>
                                    <ReactApexChart options={optionsForMonthlyReportMultipleBarChart} series={optionsForMonthlyReportMultipleBarChart?.series} type="bar" width={750} />
                                </ItemBoxWrapper>
                            </Item>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        </>
    )
}
