
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, Typography, Autocomplete, TextField, CircularProgress, TablePagination } from '@mui/material';
import * as Icons from "../../assests/icons/icons";
import { feedbackService } from '../../service/Feedback/EmployeeFeedbackService';
import { toast } from 'material-react-toastify';
import { clsDepartmentList } from '../../service/common-services/department';
import HrReporttop from './HrReportsstyle';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import { Iproject } from '../../Interfaces/IProject';
import { masterDataService } from '../../service/master-service/master-data-service';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from 'react-rainbow-components';
import dayjs from 'dayjs';
import { Button } from "@mui/material";
import { qaReportService } from '../../service/QaReports-service';
import moment from 'moment';
import { milestoneManagementService } from '../../service/milestone-management-service';
import theme from '../../theme/theme';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";


interface Department {
    id: string,
    name: string
}

enum Statusnumber {
    All = 0,
    Hold = 1,
    InProgress = 2,
    Completed = 3,
    Exceeded = 4
  }


export default function NewMilestoneReport() {


    const [milestoneReportExceededDate, setMilestoneReportExceededDate] = useState<any>([]);
    const [departmentList, setDepartmentList] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [filterSelected, setFilterSelected] = useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [projects, setProjects] = React.useState<Iproject[]>([]);
    const [selectedProjectId, setProjectId] = useState("");
    const [fromDate, setfromDate] = React.useState<any>(null);
    const [toDate, settoDate] = React.useState<any>(null);
    const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);
    const[selectedstatus,setselectedstatus] = React.useState<any>(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(milestoneReportExceededDate.length / rowsPerPage);

   
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
      };
    

    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector(
        (state: any) => state.ReportPermissionApp.ReportPermission
    );
    const navigate = useNavigate();

    useEffect(() => {
        // if (_permission.page_UserFeedbackReport_Access !== true) {
        //     navigate("/dashboard");
        // }

       
        const getDepartmentDropdown = async () => {
            if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                setSelectedDepartment(_authUser.employeeInfo.departmentId);
                setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
                getActiveProjects(_authUser.employeeInfo.departmentId);
                GetMilestoneReportExceededDate( "",_authUser.employeeInfo.departmentId,0, null, null);
        
            }
            else {
                setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
                GetMilestoneReportExceededDate( "", "",0, null, null);
                setSelectedDepartment("");
                 
            }
        };
        getDepartmentDropdown();

    }, [])


    var projectData = { id: "0", projectName: "Select Projects" };
    const getActiveProjects = async (id: any) => {
        await masterDataService.getProjectByDeptartmentId(id).then(async (response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                let data = [];
                data.push(projectData);
                response.data.map((dep: any) => {
                    data.push(dep);
                });
                setProjects(data);
            }
        });
    };

    const GetMilestoneReportExceededDate = async (ProjectId: any, deptId: any,Status :any, startDate: any, endDate: any) => {

        var req = {
            departmentId: deptId,
            projectId: ProjectId,
            status:Status,
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : "",
            endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : ""

        }
        setToggleLoader(true);
        setPage(0);
        try {
            const response = await milestoneManagementService.getMilestoneReportExceededDate(req);
            if (response.data.isError) {
                toast.error("Something went wrong while fetching data");
            } else {
                setMilestoneReportExceededDate(response?.data);
                setToggleLoader(false);
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };  

    const handleDepartmentSelection = async (event: any, value: any) => {
        setFilterSelected(true);
        setSelectedDepartment(value?.id ?? "");
        await getActiveProjects(value?.id ?? "");
        setselectedstatus(0);
    }
    const convertBgColor = (status: any, endDate: any, actualEndDate: any) => {
        debugger
        const actualEndDateFormatted = actualEndDate.split("T")[0];
        const endDateFormatted = endDate.split("T")[0];
       
        if ((status === 2) && ( actualEndDateFormatted <= endDateFormatted || actualEndDateFormatted == null || actualEndDateFormatted == "0001-01-01"))  {
            return "#ffc107";
        }

        if ((status === 1 || status === 4) && ( actualEndDateFormatted <= endDateFormatted || actualEndDateFormatted == null || actualEndDateFormatted == "0001-01-01")) {
            return "#1da7ff";
        }
        if ((status === 3) && ( actualEndDateFormatted <= endDateFormatted || actualEndDateFormatted == null  || actualEndDateFormatted == "0001-01-01")) {
            return "#2DD36F";
        }
        

        if (actualEndDateFormatted > endDateFormatted) {
            return "#f76360";
        }
    
       
        //return endDateObj < actualEndDateObj ? "#EB445A" : "#EB445A"; // red if less, otherwise red
    };
    
    

    return (
        <>
            {toggleLoader && (< CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
                                Exceeded Milestone Report</Typography>
                            <Stack sx={{mt: "3"}} direction="row" justifyContent="space-between" paddingTop={0}  spacing={3}>
                                <Autocomplete
                                    value={departmentList?.find(
                                        (option) => option?.id === selectedDepartment
                                    ) ?? null}
                                    disabled={_authUser.typeOfUser == eTypeOfUser.Manager}
                                    id="Departments"
                                    options={departmentList}
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleDepartmentSelection}
                                    sx={{ width: 230 }}
                                    renderInput={(params) => <TextField {...params} value={selectedDepartment} />}
                                />
                                <Autocomplete
                                    fullWidth
                                    value={
                                        projects?.find((option) => option.id == selectedProjectId) ??
                                        projectData
                                    }
                                    sx={{
                                        width: 230
                                    }}
                                    disabled={!selectedDepartment}
                                    id="outlined-basic"
                                    options={projects}
                                    getOptionLabel={(option) => option.projectName}
                                    onChange={async (event, value) => {
                                        setProjectId(value?.id ?? "");
                                        setFilterSelected(true);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={selectedProjectId}
                                        />
                                    )}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        style={{ width: '25%' }}
                                        // label="From"
                                        placeholder="From"
                                        labelAlignment="left"
                                        borderRadius="semi-square"
                                        value={fromDate}
                                        maxDate={dayjs().subtract(1, 'day').toDate()}
                                        onChange={(newValue) => {
                                            setfromDateOrgl(newValue)
                                            let abc = dayjs(newValue)
                                            setfromDate(abc);
                                            setFilterSelected(true);
                                            if (dayjs(toDate) < dayjs(abc)) {
                                                settoDate(
                                                    dayjs(newValue).add(1, "day").toDate()
                                                );
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        style={{ width: '25%' }}
                                        disabled={!fromDate}
                                        placeholder="To"
                                        labelAlignment="left"
                                        borderRadius="semi-square"
                                        maxDate={dayjs().subtract(1, 'day').toDate()}
                                        value={toDate}
                                        onChange={(newValue: any) => {
                                            let nValue = dayjs(newValue);
                                            settoDate(nValue);
                                            setFilterSelected(true);
                                        }}
                                    />
                                </LocalizationProvider>

                                {filterSelected && (
                                    <Button
                                        color="primary"
                                        sx={{ ml: 0.5 }}
                                        onClick={() => {
                                            GetMilestoneReportExceededDate(selectedProjectId, selectedDepartment,selectedstatus, fromDate, toDate)
                                        }}
                                        disabled={!projects && !fromDate && !toDate}
                                        variant="contained"
                                    >
                                        Apply
                                    </Button>
                                )}

                                {filterSelected && (
                                    <Button
                                        onClick={() => {
                                            setFilterSelected(false);
                                            setfromDate(null);
                                            setfromDateOrgl(null)
                                            settoDate(null);
                                            setProjectId("");
                                            if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                                                setSelectedDepartment(_authUser.employeeInfo.departmentId);
                                                GetMilestoneReportExceededDate("", _authUser.employeeInfo.departmentId, 0,null, null);
                                            } else {
                                                setSelectedDepartment("");
                                                GetMilestoneReportExceededDate("", "", 0,null, null);
                                                setselectedstatus("");
                                            }


                                        }}
                                        color="warning"
                                        variant="contained"
                                        sx={{ ml: 0.5 }}
                                    >
                                        Clear
                                    </Button>

                                )}

                                
                            </Stack>
                            <Stack
                                    direction={{ xs: "column", sm: "row" }}
                                    spacing={1}
                                    alignItems={{ xs: "flex-start", sm: "center" }}
                                    justifyContent="flex-end"
                                    sx={{ mt: 1, ml: 'auto' }}
                                >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer", // Add this to indicate it is clickable
                                    }}
                                    onClick={() => {
                                        GetMilestoneReportExceededDate(selectedProjectId , selectedDepartment,0, fromDate, toDate);
                                        setselectedstatus(0);
                                      
                                    }}
                                     // This makes it act as a button
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: "turquoise" }}
                                    />
                                    All
                                   </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer", // Add this to indicate it is clickable
                                    }}
                                    onClick={() => {
                                        GetMilestoneReportExceededDate(selectedProjectId , selectedDepartment,3, fromDate, toDate);
                                        setselectedstatus(3);
                                       
                                    }}
                                // component="button" // This makes it act as a button
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: theme.palette.success.main }}
                                    />
                                    Completed
                                </Typography>


                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 500,
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            // GetMilestoneReportExceededDate("", "",2, null, null);
                                            GetMilestoneReportExceededDate(selectedProjectId , selectedDepartment,2, fromDate, toDate);
                                            setselectedstatus(2);
                                          
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            sx={{ color: theme.palette.warning.main }}
                                        />
                                        In Progress
                                    </Typography>

                                    <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    GetMilestoneReportExceededDate(selectedProjectId , selectedDepartment,1, fromDate, toDate);
                                    setselectedstatus(1);
                                  
                                }}
                            >
                                <FiberManualRecordIcon
                                     sx={{ color: '#1da7ff'}}
                                />
                              On Hold/New
                            </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 500,
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            // GetMilestoneReportExceededDate("", "",4, null, null);
                                            GetMilestoneReportExceededDate(selectedProjectId , selectedDepartment,4, fromDate, toDate);
                                            setselectedstatus(4);
                                         
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            sx={{ color: theme.palette.error.main }}
                                        />
                                        Exceeded
                                    </Typography>
                                </Stack>
                        </Grid>
                        <TableContainer component={Paper} sx={{ maxHeight: "400px", height: "100%", overflowY: "auto", background: "transparent", boxShadow: "none" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ position: "sticky", top: "0" }}>
                                    <TableRow sx={{}}>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Sr. No</TableCell>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Milestone Name</TableCell>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Project Name</TableCell>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Start Date</TableCell>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>End Date</TableCell>
                                        <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Completed Milestone Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >



                                    {milestoneReportExceededDate?.length > 0 ? (
                                        milestoneReportExceededDate?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => (
                                            <TableRow key={index} sx={{ backgroundColor: convertBgColor(row.status, row.endDate, row.actualEndDate) }}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.milestoneName}</TableCell>
                                                <TableCell>{row.projectName}</TableCell>
                                                <TableCell>
                                                    {" "}
                                                    {`${moment(row.startDate).format(
                                                        "DD-MMM-YYYY"
                                                    )}`}
                                                </TableCell>
                                                <TableCell>
                                                    {" "}
                                                    {`${moment(row.endDate).format(
                                                        "DD-MMM-YYYY"
                                                    )}`}
                                                </TableCell>

                                                <TableCell>
                                                    {row.actualEndDate !== "0001-01-01T00:00:00Z" ?
                                                        moment(row.actualEndDate).format("DD-MMM-YYYY") :
                                                        "--"
                                                    }
                                                </TableCell>
                                                {/* <TableCell>
                                                    {" "}
                                                    {`${moment(row.actualEndDate).format(
                                                        "DD-MMM-YYYY"
                                                    )}`}
                                                </TableCell> */}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={10} align="center">
                                                No records found
                                            </TableCell>
                                        </TableRow>
                                    )}


                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <Box sx={{backgroundColor:"#fff"}}>*/}
         
          {/* </Box> */}

                    </Grid>
                </Box>
            </Paper>
            <TablePagination 
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={milestoneReportExceededDate?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

            labelDisplayedRows={() => `Total: ${milestoneReportExceededDate?.length}  Page ${page + 1} of ${pageCount}`}
          />
        </>

    );
}