import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Chip, IconButton, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import * as Icons from "../../assests/icons/icons";
import FileUpload from "../../components/styledComponent/FileUpload";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { inventoryService } from "../../service/Inventory/inventoryService";
import { attributeenum } from "../users/categorymodel";
import * as Yup from "yup";
import AppConstants from "../../config/AppConstants";
import ClearIcon from "@mui/icons-material/Clear";
import { IFiles } from "../users/userModel";
import { makeStyles } from "@mui/styles";
import { apiUrl } from "../../service/common-services/apiURL";
import FileUploadPics from "../../components/styledComponent/FileUploadPics";

const useStyles = makeStyles((theme) => ({
  anchor: {
    wordWrap: "break-word",
  },
}));

export default function Inventorynewcategory() {
  const classes = useStyles();

  const { categoryId } = useParams();
  const DisableEditLabel = !categoryId;
  const DisableAddLabel = categoryId;
  const DisableUpdateButton = !categoryId;
  const DisableAddButton = categoryId;

  const navigate = useNavigate();
  const [brandValues, setbrandValues] = React.useState<any[]>([]);
  const [Category, setsCategory] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [brandName, setbrand] = React.useState<any>("");
  const [DisableButton1, setDisableButton1] = React.useState(false);
  const [documentFile, setDocumentFile] = React.useState<any>(null);
  const [tempDocumentFileURL, setTempDocumentFileURL] = React.useState<any>("");
  const [validationMessage, setValidationMessage] = React.useState<any>("");

  console.log(brandName, "brandName");
  console.log(brandValues, "brandValues");

  let emptyAttributeObj = {
    attribute: "",
    value: [""],
    isFetched: false,
  };
  const [attributes, setattributes] = React.useState<attributeenum[]>([
    emptyAttributeObj,
  ]);
  const [uploadImageResponse1, setuploadImageResponse] = React.useState("");

  React.useEffect(() => {
    if (categoryId != null || categoryId != undefined) {
      getCategoryById(categoryId);
    }
  }, []);

  let addbrand = () => {
    // debugger;
    let objBrand = { brandName: brandName };
    if (brandName != null && brandName.length > 0) {
      objBrand.brandName = brandName;
      let fObj = [...brandValues];
      fObj.push(objBrand);
      setbrandValues(fObj);
      // setbrand("");
    } else {
      if (brandName == null || brandName.length == 0) {
        toast.warning("Enter Brand", {
          onOpen: () => setDisableButton1(true),
          onClose: () => setDisableButton1(false),
        });
      }
    }
  };

  let removeBrand = (i: any) => {
    let newbrandValues = [...brandValues];
    newbrandValues.splice(i, 1);
    setbrandValues(newbrandValues);
    setDisableButton1(false);
  };

  const addCategory = async () => {
    try {
      if (documentFile) {
        const formData = new FormData();
        formData.append("File", documentFile);
        formData.append("FileFor", Category);
        const uploadImagePromise =
          inventoryService.UploadCategoryImage(formData);
        const [uploadImageResponse] = await Promise.all([uploadImagePromise]);
        if (uploadImageResponse.data.isError) {
          toast.error("Facing issue while uploading document.");
        } else {
          debugger;
          let input = {
            category: Category.trim(),
            description: description,
            brand: brandValues,
            uploadProductDetail: uploadImageResponse?.data,
            setConfiguration: attributes,
          };
          await inventoryService.AddCategories(input).then((response: any) => {
            if (response.data.isError) {
            } else {
              debugger;
              setuploadImageResponse("");
              toast.success("Category added successfully.");
              navigate("/inventory-managemnt");
            }
          });
        }
      } else {
        let input = {
          category: Category.trim(),
          description: description,
          brand: brandValues,
          uploadProductDetail: "", // Use data from the response
          setConfiguration: attributes,
        };
        await inventoryService.AddCategories(input).then((response: any) => {
          if (response.data.isError) {
          } else {
            debugger;
            setuploadImageResponse("");
            toast.success("Category added successfully.");
            navigate("/inventory-managemnt");
          }
        });
      }
    } catch (error: any) {
      if (error.data && error.data.Error.length > 0) {
        toast.error(error.data.Error[0]);
      }
      console.error("Error adding category:", error);
      setuploadImageResponse("");
    }
  };

  const UpdateCategory = async () => {
    try {
      let uploadImageResponse = null; // Initialize to null here

      if (documentFile) {
        const formData = new FormData();
        formData.append("File", documentFile);
        formData.append("FileFor", Category);

        const uploadImagePromise =
          inventoryService.UploadCategoryImage(formData);
        [uploadImageResponse] = await Promise.all([uploadImagePromise]);

        if (uploadImageResponse.data.isError) {
          toast.error("Facing issue while uploading document.");
        } else {
          setuploadImageResponse(uploadImageResponse?.data);
        }
      }

      const input = {
        categoryId: categoryId,
        category: Category,
        description: description,
        brand: brandValues,
        uploadProductDetail: documentFile
          ? uploadImageResponse?.data
          : tempDocumentFileURL,
        setConfiguration: attributes,
      };

      const response = await inventoryService.updateCategories(input);

      if (response.data.isError) {
        toast.error("Something went wrong...");
      } else {
        toast.success("Category Updated successfully.");
        navigate("/inventory-managemnt");
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {});
      });
    }
  };

  const getCategoryById = async (id: any) => {
    try {
      await inventoryService.getCategorybyId(id).then((response: any) => {
        if (response.data.isError) {
        } else {
          setsCategory(response?.data?.category);
          setbrandValues(response?.data?.brand);
          setdescription(response?.data?.description);
          setattributes(response?.data?.setConfiguration);
          if (response?.data?.categoryImage) {
            setTempDocumentFileURL(response?.data?.categoryImage);
          } else {
            setTempDocumentFileURL("");
          }
        }
      });
    } catch (error: any) {
      if (error.data.Error.length > 0) toast.error(error.data.Error[0], {});

      return error;
    }
  };

  const changeHandler = (e: any) => {
    console.log(e);
    let newFormValues = [...brandValues];
    setbrandValues(newFormValues);
  };

  let addAttribute = () => {
    // debugger;
    setattributes([...attributes, emptyAttributeObj]);
    // setattributes([...attributes, { attribute: '', value: [''] }]);
  };

  let removeAttribute = (i: any) => {
    let newattributes = [...attributes];
    newattributes.splice(i, 1);
    setattributes(newattributes);
  };

  const removeattValue = (mainIndex: any, index: any) => {
    let obj = [...attributes];
    obj[mainIndex].value.splice(index, 1);
    setattributes(obj);
  };

  const addValueClick = (mainIndex: any) => {
    const existing = [...attributes];
    existing[mainIndex].value.push("");
    setattributes(existing);
  };

  const triggerDocumentFile = (file: FileList, index: number) => {
    // debugger;
    setDocumentFile(file[0]);
    setTempDocumentFileURL(URL.createObjectURL(file[0]));
  };

  const cancelDocumentFile = () => {
    setTempDocumentFileURL("");
    setDocumentFile(null);
  };
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const handleSubmit1 = (e: any) => {
    e.preventDefault();

    // Set the form submission state to true
    setFormSubmitted(true);

    // Your additional form submission logic here
  };

  let abc = tempDocumentFileURL.substring(0, 9);
  const fieldValidationSchema = Yup.object().shape({
    category: Yup.string()
      .matches(
        AppConstants.MatchRegExp.alphaNumericWithSpace,
        "Category name can only contain alphanumeric."
      )
      .required("Category Name is required"),
    setConfiguration: Yup.array().of(
      Yup.object().shape({
        attribute: Yup.string().required("Attribute Name is required"),
        value: Yup.array().of(Yup.string().required("Value is required")),
      })
    ),
    // brand: brandValues && brandValues.length == 0
    // ? Yup.string().required("Brand Name is required") : Yup.string(),
  });

  return (
    <Formik
      initialValues={{
        categoryId: categoryId,
        category: Category,
        uploadProductDetail: tempDocumentFileURL,
        description: description,
        brand: brandValues,
        setConfiguration: attributes,
      }}
      validationSchema={fieldValidationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (categoryId != null) {
            if (brandValues.length == 0) {
              setFormSubmitted(true);
            } else if (
              tempDocumentFileURL == null ||
              tempDocumentFileURL == ""
            ) {
              toast.warn("Please upload Image First");
            } else {
              await UpdateCategory();
            }
          } else {
            if (brandValues.length == 0) {
              setFormSubmitted(true);
            } else if (
              tempDocumentFileURL == null ||
              tempDocumentFileURL == ""
            ) {
              toast.warn("Please upload Image First");
            } else {
              await addCategory();
            }
          }
        } catch (ex: any) {
          if (ex.status == 400) {
            console.error("ex:", ex);
            ex.data.Error.map((error: any, idx: any) => {
              toast.error(error);
            });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={(e) => {
            handleSubmit();
            handleSubmit1(e);
          }}
        >
          <Box sx={{ width: "100%", typography: "body1" }}>
            <Typography sx={{ padding: "0 0 15px 0" }}>
              Inventory Management
            </Typography>
            <Paper sx={{ padding: "30px 16px" }}>
              <Stack
                direction="row"
                pb={"19px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ borderBottom: "1px solid #EEEEEE" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    display: DisableAddLabel ? "none" : "block",
                  }}
                  component="h2"
                >
                  New Category
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    display: DisableEditLabel ? "none" : "block",
                  }}
                  component="h2"
                >
                  Edit Category
                </Typography>
              </Stack>
              <Box paddingY={2}>
                <Grid container spacing={2} sx={{ display: "flex" }}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Category"
                      fullWidth
                      margin="normal"
                      name="category"
                      onBlur={handleBlur}
                      type="text"
                      required
                      value={Category}
                      variant="outlined"
                      helperText={touched.category && errors.category}
                      error={Boolean(touched.category && errors.category)}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(
                          /[^a-zA-Z-.\s]/g,
                          ""
                        );
                        value = value
                          .replace(/\s{2,}$/g, "")
                          .trimStart()
                          .substring(0, 30);
                        setsCategory(value);
                        // changeHandler(value)
                      }}
                    />
                    <TextField
                      label="Brand"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="brand"
                      required
                      onBlur={handleBlur}
                      //   helperText={(touched.brand && errors.brand)} // Include formError here
                      // error={Boolean(touched.brand && errors.brand)}
                      error={formSubmitted && brandValues.length === 0}
                      helperText={
                        formSubmitted && brandValues.length === 0
                          ? "Brand is Required."
                          : ""
                      }
                      type="text"
                      value={brandName}
                      onChange={(e) => {
                        setFormSubmitted(false);
                        let value = e.currentTarget.value.replace(
                          /[^a-zA-Z-.\s]/g,
                          ""
                        );
                        value = value
                          .replace(/\s{2,}$/g, "")
                          .trimStart()
                          .substring(0, 30);
                        setbrand(value);
                        // // changeHandler(value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box>
                      <TextField
                        label="Description"
                        id="outlined-basic"
                        multiline
                        variant="outlined"
                        // required
                        sx={{
                          width: "90%",
                     
                          height: "114px",
                          "word-break": "break-all",
                          "line-height": "1.5",
                          padding: "0",
                          "& .MuiInputBase-root": {
                            "line-height": "1.5",
                            height: "100%",
                          },

                          "& .MuiOutlinedInput-root": {
                            "line-height": "1.5",
                            padding: "10px",
                          },
                        }}
                        margin="normal"
                        name="description"
                        helperText={touched.description && errors.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        type="text"
                        value={description}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(
                            /[^a-zA-Z-.\s]/g,
                            ""
                          );
                          value = value
                            .replace(/\s{2,}$/g, "")
                            .trimStart()
                            .substring(0, 100);
                          values.description = value;
                          setdescription(value);
                          // changeHandler(value)
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Stack
                      direction="row"
                      alignContent="center"
                      sx={{ height: "100%" }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box
                          sx={{
                            marginBottom: "20px",
                            whiteSpace: "nowrap",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Upload Image *</Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "auto",
                            marginLeft: "10px",
                            display: "flex",
                            justifyContent: "strech",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{}}>
                            {documentFile == null && (
                              <FileUploadPics
                                selectFile={triggerDocumentFile}
                              />
                            )}
                          </Box>
                          {/* <Button
                          variant="contained"
                          onClick={() => addDocumentValues()}
                          // size="small"
                          sx={{ mx: 2 }}
                          disabled={!documentFile}
                        >
                          Add{" "}
                        </Button> */}
                        </Box>
                      </Box>
                      {tempDocumentFileURL && (
                        <Stack
                          sx={{ width: "auto", marginLeft: "16px" }}
                          direction="row"
                          alignItems="center"
                          justifyContent="start"
                        >
                          <Box sx={{ width: "150px" }}>
                            <a
                              className={classes.anchor}
                              href={
                                abc === "blob:http"
                                  ? tempDocumentFileURL
                                  : apiUrl.getImageURL() +
                                    "/" +
                                    tempDocumentFileURL
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Box
                                sx={{
                                  maxWidth: "130px",
                                  height: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  width={"100%"}
                                  height={"100%"}
                                  src={
                                    abc === "blob:http"
                                      ? tempDocumentFileURL
                                      : apiUrl.getImageURL() +
                                        "/" +
                                        tempDocumentFileURL
                                  }
                                  alt="Document"
                                />
                              </Box>
                            </a>
                          </Box>
                          <Box sx={{ marginLeft: "42px" }}>
                            <IconButton onClick={() => cancelDocumentFile()}>
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Box sx={{ mt: 3 }}>
                      {brandValues.map((element: any, index: any) => {
                        return (
                          <Chip
                            label={element.brandName}
                            variant="outlined"
                            onDelete={() => removeBrand(index)}
                            size="medium"
                            sx={{
                              background: "#efefef",
                              borderRadius: "0px",
                              margin: "16px",
                              "& .css-1hn4cb6-MuiButtonBase-root-MuiChip-root":
                                {
                                  background: "#efefef",
                                  borderRadius: "0px",
                                },
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ mt: "17px", mb: "11px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addbrand()}
                    disabled={DisableButton1 || !brandName}
                  >
                    {" "}
                    Add
                  </Button>
                </Box>
              </Box>
              <Box
                paddingY={"30px"}
                sx={{
                  borderBottom: "1px solid #EEEEEE",
                  borderTop: "1px solid #EEEEEE",
                }}
              >
                <Stack
                  direction="row"
                  pb={"24px"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600 }}
                    component="h2"
                  >
                    Set Configuration
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => addAttribute()}
                  >
                    Add Attribute{" "}
                  </Button>
                </Stack>
                {attributes.map((element, mainIndex) => (
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      padding: "20px",
                      borderRadius: "10px",
                      marginY: "20px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          label="Attribute Name"
                          variant="outlined"
                          fullWidth
                          sx={{ mb: "24px" }}
                          type="text"
                          value={element.attribute}
                          name={`setConfiguration.${mainIndex}.attribute`}
                          error={Boolean(
                            getIn(
                              touched,
                              `setConfiguration.${mainIndex}.attribute`
                            ) &&
                              getIn(
                                errors,
                                `setConfiguration.${mainIndex}.attribute`
                              )
                          )}
                          helperText={
                            getIn(
                              touched,
                              `setConfiguration.${mainIndex}.attribute`
                            ) &&
                            getIn(
                              errors,
                              `setConfiguration.${mainIndex}.attribute`
                            )
                          }
                          onChange={(event) => {
                            let obj = [...attributes];
                            obj[mainIndex].attribute =
                              event.currentTarget.value;
                            setattributes(obj);
                          }}
                        />
                        {attributes && attributes.length > 0 ? (
                          <Button
                            endIcon={<Icons.Delete />}
                            name="btnv"
                            color="error"
                            onClick={() => removeAttribute(mainIndex)}
                          >
                            Remove Attribute
                          </Button>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Box>
                          <Grid container spacing={1}>
                            {element.value?.map((element1: any, index: any) => (
                              <Grid item xs={12} md={4}>
                                <TextField
                                  label="Value"
                                  variant="outlined"
                                  fullWidth
                                  value={element1}
                                  onBlur={handleBlur}
                                  // error={Boolean(errors[`setConfiguration.${mainIndex}.value.${index}`])}
                                  //  helperText={errors[`setConfiguration.${mainIndex}.value.${index}`]}

                                  error={Boolean(
                                    getIn(
                                      touched,
                                      `setConfiguration.${mainIndex}.value.${index}`
                                    ) &&
                                      getIn(
                                        errors,
                                        `setConfiguration.${mainIndex}.value.${index}`
                                      )
                                  )}
                                  helperText={
                                    getIn(
                                      touched,
                                      `setConfiguration.${mainIndex}.value.${index}`
                                    ) &&
                                    getIn(
                                      errors,
                                      `setConfiguration.${mainIndex}.value.${index}`
                                    )
                                  }
                                  name={`setConfiguration.${mainIndex}.value`}
                                  onChange={(event) => {
                                    let obj = [...attributes];
                                    obj[mainIndex].value[index] =
                                      event.currentTarget.value;
                                    setattributes(obj);
                                  }}
                                />

                                <Stack
                                  direction="row"
                                  justifyContent="flex-end"
                                >
                                  <Button
                                    endIcon={<Icons.Delete />}
                                    name="btnv"
                                    color="error"
                                    onClick={() =>
                                      removeattValue(mainIndex, index)
                                    }
                                  ></Button>
                                </Stack>
                              </Grid>
                            ))}
                          </Grid>
                          <Button sx={{ marginY: "10px" }}>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                color: "#1DA7FF",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                addValueClick(mainIndex);
                              }}
                            >
                              Add Value
                              <AddIcon />
                            </Typography>
                          </Button>
                        </Box>
                        <Box mt={1}></Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
              <Box
                paddingY={"30px"}
                display={"flex"}
                justifyContent={"end"}
                sx={{ borderTop: "1px solid #EEEEEE" }}
                textAlign={"end"}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ margin: " 0px 22px" }}
                  component={Link}
                  to="/inventory-managemnt"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    fontWeight: 600,
                    display: DisableAddButton ? "none" : "auto",
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    fontWeight: 600,
                    display: DisableUpdateButton ? "none" : "auto",
                  }}
                  // onClick={()=> UpdateCategory()}
                >
                  Update
                </Button>
              </Box>
            </Paper>
          </Box>
        </form>
      )}
    </Formik>
  );
}
