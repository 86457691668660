import { Observable } from "redux";
import { commonServive } from "./service";
import { userManagementServive } from "../user-management-service";
import { toast } from "material-react-toastify";
export class clsResourcesList {
  resourceId: string = "";
  constructor() {}

  // get Resources By DepartmentId
  public static async getResourcesDropdownList(id: string) {
    var resourceFirstIndex = {
      userId: "",
      userName: "Select Resources",
    };
    let data: Array<any> = await this.getResourceData(id);
    let returnData: Array<any> = [];
    if (data?.length > 0) {
      let sortedData = data?.sort((a: any, b: any) =>
      a.userName.toUpperCase() < b.userName.toUpperCase() ? -1 : 1
    );
      let arr = [resourceFirstIndex];
      returnData = arr.concat(sortedData);
    } else {
      returnData.push(resourceFirstIndex);
    }
    return returnData;
  }
  public static async getResourceData(id: string) {
    try {
      var response = await commonServive.getResourcesByDepartment(id);
      if (response.data.isError) {
        return [];
      } else {
        return response.data;
      }
    } catch (ex: any) {
      console.error("ex:", ex);
    }
  }


///Get Resource By projectId

  public static async getResourcesDropdownListByProjectId(id: string) {
    var resourceFirstIndex = {
      id: "",
      name: "Select Resources",
    };
    let data: Array<any> = await this.getResourcesByProjectId(id);
    let returnData: Array<any> = [];
    if (data.length > 0) {
      debugger;
      let sortedData = data.sort((a: any, b: any) =>
      a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
    );
      let arr = [resourceFirstIndex];
      returnData = arr.concat(sortedData);
    } else {
      returnData.push(resourceFirstIndex);
    }
    return returnData;
  }

  public static async getResourcesByProjectId(projectId: string) {    
    try {
      let input ={
        projectId : projectId
      }
      var response = await userManagementServive.getAllUsersByProjectId(input);
      if (response.data.isError) {
        return [];
      } else {
        return response?.data;
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
       
      });
    }
  }

}
