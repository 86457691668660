import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import { grey } from '@mui/material/colors'
import FormGroup from '@mui/material/FormGroup';
// import CustomTreeItem, { CustomTreeItemProps, CustomTreeItemClasses } from '@mui/lab/CustomTreeItem';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import clsx from 'clsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { borderColor } from '@mui/system';
import CustomTreeItem from '../../components/styledComponent/CustomTreeItem'
import StepsRoles from './StepsRoles'
import { roleManagementServive } from '../../service/role-management-service';
import { strict } from 'assert';
import { string } from 'yup';
import ScreenPermission from './Screens'
import { tab } from '@testing-library/user-event/dist/tab';
import { number } from 'yup/lib/locale';
import {  toast } from 'material-react-toastify';
import Breadcrumbs from "@mui/material/Breadcrumbs"; 
import MenuJson from "../../assests/manu.json";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',

}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: "0",
    '& .MuiListItemButton-root': {
        borderBottom: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? '#2c2c2c' : ' #D1D1D1',
    },
}));

const modules = [
    {
        text: 'Admin'
    },
    {
        text: 'Staff'
    },
    {
        text: 'Client'
    },
    {
        text: 'Employee'
    },
    {
        text: 'Worker'
    },

];
interface ListActionMaster {
    actionName: string;
    actionId: string;
    displayOrder: string;
    isChecked: boolean;
}

interface ListTabMaster {
    tabName: string;
    tabId: string;
    displayOrder: string;
    listActionMaster: ListActionMaster[];
    isChecked: boolean;
}

interface ListPageMaster {
    pageName: string;
    pageId: string;
    displayOrder: string;
    listTabMaster: ListTabMaster[];
    isChecked: boolean;
}


interface MasterRole {
    moduleId: string;
    moduleName: string; 
    displayOrder: string;   
    isChecked: boolean;
    listPageMaster:ListPageMaster[]
}
interface ListPageMaster {
    displayOrder: string;
    isChecked: boolean;
    pageId: string; 
    pageName: string;
    listTabMaster:ListTabMaster[]
}
interface ListTabMaster {
    displayOrder: string;
    isChecked: boolean;
    tabId: string; 
    tabName: string;
    listActionMaster:ListActionMaster[]
}
interface ListActionMaster {
    displayOrder: string;
    isChecked: boolean;
    actionId: string; 
    actionName: string;
}

interface ListModuleMaster {
    moduleName: string;
    moduleId: string;
    displayOrder: string;
    listPageMaster: ListPageMaster[];
    isChecked: boolean;
}

interface ModuleSelection {
    module: [];
    pages: [];
    tabs: [];
    action: [];
}
const steps = [
    'Update Role',
    'Grant Permissions',
  ];
export default function UpdateRolePermission() {
    const { id } = useParams();
    const [roles, setRoles] = useState<MasterRole[]>([]);
    const [autoRoles, SetAutoRoles] = useState<[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<ListModuleMaster[]>([]);
    const [autoSelectedRoles, setAutoSelectedRoles] = useState<ListModuleMaster[]>([]);
    const [reqData, setReqData] = useState<any>([]);
    // const [tempSelectedAction, setTempSelectedActions] = useState<ModuleSelection[]>([]);
    const [tempSelectedModule, tempSetSelectedModule] = useState<string[]>([]);
    const [tempSelectedPage, tempSetSelectedPage] = useState<string[]>([]);
    const [tempSelectedTab, tempSetSelectedTab] = useState<string[]>([]);
    const [tempSelectedAction, tempSetSelectedAction] = useState<string[]>([]);
    const [isChecked, setIsChecked] = useState(false);
    const [roleName, setRoleName] = useState("");
    useEffect(() => {
        getRoleDetails();
        GetMasterRole();
        // //GetRoleAccessByRoleId();
    }, [])
    const getRoleDetails = async () => {
        // getUsers();
        await roleManagementServive.getRoleById(id).then((response: any) => {
          if (response.data.isError) {
          } else {
            setRoleName(response?.data?.name);
          }
        });
      };
    const GetMasterRole = async () => {
        await roleManagementServive.GetMasterRole()
            .then((response: any) => {
                if (response.data.isError) {

                }
                else {
                    GetRoleAccessByRoleId(response.data.result);
                }
            });
    }
    const GetRoleAccessByRoleId = async (allRoles: any) => {
        try{
            await roleManagementServive.GetRoleAccessByRoleId(id)
            .then((response: any) => { 
                if (response.data.isError) {
                    setRoles(allRoles);
                }
                else {
                    if(response.data.length>0){
                        let arr_menu=allRoles; 
                        response.data.map((menu:any)=>{
                            let objModule=arr_menu.find((objMenu:any)=>objMenu.moduleName==menu.moduleName && menu.isChecked==true);
                            if(objModule){
                                objModule.isChecked=true;
                                if(menu.listPageMaster && menu.listPageMaster.length>0){
                                    menu.listPageMaster.map((lstPage:any)=>{
                                        let objPage=objModule.listPageMaster.find((pageObj:any)=>pageObj.pageName==lstPage.pageName && lstPage.isChecked==true);
                                        if(objPage){
                                            objPage.isChecked=true;
                                            if(lstPage.listTabMaster && lstPage.listTabMaster.length>0){
                                                lstPage.listTabMaster.map((lstTab:any)=>{
                                                    let objTab=objPage.listTabMaster.find((tabObj:any)=>tabObj.tabName==lstTab.tabName && lstTab.isChecked==true);
                                                    if(objTab){
                                                        objTab.isChecked=true;
                                                        if(lstTab.listActionMaster && lstTab.listActionMaster.length>0){
                                                            lstTab.listActionMaster.map((lstAction:any)=>{
                                                                let objAction=objTab.listActionMaster.find((actionObj:any)=>actionObj.actionName==lstAction.actionName && lstAction.isChecked==true);
                                                                if(objAction){
                                                                    objAction.isChecked=true;
                                                                }
                                                            })
                                                            
                                                        }
                                                    }
                                                })
                                                
                                            }
                                        }
                                    })
                                    
                                }
                            }
                        })
                        setRoles(arr_menu);
                    }
                    else{
                        setRoles(allRoles);
                    }
                }
            }); 
        }
        catch(ex:any){
           
            setRoles(allRoles);
        }
        
        
    } 
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const User = ({ id, name, avatar }: any) => (
        <div className="user-container" data-id={id}>
            <img src={avatar} className="user-avatar" />
            <span className="user-name">{name}</span>
        </div>
    ); 
    const navigate = useNavigate();
    const SetRoleAccessRequest = async () => {
        try {
            var req = {
                "roleId": id,
                "listModuleMaster": roles
            }
            await roleManagementServive.UpdateRoleAccess(req)
                .then((response: any) => {
                    if (response.data.isError) {

                    }
                    else {
                        toast.success('Permission Updated Successfully');
                        navigate('/role-management')
                    }

                });
        }
        catch (ex: any) {
            toast.warning('Something went wrong...');
           
        }
    }
 
    const handleModuleCheckboxChange = (event: any,item:any,index:any) => { 
        const allRoles = [...roles];
        var obj = allRoles.find(a => a.moduleName === item.moduleName);
        if (obj) {
            if (event.target.checked) {
                obj.isChecked = true;
            }
            else {
                obj.isChecked = false;
                obj.listPageMaster.map((page) => {
                    page.isChecked = false;
                    page.listTabMaster.map((tabs) => {
                        tabs.isChecked = false;
                        tabs.listActionMaster.map((actions) => {
                            actions.isChecked = false;
                        })
                    })
                })
            }
        }
        setRoles(allRoles);
    };

    const handlePageCheckboxChange = (event: any, module: any, item: any, index: any) => {
        const allRoles = [...roles];
        var obj = allRoles.find(a => a.moduleName === module.moduleName);
        if (obj) {
            let pageObj=obj.listPageMaster.find(p=>p.pageName==item.pageName);
            if(pageObj){
                pageObj.isChecked = !!event.target.checked;
                pageObj.listTabMaster.map((tabs) => {
                    tabs.isChecked = !!event.target.checked;
                    
                    tabs.listActionMaster.map((actions) => {
                        actions.isChecked = !!event.target.checked;
                    })
                })
            }
        }
        setRoles(allRoles);
    };

    const handleTabsCheckboxChange = (event: any, module: any, page: any, item: any, index: any) => {
        const allRoles = [...roles];
        var obj = allRoles.find(a => a.moduleName === module.moduleName);
        if (obj) {
            let pageObj = obj.listPageMaster.find(p => p.pageName == page.pageName);
            if (pageObj) {
                let tabObj = pageObj.listTabMaster.find(t => t.tabName == item.tabName);
                if (tabObj) {
                    tabObj.isChecked = !!event.target.checked;
                    if (event.target.checked && !pageObj.isChecked) {
                        pageObj.isChecked = true;
                    }
                    //  else if 
                    //  (!event.target.checked && pageObj.isChecked && !pageObj.listTabMaster.some(t => t.isChecked)) {
                    //     pageObj.isChecked = false;
                    // }
                    tabObj.listActionMaster.map((actions) => {
                        actions.isChecked = !!event.target.checked;
                    });
                }
            }
        }
        setRoles(allRoles);
    };
    
    const handleActionsCheckboxChange = (event: any, module: any, page: any, tabs:any, item: any, index: any) => {
        const allRoles = [...roles];
        var obj = allRoles.find(a => a.moduleName === module.moduleName);
        if (obj) {
            let pageObj = obj.listPageMaster.find(p => p.pageName == page.pageName);
            if (pageObj) {
                let tabObj = pageObj.listTabMaster.find(p => p.tabName == tabs.tabName);
                if (tabObj) {
                    let actionObj = tabObj.listActionMaster.find(p => p.actionName == item.actionName);
                    if(actionObj){
                        if (event.target.checked) {
                            actionObj.isChecked = true;
                            if(!tabObj.isChecked){
                                tabObj.isChecked=true;
                            }
                            if(!pageObj.isChecked){
                                pageObj.isChecked=true;
                            }
                        }
                        else {
                            actionObj.isChecked = false; 
                        }
                    } 
                }
            }
        }
        setRoles(allRoles);
    };
    const cancelbutton = async () => {
        navigate("/role-management");
      };
    
    return (
        <>
              <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Role Management</Typography>
      </Breadcrumbs>
            <Paper elevation={0} sx={{ p: 3 }} >
                <StepsRoles currentStep={1} steps={steps} />
                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2, border: '1px solid', borderColor: '#bdbdbd', p: 3, borderRadius: '10px' }}>
                    <Avatar sx={{ width: 52, height: 52 }}>H</Avatar>
                    <Stack spacing={0}>
                        <Typography variant="subtitle1" component="h5"  >
                            Role
                        </Typography>
                        <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 600 }}>
                            {roleName}
                        </Typography>
                    </Stack>
                </Stack>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>
                        <Item elevation={0}>
                            <Typography variant="h6" align="left" noWrap component="div" sx={{ fontWeight: 500 }}>
                                Modules
                            </Typography>
                            <List >
                                {roles.map((value, idx) => {
                                    const labelId = `checkbox-list-label-${value.moduleId}`;

                                    return (
                                        <CustomListItem
                                            key={value.moduleId}
                                            disablePadding 
                                            >
                                            <ListItemButton key={idx} role={undefined} >
                                                <ListItemIcon  sx={{minWidth: 'auto'}} >
                                                    <Checkbox onChange={(e)=>handleModuleCheckboxChange(e,value,idx)}
                                                        edge="start"
                                                        defaultChecked={value.isChecked}
                                                        //tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.moduleName} />
                                            </ListItemButton>
                                        </CustomListItem>
                                    );
                                })}
                            </List>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Item elevation={0}>
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start" sx={{ mb: 3 }}>
                                <Typography variant="h6" align="left" component="h2" sx={{ fontWeight: 500 }}>
                                    Permissions
                                </Typography>
                                <Stack spacing={2} direction="row"
                                justifyContent="flex-end"
                                alignItems="center"  sx={{ mt: 3 }}> 
                                <Button onClick={() => cancelbutton()} variant="contained" color='primary'>Cancel</Button> 
                                <Button onClick={SetRoleAccessRequest} variant="contained" color='primary'>Update</Button>
                            </Stack>

                                {/* <Button variant="contained" color="primary" onClick={SetRoleAccessRequest}>
                                    Update
                                </Button> */}
                                
                            </Stack> 
                            <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                defaultExpanded={['1']}
                            >
                                {roles.filter(role=>role.isChecked).map((module, idx) => (
                                    <CustomTreeItem key={idx} nodeId={'M_' + idx} label={<Typography variant="h6" sx={{ fontWeight: 600 }}>{module.moduleName}</Typography>}>
                                        {module.listPageMaster.map((pageMaster, idx1) => (
                                            <CustomTreeItem key={idx1 + '_' + pageMaster.pageId} nodeId={'M1_' + pageMaster.pageId} label={
                                                <Stack direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start">
                                                    <Grid container spacing={0} justifyContent="flex-start">
                                                        <Grid item xs={idx1}> 
                                                            <FormControlLabel control={<Checkbox id={'page_' + pageMaster.pageId}
                                                                defaultChecked={pageMaster.isChecked}
                                                                checked={pageMaster.isChecked}
                                                            />} label={pageMaster.pageName}
                                                            onChange={(e)=>handlePageCheckboxChange(e,module,pageMaster,idx)} /> 
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            }>

                                             {pageMaster.listTabMaster.length > 0 &&(
                                                 <TableContainer sx={{ pl: idx1 }}>
                                                    <Table size="small" aria-label="dense table">
                                                        <TableBody>
                                                            {pageMaster.listTabMaster.map((tabMaster, idx2) => (
                                                                <TableRow key={idx2}>
                                                                    <TableCell component="th" scope="row">
                                                                        <FormControlLabel control={<Checkbox id={'tab_' + tabMaster.tabId}
                                                                            defaultChecked={tabMaster.isChecked} />}
                                                                            checked={tabMaster.isChecked}
                                                                            label={tabMaster.tabName}
                                                                            onChange={(e)=>handleTabsCheckboxChange(e,module,pageMaster,tabMaster,idx)} />
                                                                    </TableCell>
                                                                    {tabMaster.listActionMaster.map((actionMaster, idx3) => (
                                                                        <TableCell key={idx3} align="right">
                                                                            <FormControlLabel
                                                                                control={<Checkbox id={'action_' + idx3} defaultChecked={actionMaster.isChecked} />}
                                                                                checked={actionMaster.isChecked}
                                                                                label={actionMaster.actionName}
                                                                                onChange={(e)=>handleActionsCheckboxChange(e,module,pageMaster,tabMaster,actionMaster,idx)}
                                                                                /></TableCell>
                                                                    ))}

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                 </TableContainer>
                                               )}
                                            </CustomTreeItem>
                                        ))}
                                    </CustomTreeItem>

                                ))}

                            </TreeView>
                        </Item>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}