import { Class } from "@mui/icons-material";
import { http } from "./http-common";
class ProjectManagementServive {
  createProject = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Project/Add", data);
  };
  UpdateProjectDetail = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Project/UpdateProjectDetail", data);
  };

  getDropdownData = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Master/DropdownData/GetByName", data);
  };

  getAllProject = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetProjectDetails", req);
  };

  getMyProjects = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetOtherAndMyProject", req);
  };
  geOthersProjects = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetOtherAndMyProject", req);
  };

  EditMileStonePriority = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Milestone/Edit", req);
  };

  getMileStoneData = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Milestone/Search", req);
  };

  getProjectDetailsById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetAllDetailsById?id=${id}`);
  };

  deleteProject = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/Project/DeleteById?id=${id}`);
  };

  updateProject = async (Role: any): Promise<any> => {
    return await http.put<any>("/v1/Project/add/update", Role);
  };

  updateProjectDetail = async (Role: any): Promise<any> => {
    return await http.put<any>("/v1/Project/UpdateProjectDetail", Role);
  };

  getProjectBoardWorkById = async (data: any): Promise<any> => {
    return await http.get<any>("/v1/Project/GetProjectBoardWorkById");
  };

  getSkillSetAll = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Master/TechnicalSkills/GetAll", data);
  };

  getChannel = async (channel: any): Promise<any> => {
    return await http.post<any>("/v1/Master/GetAllChannel", channel);
  };
  getalllistingsecure = async (channel: any): Promise<any> => {
    return await http.post<any>("/v1/Master/DropdownData/GetByName", channel);
  };


  getDepartment = async (depart: any): Promise<any> => {
    return await http.post<any>("/v1/Master/Department/Search", depart);
  };

  getManagerName = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Master/ManagerName/GetByDepartmentId?departmentId=` + data
    );
  };

  getProjtDepartment = async (depart: any): Promise<any> => {
    return await http.post<any>("/v1/Master/Department/Search", depart);
  };

  getProjectOwner = async (own: any): Promise<any> => {
    return await http.post<any>("/v1/Master/Department/Search", own);
  };

  getProjectCurr = async (curr: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetProjectDetails", curr);
  };

  getProjectClientName = async (): Promise<any> => {
    return await http.get<any>("v1/Master/ClientDetail/GetAll");
  };

  getHODName = async (): Promise<any> => {
    return await http.get<any>("/v1/Master/HeadOfDepartment/GetAll");
  };

  uploadProfilePic = async (data: any): Promise<any> => {
    return await http.post<any>("/UserManagement/UploadUserDocument", data);
  };
  getUnEncrypetDocuments = async (data: any): Promise<any> => {
    return await http.get<any>("/UserManagement/DownloadFile/files/"+ data,{responseType:'blob'});
  };
  getUnEncrypetProjectDocuments = async (fileName: any,id:any): Promise<any> => {
    return await http.get<any>(`/v1/Project/DownloadProjectFile/${fileName}?projectId=${id}`,{responseType:'blob'});
  };
  GetResourcesbyDepartmentId = async (data: any): Promise<any> => {
    return await http.post<any>(
      "/UserManagement/GetResourcesbyDepartmentId?departmentId=" + data
    );
  };

  AddAssignProject = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Project/AssignResource", data);
  };

  GetAssignResourceById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetAssignResource?ProjectId=${id}`);
  };

  // /api/v1/Project/GetAssignResource

  getAllActiveSkills = async (): Promise<any> => {
    var req = {
      status: "active",
      search: "",
    };
    return await http.post<any>("/v1/Master/TechnicalSkills/GetAll", req);
  };
  updateProjectId = async (Proj: any): Promise<any> => {
    return await http.post<any>("/v1/Project/UpdateProjectDetail", Proj);
  };
  updateMileStatusDetails = async (param: any): Promise<any> => {
    return await http.post<any>(`/v1/Milestone/UpdateMilestoneStatus`, param);
  };
  UpdateMilestonePaymentStatus = async (param: any): Promise<any> => {
    return await http.post<any>(`/v1/Milestone/UpdateMilestonePaymentStatus`, param);
  };
  UpdateProjectStatus = async (param: any): Promise<any> => {
    debugger;
    return await http.post<any>(`/v1/Project/Project/UpdateProjectStatus`, param);
  };

  getSearch = async (userse: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetProjectDetails", userse);
  };
  GetProjectPinNumber = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetProjectPinNumber`);
  };
  ApproveReject= async (userse: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/ApproveMultipleOfflineWorkHours/ApproveAndReject", userse);
  };
  sendAppLink = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Project/SendApplink", data);
  };
  getMilestonesWithoutSprint = async (id: any): Promise<any> => {
    return await http.post<any>(`/v1/Milestone/GetAllMilestoneWithoutSprint?projectId=${id}`);
  };
  geOthersProjectsLogsheetCount = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetOtherAndMyProjectOfflineCount", req);
  };

   
  getSprintReport = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Reports/GetSprintReport",req);
};

raiseProjectRisk = async (req: any): Promise<any> => {
  return await http.post<any>("/v1/Project/AddProjectRiskforManager",req);
};

getProjectRiskDataById = async (id: any): Promise<any> => {
 
  return await http.get<any>(`/v1/Project/GetProjectRiskForManagerById?Id=${id}`);
};

GetAllMilestoneExtendDateRequest = async (req: any): Promise<any> => {
  debugger
  return await http.post<any>("/v1/Milestone/GetAllMilestoneExtendDateRequests", req);
};

extendMilestoneDate = async (data: any): Promise<any> => {
  return await http.post<any>(`/v1/Milestone/RequestForExtendMileStoneDate`, data);
};

updateMilestoneRequestedDate = async (data: any): Promise<any> => {
  return await http.post<any>(`/v1/Milestone/UpdateMilestoneExtendDateRequest`, data);
};
}

export const projectManagementServive = new ProjectManagementServive();