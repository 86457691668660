import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import theme from "../../theme/darkTheme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import * as Icons from "../../assests/icons/icons";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import Notes from "../.././components/styledComponent/Note";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import { Formik, getIn } from "formik";
import { masterleaveServices } from "../../service/master-service/masterLeave-services";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Leavepagestyle from "./Leavemanagementstyle";
import { Box } from "@mui/material";
import InfoPopover from "../../../src/components/CommonInfoComponent";
// const styleCrossleave = {
//   position: "absolute" as "absolute",
//   right: "24px",
//   top: "16px",
// };
// const styleCross = {
//   position: "absolute" as "absolute",
//   right: "5px",
//   top: "5px",
// };

// const styles = {
//   MonthList: {
//     "&.MuiListItemButton-root": {
//       borderBottom: "1px solid #e1e1e1",

//       "&:hover": {
//         background: theme.palette.primary.light,
//         "& .MuiTypography-root": {
//           color: theme.palette.primary.main,
//         },
//         "& .MuiSvgIcon-root": {
//           color: theme.palette.primary.main,
//         },
//       },
//     },
//     "&.Mui-selected": {
//       background: theme.palette.primary.light,
//       "& .MuiTypography-root": {
//         color: theme.palette.primary.main,
//       },
//       "& .MuiSvgIcon-root": {
//         color: theme.palette.primary.main,
//       },
//     },
//     "& .MuiListItemIcon-root": {
//       minWidth: "42px",
//       opacity: 0.6,
//     },
//   },
// };

const initialvalues = {
  id: "",
  leaveType: "",
  numberOfLeaves: "",
};

enum LeaveTypeEnum {
  "Casual Leave" = 1,
  "Sick Leave" = 2,
  "Earned Leave" = 3,
  "Maternity Leave" = 4,
  "Paternity Leave" = 5,
  Others = 6,
}

interface Notice {
  id: "";
  leaveType: any;
  numberOfLeaves: string;
}

let obj: Notice = {
  id: "",
  leaveType: "",
  numberOfLeaves: "",
};

export default function SetupLeaves() {
  const _permission = useSelector(
    (state: any) => state.LeavePermissionApp.LeavePermission
  );
  const navigate = useNavigate();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [leavess, setLeaves] = useState<Notice[]>([]);
  const [age, setAge] = React.useState("");
  const [formValues, setFormValues] = React.useState<Notice>(obj);
  const [id, setId] = useState("");
  const [leaveType, setLeaveType] = React.useState(formValues?.leaveType);
  const [numberOfLeaves, setNumberOfLeaves] = React.useState(
    formValues?.numberOfLeaves
  );
  const [isSubmit, setSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  let state = { id: id ?? "" };
  let pageTitle = "Add Leave";
  if (state.id.length > 0) {
    pageTitle = "Update Leave";
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // --------------------------------
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  //  -----------------------------------------

  const [openLeave, setLeaveOpen] = React.useState(false);

  const handleClickOpen = () => {
    setLeaveOpen(true);
    setSubmit(false);
    setErrorMessage(false);
    if (id === "" && id.length === 0) {
      setLeaveType("");
      setNumberOfLeaves("");
    }
  };

  const handleCloseLeave = () => {
    setLeaveOpen(false);
    setTimeout(() => {
      setId("");
    }, 500);
  };

  const [openAlert, setAlertOpen] = React.useState(false);

  const handleClickOpenAlert = () => {
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  // -------------------------------------------
  useEffect(() => {
    if (_permission.page_ManageSetupLeaves_Access !== true) {
      navigate("/dashboard");
    }
    getLeavesDetails();
  }, []);

  const getLeavesDetails = async () => {
    await masterleaveServices.getLeaves(value).then((response: any) => {
      if (response.data.isError) {
      } else {
        setLeaves(response?.data);
      }
    });
  };

  // const handleChange = (event: SelectChangeEvent) => {
  //     setAge(event.target.value as string);
  // };

  const addLeaveDetails = async (values: any) => {
    debugger
      await masterleaveServices.createLeave(values).then((response: any) => {
        if (response.data.isError) {
        } else {
          setLeaveOpen(false);
          toast.success("Leave added successfully.",{
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });
          getLeavesDetails();
        }
      }) .catch((error) => {
        if (error.data.Error.length > 0)
        
          toast.warning(error.data.Error[0], {
            onOpen: () => setButtonDisable(true),
           onClose: () => setButtonDisable(false),
           
          });

        return error;
      });
  };
  const handleChangeLeaveType = (value: string) => {
    setNumberOfLeaves(value);
  };

  const deleteLeaveData = async (id: any, e: any) => {
    e.stopPropagation();
    setAlertOpen(true);
    setCurrentId(id);
  };

  const DeleteLeave = async () => {
    setAlertOpen(false);
    try {
      await masterleaveServices.deleteLeave(currentId).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Leave Deleted successfully.");

          getLeavesDetails();
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getLeaveDatabyId = async (id: any, e: any) => {
    e.stopPropagation();
    await masterleaveServices.getLeaveById(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setId(response?.data.id);
        setLeaveType(response?.data.leaveType);
        setNumberOfLeaves(response?.data.numberOfLeaves);
        setLeaveOpen(true);
      }
    });
  };

  const editLeaveDetails = async (values: any) => {
    try {
      await masterleaveServices.updateLeave(values).then((response: any) => {
        if (response.data.isError) {
        } else toast.success("Leave Updated successfully");
        setId("");
        getLeavesDetails();
        setLeaveOpen(false);
      });
      getLeavesDetails();
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {
          onOpen: () => setButtonDisable(true),
         onClose: () => setButtonDisable(false),
         
        });
      });
    }
  };

  const fieldValidationSchema = Yup.object().shape({
    leaveType: Yup.string().required("Leave Type is required"),
    numberOfLeaves: Yup.string()
      .required("Number of Leave is Required")
      .matches(/^[0-9]+$/, "Number of Leave can only contains Numbers"),
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Leave Management</Typography>
        <Typography color="text.primary">Setup Leaves</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
      {/* <Notes
          NoteText="Here,admin  can set up leaves for the organization like the total number of casual leaves, sick leave, earned leaves to be provided to an employee."
        /> */}
        <Stack
          direction={{xs:'column', sm:'row'}}
          spacing={2}
          justifyContent="space-between"
          alignItems={{xs:'strech', sm:"center"}}
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Setup Leaves
          </Typography>

          <Box>
                
              <InfoPopover  onInput={"Setup"}/>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="strech" spacing={2}>
            {_permission.action_ManageSetupLeaves_Add_Access && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                sx={{width:'100%'}}
              >
                Add Leaves
              </Button>
            )}
          </Stack>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper} elevation={0}>
              <Table
                sx={{ minWidth: "100%" }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr No.</TableCell>
                    <TableCell>Leave Type</TableCell>
                    <TableCell>Number of leaves</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leavess.map((user, idx) => (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{idx + 1}</TableCell>
                        <TableCell>{LeaveTypeEnum[user.leaveType]}</TableCell>
                        <TableCell>{user.numberOfLeaves}</TableCell>
                        <TableCell align="center">
                          <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'center'}>
                            {_permission.action_ManageSetupLeaves_Edit_Access && (
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  color="primary"
                                  onClick={(e) => {
                                    getLeaveDatabyId(user.id, e);
                                  }}
                                >
                                  <Icons.Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            {_permission.action_ManageSetupLeaves_Delete_Access && (
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  size="small"
                                  onClick={(e) => deleteLeaveData(user.id, e)}
                                >
                                  <Icons.Delete />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={openLeave}
        onClose={Button}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Formik
          initialValues={{
            leaveType: leaveType,
            numberOfLeaves: numberOfLeaves,
            id: id,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);

              if (id && id.length > 0) {
                // values.noticeId=noticeId
                await editLeaveDetails(values);
              } else {
                await addLeaveDetails(values);
              }
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status === 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    position: "relative",
                  }}
                >
                  {/* {id ? 'Update Label': 'Add Label'} */}
                  {pageTitle}
                  <IconButton
                    aria-label="delete"
                    sx={Leavepagestyle.styleCrossleavesetupleaves}
                    onClick={handleCloseLeave}
                  >
                    <ClearIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Stack direction="row" spacing={2} sx={{ mb: 2, pt: 2 }}>
                    <FormControl sx={{ width: "50%" }}>
                      <TextField
                        id="outlined-basic"
                        label="Select Leave Type"
                        select
                        variant="outlined"
                        name="leaveType"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e) => {
                          values.leaveType = e.target.value;
                          setLeaveType(e.target.value);
                        }}
                        value={leaveType}
                        error={Boolean(
                          getIn(touched, "leaveType") &&
                            getIn(errors, "leaveType")
                        )}
                        helperText={
                          getIn(touched, "leaveType") &&
                          getIn(errors, "leaveType")
                        }
                      >
                        <MenuItem value={1}>Casual Leave</MenuItem>
                        <MenuItem value={2}>Sick Leave</MenuItem>
                        <MenuItem value={3}>Earned Leave</MenuItem>
                        <MenuItem value={4}>Maternity Leave</MenuItem>
                        <MenuItem value={5}>Paternity Leave</MenuItem>
                        <MenuItem value={6}>Others</MenuItem>
                      </TextField>
                    </FormControl>
                    {/* <TextField sx={{ width: "50%" }} id="outlined-basic" placeholder='Add No. of leave(days)' variant="outlined" */}
                    <TextField
                      sx={{ width: "50%" }}
                      id="outlined-basic"
                      label="Add No. of leave(days)"
                      variant="outlined"
                      error={Boolean(
                        getIn(touched, "numberOfLeaves") &&
                          getIn(errors, "numberOfLeaves")
                      )}
                      fullWidth
                      helperText={
                        getIn(touched, "numberOfLeaves") &&
                        getIn(errors, "numberOfLeaves")
                      }
                      name="numberOfLeaves"
                      value={numberOfLeaves}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").trimStart().substring(0,2);
                        values.numberOfLeaves = value;
                        setNumberOfLeaves(value);
                        // setErrorMessage(false);
                      }}
                    />
                  </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 3, pt: 0 }}>
                  <Button variant="outlined" onClick={handleCloseLeave}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setErrorMessage(true);
                      setSubmit(true);
                    }}
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openAlert}
        onClose={setAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="delete"
          sx={Leavepagestyle.styleCrosssetupleaves}
          onClick={handleCloseAlert}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={Leavepagestyle.deletepopupheading}
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Are you sure you want to delete this
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseAlert}>
            No
          </Button>
          <Button variant="contained" onClick={DeleteLeave}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
