import { Class } from "@mui/icons-material";
import { Observable } from "@reduxjs/toolkit";
import UserManagement from "../pages/users/UserManagement";
import { userModel } from "../pages/users/userModel";
import { http } from "./http-common";
class UserManagementServive{
    createUser = async (user: any): Promise<any> => {
        return await http.post<any>("/UserManagement/CreateUserDetail", user);
    };
    getUsers = async (user : any): Promise<any> => {
        return await http.post<any>("/UserManagement/GetAllUsers", user);
    };
    deleteUser = async (id: any): Promise<any> => {
         return await http.delete<any>(`/UserManagement/DeleteUserDetailById/` +id);
     }
    getUserById = async (id: any): Promise<any> => {
        return await http.get<any>(`/UserManagement/GetUserDetailById/` +id);
    }
    updateUser = async (user: any): Promise<any> => {
        return await http.put<any>("/UserManagement/UpdateUserDetail", user);
    };
    getDropdownData = async (data : any): Promise<any> => {
        return await http.post<any>("/v1/Master/DropdownData/GetByName", data);
    };
    getManagerName = async (data : any): Promise<any> => {
        return await http.post<any>(`/v1/Master/ManagerName/GetByDepartmentId?departmentId=` +data);
    }; 
    UpdateHeadOfDepartment = async (data : any): Promise<any> => {
        return await http.put<any>(`/v1/Master/HeadOfDepartment/Update`,data);
    };
    UploadUserDocument = async (data : any): Promise<any> => {
        return await http.post<any>("/UserManagement/UploadUserDocument", data);
    };
    getAllUsersByProjectId = async (data : any): Promise<any> => {
        return await http.post<any>("/UserManagement/GetAllUsersByProjectId", data);
    };

    compareUsers = async (data : any): Promise<any> => {
        return await http.post<any>("/UserManagement/VerifyUserName", data);
    };
    checkUserIdAlreadyExistsForEmployee = async (data : any): Promise<any> => {
        return await http.put<any>(`/UserManagement/IsUserExistForEmployeeId`,data);
    };
    getInsuranceDetails = async (data : any): Promise<any> => {
        return await http.get<any>('/UserManagement/UserDetailForInsuranceByDepartmentId?departmentId=' +data);
    };
    getTimezone = async (data : any): Promise<any> => {
        return await http.get<any>('/UserManagement/TimeZoneDropDownList' +data);
    };

}

export const userManagementServive = new UserManagementServive()

