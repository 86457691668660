import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
  Modal,
  TableCell,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Notes from "../.././components/styledComponent/Note";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchBar from "../../components/styledComponent/SearchBar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker } from 'react-rainbow-components';
import { toast } from "material-react-toastify";
import { noticeService } from "../../service/notice-service";
// import { bool } from "yup";
// import { boolean } from "yup/lib/locale";
import * as Yup from "yup";
import { Formik, getIn } from "formik";
import ReactQuill from "react-quill";
import { title } from "process";
import NotFound from "../../components/styledComponent/NotFound";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import { Visibility } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Notificationstyle from "./Notificationstyle";
import InfoPopover from "../../../src/components/CommonInfoComponent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const styles: any = {
  EventInfoCard: {
    border: "1px solid #EEEEEE",
    padding: "18px 14px",
    bordeRadius: "5px",
    cursor: "pointer",
    minHeight: '229px',
    display: "flex",
    flexDirection: "column",
    "word-break": "break-all",

    justifyContent: "space-between",
    "&:hover": {
      boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
    },
  },
  innerSpace: {
    // padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  EventName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.primary.dark,
  },
  EventId: {
    letterSpacing: "0.1px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    color: "#232360",
  },
  EventDesc: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginTop: "15px",
  },
  EventDate: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginLeft: "10px",
  },
  LineClamp: {
    marginTop: "15px",
    whiteSpace: "nowrap",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  DialogTitle: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: " 39px",
    color: theme.palette.primary.dark,
  },
  Schedulefor: {
    fontWeight: 400,
    fontSize: "16.2314px",
    lineHeight: "19px",
    color: " #212121",
    margin: 0,
  },
  InnerHtml: {
    marginBottom: "12px",
    "& p": {
      margin: 0,
      textAlign: "center",
      fontSize: "24px",
      color: theme.palette.primary.dark,
    },
  },
  TitleLabel: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#232360",

  },
  TitleValue: {
    fontWeight: 600,
    fontSize: " 18px",
    lineHeight: "21px",
    color: " #424242",
    marginLeft: "8px",
    "word-break": "break-all",
  },
  Active: {
    fontWeight: 400,
    color: theme.palette.success.dark,
    m: 0,
  },

  Schedule: {
    fontWeight: 400,
    color: "#5F6388",
    m: 0,
  },

  Expired: {
    fontWeight: 400,
    color: theme.palette.error.dark,
    m: 0,
  },
};

const styleCross = {
  position: "absolute" as "absolute",
  right: "03px",
  top: "03px",
};

interface Notice {
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  startDate: Date;
  endDate: Date;
}
interface NoticeView {
  id: string;
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  status: string;
  startDate: Date;
  endDate: Date;
}

const initialvalues = {
  id: "",
  title: "",
  description: "",
  visibility: "",
  notice: "",
  startDate: "",
  endDate: "",
};

export default function Notice(modalOpen: any) {
  const _permission = useSelector((state: any) => state.NotificationPermissionApp.NotificationPermission);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openStatus = Boolean(anchorEl);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [notices, setNotices] = useState<any>([]);
  const [FilterNotices, setFilterNotices] = useState<any>([]);
  const [status, setStatus] = useState("Status");
  const navigate = useNavigate();
  enum Status {
    All = 0,
    Active = "1",
    Scheduled = "2",
    Expired = "3",
  }
  type StatusList = keyof typeof Status;

  ///update programming

  const [user, setUser] = useState(initialvalues);
  // const { id } = useParams();
  //alert(id);
  // let state = { userid: id ?? "" };
  // const [previousvalue, setPreviousvalue] = React.useState(state.userid);
  // const [userIdHook, setUserIdHook] = React.useState(previousvalue);


  let obj: Notice = {
    title: "",
    description: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    notice: false,
  };
  const [id, setId] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formValues, setFormValues] = React.useState<Notice>(obj);
  const [title, setTitle] = React.useState(formValues.title);
  const [description, setDescription] = React.useState(formValues?.description);
  const [visibility, setVisibility] = React.useState(formValues?.visibility);
  const [startDate, setStartDate] = React.useState("");

  const [endDate, setEndDate] = useState("");
  const [notice, setNotice] = React.useState(formValues?.notice);
  const [visible, setVisible] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const [page, setPage] = React.useState(0);
  var noticeSearch = { id: "", name: "All Channel" };
  const [noticeHook, setNoticeHook] = useState(noticeSearch);
  let statusValue = "All";
  const [statusDataHook, setStatusDataHook] = useState(statusValue);

  ////////Pagination Programming
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const pageCount = Math.ceil(notices.length / rowsPerPage);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  ////////over

  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseStatus = () => {
    setAnchorEl(null);

  };
  function removeTagsAndAmp(str: any) {
    // Remove HTML tags
    str = str.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str.replace(/&amp;/g, '&');
    return str;
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmit(false);
    setErrorMessage(false);
    if (id == "" && id.length == 0) {
      setEndDate("");
      setStartDate("");
      setDescription("");
      setVisibility("");
      setTitle("");
      setVisible(false);
      setshowHelpText(false);
     
    }
  };

  const handleClose = () => {
    setOpen(false);
    setId("");
    setVisible(false);
  };
const [showHelpText , setshowHelpText]= React.useState(false);

const [formSubmitted, setFormSubmitted] = useState(false);

  const [openView, setOpenView] = React.useState(false);
  const [noticeView, setNoticeView] = React.useState<NoticeView | null>(null);
  const handleClickOpenView = async (id: any) => {
    await noticeService.getNoticeViewId(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setNoticeView(response?.data);
        setOpenView(true);
      }
    });
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const [noticeOpen, setnoticeOpen] = React.useState(false);

  useEffect(() => {
    if (_permission.page_NoticeManagement_Access !== true) {
      navigate("/dashboard");
    }
    setnoticeOpen(modalOpen);
    //getNoticesDatabyId();
    getNoticeDetails(statusValue);
  }, []);

  const getNoticeDetails = async (status: any, searchValue: string = "") => {
    var input = {
      status: status == "All" ? 0 : status,
      search: searchValue,
    };
    await noticeService.getNotice(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        let array: any[] = [];


        if (response?.data.length > 0) {
          response?.data.map((a: any) => {
            
            a.formattedHtml = a?.description?.replace(/<[^>]+>/g, "");
            array.push(a);
          });
        }

        setNotices(array);
      }
    });
  };

  const filterNoticebyStatus = (status: any) => {
    statusValue = status;
    getNoticeDetails(status);
    setStatusDataHook(status);
  };

  const createNotice = async (values: any) => {

    let notice = values;
    if (visible === false) {
      let dateT = new Date().toISOString();
      dateT = dateT.slice(0, 10) + 'T00:00:00Z';
      notice.startDate = dateT;
    }
    notice.notice = !visible;
    notice.visibility = isChecked;
    await noticeService.createNotice(notice).then((response: any) => {
      if (response.data.isError) {
      } else
        setOpen(false);
      toast.success("Notice added successfully.", {
        onOpen: () => setButtonDisable(true),
        onClose: () => setButtonDisable(false),
      });

      getNoticeDetails(statusValue);
    }).catch((error) => {
      if (error.data.Error.length > 0)

        toast.warning(error.data.Error[0], {
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),

        });

      return error;
    });

  };

  const deleteNoticeData = async (id: any, e: any) => {
    e.stopPropagation();
    setOpenDelete(true);
    setCurrentId(id);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const DeleteNotice = async () => {
    setOpenDelete(false);
    try {
      await noticeService.deleteNotice(currentId).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Notice Deleted successfully.");

          getNoticeDetails(statusValue);
        }
      });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const getDate = (dateObject: Date) => {
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00Z`;
  }

  /////update programming
  const getNoticesDatabyId = async (id: any, e: any) => {
    e.stopPropagation();
    await noticeService.getNoticeById(id).then((response: any) => {
      if (response.data.isError) {
      } else {

        setId(response?.data.id);
        setNotice(response?.data);
        setTitle(response?.data.title);
        setVisibility(response?.data.visibility);
        if (response?.data.visibility) {
          setIsChecked(true);
        }
        setEndDate(response?.data.endDate);
        setStartDate(response?.data.startDate);
        setNotice(response?.data.notice);
        setDescription(response?.data.description);
        setVisible(response?.data.status == "Scheduled");
        //setVisible(response?.data. startdate == 'Schedule' ? 'now' : status);
        setOpen(true);
      }
    });
  };

  const editNoticeDetails = async (values: any) => {

    let notice = values;
    if (visible === false) {
      let dateT = new Date().toISOString();
      dateT = dateT?.slice(0, 10) + 'T00:00:00Z';
      notice.startDate = dateT;
    }
    notice.notice = !visible;

    await noticeService.updateNotice(values).then((response: any) => {
      if (response.data.isError) {
      } else toast.success("Notice Updated successfully", {
        onOpen: () => setButtonDisable(true),
        onClose: () => setButtonDisable(false),
      });

      setId("");
      getNoticeDetails(statusValue);
      setOpen(false);

      getNoticeDetails(statusValue);
    })
      .catch((error) => {
        if (error.data.Error.length > 0)

          toast.warning(error.data.Error[0], {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),

          });
        return error;

      });

  };

  const fieldValidationSchema = Yup.object().shape({
    title: Yup.string()
      // .matches(
      //   /^[A-Za-z0-9 ]+$/,
      //   "Title can only contain Alphabets and Numbers"
      // )
      // .max(150, "Title can only contain 150 Alphabets")
      .required("Title Name is required"),
    startDate: (visible == true ? Yup.string().required('Start date  is required') : Yup.string()),

    endDate: Yup.string().required("End date is Required"),
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const triggerSearch = async (newValue: string) => {
    getNoticeDetails(statusDataHook, newValue);
    setPage(0);
  };


  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Notification</Typography>
        <Typography color="text.primary">Notice</Typography>

        {/* <Typography color="text.primary">Notofications</Typography> */}
        {/* <Typography color="text.primary">Notice</Typography> */}
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        {/* <Notes
          NoteText="Here, admin will create and post the Notice on Seasia Connect wall. The notice post added by admin will be shown to employees."
        /> */}
        <Stack
          sx={Notificationstyle.topbar}
          spacing={{ xs: 0, sm: 2 }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            All Notices
          </Typography>
          <Box>

            <InfoPopover onInput={"Notice"} />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction={{ xs: 'column', sm: "row" }} sx={Notificationstyle.topright} spacing={2} >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                id="basic-button"
                aria-controls={openStatus ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openStatus ? "true" : undefined}
                onClick={handleClick}
                sx={{ justifyContent: "space-between" }}
                variant="selectSmall"
              >
                {statusDataHook} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openStatus}
                onClose={handleCloseStatus}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterNoticebyStatus("All");
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterNoticebyStatus("Active");
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterNoticebyStatus("Expired");
                  }}
                >
                  Expired
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseStatus();
                    filterNoticebyStatus("Scheduled");
                  }}
                >
                  Scheduled
                </MenuItem>
              </Menu>
              <Box sx={Notificationstyle.searchbarstyle}>
                <SearchBar
                  setSearchValue={setSearchValue}
                  callBackFunction={triggerSearch}
                />
              </Box>
            </Stack>
            {_permission.action_ManageEventsNoticeListing_Add_Access && (
              <Button
                variant="contained"
                onClick={handleClickOpen}
                color="primary"
                startIcon={<AddIcon />}
              >
                Add Notice
              </Button>
            )}
          </Stack>
        </Stack>
        {notices && notices.length > 0 ? (
          <Grid container spacing={2}>
            {notices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event: any) => (
                <Grid item xs={12} lg={3}>
                  <Paper
                    elevation={0}
                    sx={[styles.EventInfoCard]}
                    onClick={() => handleClickOpenView(event.id)}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        component="p"
                        sx={[styles.EventId]}
                      ></Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          justifyContent="space-between"
                          component="p"
                          sx={[styles[`${event.status}`], styles.innerSpace]}
                        >
                          <Icons.dots />
                          &nbsp;{event.status}
                        </Typography>
                        {_permission.action_ManageEventsListing_Edit_Access && event?.status !== "Expired" && (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={(e) => {
                                getNoticesDatabyId(event.id, e);
                              }}
                            >
                              <Icons.Edit />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Stack>

                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant="body1"
                        component="h4"
                        sx={[styles.EventName]}
                      >
                        {event?.title?.length > 30
                          ? event?.title.slice(0, 30 - 1) + "..."
                          : event?.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        sx={[styles.EventDesc]}
                      >
                        {/* {event.formattedHtml?.length > 52
                        ? event.formattedHtml.slice(0, 52 - 1) + "..."
                        : event.formattedHtml} */}
                        {removeTagsAndAmp(event?.description)?.length > 30
                          ? removeTagsAndAmp(event?.description).slice(0, 30 - 1) + "..."
                          : removeTagsAndAmp(event?.description)}
                      </Typography>
                    </Box>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: 3 }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography component="p" sx={[styles.EventId]}>
                          Created
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={[styles.EventDate]}
                        >
                          {/* {event.createdDate} */}
                          {`${moment(event?.createdDate).format(
                            "DD MMM, YYYY"
                          )}`}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {_permission.action_ManageEventsListing_Delete_Access && (
                          <Tooltip title="Delete">
                            <IconButton
                              sx={{ ml: 1 }}
                              onClick={(e) => deleteNoticeData(event.id, e)}
                            >
                              <Icons.Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        ) : (
          <NotFound NotfoundText="No Result Found" />
        )}
        <TablePagination
          labelRowsPerPage="Cards per page"
          rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
          component="div"
          count={notices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        
          labelDisplayedRows={() => `Total: ${notices?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>

      <Dialog
        // onClose={Button}
        fullScreen={fullScreen}
        open={open}
        onClose={Button}
        maxWidth="md"
        aria-labelledby="responsive-dialog-title"
        scroll="body"
      >
        <Formik
          initialValues={{
            title: title,
            description: description,
            visibility: visibility,
            // notice: notice,
            startDate: startDate,
            endDate: endDate,
            // id:noticeId
            id: id,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);

              if (
                values.endDate &&
                values.startDate &&
                values.endDate < values.startDate
              ) {
                toast.warning("Start date must be less than end date", {
                  onOpen: () => setButtonDisable(true),
                  onClose: () => setButtonDisable(false),
                });
                return false;
              }
              if (id && id.length > 0) {
                // values.noticeId=noticeId
                await editNoticeDetails(values);
              } else {
                await createNotice(values);
              }
              //await editNoticeDetails();
              // alert();
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {

                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <input type="hidden" name="GuId" value=""></input>
                <IconButton sx={styleCross} onClick={handleClose}>
                  <ClearIcon />
                </IconButton>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={[styles.DialogTitle]}
                >
                  New Notice
                </DialogTitle>
                <DialogContent>
                  <Box sx={{ pt: 0.5 }}>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Title "
                        variant="outlined"
                        error={Boolean(
                          getIn(touched, "title") && getIn(errors, "title")
                        )}
                        fullWidth
                        helperText={
                          getIn(touched, "title") && getIn(errors, "title")
                        }
                        label="Title"
                        name="title"
                        value={title}
                        onBlur={handleBlur}
                        //  onChange={handleChange}
                        onChange={(e) => {
                         // let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,\s]$/g, "").trimStart().slice(0, 149);
                         // value = value.replace(/\s{3,}$/g, "");
                         
                         let value = e.currentTarget.value
                         value = value.replace(/\s{3,}$/g, "").trimStart().slice(0, 149);
                          values.title = value
                          setTitle(value);
                          // setErrorMessage(false);
                        }}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      />
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "right" }}
                      >
                        Max 150 words
                      </Typography>
                    </Box>

                    <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
                      <Typography
                        component="p"
                        sx={[styles.Schedulefor]}
                        gutterBottom
                      >
                        Scheduled For
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ ml: 3 }}
                        // defaultValue="now"
                        defaultValue={visible ? "future" : "now"}
                      >
                        <FormControlLabel
                          value="now"
                          control={<Radio />}
                          label="Now "
                          onClick={() => setVisible(false)}
                        />

                        <FormControlLabel
                          value="future"
                          control={<Radio />}
                          label="Scheduled for future"
                          onClick={() => setVisible(true)}
                        />
                      </RadioGroup>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                      {visible && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="Start Date"
                            borderRadius="semi-square"
                            label = "Start Date"
                            labelAlignment="left"
                            style={{ width: '30%'}}
                            minDate={moment().subtract(0, "years").toDate()}
                            maxDate={moment().add(7, "years").toDate()}
                            className="error"
                            value={startDate}
                            onChange={(newValue: any) => {
                              let dateT = getDate(newValue);
                              values.startDate = dateT;
                              setStartDate(dateT);
                              setErrorMessage(false);
                              setSubmit(true);
                              setshowHelpText(false);
                            }}
                            error={ showHelpText && !values.startDate ? "Start Date is required" : ""}

                            // renderInput={(params: any) => <TextField
                            //   onKeyDown={(e: any) => {

                            //     e.preventDefault();
                            //   }}
                            //   {...params}
                            //   readOnly={true} // Disable user input
                            //   autoComplete="off"
                            //   onBlur={handleBlur}
                            //   error={
                            //     getIn(touched, "startDate") &&
                            //     getIn(errors, "startDate")

                            //   }
                            //   helperText={
                            //     getIn(touched, "startDate") &&
                            //     getIn(errors, "startDate")
                            //   }
                            //   sx={{
                            //     "& .MuiSvgIcon-root": {
                            //       fill: theme.palette.primary.main,
                            //     },
                            //   }}
                            // />
                            // }
                          />
                        </LocalizationProvider>
                      )} 
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          placeholder="End Date"
                          borderRadius="semi-square"
                          style={{ width: '30%'}}
                          label = "End Date"
                          labelAlignment="left"
                          minDate={moment().subtract(0, "years").toDate()}
                          maxDate={moment().add(7, "years").toDate()}
                          className="error"
                          value={endDate}
                          onChange={(newValue: any) => {
                            let dateT = getDate(newValue);
                            values.endDate = dateT;
                            setEndDate(dateT);
                            setErrorMessage(false);
                            setSubmit(false);
                            setshowHelpText(false);
                          }}
                         // error={endDate ? "" : (showHelpText ? "End Date is required" : "")}
                         error={ showHelpText && !values.endDate ? "End Date is required" : ""}

                          // renderInput={(params: any) => <TextField
                          //   onKeyDown={(e: any) => {

                          //     e.preventDefault();
                          //   }}
                          //   {...params}
                          //   readOnly={true} // Disable user input
                          //   autoComplete="off"
                          //   onBlur={handleBlur}
                          //   error={
                          //     getIn(touched, "endDate") &&
                          //     getIn(errors, "endDate")

                          //   }
                          //   helperText={
                          //     getIn(touched, "endDate") &&
                          //     getIn(errors, "endDate")
                          //   }
                          //   sx={{
                          //     "& .MuiSvgIcon-root": {
                          //       fill: theme.palette.primary.main,
                          //     },
                          //   }}
                          // />
                          // }
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack direction="row" alignItems="start" sx={{ mt: 3 }}>
                      <Checkbox
                        defaultChecked
                        sx={{ p: 0, mr: 2 }}
                        id="visibility"
                        name="visibility"
                        value={visibility}
                        checked={isChecked}
                        onChange={handleOnChange}
                      />
                      <Typography component="p" sx={[styles.Schedulefor]}>
                        By default, notice will be display and sort based on the
                        start date. Selecting this option will show this notice
                        at top in notice lists.
                      </Typography>
                    </Stack>

                    <Box sx={{ mt: 3 }}>
                      <Typography
                        component="p"
                        sx={[styles.Schedulefor]}
                        gutterBottom
                      >
                        Description
                      </Typography>
                      <Grid item xs={12} sm={12}>
                        <ReactQuill
                          // modules={AppConstants.EditorSetting}
                          theme="snow"
                          value={values.description}
                          onChange={(newValue) => {
                            values.description = newValue;
                            let SpaceClear = newValue.replace(/(&nbsp;|<br>|<br \/>)/g, '');
                            values.description = SpaceClear;


                            setDescription(SpaceClear);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],

                              [{ size: [] }],

                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],

                              [
                                { list: "ordered" },
                                { list: "bullet" },

                                { indent: "-1" },
                                { indent: "+1" },
                              ],

                              ["link", "image", "video"],

                              ["clean"],
                            ],
                          }}
                        />
                      </Grid>
                    </Box>
                  </Box>
                </DialogContent>

                <DialogActions sx={{ p: 3, pt: 0 }}>
                  <Button autoFocus onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                    onClick={() => {
                      setErrorMessage(true);
                      setSubmit(true);
                      setshowHelpText(!endDate || !startDate);
                      
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openView}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          View Notice
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleCloseView}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Title:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {noticeView?.title}
              </Typography>
            </Stack>
            <Typography
              justifyContent="space-between"
              component="p"
              sx={[styles[`${noticeView?.status}`], styles.innerSpace]}
            >
              <Icons.dots />
              &nbsp;
              {noticeView?.status}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Scheduled For:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {`${moment(noticeView?.startDate).format("DD MMM, YYYY")}`} to
                {`${moment(noticeView?.endDate).format("DD MMM, YYYY")}`}
              </Typography>
            </Stack>
          </Stack>
          <Paper variant="outlined" sx={{ p: 2, mt: 3, textAlign: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                "word-break": "break-all",
                "& img": {
                  maxWidth: "100%",

                },
              }}
              dangerouslySetInnerHTML={{
                __html: noticeView?.description as string,
              }}
            ></Typography>
          </Paper>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleCloseView}
          >
            Back to List
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openDelete}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Notice
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Do you really want to Delete this, Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteNotice}>
                Confirm
                
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}
