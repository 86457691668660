import { http } from "../http-common";
class IOservices {

    updateIO = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/AddAndUpdateIO", desig);
    };

    createIO = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/CreateIO", desig);
    };

    GetAllUserWithIO = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/GetAllUserWithIO", desig);
    };

    dropdownUsers = async (data:any): Promise<any> => {
        return await http.post<any>("/UserManagement/GetAllUsers",data);
    }
    deleteIOById= async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/KRA/DeleteIoByUserId?UserId=${id}`);
    }
}

export const IOServices = new IOservices();