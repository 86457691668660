import { combineReducers } from 'redux';
const Fill_Meetings_Permission = 'Fill_Meetings_Permission';
const Empty_Meetings_Permission = 'Empty_Meetings_Permission';

const defaultPermissions = 
  {
    access: false,
    module_ManageMeetings_Access:false,
    
    page_ManageMeeting_Listing_Access: false,
    tab_ManageMeeting_Listing_Access: false,
    action_ManageMeeting_Listing_Add_MOM_Access: false,
    action_ManageMeeting_Listing_View_MoM_Access: false,
    action_ManageMeeting_Listing_Delete_MoM_Access: false,

    tab_ManageMeeting_Listing_Invited_To_Access: false,
    action_ManageMeeting_Listing_Invited_To_View_MOM_Access: false,

    tab_ManageMeeting_Listing_Add_Meeting_Access: false,
    
  }

export function FillMeetingsPermission(module) {
  return {
    type: Fill_Meetings_Permission,
    module,
  }
}
export function EmptyMeetingsPermission() {
  return {
    type: Empty_Meetings_Permission,defaultPermissions
  }
}

function MeetingsPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Meetings_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
                permissions.access=true;
                permissions.module_ManageMeetings_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                  data.listPageMaster.forEach((page) => {
                    if (page.pageName === "Meeting Listing") {
                      permissions.page_ManageMeeting_Listing_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "My Meeting") {
                            permissions.tab_ManageMeeting_Listing_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "Add MOM") {
                                  permissions.action_ManageMeeting_Listing_Add_MOM_Access = true;
                                }
                                if (actions.actionName === "View MoM") {
                                  permissions.action_ManageMeeting_Listing_View_MoM_Access = true;
                                }
                                if (actions.actionName === "Delete MoM") {
                                  permissions.action_ManageMeeting_Listing_Delete_MoM_Access = true;
                                }
                              });
                            }
                          }
                        });
                      }
                    }
                    if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Invited To") {
                            permissions.tab_ManageMeeting_Listing_Invited_To_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "View MOM") {
                                  permissions.action_ManageMeeting_Listing_Invited_To_View_MOM_Access = true;
                                }
                              });
                            }
                          }
                        });
                      }
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Add Meeting") {
                            permissions.tab_ManageMeeting_Listing_Add_Meeting_Access = true;
                          }
                        });
                      }
                  });
                }
                return state = permissions;
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_Meetings_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const MeetingsPermissionApp = combineReducers({
    MeetingsPermission
});

export default MeetingsPermissionApp;