import { http } from "./http-common";
class reportManagementCTO {
    getAllHod = async (hod:any): Promise<any> => {
        return await http.post<any>("/v1/Master/DropdownData/GetByName", hod);
    };
   
    getResourcesByDepartment = async (data: any): Promise<any> => {
        return await http.post<any>(
            `/UserManagement/GetResourcesbyDepartmentId?departmentId=${data}`
        );
      };  

    getProjectsByHodId = async (hod:any): Promise<any> => {
        return await http.post<any>("/v1/Master/DropdownData/GetByName", hod);
    };  
    getProjectsForAdmin = async (hod:any): Promise<any> => {
        return await http.post<any>("/v1/Project/GetProjectDetails", hod);
    }; 
    getProjectsDetailByProjectIdAdmin = async (hod:any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetAllDetailsById?id=${hod}`);
    }; 
    getProjectByClientId = async (hod:any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetProjectsListByClientId?ClientUserId=${hod}`);
        
    }; 
    getCeoDashboardReport = async (data:any): Promise<any> => {
        return await http.post<any>("v1/Reports/GetReport",data);
      };
      matricesScheduler = async (): Promise<any> => {
        return await http.post<any>("v1/Reports/SchedulerToProjectReportData",);
      };

      getReport = async (data: any): Promise<any> => {
        return await http.post<any>(
          `/v1/Reports/GetOrgChartReport`, data
        );
      };
}

export const reportmanagementCTO = new reportManagementCTO();