import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
      <Paper sx={{ p: 3, pb: 0 }}>
          <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }} component="h2">
                    Leaves
                </Typography>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Team Leave Card" {...a11yProps(0)} />
                      <Tab label="Leave Applied" {...a11yProps(1)} />
                      <Tab label="Leaves Request" {...a11yProps(2)} />
                      <Tab label="List of Holidays" {...a11yProps(3)} />
                  </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                  <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                              <TableRow>
                                  <TableCell>Emp ID</TableCell>
                                  <TableCell >Emp</TableCell>
                                  <TableCell >Total Leaves</TableCell>
                                  <TableCell >Availed</TableCell>
                                  <TableCell >Balance</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                            
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  > 
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                                  <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="th" scope="row">
                                        3096
                                      </TableCell>
                                      <TableCell >Anuj Singh</TableCell>
                                      <TableCell >14</TableCell>
                                      <TableCell >04</TableCell>
                                      <TableCell >10</TableCell>
                                  </TableRow>
                            
                          </TableBody>
                      </Table>
                  </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1}>
                  Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                  Item Three
              </TabPanel>
          </Box>
      </Paper>
    
  );
}