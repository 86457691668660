import React, { useEffect, useState } from 'react';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import theme from '../../theme/theme';
import ChatMsg from '../escalationProcess/ChatMsg';
import IconButton from '@mui/material/IconButton';
import * as Icons from "../../assests/icons/icons";
import { Autocomplete, Badge, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import { chatService } from '../../service/Po-services';
import { Formik, getIn } from 'formik';
import { toast } from 'material-react-toastify';
import ClearIcon from "@mui/icons-material/Clear";
import * as Yup from "yup";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { apiUrl } from '../../service/common-services/apiURL';
import { HubConnection, HttpTransportType, HubConnectionBuilder} from '@microsoft/signalr';
import secureLocalStorage from 'react-secure-storage';
import { eRiskStatus } from '../../Enum/eRiskStatus';
import InfoPopover from '../../components/CommonInfoComponent';

const styleCrossleave = {
    position: 'absolute' as 'absolute',
    right: '24px',
    top: '12px'
}

const styles = {
    user: {
        username: {
            fontSize: '18px', fontWeight: 500,
            lineHeight: 1,
            color: theme.palette.primary.dark,
            textTransform: "capitalize"
        },
        userProject: {
            fontSize: '14px', color: theme.palette.grey[600]
        },
        timeStamp: {
            fontSize: '13px',
            color: theme.palette.grey[400]
        } 
    },
    toggleloaders:{
        position: "fixed",
         left: "50%",
         right: "50%",
         top: "50%", 
        bottom: "50%", 
        transform: "translate(-50%, -50%)" 
       },
};

enum ProjectChatStatus {
    "All" = "0",
    "Pending" = "1",
    "Open" = "2",
    "Complete" = "3"
}
type StatusList = keyof typeof ProjectChatStatus;

interface ChatInter {
    id: string;
    subject: string;
    clientName: string;
    description: string;
    clientId:string;
    clientUserId:string;
    projectName: string;
    projectId: boolean;
    projectOwnerId:string;
    clientImage: string;
    projectOwnerImage: string;
    chat: [
        {
          senderId: string,
          receiverId: string,
          message: string,
          messageDate: string,
        }
    ]
}
interface AddChat {
    subject: string,
    description: string,
    projectId: string

}
interface Project {
    projectId: string;
    projectName: string;
}

export default function ProjectChat() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.PrismPermissionApp.PrismPermission);
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [chatView, setChatView] = useState<ChatInter[]>([]);
    const [currentChat, setCurrentChat] = useState<ChatInter>();
    let statusValue = "All";
    const [statusDataHook, setStatusDataHook] = useState(statusValue);
    const [connection, setConnection] = React.useState<null | HubConnection>(null);
    const [toggleLoader, setToggleLoader] = useState(false);
    React.useEffect(() => {
        configSocket();

    }, []);
    const configSocket = async () => {
        var options = {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => {
                return secureLocalStorage.getItem('authToken')?.toString()??""
            },
        };
        const connect = new HubConnectionBuilder()
            .withUrl(`${apiUrl.get_HUB_URL()}/notificationhub`,options)
            .withAutomaticReconnect()
            .build();
            setConnection(connect);
    };
    React.useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.on("ReceiveProjectChat", (message) => {
                        console.log("message",message);
                        if(message!=null) handleListItemClick(message.chatId);
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [connection]);
    useEffect(() => {
        if (_permission.page_Chat !== true) {
            navigate("/dashboard")
        }
        getChatDetails();
        getProject();
    }, []);

    const getChatDetails = async () => {
        setToggleLoader(true);
        try{
        await chatService.GetProjectChatByUserId().then((response: any) => {
            if (response.data.isError) {
                setToggleLoader(false);
            } else {
                setToggleLoader(false);
                setChatView(response?.data);
            }
        });}
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
              toast.warning(err);
            });
        }
    };

    // ------------------------
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = async (
        id: any
    ) => {      
         
        await chatService.GetUserChatById(id).then((response: any) => {           
            if (response.data.isError) 
            {
            }
             else 
             {
                var chatObj=response?.data;
                console.log("chatObj",chatObj);
                setCurrentChat(chatObj);
                var element = document.getElementById("ProjectChatLastMessage");
                element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }
        });
    };

    
    const readUnReadMessage = async (
        id: any
    ) => {         
        
        await chatService.ReadUnReadMessage(id).then((response: any) => {
            if (response.data.isError) 
            {
            } 
            else 
            {
                var chatObj=response?.data;              
                getChatDetails();
                handleListItemClick(id);
            }            
        });       
    };   

    // Add  programming 
    const [project, setProject] = useState<Project[]>([]);
    const [isSubmit, setSubmit] = useState(false);
    const [openChat, setChatOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [formValues, setFormValues] = React.useState<AddChat>();
    const [subject, setSubject] = React.useState(formValues?.subject)
    const [projectId, setProjectId] = React.useState(formValues?.projectId)
    const [description, setDescription] = React.useState(formValues?.description);
    const [buttonDisable, setButtonDisable] = useState(false);
    const handleClickOpen = () => {
        setChatOpen(true);
        setSubmit(false);
        setErrorMessage(false);
        setProjectId("");
        setSubject("");
        setDescription("");
        setSubmit(false);
    };

    const handleCloseLeave = () => {
        setChatOpen(false);
        setTimeout(() => {
            setId("");
        }, 500);
    };


    const addChat = async (values: any) => {
        try {
            await chatService.createUserChat(values)
                .then((response: any) => {
                    if (response.data.isError) {

                    }
                    else {
                        setChatOpen(false);
                        toast.success('Chat added successfully.');
                        getChatDetails();
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err,{
                    onOpen:()=> setButtonDisable(true),
                    onClose:()=> setButtonDisable(false)
                });
            });
        }
    };

    const getProject = async () => {
        await chatService.GetProjectsNotInChat().then((response: any) => {
            if (response.data.isError) {
            } else {
                setProject(response.data);
            }
        });
    };

    const updateCommentDetails = async (values: any) => {
        try {
            let otherData = [];//currentChat?.chat;
            otherData?.push({
                message: values.comment,
                senderId: _authUser.id,
               // receiverId: "" ,
                receiverId:(currentChat?.projectOwnerId===_authUser.id)
                ?currentChat?.clientId:currentChat?.projectOwnerId,                       
                messageDate: new Date().toString(),
            });
            var arr = {
                id: currentChat?.id,
                chat: otherData,
            }
            await chatService.updateUserChat(arr).then(async(response: any) => {
                if (response.data.isError) {
                } else{
                    var msg={
                        senderId:_authUser.id,
                        receiverId:(currentChat?.projectOwnerId===_authUser.id)?currentChat?.clientId:currentChat?.projectOwnerId,
                        message:values.comment,
                        chatId:currentChat?.id
                    }
                    if (connection) {
                        await connection.send("SendProjectChat", msg).then((response) => {
                            console.log("response",response);
                        })
                        .catch((error) => console.log(error));
                    }
                }
                setSelectedIndex(values);
            });
            getChatDetails();
        }
         catch (ex: any) {

            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const fieldValidationSchema = Yup.object().shape({
        projectId: Yup.string()
            .required("Project is required"),
        // subject: Yup.string().required("Subject  is Required").max(25, "Subject can only contains 25 words"),
        // description: Yup.string()
        //     .required("Description is required")
    });


    return (
        <Formik
            initialValues={{
                comment: ''
            }}
            // validationSchema={fieldValidationSchema}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting,

            }) => {
                try {
                    // setErrorMessage("");
                    await updateCommentDetails(values);
                    values.comment = "";
                } catch (ex: any) {
                    if (ex.status == 400) {
                        console.error("ex:", ex);
                        ex.data.Error.map((error: any, idx: any) => {
                            setErrorMessage(error);
                            toast.error(error);
                        })
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}>
                        
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                        <Typography color="text.primary">Chat</Typography>
                      
                    </Breadcrumbs>
                    {toggleLoader && (<CircularProgress sx={styles.toggleloaders} />)}
                    <Paper elevation={0} sx={{ p: 3, pb: 0, height: "calc(100vh - 126px)"}}>
                    <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
                        <InfoPopover  onInput={"Chat"}/>
            </Stack>
                        <Grid container spacing={2} sx={{ height: "100%" }}>
                            <Grid item xs={12} sm={4} lg={4} sx={{ borderRight: "1px solid #eee" }}>
                                <Box sx={{ pr: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: "100%" }}
                                        onClick={handleClickOpen}
                                        color="primary"
                                        startIcon={<AddIcon />}
                                    >
                                        Create New
                                    </Button>


                                    <List sx={{ height: ' calc(100vh - 200px)', overflowY: 'auto',}}>
                                        {chatView
                                            .map((chat: any, index: any) => (
                                                <ListItemButton alignItems="center"
                                                    selected={chat.id === currentChat?.id}
                                                    onClick={(event) => {
                                                        values.comment =""; 
                                                        handleListItemClick(chat?.id);
                                                        readUnReadMessage(chat?.id)
                                                    }}
                                                >
                                                    <ListItemAvatar sx={{ mt: 0, mr: 1.5 }}>
                                                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"
                                                            sx={{ width: '50px', height: '50px' }}
                                                        /> */}
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={_authUser.id === chat?.projectOwnerId 
                                                                ? apiUrl.getImageURL() + "/" + chat?.clientImage 
                                                                : apiUrl.getImageURL() + "/" + chat?.projectOwnerImage}
                                                            sx={{ width: '50px', height: '50px' }}
                                                        />
                                                        
                                                       
                                                    </ListItemAvatar>
                                                    {chat.unreadMessageCount>0 &&  <Stack sx={{position: "absolute", right: "20px", top: "70%"}} >
                                                            <Badge color="secondary" badgeContent={chat.unreadMessageCount}>
                                                           
                                                            </Badge>
                                                        </Stack> }
                                                    <ListItemText>

                                                        <Stack direction='row' justifyContent='space-between'>
                                                            <Box>
                                                                <Typography sx={[styles.user.username]} >
                                                                    
                                                                    {chat.subject}
                                                                </Typography>
                                                                <Typography sx={[styles.user.userProject]}>
                                                                    {chat.clientName}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ textAlign: 'right' }}>
                                                                {chat.others != null && chat.others.length > 0 &&
                                                                    <Typography sx={[styles.user.timeStamp]} >
                                                                        {`${moment(chat.others[chat.others.length - 1].commentDate).format(
                                                                            "DD  MMM YYYY h:mm"
                                                                        )}`}
                                                                    </Typography>}
                                                                <Typography sx={[styles.user.timeStamp]} >
                                                                    {/* Dec 01, 2022 */}
                                                                </Typography>
                                                            </Box>
                                                            
                                                        </Stack>

                                                    </ListItemText >
                                                </ListItemButton >
                                            ))}
                                    </List>

                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={8} lg={8}>
                                {(currentChat != null &&
                                    <Stack justifyContent='flex-start' sx={{ height: '100%', position: 'relative' }}>
                                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                            <Stack direction='row' alignItems='center'>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={_authUser.id === currentChat?.projectOwnerId 
                                                        ? apiUrl.getImageURL() + "/" + currentChat?.clientImage 
                                                        : apiUrl.getImageURL() + "/" + currentChat?.projectOwnerImage}
                                                    sx={{ mr: 1, width: 60, height: 60 }}
                                                />
                                                <ListItemText
                                                    primary={currentChat?.clientName}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                            </Typography>
                                                            {currentChat?.subject}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                        {(currentChat?.description != null &&
                                            <Paper sx={{ p: 1, mb: 2, mt: 1 }} >
                                                {currentChat?.description ? `Description -: ${currentChat?.description}` : ''}
                                            </Paper>
                                        )}

                                        <Box sx={{ height: 'calc(100vh - 350px)', overflowY: 'scroll', padding: '10px' }} >
                                            {currentChat?.chat?.filter(x => x.message!=null).map((a: any) => (
                                                <ChatMsg
                                                    side={(_authUser.id === a.senderId ) ? 'right' : 'left'}
                                                    // avatar={apiUrl.getImageURL()+"/"+(currentChat?.clientId === a.senderId ? currentChat?.clientImage:currentChat.projectOwnerImage)}
                                                    avatar={currentChat?.projectOwnerId === a.senderId ?  apiUrl.getImageURL()+"/"+currentChat?.projectOwnerImage : apiUrl.getImageURL()+"/"+currentChat?.clientImage}                                                    messages={[a.message]}
                                                    date={`${moment(a.commentDate).format(
                                                        "h:mm a"
                                                    )}`}
                                                />
                                            ))}
                                             <div id="ProjectChatLastMessage"></div>
                                        </Box>

                                        <Box  sx={{ position: 'absolute', bottom: '0', left: '0', right: 0, width: '100%' }}>
                                            {/* <Divider sx={{ my: 1.5 }} /> */}
                                           
                                            <TextField
                                            //  disabled={currentChat?.riskStatus===eRiskStatus.Complete}
                                                label="Message"
                                                name="comment"
                                                variant='outlined'
                                                value={values.comment.trimStart()}
                                                onChange={handleChange}
                                                fullWidth
                                                onKeyPress={(e) => {
                                                    if ( values.comment.length!==0 && values.comment != " " ) {
                                                    }
                                                    else{ e.key === 'Enter' && e.preventDefault();}
                                                  }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {/* { (currentChat?.riskStatus!==eRiskStatus.Complete 
                                                           && values.comment.length!==0) && */}
                                                           { values.comment.length!==0 && values.comment != " " && (
                                                            <Tooltip title="Add Message">
                                                                <IconButton
                                                                    type="submit"
                                                                    onBlur={handleBlur}
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                >
                                                                    <Icons.PaperPlane />
                                                                </IconButton></Tooltip>)}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Stack>)}
                            </Grid>
                        </Grid>
                    </Paper>


                    <Dialog
                        open={openChat}
                        onClose={Button}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth='md'
                        scroll='body'
                    >
                        <Formik
                            initialValues={{
                                projectId: projectId
                            }}
                            enableReinitialize
                            validationSchema={fieldValidationSchema}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    setErrorMessage(true);

                                    await addChat(values);
                                } catch (err: any) {
                                    console.error(err);
                                    err = JSON.parse(JSON.stringify(err));
                                    if (err.status == 400) {

                                        {
                                            err.data.Error.map((error: any, idx: any) =>
                                                setErrorMessage(error)
                                            );
                                        }
                                    }
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <>
                                        <DialogTitle id="responsive-dialog-title" sx={{ fontSize: '24px', fontWeight: 600, position: 'relative' }}>
                                            {/* {id ? 'Update Label': 'Add Label'} */}
                                            <Typography variant='h5' sx={{ fontWeight: 600 }}>Create Chat</Typography>
                                            <IconButton aria-label="delete" sx={styleCrossleave} onClick={handleCloseLeave} >
                                                <ClearIcon />
                                            </IconButton>
                                        </DialogTitle>



                                        <DialogContent>
                                            <Stack sx={{ mb: 4, pt: 2 }}>
                                                <Autocomplete
                                                    value={
                                                        project?.find(
                                                            (option) => option.projectId == projectId
                                                        ) ?? null
                                                    }
                                                    fullWidth
                                                    id="outlined-basic"
                                                    options={project}
                                                    getOptionLabel={(option) => option.projectName}
                                                    onChange={(event, value) => {
                                                        setProjectId(value?.projectId ?? "");
                                                        values.projectId = value?.projectId ?? "";
                                                      
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id="outlined-basic-projectId"
                                                    error={Boolean(
                                                        getIn(touched, "projectId") &&
                                                        getIn(errors, "projectId")
                                                    )}
                                                    helperText={
                                                        getIn(touched, "projectId") &&
                                                        getIn(errors, "projectId")
                                                    }
                                                    required
                                                    name="projectId"
                                                    label="Project Name"
                                                    variant="outlined"
                                                    value={values?.projectId}
                                                    fullWidth
                                                        />
                                                    )}
                                                />
                                                {/* <Box sx={{ mt: 5, mb: 3 }}>
                                                    <TextField
                                                        id="subject"
                                                        variant="outlined"
                                                        error={Boolean(
                                                            getIn(touched, "subject") && getIn(errors, "subject")
                                                        )}
                                                        fullWidth
                                                        helperText={
                                                            getIn(touched, "subject") && getIn(errors, "subject")
                                                        }
                                                        label="Subject"
                                                        name="subject"
                                                        value={subject}
                                                        onBlur={handleBlur}
                                                        // onChange={handleChange}
                                                        onChange={(e) => {
                                                            let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                                                            values.subject = value;
                                                            setSubject(value);
                                                        }}
                                                        inputProps={{ maxLength: 150 }}
                                                    />
                                                    <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        sx={{ textAlign: "right", color: "#757575" }}
                                                    >
                                                    </Typography>
                                                </Box> */}


                                                {/* <Box sx={{ mt: 6, mb: 3 }}>
                                                    <b>   <label> Description </label>  </b>
                                                    <TextField fullWidth multiline variant="outlined" required
                                                        value={description}
                                                        id="description"
                                                        error={Boolean(
                                                            getIn(touched, "description") && getIn(errors, "description")
                                                        )}
                                                        helperText={
                                                            getIn(touched, "description") && getIn(errors, "description")
                                                        }
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                                                            values.description = value;
                                                            setDescription(value);
                                                        }}
                                                        sx={{

                                                            '& .MuiInputBase-root': {
                                                                minHeight: '100px', backgroundColor: '#fff',
                                                                lineHeight: '1.5'
                                                            }
                                                        }} />
                                                </Box> */}

                                            </Stack>
                                        </DialogContent>
                                        <DialogActions sx={{ p: 3, pt: 0 }}>
                                            <Button variant="outlined" onClick={handleCloseLeave}>Cancel</Button>
                                            <Button  type="submit" variant="contained" disabled={buttonDisable} >Submit</Button>
                                        </DialogActions>
                                    </>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                </form>
            )
            }
        </Formik >
    );
}