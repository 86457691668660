import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Autocomplete, Box, Button, CircularProgress, InputAdornment, TablePagination, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { projectPortfolioService } from "../../service/project-portfolio-service";
import NotFound from "../../components/styledComponent/NotFound";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HrReporttop from "../reports/HrReportsstyle";
import Userlisttop from "../users/userliststyle";
import SearchBar from "../../components/styledComponent/SearchBar";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "material-react-toastify";

export default function DomainPortfolio() {

  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




  const [domainPortfolio, setDomainPortfolio] = React.useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  console.log("searchValue ===", searchValue)
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const pageCount = Math.ceil(domainPortfolio?.length / rowsPerPage);


  const [toggleLoader, setToggleLoader] = React.useState(false);
  const _permission = useSelector((state: any) => state.DomainPortfolioApp.DomainPortfolioPermission);

  useEffect(() => {
    debugger
    if (_permission.page_ManageDomainPortfolioListing_Access !== true) {
      navigate("/dashboard");
    }
    getDomainData("");
  }, []);

  const getDomainData = async (searchValue: string) => {

    await projectPortfolioService
      .getDomainPortfolioList(searchValue)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          console.log(response.data, "dtata");
          setDomainPortfolio(response.data);
        }
      });
  };

  const triggerSearch = async (newValue: any) => {
    debugger
    setfiltersSelected(true);
    getDomainData(newValue);
    setPage(0);
    setSearchValue(newValue);
  };

  const DeleteDomainPortfolio = async (id: any) => {

    try {
      await projectPortfolioService
        .deleteDomainPortFolio(id)
        .then((response: any) => {
          if (response.data.isError) {
          } else {

            toast.success(" Deleted successfully.");
            getDomainData(searchValue);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  return (

    <div>
      <Box>{toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Box>
              <Box sx={{ marginBottom: "24px", display: "flex", gap: "16px", justifyContent: "space-between", mr: "15px" }}>

                <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                  <SearchBar
                    value={searchValue}
                    callBackFunction={triggerSearch}
                  />


                  {filtersSelected && (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={async () => {
                        setfiltersSelected(false);
                        setSearchValue("");
                        getDomainData("");
                      }}
                    >
                      Clear
                    </Button>
                  )}
                </Box>

               {_permission.tab_ManageDomainPortfolioAdd_Access && (
                <Button variant="contained" color="primary" component={Link}
                  to="/Add-Domain-portfolio">Add Domain Portfolio</Button>)}

              </Box>
              <Paper elevation={0} sx={{ p: 2, height: "80%" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 750 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ cursor: "pointer" }} >

                        <TableCell>Title</TableCell>
                        <TableCell sx={{ width: "400px" }}>Description</TableCell>
                        <TableCell sx={{ width: "300px" }}>Keyword</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {domainPortfolio.length > 0 && domainPortfolio?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item: any, idx: any) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer"
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ borderRight: "1px solid #E9E9EA !important" }}>
                                {item?.title}
                              </TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                {item?.description}

                              </TableCell>



                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                <Box>
                                  {item.keywords.map((keywordItem: any, keywordIdx: any) => (
                                    <Box key={keywordIdx}>{keywordItem.keyword}</Box>
                                  ))}
                                </Box>

                              </TableCell>

                              <TableCell
                                align="right"
                                component="th"
                                scope="row"
                              >
                                <Box sx={{ display: "flex", gap: "16px" }}>

                                {_permission.tab_ManageDomainPortfolioView_Access && (
                                  <Box
                                    sx={{
                                      background: "#4B4B5A",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    {" "}
                                    <Link
                                      to={`/View-Domain-portfolio/${item?.id}`}

                                      target="_blank"
                                    >
                                      <RemoveRedEyeIcon
                                        sx={{ color: "#fff" }}
                                      />
                                    </Link>
                                  </Box>
                                )}

                                  {_permission.tab_ManageDomainPortfolioEdit_Access && (
                                  <Box
                                    sx={{
                                      background: "#4B4B5A",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                    }}
                                  >
                                    {" "}
                                    <BorderColorIcon
                                      sx={{ color: "#fff" }}
                                      onClick={() => {
                                        navigate(
                                          `/Edit-Domain-portfolio/${item?.id}`
                                        );
                                      }}
                                    />
                                  </Box>
                                   )}

                             {_permission.tab_ManageDomainPortfolioDeleteDocument_Access  && (
                                  <Box
                                    sx={{
                                      background: "#4B4B5A",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    {" "}

                                    <DeleteIcon
                                      sx={{ color: "#fff" }}
                                      onClick={() => {
                                        DeleteDomainPortfolio(item?.id);
                                      }}
                                    />

                                  </Box>
                               )}

                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {domainPortfolio.length == 0 && (
                    <NotFound NotfoundText="No Result Found" />
                  )}
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25, 30]}
                  component="div"
                  count={domainPortfolio?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={() =>
                    `Total: ${domainPortfolio?.length}  Page ${page + 1
                    } of ${pageCount}`
                  }
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
