import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import { grey } from '@mui/material/colors'
import FormGroup from '@mui/material/FormGroup';
// import CustomTreeItem, { CustomTreeItemProps, CustomTreeItemClasses } from '@mui/lab/CustomTreeItem';
import { TransitionProps } from '@mui/material/transitions';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import clsx from 'clsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { borderColor } from '@mui/system';
import CustomTreeItem from '../../components/styledComponent/CustomTreeItem'
import StepsRoles from './StepsRoles'
import { roleManagementServive } from '../../service/role-management-service';
import { strict } from 'assert';
import { string } from 'yup';
import ScreenPermission from './Screens'
import { tab } from '@testing-library/user-event/dist/tab';
import { number } from 'yup/lib/locale';
import { toast } from 'material-react-toastify';
import Breadcrumbs from "@mui/material/Breadcrumbs";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',

}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: "0",
    '& .MuiListItemButton-root': {
        borderBottom: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? '#2c2c2c' : ' #D1D1D1',
    },
}));

const modules = [
    {
        text: 'Admin'
    },
    {
        text: 'Staff'
    },
    {
        text: 'Client'
    },
    {
        text: 'Employee'
    },
    {
        text: 'Worker'
    },

];
interface ListActionMaster {
    actionName: string;
    actionId: string;
    displayOrder: string;
    isChecked: boolean;
}

interface ListTabMaster {
    tabName: string;
    tabId: string;
    displayOrder: string;
    listActionMaster: ListActionMaster[];
    isChecked: boolean;
}

interface ListPageMaster {
    pageName: string;
    pageId: string;
    displayOrder: string;
    listTabMaster: ListTabMaster[];
    isChecked: boolean;
}
interface MasterRole {
    moduleName: string;
    isDeleted: boolean;
    listModuleMaster: [],
    moduleId: number;
    displayOrder: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    isChecked: boolean;
}
interface ListModuleMaster {
    moduleName: string;
    moduleId: string;
    displayOrder: string;
    listPageMaster: ListPageMaster[];
    isChecked: boolean;
}

interface ModuleSelection {
    module: [];
    pages: [];
    tabs: [];
    action: [];
}
const steps = [
    'Update Role',
    'Grant Permissions',
];
export default function ViewRolePermissions() {
    const { id } = useParams();
    const [roles, setRoles] = useState<MasterRole[]>([]);
    const [autoRoles, SetAutoRoles] = useState<[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<ListModuleMaster[]>([]);
    const [autoSelectedRoles, setAutoSelectedRoles] = useState<ListModuleMaster[]>([]);
    const [reqData, setReqData] = useState<any>([]);
    // const [tempSelectedAction, setTempSelectedActions] = useState<ModuleSelection[]>([]);
    const [tempSelectedModule, tempSetSelectedModule] = useState<string[]>([]);
    const [tempSelectedPage, tempSetSelectedPage] = useState<string[]>([]);
    const [tempSelectedTab, tempSetSelectedTab] = useState<string[]>([]);
    const [tempSelectedAction, tempSetSelectedAction] = useState<string[]>([]);
    const [isChecked, setIsChecked] = useState(false);
    const [roleName, setRoleName] = useState("");
    useEffect(() => {
        getRoleDetails();
        GetMasterRole();
        // //GetRoleAccessByRoleId();
    }, [])
    const getRoleDetails = async () => {
        // getUsers();
        await roleManagementServive.getRoleById(id).then((response: any) => {
            if (response.data.isError) {
            } else {
                setRoleName(response?.data?.name);
            }
        });
    };
    const GetMasterRole = async () => {
        await roleManagementServive.GetMasterRole()
            .then((response: any) => {
                
                if (response.data.isError) {

                }
                else {

                    //setRoles(response.data.listModuleMaster);
                    GetRoleAccessByRoleId(response.data.listModuleMaster);
                }
            });
    }
    const handlePageSelect = (pageModule: any, e: any) => {
      
        if (e.target.checked) {
           
            pageModule.isChecked = true;
            pageModule.listTabMaster.forEach((tabs: any) => {
                tabs.isChecked = true;
                setIsChecked(tabs.isChecked);
            });
        }
        else {
            pageModule.isChecked = false;
            pageModule.listTabMaster.forEach((tabs: any) => {
                tabs.isChecked = false;
                setIsChecked(tabs.isChecked);
            });
        }
    };
    const handleTabSelect = (pageMaster: any, tabMaster: any, e: any) => {
       
        if (e.target.checked) {
            var arrTabs = [];
            tabMaster.isChecked = true;
            setIsChecked(tabMaster.isChecked);
            pageMaster.listTabMaster.forEach((tabs: any) => {
                if (tabs.isChecked || tabMaster.tabId == tabs.tabId) {
                    arrTabs.push(tabs.tabId)
                }
            });

            if (arrTabs.length == pageMaster.listTabMaster) {
                pageMaster.isChecked = true;
                setIsChecked(pageMaster.isChecked);
            }
        }
        else {
          
            pageMaster.isChecked = false;
            setIsChecked(pageMaster.isChecked);
        }

    };
    const GetRoleAccessByRoleId = async (allRoles: any) => {
        await roleManagementServive.GetRoleAccessByRoleId(id)
            .then((response: any) => {
               
                if (response.data.isError) {

                }
                else {
                    setAutoSelectedRoles(response.data);
                    let selectedModule: any = [];
                    response.data.forEach((elemRole: any) => {
                        selectedModule.push(elemRole);
                    });
                    SetAutoRoles(selectedModule);
                    const newChecked = [...selectedRoles];
                    type StringTypeArray = {
                        SelectedModule: string[];
                        SelectedPage: string[];
                        SelectedTab: string[];
                        SelectedAction: string[];
                    };

                    const tempSelectedRoles: StringTypeArray = {
                        SelectedModule: [],
                        SelectedPage: [],
                        SelectedTab: [],
                        SelectedAction: [],
                    };

                    for (var i = 0; i < allRoles.length; i++) {
                        for (var j = 0; j < selectedModule.length; j++) {
                            if (allRoles[i].moduleId == selectedModule[j].moduleId) {
                                allRoles[i].isChecked = true;
                                allRoles[i].listPageMaster.forEach((pageMaster: any) => {
                                    selectedModule[j].listPageMaster.forEach((selectedPageMaster: any) => {
                                        if (selectedPageMaster.pageId == pageMaster.pageId) {
                                            pageMaster.isChecked = selectedPageMaster.isChecked;
                                            pageMaster.listTabMaster.forEach((tabMaster: any) => {
                                                selectedPageMaster.listTabMaster.forEach((selectedTabMaster: any) => {
                                                    if (selectedTabMaster.tabId == tabMaster.tabId) {
                                                        tabMaster.isChecked = selectedTabMaster.isChecked;
                                                        tabMaster.listActionMaster.forEach((actionMaster: any) => {
                                                            selectedTabMaster.listActionMaster.forEach((selectedActionMaster: any) => {
                                                                if (selectedActionMaster.actionId == actionMaster.actionId) {
                                                                    actionMaster.isChecked = selectedActionMaster.isChecked;
                                                                    tempSelectedRoles.SelectedModule.push(allRoles[i].moduleId);
                                                                    tempSelectedRoles.SelectedPage.push(selectedPageMaster.pageId)
                                                                    tempSelectedRoles.SelectedTab.push(selectedTabMaster.tabId)
                                                                    tempSelectedRoles.SelectedAction.push(selectedActionMaster.actionId)
                                                                    // autoChangeScreenAction(allRoles[i].moduleId,selectedPageMaster,selectedTabMaster,selectedActionMaster)
                                                                }
                                                            });
                                                        });
                                                    }
                                                });
                                            });
                                        }
                                    });

                                });
                                // selectUserRole(allRoles[i]);
                                // setSelectedRoles(allRoles[i]);
                                newChecked.push(allRoles[i]);
                                setSelectedRoles(newChecked);
                            }
                        }
                    }
                    tempSetSelectedModule(tempSelectedRoles.SelectedModule);
                    tempSetSelectedPage(tempSelectedRoles.SelectedPage);
                    tempSetSelectedTab(tempSelectedRoles.SelectedTab);
                    tempSetSelectedAction(tempSelectedRoles.SelectedAction);
                }
            });

        setRoles(allRoles);
    }

    const selectUserRole = (listModuleMaster: any) => () => {
       
        var currentIndex = -1
        const newChecked = [...selectedRoles];
        var isExist = false;
        for (var i = 0; i < selectedRoles.length; i++) {
            if (selectedRoles[i].moduleId == listModuleMaster.moduleId) {
                currentIndex = i;
                isExist = true;
                break;
            }
        }
        if (!isExist) {
            newChecked.push(listModuleMaster);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }
       
        setSelectedRoles(newChecked);
    };

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const User = ({ id, name, avatar }: any) => (
        <div className="user-container" data-id={id}>
            <img src={avatar} className="user-avatar" />
            <span className="user-name">{name}</span>
        </div>
    );

    const handleChangeScreenAction = (moduleId: string, parentModule: any, moduleTabMaster: any, currentAction: any, evt: any) => {
        
        const currentIndex = tempSelectedModule.indexOf(moduleId);
        const newModuleChecked = [...tempSelectedModule];
        if (evt.target.checked) {
            // if (currentIndex === -1) {
            newModuleChecked.push(moduleId);
            //}
        }
        else
            newModuleChecked.splice(currentIndex, 1);

        tempSetSelectedModule(newModuleChecked);

        const currentPageIndex = tempSelectedPage.indexOf(parentModule.pageId);
        const newPageChecked = [...tempSelectedPage];
        if (evt.target.checked) {
            // if (currentPageIndex === -1) {
            newPageChecked.push(parentModule.pageId);
            //}
        }
        else
            newPageChecked.splice(currentPageIndex, 1);

        tempSetSelectedPage(newPageChecked);

        const currentTabIndex = tempSelectedTab.indexOf(moduleTabMaster.tabId);
        const newTabChecked = [...tempSelectedTab];
        if (evt.target.checked) {
            // if (currentTabIndex === -1) {
            newTabChecked.push(moduleTabMaster.tabId);
            //}
        }
        else
            newTabChecked.splice(currentTabIndex, 1);

        tempSetSelectedTab(newTabChecked);

        const currentActionIndex = tempSelectedAction.indexOf(currentAction.actionId);
        const newActionChecked = [...tempSelectedAction];
        if (evt.target.checked) {
            // if (currentActionIndex === -1) {
            newActionChecked.push(currentAction.actionId);
            //}
        }
        else
            newActionChecked.splice(currentActionIndex, 1);

        tempSetSelectedAction(newActionChecked);
    };

    const removeDuplicate = (data: string[]) => {
        var newArray = data.filter(function (elem, pos) {
            return data.indexOf(elem) == pos;
        });
        return newArray;
    }

    const SetRoleAccessRequest = async () => {
        const uniqeSelectedModule = removeDuplicate(tempSelectedModule);
        const uniqeSelectedPage = removeDuplicate(tempSelectedPage);
        const uniqeSelectedTab = removeDuplicate(tempSelectedTab);
        const uniqeSelectedAction = removeDuplicate(tempSelectedAction);
        if (selectedRoles.length == 0) {
            toast.warning('Please Select Module');
            return false;
        } else if (uniqeSelectedAction.length == 0) {
            toast.warning('Please Provide Permission to Screens');
            //  toast.warning('Please Provide Permission to Screens!', GeneralConstants.toastr_setting);
            return false;
        }
        var tempListPageMaster: any = [];
        uniqeSelectedModule.forEach(action => {
            var moduleId = action;
            selectedRoles.forEach(role => {
                if (role.moduleId == moduleId) {
                    let tempPageMaster: any[] = [];
                    role.listPageMaster.forEach((pagemaster) => {
                        uniqeSelectedPage.forEach((pageId, PageIdx) => {
                            if (pagemaster.pageId == pageId) {
                                tempPageMaster.push({
                                    "pageName": pagemaster.pageName,
                                    "pageId": pagemaster.pageId,
                                    "displayOrder": pagemaster.displayOrder,
                                    "isChecked": pagemaster.isChecked,
                                    "listTabMaster": []
                                })
                                pagemaster.listTabMaster.forEach((tabs, idx) => {
                                    uniqeSelectedTab.forEach(tabId => {

                                        if (tabs.tabId == tabId) {
                                            if (tempPageMaster[tempPageMaster.length - 1]) {
                                                tempPageMaster[tempPageMaster.length - 1].listTabMaster.push(
                                                    {
                                                        "tabName": tabs.tabName,
                                                        "tabId": tabs.tabId,
                                                        "displayOrder": tabs.displayOrder,
                                                        "isChecked": tabs.isChecked,
                                                        "listActionMaster": []
                                                    })

                                                tabs.listActionMaster.forEach(actionmaster => {
                                                    uniqeSelectedAction.forEach(actionId => {
                                                        if (actionmaster.actionId == actionId) {
                                                            if (tempPageMaster[tempPageMaster.length - 1].listTabMaster[(tempPageMaster[tempPageMaster.length - 1].listTabMaster).length - 1]) {
                                                                tempPageMaster[tempPageMaster.length - 1].listTabMaster[(tempPageMaster[tempPageMaster.length - 1].listTabMaster).length - 1].listActionMaster.push(
                                                                    {
                                                                        "actionName": actionmaster.actionName,
                                                                        "actionId": actionmaster.actionId,
                                                                        "displayOrder": actionmaster.displayOrder,
                                                                        "isChecked": true,
                                                                    })
                                                            }
                                                            else {
                                                              
                                                            }

                                                        }
                                                    });
                                                });
                                            }
                                            else {
                                                
                                            }

                                        }
                                    });
                                });
                            }
                        });

                    })

                    var listPageMasterArr = {
                        "moduleName": role.moduleName,
                        "moduleId": role.moduleId,
                        "displayOrder": role.displayOrder,
                        "listPageMaster": tempPageMaster,
                        "isChecked": role.isChecked,
                    }

                    tempListPageMaster.push(listPageMasterArr);
                    // }
                };

            })
        })
        setReqData(tempListPageMaster);
        saveRoleAccess(tempListPageMaster);
    };
    const navigate = useNavigate();
    const saveRoleAccess = async (data: any) => {
        var req = {
            "roleId": id,
            "listModuleMaster": data
        }
        await roleManagementServive.UpdateRoleAccess(req)
            .then((response: any) => {
                if (response.data.isError) {

                }
                else {
                    toast.success('Permission Updated Successfully');
                    navigate('/role-management')
                }

            });
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                <Link color="inherit" to="/">
                    Dashboard
                </Link>
                <Typography color="text.primary">Role Management</Typography>
            </Breadcrumbs>
            <Paper elevation={0} sx={{ p: 3 }} >
                {/* <StepsRoles currentStep={1} steps={steps} /> */}
                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2, border: '1px solid', borderColor: '#bdbdbd', p: 3, borderRadius: '10px' }}>
                    <Avatar sx={{ width: 52, height: 52 }}>H</Avatar>
                    <Stack spacing={0}>
                        <Typography variant="subtitle1" component="h5"  >
                            Role
                        </Typography>
                        <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 600 }}>
                            {roleName}
                        </Typography>
                    </Stack>
                </Stack>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={3}>
                        <Item elevation={0}>
                            <Typography variant="h6" align="left" noWrap component="div" sx={{ fontWeight: 500 }}>
                                Modules
                            </Typography>
                            <List >
                                {roles.map((value, idx) => {
                                    const labelId = `checkbox-list-label-${value.moduleId}`;

                                    return (
                                        <CustomListItem
                                            key={value.moduleId}
                                            disablePadding
                                        >
                                            <ListItemButton key={idx} role={undefined} >
                                                <ListItemIcon onClick={selectUserRole(value)} sx={{ minWidth: 'auto' }} >
                                                    <Checkbox
                                                        edge="start"
                                                        defaultChecked={value.isChecked}
                                                        disabled={true}
                                                        //tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={value.moduleName} />
                                            </ListItemButton>
                                        </CustomListItem>
                                    );
                                })}
                            </List>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Item elevation={0}>
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start" sx={{ mb: 3 }}>
                                <Typography variant="h6" align="left" component="h2" sx={{ fontWeight: 500 }}>
                                    Permissions
                                </Typography>
                                <Button variant="contained" color="primary" component={Link} to={'/role-management/update-permission/' + id}>
                                    EDIT
                                </Button>
                            </Stack>
                            <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                defaultExpanded={['1']}
                            >
                                {selectedRoles.map((module, idx) => (
                                    <CustomTreeItem key={idx} nodeId={'M_' + idx} label={<Typography variant="h6" sx={{ fontWeight: 600 }}>{module.moduleName}</Typography>}>
                                        {module.listPageMaster.map((pageMaster, idx1) => (
                                            <CustomTreeItem key={idx1 + '_' + pageMaster.pageId} nodeId={'M1_' + pageMaster.pageId} label={
                                                <Stack direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start">
                                                    <Grid container spacing={0} justifyContent="flex-start">
                                                        <Grid item xs={idx1}>
                                                            {pageMaster.isChecked &&
                                                                <FormControlLabel control={<Checkbox  disabled={true} id={'page_' + pageMaster.pageId}
                                                                    defaultChecked={pageMaster.isChecked}
                                                                />} label={pageMaster.pageName}
                                                                    onChange={(e1: any) => handlePageSelect(pageMaster, e1)} />
                                                            }
                                                            {!pageMaster.isChecked &&
                                                                <FormControlLabel control={<Checkbox disabled={true} id={'page_' + pageMaster.pageId}
                                                                    defaultChecked={pageMaster.isChecked}
                                                                />} label={pageMaster.pageName}
                                                                    onChange={(e1: any) => handlePageSelect(pageMaster, e1)} />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            }>
                                                <TableContainer sx={{ pl: idx1 }}>
                                                    <Table size="small" aria-label="dense table">
                                                        <TableBody>
                                                            {pageMaster.listTabMaster.map((tabMaster, idx2) => (
                                                                <TableRow key={idx2}>
                                                                    <TableCell component="th" scope="row">
                                                                        {tabMaster.isChecked &&
                                                                            <FormControlLabel control={<Checkbox disabled={true} id={'tab_' + tabMaster.tabId}
                                                                                defaultChecked={tabMaster.isChecked} />}
                                                                                label={tabMaster.tabName}
                                                                                onChange={(e1: any) => handleTabSelect(pageMaster, tabMaster, e1)} />}
                                                                        {!tabMaster.isChecked &&
                                                                            <FormControlLabel control={<Checkbox disabled={true} id={'tab_' + tabMaster.tabId}
                                                                                defaultChecked={tabMaster.isChecked} />}
                                                                                label={tabMaster.tabName}
                                                                                onChange={(e1: any) => handleTabSelect(pageMaster, tabMaster, e1)} />}
                                                                        {/*                                                                      
                                                                        <FormControlLabel control={<Checkbox id={'tab_' + idx1}  defaultChecked ={tabMaster.isChecked}/>} label={tabMaster.tabName} /> */}
                                                                    </TableCell>
                                                                    {tabMaster.listActionMaster.map((actionMaster, idx3) => (
                                                                        <TableCell key={idx3} align="right">
                                                                            <FormControlLabel
                                                                                control={<Checkbox disabled={true} id={'action_' + idx3} defaultChecked={actionMaster.isChecked} />}
                                                                                label={actionMaster.actionName}
                                                                                onChange={(e) => handleChangeScreenAction(module.moduleId, pageMaster, tabMaster, actionMaster, e)} /></TableCell>
                                                                    ))}

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </CustomTreeItem>
                                        ))}
                                    </CustomTreeItem>

                                ))}

                            </TreeView>
                        </Item>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}