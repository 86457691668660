import * as React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import {
  Avatar,
  AvatarGroup,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import * as Icons from "../assests/icons/icons";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import SearchBar from "../components/styledComponent/SearchBar";
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import theme from '../theme/theme';
import { AppConstants } from '../config/AppConstants';
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IssueTypeIcon } from './boardscreens/IconsLabel';
import { issueManagementService } from '../service/issues-management-service';
import { userManagementServive } from '../service/user-management-service';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { commonFunctions } from '../service/common-services/common-functions';
import { deepOrange } from '@mui/material/colors';
import HrReporttop from './reports/HrReportsstyle';
import { toast } from 'material-react-toastify';

const styles: any = {
  userAvatar: {
    justifyContent: "flex-end",
    ml: 'auto',
    "& .MuiAvatar-root": {
      textTransform: "Uppercase",
      // fontFamily: "sans-serif",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text,
      borderColor: "#fff",
      fontWeight: 500,
      width: "32px",
      height: "32px",
      cursor: "pointer",
      fontSize: 13,
      lineHeight: " 16px",
      ml: 'auto'
    },
  },
}
const TicketLabel = styled(Chip)({
  border: "none",
  background: "none",
  padding: 0,
  color: theme.palette.primary.main,
  "& .MuiChip-label": {
    fontWeight: 500,
    fontSize: "15px",
  },
});


export default function AdvanceSearch({
  projectWiseAssigneeUsers,
  projectId,


}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [projectType, setProjectType] = React.useState<any>("");
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectIdFromQuery = queryParams.get('pId');
  const sprintIdFromQuery = queryParams.get('sID');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dummy = [
    {
      value: "Story",
      label: "Story",
    },
    {
      value: "Bug",
      label: "Bug",
    },
    {
      value: "Epic",
      label: "Epic",
    },
    {
      value: "Task",
      label: "Task",
    },
  ];
  const dummy1 = [
    {
      value: "Product Backlog",
      label: "Product Backlog",
    },
    {
      value: "Sprint Backlog",
      label: "Sprint Backlog",
    },
    {
      value: "In Progress",
      label: "In Progress",
    },
    {
      value: "Review",
      label: "Review",
    },
    {
      value: "Resolved",
      label: "Resolved",
    },
    {
      value: "QA",
      label: "QA",
    },
    {
      value: "QA Failed",
      label: "QA Failed",
    },
    {
      value: "Hold",
      label: "Hold",
    },
    {
      value: "Blocked",
      label: "Blocked",
    },
    {
      value: "Closed",
      label: "Closed",
    },
  ];
  const [issueStatus, setIssueStatus] = React.useState("");
  const [users, setUsers] = useState<any>(projectWiseAssigneeUsers);
  const [users1, setUsers1] = useState<any>(projectWiseAssigneeUsers);
  const [currentUserId, setCurrentUserId] = useState("");
  const [selectedResourcesId, setSelectedResourcesId] = React.useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(projectIdFromQuery);
  const [elements, setElements] = useState<any>(null);
  const [isProductBacklog, setIsProductBacklog] = useState(false);
  const [searchTicket, setSearchTicket] = useState<string>("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const triggerSearch = async (
    searchvalue: string) => {
      setToggleLoader(true);
    if (selectedProjectId) {
      GetAllTicketsInProjects(selectedProjectId, searchvalue, projectType,issueStatus , currentUserId, selectedResourcesId);
      setSearchTicket(searchvalue)
    }
  };
  useEffect(() => {
    if (selectedProjectId) {
      getAllUserListByProjectId(selectedProjectId)
      GetAllTicketsInProjects(selectedProjectId, searchTicket, "", "", "", "");
    }
  },
    [selectedProjectId]);
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const getAllUserListByProjectId = async (projectId: any) => {
    userManagementServive
      .getAllUsersByProjectId({
        projectId: projectId,
      })
      .then((response: any) => {
        setUsers(response?.data || []);
        setUsers1(response?.data || []);
      });
  };
  useEffect(() => {
    GetAllTicketsInProjects(
      selectedProjectId || projectId,
      searchTicket,
      projectType,
      issueStatus,
      currentUserId,
      selectedResourcesId
    );
  }, [projectId, projectType, issueStatus, currentUserId, selectedResourcesId]);
  const GetAllTicketsInProjects = useCallback((
    projectId: string,
    searchTicket: string,
    projectType: any,
    issueStatus: any,
    currentUserId: any,
    selectedResourcesId: any,
  ) => {
    setToggleLoader(true);
    issueManagementService
      .globalSearchInProject({
        issueType: projectType,
        projectId: projectId,
        status: issueStatus,
        userId: currentUserId,
        reporter: selectedResourcesId,
        search: searchTicket,
      })
      .then((response: any) => {
        if (response?.data?.isError) {
          setToggleLoader(false);
        } else {
          setToggleLoader(false);
          setElements(response?.data);
        }
      })
      .catch((error) => {
        error.data.Error.map((err: any, idx: any) => {
          toast.warning(err);
          setToggleLoader(false);
        })
        return error;
      });
  }, [selectedResourcesId, issueStatus, currentUserId, selectedProjectId, projectType]);
  return (
    <>   {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
      <Paper elevation={0} sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
          Advance Search
        </Typography>
        <Stack direction="row" spacing={1} sx={{ mt: '12px' }}>
          <Box>
            <TextField
              id="outlined-basic"
              select
              value={projectType !== "" ? projectType : " "}
              variant="outlined"
              sx={{
                "& .MuiSelect-select": {
                  fontSize: "16px",
                  fontWeight: 500,
                  minWidth: 80,
                  color: theme.palette.primary.dark,
                  lineHeight: "inherit",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <MenuItem
                key="0"
                value=" "
                onClick={(event) => {
                  setProjectType("");
                  setfiltersSelected(true);
                }}
              >
                Type All
              </MenuItem>
              {AppConstants.MasterData.Issue.IssueType.map(
                (option, index) => (
                  <MenuItem
                    key={option.value}
                    value={option.label}
                    onClick={(event) => {
                      setVisible(true);
                      setProjectType(dummy[index].label);
                      setfiltersSelected(true);
                    }}
                  >
                    <>
                      <IssueTypeIcon issueIconType={option.icon} />
                      &nbsp;
                      {option.label}
                    </>
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>

          <TextField
            id="outlined-basic"
            select
            value={issueStatus !== "" ? issueStatus : " "}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                fontSize: "16px",
                fontWeight: 500,
                minWidth: 130,
                color: theme.palette.primary.dark,
                lineHeight: "inherit",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem
              key="0"
              value=" "
              onClick={(event) => {
                setIssueStatus("");
                setfiltersSelected(true);
              }}
            >
              Status  All
            </MenuItem>
            {AppConstants.MasterData.Issue.IssueStatus.map(
              (status: any, idx: any) => (
                <MenuItem key={"status_" + idx} value={status.label}
                  onClick={(event) => {
                    setVisible(true);
                    setIssueStatus(dummy1[idx].label);
                    setfiltersSelected(true);
                  }}
                >
                  <>
                    {status.label}
                  </>
                </MenuItem>
              )
            )}
          </TextField>

          <Box sx={{ minWidth: 180, mr: 1 }}>
            <Autocomplete
              value={users?.find(
                (option: any) => option.id == currentUserId
              ) ?? null}
              fullWidth
              id="outlined-basic"
              options={users}
              getOptionLabel={(option) => option?.name}
              onChange={async (event, value) => {
                setCurrentUserId(value?.id ?? null);
                setfiltersSelected(true);

              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  placeholder='Assignee'
                  variant="outlined"
                  fullWidth
                  name="UserId"
                  value={currentUserId} />
              )} />
          </Box>
          <Box sx={{ minWidth: 180, mr: 1 }}>
            <Autocomplete
              value={users1?.find(
                (option: any) => option.id == selectedResourcesId
              ) ?? null}
              fullWidth
              id="outlined-SprintId"
              options={users1}
              getOptionLabel={(option) => option?.name}
              onChange={async (event, value) => {
                setSelectedResourcesId(value?.id ?? null);
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  placeholder='Reporter'
                  variant="outlined"
                  fullWidth
                  name="UserId"
                  value={selectedResourcesId} />
              )} />
          </Box>
          <Box sx={{
            width: 100,
            "& .searcg-field": {
              height: '100%'
            }, "& .css-159ob2j": {
              height: '100%'
            }
          }}>

            <SearchBar
              setSearchTicket={setSearchTicket}
              callBackFunction={triggerSearch}
            />
          </Box>
          {filtersSelected && (
            <Box>
              <Button
              sx={{right:"-96px"}}
                onClick={async () => {
                  setfiltersSelected(false);
                  setProjectType("");
                  setIssueStatus("");
                  setCurrentUserId("");
                  setSelectedResourcesId("");
                }
                }
                variant="contained"
                color="primary"
              >
                Clear
              </Button>
            </Box>
          )}
        </Stack>
        <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0, mt: 2 }}>
          {elements && elements.length > 0 && elements.map((item: any) => (
            <Paper
              key={item.id}
              component="li"
              variant="outlined"
              sx={{
                p: 1,
                "&:hover": {
                  boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
                },
                mb: 1,
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                <Tooltip title={item.issueType}>
                  <Box component={Link}
                    to={`/issue-detail/${item?.id}`}
                    target="_blank"
                    sx={{ textDecoration: "none", color: '#1da7ff', mr: 3 }}>
                    {item.issueType === "Bug" && <Icons.Bug />}
                    {item.issueType === "Story" && <Icons.Story />}
                    {item.issueType === "Epic" && <Icons.Epic />}
                    {item.issueType === "Task" && <Icons.Task />}
                    &nbsp; {item?.ticketNumber}
                  </Box></Tooltip>
                  <Breadcrumbs separator="›" aria-label="breadcrumb" >
                    <Typography key="3" color="text.primary">
                      {item.summary?.length > 52
                        ? item.summary.slice(0, 52) + "..."
                        : item.summary}
                    </Typography>
                  </Breadcrumbs>
                </Stack>
                <Box>
                  {item.status === "Product Backlog" ? "Product Backlog" : item.sprintName}
                </Box>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} >
                  {item.priority === "Highest" && (
                    <Tooltip
                      title={
                        item?.storyPoint &&
                          item?.storyPoint != null &&
                          item?.storyPoint.length > 0
                          ? "Priority: Highest / Story Points: " +
                          item?.storyPoint
                          : "Priority: Highest"
                      }
                    >
                      <TicketLabel
                        icon={<Icons.Highest />}
                        label={item?.storyPoint}
                      />
                    </Tooltip>
                  )}
                  {item.priority === "High" && (
                    <Tooltip
                      title={
                        item?.storyPoint &&
                          item?.storyPoint != null &&
                          item?.storyPoint.length > 0
                          ? "Priority: High / Story Points:" +
                          item?.storyPoint
                          : "Priority: High"
                      }
                    >
                      <TicketLabel
                        icon={<Icons.High />}
                        label={item?.storyPoint}
                      />
                    </Tooltip>
                  )}
                  {item.priority === "Medium" && (
                    <Tooltip
                      title={
                        item?.storyPoint &&
                          item?.storyPoint != null &&
                          item?.storyPoint.length > 0
                          ? "Priority: Medium / Story Points: " +
                          item?.storyPoint
                          : "Priority: Medium"
                      }
                    >
                      <TicketLabel
                        icon={<Icons.Medium />}
                        label={item?.storyPoint}
                      />
                    </Tooltip>
                  )}
                  {item.priority === "Low" && (
                    <Tooltip
                      title={
                        item?.storyPoint &&
                          item?.storyPoint != null &&
                          item?.storyPoint.length > 0
                          ? "Priority: Low / Story Points: " +
                          item?.storyPoint
                          : "Priority: Low"
                      }
                    >
                      <TicketLabel
                        icon={<Icons.Low />}
                        label={item?.storyPoint}
                      />
                    </Tooltip>
                  )}
                  {item.priority === "Lowest" && (
                    <Tooltip
                      title={
                        item?.storyPoint &&
                          item?.storyPoint != null &&
                          item?.storyPoint.length > 0
                          ? "Priority: Lowest / Story Points: " +
                          item?.storyPoint
                          : "Priority: Lowest"
                      }
                    >
                      <TicketLabel
                        icon={<Icons.Lowest />}
                        label={item?.storyPoint}
                      />
                    </Tooltip>
                  )}
                  <AvatarGroup sx={[styles.userAvatar]}>
                    <Tooltip
                      title={
                        item?.assigneeUserName ?? "Un-Assigned"
                      }
                    >
                      <Avatar
                        {...commonFunctions.stringAvatar(item?.assigneeUserName)}
                        sx={{ bgcolor: deepOrange[500], margin: "0 28px 0 0" }}
                        src={`${process.env.REACT_APP_SERVER_URL}${item?.assigneeUserName}`}
                      />
                    </Tooltip>
                  </AvatarGroup>
                </Stack>
              </Stack>
            </Paper>
          ))}
        </Box >
      </Paper >
    </>
  );
}