import React, { useState, useEffect } from "react";
import theme from "../../theme/darkTheme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs, { Dayjs } from "dayjs";
import { calenderService } from "../../service/Calender/CalenderService";
import { toast } from "material-react-toastify";
import NotFound from "../../components/styledComponent/NotFound";

const styles = {
  MonthList: {
    "&.MuiListItemButton-root": {
      borderBottom: "1px solid #e1e1e1",

      "&:hover": {
        background: theme.palette.primary.light,
        "& .MuiTypography-root": {
          color: theme.palette.primary.main,
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "&.Mui-selected": {
      background: theme.palette.primary.light,
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "42px",
      opacity: 0.6,
    },
  },
};

interface calender {
  holidayDate: string;
  occassion: string;
}

interface getcalender {
  id: string;
  holidayDate: string;
  occassion: string;
  day: string;
  date:string;
}

export default function HolidayList() {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [getCalenders, setGetCalenders] = useState<getcalender[]>([]);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected("");
  useEffect(() => {
    getCalenderDetails();
  }, []);

  const getCalenderDetails = async () => {
    let input = {
      occassion: "",
      holidayDate: "",
      day: "",
      month: 0,
      year: new Date().getFullYear(),
      
    };
    await calenderService.getAllCalenderDetails(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setGetCalenders(response?.data);
        // getComparator(order, orderBy);
      }
    });
  };

  return (
      <>
        <form>
          <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Sr No.</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Occasion</TableCell>
                  <TableCell>Day</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* {stableSort(
                          getCalenders,
                          getComparator(order, orderBy)
                        ).map((calender, idx) => { */}

                {getCalenders?.map((calender, idx) => (
                  // return (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    // onClick={(event) => handleClick1(event, calender.occassion)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={calender.id}
                    selected={isItemSelected}
                  >
                    <TableCell>{("0" + (idx + 1)).slice(-2)} </TableCell>
                    <TableCell>
                      {""}
                      {`${moment(calender.date)
                        .format("DD-MMM-YYYY")}`}{" "}
                    </TableCell>
                    <TableCell> {calender.occassion}</TableCell>
                    <TableCell> {calender.day}</TableCell>
                  </TableRow>
                  // );
                  // })}
                ))}
              </TableBody>
            </Table>
            {getCalenders.length == 0 && (
              <NotFound NotfoundText="No Holidays Found" />
            )}
          </TableContainer>
        </form>
      </>
  );
}
