import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Icons from "../../../assests/icons/icons";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SearchBar from "../../../components/styledComponent/SearchBar";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import {
  Grid,
  Modal,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { ErrorMessage, Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import NotFound from "../../../components/styledComponent/NotFound";
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import Masteralltop from "../mastersetupstyle";
import InfoPopover from "../../../components/CommonInfoComponent";
import { Domainservice } from "../../../service/master-service/domain-master-service";
import { Any } from "@react-spring/web";
import Userlisttop from "../../users/userliststyle";
import { Domain, Height } from "@mui/icons-material";
import * as Yup from "yup";
import LongText from "../../../components/longtext";
import { debug } from "console";

interface Skills {
  id: string;
  departmentName: string;
  userName: string;
  employeeId: string;
  department: string;
  domainName: string;
  domainnames:string;
  domain:string;
  name:string;
  description: string;
  io: string;
  manager: string;
  departmentId: string;
  coHodName: string;
  srNo: string;
  synonym:string;
  assignedUsers: AssigneeUsers[];
}

interface AssigneeUsers {
  userId: string;
  name: string;
  assignedDate: string;
}

function AddIO() {
  const _permission = useSelector((state: any) => state.MasterPermissionApp.MasterPermission);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setModalOpen] = React.useState(false);
  const [project, setProject] = useState<any>();
  const pageCount = Math.ceil(project?.length / rowsPerPage);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => { setOpen(false); setModalOpen(false); setdomainNameEdit(""); };
  const [userId, setUserId] = React.useState("");
  const [domainNameEdit, setdomainNameEdit] = React.useState("");
  const [domain, setdomain] = React.useState("");
  const [domainDescription, setdomainDescription] = React.useState("");
  const [domainSynonym, setdomainSynonym] = React.useState("");
  const [users, setUsers] = useState<Depart[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  interface Depart {
    id: string;
    name: string;
    srNo: string;
  }

  const fieldValidationSchema = Yup.object().shape({
    name: Yup.string().required("Domain Name field is required.")
                      .max(100,'Domain can only 100 Alphabets')
                      .matches(/^([A-Za-z\s\-.]*)$/g,'Domain Name can only contain alphabets.'),
    domain: Yup.string().required("Domain field is required.")
                        .max(63,'DomainName can only 63 Alphabets')
                        .matches(/^([A-Za-z\s\-.]*)$/g,'domain can only contain alphabets.'),
    synonym: Yup.string().max(100, 'Synonym can only be 100 characters')
                        .matches(/^[a-zA-Z-.\s]*$/, 'Only alphabets, hyphens, periods, and spaces are allowed'),                    
    description: Yup.string()
                             .max(500,'Description can only 500 Alphabets')
                             .matches(/^([A-Za-z\s\-.]*)$/g,
                             'Description can only contain alphabets.')
                             .required('Description is required'),
  });

  const [buttonDisable, setButtonDisable] = useState(false);
  const deleteDepartmentData = async (id: any) => {

    setModalOpen(true);
    setUserId(id);
  };

  const domainDelete = async () => {
    setModalOpen(false);
    try {
      await Domainservice
        .deleteDomain(userId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Domain deleted  successfully", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            getAllDomain();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const createDomain = async (values: any) => {
    debugger
    try {
    await Domainservice.addDomain(values)
      .then((response: any) => {
        if (response.data.isError) {

        }
        else {
          toast.success("Domain saved successfully.")
          handleClose();
          getAllDomain();
        
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        handleClick();
        toast.warning(err);
      });
    }
  };
  const handleClick = () => {
    setIsDisabled(true);  
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000); 
  };
  const handleClicks = () => {
    setIsDisabled(true);  
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000); 
  };
  const updateDomain = async (values: any) => {
    
    values.id = userId;
    try{
    await Domainservice.updateDomain(values)
      .then((response: any) => {
        if (response.data.isError) {

        }
        else {
          toast.success("Domain updated successfully.")
          handleClose();
          getAllDomain();
          handleClicks();
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClickOpen = (department: any) => {
    
    setUserId(department.id);
    setOpen(true);
    setdomainNameEdit(department.name);
    setdomainDescription(department.description);
    setdomainSynonym(department.synonym);
    setdomain(department.domain);
  };

  const handleClickOpen1 = (department: any) => {
  
    setUserId("");
    setOpen(true);
    setdomainNameEdit("");
    setdomain("");
    setdomainDescription("");
    setdomainSynonym("");
  };
  
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (_permission.page_ManageHeadOfDepartments_Access !== true) {
      navigate("/dashboard");
    }
    getAllDomain();
  }, []);


  const getAllDomain = async () => {
    var input = {
      search: "",
    };
    await Domainservice.GetAllDomainData(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setSearchValue("");
        console.log(response.data);
        setProject(response?.data)
      }
    })
  };

  const triggerSearch = async (newValue: string) => {
    setSearchValue(newValue);
    getSearchDetails(newValue);
    setPage(0);
  };
  /// search programming
  const getSearchDetails = async (newValue: string) => {
    let values = {
      search: newValue,
    };
    await Domainservice.GetAllDomainData(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        setProject(response?.data);
        if (response.data.length == 0) {
        }
      }
    });
  };
  //Sorting

  const [dense, setDense] = React.useState(false);
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Skills;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "S No.",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Domain Name",
    },      
    {
      id: "domain",
      numeric: false,
      disablePadding: false,
      label: "Domain ",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "synonym",
      numeric: false,
      disablePadding: false,
      label: "Synonym",
    },
  ];
  
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Skills>("srNo");

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Skills
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Skills) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align-items="center" align="center">Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Skills
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  let abc = {
    roleId: _authUser.employeeInfo.roleId,
    pageName: oninput,
  }

  const roleIDInfo = _authUser
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
 
  const emptyRows =
    page > 1 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Master Setup</Typography>
        <Typography color="text.primary"> Department Head</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>

        <Stack
          sx={Masteralltop.topbar}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Domain
          </Typography>
          
          <Box>
            {/* <InfoPopover onInput={"Manage HOD"} /> */}
            <InfoPopover onInput={"Domain"} />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={Masteralltop.searchbar}>
            <SearchBar
               value={searchValue}
              callBackFunction={triggerSearch}
            /> </Box>
          {_permission.action_ManageDepartmentsListing_Add_Access && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleClickOpen1(Any)}
            >
              Add Domain
            </Button>
          )}
        </Stack>

        <Divider />

        <TableContainer sx={{ mt: 1 }}>
          <Table aria-label="simple table" size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={project?.length}
            />
            <TableBody>

              {stableSort(project, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.coHodName);
                  const serialNumber = page * rowsPerPage + index + 1;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick1(event, row.coHodName)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}

                    >
                      <StyledTableCell align="left">
                        {serialNumber}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.name ? (<LongText content={row.name}limit ={20}/>):("---")}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.domain ? (<LongText content={row.domain}limit ={20}/>):("---")}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.description ? (<LongText content={row.description}limit ={20}/>):("---")}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.synonym ? (<LongText content={row.synonym}limit ={20}/>):("---")}
                      </StyledTableCell>

                      
                      <StyledTableCell align="left" width={100}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {_permission.action_ManageHeadOfDepartmentsListing_Edit_Access && (
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                size="medium"
                                onClick={() => handleClickOpen(row)}
                              >
                                <Icons.Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                          {_permission.action_ManageDepartmentsListing_Delete_Access && (
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => deleteDepartmentData(row.id)}
                              >
                                <Icons.Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: dense ? 33 : 53,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {project?.length == 0 && <NotFound NotfoundText="No Result Found" />}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={project?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

          labelDisplayedRows={() => `Total: ${project?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>
      <Modal
        open={openModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Userlisttop.actionpopup}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Domain
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              {/* Are you sure, You sure you want to delete it. Continue? */}
              Are you sure, you want to delete this Domain. Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={domainDelete} disabled={buttonDisable}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>


      <Dialog sx={{ "& .MuiDialog-paper": { fieldValidationSchemaoverflow: "visible", height: "unset", width: "45%" } }} open={open} fullWidth maxWidth="sm">
        <Formik
          initialValues={{ name: domainNameEdit ?? "", description: domainDescription ?? "",synonym:domainSynonym ?? "",domain: domain ?? ""}}
          validationSchema={fieldValidationSchema}
          enableReinitialize
          onSubmit={async (values) => {
            
            
            try {

              if (!userId) {
                await createDomain(values);
              } else {
                await updateDomain(values);
              }
            } catch (ex: any) {
              if (ex.data && ex.data.errors) {
                ex.data.errors.forEach((err: any) => {
                  toast.warning(err);
                });
              } else {
                console.error(ex);
              }
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            isSubmitting,
            touched,
            handleBlur,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Domain Name </DialogTitle>
              <DialogContent sx={{ overflow: "visible !important" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <TextField
              margin="dense"
              id="domainName"
            //  name="domainName"            
              onChange={(e) => { 
                let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g,"")
                value = value.replace(/\s{2,}$/g, "").trimStart().substring(0,100);
                values.name = value
                setdomainNameEdit(value); }}
              label="Domain Name"
              type="text"
              fullWidth
              value={domainNameEdit}
              variant="outlined"
              onBlur={handleBlur}
              required
              error={Boolean(
                getIn(touched,"name") && getIn(errors, "name")
              )}
              
              helperText={touched.name && errors.name}
              InputProps={{
                style: {
                  borderColor: touched.name && errors.name ? 'red' : 'default',
                },
              }}
            />
          </Box>
          <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "right" ,
          "font-size": "13px",
          "margin-right": "2%",
          "margin-top":"-2%"}}
      >
        Max 100 words
      </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <TextField
              margin="dense"
              id="domain"            
              onChange={(e) => { 
                let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g,"")
                value = value.replace(/\s{2,}$/g, "").trimStart().substring(0,63);
                values.domain = value
                setdomain(value); }}
              label="Domain"
              type="text"
              fullWidth
              value={domain}
              onBlur={handleBlur}
              variant="outlined"             
              error={Boolean(
                getIn(touched,"domain") && getIn(errors, "domain")
              )}
              helperText={
                getIn(touched, "domain") &&
                getIn(errors, "domain")
              }
              required
            />
            <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "right" ,
          "font-size": "13px",
          "margin-right": "2%",
          "margin-top":"-2%"}}
      >
        Max 63 words
      </Typography>
          </Box>
        </Grid>
       
        <Grid item xs={12} >
          
           <Box>
            <TextField
              
              margin="dense"
              id="department-name"
              name="synonym"
              label="Synonym"
              type="text"
              fullWidth
              variant="outlined"
              onBlur={handleBlur}
              onChange={(e) => { 
                let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g,"")
                value = value.replace(/\s{2,}$/g, "").trimStart().substring(0,100);
                values.synonym = value
                setdomainSynonym(value); }}
              value={values.synonym}
              
              error={Boolean(
                getIn(touched,"synonym") && getIn(errors, "synonym")
              )}
              helperText={
                getIn(touched, "synonym") &&
                getIn(errors, "synonym")
              }
              InputProps={{
                style: {
                  borderColor: touched.synonym && errors.synonym ? 'red' : 'default',
                }
              }}
            />
          </Box>
          {/* <ErrorMessage name="synonym" component="div" style={{ color: 'red' }} /> */}
          <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "right" ,
          "font-size": "13px",
          "margin-right": "2%",
          "margin-top":"0%"}}
      >
        Max 100 words
      </Typography>
        </Grid>
      
        <Grid item xs={12}>
          <Box >
            <TextField
              margin="dense"
              id="department-name"
              onChange={(e) => { 
                let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g,"")
                value = value.replace(/\s{2,}$/g, "").trimStart().substring(0,500);
                values.description = value
                setdomainDescription(value); }}
              name="description"
              label="Description"
              type="text"
              fullWidth
              onBlur={handleBlur}
              multiline
              value={domainDescription}
              // error={Boolean(
              //   getIn(touched,"description") && getIn(errors, "description")
              // )}
              // helperText={Boolean(
              //   getIn(touched,"description") && getIn(errors, "description")
              // )}

              error={Boolean(
                getIn(touched,"description") && getIn(errors, "description")
              )}
              helperText={
                getIn(touched, "description") &&
                getIn(errors, "description")
              }
              required
              variant="outlined"
              // sx={{
              //       display: "block",
              //       mt: 2, 
              //       "word-break": "break-all",
              //       "line-height": "1.5",  
              //       "padding": "10px",    
              //       '& .MuiInputBase-root': { height: 'auto' },
              //       "& .MuiOutlinedInput-root": {  
              //           "line-height": "1.5",
              //           "padding": "10px", 
              //       }
              //   }}
              sx={{
                display: "block",
                mt: 2,
                wordBreak: "break-all",
                lineHeight: "1.5",
                padding: "10px",
                '& .MuiInputBase-root': { 
                  height: 'auto',
                  minHeight: '100px' // Set the minimum height
                },
                "& .MuiOutlinedInput-root": {
                  lineHeight: "1.5",
                  padding: "10px",
                  alignItems: "flex-start", // Aligns the text to the top for multiline input
                }
              }}
            />
            <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "right" ,
          "font-size": "13px",
          "margin-right": "2%",
          "margin-top":"-2%"}}
      >
        Max 500 words
      </Typography>
          </Box>
         
      </Grid>
      </Grid>
    </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit"  disabled={isDisabled}  variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

    </>
  );
}

export default AddIO;

