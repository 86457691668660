import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, IconButton, TextField, Typography } from "@mui/material";
import { toast } from "material-react-toastify";
import * as Icons from "../assests/icons/icons";
import { reportService } from "../service/Reports/reports";
import { useSelector } from "react-redux";

export default function WildLifePhotoGraphy(props: any) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [imageUrl, setImageUrl] = React.useState<string>("");
  const [isTextFieldEmpty, setIsTextFieldEmpty] = React.useState<boolean>(false);
  const [reports, setReports] = React.useState<any[]>([]);
  const [selectedReport, setSelectedReport] = React.useState<any>(null);

  React.useEffect(() => {
    getReport("", "");
  }, []);

  const getReport = async (
    departmentId: string = "", resourceId: string = ""
  ) => {
    try {
      const req = {
        departmentId: departmentId,
        userId: resourceId,
      };
      const response = await reportService.getWildLifePhotographyReport(req);
      if (response.data.isError) {
        setReports([]);
      } else {
        setReports(response.data);
        const selected = response.data.find((record: any) => record.userId === _authUser.id);
        setSelectedReport(selected);
        if (selected) {
          setImageUrl(selected.imageLink);
        } else {
          setImageUrl("");
        }
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any) => {
        toast.warning(err);
      });
    }
  }

  const handleCancel = () => {
    setImageUrl("");
    setIsTextFieldEmpty(false);
  }

  const uploadImageLink = async () => {
    if (!imageUrl.trim()) {
      setIsTextFieldEmpty(true);
      return;
    }
    try {
      await reportService.addWildLifePhotography({ imageLink: imageUrl });
      toast.success("Link Added Successfully")
      setImageUrl("");
      setIsTextFieldEmpty(false);
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.currentTarget.value);
    setIsTextFieldEmpty(false);
  }

  return (
    <><Box sx={{ mt: "5px", display: "flex" }}>
      <Typography sx={{ fontSize: "24px"}}>Wildlife Photography</Typography></Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Paper sx={{ maxWidth: "600px", width: "100%", padding: "20px" }}>
        <Box>
          <Typography sx={{ fontSize: "24px" }}>File Upload</Typography>
          <TextField
            required
            sx={{ width: "100%", marginBlock: "16px" }}
            placeholder="Please Upload Your Onedrive Link Here..."
            value={imageUrl}
            onChange={handleTextFieldChange}
            error={isTextFieldEmpty}
            helperText={isTextFieldEmpty ? "This field is required" : ""}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <Icons.UploadSave />
                </IconButton>
              )
            }} />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
            <Button color="primary" variant="contained"
              sx={{ ml: 0.5 }} onClick={handleCancel}>
              Cancel
            </Button>
            {selectedReport && selectedReport.userId === _authUser.id ? (
              <Button color="primary" variant="contained"
                sx={{ ml: 0.5 }} onClick={uploadImageLink}>
                Update
              </Button>
            ) : (
              <Button color="primary" variant="contained"
                sx={{ ml: 0.5 }} onClick={uploadImageLink}>
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Box></>
  );
}
