import { http } from "../http-common";
class MasterReasonServices {
  getReason = async (): Promise<any> => {
    return await http.post<any>("/v1/BrownieReason/GetAllBrownieReason");
  };
  getReasonById = async (id: any): Promise<any> => {
    return await http.get<any>(
      `/v1/BrownieReason/SearchBrownieReasonById?id=${id}`
    );
  };

//   getEmployeeById = async (id: any): Promise<any> => {
//     return await http.get<any>(
//       `/v1/BrownieReason/GetAllEmployeeDetailRewardPoints?UserId=${id}`
//     );
//   };

  GetAllEmployeeDetailRewardPoints = async (data: any): Promise<any> => {
    return await http.get<any>("/v1/BrownieReason/GetAllEmployeeDetailRewardPoints?UserId="+data);
};

  createReason = async (leave: any): Promise<any> => {
    return await http.post<any>("/v1/BrownieReason/AddBrownieReason", leave);
  };

  updateReason = async (leave: any): Promise<any> => {
    return await http.put<any>("/v1/BrownieReason/UpdateBrownieReason", leave);
  };

  deleteReason = async (id: any): Promise<any> => {
    return await http.delete<any>(
      `/v1/BrownieReason/DeleteBrownieReasonById?id=${id}`
    );
  };

  createUpdateBrownieSetting = async (reward: any): Promise<any> => {
    return await http.post<any>(
      "/v1/BrownieReason/AddOrUpdateBrownieSetting",
      reward
    );
  };

  getBrownieSetting = async (): Promise<any> => {
    return await http.get<any>("/v1/BrownieReason/SearchBrownieSetting");
  };

  getAllMonthlyReward = async (parmas: any): Promise<any> => {
    return await http.post<any>(
      "/v1/BrownieReason/GetAllMonthlyReward",
      parmas
    );
  };

  getAllAddOnRequest = async (parmas: any): Promise<any> => {
    return await http.post<any>("/v1/BrownieReason/GetAllAddOnRequest", parmas);
  };

  getDropdownData = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Master/DropdownData/GetByName", data);
  };

  approveAddOnRequest = async (parmas: any): Promise<any> => {
    return await http.post<any>(
      "/v1/BrownieReason/ApproveAddOnRequest",
      parmas
    );
  };

  getAllRewardRequest = async (parmas: any): Promise<any> => {
    return await http.post<any>("/v1/BrownieReason/GetRedeemHistory", parmas);
  };
  ApprovePaid = async (parmas: any): Promise<any> => {
    return await http.put<any>("/v1/BrownieReason/UpdateRedeemStatus", parmas);
  };
  GetManagerDetailsByDepartmentId = async (parmas: any): Promise<any> => {
    return await http.post<any>("/v1/BrownieReason/GetManagerDetailsByDepartmentId", parmas);
  };
}
export const masterreasonServices = new MasterReasonServices();
