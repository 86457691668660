import { toast } from "material-react-toastify";
import { boardDashboardServive } from "../BoardDashboardServive";

const getSprintDetailBySprintId =async (id:any)=>{
    try {
        var response = await boardDashboardServive.getSprintById(id);
        if (response.data.isError) {
          return [];
        } else {
          return response?.data?.status;
        }
      } catch (ex: any) {
        ex.data.Error.map((err: any, idx: any) => {
          toast.warning(err);
         
        });
      }
}

export default getSprintDetailBySprintId;