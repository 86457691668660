import React from "react";
import theme from '../theme/darkTheme';

const Dashboardstyle ={
    Fullheight:{    height:'100%',},

    link: {
        color: theme.palette.grey[900],
        margin: 0,
        marginLeft: theme.spacing(2),
        '&:hover': {
            color: theme.palette.primary.dark,
        }
    },
    Readmore:{
         whiteSpace: "break-spaces", color: "#616161", fontSize: "14px", lineHeight: "26px", fontWeight: "300", mt: 2 ,
    },
    Gopage:{ 
        textDecoration: "none", color: theme.palette.primary.main, mt: 1, display:"block" ,
    },
    Tdnone:{
        textDecoration: 'none',
    }

}
export default Dashboardstyle;