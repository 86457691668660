import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Notes from "../../../components/styledComponent/Note";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as Icons from "../../../assests/icons/icons";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Avatar, Chip, Divider, Icon, TablePagination, Tooltip } from "@mui/material";
import theme from "../../../theme/darkTheme";
import { Formik, getIn } from "formik";
import { browniemanager } from "../../../service/brownie-pointManagerServices";
import { toast } from "material-react-toastify";
import { clsDepartmentList } from "../../../service/common-services/department";
import { clsResourcesList } from "../../../service/common-services/resources";
import { IResource } from "../../../Interfaces/IResource";
import { Department } from "../../../Interfaces/IDepartment";
import {
  IBrowniePointsManager,
  IBrowniePointsManagerFilter,
  IBrowniePointsReason,
  IEmployeeBrowniePointsDetail,
  IRecommendPointsManager,
  IRecommendPointsManagerApproveRejectRequest,
} from "../../../Interfaces/IBrowniePoints";
import { browniePointsManagerServices } from "../../../service/BrowniePoints/manager";
import { clsBrowniePointsList } from "../../../service/common-services/browniePoints";
import { Manager } from "../../../Interfaces/IManager";
import * as Yup from "yup";
import { Monts, Monts1 } from "../../../Enum/eMonths";
import { SpaRounded } from "@mui/icons-material";
import moment from "moment";
import { blue } from "@mui/material/colors";
import { useSelector } from "react-redux";
import Reasons from "../MasterReasonData/Reasons";
import InfoPopover from "../../../components/CommonInfoComponent";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from "react-rainbow-components";
// import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const styleCross = {
  position: "absolute" as "absolute",
  right: "15px",
  top: "15px",
};

const styleCrossReasonReject = {
  position: "absolute" as "absolute",
  right: "24px",
  top: "16px",
};

const styles = {
  view: {
    label: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#1DA7FF",
    },
    value: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      color: theme.palette.primary.dark,
    },
    tBodyScroll: {
      height: "450px",
      overflowY: "scroll",
    },
    tHeaderFixed: {
      position: "sticky",
      top: "0",
      backgroundColor: "#ffffff"
    }
  },
};

export default function BrowniePoint() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const [rejectResonOpen, setRejectResonOpen] = React.useState(false);
  const montsList = Object.keys(Monts);
  const [value, setValue] = React.useState("1");
  const [rejectReasonFormik, setRejectReasonFormik] = React.useState("");

  const resetPagination = () => {
    setPage(0);
    setPage1(0);
  };
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    resetPagination();
  };


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);


  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage(0);
  };

  // -----------------------------

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickListItemDate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id } = useParams();

  const [selectedIndexPoints, setSelectedIndexPoints] = React.useState(0);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const handleClickListItemPoints = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const openPoints = Boolean(anchorEl2);
  const handleMenuItemClickPoints = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndexPoints(index);
    setAnchorEl2(null);
  };

  // -----------------------------------------

  const [openRecommendPoints, setOpenRecommendPoints] = React.useState(false);
  const handleClickOpenRecommendPoints = () => {
    setOpenRecommendPoints(true);
    setButtonDisable(false)
  };
  const handleCloseRecommendPoints = () => {
    setOpenRecommendPoints(false);
  };

  const [openRequestBrownie, setOpenRequestBrownie] = React.useState(false);
  const handleClickOpenRequestBrownie = () => {
    setOpenRequestBrownie(true);
  };
  const handleCloseRequestBrownie = () => {
    setOpenRequestBrownie(false);
  };

  const [openView, setOpenView] = React.useState(false);

  const handleCloseView = () => {
    setOpenView(false);
  };
  const [errorMessage, setErrorMessage] = useState(false);

  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [browniePointsList, setbrowniePointsList] =
    React.useState<IBrowniePointsManager>();
  const [recommendRewardsList, setrecommendRewardsList] = React.useState<
    IRecommendPointsManager[]
  >([]);

  const pageCount = Math.ceil(recommendRewardsList.length / rowsPerPage);
  const pageCount1 = Math.ceil(browniePointsList?.listOutputModels?.length || 0 / rowsPerPage1);


  const [browniePointsReasonList, setbrowniePointsReasonList] = React.useState<
    IBrowniePointsReason[]
  >([]);
  const [employeeBrowniePointsDetail, setemployeeBrowniePointsDetail] =
    React.useState<IEmployeeBrowniePointsDetail>();
  const [HodByDepartment, setHodByDepartment] = React.useState<Manager>();
  let DepartmentId = "";
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [selectedDepartmentIndex, setselectedDepartmentIndex] =
    React.useState(0);
  const [departmentHook, setDepartmentHook] = React.useState("");
  const [reportDepartmentsEl, setreportDepartmentsEl] =
    React.useState<null | HTMLElement>(null);
  const openReportDepartmentsEl = Boolean(reportDepartmentsEl);
  const handleReportDepartmentsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportDepartmentsEl(event.currentTarget);
  };
  const handleReportDepartmentsClose = () => {
    setreportDepartmentsEl(null);
  };
  const handleDepartmentItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setDepartmentHook(departments[index].id);
    setselectedDepartmentIndex(index);
    setresources(
      await clsResourcesList.getResourcesDropdownList(departments[index].id)
    );
    setresourceHook("");
    DepartmentId = departments[index].id;
  };

  let ResourceId = "";
  const [resources, setresources] = React.useState<IResource[]>([]);
  const [selectedResourceIndex, setselectedResourceIndex] = React.useState(0);
  const [resourceHook, setresourceHook] = React.useState("");
  const [reportResourcesEl, setreportResourcesEl] =
    React.useState<null | HTMLElement>(null);
  const openReportResourcesEl = Boolean(reportResourcesEl);
  const handleReportresourcesClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportResourcesEl(event.currentTarget);
  };
  const handleReportResourcesClose = () => {
    setreportResourcesEl(null);
  };
  const handleResourceItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setresourceHook(resources[index].userId);
    setselectedResourceIndex(index);
    ResourceId = resources[index].userId;
  };

  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1)
  const dateString = currentMonth.toString();
  const [fromDate, setfromDate] = React.useState<any>(null);
  const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);

  var monthName = currentDate.toLocaleString('default', { month: 'long' });
  const [selectedReason, setSelectedReason] = React.useState("");
  // let month = "";
  const [selectedUserId, setSelectedUserId] = React.useState("");
  const [selectedmonthsIndex, setselectedmonthsIndex] = React.useState(0);
  const [monthsHook, setmonthsHook] = React.useState<any>(currentMonth);
  const [monthsNameHook, setmonthsNameHook] = React.useState<any>([]);
  const [selectedMonth, setSelectedMonth] = React.useState(monthName);
  const [reportmonthsEl, setreportmonthsEl] =
    React.useState<null | HTMLElement>(null);
  const openReportmonthsEl = Boolean(reportmonthsEl);
  const handleReportmonthsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportmonthsEl(event.currentTarget);
  };
  const handleReportmonthsClose = () => {
    setreportmonthsEl(null);
  };
  const handlemonthsItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setmonthsHook(montsList[index]);
    setselectedmonthsIndex(index);
  };

  const Status = ["Approved", "Reject"];
  const [anchorStatusEl, setAnchorStatusEl] =
    React.useState<null | HTMLElement>(null);
  const openItem = Boolean(anchorStatusEl);
  const [dynamicRowId, setdynamicRowId] = React.useState("");
  const handleClickStatusListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorStatusEl(event.currentTarget);
    setdynamicRowId(event.currentTarget.id);
  };
  const handleStatusClose = () => {
    setAnchorStatusEl(null);
  };

  const handleSatusItemClick = (event: React.MouseEvent<HTMLElement>) => {
    if (approvebuttonDisable) {
      return;
    }
    setAnchorStatusEl(null);
    if ("Approved" === event.currentTarget.innerText) {
      ApproveRecomendation(dynamicRowId, 1);
    } else if ("Reject" === event.currentTarget.innerText) {
      //ApproveRecomendation(dynamicRowId, 2);
      setRejectResonOpen(true);
    }
  };

  const handleCloseRejectReason = () => {
    setRejectResonOpen(false);
    // setLeaveOpen(false);
    // setTimeout(() => {
    //   setId("");
    // }, 500);
  };

  const ApproveRecomendation = async (id: any, status: any) => {
    debugger
    setapprovebuttonDisable(true);
    try {
      let values: IRecommendPointsManagerApproveRejectRequest = {
        id: id,
        status: status,
        rejectReason: "",
      };
      await browniePointsManagerServices
        .ApproveRecomendation(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error(response.data.listErrors[0], {
              onClose: () => setapprovebuttonDisable(false)
            });

          } else if (status === 1) {
            toast.success("Approved successfully.", {
              onClose: () => setapprovebuttonDisable(false)
            });
            getEmployeeData(resourceHook, monthsHook, "", "");
          } else if (status === 2) {
            // toast.success("Rejected successfully.");
            getEmployeeData(resourceHook, monthsHook,"", "");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
       
      });
    }
  };

  const RejectRecomendation = async (values: any) => {
    try {
      await browniePointsManagerServices
        .ApproveRecomendation(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          }
          // else if (status === 1) {
          //   // toast.success("Approved successfully.");
          //   getEmployeeData(resourceHook, monthsHook);
          // } else if (status === 2) {
          toast.success("Rejected successfully.");
          setRejectReasonFormik("");
          setRejectResonOpen(false);
          getEmployeeData(resourceHook, monthsHook ,"", "");
          // }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getAllEmployeeDetailRewardPoints = async (userId: string) => {
    await browniePointsManagerServices
      .GetAllEmployeeDetailRewardPoints(userId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setemployeeBrowniePointsDetail(response.data);
          setOpenView(true);
        }
      });
  };

  const getEmployeeRewardList = async (
    resource: string = "",
    months: string = "",
    searchValue: string = "",
    year: any,
  ) => {
    var BrowniePointsManagerFilter: IBrowniePointsManagerFilter = {
      pointType: 1,
      resources: resource,
      months: months,
      year: new Date(year).getFullYear(),
      isReferScreen: false,
      status: searchValue,
    };
    await browniePointsManagerServices
      .GetAllEmployeeReward(BrowniePointsManagerFilter)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setbrowniePointsList(response.data);
        }
      });
  };
  const getAllRecommendRewardsList = async (
    resource: string = "",
    months: string = "",
    searchValue: string = "",
    year: any,
  ) => {
    var BrowniePointsManagerFilter: IBrowniePointsManagerFilter = {
      pointType: 1,
      resources: resource,
      months: months,
      year: new Date(year).getFullYear(),
      isReferScreen: false,
      status: searchValue,
    };
    await browniePointsManagerServices
      .GetAllRecommendRewards(BrowniePointsManagerFilter)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setrecommendRewardsList(response.data);
        }
      });
  };
  const getEmployeeData = async (
    resource: string = "",
    months: string = "",
    searchValue: string = "",
    year: any ,
  ) => {
    getEmployeeRewardList(resource, months, searchValue, year);
    getAllRecommendRewardsList(resource, months, searchValue, year);
  };
  const getHodByDepartment = async () => {
    await browniePointsManagerServices
      .getManagerName(_authUser.employeeInfo.departmentId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setHodByDepartment(response.data);
        }
      });
  };


  const AddRequestRewardsPointHR = async (values: any) => {
    try {
      await browniePointsManagerServices
        .RequestRewardsPointHR(values)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Reward points requested successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });

            getEmployeeData(resourceHook, monthsHook,"", currentYearString);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setButtonDisable(false)
      });
    }
  };
  const [buttonDisable, setButtonDisable] = useState(false);
  const [approvebuttonDisable, setapprovebuttonDisable] = useState(false);
  const AddRequestRewardPoint = async (values: any) => {
    setButtonDisable(true)
    try {
      await browniePointsManagerServices
        .AddRequestRewardPoint(values)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Reward points assigned successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });

            getEmployeeData(resourceHook, monthsHook,"", "");
          }
          setButtonDisable(false)
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {
          onClose: () => setButtonDisable(false),
        });

      });
    }
  };

  const fieldValidationSchemaRequestPoints = Yup.object().shape({
    requestedPoint: Yup.string()
      .matches(/^[0-9]+$/, "Points can only contains Numbers")
      .required("No. of Points are required"),
    reason: Yup.string().required("Reason is required"),
  });
  const fieldValidationSchemaAssignPoints = Yup.object().shape({
    reason: Yup.string().required("Reason is required"),
    userId: Yup.string().required("Resource is required"),
    comment: Yup.string().required("Comment is required")
    // comment: (selectedReason == "Others" ?
    //  Yup.string().required("Comment is Required") : Yup.string()),
  });

  useEffect(() => {
    if (_permission.page_Manager_BrowniePoint_Access !== true) {
      navigate("/dashboard");
    }
    setmonthsNameHook(Monts1);
   
    const GetAsyncData = async () => {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setresources(
        await clsResourcesList.getResourcesDropdownList(
          _authUser.employeeInfo.departmentId
        )
      );
      setbrowniePointsReasonList(
        await clsBrowniePointsList.getBrowniePointsList()
      );
      getHodByDepartment();      
      getEmployeeData(resourceHook, monthsHook, "", currentYearString);
      setfromDate(currentYearString);
     
    };
    GetAsyncData();
   
  }, []);

  const fieldValidationSchema = Yup.object().shape({
    rejectReason: Yup.string()
      .required("Reason is required")
      .max(100, "Reason can only contain max 100 characters")
      .matches(/^([A-Za-z\s\.]*)$/g, "Reason can only contains Alphabets "),
  });

  // const [statusOption, setStatusOption] = useState<any>();

  const [statusOption, setStatusOption] = React.useState<any>("");
  const statusList = [
    { label: 'All', statusId: "" },
    { label: 'Approved', statusId: "Approved" },
    { label: 'Rejected', statusId: "Reject" },
    { label: 'Pending', statusId: "Pending" },]
  const handleMenuItemClick1 = (value: any) => {
    //setStatusOption(value);
    if (value == "All") {
      getEmployeeData(resourceHook, monthsHook, "" , "");
      // setStatusOption("All");
    }
    else {
      getEmployeeData(resourceHook, monthsHook, value,currentYearString );
    }
  };
  const handleMenuItemClickMonth = (value: any) => {
    setmonthsNameHook(value);
    getEmployeeData(resourceHook, monthsHook, value, "");
  };

  const handleClearClick = () => {
    setStatusOption('All');
    getEmployeeData(resourceHook, monthsHook, '', "");
  };
  const handleClearClickMonth = () => {
    setSelectedMonth(monthName);
    getEmployeeData(resourceHook, dateString, "","");
  };

  let currentYear = new Date().getFullYear();
  var currentYearString = currentYear.toString();
const Years = [];
for (let i = 0; i < 4; i++) {
  Years.push({
    id: currentYear - i,
    label: (currentYear - i).toString()
  });
}


  const totalPointValue = employeeBrowniePointsDetail?.listOutputModels.reduce((sum: any, item: any) => sum + item.valueOfPoint, 0);
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Points</Typography>
        <Typography color="text.primary">Manager Brownie Point</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
          <InfoPopover onInput={"Manager Brownie Point"} />
        </Stack>

        <Stack
          direction={{ xs: 'column', xl: 'row' }}
          spacing={2}
          justifyContent={{ xs: 'start', xl: 'space-between' }}
          alignItems={{ xs: 'strech', xl: 'center' }}
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Rewards
          </Typography>

         
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width: "100%"}}>
              <Box sx={{display: "flex", alignItems:"center", justifyContent:"space-between"}}>
              {value !== "2" && (
                <Box sx={{ width: "165px", lineHeight: "0.80em", }} >
                  {/* <TextField
                id="reports-period"
                variant="outlined"
                // label="Select Status"
                value={statusOption}
                select
                fullWidth
                InputProps={{
                  endAdornment: statusOption != "All" && (
                    <div style={{ marginRight: "18px" }}>
                      <IconButton onClick={handleClearClick}>
                        <ClearIcon />
                      </IconButton>
                    </div>
                  ),
                }}
              >
                <MenuItem key="All" value="All" selected={statusOption === 'All'}
                  onClick={() => { handleMenuItemClick1('All'); setPage(0); }}>
                  All
                </MenuItem>
                <MenuItem key="Approved" value="Approved" selected={statusOption === 'Approved'}
                  onClick={() => { handleMenuItemClick1('Approved'); setPage(0); }}>
                  Approved
                </MenuItem>
                <MenuItem key="Reject" value="Reject" selected={statusOption === 'Reject'}
                  onClick={() => { handleMenuItemClick1('Reject'); setPage(0); }}>
                  Rejected
                </MenuItem>
                <MenuItem key="Pending" value="Pending" selected={statusOption === 'Pending'}
                  onClick={() => { handleMenuItemClick1('Pending'); setPage(0); }}>
                  Pending
                </MenuItem>
              </TextField> */}
                  <Autocomplete
                    disableClearable={statusOption && statusOption != "" ? false : true}
                    value={
                      statusList?.find(
                        (option) => option.statusId == statusOption
                      ) ?? null
                    }
                    sx={{

                      mr: 1,
                      lineHeight: "0.80em",
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'flip',
                            enabled: true,
                            options: {
                              altBoundary: true,
                              flipVariations: true,
                              altAxis: true,
                              padding: 8,
                              fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                            },
                          },
                        ],
                      },
                    }}
                    fullWidth
                    id="reports-period"
                    options={statusList}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => {
                      setPage(0);
                      setPage1(0);
                      setStatusOption(value?.statusId ?? "");
                      handleMenuItemClick1(value?.statusId ?? "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="outlined-basic"
                        name="selectedResourceId"
                        variant="outlined"
                        value={statusOption}
                        fullWidth
                      />
                    )}
                  />
                </Box>)}
              <Box sx={{ width: "200px", lineHeight: "0.80em", }} style={{ margin: "auto 10px " }}>
                {/* <Button
                id="reports-Department"
                aria-controls={openReportResourcesEl ? 'reports-resource-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openReportResourcesEl ? 'true' : undefined}
                onClick={handleReportresourcesClick}
                variant='selectSmall'
              >
                {resources.find(a => a.userId == resourceHook ?? "Resources")?.userName} <ArrowDropDownIcon />
              </Button>
              <Menu
                id="reports-resource-menu"
                anchorEl={reportResourcesEl}
                open={openReportResourcesEl}
                onClose={handleReportResourcesClose}
                MenuListProps={{
                  'aria-labelledby': 'eports-period',
                }}
              >
                {resources.map((resource, index) => (
                  <MenuItem
                    key={resource.userId}
                    selected={index === selectedResourceIndex}
                    onClick={(event) => {
                      handleResourceItemClick(event, index);
                      setreportResourcesEl(null);
                      setfiltersSelected(true);
                      getEmployeeData(resource.userId, monthsHook) 
                    }
                    }
                  >
                    {resource.userName }
                  </MenuItem>
                ))}

              </Menu> */}

                <Autocomplete
                  value={
                    resources?.find(
                      (option) => option.userId == selectedUserId
                    ) ?? null
                  }
                  sx={{
                    mr: 1,
                    lineHeight: "0.80em",
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  fullWidth
                  id="selectedUserId"
                  options={resources}
                  getOptionLabel={(option) => option.userName}
                  onChange={(event, value) => {
                    setPage(0);
                    setPage1(0);
                    setreportResourcesEl(null);
                    setfiltersSelected(true);
                    ResourceId = value?.userId ?? "";
                    getEmployeeData(value?.userId, monthsHook, statusOption,"");
                    setSelectedUserId(value?.userId ?? "");
                    setresourceHook(value?.userId ?? "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedUserId}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: "165px", lineHeight: "0.80em", }} style={{ marginLeft: "5px" }}>
                {/* <Button
                id="reports-Department"
                aria-controls={
                  openReportmonthsEl ? "reports-months-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openReportmonthsEl ? "true" : undefined}
                onClick={handleReportmonthsClick}
                variant="selectSmall"
              >
                {monthsNameHook}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="reports-months-menu"
                anchorEl={reportmonthsEl}
                open={openReportmonthsEl}
                onClose={handleReportmonthsClose}
                MenuListProps={{
                  "aria-labelledby": "eports-period",
                }}
              >
                {montsList.map((key, index) => (
                  <MenuItem
                    key={key}
                    onClick={(event) => {
                      setPage(0);
                      setPage(0);
                      let v = index == 0 ? "" : index;
                      handlemonthsItemClick(event, index);
                      setreportmonthsEl(null);
                      setfiltersSelected(true);
                      setmonthsHook(v.toString());
                      setmonthsNameHook(key);
                      getEmployeeData(resourceHook, v.toString());
                    }}
                  >
                    {key}
                  </MenuItem>
                ))}
              </Menu> */}
                {/* <TextField
                id="outlined-basic"
                variant="outlined"
                value={selectedMonth}
                select
                fullWidth
                InputProps={{
                  endAdornment: selectedMonth != monthName && (
                    <IconButton onClick={handleClearClickMonth} sx={{ marginRight: "18px" }}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              >
                {monthsNameHook.map((key: any, index: any) => (
                  <MenuItem
                    key={key.value}
                    value={key.label}
                    onClick={(event) => {
                      setPage(0);
                      let v = index == 0 ? "" : index;
                      handlemonthsItemClick(event, index);
                      setreportmonthsEl(null);
                      setfiltersSelected(true);
                      setmonthsHook(v.toString());
                      setSelectedMonth(key?.label ?? "");
                      getEmployeeData(resourceHook, v.toString());
                    }}
                  >
                    {key.label}
                  </MenuItem>
                ))}
              </TextField> */}
                <Autocomplete
                  value={
                    monthsNameHook?.find(
                      (option: any) => option.label == selectedMonth
                    ) ?? null
                  }
                  sx={{
                    mr: 1,
                    lineHeight: "0.80em",
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  fullWidth
                  id="outlined-basic"
                  options={monthsNameHook}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    setPage(0);
                    setreportmonthsEl(null);
                    // setfiltersSelected(true);
                    setmonthsHook(value?.id ?? "");
                    setSelectedMonth(value?.label ?? monthName);
                    getEmployeeData(resourceHook, value?.id ?? dateString, statusOption,"");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedMonth}
                      fullWidth
                    />
                  )}
                />
              </Box>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker

                  placeholder="Year"
                  style={{ width: '20%', marginLeft: "10px", height: "100%" }}
                  formatStyle="medium"
                  label= "Year"
                  labelAlignment="left"
                  borderRadius="semi-square"
                  value={fromDate}
                  onChange={(newValue: any) => {
                    debugger
                    let abc = new Date(newValue)
                    setfromDate(abc);
                    setfiltersSelected(true)
                    // getReport(newValue)
                  }}


                 />
                </LocalizationProvider>
              </Box>
             
              <Box> */}
               <Autocomplete
                  value={
                    Years?.find(
                      (option: any) => option.id == fromDate
                    ) ?? null
                  }
                  sx={{
                    mr: 1,
                    lineHeight: "0.80em",
                    width: "170px",
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  fullWidth
                  id="outlined-basic"
                  options={Years}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    setfromDate(value?.label ?? currentYearString)
                    setPage(0);
                    setfiltersSelected(true);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedMonth}
                      fullWidth
                    />
                  )}
                />
              {filtersSelected && (
                <><Button
                  color="primary"
                  sx={{ ml: 0.5 }}
                  onClick={() => {
                    getEmployeeRewardList(resourceHook, monthsHook, statusOption, fromDate);
                    getAllRecommendRewardsList(resourceHook, monthsHook, statusOption, fromDate);
                  }}
                  variant="contained"
                >
                  Apply
                </Button><Button
                  onClick={() => {
                    
                    setfiltersSelected(false);
                    setfromDate(currentYearString);
                    setStatusOption("");
                    setSelectedUserId("");
                    setSelectedMonth(Monts1[0].label);
                    getEmployeeRewardList("", monthsHook, "", currentYearString);
                    getAllRecommendRewardsList("", monthsHook, "", currentYearString);
                  }}
                  color="warning"
                  variant="contained"
                  sx={{ ml: 0.5 }}
                >
                    Clear
                  </Button></>
              )}
              </Box>
              
             
             

            </Stack>



          </Stack>
          <Box sx={{ textAlign: "end" }}>
            <Button variant="contained" onClick={handleClickOpenRequestBrownie}>
              <AddIcon /> Request Brownie Points
            </Button>
          </Box>
        </Stack>
        <Paper
          elevation={0}
          sx={{ p: 2, my: 2, background: '#F0F9FF', height: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item sm={6} md={3}>
              <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icons.Peopleicon />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    Total Resources
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }}>
                    {browniePointsList?.totalResource}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item sm={6} md={3}>
              <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icons.Moneyconverticon />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    Monthly Allotment
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }} >
                    {browniePointsList?.monthlyAllotment}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item sm={6} md={3}>
              <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icons.Totalpointsicon />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    Total assigned points
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }} >
                    {browniePointsList?.totalAssignedPoints}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item sm={6} md={3}>
              <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>

                <Box sx={{ width: '44px', height: '44px', background: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icons.Balancepointsicon />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    Balance Points
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '22px' }}>
                    {browniePointsList?.balance}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        {/* <Stack direction="row" spacing={2}>
          <Box>
            <Typography variant="h6" component="span">
              Total Resourceses:
            </Typography>
            <Typography variant="h6" component="span">
              {browniePointsList?.totalResource}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" component="span">
              Monthly Allotment:
            </Typography>
            <Typography variant="h6" component="span">
              {browniePointsList?.monthlyAllotment}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" component="span">
              Total Assigned Points:
            </Typography>
            <Typography variant="h6" component="span">
              {browniePointsList?.totalAssignedPoints}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" component="span">
              Balance Points:
            </Typography>
            <Typography variant="h6" component="span">
              {browniePointsList?.balance}
            </Typography>
          </Box>
        </Stack> */}
        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Recommendation Request" value="1" />
                <Tab label="History Points" value="2" />
              </TabList>
              <Button
                variant="contained"
                size="medium"
                onClick={handleClickOpenRecommendPoints}
                sx={{ position: "absolute", right: 0, top: "0" }}
              >
                Assign Points
              </Button>
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Point Type </TableCell> */}
                      <TableCell> Date</TableCell>
                      <TableCell> Employee</TableCell>
                      <TableCell> Reason</TableCell>
                      <TableCell> Comment</TableCell>
                      <TableCell> Points Assigned</TableCell>
                      {/* <TableCell>Points Value(Rs)</TableCell> */}
                      <TableCell>Referred By</TableCell>
                      <TableCell align="center"> Action &nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recommendRewardsList.length > 0 &&
                      recommendRewardsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell>{row.pointType}</TableCell> */}
                            <TableCell>
                              {" "}
                              {`${moment(row.allocatedPointDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell> {row.employeeName}</TableCell>
                            <TableCell> {row.reasonName}</TableCell>
                            {/* <Tooltip title={row?.comment} > */}
                            <TableCell >
                              {row?.comment ? (
                                <Tooltip title={row.comment} arrow>
                                  <span>
                                    {row.comment.length > 30
                                      ? row.comment.slice(0, 18 - 1) + "..."
                                      : row.comment}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span>-</span>
                              )}

                            </TableCell>
                            {/* </Tooltip> */}
                            <TableCell> {row.pointAssigned}</TableCell>
                            {/* <TableCell>{row.pointValueAmount}</TableCell> */}
                            <TableCell>{row.referedUserName}</TableCell>
                            <TableCell align="center">
                              {row.status.toLowerCase() == "pending" && (
                                <>
                                  <Button
                                    id={row.addOnRequestId}
                                    aria-label="when device is locked"
                                    aria-controls={
                                      openItem ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openItem ? "true" : undefined}
                                    onClick={handleClickStatusListItem}
                                    sx={{ justifyContent: "space-between" }}
                                    variant="selectSmall"
                                    disabled={approvebuttonDisable}
                                  >
                                    Pending
                                    <ArrowDropDownIcon />
                                  </Button>
                                  <Menu
                                    anchorEl={anchorStatusEl}
                                    open={openItem}
                                    onClose={handleStatusClose}
                                    MenuListProps={{
                                      "aria-labelledby": "lock-button",
                                      role: "listbox",
                                    }}
                                  >
                                    {Status.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        id={option}
                                        onClick={(event) => {
                                          handleSatusItemClick(event);
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </>
                              )}

                              {row.status.toLowerCase() == "approved" && (
                                <Chip variant="approved" label={row.status} />
                              )}
                              {row.status.toLowerCase() == "reject" && (
                                <Tooltip title={row.rejectReason}>
                                  <Chip variant="hold" label={row.status} />
                                </Tooltip>

                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={recommendRewardsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${recommendRewardsList?.length}  Page ${page + 1} of ${pageCount}`}
              />

            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Point Type </TableCell> */}
                      <TableCell> Date</TableCell>
                      <TableCell> Employee</TableCell>
                      <TableCell> Reason</TableCell>
                      <TableCell> Points Assigned</TableCell>
                      {/* <TableCell>Points Value(Rs)</TableCell> */}
                      <TableCell>Referred By</TableCell>
                      <TableCell align="right">View &nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {browniePointsList?.listOutputModels.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                      .map((row, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell>{row.pointType} </TableCell> */}
                          <TableCell>
                            {" "}
                            {`${moment(row.allocatedPointDate).format(
                              "DD-MMM-YYYY"
                            )}`}
                          </TableCell>
                          <TableCell> {row.employee}</TableCell>
                          <TableCell> {row.reasonName}</TableCell>
                          <TableCell> {row.pointAssigned}</TableCell>
                          {/* <TableCell>{row.pointValueAmount}</TableCell> */}
                          <TableCell>{row.referedUserName}</TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              spacing={2}
                            >
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="View"
                                  color="error"
                                  onClick={() => {
                                    getAllEmployeeDetailRewardPoints(
                                      row.employeeId
                                    );
                                  }}
                                >
                                  <Icons.View />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={browniePointsList?.listOutputModels?.length || 0}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
                labelDisplayedRows={() => `Total: ${browniePointsList?.listOutputModels?.length}  Page ${page + 1} of ${pageCount1}`}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>

      <Dialog
        open={openRecommendPoints}
        onClose={setOpenRecommendPoints}
        maxWidth="sm"
        scroll="body"
        fullWidth
      >
        <Formik
          initialValues={{
            departmentId: _authUser.employeeInfo.departmentId,
            userId: "",
            referedUserName: "manager",
            reason: "",
            comment: "",
          }}
          validationSchema={fieldValidationSchemaAssignPoints}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await AddRequestRewardPoint(values);
              setOpenRecommendPoints(false);
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <IconButton
                  aria-label="delete"
                  sx={styleCross}
                  onClick={handleCloseRecommendPoints}

                >
                  <ClearIcon />
                </IconButton>
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{ fontWeight: "600", fontSize: "26px" }}
                >
                  Recommend Points
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs sm={12}>
                      <TextField
                        id="outlined-basic"
                        label="Department"
                        value={
                          departments.find(
                            (a) =>
                              a.id ==
                              (_authUser.employeeInfo.departmentId != ""
                                ? _authUser.employeeInfo.departmentId
                                : "All Departments")
                          )?.name
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={12}>
                      <FormControl fullWidth>
                        {/* <TextField
                          error={Boolean(touched.userId && errors.userId)}
                          helperText={touched.userId && errors.userId}
                          name="userId"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="select-userId"
                          label="Resource"
                          value={values.userId}
                          variant="outlined"
                          select
                          fullWidth
                        >
                          {resources.filter(resource =>resource.userId !=="").map((resource, index) => (
                            <MenuItem
                              key={resource.userId}
                              value={resource.userId}
                              onClick={(event) => {
                                values.userId = resource.userId;
                              }}
                            >
                              {resource.userName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                        <Autocomplete
                          value={
                            resources?.filter(resource => resource.userId !== "" ?? null).find(
                              (option) => option.userId == values.userId
                            ) ?? null
                          }
                          fullWidth
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: true,
                                  options: {
                                    altBoundary: true,
                                    flipVariations: true,
                                    altAxis: true,
                                    padding: 8,
                                    fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                  },
                                },
                              ],
                            },
                          }}
                          id="selectedUserId"
                          options={resources.filter(resource => resource.userId !== "" ?? null)}
                          getOptionLabel={(option) => option.userName}
                          onChange={(event, value) => {
                            values.userId = value?.userId ?? ""
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.userId && errors.userId)}
                              helperText={touched.userId && errors.userId}
                              name="userId"
                              required
                              onBlur={handleBlur}
                              // onChange={handleChange}
                              id="select-userId"
                              label="Resource"
                              value={values.userId}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs sm={12} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        {/* <TextField
                          error={Boolean(touched.reason && errors.reason)}
                          helperText={touched.reason && errors.reason}
                          name="reason"
                          required
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="select-reason"
                          label="Reason"
                          value={values.reason}
                          variant="outlined"
                          select
                          fullWidth
                        >
                          {browniePointsReasonList.map((reason, index) => (
                            <MenuItem
                              key={reason.id}
                              value={reason.id}
                              onClick={(event) => {
                                values.reason = reason.id;
                              }}
                            >
                              {reason.reasonName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                        <Autocomplete
                          value={
                            browniePointsReasonList?.find(
                              (option) => option.id == values.reason
                            ) ?? null
                          }
                          fullWidth
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: true,
                                  options: {
                                    altBoundary: true,
                                    flipVariations: true,
                                    altAxis: true,
                                    padding: 8,
                                    fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                  },
                                },
                              ],
                            },
                          }}
                          id="selectedUserId"
                          options={browniePointsReasonList}
                          getOptionLabel={(option) => `${option?.reasonName} (${option?.pointsForReason})`}
                          onChange={(event, value) => {
                            values.reason = value?.id ?? "";
                            setSelectedReason(value?.reasonName ?? "");
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                              {`${option?.reasonName} (${option?.pointsForReason})`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.reason && errors.reason)}
                              helperText={touched.reason && errors.reason}
                              name="reason"
                              required
                              onBlur={handleBlur}
                              // onChange={handleChange}
                              id="select-reason"
                              label="Reason"
                              value={values.reason}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs sm={12} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          error={Boolean(touched.comment && errors.comment)}
                          helperText={touched.comment && errors.comment}
                          name="comment"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="comment"
                          label="Comment"
                          value={values.comment.replace(/\s{2,}$/g, "").trimStart().substring(0, 150)}
                          variant="outlined"
                          fullWidth
                        />
                        <span style={{ textAlign: 'right' }}>Max 150 Chars</span>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button
                    onClick={handleCloseRecommendPoints}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    autoFocus
                    variant="contained"
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      {/* //----------------------------------------------------------- */}

      <Dialog
        open={openRequestBrownie}
        onClose={setOpenRequestBrownie}
        maxWidth="sm"
        scroll="body"
        fullWidth
      >
        <Formik
          initialValues={{
            managerId: HodByDepartment?.hodId,
            departmentId: _authUser.employeeInfo.departmentId,
            requestedPoint: "",
            reason: "",
          }}
          validationSchema={fieldValidationSchemaRequestPoints}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await AddRequestRewardsPointHR(values);
              setOpenRequestBrownie(false);
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <IconButton
                  aria-label="delete"
                  sx={styleCross}
                  onClick={handleCloseRequestBrownie}
                >
                  <ClearIcon />
                </IconButton>
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{ fontWeight: "600", fontSize: "26px" }}
                >
                  Request Brownie Points
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs sm={6}>
                      <TextField
                        id="outlined-basic"
                        label="Department"
                        value={
                          departments.find(
                            (a) =>
                              a.id ==
                              (_authUser.employeeInfo.departmentId
                                ? _authUser.employeeInfo.departmentId
                                : "All Departments")
                          )?.name
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={6}>
                      <TextField
                        id="outlined-basic"
                        label="Manager"
                        value={HodByDepartment?.managerName}
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>

                    <Grid item xs sm={12}>
                      <TextField
                        id="requestedPoint"
                        required
                        fullWidth
                        error={Boolean(
                          touched.requestedPoint && errors.requestedPoint
                        )}
                        helperText={
                          touched.requestedPoint && errors.requestedPoint
                        }
                        value={values.requestedPoint}
                        name="requestedPoint"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Add No. of Brownie Points"
                      />
                    </Grid>
                    <Grid item xs sm={12}>
                      <TextField
                        id="reason"
                        required
                        fullWidth
                        error={Boolean(touched.reason && errors.reason)}
                        helperText={touched.reason && errors.reason}
                        value={values.reason}
                        name="reason"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        label="Add Reason for Brownie Points"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button
                    onClick={handleCloseRequestBrownie}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    autoFocus
                    variant="contained"
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openView}
        onClose={setOpenView}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton sx={styleCross} onClick={handleCloseView}>
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Recommend Points
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs sm={2}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 92, height: 92 }}
              />
            </Grid>
            <Grid item xs>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Emp Id : {employeeBrowniePointsDetail?.empId}
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    {employeeBrowniePointsDetail?.userName}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Total Earned Points
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    {employeeBrowniePointsDetail?.totalEarnedPoint} points
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Value
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Rs. {totalPointValue}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Redeemed
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    {employeeBrowniePointsDetail?.pointToRedeem} Points
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />

          <TableContainer sx={[styles.view.tBodyScroll]}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={[styles.view.tHeaderFixed]}>
                <TableRow>
                  {/* <TableCell>Point Type </TableCell> */}
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {employeeBrowniePointsDetail?.listOutputModels != null &&
                  employeeBrowniePointsDetail?.listOutputModels.length > 0 &&
                  employeeBrowniePointsDetail?.listOutputModels.map(
                    (row, index) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>{row.pointType} </TableCell> */}
                        <TableCell> {row.earnedPoint}</TableCell>
                        <TableCell>{row?.valueOfPoint}</TableCell>
                        <TableCell> {`${moment(row?.allocatedPointDate).format(
                          "DD MMM, YYYY")}`}</TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        open={rejectResonOpen}
        onClose={setRejectResonOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <Formik
          initialValues={{
            id: dynamicRowId,
            status: 2,
            rejectReason: rejectReasonFormik,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);
              await RejectRecomendation(values);
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    position: "relative",
                  }}
                >
                  Reject Reason
                  {/* {pageTitle} */}
                  <IconButton
                    aria-label="delete"
                    sx={styleCrossReasonReject}
                    onClick={() => { handleCloseRejectReason(); setRejectReasonFormik(""); }}
                  >
                    <ClearIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  <Stack sx={{ mb: 2, pt: 2 }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      error={Boolean(
                        getIn(touched, "rejectReason") &&
                        getIn(errors, "rejectReason")
                      )}
                      fullWidth
                      helperText={
                        getIn(touched, "rejectReason") &&
                        getIn(errors, "rejectReason")
                      }
                      label="Reason"
                      required
                      name="rejectReason"
                      value={values.rejectReason}
                      onBlur={handleBlur}
                      // onChange={handleChange}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        value = value.replace(/[^a-zA-Z\s]/g, "");;
                        values.rejectReason = value;
                        setRejectReasonFormik(value);
                      }}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 3, pt: 0 }}>
                  <Button variant="outlined" onClick={() => { handleCloseRejectReason(); setRejectReasonFormik(""); }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setErrorMessage(true);
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Reject
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
