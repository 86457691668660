import Typography from '@mui/material/Typography';
import { Autocomplete, Box, BoxProps, Stack, TextField } from '@mui/material'
import { useState } from "react";
import * as Icons from "../../assests/icons/icons";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import theme from "../../theme/theme";
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { issueManagementService } from "../../service/issues-management-service";
import { toast } from 'material-react-toastify';
import { Formik } from 'formik';

const TicketNumber = styled(Box)<BoxProps>(({ theme }) => ({
    marginLeft: "10px",
    marginRight: "10px",
    color: "#1DA7FF",
    display: "block",
}));

// StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
const styles = {

    EpicBtn: {
        background: theme.palette.success.light,
        borderRadius: "4px",
        padding: "4px 8px",
        marginLeft: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }

}

export default function EpicLinkbtn(props: any) {
    const { projectId, sprintId, ticketId, refreshFunction
    } = props;

    const [openEpic, setOpenEpic] = React.useState(false);
    const handleClickOpenEpic = () => {
        setOpenEpic(true);
        getEpicIssues();


    };

    const handleCloseEpic = () => {
        setOpenEpic(false);
    };
    const [issueList, setIssues] = React.useState<any[]>([]);
    const [AddIssue, setAddIssue] = React.useState<any[]>([]);
    const [currentEpicId, setCurrentEpicId] = useState("");


    const styleCross = {
        position: "absolute" as "absolute",
        right: "12px",
        top: "16px",
    };

    const getEpicIssues = async (values?: any) => {
        var req = {
            ProjectId: projectId,
            SprintId: sprintId,
        };
        await issueManagementService.GetAllEpic(req).then((response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                setIssues(response.data);
            }
        });
    };

    const addEpicIssues = async (epicId?: any) => {
        var req = {
            epicId: epicId,
            ticketId: ticketId,

        };
        await issueManagementService.UpdateEpicticketById(req).then((response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            }
            else {

                if (refreshFunction) {
                    refreshFunction();
                }
                handleCloseEpic();
            }
        });
    }
    return (
        <>
            <Formik
                initialValues={{
                    EpicId: null,

                }}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div>
                            <Box sx={styles.EpicBtn}>
                                <Box sx={{ color: "#74C225", lineHeight: "0" }}><Icons.EpicIcon /></Box><Box sx={{ mx: 1, fontWeight: 500, }}>{props.contentText}</Box><AddCircleIcon onClick={handleClickOpenEpic} sx={{ fill: "#1F944E", cursor: "pointer", fontSize: "16px" }} />
                            </Box>

                            <Dialog
                                open={openEpic}
                                onClose={handleCloseEpic}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title" sx={{ pb: 0 }}>
                                    <Typography sx={{ fontWeight: "600", fontSize: "26px", color: "#232360" }}>  Add Epic</Typography>
                                    <IconButton
                                        aria-label="delete"
                                        sx={styleCross}
                                        onClick={handleCloseEpic}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Select a parent issue for this issue. Issues can only belong to one parent issues at a time
                                    </DialogContentText>
                                    <Typography variant="subtitle1" component="h2" sx={{ fontWeight: "600", color: "#232360" }}>
                                        Epic
                                    </Typography>
                                    {/* <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <Select

                                id="outlined-basic"
                                variant='outlined'
                            >
                                {issueList.map((epicissues: any) => (
                                    <MenuItem value={epicissues.id} onClick={() => {
                                        addEpicIssues(epicissues.id);
                                    }}>
                                        <ListItemIcon sx={{ minWidth: "2", color: "#74C225" }}>
                                            <Icons.EpicIcon />
                                        </ListItemIcon>
                                        {epicissues.ticketNumber}&nbsp;<span>
                                        {epicissues.summary}
                                        </span>
                                    </MenuItem>

                                ))}
                                {issueList.length == 0 && <MenuItem >No Epic Found</MenuItem>}
                            </Select>
                        </FormControl>
                    </Box>  */}
                                    <Box sx={{ minWidth: 120 }}>
                                        <Autocomplete
                                            value={issueList?.find(
                                                (option: any) => option.id == currentEpicId
                                            ) ?? null}
                                            fullWidth
                                            id="Epic-issueList"
                                            options={issueList}
                                            getOptionLabel={(option) =>
                                                option.ticketNumber +
                                                " " +
                                                option.summary.slice(0, 20) +
                                                (option.summary.length > 20 ? "..." : "")}
                                            renderOption={(props, option: any) => (
                                                <Box component="li"  {...props}>
                                                    <Box sx={{ color: "#90EE90", margin: "4px" }}><Icons.EpicIcon /></Box>
                                                    {option.ticketNumber + " "}
                                                    {option.summary.slice(0, 20) +
                                                        (option.summary.length > 20 ? "..." : "")}
                                                </Box>
                                            )}
                                            onChange={(event, value) => {
                                                values.EpicId = value?.id ?? null;
                                                setCurrentEpicId(value?.id ?? null);
                                                addEpicIssues(value?.id ?? null);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    id="Epic-issueList"
                                                    error={Boolean(touched.EpicId && errors.EpicId)}
                                                    name="EpicId"
                                                    variant="outlined"
                                                    value={values.EpicId}
                                                    fullWidth />
                                            )} />

                                    </Box>
                                </DialogContent>
                                {/* <DialogActions>
                         <Button onClick={handleCloseEpic}>Disagree</Button>
                        <Button onClick={handleCloseEpic} autoFocus>
                        Agree
                       </Button>
                        </DialogActions> */}
                            </Dialog>
                        </div>
                    </form>
                )}
            </Formik>
        </>


    )
}
