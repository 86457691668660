import React, { useRef, useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { Autocomplete, CircularProgress, Grid, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import { Department } from "../Interfaces/IDepartment";
import { eTypeOfUser } from "../Enum/eTypeOfUser";
import { clsDepartmentList } from "../service/common-services/department";
import HrReporttop from "./reports/HrReportsstyle";
import theme from "../theme/theme";
import NotFound from "../components/styledComponent/NotFound";
import { IResource } from "../Interfaces/IResource";
import { clsResourcesList } from "../service/common-services/resources";
import { kraratingServices } from "../service/kra-rating-service";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


export default function KraReport() {

    const styleCross = {
        position: "absolute" as "absolute",
        right: "5px",
        top: "5px",
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (data: any) => {
        getKraRateDetails(data?.userId, currentYear)
        setOpen(true);
        selectedUserId.current=data?.userId;
    };

    const handleClose = () => {
        setOpen(false);
        selectedUserId.current = "";
        setSelectedYear(currentYear);
    };
    const selectedUserId = useRef("");
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const [ratingForms, setRatingForms] = useState<any>([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [roadMapDetails, setRoadMapDetails] = useState<any>([]);
    const [reports, setreports] = React.useState<any>([]);
    const [selectedYear, setSelectedYear] = useState<any>("");
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(reports?.outputModel?.length / rowsPerPage);
    const [page, setPage] = React.useState(0);
    const [selectedRating, setSelectedRating] = React.useState<any>(0);
    const ratings = [
        { label: 'b/w 9 to 8', value: 5 },
        { label: 'b/w 8 to 7', value: 4 },
        { label: 'b/w 7 to 6', value: 3 },
        { label: 'b/w 6 to 5 ', value: 2 },
        { label: '5 Or Less', value: 1 }
      ];
      let option = {
        series: [
            Number(reports?.counts?.eightToNineCount) || 0,
            Number(reports?.counts?.sevenToEightCount) || 0,
            Number(reports?.counts?.sixToSevenCount) || 0,
            Number(reports?.counts?.fiveToSixCount) || 0,
            Number(reports?.counts?.lessThanFiveCount) || 0,
        ],
        colors: ['#2DD36F', '#FFAD01', '#05F7E5', "#F7E905", "#EB445A"],
       // labels: ['B/W 9 to 8', 'B/W 8 to 7', 'B/W 7 to 6', "B/W 6 to 5", "5 or Less"],
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val:any, opts:any) {
                return opts.w.config.series[opts.seriesIndex];
            },
        },
        tooltip: {
            enabled: false
        }
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getReport = async (
        departmentId: string = "", resourceId: string = "", year: any , rating : any
    ) => {
        try {
            
            setPage(0);
            setToggleLoader(true);
            var req = {
                departmentId: departmentId,
                userId: resourceId,
                year: year,
                kraRating: rating
            };
            await kraratingServices.getKRAReport(req)
                .then((response: any) => {
                    if (response.data.isError) {
                        setreports([]);
                        setToggleLoader(false);
                    }
                    else {
                        setreports(response?.data);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    const getKraRateDetails = async (
        userId: string = "", year: any
    ) => {
        try {
            setPage(0);
            setToggleLoader(true);
            var req = {
                userId: userId,
                year: year
            };
            await kraratingServices.getKRARatingDetailsForReport(req)
                .then((response: any) => {
                    if (response.data.isError) {
                        setRatingForms([]);
                        setToggleLoader(false);
                    }
                    else {
                        setRatingForms(response?.data);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    const currentYear = new Date().getFullYear();
    React.useEffect(() => {
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                debugger
                setDepartmentId(_authUser.employeeInfo.departmentId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setdisabledDepartments(true);
                setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                getReport(_authUser.employeeInfo.departmentId, "", "", selectedRating);
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(''));
                setDepartmentId("");
                setdisabledDepartments(false);
            }
        };
       setSelectedYear(currentYear);
        departmentsData();
        //  setRatingForms(dummyjson);
    }, []);



    const handleChangeYear= (year:any) => {
        setSelectedYear(year);
        getKraRateDetails(selectedUserId?.current, year)
       
    };
    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ px: 3, mb: 2 }}> 
            <Typography variant="h6" sx={{ mt:"20px", py:"20px"}} >
                            KRA Report
                        </Typography>
                <Box sx={{ flexGrow: 1 }} /> 
            
                <Stack alignItems="flex-start"
                    sx={HrReporttop.topbar}
                >
                    
                    <Box sx={{ width: { xs: '100%', md: '100%' }, mt: { xs: 0, sm: 2 } }}>
                        <Stack sx={HrReporttop.rightside} alignItems="flex-start">
                 <Stack direction={"column"}>
                 <Stack direction={"row"}>  
                            <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, mr:"20px" }}>
                                <Autocomplete
                                    disabled={disabledDepartments}
                                    disableClearable={departmentId && departmentId != "" ? false : true}
                                    value={
                                        departments.find((option: any) => option.id == departmentId) ?? null
                                    }
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        debugger
                                        setDepartmentId(value?.id ?? "")
                                        setResourceId("");
                                        setfiltersSelected(true);
                                        if (value?.id) {
                                            getReport(value?.id ?? "", "", selectedYear, selectedRating);
                                            setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                                        }
                                        else {
                                            setreports([])
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Department"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={departmentId}
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                    sx={{
                                        "&.MuiAutocomplete-root": {
                                            margin: 0,
                                        }
                                    }}
                                />
                            </Box>
                            <Box>
                                <Autocomplete
                                    id="combo-box-demo"
                                    sx={{ width: "200px" }}
                                    value={ratings?.find((item:any)=>item?.value == selectedRating) || null}
                                    disabled={!departmentId || departmentId === "" || resourceId!="" }
                                    options={ratings}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                        setSelectedRating(value?.value ?? 0);
                                        getReport(departmentId, resourceId, selectedYear, value?.value ?? 0);
                                      }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Select Rating"
                                            value={selectedRating}
                                        />}
                                />
                            </Box>
                      </Stack>
                            <Box sx={{my:'20px' }}>
                                <Autocomplete
                                    disabled={!departmentId || departmentId === ""}
                                    disableClearable={resourceId && resourceId != "" ? false : true}
                                    value={
                                        resources.find(
                                            (option: any) => option.userId == resourceId 
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={async (event, value) => {
                                        setfiltersSelected(true);
                                        setResourceId(value?.userId ?? "");
                                            setSelectedRating(0); 
                                        getReport(departmentId, value?.userId ?? "", selectedYear, 0);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Resource"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={resourceId}
                                            fullWidth
                                            sx={{ width: 220 }}
                                        />
                                    )}
                                />
                            </Box>
                 </Stack>
                           

                            <Box sx={{ width: { xs: '100%', md: 'auto' }, transform: "translateY(-20px)", display: "flex", alignItems: "center", justifyContent:"end" }}>
                                <ReactApexChart
                                    options={option}
                                    series={option.series}
                                    type="pie"
                                    width={250}
                                />
                                <Box sx={{ flexDirection: "column" }}>
                                    <div style={{ fontWeight: "500", fontSize: "15px",display:"flex",flexDirection:"row",gap:"4px" }}>b/w 9 to 8 : 
                                     <div style={{ color:"#2DD36F"}}>{reports?.counts?.eightToNineCount || 0}</div></div>
                                    <div style={{ fontWeight: "500", fontSize: "15px",display:"flex",flexDirection:"row",gap:"4px"}}>b/w 8 to 7 : <div style={{color:"#FFAD01"}}>{reports?.counts?.sevenToEightCount || 0}</div></div>
                                    <div style={{ fontWeight: "500", fontSize: "15px",display:"flex",flexDirection:"row",gap:"4px"}}>b/w 7 to 6 : <div style={{color:"#05F7E5"}}>{reports?.counts?.sixToSevenCount || 0}</div></div>
                                    <div style={{ fontWeight: "500", fontSize: "15px",display:"flex",flexDirection:"row",gap:"4px"}}>b/w 6 to 5 : <div style={{color:"#F7E905"}}>{reports?.counts?.fiveToSixCount || 0}</div></div>
                                    <div style={{ fontWeight: "500", fontSize: "15px",display:"flex",flexDirection:"row",gap:"4px"}}>5 Or Less :  <div style={{color:"#EB445A"}}>{reports?.counts?.lessThanFiveCount || 0}</div></div></Box>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    {reports && reports?.outputModel?.length > 0 &&
                        <TableContainer component={Paper} elevation={0} sx={{
                            mt: 0.5,
                            maxHeight: "calc(100vh - 215px)",
                            [theme.breakpoints.down("sm")]: {
                                maxWidth: "400px",
                            },
                        }}>
                            <Table stickyHeader aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ position: 'sticky', left: '0', width: '90px', zIndex: "10" }} >S No.</TableCell>
                                        <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', zIndex: "10" }}>Emp ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Department</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Average KRA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports?.outputModel?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => (
                                        <TableRow style={{ textDecoration: 'none' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell >{index + 1}</TableCell>
                                            <TableCell >{row.empId}</TableCell>
                                            <TableCell >{row.userName}</TableCell>
                                            <TableCell >{row.departmentName}</TableCell>
                                            <TableCell >{row.designationName}</TableCell>

                                            <TableCell onClick={() => row.overAllRating&&(handleClickOpen(row))} sx={{ cursor: "pointer" }}><Tooltip title="Click to view Details" arrow>
                                                <span>{row.overAllRating}</span></Tooltip></TableCell>
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    }
                    {reports?.outputModel?.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                        component="div"
                        count={reports?.outputModel?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => `Total: ${reports?.outputModel?.length}  Page ${page + 1} of ${pageCount}`}
                    />

                </Box>
            </Paper>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'} justifyContent={'flex-start'} alignItems={"center"} >
                        <Typography sx={{ fontWeight: 800 }}>
                            {"Kra Details"}
                        </Typography>
                        <IconButton
                            aria-label="delete"
                            sx={{ position: "absolute", right: "5px", top: "5px" }}
                            onClick={handleClose}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Stack>
                    <Box sx={{ display: "flex",alignItems:"center",justifyContent: "center" }}>
                        <IconButton aria-label="delete"  onClick={() => {
                            const yearChange = selectedYear - 1;
                          handleChangeYear(yearChange);
                        }}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: " 18px", lineHeight: '21px',fontWeight: "600",}}>{selectedYear}</Typography>
                        <IconButton aria-label="delete"
                           disabled={selectedYear ==currentYear}
                            onClick={() => {
                                const yearChange = selectedYear + 1;
                                handleChangeYear(yearChange);
                            }}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                        <Button variant="text" size="small"
                        disabled={selectedYear ==currentYear}
                            onClick={() => {
                                handleChangeYear(currentYear);
                            }}
                        >Current Year</Button>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    {ratingForms?.kraDetail?.length>0 ?(
                    <Box >
                        {ratingForms?.kraDetail?.map((ratingForm: any, index: any) => (
                            <Box key={index} sx={{ mt: '16px' }}>
                                <Paper sx={{ p: 3 }}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <Typography >{ratingForm?.kra}</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={'0 6px 0 66px'}>
                                            {ratingForm?.isFixed == false &&( <Box>
                                                    <Box
                                                        onClick={() => {
                                                            if (ratingForm?.roadMap) {
                                                                setDialogOpen(true); setRoadMapDetails(ratingForm?.roadMap)
                                                            }
                                                        }}
                                                        sx={{ fontSize: '17px', fontWeight: '00', color: ratingForm?.roadMap ? '#1DA7FF' : "", cursor: "pointer" }}
                                                        component={Link}
                                                    >
                                                        RoadMap
                                                    </Box>
                                                </Box>)}
                                            </Stack>

                                        </Grid>
                                    </Grid>
                                    <Box sx={{ borderTop: '1px solid #E1E1E1', pt: '8px', mt: '18px' }}>
                                        <TableContainer component={Paper} elevation={0} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                            <Table sx={{ minWidth: 500, "& .Mui-disabled.MuiInputBase-root": { border: '1px grey' }, "& .MuiInputBase-root": { border: '1px solid black' } }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ border: '0px solid' }}></TableCell>
                                                        <TableCell
                                                            align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                        >
                                                            Q1
                                                        </TableCell>
                                                        <TableCell
                                                            align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                        >
                                                            Q2
                                                        </TableCell>
                                                        <TableCell
                                                            align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                        >
                                                            Q3
                                                        </TableCell>
                                                        <TableCell
                                                            align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                        >
                                                            Q4
                                                        </TableCell>
                                                        {/* <TableCell align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}>
                                                            Average
                                                        </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(_authUser.typeOfUser === eTypeOfUser.User || _authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.HR) && (
                                                        <TableRow
                                                            sx={{ '& td, & th': { border: 0 }, padding: '8px 0' }}
                                                        >
                                                            <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                                Self
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                <TextField
                                                                    sx={{ width: '38px', height: '25px' }}
                                                                    variant="outlined"
                                                                    inputProps={{ maxLength: 1 }}
                                                                    value={ratingForm?.self?.q1 ?? 0}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                <TextField
                                                                    sx={{ width: '38px', height: '25px' }}
                                                                    value={ratingForm?.self?.q2 ?? 0}
                                                                    variant="outlined"
                                                                    inputProps={{ maxLength: 1 }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                <TextField
                                                                    sx={{ width: '38px', height: '25px' }}
                                                                    variant="outlined"
                                                                    inputProps={{ maxLength: 1 }}
                                                                    value={ratingForm?.self?.q3 ?? 0}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                <TextField
                                                                    sx={{ width: '38px', height: '25px' }}
                                                                    variant="outlined"
                                                                    inputProps={{ maxLength: 1 }}
                                                                    value={ratingForm?.self?.q4 ?? 0}
                                                                />
                                                            </TableCell>
                                                            {/* <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                <TextField
                                                                    sx={{ width: '38px', height: '25px' }}
                                                                    variant="outlined"
                                                                    inputProps={{ maxLength: 1 }}
                                                                    value={0}
                                                                />
                                                            </TableCell> */}
                                                        </TableRow>
                                                    )}

                                                <TableRow
                                                        sx={{ '& td, & th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                            IO
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.io?.q1 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                inputProps={{ maxLength: 1 }}
                                                                variant="outlined"
                                                                value={ratingForm?.io?.q2 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.io?.q3 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.io?.q4 ?? 0}
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={0}
                                                            />
                                                        </TableCell> */}
                                                    </TableRow>

                                                    <TableRow
                                                        sx={{ '& td, & th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                            Manager
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.manager?.q1 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                inputProps={{ maxLength: 1 }}
                                                                variant="outlined"
                                                                value={ratingForm?.manager?.q2 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.manager?.q3 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.manager?.q4 ?? 0}
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={0}
                                                            />
                                                        </TableCell> */}
                                                    </TableRow>

                                                    <TableRow
                                                        sx={{ '& td, & th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                            HR
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: 0 }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px', 'margin-top': '1px', 'margin-bottom': '2px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.hr?.q1 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.hr?.q2 ?? ""}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.hr?.q3 ?? 0}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={ratingForm?.hr?.q4 ?? 0}
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell align="center" sx={{ p: '8px 0' }}>
                                                            <TextField
                                                                sx={{ width: '38px', height: '25px' }}
                                                                variant="outlined"
                                                                inputProps={{ maxLength: 1 }}
                                                                value={0}
                                                            />
                                                        </TableCell> */}
                                                    </TableRow>
                                                    {/* <TableRow
                                                        sx={{ '& td, & th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={4} align="center">

                                                        </TableCell>
                                                        <TableCell align="center">
                                                           Total Average-
                                                        </TableCell>
                                                        <TableCell align="center">
                                                           {"0"}
                                                        </TableCell>
                                                    </TableRow> */}
                                                </TableBody>
                                            </Table>
                                            <Box sx={{ justifyContent: "flex-end", display: "flex", mr: '40px', mt: "6px" }}>Total Average: {ratingForm?.rating}</Box>
                                        </TableContainer>
                                    </Box>
                                </Paper>
                            </Box>
                        ))}
                    </Box>):( 
                        <NotFound NotfoundText="Oops! No KRA Found" />
                   )}
                </DialogContent>
                <DialogActions>
                    {ratingForms?.kraDetail?.length>0 &&(
                    <Stack direction={'row'} justifyContent={'end'} spacing={3} sx={{ my: '20px', mr: '40px' }}>
                        <Typography >
                            OverAll Rating:
                        </Typography>
                        <Typography>
                            {ratingForms?.overAllRating ??0}
                        </Typography>
                    </Stack>)}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={dialogOpen}
                onClose={setDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <IconButton
                    aria-label="delete"
                    sx={styleCross}
                    onClick={() => { setDialogOpen(false); setRoadMapDetails([]); }}
                >
                    <ClearIcon />
                </IconButton>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "26px",
                        lineHeight: "39px",
                        color: "#212121",
                    }}
                >
                    ROAD MAP DETAILS
                </DialogTitle>
                <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
                    {/* <table>
                        <thead>
                            <tr>
                                <th style={{ paddingInline: "16px", textAlign: 'start' }}>Date</th>
                                <th style={{ paddingInline: "16px" }}>Checkpoints</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roadMapDetails.map((item: any) => (
                                <tr>
                                    <td style={{ paddingInline: "16px" }}>{`${moment(item?.date).format("DD MMM, YYYY")}`}</td>
                                    <td style={{ paddingInline: "16px" }}>{item?.checkPoint}</td>
                                </tr>))}
                        </tbody>
                    </table> */}
                    <TableContainer component={Paper} elevation={0} sx={{
                        mt: 0.5,
                        maxHeight: "calc(100vh - 215px)",
                        [theme.breakpoints.down("sm")]: {
                            maxWidth: "400px",
                        },
                    }}>
                        <Table stickyHeader aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ position: 'sticky', left: '0', width: 'auto', zIndex: "10" }} >Date</TableCell>
                                    <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', zIndex: "10" }}>Checkpoints</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roadMapDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index: any) => (
                                    <TableRow style={{ textDecoration: 'none' }}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell >{`${moment(item?.date).format("DD MMM, YYYY")}`}</TableCell>
                                        <TableCell >{item?.checkPoint}</TableCell>

                                    </TableRow>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions
                    sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "flex-end" }}
                >

                    <Button variant="contained" sx={{ justifyContent: "flex-end" }} onClick={() => { setDialogOpen(false); setRoadMapDetails([]); }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}