import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams, } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { useSelector } from "react-redux";
import { Department } from "../Interfaces/IDepartment";
import { IResource } from "../Interfaces/IResource";
import { reportService } from "../service/Reports/reports";
import { toast } from "material-react-toastify";
import { eTypeOfUser } from "../Enum/eTypeOfUser";
import { clsDepartmentList } from "../service/common-services/department";
import { Autocomplete, Chip, CircularProgress, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import HrReporttop from "./reports/HrReportsstyle";
import NotFound from "../components/styledComponent/NotFound";
import theme from "../theme/theme";
import { clsResourcesList } from "../service/common-services/resources";
import * as Icons from "../assests/icons/icons";



export default function WildLifeParticipantsList() {
    const navigation = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.SeasiagalaPermissionApp.SeasiagalaPermission);
    const [reports, setreports] = React.useState<any[]>([])
    const [data, setData] = React.useState([]);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    let DepartmentId = "";
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [departmentHook, setDepartmentHook] = React.useState("");
    let ResourceId = "";
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = React.useState("");
    const [resourceHook, setresourceHook] = React.useState("");
    const [imageUrl, setImageUrl] = React.useState<string>("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(reports.length / rowsPerPage);
    const [page, setPage] = React.useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
console.log(reports, "reports");
    const getReport = async (
        departmentId: string = "", resourceId: string = ""
    ) => {
        try {
            setToggleLoader(true);
            var req = {
                departmentId: departmentId,
                userId: resourceId,
            };
            await reportService.getWildLifePhotographyReport(req)
                .then((response: any) => {
                    if (response.data.isError) {
                        setreports([]);
                        setToggleLoader(false);
                    }
                    else {
                        setreports(response.data);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    const uploadVideoimageLink = async (flag: any) => {
        debugger
        let input = {
            imageLink: imageUrl,
        }
        try {
            reportService.addWildLifePhotography(input).then((response: any) => {
                if (response.data.isError) {

                } else {
                    if (flag === 2) {
                        toast.success("Link Added Successfully")
                    }
                    setImageUrl("");

                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {

                toast.warning(err);
            });
        }
    }
    React.useEffect(() => {
        if (_permission.page_WildLife_Access !== true) {
            navigation("/dashboard");
          }
        getReport("", "");
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                setDepartmentHook(_authUser.employeeInfo.departmentId);
                setDepartmentId(_authUser.employeeInfo.departmentId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                getReport(_authUser.employeeInfo.departmentId, "");
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(''));
                setDepartmentId("");


            }
        };
        departmentsData();
    }, []);
    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    sx={HrReporttop.topbar}
                >
                    <Box >
                        <Typography variant="h6" sx={{ fontWeight: 600, paddingLeft: '16px' }} component="h2">
                            Participants List
                        </Typography>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 0, sm: 2 } }}>
                        <Stack sx={HrReporttop.rightside} alignItems="flex-end">
                            <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, }}>
                                <Autocomplete
                                    disabled={disabledDepartments}
                                    disableClearable={departmentId && departmentId != "" ? false : true}
                                    value={
                                        departments.find(
                                            (option: any) => option.id == departmentId
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setDepartmentHook(value?.id ?? "");
                                        setDepartmentId(value?.id ?? "")
                                        setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                                        setresourceHook("");
                                        setResourceId("");
                                        setfiltersSelected(true);
                                        DepartmentId = value?.id ?? "";
                                        getReport(value?.id, resourceId)

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Department"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={departmentId}
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                    sx={{
                                        "&.MuiAutocomplete-root": {
                                            margin: 0,
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}>
                                <Autocomplete
                                    disabled={!departmentHook || departmentHook === ""}
                                    disableClearable={resourceId && resourceId != "" ? false : true}
                                    value={
                                        resources.find(
                                            (option: any) => option.userId == resourceId ?? ""
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={async (event, value) => {
                                        setfiltersSelected(true);
                                        setresourceHook(value?.userId ?? "");
                                        setResourceId(value?.userId ?? "");
                                        ResourceId = value?.userId ?? "";
                                        getReport(departmentId, value?.userId,)

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Resource"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={resourceId}
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                />
                            </Box>
                            {/* <Button
                                onClick={() => {
                                    getReport(departmentHook, resourceHook);
                                }}
                                color="primary"
                                variant="contained"
                                sx={{ ml: 0.5, mb: { xs: "20px", md: "0px" }, }}
                            >
                                Apply
                            </Button> */}
                            {filtersSelected && <Button
                                onClick={async () => {
                                    setfiltersSelected(false);
                                    setResourceId("");
                                    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                        await setDepartmentId(_authUser.employeeInfo.departmentId);
                                    }
                                    else {
                                        setDepartmentId("");
                                    }
                                    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                        await setDepartmentHook(_authUser.employeeInfo.departmentId);
                                        await setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                                        await getReport(_authUser.employeeInfo.departmentId, "");
                                    }
                                    else {
                                        await setDepartmentHook("");
                                        await setresources(await clsResourcesList.getResourcesDropdownList(""));
                                        setreports([]);
                                    }
                                    await setresourceHook("");
                                    getReport("", "");
                                }}
                                color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                        </Stack>
                    </Box>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    {reports && reports.length > 0 &&
                        <TableContainer component={Paper} elevation={0} sx={{
                            mt: 0.5,
                            maxHeight: "calc(100vh - 215px)",
                            [theme.breakpoints.down("sm")]: {
                                maxWidth: "400px",
                            },
                        }}>
                            <Table stickyHeader aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Sr no.</TableCell>
                                        <TableCell> Name </TableCell>
                                        <TableCell>Department </TableCell>
                                        <TableCell>Image/Video</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <TableRow style={{ textDecoration: 'none' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell>{row.firstName || "--"}</TableCell>
                                            <TableCell>{departments.find(department => department.id === row.departmentId)?.name || "--"}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => window.open(row?.imageLink)}
                                                >
                                                    <Icons.ImageOrVideo />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {reports.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                        component="div"
                        count={reports.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => `Total: ${reports?.length}  Page ${page + 1} of ${pageCount}`}
                    />
                </Box>
            </Paper>
        </>
    );
}