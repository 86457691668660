import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import theme from "../../../theme/darkTheme";
import Table from "@mui/material/Table";
import Notes from "../../../components/styledComponent/Note";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import * as Icons from "../../../assests/icons/icons";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, getIn } from "formik";
import { masterreasonServices } from "../../../service/BrowniePoints/masterReason-services";
import { toast } from "material-react-toastify";
import Box from "@mui/material/Box";
import { TablePagination, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import InfoPopover from "../../../components/CommonInfoComponent";
const styleCrossleave = {
  position: "absolute" as "absolute",
  right: "24px",
  top: "16px",
};
const styleCross = {
  position: "absolute" as "absolute",
  right: "5px",
  top: "5px",
};

const styles = {
  MonthList: {
    "&.MuiListItemButton-root": {
      borderBottom: "1px solid #e1e1e1",

      "&:hover": {
        background: theme.palette.primary.light,
        "& .MuiTypography-root": {
          color: theme.palette.primary.main,
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "&.Mui-selected": {
      background: theme.palette.primary.light,
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "42px",
      opacity: 0.6,
    },
  },
};
const initialvalues = {
  id: "",
  reasonName: "",
  description: "",
  pointsForReason: "",
};

interface Notice {
  id: string;
  reasonName: string;
  description: string;
  pointsForReason: string;
}

let obj: Notice = {
  id: "",
  reasonName: "",
  description: "",
  pointsForReason: "",
};

export default function Reasons() {
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [reason, setReason] = useState<any>([]);
  const [age, setAge] = React.useState("");
  const [formValues, setFormValues] = React.useState<Notice>(obj);
  const [id, setId] = useState("");
  const [reasonName, setreasonName] = React.useState(formValues?.reasonName);
  const [description, setDescription] = React.useState(formValues?.description);
  const [pointsForReason, setpointsForReason] = React.useState(
    formValues?.pointsForReason
  );
  const [isSubmit, setSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const pageCount = Math.ceil(reason.length / rowsPerPage);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openView, setOpenView] = React.useState(false);
  const [reasonView, setReasonView] = React.useState<Notice | null>(null);
  const handleClickOpenView = async (id: any) => {
    await masterreasonServices.getReasonById(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setReasonView(response?.data);
        setOpenView(true);
      }
    });
  };

  console.log("reasonView ==", )
  const handleCloseView = () => {
    setOpenView(false);
  };

  let state = { id: id ?? "" };
  let pageTitle = "Add Reason";
  if (state.id.length > 0) {
    pageTitle = "Update Reason";
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // --------------------------------
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  //  -----------------------------------------

  const [openLeave, setLeaveOpen] = React.useState(false);

  const handleClickOpen = () => {
    setLeaveOpen(true);
    setSubmit(false);
    setErrorMessage(false);
    if (id == "" && id.length == 0) {
      setDescription("");
      setpointsForReason("");
      setreasonName("");
    }
  };

  const handleCloseLeave = () => {
    setLeaveOpen(false);
    setTimeout(() => {
      setId("");
    }, 500);
  };

  const [openAlert, setAlertOpen] = React.useState(false);

  const handleClickOpenAlert = () => {
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  // -------------------------------------------
  useEffect(() => {
    if (_permission.page_Reasons_Access !== true) {
      navigate("/dashboard");
    }
    getReasonDetails();
  }, []);

  const getReasonDetails = async () => {
    await masterreasonServices.getReason().then((response: any) => {
      if (response.data.isError) {
      } else {
        setReason(response?.data);
      }
    });
  };

  const addReason = async (values: any) => {
    await masterreasonServices
      .createReason(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setLeaveOpen(false);
          toast.success("Reason added successfully.", {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });
          getReasonDetails();
        }
      })
      .catch((error) => {
        if (error.data.Error.length > 0)
          toast.error(error.data.Error[0], {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });

        return error;
      });
  };
  // const handleChangeLeaveType = (value: string) => {
  //     setNumberOfLeaves(value);
  // };

  const deleteReasonData = async (id: any, e: any) => {
    e.stopPropagation();
    setAlertOpen(true);
    setCurrentId(id);
  };

  const DeleteReason = async () => {
    setAlertOpen(false);
    try {
      await masterreasonServices
        .deleteReason(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Reason Deleted successfully.");

            getReasonDetails();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getReasonDatabyId = async (id: any, e: any) => {
    e.stopPropagation();
    await masterreasonServices.getReasonById(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setId(response?.data.id);
        setreasonName(response?.data.reasonName);
        setDescription(response?.data.description);
        setpointsForReason(response?.data.pointsForReason);
        setLeaveOpen(true);
      }
    });
  };

  const editReasonDetails = async (values: any) => {
    try {
      await masterreasonServices.updateReason(values).then((response: any) => {
        if (response.data.isError) {
        } else toast.success("Reason Updated successfully");
        setId("");
        getReasonDetails();
        setLeaveOpen(false);
      });
      getReasonDetails();
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const fieldValidationSchema = Yup.object().shape({
    reasonName: Yup.string()
      .required("Reason is required")
      .max(40, "Reason can only contain 40 characters")
      .matches(/^([A-Za-z\s]*)$/g, "Reason can only contains Alphabets "),

    pointsForReason: Yup.string()
      .required("Brownie Point is Required")
      .max(4, "Brownie Point can only in Hundreds or Thousands")
      .matches(/^[0-9]+$/, "Brownie Point can only contains Numbers "),
    description: Yup.string()
      .required("Description is required")
      .max(150, "Description can only contain 150 Words"),
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>

        <Typography color="text.primary">Brownie Points</Typography>
        <Typography color="text.primary">Reasons</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
      
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Brownie Points Reason
          </Typography>
          <InfoPopover  onInput={"Reasons"}/>
          
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={2}>
            {_permission.action_Reasons_Add_Access && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Add Reason
              </Button>
            )}
          </Stack>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper} elevation={0}>
              <Table
                sx={{ minWidth: "100%" }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Sr No.</TableCell> */}
                    <TableCell>Reason</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reason
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user: any) => (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell align="left" >{idx + 1}</TableCell> */}
                          <TableCell>{user.reasonName}</TableCell>
                          <TableCell>{user.pointsForReason}</TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent="flex-end"
                            >
                              {_permission.action_Reasons_Edit_Access && (
                                <Tooltip title="Edit">
                                  <IconButton
                                    aria-label="edit"
                                    size="small"
                                    color="primary"
                                    onClick={(e) => {
                                      getReasonDatabyId(user.id, e);
                                    }}
                                  >
                                    <Icons.Edit />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="view"
                                  onClick={() => handleClickOpenView(user.id)}
                                >
                                  <Icons.View />
                                </IconButton>
                              </Tooltip>
                              {_permission.action_Reasons_Delete_Access && (
                                <Tooltip title="Delete">
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    size="small"
                                    onClick={(e) =>
                                      deleteReasonData(user.id, e)
                                    }
                                  >
                                    <Icons.Delete />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25, 30]}
              component="div"
              count={reason.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            
              labelDisplayedRows={() => `Total: ${reason?.length}  Page ${page + 1} of ${pageCount}`}
            />
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={openLeave}
        onClose={Button}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <Formik
          initialValues={{
            id: id,
            description: description,
            pointsForReason: pointsForReason,
            reasonName: reasonName,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);

              if (id && id.length > 0) {
                await editReasonDetails(values);
              } else {
                await addReason(values);
              }
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    position: "relative",
                  }}
                >
                  {/* {id ? 'Update Label': 'Add Label'} */}
                  {pageTitle}
                  <IconButton
                    aria-label="delete"
                    sx={styleCrossleave}
                    onClick={handleCloseLeave}
                  >
                    <ClearIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  <Stack sx={{ mb: 2, pt: 2 }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      error={Boolean(
                        getIn(touched, "reasonName") &&
                          getIn(errors, "reasonName")
                      )}
                      fullWidth
                      helperText={
                        getIn(touched, "reasonName") &&
                        getIn(errors, "reasonName")
                      }
                      label="Reason"
                      required
                      name="reasonName"
                      value={values.reasonName}
                      onBlur={handleBlur}
                      // onChange={handleChange}
                      onChange={(e) => {
                        let value = e.currentTarget.value
                          .replace(/\s{2,}$/g, "")
                          .trimStart();
                        values.reasonName = value;
                        setreasonName(value);
                      }}
                      inputProps={{ maxLength: 150 }}
                    />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "right", color: "#757575" }}
                    ></Typography>

                    <Box sx={{ mt: 3, mb: 3 }}>
                      <b>
                        {" "}
                        <label> Description * </label>{" "}
                      </b>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        multiline
                        variant="outlined"
                        required
                        value={description}
                        error={Boolean(
                          getIn(touched, "description") &&
                            getIn(errors, "description")
                        )}
                        helperText={
                          getIn(touched, "description") &&
                          getIn(errors, "description")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let value = e.currentTarget.value
                            .replace(/\s{2,}$/g, "")
                            .trimStart();
                          values.description = value;
                          setDescription(value);
                          setErrorMessage(false);
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            minHeight: "100px",
                            backgroundColor: "#fff",
                            lineHeight: "1.5",
                          },
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "right" }}
                      >
                        Max 150 words
                      </Typography>
                    </Box>
                    <Grid item xs width={"25ch"}>
                      <TextField
                        id="outlined-basic"
                        label="Brownie Points"
                        required
                        variant="outlined"
                        error={Boolean(
                          getIn(touched, "pointsForReason") &&
                            getIn(errors, "pointsForReason")
                        )}
                        fullWidth
                        helperText={
                          getIn(touched, "pointsForReason") &&
                          getIn(errors, "pointsForReason")
                        }
                        onBlur={handleBlur}
                        name="pointsForReason"
                        value={pointsForReason}
                        onChange={(e) => {
                          let value = e.currentTarget.value
                            .replace(/[^0-9]\s{0,}$/g, "")
                            .trimStart();
                          values.pointsForReason = value;
                          setpointsForReason(value);
                          setErrorMessage(false);
                        }}
                      />
                    </Grid>
                  </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 3, pt: 0 }}>
                  <Button variant="outlined" onClick={handleCloseLeave}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setErrorMessage(true);
                      setSubmit(true);
                    }}
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openAlert}
        onClose={setAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseAlert}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "26px",
            lineHeight: "39px",
            color: "#212121",
          }}
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Are you sure you want to delete this ?
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseAlert}>
            No
          </Button>
          <Button variant="contained" onClick={DeleteReason}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openView}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          View Reason
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleCloseView}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" sx={{ mb: 2 }}>
            <Typography variant="h6">Reason: &nbsp;</Typography>
            <Typography variant="h6">{reasonView?.reasonName}</Typography>
          </Stack>
          <Stack direction="row" sx={{ mb: 2 }}>
            <Typography variant="h6">Points: &nbsp;</Typography>
            <Typography variant="h6">{reasonView?.pointsForReason}</Typography>
          </Stack>
          <Typography variant="subtitle1">Description</Typography>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="body1" component="h4" sx={{wordWrap: "break-word"}}>
              {reasonView?.description}
            </Typography>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleCloseView}
          >
            Back to List
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
