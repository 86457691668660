import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Autocomplete, TableBody, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { votepollingService } from '../../service/Votepolling-service';
import { toast } from 'material-react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function VoteResult() {
    const _permission = useSelector(
        (state: any) => state.SeasiagalaPermissionApp.SeasiagalaPermission
    ); 
     const navigate = useNavigate();
    const [result, setResult] = useState([]);
    const [categories, setCategories] = React.useState<any>([]);
    const [selectedCatId, setSelectedCatId] = React.useState<any>("");
    useEffect(() => {
        if (_permission.page_Poll_Result !== true) {
            navigate("/dashboard");
          }      
            getAllEventCategory();
    }, [])
    const getAllEventCategory = async () => {
        await votepollingService.getAllEventCategory().then((response: any) => {
            if (response.data.isError) {
            }
            else {
                setCategories(response?.data);
            }
        });
    };
    const getCategoryResultById = async (catId: any) => {
        try {
            await votepollingService.userResult(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setResult(response?.data)
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    };

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Typography fontWeight={"400px"}>Result</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box height={'auto'}>
                            <Paper sx={{
                                height: '100%', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Stack p={2} justifyContent={'space-between'} alignItems={'start'} sx={{ borderBottom: "1px solid #EEEEEE", background: '#F0F9FF', borderRadius: '4px' }}>
                                    <Autocomplete
                                        disablePortal
                                        value={categories?.find(
                                            (option: any) => option.id == selectedCatId
                                        ) ?? null}
                                        id="combo-box-demo"
                                        options={categories}
                                        getOptionLabel={(option: any) => option.categoryName}
                                        onChange={async (event, value) => {
                                            if (!value?.id) {
                                                setSelectedCatId("")
                                            }
                                            else {
                                                debugger
                                                getCategoryResultById(value?.id);
                                                setSelectedCatId(value?.id ?? "")
                                            }
                                        }}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Select Category" />}
                                    />
                                </Stack>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'100%'} >
                            <Paper sx={{
                                height: '100%', padding: '16px', paddingBottom: '0', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Box>
                                    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 540 }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                <TableCell sx={headcellcolor.cellcolor}>Rank</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Name</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Department</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>No of Votes</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {result.map((item: any, index: any) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        key={index}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {result.findIndex((el:any) => el.voteCount === item.voteCount) + 1}
                                                        </TableCell>
                                                        <TableCell>{item.userName}</TableCell>
                                                        <TableCell>{item.departmentName || "--"}</TableCell>
                                                        <TableCell>{item?.voteCount || "--"}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >
                </Grid >
            </Box >
        </>
    )
}