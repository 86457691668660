import React from "react";

const Notificationstyle = {
    topbar: {
        flexDirection: "row",
        flexWrap: 'wrap',
        justifyContent: "space-between",
        alignItems: "center",
        pb: 3, mt: 2
    },
    topright: {
        width:{ xs: '100%', sm: 'auto' },
        alignItems:{ xs: 'strech', sm: "center" },
        justifyContent:{ xs: 'strech', sm: "center"},
    },
    searchbarstyle:{
        "& .searcg-field": {
          width: { xs: "100%", md: "auto" },
        },
        width: { xs: "100%", md: "auto" },
      }


}
export default Notificationstyle;
