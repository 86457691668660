import { http } from "./http-common";

class EventManagementService {
    createEvent = async (req: any): Promise<any> => {
        return await http.post<any>("/v1/NoticeBoard/AddEvent", req);
    };
    getEvents = async (user: any): Promise<any> => {
        return await http.post<any>("/v1/NoticeBoard/GetAllEvent", user);
    };
    deleteEvent = async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/NoticeBoard/DeleteEventById?id=${id}`);
    };
    updateEvent = async (events: any): Promise<any> => {
        return await http.put<any>("/v1/NoticeBoard/EditEvent", events);
    };
    getEventById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/NoticeBoard/SearchEventbyId?id=${id}`);
    };
    getSearch = async (user: any): Promise<any> => {
        return await http.post<any>("/v1/NoticeBoard/GetAllEvent", user);
    };
    getEventViewId = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/NoticeBoard/SearchEventbyId?id=${id}`);
    };

}

export const eventManagementService = new EventManagementService;
