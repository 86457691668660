import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {CircularProgress } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from "material-react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { votepollingService } from "../../service/Votepolling-service";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { useState } from "react";
const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function NoVoteList() {
    const _permission = useSelector(
        (state: any) => state.SeasiagalaPermissionApp.SeasiagalaPermission
    );
    const [ItemList, setItemList] = React.useState<any[]>([]);
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentsId, setdepartmentsId] = useState("");
    const navigate = useNavigate();
    React.useEffect(() => {
        // if (_permission.page_Nominee_Setup_Access !== true) {
        //     navigate("/dashboard");
        //   }
   
        const departmentsData = async () => {
            setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        };
        departmentsData();
    }, []);

    //Get All Inventory Items --Api call

    const getNonVoterlistById = async (catId: any) => {
        try {
            await votepollingService.getNonVoterlistById(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setItemList(response?.data)
                       
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    };

 
    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Link color="inherit" to="/">
                       Voting List
                    </Link>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box height={'auto'}>
                            <Paper sx={{
                                height: '100%', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Stack p={2} justifyContent={'space-between'} alignItems={'start'} sx={{ borderBottom: "1px solid #EEEEEE", background: '#F0F9FF', borderRadius: '4px' }}>
                                <Autocomplete
                                    value={departments?.find(
                                        (option) => option.id == departmentsId
                                    ) ?? null}
                                    fullWidth
                                    sx={{ ml: "15px" }}
                                    id="department-drop"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        if(value){
                                        setdepartmentsId(value?.id ?? "");
                                        getNonVoterlistById(value?.id ??"")}
                                        else{
                                            setdepartmentsId("");
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="department-drop"
                                            name="departmentsId"
                                            //label="Departments"
                                            variant="outlined"
                                            value={departmentsId}
                                            fullWidth
                                            sx={{ minWidth: 215 }} />
                                    )} />
                                </Stack>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'100%'} >
                            <Paper sx={{
                                height: '100%', padding: '16px', paddingBottom: '0', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Box>
                                    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 540 }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={headcellcolor.cellcolor}>S.No</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Name</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>VoteCount</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', background: 'rgb(189, 189, 189)' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ItemList?.map((item: any, index: any) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row"> {index + 1} </TableCell>
                                                        <TableCell component="th" scope="row"> {item.userName || "--"} </TableCell>
                                                        <TableCell>{item.voteCount || "--"}</TableCell>
                                                       
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >
                </Grid >
            </Box >
        </>
    )
}
