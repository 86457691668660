import React from "react";

const Roletop = {
    topbar: {
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "flex-start", md: "space-between" },
        alignItems: { xs: "strech", md: "center" },
        pb: 3,
        mt: 2,
    },
    searchbar: {
        "& .searcg-field": {
            padding: "10px 0 10px 38px",
            border: "1px solid #EEEEEE;",
            bordeRadius: "4px",
            width: { xs: "100%", md: "auto" },
        },
        margin: { xs: '20px 0px', md: ' 0px 20px' },
    },
    actionpopup: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
    }
}
export default Roletop;