import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Card from '@mui/material/Card';
import { Formik, getIn, useFormikContext } from "formik";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as Icons from "../../assests/icons/icons";
import {
    userModelDetail,
    userModel,
    insuranceCard,
    employeeInfo,
    personalInfo,
    documents,
    pastExperince,
    badges,
    achivements,
    skillsets,
    othersDocument,
    kids,
} from "./userModel";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { toast } from "material-react-toastify";
import { userManagementServive } from "../../service/user-management-service";
import moment from "moment";
import theme from "../../theme/theme";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function InsuranceCard(props: {
    obj: any;
    setValue: any;
    callBackFunction: any;
}) {
    useEffect(() => {
        const getUserDetail = async () => {
            await getUserDetails();
        };
        getUserDetail();
    }, []);

    let emptyKidsObj: kids = {
        name: "",
        dob: null,
    };
    let addMoreKidsFields = () => {
        setkidsArray([...kidsArray, emptyKidsObj]);
        setDisabledButton(false);
    };

    let removeKidsFields = (i: any) => {
        let KidsArray = [...kidsArray];
        KidsArray.splice(i, 1);
        setkidsArray(KidsArray);
        setDisabledButton(true);
    };

    const dummyRelation = [
        {
            id: "Mother",
            relation: "Mother",
        },
        {
            id: "Father",
            relation: "Father",
        },
        {
            id: "Daughter",
            relation: "Daughter",
        },
        {
            id: "Son",
            relation: "Son",
        },
        {
            id: "Sister",
            relation: "Sister",
        },
        {
            id: "Brother",
            relation: "Brother",
        },
        {
            id: "Aunt",
            relation: "Aunt",
        },
        {
            id: "Uncle",
            relation: "Uncle",
        },
        {
            id: "Wife",
            relation: "Wife",
        },
        {
            id: "Husband",
            relation: "Husband",
        }
    ];
    const [userIdHook, setUserIdHook] = React.useState(props.obj);
    let _employeeInfo = new employeeInfo();
    let _personalInfo = new personalInfo();
    let _documents = new documents();
    let _pastExperince = new pastExperince();
    let _badges = new badges();
    let _achivements = new achivements();
    let _othersDocument = new othersDocument();
    let _skillsets = new skillsets();
    let _insuranceCard = new insuranceCard();
    let usermodel = new userModel(
        props.obj,
        _employeeInfo,
        _personalInfo,
        _insuranceCard,
        [_documents],
        [_pastExperince],
        [_badges],
        [_achivements],
        [_othersDocument],
        [_skillsets]
    );

    const [errorMessage, setErrorMessage] = useState(false);
    const [isSubmit, setSubmit] = useState(false);
    const [DisabledButton, setDisabledButton] = useState(false);
    const [age, setAge] = React.useState('');
    const [value, setValue] = useState<any>("");
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [userDataHook, setUserDataHook] = React.useState<any>(usermodel);


    const [childDisable, setChildDisable] = useState(true);
    const [spouseName, setspouseName] = useState(
        userDataHook.insuranceCard?.spouseName ?? ""
    );
    const [spouseDOB, setSpouseDOB] = useState(
        userDataHook.insuranceCard?.dob ?? null
    );
    const [nominee, setnomineeName] = useState(
        userDataHook.insuranceCard?.nominee ?? " "
    );
    const [relationship, setrelationship] = useState(
        userDataHook.insuranceCard?.relationship ?? " "
    );
    const [kidsArray, setkidsArray] = useState(
        userDataHook.insuranceCard?.childs && userDataHook.insuranceCard?.childs.length > 0
            ? userDataHook.insuranceCard?.childs
            : []
    );

    const [name, setname] = useState(userDataHook.insuranceCard?.name ?? "");
    const [dob, setdob] = useState(userDataHook.insuranceCard?.dob ?? null);
    const [visible, setVisible] = useState(false);

    const updateUser = async (values: any) => {
        setDisabledButton(true);
        let data = {
            ...userDataHook,
            ...values
        };
        
        await userManagementServive.updateUser(data).then((response: any) => {
            if (response.data.isError) {
                toast.error("Something went wrong...");
            } else {
                getUserDetails();
                props.callBackFunction();
                toast.success("InsuranceCard added successfully.",);
                setDisabledButton(true);
            }
        });
    }
    const getTimeZone = (dateObject: Date) => {
        let date = new Date(dateObject).toISOString();
        return date = date.slice(0, 10) + 'T00:00:00Z';
    }


    const getUserDetails = async () => {
        try {
            if (props.obj != "") {
                await userManagementServive
                    .getUserById(props.obj)
                    .then((response: any) => {
                        if (response.data.isError) {
                        } else {
                            setUserDataHook(response.data);
                            // if (response.data.insuranceCard == null) {
                            //     let obj = response.data;
                            //     obj.insuranceCard = insuranceCard;
                            //     setUserDataHook(obj);
                            //     return;
                            // }
                            // setDateOfBirth(response.data.insuranceCard?.dateOfBirth ?? null);
                            setnomineeName(response.data.insuranceCard?.nominee ?? "");
                            setrelationship(response.data.insuranceCard?.relationship);
                            setspouseName(response.data.insuranceCard?.spouseName);
                            setSpouseDOB(response.data.insuranceCard?.dob ?? null);
                            setname(response.data.insuranceCard?.name);
                            setdob(moment(response.data.insuranceCard?.dob ?? null));
                            setkidsArray(
                                response.data.insuranceCard?.childs &&
                                    response.data.insuranceCard?.childs.length > 0
                                    ? response.data.insuranceCard?.childs
                                    : []
                            );
                        }
                    });
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
            props.setValue(0);
        }
    };

    return (
        <Formik
            initialValues={{
                insuranceCard: {
                    relationship: relationship,
                    nominee: nominee,
                    spouseName: spouseName,
                    dob: spouseDOB,
                    name: name,
                    // dob: dob,
                    childs: kidsArray,
                },
            }}
            //  validationSchema={fieldValidationSchema}
            enableReinitialize
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting,

            }) => {
                try {
                    //setErrorMessage("");
                    await updateUser(values)
                } catch (ex: any) {
                    if (ex.status == 400) {
                        ex.data.Error.map((error: any, idx: any) => {
                            toast.error(error);
                        })
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            {/* <Grid item xs={12} md={6}>
                                <Item sx={{ minHeight: 111, padding: "24px" }}>
                                    <Stack direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="space-between">
                                        <TextField
                                            id="insuranceCard.spouseName"
                                            error={Boolean(
                                                getIn(touched, "insuranceCard.spouseName") &&
                                                getIn(errors, "insuranceCard.spouseName")
                                            )}
                                            helperText={
                                                getIn(touched, "insuranceCard.spouseName") &&
                                                getIn(errors, "insuranceCard.spouseName")
                                            }
                                            label="Spouse Name"
                                            variant="outlined"
                                            fullWidth
                                            // name="insuranceCard.spouseName"
                                            value={values.insuranceCard.spouseName}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                values.insuranceCard.spouseName =
                                                    e.currentTarget.value;
                                                setspouseName(e.currentTarget.value);
                                                setDisabledButton(false);
                                            }}
                                        />

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                            <DatePicker
                                                disableFuture
                                                // maxDate={new Date()}
                                                minDate={moment().subtract(83, "years")}
                                                maxDate={moment().subtract(18, "years")}
                                                label="Spouse DOB"
                                                className="error"
                                                openTo="year"
                                                views={["year", "month", "day"]}
                                                value={values.insuranceCard.dob}
                                                onChange={(newValue: any) => {
                                                    values.insuranceCard.dob = getTimeZone(newValue);
                                                    setSpouseDOB(getTimeZone(newValue));
                                                    setErrorMessage(false);
                                                    setSubmit(false);
                                                    setDisabledButton(false);
                                                }}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        label={spouseDOB}
                                                        onKeyDown={(e: any) => {

                                                            e.preventDefault();
                                                        }}
                                                        {...params}
                                                        onBlur={handleBlur}
                                                        error={
                                                            getIn(touched, "insuranceCard.dob") &&
                                                            getIn(errors, "insuranceCard.dob")

                                                        }
                                                        helperText={
                                                            getIn(touched, "insuranceCard.dob") &&
                                                            getIn(errors, "insuranceCard.dob")
                                                        }
                                                        sx={{
                                                            "& .MuiSvgIcon-root": {
                                                                fill: theme.palette.primary.main,
                                                            },
                                                        }}

                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                </Item>
                            </Grid> */}
                            {/* <Grid item xs={6} md={6}>
                                <Item sx={{ minHeight: 111, padding: "24px" }}>
                                    <Stack direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="space-between"


                                    >
                                        <TextField id="nominee"
                                            // id="outlined-basic"
                                            error={Boolean(
                                                getIn(touched, "insuranceCard.nominee") &&
                                                getIn(errors, "insuranceCard.nominee")
                                            )}
                                            helperText={
                                                getIn(touched, "insuranceCard.nominee") &&
                                                getIn(errors, "insuranceCard.nominee")
                                            }
                                            label="Nominee Name"
                                            variant="outlined"
                                            fullWidth
                                            //id="nomineeName"
                                            // name="insuranceCard.nomineeName"
                                            value={values.insuranceCard.nominee}
                                            onChange={(e) => {
                                                values.insuranceCard.nominee =
                                                    e.currentTarget.value;
                                                setnomineeName(e.currentTarget.value);
                                                setDisabledButton(false);

                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            select
                                            error={Boolean(
                                                getIn(touched, "insuranceCard.relationship") &&
                                                getIn(errors, "insuranceCard.relationship")
                                            )}
                                            helperText={
                                                getIn(touched, "insuranceCard.relationship") &&
                                                getIn(errors, "insuranceCard.relationship")
                                            }
                                            label="Relationship"
                                            variant="outlined"
                                            fullWidth
                                            value={relationship}
                                        // name="insuranceCard.relationship"

                                        >

                                            {dummyRelation.map((option, idx) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                    onClick={(event) => {
                                                        values.insuranceCard.relationship = dummyRelation[idx].id;
                                                        setrelationship(dummyRelation[idx].id);
                                                        setDisabledButton(false);
                                                        setErrorMessage(true);
                                                        setSubmit(false);
                                                    }}
                                                >
                                                    {option.relation}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                </Item>
                            </Grid> */}
                            {/* <Grid item xs={12} md={6}>

                                <>
                                    {kidsArray.map((kid: any, index: any) => (
                                        <Grid item xs={12} sm={12}>
                                            <ItemPaper elevation={0}>
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <TextField
                                                        fullWidth
                                                        error={Boolean(
                                                            getIn(
                                                                touched,
                                                                `insuranceCard.kidsArray.${index}.name`
                                                            ) &&
                                                            getIn(
                                                                errors,
                                                                `insuranceCard.kidsArray.${index}.name`
                                                            )
                                                        )}
                                                        helperText={
                                                            getIn(
                                                                touched,
                                                                `insuranceCard.kidsArray.${index}.name`
                                                            ) &&
                                                            getIn(
                                                                errors,
                                                                `insuranceCard.kidsArray.${index}.name`
                                                            )
                                                        }
                                                        name={`insuranceCard.kidsArray.${index}.name`}
                                                        onBlur={handleBlur}
                                                        id={"name_" + index}
                                                        label="Child Name"
                                                        value={kid.name || ""}
                                                        variant="outlined"
                                                        //   disabled={!visible}
                                                        onChange={(event) => {
                                                            let obj = [...kidsArray];
                                                            obj[index].name = event.currentTarget.value;
                                                            setname(event.currentTarget.value);
                                                            setkidsArray(obj);
                                                            setDisabledButton(false);
                                                        }}
                                                    />
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            maxDate={new Date()}
                                                            disableFuture
                                                            label="Child DOB"
                                                            openTo="year"
                                                            views={["year", "month", "day"]}
                                                            value={kid.dob || ""}
                                                            minDate={dob}
                                                            // disabled=
                                                            // {childDisable}
                                                            onChange={(newValue: any) => {
                                                                let obj = [...kidsArray];
                                                                obj[index].dob = newValue;
                                                                setkidsArray(obj);
                                                                setdob(getTimeZone(newValue))
                                                                setDisabledButton(false);
                                                                setChildDisable(false);
                                                            }}
                                                            renderInput={(params: any) => (
                                                                <TextField
                                                                    fullWidth
                                                                    onKeyDown={(e: any) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                    {...params}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(
                                                                        getIn(
                                                                            touched,
                                                                            `insuranceCard.kidsArray.${index}.dob`
                                                                        ) &&
                                                                        getIn(
                                                                            errors,
                                                                            `insuranceCard.kidsArray.${index}.dob`
                                                                        )
                                                                    )}
                                                                    helperText={
                                                                        getIn(
                                                                            touched,
                                                                            `insuranceCard.kidsArray.${index}.dob`
                                                                        ) &&
                                                                        getIn(
                                                                            errors,
                                                                            `insuranceCard.kidsArray.${index}.dob`
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        "& .MuiSvgIcon-root": {
                                                                            fill: theme.palette.primary.main,
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>

                                                    <Tooltip title="Remove">
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={(e) => {
                                                                removeKidsFields(index);
                                                                setDisabledButton(false);
                                                            }}
                                                        >
                                                            <Icons.Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </ItemPaper>
                                        </Grid>
                                    ))} */}
                                    {/* <Box>
                                        <Tooltip title="Click here to add more childs.">
                                            <Button
                                                endIcon={<AddIcon />}
                                                onClick={() => addMoreKidsFields()}
                                            >
                                                Add Child{" "}
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </>

                            </Grid> */}

                            <Grid item xs={6} md={3}>
                                <Card sx={{ minWidth: 275, textAlign: "left", position: "relative", height: "100%" }}>
                                    <CardContent sx={{ height: "100%" }}>
                                        <Box sx={{ position: "absolute", top: 0, right: 0, color: "#ECFFD9" }} >
                                            <Icons.HalfHeart />
                                        </Box>
                                        <Box sx={{ position: "absolute", bottom: "-10px", right: 0, color: "#74C225" }} >
                                            <Icons.triangle />
                                        </Box>
                                        <Typography variant="h6" sx={{ fontWeight: "600" }} color="text.secondary" gutterBottom>
                                            My Card
                                        </Typography>
                                        <Typography component="div">
                                        </Typography>
                                        <Typography variant="body2">
                                            Employee Id
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {userDataHook?.employeeInfo?.employeeId}
                                        </Typography>
                                        <Typography variant="body2">
                                            Name
                                        </Typography>
                                        <Typography sx={{ marginBottom: 2, fontWeight: 500 }} variant="body2">
                                            {`${userDataHook?.employeeInfo?.firstName} ${userDataHook?.employeeInfo?.lastName}`}
                                        </Typography>

                                        <Typography variant="body2">
                                            DOB
                                        </Typography>
                                        <Typography sx={{ marginBottom: 2, fontWeight: 500 }} variant="body2">
                                        {`${moment(userDataHook?.personalInfo?.dateOfBirth).format("DD MMM, YYYY")}`}
                                        </Typography>
                                        {/* <Typography variant="body2">
                                            Policy Number
                                        </Typography>
                                        <Typography sx={{ fontWeight: 500 }} variant="body2">
                                            A12D4FA
                                        </Typography> */}
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item xs={6} md={3}>
                                {userDataHook?.insuranceCard?.spouseName != null && userDataHook?.insuranceCard?.spouseName != "" && (
                                    <Card sx={{ minWidth: 275, textAlign: "left", position: "relative", height: "100%" }}>
                                        <CardContent sx={{ height: "100%" }}>
                                            <Box sx={{ position: "absolute", top: 0, right: 0, color: "#FFEDF6" }} >
                                                <Icons.HalfHeart />
                                            </Box>
                                            <Box sx={{ position: "absolute", bottom: "-10px", right: 0, color: "#FE4FA3" }} >
                                                <Icons.triangle />
                                            </Box>
                                            <Typography variant="h6" sx={{ fontWeight: "600" }} color="text.secondary" gutterBottom>
                                                Spouse Card
                                            </Typography>
                                            <Typography component="div">
                                            </Typography>

                                            <Typography variant="body2">
                                                Name
                                            </Typography>
                                            <Typography sx={{ marginBottom: 2, fontWeight: 500 }} variant="body2">
                                                {userDataHook?.insuranceCard?.spouseName}
                                            </Typography>
                                            <Typography variant="body2">
                                                Spouse DOB
                                            </Typography>
                                            <Typography sx={{ marginBottom: 2, fontWeight: 500 }} variant="body2">
                                                {/* {userDataHook?.insuranceCard?.dob} */}
                                                {`${moment(userDataHook?.insuranceCard?.dob).format("DD MMM, YYYY")}`}
                                            </Typography>
                                        </CardContent>
                                    </Card>)}

                            </Grid>
                            {userDataHook?.insuranceCard?.childs?.length > 0 &&
                                userDataHook?.insuranceCard?.childs?.map((event: any, index: any) => (
                                    <Grid item xs={6} md={3}>
                                        <Card sx={{ minWidth: 275, textAlign: "left", position: "relative", height: "100%" }}>
                                            <CardContent sx={{ height: "100%" }}>
                                                <Box sx={{ position: "absolute", top: 0, right: 0, color: "#FAF1FF" }} >
                                                    <Icons.HalfHeart />
                                                </Box>
                                                <Box sx={{ position: "absolute", bottom: "-10px", right: 0, color: "#CF82FE" }} >
                                                    <Icons.triangle />
                                                </Box>
                                                <Typography variant="h6" sx={{ fontWeight: "600" }} color="text.secondary" gutterBottom>
                                                    Child{index + 1}
                                                </Typography>
                                                <Typography component="div">
                                                </Typography>

                                                <Typography variant="body2">
                                                    Name
                                                </Typography>
                                                <Typography sx={{ marginBottom: 2, fontWeight: 500 }} variant="body2">
                                                    {event?.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    DOB
                                                </Typography>
                                                <Typography sx={{ marginBottom: 2, fontWeight: 500 }} variant="body2">
                                                    {/* {event?.dob} */}
                                                    {`${moment(event?.dob).format("DD MMM, YYYY")}`}
                                                </Typography>

                                            </CardContent>
                                        </Card>
                                    </Grid>))}
                        </Grid>
                        {/* <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-end"
                            sx={{ mt: 3 }}
                        >
                            <Button
                                type="submit"
                                onClick={() => {
                                    setErrorMessage(true);
                                    setSubmit(true);
                                }}
                                disabled={DisabledButton}
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Stack> */}
                    </Box>
                </form>
            )}
        </Formik>
    );
}
