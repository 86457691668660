import React from 'react';



export default function MasterManagement() {
    return (
        <>
            <h1>MasterManagement ff</h1>
        </>
    );
}