// import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import NotFound from "../../components/styledComponent/NotFound";
import AddProjectPage from "./AddProjectPage";
import Grid from "@mui/material/Grid";
import Box, { BoxProps } from "@mui/material/Box";
import theme from "../../theme/theme";
import Card, { CardProps } from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Icons from "../../assests/icons/icons";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { projectManagementServive } from "../../service/project-management-service";
import { red } from "@mui/material/colors";
import { Formik, getIn } from "formik";
import { useSelector } from "react-redux";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { projectDetails } from "./models/projectNewModal";
import { IFiles } from "../users/userModel";
import FileUpload1 from "../../components/styledComponent/FileUpload1";
import FileUpload2 from "../../components/styledComponent/FileUpload2";
import { apiUrl } from "../../service/common-services/apiURL";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress } from "@mui/material";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import ProjectDocument from "../ProjectDocuments";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  height: "100%",
  transition: "all 0.3s ease ",
  "&:hover": {
    boxShadow: " 0px 0px 40px rgba(135, 135, 135, 0.25)",
    border: "none",
    "& .MuiPaper-root": {
      borderColor: "transparent",
    },
  },
}));

const toggleloaders = {
  position: "fixed",
  left: "50%",
  right: "50%",
  top: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)"
}

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: "stretch",
  height: "100%",
  // maxWidth: 408,
}));

const New = {
  fontWeight: 400,
  color: "#16B5BF",
  m: 0,
};

const Completed = {
  fontWeight: 400,
  color: theme.palette.success.dark,
  m: 0,
};

const Onhold = {
  fontWeight: 400,
  color: theme.palette.error.dark,
  m: 0,
};

const NoMilestone = {
  fontWeight: 400,
  color: "#16B5BF",
  m: 0,
};

const Inprogress = {
  fontWeight: 400,
  color: theme.palette.primary.main,
  m: 0,
};


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Milestones(props: any) {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [projectDetailHook, setProjectDetailHook] = useState<any>({});
  const [milestoneDetailHook, setMileStoneDetailHook] = useState<any[]>([]);
  const [sprintDetailHook, setSprintDetailHook] = useState<any>("");
  const [ShowMileStone, setShowMileStone] = useState(false);
  const [projectStatus, setProjectStatus] = React.useState({});
  const [milestoneId, setMilestoneId] = React.useState<any>({});
  const [milestoneName, setMilestoneName] = React.useState<any>("");
  const [iseffortsFocused, setIsEffortsFocused] = React.useState<any>({});
  const [isLabelFocused, setIsLabelFocused] = React.useState<any>({});
  const [price, setPrice] = React.useState("300");
  const [efforts, setEfforts] = React.useState("");
  const [isPriceFocused, setIsPriceFocused] = React.useState<any>({});
  const [date, setEndDate] = React.useState("");
  const [isdateFocused, setisdateFocused] = React.useState<any>({});
  const [startdate, setStartDate] = React.useState("");
  const [isstartdateFocused, setisstartdateFocused] = React.useState<any>({});
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);
  
  const handleChange = (event: any, newValue: number) => {
    setIsPriceFocused({});
    setIsEffortsFocused({});
    setValue(newValue);
    if (newValue === 2) {
      getProjectDetails()
    }

    if (newValue === 1) {
      getMileStoneDetails();
    }
  };

  useEffect(() => {
    if ((!id && _permission.action_ManageProjectsListing_Add_Access !== true)
      || (id && _permission.action_ManageProjectsListing_Edit_Access !== true)) {
      navigation('/dashboard');
    }
    // getProjectDetails();
    getMileStoneDetails();
  }, []);

  const handleUpdateMilestone = () => {
    var req = {
      sprintIds: sprintDetailHook,
      id: milestoneId,
      milestoneName: milestoneName,
      sprintId: "",
      duration: efforts,
      efforts: efforts,
      Costing: price,
      startDate: startdate,
      endDate: date,
    };

    projectManagementServive
      .EditMileStonePriority(req)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          toast.success("Milestone Updated Successfully");
          getMileStoneDetails();
        }
      });
  };

  const updateMilestone = ( 
    id: string,
    milestoneName: string, 
    effort: string,
    price: string,
    sDate: any,
    eDate: any) => {

  var req = {
    sprintIds: sprintDetailHook,
    id: id,
    milestoneName: milestoneName,
    sprintId: "",
    duration: effort,
    efforts: effort,
    Costing: price,
    startDate: sDate,
    endDate: eDate,
  };

projectManagementServive
  .EditMileStonePriority(req)
  .then((response: any) => {
    
    if (response.data.isError) {
      toast.error("Something Went Wrong, Please try again.");
    } else {
      toast.success("Milestone Updated Successfully");
      getMileStoneDetails();
    }
  });

  }

  const updateMilestonePaymentDetail = async (
    milestoneId: any,
    paymentStatus: any
  ) => {
    try {
      
      var input = {
        milestoneId: milestoneId,
        paymentStatus:
          paymentStatus == null ? 1 : paymentStatus == 1 ? 2 : 1,
      };
      await projectManagementServive
        .UpdateMilestonePaymentStatus(input)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            getMileStoneDetails();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getMileStoneDetails = async () => {
    
    try {
      let values = {
        projectId: id,
        take: 1000,
        skip: 0,
      };
      await projectManagementServive
        .getMileStoneData(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            // setIsUpdate(true);
            setMileStoneDetailHook(response?.data);

            setMilestoneId(response.data?.id);
            setProjectStatus(response.data?.status);
            setMilestoneName(response.data?.milestoneName ?? "");
            setStartDate(response.data?.startDate ?? "");
            setEndDate(response.data?.endDate ?? "");
            setEfforts(response.data?.efforts ?? "");
            setPrice(response.data?.costing ?? "");
           let validation: any = {
              milestoneName: Yup.string()
              .required("Milestone is required")
              .matches(/^[A-Za-z0-9_-]*$/g, "Reason can only contains Alphabets "),
              startDate: Yup.date().required("Start Date is required"),
              endDate: Yup.date().required("End Date is required"),
              duration: Yup.string().required("Duration is required"),
              costing: Yup.string()
              .required("Costing is required")
              .min(1,"Please Enter Costing")
              .max(9,"Maximum character limit is 9")
              .matches(/^[0-9]+$/g, "Costing can only contains Numeric"),
              efforts:Yup.string()
              .required("Efforts is required")
              .min(1,"Please Enter Efforts")
              .max(5,"Maximum character limit is 5")
              .matches(/^[0-9]+$/g, "Effort can only contains Numeric"),
            };
            let iv: any = {
              sprintIds: sprintDetailHook,
              id: milestoneId,
              milestoneName: milestoneName,
              sprintId: "",
              duration: efforts,
              efforts: efforts,
              costing: price,
              startDate: startdate,
              endDate: date,
            };

            response.data?.forEach((x:any,y: any)=>{
              validation = {
                ...validation,
                [`milestoneName_${y}`] : validation.milestoneName,
                [`efforts_${y}`] : validation.efforts,
                [`costing_${y}`] : validation.costing,
              }
              
              iv = {
                ...iv,
                [`milestoneName_${y}`] : x.milestoneName,
                [`efforts_${y}`] : x.efforts,
                [`costing_${y}`] : x.price
              }

            });

            setFieldValidationMilestone(Yup.object().shape(validation));
            setInitialValues(iv);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const [fieldValidationMilestone, setFieldValidationMilestone] = useState<any>();
  const [initialValues, setInitialValues] = useState<any>();
 // const fieldVal//idationMilestone = 

 const getUnEncrypedFiles = async (fileName: any) => {
  try {
    await projectManagementServive
      .getUnEncrypetProjectDocuments(fileName,id)
      .then(async (response: any) => {
        if (response.data.type === "application/json") {
          const data = await response.data.text();
          toast.warning(JSON.parse(data).listErrors[0]);
         } else {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          a.href = URL.createObjectURL(response.data);
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(a.href);
        }
      });
  } catch (ex: any) {
    ex.data.Error.map((err: any, idx: any) => {
      toast.warning(err);
    });
  }

}


  const getProjectDetails = async () => {
    
    try {
      await projectManagementServive
        .getProjectDetailsById(id)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            debugger
            setProjectDetailHook(response.data);
            if(response.data?.projectDetails?.projectDocument.length > 0){
            let documentData = response.data?.projectDetails?.projectDocument;
            console.log(documentData, "documentData")
            var document_array: any[] = [];
            documentData.map((item: any) => {
              document_array.push({
                name: item?.fileName,
                fileURL: item?.wbsPath,
                fileName: item?.fileName,
                file: null,
                itemType: "",
                uploadedBy:item?.uploadedBy,
              });
            });
            setDocumentFormValues(document_array);
            setFormValues(document_array);
          }}
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  async function UpdateProjectDetail(data: any) {
    try {
      await projectManagementServive
        .UpdateProjectDetail(data)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            setToggleLoader(false);
            toast.success("Project updated successfully.", {
              onClose: () => setUpdateButtonDisable(true),
            });
            getProjectDetails();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err, {
          onOpen: () => setUpdateButtonDisable(true),
          // onClose: () => setUpdateButtonDisable(false),
        });
      });
    }
  }

  const updateProject = async () => {
    setToggleLoader(true);
    setUpdateButtonDisable(true)
    try {
      let data = projectDetailHook;
      data.projectDetails.projectDocument = [];
      formValues.forEach(async function (item, index, array) {
        if (item.file !== null) {
          let formData = new FormData();
          formData.append("File", item.file);
          formData.append("FileFor", item.fileName);
          await projectManagementServive
            .uploadProfilePic(formData)
            .then(async (response: any) => {
              if (response.data.isError) {
                toast.error(
                  "facing issue while Document Upload" + item.name
                );
              } else {
                data.projectDetails.projectDocument.push({
                  fileName: item.name,
                  wbsPath: response.data,
                  uploadedBy: _authUser.id,
                });
              }
            });
        } else {
          data.projectDetails.projectDocument.push({
            fileName: item?.name,
            wbsPath: item?.fileURL,
            uploadedBy: item?.uploadedBy,
          });
        }
      });
      setTimeout(async () => {
        await UpdateProjectDetail(data);
      }, 2000);
    } catch (ex: any) {
      toast.warning("Error while updating data, check console.");
    }
  };

  let removeFile = (i: any) => {
    setUpdateButtonDisable(false)
    let badgesformValues = [...documentFormValues];
    badgesformValues.splice(i, 1);
    setDocumentFormValues(badgesformValues);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };


  let IfileObj: IFiles = {
    itemType: "",
    name: "",
    fileURL: "",
    fileName: "",
    file: null,
  };
  let objDocument: IFiles[] = [];
  const [documentName, setDocumentName] = React.useState("");
  const [currentprojectNameName, setcurrentprojectNameName] = React.useState("");
  const [documentFile, setDocumentFile] = React.useState<any>(null);
  const [documentFileURL, setDocumentFileURL] = React.useState<any>("");
  const [tempDocumentFileURL, setTempDocumentFileURL] = React.useState<any>("");
  const [documentFileName, setDocumentFileName] = React.useState<any>("");
  const [documentFormValues, setDocumentFormValues] = useState<IFiles[]>(objDocument);
  const [formValues, setFormValues] = useState<IFiles[]>(objDocument);

  let addDocumentValues = () => {
    setUpdateButtonDisable(false)
    IfileObj.itemType = "";
    IfileObj.name = "";
    IfileObj.fileURL = "";
    IfileObj.fileName = "";
    IfileObj.file = null;
    if (
      documentName != null &&
      documentName != "" &&
      documentName.length > 0 &&
      documentFile != null
    ) {
      IfileObj.name = documentName;
      IfileObj.fileURL = documentFileURL;
      IfileObj.fileName = documentFileName;
      IfileObj.file = documentFile;
      setDocumentName("");
      setDocumentFile(null);
      setDocumentFileURL("");
      setTempDocumentFileURL("");
      setDocumentFileName("")
      let fObj = [...formValues];
      fObj.push(IfileObj);
      setFormValues([...formValues, IfileObj]);
    } else {
      if (documentName == null || documentName == "" || documentName.length == 0) {
        setUpdateButtonDisable(true)
        toast.warning("Please add Document name.");
        return;
      }
      if (documentFile == null) {
        setUpdateButtonDisable(true)
        toast.warning("Please choose Document file.");
        return;
      }
    }
  };
  let removeFormFields = (i: any) => {
    setUpdateButtonDisable(false)
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const cancelDocumentFile = () => {
    setTempDocumentFileURL("");
    setDocumentFile(null);
    setDocumentFileURL("");
    setDocumentFileName("")
  };

  const triggerDocumentFile = (fileList: FileList, index: number) => {
    setDocumentFile(fileList[0]);
    setDocumentFileURL(URL.createObjectURL(fileList[0]));
    setTempDocumentFileURL(URL.createObjectURL(fileList[0]));
    setDocumentFileName(fileList[0].name);
  };
  function getFileExtension(url: string): any {
    const splitUrl = url?.split(".");
    return splitUrl?.at(-1) || "";
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link color="inherit" to="/">
          DashBoard
        </Link>
        <Typography color="text.primary">View Project</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 2, pb: 2 }}>
        {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", display:'flex', alignItems:'center' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Project View" {...a11yProps(0)} />
              {ShowMileStone == true && _permission.tab_ManageMilestones_Access === true && (
                <Tab label="Milestones" {...a11yProps(1)} />
              )}
              {ShowMileStone == true && (
              <Tab label="Document" {...a11yProps(ShowMileStone == true && _permission.tab_ManageMilestones_Access === true ? 2: 1)} />)}
            </Tabs>

            {currentprojectNameName &&(
            <Box sx={{mt:0,ml:'auto', mr:1, display:'flex'}}>
              <Typography>Project Name-:</Typography>
            {currentprojectNameName}
            </Box>)}
          </Box>
          
          <TabPanel value={value} index={0}>  
            <AddProjectPage setShowMileStone={setShowMileStone} pName={setcurrentprojectNameName}/>
          </TabPanel>
          {ShowMileStone == true && _permission.tab_ManageMilestones_Access === true && (
            <TabPanel value={value} index={1}>
              <>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={fieldValidationMilestone}
                  onSubmit={async (values, { setStatus, setSubmitting }) => { }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>

                      <Box component={Link}
                        to={`/board/` + id}
                        sx={{ textDecoration: "none", color: theme.palette.primary.main, mr: 3, display: "inline-block" }}
                      >
                        Go to Board
                      </Box>



                      

                      <Grid container spacing={1}>
                        {/* {projectIstDetailMilestone.map((project: any, idx: any) => ( */}
                        {milestoneDetailHook?.map((project: any, idx: any) => (
                          <Grid item xs={12} md={4}>
                            <StyledBox
                              key={"peoject_" + idx}
                              id={"basic-menu_" + idx}
                            >
                              <StyledCard variant="outlined" onClick={() => {
                                //  alert('mian outside')
                                if (isstartdateFocused[idx]) {
                                  handleUpdateMilestone();
                                  setisstartdateFocused({
                                    [idx]: false
                                  });
                                }
                                if (isdateFocused[idx]) {
                                  handleUpdateMilestone();
                                  setisdateFocused({
                                    [idx]: false
                                  });
                                }
                              }}>
                                <Box

                                  id={"card-menu_" + idx}
                                  onClick={() => {
                                    setSprintDetailHook(project?.sprintIds);
                                    setMilestoneName(
                                      project?.milestoneName ?? ""
                                    );
                                    setStartDate(project?.startDate ?? "");
                                    setEndDate(project?.endDate ?? "");
                                    setEfforts(project?.efforts ?? "");
                                    setPrice(project?.costing ?? "");
                                  }}

                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"

                                  >
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                                      <Typography
                                        variant="subtitle1"
                                        component="span"
                                        sx={{ mr: 1 }}
                                      >
                                        {/* {project?.milstonename} */}
                                        {/* <Grid item xs={12} sm={6}> */}
                                        {!isLabelFocused?.[idx] ? (
                                          <Typography
                                            onClick={() => {
                                              // const existing = {
                                              //   ...isLabelFocused,
                                              //   [idx]: true
                                              // };
                                              // setIsLabelFocused(existing);
                                            }}
                                          >
                                            {project.milestoneName}
                                          </Typography>
                                        ) : (
                                          <FormControl fullWidth>
                                            <TextField
                                              autoFocus
                                              disabled
                                              name = {`milestoneName_${idx}`}
                                              error={Boolean(
                                                getIn(errors, `milestoneName_${idx}`) &&
                                                getIn(touched, `milestoneName_${idx}`)
                                              )}
                                              helperText={
                                                getIn(touched, `milestoneName_${idx}`) &&
                                                getIn(errors, `milestoneName_${idx}`)
                                              }
                                              required
                                              id={"milestoneName_" + idx}
                                              value={
                                                project.milestoneName ??
                                                milestoneName
                                              }
                                              // onChange={(event) => {
                                              //   values[`milestoneName_${idx}`]= event.target.value;
                                              //   project.milestoneName =
                                              //   event.currentTarget.value;
                                              //   handleChange(event); 
                                              // }}
                                              // onBlur={(event) => {
                                              //   const a = errors;
                                              //   handleBlur(event);   
                                              //   if(!errors[`milestoneName_${idx}`]) {
                                              //     const existing = {
                                              //       ...isLabelFocused,
                                              //       [idx]: false
                                              //     };
                                              //     setIsLabelFocused(existing);
                                              //     updateMilestone(project?.id,project?.milestoneName,project?.efforts,project?.costing,
                                              //       project?.startDate,project?.endDate);
                                              //   }
                                              // }}
                                              fullWidth
                                              sx={{
                                                "& .MuiInputBase-input": {
                                                  padding:
                                                    "15px 40px 15px 10px",
                                                },
                                              }}
                                            />
                                          </FormControl>
                                        )}
                                        {/* </Grid> */}
                                      </Typography>

                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={projectStatus}
                                      >
                                        <Typography
                                          justifyContent="space-between"
                                          component="p"
                                        >
                                          {(() => {
                                            switch (project.status) {
                                              case 1:
                                                return (
                                                  <Chip
                                                    variant="submited"
                                                    sx={New}
                                                    label="Not Started"
                                                  />
                                                  // <Typography
                                                  //   justifyContent="space-between"

                                                  //   component="p"
                                                  //   sx={New}
                                                  //   style={{ color: "#16B5BF" }}
                                                  // >
                                                  //   <Icons.dots /> New
                                                  // </Typography>
                                                );
                                              case 2:
                                                return (
                                                  // <Typography
                                                  //   justifyContent="space-between"
                                                  //   component="p"
                                                  //   sx={Inprogress}
                                                  //   style={{
                                                  //     color: theme.palette.primary.main,
                                                  //   }}
                                                  // >
                                                  <Chip
                                                    variant="progress"
                                                    sx={Inprogress}
                                                    label="In Progress"
                                                  />
                                                  //{/* <Icons.dots /> In Progress */}
                                                  //{/* </Typography> */}
                                                );
                                              case 3:
                                                return (
                                                  // <Typography
                                                  //   justifyContent="space-between"
                                                  //   component="p"
                                                  //   sx={Completed}
                                                  //   style={{
                                                  //     color: theme.palette.success.dark,
                                                  //   }}
                                                  // >
                                                  <Chip
                                                    variant="approved"
                                                    sx={Completed}
                                                    label="Completed"
                                                  />
                                                  //   {/* <Icons.CompletedPro /> Completed */}
                                                  // </Typography>
                                                );
                                              case 4:
                                                return (
                                                  // <Typography
                                                  //   justifyContent="space-between"
                                                  //   component="p"
                                                  //   sx={Onhold}
                                                  //   style={{
                                                  //     color: theme.palette.error.dark,
                                                  //   }}
                                                  // >
                                                  //  {/* <Icons.dots
                                                  //   style={{ verticalAlign: "middle" }}
                                                  // />{" "}
                                                  // On Hold */}
                                                  <Chip
                                                    variant="hold"
                                                    sx={Onhold}
                                                    label="On Hold"
                                                  />
                                                  //</Typography>
                                                );
                                              default:
                                                //   return " Testing";
                                                return (
                                                  <Chip
                                                    variant="submited"
                                                    sx={NoMilestone}
                                                    label="Not Started"
                                                  />
                                                );
                                            }
                                          })()}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                   
                                  </Stack>
                                  <Grid
                                    container
                                    spacing={3}
                                    sx={{ mt: 0.5 }}

                                  >
                                    <Grid item xs={12} sm={6}>
                                    <Typography fontSize={13}>Start Date</Typography>
                                      {!isstartdateFocused?.[idx] ? (
                                        <Typography
                                          onClick={() => {
                                            if(project?.paymentStatus == 2){
                                              return false
                                            }
                                            setisstartdateFocused({
                                              [idx]: true,
                                            });
                                          }}
                                        >
                                          {`${moment(project?.startDate).format(
                                            "DD-MMM-YYYY"
                                          )}`
                                            ? `${moment(
                                              project?.startDate
                                            ).format("DD-MMM-YYYY")}`
                                            : startdate}
                                        </Typography>
                                      ) : (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >

                                          <Box onClick={(eve) => {
                                            
                                            eve.stopPropagation();
                                          }}>
                                            <DatePicker
                                              //label="Start Dated"
                                              // disablePast
                                              borderRadius="semi-square"
                                              
                                              value={
                                                project.startDate ?? startdate
                                              }
                                              onChange={(newValue : any) => {
                                                let abc = getDate(newValue)   ;                                           
                                                project.startDate = abc;
                                                setMilestoneId(project?.id);
                                                setStartDate(abc);
                                              }}
                                              // renderInput={(params) => (
                                              //   <TextField
                                              //     {...params}
                                              //     onBlur={(event) => {
                                              //       // project.startDate =
                                              //       //   event.currentTarget.value;
                                              //       // setStartDate(
                                              //       //   event.currentTarget.value
                                              //       // );
                                              //       // setisstartdateFocused({
                                              //       //   [idx]: false,
                                              //       // });
                                              //       // setMilestoneId(project?.id);

                                              //     }}
                                              //     value={
                                              //       project.startDate ?? startdate
                                              //     }
                                              //     onChange={(event) => {
                                              //       project.startDate =
                                              //         event.currentTarget.value;
                                              //       setStartDate(
                                              //         event.currentTarget.value
                                              //       );
                                              //     }}
                                              //     // autoFocus
                                              //     fullWidth
                                              //     sx={{
                                                   
                                              //     }}
                                              //   />
                                              // )}
                                            />
                                          </Box>
                                        </LocalizationProvider>
                                      )}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                    <Typography fontSize={13}>End Date</Typography>
                                      {!isdateFocused?.[idx] ? (
                                        <Typography
                                          onClick={() => {
                                            if(project?.paymentStatus == 2){
                                              return false
                                            }
                                            setisdateFocused({ [idx]: true });
                                            
                                          }}
                                        >
                                          {/* {`${moment(project?.endDate).format(
                                  "DD/MMM/YYYY"
                                )}`} */}
                                          {`${moment(project?.endDate).format(
                                            "DD-MMM-YYYY"
                                          )}`
                                            ? `${moment(
                                              project?.endDate
                                            ).format("DD-MMM-YYYY")}`
                                            : date}
                                        </Typography>
                                      ) : (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Box onClick={(eve) => {
                                            
                                            eve.stopPropagation();
                                          }}>
                                            <DatePicker
                                              //label="End Date"
                                              // disablePast
                                              borderRadius="semi-square"
                                             
                                              value={project.endDate ?? date}
                                              onChange={(newValue : any) => {  
                                                let abc = getDate(newValue)                                              
                                                project.endDate = abc ;
                                                setMilestoneId(project?.id);
                                                setEndDate(abc);
                                              }}
                                              // renderInput={(params) => (
                                              //   <TextField
                                              //     {...params}
                                              //     onBlur={(event) => {
                                              //       project.endDate =
                                              //         event.currentTarget.value;
                                              //       setEndDate(
                                              //         event.currentTarget.value
                                              //       );
                                              //       setMilestoneId(project?.id);
                                              //       // setisdateFocused({
                                              //       //   [idx]: false,
                                              //       // });
                                              //       // handleUpdateMilestone();
                                              //     }}
                                              //     value={project.endDate ?? date}
                                              //     onChange={(event) => {
                                              //       project.endDate =
                                              //         event.currentTarget.value;
                                              //       setMilestoneId(project?.id);
                                              //       setEndDate(
                                              //         event.currentTarget.value
                                              //       );
                                              //     }}
                                              //     // autoFocus
                                              //     fullWidth
                                              //     // sx={{
                                              //     //   "& .MuiInputBase-input": {
                                              //     //     padding:
                                              //     //       "15px 40px 15px 10px",
                                              //     //   },
                                              //     // }}
                                              //   />
                                              // )}
                                            /></Box>
                                        </LocalizationProvider>
                                      )}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                      <Typography fontSize={13}>Efforts</Typography>
                                      {!iseffortsFocused?.[idx] ? (
                                        <Typography
                                          onClick={() => {
                                            if(project?.paymentStatus == 2){
                                              return false
                                            }
                                            const existing = {
                                              ...iseffortsFocused,
                                              [idx]: true
                                            };
                                            setIsEffortsFocused(existing);
                                          }}
                                        >
                                          {project.efforts || 0}
                                        </Typography>
                                      ) : (
                                        <TextField
                                          autoFocus
                                        
                                          id={"efforts" + idx}
                                          name={`efforts_${idx}`}
                                          error={Boolean(
                                            getIn(errors, `efforts_${idx}`) &&
                                            getIn(touched, `efforts_${idx}`)
                                          )}
                                          helperText={
                                            getIn(touched,`efforts_${idx}`) &&
                                            getIn(errors, `efforts_${idx}`)
                                          }
                                          value={project.efforts}
                                          onChange={(event) => {
                                            values[`efforts_${idx}`]= event.target.value;
                                            project.efforts =
                                              event.currentTarget.value; 
                                              handleChange(event);
                                             
                                          }}
                                          onBlur={(event) => {
                                            
                                            handleBlur(event);
                                            
                                            if(!errors[`efforts_${idx}`]) {
                                              const existing = {
                                                ...iseffortsFocused,
                                                [idx]: false
                                              };
                                              setIsEffortsFocused(existing);
                                              updateMilestone(project?.id,project?.milestoneName,project?.efforts,project?.costing,
                                                project?.startDate,project?.endDate);
                                            }
                                          }}
                                          fullWidth
                                          sx={{
                                            "& .MuiInputBase-input": {
                                              padding: "15px 40px 15px 10px",
                                            },
                                          }}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs>
                                      <Typography fontSize={13}>Costing</Typography>
                                      {!isPriceFocused?.[idx] ? (
                                        <Typography
                                          onClick={() => {
                                            if(project?.paymentStatus == 2){
                                              return false
                                            }
                                            const existing = {
                                              ...isPriceFocused,
                                              [idx]: true
                                            };
                                            setIsPriceFocused(existing); 
                                          }}
                                        >
                                          {project.costing || 0}
                                        </Typography>
                                      ) : (
                                        <TextField
                                          autoFocus
                                          id={"price" + idx}
                                          name={`costing_${idx}`}
                                          error={Boolean(
                                            getIn(errors, `costing_${idx}`) &&
                                            getIn(touched, `costing_${idx}`)
                                          )}
                                          helperText={
                                            getIn(touched,`costing_${idx}`) &&
                                            getIn(errors, `costing_${idx}`)
                                          }
                                          value={project.costing ?? price}
                                          onChange={(event) => {
                                            values[`costing_${idx}`]= event.target.value;
                                            project.costing =
                                              event.currentTarget.value; 
                                              handleChange(event);
                                          }}
                                          onBlur={(event) => {
                                            
                                            handleBlur(event);
                                            
                                            if(!errors[`costing_${idx}`]) {
                                              const existing = {
                                                ...isPriceFocused,
                                                [idx]: false
                                              };
                                              setIsPriceFocused(existing);                                              
                                              updateMilestone(project?.id,project?.milestoneName,project?.efforts,project?.costing,
                                                project?.startDate,project?.endDate);
                                            }
                                          }}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid sx={{marginTop: "16px"}}>
                                    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                      Payment Status- <Box sx={{
                                        display: "inline-block", backgroundColor:
                                          project?.paymentStatus === null || project?.paymentStatus === 0 ? "#f5f5f5" : project?.paymentStatus === 1 ? "#ffcc00"
                                            : "#00cc00",
                                        padding: "3px 8px", border: "1.5px solid #cccccc", borderRadius: "3px", fontSize: "14px", ml: 'auto'
                                      }}>
                                        {project?.paymentStatus === null || project?.paymentStatus === 0 ? "NA" : project?.paymentStatus === 1 ? "Invoice raised" : "Payment Received"}
                                      </Box>

                                    </Stack>
                                    <Stack flexDirection={"column"} justifyContent={'space-between'}>
                                      {/* <Typography>Invoice date {leavedetail?.startTime && leavedetail?.endTime ? `${moment().format("DD MMM, YYYY")}` : ""}</Typography> */}
                                      <Typography sx={{fontSize: "14px", display: "flex", justifyContent: "space-between"}}>
                                        {project?.raiseInvoiceDate ? (
                                          <>
                                            <span>Invoice Raised On:</span>
                                            <span >{moment(project?.raiseInvoiceDate).format("DD MMM, YYYY")}</span>
                                          </>
                                        ) : ""}
                                      </Typography>
                                      <Typography sx={{fontSize: "14px", display: "flex", justifyContent: "space-between"}}>
                                        {/* {project?.paymentDoneDate ? `Payment Received On: ${moment(project?.paymentDoneDate).format("DD MMM, YYYY")}` : ""} */}
                                        {project?.paymentDoneDate ? (
                                          <>
                                            <span>Payment Received On:</span>
                                            <span >{moment(project?.paymentDoneDate).format("DD MMM, YYYY")}</span>
                                          </>
                                        ) : ""}
                                      </Typography>
                                    </Stack>

                                  </Grid>
                                  {_permission.action_ManageMilestones_UpdatePaymentStatus_Access === true && project?.paymentStatus != 2 && (
                                    <Button
                                      disabled={project?.status === null || project?.status === 1}
                                      variant="contained"
                                      sx={{ mt: 2 }}
                                      color={project?.paymentStatus === null || project?.paymentStatus === 0 ? "info" : project?.paymentStatus === 1 ? "warning" : "success"}
                                      size="small"
                                      onClick={() => {
                                        if (project?.paymentStatus === null || project?.paymentStatus === 0 || project?.paymentStatus === 1) {
                                          updateMilestonePaymentDetail(project?.id, project?.paymentStatus);
                                        }
                                      }}
                                    >
                                      {project?.paymentStatus === null || project.paymentStatus === 0 ? "Raise Invoice" : project?.paymentStatus === 1 ? "Payment Received" : "Payment Received"}
                                    </Button>
                                  )}
                                  {/* <Grid>
                                    <Box sx={{
                                      mt: 2, display: "inline-block", backgroundColor: "#f5f5f5",
                                      padding: "3px 8px", border: "1.5px solid #cccccc", borderRadius: "3px", fontSize: "14px"
                                    }}>
                                      {project?.paymentStatus === 1 ? "Invoice Raised Payment Pending" : project?.paymentStatus === 2 ? "Payment Done" : "Paid"}
                                      </Box>
                                  </Grid> */}
                                </Box>
                              </StyledCard>
                            </StyledBox>
                          </Grid>
                        ))}
                      </Grid>

                      {milestoneDetailHook.length == 0 && (
                        <NotFound NotfoundText="No Milestones Found" />
                      )}
                    </form>
                  )}
                </Formik>
              </>
            </TabPanel>

          )}
          <TabPanel value={value} index={ShowMileStone == true && _permission.tab_ManageMilestones_Access === true ? 2: 1}>
            <>
              {/* <Box sx={{ background: "#F0F9FF", padding: "30px ", borderRadius: "10px" }}>
                <Grid container spacing={4} >
                  <Grid item xs={12} sm={4}>
                    <Typography
                    >
                      File Name
                    </Typography>
                    <TextField
                      required
                      value={documentName}
                      placeholder="Type File Name*"
                      sx={{ width: "100%", background: "#fff" }}
                      onChange={(event) => {
                        let value = event.currentTarget.value.replace(/[^a-zA-Z\.\s]/g, "")
                        value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 50);
                        setDocumentName(value);
                      }}
                    />
                    <Box sx={{ mt: 2 }}>
                      {formValues?.map((element: any, i: any) => (
                        <Chip
                          label={element?.fileURL
                            ?? element?.wbsPath}
                          variant="outlined"
                          onDelete={() => removeFormFields(i)}
                          size="medium"
                          sx={{ margin: "3px 0 0 0" }}
                        />
                      ))}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8} >
                    <Typography
                    >
                      File Browser
                    </Typography>
                    <Stack direction='row' justifyContent="space-between" alignItems="normal" sx={{ marginTop: "0px" }}>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {documentFile == null && <FileUpload2
                          selectFile={triggerDocumentFile}
                          index={1}

                        />}
                        <Button
                          variant="contained"
                          onClick={() => addDocumentValues()}
                          // size="small"
                          sx={{ mx: 2 }}
                          // disabled={!documentName || !documentFile}
                        >
                          Add{" "}
                        </Button>
                        <a href={tempDocumentFileURL} target="_blank" rel="noopener noreferrer">{tempDocumentFileURL}</a>
                        {documentFile !== null && (
                          <IconButton onClick={() => cancelDocumentFile()}><ClearIcon /></IconButton>)}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ float: "right" }}
                          disabled={updateButtonDisable}
                          onClick={() => updateProject()}
                        >
                          Save
                        </Button>
                   
                      </Box>


                    </Stack> */}

                    {/* {formValues.map((element: any, i: any) => (
                      <Chip
                        label={element?.fileURL
                          ?? element?.wbsPath}
                        variant="outlined"
                        onDelete={() => removeFormFields(i)}
                        size="medium"
                      />
                    ))} */}
                  

                  {/* <Grid item xs={12} sm={4} > */}
                  {/* <Typography
                      variant="body1"
                      component="h2"
                      sx={{ fontWeight: 500, visibility: "Hidden" }}
                    >
                      File Browser
                    </Typography> */}
                  {/* <Button
                      variant="contained"
                      color="primary"
                      sx={{ float: "right" }}
                      disabled={updateButtonDisable}
                      onClick={() => updateProject()}
                    >
                      Save
                    </Button> */}
                  {/* </Grid> */}
                {/* </Grid>
              </Box>
              <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr. No</TableCell>
                      <TableCell>File Name</TableCell>
                      <TableCell>File Link</TableCell>
                      <TableCell>File Type</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentFormValues?.map((row: any, i: any) => (
                      <TableRow
                        key={`${row.name}-${i}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell><a onClick={()=>{getUnEncrypedFiles(row?.fileURL)}} style={{ cursor: 'pointer' }}>{row?.fileURL}</a></TableCell>
                        <TableCell  >
                          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                          {getFileExtension(row?.fileURL) == "pdf"&&(
                            <Icons.Pdfdocs />
                          )}
                          {getFileExtension(row?.fileURL) == "docx"&&(
                            <Icons.docxicon />
                          )}
                          {getFileExtension(row?.fileURL) == "png"&&(
                            <Icons.pngicon/>
                          )}
                          {getFileExtension(row?.fileURL) == "jpeg"&&(
                            <Icons.jpegicon />
                          )}
                           {getFileExtension(row?.fileURL) == "jpg"&&(
                            <Icons.jpegicon />
                          )}
                          {getFileExtension(row?.fileURL) == "txt"&&(
                            <Icons.txticon />
                          )}
                           {getFileExtension(row?.fileURL) == "xlsm"&&(
                            <Icons.xlsmicon />
                          )}
                           {getFileExtension(row?.fileURL) == "xlsx"&&(
                            <Icons.xlsxicon />
                          )}
                            {getFileExtension(row?.fileURL) == "xls"&&(
                            <Icons.xlsicon />
                          )}
                           {getFileExtension(row?.fileURL) == "docm"&&(
                            <Icons.docxicon />
                          )}
                            {getFileExtension(row?.fileURL) == "doc"&&(
                            <Icons.docicon />
                          )}
                            <Box sx={{ ml: 1 }}>
                              {getFileExtension(row?.fileURL)}
                            </Box>

                          </Box>

                        </TableCell>
                        <TableCell align="center" sx={{ color: "#EB445A" }}> <IconButton
                          aria-label="delete"
                          onClick={() => removeFile(i)}
                        >
                          <Icons.Delete />
                        </IconButton> </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
              <ProjectDocument Id ={id}/>

            </>
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
