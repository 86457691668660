import Button from "@mui/material/Button";
import * as Icons from "../../assests/icons/icons";
import { toast } from "material-react-toastify";
import theme from "../../theme/theme";
import React from "react";

const styles = {
  InputStyle: {
    maxWidth: "100%",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderStyle: "dashed",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default function FileUploadPNG(props: { selectFile?: any; index?: any; disabled?: boolean; }) {
  return (
    <Button
      variant="outlined"
      sx={styles.InputStyle}
      component="label"
      startIcon={<Icons.Upload />}
      disabled={props.disabled || false}
    >
      Upload
      <input
        onChange={async (event) => {
          const files = event.target.files ?? new FileList();
          const acceptedFileTypes = ["image/png"];

          if (files.length > 0) {
            const file = files[0];
            if (acceptedFileTypes.includes(file.type)) {
              // Read the dimensions of the image
              const dimensions = await getImageDimensions(file);
              if (dimensions.width === 1779 && dimensions.height === 220) {
                // It's an accepted image file with the correct dimensions
                props.selectFile(files, props.index);
              } else {
                toast.warning("Please select a PNG image file with dimensions 1779x220.");
              }
            } else {
              toast.warning("Please select a PNG image file.");
            }
          }
        }}
        hidden
        accept=".png"
        type="file"
      />
    </Button>
  );
}

async function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
}
