import { combineReducers } from 'redux';
const Fill_RiskAlarm_Permission = 'Fill_RiskAlarm_Permission';
const Empty_RiskAlarm_Permission = 'Empty_RiskAlarm_Permission';

const defaultPermissions = 
  {
    access: false,
    module_ManageRiskAlarm_Access:false,
  }

export function FillRiskAlarmPermission(module) {
  return {
    type: Fill_RiskAlarm_Permission,
    module,
  }
}
export function EmptyRiskAlarmPermission() {
  return {
    type: Empty_RiskAlarm_Permission,defaultPermissions
  }
}

function RiskAlarmPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_RiskAlarm_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
                permissions.access=true;
                permissions.module_ManageRiskAlarm_Access = true;
                return state = permissions;
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_RiskAlarm_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const RiskAlarmPermissionApp = combineReducers({
    RiskAlarmPermission
});

export default RiskAlarmPermissionApp;