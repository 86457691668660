import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Autocomplete, TablePagination, TableSortLabel } from "@mui/material";
import { Department } from "../../../Interfaces/IDepartment";
import Notes from "../../../components/styledComponent/Note";
import { IResource } from "../../../Interfaces/IResource";
import { clsDepartmentList } from "../../../service/common-services/department";
import { clsResourcesList } from "../../../service/common-services/resources";
import { masterreasonServices } from "../../../service/BrowniePoints/masterReason-services";

import { visuallyHidden } from "@mui/utils";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import NotFound from "../../../components/styledComponent/NotFound";
import { payRewardServices } from "../../../service/BrowniePoints/payReward-services";
import { eTypeOfUser } from "../../../Enum/eTypeOfUser";
import moment from "moment";
import InfoPopover from "../../../components/CommonInfoComponent";

const options = ["All Department", "All Manager ", "All Months"];

interface Data {
  srNo: string;
  employeeId: string;
  name: string;
  userId: string;
  status: number;
  managerName: string;
  managerId: string;
  departmentName: string;
  departmentId: string;
  pointOfRedeemptionRequest: string;
  id: string,
  manager: string,
  department: string,
  pointType: 1,
  totalEarnedPoint: string,
  totalUnredeemPoint: string,
  pointToRedeem: string,
  valueOfPointToRedeem: string,
  valueOfUnredeemPoints: string,
  requestedOn: string,
  paidOn: string;
}
export default function PayReward() {
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const navigate = useNavigate();
  const [tabValue, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    debugger
    setValue(newValue);
    //setdepartmentsId("");
     // setfiltersSelected(false);
    // setPage(0);
    // setRewardList([]);
    // setRedeemHistory([]);
     // setresourceHook("");
    // setDepartmentHook("");
    setdepartmentsId(departmentsId ?? "");
    setResourceId(resourceId ?? "");
    if(departmentsId !== ""){
    if(newValue === "1"){
      getAllPendingRewardPointsOfUser(departmentsId ?? "", resourceId ?? "")}
      else if(newValue === "2"){
        getRedeemHistory(departmentsId ?? "" , resourceId ?? "")
      }}
   
  };

  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // -----------------------------
 
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: any | any },
    b: { [key in Key]: any | any }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  let DepartmentId = "";
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [departmentHook, setDepartmentHook] = React.useState("");
  const [selected, setSelected] = React.useState<any>([]);
  const [selectedsave, setSelectedSave] = React.useState<any>([]);
  const [redeemHistory, setRedeemHistory] = React.useState<any[]>([]);
  let ResourceId = "";
  const [resources, setresources] = React.useState<IResource[]>([]);
  const [resourceHook, setresourceHook] = React.useState("");
  const [resourceId, setResourceId] = useState("");
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      var toggle = rewardList.map((n) => n.userId);
      var list = rewardList
      setSelected(rewardList);
      setSelectedSave(rewardList);
      return;
    }
    else{
      setSelectedSave("");
    }
    setSelected([]);
  };
  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("asc");
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rewardList, setRewardList] = useState<Data[]>([]);
  const pageCount = Math.ceil(rewardList.length / rowsPerPage);
  const pageCount1 = Math.ceil(redeemHistory.length / rowsPerPage);
  const [departmentsId, setdepartmentsId] = useState("");
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const isSelected = (name: string) => selected.some((x:any)=>x.userId == name);

  const getAllPendingRewardPointsOfUser = async (
    departmentId: any,
    resourceId: any,
  ) => {
    let values = {
      departmentId: departmentId,
      resourceId: resourceId,
    };
    await payRewardServices
      .getAllPendingRewardPointsOfUser(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          //response.data
          setRewardList(response?.data);
        }
      });
  };
  const getRedeemHistory = async (
    departmentId: any,
    resourceId: any,
  ) => {
    let values = {
      status:2,
      department: departmentId,
      resource: resourceId,
      userTypeEmployee: false,
      isRequestByUser: null,
    };
    await masterreasonServices
      .getAllRewardRequest(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          //response.data
          setRedeemHistory(response?.data);
        }
      });
  };

  const ApprovePaid = async () => {
    if (selectedsave.length <= 0) {
      return toast.warning("Please select before update the status");
    }
    selectedsave.forEach((x:any)=>{ 
      delete x.departmentName; 
      delete x.managerName
      delete x.managerId
      delete x.name
      delete x.employeeId
      x.pointType = 1;
      x.pointToRedeem = x.totalEarnedPoint
      });
    await payRewardServices.ApprovePaid(selectedsave).then((response: any) => {
      if (response.data.isError) {
      } else {
        toast.success("Points Redeemed Successfully")
        setSelected([]);
        setSelectedSave([]);
        getAllPendingRewardPointsOfUser(departmentsId, resourceId);
      }
    });
  };
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - rewardList.length) : 0;
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }
  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: "employeeId",
      numeric: false,
      disablePadding: false,
      label: "Emp ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "managerName",
      numeric: false,
      disablePadding: false,
      label: "Manager",
    },
    {
      id: "departmentName",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },
    {
      id: "totalEarnedPoint",
      numeric: false,
      disablePadding: false,
      label: "Total Earned Point",
    },
    {
      id: "totalUnredeemPoint",
      numeric: false,
      disablePadding: false,
      label: "Unredeemed Points",
    },
    {
      id: "pointOfRedeemptionRequest",
      numeric: false,
      disablePadding: false,
      label: "Point Redemption Request",
    },
    {
      id: "valueOfPointToRedeem",
      numeric: false,
      disablePadding: false,
      label: "Value of Unredeemed Points (Rs)",
    },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      order,
      orderBy,
      onSelectAllClick,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: any,
  ) => {
    let newSelected: readonly string[] = [];
      const selectedIndex = selected.indexOf(name);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setSelectedSave(newSelected);
    
  };

  React.useEffect(() => {
    if (_permission.tab_PayRewardListing_Access !== true) {
      navigate("/dashboard");
    }

    const departmentsData = async () => {
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        await setdepartmentsId(_authUser.employeeInfo.departmentId);
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
        if(tabValue === "1"){
        getAllPendingRewardPointsOfUser(_authUser.employeeInfo.departmentId, "")}
        else if(tabValue === "2"){
          getRedeemHistory(_authUser.employeeInfo.departmentId , "")
        }
      }
      else{
        await setdepartmentsId("");
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setresources(await clsResourcesList.getResourcesDropdownList(""));
      // if(tabValue === "1"){
      //   getAllPendingRewardPointsOfUser("", "")}
      //   else if(tabValue === "2"){
      //     getRedeemHistory("" , "")
      //   }
      
    };
   
  }
  departmentsData();
  }, []);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display:"none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Points</Typography>
        <Typography color="text.primary">Reward Request</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
     
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Pay Reward
          </Typography>
          <InfoPopover  onInput={"Pay Reward"}/>
          
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={4}>
            <Box >
              <Autocomplete
                value={
                  departments?.find(
                    (option) => option.id == departmentsId
                  ) ?? null
                }
                fullWidth
                // sx={{ ml: "15px" }}
                id="department-drop"
                options={departments}
                getOptionLabel={(option) => option.name}
                onChange={async (event, value) => {
                  if(!value){
                   setRewardList([]);
                   setRedeemHistory([]);
                  }
                  setfiltersSelected(true);
                  setdepartmentsId(value?.id ?? "")
                  setResourceId("");
                  setresources(
                    await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                  );
                 
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="department-drop"
                    name="departmentsId"
                    variant="outlined"
                    value={departmentsId}
                    fullWidth
                    sx={{ minWidth: 220 }}
                  />
                )}
              />
            </Box>
            <Box sx={{mr:"20px"}}>
              <Autocomplete
              disabled={!departmentsId || departmentsId === ""}
                value={
                  resources?.find(
                    (option) => option.userId == resourceId 
                  ) ?? null
                }
                sx={{mr:"20px"}}
                fullWidth
                id="resources-drop"
                options={resources}
                getOptionLabel={(option) => option.userName}
                onChange={async (event, value) => {
                  if(!value){
                    debugger
                    if(tabValue == "1"){
                      getAllPendingRewardPointsOfUser(departmentsId,"")
                    }
                    else{
                    getRedeemHistory(departmentsId,"")}
                  }
                  setfiltersSelected(true);
                  setResourceId(value?.userId ?? "")

                  // ResourceId = value?.userId ?? "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="resources-drop"
                    name="resourceId"
                    //label="Departments"
                    variant="outlined"
                    value={resourceId}
                    fullWidth
                    sx={{ minWidth: 220 }}
                  />
                )}
              />
                
            </Box>
            <Button
                onClick={() =>{
                  if(!departmentsId){
                    toast.warn("Please select Department First")
                  }
                  else{
                  if(tabValue === "1"){
                  getAllPendingRewardPointsOfUser(departmentsId, resourceId)}
                  else if(tabValue === "2"){
                    getRedeemHistory(departmentsId, resourceId)
                  }}
                }}
                color="primary"
                variant="contained"
                sx={{ ml: 0.5 }}
              >
                Go
              </Button>
              {filtersSelected && <Button
                            onClick={async () => {
                              debugger
                            
                                setfiltersSelected(false);
                                if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                                setdepartmentsId(departmentsId ?? "")
                                if(tabValue === "1"){
                                  getAllPendingRewardPointsOfUser(departmentsId ?? "", "")}
                                  else if(tabValue === "2"){
                                    getRedeemHistory(departmentsId ?? "" , "")
                                  }
                                }
                                else{
                                  setResourceId("");
                                  await setResourceId("");
                                  setdepartmentsId("");
                                  setRewardList([]);
                                  setRedeemHistory([]);
                                }
                               
                            }}
                            color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                        
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Unpaid Brownie Points" value="1" />
                <Tab label="History" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
            <Box sx={{ width: "100%", typography: "body1" }}>
          <Button variant="outlined" onClick={ApprovePaid}>
            Mark As Paid
          </Button>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                onSelectAllClick={handleSelectAllClick}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rewardList.length}
              />
              <TableBody>
                {stableSort(rewardList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.userId);
                    const labelId: any = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.userId}
                        selected={false}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={ isItemSelected === true}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>

                        <TableCell>{row?.employeeId}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{row?.managerName}</TableCell>
                        <TableCell>{row?.departmentName}</TableCell>
                        <TableCell>{row?.totalEarnedPoint}</TableCell>
                        <TableCell>{row?.totalUnredeemPoint}</TableCell>
                        <TableCell>{row?.pointOfRedeemptionRequest}</TableCell>
                        <TableCell>{row?.valueOfPointToRedeem}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25]}
            component="div"
            count={rewardList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          
            labelDisplayedRows={() => `Total: ${rewardList?.length}  Page ${page + 1} of ${pageCount}`}
          />
        </Box>
            </TabPanel>

            <TabPanel value="2" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Emp Id </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Manager</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Total Point Paid</TableCell>
                      <TableCell>Value Of Redeemed Point</TableCell>
                      <TableCell>Paid On</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(redeemHistory, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((redeemDetail, idx) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{redeemDetail?.employeeId}</TableCell>
                            <TableCell>
                              {redeemDetail?.name}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.manager}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.department}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.pointToRedeem}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.valueOfPointToRedeem}
                            </TableCell>
                            <TableCell>
                            {`${moment(redeemDetail?.paidOn).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {redeemHistory.length === 0 && (
                  <NotFound NotfoundText="No Result Found" />
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={redeemHistory.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${redeemHistory?.length}  Page ${page + 1} of ${pageCount1}`}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
}
