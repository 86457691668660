import { Alert, Autocomplete, Avatar, Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { projectManagementServive } from "../../service/project-management-service";
import { toast } from "material-react-toastify";
import React from "react";
import toDo from "../../../src/assests/images/toDo.svg"
import { Department } from "../../Interfaces/IDepartment";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import { useSelector } from "react-redux";
import { clsDepartmentList } from "../../service/common-services/department";
import { masterDataService } from "../../service/master-service/master-data-service";
import { Iproject } from "../../Interfaces/IProject";
import { milestoneTreeService } from "../../service/Reports/milestonereportTree";
import { commonFunctions } from "../../service/common-services/common-functions";
import { deepOrange } from "@mui/material/colors";
import Stepper from '@mui/material/Stepper';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useNavigate } from "react-router-dom";

const steps = [{ label: 'Please select the Departments' }, { label: 'Select Projects' }, { label: ' Select Milestones' }, { label: ' Select Sprints' }];
export default function SprintReport() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [stepper, setStepper] = useState<any>('')
  const [disabledDepartments, setdisabledDepartments] = useState(false);
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [departmentsId, setdepartmentsId] = useState("");
  const [departments, setDepartments] = useState<Department[]>([]);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [mileStonesList, setMileStonesList] = useState<any>([]);
  const [mileStonesId, setMileStonesId] = useState("");
  const [projects, setProjects] = React.useState<Iproject[]>([]);
  const [projectId, setProjectId] = useState("");
  const [allSprintList, setAllSprintList] = React.useState<any>([]);
  const [sprintId, setSprintId] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [sprintReport, setSprintReport] = useState<any>(null);
  const navigate = useNavigate();
  const _permission = useSelector(
    (state: any) => state.ReportPermissionApp.ReportPermission
  );


  useEffect(() => {
    if (_permission.page_SprintReport_Access !== true) {
      navigate("/dashboard");
    }
    const departmentsData = async () => {
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        //   if (
        //     _permission.action_ManageUsersListing_AllDepartments_Access === false
        //   ) {
        //     setdisabledDepartments(true);
        //   }

        await setdepartmentsId(_authUser.employeeInfo.departmentId);
        await setStepper(0);
        await setDepartments(
          await clsDepartmentList.getDepartmentDropdownList()
        );

      } else {

        await setdepartmentsId("")
        await setDepartments(
          await clsDepartmentList.getDepartmentDropdownList()
        );

      }
    };
    departmentsData();
    getActiveProjects(_authUser.employeeInfo.departmentId);
  }, []);


  const getActiveProjects = async (id: any) => {

    await masterDataService.getProjectByDeptartmentId(id).then(async (response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setProjects(response?.data ? response?.data : []);
      }
    });
  };

  const getMileStoneData = async (id: any,) => {
    try {
      await projectManagementServive
        .getMilestonesWithoutSprint(id).then((response: any) => {
          if (response.data.isError) {
          } else {
            setMileStonesList(response?.data)
          }
        });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getSprintsBasedOnMilestoneId = async (id: any) => {
    await milestoneTreeService.getSprintonbasisofMilestoneId(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setAllSprintList(response?.data);
      }
    });
  };

  const getSprintReport = async (
    sprintsId: string) => {
    let values = {
      sprintId: sprintsId,
    }
    try {
      await projectManagementServive.getSprintReport(values).then(async (response: any) => {
        setToggleLoader(true);
        if (response.data.isError) {
          toast.error("Something went wrong...");
          setToggleLoader(false);
        } else {
          setSprintReport(response?.data ? response?.data : null);
          setToggleLoader(false);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }
  };

  return (
    <Box>
      {toggleLoader && (<CircularProgress sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
      <Paper elevation={0} sx={{ p: 2 }}>
        <Box sx={{ marginBottom: "20px", fontSize: "24px", fontWeight: "700" }}>Sprint Report</Box>

        <Grid
          container
          spacing={2}
        >
          <Grid item xs={2.5} >
            <Autocomplete
              disabled={_authUser.typeOfUser === eTypeOfUser.Manager}
              value={
                departments?.find(
                  (option) => option.id === departmentsId
                ) ?? null
              }
              id="outlined-basic"
              options={departments}
              getOptionLabel={(option) => option.name}

              onChange={async (event, value) => {
                if (!value) {
                  setSprintId("");
                  setMileStonesId("")
                  setdepartmentsId("")
                  setProjectId("")
                  setStepper("")
                }
                else {
                  setfiltersSelected(true)
                  setdepartmentsId(value?.id ?? "");
                  await getActiveProjects(value?.id ?? "");



                  setStepper(0)


                }
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}

                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  value={departmentsId}
                  fullWidth
                  sx={{ minWidth: 200 }}
                />
              )}
            />

          </Grid>
          <Grid item xs={2.5} >
            <Autocomplete
              disabled={!departmentsId}
              value={
                projects?.find(
                  (option) => option.id === projectId
                ) ?? null
              }
              id="outlined-basic"
              options={projects}
              getOptionLabel={(option) => option.projectName}

              onChange={async (event, value) => {
                if (!value) {
                  setSprintId("");
                  setMileStonesId("")
                  setProjectId("")
                  setStepper(0)
                }
                else {
                  setfiltersSelected(true)
                  setProjectId(value?.id ?? "");
                  await getMileStoneData(value?.id ?? "");

                  setStepper(1)
                }
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}

                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  value={projectId}
                  placeholder="Select Projects"
                  fullWidth
                  sx={{ minWidth: 200 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5} >
            <Autocomplete
              disabled={!projectId}
              value={
                mileStonesList?.find(
                  (option: any) => option.id === mileStonesId
                ) ?? null
              }
              id="outlined-basic"
              options={mileStonesList}
              getOptionLabel={(option) => option.milestoneName}

              onChange={async (event, value) => {
                if (!value) {
                  setSprintId("");
                  setMileStonesId("")
                  setStepper(1)
                }
                else {
                  setfiltersSelected(true)
                  setMileStonesId(value?.id ?? "");
                  await getSprintsBasedOnMilestoneId(value?.id ?? "");

                  setStepper(2)
                }
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={disabledDepartments}
                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  value={mileStonesId}
                  placeholder="Select Milestones"
                  fullWidth
                  sx={{ minWidth: 200 }}
                />
              )}
            />

          </Grid>
          <Grid item xs={2.5} >
            <Autocomplete
              disabled={!mileStonesId}
              value={
                allSprintList?.find(
                  (option: any) => option.sprintId === sprintId
                ) ?? null
              }
              id="outlined-basic"
              options={allSprintList}
              getOptionLabel={(option) => option.sprintName}

              onChange={async (event, value) => {
                if (!value) {
                  setSprintId("");
                  setStepper(2)
                }
                else {
                  setfiltersSelected(true)
                  setSprintId(value?.sprintId ?? "");
                  await getSprintReport(value?.sprintId ?? "");

                  setStepper(4)
                }
                setfiltersSelected(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={disabledDepartments}
                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  value={sprintId}
                  placeholder="Select Sprints"
                  fullWidth
                  sx={{ minWidth: 200 }}
                />
              )}
            />
          </Grid>
          {filtersSelected && (
            <Grid item xs={2} >  <Button
              onClick={async () => {
                if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                  setfiltersSelected(false);
                  setdepartmentsId(_authUser.employeeInfo.departmentId);
                  setProjectId("");
                  setMileStonesId("");
                  setSprintId("");
                  setSprintReport(null);
                  setStepper(0)

                }
                else {
                  setfiltersSelected(false);
                  setdepartmentsId("");
                  setProjectId("");
                  setMileStonesId("");
                  setSprintId("");
                  setSprintReport(null)
                  setStepper("")
                }

              }}
              color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>
            </Grid>)}
        </Grid>
        {!sprintId && (
          <Box sx={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", width: '100%', mt: 4, border: "1px solid #f1f1f1", borderRadius: "8px", padding: "16px", }}>

            <Box sx={{ marginBottom: "20px", fontSize: "18px", fontWeight: "700" }}>Guide</Box>


            <Stepper alternativeLabel activeStep={stepper}>
              {steps.map((item, index) => (
                <Step key={index} >
                  <StepButton color="inherit">
                    {item.label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

          </Box>
        )}

        {sprintId && sprintReport != null ? (
          <Box sx={{ paddingBlock: "30px", height: "380px", overflowY: "auto" }}>
            <Box>
              <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                Retro Comments
                <Box sx={{ display: "flex", justifyContent: "space-between", border: "1px solid #1DA7FF", padding: "4px 12px", borderRadius: "4px", background: "rgb(255, 255, 255)" }}>
                  <Box sx={{ fontSize: "1rem", height: "30px", display: "flex", alignItems: "center", }}>
                  {sprintReport?.comment ? (
        sprintReport.comment
      ) : (
        "No retro comment found"
      )}
                  </Box>
                </Box>
              </Typography>

              <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                Completed ({sprintReport?.totalCompletedCount || 0})
              </Typography>
              {sprintReport?.completedTickets?.map((item: any, idx: any) => (
                <Box sx={{ display: "flex", justifyContent: "space-between", border: "1px solid rgba(0, 0, 0, 0.12)", padding: "4px 12px", borderRadius: "4px", background: "rgb(255, 255, 255)" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <Box sx={{ color: "rgb(29, 167, 255)", fontSize: "0.8125rem" }}>
                      <img src={toDo} alt="icon" style={{ marginRight: "6px" }} />
                      {item?.ticketNumber}
                    </Box>
                    <Typography variant="h6" component="h6" sx={{ fontSize: "14px" }}>
                      {item?.summary}
                    </Typography>
                  </Box>
                  <Avatar
                    {...commonFunctions.stringAvatar(item?.assigneeName)}
                    sx={{ bgcolor: deepOrange[500], fontSize: "12px ", width: "30px", height:"30px" }}
                    src={`${process.env.REACT_APP_SERVER_URL}${item?.assigneeName}`}
                  />
                </Box>))}
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
                Spill Over ({sprintReport?.totalSpillTicketIdsCount || 0})
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {sprintReport?.ticketDetails?.map((item: any, idx: any) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", border: "1px solid rgba(0, 0, 0, 0.12)", padding: "4px 12px", borderRadius: "4px", background: "rgb(255, 255, 255)" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                      <Box sx={{ color: "rgb(29, 167, 255)", fontSize: "0.8125rem" }}>
                        <img src={toDo} alt="icon" style={{ marginRight: "6px" }} />
                        {item?.ticketNumber}
                      </Box>
                      <Typography variant="h6" component="h6" sx={{ fontSize: "14px" }}>
                        {item?.summary}
                      </Typography>
                    </Box>
                    <Avatar
                      {...commonFunctions.stringAvatar(item?.assigneeName)}
                      sx={{ bgcolor: deepOrange[500], fontSize: "12px ", width: "30px", height:"30px" }}
                      src={`${process.env.REACT_APP_SERVER_URL}${item?.assigneeName}`}
                    />
                  </Box>))}
              </Box>
            </Box>

          </Box>
        ) :
          sprintId && sprintReport == null && stepper == 4 ? (
            <Typography variant="h6" component="h6" sx={{ textAlign: "center", mt: 3 }}>
              No Record Found
            </Typography>)
            :
            (
              null
            )
        }


      </Paper>

    </Box>
  );
}


