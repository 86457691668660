import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import theme from "../../theme/theme";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { IFiles } from "../users/userModel";
import { apiUrl } from "../../service/common-services/apiURL";
import { inventoryService } from "../../service/Inventory/inventoryService";
import{bannerUploadServices} from "./../../service/bannerUpload-services"
import { toast } from "material-react-toastify";
import FileUploadPNG from "../../components/styledComponent/FileUploadPNG";

const additem = {
    Uploaddetail: {
        width: "100%",
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',

        heading: {
            color: '#232360',
            fontSize: '14px',
            fontWeight: '400',
            margin: '10px 0',
            wordWrap: 'break-word',
        },

        Button: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '18px',
            paddingLeft: '18px',
        }
    },

    dateOFPurchase: {
        maxWidth: 'auto',
        " & .css-9l522a-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
            color: '#757575'
        },
        "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main,
        },
        "& .css-1ig1lwk-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: '#EEE',
            color: '#757575'
        }
    },

    Warantydetail: {
        Fromto: {
            maxWidth: '160px',
            "& .MuiSvgIcon-root": {
                fill: theme.palette.primary.main,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: '#EEE',
                color: '#757575'
            },
        },
    }
}




export default function BannerUpload() {
    let IfileObj: IFiles = {
        itemType: "",
        name: "",
        fileURL: "",
        fileName: "",
        file: null,
    };
    let objDocument: IFiles[] = [];
    const [formValues, setFormValues] = useState<IFiles[]>(objDocument);
    useEffect(() => {
        getProjectDetails();
    }, []);
    const getProjectDetails = async () => {
        try {
          await bannerUploadServices
            .getBannerImage("Seasia")
            .then(async (response: any) => {
              if (response.data.isError) {
                toast.warning("Error while fetching data");
              } else {
                if(response?.data?.path){
                let documentData = response?.data?.path;
                console.log(documentData, "documentData")
                var document_array: any[] = [];
                  document_array.push({
                    name: response?.data?.path,
                    fileURL: response?.data?.path,
                    fileName: response?.data?.companyName,
                    file: null,
                    itemType: "",
                  });
               
                setFormValues(document_array);
              }}
            });
        } catch (ex: any) {
          ex.data.Error.map((err: any, idx: any) => {
            toast.warning(err);
          });
        }
      };
    async function updateItemDetails() {
        try {
            let dataabc:any = "";
            await Promise.all(formValues.map(async function (item: any) {
                let abc = item?.fileURL.substring(0, 9);
                if (abc == "blob:http") {
                    let formData = new FormData();
                    formData.append("File", item.file);
                    formData.append("FileFor", "dkf");
                    try {
                        const response = await inventoryService.uploadMultipleImages(formData);
                        if (response.data.isError) {
                            toast.error("Facing issue while Document Upload " + item.name);
                        } else {
                            debugger
                            dataabc += response.data + " ";
                        }
                    } catch (ex: any) {
                        toast.warn(ex);
                    }
                } else {
                    debugger
                    dataabc += item?.fileURL + " ";
                }

            }));
            try {
                let abc ={
                    companyName:"Seasia",
                    path:dataabc
                }
                await bannerUploadServices.addUpdateBannerImages(abc).then((response: any) => {
                    if (response.data.isError) {
                        toast.warn(response.data.isError);
                    }
                    else {
                        toast.success("Image uploaded Successfully")
                        getProjectDetails();
                    }
                });
            } catch (ex: any) {
                toast.warn(ex);
            }

        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            return(
                toast.warn(err)
            )
            });
        }
    }

    console.log(formValues)
    let removeFormFields = (i: any) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };
    const triggerDocumentFile = (fileList: FileList, index: number) => {
        IfileObj.itemType = "";
        IfileObj.name = "";
        IfileObj.fileURL = "";
        IfileObj.file = null;
        IfileObj.fileURL = (URL.createObjectURL(fileList[0]));
        IfileObj.file = (fileList[0]);;
        let fObj = [...formValues];
        fObj.push(IfileObj);
        setFormValues([IfileObj]);
    };

    return (

        <Box sx={{ width: '100%', typography: 'body1', }}>
            <Typography sx={{ padding: "0 0 15px 0" }}>
                {"Upload Banner Image"}
            </Typography>
            <Paper sx={{ padding: '24px 16px' }}>
                <Box paddingY={2} marginRight={'14px'} >
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={12}>
                            <Box sx={additem.Uploaddetail}>
                                {/* <Typography sx={additem.Uploaddetail.heading}>Upload Product Images</Typography> */}
                                <Box sx={additem.Uploaddetail.Button}>
                                 <FileUploadPNG
                                        selectFile={triggerDocumentFile}
                                        index={1}
                                    />
                                </Box>
                            </Box>
                            <Stack sx={{ mt: 2, mb: 4 }} direction={'row'} flexWrap={'wrap'} justifyContent={'start'} alignItems={'center'}>
                                {formValues?.map((element: any, i: any) => (
                                    <Box key={i} sx={{
                                        '&:hover': {
                                            border: '1px solid #1DA7FF',
                                        },
                                        width: '100px', height: '100px', margin: '3px 10px', position: 'relative', border: '1px solid #EEE', borderRadius: '5px'
                                    }}>
                                        {element?.file ? (
                                            <img
                                                width={'100%'} height={'100%'}
                                                src={element?.fileURL}
                                                alt=""
                                                onClick={() => window.open(element.fileURL, '_blank')}
                                            />
                                        ) : (
                                            <img
                                                width={'100%'} height={'100%'}
                                                src={apiUrl.getImageURL() + "/" + element?.fileURL}
                                                alt=""
                                                onClick={() => {
                                                    const generatedURL = apiUrl.getImageURL() + "/" + element?.fileURL;
                                                    window.open(generatedURL, '_blank');
                                                }}
                                            />
                                        )}
                                        <IconButton
                                            onClick={() => removeFormFields(i)}
                                            size="small"
                                            // style={{ position: 'absolute', top: 0, right: 0,color: 'black' }}
                                            style={{ justifyContent: 'right', position: 'absolute', top: '-6px', right: '-6px', background: '#fff', filter: 'blur', opacity: '0.5' }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box paddingTop={'38px'} marginRight={'14px'} textAlign={'end'}>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => {
                            updateItemDetails()
                        }}
                    >
                        Upload Image
                    </Button>
                </Box>
            </Paper>
        </Box>

    )
}
