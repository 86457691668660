import { useEffect, useState, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import * as Icons from "../../assests/icons/icons";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, IconButton, MenuItem, Modal, TableCell, TablePagination, Tooltip, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import SearchBar from "../../components/styledComponent/SearchBar";
import StyledAcceptButton from "../../components/styledComponent/StyledAcceptButton";
import StyledRejectButton from "../../components/styledComponent/StyledRejectButton";
import moment from "moment";
import Box from "@mui/material/Box";
import { ticketManagementService } from "../../service/ticket-management-service";
import { toast } from "material-react-toastify";
import React from "react";
import { useSelector } from "react-redux";
import { projectManagementServive } from "../../service/project-management-service";
import theme from "../../theme/theme";
import { Formik, getIn } from "formik";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { logStatusFilter } from "../../Enum/eLeaveStatus";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "react-rainbow-components";
import { userManagementServive } from "../../service/user-management-service";
import { clsResourcesList } from "../../service/common-services/resources";
import getDate from "../../components/GetDateWithSimpletime";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const toggleloadersStyle={
  position: "fixed",
   left: "50%",
   right: "50%",
   top: "30%", 
  bottom: "70%", 
  transform: "translate(-50%, -50%)" 
 };

interface Data {
  projectDescription: string;
  projectName: string;
  projectOwnerName: string;
  keyWords: string;
  clientName: string;
  departmentId: string;
  departmentName: string;
  costingType: string;
  status: string;
  id: string;
  pin: string;
  createdDate: string;
  projectKey: string;
  projectOwnerId: string;
  srNo: string;
}

const LongText = (props: {
  content?: any;
  limit?: any;
}) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);
  if (props.content.length <= props.limit) {
    // there is nothing more to show
    return <div>{props.content}</div>
  }
  if (showAll) {
    // We show the extended text and a link to reduce it
    return <Box sx={{ whiteSpace: "break-spaces" }}>
      {props.content}
      <Tooltip title="Show Less">
        <Button variant="text" size="small" onClick={showLess}>less...</Button></Tooltip>
    </Box>
  }
  // In the final case, we show a text with ellipsis and a `Read more` button
  const toShow = props.content ? props.content.substring(0, props.limit) : "";
  return <Box sx={{ whiteSpace: "break-spaces", width: "200px" }}>
    {toShow}
    <Tooltip title="Show More">
      <Button variant="text" size="small" onClick={showMore}>...</Button></Tooltip>
  </Box>
}

export default function LogSheet() {
  const navigation = useNavigate();
  const navigate = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const [workLogList, setWorkLogList] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [projects, setProjects] = useState<any[]>([]);
  const { id } = useParams();
  const { id1 } = useParams();
  const [visible, setVisible] = useState(false);
  const [selectedProjectId, setProjectId] = useState<any>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageCount1 = Math.ceil(workLogList.filter((a: any) => a.status === 5 || a.status === 7).length / rowsPerPage1);
  const pageCount = Math.ceil(workLogList.filter((a: any) => a.status === 6).length / rowsPerPage);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [page1, setPage1] = React.useState(0);
  const [statusFilter, setStatusFilter] = React.useState("All");
  const [statusFilterValue, setStatusFilterValue] = React.useState<any>(8);
  const logstatusFilter = Object.keys(logStatusFilter);
  const [iconDisable, setIconDisable] = React.useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState<any>("");
  const [selectedDate, setSelectedDate] = React.useState<any>("");
  const [resources, setResources] = useState<any>([]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - workLogList.filter((a: any) => a.status === 6).length) : 0;


  ///////Accept Reject Multiple Offline hours programmming//////////////
  const [rowCount, setRowCount] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);

  };
  const [tabValue, setTabValue] = React.useState("1");
  const [searchValue, setSearchValue] = useState("");
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  console.log(searchValue,"searchValue");
  const triggerSearch = async (newValue: string) => { 
    setSearchValue(newValue)
    getAllTicketWorkLog(selectedProjectId ?selectedProjectId:"",newValue,statusFilterValue?statusFilterValue:6,selectedResourceId,selectedDate);
    setPage(0);
    setPage1(0);
    setVisible(true);
  };
  const ApproveReject = async (status: any) => {
    if (selected.length == 0) {
      return toast.warning("Please select before update the status");
    }
    let value = {
      ids: selected,
      status: logStatus,
      comment: comment
    };
    try {
      await projectManagementServive.ApproveReject(value).then((response: any) => {
        if (response.data.isError) {
        } else {
          if (logStatus === "Reject") {
            toast.warn("Hours Rejected successfully")
          }
          else {
            toast.success("Hours Approved successfully")
          }
          setOpen(false);
          setcomment("");
          setlogStatus("");
          getAllTicketWorkLog(selectedProjectId ?selectedProjectId:"",searchValue,6,selectedResourceId,selectedDate);
          setSelected([]);
          setRowCount(0);
          setIconDisable(false);
        }
      });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any) => {
        toast.warning(err);
        setSelected([]);
        setlogStatus("");
        setRowCount(0);
      });
    }
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      var all = getLogSheetList()?.map((n: any) => n.id)
      setRowCount(all.length);
      setSelected(all);
      return;
    } {
      setSelected([]);
      setRowCount(0);
    }
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    id: string) => {
    if (event.target.checked) {
      setRowCount(rowCount + 1);
      setSelected([...selected, id]);
    } else {
      setRowCount(rowCount - 1);
      setSelected([...selected].filter(x => x != id));
    }
  };

  // const getAllTicketWorkLog2 = useCallback((projectId = "",searchValue:any,status:any,uId:any,date:any) => {
  //   debugger
  //   setToggleLoader(true);
  //   setPage(0);
  //   setPage1(0);
    
  //     try{
  //     ticketManagementService
  //       .getOfflineWorkHours({
  //         projectId: projectId ?? id ,
  //         taskType: 2,
  //         search: searchValue,
  //         status:status,
  //         userId:uId,
  //         workingday:date
  //       })
  //       .then((response: any) => {
  //         if (response.data.isError) {
  //           setToggleLoader(false)
  //         } else {
  //           setWorkLogList(response?.data);
  //           setLoading(false);
  //           setToggleLoader(false);
  //           setRowCount(0);
  //         }
  //       });
  //     } catch (ex: any) {
  //       ex.data.Error?.map((err: any, idx: any) => {
  //           toast.warning(err);
  //           setToggleLoader(false)
  //       });}
    
  // }, [searchValue]);

  const getAllTicketWorkLog = useCallback((projectId = "",searchValue:any,status:any,uId:any,date:any) => {
    
    setToggleLoader(true);
    setPage(0);
    setPage1(0);
   
      try{
      ticketManagementService
        .getOfflineWorkHours({
          projectId: projectId ?? id,
          taskType: 2,
          search: searchValue,
          status:status,
          userId:uId,
          workingday:date
        })
        .then((response: any) => {
          if (response.data.isError) {
            setToggleLoader(false)
          } else {
            setWorkLogList(response?.data);
            setLoading(false);
            setToggleLoader(false);
            setRowCount(0);
          }
        });
      } catch (ex: any) {
        ex.data.Error?.map((err: any, idx: any) => {
            toast.warning(err);
            setToggleLoader(false)
        });}
    
  }, [id,searchValue]);


  const getAllTicketWorkLog1 = useCallback((projectId = "",searchValue:any,status:any,uId:any,date:any) => {
    debugger
    setToggleLoader(true);
    setPage(0);
    setPage1(0);
    if (id) {
      try{
      ticketManagementService
        .getOfflineWorkHours({
          projectId: projectId ?? id,
          taskType: 2,
          search: searchValue,
          status:status,
          userId:uId,
          workingday:date
        })
        .then((response: any) => {
          if (response.data.isError) {
            setToggleLoader(false)
          } else {
            setWorkLogList(response?.data);
            setLoading(false);
            setToggleLoader(false);
            setRowCount(0);
          }
        });
      } catch (ex: any) {
        ex.data.Error?.map((err: any, idx: any) => {
            toast.warning(err);
            setToggleLoader(false)
        });}
    }
  }, [id,searchValue]);



  const handleSaveWorkHour = (id: any, desc: any, status: any) => {
    if (id) {
      setLoading(true);
      ticketManagementService
        .updateOfflineWorkHoursApprove({
          id: id,
          status: status,
          comment: desc,
        })
        .then(async (response: any) => {
          if (response?.data?.isError) {
            toast.error("Something Went Wrong, Please try again.");
            setLoading(false);
          } else if (status === "Approved") {
            await getAllTicketWorkLog(selectedProjectId ?selectedProjectId:"",searchValue,6,selectedResourceId,selectedDate);
            toast.success("Hours Approved successfully");
          } else {
            await getAllTicketWorkLog(selectedProjectId ?selectedProjectId:"",searchValue,6,selectedResourceId,selectedDate);
            toast.warn("Hours Rejected successfully");
            setlogStatus("");
          }
          setOpen(false);
          setIconDisable(false);
          setcomment("");
          setlogStatus("");
          setSelectedId("");
        });
    }
  };

  useEffect(() => {
    debugger
    if (_permission.action_MyProjectsListing_LogSheet_Access !== true) {
      navigation('/dashboard');
    }
    setProjectId(id);
    setLoading(true);
    getAllTicketWorkLog(id ?? "","",6,"","");
    if(id){
      getResourceOnbasisOfProj(id);
    }
   
    if(id != null)
      {
        setVisible(true);
      }
    if(id == undefined)
      {
        setVisible(false)
      }
      
   
     
   
    //getAllTicketWorkLog2("","",6,"","");
    getProjectDetails();
    
  //  getAllTicketWorkLog1("","",6,"","");
   // if(id != undefined)
     // {
       
     // }     
  }, [id1]);

  function removeTagsAndAmp(str: any) {
    // Remove HTML tags
    str = str?.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str?.replace(/&amp;/g, '&');
    str = str?.replace(/(&nbsp;)/g, ''); return str;

  }
  const handleTextChange = (e: any, index: any) => {

    setWorkLogList(() =>
      workLogList?.map((a: any, idx: number) => {
        let returnValue = { ...a };
        if (idx == index) {
          returnValue["description"] = e?.target?.value;
        }
        return returnValue;
      })
    );
  };
  const [comment, setcomment] = React.useState("");
  const getProjectDetails = async () => {
    let values = {
      // department: departmentId ?? "",
      status: "Active",
      module: 13,
      isMyProject:true,
    };
    try {
    await projectManagementServive
      .getDropdownData(values)
      .then((response: any) => {
        setProjects(response.data);
      });
    } catch (ex: any) {
      ex.data.Error?.map((err: any, idx: any) => {
          toast.warning(err);
      });
  }
  };

  const getLogSheetList = () => {
    return workLogList
      ?.filter((a: any) => a.status === 6).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
  }
  
  const getLogSheetList1 = () => {
    return workLogList
      ?.filter((a: any) => a.status === 7 || a.status === 5).slice(
        page1 * rowsPerPage1,
        page1 * rowsPerPage1 + rowsPerPage1
      )
  }

  const [selectedId, setSelectedId] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [logStatus, setlogStatus] = React.useState("");
  const handleClose = () => {
    setOpen(false);
    setlogStatus("");
    setSelectedId("");
    setcomment("");
    setIconDisable(false);
  };
  const handleClickOpen = (status: any) => {
    setIconDisable(true);
    if (selected.length === 0) {
      return toast.warning("Please select before update the status",{
        onOpen :()=> setIconDisable(true),
        onClose :()=> setIconDisable(false),
      });
    }
    setOpen(true);
  }

  const getResourceOnbasisOfProj = async (pid:any) => {
    try {
      let prm={
        projectId: pid,
      }
      await userManagementServive
        .getAllUsersByProjectId(prm)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            debugger
             if(response.data){
            let keyChange = response?.data.map((item:any)=>({
              userId:item.id,
              userName: item.name
            }))
            console.log(keyChange,"keyChangekeyChange")
            setResources(keyChange)}
            else{
              setResources([])
            }
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };



  const fieldValidationSchema = Yup.object().shape({
    comment: (logStatus == "Reject" ?
      Yup.string().required("Comment is Required") : Yup.string()),
  })



  return (
    <>
    {toggleLoader && (<CircularProgress sx={toggleloadersStyle} />)}
      <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
      <Stack direction="row">
            <Typography variant="h5" component="h2" sx={{ fontWeight: 600, mr: 3 }}>
              Logsheet
            </Typography>
            </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3 ,mt:"10px"}}
        >
          
          
            <Stack direction="row">
            <Autocomplete
              value={
                projects?.find(
                  (option: any) => option.id == selectedProjectId
                ) ?? null
              }
              id="outlined-basic"
              options={projects}
              getOptionLabel={(option) => option.name ?? id}
              onChange={async (event, value) => {
                setSelected([]);
                setRowCount(0);
                setVisible(true);
                setProjectId(value?.id ?? "");
                if (value?.id == null || "") {
                  debugger
                  getAllTicketWorkLog("","",tabValue==="2"?statusFilterValue:6,"",selectedDate);
                  setResources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                  if(selectedDate=="" && selectedResourceId==""&& searchValue==""){
                    debugger
                    setVisible(false)
                    console.log(visible,"setVisible===");   
                  }
                }
                else { getAllTicketWorkLog(value?.id,"",tabValue==="2"?"":6,"",selectedDate)
                getResourceOnbasisOfProj(value?.id??id)
               }
               setSelectedResourceId("");
               setSelectedDate("")
               setStatusFilter("All")
               setStatusFilterValue("")
               setSearchValue("");
               
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectedProjectId== ""||selectedProjectId== null ?"Select Project":""}
                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  fullWidth
                  sx={{ minWidth: 220 }}
                />
              )}
            />

             <Autocomplete
              disabled={!selectedProjectId || selectedProjectId == ""}
              value={
                resources?.find(
                  (option: any) => option.userId == selectedResourceId
                ) ?? null
              }
              id="outlined-basic"
              options={resources}
              getOptionLabel={(option) => option.userName}
              onChange={async (event, value) => {
              debugger
              getResourceOnbasisOfProj(selectedProjectId);
              setSelectedResourceId(value?.userId ??"")
              getAllTicketWorkLog(selectedProjectId,searchValue,tabValue==="2"?statusFilterValue:6,value?.userId ??"",selectedDate);
              setVisible(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectedResourceId== ""||selectedResourceId== null ?"Select Resource":""}
                  id="outlined-basic"
                  name="element.name"
                  variant="outlined"
                  fullWidth
                  sx={{ minWidth: 220 ,ml:"10px"}}
                />
              )}
            />
            <Stack sx={{ml:"20px",marginTop:"2px"}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // disablePast
                  style={{ width: '70%' }}
                  borderRadius="semi-square"
                  minDate={moment().subtract(1, "months").toDate()}
                  maxDate={new Date()}
                  placeholder="Select Date"
                  value={selectedDate ?selectedDate : null}
                 // value={selectedDate ?selectedDate :new Date()}
                  onChange={(newValue: any) => {
                  let newDate = getDate(newValue);
                    setSelectedDate(newDate);
                    getAllTicketWorkLog(selectedProjectId,searchValue,tabValue==="2"?statusFilterValue:6,selectedResourceId,newDate);
                    setVisible(true);
                  } } 
                  />
              </LocalizationProvider> </Stack>
              <Stack direction="row" 
              sx={{ml:"-61px",mt:"4px"}}
              spacing={2}>
            <SearchBar
              value={searchValue}
              callBackFunction={triggerSearch}
            />
          </Stack>
          <Stack sx={{ml:"1px",marginTop:"4px"}}>    {visible && (
                    <Button
                      variant="contained"
                      //color="info"
                     
                      sx={{
                        height: " fit-content",
                        paddingBlock: "5px",
                        ml:"10px",
                        "background-color": "#FFC409",
                        

                      }}
                      onClick={async (event) => {
                        setProjectId("");
                        setVisible(false);
                      //getResourceOnbasisOfProj(id);
                        setSelectedResourceId("")
                        setSelectedDate("");
                        setSearchValue("");
                        navigate(`/project/logsheet`);
                        setStatusFilterValue(8);
                        getAllTicketWorkLog("","",tabValue==="2"?8:6,"","");                       
                      //getAllTicketWorkLog1("","",tabValue==="2"?statusFilterValue:6,"",selectedDate);
                        setResources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                        setStatusFilter("All");
                     // 
                      }}
                    >
                      Clear
                    </Button>
                  )}</Stack>
          </Stack>

        
        </Stack>

        {/* <ListItemIcon onClick={() => { (handleClickOpen("Approved")); setlogStatus("Approved") }}>
          <Icons.TickCircle />
        </ListItemIcon>
        <ListItemIcon onClick={() => { handleClickOpen("Reject"); setlogStatus("Reject") }}>
          <Icons.CrossCircle />
        </ListItemIcon>
        <Divider /> */}

        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Pending" value="1"  onClick={()=>{getAllTicketWorkLog(selectedProjectId ?selectedProjectId:id,"",6,"","");
               // setSelectedResourceId("");
                 setSearchValue(""); setSelectedDate(""); 
                //getResourceOnbasisOfProj(id);
                setStatusFilterValue("")}}/>
                <Tab label="History" value="2" onClick={()=>{getAllTicketWorkLog(selectedProjectId ?selectedProjectId:id,"",8,"","");
                 setSearchValue(""); setSelectedDate("");
                 // setSelectedResourceId("");
                  //getResourceOnbasisOfProj(id);
                   setStatusFilterValue(8);}}/>
                {tabValue=="2" && (               
                <Box sx={{ position: "absolute", right: '0', top: '0' , width: "150px"}} >
                          <TextField
                            id="Statusfilter"
                            variant="outlined"                         
                            select
                            value={statusFilter}                          
                            fullWidth
                          >
                            {logstatusFilter?.map((key, idx) => (
                              <MenuItem
                                key={key}
                                value={key}
                                onClick={(event) => {
                                  let abc = key==="Approved"?5 :key==="Rejected" ?7 :key==="All"?"":""
                                  setStatusFilter(key);
                                  getAllTicketWorkLog(selectedProjectId ?selectedProjectId:id,"",abc,selectedResourceId,selectedDate)
                                  setStatusFilterValue(abc)
                                  setSearchValue("")
                                  setVisible(true);
                                 // handleChangeLeaveStatusFilter(key)
                                }}
                              >
                                {key}
                              </MenuItem>
                            ))}
                          </TextField>                       
                </Box>  
                 )}
              </TabList>          
               
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <Box>
              <Stack direction={'row'} spacing={2}>
              <Tooltip title="Approve the Selected Hours">
                <IconButton disabled={!!iconDisable} onClick={() => { (handleClickOpen("Approved")); setlogStatus("Approved") }}>
                  <Icons.TickCircle />
                </IconButton></Tooltip>
                <Tooltip title="Reject the Selected Hours">
                <IconButton disabled={!!iconDisable} onClick={() => { handleClickOpen("Reject"); setlogStatus("Reject") }}>
                  <Icons.CrossCircle />
                </IconButton></Tooltip>
                </Stack>
              


                {/* <ListItemIcon onClick={() => { (handleClickOpen("Approved")); setlogStatus("Approved") }}>
                  <Icons.TickCircle />
                </ListItemIcon>
                <ListItemIcon onClick={() => { handleClickOpen("Reject"); setlogStatus("Reject") }}>
                  <Icons.CrossCircle />
                </ListItemIcon> */}
              </Box>


              <TableContainer component={Paper} elevation={0}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead >
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={rowCount > 0 && rowCount < getLogSheetList()?.length}
                          checked={rowCount > 0 && getLogSheetList()?.length == rowCount}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                      <TableCell>Sr. No</TableCell>
                      <TableCell align="left">Project Name</TableCell>
                      <TableCell align="left">Employee</TableCell>
                      
                      <TableCell align="left">Ticket Number</TableCell>
                      <TableCell align="left">Title</TableCell>
                      
                      <TableCell align="left">Description </TableCell>
                      <TableCell align="left">Hours</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Comment</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/*  */}
                    {!loading ? (
                      workLogList.filter((a: any) => a.status === 6).length > 0 ? (
                        getLogSheetList()
                          ?.map((log: any, index: number) => {
                            const isItemSelected = isSelected(log.id);
                            const labelId: any = `enhanced-table-checkbox-${index}`;
                            const sno = page * rowsPerPage + index + 1;
                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, log.id)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={log.id}
                                selected={false}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={log.status === 6 ? isItemSelected : true}
                                    // disabled={log.status === 1 ? false : true}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                                <StyledTableCell component="th" scope="row">
                                {sno}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.projectName}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.createdBy}
                                </StyledTableCell>
                                
                                <StyledTableCell align="left">
                                  {" "}
                                  {log?.ticketNumber}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <LongText content={log?.title ?? ""} limit={10} />
                                </StyledTableCell>
                               
                                <StyledTableCell align="left">
                                  <LongText content={removeTagsAndAmp(log?.workDiscription)} limit={10} />
                                  {/* <div style={{ whiteSpace: "break-spaces" }}
                            dangerouslySetInnerHTML={{
                              __html: log?.workDiscription.replaceAll(
                                "\\n",
                                ""
                              ),
                            }}
                          /> */}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.consumedHours} hr

                                </StyledTableCell>
                                <StyledTableCell align="left">{`${moment(
                                  log?.workingDay
                                ).format("DD MMM, YYYY")}`}</StyledTableCell>
                                <StyledTableCell align="left">
                                  <Box sx={{ minWidth: "160px" }}>
                                    {log?.status === 5 ? (
                                      <>
                                        <TextField
                                          id="outlined-textarea"
                                          placeholder="Comment"
                                          multiline
                                          onChange={(e: any) =>
                                            handleTextChange(e, index)
                                          }
                                          value={log?.description}
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              lineHeight: "1.2",
                                            },
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>{log?.comment || "--"}</>
                                    )}
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Stack direction="row" spacing={2}>
                                    <StyledAcceptButton
                                      Status="Accept"
                                      onClick={() => {
                                        // handleSaveWorkHour(
                                        //   log?.id,
                                        //   log?.description,
                                        //   "approved"
                                        // )
                                        setlogStatus("Approved");
                                        setSelectedId(log?.id);
                                        setOpen(true);
                                      }}
                                    />
                                    <StyledRejectButton
                                      Status="Reject"
                                      onClick={() => {
                                        // handleSaveWorkHour(
                                        //   log?.id,
                                        //   log?.description,
                                        //   "reject"
                                        // )
                                        setlogStatus("Reject");
                                        setSelectedId(log?.id);
                                        setOpen(true);
                                      }
                                      }
                                    />
                                  </Stack>
                                </StyledTableCell>
                              </TableRow>
                            )
                          })
                      ) : (
                        <TableRow>
                          <StyledTableCell colSpan={8}>
                            No Record Found
                          </StyledTableCell>
                        </TableRow>
                      )
                    ) : (
                      ""
                    )}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={workLogList.filter((a: any) => a.status === 6).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${workLogList.filter((a: any) => a.status === 6)?.length}  Page ${page + 1} of ${pageCount}`}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>

                <Table stickyHeader aria-label="simple table">
                  <TableHead >

              <TableRow>
            
                <TableCell>Sr. No</TableCell>
                <TableCell align="left">Project Name</TableCell>
                <TableCell align="left">Employee</TableCell>
                
                <TableCell align="left">Ticket Number</TableCell>
                <TableCell align="left">Title</TableCell>
               
                <TableCell align="left">Description </TableCell>
                <TableCell align="left">Hours</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Comment</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {!loading ? (
                workLogList.filter((a: any) =>a.status===5 ||a.status===7).length > 0 ? (
                  getLogSheetList1()
                    ?.map((log: any, index: number) => {
                      const isItemSelected = isSelected(log.id);
                      const labelId: any = `enhanced-table-checkbox-${index}`;
                      const sno1 = page1 * rowsPerPage1 + index + 1;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, log.id)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={log.id}
                          selected={false}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={log.status === 6 ? isItemSelected : true}
                              // disabled={log.status === 1 ? false : true}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                                <StyledTableCell component="th" scope="row">
                                {sno1}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.projectName}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.createdBy}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {" "}
                                  {log?.ticketNumber}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <LongText content={log?.title ?? ""} limit={10} />
                                </StyledTableCell>
                               
                                <StyledTableCell align="left">
                                  <LongText content={removeTagsAndAmp(log?.workDiscription)} limit={10} />
                                  {/* <div style={{ whiteSpace: "break-spaces" }}
                            dangerouslySetInnerHTML={{
                              __html: log?.workDiscription.replaceAll(
                                "\\n",
                                ""
                              ),
                            }}
                          /> */}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.consumedHours} hr
                                </StyledTableCell>
                                <StyledTableCell align="left">{`${moment(
                                  log?.workingDay
                                ).format("DD MMM, YYYY")}`}</StyledTableCell>
                                <StyledTableCell align="left">
                                  {/* <Box sx={{ minWidth: "160px" }}>
                              {log?.status === 5 ? (
                                <>
                                  <TextField
                                    id="outlined-textarea"
                                    placeholder="Comment"
                                    multiline
                                    onChange={(e: any) =>
                                      handleTextChange(e, index)
                                    }
                                    value={log?.description}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        lineHeight: "1.2",
                                      },
                                    }}
                                  />
                                </>
                              ) : (
                                <>{log?.comment}</>
                              )}
                            </Box> */}
                                  {log?.comment}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {log?.status === 5 && (<Chip label="Approved" variant="approved" />)}
                                  {log?.status === 7 && (<Chip label="Rejected" variant="hold" />)}
                                </StyledTableCell>
                                {/* <StyledTableCell align="left">
                            <Stack direction="row" spacing={2}>
                              <StyledAcceptButton
                                Status="Accept"
                                onClick={() =>
                                  handleSaveWorkHour(
                                    log?.id,
                                    log?.description,
                                    "approved"
                                  )
                                }
                              />
                              <StyledRejectButton
                                Status="Reject"
                                onClick={() =>
                                  handleSaveWorkHour(
                                    log?.id,
                                    log?.description,
                                    "reject"
                                  )
                                }
                              />
                            </Stack>
                          </StyledTableCell> */}
                              </TableRow>
                            )
                          })
                      ) : (
                        <TableRow>
                          <StyledTableCell colSpan={8}>
                            No Record Found
                          </StyledTableCell>
                        </TableRow>
                      )
                    ) : (
                      ""
                    )}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={workLogList.filter((a: any) => a.status === 5 || a.status === 7).length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
                labelDisplayedRows={() => `Total: ${workLogList.filter((a: any) => a.status === 5 || a.status === 7)?.length}  Page ${page1 + 1} of ${pageCount1}`}
              />
            </TabPanel>
          </TabContext>
        </Box>


      </Paper>

      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Formik
          initialValues={{
            comment: comment,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (selectedId != "") {
                await handleSaveWorkHour(selectedId, comment, logStatus)
              }
              else {
                await ApproveReject(values);
              }
            } catch (ex: any) {
              if (ex.status == 400) {
                console.error("ex:", ex);
                ex.data.Error?.map((error: any, idx: any) => {
                  toast.error(error);
                })
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>

              <Box sx={style}>
                <Paper variant="outlined" sx={{ p: 4 }}>
                  <Typography
                    id="modal-modal-title"
                    align="center"
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: 700 }}
                  >
                    {/* Delete Event */}

                  </Typography>

                  <Typography
                    id="modal-modal-description"
                    align="center"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Do you really want to {logStatus} Hours, Continue?
                  </Typography>
                  {logStatus === "Reject" && (
                    <TextField
                      label="Comment"
                      value={comment}
                      fullWidth
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g, "")
                        value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 30);
                        values.comment = value;
                        setcomment(value);
                      }}
                      error={
                        getIn(touched, "comment") &&
                        getIn(errors, "comment")

                      }
                      helperText={
                        getIn(touched, "comment") &&
                        getIn(errors, "comment")
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fill: theme.palette.primary.main,
                        },
                      }}

                    />)}

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 3 }}
                  >

                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit" >
                      Confirm
                    </Button>
                  </Stack>
                </Paper>
              </Box>
            </form>)}
        </Formik>
      </Modal>
    </>
  );
}
