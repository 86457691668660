export enum HrRewardSatus {
    "All Status" = "",
    "Request" = "1",
    "Paid" = "2",
    
}

export enum HrRewardMonth{
    'All Months'='',
    'January'='January',
    'February'='February',
    'March'='March',
    'April'='April',
    'May'='May',
    'June'= 'June',
    'July'= 'July',
    'August'='August',
    'September'= 'September',
    'October'= 'October',
    'November'= 'November',
    'December' = 'December'
}