import React, { useEffect, useState } from 'react';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {Badge, CircularProgress} from '@mui/material';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import theme from '../../theme/theme';
import ChatMsg from '../escalationProcess/ChatMsg';
import IconButton from '@mui/material/IconButton';
import * as Icons from "../../assests/icons/icons";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import { riskServices } from '../../service/RaiseRisk';
import { Dayjs } from 'dayjs';
import { Search } from '@mui/icons-material';
import { number } from 'yup/lib/locale';
import { Formik, getIn } from 'formik';
import { toast } from 'material-react-toastify';
import ClearIcon from "@mui/icons-material/Clear";
import * as Yup from "yup";
import moment from 'moment';
import { propsToClassKey } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { apiUrl } from '../../service/common-services/apiURL';
import { HubConnection, HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import secureLocalStorage from 'react-secure-storage';
import { eRiskStatus } from '../../Enum/eRiskStatus';
import InfoPopover from '../../components/CommonInfoComponent';


const styleCrossleave = {
    position: 'absolute' as 'absolute',
    right: '24px',
    top: '12px'
}

const styles = {
    user: {
        username: {
            fontSize: '18px', fontWeight: 500,
            lineHeight: 1,
            color: theme.palette.primary.dark,
            textTransform: "capitalize"
        },
        userProject: {
            fontSize: '14px', color: theme.palette.grey[600]
        },
        timeStamp: {
            fontSize: '13px',
            color: theme.palette.grey[400]
        } 
    },
    toggleloaders:{
        position: "fixed",
         left: "50%",
         right: "50%",
         top: "50%", 
        bottom: "50%", 
        transform: "translate(-50%, -50%)" 
       },
};

enum ProjectRiskStatus {
    "All" = "0",
    "Pending" = "1",
    "Open" = "2",
    "Complete" = "3"
}
type StatusList = keyof typeof ProjectRiskStatus;

interface RiskInter {
    id: string;
    subject: string;
    clientName: string;
    description: string;
    clientId:string;
    clientUserId:string;
    projectName: string;
    riskStatus: number;
    projectId: boolean;
    projectIOwnerId:string;
    projectOwnerImage: string;
    clientImage: string;
    others: [
        {
            comment: string,
            commentDate: string,
            userId: string,
            username: string
        }
    ]
}
interface AddRisk {
    subject: string,
    description: string,
    riskStatus: string,
    projectId: string

}
interface Project {
    projectId: string;
    projectName: string;
}

// const initialvalues = {
//     id: "",
//     subject: "",
//     clientName: "",
//     description: "",
//     riskStatus: "",
//     projectId: "",
//     others: [
//         {
//             comment: "",
//             commentDate: "",
//             userId: "",
//             username: ""
//         }
//     ]
// };


export default function RiskRais() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.PrismPermissionApp.PrismPermission);
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [riskView, setRiskView] = useState<RiskInter[]>([]);
    const [currentRisk, setRiskViewById] = useState<RiskInter>();
    const [profileImage, setProfileImage] = useState<any>(null);
    let statusValue = "All";
    const [statusDataHook, setStatusDataHook] = useState(statusValue);
    const [connection, setConnection] = React.useState<null | HubConnection>(null);
    const [toggleLoader, setToggleLoader] = useState(false);
    React.useEffect(() => {
        configSocket();

    }, []);
    const configSocket = async () => {
        var options = {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => {
                return secureLocalStorage.getItem('authToken')?.toString()??""
            },
        };
        const connect = new HubConnectionBuilder()
            .withUrl(`${apiUrl.get_HUB_URL()}/notificationhub`,options)
            .withAutomaticReconnect()
            .build();
            setConnection(connect);
    };
    React.useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.on("ReceiveRiskMessage", (message) => {
                        console.log("message",message);
                        if(message!=null) handleListItemClick(message.riskId);
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [connection]);
    useEffect(() => {
        if (_permission.page_Risk_Alarm != true) {
            navigate("/dashboard")
        }
        getRaiseRiskDetails(statusValue);
        getProject();
        // getRiskById();
    }, []);

    const getChatDetails = async () => {
        await riskServices.getRiskData(id).then((response: any) => {
            if (response.data.isError) {
            } else {
                setRiskView(response?.data);
            }
        });
    };

    const getRaiseRiskDetails = async (searchValue: string = "") => {
        // let values = {
        //     riskStatus: "",
        //     search: searchValue,
        //     take: 1000,
        //     skip: 0,
        // }
        setToggleLoader(true);
        try{
        await riskServices.getRiskData(searchValue).then((response: any) => {
            if (response.data.isError) {
                setToggleLoader(false);
            } else {
                setRiskView(response?.data);
                setToggleLoader(false);
                if (response?.data?.profileImagePath) {
                    setProfileImage(
                        apiUrl.getImageURL() +
                        "/" +
                        response?.data?.profileImagePath
                    );
                }
            }
        });}
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
              toast.warning(err);
            });
          }
    };

    // ------------------------

    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = async (
        id: any
    ) => {
        await riskServices.getRiskById(id).then((response: any) => {
            if (response.data.isError)
             {

             } 
            else 
            {
                var element = document.getElementById("escalationLastMessage");
                element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                var riskObj=response?.data;
                console.log("response?.data",response?.data);
                setRiskViewById(riskObj);
            }

        });
    };
    const readUnReadMessage = async (
        id: any
    ) => {         
   
        await riskServices.readUnreadRiskComment(id).then((response: any) => {
            if (response.data.isError) 
            {
                
            } 
            else 
            {
                var chatObj=response?.data;              
                getChatDetails();
                handleListItemClick(id);
            }            
        });       
    };   


    // Add risk programming 
    const [project, setProject] = useState<Project[]>([]);
    const [isSubmit, setSubmit] = useState(false);
    const [openRisk, setRiskOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [formValues, setFormValues] = React.useState<AddRisk>();
    const [subject, setSubject] = React.useState(formValues?.subject)
    const [projectId, setProjectId] = React.useState(formValues?.projectId)
    const [description, setDescription] = React.useState(formValues?.description);
    const [riskStatus, setRiskStatus] = React.useState(formValues?.riskStatus);
    const [buttonDisable, setButtonDisable] = useState(false);

    const handleClickOpen = () => {
        setRiskOpen(true);
        setSubmit(false);
        setErrorMessage(false);
        setProjectId("");
        setSubject("");
        setDescription("");
        setSubmit(false);
    };

    const handleCloseLeave = () => {
        setRiskOpen(false);
        setTimeout(() => {
            setId("");
        }, 500);
    };


    const addRisk = async (values: any) => {
        try {
            let prm = { riskStatus: 1 }
            await riskServices.createRisk(values)
                .then((response: any) => {
                    if (response.data.isError) {

                    }
                    else {
                        setRiskOpen(false);
                    toast.success('Risk added successfully.',{
                        onOpen: () => setButtonDisable(true),
                        onClose: () => setButtonDisable(false),
                    });
                        getRaiseRiskDetails();
                    }
                });
        }
        catch (ex: any) {

            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    const getProject = async () => {
        await riskServices.getDropdownData(id).then((response: any) => {
            if (response.data.isError) {
            } else {
                setProject(response.data);
            }
        });
    };

    const updateCommentDetails = async (values: any) => {
        try {
            let otherData = currentRisk?.others;
            otherData?.push({
                comment: values.comment,
                commentDate: new Date().toString(),
                userId: _authUser.id,
                username: ""
            });
            var arr = {
                id: currentRisk?.id,
                others: otherData,
                isCompleteStatus: false
            }
            await riskServices.updateRisk(arr).then(async(response: any) => {
                if (response.data.isError) {
                } else{
                    console.log("currentRisk",currentRisk);
                    var msg={
                        senderId:_authUser.id,
                        receiverId:(currentRisk?.projectIOwnerId===_authUser.id)?(currentRisk?.clientUserId):(currentRisk?.projectIOwnerId),
                        message:values.comment,
                        riskId:currentRisk?.id
                    }
                    if (connection) {
                        await connection.send("SendRiskMessage", msg).then((response) => {
                            console.log("response",response);
                        })
                        .catch((error) => console.log(error));
                    }
                }
                   
                setSelectedIndex(values);
            });
            getRaiseRiskDetails();
        }
         catch (ex: any) {

            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const fieldValidationSchema = Yup.object().shape({
        projectId: Yup.string()
            .required("Project is required"),
        subject: Yup.string().required("Subject  is Required").max(25, "Subject can only contains 25 characters"),
        description: Yup.string()
            .required("Description is required")
            .max(150, "Description can only contain 150 characters"),
    });


    return (
        <Formik
            initialValues={{
                comment: ''
            }}
            // validationSchema={fieldValidationSchema}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting,

            }) => {
                try {
                    // setErrorMessage("");
                    await updateCommentDetails(values);
                    values.comment = "";
                } catch (ex: any) {
                    if (ex.status == 400) {
                        console.error("ex:", ex);
                        ex.data.Error.map((error: any, idx: any) => {
                            setErrorMessage(error);
                            toast.error(error);
                        })
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}>
                        
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                        <Link color="inherit" to="/">
                            Raise Risk
                        </Link>
                        {/* <Typography color="text.primary" variant="h5" component="h2" sx={{ fontWeight: 500 }}>Raise Risk</Typography> */}
                       
                    </Breadcrumbs>
                    {toggleLoader && (<CircularProgress sx={styles.toggleloaders} />)}
                    <Paper elevation={0} sx={{ p: 3, pb: 0, height: "calc(100vh - 126px)"}}>
                    <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
            <InfoPopover  onInput={"Raise Risk"}/>
            </Stack>
                        <Grid container spacing={2} sx={{ height: "100%" }}>
                            <Grid item xs={12} sm={4} lg={4} sx={{ borderRight: "1px solid #eee" }}>
                                <Box sx={{ pr: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: "100%" }}
                                        onClick={handleClickOpen}
                                        color="primary"
                                        startIcon={<AddIcon />}
                                    >
                                        Raise Risk
                                    </Button>


                                    <List sx={{ height: ' calc(100vh - 200px)', overflowY: 'auto',}}>
                                        {riskView
                                            .map((risk: any, index: any) => (
                                                <ListItemButton alignItems="center"
                                                    selected={risk.id === currentRisk?.id}
                                                    onClick={(event) => {
                                                        values.comment ="";
                                                        handleListItemClick(risk?.id);                                                        
                                                        readUnReadMessage(risk?.id)
                                                    }}
                                                >
                                                    <ListItemAvatar sx={{ mt: 0, mr: 1.5 }}>
                                                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"
                                                            sx={{ width: '50px', height: '50px' }}
                                                        /> */}
                                                         <Avatar
                                                            alt="Remy Sharp"
                                                            src={_authUser.id === risk?.projectIOwnerId 
                                                                ? apiUrl.getImageURL() + "/" + risk?.clientImage 
                                                                : apiUrl.getImageURL() + "/" + risk?.projectOwnerImage}
                                                            sx={{ width: '50px', height: '50px' }}
                                                        />
                                                    </ListItemAvatar>
                                                    {risk.unreadMessageCount>0 &&  <Stack sx={{position: "absolute", right: "20px", top: "70%"}} >
                                                            <Badge color="secondary" badgeContent={risk.unreadMessageCount}>
                                                            {/* <Badge color="secondary" badgeContent={"5"}> */}
                                                                
                                                            {/* <Icons.logisticks /> */}
                                                            </Badge>
                                                        </Stack> }

                                                    <ListItemText>

                                                        <Stack direction='row' justifyContent='space-between'>
                                                            <Box>
                                                                <Typography sx={[styles.user.username]} >
                                                                    {risk.projectName}
                                                                </Typography>
                                                                <Typography sx={[styles.user.userProject]}>
                                                                    {risk.clientName}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ textAlign: 'right' }}>
                                                                {risk.others != null && risk.others.length > 0 &&
                                                                    <Typography sx={[styles.user.timeStamp]} >
                                                                        {`${moment(risk.others[risk.others.length - 1].commentDate).format(
                                                                            "DD  MMM YYYY h:mm"
                                                                        )}`}
                                                                        {/* {risk.others[risk.others.length-1].commentDate}  */}
                                                                    </Typography>}
                                                                <Typography sx={[styles.user.timeStamp]} >
                                                                    {/* Dec 01, 2022 */}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>

                                                    </ListItemText >
                                                </ListItemButton >
                                            ))}
                                    </List>

                                </Box>
                            </Grid>

                            {/* 
                    {riskView
                        .map((risk: any, index:any) => ( */}
                            <Grid item xs={12} sm={8} lg={8}>
                                {(currentRisk != null &&
                                    <Stack justifyContent='flex-start' sx={{ height: '100%', position: 'relative' }}>
                                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                            <Stack direction='row' alignItems='center'>
                                                {/* 
                                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"
                                                    sx={{ mr: 1, width: 60, height: 60 }} /> */}
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={_authUser.id === currentRisk?.projectIOwnerId 
                                                        ? apiUrl.getImageURL() + "/" + currentRisk?.clientImage 
                                                        : apiUrl.getImageURL() + "/" + currentRisk?.projectOwnerImage}
                                                    sx={{ mr: 1, width: 60, height: 60 }}
                                                />
                                                <ListItemText
                                                    primary={currentRisk?.clientName}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography


                                                                variant="body2"
                                                                color="text.primary"
                                                            >

                                                            </Typography>
                                                            {currentRisk?.projectName}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </Stack>

                                            <Box>
                                                {/* <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>

                                                {currentRisk?.projectName}

                                            </Typography> */}

                                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                                    {/* <Typography variant="h6" component="h2" >

                                                    {currentRisk?.clientName}
                                                   
                                                </Typography> */}
                                                    <Stack direction='row' >
                                                        <Typography variant="subtitle1" component="h4">
                                                            {currentRisk?.subject ? `Subject -: ${currentRisk?.subject}` : ''}
                                                        </Typography>
                                                        <Box sx={{ ml: 2 }}>
                                                            {(currentRisk?.riskStatus===eRiskStatus.Pending &&
                                                                <Chip variant="review" label="Pending" />
                                                            )}
                                                            {(currentRisk?.riskStatus===eRiskStatus.Open &&
                                                                <Chip variant="new" label="Open " />
                                                            )}
                                                            {(currentRisk?.riskStatus===eRiskStatus.Complete &&
                                                                <Chip variant="approved" label="Completed" />
                                                            )}

                                                        </Box>
                                                    </Stack>
                                                </Stack>

                                            </Box>

                                        </Stack>
                                        {(currentRisk?.description != null &&
                                            <Paper sx={{ p: 1, mb: 2, mt: 1 }} >
                                                {currentRisk?.description ? `Description -: ${currentRisk?.description}` : ''}
                                            </Paper>
                                        )}



                                        <Box sx={{ height: 'calc(100vh - 350px)', overflowY: 'scroll', padding: '10px' }} >
                                            {currentRisk?.others?.filter(x => x.comment!=null).map((a: any) => (
                                                <ChatMsg
                                                    side={_authUser.id === a.userId ? 'right' : 'left'}
                                                    avatar={currentRisk?.projectIOwnerId === a.userId ?  apiUrl.getImageURL()+"/"+currentRisk.projectOwnerImage : apiUrl.getImageURL()+"/"+currentRisk.clientImage}
                                                    messages={[a.comment]}
                                                    // date={[a.commentDate]}
                                                    date={`${moment(a.commentDate).format(
                                                        "h:mm a"
                                                    )}`}
                                                />
                                            ))}
                                             <div id="escalationLastMessage"></div>
                                        </Box>



                                        <Box  sx={{ position: 'absolute', bottom: '0', left: '0', right: 0, width: '100%' }}>
                                            {/* <Divider sx={{ my: 1.5 }} /> */}
                                           
                                            <TextField
                                             disabled={currentRisk?.riskStatus===eRiskStatus.Complete}
                                                label="Message"
                                                name="comment"
                                                variant='outlined'
                                                value={values.comment.trimStart()}
                                                onChange={handleChange}
                                                fullWidth
                                                // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                onKeyPress={(e) => {
                                                    if ( values.comment.length!==0 && values.comment != " " ) {
                                                    }
                                                    else{ e.key === 'Enter' && e.preventDefault();}
                                                  }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            { (currentRisk?.riskStatus!==eRiskStatus.Complete 
                                                           && values.comment.length!==0 && values.comment != " ") && <Tooltip title="Add Message">
                                                                <IconButton
                                                                    type="submit"
                                                                    onBlur={handleBlur}
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                >
                                                                    <Icons.PaperPlane />
                                                                </IconButton></Tooltip>}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Stack>)}
                            </Grid>

                        </Grid>

                    </Paper>


                    <Dialog
                        open={openRisk}
                        onClose={Button}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth='md'
                        scroll='body'
                    >
                        <Formik
                            initialValues={{
                                subject: subject,
                                description: description,
                                riskStatus: 1,
                                projectId: projectId
                            }}
                            enableReinitialize
                            validationSchema={fieldValidationSchema}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    setErrorMessage(true);

                                    await addRisk(values);
                                } catch (err: any) {
                                    console.error(err);
                                    err = JSON.parse(JSON.stringify(err));
                                    if (err.status == 400) {

                                        {
                                            err.data.Error.map((error: any, idx: any) =>
                                                setErrorMessage(error)
                                            );
                                        }
                                    }
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <>
                                        <DialogTitle id="responsive-dialog-title" sx={{ fontSize: '24px', fontWeight: 600, position: 'relative' }}>
                                            {/* {id ? 'Update Label': 'Add Label'} */}
                                            <Typography variant='h5' sx={{ fontWeight: 600 }}>Raise Risk</Typography>
                                            <IconButton aria-label="delete" sx={styleCrossleave} onClick={handleCloseLeave} >
                                                <ClearIcon />
                                            </IconButton>
                                        </DialogTitle>



                                        <DialogContent>
                                            <Stack sx={{ mb: 4, pt: 2 }}>
                                                {/* <TextField
                                                    id="outlined-basic"
                                                    select
                                                    error={Boolean(
                                                        getIn(touched, "projectId") &&
                                                        getIn(errors, "projectId")
                                                    )}
                                                    helperText={
                                                        getIn(touched, "projectId") &&
                                                        getIn(errors, "projectId")
                                                    }
                                                    required
                                                    name="projectId"
                                                    label="Project Name"
                                                    variant="outlined"
                                                    value={projectId}
                                                    fullWidth
                                                //   disabled={disableForm}
                                                >
                                                    {project.map((option, index) => (
                                                        <MenuItem
                                                            key={option.projectName}
                                                            value={option.id}
                                                            onClick={(event) => {
                                                                setProjectId(project[index].id);
                                                                values.projectId =
                                                                    project[index].id;
                                                            }}
                                                        >
                                                            {option.projectName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField> */}
                                                <Autocomplete
                                                    value={
                                                        project?.find(
                                                            (option) => option.projectId == projectId
                                                        ) ?? null
                                                    }
                                                    fullWidth
                                                    id="outlined-basic"
                                                    options={project}
                                                    getOptionLabel={(option) => option.projectName}
                                                    onChange={(event, value) => {
                                                        setProjectId(value?.projectId ?? "");
                                                        values.projectId = value?.projectId ?? "";
                                                      
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id="outlined-basic-projectId"
                                                    error={Boolean(
                                                        getIn(touched, "projectId") &&
                                                        getIn(errors, "projectId")
                                                    )}
                                                    helperText={
                                                        getIn(touched, "projectId") &&
                                                        getIn(errors, "projectId")
                                                    }
                                                    required
                                                    name="projectId"
                                                    label="Project Name"
                                                    variant="outlined"
                                                    value={values?.projectId}
                                                    fullWidth
                                                        />
                                                    )}
                                                />

                                                <Box sx={{ mt: 5, mb: 3 }}>
                                                    <TextField
                                                        id="subject"
                                                        variant="outlined"
                                                        error={Boolean(
                                                            getIn(touched, "subject") && getIn(errors, "subject")
                                                        )}
                                                        fullWidth
                                                        helperText={
                                                            getIn(touched, "subject") && getIn(errors, "subject")
                                                        }
                                                        required
                                                        label="Subject"
                                                        name="subject"
                                                        value={subject}
                                                        onBlur={handleBlur}
                                                        // onChange={handleChange}
                                                        onChange={(e) => {
                                                            let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                                                            values.subject = value;
                                                            setSubject(value);
                                                        }}
                                                        inputProps={{ maxLength: 150 }}
                                                    />
                                                    <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        sx={{ textAlign: "right", color: "#757575" }}
                                                    >
                                                    </Typography>
                                                </Box>


                                                <Box sx={{ mt: 6, mb: 3 }}>
                                                    <b>   <label> Description* </label>  </b>
                                                    <TextField fullWidth multiline variant="outlined" required
                                                        value={description}
                                                        id="description"
                                                        error={Boolean(
                                                            getIn(touched, "description") && getIn(errors, "description")
                                                        )}
                                                        helperText={
                                                            getIn(touched, "description") && getIn(errors, "description")
                                                        }
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart().substring(0,150);
                                                            values.description = value;
                                                            setDescription(value);
                                                            // setErrorMessage(false);
                                                        }}

                                                        sx={{

                                                            '& .MuiInputBase-root': {
                                                                minHeight: '100px', backgroundColor: '#fff',
                                                                lineHeight: '1.5'
                                                            },
                                                        }}
                                                      />
                                                      <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        sx={{ textAlign: "right" }}
                                                      >
                                                        Max 150 Characters
                                                      </Typography>
                                                    </Box>

                                            </Stack>
                                        </DialogContent>
                                        <DialogActions sx={{ p: 3, pt: 0 }}>

                                            <Button variant="outlined" onClick={handleCloseLeave}>Cancel</Button>
                                            <Button onClick={() => {
                                                setErrorMessage(true);
                                                setSubmit(true);
                                            }} type="submit" variant="contained"  disabled={buttonDisable}>Submit</Button>
                                        </DialogActions>
                                    </>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                </form>
            )
            }
        </Formik >
    );
}