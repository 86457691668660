import React, { useEffect, useRef, useState } from "react";
import * as Icons from "../assests/icons/icons";
import { Badge, Box, Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import HBD1 from "../assests/images/HBD1.png";
import HBD2 from "../assests/images/HBD2.png";
import HBD3 from "../assests/images/HBD3.png";
import HBD4 from "../assests/images/HBD4.png";
import HBD5 from "../assests/images/HBD5.png";
import HBD6 from "../assests/images/HBD6.png";
import HBD7 from "../assests/images/HBD7.png";
import WAN1 from "../assests/images/WAN1.png";
import WAN2 from "../assests/images/WAN2.png";
import WAN3 from "../assests/images/WAN3.png";
import WAN4 from "../assests/images/WAN4.png";
import WAN5 from "../assests/images/WAN5.png";
import WAN6 from "../assests/images/WAN6.png";
import WAN7 from "../assests/images/WAN7.png";
import WOAN7 from "../assests/images/WOAN7.png";
import WOAN6 from "../assests/images/WOAN6.png";
import WOAN5 from "../assests/images/WOAN5.png";
import WOAN4 from "../assests/images/WOAN4.png";
import WOAN3 from "../assests/images/WOAN3.png";
import WOAN2 from "../assests/images/WOAN2.png";
import WOAN1 from "../assests/images/WOAN1.png";
import { notificationDetailsServices } from "../service/Notification/notification-service";
import { toast } from "material-react-toastify";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from "moment";
import { useSelector } from "react-redux";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import secureLocalStorage from "react-secure-storage";
import { apiUrl } from "../service/common-services/apiURL";
import { useParams } from "react-router-dom";
export default function HrWishing() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);

    const [showTimeLine, setshowTimeLine] = React.useState<any>([]);
    const [activeIndex, setActiveIndex] = useState<any>([]);
    const [currentcomment, setcurrentcomment] = useState<string[]>([]);
    const [editCommentNo, setEditCommentNo] = useState("");
    const [editCommentValue, setEditCommentValue] = useState("");

    const [connection, setConnection] = React.useState<null | HubConnection>(null);
    const configSocket = async () => {
        var options = {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => {
                return secureLocalStorage.getItem('authToken')?.toString() ?? '';
            },
        };
        const connect = new HubConnectionBuilder()
            .withUrl(`${apiUrl.get_HUB_URL()}/notificationhub`, options)
            .withAutomaticReconnect()
            .build();
        setConnection(connect);
    };
    const { id } = useParams();

    useEffect(() => {
        configSocket();
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on("ReceiveComment", (message) => {
                        console.log('message', message);
                        if (message != null) {
                            Gettimelines();
                        }
                        else {
                            return null
                        }
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [connection]);


    useEffect(() => {
        Gettimelines();
    }, []);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    console.log(showTimeLine, "showtitle");

    // const MoreVertMenu = (onEdit : any  , onDelete : any  ) => {

    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = (idx: any) => {
        setIsOpen(true)
        setSelectedCommentIndex(idx)
    };

    const Gettimelines = async () => {
        try {
            const response = await notificationDetailsServices.gettimelinepost();
            if (response.data.isError) {
                toast.error("Something went wrong while fetching data");
            } else {
                setshowTimeLine(response?.data);
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                // Handle errors if needed
            });
        }
    };

    const Addcommentnotifications = async (currentcomment: any, Notify: any) => {
        var req = {
            notificationId: Notify,
            like: false,
            comment: currentcomment
        };
        try {
            const response = await notificationDetailsServices.addcommentnotification(req);
            if (response.data.isError) {
                toast.error("Something went wrong while fetching data");
            } else {
                setcurrentcomment([]);
                Gettimelines();
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                // Handle errors if needed
            });
        }
    };
    const updateLike = async (Notify: any) => {
        var req = {
            notificationId: Notify,
            like: true,
            comment: ""
        };
        try {
            const response = await notificationDetailsServices.addcommentnotification(req);
            if (response.data.isError) {
                toast.error("Something went wrong while fetching data");
            } else {
                Gettimelines();
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                // Handle errors if needed
            });
        }
    };

    const editcommentsById = async (notificationId: any, currentcomment: any,) => {
        debugger
        var req = {
            commentNumber: editCommentNo,
            notificationId: notificationId,
            comment: currentcomment
        };
        try {
            const response = await notificationDetailsServices.editcomment(req);
            if (response.data.isError) {
                toast.error("Something went wrong while fetching data");
            } else {
                Gettimelines();
                setEditCommentValue("")
                setEditCommentNo("");
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                // Handle errors if needed
            });
        }
    };

    const deletecommentbyid = async (Id: any, CommentNumber: any) => {
        var req = {
            // Your request pa
            id: Id,
            commentNumber: CommentNumber
        };
        try {
            await notificationDetailsServices
                .deletecommentbyid(req)
                .then((response: any) => {
                    if (response.data.isError) {
                    } else {
                        toast.success("Allowance Deleted Successfully");
                        Gettimelines();
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const handleCommentButtonClick = (currentIndex: any) => {
        let abc = [...activeIndex];
        const index = abc.indexOf(currentIndex);
        if (index !== -1) {
            abc.splice(index, 1);
        } else {
            abc.push(currentIndex);
        }
        setActiveIndex(abc);
        setEditCommentValue("")
        setEditCommentNo("")
    };
    const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
    
    const scrollToRef = useRef<HTMLDivElement | null>(null);
    const scrollToIndex = showTimeLine.findIndex((item: any) => item?.id === id);
    useEffect(() => {
        const scrollToElement = () => {
          if (scrollToIndex !== -1 && scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        };
        if (scrollToIndex !== -1) {
          scrollToElement();
        } else {
          const timeoutId = setTimeout(() => {
            if (scrollToIndex !== -1) {
              scrollToElement();
            }
          }, 500); // Adjust the timeout duration as needed
          return () => clearTimeout(timeoutId); // Clear the timeout on component unmount
        }
      }, [id, showTimeLine]);

    return (
        <>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="start" sx={{ p: 2, pb: 0, borderBottom: '1px solid #EEE', }}>
                    <IconButton aria-label="delete">
                        <Icons.BirthdayCake />
                    </IconButton>
                    <Typography variant="subtitle1" sx={{ fontSize: "24px", fontWeight: "500" }} >
                        Seasia Timeline
                    </Typography>
                </Stack>
                {showTimeLine.length > 0 && showTimeLine?.map((item: any, index: any) => {
                    const likeCount = item?.likes.length;
                    const userLikes = item?.likes.filter((item1: any) => item1.userId === (_authUser?.id));
                    const othersLikeCount = likeCount - (userLikes.length > 0 ? 1 : 0);
                    return (
                        <Box key={index} sx={{ width: '800px', margin: '0 auto', }} ref={index === scrollToIndex ? scrollToRef : null}>
                            <Stack direction={'row'} justifyContent={'space-between'} sx={{ borderBottom: '1px solid #EEE', paddingY: "15px" }}>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>
                                    <Box sx={{ width: '40px', height: '40px', background: '#FFF3CE', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="subtitle1" sx={{ fontSize: "14px", fontWeight: "400", color: '#FFC409' }}>
                                            SF
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        marginLeft: '15px',

                                        "& .css-1vvfhnp-MuiTypography-root": {
                                            marginBottom: '0'
                                        }
                                    }}>
                                        <Typography variant="subtitle1" sx={{ fontSize: "18px", fontWeight: "400", color: '#232360' }} >
                                            {/* Seasia Family Added A New Post */}
                                            {item?.title}
                                        </Typography>
                                        <Typography sx={{ fontSize: "14px", fontWeight: "400", color: '#757575' }}>
                                            {moment(item?.createdDate).fromNow() || "--"}
                                        </Typography>
                                    </Box>

                                </Stack>
                                <Button
                                    id="basic-button"
                                    sx={{
                                        'margin-right': '9%'
                                    }}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <MoreVertIcon />
                                </Button>
                            </Stack>
                            <Typography sx={{ textAlign: 'center', marginY: '10px' }}
                                dangerouslySetInnerHTML={{
                                    __html: item?.description as string,
                                }}>


                            </Typography>
                            <Box sx={{ textAlign: 'end', borderBtoggleCommentsottom: '1px solid #EEE', paddingY: '16px', width: '500px' }}>
                                {(() => {
                                    const birthdayDate = new Date(item.createdDate);
                                    const uniqueIdentifier = index + 1 || item.id;
                                    switch (item.type) {
                                        case 1:
                                            const images = [
                                                HBD1,
                                                HBD2,
                                                HBD3,
                                                HBD4,
                                                HBD5,
                                                HBD6,
                                                HBD7,
                                            ];
                                            const imageIndex = (birthdayDate.getDate() + uniqueIdentifier) % 7;
                                            let abc = images[imageIndex]
                                            return <img style={{ maxWidth: '100%', height: 'auto' }} src={abc} alt="hdgfhg" />
                                        case 4:
                                            const anniimageIndex = (birthdayDate.getDate() + uniqueIdentifier) % 7;
                                            const images1 = [
                                                WAN1,
                                                WAN2,
                                                WAN3,
                                                WAN4,
                                                WAN5,
                                                WAN6,
                                                WAN7,
                                            ];
                                            let abce = images1[anniimageIndex]
                                            return <img style={{ maxWidth: '100%', height: 'auto' }} src={abce} alt="" />
                                        case 3:
                                            const workimageIndex = (birthdayDate.getDate() + uniqueIdentifier) % 7;
                                            const imagesWork = [
                                                WOAN1,
                                                WOAN2,
                                                WOAN3,
                                                WOAN4,
                                                WOAN5,
                                                WOAN6,
                                                WOAN7,
                                            ];
                                            let abcee = imagesWork[workimageIndex]
                                            return <img style={{ maxWidth: '100%', height: 'auto' }} src={abcee} alt="" />
                                        default:
                                            return null;
                                    }
                                })()}
                            </Box>
                            <Box sx={{ padding: '0 60px 14px 60px', borderBottom: '1px solid #EEE' }}>
                                <Stack key={item?.notificationId} direction="row" alignItems="center" justifyContent="space-between" sx={{ paddingY: '20px' }}>
                                    <Stack direction={'row'} alignItems="center" justifyContent="start">
                                        <Badge color="secondary" ></Badge>
                                        <IconButton onClick={() => handleCommentButtonClick(index)} aria-label="comment">
                                            <Icons.Comment />
                                        </IconButton>
                                        <Button type="button" onClick={() => handleCommentButtonClick(index)}>
                                            {'Comments'}
                                        </Button>
                                    </Stack>
                                    <Stack direction={'row'} alignItems="center" justifyContent="end">
                                        <IconButton aria-label="like" onClick={() => { updateLike(item?.id) }} sx={{
                                            background: item?.likes.some((item1: any) => item1.userId === (_authUser?.id)) ? '#349cff' : '',
                                            '& svg g path': { fill: item?.likes.some((item1: any) => item1.userId === (_authUser?.id)) ? 'white' : '' }
                                        }}>
                                            <Icons.Like />
                                        </IconButton>
                                        <Typography variant="body2" sx={{ marginLeft: 1 }}>
                                        {/* {userLikes.length > 0 && othersLikeCount === 0
                                                ?   `You`:""
                                            }
                                            {userLikes.length > 0 && othersLikeCount > 0
                                                ?   `You and ${othersLikeCount} other`:""
                                            }
                                            {userLikes.length === 0&&othersLikeCount >0?`${likeCount} like` :""
                                                
                                            } */}
                                            {userLikes.length > 0 && othersLikeCount === 0
                                                ? `You`
                                                : userLikes.length > 0 && othersLikeCount > 0
                                                    ? `You and ${othersLikeCount} other`
                                                    : userLikes.length === 0 && othersLikeCount > 0
                                                        ? `${likeCount} like`
                                                        : ''
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>

                                {activeIndex.includes(index) && (
                                    <>
                                        {item?.comments.map((comment: any, idx: any) => (
                                            <Box key={idx} sx={{ border: '1px solid #EEEEEE', padding: '10px 10px 10px 22px', background: '#F0F9FF' }}>
                                                <Stack direction={'row'} alignItems="center" justifyContent="space-between">
                                                    <Box>
                                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#1DA7FF' }}>{comment?.senderName}</Typography>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#424242' }}>{comment?.comment}</Typography>
                                                    </Box>
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        {_authUser.id === comment?.senderId && (
                                                            <IconButton aria-label="more" aria-controls="more-menu" aria-haspopup="true" onClick={() => handleClick(idx)}>
                                                                <MoreVertIcon />
                                                            </IconButton>)}
                                                        {isOpen && selectedCommentIndex === idx && (
                                                            <div>
                                                                <IconButton
                                                                    color="error"
                                                                    onClick={() => { deletecommentbyid(item?.id, comment?.commentNumber); setIsOpen(false) }}
                                                                >
                                                                    <Icons.Delete />
                                                                </IconButton>
                                                                <IconButton
                                                                    color="error"
                                                                    onClick={async () => {
                                                                        debugger; await setEditCommentValue(comment?.comment);
                                                                        await setEditCommentNo(comment?.commentNumber); setActiveIndex([index]); setIsOpen(false)
                                                                    }}
                                                                >
                                                                    <Icons.Edit />
                                                                </IconButton>
                                                            </div>)}

                                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#757575' }}>{moment(comment?.createdDate).fromNow() || "--"}</Typography>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        ))}

                                        <Box sx={{ border: '1px solid #EEEEEE', padding: '10px 10px 10px 10px', marginTop: '20px', background: '#F0F9FF' }}>
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    background: '#fff',
                                                    border: '1px solid #EEE',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                    <Box
                                                        sx={{
                                                            width: '90%',
                                                            "& .css-q9kwfh-MuiOutlinedInput-notchedOutline": {
                                                                border: 'none',
                                                                background: 'transparent',
                                                            },
                                                            "& .css-1gq4kjl-MuiInputBase-input-MuiOutlinedInput-input": {
                                                                paddingX: '0',
                                                            },
                                                        }}
                                                    >
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            value={editCommentNo ? editCommentValue : currentcomment[index] || ""}
                                                            multiline
                                                            placeholder="Write your comment here..."
                                                            type="text"
                                                            onChange={(e) => editCommentNo ? setEditCommentValue(e.currentTarget.value) : setcurrentcomment(prevComments => {
                                                                const newComments = [...prevComments];
                                                                newComments[index] = e.target.value;
                                                                return newComments;
                                                            })}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Box>
                                                    <Button variant="contained" onClick={() => !editCommentNo ?
                                                        Addcommentnotifications(currentcomment[index], item?.id) : editcommentsById(item?.id, editCommentValue)}
                                                        
                                                        >
                                                            Send</Button>

                                                </Stack>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>)
                })}
            </Paper >
        </>

    )
}
