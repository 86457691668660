import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Sidebar from './SideNav';
import { width } from '@mui/system';
import theme from "../theme/theme";
// import seasiagala from "../assests/images/seasia-gala.png";
import seasiagala from "../assests/images/newYear.png";
import Countdown from 'react-countdown';
import { Typography } from '@mui/material';
import { bannerUploadServices } from '../service/bannerUpload-services';
import { toast } from 'material-react-toastify';
import { apiUrl } from '../service/common-services/apiURL';
import useSignalR from './websocketComponent';
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

const Space = styled('div')(({ theme }) => ({
  display: 'block',
  height: theme.spacing(10)
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));


const InnerContent = () => {
  // const [open, setOpen] = React.useState((localStorage.getItem('userSidebar') === 'expanded') || false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const targetDate = new Date('December 14, 2023 14:00:00').getTime();

  // Random component
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <Typography>Voting has ended</Typography>;
    } else {
      return (
        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
          Voting Ends in <br />
          {days}d {hours}h {minutes}m {seconds}s
        </Typography>
      );
    }
  };
  const {connection} = useSignalR();
  React.useEffect(() => {
    getImageDetails();
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("ReceiveBannerNotification", (message) => {
            console.log('message', message);
            if (message != null) {
              getImageDetails();
            }
            else {
              return null
            }
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);
  const [bannerImage, setBannerImage] = React.useState("");
  const getImageDetails = async () => {
    try {
      await bannerUploadServices
        .getBannerImage("Seasia")
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            setBannerImage(apiUrl.getImageURL() + "/" + response?.data?.path)
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  return <div className='inner-content'>
    <Box sx={{ display: 'flex' }}>
      <Sidebar open={open} setOpen={setOpen} />
      <Box component="main" sx={{
        flexGrow: 1, p: 2, pt: 0, width: open ? 'calc(100vw - 318px)' : 'calc(100vw - 120px)', [theme.breakpoints.down('sm')]: {
          width: '99.9%',
          px: 0
        }
      }} >
        <Space />
        {/* <Box pb={3} sx={{ cursor: "pointer" }} onClick={() => (navigate("polling-systemUser"))}>
          <Box sx={{ position: 'relative' }}>
            <img style={{ width: '100%', height: 'auto', }} src={seasiagala} alt="" />
            <Box sx={{ position: 'absolute', right: '2%', bottom: '0', fontSize: '24px', fontWeight: '700', color: '#fff', maxWidth: '10%' }} >
              <Countdown date={targetDate} renderer={renderer} />
            </Box>
          </Box>
        </Box> */}

        <Box pb={3} sx={{ cursor: "pointer" }} >

          <img style={{ maxWidth: '100%', height: 'auto' }} src={bannerImage} alt="" 
   // onClick={() => window.open('https://stgn.appsndevs.com/seasiaconnectfeqa/Video/Urban Opener_1.mp4', '_blank')}
    /> 
        </Box>
        <Outlet />
      </Box>
    </Box>
  </div>;
}

export default InnerContent;