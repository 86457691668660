import * as React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import Avatar from "@mui/material/Avatar";
import OracleBadge from "../../assests/images/oraclebadge.png";
import employemonth from "../../assests/images/employe-month.png";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { userManagementServive } from "../../service/user-management-service";
import { MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { insuranceCard, othersDocument, userModelDetail } from "./userModel";
import { dateTimePickerValueManager } from "@mui/x-date-pickers/DateTimePicker/shared";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "material-react-toastify";
import AddIcon from "@mui/icons-material/Add";
import * as Icons from "../../assests/icons/icons";
import IconButton from "@mui/material/IconButton";
import {
  achivements,
  badges,
  documents,
  employeeInfo,
  pastExperince,
  personalInfo,
  skillsets,
  userModel,
  IFiles,
} from "./userModel";
import FileUpload from "../../components/styledComponent/FileUpload";
import AppConstants from "../../config/AppConstants";
import FileUpload1 from "../../components/styledComponent/FileUpload1";
import { useSelector } from "react-redux";
import { apiUrl } from "../../service/common-services/apiURL";
import theme from "../../theme/theme";
import { projectManagementServive } from "../../service/project-management-service";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";

interface User {
  badges: {
    id: string;
  };
}

interface Badge {
  id: string;
  name: string;
  selected: boolean;
}
interface Achievers {
  id: string;
  name: string;
  selected: boolean;
}

export default function AchievementsandCertifications(props: {
  obj: any;
  setValue: any;
  callBackFunction: any;
}) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [previousvalue, setPreviousvalue] = useState(props.obj);
  const [DisabledButton, setDisabledButton] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonDisable1, setButtonDisable1] = useState(false);
  const [buttonDisable2, setButtonDisable2] = useState(false);
  const [disableForm, setdisableForm] = useState(false);
  const [visible, setVisible] = useState(false);
  // const { id } = useParams();
  // let uId = props.obj ?? "";//"632da2f9fa37260b70bf4e1f";
  setTimeout(() => {
    if (!props.obj && props.obj == "") {
      props.setValue(0);
    }
  }, 200);
  const navigate = useNavigate();

  var badgeData = { id: "", name: "", selected: false };
  const [badgeDataHook, setBadgeDataHook] = useState(badgeData);
  const [badges_arr, setBadges_arr] = useState<Badge[]>([]);
  const [selectedbadges, setSelectedbadges] = useState<Badge[]>([]);

  var Achieverata = { id: "", name: "", selected: false };
  const [achieverDataHook, setAchieverDataHook] = useState(Achieverata);
  const [achievers, setAchievers] = useState<Achievers[]>([]);
  const [selectedAchievers, setSelectedAchievers] = useState<Achievers[]>([]);

  const [userIdHook, setUserIdHook] = React.useState(props.obj);
  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    props.obj,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],
    [_othersDocument],
    [_skillsets]
  );
  const [userDataHook, setUserDataHook] = React.useState(usermodel);

  let IfileObj: IFiles = {
    itemType: "",
    name: "",
    fileURL: "",
    fileName: "",
    file: null,
  };
  let objBadges: IFiles[] = [];
  let objCertificates: IFiles[] = [];

  const _permission = useSelector(
    (state: any) => state.UserPermissionApp.UserPermission
  );

  const [badgeName, setbadgeName] = React.useState("");
  const [badgeFile, setbadgeFile] = React.useState<any>(null);
  const [badgeFileURL, setbadgeFileURL] = React.useState<any>("");
  const [tempBadgeFileURL, setTempBadgeFileURL] = React.useState<any>("");
  const [badgeFileName, setbadgeFileName] = React.useState<any>("");
  const [BadgesformValues, setBadgesformValues] = useState<IFiles[]>(objBadges);

  const [certificatesName, setcertificatesName] = React.useState("");
  const [certificatesFile, setcertificatesFile] = React.useState<any>(null);
  const [certificatesFileURL, setcertificatesFileURL] = React.useState<any>("");
  const [tempcertificatesFileURL, setTempcertificatesFileURL] = React.useState<any>("");
  const [certificatesFileName, setcertificatesFileName] =
    React.useState<any>("");
  const [CertificatesformValues, setCertificatesformValues] =
    useState<IFiles[]>(objCertificates);

  const [othersName, setothersName] = React.useState("");
  const [othersFile, setothersFile] = React.useState<any>(null);
  const [othersFileURL, setothersFileURL] = React.useState<any>("");
  const [tempothersFileURL, setTempothersFileURL] = React.useState<any>("");
  const [othersFileName, setothersFileName] = React.useState<any>("");
  const [othersformValues, setothersformValues] =
    useState<IFiles[]>(objCertificates);

  let addBadgesValues = () => {
    IfileObj.itemType = "";
    IfileObj.name = "";
    IfileObj.fileURL = "";
    IfileObj.fileName = "";
    IfileObj.file = null;
    if (
      badgeName != null &&
      badgeName != "" &&
      badgeName.length > 0 &&
      badgeFile != null
    ) {
      IfileObj.name = badgeName;
      IfileObj.fileURL = badgeFileURL;
      IfileObj.fileName = badgeFileName;
      IfileObj.file = badgeFile;
      setDisabledButton(false);
      setBadgesformValues([...BadgesformValues, IfileObj]);
      setbadgeName("");
      setbadgeFile(null);
    } else {
      if (badgeName == null || badgeName == "" || badgeName.length == 0) {
        toast.warning("Please add badge name.", {
          onOpen: () => setButtonDisable(true),

          onClose: () => setButtonDisable(false),
        });
        return;
      }
      if (badgeFile == null) {
        toast.warning("Please choose badge file.",
          {
            onOpen: () => setButtonDisable(true),

            onClose: () => setButtonDisable(false),
          });
        return;
      }
    }
  };
  let addCertificatesValues = () => {
    IfileObj.name = "";
    IfileObj.fileURL = "";
    IfileObj.fileName = "";
    IfileObj.file = null;
    if (
      certificatesName != null &&
      certificatesName != "" &&
      certificatesName.length > 0 &&
      certificatesFile != null
    ) {
      IfileObj.name = certificatesName;
      IfileObj.fileURL = certificatesFileURL;
      IfileObj.fileName = certificatesFileName;
      IfileObj.file = certificatesFile;
      setCertificatesformValues([...CertificatesformValues, IfileObj]);
      setcertificatesName("");
      setcertificatesFile(null);
      setDisabledButton(false);
    } else {
      if (
        certificatesName == null ||
        certificatesName == "" ||
        certificatesName.length == 0
      ) {
        toast.warning("Please add certificate name.", {
          onOpen: () => setButtonDisable1(true),

          onClose: () => setButtonDisable1(false),
        });
        return;
      }
      if (certificatesFile == null) {
        toast.warning("Please choose certificate file.", {
          onOpen: () => setButtonDisable1(true),

          onClose: () => setButtonDisable1(false),
        });
        return;
      }
    }
  };
  let addOthersValues = () => {
    IfileObj.itemType = "";
    IfileObj.name = "";
    IfileObj.fileURL = "";
    IfileObj.fileName = "";
    IfileObj.file = null;
    if (
      othersName != null &&
      othersName != "" &&
      othersName.length > 0 &&
      othersFile != null
    ) {
      IfileObj.name = othersName;
      IfileObj.fileURL = othersFileURL;
      IfileObj.fileName = othersFileName;
      IfileObj.file = othersFile;
      setDisabledButton(false);
      setothersformValues([...othersformValues, IfileObj]);
      setothersName("");
      setothersFile(null);
    } else {
      if (othersName == null || othersName == "" || othersName.length == 0) {
        toast.warning("Please add others name.", {
          onOpen: () => setButtonDisable2(true),

          onClose: () => setButtonDisable2(false),
        });
        return;
      }
      if (othersFile == null) {
        toast.warning("Please choose a file first.", {
          onOpen: () => setButtonDisable2(true),

          onClose: () => setButtonDisable2(false),
        });
        return;
      }
    }
  };

  const triggerBadgeFile = (fileList: FileList, index: number) => {
    setbadgeFile(fileList[0]);
    setbadgeFileURL(URL.createObjectURL(fileList[0]));
    setTempBadgeFileURL(URL.createObjectURL(fileList[0]));
    setbadgeFileName(fileList[0].name);
    setDisabledButton(false);
  };
  const triggerCertificatesFile = (fileList: FileList, index: number) => {
    setcertificatesFile(fileList[0]);
    setcertificatesFileURL(URL.createObjectURL(fileList[0]));
    setTempcertificatesFileURL(URL.createObjectURL(fileList[0]));
    setcertificatesFileName(fileList[0].name);
    setDisabledButton(false);
  };
  const triggerOthersFile = (fileList: FileList, index: number) => {
    setdisableForm(false);
    setothersFile(fileList[0]);
    setothersFileURL(URL.createObjectURL(fileList[0]));
    setTempothersFileURL(URL.createObjectURL(fileList[0]));
    setothersFileName(fileList[0].name);
    setDisabledButton(false);
  };
  let removeFile = (i: any, type: string) => {
    if (type == "badges") {
      let badgesformValues = [...BadgesformValues];
      badgesformValues.splice(i, 1);
      setBadgesformValues(badgesformValues);
      setDisabledButton(false);
    }
    if (type == "certificate") {
      let certificatesformValues = [...CertificatesformValues];
      certificatesformValues.splice(i, 1);
      setCertificatesformValues(certificatesformValues);
      setDisabledButton(false);
    }
    if (type == "others") {
      let OthersformValues = [...othersformValues];
      OthersformValues.splice(i, 1);
      setothersformValues(OthersformValues);
      setDisabledButton(false);
    }
  };

  const getBadges = async () => {
    let prm = { module: 6 };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        setBadges_arr([badgeData]);
      } else {
        setBadges_arr(response.data);
      }
    });
  };

  const getAchievers = async () => {
    let prm = { module: 3 };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        setAchievers([Achieverata]);
      } else {
        setAchievers(response.data);
      }
    });
  };
  const handleAchiversCheck = (event: {
    target: { checked: any; value: any };
  }) => {
    var achievers_array = [...selectedAchievers];
    if (event.target.checked) {
      achievers_array = [...selectedAchievers, event.target.value];
    } else {
      achievers_array.splice(selectedAchievers.indexOf(event.target.value), 1);
    }
    setSelectedAchievers(achievers_array);
  };
  const handleBadgesCheck = (event: {
    target: { checked: any; value: any };
  }) => {
    var badges_array = [...selectedbadges];
    if (event.target.checked) {
      badges_array = [...selectedbadges, event.target.value];
    } else {
      badges_array.splice(selectedbadges.indexOf(event.target.value), 1);
    }
    setSelectedbadges(badges_array);
  };
  useEffect(() => {
    const getUserDetail = async () => {
      // await getBadges();
      // await getAchievers();

      await getUserDetails(props.obj);
    };
    getUserDetail();
    if (_authUser.typeOfUser == 1) {
      debugger
      setdisableForm(false);
      setVisible(true);
    }
    else if (props.obj == _authUser.id) {
      debugger
      setdisableForm(true);
      setVisible(false);
    }
  }, []);

  const getUserDetails = async (uid: any) => {
    try {
      await userManagementServive.getUserById(uid).then(async (response: any) => {
        if (response.data.isError) {
        } else {
          setUserDataHook(response.data);
          let achivementsData = response.data.achievements;
          let badgesData = response.data.badges;
          let othersData = response.data.others;

          /////achievement
          var achievers_array = [...CertificatesformValues];
          const achievementApi = achivementsData.map((item: any) => projectManagementServive.getUnEncrypetDocuments(item?.achievementImageUrl));
          await Promise.all(achievementApi).then(d => {
            achivementsData.map((item: any, index: any) => {
              achievers_array.push({
                name: item.achievementName,
                fileURL: URL.createObjectURL(d[index].data),
                fileName: item.achievementImageUrl,
                file: null,
                itemType: "",
              });
            });
          });
          setCertificatesformValues(achievers_array);

          //////Badge
          var badges_array = [...BadgesformValues];
          const appiCalls = badgesData.map((item: any) => projectManagementServive.getUnEncrypetDocuments(item?.badgeImageUrl));
          await Promise.all(appiCalls).then(d => {
            badgesData.map((item: any, index: any) => {
              badges_array.push({
                name: item.badgeName,
                fileURL: URL.createObjectURL(d[index].data),
                fileName: item.badgeImageUrl,
                file: null,
                itemType: "",
              });
            });
            setBadgesformValues(badges_array);
          })

          ////Others
          var others_array = [...othersformValues];
          const otherApi = othersData.map((item: any) => projectManagementServive.getUnEncrypetDocuments(item?.otherImageUrl));
          await Promise.all(otherApi).then(d => {
            othersData.map((item: any, index: any) => {
              others_array.push({
                name: item.otherName,
                fileURL: URL.createObjectURL(d[index].data),
                fileName: item.otherImageUrl,
                file: null,
                itemType: "",
              });
            });
          })
          setothersformValues(others_array);
        }
      });
    } catch (ex: any) {
      props.setValue(0);
    }
  };

  const updateUser = async () => {
    try {
      setVisible(false);
      setDisabledButton(true);
      let data = userDataHook;
      data.badges = [];
      data.achievements = [];
      data.others = [];
  
      const uploadBadgePromises = BadgesformValues.map(async (item) => {
        if (item.file !== null) {
          let formData = new FormData();
          formData.append("File", item.file);
          formData.append("FileFor", "badges for user management");
  
          const response = await userManagementServive.UploadUserDocument(formData);
  
          if (response.data.isError) {
            toast.error("Facing issue while uploading badge file for " + item.name);
          } else {
            data.badges.push({
              badgeName: item.name,
              badgeImageUrl: response.data,
            });
          }
        } else {
          data.badges.push({
            badgeName: item.name,
            badgeImageUrl: item.fileName,
          });
        }
      });
  
      const uploadCertificatePromises = CertificatesformValues.map(async (item) => {
        if (item.file !== null) {
          let formData = new FormData();
          formData.append("File", item.file);
          formData.append("FileFor", "Certification for user management");
  
          const response = await userManagementServive.UploadUserDocument(formData);
  
          if (response.data.isError) {
            toast.error("Facing issue while uploading certification file for " + item.name);
          } else {
            data.achievements.push({
              achievementName: item.name,
              achievementImageUrl: response.data,
            });
          }
        } else {
          data.achievements.push({
            achievementName: item.name,
            achievementImageUrl: item.fileName,
          });
        }
      });
  
      const uploadOthersPromises = othersformValues.map(async (item) => {
        if (item.file !== null) {
          let formData = new FormData();
          formData.append("File", item.file);
          formData.append("FileFor", "Others file for user management");
  
          const response = await userManagementServive.UploadUserDocument(formData);
  
          if (response.data.isError) {
            toast.error("Facing issue while uploading others file for " + item.name);
          } else {
            data.others.push({
              otherName: item.name,
              otherImageUrl: response.data,
            });
          }
        } else {
          data.others.push({
            otherName: item.name,
            otherImageUrl: item.fileName,
          });
        }
      });
  
      await Promise.all([...uploadBadgePromises, ...uploadCertificatePromises, ...uploadOthersPromises]);
  
      await updateUserDetail(data);
      props.callBackFunction();
    } catch (ex: any) {
      setDisabledButton(false);
      toast.warning("Error while updating data, check console.");
    }
  };
  

  const updateUserDetail = async (data: any) => {
    await userManagementServive.updateUser(data).then((response: any) => {
      if (response.data.isError) {
        toast.error("facing issue while uploading data.");
      } else {
        if (response.data) {
          toast.success(
            "Achievements and certifications updated successfully."
          );
          // navigate("/user-management");
        }
      }
    });
  };

  const getUnEncrypedFiles = async (fileName: any) => {
    try {
      await projectManagementServive
        .getUnEncrypetDocuments(fileName)
        .then(async (response: any) => {
          if (response.data.type === "application/json") {
            const data = await response.data.text();
            toast.warning(JSON.parse(data).listErrors[0]);
          }
          else {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            a.href = URL.createObjectURL(response.data);
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(a.href);
          }
        });
    } catch (ex: any) {
      ex.data.Error?.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }

  }

  const getUnEncryptedImage = async (fileName: any) => {
    try {
      const response = await projectManagementServive.getUnEncrypetDocuments(fileName);
      if (response.data.isError) {
        toast.warning("Error while fetching data");
      } else {
        const imageUrl = URL.createObjectURL(response.data);
        return imageUrl
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12} sm={12}>
          <ItemPaper elevation={0} sx={{ height: "100%", pb: 1 }}>
            <Typography variant="body1" gutterBottom>
              Badges
            </Typography>
            <Stack
              // direction={{ xs: "column", md: "row" }}
              alignItems="flex-start"
              spacing={3}
              sx={{ flexWrap: "wrap" }}
            >
              <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={3}>
                <Box sx={{ maxWidth: 400 }}>
                  <TextField
                    placeholder="Badge Name*"
                    variant="outlined"
                    name="Badgename"
                    id={"badgeName"}
                    value={badgeName}
                    disabled={disableForm}

                    onChange={(event) => {
                      let value = event.currentTarget.value.replace(/[^a-zA-Z\.\s]/g, "")
                      value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 50);
                      setbadgeName(value);
                      setDisabledButton(false);
                    }}
                    size="small"
                    fullWidth
                  />

                </Box>
                {_permission.action_ManageAchievementsAndBadges_Upload_Access && (
                  <>
                    {badgeFile != null &&
                      <Box sx={{ position: "relative" }}>
                        <Avatar
                          src={tempBadgeFileURL}
                          variant="square"
                          sx={{
                            width: "121px",
                            height: "200px",

                            padding: "4px",
                            "& img": {
                              maxWidth: "100%",
                              height: "auto",
                            },
                          }}
                        />
                        <IconButton
                          disabled={disableForm}
                          onClick={() => setbadgeFile(null)}
                          aria-label="Edit"
                          size="small"
                          color="error"
                          sx={{
                            position: "absolute", backgroundColor: "#f8f8f8",
                            top: "14px",
                            right: "-8px",
                            zIndex: 5,
                            padding: "10px",
                            "&:hover": {
                              backgroundColor: theme.palette.primary.light
                            }
                          }}
                        >
                          <Icons.editblue />
                        </IconButton>
                      </Box>
                    }
                    {badgeFile == null && <FileUpload1
                      selectFile={triggerBadgeFile}
                      index={1}
                      disabled={disableForm}
                    />}
                    <Button
                      variant="contained"
                      disabled={disableForm || buttonDisable}
                      onClick={() => addBadgesValues()}
                      size="small"
                    >
                      Add{" "}
                    </Button></>)}
              </Stack>


            </Stack>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {BadgesformValues.map((option, index) => (
                <Grid
                  item
                  xs={6}
                  lg={1}
                  sm={3}
                  sx={{ textAlign: "center", alignContent: "center" }}
                >
                  <Avatar
                    onClick={() => { getUnEncrypedFiles(option?.fileName) }}
                    src={option.fileURL}
                    variant="square"
                    sx={{
                      width: "100%",
                      height: 122,
                      mb: 2.5,
                      background: "#f9f9f9",
                      padding: "4px",
                      "& img": {
                        maxWidth: "100%",
                        height: "auto",
                      },
                    }}
                  />
                  <Typography variant="body1" align="center">
                    {option.name}
                  </Typography>
                  <IconButton
                    disabled={disableForm}
                    onClick={() => removeFile(index, "badges")}
                    aria-label="delete"
                    size="large"
                    color="error"
                  >
                    <Icons.Delete />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </ItemPaper>
        </Grid>

        <Grid item xs={12} sm={12}>
          <ItemPaper elevation={0} sx={{ height: "100%", pb: 1 }}>
            <Typography variant="body1" gutterBottom>
              Certification
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              spacing={3}
              sx={{ flexWrap: "wrap" }}
            >
              <Box sx={{ maxWidth: 400 }}>
                <TextField
                  placeholder="Certificate Name*"
                  variant="outlined"
                  name="Certificatename"
                  id={"CertificateName"}
                  disabled={disableForm}
                  value={certificatesName}
                  size="small"
                  onChange={(event) => {
                    let value = event.currentTarget.value.replace(/[^a-zA-Z\.\s]/g, "")
                    value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 50);
                    setcertificatesName(value);
                    setDisabledButton(false);
                  }}
                  fullWidth
                />
              </Box>
              {_permission.action_ManageAchievementsAndBadges_Upload_Access && (
                <>
                  {certificatesFile != null &&
                    <>
                      <Avatar
                        src={tempcertificatesFileURL}
                        variant="square"
                        sx={{

                          background: "#f9f9f9",
                          padding: "4px",
                          "& img": {
                            maxWidth: "100%",
                            height: "auto",
                          },
                        }}
                      />
                      <Box>
                        <IconButton
                          disabled={disableForm}
                          onClick={() => setbadgeFile(null)}
                          aria-label="Edit"
                          size="small"
                          color="error"
                        >
                          <Icons.editblue />
                        </IconButton>
                      </Box>
                    </>
                  }
                  {certificatesFile == null && <FileUpload1
                    selectFile={triggerCertificatesFile}
                    index={1}
                    disabled={disableForm}
                  />}
                  <Button
                    variant="contained"
                    disabled={disableForm || buttonDisable1}
                    onClick={() => addCertificatesValues()}
                    size="small"
                  >
                    Add{" "}
                  </Button></>)}
            </Stack>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {CertificatesformValues.map((option, index) => (
                <Grid
                  item
                  xs={6}
                  lg={1}
                  sm={3}
                  sx={{ textAlign: "center", alignContent: "center" }}
                >
                  <Avatar
                    onClick={() => { getUnEncrypedFiles(option?.fileName) }}
                    src={option.fileURL}
                    variant="square"
                    sx={{
                      width: "100%",
                      height: 122,
                      mb: 2.5,
                      background: "#f9f9f9",
                      padding: "4px",
                      "& img": {
                        maxWidth: "100%",
                        height: "auto",
                      },
                    }}
                    fit-content
                  />
                  <Typography variant="body1" align="center">
                    {option.name}
                  </Typography>
                  <IconButton
                    disabled={disableForm}
                    onClick={() => removeFile(index, "certificate")}
                    aria-label="delete"
                    size="large"
                    color="error"
                  >
                    <Icons.Delete />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </ItemPaper>
        </Grid>

        <Grid item xs={12} sm={12}>
          <ItemPaper elevation={0} sx={{ height: "100%", pb: 1 }}>
            <Typography variant="body1" gutterBottom>
              Others
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              spacing={3}
              sx={{ flexWrap: "wrap" }}
            >
              <Box sx={{ maxWidth: 400 }}>
                <TextField
                  placeholder="Name*"
                  variant="outlined"
                  name="othersname"
                  id={"othersName"}
                  value={othersName}
                  disabled={disableForm}
                  size="small"
                  onChange={(event) => {
                    let value = event.currentTarget.value.replace(/[^a-zA-Z\.\s]/g, "")
                    value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 50);
                    setothersName(value);
                    setDisabledButton(false);
                  }}
                  fullWidth
                />
              </Box>
              {_permission.action_ManageAchievementsAndBadges_Upload_Access && (
                <>
                  {othersFile != null &&
                    <>
                      <Avatar
                        src={tempothersFileURL}
                        variant="square"
                        sx={{
                          width: "10%",
                          height: "10%",
                          mb: 2.5,
                          background: "#f9f9f9",
                          padding: "4px",
                          "& img": {
                            maxWidth: "100%",
                            height: "auto",
                          },
                        }}
                      />
                      <Box>
                        <IconButton
                          disabled={disableForm}
                          onClick={() => setbadgeFile(null)}
                          aria-label="Edit"
                          size="small"
                          color="error"
                        >
                          <Icons.editblue />
                        </IconButton>
                      </Box>
                    </>
                  }
                  {othersFile == null && <FileUpload1
                    selectFile={triggerOthersFile}
                    index={1}
                    disabled={disableForm}
                  />}
                  <Button
                    variant="contained"
                    disabled={disableForm || buttonDisable2}
                    onClick={() => addOthersValues()}
                    size="small"
                  >
                    Add{" "}
                  </Button></>)}
            </Stack>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {othersformValues.map((option, index) => (
                <Grid
                  item
                  xs={6}
                  lg={1}
                  sm={3}
                  sx={{ textAlign: "center", alignContent: "center" }}
                >
                  <Avatar
                    onClick={() => { getUnEncrypedFiles(option?.fileName) }}
                    src={option.fileURL}
                    variant="square"
                    sx={{
                      width: "100%",
                      height: 200,
                      mb: 2.5,
                      background: "#f9f9f9",
                      padding: "4px",
                      "& img": {
                        maxWidth: "100%",
                        height: "auto",
                      },
                    }}
                  />
                  <Typography variant="body1" align="center">
                    {option.name}
                  </Typography>
                  <IconButton
                    disabled={disableForm}
                    onClick={() => removeFile(index, "others")}
                    aria-label="delete"
                    size="large"
                    color="error"
                  >
                    <Icons.Delete />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </ItemPaper>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 3 }}
      >
        <Button
          type="button"
          onClick={() => updateUser()}
          disabled={disableForm || DisabledButton}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </>
  );
}
