import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, IconButton, TablePagination } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from "material-react-toastify";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { votepollingService } from "../../service/Votepolling-service";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { useState } from "react";
import { IResource } from "../../Interfaces/IResource";
import { clsResourcesList } from "../../service/common-services/resources";
import * as Icons from "../../assests/icons/icons";
const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function PollingSystem() {
    const _permission = useSelector(
        (state: any) => state.SeasiagalaPermissionApp.SeasiagalaPermission
    );
    const [selectedCatId, setSelectedCatId] = React.useState<any>("");
    const [ItemList, setItemList] = React.useState<any[]>([]);
    const [description, setDescription] = React.useState<any>("");
    const [categories, setCategories] = React.useState<any>([]);
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [addButtonClick, setAddButtonClick] = React.useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [departmentsId, setdepartmentsId] = useState("");
    const [resourceId, setResourceId] = useState("");
    const navigate = useNavigate();
    React.useEffect(() => {
        if (_permission.page_Nominee_Setup_Access !== true) {
            navigate("/dashboard");
          }
        getAllcategory();
        const departmentsData = async () => {
            setDepartments(await clsDepartmentList.getDepartmentDropdownList());
            setresources(await clsResourcesList.getResourcesDropdownList(""));
        };
        departmentsData();
    }, []);

    //Get All Inventory Items --Api call
    const getAllcategory = async () => {
        try {
            votepollingService.getAllEventCategory().then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    setToggleLoader(false);
                    setCategories(response?.data);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }
    const addUserInCategory = async () => {
        let input = {
            eventCategoryId: selectedCatId,
            userId: resourceId,
            departmentId: departmentsId
        }
        try {
            votepollingService.addUserInCategory(input).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    setResourceId("");
                    toast.success("User Added Successfully")
                    getCategoryDetailsById(selectedCatId)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }

    const deleteUserFromCat = async (id: any) => {
        debugger
        let abc = {
            id: selectedCatId,
            userId: id
        }
        await votepollingService.deleteCatById(abc).then((response: any) => {
            if (response.data.isError) {
            }
            else {
                getCategoryDetailsById(selectedCatId)
                toast.success('User Deleted successfully.');
            }
        })
    }

    const getCategoryDetailsById = async (catId: any) => {
        try {
            await votepollingService.getEventCategoryById(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setItemList(response?.data?.pollingUsers)
                        setDescription(response?.data?.description)
                        // getAllInventoryItems(catId, [], []);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    };

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    const handleChangeRowsPerPage = (event: any) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRowsPerPage(selectedValue);
        setPage(0);
    };
    const pageCount = Math.ceil(ItemList.length / rowsPerPage);
    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Link color="inherit" to="/">
                        Digital Voting
                    </Link>

                </Breadcrumbs>
                <Box>{description}</Box>
                <Stack justifyContent={'right'} direction={'row'} >
                    {!addButtonClick ? (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ mb: 2 }}
                            startIcon={<AddIcon />}
                            onClick={() => {
                                if (!selectedCatId) {
                                    toast.warn("Please select Category First")
                                }
                                else { setAddButtonClick(true) }
                            }}
                        // onClick={() => { setAddButtonClick(true) }}
                        >
                            Add Nominee
                        </Button>) : (
                        <Box sx={{ display: "flex", alignItems: "center", gap: "20px", mb: 2 }}>
                            <Box>
                                <Autocomplete
                                    value={departments?.find(
                                        (option) => option.id == departmentsId
                                    ) ?? null}
                                    fullWidth
                                    sx={{ ml: "15px" }}
                                    id="department-drop"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setdepartmentsId(value?.id ?? "");
                                        setresources(
                                            await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                                        );
                                        setResourceId("");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="department-drop"
                                            name="departmentsId"
                                            //label="Departments"
                                            variant="outlined"
                                            value={departmentsId}
                                            fullWidth
                                            sx={{ minWidth: 215 }} />
                                    )} />
                            </Box>
                            <Box>
                                <Autocomplete
                                    disabled={!departmentsId || departmentsId === ""}
                                    value={resources?.find(
                                        (option) => option.userId == resourceId
                                    ) ?? null}
                                    fullWidth
                                    sx={{ ml: "8px" }}
                                    id="resources-drop"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={async (event, value) => {
                                        setResourceId(value?.userId ?? "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="resources-drop"
                                            name="resourceId"
                                            //label="Departments"
                                            variant="outlined"
                                            value={resourceId}
                                            fullWidth
                                            sx={{ minWidth: 200 }} />
                                    )} />
                            </Box>
                            <Button
                                variant="contained"
                                size="large"

                                onClick={() => { addUserInCategory(); }}
                            >
                                Save
                            </Button>
                            <Button
                                variant="outlined"
                                size="large"

                                onClick={() => { setAddButtonClick(false); setdepartmentsId(""); }}
                            >
                                Cancel
                            </Button>
                        </Box>

                    )}
                </Stack>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box height={'auto'}>
                            <Paper sx={{
                                height: '100%', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Stack p={2} justifyContent={'space-between'} alignItems={'start'} sx={{ borderBottom: "1px solid #EEEEEE", background: '#F0F9FF', borderRadius: '4px' }}>
                                    <Autocomplete
                                        disablePortal
                                        value={categories?.find(
                                            (option: any) => option.id == selectedCatId
                                        ) ?? null}
                                        id="combo-box-demo"
                                        options={categories}
                                        getOptionLabel={(option: any) => option.categoryName}
                                        onChange={async (event, value) => {
                                            if (!value?.id) {
                                                setSelectedCatId("")
                                            }
                                            else {
                                                setPage(0);
                                                setAddButtonClick(false)
                                                setResourceId("");
                                                setdepartmentsId("");
                                                getCategoryDetailsById(value?.id);
                                                setSelectedCatId(value?.id ?? "")
                                            }
                                        }}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Select Category" />}
                                    />
                                </Stack>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'100%'} >
                            <Paper sx={{
                                height: '100%', padding: '16px', paddingBottom: '0', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Box>
                                    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 540 }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={headcellcolor.cellcolor}>S.No</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Name</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Department</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', background: 'rgb(189, 189, 189)' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ItemList?.map((item: any, index: any) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row"> {index + 1} </TableCell>
                                                        <TableCell component="th" scope="row"> {item.userName || "--"} </TableCell>
                                                        <TableCell>{item.departmentName || "--"}</TableCell>
                                                        <TableCell><IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteUserFromCat(item.userId)}
                                                        >
                                                            <Icons.Delete />
                                                        </IconButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* <TablePagination
                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        component="div"
                                        count={ItemList.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={() => `Total: ${ItemList?.length}  Page ${page + 1} of ${pageCount}`}
                                    /> */}
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >
                </Grid >
            </Box >
        </>
    )
}
