 
import { masterreasonServices } from '../BrowniePoints/masterReason-services';

export class clsBrowniePointsList {
    constructor() {
    }

    public static async getBrowniePointsList() { 
        let data:Array<any>=await this.getBrowniePointsData()  
        return data;
    }
    public static async getBrowniePointsData(){
        try { 
            var response = await masterreasonServices.getReason(); 
            if (response.data.isError) {
                return [];
            } else {
                return response.data;
            }
        } catch (ex: any) {
            console.error("ex:", ex);
        }
    }
}