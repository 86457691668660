
import { Observable } from "redux";
import { commonServive } from "./service";

interface ICategory {
    id: any,
    category: any
}
interface ICategoryList {
    categories: Array<ICategory>;
}
export class clsCategoryList implements ICategoryList {
    categories: ICategory[] = [];
    constructor() {
    }
    public static async getCategoryDropdownList() {
        var departmentFirstIndex = { id: "", category: "Select Category" };
        let data: Array<any> = await this.getCategoryData()
        let returnData: Array<any> = [];
        if (data.length > 0) {
            let arr = [departmentFirstIndex];
            returnData = arr.concat(data);
        }
        else {
            returnData.push(departmentFirstIndex);
        }

        return returnData;
    }
    public static async getCategoryData() {
        try {
            var response = await commonServive.getCategoryDropdown();
            if (response.data.isError) {
                return [];
            } else {
                return response.data;
            }
        } catch (ex: any) {
            console.error("ex:", ex);
        }
    }
}
