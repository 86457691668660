
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import { Avatar, TableCell, TablePagination, TableRow } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { TextField } from '@mui/material';
import { milestoneTreeService } from '../../service/Reports/milestonereportTree';
import moment from 'moment';
import { Department } from '../../Interfaces/IDepartment';
import Rating from '@mui/material/Rating';
import { clsDepartmentList } from '../../service/common-services/department';
import Button from '@mui/material/Button';
import theme from "../../theme/theme";
import { notificationDetailsServices } from '../../service/Notification/notification-service';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Icons from "../../assests/icons/icons";
import { commonFunctions } from '../../service/common-services/common-functions';
export default function ClientReview() {
  const _permission = useSelector((state: any) => state.PrismPermissionApp.PrismPermission);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [userName, setUsername] = React.useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (_permission.page_Client_Feedback !== true) {
      navigate("/dashboard")
    }
    const currentLoggedInUserName = _authUser.employeeInfo.currentUserName;
    setUsername(currentLoggedInUserName);
    // getAllProjects("", 0);
    getAllProjectsByClientId(_authUser.clientId);
    GetAllClientFeedback("", "");
    GetClientFeedbackReport("");
    departmentData();
  }, []);
  const departmentData = async () => {
    setDepartments(await clsDepartmentList.getDepartmentDropdownList());
  }

  const [allProjList, setallProjList] = React.useState<any>([]);
  const [toggleLoader, setToggleLoader] = React.useState(false);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [selectedNodeId, setSelectedNodeId] = React.useState("");
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageCount = Math.ceil(allProjList.filter((d: any) => !!d.projectName).length / rowsPerPage1);
  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };
  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  const [showForm, setShowForm] = React.useState(true);
  console.log(showForm, 'showForm')
  const [expandedTree, setExpandedTree] = React.useState<string[]>([]);
  const [feedback, setFeedBack] = React.useState<any>([]);
  const [reports, setReports] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>("");
  console.log(expandedTree, "expandedTree")
  console.log(allProjList, "allProjList");
  console.log(feedback, "feedback");
  console.log(reports, "reports");
  console.log(data, "data");
  console.log(showForm, "showform");

  const getAllProjectsByClientId = async (id: any) => {
    await notificationDetailsServices.GetProjectsByClientId(id).then((response: any) => {
      if (response.data.isError) {

      } else {
        const filteredData = response?.data.filter((name: any) => {
          return name.projectName !== null && name.projectName.trim() !== "";
        });
        const sortedProjects = filteredData.sort((a: any, b: any) => {
          if (a.projectName?.toLowerCase() < b.projectName?.toLowerCase()) {
            return -1;
          }
          if (a.projectName?.toLowerCase() > b.projectName?.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setallProjList(sortedProjects);
      }
    });
  };


  const GetClientFeedbackReport = async (ProjectId: any) => {
    var req = {
      projectId: ProjectId,
      departmentId: "",
    }
    try {
      const response = await notificationDetailsServices.GetClientFeedbackReport(req);
      if (response.data.isError) {
        toast.error("Something went wrong while fetching data");
      } else {
        setData(response?.data);
        setSelectedProjectId(ProjectId);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
      });
    }
  };
  const GetAllClientFeedback = async (month: any, year: any) => {

    if (month === "") month = new Date().getMonth() + 1;
    if (year === "") year = new Date().getFullYear();

    var req = {
      clientId: _authUser.id,
      month: month,
      year: year
    }
    try {
      debugger
      const response = await notificationDetailsServices.GetAllClientFeedback(req);
      if (response.data.isError) {
        toast.error("Something went wrong while fetching data");
      } else {
        setFeedBack(response?.data);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {

      });
    }
  };
  const questions = [
    "How would you rank our company's overall satisfaction?",
    "How would you rate our customer service?",
    "How satisfied are you with our product quality?",
    "How likely are you to recommend us to others?",
    "How responsive were we to your inquiries?"
  ];
  const [formData, setFormData] = React.useState([
    { comment: '', ratings: Array(questions.length).fill(0) }
  ]);
  const addForm = () => {
    setFormData(prevState => [...prevState, { comment: '', ratings: Array(questions.length).fill(0) }]);
  };
  const handleCommentChange = (index: any, value: any) => {
    const updatedFormData = [...formData];
    updatedFormData[index].comment = value;
    setFormData(updatedFormData);
  };
  const handleRatingChange = (index: any, questionIndex: any, value: any) => {
    const updatedFormData = [...formData];
    updatedFormData[index].ratings[questionIndex] = value;
    setFormData(updatedFormData);
  };

  const selectedProject = allProjList.find((project: any) => project.projectId === selectedProjectId);
  if (selectedProject) {
    const projectName = selectedProject.projectName;
    console.log("Project Name:", projectName);
  } else {
    console.log("Project not found");
  }

  const addClientFeedBack = async (clientFeedbackType: number, currentcomment: any, ratingValues: any) => {
    try {
      debugger
      const data = allProjList.length > 0 ? allProjList[0] : {};
      const req = {
        projectId: selectedProjectId,
        projectName: selectedProject.projectName,
        clientFeedback: ratingValues.map((value: any, index: any) => ({ clientFeedbackType: index + 1, ratingValue: value !== null ? value : 0 })),
        description: currentcomment
      }
      const response = await notificationDetailsServices.addClientFeedBack(req);
      if (response.data.isError) {
        toast.error("Something went wrong while adding client feedback");
      } else {
        setFormData([{ comment: '', ratings: Array(questions.length).fill(0) }]);
        GetClientFeedbackReport(selectedProjectId);
        GetAllClientFeedback("", "");
        setData([]);
        setFeedBack([]);
        setReports([]);
        setShowForm(false);
        setRatingValue(null);
        toast.success("Client feedback added successfully");
      }
    } catch (ex: any) {
      if (ex.response && ex.response.data && ex.response.data.Error) {
        ex.response.data.Error.forEach((err: any, idx: any) => {
        });
      } else {
        console.error(ex);
        toast.error("An error occurred while processing your request");
      }
    }
  };
  const [ratingValue, setRatingValue] = React.useState<any>(null);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const previousMonths = months.slice(0, currentMonthIndex + 1);
  const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedStatus = event.target.value as number;
    setRatingValue(selectedStatus);
  };
  const getClientFeedBackById = async () => {
    setToggleLoader(true);
    try {
      var req = {
        id: "65af808c0d01d369780207e1"
      }
      await notificationDetailsServices
        .GetClientFeedbackById(req)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
            setToggleLoader(false);
          } else {
            setReports(response?.data);
            setToggleLoader(false);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }
  };
  const removeFormFields = (indexToRemove: number) => {
    setFormData(prevState => prevState.filter((_, index) => index !== indexToRemove));
  };
  const [monthsWithForms, setMonthsWithForms] = React.useState<number[]>([]);


  return (
    <Paper>
      <Box >
        <Stack>
          <Typography variant="h6" sx={{ fontWeight: 600, paddingLeft: "20px" }} component="h2">
            Client Review
          </Typography>
        </Stack>
        <TreeView
          aria-label="multi-select"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={(a: any, b: any) => {
            setSelectedNodeId(b);
          }}
          expanded={expandedTree}
          sx={{ flexGrow: 1, maxWidth: '100%', overflowY: 'auto', padding: 0 }}
        >
          {allProjList.filter((d: any) => !!d.projectName).slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1).map((item: any, idx: any) => (
            <TreeItem sx={{
              backgroundColor: '#fff', marginBottom: '10px', borderRadius: '10px', '&:hover': { background: 'white' }, '& .Mui-selected': { backgroundColor: 'white !important', borderRadius: '10px' }, '& .MuiTreeItem-content': {
                '&:hover': { backgroundColor: 'white', borderRadius: '10px' }
              }
            }}
              nodeId={item.projectId} label={
                <Grid container>
                  <Grid item sm={12} sx={{ paddingBlock: "8px", fontWeight: "700", paddingBottom: "8px", borderBottom: "1px solid #f1f1f1" }}>{item.projectName}</Grid>
                </Grid>}
              onClick={(a: any) => {
                setSelectedProjectId(item.projectId);
                GetClientFeedbackReport(item?.projectId);
                item.expanded = !item.expanded;
                item.expanded1 = false;
                let existing = [...expandedTree];
                if (item.expanded) {
                  existing = [item.projectId];
                  allProjList.forEach((element: any) => {
                    if (element.projectId != item?.projectId)
                      element.expanded = false;
                  });
                  item.expanded1 = false;
                  item.expanded2 = false;
                  item.expanded3 = false;
                } else {
                  existing.splice(existing.indexOf(item.projectId), 1);
                  existing.splice(existing.indexOf(item?.projectId + "-2"), 1);
                  existing.splice(existing.indexOf(item?.projectId + "-3"), 1);
                  existing.splice(existing.indexOf(item?.projectId + "-4"), 1);
                }
                console.log("First", existing)
                setExpandedTree(existing);
              }}
            >
              <Box sx={{ paddingBlock: "6px" }}></Box>
              {previousMonths.map((ele, index) => (
                <TreeItem
                  key={`${item.projectId}-month-${index}`}
                  nodeId={`${item.projectId}-month-${index}`}
                  label={
                    <Grid container sx={{}}>
                      <Grid item sm={12} sx={{ paddingBlock: "8px", borderBottom: "1px solid #f1f1f1", fontWeight: "600" }}>{ele}</Grid>
                    </Grid>
                  }

                  onClick={() => {
                    let existing = [...expandedTree];
                    const nodeToToggle = `${item.projectId}-month-${index}`;
                    const isExpanded = existing.includes(nodeToToggle);
                    if (isExpanded) {
                      existing = existing.filter(node => node !== nodeToToggle);
                    } else {
                      existing = existing.filter(node => !node.startsWith(item?.projectId + "-month-"));
                      existing.push(nodeToToggle);
                    }
                    setExpandedTree(existing);
                    setShowForm(false);
                  }}
                >
                  <Grid container spacing={2}>
                    {data.map((feedbackItem: any) => {
                      const createdDate = moment(feedbackItem.createdDate);
                      const feedbackMonth = createdDate.month();
                      const feedbackYear = createdDate.year();
                      const currentYear = new Date().getFullYear();
                      if (feedbackMonth === index && feedbackYear === currentYear) {
                        debugger
                        return (
                          <>
                            <Box sx={{ marginBlock: "25px", width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                              <React.Fragment key={feedbackItem.id}>
                                {feedbackItem.clientFeedback.map((item: any, index: number) => (
                                  <React.Fragment key={index}>
                                    <Grid item lg={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #f1f1f1", paddingInline: "20px" }}>
                                      <Box><TableCell sx={{ border: "0" }}>{item.clientFeedbackType}.</TableCell>
                                        <TableCell sx={{ border: "0" }}>{questions[index]}</TableCell></Box>
                                      <Rating value={item.ratingValue} readOnly />
                                    </Grid>
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                              <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TableCell sx={{ border: 'none', width: '100%' }}>
                                  <Box>
                                    <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} sx={{ m: '0 0 8px 0' }}>
                                      <Stack justifyContent={'center'} alignItems={'center'} sx={{ color: "#fff", width: '38px', height: '38px', borderRadius: '50%', background: '#ff5454', mr: '16px' }}>
                                        <Avatar
                                          {...commonFunctions.stringAvatar(userName)}
                                          src={`${process.env.REACT_APP_SERVER_URL}${userName}`}
                                        />
                                      </Stack>
                                      <Box>
                                        <Typography sx={{ color: theme.palette.primary.main }}>
                                          {userName}
                                        </Typography>
                                        <Typography>
                                          {`${moment(feedbackItem?.createdDate).format("DD MMM, YYYY")}`}
                                        </Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '300' }}>
                                          {feedbackItem.description}
                                        </Typography>
                                      </Box>
                                    </Stack>

                                  </Box>
                                </TableCell>
                              </TableRow>
                            </Box>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                  {showForm && monthsWithForms.includes(index) && (
                    <React.Fragment>
                      {questions.map((question, questionIndex) => (
                        <TableRow key={questionIndex} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ddd' }}>
                          <TableCell sx={{ border: 'none' }} align="center">
                            {`${questionIndex + 1}. ${question}`}
                          </TableCell>
                          <TableCell sx={{ border: 'none' }}>
                            <Rating
                              name={`rating-${questionIndex}`}
                              precision={1}
                              value={formData[0].ratings[questionIndex]}
                              onChange={(event, newValue) => handleRatingChange(0, questionIndex, newValue)} />
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow key={`comment-0`} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TableCell sx={{ border: 'none', width: '100%' }}>
                          <Typography>Suggestions or Comments</Typography>
                          <Box >
                            <TextField
                              id={`outlined-multiline-flexible-0`}
                              value={formData[0].comment}
                              sx={{ marginBlock: "16px" }}
                              multiline
                              placeholder="Type Something..."
                              type="text"
                              onChange={(e) => handleCommentChange(0, e.target.value)}
                              variant="outlined"
                              fullWidth />
                            <Button
                              variant="contained"
                              disabled={index !== currentMonthIndex || !previousMonths[index - 1]}

                              onClick={() => addClientFeedBack(1, formData[0].comment, formData[0].ratings)}
                            >
                              Submit
                            </Button>
                            <Button
                              endIcon={<Icons.Delete />}
                              color="error"
                              onClick={() => {
                                setShowForm(false)
                              }
                              }
                            ></Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )}
                  <Button
                    variant="outlined"
                    sx={{ marginTop: "30px" }}
                    onClick={() => {
                      setShowForm(true);
                      addForm();
                      setMonthsWithForms(prevMonths => [...prevMonths, index]);
                    }}
                    disabled={index !== currentMonthIndex || !previousMonths[index - 1]}
                  >
                    {!data.some((feedbackItem: any) => {
                      const createdDate = moment(feedbackItem.createdDate);
                      return (
                        createdDate.month() === index &&
                        createdDate.year() === new Date().getFullYear()
                      );
                    }) ? "Submit" : "Re-Submit"}
                  </Button>

                </TreeItem>
              ))}
              {/* ))} */}
            </TreeItem>
          ))}
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={allProjList.filter((d: any) => !!d.projectName).length}
            rowsPerPage={rowsPerPage1}
            page={page1}
            onPageChange={handleChangePage1}
            onRowsPerPageChange={handleChangeRowsPerPage1}
            labelDisplayedRows={() => `Total: ${allProjList.filter((d: any) => !!d.projectName)?.length}  Page ${page1 + 1} of ${pageCount}`}
          />
        </TreeView>
      </Box>
    </Paper>
  );
}