
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, IconButton, Menu, MenuItem, TablePagination, Typography } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as Icons from "../../assests/icons/icons";
import { inventoryService } from "../../service/Inventory/inventoryService";
import { toast } from "material-react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import { attributeenum } from "../users/categorymodel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clsCategoryList } from "../../service/common-services/category";
import moment from "moment";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { clsResourcesList } from "../../service/common-services/resources";

const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}

interface Resource {
    userId: string;
    userName: string;
}
export default function InventoryReport() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const [catId, setcatId] = React.useState<any>("");
    const [selectedCatId, setSelectedCatId] = React.useState<any>("");
    const [itemList, setItemList] = React.useState<any[]>([]);
    let itemCount = itemList.length;
    const [categories, Setcategories] = React.useState<any>([]);
    const [brands, Setbrands] = React.useState<any[]>([]);
    const [attributes, setattributes] = React.useState<attributeenum[]>([]);
    const [expanded, setExpanded] = React.useState(false);
    const [expandedBrand, setExpandedBrand] = React.useState(false);
    const [statusDataHook, setStatusDataHook] = React.useState("All");
    const [itemStatus, setItemStatus] = React.useState(null);
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [selectedDepartmentId, setselectedDepartmentId] = React.useState("");
    const [selectedResourcesId, setSelectedResourcesId] = React.useState("");
    var resourceData = { userId: "", userName: "Select Resource" };
    const [resources, setresources] = React.useState<Resource[]>([resourceData]);

    const filterItemByStatus = (status: any, statusValue: any) => {
        setAnchorEl(null);
        setStatusDataHook(status);
        setItemStatus(statusValue);
        getAllInventoryItems(catId, selectedBrands, setConfiguration, statusValue, selectedDepartmentId, selectedResourcesId);
    };
    interface StatusEnum {
        [key: number]: string;
    }
    const [selectedBrands, setSelectedBrands] = React.useState<any[]>([]);
    const [setConfiguration, setSetConfiguration] = React.useState<attributeenum[]>([]);
    const statusEnum: StatusEnum = {
        1: 'In Stock',
        2: 'Issued',
        3: 'Request to Return'
    };

    function getStatusName(status: number) {
        return statusEnum[status] || '';
    }

    const handleBrandCheckboxChange = (brandName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSelectedBrands = event.target.checked
            ? [...selectedBrands, brandName]
            : selectedBrands.filter((name) => name !== brandName);
        setSelectedBrands(updatedSelectedBrands);
        getAllInventoryItems(
            catId,
            updatedSelectedBrands,
            setConfiguration,
            itemStatus,
            selectedDepartmentId,
            selectedResourcesId
        );
    };

    const handleValueCheckboxChange = (attribute: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSelectedAttributes = [...setConfiguration]; // Create a copy of the selectedAttributes array
        // Check if the selected attribute is already in the array
        const selectedAttributeIndex = updatedSelectedAttributes.findIndex(attr => attr.attribute === attribute);
        if (selectedAttributeIndex === -1) {
            // If the attribute is not in the array, add it with the selected value
            updatedSelectedAttributes.push({ attribute, value: [value] });
        } else {
            // If the attribute is already in the array, update its values
            const selectedAttribute = updatedSelectedAttributes[selectedAttributeIndex];
            if (event.target.checked) {
                selectedAttribute.value.push(value);
            } else {
                selectedAttribute.value = selectedAttribute.value.filter((val: any) => val !== value);

                // Check if there are no selected values left for this attribute, and if so, remove the attribute
                if (selectedAttribute.value.length === 0) {
                    updatedSelectedAttributes.splice(selectedAttributeIndex, 1);
                }
            }
        }
        setSetConfiguration(updatedSelectedAttributes);
        getAllInventoryItems(catId, selectedBrands, updatedSelectedAttributes, itemStatus, selectedDepartmentId, selectedResourcesId); // Pass the updatedSelectedAttributes instead of setConfiguration
    };

    const getDepartmentDat = async () => {
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
    }

    React.useEffect(() => {
        setcatId("");
        categoryData();
        getDepartmentDat();
    }, []);
    const categoryData = async () => {
        Setcategories(await clsCategoryList.getCategoryDropdownList())
    }

    //Get All Inventory Items --Api call
    const getAllInventoryItems = async (categoryId: string, brand: any[], setSetConfiguration: any[], status: any, selectedDepartmentId: any, selectedResourcesId: any) => {
        try {
            setToggleLoader(true);
            const brandObject = brand.map((brandName) => ({ brandName }));
            let input = {
                categoryId: categoryId,
                brand: brandObject,
                setConfiguration: setSetConfiguration,
                status: status,
                departmentId: selectedDepartmentId,
                userId: selectedResourcesId
            }
            await inventoryService.getAllInventoryItemsForreport(input).then((response: any) => {
                if (response.data.Error) {
                    setToggleLoader(false);
                } else {
                    setToggleLoader(false);
                    setItemList(response?.data);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }

    const getCategoryDetailsById = async (catId: any) => {
        try {
            await inventoryService.getCategorybyId(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        Setbrands([]);
                        Setbrands(response.data.brand);
                        setattributes(response.data.setConfiguration);
                        setcatId(catId);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warn(err);
            });
        }
    };
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
   
    const [page, setPage] = React.useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRowsPerPage(selectedValue);
        setPage(0);
    };

    const numCount = Math.ceil(itemList.length / rowsPerPage);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageData = itemList.slice(startIndex, endIndex);
    const pageCount = Math.ceil(currentPageData.length / rowsPerPage);

    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Link color="inherit" to="/">
                        Inventory Report
                    </Link>

                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box height={'auto'}>
                            <Paper sx={{
                                height: '100%', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Stack p={2} justifyContent={'space-between'} alignItems={'start'}
                                    sx={{ borderBottom: "1px solid #EEEEEE", background: '#F0F9FF', borderRadius: '4px', gap: '18px' }}>
                                    <Autocomplete
                                        value={
                                            departments?.find(
                                                (option) => option.id == selectedDepartmentId
                                            ) ?? null
                                        }
                                        fullWidth
                                        id="outlined-basic"
                                        options={departments}
                                        getOptionLabel={(option) => option.name}
                                        onChange={async (event, value) => {
                                            if (!value?.id) {
                                                setSelectedResourcesId("");
                                                setselectedDepartmentId("");
                                                setItemList([]);
                                            }
                                            else {
                                                setselectedDepartmentId(value?.id ?? "");
                                                setresources(
                                                    await clsResourcesList.getResourcesDropdownList(
                                                        value?.id ?? ""
                                                    )
                                                );
                                                await getAllInventoryItems(
                                                    catId,
                                                    selectedBrands,
                                                    setConfiguration,
                                                    itemStatus,
                                                    value?.id,
                                                    selectedResourcesId
                                                );
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="outlined-basic"
                                                name="element.name"
                                                variant="outlined"
                                                value={selectedDepartmentId}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        disabled={!selectedDepartmentId || selectedDepartmentId === ""}
                                        value={
                                            resources?.find(
                                                (option) => option.userId == selectedResourcesId
                                            ) ?? null
                                        }
                                        fullWidth
                                        id="outlined-basic"
                                        options={resources}
                                        getOptionLabel={(option) => option.userName}
                                        onChange={async (event, value) => {
                                            await setSelectedResourcesId(value?.userId ?? "");
                                            getAllInventoryItems(
                                                catId,
                                                selectedBrands,
                                                setConfiguration,
                                                itemStatus,
                                                selectedDepartmentId,
                                                value?.userId
                                            );
                                        }}
                                        componentsProps={{
                                            popper: {
                                              modifiers: [
                                                {
                                                  name: 'flip',
                                                  enabled: true,
                                                  options: {
                                                    altBoundary: true,
                                                    flipVariations: true,
                                                    altAxis: true,
                                                    padding: 8,
                                                    fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                  },
                                                },
                                              ],
                                            },
                                          }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="outlined-basic"
                                                name="element.name"
                                                variant="outlined"
                                                value={selectedResourcesId ?? ""}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        disabled={!selectedDepartmentId || selectedDepartmentId === ""}
                                        value={categories?.find(
                                            (option: any) => option.id == selectedCatId
                                        ) ?? null}
                                        id="combo-box-demo"
                                        options={categories}
                                        getOptionLabel={(option: any) => option.category}
                                        onChange={async (event, value) => {
                                            debugger
                                            if (!value) {
                                                Setbrands([]);
                                                setattributes([]);
                                                setcatId("");
                                                setSelectedCatId("");
                                            }
                                            else if(value){
                                            getCategoryDetailsById(value?.id);
                                            setSelectedCatId(value?.id ?? "")}
                                            await getAllInventoryItems(
                                                value?.id ?? "",
                                                selectedBrands,
                                                setConfiguration,
                                                itemStatus,
                                                selectedDepartmentId,
                                                selectedResourcesId
                                            );
                                        }}
                                        componentsProps={{
                                            popper: {
                                              modifiers: [
                                                {
                                                  name: 'flip',
                                                  enabled: true,
                                                  options: {
                                                    altBoundary: true,
                                                    flipVariations: true,
                                                    altAxis: true,
                                                    padding: 8,
                                                    fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                  },
                                                },
                                              ],
                                            },
                                          }}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <Typography sx={{ margin: ' 0 0 0 16px', fontSize: '14px', fontWeight: '300' }}>
                                        {itemCount} Items
                                    </Typography>
                                    <Box>
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick1}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            disabled={!selectedDepartmentId || selectedDepartmentId === ""}
                                        >
                                            {statusDataHook}
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => { filterItemByStatus("All", null); }}>All</MenuItem>
                                            <MenuItem onClick={() => { filterItemByStatus("Assigned", "2"); }}>Assigned</MenuItem>
                                            <MenuItem onClick={() => { filterItemByStatus("In Stock", "1"); }}>In Stock</MenuItem>
                                        </Menu>
                                        <Button sx={{ ml: "20px" }} onClick={() => {
                                            setSelectedBrands([]); setSetConfiguration([]); setcatId("");
                                            setSelectedCatId(""); setExpanded(false); setattributes([]);
                                            setItemStatus(null); setStatusDataHook("All"); setExpandedBrand(false); Setbrands([]);
                                            setSelectedResourcesId(""); setselectedDepartmentId(""); setresources([resourceData]);
                                            setItemList([]);
                                        }}>Clear All</Button>
                                    </Box>
                                </Stack>
                                <Box sx={{ padding: '18px' }}>
                                    <Typography sx={{ marginBottom: "10px", display: "flex", fontWeight: '500' }}>
                                        <FilterAltOutlinedIcon color="primary" /> Filter
                                    </Typography>
                                    <Accordion expanded={expanded} sx={{ boxShadow: "none" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            onClick={() => setExpanded(!expanded)}
                                        >
                                            <Typography fontWeight="500">Brand</Typography>
                                        </AccordionSummary>
                                        {brands.map((brand) => (
                                            <Stack key={brand.id} paddingLeft={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedBrands.includes(brand.brandName)}
                                                            onChange={handleBrandCheckboxChange(brand.brandName)}
                                                        />
                                                    }
                                                    label={brand.brandName}
                                                />
                                            </Stack>
                                        ))}
                                    </Accordion>
                                    {attributes.map((att, index) => (
                                        <Accordion expanded={expandedBrand} key={index} sx={{ boxShadow: "none" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${index}a-content`}
                                                id={`panel${index}a-header`}
                                                onClick={() => setExpandedBrand(!expandedBrand)}
                                            >
                                                <Typography fontWeight="500">{att.attribute}</Typography>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {att.value.map((value, valueIndex) => (
                                                    <Stack key={value} paddingLeft={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    // checked={selectedValues.includes(value)}
                                                                    onChange={(event) => handleValueCheckboxChange(att.attribute, value, event)}
                                                                    value={valueIndex}
                                                                />
                                                            }
                                                            label={value}
                                                        />
                                                    </Stack>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'100%'} >
                            <Paper style={{ height: 'calc(100vh - 125px )' }} sx={{
                                padding: '16px', paddingBottom: '0', "& .css-1ps6pg7-MuiPaper-root": {

                                }
                            }} >
                                <Box>
                                    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: "500px", overflowY: "auto" }}>
                                        <Table sx={{ minWidth: "100%" }} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={headcellcolor.cellcolor}>Item Type</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>SKU</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Brand</TableCell>
                                                    {/* <TableCell sx={headcellcolor.cellcolor}>Model</TableCell> */}
                                                    <TableCell sx={headcellcolor.cellcolor}>Price(Rs.)</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Year of Purchase</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Assigned To</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Status</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', background: 'rgb(189, 189, 189)' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentPageData.map((item: any, index: any) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row"> {item.itemName} </TableCell>
                                                        <TableCell>{item.sku}</TableCell>
                                                        <TableCell key={index}>
                                                            {item.brand.map((brandItem: any, brandIndex: any) => (
                                                                <span key={brandIndex}>
                                                                    {brandItem.brandName}
                                                                    {brandIndex < item.brand.length - 1 ? ', ' : ''}
                                                                </span>
                                                            ))}
                                                        </TableCell>
                                                        {/* <TableCell>{item.itemName} </TableCell> */}
                                                        <TableCell>{item.costPrice}</TableCell>
                                                        <TableCell >{item.dateOFPurchase ? moment(item.dateOFPurchase).format("DD-MMM-YYYY") : "--"}</TableCell>
                                                        <TableCell>{item.assignToName ? item?.assignToName : "--"}</TableCell>
                                                        <TableCell>{getStatusName(item.status)}</TableCell>
                                                        <TableCell >
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="start"
                                                                spacing={1}
                                                            >
                                                                <IconButton
                                                                    aria-label="view"
                                                                    component={Link}
                                                                    to={`/inventory-viewitem/${item.id}`}
                                                                    size="medium"
                                                                >
                                                                    <Icons.View />
                                                                </IconButton>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        component="div"
                                        count={currentPageData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                      
                                        labelDisplayedRows={() => `Total: ${currentPageData?.length}  Page ${page + 1} of ${pageCount}`}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >

                </Grid >
            </Box >
        </>
    )
}
