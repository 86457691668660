import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Comment from "./Comment";
import History from "./History";
import WorkLog from "./WorkLog";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ActivityPanel({
  TicketcreatedBy,
  TicketId,
  workLogList,
  getAllTicketWorkLog,
}: any) {
  const [value, setValue] = React.useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 3 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="All"
            {...a11yProps(0)}
            sx={{ p: 1, minWidth: "max-content" }}
          />
          <Tab
            label="Comment"
            {...a11yProps(1)}
            sx={{ p: 1, minWidth: "max-content" }}
          />
          <Tab
            label="History"
            {...a11yProps(2)}
            sx={{ p: 1, minWidth: "max-content" }}
          />
          <Tab
            label="Work Log"
            {...a11yProps(3)}
            sx={{ p: 1, minWidth: "max-content" }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {/* No Content Found */}
        {/* <Comment TicketcreatedBy={TicketcreatedBy} TicketId={TicketId} /> */}
        <History TicketId={TicketId} />
        <WorkLog workLogList={workLogList} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Comment TicketcreatedBy={TicketcreatedBy} TicketId={TicketId} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <History TicketId={TicketId} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <WorkLog workLogList={workLogList} getAllTicketWorkLog={getAllTicketWorkLog}/>
      </TabPanel>
    </Box>
  );
}
