import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { clsDepartmentList } from "../../../service/common-services/department";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as Icons from "../../../assests/icons/icons";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import Notes from "../../../components/styledComponent/Note";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TablePagination from "@mui/material/TablePagination";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Avatar, Chip, Divider, Tooltip } from "@mui/material";
import { toast } from "material-react-toastify";
import NotFound from "../../../components/styledComponent/NotFound";
import theme from "../../../theme/darkTheme";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { brownieemployeeservice } from "../../../service/master-service/brownieEmployee-service";
import { Formik } from "formik";
import { format } from "date-fns";
import moment from "moment";
import { clsBrowniePointsList } from "../../../service/common-services/browniePoints";
import { useSelector } from "react-redux";
import { Manager } from "../../../Interfaces/IManager";
import { payRewardServices } from "../../../service/BrowniePoints/payReward-services";
import { masterreasonServices } from "../../../service/BrowniePoints/masterReason-services";
import InfoPopover from "../../../components/CommonInfoComponent";

const styleCross = {
  position: "absolute" as "absolute",
  right: "15px",
  top: "15px",
};

const options = ["Select Points", "Brownie Points", "Muffin Points"];

const styles = {
  view: {
    label: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#454545",
    },
    value: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      color: theme.palette.primary.dark,
    },
  },
};

interface Department {
  id: string;
  name: string;
}

interface IBrowniePointsReason {
  id: string;
  reasonName: string;
  description: string;
  pointsForReason: number;
}

interface getPointInter {
  id: string;
  pointType: string;
  totalEarnedPoint: string;
  allocatedPointDate: string;
  userId: string;
  refferedUserName: string;
  referedUserName: string;
  status: string;
  rejectReason: string;
  totalUnredeemPoint: string;
  unredeemPoint: string;
  pointToRedeem: string;
  valueOfPointToRedeem: string;
  pointAssigned: string;
  pointValueAmount: string;
  comment: string;
  reasonName: string;
}

interface getRedeemInter {
  employeeId: string;
  name: string;
  manager: string;
  department: string;
  pointType: string;
  totalEarnedPoint: string;
  userId: string;
  totalUnredeemPoint: string;
  pointToRedeem: string;
  valueOfPointToRedeem: string;
  status: string;
  requestedOn: string;
  paidOn: string;
}

interface addRedeemmPoints {
  pointType: string;
  totalEarnedPoint: string;
  userId: string;
  totalUnredeemPoint: string;
  pointToRedeem: string;
  valueOfPointToRedeem: any;
  departmentId:any;
  managerId:any;
}

interface reqrewrdsPoints {
  departmentId: string;
  userId: string;
  referedUserName: string;
  reason: string;
  comment: string;
}

export default function BrowniePointEmployee() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [resource, setResource] = React.useState("");
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectedComment, setSelectedComment] = React.useState("");
  const [disable, setDisable] = useState(true);
  const [getPoints, setGetPoint] = useState<any[]>([]);
  const [getPointss, setGetPointss] = useState<getPointInter[]>([]);
  const [redeemHistory, setRedeemHistory] = useState<any[]>([]);
  const [redeemHistoryyy, setRedeemHistoryyy] = useState<getRedeemInter[]>([]);
  const [usersValue, setUsersValue] = React.useState<any>([]);
  const [openView, setOpenView] = React.useState(false);
  const [cycle, setcycle] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [browniePointsReasonList, setbrowniePointsReasonList] = React.useState<
    IBrowniePointsReason[]
  >([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [departId, setDepartId] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState(false);
  //const [departments, setDepartments] = useState<Department[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    getRedeemHistoryDetailsById();
    getPointDetails();
  };

  const handleClick = (event: { currentTarget: { disabled: boolean; }; }) => {
    event.currentTarget.disabled = false;
    // setSelectedComment("");
  };

  // -----------------------------
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // -----------------------------------------
  const [openRequestBrowniePoints, setOpenRequestBrowniePoints] =
    React.useState(false);
  const [openRedeemPoints, setOpenRedeemPoints] = React.useState(false);

  const handleClickOpenRequestBrowniePoints = () => {
    setOpenRequestBrowniePoints(true);
  };

  const handleClickOpenRedeemPoints = () => {
    getAllEmployeeDetailRewardPoints();
    setOpenRedeemPoints(true);
    getRewardSetting();
  };

  const handleCloseRedeemPoints = () => {
    settotalEarnedPoint("");
    settotalUnredeemPoint("");
    setpointToRedeem("");
    setvalueOfPointToRedeem("");
    setOpenRedeemPoints(false);
  };

  const handleCloseRequestBrowniePoints = () => {
    setOpenRequestBrowniePoints(false);
    setSelectedComment("");
    setSelectedReason("");
    setReasonForm("");
  };

  // -----------------------------------------
  const handleChangeResource = (event: SelectChangeEvent) => {
    setResource(event.target.value as string);
  };

  //-----------------------------------------------
  const handleClickOpenView = () => {
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  //---------------
  const handleChangeCycle = (event: SelectChangeEvent) => {
    setcycle(event.target.value as string);
  };

  useEffect(() => {
    if (_permission.page_Emplyoyee_Brownie_Access !== true) {
      navigate("/dashboard");
    }
    var userIdd: any = "";
    if (_authUser.id != "") userIdd = _authUser.id;
    setUserId(userIdd);
  });

  useEffect(() => {
    var departIdd: any = "";
    if (_authUser.employeeInfo.departmentId != "")
      departIdd = _authUser.employeeInfo.departmentId;
    setDepartId(departIdd);
    // setIssuelabel(labels.find((x: any) => x.id == id)?.name);
  });

  useEffect(() => {
    var curretName: any = _authUser.employeeInfo.currentUserName;
    setMangerNameForm(curretName);
    const GetAsyncData = async () => {
      //setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setbrowniePointsReasonList(
        await clsBrowniePointsList.getBrowniePointsList()
      );
    };
    GetAsyncData();
    // setIssuelabel(labels.find((x: any) => x.id == id)?.name);
  }, []);

  let obj: addRedeemmPoints = {
    pointType: "",
    totalEarnedPoint: "",
    userId: userId,
    totalUnredeemPoint: "",
    pointToRedeem: "",
    valueOfPointToRedeem: "",
    departmentId:"",
  managerId:"",
  };

  let rquestobj: reqrewrdsPoints = {
    departmentId: departId,
    userId: userId,
    referedUserName: "",
    reason: "",
    comment: "",
  };

  const [formRwardValues, setFormRewardValues] =
    React.useState<reqrewrdsPoints>(rquestobj);

  const [departmentIdSetting, setdepartmentIdSetting] = React.useState(
    formRwardValues?.departmentId ?? ""
  );
  

  const [referedUserNameForm, setReferedUserNameForm] = React.useState("Self");
  const [managerNameForm, setMangerNameForm] = React.useState<Manager>();
  const [currentmanagerNameForm, setcurrentMangerNameForm] = React.useState("");

  const [reasonForm, setReasonForm] = React.useState(formRwardValues?.reason);

  const [formValues, setFormValues] = React.useState<addRedeemmPoints>(obj);
  const [pointType, setpointType] = React.useState(formValues?.pointType);
  const [totalEarnedPoint, settotalEarnedPoint] = React.useState(
    formValues?.totalEarnedPoint
  );
  const [perPointRs, setperPointRs] = React.useState<any>("");
  const [unRedeemPointPoint, setunRedeemPointPoint] = React.useState("");
  const [totalUnredeemPoint, settotalUnredeemPoint] = React.useState(
    formValues?.totalEarnedPoint
  );
  const [pointToRedeem, setpointToRedeem] = React.useState(
    formValues?.pointToRedeem
  );
  const [valueOfPointToRedeem, setvalueOfPointToRedeem] = React.useState(
    formValues?.valueOfPointToRedeem ?? ""
  );

  //const multiply = valueOfPointToRedeem * 10;
  const MultiplyvalueOfPointToRedeem = Math.max(valueOfPointToRedeem * perPointRs);

  useEffect(() => {
    getPointDetails();
    getDepartments();
    getProjDetails();
    GetResourcesbyDepartmentId();
    GetManagerbyDepartmentId();
  }, []);

  const getProjDetails = async () => {
    await brownieemployeeservice.getAllProject(0).then((response: any) => {
      if (response.data.isError) {
      } else {
        // setProjectIdSetting(response.data.id ?? "");
        //     setprojectNameId(response.data.projectName ?? "");
        setDepartId(response?.data.id ?? "");
        setdepartmentIdSetting(response.data[0]?.departmentName ?? "");
      }
    });
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageCount = Math.ceil(getPointss.length / rowsPerPage);
  const pageCount1 = Math.ceil(redeemHistory.length / rowsPerPage1);
  async function AddRedeemPoints(data: any) {
    
    try {
      let obj: addRedeemmPoints = {
        pointType: pointType,
        totalEarnedPoint: totalEarnedPoint,
        userId: userId,
        totalUnredeemPoint: totalUnredeemPoint,
        pointToRedeem: pointToRedeem,
        valueOfPointToRedeem: MultiplyvalueOfPointToRedeem,
        departmentId:_authUser.employeeInfo.departmentId,
        managerId:_authUser.employeeInfo.managerId,
      };
      await brownieemployeeservice.addRedeemForm(obj).then((response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          toast.success("Add Redeem Points added successfully.");
          setOpenRedeemPoints(false);
          getPointDetails();
          getRedeemHistoryDetailsById();
          setpointType("");
          settotalEarnedPoint("");
          settotalUnredeemPoint("");
          setpointToRedeem("");
          setvalueOfPointToRedeem("");
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  }

  async function AddRequestRewardsPoints(values: any) {
    // try {
    // let rquestobj: reqrewrdsPoints = {
    //   departmentId: departId,
    //   userId: userId,
    //   referedUserName: referedUserNameForm,
    //   reason: selectedReason,
    //   comment: selectedComment,
    // };
    await brownieemployeeservice
      .addRequestRewardsPoints(values)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          setOpenRequestBrowniePoints(false);
          toast.success("Request Rewards added successfully.", {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false)
          });

          getPointDetails();
          setSelectedReason("");
          setSelectedComment("");
          setReasonForm("");
        }
      })
      // } 
      // catch (ex: any) {
      //   ex.data.Error.map((err: any, idx: any) => {
      //     toast.warning(err);
      //   });
      // }
      .catch((ex: any) => {
        if (ex.data.Error.length > 0)

          toast.error(ex.data.Error[0], {
            onOpen: () => setButtonDisable(true),

            onClose: () => setButtonDisable(false),
          });

        return ex;
      });
  }

  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await brownieemployeeservice.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something went wrong...");
      } else {
        // setDepartments(response.data);
        setdepartmentIdSetting(response?.data.name ?? "");
        // setprojectNameId(response.data.projectName ?? "");
        // setDepartId(response?.data.name)
      }
    });
  };

  const getPointDetails = async () => {
    var input = {
      pointType: 0,
      isUser: false,
    };
    await brownieemployeeservice
      .getAllRewardPointWithStatus(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          let responser = response?.data;
          setGetPoint(responser);
          setGetPointss(responser);
          // if (response.data[0]?.pointType == 0) {
          //   setpointType("No point");
          //   setpointTypeNumber(response.data[0]?.pointType);
          // }
          // if (response.data[0]?.pointType == 0) {
          setpointType(response.data[0]?.pointType);
          //   setpointTypeNumber("Brownie Point");
          // }
          // if (response.data[0]?.pointType == 1) {
          setpointType(response.data[0]?.pointType);
          //   setpointTypeNumber("Muffin Point");
          // }

          settotalEarnedPoint(response.data[0]?.totalEarnedPoint);
          setunRedeemPointPoint(response.data[0]?.unredeemPoint);
          settotalUnredeemPoint(response.data[0]?.totalUnredeemPoint);
          setpointToRedeem(response.data[0]?.pointToRedeem);
          setvalueOfPointToRedeem(response.data[0]?.valueOfPointToRedeem);
        }
      });
  };

  const getRedeemHistoryDetailsById = async () => {
    var input = {
      department: departId,
      userTypeEmployee: true,
      resource: "",
      months: "",
      status: 1,
      startDate: "",
      endDate: "",
    };
    await brownieemployeeservice
      .GetRedeemHistoryById(input)
      .then(async (response: any) => {
        if (response.data.isError) {
        } else {
          setRedeemHistory(response?.data);
          setRedeemHistoryyy(response?.data);
          // // setpointType(response.data[0]?.pointType);
          // if (response.data[0]?.pointType == 1) {
          //   setBrownieName("Brownie");
          //   setpointType(response.data[0]?.pointType);
          // } else if (response.data[0]?.pointType == 2) {
          //   setBrownieName("Muffin");
          //   setpointType(response.data[0]?.pointType);
          // } else {
          //   setBrownieName("");
          // }
          // settotalEarnedPoint(response.data[0]?.totalEarnedPoint);
          // settotalUnredeemPoint(response.data[0]?.totalUnredeemPoint);
        }
      });
  };
  const getAllEmployeeDetailRewardPoints = async () => {
    await brownieemployeeservice
    .GetAllEmployeeDetailRewardPoints(_authUser.id)
      .then(async (response: any) => {
        if (response.data.isError) {
        } else {
          settotalEarnedPoint(response?.data.totalEarnedPoint);
          settotalUnredeemPoint(response?.data.totalUnredeemPoint);
        }
      });
  };

  const getRewardSetting = async () => {
    await masterreasonServices.getBrownieSetting()
      .then(async (response: any) => {
        if (response.data.isError) {
        } else {
          setperPointRs(response?.data.perPointRs);
        }
      });
  };

  const GetResourcesbyDepartmentId = async () => {
    await brownieemployeeservice
      .GetResourcesbyDepartmentId(departId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setUsersValue(response?.data);
        }
      });
  };

  const GetManagerbyDepartmentId = async () => {
    await brownieemployeeservice
      .GetManagerByDepartmentId(_authUser.employeeInfo.departmentId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setcurrentMangerNameForm(response?.data.managerName);
        }
      });
  };

  // -------------------------------------------------------------------------
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof getPointInter>("allocatedPointDate");
  const [redeemorderBy, setRedeemOrderBy] =
    React.useState<keyof getRedeemInter>("name");
  const [page, setPage] = React.useState(0);
  const [page1, setPage1] = React.useState(0);
  const [dense, setDense] = React.useState(false);
 
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - getPointss.length) : 0;

  const redeememptyRows =
    page > 10
      ? Math.max(0, (1 + page) * rowsPerPage - redeemHistoryyy.length)
      : 0;

  type Order = "asc" | "desc";

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof getPointInter
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };  

  const fieldValidationReason = Yup.object().shape({
    reason: Yup.string().required("Reason is required"),
    comment: Yup.string().required("Comment is required"),
  });

  const fieldValidationRedeem = Yup.object().shape({
    pointType: Yup.string().required("Point Name is required"),
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Point</Typography>
        <Typography color="text.primary">Employee Brownie</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        {/* <Notes
          NoteText="Brownie points are reward points given by the manager to their team. The employees performing extraordinary work get recognition and brownie points as a reward from the company. Employees can request to redeem and get the amount transferred into their salary account.
          The admin or HR will define eligibility criteria or reasons for brownie points. Each set criterion/reason will have points assigned to it. When the manager offers a brownie point to an employee or approves the Brownie point request raised by an employee, that employee will get pre-defined brownie points in his account.
          The total value or amount of assigned brownie points will get credited into the employee's account periodically as set by HR. Condition applies on redeem brownie points.
          "
        /> */}
        <Stack
          direction={{xs:'column', sm:'row'}}
          spacing={2}
          justifyContent="space-between"
          alignItems={{xs:'strech', sm:"center"}}
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            My Rewards
          </Typography>

          <Box>
                
              <InfoPopover  onInput={"Brownie Points"}/>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
          <Stack direction={{xs:'column', sm:'row'}}  alignItems={{xs:'strech', sm:"center"}}  spacing={2}>
            {/* <Box>
              <Button
                fullWidth
                id="lock-button"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickListItem}
                sx={{ justifyContent: "space-between" }}
                variant="selectSmall"
              >
                {options[selectedIndex]} <ArrowDropDownIcon />
              </Button>

              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    //  disabled={index === 0}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}

            <Button
              variant="contained"
              onClick={handleClickOpenRequestBrowniePoints}
            >
              Request Brownie Points
            </Button>
            <Button variant="contained" onClick={handleClickOpenRedeemPoints}>
              <AddIcon />
              Redeem Points
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Points" value="1" />
                <Tab label="Redeem History" value="2" />
              </TabList>
              {/* <Button
                variant="outlined"
                size="medium"
                onClick={handleClickOpenRecommendPoints}
                sx={{ position: "absolute", right: 0, top: "0" }}
              >
                Assign Points
              </Button> */}
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Point Type </TableCell> */}
                      
                      <TableCell> Date</TableCell>
                      <TableCell>Points Earned</TableCell>
                      {/* <TableCell>Point Value(Rs)</TableCell> */}
                      <TableCell>Referred By</TableCell>

                      {/* <TableCell>Value(Rs)</TableCell>
                      <TableCell>Refered By View</TableCell> */}
                      <TableCell>Comment</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell align="right"> Status &nbsp; &nbsp; &nbsp; &nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(getPointss, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((detail, idx) => {
                        return (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell>{pointType === "BrowniePoint" && "Brownie Point"}</TableCell> */}
                            <TableCell>
                              {`${moment(detail?.allocatedPointDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>{detail?.pointAssigned}</TableCell>
                            {/* <TableCell>{detail?.pointValueAmount}</TableCell> */}
                            <TableCell>{detail?.referedUserName}</TableCell>
                            <TableCell >
                              {detail?.comment ? (
                                <Tooltip title={detail.comment} arrow>
                                  <span>
                                    {detail.comment.length > 30
                                      ? detail.comment.slice(0, 18 - 1) + "..."
                                      : detail.comment}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span>-</span>
                              )}
                            </TableCell>
                            <TableCell >
                              { (detail?.status === "Reject" && detail?.rejectReason ) ?(
                                <Tooltip title={detail.rejectReason} arrow>
                                  <span>
                                    {detail.rejectReason.length > 30
                                      ? detail.rejectReason.slice(0, 18 - 1) + "..."
                                      : detail.rejectReason}
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip title={detail.reasonName} arrow>
                                <span>
                                  {detail.reasonName.length > 30
                                    ? detail.reasonName.slice(0, 18 - 1) + "..."
                                    : detail.reasonName}
                                </span>
                              </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="right">

                              {detail?.status === "Reject" &&
                                <Tooltip title={detail?.rejectReason}>
                                  <Chip variant="hold" label={detail?.status} />
                                </Tooltip>}
                              {detail?.status === "Pending" && <Chip variant="review" label={detail?.status} />}
                              {detail?.status === "Approved" && <Chip variant="approved" label={detail?.status} />}
                            </TableCell>
                          </TableRow>
                        );
                        {
                          /* ))} */
                        }
                        
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {getPointss.length === 0 && (
                  <NotFound NotfoundText="No Result Found" />
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={getPointss.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${getPointss?.length}  Page ${page + 1} of ${pageCount}`}



              />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Point Redeem </TableCell>
                      {/* <TableCell>Point Type</TableCell> */}
                      <TableCell>Total Earned Point</TableCell>
                      <TableCell> Total Unredeem Point </TableCell>
                      {/* <TableCell>Value Of Point To Redeem</TableCell> */}
                      {/* <TableCell>Value(Rs)</TableCell>
                      <TableCell>Refered By View</TableCell> */}
                      {/* <TableCell align="right"> Action &nbsp;</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {redeemHistory?.map((redeemDetail, idx) => ( */}
                    {stableSort(redeemHistory, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((redeemDetail, idx) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{redeemDetail?.pointToRedeem}</TableCell>
                            {/* <TableCell>{pointType}</TableCell> */}
                            <TableCell>
                              {redeemDetail?.totalEarnedPoint}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.totalUnredeemPoint}
                            </TableCell>
                            {/* <TableCell>
                              {redeemDetail?.valueOfPointToRedeem}
                            </TableCell> */}
                          </TableRow>
                        );
                        {
                          /* ))} */
                        }
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {redeemHistory.length === 0 && (
                  <NotFound NotfoundText="No Result Found" />
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={redeemHistory.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
                labelDisplayedRows={() => `Total: ${redeemHistory?.length}  Page ${page1 + 1} of ${pageCount1}`}

              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>

      <Dialog
        open={openRequestBrowniePoints}
        onClose={setOpenRequestBrowniePoints}
        maxWidth="sm"
        scroll="body"
        fullWidth
      >
        <IconButton 
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseRequestBrowniePoints}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Request Rewards Points
        </DialogTitle>
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              departmentId: departId,
              userId: userId,
              referedUserName: referedUserNameForm,
              reason: reasonForm,
              currentmanagerNameForm: currentmanagerNameForm,
              comment: selectedComment,
            }}
            validationSchema={fieldValidationReason}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                await AddRequestRewardsPoints(values);
                setButtonDisable(true)
              } catch (err) {
                console.error(err);
              }
              // try {
              //   setErrorMessage(true);

              //   await
              //   addCalenderr(values);
              // } catch (ex: any) {
              //   if (ex.status == 400) {
              //     console.error("ex:", ex);
              //     ex.data.Error.map((error: any) => {
              //       toast.error(error);
              //     });
              //   }
              // }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs sm={6}>
                      {/* <TextField
                        id="outlined-basic"
                        label="Department"
                        variant="outlined"
                        fullWidth
                      /> */}
                      <TextField
                        id="outlined-basic"
                        disabled={disable}
                        label="Department"
                        value={
                          departments.find(
                            (a) =>
                              a.id ==
                              (_authUser.employeeInfo.departmentId != ""
                                ? _authUser.employeeInfo.departmentId
                                : "All Departments")
                          )?.name
                        }
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          //values.departmentId = e.currentTarget.value;
                          GetResourcesbyDepartmentId();
                        }}
                      />
                    </Grid>
                    <Grid item xs sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic"
                          label="Manager"
                          disabled={disable}
                          value={currentmanagerNameForm}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            e.currentTarget.value =
                              values.currentmanagerNameForm;
                            setcurrentMangerNameForm(e.currentTarget.value);
                          }}
                        />
                        {/* <InputLabel id="demo-simple-select-label">
                          Resource
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={resource}
                          label="Resource"
                          onChange={handleChangeResource}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select> */}
                      </FormControl>
                    </Grid>

                    <Grid item xs sm={6} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        {/* <TextField
                          error={Boolean(touched.reason && errors.reason)}
                          helperText={touched.reason && errors.reason}
                          required
                          onBlur={handleBlur}
                          id="outlined-basic"
                          label="Reason"
                          variant="outlined"
                          value={values.reason}
                          select
                          fullWidth
                        >
                          {browniePointsReasonList.map((option, idx) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              onClick={(event) => {
                                // values.paymentDetails.currency = dummy[idx].label;
                                values.reason = option.id;
                                setSelectedReason(option.id);
                                setReasonForm(option.id);
                              }}
                            >
                              {option.reasonName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                        <Autocomplete
                          value={
                            browniePointsReasonList?.find(
                              (option) => option.id == values.reason
                            ) ?? null
                          }
                          fullWidth
                          id="selectedUserId"
                          options={browniePointsReasonList}
                          getOptionLabel={(option) => `${option?.reasonName} (${option?.pointsForReason})`}
                          onChange={(event, value) => {
                            values.reason = value?.id ?? "";
                            setSelectedReason(value?.id ?? "");
                            setReasonForm(value?.id ?? "");
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                              {`${option?.reasonName} (${option?.pointsForReason})`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.reason && errors.reason)}
                              helperText={touched.reason && errors.reason}
                              required
                              onBlur={handleBlur}
                              id="outlined-basic"
                              label="Reason"
                              variant="outlined"
                              value={values.reason}
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs sm={6} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <TextField
                          id="comment"
                          label="Comment"
                          variant="outlined"
                          fullWidth
                          type="text"
                          name="comment"
                          value={values.comment}
                          error={Boolean(touched.comment && errors.comment)}
                          helperText={touched.comment && errors.comment}
                          required
                          onBlur={handleBlur}
                          onChange={(e) => {
                            let value = e.target.value.replace(/\s{2,}$/g, "").trimStart().substring(0, 150);
                            values.comment = value;
                            setSelectedComment(value);
                          }}
                        />
                        <span style={{ textAlign: 'right' }}>Max 150 Chars</span>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button
                    onClick={handleCloseRequestBrowniePoints}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" onClick={handleClick} disabled={buttonDisable} autoFocus variant="contained">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      </Dialog>

      <Dialog
        open={openRedeemPoints}
        onClose={setOpenRedeemPoints}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseRedeemPoints}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Redeem Points
        </DialogTitle>
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              pointType: pointType,
              totalEarnedPoint: totalEarnedPoint,
             // userId: userId,
              totalUnredeemPoint: totalUnredeemPoint,
              pointToRedeem: pointToRedeem,
              valueOfPointToRedeem: valueOfPointToRedeem,
            }}
            validationSchema={fieldValidationRedeem}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                if(values.pointToRedeem ==""||values.pointToRedeem==null)
                {
                  toast.warn("Please select Points to Redeem first")
                }
                else
                {
                await AddRedeemPoints(values);}
              } catch (err) {
                console.error(err);
              }
              // try {
              //   setErrorMessage(true);

              //   await
              //   addCalenderr(values);
              // } catch (ex: any) {
              //   if (ex.status == 400) {
              //     console.error("ex:", ex);
              //     ex.data.Error.map((error: any) => {
              //       toast.error(error);
              //     });
              //   }
              // }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "50%" }}>
                      <Typography variant="h6">Select Points Type</Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <FormControl fullWidth>
                        <TextField
                          error={Boolean(touched.pointType && errors.pointType)}
                          helperText={touched.pointType && errors.pointType}
                          required
                          onBlur={handleBlur}
                          id="outlined-basic"
                          label="All Points"
                          // disabled={disable}
                          disabled={disable}
                          variant="outlined"
                          value={pointType}
                          //select
                          fullWidth
                          onChange={(e) => {
                            values.pointType = e.currentTarget.value;
                          }}
                        >
                          {/* {dummy.map((option, idx) => (
                            <MenuItem
                              key={idx}
                              value={option.id}
                              onClick={(event) => {
                                // values.paymentDetails.currency = dummy[idx].label;
                                values.pointType = dummy[idx].id;
                                setSelectedPoints(dummy[idx].id);
                                setpointType(dummy[idx].id);
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))} */}
                        </TextField>
                        {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cycle}
                  onChange={handleChangeCycle}
                >
                  <MenuItem value={10}>Monthly </MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select> */}
                      </FormControl>
                    </Box>
                  </Stack>
                  <Box>
                    <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                      <Box sx={{ minWidth: "50%" }}>
                        <Typography variant="h6">
                          Total Earned Points:
                        </Typography>
                        {/* <Typography variant="subtitle1">(Per month/user)</Typography> */}
                      </Box>
                      <Box sx={{ minWidth: "40%" }}>
                        <Typography variant="h6" sx={[styles.view.label]}>
                          {totalEarnedPoint}
                        </Typography>
                        {/* <TextField
                          id="outlined-basic"
                          //placeholder="Brownie Points Assignment"
                          disabled={disable}
                          value={totalEarnedPoint}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            values.totalEarnedPoint = e.currentTarget.value;
                            settotalEarnedPoint(e.currentTarget.value);
                          }}
                        /> */}
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                      <Box sx={{ minWidth: "50%" }}>
                        <Typography variant="h6">Unredeemed points:</Typography>
                        {/* <Typography variant="subtitle1">(Per month/user)</Typography> */}
                      </Box>
                      <Box sx={{ minWidth: "40%" }}>
                        <Typography variant="h6" sx={[styles.view.label]}>
                          {totalUnredeemPoint}
                        </Typography>
                        {/* <TextField
                          id="outlined-basic"
                          disabled={disable}
                          value={unRedeemPointPoint}
                          type="text"
                          variant="outlined"
                          fullWidth
                        /> */}
                      </Box>
                    </Stack>
                    
                    <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                      <Box sx={{ minWidth: "50%" }}>
                        <Typography variant="h6">Points To Redeem:</Typography>
                      </Box>
                      <Box sx={{ minWidth: "40%" }}>
                      <TextField
                          id="pointToRedeem"
                          value={pointToRedeem}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            let abbc = e.currentTarget.value.trimStart().replace(/[^0-9]\s{0,}$/g, "");
                            setvalueOfPointToRedeem(abbc);
                            values.pointToRedeem = abbc;
                            setpointToRedeem(abbc);
                          }}
                        />
                      </Box>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={3}
                      sx={{ mb: 3 }}
                    >
                      <Box sx={{ minWidth: "50%" }}>
                        <Typography variant="h6">
                          Total value of Redeemed points:
                        </Typography>
                      </Box>
                      <Box sx={{ minWidth: "40%" }}>
                        <Stack direction="row" alignItems="center">
                          <CurrencyRupeeIcon sx={{ fontSize: 16 }} />
                          <Typography variant="h6" sx={[styles.view.label]}>
                            {" "}
                            {MultiplyvalueOfPointToRedeem}
                          </Typography>
                        </Stack>
                        {/* <TextField
                          id="outlined-basic"
                          placeholder=""
                          variant="outlined"
                          disabled={disable}
                          value={totalUnredeemPoint}
                          type="text"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyRupeeIcon />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          onChange={(e) => {
                            values.totalUnredeemPoint = e.currentTarget.value;
                            settotalUnredeemPoint(e.currentTarget.value);
                          }}
                        /> */}
                      </Box>
                    </Stack>
                  </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button onClick={handleCloseRedeemPoints} variant="outlined">
                    Cancel
                  </Button>
                  <Button type="submit" autoFocus variant="contained">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      </Dialog>
      {/* //----------------------------------------------------------- */}

      <Dialog
        open={openView}
        onClose={setOpenView}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton sx={styleCross} onClick={handleCloseView}>
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Recommend Points
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs sm={2}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 92, height: 92 }}
              />
            </Grid>
            <Grid item xs>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Emp Id 1025
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Nancy Martino
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Total Earned Points
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    100 points
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Value
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Rs. 1000
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Redeemed
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    50 Points
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
