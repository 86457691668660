import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams, } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { Autocomplete, Chip, CircularProgress, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { Department } from "../Interfaces/IDepartment";
import { eTypeOfUser } from "../Enum/eTypeOfUser";
import { clsDepartmentList } from "../service/common-services/department";
import HrReporttop from "./reports/HrReportsstyle";
import theme from "../theme/theme";
import NotFound from "../components/styledComponent/NotFound";
import { reserveServices } from "../service/Reserve-services";

export default function ResourceReports() {
    const baseUrl =  window.location.protocol==="https:"?process.env.REACT_APP_FE_Secure_URL :window.location.hostname === "localhost"?window.location.origin : process.env.REACT_APP_FE_URL;
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openPopupImgPath, setopenPopupImgPath] = React.useState("");
    const handleClose = () => {
        setOpen(false);
        setopenPopupImgPath('');
    };
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [reports, setReports] = React.useState<any>([]);
    const data = reports.length > 0 ? reports[0] : {};
    const [selectedDepartmentId, setselectedDepartmentId] = React.useState("");
    const [isAscending, setIsAscending] = useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(reports.length / rowsPerPage);
    const [page, setPage] = React.useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    const handleSort = () => {
        const sortedReports = [...reports].sort((a, b) => {
            if (a.departmentName < b.departmentName) {
                return isAscending ? -1 : 1;
            }
            if (a.departmentName > b.departmentName) {
                return isAscending ? 1 : -1;
            }
            return 0;
        });
        setReports(sortedReports);
        setIsAscending(!isAscending);
    };
    console.log(reports, "reports");
    const getResourceBillingReport = async (departmentId: any) => {
        setToggleLoader(true);
        setPage(0);
        try {
            await reserveServices
                .getBillingResourceReport(departmentId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                        setToggleLoader(false);
                    } else {
                        setReports(response?.data);
                        setToggleLoader(false);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };
    React.useEffect(() => {
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                getResourceBillingReport("");
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                getResourceBillingReport("");
                setDepartmentId("");
            }
        };
        departmentsData();
    }, []);
    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                >
                    <Box >
                        <Typography variant="h6" sx={{ fontWeight: 600, paddingLeft: '16px' }} component="h2">
                            Resource Report
                        </Typography>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 0, sm: 2 } }}>
                        <Stack alignItems="center" direction="row">
                            <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, }}>
                                <Autocomplete
                                    value={
                                        departments?.find(
                                            (option) => option.id == selectedDepartmentId
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setselectedDepartmentId(value?.id ?? "");
                                        getResourceBillingReport(value?.id ?? "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={selectedDepartmentId}
                                        />
                                    )}
                                    style={{ width: 220 }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", gap: "16px", marginLeft: "6px" }}>
                                <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                                    <Typography component="h2" sx={{ fontSize: '16px' }}>
                                        Total Resources
                                    </Typography>
                                    <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#2DD36F' }}>
                                    {selectedDepartmentId ? data?.totalResources : data?.totalResourcesOfDepartments}
                                    </Typography>
                                </Box>
                                <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                                    <Typography component="h2" sx={{ fontSize: '16px' }}>
                                        On Billing Resources
                                    </Typography>
                                    <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#FFAD01' }}>
                                    {selectedDepartmentId ? data?.billingResourcesCount : data?.allDepartmentBillingResourcesCount}
                                    </Typography>
                                </Box>
                                <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                                    <Typography component="h2" sx={{ fontSize: '16px' }}>
                                        Non-Billing Resources
                                    </Typography>
                                    <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#FF0000' }}>
                                    {selectedDepartmentId ? data?.nonBillingResourcesCount : data?.allDepartmentNonBillingResourcesCount}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{  marginLeft: "6px",  px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                                <Typography component="h2" sx={{ fontSize: '16px' }}>
                                    Occupancy %
                                </Typography>
                                <Typography variant="h4" component="h2"  sx={{ fontWeight: 700, color: '#FF0000' }}>
                                    {selectedDepartmentId ? data?.resourceOccupancyIndex : data?.allDepartmentResourceOccupancyIndex}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    {reports && reports.length > 0 &&
                        <TableContainer component={Paper} elevation={0} sx={{
                            mt: 0.5,
                            maxHeight: "calc(100vh - 215px)",
                            [theme.breakpoints.down("sm")]: {
                                maxWidth: "400px",
                            },
                        }}>
                            <Table stickyHeader aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {/* <TableSortLabel
                                                active={true}
                                                direction={isAscending ? 'asc' : 'desc'}
                                                onClick={() => handleSort()}
                                            > */}
                                                Department
                                            {/* </TableSortLabel> */}
                                        </TableCell>
                                        <TableCell>Total Resources</TableCell>
                                        <TableCell>On-Billing Resources</TableCell>
                                        <TableCell>Non-Billing Resources</TableCell>
                                        <TableCell>Occupancy %</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => (
                                        <TableRow style={{ textDecoration: 'none',cursor: "pointer" }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => {window.open(baseUrl + `/reserve/${row?.departmentId}`, "_blank")}}
                                        >
                                            <TableCell component="th" scope="row">{row.departmentName}</TableCell>
                                            <TableCell>{row.totalResources}</TableCell>
                                            <TableCell>{row.billingResourcesCount}</TableCell>
                                            <TableCell>{row?.nonBillingResourcesCount}</TableCell>
                                            <TableCell>{row.resourceOccupancyIndex}</TableCell>
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>                        
                    }
                    {reports.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                       <TablePagination
                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        component="div"
                                        count={reports.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={() => `Total: ${reports?.length}  Page ${page + 1} of ${pageCount}`}
                                    />
                </Box>
            </Paper>
        </>
    );
}