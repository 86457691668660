import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import theme from "../../../theme/darkTheme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs, { Dayjs } from "dayjs";
import Avatar from "@mui/material/Avatar";
import {
  AttendenceReportFilter,
  HrReportEmpoloyeeDetailFilter,
  IHrReportEmpoloyeeDetailResponse,
} from "../../../Interfaces/IReport";
import { reportService } from "../../../service/Reports/reports";
import { Collapse, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useSelector } from "react-redux";

const styles = {
  titleLabel: {
    fontWeight: " 300",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#000000",
  },
  titles: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#232360",
  },
  EmpStats: {
    fontWeight: 700,
    fontSize: " 36px",
  },
};

export default function AttendanceReportFilter() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ReportPermissionApp.ReportPermission
  );
  const { id } = useParams();
  const [UserId, setUserId] = useState("");
  const [reports, setreports] =
    React.useState<IHrReportEmpoloyeeDetailResponse>();
  const getReport = useCallback(async () => {
    if (UserId !== "") {
      var Filter: HrReportEmpoloyeeDetailFilter = {
        userId: UserId ?? "",
      };
      await reportService.GetUserAttendance(Filter).then((response: any) => {
        if (response.data.isError) {
        } else {
          setreports(response.data);
        }
      });
    }
  }, [UserId]);
  useEffect(() => {
    if (id && id != "") {
      setUserId(id);
    }
    getReport();
  }, [getReport]);

  const [open, setOpen] = React.useState(false);
  let today = new Date();
  const [fromDate, setfromDate] = React.useState<Dayjs | null>(
    dayjs(new Date(today.getFullYear(), today.getMonth(), 1))
  );
  const [toDate, settoDate] = React.useState<Dayjs | null>(dayjs(today));
  const getDate1 = (dateObject: Date) => {
    let date = moment(dateObject).format("YYYY-MM-DD");
    return date;
  }
  const getDateWithtime = (dateObject: Date) => {
    let date = moment(dateObject).format("YYYY-MM-DD");
    return date  + 'T00:00:00Z';
  }
  const updateCollapse = (index: number) => {
    if (reports?.listOutputModels && reports?.listOutputModels.length > 0) {
      let reportsObj = { ...reports };
      let arr = reportsObj.listOutputModels;
      arr
        .filter((a: any, i: any) => i != index)
        .map((items: any) => {
          items.collapse = false;
        });
      arr[index].collapse = !arr[index].collapse;

      let sDt = new Date(
        new Date().getFullYear(),
        arr[index].monthNumber - 1,
        1
      );
      let eDt = new Date(sDt.getFullYear(), sDt.getMonth() + 1, 1);
      arr[index].start = getDate1(sDt);
      arr[index].end = getDate1(eDt > new Date() ? new Date() : eDt);
      setreports(reportsObj);
      eDt.setDate(eDt.getDate() - 1)
      getCalendarReport(sDt, getDateWithtime(new Date(eDt)));
    }
  };
  interface eventProps {
    id: string;
    title: string;
    date: string;
    color: string;
    textColor: string;
  }
  const [events, setevents] = React.useState<eventProps[]>([]);
  const getCalendarReport = async (startDate: any, endDate: any) => {
    var attendenceReportFilter: AttendenceReportFilter = {
      department: reports?.departmentId ?? "",
      resource: reports?.userId ?? "",
      status: "0",
      startDate: startDate,
      endDate: endDate,
    };
    //setLoading(true);
    await reportService
      .GetAttendanceReport(attendenceReportFilter)
      .then((response: any) => {
        //setToggleLoader(false);
        if (response.data.isError) {
        } else {
          setevents([]);
          let arr: eventProps[] = [];
          response.data.map((items: any, index: any) => {
            if (items.presentCount > 0) {
              let dt = new Date(items.date).setMinutes(0);
              arr.push({
                id: "1",
                title: "Present",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#2DD36F",
                textColor: "white",
              });
            }
            if (items.leaveCount > 0) {
              let dt = new Date(items.date).setMinutes(10);
              arr.push({
                id: "2",
                title: "Leave",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#FFC409",
                textColor: "white",
              });
            }
            if (items.absentCount > 0) {
              let dt = new Date(items.date).setMinutes(20);
              arr.push({
                id: "3",
                title: "Absent",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#EB445A",
                textColor: "white",
              });
            }
          });
          setevents(arr);
        }
      });
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>

        <Typography color="text.primary"> Attendance Report</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs sm={6}>
            <Stack direction="row" justifyContent="space-between">
              <Avatar>NS</Avatar>
              <Grid container sx={{ ml: 2 }}>
                <Grid item xs>
                  <Typography sx={styles.titleLabel}>Name</Typography>
                  <Typography sx={styles.titles}>{reports?.name}</Typography>
                </Grid>

                <Grid item xs>
                  <Typography sx={styles.titleLabel}>ID</Typography>
                  <Typography sx={styles.titles}>
                    {reports?.employeeId}
                  </Typography>
                </Grid>

                <Grid item xs>
                  <Typography sx={styles.titleLabel}>Department</Typography>
                  <Typography sx={styles.titles}>
                    {reports?.department}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs sm={6}>
            <Grid
              container
              spacing={2}
              sx={{ position: "relative", zIndex: 99 }}
            >
              <Grid item xs={6} md={6} lg={3}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    p: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.grey[700]}
                    gutterBottom
                  >
                    Working Days
                  </Typography>
                  <Typography
                    variant="h2"
                    color={theme.palette.primary.main}
                    sx={styles.EmpStats}
                  >
                    {reports?.totalWorkingDays}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    p: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.grey[700]}
                    gutterBottom
                  >
                    Present
                  </Typography>
                  <Typography
                    variant="h2"
                    color={theme.palette.success.main}
                    sx={styles.EmpStats}
                  >
                    {reports?.presentDayCount}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    p: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.grey[700]}
                    gutterBottom
                  >
                    Leave
                  </Typography>
                  <Typography
                    variant="h2"
                    color={theme.palette.warning.main}
                    sx={styles.EmpStats}
                  >
                    {reports?.leaveCount}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    p: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.grey[700]}
                    gutterBottom
                  >
                    Absent
                  </Typography>
                  <Typography
                    variant="h2"
                    color={theme.palette.error.main}
                    sx={styles.EmpStats}
                  >
                    {reports?.absentCount}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer component={Paper} elevation={0} sx={{ mt: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Months</TableCell>
                <TableCell>Total Working Day</TableCell>
                <TableCell>Present</TableCell>
                <TableCell>Leave</TableCell>
                <TableCell>Absent</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports?.listOutputModels.map((row, index) => (
                <>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                  
                    <TableCell component="th" scope="row">
                      {row.month}{" "}
                    </TableCell>
                    <TableCell>{row.totalWorkingDay}</TableCell>
                    <TableCell>{row.present}</TableCell>
                    <TableCell>{row.leave}</TableCell>
                    <TableCell>{row.absent}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => updateCollapse(index)}
                      >
                        {row.collapse === true ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse in={row.collapse} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <FullCalendar
                            headerToolbar={{
                              start: "",
                              center: "title",
                              end: "",
                            }}
                            validRange={{ end: row.end, start: row.start }}
                            plugins={[dayGridPlugin]}
                            events={events}
                          />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
