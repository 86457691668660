import { useEffect, useState, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import * as Icons from "../assests/icons/icons";
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Modal, TableCell, TablePagination, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Box from "@mui/material/Box";
import { toast } from "material-react-toastify";
import React from "react";
import { useSelector } from "react-redux";
import { Formik, getIn } from "formik";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import SearchBar from "../components/styledComponent/SearchBar";
import StyledTableCell from "../components/styledComponent/CustomTableCell";
import { logStatusFilter } from "../Enum/eLeaveStatus";
import { projectTrailorService } from "../service/projectArtifacts-service";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const toggleloadersStyle={
  position: "fixed",
   left: "50%",
   right: "50%",
   top: "30%", 
  bottom: "70%", 
  transform: "translate(-50%, -50%)" 
 };

interface Data {
  projectDescription: string;
  projectName: string;
  projectOwnerName: string;
  keyWords: string;
  clientName: string;
  departmentId: string;
  departmentName: string;
  costingType: string;
  status: string;
  id: string;
  pin: string;
  createdDate: string;
  projectKey: string;
  projectOwnerId: string;
  srNo: string;
}

const LongText = (props: {
  content?: any;
  limit?: any;
}) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);
  if (props.content.length <= props.limit) {
    // there is nothing more to show
    return <div>{props.content}</div>
  }
  if (showAll) {
    // We show the extended text and a link to reduce it
    return <Box sx={{ whiteSpace: "break-spaces" }}>
      {props.content}
      <Tooltip title="Show Less">
        <Button variant="text" size="small" onClick={showLess}>less...</Button></Tooltip>
    </Box>
  }
  // In the final case, we show a text with ellipsis and a `Read more` button
  const toShow = props.content ? props.content.substring(0, props.limit) : "";
  return <Box sx={{ whiteSpace: "break-spaces", width: "200px" }}>
    {toShow}
    <Tooltip title="Show More">
      <Button variant="text" size="small" onClick={showMore}>...</Button></Tooltip>
  </Box>
}

export default function ProjectArtifact() {
  const navigation = useNavigate();
  const navigate = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const [workLogList, setWorkLogList] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageCount1 = Math.ceil(workLogList.filter((a: any) => a.status === 5 || a.status === 7).length / rowsPerPage1);
  const pageCount = Math.ceil(workLogList.filter((a: any) => a.status === 6).length / rowsPerPage);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [page1, setPage1] = React.useState(0);
  const [statusFilter, setStatusFilter] = React.useState("All");
  const [statusFilterValue, setStatusFilterValue] = React.useState<any>("4");
  const logstatusFilter = Object.keys(logStatusFilter);
  const [iconDisable, setIconDisable] = React.useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [traileringData , setTraileringData] = React.useState<any[]>([]);
  const [artifactsData , setArtifactsData] = React.useState<any[]>([]);
  const [infoDialogOpen, setInfoDialogOpen]=useState(false);
  const [isProjectTrailorRaised, setIsProjectTrailorRaised] = useState(true);
  const [projectTailorData, setProjectTailorData] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [projTrailorID , setProjTrailorID] = useState("");

  
  const handleNewProjectDialogClose=()=>{
    setInfoDialogOpen(false);
    setProjTrailorID("");
  }
  const handleNewProjectDialogOpen=()=>{
    setInfoDialogOpen(true)
  }



  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - traileringData.length) : 0;


  ///////Accept Reject Multiple Offline hours programmming//////////////
  const [rowCount, setRowCount] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);

  };
  const [tabValue, setTabValue] = React.useState("1");
  const [searchValue, setSearchValue] = useState("");
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  console.log(searchValue,"searchValue");
  const triggerSearch = async (newValue: string) => { 
    setSearchValue(newValue)
    getDataForTalioring(tabValue,statusFilterValue , newValue);
    setPage(0);
    setPage1(0);
  };


 

  useEffect(() => {
    setLoading(true);
    getDataForTalioring(tabValue,statusFilterValue, "");
  }, [tabValue , statusFilterValue]);




  const approveOrRejectRequest = async (type : string) => {
    let newStatus = 0;

    if(type == "Accept") { newStatus = 2}
    else {newStatus = 3}

    const payload = {
      id:projTrailorID,
      status:newStatus
    }

   
    await projectTrailorService.approveorRejectProjectArtifactsRequest(payload).then((response:any) => {
      if(response.data.isError){}
      else {
        console.log("HEHEHHEH");
        handleNewProjectDialogClose();
        toast.success("Response submitted successfully");
        getDataForTalioring(tabValue,statusFilterValue, "");
      }
    })
  }


  const getDataForTalioring = async (tabVal : any , recordStatus : any , searchData : any) => {
    let newStatus = 0;

    if(tabVal == "1" ){
      newStatus = 1
    }
    else if (tabVal == "2" && recordStatus == "2" ){
      newStatus = 2
    }
    else if (tabVal == "2" && recordStatus == "3"){
      newStatus = 3
    }
    else {
      newStatus = 4
    }
    const payload = {
        status : newStatus,
        search: searchData
    }

    await projectTrailorService.getAllArtifactsRequest(payload).then((response:any) => {
      if(response.data.isError){}
      else {
        console.log(response.data, "Data Recieved");
       
         setTraileringData(response.data);
         console.log(tabValue , "TAB VALUE ");
      }
       
    })
  }


  const [open, setOpen] = React.useState(false);

 


  return (
    <>
    {toggleLoader && (<CircularProgress sx={toggleloadersStyle} />)}
      <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
      <Stack direction="row">
            <Typography variant="h5" component="h2" sx={{ fontWeight: 600, mr: 3 }}>
            Project Artifacts
            </Typography>
            </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3 ,mt:"10px"}}
        >
          <SearchBar
              value={searchValue}
              callBackFunction={triggerSearch}
            />
          
            <Stack direction="row">
          

       
              <Stack direction="row" 
              sx={{ml:"-61px",mt:"4px"}}
              spacing={2}>
            
          </Stack>
         
          </Stack>

        
        </Stack>

 

        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Pending" value="1"  onClick={()=>{
                 setSearchValue(""); setStatusFilter("All");
               
                setStatusFilterValue("")}}/>
                <Tab label="History" value="2" onClick={()=>{
                 setSearchValue(""); 
              
               }}/>
                {tabValue=="2" && (               
                <Box sx={{ position: "absolute", right: '0', top: '0' , width: "150px"}} >
                          <TextField
                            id="Statusfilter"
                            variant="outlined"                         
                            select
                            value={statusFilter}                          
                            fullWidth
                          >
                            {logstatusFilter?.map((key, idx) => (
                              <MenuItem
                                key={key}
                                value={key}
                                onClick={(event) => {
                                  let abc = key==="Approved"?"2" :key==="Rejected" ?"3" :key==="All"?"4":""
                                  setStatusFilter(key);
                                  setSearchValue("");
                                  setStatusFilterValue(abc);
                               
                                }}
                              >
                                {key}
                              </MenuItem>
                            ))}
                          </TextField>                       
                </Box>  
                 )}
              </TabList>          
               
            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              


              <TableContainer component={Paper} elevation={0}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead >
                    <TableRow>
                  
                      <TableCell>Sr. No</TableCell>
                      <TableCell align="left">Department</TableCell>
                      <TableCell align="left">Project</TableCell>
                      
                      <TableCell align="left">Client Name</TableCell>
                      <TableCell align="left">Request Date</TableCell>
                   
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {traileringData && traileringData.length > 0 ? (
                      traileringData.map((item : any , idx : any) => (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <StyledTableCell align="left">{idx + 1}</StyledTableCell>
                        <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }}>
                            {item?.departmentName || "--"}</StyledTableCell>
                        <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: '400px', background: '#fff', whiteSpace: "unset !important"}}>
                            {item?.projectName || "--"}
                            </StyledTableCell>
                        <StyledTableCell align="left">{item?.clientName || "--"}</StyledTableCell>
                        <StyledTableCell align="left">{`${moment(item?.requestedDate).format("DD MMM YYYY")}`}</StyledTableCell>
                        <StyledTableCell align="left">
                          <IconButton aria-label="view" onClick={()=>{
                            handleNewProjectDialogOpen();
                            setProjectTailorData(item?.artifacts);
                            setProjTrailorID(item?.id);
                          }}>
                               <Icons.View />
                          </IconButton>
                        </StyledTableCell>
    
                    </TableRow>
                       ))
                    ): (<TableRow>
                      <StyledTableCell colSpan={8}>
                        No Record Found
                      </StyledTableCell>
                    </TableRow>) }
                   
                  
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={artifactsData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${artifactsData?.length}  Page ${page + 1} of ${pageCount}`}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>

                <Table stickyHeader aria-label="simple table">
                  <TableHead >

              <TableRow>
            
              <TableCell>Sr. No</TableCell>
                      <TableCell align="left">Department</TableCell>
                      <TableCell align="left">Project</TableCell>
                      
                      <TableCell align="left">Client Name</TableCell>
                      <TableCell align="left">Request Date</TableCell>
                   
                      <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {traileringData && traileringData.length > 0 ? (
                 traileringData.map((item : any , idx : any) => (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCell align="left">{idx + 1}</StyledTableCell>
                  <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }}>
                      {item?.departmentName || "--"}</StyledTableCell>
                  <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: '400px', background: '#fff', whiteSpace: "unset !important"}}>
                      {item?.projectName || "--"}
                      </StyledTableCell>
                  <StyledTableCell align="left">{item?.clientName || "--"}</StyledTableCell>
                  <StyledTableCell align="left">{`${moment(item?.requestedDate).format("DD MMM YYYY")}`}</StyledTableCell>
                  <StyledTableCell align="left">
                    {item?.projectTailoringStatus == 2 && (
                      <Chip variant="approved" label="Approved"/>
                    )}
                    {item?.projectTailoringStatus == 3 && (
                      <Chip variant="hold" label="Rejected"/>
                    )}
                  </StyledTableCell>

              </TableRow>
                 ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={8}>
                    No Record Found
                  </StyledTableCell>
                </TableRow>
              )}
                  
                 
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={artifactsData?.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
                labelDisplayedRows={() => `Total: ${artifactsData?.length}  Page ${page1 + 1} of ${pageCount1}`}
              />
            </TabPanel>
          </TabContext>
        </Box>


      </Paper>

      <Dialog
        open={infoDialogOpen}
        onClose={handleNewProjectDialogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        fullWidth
        scroll="paper"
        PaperProps={{
          sx: {
            p: 2,
            borderRadius: 3,
            boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600, position: 'relative', p: 3 }}
        >
          Project Execution Process
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: 'grey.500',
            }}
            onClick={handleNewProjectDialogClose}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
      
        <DialogContent dividers sx={{ p: 3 }}>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {projectTailorData.map((label: any, index: any) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 0.5,
                  borderRadius: 1,
                  bgcolor: '#f9f9f9',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                }}
              >
                <Box sx={{ minWidth: 250 }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {label?.artifactName}
                  </Typography>
                </Box>
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#1976d2',
                    },
                  }}
                  disabled={true}
                 checked={label?.isFollowed ==true}
                  onChange={(e) => {
                  }}
                />

                <TextField
      
                  variant="outlined"
                  fullWidth
                  disabled={isProjectTrailorRaised ||label?.isFollowed ==true} 
   
                  value={label?.reason}

                  onChange={(e) => {

                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1976d2',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1565c0',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#0d47a1',
                      },
                    },
                  }}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 3, justifyContent: "end" }}>

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              approveOrRejectRequest("Reject");
            }}
            sx={{ mr: 2 }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"

            onClick={() => {
              approveOrRejectRequest("Accept");
            }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
     
    </>
  );
}
