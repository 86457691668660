import * as React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from "material-react-toastify";
import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, IconButton, Stack, TablePagination, TextField, Typography } from "@mui/material";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import StyledTableCell from "../components/styledComponent/CustomTableCell";
import NotFound from "../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import { Department } from "../Interfaces/IDepartment";
import { eTypeOfUser } from "../Enum/eTypeOfUser";
import { clsDepartmentList } from "../service/common-services/department";
import { Iproject } from "../Interfaces/IProject";
import { masterDataService } from "../service/master-service/master-data-service";
import { projectManagementServive } from "../service/project-management-service";
import { milestoneTreeService } from "../service/Reports/milestonereportTree";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { qaReportService } from "../service/QaReports-service";
import { milestoneManagementService } from "../service/milestone-management-service";


const statusOption = [
    { label: 'Status', id: "" },
    { label: 'ToDo', id: "ToDo" },
    { label: 'On Going', id: "On Going" },
    { label: 'Verification Pending', id: "Verification Pending" },
    { label: 'Resolved', id: "Resolved" },
    { label: 'Escalated', id: "Escalated" },
    { label: 'On-Hold', id: "On-Hold" },
    { label: 'Rejected', id: "Rejected" },
    { label: 'Reassign', id: "Reassign" },
]

const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "20%",
    bottom: "80%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function QaReports() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentsId, setdepartmentsId] = React.useState("");
    const [projects, setProjects] = React.useState<any[]>([]);
    const [projectId, setProjectId] = React.useState("");
    const [mileStonesList, setMileStonesList] = React.useState<any>([]);
    const [mileStonesId, setMileStonesId] = React.useState("");
    const [mileStoneIdArray, setMileStoneIdArray] = React.useState<any>([]);
    const [allSprintList, setAllSprintList] = React.useState<any>([]);
    const [sprintListIdArray, setSprintListIdArray] = React.useState<any>([]);
    const [sprintId, setSprintId] = React.useState("");
    const [visibleDepartment, setDepartmentVisible] = React.useState(false);
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [qaReports, setQaReports] = React.useState<any>([]);
    const [qaExcelReports, setQaExcelReports] = React.useState<any>([]);
    const pageCount = Math.ceil(qaReports.length / rowsPerPage);
    const [ExcelDownloadfiltersSelected, setExcelDownloadfiltersSelected] = React.useState(false);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    React.useEffect(() => {
        const departmentsData = async () => {
            // if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
            //     await setdepartmentsId(_authUser.employeeInfo.departmentId);
            //     await setDepartments(
            //         await clsDepartmentList.getDepartmentDropdownList()
            //     );
            // } else {
            //     await setdepartmentsId("")
            //     await setDepartments(
            //         await clsDepartmentList.getDepartmentDropdownList()
            //     );
            // }

            switch (_authUser.typeOfUser) {
                case eTypeOfUser.Manager:
                    await setdepartmentsId(_authUser.employeeInfo.departmentId);
                    getActiveProjectsOnBaseOfUserIdAndDepId(_authUser.employeeInfo.departmentId, _authUser?.id);
                    break;
                case eTypeOfUser.User || eTypeOfUser.Client:
                    await setdepartmentsId(_authUser.employeeInfo.departmentId);
                    getActiveProjectsOnBaseOfUserIdAndDepId("", _authUser?.id);
                    break;
                case eTypeOfUser.Admin:
                    await setdepartmentsId("")
                    await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                    setDepartmentVisible(true);
                    break;
                case 0:
                    await setdepartmentsId(_authUser.employeeInfo.departmentId);
                    getActiveProjectsOnBaseOfUserIdAndDepId("", _authUser?.id);
                    break;

                default:
                    return null;
            }
        };
        departmentsData();


    }, [])


    const getBugReportSprintsDropdownData = async (milesId: any) => {
        setToggleLoader(true);
        var payload = {
            projectId: projectId,
            milestoneId: milesId
        }
        try {
            await qaReportService.getSprintDropdownData(payload).then(async (response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                    toast.error("Something Went Wrong, Please try again.");
                } else {
                    // setQaExcelReports([]);
                    // setQaExcelReports(response.data);
                    // const transformedData = transformQAReportsData(response.data);
                    setToggleLoader(false);
                    // setQaReports(transformedData);
                    setAllSprintList(response?.data);
                }
            });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    };

    const getBugReportsData = async (milesData: any, sprintData: any) => {
        setToggleLoader(true);
        var payload = {
            projectId: projectId,
            milestoneId: milesData,
            sprintId: sprintData,
            issueType: "Bug"
        }
        try {
            await qaReportService.getQAReportsData(payload).then(async (response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                    toast.error("Something Went Wrong, Please try again.");
                } else {
                    // setQaExcelReports([]);
                    setQaExcelReports(response.data);
                    const transformedData = transformQAReportsData(response.data);
                    setToggleLoader(false);
                    setQaReports(transformedData);
                }
            });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    };


    const getActiveProjectsOnBaseOfUserIdAndDepId = async (id: any, userId: any) => {
        try {
            await qaReportService.getActiveProjectsOnBaseOfUserIdAndDepId({ departId: id ? id : "", userId: userId ? userId : "" }).then(async (response: any) => {
                if (response.data.isError) {
                    toast.error("Something Went Wrong, Please try again.");
                } else {
                    setProjects(response?.data ? response?.data : []);
                }
            });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    const transformQAReportsData = (data: any) => {
        return data.reduce((acc: any, sprint: any) => {
            if (sprint.getQASprintListOutPutModel) {
                return [...acc, ...sprint.getQASprintListOutPutModel];
            }
            return acc;
        }, []);
    };

    const getActiveProjects = async (id: any) => {
        await masterDataService.getProjectByDeptartmentId(id).then(async (response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                setProjects(response?.data ? response?.data : []);
            }
        });
    };

    const getMileStoneData = async (id: any,) => {
        try {
            await projectManagementServive
                .getMilestonesWithoutSprint(id).then((response: any) => {
                    if (response.data.isError) {
                    } else {
                        setMileStonesList(response?.data)
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    const getSprintsBasedOnMilestoneId = async (id: any) => {
        await milestoneManagementService.getSprintByMilestoneId({ MilestoneId: id }).then((response: any) => {
            if (response.data.isError) {
            } else {
                setAllSprintList(response?.data);
            }
        });
    };

    const Exportexcel = () => {
        const fileType = "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset-UTF-8";
        const FileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet([]);
        let startRow = 1;
        let formattedData: any[] = [];

        qaExcelReports.forEach((sprint: any) => {
            const sprintName = sprint.sprintName;
            const sprintData = sprint.getQASprintListOutPutModel.map((obj: any) => ({
                TicketNo: obj.ticketNumber,
                Summary: obj.summary,
                Priority: obj.priority,
                Severity: obj.severity,
                Label: obj.labelName,
                Status: obj.status,
                Sprint: sprintName,
                CreatedDate: dayjs(obj.createdDate).format("MM/DD/YYYY"),
                Reporter: obj.reporter,
                Platform: obj.platform,
                BugType: obj.bugtype,
                RCA: obj.rca,
                ResolvedDate: obj.resolvedDate ? dayjs(obj.resolvedDate).format("MM/DD/YYYY") : '',
                ClosedDate: obj.closedDate ? dayjs(obj.closedDate).format("MM/DD/YYYY") : '',
            }));
            formattedData = [...formattedData, ...sprintData];
        });

        XLSX.utils.sheet_add_json(ws, formattedData, { origin: 'A1', skipHeader: false });

        const wb = { Sheets: { "data": ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Bug Report.xlsx");
    }



    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}

            <Box>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                    Ticket Report
                </Typography>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ mt: 1.5 }}
                >
                    <Grid item xs={12} lg={12}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            spacing={1}
                            sx={{ pb: 3, mr: "15px" }}
                        >
                            {visibleDepartment && (
                                <Box >
                                    <Autocomplete
                                        disabled={_authUser.typeOfUser === eTypeOfUser.Manager}
                                        value={
                                            departments?.find(
                                                (option) => option.id === departmentsId
                                            ) ?? null
                                        }
                                        id="outlined-basic"
                                        options={departments}
                                        getOptionLabel={(option) => option.name}

                                        onChange={async (event, value) => {
                                            if (!value) {
                                                setSprintId("");
                                                setMileStonesId("")
                                                setdepartmentsId("")
                                                setProjectId("")
                                            }
                                            else {
                                                setfiltersSelected(true)
                                                setdepartmentsId(value?.id ?? "");
                                                // await getActiveProjects(value?.id ?? "");
                                                await getActiveProjectsOnBaseOfUserIdAndDepId(value?.id ?? "", "")
                                            }
                                            setfiltersSelected(true);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}

                                                id="outlined-basic"
                                                name="element.name"
                                                variant="outlined"
                                                value={departmentsId}
                                                fullWidth
                                                sx={{ minWidth: 250 }}
                                            />
                                        )}
                                    />
                                </Box>)}

                            <Box >

                                <Autocomplete
                                    disabled={!departmentsId}
                                    value={
                                        projects?.find(
                                            (option) => option.projectId === projectId
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={projects}
                                    getOptionLabel={(option) => option.projectName}

                                    onChange={async (event, value) => {
                                        if (!value) {
                                            setSprintId("");
                                            setMileStonesId("")
                                            setProjectId("")
                                            setQaReports([]);
                                            setMileStoneIdArray([]);
                                            setSprintListIdArray([]);
                                        }
                                        else {
                                            setfiltersSelected(true)
                                            setProjectId(value?.projectId ?? "");
                                            await getMileStoneData(value?.projectId ?? "");
                                            setQaReports([]);
                                        }
                                        setfiltersSelected(true);
                                    }}
                                    componentsProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: true,
                                                    options: {
                                                        altBoundary: true,
                                                        flipVariations: true,
                                                        altAxis: true,
                                                        padding: 8,
                                                        fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}

                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={projectId}
                                            placeholder="Select Projects"
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                />
                            </Box>

                            <Box>
                                <Autocomplete
                                    multiple
                                    disabled={!projectId}
                                    value={
                                        // mileStonesList?.find(
                                        //     (option: any) => option.id === mileStonesId
                                        // ) ?? null
                                        mileStoneIdArray.filter((mileId: any) => mileId !== "").map((mileId: any) => {

                                            const matchedMilestone = mileStonesList.find((option: any) => option.id === mileId);
                                            return matchedMilestone ? { id: matchedMilestone.id, milestoneName: matchedMilestone.milestoneName } : null;
                                        }).filter((mile: any) => mile !== null)
                                    }
                                    id="outlined-basic"
                                    //options={mileStonesList}
                                    options={mileStonesList.filter((item: any) => item?.id != "" && !mileStoneIdArray.includes(item.id))}
                                    getOptionLabel={(option) => option.milestoneName}

                                    onChange={async (event, value) => {
                                        if (value.length != 0) {
                                            const selectedMilestones = value.map((mile: any) => (
                                                mile.id
                                            ));
                                            setfiltersSelected(true);
                                            setExcelDownloadfiltersSelected(true);
                                            setQaReports([]);
                                            // await getSprintsBasedOnMilestoneId(selectedMilestones ?? "");
                                            setMileStoneIdArray(selectedMilestones);
                                            getBugReportSprintsDropdownData(selectedMilestones);
                                            getBugReportsData(selectedMilestones, [""]);
                                        } else {
                                            setMileStoneIdArray([]);
                                            setSprintListIdArray([]);
                                            setQaReports([]);
                                        }
                                        // if (!value) {
                                        //     setMileStonesId("")
                                        //     setExcelDownloadfiltersSelected(true);
                                        //     setQaReports([])
                                        //    // QAReports("", "", "");
                                        // }
                                        // else {
                                        //     debugger
                                        //     setfiltersSelected(true)
                                        //     setMileStonesId(value?.id ?? "");
                                        //     await getSprintsBasedOnMilestoneId(value?.id ?? "");
                                        //     setExcelDownloadfiltersSelected(true);
                                        //     QAReports(projectId, value?.id ?? "", "");
                                        // }
                                        setSprintId("");
                                        setfiltersSelected(true);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // disabled={disabledDepartments}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={mileStoneIdArray}
                                            placeholder="Select Milestones"
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                />
                            </Box>

                            <Box>
                                <Autocomplete
                                    multiple
                                    disabled={mileStoneIdArray.length === 0}
                                    value={
                                        // allSprintList?.find(
                                        //     (option: any) => option.sprintId === sprintId
                                        // ) ?? null
                                        sprintListIdArray.filter((sprintId: any) => sprintId !== "").map((sprintId: any) => {

                                            const matchedSprint = allSprintList.find((option: any) => option.sprintId === sprintId);
                                            return matchedSprint ? { sprintId: matchedSprint.sprintId, sprintName: matchedSprint.sprintName } : null;
                                        }).filter((sprintId: any) => sprintId !== null)

                                    }
                                    id="outlined-basic"
                                    options={allSprintList.filter((item: any) => item?.sprintId != "" && !sprintListIdArray.includes(item.sprintId))}
                                    getOptionLabel={(option) => option.sprintName}

                                    onChange={async (event, value) => {
                                        if (value.length != 0) {
                                            const selectedSprints = value.map((sprint: any) => (
                                                sprint.sprintId
                                            ));

                                            setSprintListIdArray(selectedSprints);
                                            getBugReportsData(mileStoneIdArray, selectedSprints);

                                        } else {
                                            setSprintListIdArray([]);
                                        }
                                        // if (!value) {
                                        //     setSprintId("");
                                        //     QAReports(mileStonesId, "");
                                        // }
                                        // else {
                                        //     setfiltersSelected(true)
                                        //     setSprintId(value?.sprintId ?? "");
                                        //     //   await getSprintReport(value?.sprintId ?? "");
                                        //     QAReports( mileStonesId, value?.sprintId ?? "");
                                        // }
                                        setfiltersSelected(true);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            //  disabled={disabledDepartments}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={sprintListIdArray}
                                            placeholder="Select Sprints"
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                />
                            </Box>



                            <Box sx={{ display: "flex", alignItems: "flex-end", gap: "16px", marginBottom: "16px" }}>

                                {ExcelDownloadfiltersSelected && qaReports.length > 0 && (
                                    <Button
                                        //disabled={row?.assignedTo != _authUser.id}

                                        variant="contained"
                                        onClick={() => Exportexcel()}>
                                        Download Excel
                                    </Button>)}
                                {filtersSelected && (<Button
                                    onClick={async () => {
                                        if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                                            setfiltersSelected(false);
                                            setdepartmentsId(_authUser.employeeInfo.departmentId);
                                            setProjectId("");
                                            setMileStonesId("");
                                            setSprintId("");
                                            setQaReports([]);
                                            setExcelDownloadfiltersSelected(false);
                                            setMileStoneIdArray([]);
                                            setSprintListIdArray([]);
                                        }
                                        else {
                                            setfiltersSelected(false);
                                            setdepartmentsId("");
                                            setProjectId("");
                                            setMileStonesId("");
                                            setSprintId("");
                                            setQaReports([]);
                                            setExcelDownloadfiltersSelected(false);
                                            setMileStoneIdArray([]);
                                            setSprintListIdArray([]);
                                        }
                                    }}
                                    color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>)}
                            </Box>

                        </Stack>

                    </Grid>
                </Grid>

            </Box>
            <TableContainer component={Paper} sx={{ mt: 1 }}>
                {qaReports && qaReports?.length > 0 ? (
                    <Table aria-label="simple table" size="small" sx={{ position: 'relative', overflowX: 'scroll' }}>
                        <TableHead sx={headcellcolor.cellcolor}>
                            <TableRow>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', Width: '90px', background: 'rgb(189, 189, 189)', }} >Ticket No.</StyledTableCell>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: '400px', background: 'rgb(189, 189, 189)', }} >Summary</StyledTableCell>
                                <StyledTableCell align="left" >Severity</StyledTableCell>
                                <StyledTableCell align="left" >Status</StyledTableCell>
                                <StyledTableCell align="left">Created Date</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {qaReports?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user: any, idx: any) => (
                                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }}>
                                            {user?.ticketNumber || "--"}</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: '400px', background: '#fff', whiteSpace: "unset !important"}}>
                                            {user?.summary || "--"}
                                            </StyledTableCell>
                                        <StyledTableCell align="left">{user?.severity || "--"}</StyledTableCell>
                                        <StyledTableCell align="left">{user?.status || "--"}</StyledTableCell>
                                        <StyledTableCell align="left">{moment(user.createdDate).format("DD-MMM-yy") || "--"}</StyledTableCell>

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>) : (<NotFound NotfoundText="No Record Found" />)}
                <Divider sx={{ my: 0.5 }} />

            </TableContainer>
            <Box sx={{ background: "#fff" }}>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={qaReports?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    labelDisplayedRows={() => `Total: ${qaReports?.length}  Page ${page + 1} of ${pageCount}`}
                />
            </Box>
        </>
    );
}
