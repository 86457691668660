import { useEffect, useState } from 'react';
import { HubConnectionBuilder, HttpTransportType, HubConnection } from '@microsoft/signalr';
import secureLocalStorage from 'react-secure-storage';
import React from 'react';
import { apiUrl } from '../service/common-services/apiURL';
// import { apiUrl, secureLocalStorage } from 'path-to-your-utils'; // Import your utility functions

const useSignalR = () => {
    const [connection, setConnection] = React.useState<null | HubConnection>(null);

  const configSocket = async () => {
    var options = {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => {
        return secureLocalStorage.getItem('authToken')?.toString() ?? '';
      },
    };
    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl.get_HUB_URL()}/notificationhub`, options)
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  };

  useEffect(() => {
    configSocket();
  }, []);
  return {connection};
};

export default useSignalR;
