import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "material-react-toastify";
import AddIcon from "@mui/icons-material/Add";
import StyledTableCell from "../../../components/styledComponent/CustomTableCell";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import * as Icons from "../../../assests/icons/icons";
import * as Yup from "yup";
import NotFound from "../../../components/styledComponent/NotFound";
import { webSiteVisitorService } from "../../../service/master-service/websitevisitors-service";
import { Formik } from "formik";
import { useState } from "react";
const currentMonth = new Date().getMonth();
const monthMap: { [key: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
const yearOption = [
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
];
const monthsOption = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const companyOption = [
  { value: "Seasia", label: "Seasia" },
  { value: "MoogleLabs", label: "MoogleLabs" },
  { value: "BugRaptors", label: "BugRaptors" },
];
const statusOption = [{ label: "2023-24", id: 0 }];
const filteredMonthsOption = monthsOption.filter(
  (option) => option.value <= currentMonth
);
const toggleloaders = {
  position: "fixed",
  left: "50%",
  right: "50%",
  top: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)",
};

const headcellcolor = {
  cellcolor: {
    background: "rgb(189, 189, 189)",
    borderBottom: "none",
  },
};
export default function WebsiteVisitorsData() {
  const [toggleLoader, setToggleLoader] = React.useState(false);
  const [selectedData, setselectedData] = useState<any | null>(null);
  const [companyName, setcompanyName] = useState<any | "">();
  const [visitorYear, setvisitorYear] = useState<number | any>(null);
  const [visitorMonth, setVisitorMonth] = useState<number | any>(null);
  const [newVisitorUser, setnewVisitorUser] = useState<any | "">();
  const [totalVisitorUser, settotalVisitorUser] = useState<any | "">();
  const [websiteVisitorsData, setWebsiteVisitorsData] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(websiteVisitorsData.length / rowsPerPage);
  const [open, setOpen] = React.useState(false);
  const [yearStatus, setYearStatus] = useState<number | undefined>(0);
  const [visitorDataId, setvisitorDataId] = React.useState("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  React.useEffect(() => {
    getWebsiteVisitorData(yearStatus);
  }, []);

  const getWebsiteVisitorData = async (year: any) => {
    let yearValue = year;
    try {
      setToggleLoader(true);
      webSiteVisitorService
        .getWebsiteVisitors(yearValue)
        .then((response: any) => {
          if (response.data.isError) {
            setToggleLoader(false);
          } else {
            setToggleLoader(false);
            setWebsiteVisitorsData(response?.data);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        return toast.warning(err);
      });
    }
  };
  const yearChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedYear = event.target.value as number;
    setYearStatus(selectedYear);
    try {
      setWebsiteVisitorsData([]);
      getWebsiteVisitorData(selectedYear);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const AddWebsiteVisitors = (data: any) => {
    try {
      webSiteVisitorService
        .AddWebsiteVisitor(data)
        .then((response: any) => {
          if (response.data.isError) {
          } else if (response.status == 200) {
            setvisitorDataId("");
            setOpen(false);
            toast.success("Data added successfully.");
            getWebsiteVisitorData(yearStatus);
          }
        })
        .catch((error) => {
          if (error.data.Error.length > 0) {
            toast.dismiss();
            toast.error(error.data.Error[0]);
          }
        });
    } catch (ex: any) {
      toast.warning(ex.Error[0]);
    }
  };
  const UpdateWebsiteVisitors = (data: any) => {
    let abc = data;
    data.id = visitorDataId;
    try {
      webSiteVisitorService
        .UpdateWebsiteVisitor(abc)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            setOpen(false);
            setvisitorDataId("");
            toast.success("Visitor's data updated");
            getWebsiteVisitorData(yearStatus);
          }
        })
        .catch((error) => {
          if (error.data.Error.length > 0) {
            toast.dismiss();
            toast.error(error.data.Error[0]);
          }
        });
    } catch (ex: any) {
      toast.warning(ex.Error[0]);
    }
  };
  const handleEditClick = (data: any) => {
    setOpen(true);
    setselectedData(data);
    setcompanyName(data.companyName);
    setvisitorYear(data.year);
    setVisitorMonth(data.month);
    setnewVisitorUser(data.newUser);
    settotalVisitorUser(data.totalUser);
    setvisitorDataId(data.id);
  };
  const handleClickOpen1 = () => {
    setselectedData([]);
    setcompanyName("");
    setvisitorYear(null);
    setVisitorMonth(null);
    setnewVisitorUser("");
    settotalVisitorUser("");
    setOpen(true);
  };
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    year: Yup.string().nullable().required("Year is required"),
    month: Yup.string().nullable().required("Month is required"),
    newUser: Yup.number()
      .required("New User is required")
      .min(0, "New User cannot be negative"),
    totalUser: Yup.number()
      .required("Total User is required")
      .min(0, "Total User cannot be negative"),
  });
  return (
    <>
      {toggleLoader && <CircularProgress sx={toggleloaders} />}
      <Box sx={{ background: "#fff" }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ fontWeight: 600, marginTop: 1, marginLeft: 1 }}
        >
          Website Visitors
        </Typography>
        <Grid
          container
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ mt: 1.5 }}
        >
          <Grid item xs={12} lg={12}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="flex-end"
              justifyContent="space-between"
              spacing={2}
              sx={{ pb: 3 }}
            >
              <Box>
                <TextField
                  sx={{
                    minWidth: 150,
                    border: "1px solid #D7D5D5",
                    borderRadius: 1,
                    marginLeft: 1,
                  }}
                  id="outlined-basic"
                  select
                  value={yearStatus}
                  variant="outlined"
                  onChange={yearChange}
                >
                  {statusOption.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                {/* <SearchBar
                  value={searchValue}
                  callBackFunction={triggerSearch}
                /> */}
                {/* {_permission.action_ManageDepartmentsListing_Add_Access && ( */}
                <Button
                  variant="contained"
                  size="large"
                  // component={Link}
                  // to="/add-department"
                  onClick={() => {
                    handleClickOpen1();
                  }}
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Add Visitors
                </Button>
                {/* )} */}
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "flex-end", gap: "16px", marginBottom: "16px" }}>
                                <Box >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                        <DatePicker
                                            // disableFuture
                                            label="From"
                                            placeholder="From"
                                            labelAlignment="left"
                                            borderRadius="semi-square"

                                            maxDate={dayjs().toDate()}
                                            // openTo="year"
                                            // views={["year", "month", "day"]}
                                            value={fromDate}
                                            onChange={(newValue: any) => {
                                                let abc = getDate(newValue);
                                                setFromDate(abc ?? null)
                                                setfiltersSelected(true);
                                            }}
                                        // renderInput={(params: any) => <TextField {...params}
                                        //     autoComplete="off" // Disable suggestions
                                        //     readOnly={true}
                                        //     fullWidth
                                        //     sx={{
                                        //         "& .MuiSvgIcon-root": {
                                        //             fill: theme.palette.primary.main,
                                        //         },
                                        //     }}
                                        // />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                        <DatePicker
                                            // disableFuture
                                            label="To"
                                            borderRadius="semi-square"
                                            placeholder="To"
                                            labelAlignment="left"
                                            // openTo="year"
                                            // views={["year", "month", "day"]}
                                            value={toDate}
                                            maxDate={dayjs().toDate()}
                                            onChange={(newValue: any) => {
                                                let abc = getDate(newValue);
                                                setToDate(abc ?? null)
                                                setfiltersSelected(true);
                                            }}
                                        // renderInput={(params: any) => <TextField {...params}
                                        //     autoComplete="off" // Disable suggestions
                                        //     readOnly={true} // Disable user input
                                        //     fullWidth
                                        //     sx={{
                                        //         "& .MuiSvgIcon-root": {
                                        //             fill: theme.palette.primary.main,
                                        //         },
                                        //     }}
                                        // />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Button
                                    onClick={() => {
                                        setfiltersSelected(true);
                                        setPage(0);
                                        getHelpDeskReport(selectedCategory,
                                            selectedStatusValue,
                                            fromDate,
                                            toDate,
                                            searchValue
                                        )
                                    }}
                                    color="primary"
                                    variant="contained"
                                    sx={{ ml: 0.5 }}
                                >
                                    Go
                                </Button>
                                {filtersSelected && (<Button
                                    onClick={async () => {
                                        setfiltersSelected(false);
                                        setToggleLoader(false);
                                        setSelectedStatusValue("");
                                        setSelectedCategory("");
                                        setFromDate(null);
                                        setToDate(null);
                                        setSearchValue("");
                                        getHelpDeskReport("", "", "", "", "")
                                    }}
                                    color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>)}
                            </Box> */}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} sx={{ mt: 1 }}>
        {websiteVisitorsData && websiteVisitorsData?.length > 0 ? (
          <Table
            aria-label="simple table"
            size="small"
            sx={{ position: "relative", overflowX: "scroll" }}
          >
            <TableHead sx={headcellcolor.cellcolor}>
              <TableRow>
                <StyledTableCell align="left">Sr.no</StyledTableCell>
                <StyledTableCell align="left">Company Name</StyledTableCell>
                <StyledTableCell align="left">Year</StyledTableCell>
                <StyledTableCell align="left">Month</StyledTableCell>
                <StyledTableCell align="left">New User</StyledTableCell>
                <StyledTableCell align="left">Total User</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {websiteVisitorsData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, idx: any) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      align="left"
                      sx={{
                        position: "sticky",
                        left: "0",
                        width: "90px",
                        background: "#fff",
                      }}
                    >
                      {idx + 1 + page * rowsPerPage || "--"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        position: "sticky",
                        left: "0",
                        width: "90px",
                        background: "#fff",
                      }}
                    >
                      {item?.companyName || "--"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        position: "sticky",
                        left: "77px",
                        width: "auto",
                        background: "#fff",
                      }}
                    >
                      {item?.year || "--"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {monthMap[item?.month] || "--"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.newUser || "--"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.totalUser || ""}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <IconButton
                        aria-label="view"
                        onClick={() => handleEditClick(item)}
                        size="medium"
                      >
                        <Icons.Edit />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <NotFound NotfoundText="No Record Found" />
        )}
        <Divider sx={{ my: 0.5 }} />
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={websiteVisitorsData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() =>
            `Total: ${websiteVisitorsData?.length}  Page ${
              page + 1
            } of ${pageCount}`
          }
        />
      </TableContainer>

      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            fieldValidationSchemaoverflow: "visible",
            height: "unset",
            width: "45%",
          },
        }}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            companyName: companyName,
            year: visitorYear,
            month: visitorMonth,
            newUser: newVisitorUser,
            totalUser: totalVisitorUser,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async (values: any) => {
            try {
              if (!visitorDataId) {
                await AddWebsiteVisitors(values);
              } else {
                await UpdateWebsiteVisitors(values);
              }
            } catch {}
          }}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            isSubmitting,
            handleBlur,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Website Visitors</DialogTitle>
              <DialogContent sx={{ overflow: "visible !important" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        id="outlined-basic"
                        value={companyOption.find(
                          (option: any) => option.value == companyName
                        )}
                        options={companyOption}
                        getOptionLabel={(option: any) => option.label}
                        onChange={async (e, value) => {
                          setcompanyName(value?.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Name"
                            variant="outlined"
                            name="companyName"
                            onBlur={handleBlur}
                            error={
                              touched.companyName && Boolean(errors.companyName)
                            }
                            helperText={
                              touched.companyName &&
                              typeof errors.companyName === "string"
                                ? errors.companyName
                                : undefined
                            }
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        id="outlined-basic"
                        value={yearOption.find(
                          (option: any) => option.value == visitorYear
                        )}
                        options={yearOption}
                        getOptionLabel={(option: any) => option.label}
                        onChange={async (e, value) => {
                          setvisitorYear(value?.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Year"
                            variant="outlined"
                            name="year"
                            onBlur={handleBlur}
                            error={touched.year && Boolean(errors.year)}
                            helperText={
                              touched.year && typeof errors.year === "string"
                                ? errors.year
                                : undefined
                            }
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Autocomplete
                        id="outlined-basic"
                        value={monthsOption.find(
                          (option) => option.value === visitorMonth
                        )}
                        options={filteredMonthsOption}
                        getOptionLabel={(option: any) => option.label}
                        onChange={async (e, value) => {
                          setVisitorMonth(value?.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Month"
                            variant="outlined"
                            name="month"
                            onBlur={handleBlur}
                            error={touched.month && Boolean(errors.month)}
                            helperText={
                              touched.month && typeof errors.month === "string"
                                ? errors.month
                                : undefined
                            }
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <TextField
                        margin="dense"
                        label="New User"
                        value={newVisitorUser}
                        type="text"
                        name="newUser"
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        onChange={(e) => {
                          let abc = e.currentTarget.value
                            .replace(/[^0-9]\s{0,}$/g, "")
                            .trimStart();
                          setnewVisitorUser(abc);
                        }}
                        onBlur={handleBlur}
                        error={touched.newUser && Boolean(errors.newUser)}
                        helperText={
                          touched.newUser && typeof errors.newUser === "string"
                            ? errors.newUser
                            : undefined
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <TextField
                        margin="dense"
                        label="Total User"
                        value={totalVisitorUser}
                        type="text"
                        name="totalUser"
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        onChange={(e) => {
                          let abc = e.currentTarget.value
                            .replace(/[^0-9]\s{0,}$/g, "")
                            .trimStart();
                          settotalVisitorUser(abc);
                        }}
                        onBlur={handleBlur}
                        error={touched.totalUser && Boolean(errors.totalUser)}
                        helperText={
                          touched.totalUser &&
                          typeof errors.totalUser === "string"
                            ? errors.totalUser
                            : undefined
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
