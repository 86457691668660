import { http } from "../http-common";

class ChessBoardService {
   
    getChessParticipants = async (): Promise<any> => {
        return await http.get<any>("/v1/VotePolling/GetAllGroup");
    };
    getChessMatches = async (): Promise<any> => {
        return await http.get<any>(
            `v1/VotePolling/GetScheduleMatches`
        );
      };  

}

export const chessBoardService = new ChessBoardService();