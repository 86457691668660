import Button from "@mui/material/Button";
import * as Icons from "../../assests/icons/icons";
import { toast } from "material-react-toastify";
import theme from "../../theme/theme";
import React from "react";
// startIcon={<Icons.Upload />}
const styles = {
  InputStyle: {
    maxWidth: "100%",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderStyle: "dashed",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default function FileUploadPics(props: { selectFile?: any; index?: any; disabled?: boolean; }) {
  return (
    <Button
      variant="outlined"
      sx={styles.InputStyle}
      component="label"
      startIcon={<Icons.Upload />}
      disabled={props.disabled || false}
    >
      Upload
      <input
        onChange={(event) => {
          const files = event.target.files ?? new FileList();
          const acceptedFileTypes = [
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/gif",
          ];

          if (files.length > 0) {
            const file = files[0];
            if (acceptedFileTypes.includes(file.type)) {
              // It's an accepted image file
              props.selectFile(files, props.index);
            } else {
              toast.warning("Please select an image file (JPEG, PNG, JPG, GIF).");
            }
          }
        }}
        hidden
        accept=".jpg, .jpeg, .png, .gif"
        type="file"
      />
    </Button>
  );
}
