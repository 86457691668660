import * as React from "react"
import Box from '@mui/material/Box';
import { Avatar, AvatarGroup, Chip, Divider, Paper, Stack, Typography } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { commonFunctions } from "../../service/common-services/common-functions";
import theme from "../../theme/theme";
import { helpDeskReport } from "../../service/hepldeskreport-service";
import { useParams } from "react-router-dom";
import moment from "moment";

const styles: any = {
    userAvatar: {
        justifyContent: "flex-end",
        "& .MuiAvatar-root": {
            textTransform: "Uppercase",
            // fontFamily: "sans-serif",
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.text,
            borderColor: "#fff",
            fontWeight: 500,
            width: "32px",
            height: "32px",
            cursor: "pointer",
            fontSize: 13,
            lineHeight: " 16px",
        },
    },
};
export default function HelpDeskReportView() {
    const _permission = useSelector(
        (state: any) => state.InventoryPermissionApp.InventoryPermission
    );
    const { id } = useParams();
    const [incidentdetail, setIncidentdetail] = React.useState<any>();
    const [comment, setComment] = React.useState<any[]>([]);
    React.useEffect(() => {
        getCommentsByIncidentId();
        getIncidentDetailByIncidentId();
    }, []);


    const getCommentsByIncidentId = async () => {
        try {
            await helpDeskReport
                .getCommentHistory(id).then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setComment(response.data);
                    }
                });
        } catch (ex: any) {
            console.log(ex.data.message)
        }
    };

    const getIncidentDetailByIncidentId = async () => {
        try {
            await helpDeskReport
                .getReportDetailsById(id).then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setIncidentdetail(response.data);
                    }
                });
        } catch (ex: any) {
            toast.warning(ex.data.message);
        }
    };
    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs sx={{ marginBottom: "10px" }} aria-label="breadcrumb">
                    <Typography color="text.primary">Report Detail</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Paper sx={{ height: '100%' }} elevation={0} >
                            <Box sx={{ display: 'flex', p: 2 }}>
                                <Typography fontWeight="bold">Ticket No</Typography>
                                <Typography sx={{ marginLeft: 'auto' }}>{incidentdetail?.id}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', p: 2 }}>
                                <Typography fontWeight="bold">Incidents</Typography>
                                <Typography sx={{ marginLeft: 'auto' }}>{incidentdetail?.title}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', p: 2 }}>
                                <Typography fontWeight="bold">Date</Typography>
                                <Typography sx={{ marginLeft: 'auto' }}>{moment(incidentdetail?.createdDate).format("DD-MMM-yy") || "--"}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', p: 2 }}>
                                <Typography fontWeight="bold">Status</Typography>
                                <Typography sx={{ marginLeft: 'auto' }}>
                                    {(incidentdetail?.status == "ToDo" && <Chip variant="todo" label="ToDo" />)}
                                    {(incidentdetail?.status == "On Going" && <Chip variant="progress" label="On Going" />)}
                                    {(incidentdetail?.status == "Verification Pending" && <Chip variant="todo" label="Verification Pending" />)}
                                    {(incidentdetail?.status == "Resolved" && <Chip variant="review" label="Resolved" />)}
                                    {(incidentdetail?.status == "Escalated" && <Chip variant="todo" label="Escalated" />)}
                                    {(incidentdetail?.status == "On-Hold" && <Chip variant="review" label="On-Hold" />)}
                                    {(incidentdetail?.status == "Rejected" && <Chip variant="hold" label="Rejected" />)}
                                    {(incidentdetail?.status == "Reassign" && <Chip variant="submited" label="Reassign" />)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', p: 2 }}>
                                <Typography fontWeight="bold">Reporter</Typography>
                                <Typography sx={{ marginLeft: 'auto' }}>{incidentdetail?.reporterName}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={9}>
                        <Paper sx={{
                            height: '100%', p: 2,
                            "& .css-13xfq8m-MuiTabPanel-root": {
                                padding: '0',
                                paddingTop: 2,
                            }
                        }} elevation={0}>
                            <Box>
                                <Typography fontWeight="bold" sx={{ fontSize: "26px" }}>Description</Typography>
                                <Typography sx={{ color: theme.palette.grey[700] }}>{incidentdetail?.description || "--"}</Typography>
                            </Box>
                            <Box sx={{ mt: "18px" }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "450" }}>History Activity</Typography>
                                <Divider sx={{ my: 2 }} />
                                {comment.length === 0 && (
                                    <div>
                                        No Activity found With <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{id}</span>
                                    </div>
                                )}
                                {comment.length > 0 && comment?.map((item: any, index: number) => (
                                    <>
                                        <Stack direction="row" alignItems="center">
                                            {/* <Avatar
                                        {...commonFunctions.stringAvatar("")}
                                        src={`${process.env.REACT_APP_SERVER_URL}${""}`}
                                        sx={{ backgroundColor: '#ffb046' }}
                                    /> */}
                                            <AvatarGroup sx={[styles.userAvatar]}>
                                                <Avatar
                                                    {...commonFunctions.stringAvatar(
                                                        item?.name
                                                    )}
                                                />
                                            </AvatarGroup>
                                            <Box sx={{ paddingLeft: "10px" }}>
                                                <Typography>{item?.name}</Typography>
                                                <Typography sx={{ color: theme.palette.grey[700] }}>{item?.comments}</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" justifyContent="flex-end">
                                            <Typography sx={{ color: theme.palette.grey[400] }}>
                                                {moment(item?.date).fromNow() || "--"}
                                            </Typography>
                                        </Stack>
                                        <Divider sx={{ my: 0.5 }} />
                                    </>))}
                            </Box>
                        </Paper>

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
