import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TablePagination, TableSortLabel, Typography, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import { noticeService } from "../../service/notice-service";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import * as Icons from "../../assests/icons/icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Tooltip } from "@material-ui/core";
import NotFound from "../../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import { Dayjs } from "dayjs";
import SearchBar from "../../components/styledComponent/SearchBar";
import ClearIcon from "@mui/icons-material/Clear";

const LongText = (props: {
  content?: any;
  limit?: any;
  }) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);
  if (props.content.length <= props.limit) {
    // there is nothing more to show
    return <div>{props.content}</div>
  }
  if (showAll) {
    // We show the extended text and a link to reduce it
    return <Box  sx={{ whiteSpace: "break-spaces" }}> 
      {props.content}
      <Tooltip title="Show Less">
      <Button variant="text" size="small" onClick={showLess}>less...</Button></Tooltip>
    </Box>
  }
  const toShow = props.content ? props.content.substring(0, props.limit) :"";
  return <Box sx={{ whiteSpace: "break-spaces" , width: "200px"}}>
    {toShow}
    <Tooltip title="Show More">
    <Button variant="text" size="small" onClick={showMore}>more...</Button></Tooltip>
  </Box>
}

const styles : any  = {
  EventInfoCard: {
    border: "1px solid #EEEEEE",
    padding: "18px 14px",
    bordeRadius: "5px",
    cursor: "pointer",
    minHeight: '229px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:hover": {
      boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
    },
  },
    innerSpace: {
      // padding: theme.spacing(2),
      fontWeight: 500,
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
    },
    EventName: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.palette.primary.dark,
    },
    EventId: {
        letterSpacing: '0.1px',
        textTransform: 'capitalize',
        fontWeight: '500',
        fontSize: '14px',
        color: '#232360',
    },
    EventDesc: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: ' #616161',
        marginTop: '15px',
    },
    EventDate: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: ' #616161',
        marginLeft: '10px'

    },
    LineClamp: {
        marginTop: '15px',
        whiteSpace: 'nowrap',
        width: '90%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    DialogTitle: {
        fontWeight: 600,
        fontSize: '26px',
        lineHeight: ' 39px',
        color: theme.palette.primary.dark,

    },
    Schedulefor: {
        fontWeight: 400,
        fontSize: '16.2314px',
        lineHeight: '19px',
        color: ' #212121',
        margin: 0,
    },



 Active : {
    fontWeight: 400,
    color: theme.palette.success.dark,
    m: 0,
},

 Schedule: {
    fontWeight: 400,
    color: theme.palette.success.dark,
    m: 0,
 },

 Expired : {
    fontWeight: 400,
    color: theme.palette.error.dark,
    m: 0,
},
};
interface Notice {
    title: string,
    description: string,
    visibility: string,
    notice: boolean,
    startDate: Date,
    endDate: Date
}
const styleCross = {
  position: "absolute" as "absolute",
  right: "03px",
  top: "03px",
};

interface NoticeView {
  id: string;
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  status: string;
  startDate: Date;
  endDate: Date;
}
interface EventView {
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  status: string;
  startDate: Date;
  endDate: Date;
  id: string;
}

const initialvalues = {
    id: "",
    title: "",
    description: "",
    visibility: "",
    notice: "",
    startDate: "",
    endDate: ""
};

enum Status {
  All = 0,
  Active = "1",
  Scheduled = "2",
  Expired = "3",
}
type StatusList = keyof typeof Status;




export default function Noticeonlyview(modalOpen: any) {
  const _permission = useSelector((state: any) => state.NotificationPermissionApp.NotificationPermission);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openStatus = Boolean(anchorEl);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [notices, setNotices] = useState<any>([]);
  const [FilterNotices, setFilterNotices] = useState<any>([]);
  const [status, setStatus] = useState("Status");
  const navigate = useNavigate();
  enum Status {
    All = 0,
    Active = "1",
    Scheduled = "2",
    Expired = "3",
  }
  type StatusList = keyof typeof Status;

  ///update programming

  const [user, setUser] = useState(initialvalues);
  // const { id } = useParams();
  //alert(id);
  // let state = { userid: id ?? "" };
  // const [previousvalue, setPreviousvalue] = React.useState(state.userid);
  // const [userIdHook, setUserIdHook] = React.useState(previousvalue);


  let obj: Notice = {
    title: "",
    description: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    notice: false,
  };
  const [id, setId] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [formValues, setFormValues] = React.useState<Notice>(obj);
  const [title, setTitle] = React.useState(formValues.title);
  const [description, setDescription] = React.useState(formValues?.description);
  const [visibility, setVisibility] = React.useState(formValues?.visibility);
  const [startDate, setStartDate] = React.useState("");

  const [endDate, setEndDate] = useState<string | null>("");
  const [notice, setNotice] = React.useState(formValues?.notice);
  const [visible, setVisible] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const [page, setPage] = React.useState(0);
  var noticeSearch = { id: "", name: "All Channel" };
  const [noticeHook, setNoticeHook] = useState(noticeSearch);
  let statusValue = "All";
  const [statusDataHook, setStatusDataHook] = useState(statusValue);

  ////////Pagination Programming
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const pageCount = Math.ceil(notices.length / rowsPerPage);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  ////////over

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseStatus = () => {
    setAnchorEl(null);

  };
  function removeTagsAndAmp(str:any) {
    // Remove HTML tags
    str = str.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str.replace(/&amp;/g, '&');
    return str;
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmit(false);
    setErrorMessage(false);
    if (id == "" && id.length == 0) {
      setEndDate("");
      setStartDate("");
      setDescription("");
      setVisibility("");
      setTitle("");
      setVisible(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setId("");
    setVisible(false);
  };

  const [openView, setOpenView] = React.useState(false);
  const [noticeView, setNoticeView] = React.useState<NoticeView | null>(null);
  const handleClickOpenView = async (id: any) => {
    await noticeService.getNoticeViewId(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setNoticeView(response?.data);
        setOpenView(true);
      }
    });
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const [noticeOpen, setnoticeOpen] = React.useState(false);
  useEffect(() => {
    if (_permission.page_NoticeManagement_Access !== true) {
      navigate("/dashboard");
    }
    setnoticeOpen(modalOpen);
    //getNoticesDatabyId();
    getNoticeDetails(statusValue);
  }, []);

  const getNoticeDetails = async (status: any, searchValue: string = "") => {
    var input = {
      status: status == "All" ? "0" : status,
      search: searchValue,
    };
    await noticeService.getNotice(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        let array: any[] = [];


        if (response?.data.length > 0) {
          response?.data.map((a: any) => {
            a.formattedHtml = a?.description?.replace(/<[^>]+>/g, "");
            array.push(a);
          });
        }

        setNotices(array);
      }
    });
  };

  const filterNoticebyStatus = (status: any) => {
    statusValue = status;
    getNoticeDetails(status);
    setStatusDataHook(status);
  };


  const deleteNoticeData = async (id: any, e: any) => {
    e.stopPropagation();
    setOpenDelete(true);
    setCurrentId(id);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const getDate = (dateObject: Date) => {
    let date = new Date(dateObject).toISOString();
    return  date?.slice(0, 10) + 'T00:00:00Z';
  }
  /////update programming
  const getNoticesDatabyId = async (id: any, e: any) => {
    e.stopPropagation();
    await noticeService.getNoticeById(id).then((response: any) => {
      if (response.data.isError) {
      } else {

        setId(response?.data.id);
        setNotice(response?.data);
        setTitle(response?.data.title);
        setVisibility(response?.data.visibility);
        if (response?.data.visibility) {
          setIsChecked(true);
        }
        setEndDate(response?.data.endDate);
        setStartDate(response?.data.startDate);
        setNotice(response?.data.notice);
        setDescription(response?.data.description);
        setVisible(response?.data.status == "Scheduled");
        //setVisible(response?.data. startdate == 'Schedule' ? 'now' : status);
        setOpen(true);
      }
    });
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const triggerSearch = async (newValue: string) => {
    getNoticeDetails(statusValue, newValue);
    setPage(0);
  };
  

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1}}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
       {/* <Typography color="text.primary">Notofications</Typography> */}
       <Typography color="text.primary">Notice</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Notice Board
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            
            
            <SearchBar
              setSearchValue={setSearchValue}
              callBackFunction={triggerSearch}
            />
            
          </Stack>
        </Stack>
        {notices && notices.length > 0 ? (
          <Grid container spacing={2}>
            {notices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event: any) => (
                <Grid item xs={12} lg={3}>
                <Paper
                  elevation={0}
                  sx={[styles.EventInfoCard]}
                  onClick={() => handleClickOpenView(event.id)}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      component="p"
                      sx={[styles.EventId]}
                    ></Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        justifyContent="space-between"
                        component="p"
                        sx={[styles[`${event.status}`], styles.innerSpace]}
                      >
                        <Icons.dots />
                        &nbsp;{event.status}
                      </Typography>
                      
                    </Box>
                  </Stack>

                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body1"
                      component="h4"
                      sx={[styles.EventName]}
                    >
                      {event?.title?.length > 30
                        ? event?.title.slice(0, 30 - 1) + "..."
                        : event?.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={[styles.EventDesc]}
                    >
                      {/* {event.formattedHtml?.length > 52
                        ? event.formattedHtml.slice(0, 52 - 1) + "..."
                        : event.formattedHtml} */}
                        {removeTagsAndAmp(event?.description)?.length > 30
                        ? removeTagsAndAmp(event?.description).slice(0, 30 - 1) + "..."
                        : removeTagsAndAmp(event?.description)}
                    </Typography>
                  </Box>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 3 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography component="p" sx={[styles.EventId]}>
                        Created
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        sx={[styles.EventDate]}
                      >
                        {/* {event.createdDate} */}
                        {`${moment(event?.createdDate).format(
                          "DD MMM, YYYY"
                        )}`}
                      </Typography>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              ))}
          </Grid>
        ) : (
          <NotFound NotfoundText="No Result Found" />
        )}
        <TablePagination
          labelRowsPerPage="Cards per page"
          rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
          component="div"
          count={notices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        
          labelDisplayedRows={() => `Total: ${notices?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={openView}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
         <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          View Notice
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleCloseView}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Title:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {noticeView?.title}
              </Typography>
            </Stack>
            <Typography
              justifyContent="space-between"
              component="p"
              sx={[styles[`${noticeView?.status}`], styles.innerSpace]}
            >
              <Icons.dots />
              &nbsp;
              {noticeView?.status}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Scheduled For:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {`${moment(noticeView?.startDate).format("DD MMM, YYYY")}`} to
                {`${moment(noticeView?.endDate).format("DD MMM, YYYY")}`}
              </Typography>
            </Stack>
          </Stack>
          <Paper variant="outlined" sx={{ p: 2, mt: 3, textAlign: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                "& img": {
                  maxWidth: "100%",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: noticeView?.description as string,
              }}
            ></Typography>
          </Paper>
        </DialogContent>
       
        <DialogActions sx={{ p: 3 }}>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleCloseView}
          >
            Back to List
          </Button>
        </DialogActions>
      </Dialog>
  
    </>
  );
}
  