import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box, { BoxProps } from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import { ToastContainer, toast } from "material-react-toastify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { settingManagementServive } from "../../service/setting-board-service";
import theme from "../../theme/theme";
// interface Props {
//     color: 'productBacklog' | 'sprintBacklog' | 'inProgress' | 'review' | 'hold'
// }

const dummy = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

type StringArray = string[];

const StatusDummy: StringArray = [
  "Product Backlog",
  "Sprint Backlog",
  "In Progress",
  "Review",
  "Resolved",
  "QA",
  "QA Failed",
  "Hold",
  "Blocked",
  "Closed",
];

interface Idummy {
  id: string;
  value: string;
  label: string;
}
const StatusDummy1: Idummy[] = [
  {
    id: "1",
    value: "Product Backlog",
    label: "Product Backlog",
  },
  {
    id: "2",
    value: "Sprint Backlog",
    label: "Sprint Backlog",
  },
  {
    id: "3",
    value: "In Progress",
    label: "In Progress",
  },
  {
    id: "4",
    value: "Review",
    label: "Review",
  },
  {
    id: "5",
    value: "Resolved",
    label: "Resolved",
  },
  {
    id: "6",
    value: "QA",
    label: "QA",
  },
  {
    id: "7",
    value: "QA Failed",
    label: "QA Failed",
  },
  {
    id: "8",
    value: "Hold",
    label: "Hold",
  },
  {
    id: "9",
    value: "Blocked",
    label: "Blocked",
  },
  {
    id: "10",
    value: "Closed",
    label: "Closed",
  },
];

const styles: any = {
  lightBackground: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 500,
    marginTop: "0.6rem",
  },
  innerSpace: {
    padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  ProductBacklog: {
    backgroundColor: theme.palette.primary.light,
    "&.MuiPaper-root": {
      color: theme.palette.primary.main,
    },
  },
  SprintBacklog: {
    backgroundColor: "#D9D9D9",
    "&.MuiPaper-root": {
      color: theme.palette.grey[600],
    },
  },
  InProgress: {
    backgroundColor: theme.palette.success.light,
    "&.MuiPaper-root": {
      color: theme.palette.success.main,
    },
  },
  Review: {
    backgroundColor: theme.palette.warning.light,
    "&.MuiPaper-root": {
      color: theme.palette.warning.main,
    },
  },
  Hold: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  Resolved: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  QA: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  QAFailed: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  Blocked: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  Closed: {
    backgroundColor: theme.palette.error.light,
    "&.MuiPaper-root": {
      color: theme.palette.error.main,
    },
  },
  textWrap: {
    whiteSpace: "normal",
    fontWeight: 500,
  },
};
const TicketLabel = styled(Chip)({
  border: "none",
  background: "none",
  padding: 0,
  marginTop: theme.spacing(1),
  color: theme.palette.primary.main,
  "& .MuiChip-label": {
    fontWeight: 500,
    fontSize: "15px",
  },
});
const KanbanWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));

const KanbanCol = {
  width: 220,
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(1),
  "&:last-of-type": {
    marginRight: theme.spacing(0),
  },
};

interface settingWorflow {
  projectId: string;
  spillOver: string;
  workflow: any;
}

interface Project {
  id: string;
  // projectDescription: string;
  projectName: string;
  // status: number;
  // clientName: string;
  // projectOwnerName: string;
  // departmentName: string;
  // costingType: string;
  // pin: string;
  // keyWords: string;
}

interface IprojectDetail {
  id: string;
  projectName: string;
}

export default function ColumnManagement() {
  const [age, setAge] = React.useState("");
  const navigate = useNavigate();
  const [disableContinue, setDisableContinue] = useState(true);
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  const [arrayValue, setArrayValue] = React.useState<any>(StatusDummy);
  const [projectDetailHook, setProjectDetailHook] = useState<IprojectDetail>();
  const [storeValue, setUsersValue] = React.useState<any>([]);
  const [activeMenu, setActiveMenu] = useState<any>(null);
  
  // const [workflowStatus, setWorkflowStatus] = useState([statusWorkflowSet]);

  // let addWorkflowStatus = () => {
  //   setWorkflowStatus([...workflowStatus, {}]);
  // };
  const [filtereditems, setfiltereditems] = React.useState<any>([]);

  

  let removeWorkflowStatus = (i: any) => {
    
    let newAllocateChange = [...worflowSetting];
    newAllocateChange.splice(i, 1);
    setfiltereditems(newAllocateChange);
    setworflowSetting(newAllocateChange);
  };

  //   const [DepartmentTT, setDepartmentTT] = useState<DepartmentT[]>([]);
  const { id } = useParams();
  const [usersProject, setUsersProject] = useState<Project[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any, id: any) => {
    // setArrayValue(event.currentTarget.id);
    setActiveMenu(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
   
    setAnchorEl(null);
    setActiveMenu(null);
  };

  useEffect(() => {
    getProjDetails();
    getGetAssignResource();
    getProjectDetails(id);
  }, []);

  // useEffect(() => {

  //   getGetAssignResource();

  // }, [id]);

  let obj: settingWorflow = {
    projectId: "",
    spillOver: "",
    workflow: [],
  };

  let obj1: Project = {
    id: "",
    projectName: "",
  };

  const [formValues, setFormValues] = React.useState<settingWorflow>(obj);
  const [formValues1, setFormValues1] = React.useState<Project>(obj1);
  const [projectIdSetting, setProjectIdSetting] = React.useState(
    formValues?.projectId
  );

  const [projectNameId, setprojectNameId] = React.useState(
    formValues1?.projectName
  );
  const [spillOverSetting, setspillOverSetting] = React.useState(
    formValues?.spillOver
  );
  const [worflowSetting, setworflowSetting] = React.useState(
    formValues?.workflow
  );

  //   useEffect(() => {
  //     if(projectIdSetting)
  //     toast.success("Setting Update Successfully.");
  //   }, [projectIdSetting]);
  const addSettingApi = async (values: any) => {

    try {
      let obj: settingWorflow = {
        projectId: projectIdSetting,
        spillOver: spillOverSetting,
        workflow: filtereditems.length > 0 ? filtereditems : worflowSetting,
      };
      await settingManagementServive
        .addSettings(obj)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            setDisableContinue(true);
            toast.success("Sprint Update successfully.");
            navigate("/board");
          }
        });
    } catch (ex: any) {
      
      ex.obj.Error.map((err: any, idx: any) => {
        setDisableContinue(true);
        toast.warning(err);
      });
    }
  };

  

  const getProjDetails = async () => {
    await settingManagementServive.getAllProjectSecure(0).then((response: any) => {
      if (response.data.isError) {
      } else {
        setUsersProject(response?.data);
      }
    });
  };

  

  const getProjectDetails = async (id: any) => {
    try {
      
      await settingManagementServive
        .getPProjectDetailsById(id)
        .then(async (response: any) => {
          
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            setProjectIdSetting(response.data.id ?? "");
            setprojectNameId(response.data.projectName ?? "");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
      
    }
  };

  const getGetAssignResource = async () => {
    debugger
    console.clear();
    await settingManagementServive
      .GetProjectBoardWorkById(id)
      .then((response: any) => {
       
        if (response?.data.spillOver == null) {
          toast.warning("Please Select SpillOver");
        } else {
          // setDateOfBirth(response.data.personalInfo.dateOfBirth ?? "");

          setFormValues(response.data ?? "");
          setProjectIdSetting(response.data.projectId ?? "");
          setprojectNameId(response.data.projectName ?? "");
         
          setspillOverSetting(response?.data.spillOver ?? "");
          setDisableContinue(true);
        }
        if (response?.data?.workflow && response?.data.workflow.length > 0) {
          setworflowSetting(response?.data.workflow);
          
        } else {
          setworflowSetting(arrayValue);
          
        }
       
      });
  };
  
  return (
    <Formik
      initialValues={{
        projectId: projectIdSetting,
        spillOver: spillOverSetting,
      }}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
       
        addSettingApi(values);
        // try {

        //   // useEffect(() => {
        //   //     setValue(1);setValue(0);
        //   // }, [])
        // } catch (err) {
        //   console.error(err);
        // }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
              <Link color="inherit" to="/">
                Dashboard
              </Link>
              <Link color="inherit" to="/board">
                Board
              </Link>
              <Typography color="text.primary">Column Management</Typography>
            </Breadcrumbs>
            <Paper
              elevation={0}
              sx={{
                overflow: "scroll",
                position: "relative ",
                height: "calc(100vh - 100px)",
                minWidth: "0",
                backgroundColor: "#fff",
                p: 2,
              }}
            >
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  component="p"
                  gutterBottom
                >
                  {/* Column Constraint */}
                </Typography>
                <FormControl sx={{ minWidth: 200 }}>
                  {/* <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{}}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select> */}
                  {/* {JSON.stringify(projectIdSetting, null, 2)} */}
                  <TextField
                    disabled={disableContinue}
                    id="outlined-basic"
                    label="Projects"
                    value={projectNameId}
                    variant="outlined"
                    sx={{ width: 350 }}
                  >
                    {/* {usersProject.map((option, idx) => (
                      <MenuItem
                        key={option.projectName}
                        value={option.id}
                        onClick={(event) => {
                          values.projectId = usersProject[idx].id;
                          setProjectIdSetting(usersProject[idx].id);
                        }}
                      >
                        {option.projectName}
                      </MenuItem>
                    ))} */}
                  </TextField>
                </FormControl>
              </Box>

              <Box sx={{ mt: 3, mb: 4 }}>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  component="p"
                  gutterBottom
                >
                  {/* Simplified Workflow */}
                </Typography>
                <FormControl sx={{ minWidth: 200 }}>
                  {/* {JSON.stringify(spillOverSetting, null, 2)} */}
                  <TextField
                    id="outlined-basic"
                    label="Spill Over Flow"
                    value={spillOverSetting}
                    variant="outlined"
                    select
                    sx={{ width: 200 }}
                  >
                    {dummy.map((option, index) => (
                      <MenuItem
                        key={option.value}
                        value={option.label}
                        onClick={(event) => {
                          values.spillOver = dummy[index].label;
                          setspillOverSetting(dummy[index].label);
                        }}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                    {/* {cclientInfort.map((option) => (
                  <MenuItem key={option.id} value={option.projectName}>
                    {option.projectName}
                  </MenuItem> */}
                    {/* ))} */}
                  </TextField>
                  {/* <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{}}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select> */}
                </FormControl>
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  setworflowSetting(arrayValue);
                }}
                type="button"
              >
                Reset
              </Button>
              <br></br>
              <br></br>

              <KanbanWrapper sx={{ position: "sticky", top: 0, zIndex: 99 }}>
                {worflowSetting.map((user: any, index: any) => (
                  <Box sx={KanbanCol}>
                    <Paper
                      elevation={0}
                      sx={[
                        styles[`${user.replace(" ", "").replace("-", "")}`],
                        styles.innerSpace,
                      ]}
                    >
                      {user}
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        key={"elipses_" + index}
                        aria-label="delete"
                        size="small"
                        id={user.id}
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(event: any) => handleClick(event, user)}
                      >
                        <MoreHorizIcon fontSize="inherit" />
                      </IconButton>
                      <Menu
                        key={user}
                        id={"basic-menu" + user}
                        anchorEl={anchorEl}
                        open={activeMenu === user}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          key={index}
                          // onClick={handleClose}
                          onClick={(event) => {
                            //alert(index);
                            // handleClose(event);
                            removeWorkflowStatus(index);
                          }}
                          sx={{ color: theme.palette.error.dark }}
                        >
                          <ListItemText>Remove</ListItemText>
                        </MenuItem>
                      </Menu>
                    </Paper>
                  </Box>
                ))}
                {/* <Box sx={KanbanCol}>
                          <Paper
                            elevation={0}
                            sx={[styles.sprintBacklog, styles.innerSpace]}
                          >
                            Sprint Backlog <Box sx={{ flexGrow: 1 }} />
                            <IconButton aria-label="delete" size="small">
                              <MoreHorizIcon fontSize="inherit" />
                            </IconButton>
                          </Paper>
                        </Box>
                        <Box sx={KanbanCol}>
                          <Paper
                            elevation={0}
                            sx={[styles.inProgress, styles.innerSpace]}
                          >
                            InProgress <Box sx={{ flexGrow: 1 }} />
                            <IconButton aria-label="delete" size="small">
                              <MoreHorizIcon fontSize="inherit" />
                            </IconButton>
                          </Paper>
                        </Box>
                        <Box sx={KanbanCol}>
                          <Paper elevation={0} sx={[styles.review, styles.innerSpace]}>
                            Review <Box sx={{ flexGrow: 1 }} />
                            <IconButton aria-label="delete" size="small">
                              <MoreHorizIcon fontSize="inherit" />
                            </IconButton>
                          </Paper>
                        </Box> */}
                {/* <Box sx={KanbanCol}>
                          <Paper elevation={0} sx={[styles.hold, styles.innerSpace]}>
                            Review <Box sx={{ flexGrow: 1 }} />
                            <IconButton aria-label="delete" size="small">
                              <MoreHorizIcon fontSize="inherit" />
                            </IconButton>
                          </Paper>
                        </Box> */}
                {/* <Box sx={KanbanCol}>
                          <Paper elevation={0} sx={[styles.hold, styles.innerSpace]}>
                            Review <Box sx={{ flexGrow: 1 }} />
                            <IconButton aria-label="delete" size="small">
                              <MoreHorizIcon fontSize="inherit" />
                            </IconButton>
                          </Paper>
                        </Box> */}
              </KanbanWrapper>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <Button variant="contained" type="submit">
                  Save Setting
                </Button>
              </Stack>
            </Paper>
          </>
        </form>
      )}
    </Formik>
  );
}
