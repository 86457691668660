import { combineReducers } from "redux";
const Fill_Prism_Permission = 'Fill_Prism_Permission';
const Empty_Prism_Permission = 'Empty_Prism_Permission';

const defaultPermissions = {
    access: false,
    module_Prism_Access: false,

    page_Escalation: false,
    page_Risk_Alarm: false,
    page_Chat: false,
    page_Client_Feedback : false,

}
export function FillPrismPermission(module) {
    return {
        type: Fill_Prism_Permission,
        module,
    }
}
export function EmptyPrismPermission() {
    return {
        type: Empty_Prism_Permission, defaultPermissions
    }
}
function PrismPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Prism_Permission:
            var data = action.module;
            var permissions = defaultPermissions;        
            if (data) {
                permissions.access = true;
                permissions.module_Prism_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                    data.listPageMaster.forEach((page) => {
                        if (page.pageName === "Escalation") {
                            permissions.page_Escalation = true;
                        }
                        if (page.pageName === "Risk Alarm") {
                            permissions.page_Risk_Alarm = true;
                        }
                        if (page.pageName === "Chat") {
                            permissions.page_Chat = true;
                        }
						 if (page.pageName === "Client Feedback") {
                            permissions.page_Client_Feedback = true;
                        }
                    });
                }
            }
            else {
                return state = defaultPermissions;
            }
        case Empty_Prism_Permission:
            return state = defaultPermissions;
        default:
            return state;
    }
}
const PrismPermissionApp = combineReducers({
    PrismPermission
})
export default PrismPermissionApp;