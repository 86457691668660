import { Box, Button, Tooltip } from "@mui/material";
import { useState } from "react";

const LongText = (props: {
    content?: any;
    limit?: any;
    }) => {
    const [showAll, setShowAll] = useState(false);
  
    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    if (props?.content?.length <= props.limit) {
      // there is nothing more to show
      return <div>{props.content}</div>
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return <Box  sx={{ whiteSpace: "break-spaces" }}> 
        {props.content}
        <Tooltip title="Show Less">
        <Button variant="text" size="small" onClick={showLess}>less...</Button></Tooltip>
      </Box>
    }
    const toShow = props.content ? props.content.substring(0, props.limit) :"";
    return <Box sx={{ whiteSpace: "break-spaces", display: "inline-block", width: "auto"}}>
    {toShow}
    <Tooltip title="Show More">
      <Button variant="text" size="small" onClick={showMore}>...</Button>
    </Tooltip>
  </Box>
  }


  export default LongText;