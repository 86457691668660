import React from 'react';
import theme from '../../theme/theme';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Chip, { ChipProps } from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import StyledTableCell from '../../components/styledComponent/CustomTableCell'
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Link from "@mui/material/Link";
import TableCell from '@mui/material/TableCell';





export default function UserImportedData() {
    return (
        <>
            <Paper elevation={0} sx={{ p: 3, pb: 0, }}>

                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
                    <Typography variant="h5" component="h2" sx={{ fontWeight: 600, color: theme.palette.primary.dark }}>
                        User Imported Successfully
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Stack direction="row" spacing={3}>
                        <Typography variant="body1" sx={{ color: theme.palette.primary.dark }}>
                            Total Record 20
                        </Typography>
                        <Typography variant="body1"  sx={{ color: theme.palette.success.dark }}>
                            Success 07
                        </Typography>
                        <Typography variant="body1" sx={{ color: theme.palette.success.dark }}>
                            Already Exits 07
                        </Typography>
                        <Typography variant="body1" sx={{ color: theme.palette.error.dark }}>
                            Failed 06
                        </Typography>
                    </Stack>

                </Stack>

                <Link variant="body1">
                    Download Summary
                </Link>

                <TableContainer component={Paper} elevation={0} sx={{mt: 3}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sr. No</TableCell>
                                <TableCell >Empl id</TableCell>
                                <TableCell >Name</TableCell>
                                <TableCell >Middle Name  </TableCell>
                                <TableCell >Last Name</TableCell>
                                <TableCell >Department</TableCell>
                                <TableCell >Designation</TableCell>
                                <TableCell >Role</TableCell>
                                <TableCell >Login Id </TableCell>
                                <TableCell >Email Id </TableCell>
                                <TableCell >Date of joining </TableCell>
                                <TableCell >Location</TableCell>
                                <TableCell >Reason</TableCell>
                           
                            </TableRow>
                        </TableHead>
                        <TableBody>
                         
                                <TableRow                                  
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell> 01  </TableCell>
                                    <TableCell>#74012</TableCell>
                                    <TableCell>John </TableCell>
                                    <TableCell>Deo</TableCell>
                                    <TableCell>Designing </TableCell>
                                    <TableCell>Design Manager</TableCell>
                                    <TableCell>Employee</TableCell>
                                    <TableCell>#3110</TableCell>
                                    <TableCell>John@domain.com</TableCell>
                                    <TableCell>06 Sept, 2022</TableCell>
                                    <TableCell>Mohali</TableCell>
                                    <TableCell>NA</TableCell>
                                </TableRow>
                         
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </>
    );
}