const projecttrailor = {
    projectId: '',
    projectName: '',
    artifacts: []
}


export const ProjectTrailerArrayObject = [
    {
        label: "Project Charter",
        value: "Project Initiation"     
    },
    {
        label: "Project Kick-off meeting",
        value: "Project Initiation" 
    },
    {
        label: "Resource onboarding process",
        value: "Project Initiation" 
    },
    {
        label: "Minutes of Meeting",
        value: "Project Planning"
    },
    {
        label: "Project release plan",
        value: "Project Planning"
    },
    {
        label: "Release notes",
        value: "Project Planning"
    },
    {
        label: "Sprint capacity planner",
        value: "Project Planning"
    },
    {
        label: "Releases checklist",
        value: "Project deployment"
    },
    {
        label: "Change request doc",
        value: "Requirement Management"
    },
    {
        label: "Change request log",
        value: "Requirement Management"
    },
    {
        label: "Technical DOU",
        value: "Technical Solution"
    },
    {
        label: "Architecture Document",
        value: "Technical Solution"
    },
    {
        label: "Risk Tracking",
        value: "Risk Management"
    },
    {
        label: "Project name KPI",
        value: "Monitoring & Control"
    },
    {
        label: "Test Plan",
        value: "Ver / Val"
    },
    {
        label: "Test Cases",
        value: "Ver / Val"
    },
    {
        label: "QA Sign Off Doc",
        value: "Ver / Val"
    },
    {
        label: "Case Study",
        value: "Closure"
    }
]

  export default projecttrailor;