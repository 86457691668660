import React, { useEffect, useState } from "react";
import CardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {  Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import theme from "../../theme/theme";
import Grid from "@mui/material/Grid";
import * as Icons from "../../assests/icons/icons";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { userManagementServive } from "../../service/user-management-service";
import { Any } from "@react-spring/types";
import { useNavigate } from "react-router-dom";
import { commonKeys } from "../../service/common-keys";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { apiUrl } from "../../service/common-services/apiURL";
import { projectManagementServive } from "../../service/project-management-service";
import { toast } from "material-react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
const CustomCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: "8px",
  paddingLeft: "0",
  alignItems: "center",
  // width: 300,
  // color: theme.palette.success.main,
  "& .MuiCardHeader-title": {
    fontSize: "0.875rem",
    fontWeight: "300",
    marginBottom: "4px",
    color: theme.palette.grey[800],
    //   '&.Mui-active': {
    //     boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    //   },
  },

  "& .MuiCardHeader-subheader": {
    fontSize: "0.9rem",
    wordBreak: "break-all",
    fontWeight: "400",
    color: theme.palette.grey[900],
    "& span": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "8px",
      },
    },
  },
}));
const styleCross = {
  position: "absolute" as "absolute",
  right: "5px",
  top: "5px",
};

const CustomIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  bottom: "0",
  position: "absolute",
  right: "20px",
  background: theme.palette.primary.main,
  width: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: theme.palette.primary.main,
  },
}));

interface EmployeeInfo {
  id: string;
  isDeleted: boolean;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  userName: string;
  imageFile: string;
  token: string;
  password: string;
  manager: string;
  department: string;
  role: string;
  designation: string;

  personalInfo: {
    dateOfBirth: string;
    gender: string;
    bloodGroup: string;
    maritalStatus: string;
    personalEmailId: string;
    phoneNumber: string;
    profileImagePath: string;
  };

  employeeInfo: {
    businessNumber: string;
    location: string;
    teamId: string;
    slackId: string;
    employeeId: string;
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    departmentId: string;
    emailId: string;
    dateOfJoining: Date;
    currentTeaure: number;
    userName: string;
    department: string;
    departmentName: string;
    designation: string;
    designationName: string;
    status: string;
    managerId: string;
    managerName: string;
    roleId: string;
    roleName: string;
    isTrackerRequired: boolean;
  };

  otpDetail: {
    otp: string;
    otpCreatedTime: string;
    usernameOTP: string;
    usernameOTPCreatedTime: string;
  };
  skillsets: [
    {
      skillId: string;
      name: string;
      level: string;
    }
  ];
  documents: [
    {
      documentType: string;
      fileName: string;
    }
  ];

  pastExperince: [
    {
      organizationName: string;
      designation: string;
      workedFrom: Date;
      workedTo: Date;
      workType: string;
    }
  ];

  badges: [
    {
      badgeName: string;
      badgeImageUrl: string;
    }
  ];

  achievements: [
    {
      achievementName: string;
      achievementImageUrl: string;
    }
  ];
  others: [
    {
      otherName: string;
      otherImageUrl: string;
    }
  ];
}
export default function UserProfile(props: any) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.UserPermissionApp.UserPermission
  );
  let connonkeys = new commonKeys();
  const navigate = useNavigate();

  const { id } = useParams();
  let uId = id ?? 0; //"632da2f9fa37260b70bf4e1f";
  setTimeout(() => {
    if (!uId && (uId === "" || uId === 0)) {
      navigate("/user-management");
    }
  }, 200);

  const [data, setData] = useState<EmployeeInfo>();
  const [visibleButton, setVisibleButton] = useState<any>(false);
  const userIdd = _authUser.id;
  const [userId, setUserId] = useState<string>("");
  const [profileImage, setProfileImage] = useState<any>(null);
  const [currentTeaure, setCurrentTeaure] = useState("");
  const skillsets = [
    {
      skillId: "",
      name: "",
      level: "",
    },
    {
      skillId: "",
      name: "",
      level: "",
    },
  ];

  const documents = [
    {
      documentType: "",
      fileName: "",
    },
    {
      documentType: "",
      fileName: "",
    },
  ];
  const [fullName, setfullName] = useState("");

  const [openAlert, setAlertOpen] = React.useState(false);
  const [openDocumentAlert, setDocumentAlertOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);


  const handleClickOpenAlert = () => {
    debugger;
    setDocumentAlertOpen(false);
    if (fileUrl) {
        URL.revokeObjectURL(fileUrl); // Clean up blob URL
        setFileUrl(null);
    }
  };
  const handleCloseDocumentAlert = () => {
    setDocumentAlertOpen(false);
    if (fileUrl) {
        URL.revokeObjectURL(fileUrl); // Clean up blob URL
        setFileUrl(null);
    }
};
  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    (async () => {
      await userManagementServive.getUserById(id).then((response: any) => {
        if (response.data.isError) {
        } else {
          setData(response?.data);

          let data = response?.data;
          setfullName(
            data?.employeeInfo.firstName +
            " " +
            data?.employeeInfo.middleName +
            " " +
            data?.employeeInfo.lastName
          );
          if (response?.data?.personalInfo?.profileImagePath) {
            setProfileImage(apiUrl.getImageURL() +
              "/" + response?.data?.personalInfo.profileImagePath || "?");
          }
          
          // else if(response?.data?.gender == "female" && ){
          //     setProfileImage( <icons.Male /> )
          // }
          // else(response?.data?.gender == "male" ){
          //     setProfileImage( <icons.Male /> )
          // }
          if (response?.data?.employeeInfo?.dateOfJoining != null) {
            setTimeout(() => {
              calculateDate(response?.data?.employeeInfo?.dateOfJoining);
            }, 200);
          }
        }
      });
    })();
  }, [id]);

  const calculateDate = async (newValue: any) => {
    let date1 = dayjs(new Date());
    let date2 = dayjs(newValue);
    let years = date1.diff(date2, "year");
    let months = date1.diff(date2, "month");
    let days = date1.diff(date2, "day");

    //calculate total days.
    let getDateOfNewValue = date2.date();
    let newDate = date1.date(getDateOfNewValue);
    if (newDate >= date1) {
      newDate = newDate.subtract(1, "month");
    }
    let newDays = date1.diff(newDate, "day");
    let message = "";
    if (years > 0) {
      message += years + "y ";
      message += months - years * 12 + "m ";
    } else if (months > 0) {
      message += months + "m ";
    }

    if (newDays > 0) {
      message += newDays + "d ";
    } else {
      message += "0d";
    }
    setCurrentTeaure(message);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (fileUrl) {
      setDocumentAlertOpen(true);
    }
  }, [fileUrl]);
    const getUnEncrypedFiles = async (fileName: any) => {
      try {
        await projectManagementServive
          .getUnEncrypetDocuments(fileName)
          .then(async (response: any) => {
            if (response.data.type === "application/json") {
              const data = await response.data.text();
              toast.warning(JSON.parse(data).listErrors[0]);
            }
            else {
              const fileUrl = URL.createObjectURL(response.data);
              setFileUrl(fileUrl);
             // handleClickDocumentOpenAlert();
              // var a = document.createElement("a");
              // document.body.appendChild(a);
              // a.style.display = "none";
              // a.href = URL.createObjectURL(response.data);
              // a.download = fileName;
              // a.click();
              // window.URL.revokeObjectURL(a.href);
            }
          });
      } catch (ex: any) {
        (ex.data?.Error || ex.Error).map((err: any, idx: any) => {
          toast.warning(err);
        });
      };
  
  
    }

  const getUnEncryptedImage = async (fileName: any) => {
    try {
      const response = await projectManagementServive.getUnEncrypetDocuments(fileName);
      if (response.data.isError) {
        toast.warning("Error while fetching data");
      } else {
        const imageUrl = URL.createObjectURL(response.data);
        setProfileImage(imageUrl);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  
   

  return (
    <>
      <span></span>
      <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
          >
            User Profile
          </Typography>

          <Stack direction="row" spacing={2}>
            {/* {UserpermissioService.action_ManageUsersListing_Edit_Access && ( */}
            {(userIdd == uId &&_permission.action_ManageUsersListing_ViewKra_Access) && (
                <Button
                  variant="contained"
                  component={Link}
                  to={`/krarating/${uId}`}
                  color="primary"
                >
                 Rate KRA
                </Button>)}
          
            {_permission.action_ManageUsersListing_Edit_Access &&
              userIdd != uId && (
                <Button
                  variant="contained"
                  component={Link}
                  to={`/users/edit-user/${uId}`}
                  color="primary"
                  startIcon={<EditIcon />}
                //   onClick={() => {
                //     setVisibleButton(false);
                //   }}
                >
                  Edit
                </Button>
              )}
            {userIdd == uId && (
              <Button
                variant="contained"
                component={Link}
                to={`/users/edit-user/${uId}`}
                color="primary"
                startIcon={<EditIcon />}
              //   onClick={() => {
              //     setVisibleButton(false);
              //   }}
              >
                Edit
              </Button>
            )}
          </Stack>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
            >
              Personal Info
            </Typography>
            <Divider />
            <Stack
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 3, mb: 3 }}
            >
              <Box sx={{ position: "relative" }}>
                {/* <IconButton size='large' color='primary' sx={{
                                    "&.MuiIconButton-root": {
                                        backgroundColor: theme.palette.primary.main,
                                        position: 'absolute',
                                        zIndex: 1,
                                        bottom: 0,
                                        right: 0,
                                        "&:hover": {
                                            backgroundColor: theme.palette.primary.dark,
                                        }
                                    }
                                }}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}>
                                <Icons.EditProfileImage />
                                </IconButton> */}
                {/* <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>Change Image</MenuItem>
                                    <MenuItem onClick={handleClose}>Remove</MenuItem>
                                     
                                </Menu> */}
                {profileImage ? (
                  <Avatar
                    alt="Remy Sharp"
                    src={profileImage}
                    sx={{ width: 150, height: 150 }}
                  />
                ) : data?.personalInfo?.gender === "female" ? (
                  <Avatar
                    sx={{
                      width: 150,
                      height: 150,
                      "& svg": {
                        width: "48px",
                        height: "48px",
                      },
                    }}
                  >
                    <Icons.Male />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{
                      width: 150,
                      height: 150,
                      "& svg": {
                        width: "48px",
                        height: "48px",
                      },
                    }}
                  >
                    <Icons.FeMale />
                  </Avatar>
                )}
              </Box>
            </Stack>
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: 700, color: theme.palette.primary.main }}
            >
              Hello
            </Typography>
            <Typography
              component="p"
              sx={{ fontWeight: 700, color: theme.palette.primary.dark, mb: 3 }}
            >
              {data?.employeeInfo?.firstName} {data?.employeeInfo?.middleName}{" "}
              {data?.employeeInfo?.lastName}
            </Typography>
            
            <CustomCardHeader
              avatar={<Icons.DOB />}
              title="Date of birth"
              subheader={data?.personalInfo?.dateOfBirth
                ?.toString()
                .slice(0, 10)}
            />
            <Divider />
            <CustomCardHeader
              avatar={<Icons.gender />}
              title="Gender"
              subheader={data?.personalInfo?.gender}
            />
            <Divider />
            <CustomCardHeader
              avatar={<Icons.blood />}
              title="Blood Group"
              subheader={data?.personalInfo?.bloodGroup}
            />
            <Divider />
            <CustomCardHeader
              avatar={<Icons.marital />}
              title="Marital status"
              subheader={data?.personalInfo?.maritalStatus}
            />
            <Divider />
            <CustomCardHeader
              avatar={<Icons.email />}
              title="Personal email"
              subheader={data?.personalInfo?.personalEmailId}
            />
            <Divider />
            <CustomCardHeader
              avatar={<Icons.phone />}
              title="Phone Number"
              subheader={data?.personalInfo?.phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
            >
              Employee Info
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <CustomCardHeader
                  title="Employee ID"
                  subheader={data?.employeeInfo?.employeeId}
                />
                <CustomCardHeader title="Name" subheader={fullName} />
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomCardHeader
                      title="Department"
                      subheader={data?.department}
                    />
                    <CustomCardHeader
                      title="Manager"
                      subheader={data?.manager}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomCardHeader
                      title="Designation"
                      subheader={data?.designation}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                                        <CustomCardHeader
                                            title="Skill Set"
                                        // subheader={data?.employeeInfo?.skillId}                 
                                        />
                                    </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomCardHeader
                  title="Username"
                  subheader={data?.employeeInfo?.userName}
                />
                <CustomCardHeader
                  title="Email ID"
                  subheader={data?.employeeInfo?.emailId}
                />
                {
                  <CustomCardHeader
                    title="Date of joining"
                    subheader={data?.employeeInfo?.dateOfJoining
                      ?.toString()
                      ?.slice(0, 10)}
                  />
                }

                {/* {(data?.employeeInfo?.dateOfJoining === undefined && */}
                {/* <CustomCardHeader
                                    title="Date of joining"
                                    //subheader={data?.employeeInfo?.dateOfJoining.toString().slice(0, 10)}
                                  subheader=  {`${moment(data?.employeeInfo?.dateOfJoining).format(
                                        "DD MMM, YYYY"
                                    )}`}
                                /> */}
                {/* )} */}
                {/* <CustomCardHeader
                                    title="Date of joining"
                                    
                                /> */}

                <CustomCardHeader
                  title="Location"
                  subheader={data?.employeeInfo?.location}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomCardHeader
                  title="Slack/Teams id"
                  subheader={
                    data?.employeeInfo?.slackId +
                    "/" +
                    data?.employeeInfo?.teamId
                  }
                />
                <CustomCardHeader
                  title="Business Number"
                  subheader={data?.employeeInfo?.businessNumber}
                />
                {/* <CustomCardHeader
                                    title="Current Tenure"
                                    subheader={data?.employeeInfo?.currentTeaure}
                                /> */}
                <Grid item xs={12} sm={4}>
                  <CustomCardHeader title="Attendance" />
                  {data?.employeeInfo?.isTrackerRequired == true && (
                    <Chip variant="approved" label="Tracker" />
                  )}
                  {data?.employeeInfo?.isTrackerRequired == false && (
                    <Chip variant="progress" label="Portal" />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 3 }}
                >
                  <Typography
                    justifyContent="space-between"
                    variant="h6"
                    component="h6"
                    sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                  >
                    Skill Set
                  </Typography>
                  {/* <IconButton aria-label="edit" size="medium" component={Link} to={`/users/edit-user/${uId}`} onClick={() => props.setValue(2)}>
                                        <Icons.editblue />
                                    </IconButton> */}
                </Stack>
                <Divider sx={{ mb: 2 }} />
                {data?.skillsets &&
                  data?.skillsets.map((skill) => {
                    return (
                      <Grid container spacing={4}>
                        <Grid item sm={6}>
                          <CustomCardHeader
                            title="Skill Set"
                            subheader={skill?.name}
                          >
                            {" "}
                          </CustomCardHeader>
                        </Grid>
                        <Grid item xs={6}>
                          <CustomCardHeader
                            title="Skill Level"
                            subheader={skill?.level}
                          //  subheader={skillId?.skillSets?.name}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <Typography
                    justifyContent="space-between"
                    variant="h6"
                    component="h6"
                    sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                  >
                    Documents
                  </Typography>
                  {/* <IconButton aria-label="delete" component={Link} to={`/users/edit-user/${uId}`}>
                                        <Icons.editblue />
                                    </IconButton> */}
                </Stack>
                <Divider sx={{ mb: 2 }} />
                {data?.documents &&
                  data.documents
                    ?.filter((a) => a.fileName != "")
                    ?.map((document) => {
                      return (
                        <Grid container spacing={4}>
                          <Grid item sm={6}>
                            <CustomCardHeader
                              title="Document Type"
                              subheader={document?.documentType}
                            >
                              {" "}
                            </CustomCardHeader>
                          </Grid>
                          <Grid item xs={6}>
                            <CustomCardHeader
                              title="File"
                              subheader={
                                document.fileName ? (
                                  // <a target="_blank" href={apiUrl.getImageURL() + "/" + document.fileName}>{document.fileName}</a>
                                  <a onClick={() => { getUnEncrypedFiles(document?.fileName) }} style={{ cursor: 'pointer' }}>{document?.fileName}</a>
                                ) : (
                                  "N/A"
                                )
                              }
                            >
                              {/* {document.fileName && <a target="_blank" href={connonkeys.files+document.fileName}></a>}
                                                        {!document.fileName && "N/A"} */}
                            </CustomCardHeader>
                          </Grid>
                        </Grid>
                      );
                    })}
              </Grid>

              <Grid item xs={12} sm={6}>
              {!data?.pastExperince &&(
                <><Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mt: 3 }}
                  >
                    <Typography
                      justifyContent="space-between"
                      variant="h6"
                      component="h6"
                      sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                    >
                      Past Work Experience
                    </Typography>
                    {/* <IconButton aria-label="delete" component={Link} to={`/users/edit-user/${uId}`}>
                          <Icons.editblue />
                      </IconButton> */}
                  </Stack><Divider sx={{ mb: 2 }} /></>)}

                {data?.pastExperince &&
                  data.pastExperince?.map((pastExperince) => {
                    return (
                      <>
                        <CustomCardHeader
                          title="Organization name"
                          subheader={pastExperince.organizationName}
                        />
                        <Grid container spacing={4}>
                          <Grid item sm={6}>
                            <CustomCardHeader
                              title="Worked from"
                              subheader={pastExperince.workedFrom
                                .toString()
                                .slice(0, 10)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomCardHeader
                              title="Work To"
                              subheader={pastExperince.workedTo
                                .toString()
                                .slice(0, 10)}
                            />
                          </Grid>
                        </Grid>
                        <CustomCardHeader
                          title="Work Type"
                          subheader={pastExperince.workType}
                        />
                      </>
                    );
                  })}

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: 3 }}
                >
                  <Typography
                    justifyContent="space-between"
                    variant="h6"
                    component="h6"
                    sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                  >
                    Achievements and Certifications
                  </Typography>
                  {/* <IconButton aria-label="delete" component={Link} to={`/users/edit-user/${uId}`}>
                                        <Icons.editblue />
                                    </IconButton> */}
                </Stack>
                <Divider sx={{ mb: 2 }} />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ pb: 4 }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      sx={{ width: "max-content", textAlign: "left", mr: 4 }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={{
                          fontWeight: 300,
                          color: theme.palette.grey[800],
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Badges
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{ flexWrap: "wrap", width: "200px" }}
                      >
                        {data?.badges &&
                          data.badges?.map((badge) => {
                            return (
                              <img
                                src={
                                  apiUrl.getImageURL() +
                                  "/" +
                                  badge.badgeImageUrl
                                }
                                alt={badge.badgeName}
                                style={{
                                  width: "50px",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              />
                            );
                          })}
                      </Stack>

                      {/* <IconButton aria-label="delete" sx={{   p: 1 }}>
                                            <Icons.Delete />
                                        </IconButton> */}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      sx={{ width: "max-content", textAlign: "left", mr: 4 }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={{
                          fontWeight: 300,
                          color: theme.palette.grey[800],
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Achievers
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{ flexWrap: "wrap", width: "200px" }}
                      >
                        {data?.achievements &&
                          data.achievements?.map((achievement) => {
                            return (
                              <img
                                src={
                                  apiUrl.getImageURL() +
                                  "/" +
                                  achievement.achievementImageUrl
                                }
                                alt={achievement.achievementName}
                                style={{
                                  width: "50px",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              />
                            );
                          })}
                      </Stack>
                      {/* <IconButton aria-label="delete" sx={{ p: 1 }}>
                                            <Icons.Delete />
                                        </IconButton> */}
                    </Grid>
                    <Grid
                      xs={5}
                      sm={5}
                      sx={{ width: "max-content", textAlign: "left", mr: 4 }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={{
                          fontWeight: 300,
                          color: theme.palette.grey[800],
                          mb: 1,
                          fontSize: "0.875rem",
                        }}
                      >
                        Others
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{ flexWrap: "wrap", width: "200px" }}
                      >
                        {data?.others &&
                          data.others?.map((other) => {
                            return (
                              <img
                                src={
                                  apiUrl.getImageURL() +
                                  "/" +
                                  other.otherImageUrl
                                }
                                alt={other.otherName}
                                style={{
                                  width: "50px",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              />
                            );
                          })}
                      </Stack>
                      {/* <IconButton aria-label="delete" sx={{ p: 1 }}>
                                            <Icons.Delete />
                                        </IconButton> */}
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      
      <Dialog
        open={openDocumentAlert}
        onClose={setDocumentAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       // maxWidth="sm"
       fullScreen
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleClickOpenAlert}
        >
          <ClearIcon />
        </IconButton>
        {fileUrl ? (
      <iframe
        src={fileUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    ) : (
      <div style={{ padding: "20px", textAlign: "center" }}>
        No document to preview
      </div>
    )}
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
        
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseDocumentAlert}>
            Go Back
          </Button>
          <IconButton
          aria-label="delete"
          //onClick={downlodFiles}
          sx={{
            marginLeft: 'auto',
              color: '#ff5733'
           
          }}
        >
          <Icons.DownloadIcon/>
        </IconButton>
          
        </DialogActions>
      </Dialog>
    </>
  );
}

function employeeDetail(employeeDetail: any): void {
  throw new Error("Function not implemented.");
}
