import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EmployeeInfo from "./EmployeeInfo";
import PersonalInfo from "./PersonalInfo";
import SkillSet from "./SkillSet";
import PostworkExp from "./PostworkExp";
import AchievementsandCertifications from "./AchievementsandCertifications";
import Documents from "./Documents";
import { userManagementServive } from "../../service/user-management-service";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  userModelDetail,
  userModel,
  insuranceCard,
  employeeInfo,
  personalInfo,
  documents,
  pastExperince,
  badges,
  achivements,
  skillsets,
  othersDocument,
} from "./userModel";
import { useSelector } from "react-redux";
import Notes from '../../components/styledComponent/Note';
import InsuranceCard from "./InsuranceCard";
import InfoPopover from "../../components/CommonInfoComponent";
import InventoryMyAssets from "../Inventory/InventoryMyAssets";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddUser() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.UserPermissionApp.UserPermission);
  const [value, setValue] = React.useState(0);
  const [userId, setUserId] = React.useState("");
  const { id } = useParams();
  let state = { userid: id ?? "" }; //(id!="" && id!='0'?id?.toString():"")};//632da2f9fa37260b70bf4e1f
  const [previousvalue, setPreviousvalue] = React.useState(state.userid);

  const [userIdHook, setUserIdHook] = React.useState(previousvalue);
  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    previousvalue,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],[_othersDocument],
    [_skillsets]
  );
 // const [showTab, setShowTab] = React.useState(true);
  const [userDataHook, setUserDataHook] = React.useState(usermodel);

  const handleUserId = () => {
    state.userid = previousvalue;
    setUserId(state.userid);
  };
  let pageTitle = "Add User";
  if (state.userid.length > 0) {
    pageTitle = "Update user detail";
  }
  useEffect(() => {
    if ((!id && _permission.action_ManageUsersListing_Add_Access !== true)) {
    navigation('/dashboard');
  }
    if(previousvalue){
      state.userid=previousvalue;
    }
    const getUserDetail = async () => {
      await getUserDetails();
    };
    getUserDetail();
    
  }, []);
const udpateStateUserId=async()=>{
  if(previousvalue){ 
    state.userid=previousvalue;
    getUserDetails();
  }
}

  const getUserDetails = async () => {
    try {
      if (state.userid.length > 0) {
        await userManagementServive
          .getUserById(state.userid)
          .then((response: any) => {
            if (response.data.isError) {
            } else {
              setUserDataHook(response.data);
              pageTitle = "Update user detail";
            }
          });
      }
    } catch {
      setValue(0);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {  
    if(previousvalue.length > 0){
      setValue(newValue);
    } 
  };

  // const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());
  // const onChange = (event: React.SyntheticEvent, newValue: null) => {
  //   setDateValue(newValue);
  // };

  return (
    <>
      <Paper elevation={0} sx={{ p: 3, pb: 2 }}>
          <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
            <InfoPopover  onInput={"Add user"}/>
          </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="start"
          alignItems="center"
          sx={{mt:1.5}}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600, width:'30%' }}>
            {pageTitle}
          </Typography>
          <Stack width={'40%'} direction={'row'} justifyContent={'space-between'}>
          <Typography  component="h2" sx={{ fontWeight: 500 }} >
            {userDataHook?.employeeInfo?.employeeId ? `Emp Id: ${userDataHook?.employeeInfo?.employeeId}` : ""}
            </Typography>
          <Typography  component="h2" sx={{ fontWeight: 500 }}>
          {userDataHook?.employeeInfo?.firstName ? `Emp Name: ${userDataHook?.employeeInfo?.firstName} ${userDataHook?.employeeInfo?.middleName} ${userDataHook?.employeeInfo?.lastName}` : ""}
          </Typography>
          </Stack>
        
        </Stack>
      </Paper>
      <Box sx={{ width: "100%", p: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Paper elevation={0}>
          {userDataHook && userDataHook?.employeeInfo?.isUserFresher == false
                &&
                <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="Employee Info" {...a11yProps(0)} />
                <Tab label="Skill Sets" {...a11yProps(1)} />
                <Tab label="Personal Info" {...a11yProps(2)} />
                <Tab label="Past Work Experience" {...a11yProps(3)} />
                <Tab label="Documents" {...a11yProps(4)} />
                <Tab label="Achievements and Badges" {...a11yProps(5)} />
                <Tab label="Insurance Card" {...a11yProps(6)} />
                {state.userid&&(
                <Tab label="My Assets" {...a11yProps(7)} />)}
              </Tabs>
                }

            {userDataHook && userDataHook?.employeeInfo?.isUserFresher == true
              &&
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="Employee Info" {...a11yProps(0)} />
                <Tab label="Skill Sets" {...a11yProps(1)} />
                <Tab label="Personal Info" {...a11yProps(2)} />
                <Tab label="Documents" {...a11yProps(3)} />
                <Tab label="Achievements and Badges" {...a11yProps(4)} />
                <Tab label="Insurance Card" {...a11yProps(5)} />
                {state.userid &&(
                <Tab label="My Assets" {...a11yProps(6)} />)}
              </Tabs>
            }
          </Paper>
        </Box>
        <TabPanel value={value} index={0}>
          <EmployeeInfo
            obj={previousvalue}
            setUId={setPreviousvalue}
            setValue={setValue}
            callBackFunction={udpateStateUserId}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SkillSet obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PersonalInfo obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId} />
        </TabPanel>
        {userDataHook && userDataHook?.employeeInfo?.isUserFresher == false &&
          <>
            <TabPanel value={value} index={3}>
              <PostworkExp obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Documents obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId}/>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AchievementsandCertifications obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId}/>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <InsuranceCard  obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId}/>
            </TabPanel>
            {state.userid&&(
            <TabPanel value={value} index={7}>
              <InventoryMyAssets userId={state.userid} userInfo={userDataHook}/>
            </TabPanel>)}
          </> 
        }
        {userDataHook && userDataHook.employeeInfo.isUserFresher == true &&
          <>
            <TabPanel value={value} index={3}>
              <Documents obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AchievementsandCertifications obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <InsuranceCard obj={previousvalue} setValue={setValue} callBackFunction={udpateStateUserId}/>
            </TabPanel>
            {state.userid&&(
            <TabPanel value={value} index={6}>
            <InventoryMyAssets userId={state.userid} userInfo={userDataHook}/>
            </TabPanel>)}
          </>
        }

      </Box>
    </>
  );
}
