import React from 'react';
import theme from '../../theme/theme';
import { Link } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Chip, { ChipProps } from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import StyledTableCell from '../../components/styledComponent/CustomTableCell'
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';




export default function UserTemplate() {
    return (
        <>
            <Paper elevation={0} sx={{ p: 3, pb: 0, }}>

                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
                    <Typography variant="h5" component="h2" sx={{ fontWeight: 600, color: theme.palette.primary.dark }}>
                        Instruction
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </Stack>

                <Typography variant="body1" gutterBottom sx={{ fontWeight: 400 }}>
                    1. Download the predefined Template.
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontWeight: 400 }}>
                    2. In the template provide details in the given column
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontWeight: 400 }}>
                    3. Upload the template past data entry.
                </Typography>



                <Typography variant="h5" component="h2" sx={{ fontWeight: 600, color: theme.palette.primary.dark, mt: 4 }}>
                    Note
                </Typography>


                <Stack direction="row" spacing={3} alignItems="center" sx={{ pb: 2, mt: 2 }}>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 500, color: theme.palette.primary.dark, }}>
                            Department
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 300 }}>
                            .Net, PHP, Design, BA, Digital Marketing, ML
                        </Typography>
                    </Paper>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 500, color: theme.palette.primary.dark, }}>
                            Roles
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 300 }}>
                            Manager, Team Lead, Head, Supervisor
                        </Typography>
                    </Paper>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 500, color: theme.palette.primary.dark, }}>
                            Location
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 300 }}>
                            Seasia 01, Seasia 02
                        </Typography>
                    </Paper>
                </Stack>
                <Stack direction="row" spacing={3} alignItems="center" sx={{ pb: 2, mt: 2 }}>
                    <Button variant="outlined">Download Template</Button>
                    <Button variant="contained">Upload Template</Button>
                </Stack>

            </Paper>

        </>
    );
}