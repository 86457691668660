import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {TablePagination,Typography} from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import SearchBar from "../../components/styledComponent/SearchBar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { eventManagementService } from "../../service/event-management-service";
import ClearIcon from "@mui/icons-material/Clear";
import NotFound from "../../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import moment from "moment";

const styles: any = {
  EventInfoCard: {
    border: "1px solid #EEEEEE",
    padding: "18px 14px",
    bordeRadius: "5px",
    cursor: "pointer",
    height:"100%",
    "&:hover": {
      boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
    },
  },
  innerSpace: {
    // padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },

  EventName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.primary.dark,
  },
  EventId: {
    letterSpacing: "0.1px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    color: "#232360",
  },
  EventDesc: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginTop: "8px",
  },
  EventDate: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginLeft: "10px",
  },
  LineClamp: {
    marginTop: "15px",
    whiteSpace: "nowrap",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  DialogTitle: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: " 39px",
    color: theme.palette.primary.dark,
  },
  Schedulefor: {
    fontWeight: 400,
    fontSize: "16.2314px",
    lineHeight: "19px",
    color: " #212121",
    margin: 0,
  },
  InnerHtml: {
    marginBottom: "12px",
    "& p": {
      margin: 0,
      textAlign: "center",
      fontSize: "24px",
      color: theme.palette.primary.dark,
    },
  },
  TitleLabel: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#232360",
  },
  TitleValue: {
    fontWeight: 600,
    fontSize: " 18px",
    lineHeight: "21px",
    color: " #424242",
    marginLeft: "8px",
  },
  Active: {
    fontWeight: 400,
    color: theme.palette.success.dark,
    m: 0,
  },

  Schedule: {
    fontWeight: 400,
    color: "#5F6388",
    m: 0,
  },

  Expired: {
    fontWeight: 400,
    color: theme.palette.error.dark,
    m: 0,
  },
};

const styleCross = {
  position: "absolute" as "absolute",
  right: "12px",
  top: "16px",
};

const Active = {
  fontWeight: 400,
  color: theme.palette.success.dark,
  m: 0,
};

const Scheduled = {
  fontWeight: 400,
  color: "#5F6388",
  m: 0,
};

const Expired = {
  fontWeight: 400,
  color: "#BDBDBD",
  m: 0,
};

interface EventView {
  title: string;
  description: string;
  visibility: string;
  notice: boolean;
  status: string;
  startDate: Date;
  endDate: Date;
  id: string;
}


export default function Notice(modalOpen: any) {
  const _permission = useSelector(
    (state: any) => state.NotificationPermissionApp.NotificationPermission
  );
  const [events, setEvents] = useState<any>([]);
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [eventOpen, seteventOpen] = React.useState(false);
  ////////Pagination Programming
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const pageCount = Math.ceil(events.length / rowsPerPage);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  ////////over
 
  function removeTagsAndAmp(str: any) {
    // Remove HTML tags
    str = str.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str.replace(/&amp;/g, '&');
    str = str.replace(/(&nbsp;)/g, '');
    return str;
  }

 
  let statusValue = "All";
  useEffect(() => {
  
    seteventOpen(modalOpen);
    getEventDetails(statusValue);
  }, []);

  const getEventDetails = async (status: any, searchValue: string = "") => {
    var input = {
      status: status == "All" ? "0" : status,
      search: searchValue,
    };
    await eventManagementService.getEvents(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setEvents(response?.data);
      }
    });
  };

  //Searching

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const triggerSearch = async (newValue: string) => {
    getEventDetails(statusValue, newValue);
    setPage(0);
  };
  
  //View
  const [openView, setOpenView] = React.useState(false);
  const [eventView, setEventView] = React.useState<EventView | null>(null);
  const handleClickOpenView = async (id: any) => {
    await eventManagementService.getEventViewId(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setEventView(response?.data);
        setOpenView(true);
      }
    });
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Notification</Typography>
        <Typography color="text.primary">Event </Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>
      <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            All Events
          </Typography> <SearchBar
              setSearchValue={setSearchValue}
              callBackFunction={triggerSearch}
            />
          </Stack>
        {events && events.length > 0 ? (
          <Grid container spacing={2}>
            {events
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event: any) => (
                <Grid item xs={12} lg={3}>
                  <Paper
                    elevation={0}
                    sx={[styles.EventInfoCard]}
                    onClick={() => handleClickOpenView(event.id)}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        component="p"
                        sx={[styles.EventId]}
                      ></Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          justifyContent="space-between"
                          component="p"
                          sx={[styles[`${event.status}`], styles.innerSpace]}
                        >
                          <Icons.dots />
                          &nbsp;{event.status}
                        </Typography>
                      </Box>
                    </Stack>

                    <Box sx={{ mt: 2, mb:2 }}>
                      <Typography
                        variant="body1"
                        component="h4"
                        sx={[styles.EventName]}
                      >
                        {event?.title?.length > 30
                          ? event?.title.slice(0, 30 - 1) + "..."
                          : event?.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        sx={[styles.EventDesc]}
                      >
                        {removeTagsAndAmp(event?.description)?.length > 30
                          ? removeTagsAndAmp(event?.description).slice(0, 30 - 1) + "..."
                          : removeTagsAndAmp(event?.description)}
                      </Typography>
                    </Box>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography component="p" sx={[styles.EventId]}>
                          Created
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={[styles.EventDate]}
                        >
                          {/* {event.createdDate} */}
                          {`${moment(event?.createdDate).format(
                            "DD MMM, YYYY"
                          )}`}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        ) : (
          <NotFound NotfoundText="No Result Found" />
        )}
        <TablePagination
          labelRowsPerPage="Cards per page"
          rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
          component="div"
          count={events.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
         
          labelDisplayedRows={() => `Total: ${events?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          View Event
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleCloseView}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Title:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {eventView?.title}
              </Typography>
            </Stack>
            <Typography
              justifyContent="space-between"
              component="p"
              sx={[styles[`${eventView?.status}`], styles.innerSpace]}
            >
              <Icons.dots />
              &nbsp;
              {eventView?.status}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Stack direction="row">
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleLabel]}
              >
                Scheduled For:
              </Typography>
              <Typography
                variant="body1"
                component="h4"
                sx={[styles.TitleValue]}
              >
                {`${moment(eventView?.startDate).format("DD MMM, YYYY")}`} to
                {`${moment(eventView?.endDate).format("DD MMM, YYYY")}`}
              </Typography>
            </Stack>
          </Stack>
          {eventView?.description !="" && eventView?.description !=null && (
          <Paper variant="outlined" sx={{ p: 2, mt: 3, textAlign: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                "& img": {
                  maxWidth: "100%",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: eventView?.description as string,
              }}
            ></Typography>
          </Paper>)}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleCloseView}
          >
            Back to List
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
