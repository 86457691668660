import { combineReducers } from 'redux';
const Fill_Leave_Permission = 'Fill_Leave_Permission';
const Empty_Leave_Permission = 'Empty_Leave_Permission';

const defaultPermissions = 
  {
    access: false,
    module_LeaveManagement_Access:false,

  page_ManageSetupLeaves_Access:false,
  tab_SetupLeavesTab_Access:false,
  action_ManageSetupLeaves_Add_Access:false,
  action_ManageSetupLeaves_Edit_Access:false,
  action_ManageSetupLeaves_Delete_Access:false,

  page_Leave_Management_Access:false,
  page_Attendance_Request_Access:false,
  page_Holidays_Calendar_Access:false,
  page_Leave_Request_Access:false,
  page_Emergency_Leave_Request_Access:false,
  page_Leave_Card_Access:false,
  tab_Leave_AcceptReject_Access:false,

  }

export function FillLeavePermission(module) {
  return {
    type: Fill_Leave_Permission,
    module,
  }
}
export function EmptyLeavePermission() {
  return {
    type: Empty_Leave_Permission,defaultPermissions
  }
}

function LeavePermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Leave_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
              permissions.module_LeaveManagement_Access = true;
              if (data.listPageMaster && data.listPageMaster.length > 0) {
                data.listPageMaster.forEach((page) => {
                  if (page.pageName === "Setup Leaves") {
                    permissions.page_ManageSetupLeaves_Access = true;
                    if (page.listTabMaster && page.listTabMaster.length > 0) {
                      page.listTabMaster.forEach((tabs) => {
                        if (tabs.tabName === "Setup Leaves Tab") {
                          permissions.tab_SetupLeavesTab_Access = true;
                          if (
                            tabs.listActionMaster &&
                            tabs.listActionMaster.length > 0
                          ) {
                            tabs.listActionMaster.forEach((actions) => {
                              if (actions.actionName === "Add") {
                                permissions.action_ManageSetupLeaves_Add_Access = true;
                              }
                              if (actions.actionName === "Edit") {
                                permissions.action_ManageSetupLeaves_Edit_Access = true;
                              }
                              if (actions.actionName === "Delete") {
                                permissions.action_ManageSetupLeaves_Delete_Access = true;
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                  if (page.pageName === "Leave Management") {
                    permissions.page_Leave_Management_Access = true;
                    page.listTabMaster.forEach((tabs) => {
                      if (tabs.tabName === "Leaves Request") {
                        permissions.page_Leave_Request_Access = true;
                      }
                      if (tabs.tabName === "Emergency Leave") {
                        permissions.page_Emergency_Leave_Request_Access = true;
                      }
                      if (tabs.tabName === "Team Leave Card") {
                        permissions.page_Leave_Card_Access = true;
                      }
                    });
                  }
                  if (page.pageName === "Holidays Calendar") {
                    permissions.page_Holidays_Calendar_Access = true;
                  }
                  if (page.pageName === "Attendance Request") {
                    permissions.page_Attendance_Request_Access = true;
                    page.listTabMaster.forEach((tabs) => {
                      if (tabs.tabName === "Approve/Reject") {
                        permissions.tab_Leave_AcceptReject_Access = true;
                      }})
                  }
                });
              }
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_Leave_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const LeavePermissionApp = combineReducers({
    LeavePermission
});

export default LeavePermissionApp;