import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Radio, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import * as Icons from "../../assests/icons/icons";
import sesialogo from '../../assests/images/seasia-logo.png';
import ClearIcon from "@mui/icons-material/Clear";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Department } from '../../Interfaces/IDepartment';
import { clsResourcesList } from '../../service/common-services/resources';
import { clsDepartmentList } from '../../service/common-services/department';
import { allowanceService } from '../../service/master-service/allowance-service';
import { toast } from 'material-react-toastify';
import { Monts1 } from '../../Enum/eMonths';
export default function PayRole() {
    const [selectedValue, setSelectedValue] = React.useState('a');
    useEffect(() => {
        //generateSalarySlip(selectedResourcesId, grossSalarySlip);
        getDepartmentDat();
    }, [])
    const [selectedDepartmentId, setselectedDepartmentId] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const getDepartmentDat = async () => {
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
    }

    const grossSalaryRef = useRef();
    var resourceData = { userId: "", userName: "Select Resource" };
    interface Resource {
        userId: string;
        userName: string;
    }
    const [selectedResourcesId, setSelectedResourcesId] = React.useState("");
    const [grossSalarySlip, setGrossSalarySlip] = React.useState("");
    const [generatedGrossSalary, setGeneratedGrossSalary] = useState("");
    const [slipList, setSlipList] = React.useState<any>();
    const [resources, setresources] = React.useState<Resource[]>([resourceData]);
    const handleClose = () => {
        setOpen(false);
    };
    console.log(slipList, "slipList");
    const [isProfitMakingYes, setIsProfitMakingYes] = useState(false);
    const [isProfitMakingNo, setIsProfitMakingNo] = useState(true);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const currentDate = new Date();
    var monthName = currentDate.toLocaleString('default', { month: 'long' });
    const [rewardMonthHook, setRewardMonthHook] = React.useState(monthName);
    const hrRewardMonth = Monts1;
    const [page, setPage] = React.useState(0);
    const handleYesCheckboxChange = (event: any) => {
        setIsProfitMakingYes(event.target.checked);
        setIsProfitMakingNo(!event.target.checked);
    };
    const buttonStyle = {

        "margin-left": '85%',

    };
    const buttonStyle1 = {

        "margin-bottom": "10px"
    };

     
    const handleNoCheckboxChange = (event: any) => {
        setIsProfitMakingNo(event.target.checked);
        setIsProfitMakingYes(!event.target.checked);
    };
           
    
    //const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1)
    const [rewardMonthValueHook, setRewardMonthValueHook] = React.useState<any>(currentMonth);
    const generateSalarySlip = async (resourceId: any, grossSalary: any) => {
        if (!grossSalary) {
            toast.warning("Gross Salary is required");
            return;
        }
        if (!selectedResourcesId) {
            toast.warning("Resource is required");
            return;
        }

        var req = {
            resourceId: resourceId,
            grossSalary: grossSalary,
            isProfitMaking: isProfitMakingYes,
        };

        setGeneratedGrossSalary(grossSalarySlip);

        try {
            await allowanceService.generateSalarySlip(req).then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong while fetching data");
                }
                else {
                    setSlipList(response?.data);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    }

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Typography >
                        Salary Details
                    </Typography>

                </Breadcrumbs>
                <Paper sx={{ p: 3 }} >
                    <Stack spacing={0} direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
                        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px", color: '#232360' }} component="h2">
                          Salary Slip
                        </Typography>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                            <Box sx={{
                                "& .MuiAutocomplete-hasClearIcon": {
                                    background: '#F0F9FF',
                                    color: '#1DA7FF',

                                }
                            }}>
                                <Autocomplete
                                    value={
                                        departments?.find(
                                            (option) => option.id == selectedDepartmentId
                                        ) ?? null
                                    }
                                    fullWidth
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setselectedDepartmentId(value?.id ?? "");
                                        setresources(
                                            await clsResourcesList.getResourcesDropdownList(
                                                value?.id ?? ""
                                            )
                                        );
                                        setfiltersSelected(true);

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={selectedDepartmentId}
                                            fullWidth
                                            sx={{ width: 230 }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{
                                "& .MuiAutocomplete-hasClearIcon": {
                                    background: '#F0F9FF',
                                    color: '#1DA7FF',

                                }
                            }}>
                                <Autocomplete
                                    disabled={!selectedDepartmentId || selectedDepartmentId === ""}
                                    value={
                                        resources?.find(
                                            (option) => option.userId == selectedResourcesId
                                        ) ?? null
                                    }
                                    fullWidth
                                    id="outlined-basic"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={async (event, value) => {
                                        setSelectedResourcesId(value?.userId ?? "");
                                        setfiltersSelected(true);

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={selectedResourcesId}
                                            fullWidth
                                            sx={{ width: 200 }}
                                        />
                                    )}
                                />
                            </Box>
                            <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} >
                                <Typography variant="h6" sx={{ fontWeight: 400, marginBottom: "10px", fontSize: '16px', mb: 0, color: '#1DA7FF' }} component="h2">
                                    Profit Making
                                </Typography>
                                <Box sx={{ width: 'auto' }}>
                                    <Stack direction={'row'} flexWrap={'wrap'}>
                                        <Box>
                                            <Radio
                                                checked={isProfitMakingYes}
                                                onChange={handleYesCheckboxChange}
                                                name="isProfitMakingYes"
                                                id="isProfitMakingYes"

                                            />
                                            Yes
                                        </Box>
                                        <Box>
                                            <Radio
                                                checked={isProfitMakingNo}
                                                onChange={handleNoCheckboxChange}
                                                name="isProfitMakingNo"
                                                id="isProfitMakingNo"

                                            />
                                            No
                                        </Box>
                                    </Stack>
                                </Box>


                            </Stack>
                            <Autocomplete
                                value={
                                    hrRewardMonth?.find(
                                        (option: any) => option.label == rewardMonthHook
                                    ) ?? null
                                }
                                sx={{
                                    width: "180px",
                                    mr: 1,
                                    ml: "3px",
                                    lineHeight: "0.80em",
                                }}
                                fullWidth
                                id="outlined-basic"
                                options={hrRewardMonth}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => {
                                    setPage(0);
                                    // setfiltersSelected(true);
                                    //   setmonthsHook(value?.id ?? "");
                                    setRewardMonthHook(value?.label ?? monthName);
                                    setRewardMonthValueHook(value?.id ?? "");
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="outlined-basic"
                                        name="selectedResourceId"
                                        variant="outlined"
                                        value={rewardMonthHook}
                                        fullWidth
                                    />
                                )}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Gross Salary"
                                variant="outlined"
                                sx={{ width: 128 }}
                                value={grossSalarySlip}
                                onChange={(event) => setGrossSalarySlip(event.target.value)}
                            />


                            <Dialog
                                fullScreen={fullScreen}
                                open={open}
                                // onClose={handleClose}
                    
                                aria-labelledby="responsive-dialog-title"
                                sx={{
                                    p: 2, "& .css-23vkr6-MuiPaper-root-MuiDialog-paper": {
                                        maxWidth: { xs: '600px', xl: '945px' },
                                    }
                                }}
                                aria-describedby="alert-dialog-description"
                                maxWidth="sm"
                                fullWidth
                                scroll="body"

                            >
                                <DialogTitle id="alert-dialog-title">
                                    <IconButton
                                        aria-label="delete"
                                        sx={{
                                            position: "absolute" as "absolute",
                                            right: "0px",
                                            top: "0px"
                                        }}
                                        onClick={handleClose}
                                    >
                                        <ClearIcon  />
                                    </IconButton>
                                    
                                </DialogTitle>
                                <DialogContent id='qrcode'>
                                 
                                        <Box sx={{ p: { xs: "18px 18px ", xl: "18px 76px" }, }}>
                                            <div className='rana'>
                                            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 2 }}>
                                        <img src={sesialogo} alt='img' />
                                        <Box width={'40%'}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                                C-136, Industrial Area, Phase 8,
                                                Chandigarh, 160071
                                            </Typography>
                                            <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                                <Icons.Phoneicon />
                                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }} >+91-8307547266</Typography>
                                            </div>
                                        </Box>
                                    </Stack>
                                            <Box sx={{ background: '#F0F9FF', p: { xs: "18px 18px", xl: "18px 76px" }, }}>
                                                <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between', background: "#F0F9FF"}}  >
                                                    <Box>
                                                        <Typography style={{ fontSize: '16px', fontWeight: '600' }}>
                                                            Employee ID
                                                        </Typography>
                                                        <Typography style={{ color: '#1DA7FF', fontSize: '16px', fontWeight: '400' }}>
                                                            {slipList?.employeeId ? slipList?.employeeId : "--"}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography  style={{ fontSize: '16px', fontWeight: '600' }}>
                                                            Employee Name
                                                        </Typography>
                                                        <Typography style={{ color: '#1DA7FF', fontSize: '16px', fontWeight: '400' }}>
                                                            {slipList?.employeeName ? slipList?.employeeName : "--"}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography  style={{ fontSize: '16px', fontWeight: '600' }}>
                                                            Pay Period
                                                        </Typography>
                                                        <Typography style={{ color: '#1DA7FF', fontSize: '16px', fontWeight: '400' }}>
                                                            {rewardMonthHook}
                                                        </Typography>

                                                    </Box>
                                                </div>
                                            </Box>
                                            <Box sx={{ p: { xs: "18px 18px 0px 18px", xl: "18px 76px 0px 76px" }, borderRadius: '5px' }}>
                                             
                                                    <Table
                                                        sx={{ minWidth: 350, }}
                                                        style={{border: "1px solid #EEEEEE", padding: '10px'}}
                                                        size="small"
                                                        aria-label="a dense table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <td align="left" style={{padding: "6px 16px", color: "rgb(35, 35, 96)", fontWeight: "600", borderBottom: "1.5px solid #c1c1c150"}}>Allowance</td>
                                                                <td align="left" style={{padding: "6px 16px", color: "rgb(35, 35, 96)", fontWeight: "600", borderBottom: "1.5px solid #c1c1c150"}}>Amount</td>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {slipList?.allowances ? (
                                                                Object.keys(slipList.allowances).map((key, index) => (
                                                                    <TableRow  key={index}>
                                                                        <td style={{padding: "6px 16px", borderBottom: ".5px solid #c1c1c150"}}>{key}</td>
                                                                        <td style={{padding: "6px 16px", borderBottom: ".5px solid #c1c1c150"}}>{slipList.allowances[key]}</td>
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell colSpan={2}>--</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>

                                                    </Table>
                                              
                                            </Box>
                                            <Box
                                                sx={{
                                                    marginTop: '50px',
                                                    width: '30%',
                                                    borderBottom: '1px solid black'
                                                }}>

                                            </Box></div>
                                            
                                                <Box sx={{ pt: 3 }}>
                                                    <Typography
                                                        // variant="contained"
                                                        color="black"
                                                    >
                                                        Authorized
                                                    </Typography>
                                                    <div onClick={() => {
                                                           let w = window.open();
                                                           let elements = document.getElementsByClassName("rana");
                                                           if (elements.length > 0) {
                                                               let htmlContent = "";
                                                               for (let i = 0; i < elements.length; i++) {
                                                                   htmlContent += elements[i].innerHTML;
                                                               }
                                                               w?.document.write(htmlContent);
                                                               w?.print();
                                                               w?.close();
                                                           }
                                                    }} >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            //  onClick={handleClickOpen}
                                                            sx={buttonStyle}
                                                        >
                                                            {/* <img src={printicon} alt="print-icon" /> */}
                                                            <Typography
                                                                sx={{ marginLeft: 'auto', paddingLeft: '3px' }}>
                                                                Print
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </Box>
                                        </Box>
                                    
                                </DialogContent>
                            </Dialog>

                        </Stack>

                        <Stack direction={'row'} justifyContent={'end'} width={"100%"} spacing={2} sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                   
                                    generateSalarySlip(selectedResourcesId, grossSalarySlip);
                                    
                                }}
                                disabled={!selectedResourcesId && !selectedDepartmentId }
                            >
                                Generate Salary Slip
                            </Button>
{filtersSelected&&(
                            <Box>
                                <Button
                                    onClick={async () => {
                                        setfiltersSelected(false);
                                        setselectedDepartmentId("");
                                        setSelectedResourcesId("");
                                        setGrossSalarySlip("");
                                        setIsProfitMakingYes(false);
                                        setIsProfitMakingNo(true);
                                        setSlipList("");
                                    }
                                    }
                                    variant="contained"
                                    color="primary"
                                >
                                    Clear
                                </Button>
                            </Box>)}

                        </Stack>
                    </Stack>
                </Paper>
                {slipList && (
                    <Paper sx={{ px: 3, pt: 3, pb: '0px', mt: '18px' }}>
                        <Box sx={{ borderBottom: '1px solid #EEE' }}>
                            <Grid container sx={{ my: 2 }}>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                                            Employee ID
                                        </Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                            {slipList?.employeeId ? slipList?.employeeId : "--"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                                     Employee Name
                                        </Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                            {slipList?.employeeName ? slipList?.employeeName : "--"}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2} sx={{ my: 2 }}>
                            <Grid item xs={6}>
                                <TableContainer sx={{ border: '1px solid #EEEEEE', borderRadius: '5px' }}>
                                    <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Allowance</TableCell>
                                                <TableCell align="left">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {slipList?.allowances ? (
                                                Object.keys(slipList.allowances).map((key, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{key}</TableCell>
                                                        <TableCell>{slipList.allowances[key]}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={2}>--</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Grid container sx={{ my: 2 }}>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', padding: "0 16px" }}>
                                            Gross Salary
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '500', padding: "0 20px" }}>
                                            {generatedGrossSalary}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickOpen}
                                    sx={buttonStyle1}
                                >
                                    Print
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Box>
        </>
    );
}