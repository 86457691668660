import { http } from "./http-common";

class MilestoneManagementService {
  createMilestone = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Milestone/Add", req);
  };
  
  getMilestoneReport = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Reports/GetMilestoneReport/", req);
  };
  
  getSprintByMilestoneId = async (req: any): Promise<any> => {
    return await http.post<any>(`/v1/Reports/GetSprints?MilestoneId=${req?.MilestoneId}`);
  };

  updateMilestoneStatus = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Milestone/UpdateMilestoneStatus", req);
  };
  SelectSprint = async (req: any): Promise<any> => {
    return await http.post<any>("/Sprint/GetSprintsByMilestoneDate/Sprint/GetSprintsByMilestoneDate", req);
  };
  
  AddsprintsToMilestone = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Milestone/AddSprintToMilestone", req);
  };
 
  getMilestoneReportExceededDate = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Reports/GetMilestoneReport/ExceededDate", req);
  };
 

}
export const milestoneManagementService = new MilestoneManagementService();
