import { Class, NestCamWiredStandTwoTone } from "@mui/icons-material";
import { userManagementServive } from "../../service/user-management-service";
export class userModelDetail{
    _employeeInfo=new employeeInfo();
    _personalInfo=new personalInfo();
    _insuranceCard=new insuranceCard();
    _documents=Array<documents>;
    _pastExperince=Array<pastExperince>;
    _badges=Array<badges>;
    _achivements=Array<achivements>;
    _othersDocument=Array<othersDocument>;
    _skillsets = Array<skillsets>;
    usermodel=new userModel("", this._employeeInfo, this._personalInfo,this._insuranceCard, new this._documents, new this._pastExperince, new this._badges, new this._achivements,new this._othersDocument,new this._skillsets)
    constructor(id:string){
        this.getUserDetails(id);
    }

    getUserDetails = async (id:string): Promise<any> => {
        if (id && id != "") {
            await userManagementServive.getUserById(id)
                .then((response: any) => {
                    if (response.data.isError) {
                         
                    }
                    else {
                        this.usermodel= response.data;
                        this._employeeInfo=this.usermodel.employeeInfo;
                        this._personalInfo=this.usermodel.personalInfo;
                        this._insuranceCard=this.usermodel.insuranceCard;
                        //this._documents=this.usermodel.documents;
                        //setUsers(response?.data);
                    }
                });
        };
    }
}



export class userModel {
    public id: string = "";
    public employeeInfo: employeeInfo = {
        userName: "",
        employeeId: "",
        firstName: "",
        lastName: "",
        middleName: "",
        departmentId: "",
        managerId: "",
        hodId: "",
        roleId: "",
        slackId: "",
        teamId: "",
        emailId: "",
        businessNumber: "",
        dateOfJoining: new Date(),
        confirmationDate: new Date(),
        tentativeConfirmationDate: new Date(),
        currentTeaure: "",
        location: "",
        designationId:"",
        countryId:"",
        timezone: "",
        isTrackerRequired:true,
        isUserFresher:true,
        appraisalMonth:0
    };
    public personalInfo: personalInfo = {
        dateOfBirth: null,
        wishMeOn:null,
        nomineeName:"",
        relationship:" ",
        spouseName:"",
        name:"",
        dob:null,
        spouseDOB:null,
        gender: "",
        bloodGroup: "",
        maritalStatus: "",
        personalEmailId: "",
        phoneNumber: "",
        emergencyNumber:"",
        profileImagePath: "",
        address1: "",
        address2: "",
        permanentAddress1: "",
        permanentAddress2: "",
        anniversaryDate: new Date(),
        sameAsAddress:true,
        kids:[]
    };
    public insuranceCard: insuranceCard ={
        spouseName:"",
        dob: null,
        nominee:"",
        relationship:" ",
        childs:[]

    };
    public documents: Array<documents> = [];
    public pastExperince: Array<pastExperince> = [];
    public badges: Array<badges> = [];
    public achievements: Array<achivements> = [];
    public others: Array<othersDocument> = [];
    public skillsets: Array<skillsets> = [];

    constructor(id: string,
        _employeeInfo: employeeInfo,
        _personalInfo: personalInfo,
        _insuranceCard: insuranceCard,
        _documents: Array<documents>,
        _pastExperince: Array<pastExperince>,
        _badges: Array<badges>,
        _achivements: Array<achivements>,
        _othersDocument:Array<othersDocument>,
        _skillsets: Array<skillsets>
    ) {
        this.id = id;
        this.employeeInfo = _employeeInfo;
        this.personalInfo = _personalInfo;
        this.insuranceCard =_insuranceCard;
        this.documents = _documents;
        this.pastExperince = _pastExperince;
        this.badges = _badges;
        this.achievements = _achivements;
        this.others = _othersDocument;
        this.skillsets = _skillsets;
        
    }
}

export class employeeInfo {
    public userName: string = "";
    public employeeId: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public middleName: string = "";
    public departmentId: string = "";
    public managerId: string = "";
    public hodId: string = "";
    public roleId: string = "";
    public slackId: string = "";
    public teamId: string = "";
    public emailId: string = "";
    public businessNumber: string = "";
    public dateOfJoining: any = null;
    public confirmationDate: any = null;
    public tentativeConfirmationDate: any = null;
    public currentTeaure: string = "";
    public location: string = "";
    public designationId: string = "";
    public countryId: string = "";
    public timezone: string= "";
    public isTrackerRequired: boolean=true;
    public isUserFresher: boolean=true;
    public appraisalMonth: number=0;
}
export class personalInfo {
    public dateOfBirth: any = null;
    public wishMeOn: any = null;
    public spouseName: string = "";
    public nomineeName: string= "";
    public relationship: string= "";
    public spouseDOB: any = null;
    public name: string = "";
    public dob: any = null;
    public gender: string = "male";
    public bloodGroup: string = "";
    public maritalStatus: string = "single";
    public personalEmailId: string = "";
    public phoneNumber: string = "";
    public emergencyNumber: string = "";
    public profileImagePath: string = "";
    public address1: string = "";
    public address2: string = "";
    public permanentAddress1: string = "";
    public permanentAddress2: string = "";
    public anniversaryDate: any = null;
    public sameAsAddress: boolean=true;
    public kids:Array<kids>=[];
}
export class insuranceCard {
    public spouseName : string= "";
    public dob : any= "";
    public nominee : string= "";
    public relationship : string= "";
    public childs :Array<kids>=[];
  }
export class kids {
    public name: string = "";
    public dob: any = null;
}
export class documents {
    public documentType: string = "";
    public fileName: string = "";
}
export class pastExperince {
    public organizationName: string = "";
    public designation: string = "";
    // public workedFrom: Date = new Date();
    public workedFrom: string = "";
    public workedTo: string = "";
    // public workedTo: Date = new Date();
    public workType: string = "";
}
export class badges {
    public badgeName: string = "";
    public badgeImageUrl: string = "";
}
export class achivements {
    public achievementName: string = "";
    public achievementImageUrl: string = "";
}
export class othersDocument {
    public otherName: string = "";
    public otherImageUrl: string = "";
}
export class skillsets {
    public skillId: string = "";
    public name: string = "";
    public level: string = "";
}

export interface IFiles {
    itemType: string;
    name: string;
    file?: any;
    fileURL: string;
    uploadedBy? : any;
    fileName: string;
    ext?: any;
  }