import { combineReducers } from 'redux';
const Fill_Notification_Permission = 'Fill_Notification_Permission';
const Empty_Notification_Permission = 'Empty_Notification_Permission';

const defaultPermissions = 
  {
    access: false,
    module_ManageNotifications_Access:false,
    
    page_ManageEventManagement_Access: false,
    tab_ManageEventsListing_Access: false,
    action_ManageEventsListing_Add_Access: false,
    action_ManageEventsListing_Edit_Access: false,
    action_ManageEventsListing_Delete_Access: false,
    
    page_NoticeManagement_Access: false,
    tab_EventsNoticeListing_Access: false,
    action_ManageEventsNoticeListing_Add_Access: false,
    action_ManageEventsNoticeListing_Edit_Access: false,
    action_ManageEventsNoticeListing_Delete_Access: false,
  }

export function FillNotificationPermission(module) {
  return {
    type: Fill_Notification_Permission,
    module,
  }
}
export function EmptyNotificationPermission() {
  return {
    type: Empty_Notification_Permission,defaultPermissions
  }
}

function NotificationPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Notification_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
                permissions.access=true;
                permissions.module_ManageNotifications_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                  data.listPageMaster.forEach((page) => {
                    if (page.pageName === "Event Management") {
                      permissions.page_ManageEventManagement_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Events Listing") {
                            permissions.tab_ManageEventsListing_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "Add") {
                                  permissions.action_ManageEventsListing_Add_Access = true;
                                }
                                if (actions.actionName === "Edit") {
                                  permissions.action_ManageEventsListing_Edit_Access = true;
                                }
                                if (actions.actionName === "Delete") {
                                  permissions.action_ManageEventsListing_Delete_Access = true;
                                }
                              });
                            }
                          }
                        });
                      }
                    }
                    if (page.pageName === "Notice Management") {
                      permissions.page_NoticeManagement_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Notice Listing") {
                            permissions.tab_ManageEventsListing_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "Add") {
                                  permissions.action_ManageEventsNoticeListing_Add_Access = true;
                                }
                                if (actions.actionName === "Edit") {
                                  permissions.action_ManageEventsNoticeListing_Edit_Access =
                                    true;
                                }
                                if (actions.actionName === "Delete") {
                                  permissions.action_ManageEventsNoticeListing_Delete_Access =
                                    true;
                                }
                              });
                            }
                          }
                        });
                      }
                    }
                  });
                }
                return state = permissions;
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_Notification_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const NotificationPermissionApp = combineReducers({
    NotificationPermission
});

export default NotificationPermissionApp;