import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, Typography, Autocomplete, TextField, CircularProgress, MenuItem, Button } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { clsDepartmentList } from '../../service/common-services/department';
import { projectGrowthRevenueService } from '../../service/ProjectRevenueGrowth/projectRevenueService';
import { toast } from 'material-react-toastify';
import { milestoneTreeService } from '../../service/Reports/milestonereportTree';
import HrReporttop from './HrReportsstyle';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';


interface Department {
  id: string;
  name: string;
}

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '4px',
  textAlign: 'center',
}));

const reportType = [
  { label: 'By Project', value: 1 },
  { label: 'By Month', value: 2 },

];

const milestoneStatus = [
  { label: 'All', value: 0 },
  { label: 'Pending', value: 1 },
  { label: 'Received', value: 2 },
];

const projectStatus = [
  { label: 'All Status ', id: "" },
  { label: 'New ', id: 1 },
  { label: 'In Progress ', id: 2 },
  { label: 'Completed', id: 3 },
  { label: 'On Hold ', id: 4 },
];

const ItemBoxWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: "10px",
  rowGap: "10px",
  marginTop: "10px"
}));
export default function ProjectRevenueReport() {

  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = React.useState("");
  var projectData = { projectId: "0", projectName: "Select Projects" };
  const [projectsList, setProjectsList] = React.useState<any[]>([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState("");
  const [selectedYear, setSelectedYear] = React.useState<any>();
  const [projectRevenueGrowth, setProjectRevenueGrowth] = useState<any>();
  const MilestoneReport = projectRevenueGrowth;
  const milestoneReceived = Number(MilestoneReport?.paymentRecievedCount);
  const milestonePending = Number(MilestoneReport?.paymentPendingCount);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [projectStatusId, setProjectStatusId] = useState<number | undefined>(1);
  const [selectedMilestoneStatus, setSelectedMilestoneStatus] = useState<number | undefined>(0);
  const [projectRevenueMonthGrowth, setProjectRevenueMonthGrowth] = useState<any>([]);
  const [revenueCost, setRevenueCost] = useState<any>([]);
  const [selectedMonthlyDepartmentId, setSelectedMonthlyDepartmentId] = React.useState("");
  const [selectedMonthlyProjectId, setSelectedMonthlyProjectId] = React.useState("");
  const [projectRevenueYearlyGrowth, setProjectRevenueYearlyGrowth] = useState<any>([]);
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const options: any = {
    series: [milestoneReceived, milestonePending],
    colors: ['#2DD36F', '#ffc01e'],
    labels: ['Payment Received', 'Payment Pending'],
    legend: {
      show: false
    }
  };

  const getCurrentMonth = () => {
    const currentMonth = new Date().getMonth();
    return currentMonth;
  };
  const [monthStatus, setMonthStatus]: any = useState<number | undefined>(getCurrentMonth());
  const Months = [
    // {
    //   value: 0,
    //   label: "All Months",
    // },
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];
  const optionsForMonthlyReportMultipleBarChart: any = {
    series: [{
      name: 'Received',
      data: projectRevenueYearlyGrowth?.outputModels?.map((receive: any) => (receive?.receivedCosting))
    }, {
      name: 'Plan Cost',
      data: projectRevenueYearlyGrowth?.outputModels?.map((receive: any) => (receive?.costing))
    }],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: !selectedDepartmentId ? projectRevenueYearlyGrowth?.outputModels?.filter((item: any) => (item.departmentName !== null)).map((item: any) => item?.departmentName || 0) : Months?.filter(month => projectRevenueYearlyGrowth?.outputModels?.map((abc: any) => (abc?.month))?.includes(month?.value))?.map(item => item?.label),

    },
    yaxis: {
      title: {
        text: '',
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + "k"
        }
      }
    },
    legend: {
      position: 'right', // Moving the legend to the right
      offsetY: 40, // Adjusting the vertical position of the legend
      offsetX: -60 // Add space between legend and chart
    }
  };



  useEffect(() => {
    departmentsData();
    handleStatusChange({ target: { value: "1" } } as React.ChangeEvent<HTMLInputElement>);
  }, []);


  const departmentsData = async () => {
    const departments = await clsDepartmentList.getDepartmentDropdownList();
    const modifiedDepartments = departments.map(department => {
      if (department.name === "Select Departments") {
        return { id: "", name: "All Department" };
      } else {
        return department;
      }
    });
    setDepartmentList(modifiedDepartments);
  };



  const getAllProjects = async (departmentId: any, status: any) => {

    let abc = {
      status: status !== "" ? status : 0,
      departmentId: departmentId,
    };

    try {
      const response = await milestoneTreeService.getprojectdetails(abc);
      if (response.data.isError) {

      } else {
        const filteredData = response?.data.filter((name: any) => {
          return name.projectName !== null && name.projectName.trim() !== "";
        });
        const sortedProjects = filteredData.sort((a: any, b: any) => {
          if (a.projectName?.toLowerCase() < b.projectName?.toLowerCase()) {
            return -1;
          }
          if (a.projectName?.toLowerCase() > b.projectName?.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        setProjectsList(sortedProjects);

      }
    } catch (error) {

      console.error(error);
    }
  };

  const getProjectRevenueGrowthReport = async (year: any, month: any,
    departmentsId: string, projectId: string, status: any) => {
    let values = {
      year: year,
      month: month,
      departmentId: departmentsId,
      projectId: projectId,
      paymentStatus: status
    }
    try {
      await projectGrowthRevenueService.getProjectRevenueGrowth(values).then(async (response: any) => {
        setToggleLoader(true);
        if (response.data.isError) {
          toast.error("Something went wrong...");
          setToggleLoader(false);
        } else {
          debugger
          setProjectRevenueGrowth(response?.data ? response?.data : []);
          debugger
          setToggleLoader(false);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }
  };

  const getProjectRevenueMonthReport = async (month: any,
    year: any, projectId: string,
    departmentsId: string) => {
    let values = {
      month: month,
      year: year,
      departmentId: departmentsId,
      projectId: projectId,
    }
    setToggleLoader(true);
    try {
      await projectGrowthRevenueService.getProjectRevenueMonthReport(values).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
          setToggleLoader(false);
        } else {
          setProjectRevenueMonthGrowth(response?.data ? response?.data : []);
          setRevenueCost(response?.data[0] ? response?.data[0] : []);
          setToggleLoader(false);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }
  };

  // const getProjectRevenueYearlyGraphReport = async (month: any, year: any, projectId: any, departmentId: any
  // ) => {
  //   let value = {
  //     month: month,
  //     year: year,
  //     projectId: projectId,
  //     departmentId: departmentId
  //   }
  //   setToggleLoader(true);
  //   try {
  //     await projectGrowthRevenueService.getProjectRevenueGraphdata(value).then(async (response: any) => {
  //       if (response.data.isError) {
  //         toast.error("Something went wrong...");
  //         setToggleLoader(false);
  //       } else {
  //         setProjectRevenueYearlyGrowth(response?.data);
  //         setToggleLoader(false);
  //       }
  //     });
  //   } catch (ex: any) {
  //     ex.data.Error.map((err: any, idx: any) => {
  //       toast.warning(err);
  //       setToggleLoader(false);
  //     });
  //   }
  // };



  const handleDepartmentSelection = async (event: any, value: any) => {
    setfiltersSelected(true);
    setSelectedProjectId("");
    setSelectedDepartmentId(value?.id ?? "");
    await getAllProjects(value?.id ?? '', '');
    await getProjectRevenueGrowthReport( selectedYear,monthStatus,value?.id ?? '', '', selectedMilestoneStatus);
  };

  // const handleProjectSelection = async (event: any, value: any) => {
  //   setfiltersSelected(true);
  //   setSelectedProjectId(value?.projectId ?? "");
  //   await getProjectRevenueGrowthReport(monthStatus,currentYear,value?.projectId ?? '', selectedDepartmentId, selectedMilestoneStatus);
  // };



  const handleMonthlyDepartmentSelection = async (event: any, value: any) => {
    debugger
    setfiltersSelected(true);
    setSelectedMonthlyProjectId("");
    setSelectedMonthlyDepartmentId(value?.id ?? "");
    await getAllProjects(value?.id ?? '', '');
    await getProjectRevenueMonthReport(monthStatus, selectedYear, selectedProjectId, value?.id ?? '');
    // await getProjectRevenueYearlyGraphReport(monthStatus, selectedYear, selectedProjectId, value?.id ?? "");
  };

  const handleMonthlyProjectSelection = async (event: any, value: any) => {
    setfiltersSelected(true);
    setSelectedMonthlyProjectId(value?.projectId ?? "");
    await getProjectRevenueMonthReport(monthStatus, selectedYear, value?.projectId ?? '', selectedMonthlyDepartmentId);
    //await getProjectRevenueYearlyGraphReport(monthStatus, selectedYear, value?.projectId ?? "", selectedMonthlyDepartmentId);
  };

  const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    debugger
    setfiltersSelected(false);
    setSelectedYear(currentYear);
    const selectedProjectStatus = event.target.value as number;
    if (selectedProjectStatus == 1) {
      await getProjectRevenueGrowthReport(currentYear,monthStatus, selectedDepartmentId, '', 0);
    }
    else {
      await getProjectRevenueMonthReport(monthStatus, currentYear, selectedMonthlyDepartmentId, selectedMonthlyProjectId);
      // await getProjectRevenueYearlyGraphReport(monthStatus, currentYear, selectedProjectId, selectedDepartmentId);
    }
    setSelectedDepartmentId('');
    setSelectedProjectId('');
    setSelectedMonthlyDepartmentId('');
    setSelectedMonthlyProjectId('');
    setProjectStatusId(selectedProjectStatus);
    setSelectedMilestoneStatus(0)
    setMonthStatus(getCurrentMonth())
  };
  const handleByMonthClear = async () => {
    setfiltersSelected(false);
    setSelectedYear(currentYear);
    await getProjectRevenueMonthReport(getCurrentMonth(), currentYear, "", "");
    //  await getProjectRevenueYearlyGraphReport(getCurrentMonth(), currentYear, "", "");
    setSelectedDepartmentId('');
    setSelectedProjectId('');
    setSelectedMonthlyDepartmentId('');
    setSelectedMonthlyProjectId('');
  };
  const handleByProjectClear = async () => {
    setfiltersSelected(false);
    setSelectedYear(currentYear);
    await getProjectRevenueGrowthReport(currentYear,getCurrentMonth(),  '', '', 0);
    setSelectedDepartmentId('');
    setSelectedProjectId('');
    setSelectedMonthlyDepartmentId('');
    setSelectedMonthlyProjectId('');
    setSelectedMilestoneStatus(0)
    setMonthStatus(getCurrentMonth())
  };

  const handleProjectMonthChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setfiltersSelected(true);
    const selectedMonthStatus = event.target.value as number;
    await setMonthStatus(selectedMonthStatus);
    await getProjectRevenueGrowthReport(selectedYear,selectedMonthStatus,  selectedDepartmentId,'', selectedMilestoneStatus);
   
  
  };

  const handleMonthChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setfiltersSelected(true);
    const selectedMonthStatus = event.target.value as number;
    await setMonthStatus(selectedMonthStatus);
    await getProjectRevenueMonthReport(selectedMonthStatus, selectedYear, selectedMonthlyProjectId, selectedMonthlyDepartmentId);
    // await getProjectRevenueYearlyGraphReport(selectedMonthStatus, selectedYear, selectedMonthlyProjectId, selectedMonthlyDepartmentId);
  };

  const handleMilestoneStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setfiltersSelected(true);
    // setSelectedYear(currentYear);
    const selectedProjectStatus = event.target.value as number;
    setSelectedMilestoneStatus(selectedProjectStatus);
    await getProjectRevenueGrowthReport(selectedYear, monthStatus,  selectedDepartmentId,'', selectedProjectStatus ?? 0);

  };
  let currentYear = new Date().getFullYear();
  var currentYearString = currentYear;
  const Years = [];
  for (let i = 0; i < 4; i++) {
    Years.push({
      id: currentYear - i,
      label: (currentYear - i)
    });
  }
  const chartSetting = {
    yAxis: [
      {
        label: 'Costing (Rs)',
      },
    ],
    width: 500,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-60px, 0)',
      },
    },
  };
  return (
    <>
      {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}

      <Paper elevation={0} sx={{ p: 3, pb: 0 }}>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
                Project Revenue Growth Report</Typography>
            </Grid>

            {projectStatusId == 1 ? (
              <>
                <Grid xs={12} sx={{ padding: "0" }}>
                  <Stack direction="row" justifyContent="left" spacing={2}>

                    <TextField
                      sx={{ minWidth: 120 }}

                      id="outlined-basic"
                      select
                      value={projectStatusId}
                      variant="outlined"
                      onChange={
                        handleStatusChange
                      }
                    >
                      {reportType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <Autocomplete
                      value={
                        departmentList?.find(
                          (option) => option.id == selectedDepartmentId
                        ) ?? null
                      }
                      sx={{
                        width: "230px",
                        mr: 1,
                        lineHeight: "0.80em",
                      }}
                      fullWidth
                      id="selectedDepartmentId"
                      options={departmentList}
                      getOptionLabel={(option) => option.name}
                      onChange={handleDepartmentSelection}
                      renderInput={(params) => <TextField {...params} value={selectedDepartmentId}

                        InputLabelProps={{ style: { color: '#1DA7FF' } }} />}
                    />

                    {/* <Autocomplete
                      disabled={!selectedDepartmentId || selectedDepartmentId === ""}
                      value={
                        projectsList?.find(
                          (option) => option.projectId == selectedProjectId
                        ) ?? projectData
                      }
                      sx={{
                        width: "220px",
                        mr: 1,
                        lineHeight: "0.80em",
                      }}
                      fullWidth
                      id="selectedProjectId"
                      options={projectsList}
                      getOptionLabel={(option) => option.projectName
                      }
                      onChange={handleProjectSelection}
                      renderInput={(params) =>
                        <TextField {...params} value={selectedProjectId} InputLabelProps={{ style: { color: '#1DA7FF' } }} />}
                    /> */}

                    <Autocomplete
                      value={
                        Years?.find(
                          (option: any) => option.id == selectedYear
                        ) ?? null
                      }
                      sx={{
                        mr: 1,
                        lineHeight: "0.80em",
                        width: "120px",
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],
                              },
                            },
                          ],
                        },
                      }}
                      fullWidth
                      id="outlined-basic"
                      options={Years}
                      getOptionLabel={(option: any) => option.label}
                      onChange={async (event, value) => {
                        setfiltersSelected(true);
                        setSelectedYear(value?.label ?? currentYearString)
                        await getProjectRevenueGrowthReport(value?.label ?? currentYearString,monthStatus,   selectedDepartmentId,'', selectedMilestoneStatus);

                      }}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-basic"
                          name="selectedResourceId"
                          variant="outlined"
                          value={selectedYear}
                          fullWidth
                        />
                      )}
                    />

                    <TextField
                      sx={{ minWidth: 120 }}

                      id="outlined-basic"
                      select
                      value={monthStatus}
                      variant="outlined"
                      onChange={
                        handleProjectMonthChange
                      }
                    >
                      {Months.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      sx={{ minWidth: 120 }}

                      id="outlined-basic"
                      select
                      value={selectedMilestoneStatus}
                      variant="outlined"
                      onChange={
                        handleMilestoneStatusChange
                      }
                    >
                      {milestoneStatus.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {filtersSelected && (
                      <Button variant="contained"
                        color="warning" onClick={() => { handleByProjectClear() }}>Clear</Button>)}
                  </Stack>
                </Grid>
                <Grid xs={12}>
                  <Item>
                    <Stack direction={{ xs: 'row' }} justifyContent="center" alignItems="center">
                      <ReactApexChart options={options} series={options?.series} type="pie" width={350} />

                      <Box py={4}>
                        <Typography variant="h5" sx={{ fontWeight: 600 }} component="h1">
                          Total Invoices : {MilestoneReport?.totalMilestones}
                        </Typography>
                        <Box py={2}>
                          <Stack spacing={2} direction="row" alignItems="top">
                            <Box sx={{ backgroundColor: '#2DD36F', width: '20', height: '20', marginTop: '3', borderRadius: '50px' }}></Box>
                            <Box sx={{ marginLeft: '10px !important', display: "flex", gap: "6px" }}>
                              <Typography>Payment Received</Typography>
                              <Typography sx={{ textAlign: 'left', fontWeight: '600' }}>
                                {MilestoneReport?.paymentRecievedCount}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                        <Box py={1}>
                          <Stack spacing={2} direction="row" alignItems="top">
                            <Box sx={{ backgroundColor: '#ffc01e', width: '20', height: '20', marginTop: '3', borderRadius: '50px' }}></Box>
                            <Box sx={{ marginLeft: '10px !important', display: "flex", gap: "6px" }}>
                              <Typography>Payment Pending</Typography>
                              <Typography sx={{ textAlign: 'left', fontWeight: '600' }}>
                                {MilestoneReport?.paymentPendingCount}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Item>
                </Grid>

                <Grid xs={12}>
                  <TableContainer component={Paper} sx={{ height: "400px", overflowY: "auto" }}>
                    <Table sx={{ tableLayout: 'fixed' }} >
                      <TableHead sx={{ position: "sticky", top: "0", background: "white" }}  >
                        <TableRow>
                          <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Project</TableCell>
                          <TableCell sx={{ background: "rgb(245, 245, 245)" }}>Milestone</TableCell>
                          <TableCell sx={{ background: "rgb(245, 245, 245)", width: '30%' }}>Plan Cost</TableCell>
                          <TableCell sx={{ background: "rgb(245, 245, 245)", textAlign: "center" }}>Received</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <React.Fragment >
                          {projectRevenueGrowth?.milestoneList &&
                            Object.keys(projectRevenueGrowth.milestoneList).map((projectType, index) => (
                              <TableRow key={index}>
                                <TableCell>{projectRevenueGrowth.milestoneList[projectType]?.projectName || "--"}</TableCell>
                                <TableCell>{projectRevenueGrowth.milestoneList[projectType]?.milestoneName || "--"}</TableCell>
                                <TableCell  >{projectRevenueGrowth.milestoneList[projectType]?.costing || "0"}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{projectRevenueGrowth.milestoneList[projectType]?.receivedCosting || "0"}</TableCell>
                              </TableRow>
                            ))}
                          {projectRevenueGrowth && (
                            <TableRow>
                              <TableCell >Total</TableCell>
                              <TableCell></TableCell>
                              <TableCell >{projectRevenueGrowth.totalCosting || "0"}</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>{projectRevenueGrowth.totalReceivedCosting || "0"}</TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={7}>

                </Grid>
                <Stack direction="row" gap={2} flexWrap="wrap" sx={{ paddingBottom: "16px" }}>
                  <TextField
                    sx={{ minWidth: 120 }}

                    id="outlined-basic"
                    select
                    value={projectStatusId}
                    variant="outlined"
                    onChange={
                      handleStatusChange
                    }
                  >
                    {reportType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{ minWidth: 120 }}

                    id="outlined-basic"
                    select
                    value={monthStatus}
                    variant="outlined"
                    onChange={
                      handleMonthChange
                    }
                  >
                    {Months.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Autocomplete
                    value={
                      Years?.find(
                        (option: any) => option.id == selectedYear
                      ) ?? null
                    }
                    sx={{
                      mr: 1,
                      lineHeight: "0.80em",
                      width: "120px",
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'flip',
                            enabled: true,
                            options: {
                              altBoundary: true,
                              flipVariations: true,
                              altAxis: true,
                              padding: 8,
                              fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                            },
                          },
                        ],
                      },
                    }}
                    fullWidth
                    id="outlined-basic"
                    options={Years}
                    getOptionLabel={(option: any) => option.label}
                    onChange={async (event, value) => {
                      setfiltersSelected(true);
                      setSelectedYear(value?.label ?? currentYearString)
                      await getProjectRevenueMonthReport(monthStatus, value?.label ?? currentYearString, selectedMonthlyProjectId, selectedMonthlyDepartmentId);
                      //await getProjectRevenueYearlyGraphReport(monthStatus, value?.label ?? currentYearString, selectedMonthlyProjectId, selectedMonthlyDepartmentId);
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="outlined-basic"
                        name="selectedResourceId"
                        variant="outlined"
                        value={selectedYear}
                        fullWidth
                      />
                    )}
                  />

                  <Autocomplete
                    value={
                      departmentList?.find(
                        (option) => option.id == selectedMonthlyDepartmentId
                      ) ?? null
                    }
                    sx={{
                      width: "230px",
                      mr: 1,
                      lineHeight: "0.80em",
                    }}
                    fullWidth
                    id="selectedUserId"
                    options={departmentList}
                    getOptionLabel={(option) => option.name}
                    onChange={handleMonthlyDepartmentSelection}
                    renderInput={(params) => <TextField {...params} value={selectedMonthlyDepartmentId}
                      InputLabelProps={{ style: { color: '#1DA7FF' } }} />}
                  />

                  <Autocomplete
                    disabled={!selectedMonthlyDepartmentId || selectedMonthlyDepartmentId === ""}
                    value={
                      projectsList?.find(
                        (option) => option.projectId == selectedMonthlyProjectId
                      ) ?? projectData
                    }
                    sx={{
                      width: "230px",
                      mr: 1,
                      lineHeight: "0.80em",
                    }}
                    fullWidth
                    id="selectedProjectId"
                    options={projectsList}
                    getOptionLabel={(option) => option.projectName
                    }
                    onChange={handleMonthlyProjectSelection}
                    renderInput={(params) =>
                      <TextField {...params} value={selectedMonthlyProjectId} InputLabelProps={{ style: { color: '#1DA7FF' } }} />}
                  />
                  {filtersSelected && (
                    <Button variant="contained"
                      color="warning" onClick={() => handleByMonthClear()}>Clear</Button>)}
                </Stack>

                <Grid xs={12} sx={{ display: "flex" }}>
                  {/*                   
                    <BarChart
                      xAxis={[{ scaleType: 'band', label: 'Name of Departments', data: projectRevenueYearlyGrowth?.outputModels?.map((receive: any) => (receive?.departmentName)) || [""] }]}
                      series={[
                        {
                          data: projectRevenueYearlyGrowth?.outputModels?.map((receive: any) => (receive?.receivedCosting)) || [0],
                          label: 'Received Costing',
                          valueFormatter: (value: any) => `${value} Rs`
                        },
                        {
                          data: projectRevenueYearlyGrowth?.outputModels?.map((receive: any) => (receive?.costing)) || [0],
                          label: 'Costing',
                          valueFormatter: (value: any) => `${value} Rs`
                        }
                      ]}
                      {...chartSetting}
                    /> */}
                  {/* <ReactApexChart options={optionsForMonthlyReportMultipleBarChart} series={optionsForMonthlyReportMultipleBarChart?.series} type="bar" width={450} /> */}

                  <Stack sx={{ fontWeight: 550, display: "flex", flexDirection: "row", gap: "18px", padding: "10px 0 15px 0" }}>
                    <Box sx={{ fontSize: "18px", display: "flex", flexDirection: "row", gap: "8px" }}>Total Planned Cost : <div style={{ color: "#1DA7FF" }}>{revenueCost?.totalCosting || 0}</div></Box>
                    <Box sx={{ fontSize: "18px", display: "flex", flexDirection: "row", gap: "8px" }}>Total Received Cost : <div style={{ color: "#1DA7FF" }}>{revenueCost?.totalRecievedCosting || 0}</div></Box>
                  </Stack>
                </Grid>
                <Grid xs={12} sx={{ padding: "0", margintTop: "16px" }}>

                  <TableContainer component={Paper} sx={{ height: "400px", overflowY: "auto" }}>
                    <Table >
                      <TableHead sx={{ position: "sticky", top: "0", background: "white" }}>
                        <TableRow>
                          <TableCell sx={{ background: "rgb(245, 245, 245)", width: "25%" }}>Project</TableCell>
                          <TableCell sx={{ background: "rgb(245, 245, 245)", width: "25%" }}>Milestone</TableCell>
                          <TableCell sx={{ background: "rgb(245, 245, 245)", width: "25%" }}>Plan Cost</TableCell>
                          <TableCell sx={{ background: "rgb(245, 245, 245)", width: "25%" }}>Received</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {projectRevenueMonthGrowth?.map((projectMonthData: any, index: any) => (
                          <React.Fragment key={index}>
                            {Object.keys(projectMonthData.projectRecords).map((projectType: string) => (
                              projectMonthData.projectRecords[projectType].map((data: any, dataIndex: number) => (
                                <TableRow key={`${index}-${dataIndex}`}>
                                  <TableCell component="th" scope="row">{data.projectName}</TableCell>
                                  <TableCell component="th" scope="row">{data.milestoneName}</TableCell>
                                  <TableCell component="th" scope="row">{data?.costing || "--"}</TableCell>
                                  <TableCell component="th" scope="row">{data?.recievedCosting || "--"}</TableCell>
                                </TableRow>
                              ))
                            ))}
                            <TableRow>
                              <TableCell colSpan={2}>Total</TableCell>
                              <TableCell>{projectMonthData.totalCosting}</TableCell>
                              <TableCell>{projectMonthData.totalRecievedCosting}</TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>


                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </>
  );
}
