import { http } from "../http-common";
class UserTimeSpentService {
  
  getReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/Reports/GetUserProjectReport`, data
    );
  };

}
export const userTimeSpentService = new UserTimeSpentService();