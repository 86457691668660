import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as Icons from "../../../src/assests//icons/icons";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useRef, useState } from 'react';
import { commonFunctions } from '../../service/common-services/common-functions';
import { Avatar, FormControl, List, ListItem, Menu, MenuItem, Popover, styled, Tooltip } from '@mui/material';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { eMeetingStatus } from "../../Enum/eMeeting";
import { meetingService } from "../../service/Meeting-service";
import { toast } from "material-react-toastify";
import dayjs, { Dayjs } from "dayjs";
import SearchBar from "../../components/styledComponent/SearchBar";
import moment from "moment";
import NotFound from "../../components/styledComponent/NotFound";
import { Formik, getIn } from "formik";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DatePicker } from 'react-rainbow-components';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import getDate from "../../components/GetDateWithSimpletime";
import theme from "../../theme/theme";
import Multiselect from "multiselect-react-dropdown";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { TabPanel } from "@mui/lab";
import CustomDropdown from "./CDropdown";

const styleCross = {
  position: "absolute" as "absolute",
  right: "15px",
  top: "15px",
};

var dropdown_syle = {
  searchBox: {
    lineHeight: "31px",
    borderColor: "#EEEEEE",
    minWidth: "40px",
    maxWidth: "100%",
    minHeight: "44px",
  },
};

interface User {
  id: string;
  serialNumber: any;
  employeeId: string;
  name: string;
  emailId: string;
  department: string;
  designation: string;
  status: string;
  workLocationStatus: number;
}
interface UserT {
  userId: string;
  userName: string;
}

const DropdownWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
}));

const DropdownButton = styled('button')(({ theme }) => ({
  backgroundColor: "#1DA7FF",
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  color: "#fff",
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  textAlign: 'center',
  border: "0"
}));

const DropdownMenu = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  right: 0,
  zIndex: theme.zIndex.tooltip,
  boxShadow: theme.shadows[2],
  maxHeight: '200px',
  minWidth: "150px",
  overflowY: 'auto',
}));

const DropdownItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
export default function MyMeetings() {
  const _permission = useSelector((state: any) => state.MeetingsPermissionApp.MeetingsPermission);
  const navigate = useNavigate();
  const [myMeetingData, setMyMeetinData] = useState<any>([]);
  const [invitedToData, setInvitedToData] = useState<any>([]);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [open, setOpen] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState("Status");
  const [statusFilterValue, setStatusFilterValue] = React.useState(0);
  const [searchValue, setSearchValue] = useState("");
  const eleaveStatus = Object.keys(eMeetingStatus);
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [subject, setSubject] = React.useState("");
  const [platForm, setPlatForm] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [fetchresources, setFetchResources] = React.useState([]);
  const [fetchMeetingID, setFetchMeetingID] = React.useState("");
  const formTitleRef = useRef('');
  const buttonTextRef = useRef('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [minTime, setMinTime] = React.useState<any>("");
  const [anchorUserEl, setAnchorUserEl] = React.useState<null | HTMLElement>(null);
  const [openUserListIndex, setOpenUserListIndex] = React.useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  // const handleClickUserList = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
  //   setAnchorUserEl(event.currentTarget);
  //   setOpenUserListIndex(index);
  //   setOpenMenu(!openMenu);
  // };

  const [openMenus, setOpenMenus] = React.useState<any>('')

  const handleClickUserList = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setOpenMenus((prevState:any) => (prevState === index ? -1 : index));
  };

  const handleCloseList = () => {
    setAnchorUserEl(null);
    setOpenUserListIndex(null);
  };

  const handleClickOpen = () => {
    formTitleRef.current = 'Add Meeting';
    buttonTextRef.current = 'Create';
    setOpen(true);
  };



  const handleClickOpenUpdate = (data: any) => {
    debugger
    setOpen(true);
    console.log(data, "data")
    setDescription(data?.description);
    setSubject(data?.subject)
    setStartDate(data?.meetingDate)
    const today = dayjs(); // Get the current date
    const combinedDateTime = `${today.format('YYYY-MM-DD')} ${data?.time}`;
    const parsedDateTime = dayjs(combinedDateTime, 'YYYY-MM-DD HH:mm');
    setStartTime(parsedDateTime)
    setPlatForm(data?.platform)
    setFetchMeetingID(data?.meetingId);
    var myArray: any = [];
    data?.attendeesList.forEach((elem: any) => {
      myArray.push(elem.userId);
    });
    setSelectedIds(myArray)
    setFetchResources(data?.attendeesList);
    formTitleRef.current = 'Edit Meeting';
    buttonTextRef.current = 'Update';
    if (data?.attendeesList?.length > 0) {
      setFormSubmitted(false);
    }
  };

  const handleDateChange = (data: any) => {

    setCurrentDate(data);
    const currentDate = new Date();
    const currentDate1 = getDate(currentDate);

    if (data === currentDate1) {
      const today = dayjs();
      const currentTime = today.format('HH:mm');
      const combinedDateTime = `${today.format('YYYY-MM-DD')} ${currentTime}`;
      const parsedDateTime = dayjs(combinedDateTime, 'YYYY-MM-DD HH:mm');

      setMinTime(parsedDateTime);
    } else {
      setMinTime(null);
    }
  };


  const handleClose = () => {
    setStartDate("");
    setStartTime(null);
    setSubject("");
    setDescription("");
    setPlatForm("");
    setFetchResources([]);
    setSelectedIds([]);
    setFormSubmitted(false);
    setOpen(false);
  };

  const handleClickAway = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    if (_permission.page_ManageMeeting_Listing_Access !== true) {
      navigate("/dashboard");
    }

    myMeetingsDataApiCall("", 0, "", dummydate);
    // setMyMeetinData(jsonData)
    getUsersdrop();
    if (tabId) {
      let abc = parseInt(tabId)
      setValue(abc)
      invitedToApiCall("", 0, "", dummydate)
    }

    //To Close custom dropdown
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);


  const { tabId } = useParams();
  const handleChangeStatusFilter = (values: any, idx: any) => {
    if (value === 0) {
      myMeetingsDataApiCall(searchValue, idx, selectedUserId, dummydate)
    }
    else {
      invitedToApiCall(searchValue, idx, selectedUserId, dummydate)
    }
    setStatusFilter(values);
    setStatusFilterValue(idx)
    console.log(values)
    console.log(idx)
  };

  const triggerSearch = async (newValue: string) => {
    if (value === 0) {
      myMeetingsDataApiCall(newValue, statusFilterValue, selectedUserId, dummydate)
    }
    else {
      invitedToApiCall(newValue, statusFilterValue, selectedUserId, dummydate)
    }
    setSearchValue(newValue);
    setfiltersSelected(true);
  };

  const myMeetingsDataApiCall = (search: string, status: any, resource: any, meetingDate: string) => {
    let prm = {
      search: search,
      userId: _authUser.id,
      status: status,
      resource: resource,
      meetingDate: meetingDate ?? ""
    }
    try {
      meetingService.getMyMeetings(prm).then((response: any) => {
        if (response?.data?.isError) {
        } else {
          setMyMeetinData(response?.data ? response?.data : []);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };




  const invitedToApiCall = (search: string, status: any, resource: any, meetingdate: string) => {
    let prm = {
      search: search,
      userId: _authUser.id,
      status: status,
      resource: resource,
      meetingDate: meetingdate ?? ""
    }
    try {
      meetingService.getInvitedMeetigs(prm).then((response: any) => {
        if (response?.data?.isError) {
        } else {
          setInvitedToData(response?.data ? response?.data : []);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  console.log(myMeetingData, "meetingDatameetingData")

  const boxColors = ['#A5303F', '#1F944E', '#1DA7FF', '#212121', '#E59324']; // colors declared for box 
  const boxBackgroundColors = ['#FBDADE', '#E7FFF0', '#F0F9FF', '#BDBDBD', '#FFEFD9']; // colors declared for box background
  const categorizeMeetingsByAttendees = (assignee: any) => {
    let fiveUserArray: any = [];
    let greaterfiveUserArray: any = [];
    let sortedData = assignee?.sort((a: any, b: any) => a.userName < b.userName ? -1 : 1)

    sortedData?.map((user: any, index: any) => {
      if (index < 5) {
        fiveUserArray.push(user);
      }
      else {
        greaterfiveUserArray.push(user);
      }
    })
    return { fiveUserArray, greaterfiveUserArray };


  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [users, setUser] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = React.useState("");

  const getUsersdrop = async () => {
    var payload = {
      department: "",
      workLocationStatus: 0,
      status: "",
      search: "",
      take: 50000,
      skip: 0,
    };

    await meetingService
      .getUserForMeeting(payload)
      .then((response: any) => {
        if (response.data.isError) {
          //setUser([hodData]);
        } else {
          setUser(response.data.result);
          console.log(response);
        }
      });
  };

  //   function getDate(dateObject: any): string {
  //     if (!dateObject || !(dateObject instanceof Date) || isNaN(dateObject.getTime())) {
  //       return '';
  //     }
  //     const year = dateObject.getFullYear();
  //     const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
  //     const day = ('0' + dateObject.getDate()).slice(-2);
  //     return year + '-' + month + '-' + day + 'T00:00:00Z';
  //   }


  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ mt: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const fieldValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Agenda is required").max(100, "Reason must be at most 100 characters"),
    platform: Yup.string().required("Platform is required"),
    description: Yup.string().required("description is required"),
    meetingDate: Yup.string().required('Start date is required'),
    time: Yup.string().required('Start time is required').nullable(),
  });
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const handleSubmit1 = (e: any, values: any) => {
    e.preventDefault();
    console.log(values, "values")
    if (values.attendeesList.length == 0) {
      setFormSubmitted(true);
    }
    else {
      setFormSubmitted(false);
    }
  };

  const createMeeting = async (values: any) => {
    let abc = [...selectedIds]
    abc.push(_authUser?.id)
    values.attendeesList = abc;
    const formattedTime = dayjs(values.time).format('HH:mm');
    values.time = formattedTime;
    //setButtonDisable(true);
    try {
      await meetingService.createMeeting(values).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Meeting created successfully.");
          handleClose();
          myMeetingsDataApiCall("", 0, "", dummydate);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const updateMeeting = async (values: any) => {
    const formattedTime = dayjs(values.time).format('HH:mm');
    values.time = formattedTime;
    values.attendeesList = selectedIds;
    const pld = {
      id: fetchMeetingID,
      subject: values.subject,
      attendeesList: selectedIds,
      meetingDate: values.meetingDate,
      time: values.time,
      status: 1,
      platform: values.platform,
      description: values.description
    }
    try {
      await meetingService.updateMeeting(pld).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Meeting updated successfully.");
          handleClose();
          myMeetingsDataApiCall("", 0, "", dummydate);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  const onKeyDown = (e: any) => {
    e.preventDefault();
  };


  const [value, setValue] = React.useState<any>(0);
  const [starttime, setStartTime] = React.useState<any>("");
  const [meetingDate, setStartDate] = React.useState("");
  const [endTime, setEndTime] = React.useState<Dayjs | null>(null);
  const [showHelpText, setshowHelpText] = React.useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState<User[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [dummydate, setDummyDate] = useState("");

  console.log(selectedIds, "selectedIds")
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      invitedToApiCall("", 0, "", dummydate)
    }
    else {
      myMeetingsDataApiCall("", 0, "", dummydate);
    }
    setValue(newValue);

    setSearchValue("");
    setStatusFilter("Status")
    setStatusFilterValue(0)
    setSelectedUserId("");
    if (dummydate) {
      setfiltersSelected(true);
    }
    else {
      setfiltersSelected(false);
    }
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
  ];
console.log('openMenus', openMenus);

  const [anchorTestEl, setAnchorTestEl] = React.useState<null | HTMLElement>(null);
  const openTest = Boolean(anchorTestEl);
  const handleTestClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorTestEl(event.currentTarget);
  };
  const handleTestClose = () => {
    setAnchorTestEl(null);
  };
  const menuHide = useRef<HTMLDivElement | null>(null); 

  const handleClickOutside = (event: MouseEvent) => {
    if (menuHide.current && !menuHide.current.contains(event.target as Node)) {
      setOpenMenus(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff' }}>
        <Stack sx={{ px: 3, pt: 3 }} justifyContent={"space-between"} flexDirection={"row"}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            Meetings
          </Typography>

          <Stack direction={
            { xs: 'column', sm: 'row' }} alignItems={{ xs: 'strech', sm: "center" }} spacing={2}>

            {_permission.tab_ManageMeeting_Listing_Add_Meeting_Access && (
              <Button
                variant="contained" onClick={handleClickOpen}>
                New Meeting
              </Button>
            )}

          </Stack>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          alignItems={{ xs: 'strech', sm: "center" }}
          sx={{ px: 3, pb: 3, mt: 2 }}
        >


          <Box sx={{ flexGrow: 1 }} />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              borderRadius="semi-square"
              placeholder="Select Date"
              name="meetingDate"
              labelAlignment="left"
              value={dummydate}
              style={{ width: "14%" }}
              minDate={moment().subtract(0, "years").toDate()}

              maxDate={
                new Date(
                  new Date().getFullYear() + 1,
                  11,
                  31
                )
              }

              onChange={(newValue: any) => {
                const trimmedStartDate = getDate(newValue);
                setDummyDate(trimmedStartDate);
                setfiltersSelected(true);
                if (value === 0 && _permission.tab_ManageMeeting_Listing_Access) {
                  myMeetingsDataApiCall("", 0, "", trimmedStartDate);
                }
                else {
                  invitedToApiCall("", 0, "", trimmedStartDate);
                }

              }}

            />
          </LocalizationProvider>

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'strech', sm: "center" }} spacing={2}>
            <Autocomplete
              value={
                users?.find(
                  (option) => option.id == selectedUserId
                ) ?? null
              }
              sx={{
                mr: 1,
                lineHeight: "0.80em",
              }}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: true,
                      options: {
                        altBoundary: true,
                        flipVariations: true,
                        altAxis: true,
                        padding: 8,
                        fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                      },
                    },
                  ],
                },
              }}
              fullWidth
              id="selectedUserId"
              options={users}
              getOptionLabel={(option) => option.name}
              onChange={(event, value1) => {
                setfiltersSelected(true);
                setSelectedUserId(value1?.id ?? "");
                if (value === 0) {
                  myMeetingsDataApiCall(searchValue, statusFilterValue, value1?.id ?? "", dummydate)
                }
                else {
                  invitedToApiCall(searchValue, statusFilterValue, value1?.id ?? "", dummydate)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  name="selectedResourceId"
                  variant="outlined"
                  label={selectedUserId == "" || selectedUserId == null ? "Select Resource" : ""}
                  // value={selectedUserId ?selectedUserId:"Select"}
                  fullWidth
                  sx={{ minWidth: 220 }}
                />
              )}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'strech', sm: "center" }} spacing={2}> <TextField
            id="Statusfilter"
            variant="outlined"
            value={statusFilter}
            select
            fullWidth
          >
            {eleaveStatus.map((key, idx) => (
              <MenuItem
                key={key}
                value={key}
                onClick={(event) => {
                  setfiltersSelected(true);
                  handleChangeStatusFilter(key, idx)
                }}
              >
                {key}
              </MenuItem>
            ))}
          </TextField></Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'strech', sm: "center" }} spacing={2}>
            <SearchBar
              value={searchValue}
              callBackFunction={triggerSearch} />
          </Stack>
          {filtersSelected && (
            <Button color="warning"
              variant="contained"
              onClick={() => {
                setfiltersSelected(false);
                setSearchValue("");
                setStatusFilter("Status")
                setStatusFilterValue(0);
                setSelectedUserId("");
                setDummyDate("")
                if (value === 0) {
                  myMeetingsDataApiCall("", 0, "", "")
                }
                else {
                  invitedToApiCall("", 0, "", "")
                }
              }}>Clear</Button>)}

        </Stack>

      </Box>
      <Box sx={{ width: '100%', "& .css-19kzrtu": { p: '16px 0' }, "& .css-8uc73f-MuiPaper-root": { boxShadow: '0px 0px 20px 0px #0000000A' } }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {_permission.tab_ManageMeeting_Listing_Access && (
              <Tab label="My Meetings" {...a11yProps(0)} />
            )}
            {_permission.tab_ManageMeeting_Listing_Invited_To_Access && (
              <Tab label="Invited To" {...a11yProps(1)} />
            )}


          </Tabs>
        </Box>
        {_permission.tab_ManageMeeting_Listing_Access && (
          <TabPanel value={value} index={0} >
            <>
              <Paper sx={{ background: 'none', boxShadow: 'none' }}>
                {myMeetingData.map((item: any, idx: number) => {
                  const userList = categorizeMeetingsByAttendees(item?.attendeesList);

                  console.log("userList ===", userList);
                  console.log("userList.greaterfiveUserArray ===", userList.greaterfiveUserArray);
                  return (
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}
                      sx={{ mb: 2, background: "#fff", padding: "5px 15px" }}>
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={_permission.action_ManageMeeting_Listing_View_MoM_Access ? () => navigate(`/meetingsView/${item?.meetingId}/${value}`) : undefined}
                      >
                        <Typography sx={{ color: '#232360', fontSize: '18px', fontWeight: 500 }}>
                          {item?.subject}
                        </Typography>
                        <Typography sx={{ color: '#232360', fontSize: '12px', fontWeight: 400 }}>
                          {`On ${moment(item?.meetingDate).format("DD MMM, YYYY")}`}
                          &nbsp;
                          {`at ${moment(item?.time, 'HH:mm').format('h:mm A')}`}
                          &nbsp;
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClickOpenUpdate(item);
                              }}
                            //component={Link}
                            //to={`/users/edit-user/`}
                            //size="medium"
                            //target="_blank"
                            >
                              <Icons.Edit />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>


                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {userList.fiveUserArray.length != 0 && userList.fiveUserArray.map((item: any, itemIdx: any) => {
                          const backgroundColor = boxBackgroundColors[itemIdx % boxBackgroundColors.length];
                          const textColor = boxColors[itemIdx % boxColors.length];
                          return (
                            <Tooltip title={item?.userName} arrow>
                              <Box sx={{
                                borderRadius: '50%',
                                background: backgroundColor,
                                color: textColor,
                                width: '36px', height: '36px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                              }}>
                                {commonFunctions.getFirstTwoLettersFromString(item?.userName)}
                              </Box></Tooltip>
                          )
                        })}
                        {userList.greaterfiveUserArray.length != 0 && (
                          <Box>
                            <Stack direction={'row'} alignItems={'center'}>


                              <DropdownWrapper>
                                <DropdownButton onClick={(event: any) => handleClickUserList(event, idx)}>
                                  <Typography sx={{ color: '#fff', marginLeft: '4px' }}>
                                    <span>+{userList.greaterfiveUserArray.length}</span>
                                  </Typography>

                                </DropdownButton>
                               {openMenus===idx && (
                                  <DropdownMenu ref={menuHide}>
                                   
                                    {userList.greaterfiveUserArray.map((user: any, index: any) => (
                                      <DropdownItem                               
                                        key={index}
                                        value={user.userId}>
                                        {user.userName}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                )}
                              </DropdownWrapper>
                            </Stack>

                          </Box>)}
                      </Stack>
                    </Stack>)
                })}
                {(myMeetingData.length == 0 || myMeetingData == undefined) && (
                  <NotFound NotfoundText="Oops! No Meeting Found " />)}
              </Paper>
            </>
          </TabPanel>
        )}
        {_permission.tab_ManageMeeting_Listing_Invited_To_Access && (
          <CustomTabPanel value={value} index={(!_permission.tab_ManageMeeting_Listing_Access && _permission.tab_ManageMeeting_Listing_Invited_To_Access) ? 0 : 1}>
            <Paper sx={{ background: 'none', boxShadow: 'none' }}>
              {invitedToData?.map((item: any, idx: number) => {
                const userList = categorizeMeetingsByAttendees(item?.attendees);
                console.log(userList.greaterfiveUserArray, "userList.greaterfiveUserArray===");
                return (
                  <Grid container spacing={2} sx={{ mb: 2, background: "#fff", padding: "5px 15px", width: "100%", marginLeft: "unset" }}>
                    <Grid item xs={5}>
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={_permission.action_ManageMeeting_Listing_Invited_To_View_MOM_Access ? () => navigate(`/meetingsView/${item?.meetingId}/${value}`) : undefined}
                      >
                        <Typography sx={{ color: '#232360', fontSize: '18px', fontWeight: 500 }}>
                          {item?.subject}
                        </Typography>
                        <Typography sx={{ color: '#232360', fontSize: '12px', fontWeight: 400 }}>
                          {`On ${moment(item?.meetingDate).format("DDMMM, YYYY")}`}
                          &nbsp;
                          {`at ${moment(item?.time, 'HH:mm').format('h:mm A')}`}

                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography sx={{ color: 'lightgrey', fontSize: '18px', fontWeight: 500 }}>
                          Meeting Owner :  {item?.meetingOwnerName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>

                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {userList.fiveUserArray.length != 0 && userList.fiveUserArray.map((item: any, itemIdx: any) => {
                          const backgroundColor = boxBackgroundColors[itemIdx % boxBackgroundColors.length];
                          const textColor = boxColors[itemIdx % boxColors.length];
                          return (
                            <Tooltip title={item?.userName} arrow>
                              <Box sx={{
                                borderRadius: '50%',
                                background: backgroundColor,
                                color: textColor,
                                width: '36px', height: '36px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                              }}>
                                {commonFunctions.getFirstTwoLettersFromString(item?.userName)}
                              </Box></Tooltip>
                          )
                        })}
                        {userList.greaterfiveUserArray.length !== 0 && (
                          <Box>
                            <Stack direction={'row'} alignItems={'center'}>
                                <DropdownWrapper>
                                <DropdownButton onClick={(event: any) => handleClickUserList(event, idx)}>
                                  <Typography sx={{ color: '#fff', marginLeft: '4px' }}>
                                    <span>+{userList.greaterfiveUserArray.length}</span>
                                  </Typography>

                                </DropdownButton>
                               {openMenus===idx && (
                                  <DropdownMenu ref={menuHide}>
                                   
                                    {userList.greaterfiveUserArray.map((user: any, index: any) => (
                                      <DropdownItem                               
                                        key={index}
                                        value={user.userId}>
                                        {user.userName}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                )}
                              </DropdownWrapper>


                              {/* <Avatar
                                component={Button}
                                aria-controls={openUserListIndex === idx ? "menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={openUserListIndex === idx ? "true" : undefined}
                                onClick={(event) => handleClickUserList(event, idx)}
                                sx={{
                                  bgcolor: theme.palette.primary.main,
                                  minWidth: "inherit",
                                  "&:hover": {
                                    bgcolor: theme.palette.primary.dark,
                                  },
                                }}
                              >

                                <Icons.AddIcon />
                                <Typography sx={{ color: '#fff', marginLeft: '4px' }}>
                                  <span>+{userList.greaterfiveUserArray.length}</span>
                                </Typography>
                              </Avatar> */}



                            </Stack>

                            {/* <Menu
                              id="menu"
                              anchorEl={anchorUserEl}
                              open={openUserListIndex === idx}
                              onClose={handleCloseList}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              {userList.greaterfiveUserArray.map((user: any, index: any) => (
                                <MenuItem
                                  key={index}
                                  value={user.userId}>

                                  {user.userName}
                                </MenuItem>
                              ))}
                            </Menu> */}

                          </Box>)}
                      </Stack>
                    </Grid>
                  </Grid>
                  // <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}
                  //   sx={{ mb: 2, background: "#fff", padding: "5px 15px" }}>



                  // </Stack>
                )
              })}
              {(invitedToData.length == 0 || invitedToData == undefined) && (
                <NotFound NotfoundText="No meeting has been assigned yet." />)}

            </Paper>
          </CustomTabPanel>

        )}

      </Box>

      <Dialog
        open={open}
        onClose={Button}
        sx={{ "& .MuiDialog-paper": { overflow: "visible", height: "unset" } }}
      >
        <Formik
          initialValues={{
            subject: subject,
            attendeesList: selectedIds,
            meetingDate: meetingDate,
            time: starttime,
            platform: platForm,
            description: description,
          }}
          validationSchema={fieldValidationSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              if (!formSubmitted) {
                if (buttonTextRef.current === 'Create') {
                  await createMeeting(values);
                } else if (buttonTextRef.current === 'Update') {
                  await updateMeeting(values);
                }
              }

            } catch (ex: any) {
              ex.data.errors.map((err: any, idx: any) => {
                toast.warning(err);
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={(e) => { handleSubmit(); handleSubmit1(e, values); }}>
              <IconButton aria-label="delete" sx={styleCross} onClick={handleClose}>
                <ClearIcon />
              </IconButton>
              <DialogTitle>{formTitleRef.current}</DialogTitle>
              <DialogContent sx={{ maxHeight: '400px' }}>
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  <Grid item xs sm={12}>
                    <TextField
                      required
                      id="name"
                      name="subject"
                      label="Agenda"
                      type="email"
                      fullWidth
                      value={subject}
                      onChange={(e) => {
                        let abc = e.currentTarget.value;
                        setSubject(abc);
                        values.subject = abc
                      }}
                      onBlur={handleBlur}
                      error={Boolean(
                        getIn(touched, "subject") && getIn(errors, "subject")
                      )}
                      helperText={
                        getIn(touched, "subject") && getIn(errors, "subject")
                      }
                    //variant="outlined"
                    />
                  </Grid>
                  <Grid item xs sm={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ gap: "8px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <FormControl >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              borderRadius="semi-square"
                              placeholder="Date"
                              name="meetingDate"
                              labelAlignment="left"
                              value={meetingDate}
                              minDate={moment().subtract(0, "years").toDate()}

                              maxDate={
                                new Date(
                                  new Date().getFullYear() + 1,
                                  11,
                                  31
                                )
                              }

                              onChange={(newValue: any) => {
                                const trimmedStartDate = getDate(newValue);
                                values.meetingDate = trimmedStartDate;
                                setStartDate(trimmedStartDate);
                                // setSubmit(true);
                                setshowHelpText(false);
                                handleDateChange(trimmedStartDate);

                              }}
                              error={Boolean(getIn(touched, "meetingDate") && getIn(errors, "meetingDate"))}
                            //helperText={getIn(touched, "meetingDate") && getIn(errors, "meetingDate")} 

                            />
                            {getIn(touched, "meetingDate") && getIn(errors, "meetingDate") && (
                              <Typography variant="body2" color="error">
                                {getIn(errors, "meetingDate")}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </FormControl>
                      </Box>
                      <Box sx={{ width: "50%" }}>
                        <FormControl >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              key="starttime"
                              // disableMaskedInput={true}
                              //ampm={false}
                              //inputFormat="hh:mm"
                              disableMaskedInput
                              mask="__:__"
                              label="Time"
                              value={starttime}
                              showToolbar={false}
                              // autoComplete='off'
                              // ampm={true}
                              // ampmInClock={true}
                              //className={styles.selectType}
                              // readOnly={true}

                              onChange={(newValue) => {


                                if (newValue !== null) {
                                  values.time = newValue;
                                  setStartTime(newValue);


                                }

                              }}
                              PopperProps={{
                                popperOptions: {
                                  modifiers: [
                                    {
                                      name: 'flip',
                                      enabled: true,
                                      options: {
                                        altBoundary: true,
                                        flipVariations: true,
                                        altAxis: true,
                                        fallbackPlacements: ['bottom'],
                                        padding: 50  // Set to ['bottom'] if you want it to always open at the bottom
                                      },
                                    },]
                                },
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  autoComplete="off"
                                  {...params}
                                  onKeyDown={onKeyDown}
                                  onBlur={handleBlur}
                                  value={starttime}
                                  //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                                  error={
                                    getIn(touched, "time") &&
                                    getIn(errors, "time")
                                  }
                                  helperText={
                                    getIn(touched, "time") &&
                                    getIn(errors, "time")
                                  }
                                  // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fill: theme.palette.primary.main,
                                    },
                                    "@media screen and (max-width: 600px)": {
                                      // Adjustments for smaller screens
                                      width: "100%", // Make 
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs sm={12} >
                    {/* <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                renderInput={(params) => <TextField {...params} label="Meeting Resources" />}
                            /> */}

                    <div style={{ border: formSubmitted && selectedIds.length === 0 ? '1px solid red' : '' }}>

                      <Multiselect
                        id="resource"
                        displayValue="name"
                        onKeyPressFn={() => { }}
                        onRemove={(selectedList, selectedItem) => {
                          var myArray: any = [];
                          selectedList.forEach((elem: any) => {
                            myArray.push(elem.id);
                          });
                          setSelectedIds(myArray);
                          values.attendeesList = myArray;
                        }}
                        onSearch={() => { }}
                        onSelect={(selectedList, selectedItem) => {
                          setSelectedAttendees(selectedList);
                          const ids = selectedList.map((user: any) => `${user.id}`);
                          values.attendeesList = ids;
                          setSelectedIds(ids);
                          setFormSubmitted(false)
                        }}
                        placeholder="Attendees"
                        options={users
                          .filter(user => user.name !== null)
                          .map(user => ({
                            id: user.id,
                            name: `${user.employeeId} -  ${user.name}  (${user.emailId})`,
                          }))}
                        selectedValues={fetchresources.map((user: any) => ({
                          id: user.userId,
                          name: user.userName,
                        }))}
                        style={dropdown_syle}
                        selectedValueDecorator={(v, option) => {
                          if (fetchresources.length === 0) {
                            return (
                              <span>{option?.name?.split(' - ')[1]?.split(' (')[0]}</span>
                            );
                          } else {
                            const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

                            let matchedvalue = v.match(emailRegex);
                            return (
                              matchedvalue ?
                                <span>{option?.name?.split(' - ')[1]?.split(' (')[0]}</span>
                                : <span>{option?.name}</span>
                            );
                          }
                        }}
                      />
                    </div>
                    {(formSubmitted && selectedIds.length == 0) && (
                      <div style={{ color: 'red', fontSize: '13px' }}>{"Resource is Required"}</div>
                    )}
                  </Grid>
                  <Grid item xs sm={12}>
                    <TextField
                      required
                      id="name"
                      name="platform"
                      label="Meeting Location /Platform"
                      type="email"
                      fullWidth
                      value={platForm}
                      onChange={(e) => {
                        let abc = e.currentTarget.value;
                        setPlatForm(abc);
                        values.platform = abc
                      }}
                      onBlur={handleBlur}
                      error={Boolean(
                        getIn(touched, "platform") && getIn(errors, "platform")
                      )}
                      helperText={
                        getIn(touched, "platform") && getIn(errors, "platform")
                      }
                    //variant="outlined"
                    />
                  </Grid>

            <Grid item xs sm={12}>
              <TextField
                required
                id="name"
                name="description"
                label="Description"
                type="email"
                fullWidth
                value={description}
                sx={{
                  "word-break": "break-all",
                  "line-height": "1.5",  
                  "padding": "10px",    
                  "& .MuiInputBase-root": { 
                      "line-height": "1.5",
                  },
                  "& .MuiOutlinedInput-root": {  
                      "line-height": "1.5",
                      "padding": "10px", 
                  }
              }}
                onChange={(e)=>{
                  let abc  =e.currentTarget.value;
                  setDescription(abc);
                  values.description = abc
                }}
                onBlur={handleBlur}
                error={Boolean(
                    getIn(touched, "description") && getIn(errors, "description")
                  )}
                  helperText={
                    getIn(touched, "description") && getIn(errors, "description")
                  }
                multiline
                rows={4}
                //variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" sx={{marginRight: "42px"}}>
          {buttonTextRef.current}
          </Button>
        </DialogActions>
        </form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
}
