import { combineReducers } from 'redux';
const Fill_Inventory_Permission = 'Fill_Inventory_Permission';
const Empty_Inventory_Permission = 'Empty_Inventory_Permission';

const defaultPermissions = 
  {
    access: false,
    module_InventoryManagement_Access:false,

    page_ManageCategoryManagement_Access: false,
    tab_ManageCategoryListing_Access: false,
    action_ManageCategoryListing_Add_Access: false,
    action_ManageCategoryListing_Edit_Access: false,
    action_ManageCategoryListing_Delete_Access: false,
    action_ManageCategoryListing_View_Access: false,

    page_ManageItemListing_Access: false,
    tab_ManageItemListing_Access: false,
    action_ManageItemListing_Add_Access: false,
    action_ManageItemListing_Edit_Access: false,
    action_ManageItemListing_Delete_Access: false,
    action_ManageItemListing_View_Access: false,
    page_ManageMyAssets_Access: false,

    page_IssueDeviceListing_Access: false,
    page_ReturnDevice_Access: false,
    tab_IssueDeviceListing_Access: false,
    action_IssueDeviceListing_Add_Access: false,
    action_IssueDeviceListing_View_Access: false,

    page_ManageReturnDevice_Access: false,
    tab_ManageReturnDevice_Access: false,
    tab_ManageReturnRequests_Access: false,
    tab_ManageRecieveDevice_Access:false,
    action_ReceiveDevice_Access: false,
    action_ReturnRequestApprove_Access: false,
  
  }

export function FillInventoryPermission(module) {
  return {
    type: Fill_Inventory_Permission,
    module,
  }
}
export function EmptyInventoryPermission() {
  return {
    type: Empty_Inventory_Permission,defaultPermissions
  }
}

function InventoryPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Inventory_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
                permissions.access=true;
                permissions.module_InventoryManagement_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                  data.listPageMaster.forEach((page) => {
                    if (page.pageName === "Manage Category") {
                      permissions.page_ManageCategoryManagement_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Category Listing") {
                            permissions.tab_ManageCategoryListing_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "Add") {
                                  permissions.action_ManageCategoryListing_Add_Access = true;
                                }
                                if (actions.actionName === "Edit") {
                                  permissions.action_ManageCategoryListing_Edit_Access = true;
                                }
                                if (actions.actionName === "Delete") {
                                  permissions.action_ManageCategoryListing_Delete_Access = true;
                                }
                                if (actions.actionName === "View") {
                                  permissions.action_ManageCategoryListing_View_Access = true;
                                }

                                
                               
                              });
                            }
                          }
                        });
                      }
                    }
                    if (page.pageName === "Manage Items") {
                      permissions.page_ManageItemListing_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Item Listing") {
                            permissions.tab_ManageItemListing_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "Add") {
                                  permissions.action_ManageItemListing_Add_Access = true;
                                }
                                if (actions.actionName === "Edit") {
                                  permissions.action_ManageItemListing_Edit_Access =true;
                                }
                                if (actions.actionName === "Delete") {
                                  permissions.action_ManageItemListing_Delete_Access =true;
                                }
                                if (actions.actionName === "View") {
                                  permissions.action_ManageItemListing_View_Access = true;
                                }
                              });
                            }
                          }
                        });
                      }
                    }
                    if (page.pageName === "Issue Device") {
                      permissions.page_IssueDeviceListing_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Issue Device Listing") {
                            permissions.tab_IssueDeviceListing_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                                if (actions.actionName === "Add") {
                                  permissions.action_IssueDeviceListing_Add_Access = true;
                                }

                                if (actions.actionName === "View") {
                                  permissions.action_IssueDeviceListing_View_Access = true;
                                }
                              });
                            }
                          }
                        });
                      }
                    }
                    if (page.pageName === "Return Device") {
                      permissions.page_ReturnDevice_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Return Device") {
                            permissions.tab_ManageReturnDevice_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                            if (actions.actionName === "Receive Device") {
                              permissions.action_ReceiveDevice_Access = true;
                            }
                          })
                          };}
                          if (tabs.tabName === "Return Requests") {
                            permissions.tab_ManageReturnRequests_Access = true;
                            if (
                              tabs.listActionMaster &&
                              tabs.listActionMaster.length > 0
                            ) {
                              tabs.listActionMaster.forEach((actions) => {
                            if (actions.actionName === "Return Request Approve") {
                              permissions.action_ReturnRequestApprove_Access = true;
                            }
                          })
                          };
                        }
                        });
                      }
                    }
                    if (page.pageName === "My Assets") {
                      permissions.page_ManageMyAssets_Access = true;
                    }
                  });
                }
                return state = permissions;
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_Inventory_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const InventoryPermissionApp = combineReducers({
  InventoryPermission
});

export default InventoryPermissionApp;