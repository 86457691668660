import { http } from "./http-common";
class LinkedIssueServices {
    createLinkedIssue = async (depart: any): Promise<any> => {
        return await http.post<any>("/v1/Ticket/LinkIssues", depart);
    };
    updateLinkedIssue = async (depart: any): Promise<any> => {
        return await http.post<any>("", depart);
    };
    createBlockedBy = async (depart: any): Promise<any> => {
        return await http.put<any>("/v1/Ticket/UpdateBlockedBy", depart);
    };
    deleteLinkIssue = async (linkIssueId: any,mainTicketId: any): Promise<any> => {
        return await http.delete<any>(`/v1/Ticket/DeleteLinkIssuesById?linkIssueId=${linkIssueId}&mainTicketId=${mainTicketId}`);
    };

}
export const linkedIssueServices = new LinkedIssueServices();