import zIndex from "@mui/material/styles/zIndex";
import theme from "../../theme/theme";


const HrReporttop = {
    styleCross: {
        position: "absolute" as "absolute",
        right: "12px",
        top: "16px",
    },
    topbar: {
        flexDirection: { xs: 'column', sm: "row" },
        alignItems: { xs: 'start', md: "start" },
        flexWrap: { xs: 'nowrap', sm: 'wrap' },
        justifyContent: 'start',
        position: "relative",
        gap: "0 16px"
    },
    rightside: {
        flexDirection: { xs: 'column', md: "row" },
        justifyContent: { xs: 'center', md: "space-between" },

    },
    toggleloaders: {
        position: "fixed",
        left: "50%",
        right: "50%",
        top: "40%",
        bottom: "50%",
        zIndex: "999",
        transform: "translate(-50%, -50%)"
    },
    toggleloaders1: {
        left: "50%",
        right: "50%",
        top: "25%",
        bottom: "50%",
        transform: "translate(-50%, -50%)",
        position: 'relative',
        zIndex: "999"
    },
    datepickertop: {
        width: { xs: '100%', md: '150px' }, mr: 1, lineHeight: '0.80em',
        "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main
        }, margin: "0",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            margin: "0"
        },
    },
}
export default HrReporttop;