import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";
import * as Icons from "../../assests/icons/icons";
import { Formik, getIn } from 'formik';
import * as Yup from "yup";
import theme from '../../theme/theme';
import { toast } from 'material-react-toastify';
import { slabgenrationService } from '../../service/SlabgenrationService';

interface Allowance {
    allowanceId: string;
    isAllowanceType: boolean;
    dependentOn: string;
    allowanceValue: string;
}

interface FormObject {
    slabName: string;
    slabRangeFrom: string;
    slabRangeTo: string;
    allowances: Allowance[];
    allowanceValue: string;
}
let objallowance =
{
    allowanceId: "",
    isAllowanceType: true,
    dependentOn: "",
    allowanceValue: ""
}

let Formobj = {
    slabName: "",
    slabRangeFrom: "",
    slabRangeTo: "",
    // Outlined: "",
    allowances: []
}
interface AllowanceList {
    id: string;
    name: string;
}

const dummy = [
    { label: "N/A" },
    { label: "Gross Salary" },
    { label: "Basic Salary" },
]
export default function AddSlab() {
    const [slabName, setslabName] = React.useState("");
    const [slabRangeFrom, setslabRangeFrom] = React.useState("");
    const [slabRangeTo, setslabRangeTo] = React.useState("");
    const [isAllowanceType, setisAllowanceType] = useState(true);
    const [allowanceList, setAllowanceList] = useState<AllowanceList[]>([]);
    const [allowanceFormValue, setAllowanceFormValue] = React.useState<any[]>([objallowance]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getpayrollslabById(id);
        }
        getallowanceDetails();
    }, [isAllowanceType]);

    const handleCancel = () => {
        window.location.reload();
    };

    const addroll = async (values: any) => {
        try {
            const response = await slabgenrationService.AddPayRoll(values);
            if (response.data.isError) {

            } else {
                debugger;
                toast.success("Slab Added successfully");
                navigate("/payrolelist")
                // setTimeout(() => {
                //     if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                //         // If you're working locally, navigate to the local URL
                //         window.location.href = "/payrolelist";
                //     } else {
                //         // If you're not working locally, navigate to the production URL
                //         window.location.reload();
                //     }
                // }, 300);
            }
        } catch (error: any) {
            if (error.data.Error.length > 0)
                toast.warn(error);
            return error;
        }
    };

    const addFormFields = () => {
        const newFormFields = {
            allowanceId: "",
            allowanceValue: "",
            isAllowanceType: true,
            dependentOn: "",
            // Outlined: "",
        };
        setAllowanceFormValue([...allowanceFormValue, newFormFields]);
    };

    const handleFormChange = (index: any, field: any, value: any) => {
        let newFormValues = [...allowanceFormValue];
        newFormValues[index] = { ...newFormValues[index], [field]: value };
        setAllowanceFormValue(newFormValues);
    };

    let removeFormFields = (i: any) => {
        let newallowance = [...allowanceFormValue];
        newallowance.splice(i, 1);
        setAllowanceFormValue(newallowance);
    };

    const getpayrollslabById = async (slabId: any) => {
        try {

            await slabgenrationService.getpayrollslabById(slabId).then((response: any) => {

                if (response.data.isError) {
                    toast.error("Something went wrong while fetching manager data ...");
                } else {

                    setslabName(response?.data?.slabName);
                    setslabRangeFrom(response?.data?.slabRangeFrom);
                    setslabRangeTo(response?.data?.slabRangeTo);
                    setAllowanceFormValue(response?.data?.allowances);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    const getallowanceDetails = async () => {
        let abc = {
            "status": "",
            "search": "",
            "isDropDown": true
        }
        try {
            await slabgenrationService.getallallow(abc).then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong while fetching manager data ...");
                } else {
                    setAllowanceList(response?.data)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    async function Updateslabpay(data: any) {
        let abc = data;
        data.id = id;
        try {
            await slabgenrationService.Updateslabpay(abc).then((response: any) => {
                if (response.data.isError) {
                    toast.warn(response.data.isError);
                }
                else {
                    toast.success("Slab Updated  successfully")
                    navigate("/payrolelist");
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    }
    const fieldValidationSchema = Yup.object().shape({
        slabName: Yup.string().required("Slab Name is required"),
        slabRangeFrom: Yup.string().required("This field is required"),
        slabRangeTo: Yup.string().required("This field is required"),

        allowances: Yup.array().of(
            Yup.object().shape({
                allowanceId: Yup.string().required("Allowance is required"),
                allowanceValue: Yup.string().required("Allowance value is required"),






                dependentOn: Yup.string().when('isAllowanceType', {
                    is: false,
                    then: Yup.string().required('Dependent On is required'),
                }),



            }),

        ),


    });


    return (
        <Formik
            initialValues={{
                slabName: slabName,
                slabRangeFrom: slabRangeFrom,
                slabRangeTo: slabRangeTo,
                allowances: allowanceFormValue
            }}
            validationSchema={fieldValidationSchema}
            enableReinitialize={true}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                debugger
                if (!id) {
                    await addroll(values);
                    // values.allowances.forEach((allowance, index) => {
                    //     values.allowances[index].allowanceId = "";
                    //     values.allowances[index].allowanceValue = "";
                    // });
                } else {
                    Updateslabpay(values);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleReset,
                touched,
                values,
            }) => (

                <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Paper sx={{ p: 3 }}>
                            <Stack spacing={2} direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px", color: '#232360' }} component="h2">
                                    Slab Generation
                                </Typography>
                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={2} >
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <Typography sx={{ fontWeight: 600, color: '#232360', whiteSpace: 'nowrap' }}>
                                            Slab Name
                                        </Typography>
                                        <TextField
                                            label="Slab Name"
                                            variant="outlined"
                                            //  required
                                            fullWidth
                                            name="Item Name"
                                            value={values.slabName}
                                            error={Boolean(
                                                getIn(touched, "slabName") &&
                                                getIn(errors, "slabName")
                                            )}
                                            helperText={
                                                getIn(touched, "slabName") &&
                                                getIn(errors, "slabName")
                                            }
                                            onBlur={handleBlur}
                                            onChange={(e) => {                                             
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.slabName = value;
                                                setslabName(value);
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <Typography sx={{ fontWeight: 600, color: '#232360' }}>
                                            Slab Range
                                        </Typography>
                                        <TextField
                                            label="From"
                                            value={values.slabRangeFrom}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value.trimStart().replace(/[^0-9]$/g, "").substring(0, 7);
                                                values.slabRangeFrom = value;
                                                setslabRangeFrom(value);
                                            }}
                                            autoComplete="off" // Disable suggestions

                                            error={
                                                getIn(touched, "slabRangeFrom") &&
                                                getIn(errors, "slabRangeFrom")
                                            }
                                            helperText={
                                                getIn(touched, "slabRangeFrom") &&
                                                getIn(errors, "slabRangeFrom")
                                            }
                                            fullWidth
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fill: theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="To"
                                            value={values.slabRangeTo}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value.trimStart().replace(/[^0-9]$/g, "").substring(0, 7);
                                                values.slabRangeTo = value;
                                                setslabRangeTo(value);
                                            }}
                                            error={
                                                getIn(touched, "slabRangeTo") &&
                                                getIn(errors, "slabRangeTo")
                                            }
                                            helperText={
                                                getIn(touched, "slabRangeTo") &&
                                                getIn(errors, "slabRangeTo")
                                            }
                                            fullWidth
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fill: theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>

                        <Grid container spacing={2} sx={{ my: 2 }}>
                            {allowanceFormValue?.map((element: any, index: any) => (
                                <Grid item xs={6}>
                                    <Paper sx={{ p: 3, height: "100%" }}>
                                        <Stack direction={'column'} justifyContent={'start'} spacing={{ xs: 2, xl: 3 }}>
                                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                {/* {element?.allowanceId} */}
                                                <Autocomplete
                                                    sx={{ width: '35%' }}
                                                    value={allowanceList?.find((option: any) => option.id == element.allowanceId) ?? null}
                                                    options={allowanceList}
                                                    getOptionLabel={(option) => option.name || ''}
                                                    onChange={(event, value) => {
                                                        let newFormValues = [...allowanceFormValue];
                                                        newFormValues[index].allowanceId = value?.id ?? "";
                                                        setAllowanceFormValue(newFormValues);
                                                    }}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Allowance"
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`allowances.${index}.allowanceId`}
                                                            value={element[index]?.allowanceId}
                                                            onBlur={handleBlur}
                                                            sx={{ mb: '24px' }}
                                                            error={Boolean(
                                                                getIn(touched, `allowances.${index}.allowanceId`) &&
                                                                getIn(errors, `allowances.${index}.allowanceId`)
                                                            )}
                                                            helperText={
                                                                getIn(touched, `allowances.${index}.allowanceId`) &&
                                                                getIn(errors, `allowances.${index}.allowanceId`)
                                                            }
                                                        />
                                                    )}
                                                />
                                                <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>
                                                    <Typography variant="h6" sx={{ fontWeight: 400, marginRight: "10px", fontSize: '16px', mb: 0 }} component="h2">
                                                        Allowance Value
                                                    </Typography>
                                                    <RadioGroup
                                                        row
                                                        value={element.isAllowanceType ? "Fixed" : "Variable"}
                                                        defaultValue={element.isAllowanceType ? "Fixed" : "Variable"}
                                                        name="radio-buttons-group"
                                                        onChange={(event) => {
                                                            const newValue = event.target.value === "Fixed";
                                                            handleFormChange(index, "isAllowanceType", newValue);
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value="Fixed"
                                                            defaultValue="Fixed"
                                                            control={<Radio />}
                                                            label="Fixed"
                                                        />
                                                        <FormControlLabel
                                                            value="Variable"
                                                            defaultValue="Variable"
                                                            control={<Radio />}
                                                            label="Variable"
                                                        />
                                                    </RadioGroup>
                                                </Stack>
                                            </Stack>
                                        </Stack>

                                        <Grid container spacing={2} sx={{ my: 1 }}>
                                            <Grid item xs={6} >
                                                <Stack direction={'row'}>
                                                    <TextField
                                                        id="allowanceValue"
                                                        label="Allowance Value"
                                                        variant="outlined"
                                                        value={element.allowanceValue}
                                                        sx={{ width: '100%'}}
                                                        error={Boolean(
                                                            getIn(touched, `allowances.${index}.allowanceValue`) &&
                                                            getIn(errors, `allowances.${index}.allowanceValue`)
                                                        )}
                                                        helperText={
                                                            getIn(touched, `allowances.${index}.allowanceValue`) &&
                                                            getIn(errors, `allowances.${index}.allowanceValue`)
                                                        }
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            let value = e.currentTarget.value.substring(0, 5).trimStart();
                                                            let newFormValues = [...allowanceFormValue];
                                                            newFormValues[index].allowanceValue = value;
                                                            setAllowanceFormValue(newFormValues);
                                                        }}
                                                    />
                                                    {!element.isAllowanceType  && (
                                                    <TextField
                                                    disabled
                                                        id="allowanceValue"                                                       
                                                        variant="outlined"
                                                        sx={{ width: '25%' }}
                                                        value = {'%'}
                                                    />
                                                    )}                                                   
                                                </Stack>
                                            </Grid>
                                            {!element.isAllowanceType ? (
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        sx={{ width: '100%' }}
                                                        value={dummy?.find((option: any) => option.label == element?.dependentOn) ?? null}
                                                        // value={element[index]?.dependentOn}
                                                        options={dummy}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, value) => {
                                                            let newFormValues = [...allowanceFormValue];
                                                            newFormValues[index].dependentOn = value?.label;
                                                            setAllowanceFormValue(newFormValues);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Dependent On"
                                                                variant="outlined"
                                                                fullWidth
                                                                name={`allowances.${index}.dependentOn`}
                                                                value={element[index]?.dependentOn || null}
                                                                onBlur={handleBlur}
                                                                sx={{ mb: '24px' }}
                                                                error={Boolean(
                                                                    getIn(touched, `allowances.${index}.dependentOn`) &&
                                                                    getIn(errors, `allowances.${index}.dependentOn`)
                                                                )}
                                                                helperText={
                                                                    getIn(touched, `allowances.${index}.dependentOn`) &&
                                                                    getIn(errors, `allowances.${index}.dependentOn`)
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Stack direction="row" justifyContent="flex-end">
                                            {index ? (
                                                <Button
                                                    endIcon={<Icons.Delete />}
                                                    color="error"
                                                    onClick={() => removeFormFields(index)}
                                                ></Button>
                                            ) : null}
                                        </Stack>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>

                        <Box sx={{ my: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => addFormFields()}
                            >
                                Add More
                            </Button>
                        </Box>


                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </form>
            )}
        </Formik>
    );
}