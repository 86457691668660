import * as React from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "../../theme/theme";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import * as Yup from "yup";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TablePagination,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import printicon from "../../assests/icons/print-icon.svg";
import QRicon from "../../assests/images/qr-image.svg";
import { toast } from "material-react-toastify";
import { inventoryService } from "../../service/Inventory/inventoryService";
import { useEffect } from "react";
import { Formik, getIn, validateYupSchema } from "formik";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { apiUrl } from "../../service/common-services/apiURL";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Keyboard from "../assests/images/keyboard-view-item.png";
import Qrcode from "../../assests/images/qr-code-img.png";
import { StyleRounded } from "@mui/icons-material";
import { height } from "@mui/system";
import NotFound from "../../components/styledComponent/NotFound";
import LongText from "../../components/longtext";
import { DatePicker } from "react-rainbow-components";
import getDate from "../../components/GetDateWithSimpletime";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const styleCross = {
  position: "absolute" as "absolute",
  right: "12px",
  top: "16px",
};
const style = {
  Configuration: {
    width: "50%",
    ":nth-child(odd)": {
      borderRight: "1px solid #EEE",
      borderBottom: "1px solid #EEE",
    },
    ":nth-child(even)": {
      borderBottom: "1px solid #EEE",
    },
    ":nth-last-child(1)": {
      borderBottom: "0px solid transparent",
    },
  },
};

const viewitem = {
  Devicename: {
    fontWeight: 400,
    fontSize: "16px",
  },
  Subheading: {
    fontSize: "20px",
    fontWeight: 500,
    padding: "15px 0",
  },
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface MaintenanceData {
  purchaseDate: string;
  description: string;
  performedBy: string;
  validationDate: string;
  validationBy: string;
  nextMaintenanceDate: string;
  remark: string;
}
interface IssueData {
  srNo: number;
  issueDate: string;
  empId: string;
  name: string;
  department: string;
  commentInstruction: string;
  status: string;
  returnedOn: string;
  comment: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InventoryViewItems() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const [openView, setOpenView] = React.useState(false);
  const [openViewQR, setOpenViewQR] = React.useState(false);
  const handleCloseView = () => {
    setOpenView(false);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [dateOfMaintenance, setDateOfMaintenance] = React.useState<any>(null);
  const [maintenanceDescription, setMaintenanceDescription] =
    React.useState("");
  const [overViewList, setOverViewList] = React.useState<any | null>(null);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [maintenancePerformedBy, setMaintenancePerformedBy] =
    React.useState("");
  const [validationPerformedBy, setValidationPerformedBy] = React.useState("");
  const [dateOfValidation, setDateOfValidation] = React.useState<any>(null);
  const [nextMaintenanceDate, setNextMaintenanceDate] =
    React.useState<any>(null);
  const [remark, setRemark] = React.useState("");
  const navigate = useNavigate();
  const { itemid } = useParams();

  function dateDiff(startingDate: any, endingDate: any) {
    if (
      startingDate == "" ||
      startingDate == undefined ||
      startingDate == null ||
      startingDate == "string" ||
      endingDate == "" ||
      endingDate == undefined ||
      endingDate == "string" ||
      endingDate == null
    ) {
      return "N/A";
    } else {
      let sdate: any = moment(startingDate).format("YYYY-MM-DD");
      let edate: any = moment(endingDate).format("YYYY-MM-DD");

      let startDate = new Date(new Date(sdate).toISOString());
      if (!edate) {
        edate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
      }
      let endDate = new Date(edate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }
      let dayDiff = endDate.getDate() - startDate.getDate();
      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }
      console.log(yearDiff, "yearDiff");
      console.log(monthDiff, "monthDiff");
      console.log(dayDiff, "dayDiff");

      // if (yearDiff !== 0 ) {
      //     return yearDiff + "Y";
      // } else if (monthDiff !== 0) {
      //     return monthDiff + "M";
      // } else {
      //     return dayDiff + "D";
      // }
      let result = "";
      if (yearDiff !== 0) {
        result += yearDiff + "Y ";
      }
      if (monthDiff !== 0) {
        result += monthDiff + "M ";
      }
      if (dayDiff !== 0) {
        result += dayDiff + "D";
      }
      return result.trim();
    }
  }

  const [deviceHistory, setDeviceHistory] = React.useState<any>([]);
  const getDeviceHistory = async (id: any) => {
    try {
      let abc = {
        itemId: id,
      };
      await inventoryService.getDeviceHistory(abc).then((response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong while fetching manager data ...");
        } else {
          setDeviceHistory(response?.data);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  useEffect(() => {
    getOverviewListById();
  }, []);

  const getOverviewListById = async () => {
    try {
      await inventoryService
        .getOverviewListById(itemid)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong while fetching manager data ...");
          } else {
            setOverViewList(response?.data);
            setqrcodeHook(response?.data?.qrCode);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const [maintenancebyId, setMaintenancebyId] = React.useState<any>([]);
  const getMaintenanceDetailsId = async (id: any) => {
    await inventoryService.getMaintenanceDetailsId(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setMaintenancebyId(response?.data);
      }
    });
  };
  const [showHelpText, setshowHelpText] = React.useState(false);

  const addmaintenace = async (values: any) => {
    await inventoryService
      .createmaintenance(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Maintenance added successfully.");
          setOpenView(false);
          getMaintenanceDetailsId(itemid);
          setDateOfMaintenance(null);
          setDateOfValidation(null);
          setNextMaintenanceDate(null);
          setMaintenancePerformedBy("");
          setMaintenanceDescription("");
          setValidationPerformedBy("");
          setRemark("");
        }
      })
      .catch((error) => {
        if (error.data.Error.length > 0) toast.error(error.data.Error[0]);

        return error;
      });
  };
  const [qrcodeHook, setqrcodeHook] = React.useState<any>("");
  // const QRcode = async () => {
  //     await inventoryService.getQR(itemid).then((response: any) => {
  //         if (response.data.isError) {

  //         }
  //         else {
  //             setqrcodeHook(response?.data)
  //             // toast.success('QRcode successfully.');
  //         }
  //     })
  //         .catch((error) => {
  //             if (error.data.Error.length > 0)
  //                 toast.error(error.data.Error[0],
  //                 );

  //             return error;
  //         });
  // }

  const [allMaintenanceDetails, setAllMaintenanceDetails] = React.useState<any>(
    []
  );
  const getallmaintenanceDetails = async () => {
    try {
      await inventoryService.getallmaintenance().then((response: any) => {
        if (response.data.isError) {
        } else {
          setAllMaintenanceDetails(response?.data);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const fieldValidationSchema = Yup.object().shape({
    dateOfMaintenance: Yup.string()
      .required("Maintenance Date is Required")
      .nullable(),
    maintenanceDescription: Yup.string().required("Desciption is Required"),
    maintenancePerformedBy: Yup.string().required(
      "Maintenance Performed by is Required"
    ),
    validationPerformedBy: Yup.string().required(
      "Validation Performed by is Required"
    ),
    dateOfValidation: Yup.string()
      .required("Validation Date is Required")
      .nullable(),
    nextMaintenanceDate: Yup.string()
      .required("Next Maintenance is Required")
      .nullable(),
    remark: Yup.string().required(" Remarks is required"),
  });
  ////////Pagination Programming
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);

  const pageCount1 = Math.ceil(deviceHistory.length / rowsPerPage1);
  const pageCount = Math.ceil(maintenancebyId.length / rowsPerPage);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  ////////over
  const [showAll, setShowAll] = React.useState(false);
  const itemsToShow = showAll ? overViewList?.setConfiguration?.length : 6; // Change 5 to the desired initial number of items to show

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1", height: "auto" }}>
        <Typography sx={{ padding: "0 0 15px 0" }}>
          {"Items>view item"}
        </Typography>

        <Paper sx={{ padding: "24px 16px", height: "auto" }} elevation={0}>
          <Box>
            <p
              style={{
                textDecoration: "underline",
                color: "#00a1e4",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            >
              {"Back to Previous Page"}
            </p>
          </Box>

          <Typography
            variant="h2"
            sx={{ fontSize: "24px", fontWeight: "500" }}
            color={"#232360"}
            marginY={"18px"}
            component="h2"
          >
            Overview
          </Typography>
          <Box sx={{ borderBottom: "1px solid #EEE" }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              paddingBottom={"12px"}
            >
              <Typography
                variant="h6"
                sx={viewitem.Devicename}
                color={"#232360"}
                component="h6"
              >
                {overViewList?.itemName || "N/A"}
              </Typography>
              <Stack
                direction={"row"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <Typography
                  variant="h6"
                  color={"#1DA7FF"}
                  sx={viewitem.Devicename}
                  paddingRight={"45px"}
                  component="h6"
                >
                  Model Name: {overViewList?.modelName || "--"}
                </Typography>
                <Typography
                  variant="h6"
                  color={"#1DA7FF"}
                  sx={viewitem.Devicename}
                  paddingRight={"40px"}
                  component="h6"
                >
                  Category: {overViewList?.category || "--"}
                </Typography>
                <Typography
                  variant="h6"
                  color={"#1DA7FF"}
                  sx={viewitem.Devicename}
                  paddingRight={"45px"}
                  component="h6"
                >
                  SKU: {overViewList?.sku || "--"}
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenViewQR(true);
                  }}
                >
                  QR Code
                </Button>
              </Stack>
            </Stack>
            <Typography sx={{ mb: "12px" }}>
              {overViewList?.description
                ? `Description:-  ${overViewList?.description}`
                : ""}
            </Typography>
            {/* <Typography sx={{mb:"12px"}}>{overViewList?.modelName ? `Model Name:-  ${overViewList?.modelName}` : ""}</Typography> */}
          </Box>

          <Typography
            variant="h2"
            color={"#232360"}
            sx={viewitem.Subheading}
            component="h2"
          >
            Configuration
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={8}>
              <Box
                width={"100%"}
                sx={{ borderRadius: "15px", border: "1px solid #EEE" }}
              >
                <Stack direction={"row"} flexWrap={"wrap"}>
                  <Box sx={style.Configuration}>
                    <Stack direction={"row"}>
                      <Typography
                        variant="h6"
                        color={"#232360"}
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "42px",
                          padding: "12px 0px 8px 16px",
                        }}
                        component="h6"
                      >
                        Brand:
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#757575"}
                        sx={{
                          fontWeight: "400",
                          fontSize: "18px",
                          paddingLeft: "18px",
                          lineHeight: "42px",
                          padding: "12px 10px 8px 16px",
                        }}
                        component="h6"
                      >
                        {overViewList?.brand
                          ?.filter((q: any) => !!q.brandName)
                          .map((a: any, index: number) => (
                            <React.Fragment key={a.brandName}>
                              <Box
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  color: "#757575",
                                }}
                              >
                                {a?.brandName}
                              </Box>
                              {index <
                                overViewList.brand.filter(
                                  (q: any) => !!q.brandName
                                ).length -
                                  1 && ", "}
                            </React.Fragment>
                          ))}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={style.Configuration}>
                    <Stack direction={"row"}>
                      <Typography
                        variant="h6"
                        color={"#232360"}
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          lineHeight: "42px",
                          padding: "12px 0px 8px 16px",
                        }}
                        component="h6"
                      >
                        SKU:
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#757575"}
                        sx={{
                          fontWeight: "400",
                          fontSize: "18px",
                          paddingLeft: "18px",
                          lineHeight: "42px",
                          padding: "12px 10px 8px 16px",
                        }}
                        component="h6"
                      >
                        {overViewList?.sku || "--"}
                      </Typography>
                    </Stack>
                  </Box>
                  {overViewList?.setConfiguration
                    ?.slice(0, itemsToShow)
                    ?.map((item: any, index: any) => (
                      // Render your items here as before
                      <Box sx={style.Configuration}>
                        <Stack
                          direction={"row"}
                          sx={{ borderBottom: "1px solid #EEE" }}
                        >
                          <Typography
                            variant="h6"
                            color={"#232360"}
                            sx={{
                              fontWeight: "500",
                              fontSize: "18px",
                              lineHeight: "42px",
                              padding: "12px 0px 8px 16px",
                            }}
                            component="h6"
                          >
                            {item?.attribute}
                          </Typography>
                          {item?.value?.map((item1: any, index: any) => (
                            <Typography
                              variant="h6"
                              color={"#757575"}
                              sx={{
                                fontWeight: "400",
                                fontSize: "18px",
                                paddingLeft: "18px",
                                lineHeight: "42px",
                                padding: "12px 10px 8px 16px",
                              }}
                              component="h6"
                            >
                              {item1}
                            </Typography>
                          ))}
                        </Stack>
                      </Box>
                    ))}
                </Stack>
              </Box>
              {overViewList?.setConfiguration?.length > 6 && (
                <p
                  style={{
                    color: "#00a1e4",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? "Show Less" : "Show More"}
                </p>
              )}
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box
                sx={{
                  borderRadius: "15px",
                  border: "1px solid #EEE",
                  padding: "28px",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: "auto",
                    flexGrow: 1,
                    "& .css-zyvc0i-MuiPaper-root-MuiMobileStepper-root": {
                      background: "none",
                    },
                  }}
                >
                  <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {overViewList?.upload?.map((step: any, index: any) => (
                      <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          <Box
                            onClick={handleClickOpen}
                            component="img"
                            sx={{
                              height: 255,
                              display: "block",
                              maxWidth: 400,
                              overflow: "hidden",
                              width: "100%",
                            }}
                            src={apiUrl.getImageURL() + "/" + step}
                            // alt={step.label}
                          />
                        ) : null}
                      </div>
                    ))}
                  </AutoPlaySwipeableViews>
                  <MobileStepper
                    steps={overViewList?.upload?.length}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={
                          activeStep === overViewList?.upload?.length - 1
                        }
                      >
                        Next
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          sx={{ padding: "16px 0", height: "auto", marginTop: "25px" }}
          elevation={0}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              aria-label="basic tabs example"
              sx={{ borderBottom: "1px solid #EEEEEE" }}
            >
              <Tab label="Purchase & Warranty" {...a11yProps(0)} />
              <Tab
                label=" Maintenance"
                {...a11yProps(1)}
                onClick={() => {
                  setPage(0);
                  getMaintenanceDetailsId(itemid);
                }}
              />
              <Tab
                label=" Device History"
                {...a11yProps(2)}
                onClick={() => {
                  setPage(0);
                  getDeviceHistory(itemid);
                }}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Grid
                container
                spacing={{ xs: 2, lg: 0 }}
                sx={{ "& .css-19kzrtu": { padding: "16px" } }}
              >
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      marginRight: { xs: "0px", lg: "30px" },
                      border: "1px solid #EEEEEE",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    height={"100%"}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "rgba(35, 35, 96, 1)" }}
                        component="h2"
                      >
                        Purchase Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Stack direction={"row"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Seller
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                          }}
                        >
                          {overViewList?.seller || "N/A"}
                        </Box>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Stack direction={"row"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Date Purchased
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                          }}
                        >
                          {overViewList?.dateOFPurchase
                            ? moment(overViewList.dateOFPurchase).format(
                                "DD.MM.YYYY"
                              )
                            : "N/A"}
                        </Box>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Stack direction={"row"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Purchase Price
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                          }}
                        >
                          {overViewList?.costPrice || "--"}
                        </Box>
                      </Stack>
                    </Box>
                    <Box sx={{ padding: "12px 10px 9px 29px" }}>
                      <Stack direction={"row"} paddingBottom={"20px"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Comment
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                            wordBreak: "break-all"
                          }}
                        >
                          {overViewList?.purchaseComment || "--"}
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      marginLeft: { xs: "0px", lg: "30px" },
                      marginRight: { xs: "0px", lg: "35px" },
                      border: "1px solid #EEEEEE",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    height={"100%"}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "rgba(35, 35, 96, 1)" }}
                        component="h2"
                      >
                        Warranty Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Stack direction={"row"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Warranty
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                          }}
                        >
                          {overViewList?.isApplicable == true
                            ? "Non-Applicable"
                            : "Applicable"}
                        </Box>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "1px solid #EEEEEE",
                        padding: "12px 10px 9px 29px",
                      }}
                    >
                      <Stack direction={"row"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Status
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                          }}
                        >
                          {overViewList?.isApplicable == true
                            ? "Out of Warranty"
                            : "Under Warranty"}
                        </Box>
                      </Stack>
                    </Box>
                    <Box sx={{ borderBottom: "1px solid #EEEEEE" }}>
                      <Stack direction={"row"}>
                        <Stack
                          direction={"row"}
                          width={"35%"}
                          borderRight={"1px solid #EEEEEE"}
                          sx={{ padding: "12px 10px 9px 29px", flexWrap: "wrap" }}
                        >
                          <Box
                            width={"140px"}
                            sx={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#232360",
                              paddingRight: "18px",
                            }}
                          >
                            Warranty
                          </Box>
                          <Box
                            sx={{
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#757575",
                            }}
                          >
                            {dateDiff(
                              overViewList?.startDate,
                              overViewList?.endDate
                            )}
                          </Box>
                        </Stack>
                        <Stack
                          direction={"row"}
                          width={"65%"}
                          sx={{ padding: "12px 10px 9px 29px" }}
                        >
                          <Box
                            width={"200px"}
                            sx={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#232360",
                              paddingRight: "18px",
                            }}
                          >
                            Warranty Duration
                          </Box>
                          {/* {overViewList?.dateOFPurchase ? moment(overViewList.dateOFPurchase).format('DD.MM.YYYY') : "N/A"} */}
                          <Box
                            sx={{
                              fontSize: "18px",
                              fontWeight: "400",
                              color: "#757575",
                            }}
                          >
                            {overViewList?.startDate != null &&
                            overViewList?.endDate != null
                              ? `${moment(overViewList.startDate).format(
                                  "MM/DD/YYYY"
                                )} to ${moment(overViewList.endDate).format(
                                  "MM/DD/YYYY"
                                )}`
                              : "N/A"}
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box sx={{ padding: "12px 10px 9px 29px" }}>
                      <Stack direction={"row"} paddingBottom={"20px"}>
                        <Box
                          width={"160px"}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#232360",
                            paddingRight: "18px",
                          }}
                        >
                          Comment
                        </Box>
                        <Box
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#757575",
                          }}
                        >
                          {overViewList?.warrantyComment || "--"}
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Box>
                <Box marginBottom={"16px"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenView(true);
                    }}
                    startIcon={<AddIcon />}
                  >
                    Maintenance
                  </Button>
                </Box>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ maxHeight: 400 }}
                >
                  {maintenancebyId && maintenancebyId.length > 0 ? (
                    <Table
                      sx={{ minWidth: 1500 }}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              verticalAlign: "top",
                            }}
                          >
                            Maintenance Date{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              whiteSpace: "break-spaces",
                              verticalAlign: "top",
                            }}
                          >
                            Maintenance Description
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              whiteSpace: "break-spaces",
                              verticalAlign: "top",
                            }}
                          >
                            Maintenance Performed By
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              whiteSpace: "break-spaces",
                              verticalAlign: "top",
                            }}
                          >
                            Date of Validation Before put into service{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              verticalAlign: "top",
                            }}
                          >
                            Validation Performed By
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              whiteSpace: "break-spaces",
                              verticalAlign: "top",
                            }}
                          >
                            Next Maintenance planned on (Date)
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "500",
                              border: "none",
                              verticalAlign: "top",
                            }}
                          >
                            Remark
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <tbody>
                        {maintenancebyId
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item: any, index: any) => (
                            <TableRow key={index}>
                              <TableCell>{`${moment(
                                item.dateOfMaintenance
                              ).format("DD.MMM.YYYY")}`}</TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    width: "150px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {item.maintenanceDescription}
                                </Box>
                              </TableCell>
                              <TableCell>
                                {item.maintenancePerformedBy}
                              </TableCell>
                              <TableCell>{`${moment(
                                item.dateOfValidation
                              ).format("DD.MMM.YYYY")}`}</TableCell>
                              <TableCell>
                                {item.validationPerformedBy}
                              </TableCell>
                              <TableCell>{`${moment(
                                item.nextMaintenanceDate
                              ).format("DD.MMM.YYYY")}`}</TableCell>
                              <TableCell>
                              <Box
                                  sx={{
                                    width: "150px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                {item.remark}
                                </Box>
                               </TableCell>
                            </TableRow>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <NotFound NotfoundText="No Result Found" />
                    </div>
                  )}
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25, 30]}
                  component="div"
                  count={maintenancebyId.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={() =>
                    `Total: ${maintenancebyId?.length}  Page ${
                      page + 1
                    } of ${pageCount}`
                  }
                />
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{ maxHeight: 400 }}
              >
                <Table
                  sx={{ minWidth: 1500 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          verticalAlign: "top",
                        }}
                      >
                        Sr. No{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          whiteSpace: "break-spaces",
                          verticalAlign: "top",
                        }}
                      >
                        Assigned On
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          whiteSpace: "break-spaces",
                          verticalAlign: "top",
                        }}
                      >
                        Emp ID
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          whiteSpace: "break-spaces",
                          verticalAlign: "top",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          verticalAlign: "top",
                        }}
                      >
                        Department
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          whiteSpace: "break-spaces",
                          verticalAlign: "top",
                        }}
                      >
                        IT Comment
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          verticalAlign: "top",
                        }}
                      >
                        Status{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          verticalAlign: "top",
                        }}
                      >
                        Returned On{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          border: "none",
                          verticalAlign: "top",
                        }}
                      >
                        End User Comment{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <tbody>
                    {deviceHistory?.length > 0 &&
                      deviceHistory
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: any) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {item?.issueDate
                                ? moment(item?.issueDate).format("DD MMM, YYYY")
                                : "--"}
                            </TableCell>
                            <TableCell>{item.empId}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.department}</TableCell>
                            <TableCell>
                              {item?.itComment ? (
                                <LongText content={item.itComment} limit={20} />
                              ) : (
                                "--"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.status == "1"
                                ? "InStock"
                                : item.status == "2"
                                ? "Assigned "
                                : "ReturnRequest"}
                            </TableCell>
                            <TableCell>
                              {item?.returnedOn
                                ? moment(item?.returnedOn).format(
                                    "DD MMM, YYYY"
                                  )
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {item?.userComment ? (
                                <LongText
                                  content={item.userComment}
                                  limit={20}
                                />
                              ) : (
                                "--"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                  </tbody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={deviceHistory.length}
                rowsPerPage={rowsPerPage1}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() =>
                  `Total: ${deviceHistory?.length}  Page ${
                    page + 1
                  } of ${pageCount1}`
                }
              />
            </CustomTabPanel>
          </Box>
        </Paper>
      </Box>

      <Dialog
        open={openView}
        onClose={Button}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        scroll="body"
        sx={{
          "& .css-23vkr6-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "763px",
            maxHeight: "85%",
          },
        }}
      >
        <Formik
          initialValues={{
            itemId: itemid,
            dateOfMaintenance: dateOfMaintenance,
            maintenanceDescription: maintenanceDescription,
            maintenancePerformedBy: maintenancePerformedBy,
            validationPerformedBy: validationPerformedBy,
            dateOfValidation: dateOfValidation,
            nextMaintenanceDate: nextMaintenanceDate,
            remark: remark,
          }}
          validationSchema={fieldValidationSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (
                values.dateOfValidation &&
                values.dateOfMaintenance &&
                values?.dateOfValidation < values?.dateOfMaintenance
              ) {
                toast.warn(
                  "Date of Validation cannot be less than date of maintenance"
                );
              } else {
                await addmaintenace(values);
              }
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    toast.warn(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={{ padding: "28px 36px 30px 36px" }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ position: "relative", overflow: "hidden" }}
                  >
                    <Typography sx={{ fontSize: "26px", fontWeight: 600 }}>
                      {" "}
                      Maintenance Details
                    </Typography>

                    <IconButton
                      aria-label="delete"
                      //sx={styleCross}
                      onClick={handleCloseView}
                      sx={{
                        marginLeft: "auto",
                        position: "absolute",
                        top: "0",
                        right: "0",
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                </DialogTitle>

                <DialogContent sx={{ padding: "10px 40px 30px 40px" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      flexGrow: 1,
                      margin: "0px",
                      maxWidth: "100%",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid xs={5}>
                      <Box margin={"10px 0px"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            // disableFuture
                            placeholder="Date of Maintenance"
                            borderRadius="semi-square"
                            label="Date of Maintenance"
                            labelAlignment="left"
                            // inputFormat="DD-MMM-YYYY"
                            // openTo="year"
                            className="error"
                            maxDate={dayjs().toDate()}
                            // views={["year", "month", "day"]}
                            value={values?.dateOfMaintenance}
                            onChange={(newValue: any) => {
                              let abc = getDate(newValue);
                              setDateOfMaintenance(abc);
                              values.dateOfMaintenance = abc;

                              setshowHelpText(false);
                            }}
                            error={
                              showHelpText && !values.dateOfMaintenance
                                ? "Maintenance Date is Required"
                                : ""
                            }
                            // renderInput={(params: any) => (
                            //     <TextField
                            //         onKeyDown={(e: any) => {
                            //             e.preventDefault();
                            //         }}
                            //         {...params}
                            //         autoComplete="off" // Disable suggestions
                            //         readOnly={true} // Disable user input
                            //         onBlur={handleBlur}
                            //         value={values?.dateOfMaintenance}
                            //         // name="purchaseDate"
                            //         // id="outlined-basic"
                            //         error={Boolean(
                            //             getIn(touched, "dateOfMaintenance") && getIn(errors, "dateOfMaintenance")
                            //         )}
                            //         helperText={
                            //             getIn(touched, "dateOfMaintenance") && getIn(errors, "dateOfMaintenance")
                            //         }
                            //         fullWidth

                            //         sx={{

                            //             maxWidth: 'auto',
                            //             " & .css-9l522a-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                            //                 color: '#757575'
                            //             },
                            //             "& .MuiSvgIcon-root": {
                            //                 fill: theme.palette.primary.main,
                            //             },
                            //             "& .css-1ig1lwk-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                            //                 borderColor: '#EEE',
                            //                 color: '#757575'
                            //             }
                            //         }}
                            //     />
                            // )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid xs={12}>
                      <Box padding={"10px 0px"}>
                        <TextField
                          minRows={3}
                          sx={{
                            "& .css-r2i9ug-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16px 10px",
                              },
                            "& .css-1s3khu2-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                lineHeight: "18px",
                              },
                          }}
                          variant="outlined"
                          name="Description"
                          label="Maintenance Description"
                          multiline
                          rows={5}
                          className="error"
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          value={values.maintenanceDescription}
                          error={Boolean(
                            getIn(touched, "maintenanceDescription") &&
                              getIn(errors, "maintenanceDescription")
                          )}
                          helperText={
                            getIn(touched, "maintenanceDescription") &&
                            getIn(errors, "maintenanceDescription")
                          }
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            values.maintenanceDescription = value;
                            setMaintenanceDescription(value);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box padding={"10px 10px 10px 0px"} sx={{ mt: "-1px" }}>
                        <TextField
                          sx={{
                            "& .css-r2i9ug-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16px 10px",
                              },
                            "& .css-2tm4o7-MuiFormLabel-root-MuiInputLabel-root":
                              {
                                mt: 1,
                              },
                          }}
                          variant="outlined"
                          label=" Maintenance performed by"
                          name="Mainperformed"
                          id={"mainperformed"}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          value={values.maintenancePerformedBy}
                          error={Boolean(
                            getIn(touched, "maintenancePerformedBy") &&
                              getIn(errors, "maintenancePerformedBy")
                          )}
                          helperText={
                            getIn(touched, "maintenancePerformedBy") &&
                            getIn(errors, "maintenancePerformedBy")
                          }
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            values.maintenancePerformedBy = value;
                            setMaintenancePerformedBy(value);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box padding={"10px 0px 10px 10px"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="Date of Validation"
                            borderRadius="semi-square"
                            label="Date of Validation"
                            labelAlignment="left"
                            // inputFormat="DD-MMM-YYYY"
                            // openTo="year"
                            className="error"
                            maxDate={dayjs().toDate()}
                            // views={["year", "month", "day"]}
                            value={values?.dateOfValidation}
                            onChange={(newValue: any) => {
                              let a = getDate(newValue);
                              setDateOfValidation(a);
                              values.dateOfValidation = a;
                              setshowHelpText(false);
                            }}
                            error={
                              showHelpText && !values.dateOfValidation
                                ? "Validation Date is Required"
                                : ""
                            }
                            // renderInput={(params: any) => (
                            //     <TextField

                            //         onKeyDown={(e: any) => {
                            //             e.preventDefault();
                            //         }}
                            //         {...params}
                            //         autoComplete="off" // Disable suggestions
                            //         readOnly={true} // Disable user input
                            //         error={Boolean(
                            //             getIn(touched, "dateOfValidation") && getIn(errors, "dateOfValidation")
                            //         )}
                            //         helperText={
                            //             getIn(touched, "dateOfValidation") && getIn(errors, "dateOfValidation")
                            //         }
                            //         fullWidth
                            //         sx={{
                            //             marginLeft: '0',
                            //             maxWidth: 'auto',
                            //             " & .css-9l522a-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                            //                 color: '#757575'
                            //             },
                            //             "& .MuiSvgIcon-root": {
                            //                 fill: theme.palette.primary.main,
                            //             },
                            //             "& .css-1ig1lwk-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                            //                 borderColor: '#EEE',
                            //                 color: '#757575'
                            //             }
                            //         }}
                            //     />
                            // )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box padding={"10px 10px 10px 0"}>
                        <TextField
                          sx={{
                            "& .css-r2i9ug-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16px 10px",
                              },

                            "& .css-2tm4o7-MuiFormLabel-root-MuiInputLabel-root":
                              {
                                mt: 1,
                              },
                          }}
                          variant="outlined"
                          name="validperformed"
                          label="Validation performed by"
                          id={"validperformed"}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          value={values.validationPerformedBy}
                          error={Boolean(
                            getIn(touched, "validationPerformedBy") &&
                              getIn(errors, "validationPerformedBy")
                          )}
                          helperText={
                            getIn(touched, "validationPerformedBy") &&
                            getIn(errors, "validationPerformedBy")
                          }
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            values.validationPerformedBy = value;
                            setValidationPerformedBy(value);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6}>
                      <Box padding={"10px 0px 10px 10px"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="Next Maintenance date"
                            borderRadius="semi-square"
                            label="Next Maintenance date"
                            labelAlignment="left"
                            // inputFormat="DD-MMM-YYYY"
                            // openTo="year"
                            className="error"
                            minDate={dayjs().toDate()}
                            // views={["year", "month", "day"]}
                            value={values?.nextMaintenanceDate}
                            onChange={(newValue: any) => {
                              let b = getDate(newValue);
                              setNextMaintenanceDate(b);
                              values.nextMaintenanceDate = b;
                              setshowHelpText(false);
                            }}
                            error={
                              showHelpText && !values.nextMaintenanceDate
                                ? "Next Maintenance is Required"
                                : ""
                            }
                            // renderInput={(params: any) => (
                            //     <TextField
                            //         onKeyDown={(e: any) => {
                            //             e.preventDefault();
                            //         }}
                            //         {...params}
                            //         autoComplete="off" // Disable suggestions
                            //         readOnly={true} // Disable user input
                            //         error={Boolean(
                            //             getIn(touched, "nextMaintenanceDate") && getIn(errors, "nextMaintenanceDate")
                            //         )}
                            //         helperText={
                            //             getIn(touched, "nextMaintenanceDate") && getIn(errors, "nextMaintenanceDate")
                            //         }
                            //         fullWidth
                            //         sx={{
                            //             marginLeft: '0',
                            //             maxWidth: 'auto',
                            //             " & .css-9l522a-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                            //                 color: '#757575'
                            //             },
                            //             "& .MuiSvgIcon-root": {
                            //                 fill: theme.palette.primary.main,
                            //             },
                            //             "& .css-1ig1lwk-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                            //                 borderColor: '#EEE',
                            //                 color: '#757575'
                            //             }
                            //         }}
                            //     />
                            // )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid xs={12}>
                      <Box padding={"10px 0px"}>
                        <TextField
                          minRows={3}
                          variant="outlined"
                          name="Remark"
                          label="Remark"
                          multiline
                          rows={5}
                          size="small"
                          onBlur={handleBlur}
                          fullWidth
                          value={values.remark}
                          error={Boolean(
                            getIn(touched, "remark") && getIn(errors, "remark")
                          )}
                          helperText={
                            getIn(touched, "remark") && getIn(errors, "remark")
                          }
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            values.remark = value;
                            setRemark(value);
                          }}
                          sx={{
                            "& .css-r2i9ug-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16px 10px",
                                minHeight: "90px",
                              },

                            "& .css-1s3khu2-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                lineHeight: "18px",
                              },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions sx={{ padding: "28px 40px 30px 40px" }}>
                  <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      setshowHelpText(
                        !dateOfMaintenance ||
                          !dateOfValidation ||
                          !nextMaintenanceDate
                      );
                    }}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={openViewQR}
        onClose={Button}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        scroll="body"
        sx={{
          "& .css-23vkr6-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "763px",
            maxHeight: "85%",
            padding: "28px 36px",
          },
        }}
      >
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography sx={{ fontSize: "26px", fontWeight: 600 }}>
              {" "}
              QR Code
            </Typography>
            <IconButton
              aria-label="delete"
              //sx={styleCross}
              onClick={() => {
                setOpenViewQR(false);
              }}
              sx={{
                marginLeft: "auto",
                position: "absolute",
                top: "0",
                right: "0",
              }}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ width: "50%" }}>
              <img id="qrcode" src={qrcodeHook} alt="qr-image" />
            </Box>
          </Stack>
          <Typography
            color={"#1DA7FF"}
            sx={{ "margin-left": "305px ", "margin-bottom": "18px" }}
          >
            {overViewList?.sku ? `SKU-${overViewList?.sku}` : "--"}
          </Typography>

          <Box sx={{ width: "60%" }}>
            <div
              onClick={() => {
                let w = window.open();
                w?.document.write(
                  document.getElementById("qrcode")?.parentElement?.innerHTML ||
                    ""
                );
                w?.print();
                w?.close();
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  <Button variant="outlined">
                    <img src={printicon} alt="print-icon" />
                    <Typography
                      sx={{ marginLeft: "auto", paddingLeft: "15px" }}
                    >
                      Print QR Code
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </div>
          </Box>
        </>
      </Dialog>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <IconButton aria-label="delete" sx={styleCross} onClick={handleClose}>
          <ClearIcon />
        </IconButton>
        <DialogContent
          sx={{
            pt: 5,
            "& img": {
              maxWidth: "100%",
              height: "100%",
            },
          }}
        >
          <Box
            sx={{
              width: "auto",
              flexGrow: 1,
              "& .css-zyvc0i-MuiPaper-root-MuiMobileStepper-root": {
                background: "none",
              },
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {overViewList?.upload?.map((step: any, index: any) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        margin: "0 auto",
                        height: 255,
                        display: "block",
                        maxWidth: 400,
                        overflow: "hidden",
                        width: "auto",
                      }}
                      src={apiUrl.getImageURL() + "/" + step}
                      // alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={overViewList?.upload?.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === overViewList?.upload?.length - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
