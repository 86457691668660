import { http } from "./http-common";

class QaReportsServices {

    getQAReportsData = async (data:any): Promise<any> => {
        return await http.post<any>("/v1/Reports/GetQASprintReport", data);
    }; 

    getActiveProjectsOnBaseOfUserIdAndDepId = async (data:any): Promise<any> => {
        return await http.post<any>( `/v1/Master/GetAllProjectByDepartmentIdAndUserId?DepartmentId=${data.departId}&UserId=${data.userId}`);
    }; 
   
    getSprintDropdownData = async (data:any): Promise<any> => {
        return await http.post<any>("/v1/Reports/GetSprintByMultipleMilestone", data);
    }

}

export const qaReportService = new QaReportsServices();
