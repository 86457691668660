import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import theme from "../../theme/theme";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AppConstants from "../../config/AppConstants";
import {
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { leaveService } from "../../service/Leave/LeaveService";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as Yup from "yup";
import dayjs, { Dayjs } from "dayjs";
import { Department } from "../../Interfaces/IDepartment";
import { IResource } from "../../Interfaces/IResource";
import { clsDepartmentList } from "../../service/common-services/department";
import { clsResourcesList } from "../../service/common-services/resources";
import { useSelector } from "react-redux";
import { Autocomplete, Checkbox } from "@mui/material";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import moment from "moment";
import OutlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const styles = {
  MonthList: {
    "&.MuiListItemButton-root": {
      borderBottom: "1px solid #e1e1e1",

      "&:hover": {
        background: theme.palette.primary.light,
        "& .MuiTypography-root": {
          color: theme.palette.primary.main,
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "&.Mui-selected": {
      background: theme.palette.primary.light,
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "42px",
      opacity: 0.6,
    },
  },
  selectType: {
    backgroundColor: "#fff",
    "margin-top": "10px",
    // marginTop: '10px'
  },
};

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export default function AddLeaveRequest(props: any) {
  const { handleORClose } = props;

  let obj: Leave = {
    type: "",
    durationType: "",
    duration: "",
    startDate: "",
    endDate: "",
    reason: "",
    startTime: "",
    endTime: "",
    userId: "",
    compOffFor:"",
    isEmergencyLeave:true,
    isEarnedAdvance:false 
  };
  //const { leaveId, handleCloseAR, check } = props;
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [formValues, setFormValues] = React.useState<Leave>(obj);
  const [errorMessage, setErrorMessage] = useState("");
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [resources, setresources] = React.useState<IResource[]>([]);
  const [disableform, setState] = useState(3);
  const [durationType, setDurationType] = React.useState(
    formValues.durationType
  );
  // console.log("huuyhiyiy resources", resources);
  const [type, setType] = React.useState(formValues?.type);
  const [duration, setDuration] = React.useState("Short Leave");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [futureLeaveCheckBox, setFutureLeaveCheckBox] = useState(false);
  const [reason, setReason] = React.useState("");
  const [startDate, setStartDate] = React.useState<any>("");
  const [endDate, setEndDate] = React.useState<any>("");
  const [userId, setUser] = React.useState("");
  const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
  const [endTime, setEndTime] = React.useState<Dayjs | null>(null);

  const handleChangeLeaveType = (value: string) => {
    setFutureLeaveCheckBox(false)
    setType(value);
  };
  const [leaveCount, setLeaveCount] = useState<any>();
  const mindate = new Date().getFullYear().toString();

  const handleChangeUserID = (value: string) => {
    setUser(value);
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const handleDurationType = (value: string) => {
    setDurationType(value);
  };

  const handleCheckBoxChange = (value: boolean) => {
    setFutureLeaveCheckBox(prevValue => !prevValue);
};
  // function getDate(dateObject: any): string {
  //   if (!dateObject || !(dateObject instanceof Date) || isNaN(dateObject.getTime())) {
  //     return ''; 
  //   }
  //   return dateObject.getFullYear() + '-' + ('0' + (dateObject.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObject.getDate()).slice(-2);
  // }

  function getDate(dateObject: any): string {
    if (!dateObject || !(dateObject instanceof Date) || isNaN(dateObject.getTime())) {
      return '';
    }
    const year = dateObject.getFullYear();
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObject.getDate()).slice(-2);
    return year + '-' + month + '-' + day + 'T00:00:00Z';
  }


  const addleave = async (LeaveInfo: any) => {
    try {
      var starttimef = "";
      var endtimef = "";
      let obj: Leave = {
        durationType: durationType,
        type: type,
        duration: duration,
        startDate: startDate,
        endDate: endDate,
        reason: reason,
        startTime: starttimef,
        endTime: endtimef,
        userId: userId,
        compOffFor:"",
        isEmergencyLeave:true,
        isEarnedAdvance:false 
      };
      if (obj.type === "Comp Off Leave") {
        debugger
        obj.compOffFor = getDate(obj.startDate);
        obj.endDate = endDate;
        obj.startTime = "";
        obj.endTime = "";
        obj.startDate = endDate;
      }
      if (obj.duration === "Half Day") {
        let date = new Date();
        let strDate: string = date.toUTCString();
        obj.startDate = startDate;
        obj.endDate = startDate;
        obj.startTime = "";
        obj.endTime = "";
      }
      if (obj.duration === "Short Leave") {
        debugger
        let date = new Date();
        obj.type = "Casual Leaves";
        let strDate: string = date.toUTCString();
        obj.startDate = startDate;
        obj.endDate = startDate;
        obj.endTime = dayjs(endTime).format("hh:mm A");
        obj.startTime = dayjs(startTime).format("hh:mm A");
      }
     
      // if(type === "Marriage Leave"){
      //   obj.startDate = getDate(obj.startDate);}
      // } else{
      //   obj.startDate = getDate(obj.startDate);
      //   obj.endDate = getDate(obj.endDate);
      // }

      if (duration === "Full Day" && type === "Marriage Leave") {
        obj.startDate = getDate(obj.startDate);
    } else if (duration === "Short Leave" || duration === "Half Day") {
      obj.startDate = startDate;
      obj.endDate = startDate;
    } else {
      debugger
      obj.startDate = getDate(obj.startDate);
       obj.endDate = getDate(obj.endDate);
    }
                 
      let selectedDays;
      // if (duration === "Half Day" || duration === "Short Leave") {
      //     selectedDays = moment(startDate).add(1, 'days').diff(startDate, 'days');
      // } else {
      //     selectedDays = moment(endDate).add(1, 'days').diff(startDate, 'days');
      // }

      if (duration === "Half Day") {
        selectedDays = 0.5;
    } else if (duration === "Short Leave") {
        selectedDays = moment(startDate).add(1, 'days').diff(startDate, 'days');
    } else {
        selectedDays = moment(endDate).add(1, 'days').diff(startDate, 'days');
    }

      if (userId == leaveCount.userId) {
        if (type == 'Sick Leave' || type == 'Casual Leaves' || type == 'Earned Leave') {
          debugger
          if ((type == 'Sick Leave' || type == 'Casual Leaves') && selectedDays <= leaveCount?.leaveBalance ||
          (type === 'Earned Leave' && selectedDays <= leaveCount?.earnedLeaveBalance) || 
          (type === 'Earned Leave' && selectedDays > leaveCount?.earnedLeaveBalance && futureLeaveCheckBox))
            {
              if(type === 'Earned Leave' && selectedDays > leaveCount?.earnedLeaveBalance && futureLeaveCheckBox){
                obj.isEarnedAdvance = true
              }
              debugger
            await leaveService.addleave(obj).then(async (response: any) => {
              if (response.data.isError) {
                toast.error("Something went wrong...");
              } else {
                handleORClose();
                toast.success("Leave added successfully.", {
                  onOpen: () => setButtonDisable(true),
                  onClose: () => setButtonDisable(false),
                });
              }
            });
          }
          else {
            toast.warning("User don't have enough balance leaves to apply")
          }
        }
        else {
          await leaveService.addleave(obj).then(async (response: any) => {
            if (response.data.isError) {
              toast.error("Something went wrong...");
            } else {
              handleORClose();
              toast.success("Leave added successfully.", {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
              });
            }
          });
        }

      }
      else {
        toast.warning("Invalid User")
      }

    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        console.log("err sdvdssdgv", err);
        // toast.warning(err);
        toast.warning(err, {
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),
        });
      });
    }
  };




  interface Leave {
    type: string;
    durationType: string;
    duration: string;
    startDate: string;
    reason: string
    endDate: string;
    startTime: string;
    endTime: string;
    userId: string;
    compOffFor:string;
    isEmergencyLeave:boolean; 
    isEarnedAdvance:boolean 
  }

  const GetBalancedLeaveBasedOnUserID = async (id: any) => {
    await leaveService.getBalancedLeaveBasedOnUserID(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setLeaveCount(response.data);

        console.log("setLeaveCount===", response.data)
      }
    });
  };



  React.useEffect(() => {
    const GetAsyncData = async () => {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setresources(
        await clsResourcesList.getResourceData(
          _authUser.employeeInfo.departmentId ?? "All Resources"
        )
      );
    };
    GetAsyncData();

  }, []);

  const fieldValidationSchema = Yup.object().shape({
    reason: Yup.string().required("Reason is required").max(100, "Reason must be at most 100 characters"),
    userId: Yup.string().required("Employee is required"),
    durationType:
      duration == "Half Day" ? Yup.string().required("Duration half is required") : Yup.string(),
    startDate: Yup.string().required('Start date is required'),
    endDate: duration != "Short Leave" ? Yup.string().required('End date is required') : Yup.string(),
    startTime: duration === "Short Leave" ? Yup.string().required("Start Time is required") : Yup.string(),
    type: duration != "Short Leave" ? Yup.string().required('Leave type is Required') : Yup.string(),
  });

  const handleClearForAddLeave = () => {
    setStartDate("");
    setEndDate("");
    setReason("");
    setStartTime(null);
    setEndTime(null);
  };

  const handleFeildDisable = (value: any) => {
    handleClearForAddLeave();
    setshowHelpText(false);
    if (value === "Half Day") {
      setState(0);
      //setDurationType('2nd half')
      setDuration(value);
      var date = new Date();
      // setStartDate(date)
      //setStartDate(date)
    } else if (value === "Full Day") {
      setState(1);
      //setDurationType('1st half')
      setDuration(value);
    } else if (value === "Short Leave") {
      setState(3);
      //setDurationType('1st half')
      setDuration(value);
    }
  };
  const [showHelpText, setshowHelpText] = React.useState(false);



  return (
    <Formik
      initialValues={{
        durationType: "",
        type: "",
        duration: "",
        startDate: "",
        endDate: "",
        reason: "",
        startTime: "",
        userId: "",
      }}
      validationSchema={fieldValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (values.endDate < values.startDate) {

            if (values.type === "Comp Off Leave") {
              toast.warning("Worked on date must be less than Leave date", {
                onOpen: () => setButtonDisable(true),
  
                onClose: () => setButtonDisable(false),
              });
            }
            else {
              toast.warning("Start date must be less than end date", {
                onOpen: () => setButtonDisable(true),
  
                onClose: () => setButtonDisable(false),
              });
            }
            return false;
          }
          setErrorMessage("");
          if (values.userId === null || values.userId === "") {
            toast.warning("Please Select Employee");
          } else {
            await addleave(values);
          }

        } catch (err: any) {
          console.error(err);
          err = JSON.parse(JSON.stringify(err));
          if (err.status == 400) {
            {
              err.data.Error.map((error: any, idx: any) =>
                setErrorMessage(error)
              );
            }
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        handleReset,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">
                  Department
                </FormLabel> */}
                <TextField
                  id="departmentId"
                  label="Department"
                  name="departmentId"
                  InputLabelProps={{ shrink: true }}
                  value={
                    departments.find(
                      (a) =>
                        a.id == _authUser.employeeInfo.departmentId ??
                        "All Departments"
                    )?.name
                  }
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">
                  Employee*
                </FormLabel> */}

                {/* <TextField
                  label=" Employee"
                  //error={Boolean(touched.userId && errors.userId)}
                  // helperText={touched.userId && errors.userId}
                  name="userId"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="select-userId"
                  // label="Resource"
                  //value={values.userId}
                 
                  //  label="Resource"
                  // value={user}
                  variant="outlined"
                  select
                  fullWidth
                  error={Boolean(
                    getIn(touched, "userId") && getIn(errors, "userId")
                  )}
                  helperText={
                    getIn(touched, "userId") && getIn(errors, "userId")
                  }
                >
                  {resources.map((resource, index) => (
                    <MenuItem
                      key={resource.userId}
                      value={resource.userId}
                      onClick={(event) => {
                        handleChangeUserID(resource.userId);
                        {console.log("resource.userId",resource.userId)}
                        if (resource.userId === "") {
                          values.userId = "All Resources";
                        } else {
                          values.userId = resource.userId;
                        }
                      }}
                    >
                      {resource.userName ?? "All Resources"}
                    </MenuItem>
                  ))}
                </TextField> */}

                <Autocomplete
                  value={
                    resources?.find(
                      (option) => option.userId == userId
                    ) ?? null
                  }
                  sx={{
                    width: "220px",
                    mr: 1,
                    lineHeight: "0.80em",
                  }}
                  fullWidth
                  id="selectedUserId"
                  options={resources}
                  getOptionLabel={(option) => option.userName}
                  onChange={(event, value) => {
                    values.userId = value?.userId ?? "";
                    setUser(value?.userId ?? "");
                    GetBalancedLeaveBasedOnUserID(value?.userId ?? "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=" Employee"
                      name="userId"
                      required
                      onBlur={handleBlur}
                     // onChange={handleChange}
                      value={userId?userId:values?.userId}
                      id="selectedUserId"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        getIn(touched, "userId") && getIn(errors, "userId")
                      )}
                      helperText={
                        getIn(touched, "userId") && getIn(errors, "userId")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Duration
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="Short-Leave"
                  >
                    <FormControlLabel
                      value="Short-Leave"
                      control={<Radio />}
                      onClick={() => {
                        handleReset();
                        handleFeildDisable("Short Leave");
                        values.startDate = "";
                        values.endDate = "";
                        values.startTime = "";
                        values.duration = "";
                        values.durationType = "";
                        values.reason = "";
                        values.type = "";
                      }}
                      label="Short Leave"
                    />
                    <FormControlLabel
                      value="Half-Day"
                      control={<Radio />}
                      onClick={() => {
                        handleFeildDisable("Half Day");
                        handleReset();
                        values.startDate = "";
                        values.endDate = "";
                        values.startTime = "";
                        values.duration = "";
                        values.durationType = "";
                        values.reason = "";
                        values.type = "";
                      }}
                      label="Half Day"
                    />
                    <FormControlLabel
                      value="Full-Day"
                      control={<Radio />}
                      onClick={() => {
                        handleFeildDisable("Full Day");
                        if(values.startDate){
                        handleReset();}
                        values.startDate = "";
                        values.endDate = "";
                        values.startTime = "";
                        values.duration = "";
                        values.durationType = "";
                        values.reason = "";
                        values.type = "";
                      }}
                      label="Full Day"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {values.type ==="Earned Leave" &&(
              <Grid item xs={12} md={12}>
              <FormControlLabel control={<Checkbox checked={futureLeaveCheckBox} onChange={(e)=>{
                handleCheckBoxChange(e.target.checked)}} />}
               label="Take Leave from Future" />
              </Grid>)}

              <Grid item xs={12} md={6} >
                <FormLabel id="demo-row-radio-buttons-group-label" >
                  Leave Type
                </FormLabel>

                {disableform == 3 && (
                  <TextField
                    id="outlined-basic"
                    placeholder="Select Type"
                    value={"Casual Leave"}
                    //  select
                    // label="Select Type"
                    variant="outlined"
                    name="type"
                    disabled={true}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={[styles.selectType]}

                  >
                    {AppConstants.MasterData.Issue.LeaveTypehalf.map(
                      (issue: any) => (
                        <MenuItem
                          key={issue.value}
                          value={issue.value}
                          onClick={() => handleChangeLeaveType(issue.value)}
                        >
                          {issue.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
                {disableform == 0 && (
                  <TextField
                    id="type"
                    // value={"Casual Leave"}
                    //placeholder="Select Type"
                    label="Select Type"
                    select
                    //  label="Select Type"
                    variant="outlined"

                    name="type"
                    //disabled={true}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={[styles.selectType]}
                    error={Boolean(
                      getIn(touched, "type") && getIn(errors, "type")
                    )}
                    helperText={getIn(touched, "type") && getIn(errors, "type")}
                  //value={duration}
                  //required
                  >
                    {AppConstants.MasterData.Issue.LeaveTypehalf.map(
                      (issue: any) => (
                        <MenuItem
                          key={issue.value}
                          value={issue.value}
                          onClick={() => handleChangeLeaveType(issue.value)}
                        >
                          {issue.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
                {disableform == 1 && (
                  <TextField
                    id="type1"
                    //placeholder="Select Type"
                    select
                    label="Select Type"
                    //  label="Select Type"
                    variant="outlined"
                    name="type"
                    //disabled={true}
                    fullWidth
                    onBlur={handleBlur}
                  //  onChange={handleChange}
                    onChange={(event) => {
                      handleChange(event);                  
                      setStartDate('');
                      setEndDate('');
                    }}
                    sx={[styles.selectType]}
                    error={Boolean(
                      getIn(touched, "type") && getIn(errors, "type")
                    )}
                    helperText={getIn(touched, "type") && getIn(errors, "type")}
                  //value={duration}
                  //required
                  >
                    {AppConstants.MasterData.Issue.LeaveType.map(
                      (issue: any) => (
                        <MenuItem
                          key={issue.value}
                          value={issue.value}
                          onClick={() => handleChangeLeaveType(issue.value)}
                        >
                          {issue.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 1.5 }}>
                {/* <FormLabel id="demo-row-radio-buttons-group-label" >Select Date</FormLabel> */}

                <Stack direction="row" spacing={1} alignItems="center">
                  {disableform == 3 && (
                    <FormControl sx={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          borderRadius="semi-square"
                          placeholder="Select Date"
                          labelAlignment="left"
                          // className="error"
                          // autoComplete='off'
                          // disableFuture
                          label="Select Date"
                          // openTo="year"
                          // views={["year", "month", "day"]}
                          value={startDate}
                          // minDate={new Date("2023")}
                          minDate={new Date(mindate)}

                          // maxDate={new Date("2024")}
                          maxDate={
                            new Date(new Date().getFullYear() + 1, 11, 31)
                          }
                          onChange={(newValue: any) => {
                            // let dayjsdate:any = dayjs(newValue);
                            // let abc = new Date(newValue)
                            let dateT = getDate(newValue);
                            values.startDate = dateT;
                            values.endDate = dateT;
                            setStartDate(dateT);
                            // setSubmit(true);
                            setshowHelpText(false);
                          }}
                          error={showHelpText && !values.startDate ? "Start Date is required" : ""}
                        // error={Boolean(
                        //     getIn(touched, "type") && getIn(errors, "type")
                        // )}

                        // renderInput={(params: any) => (
                        //   <TextField
                        //     {...params}
                        //     onBlur={handleBlur}
                        //     autoComplete="off" // Disable suggestions
                        //             readOnly={true} 
                        //     onKeyDown={(e: any) => {
                        //       e.preventDefault();
                        //     }}
                        //     //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                        //     error={
                        //       getIn(touched, "startDate") &&
                        //       getIn(errors, "startDate")
                        //     }
                        //     helperText={
                        //       getIn(touched, "startDate") &&
                        //       getIn(errors, "startDate")
                        //     }
                        //     required
                        //     // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                        //     sx={{
                        //       "& .MuiSvgIcon-root": {
                        //         fill: theme.palette.primary.main,
                        //       },
                        //     }}
                        //   />+.
                        // )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                  {disableform == 0 && (
                    <FormControl sx={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          borderRadius="semi-square"
                          placeholder="Select Date"
                          labelAlignment="left"
                          // className="error"
                          // disablePast
                          // autoComplete='off'
                          // disableFuture
                          label="Select Date"
                          // openTo="year"
                          // views={["year", "month", "day"]}
                          value={startDate}
                          minDate={new Date(mindate)}

                          // maxDate={new Date("2024")}
                          maxDate={
                            new Date(new Date().getFullYear() + 1, 11, 31)
                          }
                          onChange={(newValue: any) => {
                            let dateT = getDate(newValue);
                            values.startDate = dateT;
                            values.endDate = dateT;
                            setStartDate(dateT);
                            // setSubmit(true);
                            setshowHelpText(false);

                          }}
                          error={showHelpText && !values.startDate ? "Start Date is required" : ""}
                        // error={Boolean(
                        //     getIn(touched, "type") && getIn(errors, "type")
                        // )}

                        // renderInput={(params: any) => (
                        //   <TextField
                        //     {...params}
                        //     autoComplete="off" // Disable suggestions
                        //             readOnly={true} 
                        //     onBlur={handleBlur}
                        //     onKeyDown={(e: any) => {
                        //       e.preventDefault();
                        //     }}
                        //     //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                        //     error={
                        //       getIn(touched, "startDate") &&
                        //       getIn(errors, "startDate")
                        //     }
                        //     helperText={
                        //       getIn(touched, "startDate") &&
                        //       getIn(errors, "startDate")
                        //     }
                        //     required
                        //     // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                        //     sx={{
                        //       "& .MuiSvgIcon-root": {
                        //         fill: theme.palette.primary.main,
                        //       },
                        //     }}
                        //   />
                        // )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                  {disableform == 1 && (
                    <FormControl sx={{ width: "50%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          borderRadius="semi-square"
                          placeholder={values.type === "Comp Off Leave" ? "Worked on" : "From"}
                          labelAlignment="left"
                          label={values.type === "Comp Off Leave" ? "Worked on" : "From"}
                          // openTo="year"
                          // views={["year", "month", "day"]}                        
                          value={startDate}
                          minDate={new Date(mindate)}
                          // minDate={values.type === "Sick Leave" ? moment().subtract(2, 'days').toDate() :
                          //         values.type === "Comp Off Leave" ? moment().startOf('year').toDate()
                          //             : moment().subtract(0, "years").toDate()}
                                  maxDate={values.type=="Comp Off Leave"?moment().subtract(1, 'days').toDate():
                                    new Date(
                                      new Date().getFullYear() + 1,
                                      11,
                                      31
                                    )
                                  }
                          onChange={(newValue: any) => {
                            values.startDate = getDate(newValue);
                            setStartDate(newValue);
                            setshowHelpText(false);
                           
                            if (values.type === "Marriage Leave") {
                              const newEndDate = moment(newValue).add(9, "days").toDate();
                              values.endDate = getDate(newEndDate);
                              setEndDate(getDate(newEndDate));
                            }
                          }}
                         
                         // error={showHelpText && !values.startDate ? "Start Date is required" : ""}
                          error = {(showHelpText && !values.startDate && values.type === "Comp Off Leave"
                                  ? "Worked On is Required" : showHelpText && !values.startDate
                                    ? "Start Date is required"
                                    : "")}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                  {disableform == 1 && (
                    <FormControl sx={{ width: "50%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          borderRadius="semi-square"
                          label={values.type === "Comp Off Leave" ? "Leave Date" : "Till"}
                          placeholder={values.type === "Comp Off Leave" ? "Leave Date" : "Till"}
                          labelAlignment="left"
                        
                          value={endDate}
                          minDate={new Date(mindate)}

                         // minDate={values.type === "Sick Leave" ? moment().subtract(2, 'days').toDate() : moment().subtract(0, "years").toDate()}
                          // maxDate={new Date("2024")}
                          maxDate={
                            new Date(new Date().getFullYear() + 1, 11, 31)
                          }
                          disabled={values.type === "Marriage Leave"}
                          onChange={(newValue: any) => {
                            let dateT = getDate(newValue);
                            values.endDate = dateT;
                            setEndDate(newValue);
                            setshowHelpText(false);

                          }}
                          //error={showHelpText && !values.endDate ? "End Date is required" : ""}
                          error={showHelpText && !values.endDate && values.type === "Comp Off Leave" ? "Leave Date is required" :
                          showHelpText && !values.endDate ? "End Date is required"
                            : ""}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                {disableform == 3 && (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 1.5 }}
                  >
                    <FormControl sx={{ width: "50%" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          key="starttime"
                          
                          // disableMaskedInput={true}
                          //ampm={false}
                          //inputFormat="hh:mm"
                          disableMaskedInput
                          mask="__:__"
                          label="From"
                          value={startTime}
                          showToolbar={false}
                          // autoComplete='off'
                          // ampm={true}
                          // ampmInClock={true}
                          //className={styles.selectType}
                          // slotProps={{
                          //   textField: {
                          //     sx: {
                          //       borderRadius: 6,
                          //       fieldset: { borderRadius: 6 },
                             
                          //     [`.${OutlinedInputClasses.root}`]: {
                          //        height: 80,
                          //        width: 300,
                          //     },
                        
                          //     '& .MuiInputLabel-root': { lineHeight: 3 },
                          //    },
                          //   },
                          // }}
                     
                          onChange={(newValue) => {
                            setStartTime(newValue);
                            values.startTime = "1";
                            const a = dayjs(newValue);
                            const b = a.add(2, "hour");
                            // var a= dayjs(newValue).(120);
                            setEndTime(b);
                          }}

                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              {...params}
                              onKeyDown={onKeyDown}
                              onBlur={handleBlur}
                              //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                              error={
                                getIn(touched, "startTime") &&
                                getIn(errors, "startTime")
                              }
                              helperText={
                                getIn(touched, "startTime") &&
                                getIn(errors, "startTime")
                              }
                              // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fill: theme.palette.primary.main,
                                },
                                "@media screen and (max-width: 600px)": {
                                  // Adjustments for smaller screens
                                  width: "100%", // Make 
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ width: "50%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          disabled
                          key="starttime"
                          label="Till"
                          value={endTime}
                          onChange={(newValue) => {
                            setEndTime(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Stack>
                )}
                {disableform == 0 && (
                  <FormControl sx={{ width: "100%", mb: 1 }}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Duration Type"
                      variant="outlined"
                      name="Duration Type"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      sx={[styles.selectType]}
                      error={Boolean(
                        getIn(touched, "durationType") &&
                        getIn(errors, "durationType")
                      )}
                      helperText={
                        getIn(touched, "durationType") &&
                        getIn(errors, "durationType")
                      }
                      // value={durationType}
                      required
                    >
                      {AppConstants.MasterData.Issue.LeaveDurationType.map(
                        (issue: any) => (
                          <MenuItem
                            key={issue.value}
                            value={issue.value}
                            onClick={() => {
                              handleDurationType(issue.value);
                              values.durationType = issue.value;
                            }
                            }
                          >
                            {issue.label}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </FormControl>
                )}
                <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                  {/* <FormLabel id="demo-row-radio-buttons-group-label">
                    Reason
                  </FormLabel> */}
                  <TextField
                    label="Reason"
                    id="demo-row-radio-buttons-group-label"
                    fullWidth
                    variant="outlined"
                    required
                    name="reason"
                    value={values.reason.trimStart()}
                    error={Boolean(
                      getIn(touched, "reason") && getIn(errors, "reason")
                    )}
                    helperText={
                      getIn(touched, "reason") && getIn(errors, "reason")
                    }
                    onChange={(e) => {
                      // values.managerComment = e.currentTarget.value;
                      // setManagerComment(e.target.value);
                      let abc = e.currentTarget.value.trimStart();
                      values.reason = abc;
                      setReason(abc);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        minHeight: "50px",
                        backgroundColor: "#fff",
                        lineHeight: "1.5",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3, pt: 0 }}>
            <Button onClick={handleORClose} variant="outlined">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={buttonDisable}
              onClick={() => {
                setshowHelpText(!endDate || !startDate)

              }}
            >
              Apply
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
}

// RequestUpdate;
