
export enum TaskStatus {
    "Select Status" = "",
    "Product Backlog" = "Product Backlog",
    "Sprint Backlog" = "Sprint Backlog",
    "In Progress" = "In Progress",
    "Review" = "Review",
    "Resolved" = "Resolved",
    "QA" = "QA",
    "QA Failed" = "QA Failed",
    "Hold" = "Hold",
    "Blocked" = "Blocked",
    "Closed" = "Closed"
}
export enum HrReportStatus {
    "Select Status" = "",
    "Present" = "1",
    "Absent" = "2",
    "Leave" = "3"
}