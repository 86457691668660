import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {TablePagination} from "@mui/material";
import { chessBoardService } from "../../service/ChessBoard/ChessBoardService";
import { toast } from "material-react-toastify";
import moment from "moment";

export default function ChessMatches() {
    const [listofmatches, setlistofmatches] = useState([]);
    const getChessMatchesList = async () => {
        try {
            await chessBoardService.getChessMatches()
                .then(async (response: any) => {
                    if (response.data.isError) {
                        // toast.warning("Error while fetching data");
                    } else {
                        setlistofmatches(response?.data)
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warn(err)
            });
        }
    };
    useEffect(() => {
        getChessMatchesList();
    }, []);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper sx={{ p: 3, pb: 0 }}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }} component="h2">
                      Final Match
                    </Typography>
                </Box>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }}>Match</TableCell>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }} colSpan={2}>Player 1</TableCell>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }} colSpan={2}>Dept</TableCell>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }} colSpan={2}>Player 2</TableCell>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }} colSpan={4}>Dept</TableCell>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }} >Time</TableCell>
                                <TableCell sx={{ color: "rgb(35, 35, 96)", fontWeight: "600", background: "rgb(245, 245, 245)" }} colSpan={3}>Winner</TableCell>
                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listofmatches
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((event: any,idx:any) => {
                                    const sno = page * rowsPerPage + idx + 1; //
                                    return(
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                         {sno}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                        {event?.player1}
                                        </TableCell>
                                        <TableCell colSpan={3}> {event?.deptPlayer1}</TableCell>
                                        <TableCell colSpan={3}>{event?.player2}</TableCell>
                                        <TableCell colSpan={3}>{event?.deptPlayer2}</TableCell>
                                        <TableCell colSpan={3}>{`${moment.utc(event?.matchTime).format("dddd, MMMM Do YYYY, h:mm")}`}</TableCell>
                                        <TableCell colSpan={2}>{event?.winner ||"--"}</TableCell>

                                    </TableRow>)})}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <TablePagination
        //   labelRowsPerPage="Cards per page"
          rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
          component="div"
          count={listofmatches.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        
          labelDisplayedRows={() => `Total: ${listofmatches?.length}  Page ${page + 1} of `}
        />
        </Paper>

    );
}