import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState, useCallback, useRef } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import { toast } from "material-react-toastify";
import { useParams } from "react-router-dom";
import { masterDataService } from "../../../service/master-service/master-data-service";
import { projectManagementServive } from "../../../service/project-management-service";
import theme from "../../../theme/theme";
import { AssignModel, resources, skillSets } from "./assignModel";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Multiselect from "multiselect-react-dropdown";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { eTypeOfUser } from "../../../Enum/eTypeOfUser";
import { addDays } from "date-fns";

var dropdown_syle = {
  searchBox: {
    lineHeight: "31px",
    borderColor: "#EEEEEE",
    minWidth: "320px",
    maxWidth: "320px",
  },
};

const dummy = [
  {
    id: "1",
    value: "1",
  },

  {
    id: "2",
    value: "2",
  },

  {
    id: "3",
    value: "3",
  },
  {
    id: "4",
    value: "4",
  },
  {
    id: "5",
    value: "5",
  },
  {
    id: "6",
    value: "6",
  },

  {
    id: "7",
    value: "7",
  },
  {
    id: "8",
    value: "8"
  },
  {
    id: "9",
    value: "9",
  },
  {
    id: "10",
    value: "10",
  },
  {
    id: "11",
    value: "11",
  },
  {
    id: "12",
    value: "12",
  },
  {
    id: "13",
    value: "13",
  },
  {
    id: "14",
    value: "14",
  },
  {
    id: "15",
    value: "15",
  },
  {
    id: "16",
    value: "16",
  },
  {
    id: "17",
    value: "17",
  },
  {
    id: "18",
    value: "18",
  },
  {
    id: "19",
    value: "19",
  },
  {
    id: "20",
    value: "20",
  },
  {
    id: "21",
    value: "21",
  },
  {
    id: "22",
    value: "22",
  },
  {
    id: "23",
    value: "23",
  },
  {
    id: "24",
    value: "24",
  },
  {
    id: "25",
    value: "25",
  },
];

interface Depart {
  id: string;
  name: string;
}

export default function AssignResource(props: any) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const dataValue: string[] = [];
  const departmentValuesOnTheForm = useRef(dataValue);
  const [skillValue, setskillValue] = React.useState<any>([]);
  const [usersValue, setUsersValue] = React.useState<any>([]);
  const [departmentValues, setDepartmentValues] = React.useState<any>([]);
  const [noOfResourcesValues, setnoOfResourcesValues] = React.useState<any>([]);

  const [users, setUsers] = useState<Depart[]>([]);
  const [users1, setUsers1] = useState<Depart[]>([]);
  const [skillNameT, setSkillSet] = React.useState<any>([]);
  const [resourcesT, setResouces] = React.useState<any>([]);
  const [allocateGetData, setAllocateGetData] = useState<any>([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [localUserId, setLocalUserId] = useState(
    _authUser.id
  );

  const resourceId = allocateGetData[0]?.id;
  const {
    projectId,
    loginDepartmentId,
    currentLoggedInRole,
    projectOwnerId,
    projectOwnerDepartMentId,
    getProjectDetails,
    handleManModalClose

  } = props;
  const { id } = useParams();
  let objAllocateAndResoure = {
    id: null,
    departmentId: "",
    noOfResources: "",
    projectId: projectId,
    isQA: false,
    skillSets: [],
    resources: [],
    resourcesList: [],
  };

  const [addAllocateAndResoure, setAllocateAndResoure] = useState([
    objAllocateAndResoure,
  ]);

  let _skillSets = new skillSets();
  let _resources = new resources();
  let assignModel = new AssignModel([_skillSets], [_resources]);

  const [assignModelDataHook, setAssignModelDataHook] =
    React.useState(assignModel);

  const [modelDepartmentId, setmodelDepartmentId] = useState(
    assignModelDataHook?.departmentId ?? ""
  );

  const [noOfResources, setnoOfResources] = useState(
    assignModelDataHook?.noOfResources ?? ""
  );
  const [isQaDept, setIsQaDept] = useState(false);
  console.log(addAllocateAndResoure,"addAllocateAndResoure")

  useEffect(() => {
    if (modelDepartmentId === loginDepartmentId) {
      setDisabled(false);
    }
  }, [loginDepartmentId, modelDepartmentId]);


  const getButtonDisabledProperty = () => {
    if (["1"].includes(currentLoggedInRole)) {
      return false;
    } else if (projectOwnerId === localUserId) {
      return false;
    } else {
      return true;
    }
  };
  console.log(projectOwnerDepartMentId, "projectOwnerDepartMentId")
  const fetchMyAPI = useCallback(async () => {
    debugger
    if (resourceId)
      //setmodelDepartmentId(projectOwnerDepartMentId);
      setmodelDepartmentId(allocateGetData[0]?.departmentId);
    if (allocateGetData[0]?.departmentId) {
      departmentValuesOnTheForm.current[0] = projectOwnerDepartMentId;
    }
    setSkillSet(allocateGetData[0]?.skillSets);
    setnoOfResources(allocateGetData[0]?.noOfResources);
    setIsQaDept(allocateGetData[0]?.isQA)
    setResouces(allocateGetData[0]?.resources);
    let newArray = allocateGetData.slice(1);
    let anotherArray = newArray.map(async (item: any, index: number) => {
      departmentValuesOnTheForm.current[index + 1] = item?.departmentId;
      const resourcesList = await GetMoreResourcesbyDepartmentId(
        item?.departmentId
      );
      return {
        id: item?.id,
        departmentId: item?.departmentId,
        projectId: item?.projectId,
        skillSets: item?.skillSets?.map((data: any) => ({
          skillId: data.skillId,
          name: data.name,
        })),
        resources: item?.resources?.map((data: any) => ({
          resourceId: data.resourceId,
          name: data.name,
        })),
        isQA:item?.isQA,
        noOfResources: item?.noOfResources,
        //resourcesList: users ? getResourceList(item?.departmentId) : usersValue
        resourcesList,
      };
    });
    let dataResults = await Promise.all(anotherArray);
    if (dataResults.length) {
      setAllocateAndResoure(dataResults);
    }
  }, [resourceId]);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  const AddAssignSavebutton = async (values: any) => {
    debugger
    setButtonDisable(true)

    try {
      let data = assignModel;
      let skillSets = skillNameT;
      let resources = resourcesT;
      let isQA = isQaDept;

      data.id = allocateGetData[0]?.id ?? null;
      data.projectId = projectId;
      data.noOfResources = noOfResources;
      data.departmentId = modelDepartmentId;
      data.skillSets = skillSets;
      data.resources = resources;
      data.isQA =isQA;

      let newData: any = [];
      newData = [...newData, data];

      if (addAllocateAndResoure && addAllocateAndResoure[0].departmentId != "") {
        const newArray = addAllocateAndResoure.filter((item) => item.departmentId != "").map(({ resourcesList, ...item }) => item);
        newData = [...newData, ...newArray];
      }
      await projectManagementServive
        .AddAssignProject(newData)
        .then(async (response: any) => {
          if (response.data?.isError) {
            toast.error("Something went wrong...", {

            });
            setButtonDisable(false)

          } else {
            toast.success("Resources updated successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
              // props.open(false);
            });
            handleManModalClose();
            props.getProjectDetails("", props.statusOfFilter);
          }
        });

    } catch (ex: any) {
      toast.warning("Error:remove the empty allocate row")
      setButtonDisable(false)
    };
  };


  let addAllocateFormResoure = () => {
    setAllocateAndResoure([
      ...addAllocateAndResoure,
      {
        id: null,
        departmentId: "",
        isQA: false,
        projectId: "",
        noOfResources: "",
        skillSets: [],
        resources: [],
        resourcesList: [],
      },
    ]);
  };

  const updateAllocateChange = async (
    value: any,
    type: string,
    index: number,
    name: string
  ) => {
    debugger
    let newUpdateAllocateChange = [...addAllocateAndResoure];
    if (type == "departmentId") {
      const resourcesData = await GetMoreResourcesbyDepartmentId(value);
      newUpdateAllocateChange[index].departmentId = value;
      newUpdateAllocateChange[index].projectId = projectId;
      // newUpdateAllocateChange[index].noOfResources = noOfResources;
      //newUpdateAllocateChange[index].resourcesList = usersValue.filter((user: any) => user.department === name);
      newUpdateAllocateChange[index].resourcesList = resourcesData;
    }

    if (type == "skillSets") {
      newUpdateAllocateChange[index].skillSets = value;
    }
    if (type == "noOfResources") {
      newUpdateAllocateChange[index].noOfResources = value;
    }
    if(type=="isQa"){
      newUpdateAllocateChange[index].isQA = value;
    }
    setAllocateAndResoure(newUpdateAllocateChange);
  };

  let removeFormFields = (i: any) => {
    let newAllocateChange = [...addAllocateAndResoure];
    if (newAllocateChange != null && newAllocateChange.length == 1) {
      newAllocateChange.push(objAllocateAndResoure)
    }
    newAllocateChange.splice(i, 1);
    setAllocateAndResoure(newAllocateChange);
  };

  useEffect(() => {
    getSkilDetails();
    //getUserDetails();
    getDepartmentDetails();
    getDepartmentSkillDetails();
    getGetAssignResource();

  }, []);

  const getGetAssignResource = async () => {
    await projectManagementServive
      .GetAssignResourceById(projectId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          // setAssignModelDataHook(response?.data);
          if (response?.data?.length > 0) {
            GetResourcesbyDepartmentId(_authUser.typeOfUser === eTypeOfUser.Admin ? response?.data[0].departmentId : projectOwnerDepartMentId);
            setAllocateGetData(response?.data);
          } else {
            // setmodelDepartmentId(
            //   localUserId === projectOwnerId ? projectOwnerDepartMentId : ""
            // );
            GetResourcesbyDepartmentId(projectOwnerDepartMentId);
            setmodelDepartmentId(projectOwnerDepartMentId);
          }
        }
      });
  };

  const getSkilDetails = async () => {
    await masterDataService.getAllActiveSkills().then((response: any) => {
      if (response.data.isError) {
      } else {
        setskillValue(response.data);
      }
    });
  };


  const GetResourcesbyDepartmentId = async (id: any) => {
    await projectManagementServive
      .GetResourcesbyDepartmentId(id)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          if (response.data) {
            const newUsersList = response.data?.map((data: any) => ({
              id: data.userId,
              name: data.userName,
            }));

            setUsersValue(newUsersList);
          }
        }
      });
  };

  const GetMoreResourcesbyDepartmentId = async (id: any) => {
    const apiResult = await projectManagementServive
      .GetResourcesbyDepartmentId(id)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          if (response.data) {
            const newUsersList = response.data?.map((data: any) => ({
              id: data.userId,
              name: data.userName,
            }));
            return newUsersList;
          }
        }
      });

    return apiResult;
  };

  const getDepartmentDetails = async () => {
    var input = {
      module: 1,
      status: "",
      search: "",
    };
    await masterDataService.getDepartment(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setUsers(response?.data);
      }
    });
  };

  const getDepartmentSkillDetails = async () => {
    var input = {
      module: 1,
      status: "",
      search: "",
    };
    await masterDataService.getDepartment(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setUsers1(response?.data);
      }
    });
  };

  const noRefCheck = (e: any) => {
    var myArray: any = [];
    e.forEach((elem: any) => {
      myArray.push({
        skillId: elem.id ?? elem.skillId,
        name: elem.name,
        level: "Beginer",
      });
    });
    setSkillSet(myArray);
  };

  const addMoreSkills = (index: number, e: any) => {
    let newUpdateAllocateChange = [...addAllocateAndResoure];
    var myArray: any = [];
    e.forEach((elem: any) => {
      myArray.push({
        skillId: elem.id ?? elem.skillId,
        name: elem.name,
        level: "Beginer",
      });
    });
    newUpdateAllocateChange[index].skillSets = myArray;
    setAllocateAndResoure(newUpdateAllocateChange);
  };

  const formatDateToMidnightUTC = (date: Date): string => {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00Z`;
  };


  const addMoreResources = (index: number, e: any) => {

    let newUpdateAllocateChange = [...addAllocateAndResoure];
    var myArray: any = [];
    const currentDate = new Date();
    const nextDayDate = addDays(currentDate, 1);
    const formattedNextDayDate = formatDateToMidnightUTC(nextDayDate);
    e.forEach((elem: any) => {
      myArray.push({ resourceId: elem.id ?? elem.resourceId, name: elem.name, assignedOn: formattedNextDayDate });
    });
    newUpdateAllocateChange[index].resources = myArray;
    setAllocateAndResoure(newUpdateAllocateChange);
  };


  const noRefCheckedReources = (e: any) => {
    var myArray: any = [];
    e.forEach((elem: any) => {
      myArray.push({ resourceId: elem.id ?? elem.resourceId, name: elem.name });
    });
    setResouces(myArray);
  };

  const validationSchema = Yup.object().shape({
    departmentId: Yup.string()
      .required("Department is required")
      .test("Unique", "Department Option needs to be unique", (value) => {
        return !(
          Object.values(departmentValuesOnTheForm.current).filter(
            (v) => v === value
          ).length > 1
        );
      }),
    // resources: Yup.string()
    //   .test("Unique", "Department Option needs to be unique", (value) => {
    //     return !(
    //       Object.values(departmentValuesOnTheForm.current).filter(
    //         (v) => v === value
    //       ).length > 1
    //     );
    //   }),
    moreDepartment: Yup.array().of(
      Yup.object().shape({
        departmentId: Yup.string().test(
          "Unique",
          "Department Option needs to be unique",
          (value) => {
            return !(
              Object.values(departmentValuesOnTheForm.current).filter(
                (v) => v === value
              ).length > 1
            );
          }
        ),
      })
    ),
  });

  const checkExistingDepartmentValuesOnTheForm = (key: number, e: any) => {
    const {
      target: { id, value },
    } = e;
    const selectedDepartmentId = e.target.getAttribute("data-value");
    if (selectedDepartmentId) {
      setDepartmentValues([...departmentValues, selectedDepartmentId]);
      departmentValuesOnTheForm.current[key] = selectedDepartmentId;
      // setExistNoOfResourcesValues([...existNoOfResourcesValues, selectedDepartmentId]);
    }
  };

  const checkExistingNoOfResourcesValuesOnTheForm = (key: number, e: any) => {
    const {
      target: { id, value },
    } = e;
    const selectedNoOfRespourcesId = e.target.getAttribute("data-value");

    if (selectedNoOfRespourcesId) {
      setnoOfResourcesValues([
        ...noOfResourcesValues,
        selectedNoOfRespourcesId,
      ]);
      departmentValuesOnTheForm.current[key] = selectedNoOfRespourcesId;
      // setExistNoOfResourcesValues([...existNoOfResourcesValues, selectedDepartmentId]);
    }
  };

  const checkDisableElements = (elementDepartmentId: string = "") => {
    if (_authUser.typeOfUser === eTypeOfUser.Admin) {
      return false;
    }
    if (elementDepartmentId == "" && projectOwnerDepartMentId === _authUser.employeeInfo.departmentId) {
      return false;
    }
    if (elementDepartmentId === _authUser.employeeInfo.departmentId) {
      return false;
    }
    return true;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        departmentId: modelDepartmentId,
        noOfResources: "",
        // projectId: modelProjectId,
        // skillId: modelSkillId,
        // name: modelSkillName,
        // level: modelSkillLevel,
        moreDepartment: addAllocateAndResoure || [
          {
            id: null,
            projectId: projectId,
            departmentId: "",
            noOfResources: "",
            skillSets: [],
            resources: [],
            resourcesList: usersValue,
          },
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        AddAssignSavebutton(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <FormHelperText
              sx={{
                mb: 2,
                fontWeight: 500,
                fontSize: " 14px",
                lineHeight: " 21px",
                /* identical to box height */

                /* Primary Color/Primary_dark shade */

                color: theme.palette.primary.dark,
              }}
            >
              Project owner
            </FormHelperText>
            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: "200px" }}>
                  {/* <TextField
                    disabled={true}
                    id="outlined-basic"
                    select
                    label="Department"
                    value={values.departmentId || modelDepartmentId}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.departmentId && errors.departmentId)}
                    helperText={touched.departmentId && errors.departmentId}
                  >
                    {users.map((user, idx) => (
                      <MenuItem
                        key={user.name}
                        value={user.id}
                        onClick={(event) => {
                          values.departmentId = users[idx].id;
                          setmodelDepartmentId(users[idx].id);
                          GetResourcesbyDepartmentId(users[idx].id);
                          checkExistingDepartmentValuesOnTheForm(0, event);
                          setFieldValue("departmentId", users[idx].id);
                        }}
                      >
                        {user.name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <Autocomplete
                    disabled={true}
                    value={
                      users?.find(
                        (option: any) => option.id == values.departmentId
                      ) ?? null
                    }
                    fullWidth
                    id="outlined-SprintId"
                    options={users}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      values.departmentId = value?.id ?? "";
                      setmodelDepartmentId(value?.id ?? "");
                      GetResourcesbyDepartmentId(value?.id ?? "");
                      checkExistingDepartmentValuesOnTheForm(0, event);
                      setFieldValue("departmentId", value?.id ?? "");
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'flip',
                            enabled: true,
                            options: {
                              altBoundary: true,
                              flipVariations: true,
                              altAxis: true,
                              padding: 8,
                              fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                            },
                          },
                        ],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={true}
                        id="outlined-basic"
                        label="Department"
                        value={values.departmentId || modelDepartmentId}
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.departmentId && errors.departmentId)}
                        helperText={touched.departmentId && errors.departmentId}
                      />
                    )}
                  />
                </Box>
              </Stack>

              <Multiselect
                disable={checkDisableElements()}
                displayValue="name"
                onKeyPressFn={noRefCheck}
                onRemove={noRefCheck}
                onSearch={noRefCheck}
                onSelect={noRefCheck}
                placeholder="Skill Set"
                options={skillValue}
                selectedValues={skillNameT}
                style={dropdown_syle}
              />

              <Stack direction="row" spacing={2}>
                <Box sx={{ width: "220px" }}>
                  <FormControlLabel
                    sx={{ fontWeight: 600, m: 0 }}
                    control={
                      <Checkbox
                         value={isQaDept}
                         checked={isQaDept}

                        onClick={(e) => {
                          debugger
                          setIsQaDept(!isQaDept)

                        }}
                      />
                    }
                    label="Is Qa Department"
                  />
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: "200px" }}>
                  <TextField
                    // disabled={costing == "fixed"}
                    disabled={checkDisableElements()}
                    id="outlined-basic"
                    label="No. of Resource"
                    variant="outlined"
                    value={values.noOfResources || noOfResources}
                    select
                    fullWidth
                  >
                    {dummy.map((option, idx) => (
                      <MenuItem
                        key={idx}
                        value={option.value}
                        onClick={(event) => {
                          // values.paymentDetails.currency = dummy[idx].value;
                          // setcurrency(dummy[idx].value);
                          checkExistingNoOfResourcesValuesOnTheForm(0, event);
                          GetResourcesbyDepartmentId(dummy[idx].value);
                          values.noOfResources = dummy[idx].value;
                          setFieldValue("noOfResources", dummy[idx].value);
                          setnoOfResources(dummy[idx].value);
                        }}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Stack>

              <Multiselect
                disable={checkDisableElements()}
                displayValue="name"
                onKeyPressFn={function noRefCheckedReources() { }}
                onRemove={noRefCheckedReources}
                onSearch={function noRefCheckedReources() { }}
                onSelect={noRefCheckedReources}
                placeholder="Resources"
                options={usersValue}
                selectedValues={resourcesT}
                style={dropdown_syle}
                key={id}
                selectionLimit={noOfResources}
              />
            </Stack>
            <FormHelperText
              sx={{
                mb: 2,
                fontWeight: 500,
                fontSize: " 14px",
                lineHeight: " 21px",
                /* identical to box height */

                /* Primary Color/Primary_dark shade */

                color: theme.palette.primary.dark,
              }}
            >
              Request resources from other department
            </FormHelperText>
            {addAllocateAndResoure.map((element, index) => (
              <Stack direction="row" spacing={1}>
                <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                  <Box sx={{ width: "200px" }}>

                    <Autocomplete
                      disabled={checkDisableElements()}
                      value={
                        users1?.find(
                          (option: any) => option.id == element.departmentId
                        ) ?? null
                      }
                      fullWidth
                      id="outlined-SprintId"
                      options={users1}
                      getOptionLabel={(option) => option.name}
                      getOptionDisabled={(option) => {
                        return (addAllocateAndResoure.find((dept: any) => (dept.departmentId === option.id && dept.departmentId !== element.departmentId)) ||
                          (values.departmentId !== null && option.id === values.departmentId)) ? true : false;
                      }
                      }
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      onChange={(event, value) => {
                        values.departmentId = value?.id ?? "";
                        setFieldValue(
                          `moreDepartment[${index}].departmentId`,
                          value?.id ?? ""
                        );
                        checkExistingDepartmentValuesOnTheForm(
                          index + 1,
                          event
                        );
                        updateAllocateChange(
                          value?.id ?? "",
                          "departmentId",
                          index,
                          value?.name ?? ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-basic"
                          disabled={checkDisableElements()}
                          label="Department"
                          name={`moreDepartment[${index}].departmentId`}
                          value={element.departmentId || ""}
                          variant="outlined"
                          fullWidth
                          data-id={index + 1}
                          error={Boolean(
                            touched.moreDepartment &&
                            touched.moreDepartment[index] &&
                            touched.moreDepartment[index]["departmentId"] &&
                            errors.moreDepartment &&
                            errors.moreDepartment[index] &&
                            "Department Option needs to be unique"
                          )}
                          helperText={
                            touched.moreDepartment &&
                            touched.moreDepartment[index] &&
                            touched.moreDepartment[index]["departmentId"] &&
                            errors.moreDepartment &&
                            errors.moreDepartment[index] &&
                            "Department Option needs to be unique"
                          }
                        />
                      )}
                    />
                  </Box>
                  <Multiselect
                    displayValue="name"
                    disable={checkDisableElements()}
                    onKeyPressFn={function addMoreSkills() { }}
                    onRemove={(e) => {
                      addMoreSkills(index, e);
                    }}
                    onSearch={function addMoreSkills() { }}
                    onSelect={(e) => {
                      addMoreSkills(index, e);
                    }}
                    placeholder="Skill Set"
                    options={skillValue}
                    selectedValues={element?.skillSets}
                    style={dropdown_syle}
                  />

  <Stack direction="row" spacing={2}>
                <Box sx={{ width: "220px" }}>
                  <FormControlLabel
                    sx={{ fontWeight: 600, m: 0 }}
                    control={
                      <Checkbox
                         value={element.isQA}
                         checked={element.isQA}
                        onClick={(e:any) => {
                          debugger
                           updateAllocateChange(
                                e.target.checked,
                                "isQa",
                                index,
                               ""
                              );

                        }}
                      />
                    }
                    label="Is Qa Department"
                  />
                </Box>
              </Stack>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: "200px" }}>
                      <TextField
                        disabled={checkDisableElements()}
                        // disabled={costing == "fixed"}
                        id="outlined-basic"
                        name={`moreDepartment[${index}].noOfResources`}
                        label="No. of Resource"
                        variant="outlined"
                        value={element.noOfResources || ""}
                        data-id={index + 1}
                        select
                        fullWidth
                      >
                        {dummy.map((option, idx) => (
                          <MenuItem
                            key={idx}
                            value={option.value}
                            onClick={(event) => {
                              // values.paymentDetails.currency = dummy[idx].value;
                              // setcurrency(dummy[idx].value);
                              setFieldValue(
                                `moreDepartment[${index}].noOfResources`,
                                dummy[idx].value
                              );
                              checkExistingNoOfResourcesValuesOnTheForm(
                                index + 1,
                                event
                              );
                              // setnoOfResources(dummy[idx].value);
                              updateAllocateChange(
                                dummy[idx].id,
                                "noOfResources",
                                index,
                                dummy[idx].value
                              );
                            }}
                          >
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Stack>

                  <Multiselect
                    disable={checkDisableElements(element.departmentId)}
                    displayValue="name"
                    onKeyPressFn={function addMoreResources() { }}
                    onRemove={(e) => {
                      addMoreResources(index, e);
                    }}
                    onSearch={function addMoreResources() { }}
                    onSelect={(e) => {
                      addMoreResources(index, e);
                    }}
                    placeholder="Resources"
                    options={element?.resourcesList}
                    selectedValues={element?.resources}
                    style={dropdown_syle}
                  />
                </Stack>

                {index >= 0 && projectOwnerDepartMentId === _authUser.employeeInfo.departmentId ? (
                  <Box sx={{ textAlign: "right" }}>
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        // endIcon={<Icons.Delete />}
                        color="error"
                        size="small"
                        onClick={() => removeFormFields(index)}
                        disabled={projectOwnerDepartMentId !== _authUser.employeeInfo.departmentId}
                        sx={{ mb: 2 }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : null}
              </Stack>
            ))}

            {projectOwnerDepartMentId === _authUser.employeeInfo.departmentId && <Box>
              {
                <Button
                  variant="text"
                  endIcon={<AddIcon />}
                  onClick={() => addAllocateFormResoure()}
                  sx={{ my: 2 }}
                  disabled={getButtonDisabledProperty()}
                >
                  Add More{" "}
                </Button>
              }
            </Box>}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" type="button" onClick={handleManModalClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={buttonDisable}>
                Confirm
              </Button>
            </Stack>
            {/* myArray = {JSON.stringify(skillName, null, 2)} */}
          </>
        </form>
      )}
    </Formik>
  );
}
