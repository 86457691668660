import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useParams } from "react-router-dom";
import { slabgenrationService } from '../../service/SlabgenrationService';
import { toast } from 'material-react-toastify';



interface AllowanceList {
    id: string;
    name: string;
}
export default function SlabView() {

    const [payroleviewlist, setpayroleviewlist] = React.useState<any | null>(null);
    const { id } = useParams();

    useEffect(() => {
        getpayrollslabById(id);
        getallowanceDetails();
    }, []);
    const getpayrollslabById = async (slabId: any) => {
        try {
            await slabgenrationService.getpayrollslabById(slabId).then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong while fetching manager data ...");
                } else {
                    setpayroleviewlist(response?.data)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const [allowanceList, setAllowanceList] = useState<AllowanceList[]>([]);
    const getallowanceDetails = async () => {
        let abc = {
            "status": "",
            "search": "",
            "isDropDown": true
        }
        try {
            await slabgenrationService.getallallow(abc).then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong while fetching manager data ...");
                } else {
                    setAllowanceList(response?.data)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };


    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Link color="inherit" to="/">
                        Slab Generation
                    </Link>
                </Breadcrumbs>
                <Paper sx={{ p: 3, mb: 3 }} >
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }} component="h2">
                                Slab Generation
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 300, marginBottom: "0px" }} component="h2">
                                        Slab Name
                                    </Typography>
                                    <Typography sx={{ marginBottom: "10px", fontSize: "18px" }} component="h2">
                                        {payroleviewlist?.slabName || "--"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 300, marginBottom: "0px" }} component="h2">
                                        Slab Range
                                    </Typography>                                  
                                    <Typography sx={{ marginBottom: "10px", fontSize: "18px" }} component="h2">
                                        {`${payroleviewlist?.slabRangeFrom} to ${payroleviewlist?.slabRangeTo}`}
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button
                                variant="contained"
                                component={Link}
                                to={`/edit-slab/${id}`}
                                color="primary"
                            >
                                Edit
                            </Button>

                        </Grid>
                    </Grid>
                </Paper>
                <Grid container spacing={2}>
                    {payroleviewlist?.allowances?.map((item: any, idx: any) => (<Grid item xs={6}>
                        <Paper sx={{ p: 3 }}>
                            <Grid container  >
                                <Grid item xs={6}  >
                                    <Typography variant="subtitle1" sx={{ marginBottom: "0px" }} component="h2">
                                        Allowance
                                    </Typography>
                                    <Typography variant="body1" sx={{ width: '35%', marginBottom: '24px' }}>
                                        {allowanceList?.find((option: any) => option.id === item?.allowanceId)?.name || 'No Allowance'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="subtitle1" sx={{ marginBottom: "0px" }} component="h2">
                                        Allowance Value
                                    </Typography>                                   
                                    <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }} component="h2">
                                        {item?.isAllowanceType === false  ? `${item?.allowanceValue}%`: item?.allowanceValue || "--"}
                                    </Typography>
                                </Grid>
                                {!item.isAllowanceType && (

                                    <Grid item xs={6} >
                                        <Typography variant="subtitle1" sx={{ marginBottom: "0px" }} component="h2">
                                            Dependent On
                                        </Typography>
                                        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }} component="h2">
                                            {item?.dependentOn || "--"}

                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={6} >
                                    <Typography variant="subtitle1" sx={{ marginBottom: "0px" }} component="h2">
                                        Allowance Type
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }} component="h2">
                                        {item?.isAllowanceType == true ? "Fixed" : "Variable"}

                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>))}
                </Grid>
            </Box>
        </>
    );
}