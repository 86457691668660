import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams, } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import theme from "../../theme/theme";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClearIcon from "@mui/icons-material/Clear";
import { clsResourcesList } from "../../service/common-services/resources";
import { Department } from "../../Interfaces/IDepartment";
import { IResource } from "../../Interfaces/IResource";
import { useSelector } from "react-redux";
import { HrReportFilter, IDepartmentWiseAttendanceReportFilter, IDepartmentWiseAttendanceReportResponse, IEmployeeWorkDiaryReportResponse, IHrReportResponse, WorkDiaryReportFilter } from "../../Interfaces/IReport";
import { reportService } from "../../service/Reports/reports";
import { clsDepartmentList } from "../../service/common-services/department";
import { toast } from "material-react-toastify";
import { Autocomplete, Chip, CircularProgress, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import HrReporttop from "./HrReportsstyle";
import InfoPopover from "../../components/CommonInfoComponent";
import NotFound from "../../components/styledComponent/NotFound";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import { projectManagementServive } from "../../service/project-management-service";

export default function HrReport() {
    const navigation = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [reports, setreports] = React.useState<IDepartmentWiseAttendanceReportResponse[]>([])
    const [data, setData] = React.useState([]);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openPopupImgPath, setopenPopupImgPath] = React.useState("");

    const options = [
        // {
        //   value: 0,
        //   label: "All Months",
        // },
        {
            value: 1,
            label: "January",
        },
        {
            value: 2,
            label: "February",
        },
        {
            value: 3,
            label: "March",
        },
        {
            value: 4,
            label: "April",
        },
        {
            value: 5,
            label: "May",
        },
        {
            value: 6,
            label: "June",
        },
        {
            value: 7,
            label: "July",
        },
        {
            value: 8,
            label: "August",
        },
        {
            value: 9,
            label: "September",
        },
        {
            value: 10,
            label: "October",
        },
        {
            value: 11,
            label: "November",
        },
        {
            value: 12,
            label: "December",
        },
    ];

    const handleClickOpen = (imgPath: string) => {
        setOpen(true);
        setopenPopupImgPath(imgPath);
    };

    const handleClose = () => {
        setOpen(false);
        setopenPopupImgPath('');
    };

    const LoginType = [
        {
            value: 0,
            label: "Select Type",
        },
        {
            value: 1,
            label: "Tracker",
        },
        {
            value: 2,
            label: "Portal",
        },

    ];
    let DepartmentId = "";
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [selectedDepartmentIndex, setselectedDepartmentIndex] = React.useState(0);
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [departmentHook, setDepartmentHook] = React.useState("");

    let ResourceId = "";
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = React.useState("");
    const [resourceHook, setresourceHook] = React.useState("");

    let today = dayjs();
    const [fromDate, setfromDate] = React.useState<Dayjs | null>(setDateWithoutTime(today));
    const [month, setMonth] = React.useState<any>(setDateWithoutTime(today));
    const [toDate, settoDate] = React.useState<Dayjs | null>(setIncreaseDateWithoutTime(today));
    function setDateWithoutTime(fdate: any) {
        return dayjs(fdate.format('YYYY-MM-DD'));
    }
    function setIncreaseDateWithoutTime(fdate: any) {
        let date: any = fdate.format('YYYY-MM-DD');
        let tdate: any = dayjs(date).add(1, 'day')
        return tdate;
    }
    const setdatePeriod = (fdate: any) => {
        let date: any = fdate.format('YYYY-MM-DD');
        setfromDate(dayjs(date));
        let tdate: any = dayjs(date).add(1, 'day')
        settoDate(tdate);
    }

    const [StaticStatusId, setStaticStatusId] = useState<number | undefined>(1);
    console.log(data, "data");
    console.log(resources, "resources");
    console.log(departmentId, "departmentId");

    let currentYear = new Date().getFullYear();
    var currentYearString = currentYear;
    console.log(currentYear, "currentYear");
    const Years = [];
    for (let i = 0; i < 2; i++) {
        Years.push({
            id: currentYear - i,
            label: (currentYear - i)
        });
    }

    const getCurrentMonth = () => {
        const currentMonth = new Date().getMonth() + 1;
        return currentMonth;
    };
    const [selectedYear, setSelectedYear] = React.useState<any>();
    const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1)
    const [monthStatus, setMonthStatus] = useState<number | undefined>(getCurrentMonth());
    const [monthHook, setMonthHook] = useState<any>(currentMonth);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(reports.length / rowsPerPage);
    const [page, setPage] = React.useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isLoggedInTracker = _authUser.employeeInfo.isTrackerRequired;

    const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setfiltersSelected(true);
        const selectedStatus = event.target.value as number;
        setStaticStatusId(selectedStatus);
        setResourceId("");
        if(selectedStatus == 0 &&departmentId!=""){
            GetResourcesbyDepartmentId(departmentId);
        }
        if (selectedStatus == 0 || departmentId == "") {

        }
        else {
            getResourcesByDepartmentOrType(departmentId, selectedStatus);
        }
    };
    const handleMonthChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setfiltersSelected(true);
        const selectedStatus = event.target.value as number;
        setMonthStatus(selectedStatus);
    };
    const getReport = async (
        departmentId: string = "", monthStatus: number, resourceId: string = "", StaticStatusId: number, yearValue: number
    ) => {
        try {

            setToggleLoader(true);
            // var monthNumber = new Date(date).getMonth() + 1;
            var hrReportFilter: IDepartmentWiseAttendanceReportFilter = {
                departmentId: departmentId,
                monthNumber: monthStatus !== undefined ? monthStatus : 0,
                resourceId: resourceId,
                loginType: StaticStatusId !== undefined ? StaticStatusId : 0,
                year: yearValue ? yearValue : currentYearString,
            };
            await reportService.getDepartmentWiseHrReport(hrReportFilter)
                .then((response: any) => {
                    if (response.data.isError) {
                        setreports([]);
                        setToggleLoader(false);
                    }
                    else {
                        setreports(response.data);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    const getResourcesByDepartmentOrType = async (
        departmentId: string = "", StaticStatusId: number
    ) => {
        try {

            //    setToggleLoader(true);
            // var monthNumber = new Date(date).getMonth() + 1;
            var req = {
                departmentId: departmentId,
                isTrackerRequired: StaticStatusId !== undefined ? StaticStatusId === 1 : false

            };
            await reportService.getResourcesByDepartmentOrType(req)
                .then((response: any) => {
                    if (response.data.isError) {
                        setresources([]);
                        //  setToggleLoader(false);
                    }
                    else {
                        setresources(response.data);
                        // setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }


    const GetResourcesbyDepartmentId = async (id: any) => {
        try {
            await projectManagementServive
                .GetResourcesbyDepartmentId(id)
                .then((response: any) => {
                    if (response.data.isError) {
                    } else {
                        if (response.data) {
                            // const newUsersList = response.data?.map((data: any) => ({
                            //     userId: data.userId,
                            //     name: data.userName,
                            // }));
                            setresources(response.data);
                        }
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };

    React.useEffect(() => {
        if (_permission.page_HrReport_Access !== true) {
            navigation('/dashboard');
        }
        const departmentsData = async () => {
            setSelectedYear(currentYear);
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                if (_permission.action_HrReport_ReportListing_AllDepartments_Access == false) {
                    setdisabledDepartments(true);
                }
                setToggleLoader(true);
                setDepartmentHook(_authUser.employeeInfo.departmentId);
                setDepartmentId(_authUser.employeeInfo.departmentId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                // setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                getReport(_authUser.employeeInfo.departmentId, monthHook, "", 0, currentYearString);
                // getResourcesByDepartmentOrType(_authUser.employeeInfo.departmentId, StaticStatusId !== undefined ? (StaticStatusId ? 1 : 2) : 2);
                setStaticStatusId(0)
                GetResourcesbyDepartmentId(_authUser.employeeInfo.departmentId);
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                // setresources(await clsResourcesList.getResourcesDropdownList(''));
                setDepartmentId("");

            }
        };
        departmentsData();



    }, []);
    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    sx={HrReporttop.topbar}
                >
                    <Box sx={{ position: "absolute", inset: "6px auto auto 0" }}>
                        <InfoPopover onInput={"Reports"} />
                    </Box>
                    <Typography component="h5" sx={{ fontWeight: 500, ml: 6, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }}>
                        Working Days: {reports && reports.length > 0 && <>{reports[0].totalWorkingDay}</>}
                    </Typography>
                    <Typography component="h5" sx={{ fontWeight: 500, background: '#f0f9ff', padding: "8px 16px", borderRadius: "8px" }}>
                        Total Working Hours: {reports && reports.length > 0 && <>{reports[0].totalWorkingDay * 8}</>}
                    </Typography>
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 0, sm: 2 } }}>
                        <Stack sx={HrReporttop.rightside} alignItems="flex-end">

                            <Box sx={{ marginRight: "20px", width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, }}>
                                <Autocomplete
                                    disabled={disabledDepartments}
                                    disableClearable={departmentId && departmentId != "" ? false : true}
                                    value={
                                        departments.find(
                                            (option: any) => option.id == departmentId
                                        ) ?? null
                                    }
                                    // fullWidth
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setDepartmentHook(value?.id ?? "");
                                        setDepartmentId(value?.id ?? "")
                                        // setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                                        setresourceHook("");
                                        setResourceId("");
                                        setfiltersSelected(true);
                                        DepartmentId = value?.id ?? "";
                                        if (value) {
                                            getResourcesByDepartmentOrType(value?.id ?? "", StaticStatusId !== undefined ? (StaticStatusId ? 1 : 2) : 2);
                                        }
                                        if (value && _permission.action_HrReport_ReportListing_AllDepartments_Access == true) {
                                            GetResourcesbyDepartmentId(value?.id);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Department"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={departmentId}
                                            fullWidth
                                            sx={{ minWidth: 200 }}
                                        />
                                    )}
                                    sx={{
                                        "&.MuiAutocomplete-root": {
                                            margin: 0,
                                        }
                                    }}
                                />
                            </Box>

                            <Box>
                                <TextField
                                    sx={{ minWidth: 200 }}

                                    id="outlined-basic"
                                    select
                                    value={StaticStatusId}
                                    variant="outlined"
                                    onChange={

                                        handleStatusChange


                                    }
                                >
                                    {LoginType.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ my: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}>
                                <Autocomplete
                                    disabled={!departmentHook || departmentHook === ""}
                                    disableClearable={resourceId && resourceId != "" ? false : true}
                                    value={
                                        resources.find(
                                            (option: any) => option.userId == resourceId ?? ""
                                        ) ?? null
                                    }
                                    // fullWidth                          

                                    id="outlined-basic"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={async (event, value) => {
                                        setfiltersSelected(true);
                                        setresourceHook(value?.userId ?? "");
                                        // setselectedResourceIndex(index);
                                        setResourceId(value?.userId ?? "");
                                        ResourceId = value?.userId ?? "";

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Resource"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={resourceId}
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}

                                />
                            </Box>


                            {/* <Box sx={{ mb: { xs: "20px", md: "0px" }, mx: { xs: "0px", md: "20px" } }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                    <DatePicker
                                        // disableFuture
                                        label="Month"
                                        placeholder="Month"
                                        labelAlignment="left"
                                        borderRadius="semi-square"
                                        // openTo="year"
                                        // views={["month"]}
                                        // inputFormat="MMMM"
                                       
                                        value={month}
                                        onChange={(newValue: any) => {
                                            let abc= dayjs(newValue)   
                                            setMonth(abc);
                                            //  getReport(departmentHook, newValue, resourceHook)
                                            setfiltersSelected(true);
                                        }}
                                    // renderInput={(params: any) => <TextField {...params}
                                    //     autoComplete="off" // Disable suggestions
                                    //     readOnly={true} // Disable user input
                                    //     fullWidth
                                    //     sx={HrReporttop.datepickertop}
                                    // />}
                                    />
                                </LocalizationProvider>
                            </Box> */}
                            <Box sx={{ marginRight: "16px" }}>
                                <Autocomplete
                                    value={
                                        Years?.find(
                                            (option: any) => option.id == selectedYear
                                        ) ?? null
                                    }
                                    sx={{
                                        mr: 1,
                                        lineHeight: "0.80em",
                                        width: "120px",
                                    }}
                                    componentsProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: true,
                                                    options: {
                                                        altBoundary: true,
                                                        flipVariations: true,
                                                        altAxis: true,
                                                        padding: 8,
                                                        fallbackPlacements: ['bottom'],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                    fullWidth
                                    id="outlined-basic"
                                    options={Years}
                                    getOptionLabel={(option: any) => option.label}
                                    onChange={async (event, value) => {
                                        setfiltersSelected(true);
                                        setSelectedYear(value?.label ?? currentYearString)
                                        if (value?.label == currentYear) {
                                            setMonthStatus(currentMonth)
                                        }
                                        if (!value) {
                                            setMonthStatus(currentMonth)
                                        }
                                    }}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="selectedResourceId"
                                            variant="outlined"
                                            value={selectedYear}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{ marginRight: "16px" }}>
                                <TextField
                                    sx={{ minWidth: 160 }}

                                    id="outlined-basic"
                                    select
                                    value={monthStatus}
                                    variant="outlined"
                                    onChange={
                                        handleMonthChange
                                    }
                                >
                                    {(selectedYear == currentYear ? options.slice(0, currentMonth) : options)?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <Button
                                onClick={() => {

                                    if (!!!departmentHook) {
                                        toast.warning("Please select Department First ")
                                    }
                                    else {
                                        getReport(departmentHook, monthStatus !== undefined ? monthStatus : 0,
                                            resourceHook, StaticStatusId !== undefined ? StaticStatusId : 0, selectedYear ? selectedYear : currentYearString);
                                    }
                                }}
                                color="primary"
                                variant="contained"
                                sx={{ ml: 0.5, mb: { xs: "20px", md: "0px" }, }}
                            >
                                Apply
                            </Button>
                            {filtersSelected && <Button
                                onClick={async () => {
                                    setfiltersSelected(false);
                             
                                    setMonthStatus(getCurrentMonth());
                                    setResourceId("");
                                    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                        await setDepartmentId(_authUser.employeeInfo.departmentId);
                                    }
                                    else {
                                        setDepartmentId("");
                                    }
                                    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                        setStaticStatusId(0);
                                        await setDepartmentHook(_authUser.employeeInfo.departmentId);
                                        // await setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                                        await getReport(_authUser.employeeInfo.departmentId, monthHook, "", 0, currentYearString);
                                    }
                                    else {
                                        setStaticStatusId(1);
                                        await setDepartmentHook("");
                                        // await setresources(await clsResourcesList.getResourcesDropdownList(""));

                                        setreports([]);
                                    }
                                    await setresourceHook("");
                                    await setMonth(setDateWithoutTime(today))
                                    setSelectedYear(currentYear);

                                }}
                                color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                            {/* <Button
                                onClick={async () => {navigation('/hr-monthly', { state: {id:row?.userId,
                                                type:StaticStatusId === 1 ? 1 : (StaticStatusId === 0 ? 1 : 2),
                                                month:monthStatus} });}}>ddddddd</Button> */}
                        </Stack>
                    </Box>

                </Stack>
                <Box sx={{ mt: 2 }}>
                    {reports && reports.length > 0 &&

                        <TableContainer component={Paper} elevation={0} sx={{
                            mt: 0.5,
                            maxHeight: "calc(100vh - 215px)",
                            [theme.breakpoints.down("sm")]: {
                                maxWidth: "400px",
                            },
                        }}>
                            <Table stickyHeader aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ position: 'sticky', left: '0', width: '90px', zIndex: "10" }} >Emp ID</TableCell>
                                        <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', zIndex: "10" }}>Name</TableCell>
                                        {/* {_authUser.typeOfUser == eTypeOfUser.Admin && (
                                            <TableCell>Department</TableCell>)} */}
                                            { StaticStatusId ===0&&(<TableCell>Type</TableCell>)}
                                        {StaticStatusId === 1 || StaticStatusId === 0 ? (
                                            <>
                                                <TableCell>Active Hours</TableCell>
                                                <TableCell>Offline Hours</TableCell>
                                                <TableCell>Total Hours</TableCell>
                                                <TableCell>Present</TableCell>
                                                <TableCell>Absent</TableCell>
                                                <TableCell>Leave</TableCell>
                                                <TableCell>Lop Leave Count </TableCell>
                                                <TableCell>Total Earned Leave Taken </TableCell>
                                                <TableCell>Total Leave Taken (Casual + Sick) </TableCell>
                                                <TableCell>Total Leave Balance (Casual + Sick)</TableCell>
                                                <TableCell>Marriage Leave</TableCell>
                                                <TableCell>Maternity Leave </TableCell>
                                                <TableCell>Paternity Leave</TableCell>

                                            </>
                                        ) : (
                                            <>
                                                {/* <TableCell>Login Time</TableCell>
                                                <TableCell>Logout Time</TableCell> */}
                                                <TableCell>Total Time</TableCell>
                                                <TableCell>Present</TableCell>
                                                <TableCell>Absent</TableCell>
                                                <TableCell>Leave</TableCell>
                                                <TableCell>Lop Leave Count </TableCell>
                                                <TableCell> Total Earned Leave Taken </TableCell>
                                                <TableCell>Total Leave Taken (Casual + Sick)</TableCell>
                                                <TableCell>Total Leave Balance (Casual + Sick)</TableCell>
                                                <TableCell>Marriage Leave</TableCell>
                                                <TableCell>Maternity Leave </TableCell>
                                                <TableCell>Paternity Leave</TableCell>

                                            </>
                                        )}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.map((row, index) => (
                                        <TableRow style={{ textDecoration: 'none' }}
                                            component={Link}
                                            to={`/hr-monthly/${row?.userId}/${row?.isTracker == true ? 1 : (row?.isTracker === false ? 2 : 2)}/${monthStatus}/${selectedYear ? selectedYear : currentYearString}`}
                                            // to={`/hr-monthly`}
                                            // state={{
                                            //     id: row?.userId,
                                            //     type: StaticStatusId === 1 ? 1 : (StaticStatusId === 0 ? 1 : 2),
                                            //     month: monthStatus,
                                            //     year: selectedYear ? selectedYear : currentYearString
                                            // }}

                                            target="_blank"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }} >{row.employeeId}</TableCell>
                                            <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', background: '#fff', }}>{row.name}</TableCell>
                                            {/* {_authUser.typeOfUser == eTypeOfUser.Admin && (
                                                <TableCell >{row.department}</TableCell>)} */}
                                                 { StaticStatusId ===0&&(<TableCell>{row?.isTracker == true ?"Tracker":"Portal"}</TableCell>)}
                                            {StaticStatusId === 2 ? (
                                                // Render these cells if isTrackerRequired is true
                                                <>
                                                    {/* <TableCell>{row?.loginTime || "--"}</TableCell>
                                                    <TableCell>{row?.logoutTime || "--"}</TableCell> */}
                                                    <TableCell>{row?.totalHours || "--"}</TableCell>
                                                    <TableCell><Chip variant="approved" label={row.present} /></TableCell>
                                                    <TableCell><Chip variant="hold" label={row.absent} /></TableCell>
                                                    <TableCell><Chip variant="review" label={row.leave} /></TableCell>
                                                    <TableCell><Chip variant="progress" label={row.lopLeaveCount} /></TableCell>
                                                    <TableCell><Chip variant="hold" label={row.earnedLeaveTaken} /></TableCell>
                                                    <TableCell><Chip variant="progress" label={row.leaveTaken} /></TableCell>
                                                    <TableCell><Chip variant="progress" label={row.leaveBalance} /></TableCell>
                                                    <TableCell><Chip variant="review" label={row.marriageLeave ? "Yes" : "No"} /></TableCell>
                                                    <TableCell><Chip variant="approved" label={row.maternityLeave ? "Yes" : "No"} /></TableCell>
                                                    <TableCell><Chip variant="review" label={row.paternityLeave ? "Yes" : "No"} /></TableCell>

                                                </>
                                            ) : (
                                                // Render these cells if isTrackerRequired is false
                                                <>
                                                    <TableCell>{row?.activeHours}</TableCell>
                                                    <TableCell>{row?.offlineHours}</TableCell>
                                                    <TableCell>{row?.totalHours}</TableCell>
                                                    <TableCell><Chip variant="approved" label={row.present} /></TableCell>
                                                    <TableCell><Chip variant="hold" label={row.absent} /></TableCell>
                                                    <TableCell><Chip variant="review" label={row.leave} /></TableCell>
                                                    <TableCell><Chip variant="progress" label={row.lopLeaveCount} /></TableCell>
                                                    <TableCell><Chip variant="hold" label={row.earnedLeaveTaken} /></TableCell>
                                                    <TableCell><Chip variant="progress" label={row.leaveTaken} /></TableCell>
                                                    <TableCell><Chip variant="progress" label={row.leaveBalance} /></TableCell>
                                                    <TableCell><Chip variant="review" label={row.marriageLeave ? "Yes" : "No"} /></TableCell>
                                                    <TableCell><Chip variant="approved" label={row.maternityLeave ? "Yes" : "No"} /></TableCell>
                                                    <TableCell><Chip variant="review" label={row.paternityLeave ? "Yes" : "No"} /></TableCell>

                                                </>
                                            )}

                                        </TableRow>))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    }
                    {reports.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                    {/* <TablePagination
                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        component="div"
                                        count={reports.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={() => `Total: ${reports?.length}  Page ${page + 1} of ${pageCount}`}
                                    /> */}
                </Box>
            </Paper>

            <Dialog open={open} onClose={handleClose}

                fullWidth
                maxWidth="md"

            >
                <IconButton
                    aria-label="delete"
                    sx={HrReporttop.styleCross}
                    onClick={handleClose}
                >
                    <ClearIcon />
                </IconButton>


                <DialogContent sx={{
                    pt: 5, "& img": {
                        maxWidth: '100%'
                    }
                }}>
                    <img src={openPopupImgPath} />
                </DialogContent>

            </Dialog>
        </>
    );
}