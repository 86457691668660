import React, { useState, useEffect } from "react";
import theme from "../../theme/theme";
import { Typography, Paper, Stack, TextField, Box, Button, Grid, Autocomplete, CircularProgress, TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "material-react-toastify";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { useSelector } from "react-redux";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import { DatePicker } from 'react-rainbow-components';
import NotFound from "../../components/styledComponent/NotFound";
import { reportService } from "../../service/Reports/reports";
import { clsResourcesList } from "../../service/common-services/resources";
const HrReporttop = {
toggleloaders1: {
    left: "50%",
    right: "50%",
    top: "25%",
    bottom: "50%",
    transform: "translate(-50%, -50%)",
    position: 'relative',
    zIndex: "999"
},}
export default function EmployeeTaskReport() {
    const handleCellClick = (projectsId: any, userId: any) => {
        const stateParams = {
            projectsId,
            userId,
            startDate: dayjs(fromDate).format("DD-MMM-YYYY"),
            endDate: dayjs(toDate).format("DD-MMM-YYYY")
        };
        
        const queryString = new URLSearchParams(stateParams).toString();
        const newWindowUrl = `/report-management?${queryString}`;
        window.open(newWindowUrl, "_blank");
        console.log(projectsId, "abc");
        console.log(userId, "id");
        console.log(dayjs(fromDate).format("DD-MMM-YYYY"), "startDate");
        console.log(dayjs(toDate).format("DD-MMM-YYYY"), "endDate");
    };

    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [selectedDepartmentId, setDepartmentId] = useState("");
    const [toggleLoader, setToggleLoader] = useState(false);
    const [departmentList, setDepartmentList] = useState<Department[]>([]);
    const [disabledDepartments, setdisabledDepartments] = useState(false);
    const [resources, setresources] = React.useState<any[]>([]);
    const [selectedResourcesId, setSelectedResourcesId] = React.useState("");
    const [reports, setReport] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);

    const initialFromDate = dayjs().subtract(7, "day").toDate();
    // Initialize toDate to the current date as a Date object
    const initialToDate = dayjs().toDate();

    const [fromDate, setFromDate] = useState<Date>(initialFromDate);
    const [toDate, setToDate] = useState<Date>(initialToDate);
    const [filtersSelected, setFiltersSelected] = useState(false);


    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(reports.length / rowsPerPage);
    const [page, setPage] = React.useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const getEmployeeTaskReportList = (selectedDepartmentId: any,resorceId:string, fromDate: any, toDate: any) => {
        setToggleLoader(true);
        setLoading(true);
        setPage(0);

        try {
            reportService.getEmployeesProjectDailyReport({
                departmentId: selectedDepartmentId,
                resourceId:resorceId,
                startDate: fromDate ? dayjs(fromDate).format("DD-MMM-YYYY") : "",
                endDate: toDate ? dayjs(toDate).format("DD-MMM-YYYY") : "",
            })
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.error("Something Went Wrong, Please try again.");
                        setToggleLoader(false);
                    } else {
                        setReport(response.data);
                        setLoading(false);
                        setToggleLoader(false);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setLoading(false);
                setToggleLoader(false);
            });
        }
    };
    useEffect(() => {
        // if (_permission.page_ManageMilestoneReport_Access !== true) {
        //     navigation("/dashboard");
        // }
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                // if (_permission.action_MilestoneReport_ReportListing_AllDepartments_Access == true) {
                //     setdisabledDepartments(false);
                // } else {
                //     setdisabledDepartments(true);
                // }
                setDepartmentId(_authUser.employeeInfo.departmentId);
                setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
                setresources(
                    await clsResourcesList.getResourcesDropdownList(
                      _authUser.employeeInfo.departmentId
                    )
                  );
            } else {
                setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
            }
        };
        departmentsData();
    }, []);
    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders1} />)}
            <Paper elevation={0} sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99, background: 'white', height: 'auto' }}>
                <Box>
                    <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center" >
                        <Grid item xs={12} lg={3} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>  Task Report  </Typography>
                            {/* <InfoPopover onInput={"Reports"} /> */}
                        </Grid>
                        <Grid item xs={12} lg={9} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Paper elevation={0} sx={{ position: "sticky", top: "64px ", zIndex: 99, gap: 0.5, }}>
                                <Stack justifyContent={'flex-start'} alignItems={'self-start'}
                                    sx={{ position: 'relative', flexDirection: "row", gap: 1, alignItems: "flex-end" }}>
                                    <Autocomplete
                                        fullWidth
                                        value={
                                            departmentList?.find(
                                                (option) => option.id == selectedDepartmentId
                                            ) ?? null
                                        }
                                        id="outlined-basic"
                                        options={departmentList}
                                        getOptionLabel={(option) => option.name}
                                        disabled={disabledDepartments}
                                        onChange={async (event, value) => {
                                            setDepartmentId(value?.id ?? "");
                                            setFiltersSelected(true);
                                            setSelectedResourcesId("")
                                            if(value){
                                            setresources(
                                                await clsResourcesList.getResourcesDropdownList(
                                                  value?.id ?? ""
                                                )
                                              );}
                                             
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{ minWidth: "220px" }}
                                                id="outlined-basic"
                                                name="element.name"
                                                variant="outlined"
                                                value={selectedDepartmentId}
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        disabled={!selectedDepartmentId || selectedDepartmentId === ""}
                                        value={
                                            resources?.find(
                                                (option:any) => option.userId == selectedResourcesId
                                            ) ?? null
                                        }
                                        fullWidth
                                        id="outlined-basic"
                                        options={resources}
                                        getOptionLabel={(option:any) => option.userName}
                                        onChange={async (event, value) => {
                                            setSelectedResourcesId(value?.userId ?? "");
                                            setFiltersSelected(true);
                                        }}
                                        componentsProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: "flip",
                                                        enabled: true,
                                                        options: {
                                                            altBoundary: true,
                                                            flipVariations: true,
                                                            altAxis: true,
                                                            padding: 8,
                                                            fallbackPlacements: ["bottom"],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select Resources"
                                                id="outlined-basic"
                                                name="element.name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="From"
                                            placeholder="From"
                                            value={fromDate || undefined}
                                            borderRadius="semi-square"
                                            onChange={(newValue: Date | null) => {
                                                if (newValue) {
                                                    const startDate = dayjs(newValue);
                                                    setFromDate(startDate.toDate());

                                                    // Automatically set end date to 7 days after the start date, but not beyond today.
                                                    const newEndDate = startDate.add(7, "day");
                                                    const today = dayjs();

                                                    if (!toDate || newEndDate.isAfter(today)) {
                                                        setToDate(today.toDate());
                                                    } else {
                                                        setToDate(newEndDate.toDate());
                                                    }

                                                    setFiltersSelected(true);
                                                }
                                            }}
                                            maxDate={dayjs().toDate()}  // Limit fromDate to the current date
                                        />
                                        <DatePicker
                                            disabled={!fromDate}
                                            label="To"
                                            placeholder="To"
                                            minDate={fromDate || undefined}
                                            maxDate={dayjs().toDate()}  // Limit toDate to the current date
                                            borderRadius="semi-square"
                                            value={toDate || undefined}
                                            onChange={(newValue: Date | null) => {
                                                if (newValue) {
                                                    const endDate = dayjs(newValue);
                                                    setToDate(endDate.toDate());
                                                    setFiltersSelected(true);
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                    <Button color="primary" sx={{ ml: 0.5 }} onClick={() => {
                                        if (!selectedDepartmentId) {
                                            toast.warning("Please select Department First ")
                                        }
                                        else {
                                            getEmployeeTaskReportList(selectedDepartmentId,selectedResourcesId, fromDate, toDate);
                                        }
                                    }}
                                        variant="contained">
                                        Apply
                                    </Button>
                                    {filtersSelected && (
                                        <Button
                                            onClick={() => {
                                                setFiltersSelected(false);
                                                // setFromDate(null);
                                                // setToDate(null);
                                                setReport([]);
                                                if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                                    setDepartmentId(_authUser.employeeInfo.departmentId);
                                                } else {
                                                    setDepartmentId("");
                                                }
                                                setToggleLoader(false);
                                                setSelectedResourcesId("");
                                            }}
                                            color="warning"
                                            variant="contained"
                                            sx={{ ml: 0.5 }}
                                        >
                                            Clear
                                        </Button>
                                    )}
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                {reports?.length > 0 && (
                    <Paper elevation={0} sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99 }}  >
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <TableContainer component={Paper} elevation={0} sx={{
                                mt: 0.5, maxHeight: "calc(100vh - 280px)",
                                [theme.breakpoints.down("sm")]: { maxWidth: "400px", },
                            }} >
                                <Table aria-label="collapsible table" size="small" stickyHeader >
                                    <TableHead>
                                        <TableRow sx={{ "& th, & td": { whiteSpace: "nowrap" } }} >
                                            <TableCell width={80}>Sr no.</TableCell>
                                            <TableCell width={80}>Emp ID</TableCell>
                                            <TableCell width={100}>Emp Name</TableCell>
                                            <TableCell width={120}>Department Name</TableCell>
                                            <TableCell width={190}>Projects Name</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => (
                                            <TableRow key={index}>
                                                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                <TableCell>{row.employeeId != null ? row.employeeId : "--"}</TableCell>
                                                <TableCell>{row.employeeName != null ? row.employeeName : "--"}</TableCell>
                                                <TableCell>{row.departementName != null ? row.departementName : "--"}</TableCell>
                                                <TableCell>
                                                    {row.projectsList.length > 0 ? (
                                                        row.projectsList.map((project: any, idx: any) => (
                                                            <span
                                                                key={idx}
                                                                style={{
                                                                    padding: "6px",
                                                                    borderRadius: "12px",
                                                                    display: "inline-block",
                                                                    marginRight: "8px",
                                                                    marginBottom: "8px",
                                                                    fontSize: "12px",
                                                                    color: "#fff",
                                                                    backgroundColor: project.isWorking ? 'green' : ' red',
                                                                    cursor: 'pointer',
                                                                    fontWeight: "bold"
                                                                }}
                                                                onClick={() => handleCellClick(project.projectId, row.userId)}
                                                            >
                                                                {project.projectName != null ? project.projectName : "--"}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        "--"
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>
                    </Paper>
                )}
                {reports.length === 0 && (
                    <NotFound NotfoundText="No Result Found" />
                )}
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={reports?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={() => `Total: ${reports?.length}  Page ${page + 1} of ${pageCount}`}
                />
            </Paper>
        </>
    );
}
