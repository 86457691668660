import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { Formik, useFormikContext } from "formik";
import { styled } from "@material-ui/core";
import { roleManagementServive } from "../../service/role-management-service";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import StepsRoles from "./StepsRoles";
import MenuItem from "@mui/material/MenuItem";
import { toast } from 'material-react-toastify';
import { useSelector } from "react-redux";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import { Typography } from "@mui/material";
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
}));

interface Role {
  id: "";
  name: "";
}

const lookup = [
  { id: 1, value: "one" },
  { id: 2, value: "two" },
  { id: 3, value: "three" },
];
const steps = [
  'Update Role',
  'Grant Permissions',
];
export default function AddRole() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [users, setUsers] = useState<Role[]>([]);
  const [selected, setSelected] = useState('');

  const { id } = useParams();
  const [role, setRole] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const [formikName, setFormikName] = useState("");
  const [formikId, setFormikId] = useState("");
  var formdata = { name: formikName, id: formikId }; 
  useEffect(() => {
    if (_authUser.typeOfUser != eTypeOfUser.Admin){
      navigate("/dashboard");}
    getRoleDetails();
    getRoleDetails1();
  }, []);


  const _handleErrorInputChange = (event: any) => {
    
    setSelected(event.target.value)

  }
  const getRoleDetails1 = async () => {
    let roleDataArr: any = [];
    await roleManagementServive.getRole().then((response: any) => {
      roleDataArr.push(response);
      if (response.data.isError) {
      } else {
        setUsers(response?.data);
      }
    });
    setRole(roleDataArr);
  };

  const getRoleDetails = async () => {
    // getUsers();

    await roleManagementServive.getRoleById(id).then((response: any) => {
      if (response.data.isError) {
      } else {
       
        setUsers(response?.data?.data);
        setFormikName(response?.data?.name);
        setFormikId(response?.data?.id);
      }
    });
  };

  const addEditRoleManagementDetails = async (values: any) => {
   
    if (visible && selected == '') {
      toast.warning('Please existing role, which you want to copy');
      return false;
    }
    await roleManagementServive.updateRole(values).then((response: any) => {
      if (response.data.isError) {
      } else { 
        
        // if(selected != '')
        // {
        //   copyRoleManagementDetails(response)
        // }
        // toast.success('Role Updated Successfully');
        navigate("/role-management/update-permission/" + response.data);
      }
    }).catch((error) => {
      if (error.data.Error.length > 0)
      
        toast.error(error.data.Error[0], {
          onOpen: () => setButtonDisable(true),
          
          onClose: () => setButtonDisable(false),
        });

      return error;
    });

    // navigate("/role-management");
  };

  const copyRoleManagementDetails = async (FromTo: any) => {
    var req = {
      "copyFromRoleId": selected,
      "copyToRoleId": id
    }
    await roleManagementServive.copyFromToRole(req).then((response: any) => {
      if (response.data.isError) {
      } else {
        toast.success('Role Updated Successfully');
        navigate("/role-management")
      };
    });
  };

  // console.log();

  <Paper elevation={0} sx={{ p: 3, height: "calc(100vh - 142px )" }}></Paper>;
  return (
    <>
      <Formik
        initialValues={{
          id: formikId,
          name: formikName,
        }}
        initialValues1={{
          copyFromRoleId: formikId,
          copyToRoleId: id,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Role Name is required"),
        })}
        onSubmit={async (values: any) => {
          try {
            await addEditRoleManagementDetails(values);
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
             
      <Paper elevation={0} sx={{ p: 3 }}>
      <Typography variant="h6" component="h2" sx={{ fontWeight: 400, width:'30%',mb:"3px", ml:"5px" }}>
            {"Update Role"}
          </Typography>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 5 , mt:5}}
            >
              <Grid item xs={12} sx={{ mb: 5 }}>
                <StepsRoles currentStep={0} steps={steps} />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  id={values?.id}
                  label="Edit Role Name"
                  variant="outlined"
                  margin="normal"
                  name="name"
                  value={values?.name.replace(/\s{2,}$/g, "").trimStart()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"

                // variant="standard"
                />
                {/* <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Copy from edit existing role"
                    onClick={() => setVisible(!visible)}
                  />
                </FormGroup>

                <Box sx={{ maxWidth: 200, mt: 3 }}>
                  {visible && (
                    <TextField
                      id="outlined-basic"
                      select
                      label="Select Role"
                      variant="outlined"
                      onChange={_handleErrorInputChange}
                      fullWidth
                    >
                      {role?.[0]?.data?.map((roles: any,idx : any) => {
                        // console.log(roles?.name)
                        return (
                          <MenuItem key={idx} value={roles?.id}>
                            {roles?.name}
                          </MenuItem>
                        );
                      })}

                    </TextField>
                  )}
                </Box> */}
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {/* <Button  onClick={() => addTechnicalSkillDetails()} variant="contained"  component={Link} to='/role-management/addRole'>Next</Button> */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    disabled={buttonDisable}
                  >
                    Next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            </Paper>
          </form>
        )}
      </Formik>
    </>
  );
}
