import * as React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { CircularProgress, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import * as Icons from "../../assests/icons/icons";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import { useSelector } from "react-redux";
import { useState } from "react";
import { kraratingServices } from "../../service/kra-rating-service";
import ClearIcon from "@mui/icons-material/Clear";
import { userManagementServive } from "../../service/user-management-service";
import { Monts } from "../../Enum/eMonths";
import { toast } from "material-react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from '@mui/material/DialogContentText';
import moment from "moment";
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import NotFound from "../../components/styledComponent/NotFound";
import { generateYearRangeObjects, getCurrentYearId } from "../../Enum/KRA";
import FileUpload from "../../components/styledComponent/FileUpload";
import { projectManagementServive } from "../../service/project-management-service";
import { AddBoxTwoTone } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HrReporttop from "../reports/HrReportsstyle";
import FileNotFound from "../../components/styledComponent/FileNotFound";


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IFiles {
    itemType: string;
    name: string;
    file?: any;
    fileURL: string;
    uploadedBy?: any;
    fileName: string;
    ext?: any;
}

export default function RateKra() {

    const [open, setOpen] = React.useState(false);

    const reviewDetails = (kraId: any, index: number) => {
        debugger
        setSelectedKraId(kraId);
        getAllKRAcomments(kraId, index);
    };

    const handleClose = () => {
        setOpen(false);
        setComment([]);
        setValidationMessage("");
    };

    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector(
        (state: any) => state.UserPermissionApp.UserPermission
    );
    const [buttonDisable, setButtonDisable] = useState(false);
    const [ratingForms, setRatingForms] = useState<any>([]);
    const [userDetail, setUserDetail] = useState<any>();
    const [selectedYear, setSelectedYear] = useState("");
    const [yearValue, setYearValue] = useState<any>([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isMonthZero, setMonthZero] = React.useState(false);

    const [openDeleteAlert, setDeleteAlertOpen] = React.useState(false);
    const [idForKraDelete, setIdForKraDelete] = React.useState("");
    const [indexValueForKraDelete, setIndexValueForKraDelete] = React.useState<number | null>(null);
    const [roadMapDetails, setRoadMapDetails] = useState<any>([]);
    const { id } = useParams();
    const [currentQuater, setCurrentQuater] = useState(0);
    const [comment, setComment] = useState<any[]>([]);
    const [selectedKraId, setSelectedKraId] = useState(null);

    const [kraComment, setKRAComment] = useState<any[]>([]);

    const [dialogComment, setDialogComment] = React.useState(false);
    const [idForCommentDelete, setIdForCommentDelete] = React.useState("");
    const navigate = useNavigate();

    const [validationMessage, setValidationMessage] = useState<any>("");

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [roadMapComment, setRoadmapComment] = useState<any>({});
    
    const [uploadFileIndex, setUploadFileIndex] = useState<any>({});
    const [mainIndex, setMainIndex] = useState<any>({});


    const [roadmapValidationMessage, setRoadmapValidationMessage] = useState<any[]>([]);
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [editingIndex, setEditingIndex] = useState<{ mainIndex: number, roadmapIndex: number } | null>(null);
    console.log('editingIndex----->>editingIndex', uploadFileIndex);
    const [hasChanges, setHasChanges] = useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [yearLabel, setYearLabel] = useState<any>("");

    const handleDeleteDialogclose = () => {

        setDeleteAlertOpen(false);
        setIdForKraDelete("");
        setIndexValueForKraDelete(null);
        getallKraByUserIds(id, selectedYear ?? getCurrentYearId(yearValue));

    }

    const handleDialogCommentclose = () => {

        setDialogComment(false);
        setIdForCommentDelete("");

    }
    const location = useLocation();
    const { additionalVar } = location.state || {};
    console.log("additionalVar ===", additionalVar)
    
    React.useEffect(() => {
        getUserDetailsByUserId(id);

    }, []);



    const KraRatingSave = async () => {

        try {
            const promises = ratingForms.kraDetails.map(async (element: any) => {
                delete element.userId;
                delete element.departmentId;
                delete element.kraDate;
                await kraratingServices.UpdateKRA(element).then((response: any) => {
                    if (response.data.isError) {

                    } else {

                    }
                });
            });
            await Promise.all(promises);
            toast.success("Data Updated Successfully", {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
            });
            setHasChanges(false);
        } catch (ex: any) {
            if (ex.data && ex.data.Error) {
                ex.data.Error.forEach((err: string) => {
                    toast.warn(err, {
                        onOpen: () => setButtonDisable(true),
                        onClose: () => setButtonDisable(false),
                    });
                });
            } else {
                toast.error("An unexpected error occurred", {
                    onOpen: () => setButtonDisable(true),
                    onClose: () => setButtonDisable(false),
                });
            }
        }
    };




    const getUserDetailsByUserId = async (userId: any) => {
 debugger
        try {
            setToggleLoader(true);
            await userManagementServive.getUserById(userId).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                }
                else {

                    setUserDetail(response?.data);
                    const providedYear = response.data?.employeeInfo?.confirmationDate ?
                        new Date(response?.data?.employeeInfo.confirmationDate).getFullYear() : new Date().getFullYear();
                    const providedMonth = response.data?.employeeInfo?.appraisalMonth ? response.data?.employeeInfo?.appraisalMonth : 0;
                    const yearRangeObjects = generateYearRangeObjects(providedYear, providedMonth);
                    setYearValue(yearRangeObjects)
                  console.log("year",yearRangeObjects )
                    getallKraByUserIds(userId, additionalVar ? additionalVar : getCurrentYearId(yearRangeObjects))
                    setSelectedYear(additionalVar ? additionalVar : getCurrentYearId(yearRangeObjects));
             
                 
                    const isMonthZero = providedMonth === 0;
                    setMonthZero(isMonthZero);
                    generateQuarters(providedMonth);
                    setToggleLoader(false);
                }
            })
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
            });
        }
    }

    const deleteKraByid = async () => {

        try {
            if (indexValueForKraDelete !== null && indexValueForKraDelete !== undefined) {
                const updatedForms = [...ratingForms.kraDetails];
                updatedForms.splice(indexValueForKraDelete, 1);
                setRatingForms(updatedForms);
            }
            await kraratingServices.deleteKraById(idForKraDelete).then((response: any) => {
                if (response.data.isError) {
                }
                else {
                    toast.success("KRA Deleted Successfully")
                    handleDeleteDialogclose();
                    getUserDetailsByUserId(id);
                }
            })
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {

                toast.warn(err)
                handleDeleteDialogclose();
            });
        }
    };

    console.log("ratingForms==", ratingForms)

    const getallKraByUserIds = async (userId: any, year: any) => {

        let prm = {
            "userId": userId,
            "year": year
        }
        try {
            await kraratingServices.getallKraByUserId(prm).then((response: any) => {
                if (response.data.isError) {
                }
                else {
                    setRatingForms(response?.data);
                }
            })
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warn(err)
            });
        }
    };
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    const currentMonth1 = (new Date().getMonth() + 1);
    const quarter = Math.ceil(currentMonth1 / 3);

    const generateQuarters = (providedMonth: number) => {

        const quarters: number[][] = [];
        let appraisalMonth = providedMonth;

        for (let i = 0; i < 4; i++) {
            const quarter: number[] = [];
            for (let j = 0; j < 3; j++) {
                quarter.push(appraisalMonth);
                appraisalMonth = (appraisalMonth % 12) + 1;
            }
            quarters.push(quarter);
        }

        const currentMonth = new Date().getMonth() + 1;
        const currentQuarterIndex = findIndex(quarters, currentMonth);
        console.log("Current quarter index:", currentQuarterIndex);
        console.log("quater:", quarters);

        return { quarters };
    };

    function findIndex(arr: number[][], value: number) {

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].includes(value)) {
                isCheckboxEnabled(i + 1);
                setCurrentQuater(i + 1);
                return i;

            }
        }
        return -1;
    }


    const isCheckboxEnabled = (quarterNumber: any) => {
        return quarterNumber === currentQuater;
    };


    const styleCross = {
        position: "absolute" as "absolute",
        right: "5px",
        top: "5px",
    };

    function handleChange(index: any, field: any, value: any) {


        const updatedRatingForms = { ...ratingForms };
        const employee = updatedRatingForms.kraDetails[index];;
        if (employee) {
            const kraDetail = employee;
            if (kraDetail) {

                switch (field) {
                    case 'self':
                        if (!kraDetail.self) {
                            kraDetail.self = { q1: 0, q2: 0, q3: 0, q4: 0 };
                        }
                        kraDetail.self[value.field] = value.rating;
                        break;
                    case 'io':
                        if (!kraDetail.io) {
                            kraDetail.io = { q1: 0, q2: 0, q3: 0, q4: 0 };
                        }
                        kraDetail.io[value.field] = value.rating;
                        break;
                    case 'manager':
                        if (!kraDetail.manager) {
                            kraDetail.manager = { q1: 0, q2: 0, q3: 0, q4: 0 };
                        }
                        kraDetail.manager[value.field] = value.rating;
                        break;
                    case 'hr':
                        if (!kraDetail.hr) {
                            kraDetail.hr = { q1: 0, q2: 0, q3: 0, q4: 0 };
                        }
                        kraDetail.hr[value.field] = value.rating;
                        break;
                    default:
                        console.error('Invalid field provided.');
                        break;
                }
                setRatingForms(updatedRatingForms);
                setHasChanges(true);
                console.log(updatedRatingForms, "updatedRatingFormsupdatedRatingForms")
            } else {
                console.error('kraDetails not found at index:', index);
            }
        } else {
            console.error('Employee not found at index:', index);
        }
    }


    const [showMoreStates, setShowMoreStates] = useState<number[]>([]);

    
    const toggleShowMore = (index: number) => {
        setMainIndex(index)
        debugger
        let isIndexInArray = showMoreStates.includes(index);
        const updatedTabValues = [...tabValues];
        updatedTabValues[index] = 0;
        setTabValues(updatedTabValues);

        if (isIndexInArray) {

            let updateArray = showMoreStates.filter((item) => item !== index);
            setShowMoreStates(updateArray);
        } else {

            let newValue = [...showMoreStates, index];
            setShowMoreStates(newValue);
            console.log("current tab value", tabValues);
        }
    };
    const [tabValues, setTabValues] = useState<number[]>([]);
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number, index: number) => {
        debugger
        const updatedTabValues = [...tabValues];
        updatedTabValues[index] = newValue;
        setTabValues(updatedTabValues);

        const selectedId = ratingForms.kraDetails[index].id;


        setSelectedKraId(selectedId);

        if (newValue === 2) {

            debugger
            reviewDetails(selectedId, index);

        }
    };

    // ----------------------------Review Tab Comment Section--------------------------------------------

    const handleCommentChange = (index: number, e: any) => {
        const value = e.target.value;
        const newComments = [...comment];
        newComments[index] = value.slice(0, 200);

        if (value.length > 200) {
            const newValidationMessages = [...validationMessage];
            newValidationMessages[index] = "The comment must be 200 characters or less.";
            setValidationMessage(newValidationMessages);
        } else {
            const newValidationMessages = [...validationMessage];
            newValidationMessages[index] = "";
            setValidationMessage(newValidationMessages);
        }
        setComment(newComments);
    }


    const saveComment = async (index: number) => {
        debugger;
        const comments = comment[index] ?? "";

        if (!comments.trim()) {
            debugger;
            const newValidationMessages = [...validationMessage];
            newValidationMessages[index] = "Comment is required";
            setValidationMessage(newValidationMessages);
            return;
        }

        const newValidationMessages = [...validationMessage];
        newValidationMessages[index] = "";
        setValidationMessage(newValidationMessages);

        await addComment(selectedKraId, comments);

        const newComments = [...comment];
        newComments[index] = "";
        setComment(newComments);
        await getAllKRAcomments(selectedKraId, index);
    };


    const addComment = async (kraId: any, comment: any) => {
        debugger
        let data = {
            "kraId": kraId,
            "comment": comment
        }

        try {
            await kraratingServices.addComment(data).then((response: any) => {

            })
            toast.success("Comment added successfully");
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warn(err)
            });
        }
    };

    const getAllKRAcomments = async (kraId: any, index: number) => {
        debugger
        try {
            const response = await kraratingServices.getAllKraCommentByKraId(kraId);
            if (!response.data.isError) {
                debugger
                setKRAComment((prevComments: any) => {
                    debugger
                    const updatedComments = [...prevComments];
                    updatedComments[index] = response?.data;
                    return updatedComments;
                });
            }
        } catch (ex: any) {
            ex.data.Error.forEach((err: any) => {
                toast.warn(err);
            });
        }
    };


    const deleteCommentByid = async (index: number) => {

        try {

            await kraratingServices.deleteCommentById(idForCommentDelete).then((response: any) => {
                if (response.data.isError) {
                }
                else {
                    toast.success("Comment Deleted Successfully")
                    handleDialogCommentclose();
                    getAllKRAcomments(selectedKraId, index);
                }
            })
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {

                toast.warn(err)
            });
        }
    };




    //  ----------------------- Roadmap Tab comment section--------------------------------


    const [isUserEditing, setIsUserEditing] = React.useState<boolean>(false);


    const handleRoadmapCommentChange = (index: number, checkpointIndex: number, e: any) => {
        debugger
        const value = e.target.value;
        const newRoadmapComments = { ...roadMapComment };
        const newValidationMessages = { ...roadmapValidationMessage };
       
        if (!newRoadmapComments[index]) {
            newRoadmapComments[index] = {};
        }
        if (!newValidationMessages[index]) {
            newValidationMessages[index] = {};
        }
 
        // Set editing status to true when user starts modifying the comment
        setIsUserEditing(true);
 
        // Handle empty input case
        if (value.trim() === "") {
            newRoadmapComments[index][checkpointIndex] = ""; // Clear the comment value
            newValidationMessages[index][checkpointIndex] = ""; // Reset validation message as well
        } else {
            
            newRoadmapComments[index][checkpointIndex] = value.slice(0, 250);
 
            if (value.length > 250) {
                newValidationMessages[index][checkpointIndex] = "Comment must be 250 characters or less.";
            } else {
                newValidationMessages[index][checkpointIndex] = "";
            }
        }
 
        setRoadmapComment(newRoadmapComments);
        setRoadmapValidationMessage(newValidationMessages);
    };

    let IfileObj: IFiles = {
        itemType: "",
        name: "",
        fileURL: "",
        fileName: "",
        file: null,
    };
    let objDocument: IFiles[] = [];
    const [uploadFile, setUploadFile] = useState<any[][]>([]);

    const triggerDocumentFile = (fileList: FileList, index: number, roadmapIndex: number) => {
        setUploadFileIndex(roadmapIndex)
        debugger
        let updatedUploadFile: any = [...uploadFile];
        const validFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.text',
            'text/plain',
            'text/rtf',
            'text/html',
            'video/mp4',
            'video/x-msvideo',
            'video/quicktime',
            'video/x-ms-wmv',
            'video/x-matroska',
            'video/x-flv',
            'video/webm',
            'video/3gpp'
        ];

        Array.from(fileList).forEach((file) => {
            if (validFileTypes.includes(file.type)) {
                const newFileObj: IFiles = {
                    itemType: "",
                    name: "",
                    fileURL: URL.createObjectURL(file),
                    fileName: file.name,
                    file: file,
                };

                if (!updatedUploadFile[index]) {
                    updatedUploadFile[index] = [];
                }

                if (!updatedUploadFile[index][roadmapIndex]) {
                    updatedUploadFile[index][roadmapIndex] = [];
                }

                updatedUploadFile[index][roadmapIndex].push(newFileObj);
            } else {
            
                toast.warn("Invalid file type. Please upload a valid document or video file.");
            }
        });

        setUploadFile(updatedUploadFile);
    };

    const handleUploadFile = async (selectedKraId: string, checkpoint: string, roadMapComment: string, roadmapItem: any, isDeleted: boolean) => {
        debugger
        uploadFileItems(selectedKraId, checkpoint, roadMapComment, roadmapItem, isDeleted);
        setUploadFile([]);
    };

    async function uploadFileItems(
        kraId: string,
        checkpoint: string,
        comment: string,
        roadmapItem: any,
        isDeleted: boolean
    ) {
        try {
            let dataabc: any = "";

            if ((comment.length > 0 || comment.length === 0) && uploadFile.length === 0 ) {
                let formData = new FormData();

                if (roadmapItem.file && roadmapItem.file !== "" && isDeleted == true) {
                    formData.append("fileUrlToRemove", roadmapItem.file);
                }

                formData.append("KRAId", kraId);
                formData.append("CheckPoint", checkpoint);
                formData.append("Comment", comment);
                formData.append("IsDeleted", isDeleted.toString());

                const response = await kraratingServices.UploadKRADocument(formData);
                if (response.data.isError) {
                    toast.error("Facing issue something went wrong");
                } else {
                    dataabc += response.data + " ";
                    toast.success(response.data);
                    getallKraByUserIds(id, selectedYear ?? getCurrentYearId(yearValue));
                }
            }
            else {
               
                const flattenedUploadFiles = uploadFile.flat();
                console.log('uploadFile==', uploadFile);
                console.log('flattenedUploadFiles:', flattenedUploadFiles);

                await Promise.all(flattenedUploadFiles.map(async (item: any) => {

                    const multipleFile = item.map((item: any) => {
                        return item.file
                    })

                    let formData = new FormData();
                    if (roadmapItem.file && roadmapItem.file !== "" && isDeleted === true) {
                        formData.append("fileUrlToRemove", roadmapItem.file);
                    } else if (multipleFile.length > 0) {
                        // Only append multiple files if fileUrlToRemove does not have a value
                        multipleFile.forEach((data: any) => {
                            formData.append("File", data);
                        });
                    }

                    formData.append("KRAId", kraId);
                    formData.append("CheckPoint", checkpoint);
                    formData.append("Comment", comment);
                    formData.append("IsDeleted", isDeleted.toString());

                    const response = await kraratingServices.UploadKRADocument(formData);

                    if (response?.data?.isError) {
                        toast.error("Facing issue while Document Upload");
                    } else {
                        dataabc += response?.data + " ";
                        toast.success(response?.data || "Uploaded Successfully ");
                        getallKraByUserIds(id, selectedYear ?? getCurrentYearId(yearValue));

                    }

                }));

            }
        } catch (ex: any) {
            const errorMessage = ex?.data?.errors?.[0];
            toast.warning(errorMessage);
        }
    }




    const getUnEncrypedFiles = async (fileName: any) => {
        try {
            await projectManagementServive
                .getUnEncrypetDocuments(fileName)
                .then(async (response: any) => {
                    if (response.data.type === "application/json") {
                        const data = await response.data.text();
                        toast.warning(JSON.parse(data).listErrors[0]);
                    }
                    else {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = URL.createObjectURL(response.data);
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(a.href);
                    }
                });
        } catch (ex: any) {
            (ex.data?.Error || ex.Error).map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    }

    const handleEditClick = (mainIndex: number, roadmapIndex: number) => {
        setIsEditing(roadmapIndex);
        setEditingIndex({ mainIndex, roadmapIndex });
    };


    const diffofNoBWCheckPoints = (totalCheckpoints: number, completedCheckpoints: number): string => {
        if (completedCheckpoints === totalCheckpoints) {
            return "#2DD36F";
        } else if (completedCheckpoints >= totalCheckpoints / 2) {
            return "#FFC409";
        } else {
            return "#EB445A";
        }
    };

    const handleClearFile = (index: number, roadmapIndex: number, fileIndex: number) => {
        debugger
        const updatedFiles = [...uploadFile];
        updatedFiles[index][roadmapIndex].splice(fileIndex, 1);
        setUploadFile(updatedFiles);
    };



    return (

        <>
        
        {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Box>
            {id?.length !== 24?( <FileNotFound/>):(
            <>
                <Box>
                    <Paper elevation={0} sx={{ padding: '20px 14px', display: "flex" }}>
                        <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                        >
                            <>
                                <Grid item sm={2}>
                                    <Typography sx={{ p: 2, fontSize: '24px', fontWeight: '500', color: '#232360' }}>
                                        KRA
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box>
                                        <Typography sx={{ mb: '8px', fontSize: '14px', fontWeight: '300', color: '#424242' }}>
                                            Name
                                        </Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#212121' }}>
                                            {/* {`${userDetail?.employeeInfo?.firstName} ${userDetail?.employeeInfo?.lastName}`} */}
                                            {/* {userDetail?.employeeInfo?.firstName} */}
                                            {userDetail?.employeeInfo?.firstName}  {userDetail?.employeeInfo?.lastName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box>
                                        <Typography sx={{ mb: '8px', fontSize: '14px', fontWeight: '300', color: '#424242' }}>
                                            Employee ID
                                        </Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#212121' }}>
                                            {userDetail?.employeeInfo?.employeeId}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box>
                                        <Typography sx={{ mb: '8px', fontSize: '14px', fontWeight: '300', color: '#424242' }}>
                                            Appraisal
                                        </Typography>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#212121' }}>

                                            {(Object.keys(Monts) as Array<keyof typeof Monts>).find(key => Monts[key] === String(userDetail?.employeeInfo?.appraisalMonth))}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item sm={4}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 500,
                                            display: "flex",
                                            alignItems: "center",
                                            mx: 2,
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            sx={{ color: "#2DD36F" }}
                                        />{" "}
                                        Completed
                                    </Typography>

                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 500,
                                            display: "flex",
                                            alignItems: "center",
                                            mx: 2,
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            sx={{ color: "#FFC409" }}
                                        />{" "}
                                        In-B/W
                                    </Typography>

                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 500,
                                            display: "flex",
                                            alignItems: "center",
                                            mx: 2,
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            sx={{ color: "#EB445A" }}
                                        />{" "}
                                        Pending
                                    </Typography>
                                </Grid>

                            </>

                        </Grid>
                        <Box sx={{ mt: "18px" }}>
                            {isMonthZero ? (

                                <TextField
                                    label="Select Year"
                                    value="N/A"
                                    disabled
                                    sx={{ width: '250px' }}
                                />
                            ) : (
                                <Autocomplete
                                    options={yearValue}
                                    id="combo-box-demo"
                                    sx={{ width: '250px' }}
                                    value={
                                        yearValue?.find(
                                            (option: any) => option.id == selectedYear
                                        ) ?? null
                                    }
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                        getallKraByUserIds(id, value?.id ?? getCurrentYearId(yearValue));
                                        setSelectedYear(value?.id ?? getCurrentYearId(yearValue));
                                        setYearLabel(value?.id);
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Select Year"
                                            value={selectedYear}
                                        />}
                                />
                            )}
                        </Box>
                    </Paper>

                    <Grid item sm={12} sx={{ marginTop: "0px", display: "flex", justifyContent: "end" }}>
                        {(_permission.action_ManageUsersListing_AddKra_Access && _authUser.typeOfUser != 3) && (
                            <Button
                                variant="outlined"
                                sx={{ marginTop: "12px" }}
                                onClick={() => { 
                                    debugger
                                    const navigateYearValue = yearLabel;
                                    console.log("navigateYearValue ==", navigateYearValue)
                                    navigate(`/addKRA/${id}`, { state: { navigateYear : navigateYearValue ?  navigateYearValue: additionalVar } })}}
                            >
                                {"Add-KRA"}
                            </Button>)}
                    </Grid>

                    {ratingForms.kraDetails && ratingForms.kraDetails.length > 0 ? (
                        <Box>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                            >

                                <Grid item sm={12}>
                                    {ratingForms?.kraDetails?.map((ratingForm: any, index: any) => (
                                        <Box key={index} sx={{ mt: '16px' }}>
                                            <Paper elevation={0} sx={{ padding: '22px 20px' }}>
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Stack direction="row" spacing={2}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                disabled
                                                                variant="outlined"
                                                                sx={{ width: '96%' }}
                                                                value={ratingForm?.kra}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: (
                                                                        <FiberManualRecordIcon sx={{ color: diffofNoBWCheckPoints(ratingForm?.checkPointTotalCount, ratingForm?.checkPointFileUploadCount) }} />
                                                                    ),
                                                                }} />


                                                            {(ratingForm.isFixed === false && _permission.action_ManageUsersListing_DeleteKra_Access) && (
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => {
                                                                        setDeleteAlertOpen(true);
                                                                        setIdForKraDelete(ratingForm?.id);
                                                                        setIndexValueForKraDelete(index);
                                                                    }}
                                                                >
                                                                    <Icons.RedDelete />
                                                                </IconButton>
                                                            )}


                                                        </Stack>


                                                    </Grid>


                                                </Grid>


                                                <Box sx={{ borderTop: '1px solid #E1E1E1', pt: '8px', mt: '18px' }}>
                                                    {showMoreStates.includes(index) ? (
                                                        <Box sx={{ width: '100%' }}>
                                                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <Tabs
                                                                    value={tabValues[index] || 0}
                                                                    onChange={(event, newValue) => handleChangeTab(event, newValue, index)}
                                                                    aria-label="basic tabs example"
                                                                >
                                                                    {(ratingForm.isFixed === false &&
                                                                        <Tab label="Roadmap Details" {...a11yProps(0)} />
                                                                    )}

                                                                    <Tab label="Rating Details" {...a11yProps((ratingForm.isFixed === false ? 0 : 1))} />
                                                                    <Tab label="Review" {...a11yProps((ratingForm.isFixed === false ? 1 : 2))} />


                                                                </Tabs>
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{ mb: '8px', fontSize: '16px', fontWeight: '500', color: '#1DA7FF', paddingRight: "24px" }}
                                                                    onClick={() => toggleShowMore(index)} // Toggle visibility for "Show Less" button
                                                                >
                                                                    Close Tab
                                                                </Button>

                                                            </Box>


                                                            <Box
                                                                role="tabpanel"
                                                                hidden={tabValues[index] !== 0}
                                                                id={`simple-tabpanel-${index}-0`}
                                                                aria-labelledby={`simple-tab-${index}-0`}
                                                                sx={{ p: 3 }}>
                                                                {ratingForm?.roadMap?.map((roadmapItem: any, roadmapIndex: any) => (
                                                                    <Box key={roadmapIndex} sx={{ marginBottom: '16px', borderBottom: "1px solid #cccccc", paddingBottom: "32px" }}>
                                                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                            <Box sx={{ display: "flex", gap: "12px" }}>
                                                                                <Stack direction={'row'} alignItems={"center"} sx={{ marginRight: '100px' }}>
                                                                                    <Icons.CalenderEvent />
                                                                                    <Typography sx={{ fontWeight: 'bold', marginLeft: '10px', color: '#232360', marginRight: '10px' }}>
                                                                                        Date
                                                                                    </Typography>
                                                                                    <Typography>{`${moment(roadmapItem?.date).format("DD MMM, YYYY")}`}</Typography>
                                                                                </Stack>
                                                                                <Stack direction={'row'} alignItems={"center"}>
                                                                                    <Icons.TickCircleBlue />
                                                                                    <Typography sx={{ fontWeight: 'bold', marginLeft: '10px', marginRight: '10px', color: '#232360' }}>
                                                                                        Checkpoint
                                                                                    </Typography>
                                                                                    <Typography>{roadmapItem?.checkPoint}</Typography>
                                                                                </Stack>
                                                                            </Box>

                                                                            {(roadmapItem.comment?.trim() || roadmapItem.file?.length > 0) && (_permission.action_ManageUsersListing_UploadCheckpoint_Access || _permission.action_ManageUsersListing_AddComment_Access) && (
                                                                                <Box>
                                                                                    {isEditing !== roadmapIndex && (
                                                                                        <Tooltip title="Edit">
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    handleEditClick(index, roadmapIndex);
                                                                                                    setIsUserEditing(false);
                                                                                                }}
                                                                                            >
                                                                                                <Icons.Edit />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </Box>
                                                                            )}
                                                                        </Box>


                                                                        {(editingIndex?.mainIndex === index && editingIndex?.roadmapIndex === roadmapIndex) ||
                                                                            roadmapItem.comment === null || roadmapItem.comment === "" ||
                                                                            (roadmapItem.file && (roadmapItem.comment === null || roadmapItem.comment === "")) ? (
                                                                            <Box mt={1}
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    "& .css-q9kwfh-MuiOutlinedInput-notchedOutline": {
                                                                                        border: 'none',
                                                                                        background: 'transparent',
                                                                                    },
                                                                                    "& .css-1gq4kjl-MuiInputBase-input-MuiOutlinedInput-input": {
                                                                                        paddingX: '0',
                                                                                    },
                                                                                    "word-break": "break-all"
                                                                                }}>

                                                                                {(
                                                                                    (_permission.action_ManageUsersListing_AddComment_Access) ? (
                                                                                        <TextField
                                                                                            id="outlined-multiline-flexible"
                                                                                            multiline
                                                                                            placeholder="Write your comment here..."
                                                                                            type="text"
                                                                                            variant="outlined"

                                                                                            fullWidth
                                                                                            value={
                                                                                                isUserEditing
                                                                                                    ? roadMapComment[index]?.[roadmapIndex] 
                                                                                                    : roadmapItem?.comment || roadMapComment[index]?.[roadmapIndex] || ""
                                                                                            }
                                                                                            sx={{
                                                                                                "marginTop": "24px",
                                                                                                "word-break": "break-all",
                                                                                                "line-height": "1.5",
                                                                                                "border": "1px solid #ccc",
                                                                                                "padding": "10px",
                                                                                                "border-radius": "8px",
                                                                                                "& .MuiInputBase-root": {
                                                                                                    "line-height": "1.5",
                                                                                                },
                                                                                                "& .MuiOutlinedInput-root": {
                                                                                                    "line-height": "1.5",
                                                                                                    "padding": "10px",
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e: any) => {
                                                                                                debugger
                                                                                                handleRoadmapCommentChange(index, roadmapIndex, e);
                                                                                                setHasChanges(true);
                                                                                               // setIsUserEditing(false);
                                                                                            }

                                                                                            }

                                                                                            error={!!roadmapValidationMessage[index]?.[roadmapIndex]}
                                                                                            helperText={roadmapValidationMessage[index]?.[roadmapIndex]}
                                                                                        />

                                                                                    ) : (
                                                                                        <Stack direction={'row'} alignItems={"center"} sx={{ gap: "12px", marginTop: "28px" }}>
                                                                                            <Typography sx={{ fontWeight: 'bold', color: '#1DA7FF', display: "flex", alignItems: "center", gap: "12px" }}>
                                                                                                No comment added yet
                                                                                            </Typography>
                                                                                        </Stack>
                                                                                    )

                                                                                )}

                                                                            </Box>
                                                                        ) : (
                                                                            roadmapItem.comment && (
                                                                                <div>
                                                                                    <Stack direction={'row'} alignItems={"center"} sx={{ gap: "12px", marginTop: "28px" }}>
                                                                                        <Typography sx={{ fontWeight: 'bold', color: '#232360', display: "flex", alignItems: "center", gap: "12px" }}>
                                                                                            <Icons.Comment />  Comment
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                    <Typography sx={{ display: "block", marginLeft: "34px" }}>{roadmapItem.comment}</Typography>
                                                                                </div>
                                                                            )
                                                                        )}


                                                                        {(editingIndex?.mainIndex === index && editingIndex?.roadmapIndex === roadmapIndex) ||
                                                                            (roadmapItem.file === null || roadmapItem.file.length === 0) ||
                                                                            (roadmapItem.comment && (roadmapItem.file === null || roadmapItem.file.length === 0)) ? (

                                                                            <Box mt={3}>
                                                                                <Stack direction={'row'} spacing="2" justifyContent={"space-between"}>

                                                                                    <Stack direction={'row'} alignItems={"center"} sx={{ marginRight: '100px', gap: "12px" }} >
                                                                                        {_permission.action_ManageUsersListing_UploadCheckpoint_Access && (

                                                                                            <><input
                                                                                                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.txt,.rtf,.html,.htm,.mp4,.avi,.mov,.wmv,.mkv,.flv,.webm,.3gp"
                                                                                                style={{ display: "none" }}
                                                                                                id={`upload-document-${index}-${roadmapIndex}`}  //
                                                                                                type="file"
                                                                                                onChange={(fileList: any) => {

                                                                                                    triggerDocumentFile(fileList?.target?.files, index, roadmapIndex);
                                                                                                    setHasChanges(true);
                                                                                                }
                                                                                                }
                                                                                                multiple

                                                                                            />

                                                                                                <label htmlFor={`upload-document-${index}-${roadmapIndex}`}>
                                                                                                    <IconButton
                                                                                                        color="primary"
                                                                                                        aria-label="upload document"
                                                                                                        component="span"
                                                                                                    >
                                                                                                        <CloudUploadIcon
                                                                                                            sx={{ fontSize: 40, color: "#03A9F4" }}
                                                                                                        />
                                                                                                    </IconButton>
                                                                                                </label>
                                                                                                <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#4B4B5A" }} variant="h6">
                                                                                                    Upload Document
                                                                                                </Typography>

                                                                                            </>
                                                                                        )}


                                                                                        {(roadmapItem.file && !uploadFile[index]?.[roadmapIndex]?.file) && (
                                                                                            <div>

                                                                                                <Stack direction={'column'} alignItems={"flex-start"} sx={{ gap: "12px" }}>

                                                                                                    {Array.isArray(roadmapItem.file) && (
                                                                                                        roadmapItem.file?.map((fileItem: string, index: number) => (
                                                                                                            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                                                                <Typography onClick={() => { getUnEncrypedFiles(fileItem) }} style={{ cursor: 'pointer', color: "#1DA7FF", textDecoration: "underline" }}>
                                                                                                                    {fileItem.replace(/_\d+/, '')}
                                                                                                                </Typography>

                                                                                                                {_permission.action_ManageUsersListing_DeleteCheckpoint_Access && (
                                                                                                                    <Tooltip title="Delete File">
                                                                                                                        <IconButton
                                                                                                                            sx={{ border: "1px solid #ff4747", padding: "0" }}
                                                                                                                            onClick={async () => {
                                                                                                                                debugger
                                                                                                                                const updatedFiles = roadmapItem.file.filter((item: string) => item == fileItem);


                                                                                                                                const updatedRoadmapItem = { ...roadmapItem, file: updatedFiles };
                                                                                                                                await handleUploadFile(ratingForm.id, roadmapItem.checkPoint, roadmapItem.comment, updatedRoadmapItem, true);
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <ClearIcon sx={{ color: "#ff4747", fontSize: "18px", fontWeight: "700" }} />
                                                                                                                        </IconButton>
                                                                                                                    </Tooltip>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        ))


                                                                                                    )}


                                                                                                </Stack>
                                                                                            </div>
                                                                                        )}

                                                                                        <Stack sx={{ mt: 2, mb: 4, gap: "8px", justifyContent: "space-between" }} >

                                                                                            {Array.isArray(uploadFile[index]?.[roadmapIndex]) && uploadFile[index]?.[roadmapIndex].length > 0 ? (

                                                                                                uploadFile[index][roadmapIndex]?.map((fileObj: any, fileIndex: number) => (
                                                                                                    <React.Fragment key={fileIndex}>
                                                                                                        {fileObj.fileURL && /\.(jpg|jpeg|png|gif)$/i.test(fileObj.fileName) ? (
                                                                                                            <Box
                                                                                                                sx={{
                                                                                                                    '&:hover': { border: '1px solid #1DA7FF' },
                                                                                                                    width: '100px',
                                                                                                                    height: '100px',
                                                                                                                    margin: '3px 10px',
                                                                                                                    position: 'relative',
                                                                                                                    border: '1px solid #EEE',
                                                                                                                    borderRadius: '5px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    width={'100%'}
                                                                                                                    height={'100%'}
                                                                                                                    src={fileObj.fileURL}
                                                                                                                    alt={fileObj.file.name}
                                                                                                                    onClick={() => window.open(fileObj.fileURL, '_blank')}
                                                                                                                />
                                                                                                            </Box>
                                                                                                        ) : (
                                                                                                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                                                                                <a href={fileObj.fileURL} target="_blank" rel="noopener noreferrer">
                                                                                                                    {fileObj.fileName}
                                                                                                                </a>
                                                                                                                {/* Clear Icon */}

                                                                                                                <IconButton
                                                                                                                    aria-label="clear"
                                                                                                                    onClick={() => handleClearFile(index, roadmapIndex, fileIndex)}
                                                                                                                >
                                                                                                                    <ClearIcon color="error" />
                                                                                                                </IconButton>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                    </React.Fragment>
                                                                                                ))

                                                                                            ) : (
                                                                                                // If no files are uploaded, show an alternative message or element
                                                                                                <a
                                                                                                    style={{
                                                                                                        color: 'blue',
                                                                                                        textDecoration: 'underline',
                                                                                                        cursor: 'pointer',
                                                                                                        transition: 'color 0.2s ease',
                                                                                                    }}
                                                                                                >
                                                                                                    {uploadFile[index]?.[roadmapIndex]?.fileURL}
                                                                                                </a>
                                                                                            )}
                                                                                        </Stack>


                                                                                    </Stack>

                                                                                </Stack>
                                                                            </Box>


                                                                        ) : (
                                                                            roadmapItem.file && (
                                                                                <div>
                                                                                    <Stack direction={'column'} alignItems={"flex-start"} sx={{ gap: "12px", marginTop: "28px" }}>
                                                                                        <Typography sx={{ fontWeight: 'bold', color: '#232360' }}>
                                                                                            Files
                                                                                        </Typography>

                                                                                        {roadmapItem.file?.map((fileItem: string, index: number) => (
                                                                                            <Stack key={index} direction={'row'} alignItems={"center"} sx={{ gap: "12px" }}>
                                                                                                <Typography
                                                                                                    onClick={() => { getUnEncrypedFiles(fileItem) }}
                                                                                                    style={{ cursor: 'pointer', color: "#1DA7FF", textDecoration: "underline" }}
                                                                                                >
                                                                                                    <Box>
                                                                                                        {fileItem.replace(/_\d+/, '')}

                                                                                                    </Box>
                                                                                                </Typography>


                                                                                            </Stack>
                                                                                        ))}
                                                                                    </Stack>
                                                                                </div>

                                                                            )

                                                                        )}



                                          <>

                                {(!roadmapItem.comment || (roadmapItem.file === null || roadmapItem.file.length === 0)) && (
                                                                                <>
                                                                                {isEditing !== roadmapIndex && (
                                                                                        <Stack direction={'row'} justifyContent={'end'}>

                                                                                            <>
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    size="large"
                                                                                                    color="primary"
                                                                                                    disabled={!hasChanges || (!roadMapComment[index]?.[roadmapIndex] && !uploadFile[index]?.[roadmapIndex] )}
                                                                                                    sx={{ p: '9px 30px' }}
                                                                                                    onClick={() => {
                                                                                                        const newRoadMapComment = { ...roadMapComment };
                                                                                                        const newRoadmapValidationMessage = { ...roadmapValidationMessage };
                                                                                                        const newUploadFile = [...uploadFile];

                                                                                                        if (newRoadMapComment[index]) {
                                                                                                            newRoadMapComment[index][roadmapIndex] = '';
                                                                                                        }

                                                                                                        if (newRoadmapValidationMessage[index]) {
                                                                                                            newRoadmapValidationMessage[index][roadmapIndex] = '';
                                                                                                        }

                                                                                                        if (newUploadFile[index]) {
                                                                                                            newUploadFile[index][roadmapIndex] = { ...IfileObj };
                                                                                                        }
                                                                                                        setRoadmapComment(newRoadMapComment);
                                                                                                        setIsEditing(null);
                                                                                                        setEditingIndex(null);
                                                                                                        setUploadFileIndex('')
                                                                                                        setUploadFile([])
                                                                                                        setRoadmapValidationMessage(newRoadmapValidationMessage);
                                                                                                        
                                                                                                    }}
                                                                                                >
                                                                                                    Cancel
                                                                                                </Button>
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    size="large"
                                                                                                    color="primary"
                                                                                                    sx={{ p: '9px 30px', marginLeft: '22px' }}
                                                                                                    disabled={!hasChanges || (!roadMapComment[index]?.[roadmapIndex] && !uploadFile[index]?.[roadmapIndex])} 
                                                                                                    onClick={async () => {
                                                                                                        const comment = roadMapComment[index]?.[roadmapIndex] || '';
                                                                                                        await handleUploadFile(ratingForm.id, roadmapItem.checkPoint, comment, '', false,);
                                                                                                        setHasChanges(false);
                                                                                                    }}
                                                                                                >
                                                                                                    Save
                                                                                                </Button></>


                                                                                        </Stack>
                                                                                    )}
                                                                                </>
                                                                       
                                                                    )}
                                                                        </>



                                                                                {editingIndex?.mainIndex === index && editingIndex?.roadmapIndex === roadmapIndex && (
                                                                            <Stack direction={'row'} justifyContent={'end'}>
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    size="large"
                                                                                    color="primary"
                                                                                    sx={{ p: '9px 30px' }}
                                                                                    onClick={() => {
                                                                                       
                                                                                        setUploadFileIndex('')
                                                                                        setRoadmapComment([]);
                                                                                        setUploadFile([])
                                                                                        setIsEditing(null);
                                                                                        setEditingIndex(null);
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    size="large"
                                                                                    color="primary"
                                                                                    sx={{ p: '9px 30px', marginLeft: '22px' }}
                                                                                    disabled={buttonDisable || !hasChanges}
                                                                                    onClick={async () => {
                                                                                        debugger
                                                                                        const clearedRoadmapItem = { ...roadmapItem, file: '' };
                                                                                        const comment = roadMapComment[index]?.[roadmapIndex] || '';
                                                                                        await handleUploadFile(ratingForm.id, roadmapItem.checkPoint, comment, clearedRoadmapItem, false);
                                                                                        setIsEditing(null);
                                                                                        setEditingIndex(null);
                                                                                        setHasChanges(false);
                                                                                    }}
                                                                                >
                                                                                    Update
                                                                                </Button>
                                                                            </Stack>
                                                                        )
                                                                        }
                                                                    </Box>

                                                                ))}
                                                            </Box>

                                                            <Box
                                                                role="tabpanel"
                                                                hidden={tabValues[index] !== (ratingForm.isFixed === false ? 1 : 0)}
                                                                id={`simple-tabpanel-${index}-1`}
                                                                aria-labelledby={`simple-tab-${index}-1`}
                                                                sx={{ p: 3 }}>

                                                                <TableContainer component={Paper} elevation={0}>
                                                                    <Table sx={{ minWidth: 500, "& .Mui-disabled.MuiInputBase-root": { border: '1px grey' }, "& .MuiInputBase-root": { border: '1px solid black' } }} aria-label="simple table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell sx={{ border: '0px solid' }}></TableCell>
                                                                                <TableCell
                                                                                    align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                                                >
                                                                                    Q1
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                                                >
                                                                                    Q2
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                                                >
                                                                                    Q3
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="center" sx={{ color: '#000000', fontSize: '14px', fontWeight: '500', p: 0, border: '0px solid' }}
                                                                                >
                                                                                    Q4
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {(_authUser.typeOfUser === eTypeOfUser.User || _authUser.id === ratingForms.ioUserId || _authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.HR) && (
                                                                                <TableRow
                                                                                    sx={{ '& td, & th': { border: 0 }, padding: '8px 0' }}
                                                                                >
                                                                                    <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                                                        Self
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.HR || _authUser.typeOfUser === eTypeOfUser.Admin ? true : !isCheckboxEnabled(1)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.self?.q1 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.self?.q1 == "") {
                                                                                                    handleChange(index, 'self', { field: 'q1', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.self?.q1 == 0 || ratingForm?.self == null) {
                                                                                                    handleChange(index, 'self', { field: 'q1', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'self', { field: 'q1', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.HR || _authUser.typeOfUser === eTypeOfUser.Admin ? true : !isCheckboxEnabled(2)}
                                                                                            value={ratingForm?.self?.q2 ?? 0}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.self?.q2 == "") {
                                                                                                    handleChange(index, 'self', { field: 'q2', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.self?.q2 == 0 || ratingForm?.self == null) {
                                                                                                    handleChange(index, 'self', { field: 'q2', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'self', { field: 'q2', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.HR || _authUser.typeOfUser === eTypeOfUser.Admin ? true : !isCheckboxEnabled(3)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.self?.q3 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.self?.q3 == "") {
                                                                                                    handleChange(index, 'self', { field: 'q3', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.self?.q3 == 0 || ratingForm?.self == null) {
                                                                                                    handleChange(index, 'self', { field: 'q3', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'self', { field: 'q3', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.HR || _authUser.typeOfUser === eTypeOfUser.Admin ? true : !isCheckboxEnabled(4)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.self?.q4 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.self?.q4 == "" || ratingForm?.self == null) {
                                                                                                    handleChange(index, 'self', { field: 'q4', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.self?.q4 == 0) {
                                                                                                    handleChange(index, 'self', { field: 'q4', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'self', { field: 'q4', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                            {(_authUser.id === ratingForms.ioUserId || _authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.HR) && (
                                                                                <TableRow
                                                                                    sx={{ '& td, & th': { border: 0 }, padding: '8px 0' }}
                                                                                >
                                                                                    <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                                                        IO
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.id !== ratingForms.ioUserId ? true : !isCheckboxEnabled(1)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.io?.q1 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.io?.q1 == "") {
                                                                                                    handleChange(index, 'io', { field: 'q1', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.io?.q1 == 0 || ratingForm?.io == null) {
                                                                                                    handleChange(index, 'io', { field: 'q1', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {

                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'io', { field: 'q1', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.id !== ratingForms.ioUserId ? true : !isCheckboxEnabled(2)}
                                                                                            value={ratingForm?.io?.q2 ?? 0}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.io?.q2 == "") {
                                                                                                    handleChange(index, 'io', { field: 'q2', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.io?.q2 == 0 || ratingForm?.io == null) {
                                                                                                    handleChange(index, 'io', { field: 'q2', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'io', { field: 'q2', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.id !== ratingForms.ioUserId ? true : !isCheckboxEnabled(3)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.io?.q3 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.io?.q3 == "") {
                                                                                                    handleChange(index, 'io', { field: 'q3', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.io?.q3 == 0 || ratingForm?.io == null) {
                                                                                                    handleChange(index, 'io', { field: 'q3', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'io', { field: 'q3', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.id !== ratingForms.ioUserId ? true : !isCheckboxEnabled(4)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.io?.q4 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.io?.q4 == "") {
                                                                                                    handleChange(index, 'io', { field: 'q4', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.io?.q4 == 0 || ratingForm?.io == null) {
                                                                                                    handleChange(index, 'io', { field: 'q4', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'io', { field: 'q4', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                            {(_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.HR) && (
                                                                                <TableRow
                                                                                    sx={{ '& td, & th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                                                        Manager
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser !== eTypeOfUser.Manager ? true : !isCheckboxEnabled(1)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.manager?.q1 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.manager?.q1 == "") {
                                                                                                    handleChange(index, 'manager', { field: 'q1', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.manager?.q1 == 0 || ratingForm?.manager == null) {
                                                                                                    handleChange(index, 'manager', { field: 'q1', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'manager', { field: 'q1', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser !== eTypeOfUser.Manager ? true : !isCheckboxEnabled(2)}
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            variant="outlined"
                                                                                            value={ratingForm?.manager?.q2 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.manager?.q2 == "") {
                                                                                                    handleChange(index, 'manager', { field: 'q2', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.manager?.q2 == 0 || ratingForm?.manager == null) {
                                                                                                    handleChange(index, 'manager', { field: 'q2', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'manager', { field: 'q2', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser !== eTypeOfUser.Manager ? true : !isCheckboxEnabled(3)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.manager?.q3 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.manager?.q3 == "") {
                                                                                                    handleChange(index, 'manager', { field: 'q3', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.manager?.q3 == 0 || ratingForm?.manager == null) {
                                                                                                    handleChange(index, 'manager', { field: 'q3', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'manager', { field: 'q3', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser !== eTypeOfUser.Manager ? true : !isCheckboxEnabled(4)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.manager?.q4 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.manager?.q4 == "") {
                                                                                                    handleChange(index, 'manager', { field: 'q4', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.manager?.q4 == 0 || ratingForm?.manager == null) {
                                                                                                    handleChange(index, 'manager', { field: 'q4', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'manager', { field: 'q4', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                            {(_authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.HR) && (
                                                                                <TableRow
                                                                                    sx={{ '& td, & th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell component="th" sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', p: '8px 0' }}>
                                                                                        HR
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: 0 }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px', 'margin-top': '1px', 'margin-bottom': '2px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.id === ratingForms.ioUserId || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.User ? true : !isCheckboxEnabled(1)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.hr?.q1 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.hr?.q1 == "") {
                                                                                                    handleChange(index, 'hr', { field: 'q1', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.hr?.q1 == 0 || ratingForm?.hr == null) {
                                                                                                    handleChange(index, 'hr', { field: 'q1', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'hr', { field: 'q1', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.id === ratingForms.ioUserId || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.User ? true : !isCheckboxEnabled(2)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.hr?.q2 ?? ""}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.hr?.q2 == "") {
                                                                                                    handleChange(index, 'hr', { field: 'q2', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.hr?.q2 == 0 || ratingForm?.hr == null) {
                                                                                                    handleChange(index, 'hr', { field: 'q2', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {

                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                //  handleChangeRating(index, 'self', "q2", e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1))
                                                                                                handleChange(index, 'hr', { field: 'q2', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.id === ratingForms.ioUserId || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.User ? true : !isCheckboxEnabled(3)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.hr?.q3 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.hr?.q3 == "") {
                                                                                                    handleChange(index, 'hr', { field: 'q3', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.hr?.q3 == 0 || ratingForm?.hr == null) {
                                                                                                    handleChange(index, 'hr', { field: 'q3', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'hr', { field: 'q3', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                    <TableCell align="center" sx={{ p: '8px 0' }}>
                                                                                        <TextField
                                                                                            sx={{ width: '38px', height: '25px' }}
                                                                                            disabled={_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.id === ratingForms.ioUserId || _authUser.typeOfUser === eTypeOfUser.Admin || _authUser.typeOfUser === eTypeOfUser.User ? true : !isCheckboxEnabled(4)}
                                                                                            variant="outlined"
                                                                                            inputProps={{ maxLength: 1 }}
                                                                                            value={ratingForm?.hr?.q4 ?? 0}
                                                                                            onBlur={() => {
                                                                                                if (ratingForm?.hr?.q4 == "" || ratingForm?.hr == null) {
                                                                                                    handleChange(index, 'hr', { field: 'q4', rating: 0 });
                                                                                                }
                                                                                            }}
                                                                                            onFocus={() => {
                                                                                                if (ratingForm?.hr?.q4 == 0) {
                                                                                                    handleChange(index, 'hr', { field: 'q4', rating: "" });
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                let abc = e.currentTarget.value.replace(/[^5-9]\s{0,}$/g, "").substring(0, 1);
                                                                                                let xyz = abc == "" ? abc : parseInt(abc)
                                                                                                handleChange(index, 'hr', { field: 'q4', rating: xyz });
                                                                                            }} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>



                                                                    <Box sx={{ borderTop: '1px solid #E1E1E1', pt: '24px', mt: '70px', mb: '40px' }}>
                                                                        <Stack direction={'row'} justifyContent={'end'}>


                                                                            <Button
                                                                                variant="contained"
                                                                                size="large"
                                                                                color="primary"
                                                                                sx={{ p: '9px 30px', marginLeft: '22px' }}
                                                                                disabled={buttonDisable || !hasChanges}
                                                                                onClick={() => {
                                                                                    KraRatingSave();
                                                                                    setButtonDisable(true);
                                                                                }}
                                                                            >
                                                                                Save
                                                                            </Button>
                                                                        </Stack>
                                                                    </Box>
                                                                </TableContainer>

                                                            </Box>


                                                            <Box
                                                                role="tabpanel"
                                                                hidden={tabValues[index] !== (ratingForm.isFixed === false ? 2 : 1)}
                                                                id={`simple-tabpanel-${index}-2`}
                                                                aria-labelledby={`simple-tab-${index}-2`}
                                                                sx={{ p: 3 }}
                                                            >
                                                                <Stack spacing={2}>
                                                                    {kraComment[index]?.comments?.length > 0 ? (
                                                                        kraComment[index].comments.map((item: any, idx: number) => (
                                                                            <Box key={idx} sx={{ border: '1px solid #EEEEEE', padding: '10px' }}>
                                                                                <Stack direction={'row'} alignItems="center" justifyContent="space-between">
                                                                                    <Stack direction={'row'} alignItems={'center'} justifyContent="space-between" spacing={2}>
                                                                                        <Avatar sx={{ background: '#F0F9FF', color: '#1DA7FF' }}>
                                                                                            {item.userName ? item.userName.charAt(0) : 'U'}
                                                                                        </Avatar>
                                                                                        <Box>
                                                                                            <Typography variant="subtitle1" sx={{ fontSize: "18px", fontWeight: "400", color: '#232360', "word-break": "break-all" }}>
                                                                                                {item.comment}
                                                                                            </Typography>
                                                                                            <Typography sx={{ fontSize: "14px", fontWeight: "400", color: '#757575' }}>
                                                                                                {item.userName}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Stack>
                                                                                    <Box sx={{ textAlign: 'right' }}>
                                                                                        {item.createdBy === _authUser.id && (
                                                                                            <IconButton aria-label="more" aria-controls="more-menu" aria-haspopup="true"
                                                                                                onClick={() => {
                                                                                                    setDialogComment(true);
                                                                                                    setIdForCommentDelete(item.id);
                                                                                                    setSelectedIndex(index);
                                                                                                }}
                                                                                            >
                                                                                                <Icons.RedDelete />
                                                                                            </IconButton>
                                                                                        )}
                                                                                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#757575', width: "200px" }}>
                                                                                            {`${moment(item?.createdDate).format("DD MMM, YYYY")}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Stack>
                                                                            </Box>
                                                                        ))
                                                                    ) : (
                                                                        <Typography>No comments available</Typography>
                                                                    )}
                                                                </Stack>

                                                                <Box >
                                                                    <Box
                                                                        sx={{
                                                                            width: '100%',
                                                                            "& .css-q9kwfh-MuiOutlinedInput-notchedOutline": {
                                                                                border: 'none',
                                                                                background: 'transparent',
                                                                            },
                                                                            "& .css-1gq4kjl-MuiInputBase-input-MuiOutlinedInput-input": {
                                                                                paddingX: '0',
                                                                            },
                                                                            "word-break": "break-all"
                                                                        }}
                                                                    >
                                                                        <TextField
                                                                            id="outlined-multiline-flexible"
                                                                            multiline
                                                                            placeholder="Write your comment here..."
                                                                            type="text"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            value={comment[index]}
                                                                            sx={{
                                                                                "word-break": "break-all",
                                                                                "marginTop": "24px",
                                                                                "line-height": "1.5",
                                                                                "padding": "10px",
                                                                                "border": "1px solid #ccc",
                                                                                "border-radius": "8px",
                                                                                "& .MuiInputBase-root": {
                                                                                    "line-height": "1.5",
                                                                                },
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    "line-height": "1.5",
                                                                                    "padding": "10px",
                                                                                }
                                                                            }}


                                                                            onChange={(e: any) => handleCommentChange(index, e)}
                                                                            error={!!validationMessage[index]}
                                                                            helperText={validationMessage[index]}

                                                                        />

                                                                    </Box>
                                                                    <Box mt="5" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                                        <Box mt="5" sx={{ display: "flex", alignItems: "alignItems", gap: "12px" }}>
                                                                            <Button variant="outlined"
                                                                                onClick={() => {

                                                                                    const newComments = [...comment];
                                                                                    const newValidationMessages = [...validationMessage];

                                                                                    newComments[index] = '';
                                                                                    newValidationMessages[index] = '';

                                                                                    setComment(newComments);
                                                                                    setValidationMessage(newValidationMessages);
                                                                                }}
                                                                            >
                                                                                Cancel</Button>
                                                                            <Button variant="contained"
                                                                                onClick={() => {
                                                                                    saveComment(index);
                                                                                }}
                                                                            >
                                                                                Send</Button>


                                                                        </Box>
                                                                    </Box>

                                                                </Box>


                                                            </Box>



                                                        </Box>



                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ mb: '8px', fontSize: '16px', fontWeight: '500', color: '#1DA7FF' }}
                                                            onClick={() => { toggleShowMore(index) }} // Toggle visibility for "Show More" button
                                                        >
                                                            {_authUser.typeOfUser === eTypeOfUser.Admin ? "View Rating" : "View Details"}
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Paper>
                                        </Box>
                                    ))}


                                </Grid>
                            </Grid>

                        </Box>) :

                        (<Typography sx={{ textAlign: "center", mt: "5rem", fontWeight: "700", color: "#1DA7FF" }}>Please Ask Your Manager to Add KRA's</Typography>)}
                </Box>
                </>)}
            </Box>





            <Dialog
                open={dialogOpen}
                onClose={setDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <IconButton
                    aria-label="delete"
                    sx={styleCross}
                    onClick={() => { setDialogOpen(false); setRoadMapDetails([]); }}
                >
                    <ClearIcon />
                </IconButton>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "26px",
                        lineHeight: "39px",
                        color: "#212121",
                    }}
                >
                    ROAD MAP DETAILS
                </DialogTitle>
                <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ paddingInline: "16px", textAlign: 'start' }}>Date</th>
                                <th style={{ paddingInline: "16px" }}>Checkpoints</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roadMapDetails.map((item: any) => (
                                <tr>
                                    <td style={{ paddingInline: "16px" }}>{`${moment(item?.date).format("DD MMM, YYYY")}`}</td>
                                    <td style={{ paddingInline: "16px" }}>{item?.checkPoint}</td>
                                </tr>))}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions
                    sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "flex-end" }}
                >

                    <Button variant="contained" sx={{ justifyContent: "flex-end" }} onClick={() => { setDialogOpen(false); setRoadMapDetails([]); }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            {/* // To delete Review comment */}

            <Dialog
                open={openDeleteAlert}
                onClose={setDeleteAlertOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <IconButton
                    aria-label="delete"
                    sx={styleCross}
                    onClick={(e) => handleDeleteDialogclose()}
                >
                    <ClearIcon />
                </IconButton>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "26px",
                        lineHeight: "39px",
                        color: "#212121",
                    }}
                >
                    Delete
                </DialogTitle>
                <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
                    Are you sure you want to delete this KRA ?
                </DialogContent>
                <DialogActions
                    sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
                >
                    <Button variant="outlined" onClick={() => handleDeleteDialogclose()}>
                        No
                    </Button>
                    <Button variant="contained" onClick={() => deleteKraByid()} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={dialogComment}
                onClose={setDialogComment}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <IconButton
                    aria-label="delete"
                    sx={styleCross}
                    onClick={(e) => handleDialogCommentclose()}
                >
                    <ClearIcon />
                </IconButton>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "26px",
                        lineHeight: "39px",
                        color: "#212121",
                    }}
                >
                    Delete
                </DialogTitle>
                <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
                    Are you sure you want to delete this Comment ?
                </DialogContent>
                <DialogActions
                    sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
                >
                    <Button variant="outlined" onClick={() => handleDialogCommentclose()}>
                        No
                    </Button>
                    <Button variant="contained" onClick={() => {
                        if (selectedIndex !== null) {
                            deleteCommentByid(selectedIndex)
                        }
                    }
                    }

                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
