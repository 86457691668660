import * as React from "react";
import Box from "@mui/material/Box";
import {
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { inventoryService } from "../../service/Inventory/inventoryService";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { toast } from "material-react-toastify";
import NotFound from "../../components/styledComponent/NotFound";
import { attributeenum } from "../users/categorymodel";
import { array } from "yup";
import { useSelector } from "react-redux";

const headcellcolor = {
  cellcolor: {
    background: "rgb(189, 189, 189)",
    borderBottom: "none",
    position: "sticky",
    top: "0",
    zIndex: "99",
  },
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function IssueDevice() {
  const _permission = useSelector(
    (state: any) => state.InventoryPermissionApp.InventoryPermission
  );
  const [open, setOpen] = React.useState(true);
  const [modalOpen, setOpenmodal] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const [clickedItemId, setClickedItemId] = React.useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    //debugger
    setValue(newValue);
    // getAllInventoryItems();
  };
  const [ItemList, setItemList] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    clearAll();
    setOpenmodal(false);
    // navigate("/inventory-viewitem", selectedItemId);
  };

  const handleViewClick = (itemid: any) => {
    //debugger;
    setOpenmodal(false);
    setClickedItemId("");
    navigate(`/inventory-viewitem/${itemid}`);
  };
  const handleViewClick1 = () => {
    setOpenmodal(false);
    setClickedItemId("");
    navigate(`/inventory-viewitem/${clickedItemId}`);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, id: any) => {
    setClickedItemId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const [categories, Setcategories] = React.useState<any>([]);
  const [empId, setEmpId] = React.useState("");
  const [userId, setuserId] = React.useState("");
  const [dept, setDept] = React.useState("");
  const [name, setName] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [buttonDisable, setButtonDisable] = React.useState(true);

  const clearAll = () => {
    setEmpId("");
    setuserId("");
    setDept("");
    setName("");
    setPosition("");
    setClickedItemId("");
  };

  // const [brandValues, setbrandValues] = React.useState<any[]>([]);
  const [brands, Setbrands] = React.useState<any[]>([]);
  const [attributes, setattributes] = React.useState<attributeenum[]>([]);

  const [assignedList, SetassignedList] = React.useState<any>([]);
  const [inStockList, SetinStockList] = React.useState<any>([]);
  const [selectedBrands, setSelectedBrands] = React.useState<any[]>([]);
  const [catId, setcategoryId] = React.useState("");
  const [catName, setcatName] = React.useState("");
  const [DisplayDiv, setDisplayDiv] = React.useState(false);
  const [setConfiguration, setSetConfiguration] = React.useState<
    attributeenum[]
  >([]);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedAttribute, setExpandedAttribute] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(inStockList.length / rowsPerPage);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageCount1 = Math.ceil(assignedList.length / rowsPerPage1);
  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  // const handleBrandCheckboxChange = (brandName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const isChecked = event.target.checked;

  //     // Calculate the updated selected brands array
  //     const updatedSelectedBrands = isChecked
  //         ? [...selectedBrands, brandName]
  //         : selectedBrands.filter((name) => name !== brandName);

  //     setSelectedBrands(updatedSelectedBrands);

  //     // Use the updated selected brands array in the API call
  //     getAllInventoryItems(catId, selectedBrands, []);
  // }

  const handleBrandCheckboxChange =
    (brandName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        debugger;
        setSelectedBrands((prevSelectedBrands) => [
          ...prevSelectedBrands,
          brandName,
        ]);
      } else {
        //debugger;
        setSelectedBrands((prevSelectedBrands) =>
          prevSelectedBrands.filter((name) => name !== brandName)
        );
      }
      getAllInventoryItems(catId, selectedBrands, setConfiguration);
    };

  const handleValueCheckboxChange = (
    attribute: string,
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedSelectedAttributes = [...setConfiguration]; // Create a copy of the selectedAttributes array
    debugger;
    // Check if the selected attribute is already in the array
    const selectedAttributeIndex = updatedSelectedAttributes.findIndex(
      (attr) => attr.attribute === attribute
    );

    if (selectedAttributeIndex === -1) {
      // If the attribute is not in the array, add it with the selected value
      updatedSelectedAttributes.push({ attribute, value: [value] });
    } else {
      // If the attribute is already in the array, update its values
      const selectedAttribute =
        updatedSelectedAttributes[selectedAttributeIndex];
      if (event.target.checked) {
        selectedAttribute.value.push(value);
      } else {
        selectedAttribute.value = selectedAttribute.value.filter(
          (val: any) => val !== value
        );

        // Check if there are no selected values left for this attribute, and if so, remove the attribute
        if (selectedAttribute.value.length === 0) {
          updatedSelectedAttributes.splice(selectedAttributeIndex, 1);
        }
      }
    }
    setSetConfiguration(updatedSelectedAttributes);

    getAllInventoryItems(catId, selectedBrands, updatedSelectedAttributes);
  };

  // React.useEffect(() => {
  //     //  //debugger
  //     getCategoriesDropdown();

  // }, []);
  React.useEffect(() => {
    if (_permission.tab_IssueDeviceListing_Access !== true) {
      navigate("/dashboard");
    }

    getCategoriesDropdown();
    getAllInventoryItems(catId, selectedBrands, setConfiguration);
  }, [selectedBrands, catId, setConfiguration]);

  //Get All Inventory Categories Dropdown --Api call
  const getCategoriesDropdown = async () => {
    let values = {
      search: "",
      take: 100,
      skip: 0,
    };
    inventoryService.getCategories(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        //  //debugger;
        var data = response?.data;
        Setcategories(data);
        console.log(categories);
      }
    });
  };
  const getAllInventoryItems = async (
    categoryId: string,
    brand: any[],
    setSetConfiguration: any[]
  ) => {
    //debugger;

    const brandObject = brand.map((brandName) => ({ brandName }));
    //debugger
    try {
      let input = {
        categoryId: categoryId,
        brand: brandObject,
        setConfiguration: setSetConfiguration,
      };
      await inventoryService
        .getAllInventoryItems(input)
        .then(async (response: any) => {
          //debugger;
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            var data = response.data;
            //debugger
            setItemList(data);
            // Filter items based on status
            const inStockItems = data.filter((item: any) => item.status == 1);
            const assignedItems = data.filter(
              (item: any) => item.status == 2 || item.status == 3
            );

            // Update state using the filtered lists
            SetinStockList(inStockItems);
            SetassignedList(assignedItems);
            console.log(inStockList, "inStockList");
            console.log(assignedList, "assignedList");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getCategoryDetailsById = async (categoryId: any) => {
    try {
      //debugger;
      await inventoryService
        .getCategorybyId(categoryId)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            var data = response.data;
            //debugger;
            Setbrands(response.data.brand);
            setattributes(response.data.setConfiguration);

            // console.log(instockList);
            console.log(attributes);
            setDisplayDiv(true); // Update DisplayDiv state
            // getAllInventoryItems(categoryId, [], []);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {});
    }
  };

  // const handleSearchClick = (value: any) => {
  //     let input = {
  //         employeeId: value
  //     }

  //     inventoryService.getEmployeeDetails(input)
  //         .then((response: any) => {
  //             if (response.data.isError) {
  //                 // Handle specific error cases if needed
  //             } else {
  //                 var data = response?.data;
  //                 setButtonDisable(false);
  //                 setDept(data.department);
  //                 setName(data.name);
  //                 setPosition(data.position);
  //                 setuserId(data.userId);
  //             }
  //         })
  //         .catch((error: any) => {
  //             error.data.Error.map((err: any, idx: any) => {
  //                 setButtonDisable(true);
  //                 setDept("");
  //                 setName("");
  //                 setPosition("");
  //                 setuserId("");
  //             });
  //         });
  // };

  const handleSearchClick = (value: any) => {
    let input = {
      employeeId: value,
    };

    inventoryService
      .getEmployeeDetails(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          var data = response?.data;
          setButtonDisable(false);
          setDept(data.department);
          setName(data.name);
          setPosition(data.position);
          setuserId(data.userId);
        }
      })
      .catch((error: any) => {
        if (error.data && error.data.Error) {
          error.data.Error.forEach((errMsg: string) => {
            toast.error(errMsg);
          });
        }

        setButtonDisable(true);
        setDept("");
        setName("");
        setPosition("");
        setuserId("");
      });
  };

  const handleAssignClick = async (
    empId: string,
    userId: string,
    name: string,
    dept: string,
    position: string
  ) => {
    try {
      let input = {
        productId: clickedItemId,
        userId: userId,
        name: name,
        department: dept,
        position: position,
        employeeId: empId,
      };
      //debugger;
      await inventoryService.assignDevice(input).then(async (response: any) => {
        if (!response) {
          setOpenmodal(false);
          toast.warning("Error while Assigning");
        } else {
          var data = response.data;
          //debugger;
          setOpenmodal(false);
          toast.success("Device Assigned Successfully");
          setButtonDisable(true);
          setClickedItemId("");
          setEmpId("");
          setuserId("");
          setDept("");
          setName("");
          setPosition("");
          getAllInventoryItems(catId, [], []);
        }
      });
    } catch (ex: any) {
      ex?.data?.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Select Employee
        </BootstrapDialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Emp ID"
                  fullWidth
                  value={empId}
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(
                      /[^0-9]\s{0,}$/g,
                      ""
                    );
                    value = value
                      .replace(/\s{2,}$/g, "")
                      .trimStart()
                      .substring(0, 6);
                    setEmpId(value);
                  }}
                  InputProps={{
                    endAdornment: empId != "" && (
                      <IconButton onClick={() => handleSearchClick(empId)}>
                        <SearchIcon style={{ color: "#1DA7FF" }} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Name"
                  multiline
                  disabled
                  maxRows={4}
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Department"
                  multiline
                  disabled
                  maxRows={4}
                  fullWidth
                  value={dept}
                  onChange={(e) => setDept(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Designation"
                  multiline
                  disabled
                  maxRows={4}
                  fullWidth
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={buttonDisable}
            onClick={() =>
              handleAssignClick(empId, userId, name, dept, position)
            }
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <Breadcrumbs sx={{ marginBottom: "10px" }} aria-label="breadcrumb">
          <Typography color="text.primary">Issue Item</Typography>
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Paper sx={{ height: "100%" }} elevation={0}>
              <Box
                sx={{
                  background: "rgb(240, 249, 255)",
                  p: 2,
                }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={
                    categories?.find(
                      (option: any) => option.category == catName
                    ) ?? null
                  }
                  options={categories}
                  getOptionLabel={(option: any) => option.category}
                  onChange={async (event, value) => {
                    setcategoryId(value?.id ?? "");
                    getCategoryDetailsById(value?.id ?? "");
                    setcatName(value?.category ?? "");
                    // getAllInventoryItems(value?.id, [], "");
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Category"
                      value={catName}
                    />
                  )}
                />
              </Box>

              <Box sx={{ p: 2 }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  py={1}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      color: "#1DA7FF",
                    }}
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <TuneRoundedIcon /> : <CloseRoundedIcon />}
                    Filter & Refine
                  </Typography>
                  <Button
                    id="basic-button"
                    aria-controls={open1 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? "true" : undefined}
                    onClick={(e: any) => {
                      setSelectedBrands([]);
                      setSetConfiguration([]);
                      setcategoryId("");
                      setExpanded(false);
                      setExpandedAttribute(false);
                      Setbrands([]);
                      setattributes([]);
                      setOpen(true);
                      setcatName("");
                    }}
                  >
                    Clear All
                  </Button>
                </Stack>

                {!open && (
                  <>
                    <Accordion expanded={expanded} sx={{ boxShadow: "none" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => setExpanded(!expanded)}
                      >
                        <Typography fontWeight="bold">Brand</Typography>
                      </AccordionSummary>
                      {brands.map((brand) => (
                        <Stack key={brand.id} paddingLeft={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedBrands.includes(
                                  brand.brandName
                                )}
                                onChange={handleBrandCheckboxChange(
                                  brand.brandName
                                )}
                              />
                            }
                            label={brand.brandName}
                          />
                        </Stack>
                      ))}
                    </Accordion>
                    {attributes.map((att) => (
                      <Accordion
                        expanded={expandedAttribute}
                        sx={{ boxShadow: "none" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          onClick={() =>
                            setExpandedAttribute(!expandedAttribute)
                          }
                        >
                          <Typography fontWeight="bold">
                            {att.attribute}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {att.value.map((value, valueIndex) => (
                            <Stack key={value} paddingLeft={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // checked={selectedValues.includes(value)}
                                    onChange={(event) =>
                                      handleValueCheckboxChange(
                                        att.attribute,
                                        value,
                                        event
                                      )
                                    }
                                    value={valueIndex}
                                  />
                                }
                                label={value}
                              />
                            </Stack>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper
              sx={{
                height: "100%",
                p: 2,
                "& .css-13xfq8m-MuiTabPanel-root": {
                  padding: "0",
                  paddingTop: 2,
                },
              }}
              elevation={0}
            >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab label="In-stock" value="1" />
                    <Tab label="Assigned" value="2" />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      height: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <Table
                      sx={{ minWidth: 650 }}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead sx={headcellcolor.cellcolor}>
                        <TableRow>
                          <TableCell sx={headcellcolor.cellcolor}>
                            <Typography fontWeight="bold">Item Name</Typography>
                          </TableCell>
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">SKU</Typography>
                          </TableCell>
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">Brand</Typography>
                          </TableCell>
                          {/* <TableCell>Model</TableCell> */}
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">Action</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {ItemList && ItemList.length > 0 ? (
                        <TableBody sx={{ overflowY: "auto", maxHeight: "300px" }}>
                          {inStockList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item: any, index: any) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell scope="row">
                                  <Box>{item.itemName}</Box>
                                </TableCell>
                                <TableCell>
                                  <Box>{item.sku}</Box>
                                </TableCell>
                                <TableCell key={index}>
                                  {item.brand.map(
                                    (brandItem: any, brandIndex: any) => (
                                      <span key={brandIndex}>
                                        {brandItem.brandName}
                                        {brandIndex < item.brand.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )}
                                </TableCell>

                                <TableCell>
                                  {(_permission.action_IssueDeviceListing_View_Access ==
                                    true ||
                                    _permission.action_IssueDeviceListing_View_Access ==
                                      true) && (
                                    <div>
                                      <Button
                                        id="basic-button"
                                        aria-controls={
                                          open1 ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open1 ? "true" : undefined
                                        }
                                        onClick={(e: any) => {
                                          handleClick(e, item?.id);
                                        }}
                                      >
                                        <MoreHorizIcon />
                                      </Button>
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open1}
                                        onClose={handleClose1}
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                        sx={{
                                          "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
                                            {
                                              boxShadow:
                                                "0px 2px 1px -1px rgba(0,0,0,0.0), 0px 1px 1px 0px rgba(0,0,0,0.0), 0px 1px 3px 0px rgba(0,0,0,0.0)",
                                              border: "1px solid #EEE",
                                            },
                                        }}
                                      >
                                        {_permission.action_IssueDeviceListing_Add_Access ==
                                          true && (
                                          <MenuItem onClick={handleClickOpen}>
                                            <Button
                                              variant="outlined"
                                              fullWidth
                                              sx={{
                                                height: "auto",
                                                color: "#1DA7FF",
                                                py: "0px",
                                              }}
                                              onClick={() => {
                                                setAnchorEl(null);
                                              }}
                                            >
                                              Assign
                                            </Button>
                                          </MenuItem>
                                        )}
                                        {_permission.action_IssueDeviceListing_View_Access ==
                                          true && (
                                          <MenuItem>
                                            <Button
                                              variant="outlined"
                                              fullWidth
                                              sx={{
                                                height: "auto",
                                                color: "#1DA7FF",
                                                py: "0px",
                                              }}
                                              onClick={() => {
                                                handleViewClick1();
                                                setAnchorEl(null);
                                              }}
                                            >
                                              View
                                            </Button>
                                          </MenuItem>
                                        )}
                                      </Menu>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      ) : (
                        <TableRow>
                          {/* <TableCell colSpan={15} align="center">
                                                            No Record Found
                                                          </TableCell> */}
                          <NotFound NotfoundText="No Record Found" />
                        </TableRow>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={inStockList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={() =>
                      `Total: ${inStockList?.length}  Page ${
                        page + 1
                      } of ${pageCount}`
                    }
                  />
                </TabPanel>
                <TabPanel value="2">
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      height: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <Table
                      sx={{ minWidth: 650 }}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead sx={headcellcolor.cellcolor}>
                        <TableRow>
                          <TableCell sx={headcellcolor.cellcolor}>
                            <Typography fontWeight="bold">Item Name</Typography>
                          </TableCell>
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">SKU</Typography>
                          </TableCell>
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">Brand</Typography>
                          </TableCell>
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">
                              Assigned To
                            </Typography>
                          </TableCell>
                          {/* <TableCell>Model</TableCell> */}
                          <TableCell sx={headcellcolor.cellcolor}>
                            {" "}
                            <Typography fontWeight="bold">Action</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {ItemList && ItemList.length > 0 ? (
                        <TableBody sx={{ height: "300px", overflowY: "auto" }}>
                          {assignedList
                            .slice(
                              page1 * rowsPerPage1,
                              page1 * rowsPerPage1 + rowsPerPage1
                            )
                            .map((item: any, index: any) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {item.itemName}
                                </TableCell>
                                <TableCell>{item.sku}</TableCell>
                                <TableCell key={index}>
                                  {item.brand.map(
                                    (brandItem: any, brandIndex: any) => (
                                      <span key={brandIndex}>
                                        {brandItem.brandName}
                                        {brandIndex < item.brand.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )}
                                </TableCell>
                                <TableCell>
                                  {item.assignToName
                                    ? item?.assignToName
                                    : "--"}
                                </TableCell>
                                {/* <TableCell>DELL KB216 USB Wired Keyboard Wired USB Desktop Keyboard</TableCell> */}
                                <TableCell>
                                  <Button
                                    onClick={() => {
                                      handleViewClick(item.id);
                                    }}
                                    variant="outlined"
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      ) : (
                        <TableRow>
                          {/* <TableCell colSpan={15} align="center">
                                                                    No Record Found
                                                                  </TableCell> */}
                          <NotFound NotfoundText="No Record Found" />
                        </TableRow>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={assignedList.length}
                    rowsPerPage={rowsPerPage1}
                    page={page1}
                    onPageChange={handleChangePage1}
                    onRowsPerPageChange={handleChangeRowsPerPage1}
                    labelDisplayedRows={() =>
                      `Total: ${assignedList?.length}  Page ${
                        page + 1
                      } of ${pageCount1}`
                    }
                  />
                </TabPanel>
              </TabContext>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
