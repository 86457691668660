import { http } from "./http-common";
class ChatService {
    getAllUserChat = async (achieve: any): Promise<any> => {
        return await http.get<any>("/v1/Chat/GetAllUserChats" );
    };
    GetProjectChatByUserId = async (): Promise<any> => {
        return await http.get<any>("/v1/Chat/GetProjectChatByUserId" );
    };
    GetProjectsNotInChat = async (): Promise<any> => {
        return await http.get<any>("/v1/Chat/GetProjectsNotInChat" );
    };
    getUserChatByProjectId = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Chat/GetUserChatByProjectId?projectId=${id}`);
    };
    GetUserChatById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Chat/GetUserChatById?Id=${id}`);
    };

    createUserChat = async (leave: any): Promise<any> => {
        return await http.post<any>("/v1/Chat/CreateUserChat", leave);
    };
    updateUserChat = async (leave: any): Promise<any> => {
        return await http.post<any>("/v1/Chat/UpdateUserChat", leave);
    };
    getDropdownData = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetProjectByProjectOwnerId/` +id);
    }
    ReadUnReadMessage = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Chat/ReadUnreadMessages?Id=${id}`);
    }
}
export const chatService = new ChatService ();