import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box, Breadcrumbs, Divider, Modal, Grid, Button, TablePagination, Checkbox, Collapse, IconButton, CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { reserveServices } from '../service/Reserve-services';
import { toast } from 'material-react-toastify';
import ReactApexChart from 'react-apexcharts';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HrReporttop from './reports/HrReportsstyle';
import TableSortLabel from "@mui/material/TableSortLabel";

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const MilestoneGrid = (props: { row: any; onRowClick: (rowData: any) => void; expandedRow: number | null }) => {
    const { row, onRowClick, expandedRow } = props;
    const isOpen = expandedRow === row.departmentId;
    const [innerRows, setinnerRows] = React.useState([]);

    const getResourceBillingReport = async (departmentId: any) => {
        try {
            await reserveServices
                .getBillingResourceReport(departmentId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setinnerRows(response?.data);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& th, & td": { whiteSpace: "nowrap" },
                }}
                onClick={() => onRowClick(row)}
                style={{ cursor: 'pointer' }}
            >
                <TableCell>
                    <IconButton
                        color="primary"
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            getResourceBillingReport(row.departmentId);
                            // setOpen(!open);
                        }}
                    >
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" >
                    {row.departmentName}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    sx={{ pl: 0, backgroundColor: "#EEEEEE" }}
                    colSpan={11}
                >
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow sx={{ "&:last-child td,": { border: 0 } }}>
                                    <TableCell width={75} />
                                    <TableCell width={190}>Total Resources</TableCell>
                                    <TableCell>On Billing Resources</TableCell>
                                    <TableCell>Non Billing Resources</TableCell>
                                    <TableCell>Occupance value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {innerRows.length > 0 ? (
                                    innerRows.map((item: any) => (
                                        <TableRow
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell width={60} />
                                            <TableCell width={190}>{item?.totalResources}</TableCell>
                                            <TableCell>{item?.billingResourcesCount}</TableCell>
                                            <TableCell>{item?.nonBillingResourcesCount} </TableCell>
                                            <TableCell>{item?.resourceOccupancyIndex} </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={15} align="center">
                                            No Record Found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};
export default function ResourceBillingReport() {
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const navigate = useNavigate();
    const [reports, setReports] = React.useState<any>([]);
    const data = reports.length > 0 ? reports[0] : {};

    console.log(reports, "reports");
    const [toggleLoader, setToggleLoader] = useState(false);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [selectedRowData, setSelectedRowData] = React.useState<any>(null);
    console.log(data, "data")
    const Total = Number(data?.totalResourcesOfDepartments) || 0;
    const Billing = Number(data?.allDepartmentBillingResourcesCount) || 0;
    const NonBilling = Number(data?.allDepartmentNonBillingResourcesCount) || 0;

    console.log(Total, "Total");
    console.log(Billing, "Billing");
    console.log(NonBilling, "NonBilling");

    const [options, setOptions] = React.useState<any>({
        series: [Total, Billing, NonBilling],
        colors: ['#2DD36F', '#FFAD01', '#FF0000'],
        labels: ['Total Resources', 'On Billing Resources', 'Non Billing Resources'],
        legend: {
            show: false,
        },
    });
    const [isAscending, setIsAscending] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    React.useEffect(() => {
        setOptions({
            ...options,
            series: [Total, Billing, NonBilling],
        });
    }, [Total, Billing, NonBilling]);

    const handleRowClick = (rowData: any) => {
        if (expandedRow === rowData.departmentId) {
            setExpandedRow(null);
            setSelectedRowData(null);
            setOptions({
                series: [Total, Billing, NonBilling],
                colors: ['#2DD36F', '#FFAD01', '#FF0000'],
                labels: ['Total Resources', 'On Billing Resources', 'Non Billing Resources'],
                legend: {
                    show: false,
                },
            });
        } else {
            setExpandedRow(rowData.departmentId);
            setSelectedRowData(rowData);
            const newOptions = {
                series: [
                    rowData.totalResources,
                    rowData.billingResourcesCount,
                    rowData.nonBillingResourcesCount,
                ],
                colors: ['#2DD36F', '#FFAD01', '#FF0000'],
                labels: ['Total Resources', 'On Billing Resources', 'Non Billing Resources'],
                legend: {
                    show: false,
                },
            };
            setOptions(newOptions);
        }
    };

    const getResourceBillingReport = async (departmentId: any) => {
        setToggleLoader(true);
        try {
            await reserveServices
                .getBillingResourceReport(departmentId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                        setToggleLoader(false);
                    } else {

                        setReports(response?.data);
                        setToggleLoader(false);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    };
    React.useEffect(() => {
        if (_permission.tab_ReserveBrigade_ReserveBrigadeListing_Access !== true) {
            navigate("/dashboard");
        }
        getResourceBillingReport("");
    }, []);
    const handleSort = () => {
        const sortedReports = [...reports].sort((a, b) => {
            if (a.departmentName < b.departmentName) {
                return isAscending ? -1 : 1;
            }
            if (a.departmentName > b.departmentName) {
                return isAscending ? 1 : -1;
            }
            return 0;
        });

        setReports(sortedReports);
        setIsAscending(!isAscending);
    };

    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper
                elevation={0}
                sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99, background: 'white', height: 'auto' }}
            >
                <Box>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ mt: 1.5 }}
                    >
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                                Resource Billing Report
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={8}>
                            {reports?.length > 0 && (
                                <Paper
                                    elevation={0}
                                    sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99 }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <TableContainer
                                            component={Paper}
                                            elevation={0}
                                            sx={{
                                                mt: 0.5,
                                                maxHeight: "calc(100vh - 350px)",
                                            }}
                                        >
                                            <Table aria-label="collapsible table" size="small" stickyHeader>
                                                <TableHead>
                                                    <TableRow sx={{ "& th, & td": { whiteSpace: "nowrap" } }}>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={true}
                                                                direction={isAscending ? 'asc' : 'desc'}
                                                                onClick={() => handleSort()}
                                                            >
                                                                Department
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {reports.map((row: any) => (
                                                        <MilestoneGrid
                                                            key={row.departmentId}
                                                            row={row}
                                                            onRowClick={handleRowClick}
                                                            expandedRow={expandedRow}
                                                        />
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Paper>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={4} sx={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                            <ReactApexChart
                                options={options}
                                series={options.series}
                                type="pie"
                                width={350}
                            />

                            <Stack direction="row" justifyContent="center">
                                <Box>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "6px" }}>
                                        <Box sx={{ backgroundColor: '#2DD36F', width: '16', height: '16', borderRadius: '50px' }}></Box>
                                        <Box>
                                            <Typography>Total Resources</Typography>
                                        </Box>
                                    </Box>
                                    <Box mt='3' sx={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "6px" }}>
                                        <Box sx={{ backgroundColor: '#FFAD01', width: '16', height: '16', borderRadius: '50px' }}></Box>
                                        <Box>
                                            <Typography>On Billing Resources</Typography>
                                        </Box>
                                    </Box>
                                    <Box mt='3' sx={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "6px" }}>

                                        <Box sx={{ backgroundColor: '#FF0000', width: '16', height: '16', borderRadius: '50px' }}></Box>
                                        <Box>
                                            <Typography>Non - Billing Resources</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Stack>

                        </Grid>
                    </Grid>
                </Box>



                <Box sx={{ display: "flex", gap: "16px" }}>
                    <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                        <Typography component="h2" sx={{ fontSize: '16px' }}>
                            Total Resources
                        </Typography>
                        <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#2DD36F' }}>
                            {data?.totalResourcesOfDepartments}

                        </Typography>
                    </Box>
                    <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                        <Typography component="h2" sx={{ fontSize: '16px' }}>
                            On Billing Resources
                        </Typography>
                        <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#FFAD01' }}>
                            {data?.allDepartmentBillingResourcesCount}

                        </Typography>
                    </Box>
                    <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                        <Typography component="h2" sx={{ fontSize: '16px' }}>
                            Non-Billing Resources
                        </Typography>
                        <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#FF0000' }}>
                            {data?.allDepartmentNonBillingResourcesCount}

                        </Typography>
                    </Box>
                </Box>


            </Paper>
        </>
    );
}