import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { Autocomplete, Chip, CircularProgress, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { Department } from "../Interfaces/IDepartment";
import { eTypeOfUser } from "../Enum/eTypeOfUser";
import { clsDepartmentList } from "../service/common-services/department";
import HrReporttop from "./reports/HrReportsstyle";
import theme from "../theme/theme";
import NotFound from "../components/styledComponent/NotFound";
import { brownieReportService } from "../service/BrownieReport";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "react-rainbow-components";
import moment from "moment";
import getDate from "../components/GetDateWithSimpletime";
import { useNavigate } from "react-router-dom";

export default function BrownieReports() {
    // const _permission = useSelector((state : any) => state.BrowniePermissionApp.BrowniePermission);
    const _permission = useSelector(
        (state: any) => state.ReportPermissionApp.ReportPermission
    );
    const navigate = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [reports, setReports] = React.useState<any>([]);
    const [selectedDepartmentId, setselectedDepartmentId] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [brownieData, setBrownieData] = useState([]);
    const newStartDate = new Date();
    newStartDate.setMonth(newStartDate.getMonth() - 1);
    const [startDate, setStartDate] = useState(getDate(newStartDate));
    const [endDate, setEndDate] = useState(getDate(new Date()));
    const pageCount = Math.ceil(brownieData.length / rowsPerPage);
    const [disableDepartment , setDisableDepartment] = useState(false);



    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    React.useEffect(() => {
        if(_permission.page_BrownieReport_Access !== true){
            navigate("/dashboard");
        }

        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                getBrownieReportsData(_authUser.employeeInfo.departmentId, startDate , endDate);
                setselectedDepartmentId(_authUser.employeeInfo.departmentId);
                setDisableDepartment(true);
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setselectedDepartmentId("");
                getBrownieReportsData("" , startDate ,  endDate);
                setDisableDepartment(false);
            }
        };
        departmentsData();
    }, []);


    const getBrownieReportsData = async (departId: any , stDate : string , edDate : string) => {
        const payload = {
            departmentId: departId,
            startDate: stDate,
            endDate: edDate
        }
        setToggleLoader(true);

        try {
            await brownieReportService.getBrownieReport(payload).then(async (response: any) => {
                if (response.data.isError) { setToggleLoader(false); }
                else {
                    setBrownieData(response.data);
                    setToggleLoader(false);
                }
            })
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }

    const getDepartmentNameFromID = (id: string) => {
        const newList = [...departments];
        const abc = newList.find((item) => item.id == id);
        return abc?.name;
    }


    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                >
                    <Box >
                        <Typography variant="h6" sx={{ fontWeight: 600, paddingLeft: '16px' }} component="h2">
                            Brownie Points Reports
                        </Typography>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 0, sm: 2 } }}>
                        <Stack alignItems="center" direction="row" spacing={2}>
                            <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: "20px", md: "0px" }, }}>
                                <Autocomplete
                                    disabled={disableDepartment}
                                    value={
                                        departments?.find(
                                            (option) => option.id == selectedDepartmentId
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        debugger
                                        setselectedDepartmentId(value?.id ?? "");
                                        getBrownieReportsData(value?.id ?? "" , startDate , endDate);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="element.name"
                                            variant="outlined"
                                            value={selectedDepartmentId}
                                        />
                                    )}
                                    style={{ width: 220 }}
                                />


                            </Box>
                            <Box sx={{position:"relative", width:"155px"}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        borderRadius="semi-square"
                                        placeholder="Select Date"
                                        name="meetingDate"
                                        labelAlignment="left"
                                        value={startDate}
                                        // style={{ width: "14%" }}
                                        minDate={moment().subtract(1, "years").toDate()}

                                        maxDate={
                                            new Date(
                                                new Date().getFullYear(),
                                                11,
                                                31
                                            )
                                        }

                                        onChange={(newValue: any) => {
                                            const trimmedStartDate = getDate(newValue);
                                            setStartDate(trimmedStartDate);
                                            getBrownieReportsData(selectedDepartmentId , trimmedStartDate , endDate);

                                        }}

                                    />
                                </LocalizationProvider>


                            </Box>
                            <Box sx={{position:"relative", width:"155px"}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        borderRadius="semi-square"
                                        placeholder="Select Date"
                                        name="meetingDate"
                                        labelAlignment="left"
                                        value={endDate}
                                        // style={{ width: "14%" }}
                                        minDate={moment().subtract(1, "years").toDate()}

                                        maxDate={
                                            new Date(
                                                new Date().getFullYear() ,
                                                11,
                                                31
                                            )
                                        }

                                        onChange={(newValue: any) => {
                                            const trimmedStartDate = getDate(newValue);
                                            setEndDate(trimmedStartDate);
                                            getBrownieReportsData(selectedDepartmentId , startDate , trimmedStartDate);
                                        }}

                                    />
                                </LocalizationProvider>
                            </Box>


                        </Stack>
                    </Box>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    {brownieData && brownieData?.length > 0 &&
                        <TableContainer component={Paper} elevation={0} sx={{
                            mt: 0.5,
                            maxHeight: "calc(100vh - 215px)",
                            [theme.breakpoints.down("sm")]: {
                                maxWidth: "400px",
                            },
                        }}>
                            <Table stickyHeader aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Department</TableCell>
                                        <TableCell>Employee ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Brownie Points</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {brownieData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => (
                                        <TableRow style={{ textDecoration: 'none', cursor: "pointer" }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{getDepartmentNameFromID(row?.department)}</TableCell>
                                            <TableCell>{row?.employeeId}</TableCell>
                                            <TableCell component="th" scope="row">{row?.name}</TableCell>
                                            <TableCell>{row?.browniePoint}</TableCell>
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {brownieData?.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                        component="div"
                        count={brownieData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => `Total: ${brownieData?.length}  Page ${page + 1} of ${pageCount}`}
                    />
                </Box>
            </Paper>
        </>
    );
}