import theme from "../../theme/theme";

const Myprojectttop = {
    topbar: {
        flexWrap: 'wrap',
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: { xs: "start", md: 'flex-start' },
        alignItems: { xs: "start", md: "center" },
        mt: 2,
    },

    heading: {
        justifyContent: "space-between",
        fontSize: "24px",
        fontWeight: 600,
        order: { xs: '1', sm: '1' }
    },

    navtab: {
        order: { xs: '4', md: '3', lg: '3' },
        width: { xs: "100%", md: 'auto' },
        justifyContent: { xs: "space-between", md: "flex-start" },
        mt: { xs: 2, md: 0 },
        mr: 3,
    },
    searchtab: {
        "& .searcg-field": {
            width: { xs: "100%", sm: 'auto' },
            // mt:2,
        },
        width: { xs: "100%", sm: 'auto' },
        order: { xs: '3', md: '4', lg: '4' },
        ml: { xs: 0, sm: 'auto' },
        mt: { xs: 2, sm: 0 },
    },

    cardstyle: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'strech'
    },

    New: {
        fontWeight: 400,
        color: "#16B5BF",
        m: 0,
    },
    Completed: {
        fontWeight: 400,
        color: theme.palette.success.dark,
        m: 0,
    },

    Onhold: {
        fontWeight: 400,
        color: theme.palette.error.dark,
        m: 0,
    },

    Inprogress: {
        fontWeight: 400,
        color: theme.palette.primary.main,
        m: 0,
    },
    styleCross: {
        position: "absolute" as "absolute",
        right: "-20px",
    },
}
export default Myprojectttop;