import { http } from "../http-common";

class WebSiteVisitorService{
    getWebsiteVisitors = async (data: any): Promise<any> => {
        return await http.post<any>(`/v1/Master/GetWebsiteVisitors?year=${data}`);
    };
    AddWebsiteVisitor = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Master/AddWebsiteVisitors", data);
    };
    UpdateWebsiteVisitor = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Master/UpdateWebsiteVisitors", data);
    };
}
export const webSiteVisitorService = new WebSiteVisitorService();