import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import * as Icons from "../../assests/icons/icons";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Box, TablePagination } from "@mui/material";
import Leavepagestyle from "../leavemanagement/Leavemanagementstyle";
import { learningsessionServices } from "../../service/learning-session";
import SearchBar from "../../components/styledComponent/SearchBar";
import theme from "../../theme/theme";
import NotFound from "../../components/styledComponent/NotFound";
import FileUploadPics from "../../components/styledComponent/FileUploadPics";
import { makeStyles } from "@mui/styles";
import { apiUrl } from "../../service/common-services/apiURL";
import NoImage from "../../assests/images/NoImageFound.png";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  anchor: {
    wordWrap: 'break-word'
  },
}));
const styles: any = {
  EventInfoCard: {
    border: "1px solid #bdbdbd",
    padding: "0",
    bordeRadius: "5px",
    cursor: "pointer",
    minHeight: '229px',
    display: "flex",
    height: '100%',
    flexDirection: "column",
    "word-break": "break-all",

    justifyContent: "space-between",
    "&:hover": {
      boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
    },
  },
  innerSpace: {
    // padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  EventName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.primary.dark,
  },
  EventId: {
    letterSpacing: "0.1px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    color: "#232360",
  },
  EventDesc: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginTop: "15px",
  },

  LineClamp: {
    marginTop: "15px",
    whiteSpace: "nowrap",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  DialogTitle: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: " 39px",
    color: theme.palette.primary.dark,
  },

  InnerHtml: {
    marginBottom: "12px",
    "& p": {
      margin: 0,
      textAlign: "center",
      fontSize: "24px",
      color: theme.palette.primary.dark,
    },
  },
  TitleLabel: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#232360",

  },
  TitleValue: {
    fontWeight: 600,
    fontSize: " 18px",
    lineHeight: "21px",
    color: " #424242",
    marginLeft: "8px",
    "word-break": "break-all",
  },

};
export default function LearningSession() {
  const navigate = useNavigate(); 
  const classes = useStyles();
  const _permission = useSelector(
    (state: any) => state.LearningSessionApp.LearningSessionPermission
  );
  const [buttonDisable, setButtonDisable] = useState(false);
  const [dataList, setDataList] = useState<any>([]);
  const [id, setId] = useState("");
  const [currentId, setCurrentId] = React.useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [link, setlink] = useState("");
  const [searchValue, setSearchvalue] = useState("");
  const [documentFile, setDocumentFile] = React.useState<any>(null);
  const [tempDocumentFileURL, setTempDocumentFileURL] = React.useState<any>("");
  const triggerDocumentFile = (file: FileList, index: number) => {
    //debugger;
    setDocumentFile(file[0]);
    setTempDocumentFileURL(URL.createObjectURL(file[0]));
    setButtonDisable(false);
  };
  let abc = tempDocumentFileURL.substring(0, 9);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const pageCount = Math.ceil(dataList.length / rowsPerPage);

  const [page, setPage] = React.useState(0);
  const handleChangeRowsPerPage = (event: any) => {
    const selectedValue = parseInt(event.target.value, 10);
    setRowsPerPage(selectedValue);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleClearField = () => {
    setId("");
    setTitle("");
    setlink("");
    setDescription("");
    setKeywords("");
    setTempDocumentFileURL("")
    setDocumentFile(null)
  }

  let state = { id: id ?? "" };
  let pageTitle = "Add Session";
  if (state.id.length > 0) {
    pageTitle = "Update Session";
  }
  //  -----------------------------------------

  const [openSessionDialog, setOpenSessionDialog] = React.useState(false);
  const handleClickOpenSessionDialog = () => {
    setOpenSessionDialog(true);
  };

  const handleCloseSessionDialog = () => {
    setOpenSessionDialog(false);
    handleClearField();
    setButtonDisable(false);
  };

  const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);
  const handleCloseAlert = () => {
    setDeleteAlertOpen(false);
  };

  // -------------------------------------------
  useEffect(() => {
    if (_permission.page_ManageLearningSessionListing_Access !== true) {
      navigate("/dashboard");
    }
    geAllSessionDetails("");
  }, []);

  const triggerSearch = async (newValue: string) => {
    setSearchvalue(newValue)
    geAllSessionDetails(newValue);
    setPage(0);
  };

  const geAllSessionDetails = async (searchVal: any) => {
    try {

      await learningsessionServices.getAllSession({ search: searchVal }).then((response: any) => {
        if (response.data.isError) {
        } else {
          setDataList(response?.data);
        }
      });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any) => {
        toast.warning(err);

      });
    }
  };

  function validURL(str: any) {
    var pattern = new RegExp('^(https?:\\/\\/)', 'i'); 
    return pattern.test(str);
  }

  const deleteSessionData = async (id: any, e: any) => {
    e.stopPropagation();
    setDeleteAlertOpen(true);
    setCurrentId(id);
  };

  const deleteSession = async () => {
    setDeleteAlertOpen(false);
    try {
      await learningsessionServices.deleteSession(currentId).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Session Deleted successfully.");
          setCurrentId("");
          geAllSessionDetails(searchValue);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const uploadSessionImage = async () => {
    const formData = new FormData();
    formData.append("File", documentFile);
    formData.append("FileFor", title);

    try {

      let response = await learningsessionServices.uploadSessionImage(formData)
      if (response.data.isError) {
      } else {

        return response?.data;
      }
    } catch (ex: any) {
      ex.data.errors.File.map((err: any, idx: any) => {
        toast.warning(err);

      });
    }
  };

  const updateSessionDetailsById = async (values: any) => {
    debugger
    setButtonDisable(true)
    try {
      let uploadImageResponse = null;
      if (documentFile) {
        const uploadImagePromise = uploadSessionImage();
        [uploadImageResponse] = await Promise.all([uploadImagePromise]);
        values.thumbnail = uploadImageResponse;
      }
      else {
        values.thumbnail = tempDocumentFileURL
      }
      values.id = id;
      await learningsessionServices.updateSessionById(values).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Session Updated successfully");
          setId("");
          handleClearField();
          geAllSessionDetails(searchValue);
          setOpenSessionDialog(false);
          setButtonDisable(false)
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setButtonDisable(false)
      });
    }
  };

  const addSession = async (values: any) => {
    debugger
    let uploadImageResponse = null;
    setButtonDisable(true)
      const uploadImagePromise = uploadSessionImage();
      [uploadImageResponse] = await Promise.all([uploadImagePromise]);

      if(uploadImageResponse !== undefined){
        values.thumbnail = uploadImageResponse;

        await learningsessionServices.addSession(values).then((response: any) => {
          debugger
          if (response.data.isError) {
          }
          else {
            toast.success("Session Added successfully");
            handleClearField();
            setButtonDisable(false)
            geAllSessionDetails(searchValue);
            setOpenSessionDialog(false);
          }
        });
      }
      else {

      }
     

  };

  const fieldValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title Type is required"),
    description: Yup.string().required("Description is required"),
    link: Yup.string().required("Link is required"),
  });
  const cancelDocumentFile = () => {
    setTempDocumentFileURL("");
    setDocumentFile(null);
  };

  return (
    <>

      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          alignItems={{ xs: 'strech', sm: "center" }}
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Learning Session
          </Typography>

          {/* <Box>
                
              <InfoPopover  onInput={"Setup"}/>
              </Box> */}

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="strech" spacing={2}>
            <Box sx={{ alignContent: "center", mr: 2 }}>
              <SearchBar
                value={searchValue}
                callBackFunction={triggerSearch}
              />
            </Box>
            {_permission.tab_ManageLearningSessionEdit_Access && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickOpenSessionDialog}
                sx={{ width: '100%' }}
              >
                Add Session
              </Button>
            )}
          </Stack>
        </Stack>

      
        {dataList && dataList.length > 0 ? (
          <Grid container spacing={2}>
            {dataList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user: any) => (
                <Grid item xs={12} lg={3} sx={{position: "relative"}}>
                  <Paper
                    elevation={0}
                    sx={[styles.EventInfoCard]}
                  >
                       {_permission.tab_ManageLearningSessionEdit_Access && (
                   <Box sx={{position: "absolute", top: "25px" , right: "10px"}}>
                   <Tooltip title="Edit">
                      <IconButton
                        aria-label="edit"
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          setId(user?.id);
                          setDescription(user?.description);
                          setlink(user?.link);
                          setKeywords(user?.keywords);
                          setTitle(user?.title);
                          handleClickOpenSessionDialog();
                          setTempDocumentFileURL(user?.thumbnail)
                        }}
                      >
                        <Icons.Edit />
                      </IconButton>
                    </Tooltip>
                   </Box>)}
                    <Box sx={{ height: "150px" }}>

                      <img src={user?.thumbnail ?apiUrl.getImageURL() + "/" + user?.thumbnail:NoImage} alt="" style={{ height: "150px", objectFit: "cover", width: "100%" }} 
                      onClick={()=>{  user?.thumbnail && window.open(apiUrl.getImageURL() + "/" + user?.thumbnail, '_blank');
                      }}  />
                    </Box>

                    <Box sx={{ padding: "16px" }}>
                      <Box >
                        <Typography
                          variant="body1"
                          component="h4"
                          sx={[styles.EventName]}
                        >
                          {user?.title?.length > 30 ? (
                            <Tooltip title={user?.title} arrow>
                              <span>{user?.title.slice(0, 30 - 1) + "..."}</span>
                            </Tooltip>
                          ) : (
                            user?.title
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={[styles.EventDesc]}
                        >
                          {user?.description?.length > 30 ? (
                            <Tooltip title={user?.description} arrow>
                              <span>{user?.description.slice(0, 30 - 1) + "..."}</span>
                            </Tooltip>
                          ) : (
                            user?.description
                          )}
                        </Typography>
                        <Typography
                          component="h4"
                          sx={[styles.EventDesc]}
                        >
                          Keyword:   {user?.keywords ? (
                            user?.keywords.length > 30 ? (
                              <Tooltip title={user?.keywords} arrow>
                                <span>{user?.keywords.slice(0, 30 - 1) + "..."}</span>
                              </Tooltip>
                            ) : (
                              user?.keywords
                            )
                          ) : (
                            "--"
                          )}
                        </Typography>
                      </Box>

                      <Box mt="4" sx={{ display: "flex", alignItems: "center", justifyContent: user.link ? "space-between" : "flex-end" }}>
                        {user.link && (
                          <Typography sx={{ color: "#068DF2", cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => { window.open(user.link, '_blank'); }}>View Details</Typography>)}
                               {_permission.tab_ManageLearningSessionDelete_Access && (
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={(e) => deleteSessionData(user.id, e)}
                          >
                            <Icons.Delete />
                          </IconButton>
                        </Tooltip>)}
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        ) : (
          <NotFound NotfoundText="No Result Found" />
        )}
        <TablePagination
          labelRowsPerPage="Cards per page"
          rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() => `Total: ${dataList?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>

      <Dialog
        open={openSessionDialog}
        onClose={Button}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Formik
          initialValues={{
            title: title,
            link: link,
            thumbnail: "",
            description: description,
            keywords: keywords
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {

              if (id && id.length > 0) {
                // values.noticeId=noticeId
                await updateSessionDetailsById(values);
              } else {
                await addSession(values);
              }
            } catch (ex: any) {
              ex.data.Error.map((err: any) => {
                toast.warning(err);
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle
                  id="responsive-dialog-title"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    position: "relative",
                  }}
                >
                  {/* {id ? 'Update Label': 'Add Label'} */}
                  {pageTitle}
                  <IconButton
                    aria-label="delete"
                    sx={Leavepagestyle.styleCrossleavesetupleaves}
                    onClick={handleCloseSessionDialog}
                  >
                    <ClearIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Stack direction="column" spacing={3} sx={{ mb: 2, pt: 2 }}>
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                      required
                      error={Boolean(
                        getIn(touched, "title") &&
                        getIn(errors, "title")
                      )}
                      fullWidth
                      helperText={
                        getIn(touched, "title") &&
                        getIn(errors, "title")
                      }
                      name="title"
                      value={title}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.title = value;
                        setTitle(value);
                        // setErrorMessage(false);
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      multiline
                      required
                      variant="outlined"
                      label="Description"
                      value={description}
                      error={Boolean(
                        getIn(touched, "description") &&
                        getIn(errors, "description")
                      )}
                      helperText={
                        getIn(touched, "description") &&
                        getIn(errors, "description")
                      }
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value
                        value = value.replace(/\s{3,}$/g, "").trimStart().slice(0, 999);
                        values.description = value;
                        setDescription(value);
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          minHeight: "100px",
                          backgroundColor: "#fff",
                          lineHeight: "1.5",
                        },
                      }}
                    />
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "right" }}
                      >
                        Max 1000 words
                      </Typography>
                    <FileUploadPics
                      selectFile={triggerDocumentFile}
                    />
                    {tempDocumentFileURL && (
                      <Stack sx={{ width: 'auto', marginLeft: "16px" }} direction='row' alignItems="center" justifyContent="start">
                        <Box sx={{ width: '150px' }}>
                          <a className={classes.anchor} href={abc === "blob:http" ? tempDocumentFileURL : apiUrl.getImageURL() + "/" + tempDocumentFileURL} target="_blank" rel="noopener noreferrer">
                            <Box sx={{ maxWidth: '130px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img width={'100%'} height={'100%'} src={abc === "blob:http" ? tempDocumentFileURL : apiUrl.getImageURL() + "/" + tempDocumentFileURL} alt="Document" />
                            </Box>

                          </a>
                        </Box>
                        <Box sx={{ marginLeft: "42px" }}>
                          <IconButton onClick={() => cancelDocumentFile()}><ClearIcon /></IconButton>
                        </Box>
                      </Stack>
                    )}
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      label="Keywords"
                      variant="outlined"
                      error={Boolean(
                        getIn(touched, "keywords") &&
                        getIn(errors, "keywords")
                      )}
                      fullWidth
                      helperText={
                        getIn(touched, "keywords") &&
                        getIn(errors, "keywords")
                      }
                      name="keywords"
                      value={keywords}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.keywords = value;
                        setKeywords(value);
                        // setErrorMessage(false);
                      }}
                    />

                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      label="Link"
                      required
                      variant="outlined"
                      error={Boolean(
                        getIn(touched, "link") &&
                        getIn(errors, "link")
                      )}
                      fullWidth
                      helperText={
                        getIn(touched, "link") &&
                        getIn(errors, "link")
                      }
                      name="link"
                      value={link}
                      onBlur={(e) => {
                        let isvalidURL =  validURL(e.target.value);
                        if(isvalidURL){
                          handleBlur(e); 
                          setButtonDisable(false);
                        }else {
                          toast.warn("Please attach a valid URL.");
                          setButtonDisable(true);
                        }
                      }}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.link = value;
                        setlink(value);
                        // setErrorMessage(false);
                      }}
                    />
                  </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 3, pt: 0 }}>
                  <Button variant="outlined" onClick={() => {
                    handleCloseSessionDialog();
                    handleClearField();
                  }}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={deleteAlertOpen}
        onClose={setDeleteAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="delete"
          sx={Leavepagestyle.styleCrosssetupleaves}
          onClick={handleCloseAlert}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={Leavepagestyle.deletepopupheading}
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Are you sure you want to delete this
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseAlert}>
            No
          </Button>
          <Button variant="contained" onClick={deleteSession}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
