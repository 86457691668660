import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as Icons from "../../../assests/icons/icons";
import theme from "../../../theme/theme";
import moment from "moment";
import { Box, Button, IconButton, Modal, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import Userlisttop from "../../users/userliststyle";
import { toast } from "material-react-toastify";
import { ticketManagementService } from "../../../service/ticket-management-service";
import axios from "axios";

export default function WorkLog({ workLogList,getAllTicketWorkLog }: any) {
  const statusLog: any = (status: any) => {
    switch (status) {
      case 1:
        return {
          color: "review",
          status: "Start",
        };
      case 2:
        return {
          color: "hold",
          status: "Stop",
        };

      case 2:
        return {
          color: "review",
          status: "Pause",
        };
      case 4:
        return {
          color: "progress",
          status: "In-Progress",
        };
      case 5:
        return {
          color: "review",
          status: "Approved",
        };
      case 6:
        return {
          color: "approved",
          status: "Submitted",
        };
      case 7:
        return {
          color: "hold",
          status: "Rejected",
        };

      default:
        return {
          color: "approved",
          status: "Submitted",
        };
    }
  };

  console.log(workLogList,'workLogList');
  

  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [openModal, setModalOpen] = React.useState(false);
  const [hourDelete,setHourDelete]=useState({
    id:"",
    ticketId:"" 
  })

  console.log("auth user ===", _authUser )

  const calculateData = (timeTaken: any)=> {
    const hours = Math.floor(timeTaken);
    const minutes = Math.round((timeTaken - hours) * 60);
   const abc  = parseInt(timeTaken) > 1 ? "hrs" : "hr"
   if (minutes > 0) {
    return `${hours}${abc} ${minutes}mins`;
  } else {
    return `${hours}${abc}`;
  }
   };

   const handleModalClose = () => {
    setModalOpen(false);
    //setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    //setAnchorEl(null);
  };

   const handleDelete =() => {
    deleteOfflineHours();
   }
   
  




  const deleteOfflineHours = async () => {
    try {
      console.log(hourDelete,'dsadsadasdad')
     
      debugger
      await ticketManagementService
        .deleteOfflineHours(
           hourDelete.ticketId,
           hourDelete.id)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Offline Work Log Deleted Successfully");
            setModalOpen(false);
            getAllTicketWorkLog();
          }
        });
    } catch (ex: any) {
      ex?.data?.Error?.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  return (
    <>
      {workLogList?.map((iteam: any) => (
        <Paper variant="outlined" sx={{ p: 2, mb: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h6"
              sx={{ color: theme.palette.primary.dark, mb: 1 }}
            >
              {iteam?.createdBy}{" "}
            </Typography>
            <Chip
              label={statusLog(iteam?.status)?.status}
              variant={`${iteam?.status === 4
                ? "progress"
                : iteam?.status === 5
                  ? "approved"
                  : iteam?.status === 7 || iteam?.status === 2
                    ? "hold"
                    : "review"
                }`}
              color="default"
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="row" alignItems="center">
              {" "}
              <Icons.LoogedWork />
              <Typography sx={{ ml: 1 }}>
                Logged Work{" "}
                {`${moment(iteam?.workingDay).format("DDMMM, YYYY  ")}`}{" "}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Icons.SpentTime />{" "}
              <Typography sx={{ ml: 1 }}>
                {" "}
                Time Spent - {iteam?.consumedHours}{" "}
              </Typography>{" "}
            </Stack>
            <Stack direction="row" alignItems="center">
              {" "}
              <Icons.Online />{" "}
              <Typography sx={{ ml: 1 }}>
                {iteam?.taskType === 1 ? "Online" : "Offline"}{" "}
              </Typography>{" "}
            </Stack>
          {iteam.status !== 5 && (
            <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                           
                             onClick={() =>{
                               handleModalOpen()
                               setHourDelete({
                                 id:iteam.id,
                                 ticketId:iteam.ticketId
                                })
                              }
                             }
                            disabled={iteam?.status !== 6 ||  _authUser?.id !== iteam?.userId}
                          >
                            <Icons.Delete />
                          </IconButton>
                        </Tooltip>
                        )}
          </Stack>
          <Typography component='p' sx={{
            fontWeight: '400',
            fontSize: '  14px',
            lineHeight: '18px',
            /* identical to box height */
            /* Primary Color/Primary_dark shade */
            color: '#232360',
            mt: 1
          }}>
            Description
          </Typography>
          <Typography component='div' sx={{
            display: 'inline', fontSize: '12px',
            lineHeight: '18px',

            /* Grayscale-Shades/Grey-300 */

            color: " #616161",
          }}
            dangerouslySetInnerHTML={{
              __html: iteam?.workDiscription,
            }}>

          </Typography>{" "}
        </Paper>
      ))
      }

<Modal
          open={openModal}

          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleModalClose();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Userlisttop.actionpopup}>
            <Paper variant="outlined" sx={{ p: 4 }}>
              <Typography
                id="modal-modal-title"
                align="center"
                variant="h6"
                component="h2"
                sx={{ fontWeight: 700 }}
              >
                Delete Offline Work Log
              </Typography>
              <Typography
                id="modal-modal-description"
                align="center"
                sx={{ mt: 2 }}
              >
                Are you sure, You sure you want to delete it. Continue?
              </Typography>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <Button variant="outlined" onClick={handleModalClose} >
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleDelete}>
                  Confirm
                </Button>
              </Stack>
            </Paper>
          </Box>
        </Modal>
    </>
  );
}
