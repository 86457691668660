import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,

} from "react-router-dom";
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from 'redux';
import MenuApp from './store/menu';
import AuthUserApp, { FillUser } from './store/authUser';
import BrowniePermissionApp from './store/permissions/brownie-permission';
import EscalationPermissionApp from './store/permissions/escalation-permission';
import LeavePermissionApp from './store/permissions/leave-permission';
import MasterPermissionApp from './store/permissions/master-permission';
import NotificationPermissionApp from './store/permissions/notification-permission';
import ProjectPermissionApp from './store/permissions/project-permission';
import ReportPermissionApp from './store/permissions/report-permission';
import RiskAlarmPermissionApp from './store/permissions/riskAlarm-permission';
import UserPermissionApp from './store/permissions/user-permission';
import InventoryPermissionApp from './store/permissions/inventory-permission';

import { combineReducers } from "redux";
import SeasiagalaPermission from './store/permissions/seasiagala-permission';
import SeasiagalaPermissionApp from './store/permissions/seasiagala-permission';
import PrismPermissionApp from './store/permissions/prism-permission';
import MeetingsPermissionApp from './store/permissions/meetings-permission';
import ProjectPortfolioApp from './store/permissions/projectPortfoli-permission';
import LearningSessionApp from './store/permissions/LearningSession-permission';
import DomainPortfolioApp from './store/permissions/domainPortfolio-permission';
const rootReducer = combineReducers({
  MenuApp,
  AuthUserApp,
  BrowniePermissionApp,EscalationPermissionApp,LeavePermissionApp,
  MasterPermissionApp,NotificationPermissionApp,ProjectPermissionApp,
  ReportPermissionApp,RiskAlarmPermissionApp,UserPermissionApp,
  InventoryPermissionApp,SeasiagalaPermissionApp,PrismPermissionApp,
  MeetingsPermissionApp,ProjectPortfolioApp,LearningSessionApp,DomainPortfolioApp
});
const store = createStore(rootReducer);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
); 

root.render(
  <>
     <BrowserRouter basename="">
     <Provider store={store}><App /></Provider>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


