import { Class } from "@mui/icons-material";
import { Observable } from "@reduxjs/toolkit";
import UserManagement from "../pages/users/UserManagement";
import { userModel } from "../pages/users/userModel";
import { http } from "./http-common";
class SettingManagementServive{

    addSettings = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Project/add/update", data);
    };

    getProjectDetailsById = async (id: any): Promise<any> => {
      return await http.get<any>(`/v1/Project/GetAllDetailsById?id=${id}`);
    };
    // /api/v1/Project/GetProjectDetails
       getAllProject = async (actionType: any): Promise<any> => {
        var req = {
          skip: 0,
          take: 10,
          status: actionType,
        };
        return await http.post<any>("/v1/Project/GetProjectDetails", req);
      };
      getAllProjectSecure = async (actionType: any): Promise<any> => {
        var req = {
          module: 13,
          status: "active"
        };
        return await http.post<any>("/v1/Master/DropdownData/GetByName", req);
      };
      getAllProjectSecureOnRoleBase = async (): Promise<any> => {
        var req = {
          module: 14,
          status: "active"
        };
        return await http.post<any>("/v1/Master/DropdownData/GetByName", req);
      };

      GetProjectBoardWorkById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetProjectBoardWorkById?id=${id}`);
      };

      getPProjectDetailsById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetAllDetailsById?id=${id}`);
      };

}

export const settingManagementServive = new SettingManagementServive()