import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Autocomplete, CircularProgress, MenuItem, TableCell, TableSortLabel, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import { useNavigate, Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import SearchBar from "../../components/styledComponent/SearchBar";
import Modal from "@mui/material/Modal";
import { projectManagementServive } from "../../service/project-management-service";
import { toast } from "material-react-toastify";
import { visuallyHidden } from "@mui/utils";
import NotFound from "../../components/styledComponent/NotFound";
import CreateProject from "../users/CreateProject";
import { useSelector } from "react-redux";
import { clsDepartmentList } from "../../service/common-services/department";
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import Projecttop from "./Projectstyle";
import InfoPopover from "../../../src/components/CommonInfoComponent";

interface PointOfContacts {
  email: string;
  skypeId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  code: string;
  alterNateNumber: string;
}

interface Department {
  id: string;
  name: string;
}

enum eProjectStatus {
  "All Status" = "0",
  "New" = "1",
  "In Progress" = "2",
  "Completed " = "3",
  "On Hold " = "4",
}


enum eProjectType {
  "All Projects" = "0",
  "In House" = "1",
  "Client" = "2"
}

interface Project {
  id: number;
  projectDescription: string;
  projectName: string;
  status: number;
  clientName: string;
  projectOwnerName: string;
  departmentName: string;
  costingType: string;
  pin: string;
  keyWords: string;
}

///////page change and dropdown programming

interface Data {
  projectDescription: string;
  projectName: string;
  projectOwnerName: string;
  keyWords: string;
  clientName: string;
  departmentName: string;
  costingType: string;
  status: string;
  id: string;
  pin: string;
  createdDate: string;
  projectKey: string;
  projectOwnerId: string;
  srNo: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let first: any = a[orderBy],
    second: any = b[orderBy];
  if (!isNaN(Number(first))) {
    first = Number(first);
  } else if (first != undefined) {
    first = first.toLowerCase();
  }
  if (!isNaN(Number(second))) {
    second = Number(second);
  } else if (second != undefined) {
    second = second.toLowerCase();
  }

  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}
type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string | string },
  b: { [key in Key]: string | string }
) => number {
  if (orderBy === 'pin') {
    return order === 'desc'
      ? (a, b) => descendingAlphanumericComparator(a[orderBy], b[orderBy])
      : (a, b) => alphanumericComparator(a[orderBy], b[orderBy]);
  } else {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function alphanumericComparator(a: string, b: string): number {

  const aParts = a.match(/([a-zA-Z]*)(\d*)/) || [];
  const bParts = b.match(/([a-zA-Z]*)(\d*)/) || [];

  const aAlpha = aParts[1];
  const bAlpha = bParts[1];
  const aNum = parseInt(aParts[2], 10);
  const bNum = parseInt(bParts[2], 10);

  if (aAlpha !== bAlpha) {
    return aAlpha.localeCompare(bAlpha);
  }

  return aNum - bNum;
}

function descendingAlphanumericComparator(a: string, b: string): number {
  return alphanumericComparator(b, a);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: "pin",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Pin",
  // },
  {
    id: "projectName",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "projectOwnerName",
    numeric: false,
    disablePadding: false,
    label: "Project Owner",
  },
  {
    id: "keyWords",
    numeric: false,
    disablePadding: false,
    label: "Keyword",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
  {
    id: "departmentName",
    numeric: false,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "costingType",
    numeric: false,
    disablePadding: false,
    label: "Costing Type",
  },
  // {
  //   id: "projectDescription",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Action",
  // },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                ...(headCell.id === "projectName" && {
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  background: "#fff"

                }),
                ...(headCell.id === "projectOwnerName" && {
                  position: "sticky",
                  left: '160px',
                  zIndex: 1,
                  background: "#fff"

                }),
              }}

            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {/* <TableCell
        >
          Status
        </TableCell> */}
          {(_permission.action_ManageProjectsListing_Edit_Access == true ||
            _permission.action_ManageProjectsListing_Delete_Access == true) && <TableCell align-items="center" align="center">Action</TableCell>}

        </TableRow>
      </TableHead>
    </>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return <></>;
};

export default function ProjectList() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const eprojectStatus = Object.keys(eProjectStatus);
  const eProjectTypeStatus = Object.keys(eProjectType);
  const [statusFilter, setStatusFilter] = React.useState("In Progress");
  const [projectstatusHook, setProjectstatusHook] = React.useState("2");
  const [projectTypeFilter, setProjectTypeFilter] = React.useState("All Projects");
  const [projectTypeValueHook, setProjectTypeValueHook] = React.useState(0);

  const [disabledDepartments, setdisabledDepartments] = useState(false);
  const [users, setUsers] = useState<Data[]>([]);
  const [actionAllowed, setAllowdAction] = React.useState<any>([]);
  var departmentData = { id: "", name: "All Departments" };
  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentsId, setdepartmentsId] = useState("");

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(users.length / rowsPerPage);
  const [currentLoggedInRole, setLoggedInRole] = useState("");
  const [noProject, setNoProject] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [filterselected, setfiltersSelected] = useState(false);

  const handleChangeStatusFilter = (value: any, idx: number) => {
    setfiltersSelected(true)
    setStatusFilter(value);
    let statusproj = idx.toString();
    setProjectstatusHook(statusproj);
    getProjDetails(departmentsId, searchValue, statusproj, projectTypeValueHook);
  };
  const handleChangeProjectType = (value: any, idx: any) => {
    setProjectTypeFilter(value);
    setProjectTypeValueHook(idx)
    setfiltersSelected(true)
    getProjDetails(departmentsId, searchValue, projectstatusHook, idx);
  };


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  ///////page change and dropdown programming over

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (_permission.tab_ManageProjectsListing_Access !== true) {
      navigation('/dashboard');
    }

    //getDepartments();


    const departmentsData = async () => {
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        if (_permission.action_ManageProjectsListing_AllDepartments_Access == false) {
          setdisabledDepartments(true);
        }
        await setdepartmentsId(_authUser.employeeInfo?.departmentId);
        await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        getProjDetails(_authUser.employeeInfo?.departmentId, "", "2", 0);
      }
      else {
        await setdepartmentsId("");
        await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        getProjDetails("", "", "2", 0);
      }
    }
    departmentsData();
    setLoggedInRole(_authUser.typeOfUser);
    setAllowdAction({
      add: true,
      delete: true,
      edit: true,
      view: true,
    });
  }, []);

  const getProjDetails = async (departmentId: any, search: any, projectStatus: any, projectTypeStatus: any) => {
    setToggleLoader(true);
    let values = {
      department: departmentId ?? "",
      status: projectStatus,
      search: search ?? "",
      take: 1000,
      skip: 0,
      isManageProject: true,
      projectType: projectTypeStatus,
    };

    await projectManagementServive
      .getAllProject(values)
      .then(async (response: any) => {
        if (response.data.type === "application/json") {
          const data = await response.data.text();
          toast.warning(JSON.parse(data).listErrors[0]);
        } else {
          setUsers(response?.data);
          if (response.data.length == 0) {
            if (searchValue.length == 0) {
              setNoProject("true");
              //setNoProject("false");
            } else {
              setNoProject("true");
            }
            setToggleLoader(false);
          } else {
            setUsers(response?.data);
            setNoProject("true");
            setToggleLoader(false);
          }
        }
      });
  };

  const triggerSearch1 = async (obj: any, search: any) => {
    setSearchValue(search);
    setfiltersSelected(true);
    getProjDetails(obj?.departmentId, search, projectstatusHook, projectTypeValueHook);
    setPage(0);
  };

  // ////////search programming
  // const getSearchDetails = async (newValue: string) => {
  //   console.log("newValue", newValue)
  //   let values = {
  //     department: projectHook.id,
  //     status: statusDataHook,
  //     search: newValue,
  //     take: 50,
  //     skip: 0
  //   }
  //   await projectManagementServive.getSearch(values)
  //     .then((response: any) => {
  //       console.log("UserData- Response: ", response);
  //       if (response.data.isError) {

  //       }
  //       else {
  //         setUsers(response?.data);
  //         if (response.data.length == 0) {

  //         }

  //       }
  //     });
  // }

  const deleteProjData = async (id: any) => {
    setOpenn(true);
    setCurrentId(id);
  };

  const [openn, setOpenn] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);
  const [searchBarKey, setSearchBarKey] = useState(0);

  const DeleteProj = async () => {
    setOpenn(false);
    try {
      await projectManagementServive
        .deleteProject(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Project Deleted Successfully");

            getProjDetails(departmentsId, searchValue, projectstatusHook, projectTypeValueHook);
            setSearchBarKey(prevKey => prevKey + 1);
          }
        });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  const [aanchorEl, statussetAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [selectedstatusIndex, setStatusSelectedIndex] = React.useState(1);
  const stopen = Boolean(aanchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    statussetAnchorEl(event.currentTarget);
  };

  /////search programming
  var headSearch = { id: "", name: "All Projects" };
  const [searchValue, setSearchValue] = useState("");

  const [openModal, setModalOpen] = React.useState(false);



  return (
    <>
      {toggleLoader && (<CircularProgress sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
      {noProject == "false" && <CreateProject></CreateProject>}
      {/* <Paper elevation={0} sx={{ p: 3, pb: 0 }}> */}
      {noProject == "true" && (
        <>

          {/* <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
            <Link color="inherit" to="/">
              Dashboard
            </Link>
            <Typography color="text.primary">Projects</Typography>
            <Typography color="text.primary">Manage Project</Typography>
          </Breadcrumbs> */}
          <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
            {/* <Notes NoteText="Admin will create new projects and manage already created projects on the system. Admin will be able to create, view, edit projects and will manage milestones statuses. " /> */}
            <Stack
              sx={Projecttop.topbar}
            >
              <Stack sx={Projecttop.topright}>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 600, display: "flex", alignItems: "center", gap: "12px" }}>
                  Project List <div >
                    <InfoPopover onInput={"Project List"} />
                  </div>
                </Typography>


                {_permission.action_ManageProjectsListing_Add_Access && (
                  <Button
                    variant="contained"
                    component={Link}
                    to="/project/add-project"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{ width: "fit-content" }}
                  >
                    Add Project
                  </Button>
                )}
              </Stack>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "12px" }}>
                <TextField
                  id="Statusfilter"
                  variant="outlined"
                  value={projectTypeFilter}
                  select
                  sx={{ width: '50%', marginRight: '12px', marginBottom: '1%' }}
                >
                  {eProjectTypeStatus.map((key, idx) => (
                    <MenuItem
                      key={key}
                      value={key}
                      onClick={(event) => {
                        handleChangeProjectType(key, idx);
                      }}
                    >
                      {key}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  disabled={disabledDepartments}
                  value={departments?.find((option) => option.id == departmentsId) ?? null}
                  sx={{ width: '70%', marginBottom: '1%' }}
                  id="outlined-basic"
                  options={departments}
                  getOptionLabel={(option) => option.name}
                  onChange={async (event, value) => {
                    setfiltersSelected(true);
                    setdepartmentsId(value?.id ?? "");
                    await getProjDetails(value?.id, searchValue, projectstatusHook, projectTypeValueHook);
                    setPage(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="element.name"
                      variant="outlined"
                      value={departmentsId}
                      sx={{ width: '100%', minWidth: 50 }}
                    />
                  )}
                />

                <TextField
                  id="Statusfilter"
                  variant="outlined"
                  value={statusFilter}
                  select
                  sx={{ width: '50%', marginBottom: '1%', }}
                >
                  {eprojectStatus.map((key, idx) => (
                    <MenuItem
                      key={key}
                      value={key}
                      onClick={(event) => {
                        setfiltersSelected(true);
                        handleChangeStatusFilter(key, idx);
                      }}
                    >
                      {key}
                    </MenuItem>
                  ))}
                </TextField>
                <Stack  >
                  {filterselected && (
                    <Button
                      variant="contained"
                      color="warning" sx={{ marginBottom: '12%', }} onClick={async () => {
                        if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                          if (_permission.action_ManageProjectsListing_AllDepartments_Access == false) {
                            setdisabledDepartments(true);
                          }
                          await setdepartmentsId(_authUser.employeeInfo?.departmentId);
                          await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                          getProjDetails(_authUser.employeeInfo?.departmentId, "", "2", 0);
                        }
                        else {
                          await setdepartmentsId("");
                          await setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                          getProjDetails("", "", "2", 0);
                        }
                        setfiltersSelected(false);
                        setStatusFilter("In Progress");
                        setProjectTypeFilter("All Projects");
                        setProjectstatusHook("2");
                        setProjectTypeValueHook(0);
                        setSearchValue("");
                      }}>Clear</Button>)}


                </Stack>
                <Stack>
                  <Box sx={{ marginBottom: '5%', }}>
                    <SearchBar
                      key={searchBarKey}
                      value={searchValue}
                      // setSearchValue={setSearchValue} // Commented out as it seems not needed
                      obj={{ departmentId: departmentsId }}
                      callBackFunction={triggerSearch1}
                    />
                  </Box>
                </Stack>
              </Box>

            </Stack>

            <Divider />

            <TableContainer sx={{
              mt: 1,
              [theme.breakpoints.down('sm')]: {
                maxWidth: '320px'
              },
            }}>
              {_permission.tab_ManageProjectsListing_Access && (
                <Table aria-label="simple table" size="small">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={users.length}
                  />

                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(users, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.projectName);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick1(event, row.projectName)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}

                          >
                            {/* <StyledTableCell align="left">
                              {row.pin}
                            </StyledTableCell> */}
                            <StyledTableCell align="left" sx={{ position: "sticky", left: "0", background: "#fff" }}>
                              {row.projectName}
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{ position: "sticky", left: "160px", background: "#fff" }}>
                              {row.projectOwnerName}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.keyWords}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.clientName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.departmentName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.costingType}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left">
                            {row.projectDescription}
                          </StyledTableCell> */}

                            {/* <StyledTableCell align="left">
                      {(() => {
                        
                        switch (row.status.toString()) {
                          case "1":
                            return  <StatusChip label={<span><Icons.CircleFilled /> Active</span>}/>;

                          case "0":
                            return  <StatusChip label={<span><Icons.CircleFilled /> In Active</span>}/>;
                          default:
                            return row.status
                        }
                      })()}
                       
                           
                        </StyledTableCell> */}
                            {(_permission.action_ManageProjectsListing_Edit_Access == true ||
                              _permission.action_ManageProjectsListing_Delete_Access == true) &&
                              <StyledTableCell align="left" width={100}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                  spacing={2}
                                >
                                  {/* {actionAllowed.edit && ( */}
                                  {_permission.action_ManageProjectsListing_Edit_Access && projectstatusHook != "3"  && (
                                    <Tooltip title="Edit">
                                      <IconButton
                                        aria-label="edit"
                                        component={Link}
                                        to={`/project/edit-project/${row.id}`}
                                        target="blank"
                                        size="medium"
                                      >
                                        <Icons.Edit />
                                      </IconButton></Tooltip>
                                  )}
                                  {/* )} */}
                                  {/* {(actionAllowed.view &&
                                                                        <IconButton aria-label="view" component={Link} to={`/project/edit-project/${row.id}`}>
                                                                            <Icons.View />
                                                                        </IconButton>
                                                                    )} */}
                                  {/* {actionAllowed.delete && ( */}
                                  {_permission.action_ManageProjectsListing_Delete_Access && (
                                    <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => deleteProjData(row.id)}
                                      >
                                        <Icons.Delete />
                                      </IconButton></Tooltip>
                                  )}
                                  {/* )}  */}
                                </Stack>
                              </StyledTableCell>}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={9} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
              {users.length == 0 && (
                <NotFound NotfoundText="No Result Found" />
                // <CreateProjectMui />
              )}

            </TableContainer>

            <Stack sx={{ pt: 3, pb: 3 }}>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={!users.length || users.length <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}

                labelDisplayedRows={() => `Total: ${users?.length}  Page ${page + 1} of ${pageCount}`}
              />
            </Stack>
          </Paper>
        </>
      )}
      {/* </Paper> */}
      <Modal
        open={openn}
        onClose={handleClosee}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Projecttop.actionpopup}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Role
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              If the Project is assigned to any user, it cannot be deleted. If
              the Project is not assigned to any user, it will be deleted.
              Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleClosee}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteProj}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}
