
const Leavepagestyle = {
    topbar: {
        flexDirection: { xs: 'column', sm: "row" },
        justifyContent: "space-between",
        alignItems: { xs: 'strech', sm: 'center' },
        marginY: "20px",
    },
    requesttopbar: {
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: '100%',
    },
    emergencybtn: {
        marginLeft: "auto",
        mt: { xs: 2, sm: 0 },
        width: { xs: '100%', sm: 'auto' },
    },
    toptabs: {
        borderBottom: 1,
        borderColor: "divider",
        mb: 2,
        Width: '100%',
        bgcolor: 'background.paper'
    },
    balanceleave: {
        flexDirection: { xs: 'row', sm: 'column' },
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    styleCross: {
        position: "absolute" as "absolute",
        right: "12px",
        top: "16px",
    },
    styleCrosssetupleaves: {
        position: "absolute" as "absolute",
        right: "5px",
        top: "5px",
    },

    styleCrossleavesetupleaves: {
        position: "absolute" as "absolute",
        right: "24px",
        top: "16px",
    },

    deletepopupheading:{
        textAlign: "center",
        fontWeight: 700,
        fontSize: "26px",
        lineHeight: "39px",
        color: "#212121",
      }
}
export default Leavepagestyle;