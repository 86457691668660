import { http } from "../http-common";
class MilestoneTreeService {
  getprojectdetails = async (id: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetAllProjects",id);
  };
  getMilestoneonbasisofProjIds = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GeProjectMilestonesById?ProjectId=${id}`);
  };
  getSprintonbasisofMilestoneId = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetSprintsByMilestoneId?MilestoneId=${id}`);
  };
  getIssueBasedonMilestoneId = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Reports/MilestoneDefectDensityReport?milestoneId=${id}`);
  };
  geLeakageReport = async (id: any): Promise<any> => {
    return await http.post<any>("/v1/Reports/MilestoneDefectLeakageReport",id);
  };
  getScheduleVarianceReport = async (id: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetScheduleVarianceReport",id);
  };
  getEffortVarianceReport = async (id: any): Promise<any> => {
    return await http.post<any>("/v1/Reports/GetAllMilestonesEffortVarianceReport",id);
  };

}

export const milestoneTreeService = new MilestoneTreeService();
