import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ReportImage from "../../assests/images/report-management.svg";
import * as Icons from "../../assests/icons/icons";
import { Accordion, AccordionDetails, AccordionSummaryProps, CardHeader, CardHeaderProps, CircularProgress, Divider, TablePagination } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Chip from "@mui/material/Chip";
import theme from "../../theme/theme";
import { useEffect } from 'react';
import { reportmanagementCTO } from '../../service/reportManagementCto';
import { projectManagementServive } from '../../service/project-management-service';
import { toast } from 'material-react-toastify';
import NotFound from '../../components/styledComponent/NotFound';
import moment from 'moment';
import StyledTableCell from '../../components/styledComponent/CustomTableCell';
import { styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchBar from '../../components/styledComponent/SearchBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import { TreeItem, TreeView } from '@mui/x-tree-view';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const toggleloaders = {
  position: "fixed",
  left: "50%",
  right: "50%",
  top: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)"
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#1DA7FF" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const CustomCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: "0px",
  paddingLeft: "0",
  alignItems: "center",
  // width: 300,
  // color: theme.palette.success.main,
  "& .MuiCardHeader-title": {
    fontSize: "0.875rem",
    fontWeight: "300",
    marginBottom: "4px",
    color: theme.palette.grey[800],
  },

  "& .MuiCardHeader-subheader": {
    fontSize: "0.875rem",
    fontWeight: "500",
    "word-break": "break-all",
    color: theme.palette.grey[900],
    "& span": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "8px",
      },
    },
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pl: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ReportManagementCTO() {
  const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [EmployeBlock, setEmployeBlock] = React.useState(false)
  const [EmployeeResult, setEmpShowResults] = React.useState(false)
  const onClick = () => {
    setEmpShowResults(true);
    setEmployeBlock(true)
  }

  const onClickResultFalse = () => setEmpShowResults(false);

  const [ByProjectRsult, setByProjectRsult] = React.useState(false)
  const onClickShowProject = () => {
    setByProjectRsult(true);
    setEmployeBlock(false)
  };
  const onClickHideProject = () => setByProjectRsult(false);



  useEffect(() => {
    if (_permission.tab_OrgChart_OrgChartListing_Access !== true) {
      navigate("/dashboard");
    }
    getAllHodDetails();
  }, []);
  const [allMgrList, setAllMgrList] = React.useState<any>([]);
  const [allMgrListSearch, setAllMgrListSearch] = React.useState<any>([]);
  const [allResourceList, setAllResourceList] = React.useState<any>([]);
  const [allProjectList, setAllProjectList] = React.useState<any>([]);
  const [allResourceListSearch, setAllResourceListSearch] = React.useState<any>([]);
  const [allProjectListSearch, setAllProjectListSearch] = React.useState<any>([]);
  const [allProjectListAdmin, setAllProjectListAdmin] = React.useState<any>([]);
  const [allProjectListAdminSearch, setAllProjectListAdminSearch] = React.useState<any>([]);
  const [allProjectListById, setAllProjectListById] = React.useState<any>(null);
  const [toggleLoader, setToggleLoader] = React.useState(false);
  const [departmentIdForReport, setDepartmentIdForReport] = React.useState<any>("");
  const [resourcetIdForReport, setResourceForReport] = React.useState<any>("");
  const [resourceReportList, setResourceReportList] = React.useState<any>([]);
  const [resourceName, setResourceName] = React.useState<any>("");
  const [resourceName1, setResourceName1] = React.useState<any>("");
  const [fromDate, setfromDate] = React.useState<any>(null);
  const [toDate, settoDate] = React.useState<any>(null);
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [clientProject, setClientProject] = React.useState<any>([]);
  const [selectedNodeId, setSelectedNodeId] = React.useState("");
  const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(allMgrList.filter((d: any) => !!d.name).length / rowsPerPage); 
 

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageeCount = Math.ceil(allProjectListAdmin.length / rowsPerPage1);
  

  const handleChangePage1 = (event: unknown, newPage: number) => {
    debugger
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [expandedTree, setExpandedTree] = React.useState<string[]>([]);
  //console.log(expandedTree, "expandedTree")

  const acordianChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      // setNoVisible(true)
    };

  const triggerSearch = async (newValue: string) => {
    let abc = allProjectListAdminSearch.filter((abc: any) => (newValue == "" || abc.projectName.toLowerCase().includes(newValue.toLowerCase())));
    setAllProjectListAdmin(abc);
    setPage(0);
  };
  const triggerSearch1 = async (newValue: string) => {
    
    if (newValue == "") {
      setAllMgrList(allMgrListSearch);
      setAllResourceList(allResourceListSearch);
      setAllProjectList(allProjectListSearch);
    }
    else {
      if (selectedNodeId.includes('-') && expandedTree.includes(selectedNodeId)) {
        const nodeType = selectedNodeId.split('-').at(-1);
        if (nodeType == '2') {
          let abc = allResourceListSearch.filter((abc: any) => (newValue == "" || abc.userName.toLowerCase().includes(newValue.toLowerCase())));
          setAllResourceList(abc);
        } else if (nodeType == '3') {
          let abc = allProjectListSearch.filter((abc: any) => (newValue == "" || abc.name.toLowerCase().includes(newValue.toLowerCase())));
          setAllProjectList(abc);
        }
      } else {
        let abc = allMgrListSearch.filter((abc: any) => (newValue == "" || abc.name.toLowerCase().includes(newValue.toLowerCase())));
        setAllMgrList(abc);
        setPage(0);
      }
    }

  };

  const getAllHodDetails = async () => {
    let abc = {
      module: 7,
      status: "",
      search: "",
    }
    await reportmanagementCTO.getAllHod(abc).then((response: any) => {
      if (response.data.isError) {
        
      } else {
        const filteredData = response?.data.filter((name: any) => {
          return name.name !== null && name.name.trim() !== "";
        });
        const sortedProjects = filteredData.sort((a: any, b: any) => {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
            return -1;
          }
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setAllMgrList(sortedProjects);
        setAllMgrListSearch(sortedProjects);
        console.log(sortedProjects, "MGR List");
      }
    });
    
  };

  const getResourcesbyDepartmentId = async (id: any) => {
    let abc = {
      departmentId: id,
      IsHodExclude: true,
    }
    await reportmanagementCTO.getResourcesByDepartment(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        const sortedProjects = response?.data.sort((a: any, b: any) => {
          if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
            return -1;
          }
          if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setAllResourceList(sortedProjects);
        setAllResourceListSearch(sortedProjects);
        console.log(sortedProjects, "Sorted projects --")
      }
    });
  };

  const getProjectsByHodId = async (id: any) => {
    let abc = {
      module: 13,
      status: "active",
      userId: id
    }
    await reportmanagementCTO.getProjectsByHodId(abc).then((response: any) => {
      if (response.data.isError) {
      } else {
        setAllProjectList(response?.data);
        setAllProjectListSearch(response?.data);
        console.log(response?.data , "Project List");
      }
    });
  };
  const getProjectsForAdmin = async () => {
    setAllProjectListAdmin([]);
    setToggleLoader(true);
    let abc = {
      department: "",
      status: 0,
      search: "",
      take: 1000,
      skip: 0,
      isManageProject: true
    }
    await reportmanagementCTO.getProjectsForAdmin(abc).then((response: any) => {
      if (response.data.isError) {
        setToggleLoader(false);
      } else {
        const sortedProjects = response?.data.sort((a: any, b: any) => {
          if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
            return -1;
          }
          if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setAllProjectListAdmin(sortedProjects);
        setAllProjectListAdminSearch(sortedProjects);
        setToggleLoader(false);
      }
    });
  };
  const getProjectsDetailByProjectIdAdmin = async (id: any) => {
    await reportmanagementCTO.getProjectsDetailByProjectIdAdmin(id).then(async (response: any) => {
      if (response.data.isError) {
      } else {
        setAllProjectListById(response?.data);
        let abc = response?.data?.colAddClientModel?.clientUserId;
        await reportmanagementCTO.getProjectByClientId(abc).then((response: any) => {
          if (response.data.isError) {
          }
          else { setClientProject(response?.data) }
        })
      }
    });
  };

  const getUnEncrypedFiles = async (fileName: any) => {
    setToggleLoader(true);
    try {
      await projectManagementServive
        .getUnEncrypetDocuments(fileName)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
            setToggleLoader(false);
          } else {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            a.href = URL.createObjectURL(response.data);
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(a.href);
            setToggleLoader(false);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }

  }

  const getProjectsReportForResource = async (departmentId: string = "", resourceId: string = "",
    startDate: any = "", endDate: any = "") => {
    let abc = {
      departmentId: departmentId,
      projectId: "",
      resourceId: resourceId,
      status: "",
      type: "",
      startDate: startDate ? dayjs(startDate).format("DD-MMM-YYYY") : "",
      endDate: endDate ? dayjs(endDate).format("DD-MMM-YYYY") : "",
      take:2000,
      skip:0,
    }
    await reportmanagementCTO.getReport(abc).then((response: any) => {
      if (response.data.isError) {
      } else {
        setResourceReportList(response?.data);
        setResourceName(response?.data[0]?.listRecords[0]);

      }
    });
  };
  return (
    <Paper sx={{ p: 3, pb: 0 }}>

      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2, border: '1px solid', borderColor: '#bdbdbd', p: 3, borderRadius: '10px' }}>
        <Avatar sx={{ width: 52, height: 52 }}>H</Avatar>
        <Stack spacing={0}>
          <Typography variant="subtitle1" component="h5"  >
            Role
          </Typography>
          <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 600 }}>
            Ganesh Verma
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="By People" {...a11yProps(0)} onClick={() => {
              setAllProjectListById(null); setResourceForReport(""); setDepartmentIdForReport("");
              setAllMgrList(allMgrListSearch);
              setAllResourceList(allResourceListSearch);
              setAllProjectList(allProjectListSearch);
              setExpandedTree([]);
            }} />
            <Tab label="By Project" {...a11yProps(1)} onClick={() => { setAllProjectListById(null); getProjectsForAdmin(); setResourceForReport(""); setDepartmentIdForReport(""); setResourceReportList(""); }} />
          </Tabs>
          {value == 0 && (
            <Box sx={{ position: "absolute", right: '0', top: '0' }} >
              <SearchBar
                // setSearchValue={setSearchValue}
                callBackFunction={triggerSearch1}
              /></Box>)}
          {value == 1 && (
            <Box sx={{ position: "absolute", right: '0', top: '0' }} >
              <SearchBar
                // setSearchValue={setSearchValue}
                callBackFunction={triggerSearch}
              /></Box>)}
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container>
            <Grid item sm={2} pr={4}>

              <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={(a: any, b: any) => {
                  setSelectedNodeId(b);

                }}
                expanded={expandedTree}
                sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
              >
                {allMgrList.filter((d: any) => !!d.name).slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1).map((item: any, idx: any) => (
                  <TreeItem nodeId={item.id} label={item?.name} sx={{
                    "&.MuiTreeItem-root": {
                      "& .MuiTreeItem-content": {
                        borderBottom: "1px solid #eee", marginBottom: '10px'
                      }
                    }
                  }}
                    onClick={(a: any) => {
                      getResourcesbyDepartmentId(item?.departmentId);
                      setDepartmentIdForReport(item?.departmentId);
                      getProjectsByHodId(item?.id);
                      item.expanded = !item.expanded;
                      item.expanded1 = false;
                      let existing = [...expandedTree];
                      if (item.expanded) {
                        existing = [item.id];
                        allMgrList.forEach((element: any) => {
                          if (element.id != item?.id)
                            element.expanded = false;
                        });
                        item.expanded1 = false;
                        item.expanded2 = false;
                      } else {
                        existing.splice(existing.indexOf(item.id), 1);
                        existing.splice(existing.indexOf(item?.id + "-2"), 1);
                        existing.splice(existing.indexOf(item?.id + "-3"), 1);
                      }
                      //console.log("First", existing)
                      setExpandedTree(existing);
                    }}
                  >
                    <TreeItem nodeId={item?.id + "-2"} label="By Resource" onClick={() => {
                      onClickResultFalse();
                      item.expanded1 = !item.expanded1;
                      let existing = [...expandedTree];
                      if (item.expanded1) {
                        existing.push(item?.id + "-2")
                      } else {
                        existing.splice(existing.indexOf(item?.id + "-2"), 1);
                      }
                      setExpandedTree(existing);
                    }}>
                      {allResourceList.map((resrc: any, idx: any) => (
                        <TreeItem sx={{ml:"-17px"}} nodeId = {`resc-${resrc.userId}`}  label={resrc?.userName} onClick={() => {
                          onClick();
                          getProjectsReportForResource(departmentIdForReport, resrc?.userId);
                          setResourceForReport(resrc?.userId);
                          setResourceName1(resrc?.userName);

                        }} />
                      ))}
                    </TreeItem>
                    <TreeItem nodeId={item?.id + "-3"} label="By Project" onClick={() => {
                      onClickHideProject();
                      item.expanded2 = !item.expanded2;
                      let existing = [...expandedTree];
                      if (item.expanded2) {
                        existing.push(item?.id + "-3")
                      } else {
                        existing.splice(existing.indexOf(item?.id + "-3"), 1);
                      }
                      setExpandedTree(existing);
                    }
                    }>
                      {allProjectList.map((proj: any, idx: any) => (
                        <TreeItem sx={{ml:"-17px"}} nodeId={`project-${proj.id}`}  label={proj?.name} onClick={() => { onClickShowProject(); getProjectsDetailByProjectIdAdmin(proj?.id) }}>
                        </TreeItem>
                      ))}
                    </TreeItem>
                  </TreeItem>))}
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={allMgrList?.length}
                  rowsPerPage={rowsPerPage1}
                  page={page1}
                  onPageChange={handleChangePage1}
                  onRowsPerPageChange={handleChangeRowsPerPage1}
                  labelDisplayedRows={() => `Total: ${allMgrList?.length}  Page ${page1 + 1} of ${pageCount}`}
                />

              </TreeView>
            </Grid>

            <Grid item sm={10} sx={{ border: '1px solid #EEEEEE', borderRadius: '10px', padding: '20px' }}>
              {EmployeBlock ?
                <Box>
                  {EmployeeResult ? <>
                    <Box>
                      <Box pb={2} pl={2} pr={2}>
                        <Grid container>
                          <Grid item sm={5}>
                            <Typography variant='h6' sx={{ fontWeight: 600 }} component="h2">
                              {resourceName1}
                            </Typography>
                            <Typography variant='subtitle2'>
                              Emp ID: {resourceName?.empId || "--"} 
                            </Typography>
                          </Grid>
                          <Grid item sm={7}>
                            <Stack direction="row" spacing={2} alignItems="flex-end">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  // disableFuture
                                  label="From"
                                  borderRadius="semi-square"
                                  placeholder="From"
                                  labelAlignment="left"    
                                 
                                  // openTo="year"
                                  // views={["year", "month", "day"]}
                                  maxDate={dayjs().toDate()}
                                  value={fromDate}
                                  onChange={(newValue : any) => {
                                    setfromDateOrgl(newValue)
                                    let abc = getDate(newValue)
                                    setfromDate(abc);
                                    setfiltersSelected(true);
                                    if (dayjs(toDate) < dayjs(newValue)) {
                                      settoDate(
                                        dayjs(newValue) < dayjs()
                                          ? dayjs(newValue?.add(1, "day"))
                                          : newValue
                                      );
                                    }
                                  }}
                                  // renderInput={(params: any) => (
                                  //   <TextField
                                  //     aria-readonly
                                  //     {...params}
                                  //     autoComplete="off" // Disable suggestions
                                  //     readOnly={true} // Disable user input
                                  //     onKeyDown={(e: any) => {
                                  //       e.preventDefault();
                                  //     }}
                                  //     {...params}
                                  //     fullWidth
                                  //   />
                                  // )}
                                />
                              </LocalizationProvider>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disabled={!fromDate}
                                  borderRadius="semi-square"
                                  
                                  // disableFuture
                                  label="To"
                                  placeholder="To"
                                  labelAlignment="left"    
                                  // openTo="year"
                                  // views={["year", "month", "day"]}
                                  maxDate={dayjs().toDate()}
                                  minDate={fromDateOrgl}
                                  value={toDate}
                                  onChange={(newValue) => {
                                    let abc = getDate(newValue)
                                    settoDate(abc);
                                    setfiltersSelected(true);
                                  }}
                                  // renderInput={(params: any) => (
                                  //   <TextField
                                  //     {...params}
                                  //     autoComplete="off" // Disable suggestions
                                  //     readOnly={true} // Disable user input
                                  //     onKeyDown={(e: any) => {
                                  //       e.preventDefault();
                                  //     }}
                                  //     {...params}
                                  //     fullWidth
                                  //   />
                                  // )}
                                />
                              </LocalizationProvider>
                              <Button variant="contained" onClick={() =>
                                getProjectsReportForResource(
                                  departmentIdForReport,
                                  resourcetIdForReport,
                                  fromDate,
                                  toDate
                                )
                              }>Search</Button>
                              {filtersSelected && (
                                <Button
                                  onClick={() => {
                                    setfiltersSelected(false);
                                    setfromDate(null);
                                    setfromDateOrgl(null);
                                    settoDate(null);
                                    getProjectsReportForResource(
                                      departmentIdForReport,
                                      resourcetIdForReport,
                                      "",
                                      ""
                                    )
                                  }}
                                  color="warning"
                                  variant="contained"
                                  sx={{ ml: 0.5 }}
                                >
                                  Clear
                                </Button>
                              )}

                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider></Divider>
                      {resourceReportList && resourceReportList.length > 0 ? (
                        <Box mt={2}>
                          {resourceReportList.map((item: any) => (
                            <><Typography ml={2}>
                              <Icons.CalenderEvent /> {item?.date}
                            </Typography><TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Task</TableCell>
                                      <TableCell>Project</TableCell>
                                      <TableCell>Hrs Logged</TableCell>
                                      <TableCell>Completed On</TableCell>
                                      <TableCell>Status</TableCell>
                                      <TableCell>Tast TAT</TableCell>
                                      <TableCell>Allocated Hrs</TableCell>
                                      <TableCell>Actual Hrs</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <React.Fragment key={item.date}>
                                    {item.listRecords.map((record: any) => (
                                      <TableRow key={record.taskId}>
                                        <TableCell component="th" scope="row">
                                          {record.task}
                                        </TableCell>
                                        <TableCell align="left">{record.projectName}</TableCell>
                                        <TableCell align="left">{record.loggedHours || 0}</TableCell>
                                        <TableCell align="left">{record.completedOn}</TableCell>
                                        <TableCell align="left">
                                          <Chip
                                            variant={record.status === "Product Backlog"
                                              ? "hold"
                                              : record.status === "Sprint Backlog"
                                                ? "hold"
                                                : record.status === "In Progress"
                                                  ? "progress"
                                                  : record.status === "Review"
                                                    ? "review"
                                                    : record.status === "QA"
                                                      ? "submited"
                                                      : record.status === "QA Failed"
                                                        ? "hold"
                                                        : record.status === "Hold"
                                                          ? "hold"
                                                          : record.status === "Blocked"
                                                            ? "hold"
                                                            : record.status === "Closed"
                                                              ? "approved"
                                                              : "new"}
                                            label={record.status} />
                                        </TableCell>
                                        <TableCell align="left">{record.taskTAT}</TableCell>
                                        <TableCell align="left">{record.totalAllocatedHours}</TableCell>
                                        <TableCell align="left">{record.actualHours}</TableCell>
                                      </TableRow>
                                    ))}
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: ' #F0F9FF' }}>
                                      <TableCell component="th" colSpan={6} scope="row">
                                        Total Hours
                                      </TableCell>
                                      <TableCell align="left">{item.totalAssignedHours}</TableCell>
                                      <TableCell align="left">{item.totalConsumedHours}</TableCell>
                                    </TableRow>
                                  </React.Fragment>


                                </Table>
                              </TableContainer></>))}
                        </Box>) : (
                        <NotFound NotfoundText="No Result Found" />
                      )}
                    </Box>
                  </> : <img width="100%" src={ReportImage} alt="" />}
                </Box>
                :
                <Box>
                  {ByProjectRsult ? <Box>
                    <Typography
                      justifyContent="space-between"
                      variant="h5"
                      component="h5"
                      mb={2}
                      sx={{ fontWeight: 700, color: theme.palette.primary.dark }}
                    >
                      {allProjectListById?.projectName}
                    </Typography>

                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="start"
                        sx={{ mb: 3 }}
                      >
                        <Typography
                          justifyContent="space-between"
                          variant="h6"
                          component="h6"
                          mb={2}
                          sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                        >
                          Project Details
                        </Typography>

                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              PIN
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.projectDetails?.pin}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Project Name
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.projectName}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Keyword
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.projectDetails?.keyWords}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Channel
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.channelName}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Costing Type
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.paymentDetails?.costingType}
                            </Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Project Description
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.projectDetails?.projectDescription || "--"}
                            </Typography>
                          </Grid>

                        </Grid>

                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Pin Efforts
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.projectDetails?.pinEfforts || 0}
                            </Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Pin Amount
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.paymentDetails?.currency} {allProjectListById?.projectDetails?.pinAmount || 0}
                            </Typography>
                          </Grid>

                        </Grid>




                      </Stack>

                    </Paper>

                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="start"
                        sx={{ mb: 3 }}
                      >
                        <Typography
                          justifyContent="space-between"
                          variant="h6"
                          component="h6"
                          mb={2}
                          sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                        >
                          Client Information
                        </Typography>

                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Email
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.email}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Skype Id
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.skypeId || "--"}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              WBS
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark, "word-break": "break-all" }}
                            >
                              <ol type='1'>
                                {allProjectListById?.projectDetails?.projectDocument?.map((path: any) => (
                                  <li>   <a onClick={() => { getUnEncrypedFiles(path?.wbsPath) }} style={{ cursor: 'pointer' }}>{path?.wbsPath}</a></li>
                                ))}
                              </ol>

                            </Typography>
                          </Grid>

                        </Grid>

                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              First Name
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.firstName}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Middle Name
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.middleName || "--"}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Last Name
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.lastName || "--"}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Country
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.country || "--"}
                            </Typography>
                          </Grid>

                        </Grid>


                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Mobile Number
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.mobileNumber || "--"}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Alternative Number
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.alterNateNumber || "--"}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Company Name
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.colAddClientModel?.companyName || "--"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Projects
                            </Typography>
                            <Stack direction="row" mt={1} spacing={2}>
                              {clientProject && clientProject.map((item: any) => (
                                <Chip
                                  variant="progress"
                                  label={item?.projectName}
                                  color="default"
                                />))}
                            </Stack>

                          </Grid>
                        </Grid>



                      </Stack><Box p={2} sx={{ backgroundColor: theme.palette.primary.light }}>
                        <Typography>
                          Company Description
                        </Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {allProjectListById?.colAddClientModel?.companyDescription || "--"}
                        </Typography>

                        <Grid container mt={1} spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              justifyContent="space-between"
                              variant="h6"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Point of Contact
                            </Typography>
                          </Grid>
                          {allProjectListById?.pointOfContacts.length > 0 && allProjectListById?.pointOfContacts.map((item: any) => (
                            <><Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                First Name
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.firstName || "--"}
                              </Typography>
                            </Grid><Grid item xs={3}>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                                >
                                  Middle Name
                                </Typography>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                                >
                                  {item?.middleName || "--"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                                >
                                  Last Name
                                </Typography>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                                >
                                  {item?.lastName || "--"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                                >
                                  Email
                                </Typography>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                                >
                                  {item?.email || "--"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                                >
                                  Skype Id
                                </Typography>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                                >
                                  {item?.skypeId || "--"}
                                </Typography>
                              </Grid><Grid item xs={3}>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                                >
                                  Mobile Number
                                </Typography>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                                >
                                  {item?.mobileNumber || "--"}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                                >
                                  Alternative Number
                                </Typography>
                                <Typography
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h6"
                                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                                >
                                  {item?.alterNateNumber || "--"}
                                </Typography>
                              </Grid>
                            </>))}
                        </Grid>
                      </Box>
                    </Paper>

                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="start"
                        sx={{ mb: 3 }}
                      >
                        <Typography
                          justifyContent="space-between"
                          variant="h6"
                          component="h6"
                          mb={2}
                          sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                        >
                          Payment Details
                        </Typography>

                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Costing Type
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.paymentDetails?.costingType || "--"}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Billing Type
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.paymentDetails?.billingType || "--"}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Rate Per Hour
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.paymentDetails?.ratePerHour || 0}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              Currency
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {allProjectListById?.paymentDetails?.currency || "--"}
                            </Typography>
                          </Grid>
                        </Grid>

                      </Stack>
                    </Paper>  <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="start"
                        sx={{ mb: 3 }}
                      >
                        <Typography
                          justifyContent="space-between"
                          variant="h6"
                          component="h6"
                          mb={2}
                          sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                        >
                          Resources
                        </Typography>

                        <Grid container spacing={2}>
                          {allProjectListById?.lstResourceinfo?.length > 0 ? (allProjectListById?.lstResourceinfo?.map((prj: any) => (
                            <Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                {prj.departmentname}
                              </Typography>
                              {prj?.resources?.map((r: any) => {
                                return <Typography
                                  key={r.id}
                                  justifyContent="space-between"
                                  variant="subtitle2"
                                  component="h5"
                                  sx={{ fontWeight: 500, color: theme.palette.primary.main }}
                                >
                                  {r?.name}
                                </Typography>
                              })}
                            </Grid>)))
                            : (<Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h5"
                              sx={{ fontWeight: 500, color: theme.palette.primary.main, ml: "16px" }}
                            >
                              No Resource Assigned Yet
                            </Typography>)
                          }
                        </Grid>

                      </Stack>
                    </Paper>

                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}> <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        justifyContent="space-between"
                        variant="h6"
                        component="h6"
                        sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                      >
                        Milestones
                      </Typography>
                    </Stack>

                      {allProjectListById?.lstMilestoneinfo?.map((data: any, i: any) => {
                        return (
                          <Box>

                            <Accordion
                              expanded={expanded === "panel" + i}
                              onChange={acordianChange("panel" + i)}
                            >
                              <AccordionSummary
                                sx={{ backgroundColor: "transparent" }}
                                aria-controls={"panel" + i + "d-content"}
                                id={"panel" + i + "d-header"}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{ width: "100%" }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "300",
                                        lineHeight: "21px",
                                        color: "#424242",
                                      }}
                                    >
                                      {data?.milstonename}
                                      <Box
                                        sx={{ marginLeft: "5px", display: "inline-block" }}
                                      >
                                        {(() => {
                                          switch (data.status) {
                                            case 1:
                                              return (
                                                <Chip
                                                  variant="submited"
                                                  label="Not Started"
                                                />
                                              );
                                            case 2:
                                              return (
                                                <Chip
                                                  variant="progress"
                                                  label="In Progress"
                                                />
                                              );
                                            case 3:
                                              return (
                                                <Chip
                                                  variant="approved"
                                                  label="Completed"
                                                />
                                              );
                                            case 4:
                                              return (
                                                <Chip
                                                  variant="hold"
                                                  label="On Hold"
                                                />
                                              );
                                            default:
                                              return (
                                                <Chip
                                                  variant="submited"
                                                  label="Not Started"
                                                />
                                              );
                                          }
                                        })()}
                                      </Box>
                                      <Box
                                        sx={{ marginLeft: "5px", display: "inline-block", ml: "70px" }}
                                      >  Payment Status- {(data?.paymentStatus === null || data?.paymentStatus === "0") && (
                                        <Chip variant="filled" label={`N/A  ${data?.costing || 0}`} />
                                      )}
                                        {data?.paymentStatus == "1" && (
                                          <Chip
                                            variant="progress"
                                            label={`Invoice Raised  ${data?.costing || 0}`}
                                          />
                                        )}
                                        {data?.paymentStatus == "2" && (
                                          <Chip variant="approved" label={`Payment Received  ${data?.costing || 0}`} />
                                        )}
                                      </Box>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "21px",
                                        color: "#000",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {`${moment(data?.startDate.toString().slice(0, 10)).format(
                                        "DD-MMM-YYYY"
                                      )}`}{" "}
                                      To{" "}
                                      {`${moment(data?.endDate.toString().slice(0, 10)).format(
                                        "DD-MMM-YYYY"
                                      )}`}

                                    </Typography>
                                  </Box>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails sx={{ p: 0 }}>
                                <TableContainer component={Paper}>
                                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                                          Sprint
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                                          Start Date
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                                          End Date
                                        </TableCell>
                                        <TableCell
                                          sx={{ backgroundColor: "#F0F9FF;" }}
                                        >Status</TableCell>
                                        <TableCell
                                          sx={{ backgroundColor: "#F0F9FF;" }}
                                        ></TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {data?.sprints?.map((sprint: any) => {
                                        return (
                                          <TableRow>
                                            <TableCell sx={{ fontSize: 14 }}>
                                              {sprint?.sprintName}
                                            </TableCell>
                                            <StyledTableCell component="th">
                                              {" "}
                                              {`${moment(sprint?.startDate).format(
                                                "DD-MMM-YYYY"
                                              )}`}{" "}
                                            </StyledTableCell>
                                            <StyledTableCell component="th">
                                              {" "}
                                              {`${moment(
                                                sprint?.endDate.toString().slice(0, 10)
                                              ).format("DD-MMM-YYYY")}`}{" "}
                                            </StyledTableCell>
                                            <TableCell sx={{ fontSize: 14 }} colSpan={2}>
                                              {/* {sprint?.status}  */}
                                              {sprint?.status == "1" && (
                                                <Chip variant="review" label="To Do" />
                                              )}
                                              {sprint?.status == "2" && (
                                                <Chip
                                                  variant="progress"
                                                  label="In Progress "
                                                />
                                              )}
                                              {sprint?.status == "3" && (
                                                <Chip variant="approved" label="Completed" />
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        );
                      })}
                      {allProjectListById?.lstMilestone?.map((i: any, index: number) => (
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                              <CustomCardHeader
                                title={i?.milestoneName}
                                subheader={`${moment(i?.startDate).format(
                                  "Do MMM"
                                )} - ${moment(i?.endDate).format("Do MMM")}`}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      ))}</Paper>
                  </Box> : <img width="100%" src={ReportImage} alt="" />}
                </Box>
              }
            </Grid>


          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid item sm={2} pr={4}>
              {/* <SearchBar
              // setSearchValue={setSearchValue}
              callBackFunction={triggerSearch}
            /> */}
              <TreeView
                aria-label="multi-select"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
              >
                {allProjectListAdmin.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prjct: any) => (
                  <TreeItem nodeId={prjct?.id} label={prjct?.projectName} onClick={() => { getProjectsDetailByProjectIdAdmin(prjct?.id); }}
                    sx={{
                      "&.MuiTreeItem-root": {
                        "& .MuiTreeItem-content": {
                          borderBottom: "1px solid #eee", marginBottom: '10px'
                        }
                      }
                    }}>
                  </TreeItem>))}
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={allProjectListAdmin.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                 
                  labelDisplayedRows={() => `Total: ${allProjectListAdmin?.length}  Page ${page + 1} of ${pageeCount}`}
                />
              </TreeView>
            </Grid>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            {allProjectListById && (
              <Grid item sm={10} sx={{ border: '1px solid #EEEEEE', borderRadius: '10px', padding: '20px' }}>
                <Box>
                  <Typography
                    justifyContent="space-between"
                    variant="h5"
                    component="h5"
                    mb={2}
                    sx={{ fontWeight: 700, color: theme.palette.primary.dark }}
                  >
                    {allProjectListById?.projectName}
                  </Typography>

                  <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="start"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        justifyContent="space-between"
                        variant="h6"
                        component="h6"
                        mb={2}
                        sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                      >
                        Project Details
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            PIN
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.projectDetails?.pin}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Project Name
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.projectName}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Keyword
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.projectDetails?.keyWords}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Channel
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.channelName}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container mt={1} spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Costing Type
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.paymentDetails?.costingType}
                          </Typography>
                        </Grid>

                        <Grid item xs={9}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Project Description
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.projectDetails?.projectDescription || "--"}
                          </Typography>
                        </Grid>

                      </Grid>

                      <Grid container mt={1} spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Pin Efforts
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.projectDetails?.pinEfforts || 0}
                          </Typography>
                        </Grid>

                        <Grid item xs={9}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Pin Amount
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.paymentDetails?.currency} {allProjectListById?.projectDetails?.pinAmount || 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Paper>

                  <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="start"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        justifyContent="space-between"
                        variant="h6"
                        component="h6"
                        mb={2}
                        sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                      >
                        Client Information
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Email
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.email}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Skype Id
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.skypeId || "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            WBS
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark, "word-break": "break-all", }}
                          >
                            <ol type='1'>
                              {allProjectListById?.projectDetails?.projectDocument?.filter((a: any) => !!a.wbsPath).map((path: any) => (
                                <li>   <a onClick={() => { getUnEncrypedFiles(path?.wbsPath) }} style={{ cursor: 'pointer' }}>{path?.wbsPath}</a></li>
                              ))}
                            </ol>
                          </Typography>
                        </Grid>

                      </Grid>

                      <Grid container mt={1} spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            First Name
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.firstName}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Middle Name
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.middleName || "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Last Name
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.lastName || "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Country
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.country || "--"}
                          </Typography>
                        </Grid>

                      </Grid>


                      <Grid container mt={1} spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Mobile Number
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.mobileNumber || "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Alternative Number
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.alterNateNumber || "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Company Name
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.colAddClientModel?.companyName || "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Projects 43
                          </Typography>
                          <Stack direction="row" mt={1} sx={{flexWrap: 'wrap', gap: '12px'}}>
                            {clientProject?.map((item: any) => (
                              <Chip
                                variant="progress"
                                label={item?.projectName}
                                color="default"
                              />))}
                          </Stack>
                        </Grid>
                      </Grid>



                    </Stack><Box p={2} sx={{ backgroundColor: theme.palette.primary.light }}>
                      <Typography>
                        Company Description
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {allProjectListById?.colAddClientModel?.companyDescription || "--"}
                      </Typography>

                      <Grid container mt={1} spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            justifyContent="space-between"
                            variant="h6"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Point of Contact
                          </Typography>
                        </Grid>
                        {allProjectListById?.pointOfContacts.length > 0 && allProjectListById?.pointOfContacts.map((item: any) => (
                          <><Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              First Name
                            </Typography>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                            >
                              {item?.firstName || "--"}
                            </Typography>
                          </Grid><Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                Middle Name
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.middleName || "--"}
                              </Typography>
                            </Grid><Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                Last Name
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.lastName || "--"}
                              </Typography>
                            </Grid><Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                Email
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.email || "--"}
                              </Typography>
                            </Grid><Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                Skype Id
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.skypeId || "--"}
                              </Typography>
                            </Grid><Grid item xs={3}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                Mobile Number
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.mobileNumber || "--"}
                              </Typography>
                            </Grid><Grid item xs={4}>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                              >
                                Alternative Number
                              </Typography>
                              <Typography
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h6"
                                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                              >
                                {item?.alterNateNumber || "--"}
                              </Typography>
                            </Grid></>))}
                      </Grid>
                    </Box>
                  </Paper>

                  <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="start"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        justifyContent="space-between"
                        variant="h6"
                        component="h6"
                        mb={2}
                        sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                      >
                        Payment Details
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Costing Type
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.paymentDetails?.costingType || "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Billing Type
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.paymentDetails?.billingType || "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Rate Per Hour
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.paymentDetails?.ratePerHour || 0}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                          >
                            Currency
                          </Typography>
                          <Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h6"
                            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                          >
                            {allProjectListById?.paymentDetails?.currency || "--"}
                          </Typography>
                        </Grid>

                      </Grid>

                    </Stack>
                  </Paper>

                  <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="start"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        justifyContent="space-between"
                        variant="h6"
                        component="h6"
                        mb={2}
                        sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                      >
                        Resources
                      </Typography>

                      <Grid container spacing={2}>
                        {allProjectListById?.lstResourceinfo?.length > 0 ? (allProjectListById?.lstResourceinfo?.map((prj: any) => (
                          <Grid item xs={3}>
                            <Typography
                              justifyContent="space-between"
                              variant="subtitle2"
                              component="h6"
                              sx={{ fontWeight: 400, color: theme.palette.primary.dark }}
                            >
                              {prj.departmentname}
                            </Typography>
                            {prj?.resources?.map((r: any) => {
                              return <Typography
                                key={r.id}
                                justifyContent="space-between"
                                variant="subtitle2"
                                component="h5"
                                sx={{ fontWeight: 500, color: theme.palette.primary.main }}
                              >
                                {r?.name}
                              </Typography>
                            })}
                          </Grid>)))
                          : (<Typography
                            justifyContent="space-between"
                            variant="subtitle2"
                            component="h5"
                            sx={{ fontWeight: 500, color: theme.palette.primary.main, ml: "16px" }}
                          >
                            No Resource Assigned Yet
                          </Typography>)
                        }
                      </Grid>

                    </Stack>
                  </Paper>
                  <Paper variant="outlined" sx={{ p: 2, mb: 2 }}> <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                  >
                    <Typography
                      justifyContent="space-between"
                      variant="h6"
                      component="h6"
                      sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                    >
                      Milestones
                    </Typography>
                  </Stack>

                    {allProjectListById?.lstMilestoneinfo?.map((data: any, i: any) => {
                      return (
                        <Box>

                          <Accordion
                            expanded={expanded === "panel" + i}
                            onChange={acordianChange("panel" + i)}
                          >
                            <AccordionSummary
                              sx={{ backgroundColor: "transparent" }}
                              aria-controls={"panel" + i + "d-content"}
                              id={"panel" + i + "d-header"}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: "100%" }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      lineHeight: "21px",
                                      color: "#424242",
                                    }}
                                  >
                                    {data?.milstonename}
                                    <Box
                                      sx={{ marginLeft: "5px", display: "inline-block" }}
                                    >
                                      <Box
                                        sx={{ marginLeft: "5px", display: "inline-block" }}
                                      >
                                        {(() => {
                                          switch (data.status) {
                                            case 1:
                                              return (
                                                <Chip
                                                  variant="submited"
                                                  label="Not Started"
                                                />
                                              );
                                            case 2:
                                              return (
                                                <Chip
                                                  variant="progress"
                                                  label="In Progress"
                                                />
                                              );
                                            case 3:
                                              return (
                                                <Chip
                                                  variant="approved"
                                                  label="Completed"
                                                />
                                              );
                                            case 4:
                                              return (
                                                <Chip
                                                  variant="hold"
                                                  label="On Hold"
                                                />
                                              );
                                            default:
                                              return (
                                                <Chip
                                                  variant="submited"
                                                  label="Not Started"
                                                />
                                              );
                                          }
                                        })()}
                                      </Box>
                                      <Box
                                        sx={{ marginLeft: "5px", display: "inline-block", ml: "70px" }}
                                      >  Payment Status- {(data?.paymentStatus === null || data?.paymentStatus === "0") && (
                                        <Chip variant="filled" label={`N/A  ${data?.costing || 0}`} />
                                      )}
                                        {data?.paymentStatus == "1" && (
                                          <Chip
                                            variant="progress"
                                            label={`Invoice Raised  ${data?.costing || 0}`}
                                          />
                                        )}
                                        {data?.paymentStatus == "2" && (
                                          <Chip variant="approved" label={`Payment Received  ${data?.costing || 0}`} />
                                        )}
                                      </Box>
                                    </Box>
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "21px",
                                      color: "#000",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {`${moment(data?.startDate.toString().slice(0, 10)).format(
                                      "DD-MMM-YYYY"
                                    )}`}{" "}
                                    To{" "}
                                    {`${moment(data?.endDate.toString().slice(0, 10)).format(
                                      "DD-MMM-YYYY"
                                    )}`}

                                  </Typography>
                                </Box>
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                              <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                                        Sprint
                                      </TableCell>
                                      <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                                        Start Date
                                      </TableCell>
                                      <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                                        End Date
                                      </TableCell>
                                      <TableCell
                                        sx={{ backgroundColor: "#F0F9FF;" }}
                                      >Status</TableCell>
                                      <TableCell
                                        sx={{ backgroundColor: "#F0F9FF;" }}
                                      ></TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {data?.sprints?.map((sprint: any) => {
                                      return (
                                        <TableRow>
                                          <TableCell sx={{ fontSize: 14 }}>
                                            {sprint?.sprintName}
                                          </TableCell>
                                          <StyledTableCell component="th">
                                            {" "}
                                            {`${moment(sprint?.startDate).format(
                                              "DD-MMM-YYYY"
                                            )}`}{" "}
                                          </StyledTableCell>
                                          <StyledTableCell component="th">
                                            {" "}
                                            {`${moment(
                                              sprint?.endDate.toString().slice(0, 10)
                                            ).format("DD-MMM-YYYY")}`}{" "}
                                          </StyledTableCell>
                                          <TableCell sx={{ fontSize: 14 }} colSpan={2}>
                                            {/* {sprint?.status}  */}
                                            {sprint?.status == "1" && (
                                              <Chip variant="review" label="To Do" />
                                            )}
                                            {sprint?.status == "2" && (
                                              <Chip
                                                variant="progress"
                                                label="In Progress "
                                              />
                                            )}
                                            {sprint?.status == "3" && (
                                              <Chip variant="approved" label="Completed" />
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      );
                    })}
                    {allProjectListById?.lstMilestone?.map((i: any, index: number) => (
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={4}>
                            <CustomCardHeader
                              title={i?.milestoneName}
                              subheader={`${moment(i?.startDate).format(
                                "Do MMM"
                              )} - ${moment(i?.endDate).format("Do MMM")}`}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}</Paper>
                </Box>
              </Grid>)}
          </Grid>
        </TabPanel>

      </Box>
    </Paper>

  );
}