import { http } from "./http-common";
class RiskServices {
    getRiskData = async (achieve: any): Promise<any> => {
        return await http.post<any>("/v1/Project/GetProjectRiskListForManager?Search=" );
    };
    getRiskById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetProjectRiskById?id=${id}`);
    };

    createRisk = async (leave: any): Promise<any> => {
        return await http.post<any>("/v1/Project/AddProjectRisk", leave);
    };
    updateRisk = async (leave: any): Promise<any> => {
        return await http.put<any>("/v1/Project/UpdateProjectRisk", leave);
    };
    getDropdownData = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetProjectByProjectOwnerId/` +id);
    }

    readUnreadRiskComment = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/ReadUnreadRiskComment?id=${id}` );
    }
    
}
export const riskServices = new RiskServices ();