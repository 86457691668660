import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from '@mui/material/Typography';
import { Link, useNavigate, } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import theme from '../../../theme/darkTheme';
import { Department } from '../../../Interfaces/IDepartment';
import { IResource } from '../../../Interfaces/IResource';
import { clsDepartmentList } from '../../../service/common-services/department';
import { clsResourcesList } from '../../../service/common-services/resources';
import { HrReportFilter, IHrReportResponse } from '../../../Interfaces/IReport';
import { reportService } from '../../../service/Reports/reports';
import { HrReportStatus } from '../../../Enum/eReports'
import { Autocomplete,CircularProgress, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import AttendanceReportTable from './AttendanceReportTable';
import ReactApexChart from 'react-apexcharts';
import { eTypeOfUser } from '../../../Enum/eTypeOfUser';
import InfoPopover from '../../../components/CommonInfoComponent';
import { toast } from 'material-react-toastify';

const styles = {
    EmpStats: {
        fontWeight: 700,
        fontSize: ' 36px',
    },
    CurrentAttendanceStatus: {
        fontWeight: 500,
        fontSize: '20px',
    }
}

// export const data = [
//     ["Task", "Hours per Day"],
//     ["Total Employees", 11],
//     ["Present", 2],
//     ["Leave", 2],
//     ["Absent", 2],
// ];

const toggleloadersStyle={
    position: "fixed",
     left: "50%",
     right: "50%",
     top: "50%", 
    bottom: "50%", 
    transform: "translate(-50%, -50%)" 
   };
export default function DailyAttendanceReport() {
    const navigation = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [reports, setreports] = React.useState<IHrReportResponse>()
    const [toggleLoader, setToggleLoader] = useState(false);
    const chartdata = [
        ["task", "hours per day"],
        ["total employees", 0],
        ["present", reports?.presentCount],
        ["leave", reports?.leaveCount],
        ["absent", reports?.absentCount],
    ];

    const options: any =  {
        series: [reports?.leaveCount, reports?.absentCount, reports?.presentCount],
        colors: ['#FFC409', '#EB445A', '#2DD36F',  ],
        labels: ['Leave', 'Absent', 'Present'],
        legend: {
            show: false
          }
    };

    const hrReportStatus = Object.keys(HrReportStatus);
    //let departmentsList=new clsDepartmentList();
    const departmentsList = new clsDepartmentList();
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let DepartmentId = "";
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentsId, setdepartmentsId] = React.useState("");
    const [resourceId, setresourceId] = React.useState("");
    const [selectedDepartmentIndex, setselectedDepartmentIndex] = React.useState(0);
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [departmentHook, setDepartmentHook] = React.useState("");
    const [reportDepartmentsEl, setreportDepartmentsEl] = React.useState<null | HTMLElement>(null);
    const openReportDepartmentsEl = Boolean(reportDepartmentsEl);
    const handleReportDepartmentsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setreportDepartmentsEl(event.currentTarget);
    };
    const handleReportDepartmentsClose = () => {
        setreportDepartmentsEl(null);
    };
    const handleDepartmentItemClick = async (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setDepartmentHook(departments[index].id);
        setselectedDepartmentIndex(index);
        setresources(await clsResourcesList.getResourcesDropdownList(departments[index].id));
        setresourceHook("");
        DepartmentId = departments[index].id;
        getReport(departments[index].id, resourceHook, reportStatusesValueHook);
    };

    let ResourceId = "";
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [selectedResourceIndex, setselectedResourceIndex] = React.useState(0);
    const [resourceHook, setresourceHook] = React.useState("");
    const [reportResourcesEl, setreportResourcesEl] = React.useState<null | HTMLElement>(null);
    const openReportResourcesEl = Boolean(reportResourcesEl);
    const handleReportresourcesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setreportResourcesEl(event.currentTarget);
    };
    const handleReportResourcesClose = () => {
        setreportResourcesEl(null);
    };
    const handleResourceItemClick = async (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setresourceHook(resources[index].userId);
        setselectedResourceIndex(index);
        ResourceId = resources[index].userId;
        getReport(departmentHook, resources[index].userId, reportStatusesValueHook);
    };

    const [reportStatusesHook, setreportStatusesHook] = React.useState('Select Status');
    const [reportStatusesValueHook, setreportStatusesValueHook] = React.useState('');
    const [reportStatusesEl, setreportStatusesEl] = React.useState<null | HTMLElement>(null);
    const openReportStatusesEl = Boolean(reportStatusesEl);
    const handleReportStatusesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setreportStatusesEl(event.currentTarget);
    };
    const handleReportStatusesClose = () => {
        setreportStatusesEl(null);
    };

    const getReport = async (
        department: string = "", resource: string = "", status: string = ''
    ) => {
        setToggleLoader(true);
        var hrReportFilter: HrReportFilter = {
            department: department, resource: resource, status: status == '' ? "0" : status,
            day: null
        };
        try{
        await reportService.GetDailyAttendance(hrReportFilter)
            .then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                }
                else {

                    setreports(response.data);
                    setToggleLoader(false);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
                setToggleLoader(false)
            });
        }
    }
    React.useEffect(() => {
        if (_permission.page_ManageDailyAttendenceReport_Access !== true) {
            navigation('/dashboard');
        }
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                if (_permission.action_DailyAttendenceReport_ReportListing_AllDepartments_Access == false) {
                    setdisabledDepartments(true);
                }
                setDepartmentHook(_authUser.employeeInfo.departmentId);
                setdepartmentsId(_authUser.employeeInfo.departmentId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                getReport(_authUser.employeeInfo.departmentId, resourceHook, reportStatusesValueHook);
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(''));
                getReport(departmentHook, resourceHook, reportStatusesValueHook);

            }
        };
        departmentsData();

    }, []);
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
                <Link color="inherit" to="/">
                    Dashboard
                </Link>

                <Typography color="text.primary"> Report Management</Typography>
                <Typography color="text.primary">Daily Attendance Report</Typography>
            </Breadcrumbs>
            {toggleLoader && (<CircularProgress sx={toggleloadersStyle} />)}
            <Paper elevation={0} sx={{ p: 3 }}>
            
            <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
            <InfoPopover  onInput={"Reports"}/>
            </Stack>
                <Box sx={{ flexGrow: 1 }} />
                <Grid sx={{mt: 2}} container direction={{ xs: 'column', sm: 'row' }}
                    justifyContent={{ xs: 'flex-start', md: "space-between" }} alignItems={{ xs: "strech", md: "center" }}>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h2" sx={{
                            fontWeight: 500,
                            fontSize: '24px',
                            lineHeight: '36px',
                            color: theme.palette.primary.dark
                        }}>
                            Today’s Attendance Report
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Stack direction={{ xs: 'column', md: 'row' }}
                            justifyContent={{ xs: 'flex-start', md: "space-between" }} alignItems={{ xs: "strech", md: "center" }} spacing={1}>
                            <Box>
                                <Autocomplete
                                    disabled={disabledDepartments}
                                    value={
                                        departments.find(
                                            (option: any) => option.id == departmentsId
                                        ) ?? null
                                    }
                                    // fullWidth
                                    sx={{ ml: {xs:'0px', md:"15px"} }}
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        setDepartmentHook(value?.id ?? "");
                                        setdepartmentsId(value?.id ?? "")
                                        setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                                        setresourceHook("");
                                        setresourceId("");
                                        DepartmentId = value?.id ?? "";
                                        getReport(value?.id ?? "", resourceHook, reportStatusesValueHook);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={departmentsId}
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box>
                                <Autocomplete
                                    value={
                                        resources.find(
                                            (option: any) => option.userId == resourceId ?? ""
                                        ) ?? null
                                    }
                                    // fullWidth
                                    sx={{ ml: {xs:'0px', md:"15px"} }}
                                    id="reports-resource-menu"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={(event, value) => {
                                        setresourceId(value?.userId ?? "");
                                        setresourceHook(value?.userId ?? "");
                                        //setselectedResourceIndex(value);
                                        ResourceId = value?.userId ?? "";
                                        getReport(departmentHook, value?.userId ?? "", reportStatusesValueHook);
                                        setfiltersSelected(true);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="reports-resource-menu"
                                            name="element.name"
                                            variant="outlined"
                                            value={resourceId}
                                            fullWidth
                                            sx={{ minWidth: 220 }}
                                        />
                                    )}
                                />

                            </Box>
                            <Box>

                                <TextField
                                    id="reports-Statuses"
                                    variant="outlined"
                                    value={reportStatusesHook}
                                    select
                                    fullWidth

                                >
                                    {hrReportStatus.map((key, idx) => (
                                        <MenuItem  key={key}
                                          value={key}
                                           onClick={(event) => {
                                            var v = key;
                                            if (key == "Select Status") {
                                                v = "";
                                            }
                                            handleReportStatusesClose();
                                            setreportStatusesHook(key);
                                            setreportStatusesValueHook(v.toString());
                                            setfiltersSelected(true);
                                            getReport(departmentHook, resourceHook, v.toString());
                                            setfiltersSelected(true);
                                        }}
                                    >
                                        {key}</MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box>

                                {filtersSelected &&
                                    <Button
                                        onClick={() => {
                                            DepartmentId = "";
                                            ResourceId = "";
                                            setfiltersSelected(false);
                                            setresourceHook("");
                                            setresourceId("");
                                            setreportStatusesValueHook("0")
                                            setreportStatusesHook("Select Status")

                                            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                                setDepartmentHook(_authUser.employeeInfo.departmentId);
                                                DepartmentId = _authUser.employeeInfo.departmentId;
                                                setdepartmentsId(_authUser.employeeInfo.departmentId);
                                            }
                                            else {
                                                setDepartmentHook("");
                                                setdepartmentsId("");

                                            }
                                            getReport(DepartmentId, "", "0");
                                        }}
                                        color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                {/* --------------------------------------------------------------------- */}


                <Grid container sx={{ mt: 4 }} alignItems='center'>

                    <Grid item xs={12} lg={4}>
                        <ReactApexChart options={options} series={options?.series}  type="pie" width={380}/>
                    </Grid>
                    <Grid item xs={12} lg={8} >
                        <Grid container spacing={2} sx={{ position: "relative", zIndex: 99 }}>
                            <Grid item xs={6} md={6} lg={3}>
                                <Box sx={{ backgroundColor: theme.palette.grey[50], p: 2, borderRadius: "10px" }}>
                                    <Typography variant="body1" color={theme.palette.grey[700]} gutterBottom>Total Employees </Typography>
                                    <Typography variant="h2" color={theme.palette.primary.main}
                                        sx={styles.EmpStats}>
                                        {reports?.totalEmployee}
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>

                                <Box sx={{ backgroundColor: theme.palette.grey[50], p: 2, borderRadius: "10px" }}>
                                    <Typography variant="body1" color={theme.palette.grey[700]} gutterBottom>Present</Typography>
                                    <Typography variant="h2" color={theme.palette.success.main}
                                        sx={styles.EmpStats}>
                                        {reports?.presentCount}
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>


                                <Box sx={{ backgroundColor: theme.palette.grey[50], p: 2, borderRadius: "10px" }}>
                                    <Typography variant="body1" color={theme.palette.grey[700]} gutterBottom>Leave</Typography>
                                    <Typography variant="h2" color={theme.palette.warning.main}
                                        sx={styles.EmpStats}>
                                        {reports?.leaveCount}
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={6} md={6} lg={3}>



                                <Box sx={{ backgroundColor: theme.palette.grey[50], p: 2, borderRadius: "10px" }}>
                                    <Typography variant="body1" color={theme.palette.grey[700]} gutterBottom>Absent</Typography>
                                    <Typography variant="h2" color={theme.palette.error.main}
                                        sx={styles.EmpStats}>
                                        {reports?.absentCount}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {
                    reports?.listOutputModels && reports?.listOutputModels.length > 0 &&
                    <AttendanceReportTable tableData={reports?.listOutputModels}></AttendanceReportTable>
                }


            </Paper>
        </>
    );
}