import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { userManagementServive } from "../../service/user-management-service";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../assests/icons/icons";
import { useEffect, useState } from "react";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import {
  userModelDetail,
  userModel,
  employeeInfo,
  insuranceCard,
  personalInfo,
  documents,
  pastExperince,
  badges,
  achivements,
  skillsets,
  othersDocument,
} from "./userModel";
import { toast } from "material-react-toastify";
import { Autocomplete, Select } from "@mui/material";
import { AlignVerticalTopRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material/";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  responsiveFontSizes,
} from "@mui/material";
import theme from "../../theme/theme";
import { bool } from "prop-types";
// import { getDate } from "date-fns";
import { useSelector } from "react-redux";
import { clsDepartmentList } from "../../service/common-services/department";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import { MontsInt } from "../../Enum/eMonths";

interface Designation {
  id: string;
  name: string;
}
interface Department {
  id: string;
  name: string;
}
interface Roles {
  id: string;
  name: string;
}
interface Manager {
  hodId: string;
  managerName: string;
}
interface Country {
  id: string;
  name: string;
}

interface timezone {
  id: string;
  displayname: string;
}


const dummyCountry: Country[] = [
  {
    id: "India",
    name: "India",
  },
];
const style = {

  width: '143%'
}


const dummy = [
  {
    id: "Mohali",
    state: "Mohali",
  },
  {
    id: "Chandigarh",
    state: "Chandigarh",
  },
  {
    id: "Bangalore",
    state: "Bangalore",
  },
];
// const timezone = [
//   {
//     id:" (GMT-10:00) Hawaii",
//     name: "(GMT-10:00) Hawaii",
//   },
//   {
//     id:" (GMT-10:00) Hawaii",
//     name: "(GMT-10:00) Hawaii",
//   },
//   {
//     id:" (GMT-10:00) Hawaii",
//     name: "(GMT-10:00) Hawaii",
//   },
//   {
//     id:" (GMT-10:00) Hawaii",
//     name: "(GMT-10:00) Hawaii",
//   },
//   {
//     id:" (GMT-10:00) Hawaii",
//     name: "(GMT-10:00) Hawaii",
//   },

enum WorkLocation {
  WorkFromHome = 1,
  WorkFromOffice = 2,
  Hybrid = 3,
}

interface User {
  employeeInfo: {
    userName: string;
    employeeId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    departmentId: string;
    managerId: string;
    hodId: string;
    roleId: string;
    slackId: string;
    teamId: string;
    emailId: string;
    businessNumber: string;
    dateOfJoining: Date;
    confirmationDate: Date;
    tentativeConfirmationDate: Date;
    currentTeaure: 0;
    location: string;
    isUserFresher: boolean;
    isTrackerRequired: boolean;
    workLocationStatus: WorkLocation;
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

export default function EmployeeInfo(props: {
  obj: any;
  setUId: any;
  setValue: any;
  callBackFunction: any;
}) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.UserPermissionApp.UserPermission
  );
  const [textBoxUnable, setTextBoxUnable] = useState(false);
  const [disableForm, setdisableForm] = useState(false);

  // console.log("disableForm", disableForm);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  //use state
  const [buttonDisable, setButtonDisable] = useState(false);
  const [tendateDisable, settenDateDisable] = useState(false);

  const [confirmationdateDisable, setconfixrmationDateDisable] =
    useState(false);

  const [disableContinue, setDisableContinue] = useState(true);
  const [DisabledButton, setDisabledButton] = useState(false);

  //console.log("buttonDisable", buttonDisable);
  //console.log("DisabledButton", DisabledButton);
  //console.log("tendateDisable", tendateDisable);
  //console.log("confirmationdateDisable", confirmationdateDisable);
  const [updated, setUpdated] = useState(false);
  const [isUserFresher, setisUserFresher] = useState(true);
  const [isTrackerRequired, setisTrackerRequired] = useState(true);

  var designationData = { id: "", name: "All Designations" };
  const [designationHook, setDesignationHook] = useState(designationData);
  const [designations, setDesignations] = useState<Designation[]>([]);
  const [designationIndex, setDesignationIndex] = React.useState(1);

  var roleData = { id: "", name: "All Roles" };
  const [roleDataHook, setRoleDataHook] = useState(roleData);
  const [roles, setRoles] = useState<Roles[]>([]);
  const [selectedRolesId, setSelectedRolesId] = useState("");
  const [selectedRoleName, setSelectedRolesName] = useState("");

  const [roleIndex, setRoleIndex] = React.useState(1);

  var departmentData = { id: "", name: "All Departments" };
  const [departmentDataHook, setDepartmentDataHook] = useState(departmentData);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [disabledDepartments, setdisabledDepartments] = useState(false);
  const [departmentIndex, setDepartmentIndex] = React.useState(1);
  const [isSubmit, setSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  var countryData = { id: "", name: "All Contries" };
  const [countryDataHook, setCountryDataHook] = useState(departmentData);
  const [countries, setCountries] = useState<Country[]>([]);

  const [workLocationStatus, setworkLocationStatus] = useState<WorkLocation>(WorkLocation.WorkFromOffice);



  const [managerHook, setManagerHook] = useState<Manager>({
    hodId: "",
    managerName: "",
  });

  console.log("managerHook.managerName", managerHook.managerName);
  const [addAsManager, setAddAsManager] = useState(false);

  const [userIdHook, setUserIdHook] = React.useState();
  //const { id } = useParams();
  //let uId = userIdHook!=null ?userIdHook: id ?? "";//"632da2f9fa37260b70bf4e1f";

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    props.obj,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],
    [_othersDocument],
    [_skillsets]
  );
  const [userDataHook, setUserDataHook] = React.useState(usermodel);
  const [tempUserDataHook, setTempUserDataHook] = React.useState(null);

  const [employeeDetail, setEmployeeDetail] = useState({
    employeeId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    emailId: "",
    managerId: "",
    slackId: "",
    businessNumber: "",
    currentTeaure: 0,
    location: "",
    departmentId: "",
    teamId: "",
    dateOfJoining: null,
    confirmationDate: null,
    tentativeConfirmationDate: null,
    roleId: "",
    skillSets: "",
    userName: "",
    imageFile: "",
    password: "",
    designation: designationHook.id,
    hodId: "",
    isTrackerRequired: true,
    isUserFresher: true,
    workLocationStatus: WorkLocation.WorkFromHome,
  });

  // React.useEffect
  const handleInputChange = (e: any) => {
    console.log("handleInputChange", e.target);
    const { name, value } = e.target;
    setEmployeeDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //const
  const [users, setUsers] = useState<User[]>([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());
  const onChange = (event: React.SyntheticEvent, newValue: null) => {
    setDateValue(newValue);
  };

  const [dojValue, setDojValue] = React.useState<Dayjs>(dayjs());
  const onChangeDoj = (newValue: Dayjs) => {
    setDojValue(newValue);
  };

  const getDesignations = async () => {
    let prm = { module: 4, status: "active" };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
      } else {
        setDesignations(response.data);
      }
    });
  };
  const datePickerLabel = "Select a Date";
  const datePickerLabels = <div className="custom-label">Choose a Date</div>;

  const getRoles = async () => {
    let prm = { module: 9, status: "active" };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        setRoles([roleData]);
      } else {
        setRoles(response.data);
      }
    });
  };

  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        setDepartments([departmentData]);
      } else {
        setDepartments(response.data);
      }
    });
  };
  const getHodId_ManagerName = async (id: any) => {
    try {
      await userManagementServive.getManagerName(id).then((response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong while fetching manager data ...");
        } else {
          if (!response.data.hodId) {
            //toast.warning("Department Head is not assigned for selected department. So, this user will be made department head by default. You can change it later in master configuration.");
            setopenDepartmentHeadMessage(true);
            setAddAsManager(true);
          } else {
            setopenDepartmentHeadMessage(false);
            setAddAsManager(false);
          }
          setManagerHook(response.data);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const UpdateHeadOfDepartment = async (data: any) => {
    try {
      await userManagementServive
        .UpdateHeadOfDepartment(data)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong while updating manager...");
          } else {
            //setManagerHook(response.data);
            toast.success("Head of Department updated successfuly.");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const handleDesignationItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setDesignationHook(designations[index]);
  };
  const handleRoleItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setRoleDataHook(designations[index]);
  };
  // let date2 = dayjs(newValue);
  let today = new Date();
  let futureDates = new Date(today.setFullYear(today.getFullYear() + 1));
  // let tentativeDate = new Date(dateOfJoining.setMonth(today.getFullYear() + 1));

  const handleDepartmentItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setDepartmentDataHook(departments[index]);
  };
  const [userName, setUserName] = useState(
    userDataHook.employeeInfo.userName ?? ""
  );
  const [employeeId, setEmployeeId] = useState(
    userDataHook.employeeInfo.employeeId ?? ""
  );
  const [firstName, setFirstName] = useState(
    userDataHook.employeeInfo.firstName ?? ""
  );
  const [lastName, setLastName] = useState(
    userDataHook.employeeInfo.lastName ?? ""
  );
  const [middleName, setMiddleName] = useState(
    userDataHook.employeeInfo.middleName ?? ""
  );
  const [departmentId, setDepartmentId] = useState(userDataHook.employeeInfo.departmentId ?? "");
  const [managerId, setManagerId] = useState(managerHook?.hodId ?? "");
  const [hodId, setHodId] = useState(managerHook?.hodId ?? "");
  const [roleId, setRoleId] = useState(userDataHook.employeeInfo.roleId ?? "");
  const [slackId, setSlackId] = useState(
    userDataHook.employeeInfo.slackId ?? ""
  );
  const [teamId, setTeamId] = useState(userDataHook.employeeInfo.teamId ?? "");
  const [emailId, setEmailId] = useState(
    userDataHook.employeeInfo.emailId ?? ""
  );
  const [dateOfJoining, setDateOfJoining] = useState(
    userDataHook.employeeInfo.dateOfJoining ?? null
  );

  const [confirmationDate, setconfirmationDate] = useState(
    userDataHook.employeeInfo.confirmationDate ?? null
  );
  const [tentativeConfirmationDate, settentativeConfirmationDate] = useState(
    userDataHook.employeeInfo.tentativeConfirmationDate ?? null
  );
  const [businessNumber, setBusinessNumber] = useState(
    userDataHook.employeeInfo.businessNumber ?? ""
  );
  const [currentTeaure, setCurrentTeaure] = useState(
    userDataHook.employeeInfo.currentTeaure ?? ""
  );

  const [location, setLocation] = useState(
    userDataHook.employeeInfo.location ?? ""
  );
  const [designationId, setDesignationId] = useState(
    userDataHook.employeeInfo.designationId ?? ""
  );
  const [countryId, setCountryId] = useState(
    userDataHook.employeeInfo.countryId ?? ""
  );
  const [timezone, setTimezone] = useState<any>(
    userDataHook.employeeInfo.timezone ?? ""
  );
  const [appraiselMonth, setAppraiselMonth] = React.useState(userDataHook.employeeInfo.appraisalMonth ?? 0);

  const [timezoneapi, setTimezoneapi] = useState<any[]>([]);

  const addUser = async (employeeInfo: any) => {
    try {
      let UpdateHOD = addAsManager;
      let data = userDataHook;

      let userId = data.id;
      let empInfo = {
        userName: userName,
        employeeId: employeeId,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        departmentId: departmentId,
        managerId: managerHook.hodId,
        hodId: managerHook.hodId,
        roleId: roleId,
        slackId: slackId,
        teamId: teamId,
        emailId: emailId,
        businessNumber: businessNumber,
        dateOfJoining: dateOfJoining,
        confirmationDate: confirmationDate,
        tentativeConfirmationDate: tentativeConfirmationDate,
        currentTeaure: "0",
        //currentTeaure: currentTeaure,
        location: location,
        designationId: designationId,
        countryId: countryId,
        countryName: "India",
        timezone: timezone,
        isTrackerRequired: isTrackerRequired,
        isUserFresher: isUserFresher,
        workLocationStatus: workLocationStatus,
        appraisalMonth:appraiselMonth
      };
      data.employeeInfo = empInfo;
      if (updated && isUserFresher == true) {
        data.pastExperince = [];
      }
      if (updated) {
        setButtonDisable(true)
        //alert("updated");
        await userManagementServive.updateUser(data).then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            if (response.data) {
              // if (
              //   dateOfJoining > tentativeConfirmationDate &&
              //   dateOfJoining > confirmationDate
              // ) {
              //   return toast.warning(
              //     "Confirmation && Tentative Confirmation Date not ",
              //     {
              //       onOpen: () => setButtonDisable(true),

              //       onClose: () => setButtonDisable(false),
              //     }
              //   );
              // } else {
              toast.success("Employee info updated successfully.", {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
              });
              setDisabledButton(true);
              settenDateDisable(false);
              setconfixrmationDateDisable(false);

              // setTimeout(window.location.reload.bind(window.location), 1000);
              // }
            }
          }
        });
      } else {
        let isValidId;
        await checkUserIdAlreadyExistsForEmployee(
          employeeId,
          (response: boolean) => {
            isValidId = response;
          }
        );
        let isValid;
        await compareUserName(userName, (response: boolean) => {
          isValid = response;
        });
        if (!isValid) return false;
        await userManagementServive
          .createUser({ employeeInfo: empInfo })
          .then(async (response: any) => {
            if (response.data.isError) {
              toast.error("Something went wrong...");
            } else {
              userId = response.data;
              props.setUId(response.data);
              setDisableContinue(false);
              setDisabledButton(false);
              settenDateDisable(false);
              setconfixrmationDateDisable(false);
              setUpdated(true);
              props.callBackFunction();
              setUserIdHook(response.data);
              toast.success("Employee info added successfully.", {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
              });
              await getUserDetails();
              //window.location.href = "/users/edit-user/" + response.data;
              //uId=response.data;
              // navigate("/users/edit-user/" + response.data);
              // window.location.reload();
            }
          });
      }
      if (UpdateHOD) {
        debugger
        let mData = { departmentId: departmentId, hodId: userId };
        data.id= userId;
        data.employeeInfo.managerId=userId;
        data.employeeInfo.hodId=userId;
        data.pastExperince=[];
        await userManagementServive.updateUser(data)
        await UpdateHeadOfDepartment(mData);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setDisabledButton(true);
        setButtonDisable(false);
      });
    }
  };
  const [showHelpText, setshowHelpText] = React.useState(false);

  const getUserDetails = async () => {
    try {
      if (props.obj.length > 0) {
        await userManagementServive
          .getUserById(props.obj)
          .then(async (response: any) => {
            if (response.data.isError) {
              toast.warning("Error while fetching data");
            } else {
              setUserDataHook(response.data);
              setUserName(response.data.employeeInfo.userName);
              setEmployeeId(response.data.employeeInfo.employeeId);
              setFirstName(response.data.employeeInfo.firstName);
              setLastName(response.data.employeeInfo.lastName);
              setMiddleName(response.data.employeeInfo.middleName);
              setDepartmentId(response.data.employeeInfo.departmentId);
              setManagerId(response.data.employeeInfo.managerId);
              setHodId(response.data.employeeInfo.hodId);
              setRoleId(response.data.employeeInfo.roleId);
              setSlackId(response.data.employeeInfo.slackId);
              setTeamId(response.data.employeeInfo.teamId);
              setEmailId(response.data.employeeInfo.emailId);
              setDateOfJoining(response.data.employeeInfo.dateOfJoining);
              setconfirmationDate(response.data.employeeInfo.confirmationDate);
              settentativeConfirmationDate(
                response.data.employeeInfo.tentativeConfirmationDate
              );
              calculateDate(response.data.employeeInfo.dateOfJoining);
              setBusinessNumber(response.data.employeeInfo.businessNumber);
              setisUserFresher(response?.data.employeeInfo.isUserFresher);
              setisTrackerRequired(
                response.data.employeeInfo.isTrackerRequired
              );
              //setCurrentTeaure(response.data.employeeInfo.currentTeaure);
              setLocation(response.data.employeeInfo.location);
              setCountryId(response.data.employeeInfo.countryId ?? "");
              setDesignationId(response.data.employeeInfo.designationId ?? "");
              setTimezone(response?.data?.employeeInfo?.timezone ?? "");
              setworkLocationStatus(
                response.data.employeeInfo.workLocationStatus
              );
              setAppraiselMonth( response.data.employeeInfo.appraisalMonth)
              //alert(response.data.employeeInfo.designationId);
              await getHodId_ManagerName(
                response.data.employeeInfo.departmentId
              );
              setDisableContinue(false);
              setDisabledButton(true);
              settenDateDisable(false);
              setconfixrmationDateDisable(false);
              setUpdated(true);
            }
          });
      } else {
        settenDateDisable(true);
        setconfixrmationDateDisable(true);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
      props.setValue(0);
      setDisableContinue(true);
      setDisabledButton(false);
      setUpdated(false);
    }
  };

  // const getDateofJoining = async (newValue: any) => {
  //   console.log("newValue", newValue);
  //   // let today = new Date();
  //   // let futureDates = new Date(today.setFullYear(today.getFullYear() + 1));
  //   let tentativeNewValue = dayjs(newValue);
  //   let getDateOfNewValue = tentativeNewValue.date();
  //   let tentativeMaxValue = new Date(
  //     getDateOfNewValue.setMonth(getDateOfNewValue.getMonth() + 3)
  //   );
  // };

  const calculateDate1 = async (newValue: any) => {
    let date1 = dayjs(new Date());
    let date2 = dayjs(newValue);
    let years = date1.diff(date2, "year");
    let months = date1.diff(date2, "month");
    let days = date1.diff(date2, "day");

    //calculate total days.
    let getDateOfNewValue = date2.date();

    let newDate = date1.date(getDateOfNewValue);

    if (newDate >= date1) {
      newDate = newDate.subtract(1, "month");
    }

    // let newDays = date1.diff(newDate, "day");

    let difference = date1.date() - date2.date();
    let newDays = Math.ceil(difference / (1000 * 3600 * 24));

    let message = "";
    if (years > 0) {
      message += years + "y ";
      message += months - years * 12 + "m ";
      // message += days - years * 12 + "m " + "d ";
    } else if (months > 0) {
      message += months + "m ";
      // message += days - years * 12 + "d ";
      // message += days + "d ";
    } else if (days > 0) {
      message += days + "d ";
    }

    if (newDays > 0) {
      message += newDays + "d ";
    } else {
      // message += "0d";
      message += "0d";
    }
    setCurrentTeaure(message);
  };

  const calculateDate = async (newValue: any) => {

    if (newValue === "" || newValue === undefined || newValue === null) {
      setCurrentTeaure("");
    } else {
      let startDate = new Date(new Date(newValue).toISOString().substr(0, 10));

      let endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format

      let endDate = new Date(endingDate);
      if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      const startYear = startDate.getFullYear();
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }
      let dayDiff = endDate.getDate() - startDate.getDate();
      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      let message = "";
      if (yearDiff > 0) {
        message += yearDiff + "y ";
      }
      if (monthDiff > 0) {
        message += monthDiff + "m ";
      }
      if (yearDiff > 0 && monthDiff <= 0 && dayDiff > 0) {
        message += "0m";
      }
      if (dayDiff > 0) {
        message += dayDiff + "d ";
      }

      if (yearDiff <= 0 && monthDiff <= 0 && dayDiff <= 0) {
        message += "0d";
      }

      // if (newDays > 0) {
      //   message += newDays + "d ";
      // } else {
      //   // message += "0d";
      //   message += "0d";
      // }

      var i = yearDiff + "Y " + monthDiff + "M " + dayDiff + "D";
      setCurrentTeaure(message);
    }
    //let endingDate= new Date();
  };

  useEffect(() => {
    getDesignations();
    getTimezone();
    getRoles();
    //getDepartments();
    getUserDetails();
    if (props.obj == _authUser.id) {
      setdisableForm(true);
    }
    var type = _authUser.typeOfUser;
    if (1 === type) {
      setTextBoxUnable(true);
    }
    const departmentsData = async () => {
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        if (
          _permission.action_ManageUsersListing_AllDepartments_Access == false
        ) {
          setdisabledDepartments(true);
        }
        await setDepartmentId(_authUser.employeeInfo.departmentId);
        await setDepartments(
          await clsDepartmentList.getDepartmentDropdownList()
        );
      } else {
        await setDepartmentId("");
        await setDepartments(
          await clsDepartmentList.getDepartmentDropdownList()
        );
      }
    };
    departmentsData();
  }, []);

  const getTimezone = async () => {
    try {
      await userManagementServive
        .getTimezone("")
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            setTimezoneapi(response.data);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const [error, setError] = useState(false);

  const [openDepartmentHeadMessage, setopenDepartmentHeadMessage] =
    React.useState(false);

  const handleClickOpen = () => {
    setopenDepartmentHeadMessage(true);
  };

  const handleClose = () => {
    setopenDepartmentHeadMessage(false);
  };

  const compareUserName = async (evt: any, callback: any) => {
    try {
      var req = {
        userName: evt,
      };
      await userManagementServive.compareUsers(req).then((response: any) => {
        callback && callback(true);
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
      callback && callback(false);
    }
  };
  const [hasInteracted, setHasInteracted] = useState(false);

  const checkUserIdAlreadyExistsForEmployee = async (
    evt: any,
    callback: any
  ) => {
    try {
      var req = {
        userId: userDataHook.id.length > 0 ? userDataHook.id : null,
        employeeId: evt,
      };
      await userManagementServive
        .checkUserIdAlreadyExistsForEmployee(req)
        .then((response: any) => {
          callback && callback(true);
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
      callback && callback(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          employeeInfo: {
            userName: userName,
            employeeId: employeeId,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            departmentId: departmentId,
            managerId: managerId,
            hodId: hodId,
            roleId: roleId,
            slackId: slackId,
            teamId: teamId,
            emailId: emailId,
            businessNumber: businessNumber,
            dateOfJoining: dateOfJoining,
            confirmationDate: confirmationDate,
            tentativeConfirmationDate: tentativeConfirmationDate,
            currentTeaure: currentTeaure,
            location: location,
            designationId: designationId,
            countryId: countryId,
            countryName: "India",
            timezone: timezone,
            isUserFresher: isUserFresher,
            isTrackerRequired: isTrackerRequired,
            workLocationStatus: workLocationStatus,
          },
        }}
        validationSchema={Yup.object().shape({
          employeeInfo: Yup.object().shape({
            employeeId: Yup.string()
              .max(10, "Employee Id must be at most 10 characters")
              .matches(/^[0-9]+$/, "Employee Id  can only contain Numbers.")
              .required("Employee ID is required"),
            //   .test('Unique EmpId', 'EmployeeId already in use', // <- key, message
            //     function (value) {
            //         return new Promise((resolve, reject) => {
            //           resolve((bool)checkUserIdAlreadyExistsForEmployee(value));
            //         })
            //     }
            // )
            userName: Yup.string()
              .max(20, "User name can only contain 20 alphanumeric.")
              .matches(
                /^[A-Za-z0-9\s]+$/,
                "User name  can only contain alphanumbric."
              )
              .required("Username is required"),
            // firstName: Yup.string()
            //   .max(20, "First name must be at most 20 characters")
            //   .required("First name is required"),
            firstName: Yup.string()
              .max(20, "First name must be at most 20 characters")
              .matches(
                /^([A-Za-z\s]*)$/g,
                "First name  can only contain alphabets."
              )
              .required("First Name is required"),
            // middleName: Yup.string().max(
            //   20,
            //   "Middle name must be at most 20 characters"
            // ),
            middleName: Yup.string()
              .max(20, "Middle name must be at most 20 characters")
              .matches(
                /^([A-Za-z\s]*)$/g,
                "Middle Name  can only contain alphabets."
              ),
            // lastName: Yup.string()
            //   .max(20, "Last name must be at most 20 characters")
            //   .required("Last name is required"),
            lastName: Yup.string()
              .max(20, "Last Name must be at most 20 characters")
              .matches(
                /^([A-Za-z\s]*)$/g,
                "Last Name  can only contain alphabets."
              ),
            //.required("Last name is required"),
            //dateOfJoining: Yup.string().max(20).required('Joining Date is required'),
            //currentTeaure: Yup.string().max(20,"User name must be at most 20 characters").required('Current tenure is required'),
            // location: Yup.string()

            //   //   .max(50, "Company location must be at most 50 characters")
            //   .required("Company location is required")
            designationId: Yup.string().required("Designation is required"),
            businessNumber: Yup.string()
              .matches(/^[0-9]+$/, "Can only contains numeric")
              .min(10, "Business number must be 10 digits.")
              .max(10, "Business number must be 10 digits."),

            departmentId: Yup.string().required("Department is required"),
            countryId: Yup.string(), //.required("Country is required"),
            // currentTeaure: Yup.string().required("Current Tenure is required"),

            roleId: Yup.string().required("Role is required"),
            emailId: Yup.string()
              .matches(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z]+(\.(com|in|digital|))$/i, "Please Enter Valid Email ID")
              .max(50, "Email must be at most 50 characters")
              //.matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Please enter valid Email ID")
              .required("Email Address is required"),
            teamId: Yup.string()
              .max(50, "Team Id must be at most 50 characters")
              .matches(/^[0-9]+$/, "Can only contains numeric"),

            slackId: Yup.string()
              .max(21, "Slack Id must be at most 21 characters")
              .matches(
                /^[0-9a-z\s\-_?]+$/,
                "Must contain lowercase characters,numbers, hyphen and underscore only."
              ),
          }),
        })}
        enableReinitialize //</>={updated}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

          try {
            if (
              values.employeeInfo.tentativeConfirmationDate && values.employeeInfo.confirmationDate <= values.employeeInfo.dateOfJoining
            ) {
              toast.warning(
                " tentativeConfirmationDate && confirmationDate must be greater than dateOfJoining", {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
              }
              );
              return false;
            } else {
              await addUser(values);
            }

            // useEffect(() => {
            //     setValue(1);setValue(0);
            // }, [])
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6}>
                {/* ---------------------------------------------------------------- */}
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, "employeeInfo.employeeId") &&
                        getIn(errors, "employeeInfo.employeeId")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.employeeId") &&
                        getIn(errors, "employeeInfo.employeeId")
                      }
                      required
                      label="Employee ID"
                      variant="outlined"
                      fullWidth
                      disabled={textBoxUnable === true ? false : updated}
                      name="employeeInfo.employeeId"
                      value={values.employeeInfo.employeeId}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value
                          .replace(/\s{0,}$/g, "")
                          .trimStart()
                          .substring(0, 10);
                        values.employeeInfo.employeeId = value;
                        setDisabledButton(false);
                        setEmployeeId(value);
                      }}
                    />
                  
                    <Autocomplete
                      disabled={disableForm}
                      value={designations?.find((option) => option.id == designationId) ?? null}
                      fullWidth
                      id="outlined-basic"
                      options={designations}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        setDesignationId(value?.id ?? "");
                        values.employeeInfo.designationId = value?.id ?? "";
                        setDisabledButton(false);
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                        onBlur={handleBlur}
                          {...params}
                          id="outlined-basic"
                          error={Boolean(
                            getIn(touched, "employeeInfo.designationId") &&
                            getIn(errors, "employeeInfo.designationId")
                          )}
                          helperText={
                            getIn(touched, "employeeInfo.designationId") &&
                            getIn(errors, "employeeInfo.designationId")
                          }
                          required
                          name="employeeInfo.designationId"
                          label="Designation"
                          variant="outlined"
                          value={designationId}
                          fullWidth
                          disabled={disableForm}
                        />
                      )}
                    />
                    
                  </Stack>
                </Item>
                <Item elevation={0}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs sm={6}>
                      <FormControl disabled={disableForm}>
                        <RadioGroup
                          row
                          value={
                            isUserFresher == true ? "fresher" : "experienced"
                          }
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        // disabled={disableForm}
                        >
                          <FormControlLabel
                            disabled={disableForm}
                            value="fresher"
                            control={<Radio />}
                            label="Fresher"
                            onClick={(e) => {
                              if (!disableForm) {
                                values.employeeInfo.isUserFresher = true;
                                setisUserFresher(true);
                                setErrorMessage(false);
                                setDisabledButton(false);
                              }

                              //alert(isUserFresher);
                            }}
                          />
                          <FormControlLabel
                            disabled={disableForm}
                            value="experienced"
                            control={<Radio />}
                            label="Experienced"
                            onClick={(e) => {
                              if (!disableForm) {
                                values.employeeInfo.isUserFresher = false;
                                setisUserFresher(false);
                                setErrorMessage(false);
                                setDisabledButton(false);
                              }

                              // alert(isUserFresher);
                            }}
                          />
                        </RadioGroup>
                      </FormControl>

                      {/* <FormControl>

                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                         // defaultValue="experienced"
                         defaultValue="{isUserFresher}"
                          name="employeeInfo.isUserFresher"
                          value={isUserFresher}
                          
                        >
                          <FormControlLabel value="fresher" control={<Radio />} label="Freshers"  onChange={(event) => {
                               values.employeeInfo.isUserFresher = true;
                                setisUserFresher(true);
                                props.handleClosed();
                                setErrorMessage(false);
                                setSubmit(false);
                              }} />
                          <FormControlLabel value="experienced" control={<Radio />} label="Experienced" onClick={(event) => {
                                values.employeeInfo.isUserFresher = false;
                                setisUserFresher(false);
                                props.handleOpen();
                              }}/>

                        </RadioGroup>
                      </FormControl> */}
                    </Grid>

                    {/* <Grid item xs sm={6}>
                      <TextField id="outlined-basic" label="Login id" variant="outlined"
                        fullWidth
                      />
                    </Grid> */}
                  </Grid>
                </Item>
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Stack direction="row" spacing={2}>
                    {/* <TextField
                      id="outlined-basic"
                      select
                      error={Boolean(
                        getIn(touched, "employeeInfo.departmentId") &&
                          getIn(errors, "employeeInfo.departmentId")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.departmentId") &&
                        getIn(errors, "employeeInfo.departmentId")
                      }
                      required
                      name="employeeInfo.departmentId"
                      label="Department"
                      variant="outlined"
                      value={departmentId}
                      fullWidth
                      disabled={disableForm}
                    >
                      {departments.map((option, index) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          onClick={(event) => {
                            values.employeeInfo.departmentId =
                              departments[index].id;
                            setDepartmentId(departments[index].id);
                            getHodId_ManagerName(option.id);
                            setDisabledButton(false);
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <Autocomplete
                      disabled={disableForm || disabledDepartments}
                      value={
                        departments?.find(
                          (option) => option.id == departmentId
                        ) ?? null
                      }
                      fullWidth
                      id="bbb"
                      
                      options={departments}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        setDepartmentId(value?.id ?? "");
                        values.employeeInfo.departmentId = value?.id ?? "";
                        setDisabledButton(false);
                        getHodId_ManagerName(value?.id ?? "");
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="bbb"
                          error={Boolean(
                            getIn(touched, "employeeInfo.departmentId") &&
                            getIn(errors, "employeeInfo.departmentId")
                          )}
                          helperText={
                            getIn(touched, "employeeInfo.departmentId") &&
                            getIn(errors, "employeeInfo.departmentId")
                          }
                          onBlur={handleBlur}
                          required
                          name="employeeInfo.departmentId"
                          label="Department"
                          variant="outlined"
                          value={departmentId}
                          fullWidth
                          disabled={disableForm}
                        />
                      )}
                    />
                    <Autocomplete
                      disabled={disableForm}
                      value={
                        roles?.find((option) => option.id == roleId) ?? null
                      }
                      fullWidth
                      id="outlined-basic"
                      options={roles}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        values.employeeInfo.roleId = value?.id ?? "";
                        setDisabledButton(false);
                        setSelectedRolesId(value?.id ?? "");
                        setSelectedRolesName(value?.name ?? "");
                        setRoleId(value?.id ?? "");
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-basic"
                          error={Boolean(
                            getIn(touched, "employeeInfo.roleId") &&
                            getIn(errors, "employeeInfo.roleId")
                          )}
                          helperText={
                            getIn(touched, "employeeInfo.roleId") &&
                            getIn(errors, "employeeInfo.roleId")
                          }
                          required
                          name="employeeInfo.roleId"
                          onBlur={handleBlur}
                          label="Role"
                          variant="outlined"
                          value={roleId}
                          fullWidth
                          disabled={disableForm}
                        />
                      )}
                    />
                  </Stack>
                </Item>

                {/* -----------------------------------------------------------------*/}
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, "employeeInfo.emailId") &&
                        getIn(errors, "employeeInfo.emailId")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.emailId") &&
                        getIn(errors, "employeeInfo.emailId")
                      }
                      onBlur={handleBlur}
                      required
                      label="Email ID"
                      variant="outlined"
                      fullWidth
                      disabled={disableForm}
                      name="employeeInfo.emailId"
                      value={values.employeeInfo.emailId}
                      // onBlur={handleBlur}
                      onChange={(e) => {
                        values.employeeInfo.emailId = e.currentTarget.value;
                        setEmailId(e.currentTarget.value);
                        setDisabledButton(false);
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, "employeeInfo.userName") &&
                        getIn(errors, "employeeInfo.userName")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.userName") &&
                        getIn(errors, "employeeInfo.userName")
                      }
                      // required
                      label="Username"
                      variant="outlined"
                      fullWidth
                      required
                      disabled={updated}
                      name="employeeInfo.userName"
                      value={values.employeeInfo.userName}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value
                          .replace(/\s{1,}$/g, "")
                          .trimStart()
                          .substring(0, 20);
                        values.employeeInfo.userName = value;
                        setUserName(value);
                      }}
                    />
                  </Stack>
                </Item>

                {/* -----------------------------------------------------------------*/}
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Grid container spacing={2} alignItems="end">
                    <Grid item xs sm={6}>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <DatePicker
                          // disableFuture
                          // maxDate={new Date()}
                          borderRadius="semi-square"

                          maxDate={dayjs().toDate()}
                          required={true}
                          // error={!hasInteracted && !dateOfJoining ? "Please set a date" : ''}   

                          label="Date of Joining"

                          labelAlignment="left"

                          placeholder="Date of joining"
                          // openTo="year"
                          // views={["year", "month", "day"]}
                          //  views={["day", "month", "year"]}
                          value={dateOfJoining}
                          disabled={disableForm}
                          onChange={(newValue: any) => {
                            let abc = getDate(newValue);
                            values.employeeInfo.dateOfJoining = abc;
                            setDateOfJoining(abc);
                            calculateDate(newValue);
                            setDisabledButton(false);
                            settenDateDisable(false);
                            setHasInteracted(true);
                            setshowHelpText(false);

                          }}
                          error={showHelpText && !values.employeeInfo.dateOfJoining ? "Date Of Joining is required" : ""}
                          // inputFormat="DD-MMM-YYYY"
                          minDate={new Date("1970")}
                        // renderInput={(params: any) => (

                        //   <TextField
                        //     fullWidth
                        //     onKeyDown={(e: any) => {
                        //       e.preventDefault();
                        //     }}
                        //     {...params}
                        //     onBlur={handleBlur}
                        //     error={
                        //       getIn(touched, "dateOfJoining") &&
                        //       getIn(errors, "dateOfJoining")
                        //     }
                        //     helperText={
                        //       getIn(touched, "dateOfJoining") &&
                        //       getIn(errors, "dateOfJoining")
                        //     }
                        //     sx={{
                        //       "& .MuiSvgIcon-root": {
                        //         fill: theme.palette.primary.main,
                        //       },
                        //     }}
                        //   />
                        // )}
                        />
                      </LocalizationProvider>



                    </Grid>
                    <Grid item xs sm={6}>
                      <TextField
                        id="outlined-basic"
                        error={Boolean(
                          getIn(touched, "employeeInfo.currentTeaure") &&
                          getIn(errors, "employeeInfo.currentTeaure")
                        )}
                        helperText={
                          getIn(touched, "employeeInfo.currentTeaure") &&
                          getIn(errors, "employeeInfo.currentTeaure")
                        }
                        label="Current Tenure"
                        variant="outlined"
                        fullWidth
                        disabled
                        name="employeeInfo.currentTeaure"
                        value={currentTeaure}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          //values.employeeInfo.currentTeaure="0";
                          //setCurrentTeaure(e.currentTarget.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Item>
                <Item>
                  <Grid container spacing={2}>
                    <Grid item xs sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          //disableFuture
                          // maxDate={new Date()}
                          label=" Tentative Confirmation"
                          placeholder="Tentative Confirmation"
                          labelAlignment="left"
                          borderRadius="semi-square"

                          // openTo="year"
                          // views={["year", "month", "day"]}
                          // inputFormat="DD-MMM-YYYY"
                          value={tentativeConfirmationDate}
                          disabled={
                            disableForm || buttonDisable || tendateDisable
                          }
                          onChange={(newValue: any) => {
                            debugger
                            let abc = getDate(newValue);
                            values.employeeInfo.tentativeConfirmationDate = abc;
                            if (
                              dateOfJoining === null &&
                              tentativeConfirmationDate === null
                            ) {
                              return toast.warning(
                                "Date of Joining is required",
                                {
                                  onOpen: () => setButtonDisable(true),

                                  onClose: () => setButtonDisable(false),
                                }
                              );
                            }
                            settentativeConfirmationDate(abc);
                            setErrorMessage(false);
                            setSubmit(false);
                            settenDateDisable(false);
                            setconfixrmationDateDisable(false);
                            setDisabledButton(false);
                            // getDateofJoining(newValue);
                            setshowHelpText(false);

                          }}
                          error={showHelpText && !values.employeeInfo.tentativeConfirmationDate ? "Tentative Confirmation Date is required" : ""}
                          minDate={new Date(dateOfJoining)}
                          maxDate={new Date(futureDates)}
                        // renderInput={(params: any) => (
                        //   <TextField
                        //     autoComplete="off" // Disable suggestions
                        //     readOnly={true} // Disable user input
                        //     onKeyDown={onKeyDown}
                        //     {...params}
                        //   />
                        //   // <TextField
                        //   //   fullWidth
                        //   //   onKeyDown={(e: any) => {
                        //   //     e.preventDefault();
                        //   //   }}
                        //   //   {...params}
                        //   //   onBlur={handleBlur}
                        //   //   error={
                        //   //     getIn(touched, "tentativeConfirmationDate") &&
                        //   //     getIn(errors, "tentativeConfirmationDate")
                        //   //   }
                        //   //   helperText={
                        //   //     getIn(touched, "tentativeConfirmationDate") &&
                        //   //     getIn(errors, "tentativeConfirmationDate")
                        //   //   }
                        //   //   sx={{
                        //   //     "& .MuiSvgIcon-root": {
                        //   //       fill: theme.palette.primary.main,
                        //   //     },
                        //   //   }}
                        //   // />
                        // )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          //disableFuture
                          // maxDate={new Date()}
                          label="Confirmation"
                          placeholder="Confirmation"
                          labelAlignment="left"
                          borderRadius="semi-square"

                          // openTo="year"
                          // views={["year", "month", "day"]}
                          value={confirmationDate}
                          // inputFormat="DD-MMM-YYYY"
                          disabled={
                            disableForm ||
                            buttonDisable ||
                            confirmationdateDisable
                          }
                          onChange={(newValue: any) => {
                            let abc = getDate(newValue);
                            values.employeeInfo.confirmationDate = abc;
                            if (
                              dateOfJoining === null &&
                              tentativeConfirmationDate === null
                            ) {
                              return toast.warning(
                                "Date of Joining is required",
                                {
                                  onOpen: () => setButtonDisable(true),

                                  onClose: () => setButtonDisable(false),
                                }
                              );
                            } else if (
                              dateOfJoining !== null &&
                              tentativeConfirmationDate === null
                            ) {
                              return toast.warning(
                                "Tentative Confirmation Date is required",
                                {
                                  onOpen: () => setButtonDisable(true),

                                  onClose: () => setButtonDisable(false),
                                }
                              );
                            }
                            setconfirmationDate(abc);
                            setErrorMessage(false);
                            setSubmit(false);
                            // settenDateDisable(false);
                            setconfixrmationDateDisable(false);
                            setDisabledButton(false);
                            setshowHelpText(false);

                          }}
                          error={showHelpText && !values.employeeInfo.confirmationDate ? "Confirmation Date is required" : ""}
                          minDate={new Date(tentativeConfirmationDate || dateOfJoining)}
                          maxDate={new Date(futureDates)}
                        // renderInput={(params: any) => (
                        //   <TextField
                        //   {...params}
                        //   autoComplete="off" // Disable suggestions
                        //   readOnly={true} // Disable user input
                        //     onKeyDown={onKeyDown}

                        //   />
                        //   // <TextField
                        //   //   fullWidth
                        //   //   onKeyDown={(e: any) => {
                        //   //     e.preventDefault();
                        //   //   }}
                        //   //   {...params}
                        //   //   onBlur={handleBlur}
                        //   //   error={
                        //   //     getIn(touched, "confirmationDate") &&
                        //   //     getIn(errors, "confirmationDate")
                        //   //   }
                        //   //   helperText={
                        //   //     getIn(touched, "confirmationDate") &&
                        //   //     getIn(errors, "confirmationDate")
                        //   //   }
                        //   //   sx={{
                        //   //     "& .MuiSvgIcon-root": {
                        //   //       fill: theme.palette.primary.main,
                        //   //     },
                        //   //   }}
                        //   // />
                        // )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              {/* rightr side grid          */}
              <Grid item xs={12} sm={6}>
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, "employeeInfo.firstName") &&
                        getIn(errors, "employeeInfo.firstName")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.firstName") &&
                        getIn(errors, "employeeInfo.firstName")
                      }
                      required
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      disabled={disableForm}
                      name="employeeInfo.firstName"
                      value={values.employeeInfo.firstName}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value
                          .replace(/\s{2,}$/g, "")
                          .trimStart()
                          .substring(0, 20);

                        values.employeeInfo.firstName = value;
                        setFirstName(value);
                        setDisabledButton(false);
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, "employeeInfo.middleName") &&
                        getIn(errors, "employeeInfo.middleName")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.middleName") &&
                        getIn(errors, "employeeInfo.middleName")
                      }
                      //  required
                      label="Middle Name"
                      variant="outlined"
                      fullWidth
                      disabled={disableForm}
                      name="employeeInfo.middleName"
                      value={values.employeeInfo.middleName}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setDisabledButton(false);
                        let value = e.currentTarget.value
                          .replace(/\s{2,}$/g, "")
                          .trimStart()
                          .substring(0, 20);
                        values.employeeInfo.middleName = value;
                        setMiddleName(value);
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, "employeeInfo.lastName") &&
                        getIn(errors, "employeeInfo.lastName")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.lastName") &&
                        getIn(errors, "employeeInfo.lastName")
                      }
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      disabled={disableForm}
                      name="employeeInfo.lastName"
                      value={values.employeeInfo.lastName}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setDisabledButton(false);
                        let value = e.currentTarget.value
                          .replace(/\s{2,}$/g, "")
                          .trimStart()
                          .substring(0, 20);
                        values.employeeInfo.lastName = value;
                        setLastName(value);
                      }}
                    />
                  </Stack>
                </Item>
                <Item elevation={0}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs sm={6}>
                      <FormLabel>Mark Attendance</FormLabel>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={isTrackerRequired ? "tracker" : "connect"}
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            disabled={disableForm}
                            value="tracker"
                            control={<Radio />}
                            label="Tracker"
                            onClick={(event) => {
                              if (!disableForm) {
                                setisTrackerRequired(true);
                                setDisabledButton(false);
                                values.employeeInfo.isTrackerRequired = true;
                              }
                            }
                            }
                          />
                          <FormControlLabel
                            disabled={disableForm}
                            value="connect"
                            control={<Radio />}
                            label="Seasia Connect"
                            onClick={(event) => {
                              if (!disableForm) {
                                setisTrackerRequired(false);
                                setDisabledButton(false);
                                values.employeeInfo.isTrackerRequired = false;
                              }
                            }
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs sm={6}>
                      <TextField id="outlined-basic" label="Slack/Teams id" variant="outlined"
                        fullWidth
                      />
                    </Grid> */}
                  </Grid>
                </Item>
                {/* -----------------------------------------------------------------*/}
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Stack direction="row" spacing={2}>
                    <input
                      hidden
                      name="employeeInfo.managerId"
                      value={managerHook?.hodId}
                    ></input>
                    <input
                      hidden
                      name="employeeInfo.hodId"
                      value={managerHook?.hodId}
                    ></input>
                    <TextField
                      id="outlined-basic"
                      label="Department Head"
                      variant="outlined"
                      fullWidth
                      disabled
                      value={managerHook?.managerName ?? ""}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {/* 
                    <TextField
                      id="outlined-basic"
                      select
                      error={Boolean(
                        getIn(touched, "employeeInfo.roleId") &&
                          getIn(errors, "employeeInfo.roleId")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.roleId") &&
                        getIn(errors, "employeeInfo.roleId")
                      }
                      required
                      name="employeeInfo.roleId"
                      label="Role"
                      variant="outlined"
                      value={roleId}
                      fullWidth
                      disabled={disableForm}
                    >
                      {roles.map((option, index) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          onClick={(event) => {
                            setRoleId(roles[index].id);
                            setDisabledButton(false);
                            values.employeeInfo.roleId = roles[index].id;
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}

                  </Stack>
                </Item>
                {/* -----------------------------------------------------------------*/}
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs lg={6}>
                      <TextField
                        id="outlined-basic"
                        error={Boolean(
                          getIn(touched, "employeeInfo.slackId") &&
                          getIn(errors, "employeeInfo.slackId")
                        )}
                        helperText={
                          getIn(touched, "employeeInfo.slackId") &&
                          getIn(errors, "employeeInfo.slackId")
                        }
                        // required
                        label="Slack ID"
                        variant="outlined"
                        fullWidth
                        disabled={disableForm}
                        name="employeeInfo.slackId"
                        value={values.employeeInfo.slackId}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let value = e.currentTarget.value
                            .replace(/\s{2,}$/g, "")
                            .trimStart()
                            .substring(0, 21);
                          values.employeeInfo.slackId = value;
                          setSlackId(value);
                          setDisabledButton(false);
                        }}
                      />
                    </Grid>

                    <Grid item xs lg={6}>
                      <TextField
                        id="outlined-basic"
                        error={Boolean(
                          getIn(touched, "employeeInfo.teamId") &&
                          getIn(errors, "employeeInfo.teamId")
                        )}
                        helperText={
                          getIn(touched, "employeeInfo.teamId") &&
                          getIn(errors, "employeeInfo.teamId")
                        }
                        //  required
                        label="Team ID"
                        variant="outlined"
                        fullWidth
                        disabled={disableForm}
                        name="employeeInfo.teamId"
                        value={values.employeeInfo.teamId}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let value = e.currentTarget.value
                            .replace(/[^0-9]\s{0,}$/g, "")
                            .trimStart()
                            .substring(0, 50);
                          setDisabledButton(false);
                          values.employeeInfo.teamId = value;
                          setTeamId(value);
                        }}
                      />
                    </Grid>

                    <Grid item xs lg={6}>
                      <TextField
                        id="outlined-basic"
                        error={Boolean(
                          getIn(touched, "employeeInfo.businessNumber") &&
                          getIn(errors, "employeeInfo.businessNumber")
                        )}
                        helperText={
                          getIn(touched, "employeeInfo.businessNumber") &&
                          getIn(errors, "employeeInfo.businessNumber")
                        }
                        //  required
                        label="Business Number"
                        variant="outlined"
                        fullWidth
                        disabled={disableForm}
                        name="employeeInfo.businessNumber"
                        value={values.employeeInfo.businessNumber}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let value = e.currentTarget.value
                            .replace(/[^0-9]\s{0,}$/g, "")
                            .trimStart()
                            .substring(0, 10);
                          values.employeeInfo.businessNumber = value;
                          setBusinessNumber(value);
                          setDisabledButton(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Item>
                {/* {JSON.stringify(disableForm, null, 2)} */}
                <Item elevation={0} sx={{ minHeight: 111 }}>
                  <Grid flexWrap="wrap" container spacing={2}>
                    <Grid item lg={6}> <TextField
                      disabled={disableForm}
                      id="outlined-basic"
                      label="Company Location"
                      variant="outlined"
                      value={location}
                      onBlur={handleBlur}
                      select
                      fullWidth
                    >
                      {dummy.map((option, idx) => (
                        <MenuItem
                          key={option.state}
                          value={option.id}
                          onClick={(event) => {
                            values.employeeInfo.location = dummy[idx].id;
                            setLocation(dummy[idx].id);
                            setDisabledButton(false);
                          }}
                        >
                          {option.state}
                        </MenuItem>
                      ))}
                    </TextField></Grid>
                    <Grid item lg={6}> <TextField
                      id="outlined-basic"
                      select
                      error={Boolean(
                        getIn(touched, "employeeInfo.countryId") &&
                        getIn(errors, "employeeInfo.countryId")
                      )}
                      helperText={
                        getIn(touched, "employeeInfo.countryId") &&
                        getIn(errors, "employeeInfo.countryId")
                      }
                      name="employeeInfo.countryId"
                      label="Country"
                      variant="outlined"
                      value={countryId}
                      fullWidth
                      disabled={disableForm}
                    >
                      {dummyCountry.map((option, index) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          onClick={(event) => {
                            values.employeeInfo.countryId =
                              dummyCountry[index].id;
                            setCountryId(dummyCountry[index].id);
                            setDisabledButton(false);
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField></Grid>
                    <Grid item lg={6}>
                      <Autocomplete
                      disabled={disableForm}
                      value={
                        timezoneapi?.find(
                          (option) => option.displayName == timezone
                        ) ?? null
                      }
                      options={timezoneapi}
                      getOptionLabel={(option) => option.displayName}
                      onChange={(event, value) => {
                        setTimezone(value.displayName ?? "");
                        values.employeeInfo.timezone = value?.id ?? "";
                        setDisabledButton(false);
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="timezone"
                          error={Boolean(
                            getIn(touched, "employeeInfo.timezone") &&
                            getIn(errors, "employeeInfo.timezone")
                          )}
                          helperText={
                            getIn(touched, "employeeInfo.timezone") &&
                            getIn(errors, "employeeInfo.timezone")
                          }
                          name="employeeInfo.timezone"
                          label="Time Zone"
                          variant="outlined"
                          value={timezone}
                          // fullWidth
                          sx={style}
                          disabled={disableForm}
                        >
                        </TextField>
                      )}
                    /></Grid>
                  </Grid>
                  
                </Item>
                <Item elevation={0}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs sm={8}>
                      <FormLabel>Work Location</FormLabel>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"

                          value={workLocationStatus}
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            disabled={disableForm}
                            value={WorkLocation.WorkFromOffice}
                            control={<Radio />}
                            label="Work From Office"
                            onClick={(event) => {
                              if (!disableForm) {
                                setworkLocationStatus(WorkLocation.WorkFromOffice);
                                setDisabledButton(false);
                                // values.employeeInfo.workLocationStatus = 1 ;
                              }
                            }
                            }
                          />
                          <FormControlLabel
                            disabled={disableForm}
                            value={WorkLocation.WorkFromHome}
                            control={<Radio />}
                            label="Work From Home"
                            onClick={(event) => {
                              if (!disableForm) {
                                setworkLocationStatus(WorkLocation.WorkFromHome);
                                setDisabledButton(false);
                                //values.employeeInfo.workLocationStatus = 2;
                              }
                            }
                            }
                          />
                          <FormControlLabel
                            disabled={disableForm}
                            value={WorkLocation.Hybrid}
                            control={<Radio />}
                            label="Hybrid"
                            onClick={(event) => {
                              if (!disableForm) {
                                setworkLocationStatus(WorkLocation.Hybrid);
                                setDisabledButton(false);
                                // values.employeeInfo.workLocationStatus = 3;
                              }
                            }
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs sm={4}>
                    <Autocomplete
                                                value={
                                                  MontsInt?.find(
                                                        (option: any) => option.id == appraiselMonth
                                                    ) ?? null
                                                }
                                                disabled={disableForm}
                                                sx={{ width: 160 }}
                                                id="outlined-basic"
                                                options={MontsInt}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, value) => {
                                                    setAppraiselMonth(value?.id ?? 0);
                                                    setDisabledButton(false);
                                                }}
                                                componentsProps={{
                                                    popper: {
                                                      modifiers: [
                                                        {
                                                          name: 'flip',
                                                          enabled: true,
                                                          options: {
                                                            altBoundary: true,
                                                            flipVariations: true,
                                                            altAxis: true,
                                                            padding: 8,
                                                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Appraisal Month"
                                                        id="outlined-basic"
                                                        name="selectedResourceId"
                                                        variant="outlined"
                                                        value={appraiselMonth}
                                                        disabled={disableForm}
                                                    />
                                                )}
                                            />
                    </Grid>
                  </Grid>
                </Item>
              </Grid>

            </Grid>
            <Divider />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 3 }}
            >
              <Button
                type="submit"
                onClick={() => {
                  setErrorMessage(true);
                  setSubmit(true);
                  //setshowHelpText(!dateOfJoining && !tentativeConfirmationDate && !confirmationDate);
                }}
                variant="contained"
                disabled={disableForm || DisabledButton || buttonDisable}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                type="button"
                disabled={disableContinue}
                onClick={() => props.setValue(1)}
              >
                Continue{" "}
              </Button>
            </Stack>
          </form>

        )}
      </Formik>


      {/* <Button   onClick={handleClickOpen}>
                Deparment modal
            </Button> */}
      <Dialog
        open={openDepartmentHeadMessage}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          color="primary"
          onClick={handleClose}
          component="label"
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <CloseIcon sx={{ color: "#000" }} />
        </IconButton>
        <Box sx={{ p: 2, pt: 3 }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{ textAlign: "center", fontWeight: 700, fontSize: "21px" }}
          >
            Department Head
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: "center" }}>
              Department Head is not assigned for selected department. So, this
              user will be made department head by default. You can change it
              later in master configuration.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "center", width: 120, margin: "auto" }}
          >
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
