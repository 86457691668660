

import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Autocomplete, CircularProgress, TableCell, TableSortLabel, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../theme/theme";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import { toast } from "material-react-toastify";
import { visuallyHidden } from "@mui/utils";
import NotFound from "../../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import { clsDepartmentList } from "../../service/common-services/department";
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import Projecttop from "../projectmanagement/Projectstyle";
import { milestoneTreeService } from "../../service/Reports/milestonereportTree";
import moment from "moment";

interface Department {
  id: string;
  name: string;
}
///////page change and dropdown programming

interface Data {
  projectName: string;
  milestoneName: string;
  startDate: string;
  endDate: string;
  status: string;
  numberOfMilestones: string;
  sumOfMilestonesVariance: string;
  effortVariance: string;
  departmentName: string;
  srNo: string;
  actualEfforts: string;
  plannedEfforts: string;
  QAEfforts: string;
  DevEfforts: string;
  QAConsumed: string;
  DevConsumed: string;
  billedHour:string
}

let Duration = [{ label: "Last Month", id: 1 },
{ label: "Last 3 Months", id: 2 },
{ label: "Last 6 Months", id: 3 },
{ label: "Last 1 Year", id: 4 },
]
let Status = [{ label: "All", id: 0 },
{ label: "On Time", id: 1 },
{ label: "Delayed", id: 2 },
]
const top100Films = [
  { label: 'All Status ', id: "" },
  { label: 'New ', id: 1 },
  { label: 'In Progress ', id: 2 },
  { label: 'Completed', id: 3 },
  { label: 'On Hold ', id: 4 },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let first: any = a[orderBy],
    second: any = b[orderBy];
  if (!isNaN(Number(first))) {
    first = Number(first);
  } else if (first !== undefined) {
    first = first.toLowerCase();
  }
  if (!isNaN(Number(second))) {
    second = Number(second);
  } else if (second !== undefined) {
    second = second.toLowerCase();
  }

  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}
type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string | string },
  b: { [key in Key]: string | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "milestoneName",
    numeric: false,
    disablePadding: false,
    label: "Milestone Name",
  },
  {
    id: "projectName",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "departmentName",
    numeric: false,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
  },

  {
    id: "plannedEfforts",
    numeric: false,
    disablePadding: false,
    label: "Planned Efforts",
  },
  {
    id: "actualEfforts",
    numeric: false,
    disablePadding: false,
    label: "Actual Efforts",
  },
  {
    id: "billedHour",
    numeric: false,
    disablePadding: false,
    label: "Billing Hours",
  },
  {
    id: "effortVariance",
    numeric: false,
    disablePadding: false,
    label: "Effort variance",
  },
  {
    id: "QAEfforts",
    numeric: false,
    disablePadding: false,
    label: "QA Efforts",
  },
  {
    id: "DevEfforts",
    numeric: false,
    disablePadding: false,
    label: "Dev Efforts",
  },
  {
    id: "QAConsumed",
    numeric: false,
    disablePadding: false,
    label: "QA Consumed",
  },
  {
    id: "DevConsumed",
    numeric: false,
    disablePadding: false,
    label: "Dev Consumed",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                ...(headCell.id === "milestoneName" && {
                  position: "sticky",
                  left: 0,
                  zIndex: 99,
                  background: "#fff"

                }),
                ...(headCell.id === "projectName" && {
                  position: "sticky",
                  left: '160px',
                  zIndex: 99,
                  background: "#fff"

                }),
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function EffortVarianceReportNew() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [users, setUsers] = useState<Data[]>([]);
  const [effortVariance, setEffortVariance] = useState<any>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentsId, setdepartmentsId] = useState("");
  const [selectedDurationValue, setselectedDurationValue] = useState<any>(1);
  const [selectedStatusValue, setSelectedStatusValue] = useState<any>(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(users.length / rowsPerPage);
  const [toggleLoader, setToggleLoader] = useState(false);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  ///////page change and dropdown programming over

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const effortsColors = (plannedEfforts: any, actualEfforts: any,) => {

    const Plannedefforts = plannedEfforts;
    const Actualefforts = Math.round(actualEfforts);
    //const Effortsvariance = effortsVariance;

    let color =
      Actualefforts > Plannedefforts ? '#EB445A' : // Red if actual efforts exceed planned efforts
        Actualefforts <= Plannedefforts ? '#2DD36F' : // Green if actual efforts are less than planned efforts
          Actualefforts === Plannedefforts ? '#2DD36F' : ''; // Green if actual efforts are equal to planned efforts

    return color;
  };


  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const departmentsData = async () => {
      const departments = await clsDepartmentList.getDepartmentDropdownList();
      const modifiedDepartments = departments?.map(department => {
        if (department?.name === "Select Departments") {
          return { id: "", name: "All Department" };
        } else {
          return department;
        }
      });
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        await setdepartmentsId(_authUser.employeeInfo?.departmentId);
        await setDepartments(modifiedDepartments);
        getScheduleVarianceDetails(_authUser.employeeInfo?.departmentId, "", "");
      }
      else {
        await setdepartmentsId("");
        await setDepartments(modifiedDepartments);
        getScheduleVarianceDetails("", "", "");
      }
    }
    departmentsData();
  }, []);

  const getScheduleVarianceDetails = async (departmentId: any, duration: any, status: any) => {
    setToggleLoader(true);
    let values = {
      departmentId: departmentId ?? "",
      duration: duration ? duration : 1,
      status: 0,
      projectId: "",
    };
    try {
      await milestoneTreeService
        .getEffortVarianceReport(values)
        .then(async (response: any) => {
          if (response.data.type === "application/json") {
            const data = await response.data.text();
            toast.warning(JSON.parse(data).listErrors[0]);
            setToggleLoader(false);
          } else {
            setUsers(response?.data?.reportModel);
            setEffortVariance(response?.data)
            setToggleLoader(false);
          }
        });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err);
      });
    }
  };

  return (
    <>
      {toggleLoader && (<CircularProgress sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
      <>
        <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
          <Stack
            sx={Projecttop.topbar}
          >
            <Typography variant="h5" component="h2" sx={{ fontWeight: 600, }}>
              Effort Variance Report
            </Typography>
            <Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Stack sx={Projecttop.topright} >
              <Box sx={{ px: "18px", py: "5px", alignItems: 'center', display: 'flex', border: 1, borderColor: '#EEEEEE', borderRadius: '12px', }}>
                <Typography component="h2" sx={{ fontSize: '16px' }}>
                  Effort Variance
                </Typography>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 500, color: '#2DD36F', marginLeft: "4px" }}>
                  {parseFloat(effortVariance?.effortsVariance).toFixed(3) || "--"}
                </Typography>
              </Box>
              <Box>
                <Autocomplete
                  value={
                    departments?.find(
                      (option) => option.id === departmentsId
                    ) ?? null
                  }
                  sx={{ ml: { xs: "0px", lg: "15px" } }}
                  id="outlined-basic"
                  options={departments}
                  getOptionLabel={(option) => option.name}
                  disabled={_authUser.typeOfUser !== eTypeOfUser.Admin}
                  // onClick={handleClickListItem}
                  onChange={async (event, value) => {
                    setdepartmentsId(value?.id ?? "");
                    await getScheduleVarianceDetails(value?.id ?? "", selectedDurationValue, selectedStatusValue);
                    setPage(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="element.name"
                      disabled={_authUser.typeOfUser !== eTypeOfUser.Admin}
                      variant="outlined"
                      value={departmentsId}
                      fullWidth
                      sx={{ minWidth: 220 }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  value={
                    Duration?.find(
                      (option) => option.id === selectedDurationValue
                    ) ?? null
                  }
                  sx={{ ml: { xs: "0px", lg: "15px" } }}
                  id="outlined-basic"
                  options={Duration}
                  getOptionLabel={(option) => option.label}
                  onChange={async (event, value) => {
                    setselectedDurationValue(value?.id ?? 1);
                    await getScheduleVarianceDetails(departmentsId, value?.id ?? "", selectedStatusValue);
                    setPage(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="element.name"
                      variant="outlined"
                      label="Duration"
                      value={selectedDurationValue}
                      fullWidth
                      sx={{ minWidth: 200 }}
                    />
                  )}
                />
              </Box>
              {/* <Box>
                <Autocomplete
                  value={
                    Status?.find(
                      (option) => option.id === selectedStatusValue
                    ) ?? null
                  }
                  sx={{ ml: { xs: "0px", lg: "15px" } }}
                  id="outlined-basic"
                  options={Status}
                  getOptionLabel={(option) => option.label}
                  onChange={async (event, value) => {
                    setSelectedStatusValue(value?.id ?? 0);
                    await getScheduleVarianceDetails(departmentsId, selectedDurationValue, value?.id ?? 0);
                    setPage(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="element.name"
                      variant="outlined"
                      value={departmentsId}
                      fullWidth
                      sx={{ minWidth: 145 }}
                    />
                  )}
                />
              </Box> */}
              {/* <Box>
                <Autocomplete
                  value={
                    top100Films?.find(
                      (option) => option.id === selectedstatus
                    ) ?? null
                  }
                  id="outlined-basic"
                  options={top100Films}
                  getOptionLabel={(option) => option.label}
                  onChange={async (event, value) => {
                    setSelectedstatus(value?.id ?? "")
                  
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="element.name"
                      variant="outlined"
                      sx={{ width: 170 }}
                    />
                  )}
                />
                </Box> */}
            </Stack>
          </Stack>
          <Divider />
          <TableContainer sx={{
            mt: 1,
            [theme.breakpoints.down('sm')]: {
              maxWidth: '320px'
            },
          }}>
            <Table aria-label="simple table" size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />

              <TableBody>
                {stableSort(users, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index) => {
                    const isItemSelected = isSelected(row.projectName);
                    const rowBackgroundColor = effortsColors(row.plannedEfforts, row.actualEfforts);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick1(event, row.projectName)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        style={{ backgroundColor: rowBackgroundColor }}
                      >
                        <TableCell align="left" sx={{position: "sticky", left: "0", background: rowBackgroundColor, zIndex: "99"}}>{row.milestoneName || "--"}</TableCell>
                        <TableCell align="left" sx={{position: "sticky", left: "160px", background: rowBackgroundColor, zIndex: "99"}}>{row.projectName || "--"}</TableCell>
                        <TableCell align="left">{row.departmentName || "--"}</TableCell>
                        <TableCell align="left">{`${moment(row.startDate).format("DD MMM, YYYY") || "--"}`}</TableCell>
                        <TableCell align="left">{`${moment(row?.actualEndDate?row?.actualEndDate:row.endDate).format("DD MMM, YYYY") || "--"}`}</TableCell>
                        <TableCell align="left">{row?.plannedEfforts || 0}</TableCell>
                        <TableCell align="left">{parseFloat(row?.actualEfforts).toFixed(3) || "--"}</TableCell>
                        <TableCell align="left">{row?.billedHour || "--"}</TableCell>
                        <TableCell align="left">{parseFloat(row?.effortVarianceHours).toFixed(3) || "--"}</TableCell>
                        <TableCell align="left">{parseFloat(row?.qaEstimateHours).toFixed(3) || "--"}</TableCell>
                        <TableCell align="left">{parseFloat(row?.devEstimateHours).toFixed(3) || "--"}</TableCell>
                        <TableCell align="left">{parseFloat(row?.actualEffortsQA).toFixed(3) || "--"}</TableCell>
                        <TableCell align="left">{parseFloat(row?.actualEffortsDev).toFixed(3) || "--"}</TableCell>

                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>

            </Table>

            {users.length === 0 && (
              <NotFound NotfoundText="No Result Found" />
              // <CreateProjectMui />
            )}

          </TableContainer>

          <Stack sx={{ pt: 3, pb: 3 }}>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25, 30]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={!users.length || users.length <= 0 ? 0 : page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}

              labelDisplayedRows={() => `Total: ${users?.length}  Page ${page + 1} of ${pageCount}`}
            />
          </Stack>
        </Paper>
      </>
    </>
  );
}
