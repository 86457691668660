import * as React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from "material-react-toastify";
import { DatePicker } from "react-rainbow-components";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { kraratingServices } from "../../service/kra-rating-service";
import { userManagementServive } from "../../service/user-management-service";
import { Monts } from "../../Enum/eMonths";
import { generateYearRangeObjects, getCurrentYearId } from "../../Enum/KRA";
import { masterKRAServices } from "../../service/master-service/master-kra-service";
import * as Icons from "../../assests/icons/icons";
import { AnyAction } from "redux";
import dayjs from 'dayjs';
import FileNotFound from "../../components/styledComponent/FileNotFound";

interface RoadMap {
    checkPoint: string;
    date: string;
}

interface KraValue {
    kra: string;
    isFixed: boolean;
    roadMap: RoadMap[];
}

export default function AddKra() {

    let objkra =
    {
        kra: "",
        isFixed: false,
        roadMap: [{ checkPoint: "", date: "" }]

    }
    let objRoadmap =
    {
        date: null,
        checkPoint: ""
    }
    const [open, setOpen] = React.useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [kraValue, setKraValue] = useState<KraValue[]>([objkra]);
    const [roadMapValue, setRoadMapValue] = useState<any[]>([objRoadmap]);
    const [mapIndex, setMapIndex] = useState<any>(null)
    const { id } = useParams();
    let UserId = id
    const [userDetails, setUserDetails] = React.useState<any>("");
    const [validationErrors, setValidationErrors] = useState<any>([]);
    const [selectedYear, setSelectedYear] = useState<any>("");
    const [yearValue, setYearValue] = useState<any>([]);
    const [isMonthZero, setMonthZero] = React.useState<any>("");
    const [selectedYearLabel, setSelectedYearLabel] = useState<any>("");
    
    const navigate = useNavigate();
    const location = useLocation();
    const { navigateYear } = location.state || {};

    console.log(navigateYear , " navigateYear==")

    useEffect(() => {
        debugger
        if(UserId?.length==24){
        getUserDetails(UserId);}
 
    }, []);

    const getUserDetails = async (userId: any) => {
        try {
            await userManagementServive.getUserById(userId).then((response: any) => {
                if (response.data.isError) {
                }
                else {
                    setUserDetails(response?.data);
                    const providedYear = response.data?.employeeInfo?.confirmationDate ?
                        new Date(response?.data?.employeeInfo.confirmationDate).getFullYear() : new Date().getFullYear();
                    const providedMonth = response.data?.employeeInfo?.appraisalMonth ? response.data?.employeeInfo?.appraisalMonth : 0;
                    const yearRangeObjects = generateYearRangeObjects(providedYear, providedMonth);
                    setYearValue(yearRangeObjects)
                    setSelectedYear(navigateYear ? navigateYear : getCurrentYearId(yearRangeObjects));
                    const isMonthZero = providedMonth === 0;
                    setMonthZero(isMonthZero);
                    getKraDetails(navigateYear ? navigateYear : getCurrentYearId(yearRangeObjects), userId)
                }
            })
        }
        catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warn(err)
            });
        }
    }

    // const getKraDetails = async (year: any, userId: any) => {
    //     try {
    //         await masterKRAServices.getAllKRA().then(async (response: any) => {
    //             if (response.data.isError) {
    //             }
    //             else {
    //                 const attributeNames = response?.data?.map((item: any) => item.atrributeName);
    //                 const attributeNamesStr = attributeNames.join(', ');
    //                 const resultArray = [attributeNamesStr];
    //                 let prm = {
    //                     year: year,
    //                     userId: userId,
    //                     isFixed: true,
    //                     kRaName: resultArray,
    //                 }
    //                 await kraratingServices.getAllPredefinedKraNotAddedForUser(prm).then((response: any) => {
    //                     if (response.data.isError) {
    //                     }
    //                     else {
    //                         debugger
    //                         //Now here i removed the comma separated names and convert it to object as i need using Foreach Methos
    //                         const namesArray = response?.data[0]?.split(",");
    //                         const items = [];
    //                         for (let i = 0; i < namesArray?.length; i++) {
    //                             //Here i use foreach Loop to create my objects according to the length of my data
    //                             let item:any = {};
    //                             item.kra = namesArray[i];
    //                             item.isFixed=true;
    //                             item.roadMap=[];
    //                             items.push(item);
    //                         }
    //                         // setKraValue(items)
    //                             console.log(items,"abcabc")
    //                             console.log(kraValue,"kraValue")

    //                     }
    //                 })
    //             }
    //         })
    //     }
    //     catch (ex: any) {
    //         ex.data.Error.map((err: any, idx: any) => {

    //         });
    //     }
    // }

    const getKraDetails = async (year: any, userId: any) => {
        try {
            const response = await masterKRAServices.getAllKRA("");
            if (response.data.isError) {
                console.error('Error fetching all KRA:', response.data.message);
                return;
            }
            const attributeNames = response.data.map((item: any) => item.atrributeName.trim());
            const prm = {
                year: year,
                userId: userId,
                isFixed: true,
                kRaName: attributeNames,
            };

            const predefinedResponse = await kraratingServices.getAllPredefinedKraNotAddedForUser(prm);
            if (predefinedResponse.data.isError) {
                console.error('Error fetching predefined KRA not added for user:', predefinedResponse.data.message);
                return;
            }
            const namesArray = predefinedResponse?.data;
            if (!namesArray) {
                console.error('No names found in predefined KRA response');
                return;
            }
            else {
                const items = namesArray?.filter((item: any) => item?.trim() !== "")?.map((name: string) => ({
                    kra: name.trim(),
                    isFixed: true,
                    roadMap: [],
                }));
                
                if (items && items.length > 0) {
                    setKraValue(items);
                }
                else {
                    setKraValue([objkra]);
                }
            }

        } catch (ex: any) {
            console.error('An error occurred:', ex);
            if (ex.data?.Error) {
                ex.data.Error.forEach((err: any, idx: any) => {
                    console.error(`Error ${idx}:`, err);
                });
            }
        }
    };


    let addFormFields = () => {
        setKraValue([...kraValue, objkra]);
        setRoadMapValue([objRoadmap]);
    };

    let addRoadMapFields = () => {
        setRoadMapValue([...roadMapValue, objRoadmap]);
    };

    let removeFormFields = (i: any) => {
        let newKraValues = [...kraValue];
        newKraValues.splice(i, 1);
        setKraValue(newKraValues);
    };

    let removeRoadmapFields = (i: any) => {
        let newRoadmapValues = [...roadMapValue];
        newRoadmapValues.splice(i, 1);
        setRoadMapValue(newRoadmapValues);
        let newValidationErrors = [...validationErrors]
        newValidationErrors.splice(i, 1);
        setValidationErrors(newValidationErrors);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        debugger
        setOpen(false);
        setValidationErrors([]);
        setRoadMapValue([objRoadmap]);
        console.log("roadMapValue ===", roadMapValue)
    };

    const handleInputChange = (index: any, value: any) => {
        const updatedValidationErrors = [...validationErrors];

        if (value.length > 85) {
            updatedValidationErrors[index] = {
                ...updatedValidationErrors[index],
                checkpointError: true
            };
        } else {
            updatedValidationErrors[index] = {
                ...updatedValidationErrors[index],
                checkpointError: false
            };

            const updatedRoadMapValue = roadMapValue.map((item, i) =>
                i === index ? { ...item, checkPoint: value } : { ...item } // Create new objects
            );

           // const updatedRoadMapValue = [...roadMapValue];
           // updatedRoadMapValue[index].checkPoint = value;
            setRoadMapValue(updatedRoadMapValue);
        }

        setValidationErrors(updatedValidationErrors);
    };
    console.log(kraValue, "kraValuekraValue")
    const handleDateChange = (index: any, date: any) => {
        debugger
        let existDate = false;
        if (roadMapValue.length > 0) {
            existDate = roadMapValue.some((item, i) => {
                if (i < index) { // Change to check all previous dates
                    const prevDate = roadMapValue[i].date; // Get the date of the current item in iteration
                    return prevDate && prevDate >= date; // Compare with the entered date
                } else if (i > index) { // Check all next dates
                    const nextDate = roadMapValue[i].date; // Get the date of the current item in iteration
                    return nextDate && nextDate <= date; // Compare with the entered date
                }
                return false;
            });

        }
        if (existDate) {
            toast.warn("You can't select a date earlier than the already selected checkpoint.")

        }
        else {
            const updatedValidationErrors = [...validationErrors];
            updatedValidationErrors[index] = {
                ...updatedValidationErrors[index],
                dateError: false
            };
            setValidationErrors(updatedValidationErrors);

            //const updatedRoadMapValue = [...roadMapValue];
           // updatedRoadMapValue[index].date = date;
           const updatedRoadMapValue = roadMapValue.map((item, i) =>
            i === index ? { ...item, date: date } : { ...item } // Create new objects
        );
            setRoadMapValue(updatedRoadMapValue);
        }
    };







    const isKraEmpty = (index: any) => {
        return kraValue[index].kra.trim() === '';
    };

    const validateFields = () => {
        const errors = kraValue.map((item, index) => {
            return isKraEmpty(index);
        });
        setValidationErrors(errors);
        return !errors.some((error) => error);
    };

    // const addKra = async () => {
    //     debugger
    //     try {
    //         const hasEmptyRoadmap = kraValue.some((kra) => kra.roadMap.length === 0 && kra.isFixed === false);
    //         if (hasEmptyRoadmap) {
    //             toast.warn('Please add roadmap for all KRAs');
    //             return;
    //         }
    //         const data = {
    //             userId: UserId,
    //             userKRAList: kraValue,
    //             kraDate: selectedYear
    //         };

    //         const response = await kraratingServices.addKRA(data);
    //         if (response.data.isError) {
    //             // Handle error if needed
    //         } else {
    //             toast.success("KRA added successfully");
    //             clearFields();
    //         }
    //     } catch (error: any) {
    //         error.data.Error.map((err: any) => {
    //             toast.warn(err)

    //         });

    //     }
    // };

    const addKra = async () => {
        debugger
        try {
            const hasEmptyRoadmap = kraValue.some((kra) =>
                !kra.isFixed && (kra.roadMap.length === 0 || kra.roadMap.some(item => !item.checkPoint || !item.date))
            );;
            if (hasEmptyRoadmap) {
                toast.warn('Please add roadmap for all KRAs', {
                    onOpen: () => setButtonDisable(true),
                    onClose: () => setButtonDisable(false),
                });
                return;
            }

            const formattedKraValue = kraValue.map(kra => ({
                ...kra,
                roadMap: kra.roadMap.map(item => ({
                    ...item,
                    date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : item.date
                }))
            }));

            const data = {
                userId: UserId,
                userKRAList: formattedKraValue,
                kraDate: selectedYear
            };

            const response = await kraratingServices.addKRA(data);
            if (response.data.isError) {

            } else {
                toast.success("KRA added successfully", {
                    onOpen: () => setButtonDisable(true),
                    onClose: () => setButtonDisable(false),
                });
                clearFields();
            }
        } catch (error: any) {
            toast.warn(error.data.Error[0], {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
            });
        }
    };


    const clearFields = () => {
        setKraValue([objkra]);
        setSelectedYear(getCurrentYearId(yearValue));
        setRoadMapValue([objRoadmap])
    };

    const handleSave = () => {
        const isValid = validateFields();
        if (isValid) {
            addKra();
            setButtonDisable(true);

        } else {
        }
    };

    const validateRoadFields = () => {
        const errors = roadMapValue.map((item, index) => {
            return {
                checkpointError: isCheckpointEmpty(index),
                dateError: isDateEmpty(index)
            };

        });
        setValidationErrors(errors);
        return !errors.some((error) => error.checkpointError || error.dateError);
    };

    const isCheckpointEmpty = (index: any) => {
        return roadMapValue[index].checkPoint?.trim() === '';
    };

    const isDateEmpty = (index: any) => {
        return !roadMapValue[index].date;
    };

    const handleSaveRoadmap = () => {
        debugger
        const isValid = validateRoadFields();
        if (isValid) {
            handleClose();
            const sortedRoadMapValue = [...roadMapValue].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            setRoadMapValue(sortedRoadMapValue);

            const index = kraValue.findIndex((item, index) => index === mapIndex);

            if (index !== -1) {
                const updatedSetKraValue = [...kraValue];
                console.log("updatedSetKraValue===", updatedSetKraValue)
                updatedSetKraValue[index].roadMap = sortedRoadMapValue;
                setKraValue(updatedSetKraValue);
            } else {
                console.error("Main index value not found in setKraValue array");
            }
        }
    };


    // const handleSaveRoadmap = () => {
    //     const isValid = validateRoadFields();
    //     if (isValid) {

    //         handleClose();
    //         debugger
    //         const index = kraValue.findIndex((item, index) => index === mapIndex);

    //         if (index !== -1) {
    //             const updatedSetKraValue = [...kraValue];
    //             updatedSetKraValue[index].roadMap = roadMapValue;
    //             setKraValue(updatedSetKraValue);
    //         } else {
    //             console.error("Main index value not found in setKraValue array");
    //         }
    //     }
    // };


    return (

        <Box>
              {UserId?.length !=24?( <FileNotFound/>):(
            <>
            <Box sx={{ position: "fixed", top: "63px", zIndex: "99", p: 2, background: "whitesmoke", width: "100%" }}>
                <Paper elevation={0} sx={{ padding: '20px 14px' }}>
                    <Grid
                        container
                        justifyContent="start"
                        alignItems="center"

                    >
                        <Grid item sm={1}>
                            <Typography sx={{ p: 2, fontSize: '24px', fontWeight: '500', color: '#232360' }}>
                                KRA
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <Box>
                                <Typography sx={{ mb: '8px', fontSize: '14px', fontWeight: '300', color: '#424242' }}>
                                    Name
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#212121' }}>
                                    {userDetails?.employeeInfo?.firstName}  {userDetails?.employeeInfo?.lastName}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={2}>
                            <Box>
                                <Typography sx={{ mb: '8px', fontSize: '14px', fontWeight: '300', color: '#424242' }}>
                                    Employee ID
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#212121' }}>
                                    {userDetails?.employeeInfo?.employeeId}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={2}>
                            <Box>
                                <Typography sx={{ mb: '8px', fontSize: '14px', fontWeight: '300', color: '#424242' }}>
                                    Appraisal
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#212121' }}>
                                    {(Object.keys(Monts) as Array<keyof typeof Monts>).find(key => Monts[key] === String(userDetails?.employeeInfo?.appraisalMonth))}

                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item sm={5}>
                            {isMonthZero ? (
                                <TextField
                                    label="Select Year"
                                    value="N/A"
                                    disabled
                                    sx={{ width: '242px' }}
                                />
                            ) : (

                                <Autocomplete
                                    options={yearValue}
                                    id="combo-box-demo"
                                    sx={{ width: '242px' }}
                                    value={ 
                                        yearValue?.find(
                                            (option: any) => option.id == selectedYear
                                        ) ?? null
                                    }

                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                     
                                        setSelectedYear(value?.id ?? getCurrentYearId(yearValue))
                                        getKraDetails(value?.id ?? getCurrentYearId(yearValue), UserId)
                                        setSelectedYearLabel(value?.id);
                                        console.log("value===", selectedYearLabel)
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Select Year"
                                            value={selectedYear}
                                        />}
                                />
                            )}
                        </Grid>

                    </Grid>
                </Paper>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    width: '50%', margin: '0 auto', "& .MuiPaper-root": {
                        width: '100%'
                    }
                }}
            >

                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 2 }}>
                        <Typography sx={{ fontSize: '24px', color: '#232360', fontWeight: '500' }}>
                            Add Roadmap
                        </Typography>
                        <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
                    </Stack>

                </DialogTitle>

                <DialogContent>
                    {roadMapValue.map((item: any, index: any) => (
                        <Box sx={{ display: "flex", alignItems: "start", gap: "8px", mb: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    error={validationErrors[index]?.dateError && 'Date is required'}
                                    required
                                    style={{ width: index === 0 ? "250px" : "268px" }}
                                    borderRadius="semi-square"
                                    placeholder="ETA Date"
                                    labelAlignment="left"

                                    value={item.date !== null ? item.date : undefined}
                                    onChange={(date) => handleDateChange(index, date)}
                                    minDate={moment().subtract(0, "years").toDate()}
                                    maxDate={
                                        new Date(
                                            new Date().getFullYear() + 1,
                                            11,

                                            31
                                        )
                                    }
                                />
                            </LocalizationProvider>

                            <TextField
                                error={validationErrors[index]?.checkpointError}
                                helperText={validationErrors[index]?.checkpointError && 'Roadmap Is Required'}
                                required
                                placeholder="Roadmap"
                                variant="outlined"
                                multiline
                                rows={1}
                                fullWidth
                                value={item?.checkPoint}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: '100%',
                                        lineHeight: '18px',
                                        p: '10',
                                    },
                                    "& .MuiFormHelperText-root": {
                                        fontSize: "0.875rem",
                                        marginTop: "0.5rem",
                                        alignSelf: "flex-start",
                                        color: validationErrors[index]?.checkpointError ? "rgb(254, 72, 73)" : "inherit", // Conditional color change
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderWidth: "2px",
                                        borderColor: validationErrors[index]?.checkpointError ? "rgb(254, 72, 73)" : "inherit", // Conditional border color
                                    }
                                }}
                                inputProps={{
                                    maxLength: 85,
                                }}
                            />


                            {/* <TextField
                                error={validationErrors[index]?.checkpointError}
                                helperText={validationErrors[index]?.checkpointError && 'Roadmap is required'}
                                required
                                placeholder="Roadmap"
                                variant="outlined"
                                multiline
                                rows={1}
                                fullWidth
                                value={item?.checkPoint}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: '100%',
                                        lineHeight: '18px',
                                        p: '10'
                                    },
                                    "& .MuiFormHelperText-root": {
                                        fontSize: " 0.875rem",
                                        marginTop: " 0.5rem",
                                        alignSelf: "flex-start",
                                        color: " rgb(254, 72, 73)",

                                    },
                                    "& .MuiOutlinedInput-notchedOutline ":{
                                        borderWidth: "2px",
                                        borderColor: "rgb(254, 72, 73) !important"
                                    }
                                }}
                                inputProps={{
                                    maxLength: 85
                                }}
                            /> */}
                            <Box>
                                {index ? (
                                    <Box sx={{ cursor: "pointer" }} onClick={() => removeRoadmapFields(index)}>
                                        <DeleteIcon sx={{ color: "#ff5858" }} />
                                    </Box>

                                ) : null}
                            </Box>

                        </Box>
                    ))}
                </DialogContent>

                <DialogActions sx={{ padding: '0 24px 20px 24px' }}>
                    <Stack direction={'row'} alignItems={'center'}>

                        <Button
                            variant="text"
                            endIcon={<AddIcon />}
                            onClick={() => addRoadMapFields()}
                            sx={{ my: 2 }}

                        >
                            Add Checkpoints{" "}
                        </Button>

                    </Stack>
                    <Button
                        //type="submit"
                        onClick={handleSaveRoadmap}
                        variant="contained"
                        size="large"
                        color="primary">
                        Save
                    </Button>
                </DialogActions>

            </Dialog>

            {isMonthZero ? (
                <Box sx={{ mt: '100px', textAlign: 'center' }}>
                    <Typography>Please, firstly add the Appraisal Month for this record.</Typography>
                </Box>
            ) : (

                <Box sx={{ mt: '20px' }}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item sm={12} sx={{ marginTop: "60px", display: "flex", justifyContent: "end" }}>
                            <Button
                               variant="outlined"
                               sx={{marginTop: "24px"}}
                                onClick={() => {
                                 debugger;
                                    const additionalVariable = selectedYearLabel;
                                    navigate(`/krarating/${UserId}`, { state: { additionalVar:  additionalVariable?additionalVariable : navigateYear  } })
                                }}
                            >
                                {"View-KRA"}
                            </Button>
                        </Grid>
                        <Grid item sm={6}>
                            <Paper elevation={0} sx={{ padding: '22px 20px', }}>
                                {kraValue.map((item, mainIndex) => (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        key={mainIndex}
                                        sx={{ mb: 2 }}
                                    >
                                        <Grid item xs={6}>
                                            <TextField
                                                id={`kra-${mainIndex}`}
                                                fullWidth
                                                label="KRA"
                                                variant="outlined"
                                                value={item.kra}
                                                InputProps={{
                                                    readOnly: item?.isFixed === false ? false : true,
                                                }}
                                                onChange={(e) => {
                                                    const updatedValidationErrors = [...validationErrors];
                                                    updatedValidationErrors[mainIndex] = false;
                                                    setValidationErrors(updatedValidationErrors);
                                                    const updatedKraValue = [...kraValue];
                                                    updatedKraValue[mainIndex].kra = e.target.value;
                                                    setKraValue(updatedKraValue);
                                                }}
                                                error={validationErrors[mainIndex]}
                                                helperText={validationErrors[mainIndex] && 'KRA is required'}

                                            />
                                        </Grid>


                                        {/* <Grid item xs={6}>
                                            <Box sx={{ textAlign: 'center', display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                                                {item?.roadMap?.length === 0 && item?.isFixed === false ? (
                                                    <Button
                                                        variant="outlined"
                                                        size="large"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            setMapIndex(mainIndex);
                                                            handleClickOpen();
                                                        }}
                                                        sx={{ p: '6px 15px', mx: '24px' }}
                                                    >
                                                        Add Roadmap
                                                    </Button>
                                                ) : (
                                                    item?.isFixed === false && (
                                                        <Typography sx={{ textAlign: "left", marginLeft: "30px", cursor: 'pointer' }} onClick={() => {
                                                            setMapIndex(mainIndex);
                                                            setOpen(true);
                                                            setRoadMapValue(item?.roadMap)
                                                        }}>
                                                            <Box>
                                                                <Typography sx={{ fontSize: "15px", fontWeight: "700" }}>{'Roadmap'}</Typography>
                                                                <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                                                                    {item?.roadMap?.map((roadMapItem, roadMapIndex) => (
                                                                        <div key={roadMapIndex} style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                                                                            <Typography>{moment(roadMapItem.date).format('MM/DD/YYYY')}</Typography>
                                                                            <Typography sx={{ width: "80px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                                                {roadMapItem.checkPoint.length > 10 ? `${roadMapItem.checkPoint.substring(0, 10)}...` : roadMapItem.checkPoint}
                                                                            </Typography>

                                                                                    <Icons.View />


                                                                        </div>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        </Typography>)

                                                )}
                                                <Box>
                                                    {mainIndex && item?.isFixed === false ? (
                                                        <Box sx={{ cursor: "pointer" }} onClick={() => removeFormFields(mainIndex)}>
                                                            <DeleteIcon sx={{ color: "#ff5858" }} />
                                                        </Box>
                                                    ) : null}
                                                </Box>
                                            </Box>
                                        </Grid> */}

                                        <Grid item xs={6}>
                                            <Box sx={{ textAlign: 'center', display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                                                {(item?.roadMap[0]?.checkPoint == "" && item?.isFixed === false) ? (
                                                    <Button
                                                        variant="outlined"
                                                        size="large"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            setMapIndex(mainIndex);
                                                            handleClickOpen();
                                                            setRoadMapValue([objRoadmap]);
                                                        }}
                                                        sx={{ p: '6px 15px', mx: '24px' }}
                                                    >
                                                        Add Roadmap
                                                    </Button>
                                                ) : (
                                                    item?.isFixed === false && (
                                                        <Button
                                                            variant="outlined"
                                                            size="large"
                                                            color="primary"
                                                            onClick={() => {
                                                                setMapIndex(mainIndex);
                                                                setOpen(true);
                                                                setRoadMapValue(item?.roadMap);
                                                            }}
                                                            sx={{ p: '6px 15px', mx: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                        >
                                                            View Roadmap
                                                            <Box sx={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
                                                                <Icons.View />
                                                            </Box>
                                                        </Button>

                                                    )
                                                )}
                                                <Box>
                                                    {mainIndex && item?.isFixed === false ? (
                                                        <Box sx={{ cursor: "pointer" }} onClick={() => removeFormFields(mainIndex)}>
                                                            <DeleteIcon sx={{ color: "#ff5858" }} />
                                                        </Box>
                                                    ) : null}
                                                </Box>
                                            </Box>
                                        </Grid>


                                    </Grid>
                                ))}
                            </Paper>
                            <Box sx={{ mt: '26px' }}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Button
                                        variant="text"
                                        endIcon={<AddIcon />}
                                        onClick={() => addFormFields()}
                                        sx={{ my: 2 }}

                                    >
                                        Add More{" "}
                                    </Button>

                                </Stack>
                            </Box>
                            <Box sx={{ borderTop: '1px solid #E1E1E1', pt: '24px', mt: '26px' }}>
                                <Stack direction={'row'} justifyContent={'end'}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        color="primary"
                                        sx={{ p: '14px 30px' }}
                                        onClick={() => {
                                            window.close();
                                            window.history.back();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleSave}
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        sx={{ p: '14px 30px', marginLeft: '22px' }}
                                        disabled={buttonDisable}
                                    >
                                        Save
                                    </Button>
                                </Stack>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}</>)}

        </Box>

    )
}


