import { http } from "../http-common";
class masterKRAService {


    getAllKRA = async (data: any): Promise<any> => {
        return await http.get<any>(`/v1/Master/GetAllBehavioralAtrributesSkills?search=${data}`);
    };

    createKRA = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Master/CreateBehavioralAtrributesSkills", data);
    };

    deleteKRA = async (id: string): Promise<any> => {
        return await http.delete<any>(`/v1/KRA/DeleteKraBehaviourSkillAttribute?id=${id}`);
    }

}

export const masterKRAServices = new masterKRAService();