import { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { issueManagementService } from "../../service/issues-management-service";
import TicketDetails from "./TicketDetails";
import { boardDashboardServive } from "../../service/BoardDashboardServive";
import { userManagementServive } from "../../service/user-management-service";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const initialvalues = {
  id: "",
  name: "",
};

export default function IssueDetailLarge() {
  const navigate = useNavigate();
  const [issueDetail, setIssuesDetail] = useState<any>({});
  const [userList, setUserList] = useState<any>([]);
  const [spritList, setSpritList] = useState<any>([]);
  const [selectedProjectId, setProjectId] = useState("");
  const [elements, setElements] = useState<any>(null);

  const { id } = useParams();
  const getAllSprint = useCallback(() => {
    if (selectedProjectId !== "") {
      boardDashboardServive
        .getAllSprintByProjectId({
          projectId: selectedProjectId,
        })
        .then((response: any) => {
          setSpritList(response?.data || []);
        });
    }
  }, [selectedProjectId]);
  const getAllUserListByProjectId = useCallback(() => {
    if (selectedProjectId !== "") {
      userManagementServive
        .getAllUsersByProjectId({
          projectId: selectedProjectId,
        })
        .then((response: any) => {
          setUserList(response?.data || []);
        });
    }
  }, [selectedProjectId]);
  useEffect(() => {
    GetIssueDetailById(id);
    getAllSprint();
    getAllUserListByProjectId();
  }, [getAllSprint, getAllUserListByProjectId,id]);

  const GetIssueDetailById = async (id: any) => {
    await issueManagementService
      .GetTicketDetailById(id)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setIssuesDetail(response.data);
          if (
            response.data.projectId != null &&
            response.data.projectId != ""
          ) {
            setProjectId(response.data.projectId);
            getAllSprint();
            getAllUserListByProjectId();
            getAllTicketForBoardFilters(response?.data?.projectId,response?.data?.sprintId)
          }
        }
      });
  };

  const getAllTicketForBoardFilters =
    (selectedProjectId: string,selectedSprintId:any) => {
    
      boardDashboardServive
        .getAllTicketForBoardFiltersAsync({
          projectId: selectedProjectId,
          sprintId: selectedSprintId,
          search: "",
          issueType: "",
          userIds: "",
          isUnassignee: false,
        })
        .then((response: any) => {
          if (response?.data?.isError) {
            
          } else {
            var arr: [] = response?.data?.ticketOutputModels;
            setElements(arr);
          
          }
        });
    } ;
const abc = (data: any) =>{
  GetIssueDetailById(data.id)
}
console.log("sprint ID ==", issueDetail.sprintId)
console.log("issueDetail ==", issueDetail)
  return (
    <>
      
      {issueDetail.id && (
        <TicketDetails
          TicketData={issueDetail}
          projectWiseAssigneeUsers={userList}
          spritList={spritList}
          currentSprint={issueDetail.sprintId}
          selectedUserId ={issueDetail?.userId ? [issueDetail.userId] : []}
          onDrawerClose={() => {
            navigate(`/board/${selectedProjectId}`);
          }}
          refreshData={abc}
          elements={issueDetail}
        />
      )}
    </>
  );
}

// export default IssueDetailLarge;
