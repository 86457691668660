import React from "react";
import Manager from "../pages/projectmanagement/Manager";
import ProjectList from "../pages/projectmanagement/ProjectList";

import { useEffect, useState } from "react";
import { AppConstants } from "../config/AppConstants";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
export default function ProjectManagement() {
  const [currentLoggedInRole, setLoggedInRole] = useState("");
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  useEffect(() => {
    if (_authUser.typeOfUser){
      let curreUserRole = _authUser.typeOfUser;
      curreUserRole = curreUserRole ? curreUserRole : "";
      setLoggedInRole(curreUserRole);
    }
  }, []);

  return (
    <>
      {/* <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
        <Box sx={{ mb: 2 }}>
          <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        </Box>

        <Button variant="contained">Contained</Button>
      </Paper> */}
      <ProjectList />
      {/* {currentLoggedInRole == AppConstants.UserType.Manager && <Manager />} */}
    </>
  );
}
