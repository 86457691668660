import { http } from "../http-common";
class HODservices {
    getHod = async (hod:any): Promise<any> => {
        return await http.post<any>("/v1/Master/HeadOfDepartment/GetAll", hod);
    };
    getHodById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Master/ManagerName/GetByDepartmentId?id=${id}`);
    };
   
    updateHod = async (desig: any): Promise<any> => {
        return await http.put<any>("/v1/Master/HeadOfDepartment/Update", desig);
    };
 
    updatesubmitHod = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/UpdateColIO", desig);
    };

    createsubmitHod = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/CreateColIO", desig);
    };

    GetAllUserWithIO = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/GetAllUserWithIO", desig);
    };
   

    getDepartmentById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Master/Department/SearchbyId?id=${id}`);
    };

    
    getDropdownData = async (id : any): Promise<any> => {
        return await http.post<any>(`/UserManagement/GetResourcesbyDepartmentId?departmentId=${id}`);
    };
    
    getSearch = async (hod : any): Promise<any> => {
        return await http.post<any>("/v1/Master/HeadOfDepartment/GetAll", hod);
    }; 

    getCoHod = async (hod:any): Promise<any> => {
        return await http.post<any>("/v1/Master/CoHod/GetAll", hod);
    };

}

export const hodServices = new HODservices();