
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { Box, Grid, Button, TablePagination, Checkbox, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Department } from '../../Interfaces/IDepartment';
import { IResource } from '../../Interfaces/IResource';
import { clsResourcesList } from '../../service/common-services/resources';
import { clsDepartmentList } from '../../service/common-services/department';
import InfoPopover from '../../components/CommonInfoComponent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { skillsetReportService } from '../../service/skillsetreport-service';
import NotFound from '../../components/styledComponent/NotFound';
import { toast } from 'material-react-toastify';
import SearchBar from '../../components/styledComponent/SearchBar';
import { alpha, styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}

interface ReserveInter {
    empId: string,
    experience: string,
    name: string,
    projectsList: string,
    skillSets: []

}
const NewStyledTableCell = styled(StyledTableCell)(({ theme }) => ({
    whiteSpace: "unset",
    [`&.${tableCellClasses.head}`]: {
        width: "200px",
        whiteSpace: "unset",
    },
}));


export default function SkillSetReport() {
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const navigate = useNavigate();
    const [skillSets, setSkillSets] = useState<ReserveInter[]>([]);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = useState("");
    const [departmentsId, setdepartmentsId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);

    const getSkillsetReports = async (
        departmentId: any,
        resourceId: any,
        searchValue: any,
    ) => {
        let values = {
            department: departmentId,
            resources: resourceId,
            skip: 0,
            take: 1000,
            search: searchValue
        };
        await skillsetReportService
            .getSkillSetDetails(values)
            .then((response: any) => {
                if (response.data.isError) {
                } else {
                    setSkillSets(response?.data);
                }
            });
    };

    React.useEffect(() => {
        if (_permission.tab_SkillSet_SkillSetReport_Access !== true) {
            navigate("/dashboard");
        }
        const departmentsData = async () => {
            setDepartments(await clsDepartmentList.getDepartmentDropdownList());
            if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                if (_permission.action_SkillSet_SkillSetReport_AllDepartment_Access == true) {
                    setdisabledDepartments(false);
                } else {
                    setdisabledDepartments(true);
                }
                await setdepartmentsId(_authUser.employeeInfo?.departmentId);
                setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo?.departmentId));
                getSkillsetReports(_authUser.employeeInfo?.departmentId, "", "")
            }
            else {
                setdepartmentsId("");
                setresources(await clsResourcesList.getResourcesDropdownList(""));
            }
        };
        departmentsData();
    }, []);
    const triggerSearch = async (newValue: string) => {
        debugger
        if (!newValue) {
            setSearchValue("");
            if (departmentsId || resourceId) {
                getSkillsetReports(departmentsId, resourceId, "");
            } else {
                setSkillSets([]);
            }
            setPage(0);
        }
        else {
            setfiltersSelected(true);
            setSearchValue(newValue)
            getSkillsetReports(departmentsId, resourceId, newValue);
            setPage(0);
        }
    };
    const [dense, setDense] = React.useState(false);
    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(skillSets.length / rowsPerPage);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    const emptyRows =
        page > 10 ? Math.max(0, (1 + page) * rowsPerPage - skillSets?.length) : 0;

        const formatExperience = (experience: string): string => {
            const [years, months] = experience.split(' ').filter(Boolean);
            const monthsValue = parseInt(months, 10);
            if (years === '0' && monthsValue === 1) {
                return '1 Month';
            }
            return experience;
        };
      
       

    return (
        <>
            <Paper
                elevation={0}
                sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99, background: 'white', height: 'auto' }}
            >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Box sx={{ mt: "4px", fontSize: "21px", fontWeight: "500" }}>Workforce Specializations</Box>
                    <Box>
                        <SearchBar
                            callBackFunction={triggerSearch}
                            value={searchValue}
                        />
                    </Box>
                </Stack>

                <Grid
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ mt: 1.5 }}
                >
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "strech", md: "center" }}
                        justifyContent={{ xs: "flex-start", md: "space-between", lg: "flex-end" }}
                        spacing={2}
                        sx={{ pb: 3 }}
                    >
                        <Box >
                            <Autocomplete
                                value={
                                    departments?.find(
                                        (option) => option.id == departmentsId
                                    ) ?? null
                                }
                                disabled={disabledDepartments}
                                fullWidth
                                id="department-drop"
                                options={departments}
                                getOptionLabel={(option) => option.name}
                                onChange={async (event, value) => {
                                    if (value) {
                                        setfiltersSelected(true);
                                        setdepartmentsId(value?.id ?? "")
                                        setResourceId("");
                                        setresources(
                                            await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="department-drop"
                                        name="departmentsId"
                                        variant="outlined"
                                        value={departmentsId}
                                        disabled={disabledDepartments}
                                        fullWidth
                                        sx={{ minWidth: 220 }}
                                    />
                                )}
                            />
                        </Box>
                        <Box sx={{ mr: "20px" }}>
                            <Autocomplete
                                disabled={!departmentsId || departmentsId === ""}
                                value={
                                    resources?.find(
                                        (option) => option.userId == resourceId
                                    ) ?? null
                                }
                                sx={{ mr: "20px" }}
                                fullWidth
                                id="resources-drop"
                                options={resources}
                                getOptionLabel={(option) => option.userName}
                                onChange={async (event, value) => {
                                    setfiltersSelected(true);
                                    setResourceId(value?.userId ?? "")
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="resources-drop"
                                        name="resourceId"
                                        variant="outlined"
                                        value={resourceId}
                                        fullWidth
                                        sx={{ minWidth: 220 }}
                                    />
                                )}
                            />

                        </Box>
                        <Button
                            onClick={() => {
                                if (!departmentsId) {
                                    toast.warn("Please select Department first!")
                                }
                                else {
                                    getSkillsetReports(departmentsId, resourceId, searchValue)
                                    setPage(0);
                                }
                            }}
                            color="primary"
                            variant="contained"
                            sx={{ ml: 0.5 }}
                        >  Go </Button>
                        {filtersSelected &&
                            <Button
                                onClick={async () => {
                                    setfiltersSelected(false);
                                    setResourceId("");
                                    setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                                    setSearchValue("");
                                    setSkillSets([]);
                                    if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                                        await setdepartmentsId(_authUser.employeeInfo?.departmentId);
                                        setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo?.departmentId));
                                        getSkillsetReports(_authUser.employeeInfo?.departmentId, "", "")
                                    }
                                    else {
                                        setdepartmentsId("");
                                        setresources(await clsResourcesList.getResourcesDropdownList(""));
                                    }

                                }}
                                color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                    </Stack>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={1}
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        justifyContent="flex-end"
                        sx={{ mt: 1, ml: 'auto' }}
                    >

                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}

                        >
                            <FiberManualRecordIcon
                                sx={{ color: '#ffc409' }}
                            />
                            Beginner
                        </Typography>



                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}

                        >
                            <FiberManualRecordIcon
                                sx={{ color: "#2dd36f" }}
                            />
                            Intermediate
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer", // Add this to indicate it is clickable
                            }}

                        // component="button" // This makes it act as a button
                        >
                            <FiberManualRecordIcon
                                sx={{ color: "#1DA7FF" }}
                            />
                            Expert
                        </Typography>

                    </Stack>
                </Grid>
                {skillSets && skillSets.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table aria-label="simple table" size="small" sx={{ position: 'relative', overflowX: 'scroll' }}>
                    <TableHead sx={headcellcolor.cellcolor}>
                        <TableRow>
                            <TableCell align="left" sx={{ position: 'sticky', left: '0', width: '40px !important', background: 'rgb(189, 189, 189)' }}>S.No</TableCell>
                            <TableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: 'rgb(189, 189, 189)' }}>Emp ID</TableCell>
                            <TableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: 'rgb(189, 189, 189)' }}>Name</TableCell>
                            <TableCell align="left">Experience</TableCell>
                            <TableCell align="left">Project</TableCell>
                            <TableCell align="left">Skill Set</TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {skillSets?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user: any, idx: any) => (
                            <TableRow
                                key={idx} 
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 0 }, borderBottom: "1px solid rgba(224, 224, 224, 1)"
                                }}
                            >
                                <TableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }}>{idx + 1 + page * rowsPerPage}</TableCell>
                                <TableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }}>{user?.empId}</TableCell>
                                <TableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: '#fff' }}>{user?.name}</TableCell>
                                <TableCell align="left">{formatExperience(user?.experience)}</TableCell>
                                <TableCell align="left">{user?.projectsList}</TableCell>
                                <TableCell>
                                    {user?.skillSets.length > 0 ? (
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: "wrap", width: "200px" }}>
                                            {user.skillSets.map((skillSetItem: any, index: number) => {
                                                const { level } = skillSetItem;
            
                                                const backgroundColor = level === 'expert' ? '#eaf7ff'
                                                    : level === 'beginner' ? '#fffaea'
                                                        : level === 'intermediate' ? '#E7FFF0'
                                                            : '#757575';
            
                                                const borderColor = level === 'expert' ? '#1DA7FF'
                                                    : level === 'beginner' ? '#ffc409'
                                                        : level === 'intermediate' ? '#2dd36f'
                                                            : '#757575';
            
                                                return (
                                                    <div
                                                        key={index} 
                                                        style={{
                                                            color: borderColor,
                                                            fontWeight: '400',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '12px',
                                                            border: `1px solid ${borderColor}`,
                                                            padding: '4px 12px',
                                                            borderRadius: '4px',
                                                            width: "fit-content",
                                                            background: backgroundColor,
            
                                                        }}
                                                    >
                                                        {`${skillSetItem.skillSet} `}
                                                    </div>
                                                );
                                            })}
                                        </Box>
                                    ) : (
                                        <Box>
                                            --
                                        </Box>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {/* Render emptyRows */}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            
                ) : (
                    <NotFound NotfoundText="No Record Found" />
                )}
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={skillSets?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={() => `Total: ${skillSets?.length}  Page ${page + 1} of ${pageCount}`}


                />
            </Paper>
        </>
    );
}
