import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TablePagination from "@mui/material/TablePagination";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Avatar, Chip, Divider, Modal, Tooltip } from "@mui/material";
import { toast } from "material-react-toastify";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Formik, getIn } from "formik";
import { format } from "date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import { debug } from "console";
import NotFound from "../../components/styledComponent/NotFound";
import { clsDepartmentList } from "../../service/common-services/department";
import InfoPopover from "../../components/CommonInfoComponent";
import { masterreasonServices } from "../../service/BrowniePoints/masterReason-services";
import { Manager } from "../../Interfaces/IManager";
import { clsBrowniePointsList } from "../../service/common-services/browniePoints";
import { brownieemployeeservice } from "../../service/master-service/brownieEmployee-service";
import theme from "../../theme/theme";
import { milestoneDateExtendService } from "../../service/milestoneDateExtend-service";
import { eMasterDropdown } from "../../Enum/eMasterDropdown";
import { commonServive } from "../../service/common-services/service";
import Userlisttop from "../users/userliststyle";

const styleCross = {
  position: "absolute" as "absolute",
  right: "15px",
  top: "15px",
};

const options = ["Select Points", "Brownie Points", "Muffin Points"];

const styles = {
  view: {
    label: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#454545",
    },
    value: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      color: theme.palette.primary.dark,
    },
  },
};

interface Department {
  id: string;
  name: string;
}

interface IBrowniePointsReason {
  id: string;
  reasonName: string;
  description: string;
  pointsForReason: number;
}

interface getPointInter {

  milestoneId: string;
  milestoneName: string;
  projectName: string;
  startDate: string;
  endDate:string;
  projectId: string;
  lastMileStoneDate: string;
  newMileStoneDate: string;
  status: number;
  comment: string;
  reason: string;

}

interface getRedeemInter {
  employeeId: string;
  name: string;
  manager: string;
  department: string;
  pointType: string;
  totalEarnedPoint: string;
  userId: string;
  totalUnredeemPoint: string;
  pointToRedeem: string;
  valueOfPointToRedeem: string;
  status: string;
  requestedOn: string;
  paidOn: string;
}

interface addRedeemmPoints {
  pointType: string;
  totalEarnedPoint: string;
  userId: string;
  totalUnredeemPoint: string;
  pointToRedeem: string;
  valueOfPointToRedeem: any;
  departmentId:any;
  managerId:any;
}

interface reqrewrdsPoints {
  departmentId: string;
  userId: string;
  referedUserName: string;
  reason: string;
  comment: string;
}

enum eMilestoneStatus {
  "All" = "5",
  "Approved" = "3",
  "Rejected" = "2"
}

export default function MilestoneDateExtend() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const [valueTab, setValue] = React.useState("1");
  const [selectedComment, setSelectedComment] = React.useState("");
  const [redeemHistoryyy, setRedeemHistoryyy] = useState<getRedeemInter[]>([]);
  const [openView, setOpenView] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const [getMilestonesExtend, setMilestonesExtend] = useState<[]>([]);
  const [open, setOpen] = useState(false);
  const [rejectComment, setRejectComment] = React.useState("");
  const [projects, setProject] = useState<any>([]);
  const [selectedProjectId, setProjectId] = useState("");
  const eMilestoneStatusFilter = Object.keys(eMilestoneStatus);
  const [milestoneStatus, setMilestoneStatus] = React.useState("All");
  const [milestoneRecordId, setMilestoneRecordId] = React.useState("");
  const [milestoneRejectComment, setMilestoneRejectComment] = React.useState("");
  const [openModal, setModalOpen] = React.useState(false);

  const depData= useRef<any>()
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
     const numericValue = parseInt(newValue, 10);
    getMilestoneExtendDates("",numericValue);
    setValue(newValue);
    setProjectId("0");
  };

  const handleClick = (event: { currentTarget: { disabled: boolean; }; }) => {
    event.currentTarget.disabled = false;
  };
 console.log("selectedComment===", selectedComment)
 




  const handleClickOpenView = () => {
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const handleRejectDialog = (recordData : any) => {
    setOpen(true);

    setMilestoneRecordId(recordData.id);

  }

  const handleApproveModal = (recordData : any) => {
    setModalOpen(true);
    setMilestoneRecordId(recordData.id);
  }

  const handleClose = () => {
    setOpen(false);
    setRejectComment("");
};


const handleCloseModal = () => {
  setModalOpen(false);
}


  useEffect(() => {
    if (_permission.page_Emplyoyee_Brownie_Access !== true) {
      navigate("/dashboard");
    }
 
    getMilestoneExtendDates("",1);
   
   

    const GetAsyncData = async () => {
      depData.current = await (await clsDepartmentList.getDepartmentDropdownList()).filter((item : any) => 
        _authUser.employeeInfo.departmentId.some((deptId:any) => item?.id === deptId));
     
      setDepartments( depData.current);
      if(depData.current.length>1){
        setdisabledDepartments(false);
       
    }
    else{
    setdisabledDepartments(true);}
     

    };
    GetAsyncData();
    getProjectsDropdown();
   
  }, []);

  console.log("depData.current ====", depData.current)


  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const pageCount = Math.ceil(getMilestonesExtend.length / rowsPerPage);
  const pageCount1 = Math.ceil(getMilestonesExtend.length / rowsPerPage1);
  var projectData = { id: "0", name: "Select Projects" };  


  const getMilestoneExtendDates = async (projId: string,id: number) => {
    var input = {
      projectId: projId,
      status: id,
    };

    await milestoneDateExtendService.getMilestoneExtendDates(input).then((response:any) => {
      if(response.data.isError){
      } else{
        console.log(response.data);
        setMilestonesExtend(response.data);
      }
    })    
  };

  const rejectMilestoneDate = async (data: any) => {
    var input = {
      requestId: milestoneRecordId,
      status: 2,
      comment: data.comment
    };

    await milestoneDateExtendService.updateExtendMilestoneDate(input).then((response:any) => {
      if(response.data.isError){
      } else{
        setOpen(false);
        getMilestoneExtendDates("",1);
      }
    })    
  };

  const approveMilestoneDate = async () => {
    var input = {
      requestId: milestoneRecordId,
      status: 3,
      comment: ""
    };

    await milestoneDateExtendService.updateExtendMilestoneDate(input).then((response:any) => {
      if(response.data.isError){
      } else{
        setModalOpen(false);
        getMilestoneExtendDates("",1);
      }
    })    
  };

  const getProjectsDropdown = async () => {
    let prm = { module: eMasterDropdown.Project, status: "active" };
    await commonServive.getDropdownData(prm).then(async (response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let data = [];
        data.push(projectData);
        response.data.map((dep:any) => {
          data.push(dep);
        })
        setProject(data);
        var projectId = data[0].id;
        setProjectId(projectId);
     
      }
    });
  };

 
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof getPointInter>("milestoneName");
  const [redeemorderBy, setRedeemOrderBy] =
    React.useState<keyof getRedeemInter>("name");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
 
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - getMilestonesExtend.length) : 0;

  const redeememptyRows =
    page > 10
      ? Math.max(0, (1 + page) * rowsPerPage - redeemHistoryyy.length)
      : 0;

  type Order = "asc" | "desc";

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof getPointInter
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };  

  const fieldValidationSchema = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
  })

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Point</Typography>
        <Typography color="text.primary">Employee Brownie</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>

        <Stack
          direction={{xs:'column', sm:'row'}}
          spacing={2}
          justifyContent="space-between"
          alignItems={{xs:'strech', sm:"center"}}
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
            Extend Date Request
          </Typography>

          <Box>
                
              <InfoPopover  onInput={"Brownie Points"}/>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
          <Stack direction={{xs:'column', sm:'row'}}  alignItems={{xs:'strech', sm:"center"}}  spacing={2}>

            <Box sx={{ width: '30vh', mt: { xs: "20px", md: "0px" }, }}>
            <Autocomplete
                      
                      value={
                        projects?.find(
                          (option: any) => option.id == selectedProjectId
                        ) ?? ""
                      }
                      fullWidth
                      sx={{
                        lineHeight: "0.80em",
                      }}
                      id="outlined-basic"
                      options={projects}
                      getOptionLabel={(option) => option.name}
                      onChange={async (event, value) => {
                        if(value){
                          setProjectId(value?.id ?? "0");
                          if(valueTab == "5"){
                            getMilestoneExtendDates(value?.id, 5);
                            setMilestoneStatus("All");
                          } else {
                            getMilestoneExtendDates(value?.id, 1);
                          }
                        } else{
                          setProjectId("0");
                          if(valueTab == "5"){
                            getMilestoneExtendDates("",5);
                            setMilestoneStatus("All");
                          } else {
                            getMilestoneExtendDates("",1);
                          }
                        }
                        setPage(0);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          name="element.name"
                          variant="outlined"
                          value={selectedProjectId}
                        />
                      )}
                    />
                            </Box>

                          {valueTab == "5" && ( 
                            <Box sx={{ width: '20vh'}}>
                 
                 <TextField
                               id="Statusfilter"
                               variant="outlined"
                               value={milestoneStatus}
                               select
                               fullWidth
                             >
                               {eMilestoneStatusFilter.map((key, idx) => (
                                 <MenuItem
                                   key={key}
                                   value={key}
                                   onClick={(event) => {
                                    if(selectedProjectId !== "0"){
                                      if(idx === 1){
                                        getMilestoneExtendDates(selectedProjectId,3);
                                      } else if (idx === 2){
                                        getMilestoneExtendDates(selectedProjectId,idx);
                                      } else{
                                        getMilestoneExtendDates(selectedProjectId,5);
                                      }
                                    } else {
                                      if(idx === 1){
                                        getMilestoneExtendDates("",3);
                                      } else if (idx === 2){
                                        getMilestoneExtendDates("",idx);
                                      } else{
                                        getMilestoneExtendDates("",5);
                                      }
                                    }
                                    setMilestoneStatus(key);
                                   }}
                                 >
                                   {key}
                                 </MenuItem>
                               ))}
                             </TextField>
                  </Box>
                  )}

          </Stack>
        </Stack>
        <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Pending Request" value="1" />
                <Tab label="History" value="5" />
              </TabList>

            </Box>
            <TabPanel value="1" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Project</TableCell>
                      <TableCell> Milestone Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Requested Date</TableCell>
                      <TableCell>Reason</TableCell>

                      <TableCell align="right"> Action &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(getMilestonesExtend, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((detail, idx) => {
                        return (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                          
                            <TableCell>{detail?.projectName}</TableCell>
                            <TableCell>{detail?.milestoneName}</TableCell>
                            <TableCell>
                              {`${moment(detail?.startDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>
                              {`${moment(detail?.endDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>
                              {`${moment(detail?.newMileStoneDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>{detail?.reason}</TableCell>
                           
                            <TableCell align="right">                               
                               <Chip variant="approved" label="Approve" onClick={() => handleApproveModal(detail)}/>
                               <Chip variant="hold" label="Reject" onClick={() => handleRejectDialog(detail)}/>
                            </TableCell>
                          </TableRow>
                        );
                        {
                          /* ))} */
                        }
                        
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {getMilestonesExtend.length === 0 && (
                  <NotFound NotfoundText="No Result Found" />
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={getMilestonesExtend.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${getMilestonesExtend?.length}  Page ${page + 1} of ${pageCount}`}



              />
            </TabPanel>
            <TabPanel value="5" sx={{ px: 0 }}>
              <TableContainer component={Paper} elevation={0}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Project</TableCell>
                      <TableCell>Milestone Name </TableCell>
                      {/* <TableCell>Point Type</TableCell> */}
                      <TableCell>Start Date</TableCell>
                      <TableCell> End Date</TableCell>
                      <TableCell>Requested Date</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Comment</TableCell>
                      <TableCell> &nbsp; &nbsp; &nbsp; Status</TableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                 
                    {stableSort(getMilestonesExtend, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((redeemDetail, idx) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{redeemDetail?.projectName}</TableCell>
                            <TableCell>{redeemDetail?.milestoneName}</TableCell>
                           
                            <TableCell>
                              {`${moment(redeemDetail?.startDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>
                              {`${moment(redeemDetail?.endDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>
                              {`${moment(redeemDetail?.newMileStoneDate).format(
                                "DD-MMM-YYYY"
                              )}`}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.reason}
                            </TableCell>
                            <TableCell>
                              {redeemDetail?.comment}
                            </TableCell>
                            <TableCell>
                              {parseInt(redeemDetail?.status)===2 && <Chip variant="hold" label="Rejected" />}
                              {parseInt(redeemDetail?.status)=== 3 && <Chip variant="approved" label= "Approved" />}</TableCell>
                          </TableRow>
                        );
                        {
                          /* ))} */
                        }
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {getMilestonesExtend.length === 0 && (
                  <NotFound NotfoundText="No Result Found" />
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={getMilestonesExtend.length}
                rowsPerPage={rowsPerPage1}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Total: ${getMilestonesExtend?.length}  Page ${page + 1} of ${pageCount1}`}

              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>

     

      <Dialog
                open={open}
                sx={{ "& .MuiDialog-paper": { overflow: "visible", height: "unset" } }}
                fullWidth maxWidth="sm"
            >
               <Formik
          initialValues={{
            comment: rejectComment
          }}
          validationSchema={fieldValidationSchema}
          enableReinitialize={true}
          onSubmit={async (values) => {
            try {
          
            
           
               rejectMilestoneDate(values);
        
           
            } catch (ex: any) {
              ex.data.errors.map((err: any, idx: any) => {
                toast.warning(err);
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={ handleSubmit}>
        <IconButton aria-label="delete" sx={styleCross} onClick={handleClose}>
          <ClearIcon />
        </IconButton>
        <DialogTitle>Provide Reason for Rejection</DialogTitle>
        
        <DialogContent sx={{maxHeight: '400px'}}>
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            <Grid item xs sm={12}>
              
            </Grid>
            <Grid item xs sm={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{gap: "8px"}}
              >
                <Box sx={{ width: "50%" }}>
                </Box>
                <Box sx={{ width: "50%" }}>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs sm={12} >

           
            </Grid>

            <Grid item xs sm={12}>
              <TextField
                required
                id="name"
                name="comment"
                label="Comment"
                type="email"
                fullWidth
                value={rejectComment}
                sx={{
                  "word-break": "break-all",
                  "line-height": "1.5",  
                  "padding": "10px",    
                  "& .MuiInputBase-root": { 
                      "line-height": "1.5",
                  },
                  "& .MuiOutlinedInput-root": {  
                      "line-height": "1.5",
                      "padding": "10px", 
                  }
              }}
                onChange={(e)=>{
                  let abc  = e.currentTarget.value;
                  setRejectComment(abc);
                  values.comment = abc
                }}
                onBlur={handleBlur}
                error={Boolean(
                    getIn(touched, "comment") && getIn(errors, "comment")
                  )}
                  helperText={
                    getIn(touched, "comment") && getIn(errors, "comment")
                  }
                multiline
                rows={4}
                //variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
        <Button variant="contained" type="submit">
          Submit
          </Button>
        </DialogActions>
        </form>
          )}
        </Formik>
            </Dialog>


            <Modal
            open={openModal}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Userlisttop.actionpopup}>
              <Paper variant="outlined" sx={{ p: 4 }}>
                <Typography
                  id="modal-modal-title"
                  align="center"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: 700 }}
                >
                  Approve Milestone Extension
                </Typography>
                <Typography
                  id="modal-modal-description"
                  align="center"
                  sx={{ mt: 2 }}
                >
                  Are you sure, you want to approve this milestone date extension. Continue?
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 3 }}
                >
                  <Button variant="outlined" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={approveMilestoneDate}>
                    Confirm
                  </Button>
                </Stack>
              </Paper>
            </Box>
          </Modal>

     
      {/* //----------------------------------------------------------- */}

      <Dialog
        open={openView}
        onClose={setOpenView}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton sx={styleCross} onClick={handleCloseView}>
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Recommend Points
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs sm={2}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 92, height: 92 }}
              />
            </Grid>
            <Grid item xs>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Emp Id 1025
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Nancy Martino
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Total Earned Points
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    100 points
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Value
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Rs. 1000
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Redeemed
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    50 Points
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
