import { http } from "./http-common";
class ChildIssueServices {
   
    createChildIssue = async (depart: any): Promise<any> => {
        return await http.post<any>("/v1/Ticket/ChildIssues", depart);
    };
    deleteChildIssue = async (childIssueId: any,mainTicketId: any): Promise<any> => {
        return await http.delete<any>(`v1/Ticket/DeleteIssuesById?ChildIssueId=${childIssueId}&mainTicketId=${mainTicketId}`);
    };
}
export const childIssueServices = new ChildIssueServices();