import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {Button, Checkbox, Chip, IconButton, MenuItem, Modal, TableCell, TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Box from "@mui/material/Box";
import { toast } from "material-react-toastify";
import React from "react";
import { Formik} from "formik";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { AttendanceStatusFilter } from "../../../Enum/eLeaveStatus";
import StyledTableCell from "../../../components/styledComponent/CustomTableCell";
import StyledRejectButton from "../../../components/styledComponent/StyledRejectButton";
import StyledAcceptButton from "../../../components/styledComponent/StyledAcceptButton";
import { reportService } from "../../../service/Reports/reports";
import * as Icons from "../../../assests/icons/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
};

export default function AttendanceReportSheet() {
    const _permission = useSelector((state: any) => state.LeavePermissionApp.LeavePermission);
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [data1, setData1] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPage1, setRowdatasPerPage1] = React.useState(10);
    const pageCount1 = Math.ceil(data1?.length / rowsPerPage1);
    const pageCount = Math.ceil(data?.length / rowsPerPage);
    const [dense, setDense] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [page1, setPage1] = React.useState(0);
    const [statusFilter, setStatusFilter] = React.useState("All");
    const logstatusFilter = Object.keys(AttendanceStatusFilter);
    const [iconDisable, setIconDisable] = React.useState(false);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage1 = (event: any) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRowdatasPerPage1(selectedValue);
        setPage1(0);
      };
    const handleChangePage1 = (event: unknown, newPage: number) => {
        setPage1(newPage);
    };
    const emptyRows =
        page > 10 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;
    ///////Accept Reject Multiple Offline hours programmming//////////////
    const [rowCount, setRowCount] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const [value, setValue] = React.useState("1");
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const [selected, setSelected] = React.useState<readonly string[]>([]);

    /////Approve Or Reject The User Data//////////////
    const ApproveReject = async (status: any) => {
        if (selected.length === 0) {
            return toast.warning("Please select before updating the status");
        }
        try {
            const selectedDataList = selected.map((selectedItemId: any) => {
                const selectedData = data.find((item: any) => item.id === selectedItemId);
    
                let attendanceStatus;
                if (logStatus === "Reject") {
                    attendanceStatus = 3;
                } else {
                    attendanceStatus = 2;
                }
                return {
                    id: selectedData.id,
                    userId: selectedData.userId,
                    absentDate: selectedData.absentDate,
                    attendanceStatus: attendanceStatus,
                };
            });
            await reportService.approveAndRejectUsersAttendance(selectedDataList).then((response: any) => {
                if (response.data.isError) {
                    // Handle error if needed
                } else {
                    if (logStatus === "Reject") {
                        toast.warn("Requests Rejected successfully");
                    } else {
                        toast.success("Requests Approved successfully");
                    }
                    setOpen(false);
                }
    
                setlogStatus("");
                getAllRequests(1);
                setSelected([]);
                setIconDisable(false);
                setRowCount(0);
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setSelected([]);
                setlogStatus("");
                setRowCount(0);
            });
        }
    };
    

    const handleClick = (
        event: React.ChangeEvent<HTMLInputElement> | any,
        id: string) => {
        if (event.target.checked) {
          setRowCount(rowCount + 1);
          setSelected([...selected, id]);
        } else {
          setRowCount(rowCount - 1);
          setSelected([...selected].filter(x => x != id));
        }
      };

    ////////Get All Data For Attendance Data ////////////
    const getAllRequests = (status:any) => {
        setPage(0);
        setPage1(0);
        let abc ={
            attendanceRequest: status?status:0
        }
        reportService
            .allUsersAttendanceRequestData(
               abc
            )
            .then((response: any) => {
                if (response.data.isError) {
                } else {
                    if(status===1){
                        let abc = response?.data ? response?.data?.filter((a: any) => a?.attendanceRequest === 1):[]
                        setData(abc);
                    }
                    else{
                        let dataAbc = response?.data ? response?.data?.filter((a: any) => a?.attendanceRequest === 2 || a?.attendanceRequest === 3):[]
                        setData1(dataAbc);
                    }
                }
                setLoading(false);
            });
    }
    const handleSaveWorkHour = (values: any) => {
        debugger
        let value = [
            {
                id: values.id,
                userId: values.userId,
                absentDate: values.absentDate,
                attendanceStatus: logStatus === "Reject" ? 3 : 2,
            },
        ];
        if (values.id) {
            setLoading(true);
            reportService
                .approveAndRejectUsersAttendance(
                   value
                )
                .then(async (response: any) => {
                    if (response?.data?.isError) {
                        toast.warn("Something Went Wrong, Please try again.");
                        setLoading(false);
                    } else if (logStatus === "Approved") {
                        await getAllRequests(1);
                        toast.success("Request Approved successfully");
                    } else {
                        await getAllRequests(1);
                        toast.warn("Request Rejected successfully");
                        setlogStatus("");
                    }
                    setOpen(false);
                    setlogStatus("");
                    setSelectedId("");
                });
        }
    };

    useEffect(() => {
        if (_permission.page_Attendance_Request_Access !== true) {
            navigate("/dashboard");
          }
        getAllRequests(1);
    }, []);

    const getLogSheetList = () => {
        // return data
        //     ?.filter((a: any) => a?.attendanceRequest === 1).slice(
        //         page * rowsPerPage,
        //         page * rowsPerPage + rowsPerPage
        //     )
        return data
        ?.filter((a: any) => a?.attendanceRequest === 1)
        .sort((a:any, b:any) => {
          const dateA = new Date(String(a.createdDate));
          const dateB = new Date(String(b.createdDate));
          return dateB.getTime() - dateA.getTime();
        })
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            debugger
            var all =   data.map((item:any) => item.id); // Extracting all ids from data array
            setRowCount(all.length);
            setSelected(all);
        
        } else
        {
            setSelected([]);
            setRowCount(0);
        }
    };
    const getLogSheetList1 = () => {
        return data1?.slice(
                page1 * rowsPerPage1,
                page1 * rowsPerPage1 + rowsPerPage1
            )
    }

    const [selectedId, setSelectedId] = React.useState<any>("");
    console.log(selectedId,"selectedId")

    const [open, setOpen] = React.useState(false);
    const [logStatus, setlogStatus] = React.useState("");
    const handleClose = () => {
        setOpen(false);
        setlogStatus("");
        setSelectedId("");
        setIconDisable(false);
    };
    const handleClickOpen = (status: any) => {
        setIconDisable(true);
        if (selected.length === 0) {
            return toast.warning("Please select before update the status", {
                onOpen: () => setIconDisable(true),
                onClose: () => setIconDisable(false),
            });
        }
        setOpen(true);
    }
  
    return (
        <>
            <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ pb: 3 }}
                >
                    <Stack direction="row">
                        <Typography variant="h5" component="h2" sx={{ fontWeight: 600, mr: 3 }}>
                            User Attendance Report
                        </Typography>
                    </Stack>

                    {/* <Stack direction="row" spacing={2}>
                        <SearchBar
                            setSearchValue={setSearchValue}
                            callBackFunction={triggerSearch}
                        />
                    </Stack> */}
                </Stack>

              

                <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab label="Pending" value="1" onClick={()=>{getAllRequests(1); setPage(0); setPage1(0)}}/>
                                <Tab label="History" value="2" onClick={()=>{getAllRequests(0); setPage(0); setPage1(0)}}/>
                                {value == "2" && (
                                    <Box sx={{ position: "absolute", right: '0', top: '0', width: "150px" }} >
                                        <TextField
                                            id="Statusfilter"
                                            variant="outlined"
                                            select
                                            value={statusFilter}
                                            fullWidth
                                        >
                                            {logstatusFilter.map((key, idx) => (
                                                <MenuItem
                                                    key={key}
                                                    value={key}
                                                    onClick={(event) => {
                                                        setStatusFilter(key);
                                                        getAllRequests(key ==="Approved" ? 2 :key==="Pending"? 1 :key==="Reject"?3 :0)
                                                        // handleChangeLeaveStatusFilter(key)
                                                    }}
                                                >
                                                    {key}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                )}
                            </TabList>

                        </Box>
                        <TabPanel value="1" sx={{ px: 0 }}>
                        {_permission.tab_Leave_AcceptReject_Access &&(
                            <Box>
                                <Stack direction={'row'} spacing={2}>
                                    <IconButton disabled={!!iconDisable} onClick={() => { (handleClickOpen("Approved")); setlogStatus("Approved") }}>
                                        <Icons.TickCircle />
                                    </IconButton>
                                    <IconButton disabled={!!iconDisable} onClick={() => { handleClickOpen("Reject"); setlogStatus("Reject") }}>
                                        <Icons.CrossCircle />
                                    </IconButton>
                                </Stack>
                            </Box>)}

                            <TableContainer component={Paper} elevation={0}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead >
                                        <TableRow>
                                        {_permission.tab_Leave_AcceptReject_Access &&(
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={rowCount > 0 && rowCount < data?.length}
                                                    checked={rowCount > 0 && data?.length == rowCount}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{

                                                        "aria-label": "select all desserts",
                                                    }}
                                                />
                                            </TableCell>)}
                                            <TableCell>Sr. No</TableCell>
                                            <TableCell align="left">UserName</TableCell>
                                            <TableCell align="left">Request Date</TableCell>
                                            <TableCell align="left">Absent Date </TableCell>
                                            <TableCell align="left">Reason</TableCell>
                                            {_permission.tab_Leave_AcceptReject_Access &&(
                                            <TableCell align="left">Status</TableCell>)}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {/*  */}
                                        {!loading ? (
                                              data?.length > 0 ? (
                                                getLogSheetList()
                                                  .map((log: any, index: number) => {
                                                    const serialNumber = (page * rowsPerPage) + index + 1;
                                                    const isItemSelected = isSelected(log.id);
                                                    const labelId: any = `enhanced-table-checkbox-${index}`;
                                                    const sno =
                                                      page * rowsPerPage +
                                                      index +
                                                      1;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) =>
                                                                handleClick(event, log?.id)
                                                            }
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={log.id}
                                                            selected={false}
                                                        >
                                                               {_permission.tab_Leave_AcceptReject_Access &&(
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={log.attendanceRequest === 1 ? isItemSelected : true}
                                                                    // disabled={log.status === 1 ? false : true}
                                                                    inputProps={{
                                                                        "aria-labelledby": labelId,
                                                                    }}
                                                                />
                                                            </TableCell>)}
                                                            <TableCell component="th" scope="row">
                                                                {sno}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {" "}
                                                                {log?.userName}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {`${moment(
                                                                    log?.createdDate
                                                                ).format("DD MMM, YYYY")}`}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {`${moment(
                                                                    log?.absentDate
                                                                ).format("DD MMM, YYYY")}`}

                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {log?.reason}

                                                            </TableCell>

                                                            {_permission.tab_Leave_AcceptReject_Access &&(
                                                            <StyledTableCell align="left">
                                                                <Stack direction="row" spacing={2}>
                                                                    <StyledAcceptButton
                                                                        Status="Accept"
                                                                        onClick={() => {
                                                                            setlogStatus("Approved");
                                                                            setSelectedId(log);
                                                                            setOpen(true);
                                                                        }}
                                                                    />
                                                                    <StyledRejectButton
                                                                        Status="Reject"
                                                                        onClick={() => {
                                                                            // handleSaveWorkHour(
                                                                            //   log?.id,
                                                                            //   log?.description,
                                                                            //   "reject"
                                                                            // )
                                                                            setlogStatus("Reject");
                                                                            setSelectedId(log);
                                                                            setOpen(true);
                                                                        }
                                                                        }
                                                                    />
                                                                </Stack>
                                                            </StyledTableCell>)}
                                                        </TableRow>
                                                    )
                                                })
                                            ) : (
                                                <TableRow>
                                                    <StyledTableCell colSpan={8}>
                                                        No Record Found
                                                    </StyledTableCell>
                                                </TableRow>
                                            )
                                        ) : (
                                            ""
                                        )}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20, 25]}
                                component="div"
                                count={data?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={() => `Total: ${data?.length}  Page ${page + 1} of ${pageCount}`}
                            />
                        </TabPanel>
                        <TabPanel value="2" sx={{ px: 0 }}>
                            <TableContainer component={Paper} elevation={0}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead >
                                        <TableRow>
                                           
                                            <TableCell>Sr. No</TableCell>
                                            <TableCell align="left">UserName</TableCell>
                                            <TableCell align="left">Request Date</TableCell>
                                            <TableCell align="left">Absent Date </TableCell>
                                            <TableCell align="left">Reason</TableCell>
                                            <TableCell align="left">Status</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>

                                        {!loading ? (
                                            data1?.length > 0 ? (
                                                getLogSheetList1()
                                                  .map((log: any, index: number) => {
                                                    const isItemSelected = isSelected(log.id);
                                                    const labelId: any = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) =>
                                                                handleClick(event, log.id)
                                                            }
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={log.id}
                                                            selected={false}
                                                        >

                                                            <TableCell component="th" scope="row">
                                                            {page1 * rowsPerPage1 + index + 1}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {" "}
                                                                {log?.userName}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {`${moment(
                                                                    log?.createdDate
                                                                ).format("DD MMM, YYYY")}`}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {`${moment(
                                                                    log?.absentDate
                                                                ).format("DD MMM, YYYY")}`}

                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {log?.reason}

                                                            </TableCell>

                                                            <StyledTableCell align="left">
                                                                {log?.attendanceRequest === 2 && (<Chip label="Approved" variant="approved" />)}
                                                                {log?.attendanceRequest === 3 && (<Chip label="Rejected" variant="hold" />)}
                                                            </StyledTableCell>

                                                        </TableRow>
                                                    )
                                                })
                                            ) : (
                                                <TableRow>
                                                    <StyledTableCell colSpan={8}>
                                                        No Record Found
                                                    </StyledTableCell>
                                                </TableRow>
                                            )
                                        ) : (
                                            ""
                                        )}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20, 25]}
                                component="div"
                                count={data1?.length}
                                rowsPerPage={rowsPerPage1}
                                page={page1}
                                onPageChange={handleChangePage1}
                                onRowsPerPageChange={handleChangeRowsPerPage1}
                                labelDisplayedRows={() => `Total: ${data1?.length}  Page ${page1 + 1} of ${pageCount1}`}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>


            </Paper>

            <Modal
                open={open}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Formik
                    initialValues={{                          
                        attendanceStatus: logStatus === "Reject" ? 3 : 2,

                    }}
                    enableReinitialize
                    // validationSchema={fieldValidationSchema}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (selectedId != "") {
                              await handleSaveWorkHour(selectedId)
                            }
                            else {
                              await ApproveReject(values);
                            }
                          } catch (ex: any) {
                            if (ex.status == 400) {
                                ex.data.Error.map((error: any, idx: any) => {
                                    toast.warn(error);
                                })
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Box sx={style}>
                                <Paper variant="outlined" sx={{ p: 4 }}>
                                    <Typography
                                        id="modal-modal-title"
                                        align="center"
                                        variant="h6"
                                        component="h2"
                                        sx={{ fontWeight: 700 }}
                                    >
                                        {/* Delete Event */}

                                    </Typography>
                                    <Typography
                                        id="modal-modal-description"
                                        align="center"
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        Do you really want to {logStatus} Request, Continue?
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ mt: 3 }}
                                    >

                                        <Button variant="outlined" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" type="submit" >
                                            Confirm
                                        </Button>
                                    </Stack>
                                </Paper>
                            </Box>
                        </form>)}
                </Formik>
            </Modal>
        </>
    );
}
