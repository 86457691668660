
import { combineReducers } from 'redux';
const Fill_Report_Permission = 'Fill_Report_Permission';
const Empty_Report_Permission = 'Empty_Report_Permission';

const defaultPermissions = 
  {
    access: false,
    module_ReportList_Access:false,
    
    page_ManageReportReport_Access: false,
    tab_ReportReport_ReportListing_Access: false,
    action_ReportReport_ReportListing_AllDepartments_Access: false,
    action_ReportReport_ReportListing_AllProjects_Access: false,
    
    page_ManageMilestoneReport_Access: false,
    tab_MilestoneReport_ReportListing_Access: false,
    action_MilestoneReport_ReportListing_AllDepartments_Access: false,
    
    page_ManageAttendenceReport_Access: false,
    tab_AttendenceReport_ReportListing_Access: false,
    action_AttendenceReport_ReportListing_AllDepartments_Access: false,

    page_ManageDailyAttendenceReport_Access: false,
    tab_DailyAttendenceReport_ReportListing_Access: false,
    action_DailyAttendenceReport_ReportListing_AllDepartments_Access: false,

    page_ManageWorkDairyReport_Access: false,
    tab_ManageWorkDairyReport_ReportListing_Access: false,
    action_ManageWorkDairyReport_ReportListing_AllDepartments_Access: false,
    action_ManageWorkDairyReport_ReportListing_Departmentspecific_Access: false,

    page_HrReport_Access: false,
    tab_HrReport_ReportListing_Access: false,
    action_HrReport_ReportListing_AllDepartments_Access: false,

    page_ReserveBrigade_Access: false,
    tab_ReserveBrigade_ReserveBrigadeListing_Access: false,
    action_ReserveBrigade_ReserveBrigadeListing_AllDepartments_Access: false,

    page_OrgChart_Access: false,
    tab_OrgChart_OrgChartListing_Access: false,
    action_OrgChart_OrgChartListing_AllDepartments_Access: false,

    page_SkillSet_Access: false,
    tab_SkillSet_SkillSetReport_Access: false,
    action_SkillSet_SkillSetReport_AllDepartment_Access: false,

    page_UserFeedbackReport_Access: false,
    page_AttendanceReportNew_Access: false,
    tab_AttendanceReportNew_Access:false,
    action_AttendanceReportNew_AllDepartment_Access:false,
    page_SprintReport_Access: false,
    page_BrownieReport_Access : false,
  }

export function FillReportPermission(module) {
  return {
    type: Fill_Report_Permission,
    module,
  }
}
export function EmptyReportPermission() {
  return {
    type: Empty_Report_Permission,defaultPermissions
  }
}

function ReportPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Report_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
                permissions.access=true;
                permissions.module_ReportList_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                    data.listPageMaster.forEach((page) => {
                        if (page.pageName === "Project Report") {
                            permissions.page_ManageReportReport_Access = true;
                              if (page.listTabMaster && page.listTabMaster.length > 0) {
                                page.listTabMaster.forEach((tabs) => {
                                  if (tabs.tabName === "Report Listing") {
                                    permissions.tab_ReportReport_ReportListing_Access = true;
                                    if (
                                      tabs.listActionMaster &&
                                      tabs.listActionMaster.length > 0
                                    ) {
                                      tabs.listActionMaster.forEach((actions) => {
                                        if (actions.actionName === "All Departments") {
                                            permissions.action_ReportReport_ReportListing_AllDepartments_Access = true;
                                        }
                                        if (actions.actionName === "All Projects") {
                                          permissions.action_ReportReport_ReportListing_AllProjects_Access = true;
                                      }
                                      });
                                    }
                                  }
                                });
                              }
                          }
                          if (page.pageName === "Milestone Report") {
                            permissions.page_ManageMilestoneReport_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Report Listing") {
                                    permissions.tab_MilestoneReport_ReportListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_MilestoneReport_ReportListing_AllDepartments_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }
                          if (page.pageName === "Attendance Report") {
                            permissions.page_ManageAttendenceReport_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Report Listing") {
                                    permissions.tab_AttendenceReport_ReportListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_AttendenceReport_ReportListing_AllDepartments_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }
                          if (page.pageName === "Daily Attendance Report") {
                            permissions.page_ManageDailyAttendenceReport_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Report Listing") {
                                    permissions.tab_DailyAttendenceReport_ReportListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_DailyAttendenceReport_ReportListing_AllDepartments_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }
                          if (page.pageName === "Work Diary") {
                            permissions.page_ManageWorkDairyReport_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Work Dairy Listing") {
                                    permissions.tab_ManageWorkDairyReport_ReportListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_ManageWorkDairyReport_ReportListing_AllDepartments_Access = true;
                                      }
                                      if (actions.actionName === "Department Specific") {
                                        permissions.action_ManageWorkDairyReport_ReportListing_Departmentspecific_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }
                          if (page.pageName === "HR Report") {
                            permissions.page_HrReport_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "HR Report Listing") {
                                    permissions.tab_HrReport_ReportListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_HrReport_ReportListing_AllDepartments_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }

                          if (page.pageName === "Reserve Brigade") {
                            permissions.page_ReserveBrigade_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Reserve Brigade Listing") {
                                    permissions.tab_ReserveBrigade_ReserveBrigadeListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_ReserveBrigade_ReserveBrigadeListing_AllDepartments_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }

                          if (page.pageName === "Org Chart") {
                            permissions.page_OrgChart_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Org Chart Listing") {
                                    permissions.tab_OrgChart_OrgChartListing_Access = true;
                                  if (
                                    tabs.listActionMaster &&
                                    tabs.listActionMaster.length > 0
                                  ) {
                                    tabs.listActionMaster.forEach((actions) => {
                                      if (actions.actionName === "All Departments") {
                                        permissions.action_OrgChart_OrgChartListing_AllDepartments_Access = true;
                                      }
                                    });
                                  }
                                }
                              });
                            }
                          }

                          if (page.pageName ==="User Feedback Report"){
                          permissions.page_UserFeedbackReport_Access = true;
                          }

                          if (page.pageName === "Attendance Report New") {
                            permissions.page_AttendanceReportNew_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                              page.listTabMaster.forEach((tabs) => {
                                if (tabs.tabName === "Attendance Report List") {
                                    permissions.tab_AttendanceReportNew_Access = true;
                                    if (
                                      tabs.listActionMaster &&
                                      tabs.listActionMaster.length > 0
                                    ) {
                                      tabs.listActionMaster.forEach((actions) => {
                                        if (actions.actionName === "All Departments") {
                                          permissions.action_AttendanceReportNew_AllDepartment_Access = true;
                                        }
                                      });
                                    }
                                }
                              });
                            }
                          }

                          if (page.pageName ==="Sprint Report"){
                            permissions.page_SprintReport_Access = true;
                            }
                            
                            if (page.pageName === "Skillset Report") {
                              permissions.page_SkillSet_Access = true;
                              if (page.listTabMaster && page.listTabMaster.length > 0) {
                                page.listTabMaster.forEach((tabs) => {
                                  if (tabs.tabName === "Skillset Listing") {
                                      permissions.tab_SkillSet_SkillSetReport_Access = true;
                                    if (
                                      tabs.listActionMaster &&
                                      tabs.listActionMaster.length > 0
                                    ) {
                                      tabs.listActionMaster.forEach((actions) => {
                                        if (actions.actionName === "All Departments") {
                                          permissions.action_SkillSet_SkillSetReport_AllDepartment_Access = true;
                                        }
                                      });
                                    }
                                  }
                                });
                              }
                            }

                            if (page.pageName === "Brownie Report") {
                              permissions.page_BrownieReport_Access = true;
                            }
  
                    })
                }
                return state = permissions;
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_Report_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const ReportPermissionApp = combineReducers({
    ReportPermission
});

export default ReportPermissionApp;