import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, Typography, Autocomplete , TextField, MenuItem} from '@mui/material';
import ReactApexChart from 'react-apexcharts';

 import { HrReportFilter, IHrReportResponse } from "../../Interfaces/IReport";
import { settingManagementServive } from '../../service/setting-board-service';
import { clsDepartmentList } from '../../service/common-services/department';


interface Department {
    id: string;
    name: string;
  }

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '4px',
  textAlign: 'center',
}));

const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
  ];

export default function ProjectMonthlyRevenueReport() {
    const options: any =  {
        series: [1, 2, 2],
        colors: ['#FFC409', '#EB445A', '#2DD36F',  ],
        labels: ['Jan', 'Feb', 'Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
        legend: {
            show: false
          }
    };
 
    const getCurrentMonth = () => {
        const currentMonth = new Date().getMonth() + 1; 
        return currentMonth;
        };
    const [monthStatus, setMonthStatus] = useState<number | undefined>(getCurrentMonth());
    const Months = [
        // {
        //   value: 0,
        //   label: "All Months",
        // },
        {
          value: 1,
          label: "January",
        },
        {
          value: 2,
          label: "February",
        },
        {
          value: 3,
          label: "March",
        },
        {
          value: 4,
          label: "April",
        },
        {
          value: 5,
          label: "May",
        },
        {
          value: 6,
          label: "June",
        },
        {
          value: 7,
          label: "July",
        },
        {
          value: 8,
          label: "August",
        },
        {
          value: 9,
          label: "September",
        },
        {
          value: 10,
          label: "October",
        },
        {
          value: 11,
          label: "November",
        },
        {
          value: 12,
          label: "December",
        },
      ];

    const [departmentList, setDepartmentList] = useState<Department[]>([]);
    const [selectedDepartmentId, setSelectedDepartmentId] = React.useState("");
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    var projectData = { id: "0", name: "Select Projects" };
    const [projectsList, setProjectsList] = React.useState<any[]>([]);
    const [selectedProjectId, setSelectedProjectId] = React.useState("");
    const [reports, setreports] = React.useState<IHrReportResponse>()

    useEffect(() =>{

        departmentsData();
        getProjectDetails();
    },[]);

   
    const departmentsData = async () => {
        setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
      };

      const getProjectDetails = async () => {
        await settingManagementServive.getAllProjectSecure(0).then((response: any) => {
          if (response.data.isError) {
          } else {
            let data = [];
            data.push(projectData);
            response.data.map((dep: any) => {
              data.push(dep);
            });
           
            setProjectsList(response?.data);
          }
        });
      };

      const handleMonthChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        debugger;
        const selectedMonthStatus = event.target.value as number;
        await setMonthStatus(selectedMonthStatus);
       // await getReport(selectedMonthStatus);
    };

  return (
    <Paper elevation={0} sx={{ p: 3, pb: 0 }}>

    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={7}>
            <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
            Project Report</Typography>
            <Stack direction="row"  justifyContent="between" flexWrap="wrap" paddingTop={3} paddingBottom={3} gap="8px">
                      <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={top100Films}
                          sx={{ width: '150' }}
                          renderInput={(params) => <TextField {...params} label="Report Type" InputLabelProps={{ style: { color: '#1DA7FF', border:'0' } }}/>}
                      />
                      <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={top100Films}
                          sx={{ width: '100' }}
                          renderInput={(params) => <TextField {...params} label="2024" InputLabelProps={{ style: { color: '#1DA7FF', border:'1' } }} />}
                      />
                       <TextField
                                    sx={{ minWidth: 220 }}

                                    id="outlined-basic"
                                    select
                                    value={monthStatus}
                                    variant="outlined"
                                    onChange={                                     
                                        handleMonthChange
                                    }
                                >
                                    {Months.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                      <Autocomplete
                             value={
                                departmentList?.find(
                                  (option) => option.id == selectedDepartmentId
                                ) ?? null
                              }
                              sx={{
                                width: "220px",
                                mr: 1,
                                lineHeight: "0.80em",
                              }}
                              fullWidth
                              id="selectedUserId"
                              options={departmentList}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {  
                                setfiltersSelected(true)
                                setSelectedDepartmentId(value?.id ?? "");
                              }} 
                                renderInput={(params) => <TextField {...params} value={selectedDepartmentId}
                                 InputLabelProps={{ style: { color: '#1DA7FF' } }} />}
                            />

                            <Autocomplete
                             value={
                                projectsList?.find(
                                  (option) => option.id == selectedProjectId
                                ) ?? projectData
                              }
                              sx={{
                                width: "220px",
                                mr: 1,
                                lineHeight: "0.80em",
                              }}
                              fullWidth
                              id="selectedProjectId"
                              options={projectsList}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {  
                                setfiltersSelected(true)
                                setSelectedProjectId(value?.id ?? "");
                              }} 
                                renderInput={(params) => 
                                <TextField {...params} value={selectedProjectId} InputLabelProps={{ style: { color: '#1DA7FF' } }} />}
                            />
                      
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Milestone</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Planned Efforts</TableCell>
                        <TableCell>Received</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {/* {rows.map((row) => ( */}
                        <TableRow
                        //   key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell>Milestone 1</TableCell>
                        <TableCell><Typography>Seasia Connect</Typography></TableCell>
                        <TableCell>$200</TableCell>
                        <TableCell>$150</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell sx={{color: '#1F944E', fontSize: '18px'}}>Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{color: '#1F944E', fontSize: '18px'}}>$1400</TableCell>
                        <TableCell sx={{color: '#1F944E', fontSize: '18px'}}>$1300</TableCell>
                        </TableRow>
                        {/* <table></table> */}
                    {/* ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
       
        <Grid xs={5}>
            <Stack pt={4} pb={2} sx={{flexDirection:"row", display: "flex", justifyContent: "space-between"}}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }} component="h1">Revenue Report</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Box py={1} sx={{marginRight: '50px'}}>
                                        <Stack spacing={2} direction="row" alignItems="top">
                                            <Box sx={{backgroundColor:'#1da7ff', width:'20', height:'20', marginTop: '3', borderRadius: '50px'}}></Box>
                                            <Box sx={{marginLeft: '10px !important'}}>
                                                <Typography>Received</Typography>
                                            </Box>
                                        </Stack>
                                </Box>
                                <Box py={1}>
                                        <Stack spacing={2} direction="row" alignItems="top">
                                            <Box sx={{backgroundColor:'#2dd36f', width:'20', height:'20', marginTop: '3', borderRadius: '50px'}}></Box>
                                            <Box sx={{marginLeft: '10px !important'}}>
                                                <Typography>Plan Effects</Typography>
                                            </Box>
                                        </Stack>
                                </Box>
                            </Box>
                </Stack>
            <Item>
                
                <ReactApexChart options={options} series={options?.series}  type="bar" width={380}/>
               
            </Item>
               
        </Grid>

      </Grid>
    </Box>
    </Paper>
  );
}
