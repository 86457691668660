import { boolean } from "yup";
import AppConstants from "../config/AppConstants";
import { http } from "./http-common";

class IssueManagementService {
  GetIssueDetailByProject = async (projectId: any): Promise<any> => {
    var req = {
      "projectId": projectId
    }
    return await http.post<any>("/v1/Ticket/GetAll", req);
  };
  createIssue = async (req: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/Add", req);
  };
  GetTicketHistoryById = async (ticketId: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/GetTicketHistoryById?Id=" + ticketId);
  };
  UpdateResourceId = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateResourceToTicket", req);
  };
  UpdateStoryPoint = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateStoryPoint", req);
  };

  UpdateHours = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateEstimatedHour", req);
  };
  UpdateIssueStatus = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateStatus", req);
  };
  GetTicketDetailById = async (ticketId: any): Promise<any> => {
    return await http.get<any>("/v1/Ticket/GetById?Id=" + ticketId);
  };
  createComment = async (req: any): Promise<any> => {
    
    return await http.post<any>("/v1/Ticket/AddTicketComment", req);
  };
  GetComments = async (ticketId: any): Promise<any> => {
    return await http.get<any>("/v1/Ticket/GetAllTicketComments?ticketId=" + ticketId);
  };
  deleteComment = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/Ticket/DeleteTicketCommentById?Id=${id}`);
  }
  updateComment = async (comment: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateTicketComment", comment);
  };
  UpdateSeverity = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateSeverity", req);
  };
  UpdateLabel = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateLabel", req);
  };
  UpdateDescription = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateDescription", req);
  };
  UpdatePriority = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdatePriority", req);
  };

  UpdateSummary = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/UpdateSummary", req);
  };
  UpdateSprint = async (req: any): Promise<any> => {
    return await http.put<any>("/v1/Ticket/AssignSprintToTicket", req);
  };
  deleteTicket = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/Ticket/DeleteById?id=${id}`);
  };

  GetAllEpic = async (data: any): Promise<any> => {
    return await http.post<any>(`/v1/Ticket/GetAllEpic`, data);
  };
  
  GetEpicByTicketId = async (data: any): Promise<any> => {
    return await http.get<any>(`/v1/Ticket/GetEpicByTicketId?ticketId=`+ data);
  };

  UpdateEpicticketById = async (data: any): Promise<any> => {
    return await http.put<any>(`/v1/Ticket/UpdateEpicticketById`, data);
  };

  PinTicketOnTopOrBottom = async (data: any): Promise<any> => {
    return await http.put<any>(`/v1/Ticket/PinTicketOnTopOrBottom`, data);
  };
  GetTicketsByEpicIds = async (data: any): Promise<any> => {
    return await http.get<any>(`/v1/Ticket/GetTicketsByEpicId?epicId=`+ data);
  };

  globalSearchInProject = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/Globalsearch", data);
};

  updateBugArtifacts = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/UpdateTicketArtifacts",data);
  }

}
export const issueManagementService = new IssueManagementService();