import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as Icons from "../../../assests/icons/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function RewardManagement() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const styles = {
    Reward: {
      style1: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#232360",
      },
      style2: {
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "29px",
        color: "#232360",
      },
      style3: {
        backgroundColor: "#F0F9FF",
        padding: "18px 21px",
        margin: "32px 0 0 0",
      },
    },
  };
  return (
    <div>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Rewards
          </Typography>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "150px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  All Manager
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="All Manager"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: "150px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Month
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Month"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button variant="contained">
              <AddIcon /> Request Brownie Points
            </Button>
          </Stack>
        </Stack>
        <Grid container  sx={[styles.Reward.style3]}>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", padding: "0" }}
          >
            <Box>
              <Icons.userGroup />
            </Box>
            <Box sx={{ marginLeft: "7px" }}>
              <Typography variant="h5" sx={[styles.Reward.style1]}>
                Total Recourses
              </Typography>
              <Typography variant="h4" sx={[styles.Reward.style2]}>
                08
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", padding: "0" }}
          >
            <Box>
              <Icons.Allotment />
            </Box>
            <Box sx={{ marginLeft: "7px" }}>
              <Typography variant="h5" sx={[styles.Reward.style1]}>
                Monthly Allotment
              </Typography>
              <Typography variant="h4" sx={[styles.Reward.style2]}>
                1000
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", padding: "0" }}
          >
            <Box>
              <Icons.AssignedPoints />
            </Box>
            <Box sx={{ marginLeft: "7px" }}>
              <Typography variant="h5" sx={[styles.Reward.style1]}>
                Total assigned points
              </Typography>
              <Typography variant="h4" sx={[styles.Reward.style2]}>
                500
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", padding: "0" }}
          >
            <Box>
              <Icons.GiftIcon />
            </Box>
            <Box sx={{ marginLeft: "7px" }}>
              <Typography variant="h5" sx={[styles.Reward.style1]}>
                Balance Points
              </Typography>
              <Typography variant="h4" sx={[styles.Reward.style2]}>
                08
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", marginTop: "54px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Alloted Points" {...a11yProps(0)} />
              <Tab label="Recommendation Request" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            ...
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer>
              <Table
                sx={{ minWidth: 650, mt: 2 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: "0" }}>Date</TableCell>
                    <TableCell align="left" sx={{ border: "0" }}>
                      Employee
                    </TableCell>
                    <TableCell align="left" sx={{ border: "0" }}>
                      Points Assigned
                    </TableCell>
                    <TableCell align="left" sx={{ border: "0" }}>
                      Points Value(Rs)
                    </TableCell>
                    <TableCell align="left" sx={{ border: "0" }}>
                      Refered By
                    </TableCell>
                    <TableCell align="left" sx={{ border: "0" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>07/11/2022</TableCell>
                    <TableCell align="left">Richard</TableCell>
                    <TableCell align="left">20</TableCell>
                    <TableCell align="left">200</TableCell>
                    <TableCell align="left">Raju</TableCell>
                    <TableCell align="left">
                      <Box sx={{ minWidth: "50px" }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Approve
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Approve"
                          >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Box>
      </Paper>
    </div>
  );
}
