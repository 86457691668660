import * as React from "react";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import { Paper, Box, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { toast } from "material-react-toastify";
import { Formik } from "formik";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { masterDataService } from "../../service/master-service/master-data-service";
import AppConstants from "../../config/AppConstants";
import { linkedIssueServices } from "../../service/LinkedIssue-Services";
import { commonFunctions } from "../../service/common-services/common-functions";
import { Tooltip } from "@material-ui/core";
import * as Yup from "yup";
import { issueManagementService } from "../../service/issues-management-service";
import CustomField from "./CustomField";
import EditIcon from '@mui/icons-material/Edit';
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const styles: any = {
    customField: {
        " & .MuiSelect-select": {
            paddingTop: "8px",
            paddingBottom: "8px",
        }
    },
    Inprogress: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main
    },
    Hold: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main
    },
    Review: {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.main
    },
    Resolved: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.warning.main
    },
    QA: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main
    },
    Blocked: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main
    },
    Closed: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main
    },
    Defualt: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
    },
    valuetext: {

        "&:hover > button": {
            visibility: "visible",
        }
    },
    reporter: {
        width: 30,
        height: 30,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 500,
        fontFamily: "sans-serif",
    },
}
const ItemTicket = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "14px",



    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
}));
const TicketLabel = styled(Chip)({
    border: "none",
    background: "none",
    padding: 0,

    color: theme.palette.primary.main,
    "& .MuiChip-label": {
        fontWeight: 500,
        fontSize: "15px",
    },
});

// interface LinkedIssue {
//     Ticketid: string;
//     priority: string;
// }

const LinkedIssue = forwardRef((props: any, ref: any) => {
    const { setFieldVisible, fieldVisible, projectId, ticketId,
        linkIssueDetails, refreshData, ticketData, projectWiseAssigneeUsers } = props;
    const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);

    const [addMoreButtonVisible, setAddMoreButtonVisible] = React.useState(true);
    const [buttonDisable, setButtonDisable] = React.useState(false);
    const [issueList, setIssues] = useState<any>([]);
    const [link, setLinks] = useState<any>(linkIssueDetails);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [users, setUsers] = React.useState<any>(projectWiseAssigneeUsers);
    const [selectedLinkIssueId, setSelectedLinkIssueId] = React.useState("");


    useEffect(() => {
        // const abc = { isEdit: false, isNameFocused: false }
        //     Object?.assign(linkIssueDetails, abc);
        let arr = [...linkIssueDetails ?? "" ?? null];
        arr.forEach(function (value) {
            if (value) {
                value.isEdit = false;
                value.isNameFocused = false;
            }
        });
        setLinks(arr);
    }, [linkIssueDetails]);

    const handleEditUserName = (id: any) => {
        let arr = [...linkIssueDetails];
        arr.forEach(function (value) {
            value.isNameFocused = false;
        });
        arr.find((a: any) => a.id === id).isNameFocused = true;
        setLinks(arr);
    };
    const saveUsername = (tcktId: any) => {
        let arr = [...linkIssueDetails];
        arr.forEach(function (value) {
            value.isNameFocused = false;
        });
        setLinks(arr);
    };

    const handleEdit = (id: any) => {
        let arr = [...linkIssueDetails];
        arr.forEach(function (value) {
            value.isEdit = false;
        });
        arr.find((a: any) => a.id === id).isEdit = true;
        setLinks(arr);
    };
    const save = (val: any) => {
        let arr = [...linkIssueDetails];
        arr.forEach(function (value) {
            value.isEdit = false;
        });
        setLinks(arr);
        editChildDetails(val);
    };
    const close = () => {
        let arr = [...linkIssueDetails];
        arr.forEach(function (value) {
            value.isEdit = false;
        });
        setLinks(arr);
    };

    const deleteLinkIssueData = async (id: any,) => {
        setOpenDelete(true);
        setCurrentId(id);
    };

    useImperativeHandle(ref, () => ({
        linkedIssueFunction() {
            GetIssueDetailByProject(projectId);
            setAddMoreButtonVisible(false);
        }
    }));

    const GetIssueDetailByProject = async (projectId: any) => {
      
        var linkedDropData = '';
        if(ticketData.issueType == "Task") {
            linkedDropData = "Bug"
        }
        else if(ticketData.issueType == "Story") {
            linkedDropData = "Task"
        } 
        else {
            linkedDropData = '';
        }
        await masterDataService
            .GetIssuesByProject(projectId, linkedDropData)
            .then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something Went Wrong, Please try again.");
                } else {
                    debugger
                    setIssues(response.data);

                }
            });
    };

    const getLinkIssueDetails = async () => {
        refreshData(ticketData);
    }

    const addLinkIssue = async (values: any) => {
        let obj = {
            ticketId: values.ticketId,
            linkIssueId: values.linkIssueId,
        }
        let obj1 = {
            ticketId: values.linkIssueId,
            blockedBy: values.blockedBy,
        }
        await
            linkedIssueServices.createBlockedBy(obj1).then(async (response: any) => {
                if (response.data.isError) {
                }
                else {
                    await linkedIssueServices.createLinkedIssue(obj)
                    setFieldVisible(false);
                    toast.success('Issue Linked successfully.');
                    getLinkIssueDetails();
                }
            })
                .catch((error) => {
                    if (error.data.Error.length > 0)

                        toast.error(error.data.Error[0], {
                            onOpen: () => setButtonDisable(true),

                            onClose: () => setButtonDisable(false),
                        });

                    return error;
                });
    }

    const DeleteIssue = async () => {
        setButtonDisable(true);
        try {
            await linkedIssueServices
                .deleteLinkIssue(currentId, ticketId)
                .then((response: any) => {
                    if (response.data.isError) {
                    } else {
                        setOpenDelete(false);
                        toast.success("Issue Deleted successfully.", {
                            onClose: () => setButtonDisable(false),
                        });
                        getLinkIssueDetails();
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err, {
                    onClose: () => setButtonDisable(false),
                });

            });
        }
    };

    const handleStatusChange = (status: any, TicketId: any) => {
        var req = {
            ticketId: TicketId,
            status: status,
        };
        issueManagementService.UpdateIssueStatus(req).then((response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                getLinkIssueDetails();
            }
        });
    };
    const handleUserChange = (TicketId: any, id: any) => {
        var req = {
            ticketId: TicketId,
            userId: id,
        };
        issueManagementService.UpdateResourceId(req).then((response: any) => {
            if (response.data.isError) {
                toast.error("Something Went Wrong, Please try again.");
            } else {
                getLinkIssueDetails();
            }
        });
    };

    const editChildDetails = async (val: any) => {
        try {
            await issueManagementService.UpdateSummary(val).then((response: any) => {
                if (response.data.isError) {
                } else toast.success("Child Issue  Updated successfully");
                getLinkIssueDetails();
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    return (
        <>
            <Typography variant="h6" color={theme.palette.primary.dark} sx={{ fontSize: "1rem" }}>Linked issues</Typography>
            {link != null &&
                link.map((issue: any, index: any) => (
                    <>
                        <Typography variant="subtitle2">{issue?.blockedBy}</Typography>
                        <Paper variant="outlined" sx={{ mb: 0.5, padding: "4px 12px 4px 12px" }}>
                            <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between">
                                <Box component={Link}
                                    to={`/issue-detail/${issue?.id}`}
                                    target="_blank"
                                    sx={{ textDecoration: "none", color: theme.palette.primary.main }}>
                                    <ItemTicket sx={{ mr: 4 }}>
                                        {(issue?.issueType == "Bug" &&
                                            <Tooltip title={issue?.issueType}>
                                                <span><Icons.BackLog /></span></Tooltip>
                                        )}  
                                        {(issue?.issueType == "Story" &&
                                            <Tooltip title={issue?.issueType}>
                                                <span><Icons.Story /></span></Tooltip>
                                        )}
                                        {(issue?.issueType == "Epic" &&
                                            <Tooltip title={issue?.issueType}>
                                                <span><Icons.Epic /></span></Tooltip>
                                        )}
                                        {(issue?.issueType == "Task" &&
                                            <Tooltip title={issue?.issueType}>
                                                <span><Icons.Task /></span></Tooltip>
                                        )}
                                         {(issue?.issueType == "Child" &&
                                            <Tooltip title={issue?.issueType}>
                                                <span><Icons.copy /></span></Tooltip>
                                        )} 
                                        &nbsp;<span>{issue?.ticketNumber}</span>
                                    </ItemTicket>
                                </Box>


                                <Stack direction='row' alignItems='center' sx={{ ml: 2 }}>
                                    {(issue?.priority == "Highest" &&
                                        <Tooltip title={issue?.priority}>
                                            <span><Icons.Highest /></span></Tooltip>
                                    )}
                                    {(issue?.priority == "High" &&
                                        <Tooltip title={issue?.priority}>
                                            <span><Icons.High /></span></Tooltip>
                                    )}
                                    {(issue?.priority == "Low" &&
                                        <Tooltip title={issue?.priority}>
                                            <span><Icons.Low /></span></Tooltip>
                                    )}
                                    {(issue?.priority == "Lowest" &&
                                        <Tooltip title={issue?.priority}>
                                            <span><Icons.Lowest /></span></Tooltip>
                                    )}
                                    {(issue?.priority == "Medium" &&
                                        <Tooltip title={issue?.priority}>
                                            <span><Icons.Medium /></span></Tooltip>
                                    )}
                                    <Typography component='p' sx={{ fontSize: 14, display: 'flex', alignItems: 'center', ml: 1 }}>
                                        {!issue?.isEdit ? (
                                            <Stack alignItems='center' direction='row' component='div' sx={styles.valuetext} >
                                                <Typography component={Link}
                                                    to={`/issue-detail/${issue?.id}`}
                                                    target="_blank"
                                                    sx={{ textDecoration: "none", color: theme.palette.primary.main }}> {issue?.summary.slice(0, 44) + (issue?.summary.length > 20 ? "..." : "")}</Typography>
                                                <IconButton onClick={() => { handleEdit(issue?.id); }}
                                                    sx={{ fontSize: "16px", ml: 1, visibility: 'hidden' }}><EditIcon sx={{ fontSize: "16px" }} /></IconButton>
                                            </Stack>

                                        ) : (
                                            <CustomField
                                                defaultValue={issue?.summary}
                                                saveText={save}
                                                cancelEdit={close}
                                                ticketId={issue?.id}
                                            />
                                        )}
                                    </Typography>
                                    {/* <Typography component='p' sx={{ fontSize: 14, display: 'flex', alignItems: 'center', ml: 1 }}>
                                        {!issue?.isEdit ? (
                                            <Stack alignItems='center' direction='row'  component={Link} to={`/issue-detail/${issue?.id}`}
                                            target="_blank" 
                                            >
                                                <Typography component='span'> {issue?.summary.slice(0, 44) + (issue?.summary.length > 20 ? "..." : "")}</Typography>
                                                <IconButton onClick={() => { handleEdit(issue?.id); }}
                                                    sx={{ fontSize: "16px", ml: 1, visibility: 'hidden' }}><EditIcon sx={{ fontSize: "16px" }} /></IconButton>
                                            </Stack>
                                        ) : (
                                            <CustomField
                                                defaultValue={issue?.summary}
                                                saveText={save}
                                                cancelEdit={close}
                                                ticketId={issue?.id}
                                            />
                                        )}
                                    </Typography> */}
                                </Stack>

                                <Box sx={{ flexGrow: 1 }}></Box>
                                <Stack direction='row' alignItems="center">
                                    <Box>
                                        {!issue?.isNameFocused ? (
                                            <Tooltip title={issue?.assignUsername}>
                                                <Avatar
                                                    {...commonFunctions.stringAvatar(issue?.assignUsername)}
                                                    sx={[styles.reporter]}
                                                    src={`${process.env.REACT_APP_SERVER_URL}${issue?.assignUsername}`}
                                                    onClick={() => { handleEditUserName(issue?.id); }} /></Tooltip>
                                        ) : (
                                            <Autocomplete
                                                value={users?.find(
                                                    (option: any) => option.id == issue?.userId
                                                ) ?? null}
                                                fullWidth
                                                id="selectedAssigneId"
                                                options={users}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, value) => {

                                                    handleUserChange(issue?.id, value?.id ?? "");
                                                }}
                                                componentsProps={{
                                                    popper: {
                                                      modifiers: [
                                                        {
                                                          name: 'flip',
                                                          enabled: true,
                                                          options: {
                                                            altBoundary: true,
                                                            flipVariations: true,
                                                            altAxis: true,
                                                            padding: 8,
                                                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        autoFocus
                                                        id="selectedAssigneId"
                                                        variant="outlined"
                                                        size="small"
                                                        name="IssueAssigneeId"
                                                        sx={{ width: '20ch' }}
                                                        value={issue?.assignUsername}
                                                        onBlur={saveUsername} />
                                                )} />)}
                                    </Box>

                                    <Box sx={{ mx: 1 }}>
                                        <FormControl sx={{ p: 0, minWidth: 120 }} size="small">

                                            <FormControl sx={{ minWidth: 150 }}>
                                                <Select
                                                    key={index}
                                                    name="status"
                                                    value={issue?.status ?? ""}
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    size='small'
                                                    sx={[styles?.customField, styles[`${issue?.status}`]]}
                                                // sx={{
                                                //     "& .MuiSelect-select": {
                                                //         paddingTop: "8px",
                                                //         paddingBottom: "8px",
                                                //     },
                                                // }}

                                                >
                                                    {AppConstants.MasterData.Issue.IssueStatus.map(
                                                        (status: any, idx: any) => (
                                                            <MenuItem key={"status_" + idx} value={status.label}
                                                                onClick={() => { handleStatusChange(status?.label ?? "", issue?.id); }}>
                                                                {status.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </FormControl>
                                    </Box>
                                </Stack>

                                {_permission.action_Issue_Delete_Access == true && (<Tooltip title="Unlink Issue">
                                    <IconButton onClick={(e) => {
                                        deleteLinkIssueData(issue?.id);
                                    }}><CloseIcon sx={{ fontSize: "1rem" }} /></IconButton>
                                </Tooltip>)}
                            </Stack>
                        </Paper>
                    </>
                ))
            }


            {fieldVisible != false && (
                <>
                    <Formik
                        initialValues={{
                            linkIssueId: "",
                            blockedBy: "",
                            ticketId: ticketId,
                        }}
                        validationSchema={Yup.object().shape({
                            blockedBy: Yup.string().required("Link a issue Type"),
                            linkIssueId: Yup.string().nullable().required("Please select a Issue"),
                        })}
                        onSubmit={async (values, {
                            setErrors,
                            setStatus,
                            setSubmitting,

                        }) => {
                            try {
                                //   setErrorMessage("");
                                await addLinkIssue(values);
                            } catch (ex: any) {
                                if (ex.status == 400) {
                                    console.error("ex:", ex);
                                    ex.data.Error.map((error: any, idx: any) => {
                                        // setErrorMessage(error);
                                        toast.error(error);
                                    })
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}>


                                <Grid container spacing={1} sx={{ mt: 1.5 }}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="blockedBy"
                                                select
                                                label="Linked Issue"
                                                variant="outlined"
                                                fullWidth
                                                name="blockedBy"
                                                error={Boolean(touched.blockedBy && errors.blockedBy)}
                                                helperText={touched.blockedBy && errors.blockedBy}
                                                onChange={handleChange}
                                                value={values.blockedBy}
                                            >
                                                {AppConstants.MasterData.Issue.LinkedIssue.map(
                                                    (issue: any) => (
                                                        <MenuItem key={issue.value} value={issue.value}>
                                                            {issue.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <Autocomplete

                                                value={
                                                    issueList?.find(
                                                        (option: any) => option.id == values.linkIssueId
                                                    ) ?? null
                                                }
                                                fullWidth
                                                id="Epic-issueList"
                                                options={issueList}
                                                getOptionLabel={(option) =>
                                                    option.ticketNumber +
                                                    " " +
                                                    option.summary.slice(0, 20) +
                                                    (option.summary.length > 20 ? "..." : "")
                                                }
                                                getOptionDisabled={(option) => {
                                                    return (link?.find((issue: any) => (issue.id === option.id)
                                                    ) || (option.id === ticketId)) ? true : false;
                                                }
                                                }
                                                onChange={(event, value) => {
                                                    values.linkIssueId = value?.id ?? null;
                                                    setSelectedLinkIssueId(value?.id ?? "");
                                                }}
                                                componentsProps={{
                                                    popper: {
                                                      modifiers: [
                                                        {
                                                          name: 'flip',
                                                          enabled: true,
                                                          options: {
                                                            altBoundary: true,
                                                            flipVariations: true,
                                                            altAxis: true,
                                                            padding: 8,
                                                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        id="Epic-issueList"
                                                        error={Boolean(touched.linkIssueId && errors.linkIssueId)}
                                                        helperText={touched.linkIssueId && errors.linkIssueId}
                                                        name="linkIssueId"
                                                        label="Select Issue"
                                                        variant="outlined"
                                                        value={selectedLinkIssueId}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 1 }}>
                                    <Button variant="contained" size="small" type="submit" sx={{ height: 30 }}
                                    >Create</Button>
                                    <Button variant="outlined" size="small" sx={{ height: 30 }}
                                        onClick={() => { setFieldVisible(false); }}>Cancel</Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </>
            )}
            {fieldVisible != true && (
                <Tooltip title="Link Issue" arrow>
                    <IconButton color="primary" onClick={() => { setFieldVisible(true); GetIssueDetailByProject(projectId); }}><AddIcon /></IconButton>
                </Tooltip>
            )}


            <Dialog
                open={openDelete}
                onClose={setOpenDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                    Delete
                </DialogTitle>
                <DialogContent sx={{ pt: 1 }}>
                    Are you sure you want to Un-link this {link?.ticketNumber}{" "}
                    {link?.issueType}
                </DialogContent>
                <DialogActions
                    sx={{
                        p: 3,
                        pt: 0,
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button variant="outlined" onClick={() => {
                        setOpenDelete(false);
                    }}>
                        No
                    </Button>
                    <Button variant="contained" onClick={DeleteIssue} disabled={buttonDisable}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
});
export default LinkedIssue;

