import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import { projectPortfolioService } from "../../service/project-portfolio-service";

import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { projectManagementServive } from "../../service/project-management-service";


const DomainPortfolioView = () => {


  const [domainPortfolioData, setDomainPortfolioData] = React.useState<any>([]);


  const { domainId } = useParams();

  const navigate = useNavigate();
  const _permission = useSelector((state: any) => state.DomainPortfolioApp.DomainPortfolioPermission);

  useEffect(() => {
    if(_permission.tab_ManageDomainPortfolioView_Access !== true){
      navigate("/dashboard");
    }
    getDomainPortfolioDocumentsById(domainId);
  }, [])





  var getDomainPortfolioDocumentsById = async (id: any) => {
    debugger
    await projectPortfolioService.getDomainPortfolioDocumentsById(id).then((response: any) => {
      if (response.data.isError) { }
      else {

        setDomainPortfolioData([response.data]);
      }
    })
  }
  
  const getUnEncrypedFiles = async (fileName: any) => {
    try {
        await projectManagementServive
            .getUnEncrypetDocuments(fileName)
            .then(async (response: any) => {
                if (response.data.type === "application/json") {
                    const data = await response.data.text();
                    toast.warning(JSON.parse(data).listErrors[0]);
                }
                else {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = URL.createObjectURL(response.data);
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(a.href);
                }
            });
    } catch (ex: any) {
        (ex.data?.Error || ex.Error).map((err: any, idx: any) => {
            toast.warning(err);
        });
    }
}


  return (
    <div>
      <Box>
        <p
          style={{
            textDecoration: 'underline',
            color: '#00a1e4',
            cursor: 'pointer',
          }}
          //  onClick={() => navigate(`/project-portfolio`)}
          onClick={() => {
            window.close();

          }}
        >
          {"Go Back to Domain Portfolio"}
        </p>
        <Grid container spacing={2} >

          <Grid item xs={12}>

            {domainPortfolioData.map((item: any, idx: any) => {
              return (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        padding: "28px",
                        background: "#fff",
                        border: "1px solid #F3F3F3",
                      }}
                    >
                      <Box
                        sx={{
                          // display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingBottom: "30px",
                          borderBottom: "1px solid #E9E9E9",
                          flexWrap: "wrap",
                          gap: "16px",
                        }}
                      >
                        <h3 style={{ fontSize: "18px", marginTop: "0" }}>
                          Title
                        </h3>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#666666",
                            margin: "0",
                          }}
                        >
                          {item?.title}
                        </p>

                      </Box>
                      <Box
                        sx={{
                          paddingBlock: "20px",
                          borderBottom: "1px solid #E9E9E9",
                          wordWrap: "break-word"
                        }}
                      >
                        <h3 style={{ fontSize: "18px", marginTop: "0" }}>
                          Project Description
                        </h3>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#666666",
                            margin: "0",
                          }}
                        >
                          {item?.description}
                        </p>
                      </Box>

                    </Box>




                  </Grid>
                  <Grid item xs={6}>


                    <Box
                      sx={{
                        padding: "24px",
                        border: "1px solid #F3F3F3",
                        backgroundColor: "#fff",
                        // marginTop: "18px",
                      }}
                    >
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        Keywords
                      </h4>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", wordBreak: "break-word" }}>

                        {/* {item?.techStack || "--"} */}
                        {item?.keywords && item?.keywords.length > 0 ? (
                          item?.keywords.map((obj: any, idx: number) => (
                            <Box
                              sx={{
                                border: "1px solid #F6F6F6",
                                padding: "6px 8px",
                                borderRadius: "6px",
                                background: "#F9F9F9",
                              }}
                            >
                              <span key={idx} >
                                {obj?.keyword || "--"}
                              </span>
                            </Box>
                          ))
                        ) : (
                          "--"
                        )}


                      </Box>
                    </Box>



                    <Box
                      sx={{
                        padding: "24px",
                        border: "1px solid #F3F3F3",
                        backgroundColor: "#fff",
                        marginTop: "18px",
                      }}
                    >
                      <h4
                        style={{
                          color: "#4B4B5A",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginTop: "0",
                          marginBottom: "8px",
                        }}
                      >
                        File Path
                      </h4>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", wordBreak: "break-word" }}>
                        {item?.files && item?.files.length > 0 ? (
                          item?.files.map((fileItem: any, idx: number) => (
                            <Box
                              sx={{
                                border: "1px solid #F6F6F6",
                                padding: "6px 8px",
                                borderRadius: "6px",
                                background: "#F9F9F9",
                              }}
                            >
                              <Typography
                                onClick={() => { getUnEncrypedFiles(fileItem?.wbsPath) }}
                                style={{ cursor: 'pointer', color: "#1DA7FF", textDecoration: "underline" }}
                              >
                                <Box>
                                  {fileItem.wbsPath}

                                </Box>
                              </Typography>

                            </Box>
                          ))
                        ) : (
                          "--"
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )
            })}

          </Grid>
        </Grid>
      </Box>


    </div>
  );
};

export default DomainPortfolioView;
