import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
  Autocomplete,
  TableCell,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchBar from "../../components/styledComponent/SearchBar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Modal from "@mui/material/Modal";
import { userManagementServive } from "../../service/user-management-service";
import NoUser from "./NoUser";
import { toast } from "material-react-toastify";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import { clsDepartmentList } from "../../service/common-services/department";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Userlisttop from "./userliststyle";
import InfoPopover from "../../components/CommonInfoComponent";
import dayjs from "dayjs";
import PaginationControls from "../../components/PaginationComponent";
import { Grid } from "@material-ui/core";

// const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
//     color: theme.palette.success.dark,
//     backgroundColor: theme.palette.success.light,
//     fontWeight: 400,
//     fontSize: 14,
// }));

// const StatusChipDeactive = styled(Chip)<ChipProps>(({ theme }) => ({
//     color: theme.palette.success.dark,
//     backgroundColor: theme.palette.success.light,
//     fontWeight: 400,
//     fontSize: 14,
// }));
interface User {
  id: number;
  employeeId: number;
  name: string;
  emailId: string;
  department: string;
  designation: string;
  workLocationStatus: string;
  status: string;
}
interface Department {
  id: string;
  name: string;
}
interface Data {
  department: string;
  designation: string;
  emailId: string;
  employeeId: number;
  workLocationStatus: number;
  id: string;
  name: string;
  serialNumber: string;
  status: string;
  srNo: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let first: any = a[orderBy],
    second: any = b[orderBy];
  if (!isNaN(Number(first))) {
    first = Number(first);
  } else if (first != undefined) {
    first = first.toLowerCase();
  }
  if (!isNaN(Number(second))) {
    second = Number(second);
  } else if (second != undefined) {
    second = second.toLowerCase();
  }

  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: string | number },
  b: { [key in Key]: string | number }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "employeeId",
    numeric: false,
    disablePadding: false,
    label: "Employee Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "emailId",
    numeric: false,
    disablePadding: false,
    label: "Email-Id",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
  },
  {
    id: "workLocationStatus",
    numeric: false,
    disablePadding: false,
    label: "Work Location",
  },
];

const dummy = [
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
  { value: 30 },
  // Add more options if needed
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              ...(headCell.id === "employeeId" && {
                position: "sticky",
                left: 0,
                zIndex: 99,
                background: "#F5F5F5"

              }),
              ...(headCell.id === "name" && {
                position: "sticky",
                left: '135px',
                zIndex: 99,
                background: "#F5F5F5"

              }),
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell
                >
                    Status
                </TableCell> */}
        <TableCell align-items="center" align="center">
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return <></>;
};

export default function UserList() {
  const [openModal, setModalOpen] = React.useState(false);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.UserPermissionApp.UserPermission
  );
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const optionsStatus = ["Active", "In-Active"];
  var departmentData = { id: "", name: "All Departments" };
  const [departmentHook, setDepartmentHook] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [statusDataHook, setStatusDataHook] = useState("Active");
  const [users, setUsers] = useState<Data[]>([]);
  const [disabledDepartments, setdisabledDepartments] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentsName, setdepartmentsName] = useState("");
  const [departmentsId, setdepartmentsId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionAllowed, setAllowdAction] = React.useState<any>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentId, setCurrentId] = React.useState(false);
  const [filtersSelected, setfiltersSelected] = React.useState(false);

  const WorkLocationData = [
    {
      value: 0,
      label: "Select Work Location",
    },
    {
      value: 1,
      label: "Work From Home",
    },
    {
      value: 2,
      label: "Work From Office",
    },
    {
      value: 3,
      label: "Hybrid",
    },
  ];

  const [StaticStatusId, setStaticStatusId]: any = useState<number | undefined>(
    0
  );

  // const [monthStatus, setMonthStatus]: any = useState<number | undefined>(getCurrentMonth());

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
  const [noUser, setNoUser] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    const selectedValue = parseInt(event.target.value, 10);
    setRowsPerPage(selectedValue);
    setPage(0);
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    debugger;
    setfiltersSelected(true);

    const selectedStatus = event.target.value as number;
    setStaticStatusId(selectedStatus);
    getUsersDetails(departmentsId, searchValue, selectedStatus);
  };

  const handleDecrement = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleIncrement = () => {
    setPage((prevPage) => Math.min(prevPage + 1, numCount - 1));
  };
  const numCount = Math.ceil(users.length / rowsPerPage);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentPageData = users.slice(startIndex, endIndex);

  useEffect(() => {
    if (_permission.page_ManageUsers_Access !== true) {
      navigate("/dashboard");
    }
    const departmentsData = async () => {
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        if (
          _permission.action_ManageUsersListing_AllDepartments_Access === false
        ) {
          setdisabledDepartments(true);
        }
        await setDepartmentHook(_authUser.employeeInfo.departmentId);
        await setdepartmentsId(_authUser.employeeInfo.departmentId);
        await setDepartments(
          await clsDepartmentList.getDepartmentDropdownList()
        );
        getUsersDetails(_authUser.employeeInfo.departmentId, "", 0);
      } else {
        await setDepartmentHook("");
        await setdepartmentsId("");
        await setDepartments(
          await clsDepartmentList.getDepartmentDropdownList()
        );
        getUsersDetails("", "", 0);
      }
    };
    departmentsData();
  }, []);
  const triggerSearch = async (obj: any, search: any) => {
    setfiltersSelected(true);
    getUsersDetails(obj.departmentId, search, StaticStatusId);
    setPage(0);
  };

  const getUsersDetails = async (
    departmentId: any,
    search: any,
    StaticStatusId: number
  ) => {
    debugger;
    let values = {
      department: departmentId ?? "",
      workLocationStatus: StaticStatusId !== undefined ? StaticStatusId : 0,
      status: statusDataHook,
      search: search ?? "",
      take: 1000,
      skip: 0,
    };
    console.log("API Call Parameters:", values);
    await userManagementServive.getUsers(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        setUsers(response?.data?.result);
        // if(response.data.length==0){
        //     setNoUser(true);
        // }
      }
    });
  };

  // const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
  //     setfiltersSelected(true);
  //     getUsersDetails(departmentsId, searchValue)
  //   const selectedStatus = event.target.value as number;
  //   setStaticStatusId(selectedStatus);
  // };

  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
      } else {
        let data = []; //response.data;//[departmentData];
        data.push(departmentData);
        response.data.map((dep: any) => {
          data.push(dep);
        });
        setDepartments(data);

        if (_authUser.typeOfUser != eTypeOfUser.Admin) {
          setdisabledDepartments(true);
          setDepartmentHook(_authUser.employeeInfo.departmentId);
          setdepartmentsId(_authUser.employeeInfo.departmentId);
          getUsersDetails(_authUser.employeeInfo.departmentId, "", 0);
        } else {
          getUsersDetails("", "", 0);
        }
      }
    });
  };
  const deleteUserDetaildata = async (id: any) => {
    setModalOpen(true);
    setCurrentId(id);
  };

  const deleteUserDetail = async () => {
    try {
      await userManagementServive
        .deleteUser(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("User Deleted Successfully");
            setModalOpen(false);
            getUsersDetails(departmentsId, searchValue, 0);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (id: any) => {
    setDepartmentHook(id);
    await setSelectedIndex(id);
    await getUsersDetails(id, searchValue, 0);
    setPage(0);
  };

  const handleClose = () => {
    setModalOpen(false);
    setAnchorEl(null);
  };

  const ExportUserListExcel = () => {
    debugger;
    const fileType =
      "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset-UTF-8";
    const FileExtension = ".xlsx";

    let startRow = 1;

    const formattedData = users.map((item: any) => ({
      EmployeeID: item.employeeId,
      Name: item.name,
      EmailID: item.emailId,
      Department: item.department,
      Designation: item.designation,
      WorkLocation:
        item.workLocationStatus === 1
          ? "Work From Home"
          : item.workLocationStatus === 2
          ? "Work From Office"
          : item.workLocationStatus === 3
          ? "Hybrid"
          : "--",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "User List.xlsx");
  };

  const [aanchorEl, statussetAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [bloodGroup, setBloodGroup] = useState("");
  const [selectedstatusIndex, setStatusSelectedIndex] = React.useState(0);
  const [issueStatus, setIssueStatus] = React.useState("");

  const stopen = Boolean(aanchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    statussetAnchorEl(event.currentTarget);
  };

  const statushandleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setStatusDataHook(optionsStatus[index]);
    setStatusSelectedIndex(index);
    setAnchorEl(null);
    getUsersDetails(departmentsId, searchValue, 0);
  };
  const statushandleClose = () => {
    statussetAnchorEl(null);
  };

  const [hideStatus, sethideStatus] = useState(true);

  const getIsuranceDetails = async () => {
    try {
      await userManagementServive
        .getInsuranceDetails(departmentsId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            console.log(response?.data, "response?.data");
            Exportexcel(response?.data);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const Exportexcel = (excelData: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset-UTF-8";
    const FileExtension = ".xlsx";

    const new_list = excelData?.map((obj: any) => {
      const child: any = {};
      obj.insuranceCard?.childs?.forEach((c: any, i: any) => {
        child[`ChildName ${i + 1}`] = c.name;
        child[`ChildDOB ${i + 1}`] = dayjs(c.dob).format("MM/DD/YYYY");
      });

      return {
        EmployeeName: obj.employeeName,
        Gender: obj.gender,
        DOB: dayjs(obj.dateOfBirth).format("MM/DD/YYYY"),
        EmailId: obj.personalEmailId,
        DateOfJoining: dayjs(obj.dateOfJoining).format("MM/DD/YYYY"),
        Department: obj.department,
        Desgination: obj.desgination,

        Address1: obj.address1,
        Address2: obj.address2,
        PermanentAddress1: obj.permanentAddress1,
        PermanentAddress2: obj.permanentAddress2,
        DateOfMarriage: dayjs(obj.anniversaryDate).format("MM/DD/YYYY"),
        PhoneNumber: obj.phoneNumber,
        NomineeName: obj.insuranceCard?.nominee,
        Relationship: obj.insuranceCard?.relationship,
        SpouseName: obj.insuranceCard?.spouseName,
        Spousedob: dayjs(obj.insuranceCard?.dob).format("MM/DD/YYYY"),
        ...child,
      };
    });

    const ws = XLSX.utils.json_to_sheet(new_list);
    console.log("new_list", new_list);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Insurence Detail.xlsx");
  };
  const pageCount = Math.ceil(users.length / rowsPerPage);
  return (
    <>
      {noUser && <NoUser></NoUser>}
      {!noUser && (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
            <Link color="inherit" to="/">
              Dashboard
            </Link>
            <Typography color="text.primary">User Management</Typography>
            <Typography color="text.primary">User List</Typography>
          </Breadcrumbs>
          <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
            <Stack >
              <Stack direction={"row"}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600 }}
                  component="h2"
                >
                  User List
                </Typography>
                <Box>
                  <InfoPopover onInput={"User List"} />
                </Box>
              </Stack>

              {/* <Box sx={{ flexGrow: 1 }} /> */}
              <Stack
                sx={Userlisttop.topright}
                spacing={2}
                direction={"row"}
                width={"auto"}
              >
                {_permission.tab_InsuranceDetailsDownload_Access && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Icons.Download />
                    <Button
                      sx={{ paddingLeft: "10px" }}
                      variant="text"
                      onClick={(e) => getIsuranceDetails()}
                    >
                      Insurance Details
                    </Button>
                  </Box>
                )}

                <Stack direction={"row"}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={"Download Excel"} arrow>
                      <IconButton onClick={ExportUserListExcel}>
                        <Icons.Download />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>

                <Box>
                  <Autocomplete
                    disabled={disabledDepartments}
                    value={
                      departments?.find(
                        (option) => option.id === departmentsId
                      ) ?? null
                    }
                    // fullWidth

                    id="outlined-basic"
                    options={departments}
                    getOptionLabel={(option) => option.name}
                    // onClick={handleClickListItem}
                    onChange={async (event, value) => {
                      setfiltersSelected(true);
                      setdepartmentsId(value?.id ?? "");
                      setdepartmentsName(value?.name ?? "");
                      setSearchValue("");
                      setPage(0);
                      //handleMenuItemClick( id)
                      // setStatusDataHook(value?.id??"");
                      await getUsersDetails(
                        value?.id,
                        searchValue,
                        StaticStatusId
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={disabledDepartments}
                        id="outlined-basic"
                        name="element.name"
                        variant="outlined"
                        value={departmentsId}
                        fullWidth
                        sx={{ minWidth: 200 }}
                      />
                    )}
                  />
                </Box>
                {!hideStatus && (
                  <Box>
                    <Button
                      fullWidth
                      aria-controls={
                        stopen ? "demo-positioned-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={stopen ? "true" : undefined}
                      onClick={handleClick}
                      sx={{ justifyContent: "space-between" }}
                      variant="selectSmall"
                    >
                      {optionsStatus[selectedstatusIndex]} <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={aanchorEl}
                      open={stopen}
                      onClose={statushandleClose}
                    >
                      {optionsStatus.map((optionsStatus, index) => (
                        <MenuItem
                          key={optionsStatus}
                          // disabled={index === 0}
                          selected={index === selectedstatusIndex}
                          onClick={(event) =>
                            statushandleMenuItemClick(event, index)
                          }
                        >
                          {optionsStatus}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                )}
                {actionAllowed.bulk_import && (
                  <Button
                    color="primary"
                    startIcon={<Icons.Export />}
                    variant="selectSmall"
                  >
                    Bulk Export
                  </Button>
                )}

                <TextField
                  sx={{ minWidth: 200 }}
                  //label="Select Location"
                  id="outlined-basic"
                  select
                  value={StaticStatusId}
                  variant="outlined"
                  onChange={handleStatusChange} // Use onChange instead of onClick
                >
                  {WorkLocationData.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Box sx={Userlisttop.searchbar}>
                  <SearchBar
                    setSearchTicket={setSearchValue}
                    value={searchValue}
                    obj={{ departmentId: departmentsId }}
                    callBackFunction={triggerSearch}
                  />
                </Box>

                {filtersSelected && (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      setfiltersSelected(false);
                      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                        setdepartmentsId(_authUser.employeeInfo.departmentId);
                        setDepartmentHook(_authUser.employeeInfo.departmentId);
                        getUsersDetails(
                          _authUser.employeeInfo.departmentId,
                          "",
                          0
                        );
                      } else {
                        setdepartmentsId("");
                        setDepartmentHook("");
                        getUsersDetails("", "", 0);
                      }
                      setStaticStatusId(0);
                      setSearchValue("");
                    }}
                  >
                    Clear
                  </Button>
                )}

                {_permission.action_ManageUsersListing_Add_Access && (
                  <Button
                    variant="contained"
                    component={Link}
                    to="/users/add-user"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{
                      height: "100%",
                      minWidth: "auto",
                      "& .css-1ee1w78-MuiButtonBase-root-MuiButton-root": {
                        height: "100%",
                        minWidth: "auto",
                      },
                    }}
                  >
                    Add User
                  </Button>
                )}
              </Stack>
            </Stack>

            <Divider />

            <Box sx={{ width: "100%" }}>
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    maxWidth: "320px",
                  },
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={users.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(users, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick1(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                          >
                            <StyledTableCell component="th" id={labelId}  sx={{ position: "sticky", left: "0", background: "#fff" }}>
                              {" "}
                              {row.employeeId}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left"  sx={{ position: "sticky", left: "135px", background: "#fff" }}>
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.emailId}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.department}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.designation}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.workLocationStatus === 1
                                ? "Work From Home"
                                : row.workLocationStatus === 2
                                ? "Work From Office"
                                : row.workLocationStatus === 3
                                ? "Hybrid"
                                : "--"}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left"> */}
                            {/* <Chip variant='approved' label={row.status} color="default" /> */}
                            {/* <StatusChip label={<span><Icons.CircleFilled /> {row.status}</span>} /> */}
                            {/* </StyledTableCell> */}
                            <StyledTableCell align="left" width={100}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                              >
                                {/* {(actionAllowed.edit && */}
                                {_permission.action_ManageUsersListing_Edit_Access && (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      component={Link}
                                      to={`/users/edit-user/${row.id}`}
                                      size="medium"
                                      target="_blank"
                                    >
                                      <Icons.Edit />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {/* )} */}
                                {/* {(actionAllowed.view && */}
                                {_permission.action_ManageUsersListing_View_Access && (
                                  <Tooltip title="View">
                                    <IconButton
                                      aria-label="view"
                                      component={Link}
                                      to={`/users/user-profile/${row.id}`}
                                      target="_blank"
                                    >
                                      <Icons.View />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {/* )} */}
                                {/* {(actionAllowed.delete && */}
                                {_permission.action_ManageUsersListing_Delete_Access && (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() =>
                                        deleteUserDetaildata(row.id)
                                      }
                                    >
                                      <Icons.Delete />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {_permission.action_ManageUsersListing_AddKra_Access && (
                                  <Tooltip title="Add KRA">
                                    <IconButton
                                      aria-label="add"
                                      component={Link}
                                      to={`/addKRA/${row.id}`}
                                      target="_blank"
                                    >
                                      <Icons.AddKRAIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {_permission.action_ManageUsersListing_ViewKra_Access && (
                                  <Tooltip title="View KRA">
                                    <IconButton
                                      aria-label="view"
                                      component={Link}
                                      to={`/krarating/${row.id}`}
                                      target="_blank"
                                    >
                                      <Icons.ViewKRAIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {/* )} */}
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={users.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() =>
                  `Total: ${users?.length}  Page ${page + 1} of ${pageCount}`
                }
              />
            </Box>
          </Paper>
          <Modal
            open={openModal}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Userlisttop.actionpopup}>
              <Paper variant="outlined" sx={{ p: 4 }}>
                <Typography
                  id="modal-modal-title"
                  align="center"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: 700 }}
                >
                  Delete User
                </Typography>
                <Typography
                  id="modal-modal-description"
                  align="center"
                  sx={{ mt: 2 }}
                >
                  Are you sure, You sure you want to delete it. Continue?
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 3 }}
                >
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={deleteUserDetail}
                    disabled={buttonDisable}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Paper>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
