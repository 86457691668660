
import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import theme from "../../theme/theme";
import * as XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
import { clsResourcesList } from "../../service/common-services/resources";
import { Department } from "../../Interfaces/IDepartment";
import { IResource } from "../../Interfaces/IResource";
import { useSelector } from "react-redux";
import { reportService } from "../../service/Reports/reports";
import { clsDepartmentList } from "../../service/common-services/department";
import { toast } from "material-react-toastify";
import { Autocomplete, Checkbox, CircularProgress, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import HrReporttop from "./HrReportsstyle";
import NotFound from "../../components/styledComponent/NotFound";
import { DatePicker } from 'react-rainbow-components';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import * as Icons from "../../assests/icons/icons";
import FileSaver from "file-saver";
import saveAs from "file-saver";

const AttendanceStatus = [
    {
        value: 0,
        label: "All",
    },
    {
        value: 1,
        label: "Present",
    },
    {
        value: 2,
        label: "Absent",
    },
    {
        value: 3,
        label: "On Leave",
    },
    {
        value: 4,
        label: "CompOff Leave",
    },
];


const LoginType = [
    {
        value: 0,
        label: "Select Type",
    },
    {
        value: 1,
        label: "Tracker",
    },
    {
        value: 2,
        label: "Portal",
    },

];
export default function TrackerAndPortalReport() {
    function setDateWithoutTime(fdate: any) {
        const year = fdate.year();
        const month = fdate.month() + 1; // Month index is zero-based in dayjs
        const day = fdate.date();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T00:00:00Z`;
    }

    let today = dayjs();
    let yesterday = today.subtract(1, 'day');
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [reports, setreports] = React.useState<[]>([])
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = React.useState("");
    const [startDate, setStartDate] = React.useState<any>(setDateWithoutTime(yesterday));
    const [StaticStatusId, setStaticStatusId] = useState<any>(0);
    const [attendanceStatus, setAttendanceStatus] = useState<number | undefined>(0);
    const [checked, setChecked] = React.useState(false);
    const [schedularLoader, setSchedularLoader] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setfiltersSelected(true);
        setChecked(event.target.checked);
    };
    const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setfiltersSelected(true);
        const selectedStatus = event.target.value as number;
        setStaticStatusId(selectedStatus);
        setResourceId("");
        if (selectedStatus == 0) {
            let resourceList = await clsResourcesList.getResourcesDropdownList(
                departmentId ?? ""
            )
            let filteredResourcelist = resourceList.filter((resource: any) => (resource.userId != ""))
            setresources(filteredResourcelist);
        }
        else {
            getResourcesByDepartmentOrType(departmentId, selectedStatus);
        }
    };
    const getDate = (dateObject: Date) => {
        debugger
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        debugger
        return `${year}-${month}-${day}T00:00:00Z`;
    }
    const getReport = async (
        departmentId: string = "",
        date: any,
        resourceId: string = "",
        StaticStatusId: number,
        isChecked: any,
        status: any
    ) => {
        try {
            setToggleLoader(true);
            var hrReportFilter = {
                department: departmentId,
                resourceId: resourceId,
                loginType: StaticStatusId !== undefined ? StaticStatusId : 0,
                status: status,
                isLessThanEightHour: isChecked,
                atttendaceDate: date
            };
            await reportService.getPortalAndTrackerReport(hrReportFilter)
                .then((response: any) => {
                    if (response.data.isError) {
                        setreports([]);
                        setToggleLoader(false);
                    }
                    else {
                        const orderdData = response.data.sort((a : any,b : any) => a.name.localeCompare(b.name));
                        setreports(orderdData);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    const getResourcesByDepartmentOrType = async (
        departmentId: string = "", StaticStatusId: number
    ) => {
        try {
            setToggleLoader(true);
            var req = {
                departmentId: departmentId,
                isTrackerRequired: StaticStatusId !== undefined ? StaticStatusId === 1 : false
            };
            await reportService.getResourcesByDepartmentOrType(req)
                .then((response: any) => {
                    if (response.data.isError) {
                        setresources([]);
                        setToggleLoader(false);
                    }
                    else {
                        setresources(response.data);
                        setToggleLoader(false);
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
                setToggleLoader(false);
            });
        }
    }
    const dateWithoutString = () => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day from current date
        let dateT = currentDate.toISOString();
        dateT = dateT.slice(0, 10) + 'T00:00:00Z';
        return dateT;
    }
    React.useEffect(() => {
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                debugger
                if (_permission.action_AttendanceReportNew_AllDepartment_Access == true) {
                    debugger
                    setdisabledDepartments(false);
                } else {
                    debugger
                    setdisabledDepartments(true);
                }
                setDepartmentId(_authUser.employeeInfo.departmentId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                getReport(_authUser.employeeInfo.departmentId, dateWithoutString(), resourceId, StaticStatusId, checked, attendanceStatus);
                //getResourcesByDepartmentOrType(_authUser.employeeInfo.departmentId, StaticStatusId !== undefined ? (StaticStatusId ? 1 : 2) : 2);
                let resourceList = await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId ?? "")
                let filteredResourcelist = resourceList.filter((resource: any) => (resource.userId != ""))
                setresources(filteredResourcelist);
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setDepartmentId("");
                getReport("", dateWithoutString(), "", StaticStatusId, checked, attendanceStatus);
            }
        };
        departmentsData();
    }, []);

    const convertTotalHours = (totalHours: any, status: any) => {
        debugger
        if (status == "On Leave" || status == "On Holiday") {
            return '#D0D3D4';
        } else if (status === "Absent" || !totalHours) {
            return "#EB445A";
        } else {
            // Convert totalHours to string if it's not already
            //totalHours = typeof totalHours === 'number' ? totalHours.toString() : totalHours;

            // If totalHours is in the format of a single number, convert it to HH:MM format
            if (totalHours?.length == 2) {
                totalHours = totalHours + ':00';
            }

            if (typeof totalHours !== 'string' || totalHours === null) {
                return '';
            }
            const [hours, minutes] = totalHours.split(':').map(Number);
            if (isNaN(hours) || isNaN(minutes)) {
                return '';
            }
            const totalInMinutes = hours * 60 + minutes;
            let abc = totalInMinutes >= 360 && totalInMinutes < 480 ? "#FF8C00" // Orange( if value is less than 8 shortleave)
                : totalInMinutes < 240 ? '#EB445A' // Red(If value is less than 4 hrs  Absent)
                    : totalInMinutes >= 240 && totalInMinutes < 360 ? '#ffc107' // Yellow(If value is greater than 4 hrs and less than 6 hrs halfday) 
                        : totalInMinutes >= 480 ? '#2DD36F' : ""; // Green Present
            return abc;
        }
    };
    const convertJsonToExcel = (jsonData: Array<any>) => {
        const defaultCellValue = '--';
        const modifiedData1 = jsonData.map(({ departmentId, ...rest }: any) => rest);
        const modifiedData = modifiedData1.map((item: any) => {
            const newItem: any = {};
            Object.keys(item).forEach(key => {
                if (key === 'isLoggedInTracker') {
                    newItem[key] = item[key] ? 'Tracker' : 'Portal';
                } else {
                    newItem[key] = item[key] !== null ? item[key] : defaultCellValue;
                }
            });
            return newItem;
        });
        const worksheet = XLSX.utils.json_to_sheet(modifiedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, "Attendance Report" + '.xlsx');
    };
    const updateUserAttendanceSchedular = async () => {
        try {
            var req = {
                userId: resourceId,
                departmentId: departmentId,
                attendenceDate: startDate
              }
            await reportService.updateResourceDataSchedular(req)
                .then((response: any) => {
                    if (response.data.isError) {
                        setSchedularLoader(false)
                    }
                    else {
                        setSchedularLoader(false)
                    }
                });
        }
        catch (ex: any) {
            ex.data.Error.map((err: any) => {
                toast.warning(err);
               setSchedularLoader(false)
            });
        }
    }

    return (
        <>
            {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders1} />)}
            <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
            <Typography variant="h6" sx={{ mt:"20px", py:"20px" , fontWeight:'bold'}} >
            Attendance Report New
                        </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    sx={HrReporttop.topbar}
                >
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 0, sm: 2 } }}>
                        <Box sx={{ display: "flex", alignItems: "flex-end", gap: "32px", flexWrap: "wrap" }} >
                            <Box >
                                <Autocomplete
                                    disabled={disabledDepartments}
                                    disableClearable={departmentId && departmentId != "" ? false : true}
                                    value={
                                        departments.find(
                                            (option: any) => option.id == departmentId
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={departments}
                                    getOptionLabel={(option) => option.name}
                                    onChange={async (event, value) => {
                                        if (value) {
                                            setDepartmentId(value?.id ?? "")
                                            setResourceId("");
                                            setfiltersSelected(true);
                                            if (StaticStatusId == 0) {
                                                let resourceList = await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                                                let filteredResourcelist = resourceList.filter((resource: any) => (resource.userId != ""))
                                                setresources(filteredResourcelist);
                                            }
                                            else {
                                                getResourcesByDepartmentOrType(value?.id ?? "", StaticStatusId !== undefined ? (StaticStatusId ? 1 : 2) : 2);
                                            }
                                        }
                                        else {
                                            setresources([])
                                            setResourceId("");
                                            setStaticStatusId(0);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Department"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={departmentId}
                                            disabled={disabledDepartments}
                                            fullWidth
                                            sx={{ minWidth: 200, height: "40px" }}
                                        />
                                    )}
                                    sx={{
                                        "&.MuiAutocomplete-root": {
                                            margin: 0,
                                        }
                                    }}
                                />
                            </Box>

                            <Box>
                                <TextField
                                    sx={{ minWidth: 200, height: "40px" }}
                                    id="outlined-basic"
                                    select
                                    value={StaticStatusId}
                                    disabled={!departmentId || departmentId === ""}
                                    variant="outlined"
                                    onChange={
                                        handleStatusChange
                                    }
                                >
                                    {LoginType.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box >
                                <Autocomplete
                                    disabled={!departmentId || departmentId === ""}
                                    disableClearable={resourceId && resourceId != "" ? false : true}
                                    value={
                                        resources.find(
                                            (option: any) => option.userId == resourceId ?? ""
                                        ) ?? null
                                    }
                                    id="outlined-basic"
                                    options={resources}
                                    getOptionLabel={(option) => option.userName}
                                    onChange={async (event, value) => {
                                        setfiltersSelected(true);
                                        setResourceId(value?.userId ?? "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Resource"
                                            id="outlined-basic-departmentsId"
                                            name="element.name"
                                            variant="outlined"
                                            value={resourceId}
                                            fullWidth
                                            sx={{ minWidth: 200, height: "40px" }}
                                        />
                                    )}

                                />
                            </Box>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        borderRadius="semi-square"
                                        labelAlignment="center"
                                        style={{ width: "200px" }}
                                        minDate={moment().subtract(2, "years").toDate()} // Set minDate to 2 years ago
                                        maxDate={moment().subtract(1, "day").toDate()} // Set maxDate to today's date
                                        className="error"
                                        value={startDate}
                                        onChange={(newValue: any) => {
                                            setfiltersSelected(true)
                                            let dateT = getDate(newValue);
                                            setStartDate(dateT)

                                        }}

                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box>
                                <TextField
                                    sx={{ minWidth: 200, height: "40px" }}
                                    id="outlined-basic"
                                    select
                                    value={attendanceStatus}
                                    variant="outlined"
                                    onChange={async (value: any) => {
                                        setfiltersSelected(true);
                                        setAttendanceStatus(value.target.value ?? "");
                                    }}
                                >
                                    {AttendanceStatus.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField></Box>
                            <Box sx={{ fontSize: "14px", display: "flex", alignItems: "center" }}>Less then 8hrs <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }} /></Box>
                            <Button
                                onClick={() => {
                                    if (!!!departmentId && _authUser.typeOfUser != eTypeOfUser.Admin && disabledDepartments == true) {
                                        toast.warning("Please select Department First ")
                                    }
                                    else {
                                        getReport(departmentId, startDate, resourceId, StaticStatusId, checked, attendanceStatus);
                                    }
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Apply
                            </Button>
                            {filtersSelected && <Button
                                onClick={async () => {
                                    setfiltersSelected(false);
                                    setStaticStatusId(0);
                                    setResourceId("");
                                    setChecked(false);
                                    setAttendanceStatus(0)
                                    setStartDate(setDateWithoutTime(yesterday));
                                    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                        await setDepartmentId(_authUser.employeeInfo.departmentId);
                                        await getReport(_authUser.employeeInfo.departmentId, dateWithoutString(), "", 0, false, 0);
                                        let resourceList = await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId ?? "")
                                        let filteredResourcelist = resourceList.filter((resource: any) => (resource.userId != ""))
                                        setresources(filteredResourcelist);
                                    }
                                    else {
                                        setDepartmentId("");
                                        setreports([]);
                                        getReport("", dateWithoutString(), "", 0, false, 0);
                                    }
                                }}
                                color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                        </Box>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={1}
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            justifyContent="space-between"
                            sx={{ mt: 1, ml: 'auto' }}
                        >
                            <Stack direction={{ xs: "column", sm: "row" }}
                                spacing={1}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: theme.palette.success.main }}
                                    />{" "}
                                    Present
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        mx: 2,
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: '#FF8C00' }}
                                    />{" "}
                                    Short Leave
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        mx: 2,
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: theme.palette.warning.main }}
                                    />{" "}
                                    Half Day
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        mx: 2,
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: theme.palette.error.main }}
                                    />{" "}
                                    Absent
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 500,
                                        display: "flex",
                                        alignItems: "center",
                                        mx: 2,
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        sx={{ color: "#D0D3D4" }}
                                    />{" "}
                                    on Leave/On Holiday
                                </Typography>
                                <Box sx={{ ml: 'auto' }}>  <IconButton
                                    sx={{ mr: '20px' }}
                                    onClick={() => {
                                        convertJsonToExcel(reports);
                                    }}
                                    title="Download Billing Report"
                                    aria-label="Download Billing Report"
                                >
                                    <Icons.xlsxicon />
                                </IconButton></Box>

                                <Box sx={{ ml: 'auto' }}>  {!schedularLoader ? (
                                        <IconButton onClick={(e) => { 
                                            setSchedularLoader(true);
                                            updateUserAttendanceSchedular();
                                         }}> <Icons.RefreshBlue /></IconButton>
                                    ) : (
                                        <CircularProgress />)}</Box>
                            </Stack>
                            <span style={{ color: "#1DA7FF", fontWeight: "600" }}> Total Records: {reports.length}</span>
                        </Stack>

                    </Box>

                </Stack>
                {/* <Box sx={{
                    mt: 2,                   // Margin top for spacing
                    px: 2,                   // Horizontal padding
                    py: 0,                   // Vertical padding
                    backgroundColor: '#F0F9FF', // Light background color
                    borderRadius: '4px',     // Rounded corners

                    textAlign: 'right'       // Align text to the right
                }}>
                    <Typography variant="subtitle1" sx={{
                        fontWeight: 'bold', // Bold font weight
                        // Darker text color for contrast
                    }}>

                    </Typography>
                </Box> */}
                <Box sx={{ mt: 2 }}>
                    {reports && reports?.length > 0 && (
                        <>
                            <TableContainer component={Paper} elevation={0} sx={{
                                mt: 0.5,
                                maxHeight: "calc(100vh - 215px)",
                                [theme.breakpoints.down("sm")]: {
                                    maxWidth: "400px",
                                },
                            }}>
                                <Table stickyHeader aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ position: 'sticky', left: '0', width: '90px', zIndex: "10" }} >Emp ID</TableCell>
                                            <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', zIndex: "10" }}>Name</TableCell>
                                            <TableCell>Department</TableCell>
                                            <TableCell>Login Time</TableCell>
                                            <TableCell>LogOut Time</TableCell>
                                            <TableCell>Active Hrs</TableCell>
                                            <TableCell>Offline Hrs</TableCell>
                                            <TableCell>Un-Approved Hrs</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Total Hrs</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reports.map((row: any, index) => (
                                            <TableRow style={{ textDecoration: 'none' }}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    backgroundColor: convertTotalHours(row?.totalHours, row?.status)
                                                }}
                                            >
                                                <TableCell sx={{ position: 'sticky', left: '0', width: '90px', background: convertTotalHours(row?.totalHours, row?.status) }} >{row.employeeId}</TableCell>
                                                <TableCell sx={{ position: 'sticky', left: '77px', width: 'auto', background: convertTotalHours(row?.totalHours, row?.status) }}>{row.name}</TableCell>
                                                <TableCell >{row.department}</TableCell>
                                                <TableCell>{row?.loginTime || "--"}</TableCell>
                                                <TableCell>{row?.logoutTime || "--"}</TableCell>
                                                <TableCell>{row?.activeTime || "--"}</TableCell>
                                                <TableCell>{row?.offlineHours || "--"}</TableCell>
                                                <TableCell>{row?.unapprovedOfflineHours || "--"}</TableCell>
                                                <TableCell>{row?.isLoggedInTracker == false ? "Portal" : row?.isLoggedInTracker == true ? "Tracker" : "--" || "--"}</TableCell>
                                                {row?.compOffFor ? (
                                                    <TableCell sx={{ cursor: "pointer" }}>
                                                        <Tooltip arrow title={`Worked On ${moment(row.compOffFor).format("DD-MMM-YYYY")}`}>
                                                            <span>{row?.status || "--"}</span>
                                                        </Tooltip>
                                                    </TableCell>

                                                ) : (<TableCell>{row?.status || "--"}</TableCell>)}
                                                <TableCell>{row?.totalHours || "--"}</TableCell>
                                            </TableRow>))}
                                    </TableBody>
                                </Table>

                            </TableContainer>


                        </>
                    )}
                    {reports?.length === 0 && (
                        <NotFound NotfoundText="No Result Found" />
                    )}
                </Box>
            </Paper>

        </>
    );
}
