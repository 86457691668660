import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import theme from "../../../theme/theme";
import { Grid, Typography, Box } from "@mui/material";
import ReactQuill from "react-quill";
import AppConstants from "../../../config/AppConstants";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CommentBox from "../../../components/styledComponent/CommentBox";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import { issueManagementService } from "../../../service/issues-management-service";
import { commonFunctions } from "../../../service/common-services/common-functions";
import { toast } from "material-react-toastify";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const styleCommentIconOrange = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  background: theme.palette.warning.light,
  color: theme.palette.warning.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
};

const styleCommentIconGreen = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  background: theme.palette.success.light,
  color: theme.palette.success.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
};

const styles: any = {
  userAvatar: {
    justifyContent: "flex-end",
    "& .MuiAvatar-root": {
      textTransform: "Uppercase",
      // fontFamily: "sans-serif",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text,
      borderColor: "#fff",
      fontWeight: 500,
      width: "32px",
      height: "32px",
      cursor: "pointer",
      fontSize: 13,
      lineHeight: " 16px",
    },
  },
};

const styleCommentTitle = {
  color: theme.palette.primary.dark,
  fontWeight: 500,
  margin: 0,
};

const styleCommentdate = {
  color: theme.palette.grey[400],
  fontWeight: 500,
  fontSize: "0.75rem",
  margin: 0,
};

const styleCommentdesc = {
  width: "100%",
};

const styleCommenttext = {
  color: theme.palette.grey[600],
  fontWeight: 400,
  fontSize: "0.875rem",
  margin: 0,
  width: "100%",
};

const styleCommentEdit = {
  textTransform: "capitalize",
  fontWeight: 300,
  padding: "0px",
  fontSize: "0.875rem",
  "& .MuiButton-startIcon": {
    marginRight: "4px",
  },
};

export default function Comment({ TicketId, TicketcreatedBy }: any) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [comment, setComment] = useState<any>([]);
  const [commentvalue, setcommentvalue] = useState<any>("");
  //console.log("commentvalue", commentvalue);
  const [editId, setEditId] = useState("");
  const [currentEditValue, setEditValue] = useState("");
  const LocalcurreUserId = _authUser.id; //localStorage.getItem("userId");
  const [commentCurrentBy, setCommentCurrentBy] = useState("");
  const [buttonUpdateDisable, setUpdateButtonDisable] = useState(false);
  // console.log("buttonUpdateDisable", buttonUpdateDisable);
  const [isDescriptionFocused, setIsDescriptionFocused] = React.useState(false);
  // const [visible, setVisible] = useState(false);
  const [visible, setVisible] = useState<any>({});
  // const [disable, setDisable] = React.useState(false);
  const [disable, setDisable] = React.useState<any>({});
  var datacommentvalue = commentvalue;
  // var xyz = datacommentvalue.textValue.replace(/<(.|\n)*?>/g, "").trim();
  //console.log("datacommentvalue", datacommentvalue);
  useEffect(() => {
    GetComments();
    if (commentCurrentBy == "<p><br></p>") {
      setcommentvalue("");
    } else {
      setcommentvalue(commentCurrentBy);
    }

    //handleEditorQuillTag();
  }, []);

  const GetComments = async () => {
    // if (datacommentvalue === "<p><br></p>") {
    //   createComment("");
    // }
    await issueManagementService.GetComments(TicketId).then((response: any) => {
      if (response.data.isError) {
        // toast.error("Unable to load Comment, Please try again.")
      } else {
        setComment(response?.data ? response.data : []);
        setCommentCurrentBy(response?.data[0]?.description);
      }
    });
  };

  const deleteComment = async (id: any) => {
    await issueManagementService.deleteComment(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        toast.success(" Comment Deleted successfully.");
        GetComments();
      }
    });
  };

  const updateComment = async () => {
    var req = {
      id: editId,
      description: currentEditValue,
    };
    await issueManagementService.updateComment(req).then((response: any) => {
      if (response.data.isError) {
      } else {
        setEditValue(currentEditValue);
        toast.success("Comment Updated successfully.");
        GetComments();
        setEditId("");
        setEditValue("");
        setVisible(false);
      }
    });
  };

  const editComment = (comment: any) => {
    setEditId(comment.id);
    setEditValue(comment.description);
  };

  const handleDescription = (event: any, index: any) => {
    if (event == "<p><br></p>") {
      setUpdateButtonDisable(true);
      setEditValue("")
      //toast.error("Empty comment not allow");
    } else {
      setEditValue(event);
      setUpdateButtonDisable(false);
    }
  };

  const handleEditorQuillTag = () => {
    while (datacommentvalue.startsWith("<p><br></p>")) {
      datacommentvalue = datacommentvalue.replace("<p><br></p>", "");
    }
    while (datacommentvalue.endsWith("<p><br></p>")) {
      datacommentvalue = datacommentvalue.replace(
        new RegExp("<p><br></p>$"),
        ""
      );
    }
    setcommentvalue("");
  };
  function removeTagsAndAmp(str: any) {
    // Remove HTML tags
    str = str.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str.replace(/&amp;/g, '&');
    return str;
  }

  //console.log("xyz", xyz);
  const createComment = async (comment: any) => {
    handleEditorQuillTag();
    //   $('p').filter(function(){
    //     return this.innerHTML == '&nbsp;';
    //  }).remove();
    //console.log(`${commentvalue}`, "`<p>${commentvalue}</p>`");
    if (datacommentvalue != "" && datacommentvalue != "<p>&nbsp;</p>") {
      let req = {
        ticketId: TicketId,
        description: datacommentvalue,
      };
      // console.log("req", req);
      await issueManagementService.createComment(req).then((response: any) => {
        toast.success("Comment Added successfully.");
        setcommentvalue("");
        handleEditorQuillTag();
      });
    } else {
      setcommentvalue("");
    }
  };

  return (
    <>
      <Paper elevation={0}>
        <Typography variant="body2">
          <>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {/* <Stack sx={styleCommentIconGreen}>
                            </Stack> */}
              <Stack sx={styleCommentdesc}>
                <>
                  <Formik
                    initialValues={{
                      comment: commentvalue || "",
                    }}
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                      comment: Yup.string().required("comment is required"),
                    })}
                    onSubmit={async (values) => {
                      try {
                        await createComment(values);
                        GetComments();
                      } catch (err: any) {
                        console.error(err);
                        err = JSON.parse(JSON.stringify(err));
                        if (err.status == 400) {
                          {
                            err.data.Error.map((error: any, idx: any) =>
                              toast.error(error)
                            );
                          }
                        }
                      }
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <CommentBox
                          commentvalue={commentvalue?.replace(
                            /<p>(\s|(&nbsp))*<\/p>/gim,
                            ""
                          )}
                          setcomment={setcommentvalue}
                          validationmsg={
                            errors.comment && "comment is required"
                          }
                          handleEditorQuillTag={handleEditorQuillTag}

                          
                        />
                        {/* <p className="Error" style={{ color: "red" }}>
                          {errors.comment && "Comment is Required"}
                          <>{console.log("errors", errors)}</>
                        </p> */}

                        {comment?.map((value: any, idx: any) => {
                          return (
                            <>
                              <Stack
                                direction={{ xs: "column", sm: "row" }}
                                spacing={2}
                                sx={{ mb: 2, mt: 2 }}
                              >
                                {/* <Stack sx={styleCommentIconGreen}>
                                  {value.createdBy.charAt(0).toUpperCase()}
                                </Stack> */}
                                <AvatarGroup sx={[styles.userAvatar]}>
                                  <Avatar
                                    {...commonFunctions.stringAvatar(
                                      value?.createdBy
                                    )}
                                  />
                                </AvatarGroup>
                                <Stack sx={styleCommentdesc}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography sx={styleCommentTitle}>
                                      {value?.createdBy}
                                    </Typography>
                                    <Typography sx={styleCommentdate}>
                                      {value?.createdDate}
                                    </Typography>
                                  </Stack>
                                  {value?.id != editId && (
                                    <Typography
                                      sx={styleCommenttext}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          value.description.length > 0
                                            ? value.description
                                            : "",
                                      }}
                                    ></Typography>
                                  )}
                                  {value?.id == editId && (
                                    <Box
                                      sx={{
                                        mb: 2,
                                        "& img": { maxWidth: "100%" },
                                      }}
                                      className="ql-selector"
                                    >
                                      <ReactQuill
                                        key={value?.id}
                                        // modules={AppConstants.EditorSetting}
                                        theme="snow"
                                        value={
                                          currentEditValue?.replace(
                                            /<p>(\s|(&nbsp))*<\/p>/gim,
                                           ""
                                          )
                                            ? currentEditValue
                                            : ""
                                        }
                                        onChange={(value) =>
                                          handleDescription(value, idx)
                                        }
                                        onKeyPress={(ev) => {
                                          if (ev.key === "") {
                                            updateComment();
                                          }
                                        }}
                                        modules={{
                                          toolbar: [
                                            [{ header: "1" }, { header: "2" }, { font: [] }],
                                            [{ size: [] }],
                                            [
                                              "bold",
                                              "italic",
                                              "underline",
                                              "strike",
                                              "blockquote",
                                            ],
                                            [
                                              { list: "ordered" },
                                              { list: "bullet" },
                                              { indent: "-1" },
                                              { indent: "+1" },
                                              {imageHandler: "imageHandler"}, 
                                            ],
                                            ["link", "image", "video"],
                                            ["clean"],
                                          ],
                                          
                                        }}
                                      />
                                    </Box>
                                  )}

                                  {LocalcurreUserId ==
                                    comment[idx]?.createdById && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                      sx={{ mt: 1 }}
                                    >
                                      <>
                                        {visible?.[idx] ? (
                                          <Button
                                            size="small"
                                            startIcon={<EditIcon />}
                                            disabled={buttonUpdateDisable}
                                            sx={styleCommentEdit}
                                            onClick={() => [
                                              // setDisable(false),
                                              // setVisible(!visible),
                                              setVisible({ [idx]: true }),
                                              setDisable({ [idx]: false }),
                                              setUpdateButtonDisable(false),
                                              editComment(value),
                                              updateComment(),
                                            ]}
                                            color="warning"
                                          >
                                            Update
                                          </Button>
                                        ) : (
                                          <Button
                                            size="small"
                                            startIcon={<EditIcon />}
                                            sx={styleCommentEdit}
                                            disabled={disable?.[idx]}
                                            onClick={() => [
                                              // editComment(value),
                                              editComment(value),
                                              // setVisible(!visible),
                                              setVisible({ [idx]: true }),
                                              // setDisable(true),
                                              setDisable({ [idx]: true }),
                                            ]}
                                            color="success"
                                          >
                                            Edit
                                          </Button>
                                        )}

                                        <Button
                                          size="small"
                                          startIcon={<DeleteIcon />}
                                          sx={styleCommentEdit}
                                          onClick={() => {
                                            deleteComment(value?.id);
                                          }}
                                          color="error"
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    </Stack>
                                  )}
                                </Stack>
                              </Stack>
                            </>
                          );
                        })}
                      </form>
                    )}
                  </Formik>
                </>
              </Stack>
            </Stack>
          </>
        </Typography>
      </Paper>
    </>
  );
}
