import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import Notes from "../../../components/styledComponent/Note";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Chart } from "react-google-charts";
import theme from "../../../theme/darkTheme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { Department } from "../../../Interfaces/IDepartment";
import { clsResourcesList } from "../../../service/common-services/resources";
import { IResource } from "../../../Interfaces/IResource";
import {
  AttendenceReportFilter,
  HrReportFilter,
  HrReportFilterNew,
  IAttendanceReportResponse,
  IEmployeeAttendanceReportResponse,
  IHrReportResponse,
} from "../../../Interfaces/IReport";
import { reportService } from "../../../service/Reports/reports";
import { HrReportStatus } from "../../../Enum/eReports";
import { clsDepartmentList } from "../../../service/common-services/department";
import { Monts } from "../../../Enum/eMonths";
import { toast } from "material-react-toastify";
import Moment from "react-moment";
import moment from "moment";
import { useSelector } from "react-redux";
import AttendanceReportTable from "./AttendanceReportTable";
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete } from "@mui/material";
import { eTypeOfUser } from "../../../Enum/eTypeOfUser";
import InfoPopover from "../../../components/CommonInfoComponent";

const Loader = {
  position: "fixed",
  width: " 100%",
  height: " 100%",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.dark,
  zIndex: 9999,
  marginRight: "-50px",
};
const styles = {
  EmpStats: {
    fontWeight: 700,
    fontSize: " 36px",
  },
  CurrentAttendanceStatus: {
    fontWeight: 500,
    fontSize: "20px",
  },
  report: {
    display: "flex",
    alignItems: "center",
  },
  Label: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.palette.grey[700],
    marginLeft: "6px",
  },
  DateWrap: {
    backgroundColor: theme.palette.primary.light,
    padding: "24px",
    color: theme.palette.grey[700],
    minWidth: "140px",
    textAlign: "center",

    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  Day: {
    fontWeight: 500,
    fontSize: "14px",
  },
  Date: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: 1,
    color: theme.palette.primary.main,
  },
  present: {
    "&.MuiLinearProgress-root": {
      height: "22px",
      backgroundColor: theme.palette.warning.main,
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        borderRadius: " 20px",
        backgroundColor: theme.palette.success.main,
      },
    },
  },
};

export const dataAtt = [
  ["", "Absent", "Present", "Leave"],
  [250, 10, 80, 5],
];

export const optionsFilter = {
  bars: "horizontal",
  legend: { position: "none" },
  // width: 600,
  colors: ["#EB445A", "#2DD36F", "#FFC409"],
  maintainAspectRatio: false,
  barWidrth: 1,
  responsive: true,
};

export const data = [
  ["Task", "Hours per Day"],
  ["Total Employees", 11],
  ["Present", 2],
  ["Leave", 2],
  ["Absent", 2],
];

export const options = {
  legend: "none",
  chartArea: { width: "80%", height: "80%" },
  //labelStacking: "horizontal",
  slices: {
    0: { color: "#1DA7FF" },
    1: { color: "#2DD36F" },
    2: { color: "#FFC409" },
    3: { color: "#EB445A" },
  },
};

export default function AttendanceReportFilter() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ReportPermissionApp.ReportPermission
  );
  const calendar = useRef(null);
  const montsList = Object.keys(Monts);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-07"));
  let today = new Date();
  let pastDate = new Date();
  const [fromDate, setfromDate] = React.useState<Dayjs | null>(dayjs().date(1));
  const [toDate, settoDate] = React.useState<Dayjs | null>(dayjs(today));
  let FromDateTemp = fromDate;
  let toDateTemp = toDate;

  let objApifilter: AttendenceReportFilter = {
    department: "",
    resource: "",
    startDate: fromDate,
    endDate: toDate,
    status: "0",
  };
  const [Apifilter, setApifilter] =
    React.useState<AttendenceReportFilter>(objApifilter);

  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const hrReportStatus = Object.keys(HrReportStatus);
  const [reports, setreports] = React.useState<IAttendanceReportResponse[]>([]);
  let DepartmentId = "";
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [departmentId, setDepartmentId] = React.useState("");
  const [selectedDepartmentIndex, setselectedDepartmentIndex] =
    React.useState(0);
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const [departmentHook, setDepartmentHook] = React.useState("");
  const [reportDepartmentsEl, setreportDepartmentsEl] =
    React.useState<null | HTMLElement>(null);
  const openReportDepartmentsEl = Boolean(reportDepartmentsEl);
  const handleReportDepartmentsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportDepartmentsEl(event.currentTarget);
  };
  const handleReportDepartmentsClose = () => {
    setreportDepartmentsEl(null);
  };
  const handleDepartmentItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    await setDepartmentHook(departments[index].id);
    await setselectedDepartmentIndex(index);
    await setresources(
      await clsResourcesList.getResourcesDropdownList(departments[index].id)
    );
    setresourceHook("");
    DepartmentId = departments[index].id;
    await getReport(departments[index].id, resourceHook, fromDate, toDate);
  };

  var ResourceId = "";
  const [resources, setresources] = React.useState<IResource[]>([]);
  const [resourceId, setResourceId] = React.useState("");
  const [selectedResourceIndex, setselectedResourceIndex] = React.useState(0);
  const [resourceHook, setresourceHook] = React.useState("");
  const [reportResourcesEl, setreportResourcesEl] =
    React.useState<null | HTMLElement>(null);
  const openReportResourcesEl = Boolean(reportResourcesEl);
  const handleReportresourcesClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportResourcesEl(event.currentTarget);
  };
  const handleReportResourcesClose = () => {
    setreportResourcesEl(null);
  };
  const handleResourceItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    await setresourceHook(resources[index].userId);
    await setselectedResourceIndex(index);
    ResourceId = resources[index].userId;
    await getReport(departmentHook, resources[index].userId, fromDate, toDate);
  };

  const [reportStatusesHook, setreportStatusesHook] =
    React.useState("All Status");
  const [reportStatusesValueHook, setreportStatusesValueHook] =
    React.useState("0");
  const [reportStatusesEl, setreportStatusesEl] =
    React.useState<null | HTMLElement>(null);
  const openReportStatusesEl = Boolean(reportStatusesEl);
  const handleReportStatusesClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportStatusesEl(event.currentTarget);
  };
  const handleReportStatusesClose = () => {
    setreportStatusesEl(null);
  };

  const [viewFullCalander, setviewFullCalander] = React.useState(false);
  let month = "";
  const [selectedmonthsIndex, setselectedmonthsIndex] = React.useState(0);
  const [monthsHook, setmonthsHook] = React.useState("");
  const [monthsNameHook, setmonthsNameHook] = React.useState("Select Month");
  const [reportmonthsEl, setreportmonthsEl] =
    React.useState<null | HTMLElement>(null);
  const openReportmonthsEl = Boolean(reportmonthsEl);
  const handleReportmonthsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportmonthsEl(event.currentTarget);
  };
  const handleReportmonthsClose = () => {
    setreportmonthsEl(null);
  };
  const handlemonthsItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setmonthsHook(montsList[index]);
    setselectedmonthsIndex(index);
  };

  const setdatePeriod = (fdate: any, tdate: any, type: string = "fdate") => {
    if (fdate && tdate) {
      if (type == "fdate") {
        setfromDate(dayjs(fdate));
        let tdt = dayjs(new Date(dayjs(tdate).format("MM/DD/YYYY")));
        let fdt = dayjs(new Date(dayjs(fdate).format("MM/DD/YYYY")));
        let diff = tdt.diff(fdt, "days");
        if (diff < 0 || diff > 6) {
          if (
            dayjs(new Date()) <
            dayjs(
              new Date(new Date(fdate).setDate(new Date(fdate).getDate() + 6))
            )
          ) {
            settoDate(dayjs(new Date()));
          } else {
            settoDate(
              dayjs(
                new Date(new Date(fdate).setDate(new Date(fdate).getDate() + 6))
              )
            );
          }
        }
      }
      if (type == "tdate") {
        settoDate(dayjs(tdate));
        let tdt = dayjs(new Date(dayjs(tdate).format("MM/DD/YYYY")));
        let fdt = dayjs(new Date(dayjs(fdate).format("MM/DD/YYYY")));
        let diff = tdt.diff(fdt, "days");
        if (diff < 0 || diff > 6) {
          setfromDate(
            dayjs(
              new Date(new Date(tdate).setDate(new Date(tdate).getDate() - 6))
            )
          );
        }
      }
      setmonthsHook("");
      setmonthsNameHook("Select Month");
    } else {
      settoDate(dayjs(new Date()));
      setfromDate(
        dayjs(new Date(new Date().setDate(new Date().getDate() - 6)))
      );
    }
  };
  const setdatePeriodbyMonth = (value: string) => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), parseInt(value) - 1, 1);
    var lastDay = new Date(date.getFullYear(), parseInt(value), 0);
    if (value == "") {
      settoDate(dayjs(new Date()));
      setfromDate(
        dayjs(new Date(new Date().setDate(new Date().getDate() - 6)))
      );
    } else {
      setfromDate(dayjs(new Date(firstDay)));
      settoDate(dayjs(new Date(lastDay)));
      if (dayjs(lastDay) > dayjs(new Date())) {
        settoDate(dayjs(new Date()));
      }
    }
  };

  const BindReport = async (sDate: any, eDate: any) => {
    debugger
    await setfromDate(sDate);
    await settoDate(eDate);
    await getReport(departmentHook, resourceHook, sDate, eDate);
    setoutputModal([]);
  };
  interface eventProps {
    id: string;
    title: string;
    date: string;
    color: string;
    textColor: string;
  }
  const [events, setevents] = React.useState<eventProps[]>([]);
  const [toggleLoader, setToggleLoader] = React.useState(false);

  const getDateString = (dateObject: Date) => {
    if (dateObject == null) {
      return null;
    }
    let date = new Date(dateObject);
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T00:00:00Z';;
  }

  const getReport = async (
    departmentId: string,
    resourceId: string,
    fdate: any,
    tDate: any,
    reportStatus: string = "0"
  ) => {
    debugger
    setToggleLoader(true);
    let filter: AttendenceReportFilter = {
      department: departmentId,
      resource: resourceId,
      status: reportStatus,
      startDate: getDateString(fdate),
      endDate: tDate,
    };
    await reportService.GetAttendanceReport(filter).then((response: any) => {
      setToggleLoader(false);
      if (response.data.isError) {
      } else {
        setreports(response.data);
        setevents([]);
        let arr: eventProps[] = [];
        response.data.map((items: any, index: any) => {
          if (items.isWeekend === false && (!items.holiday || items.holiday === "")) {
            if (resourceId == "") {
              let dt = new Date(items.date).setMinutes(30);
              arr.push({
                id: "4",
                title: items.totalEmployee + " Total",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#1DA7FF",
                textColor: "white",
              });
            }
            if (resourceId == "" ||(resourceId != "" && items.presentCount > 0)) {
              let dt = new Date(items.date).setMinutes(0);
              arr.unshift({
                id: "1",
                title:
                  (resourceId == "" ? items.presentCount + " " : "") +
                  "Present",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#2DD36F",
                textColor: "white",
              });
            }
            if (
              resourceId == "" ||
              (resourceId != "" && items.leaveCount > 0)
            ) {
              let dt = new Date(items.date).setMinutes(10);
              arr.push({
                id: "2",
                title:
                  (resourceId == "" ? items.leaveCount + " " : "") + "Leave",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#FFC409",
                textColor: "white",
              });
            }
            if (
              resourceId == "" ||
              (resourceId != "" && items.absentCount > 0)
            ) {
              let dt = new Date(items.date).setMinutes(20);
              arr.push({
                id: "3",
                title:
                  (resourceId == "" ? items.absentCount + " " : "") + "Absent",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#EB445A",
                textColor: "white",
              });
            }
          } else {
            let dt = new Date(items.date).setMinutes(20);
            if (items.holiday && items.holiday !== "") {
              arr.push({
                id: "1",
                title: `Holiday: ${items.holiday}`,
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#7a7a7a",
                textColor: "white",
              });
            }
            if (items.isWeekend === true) {
              arr.push({
                id: "3",
                title: "Weekend",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#cdcdcd",
                textColor: "white",
              });
            }
            if (items.presentCount > 0) {
              arr.push({
                id: "2",
                title:
                  (resourceId == "" ? items.presentCount + " " : "") +
                  "Present",
                date: moment(dt).format("YYYY-MM-DD"),
                color: "#2DD36F",
                textColor: "white",
              });
            }
          }
        });
        setevents(arr);
      }
    });
  };
  // let emptyOutputModel: IHrReportResponse = {
  //   totalEmployee: 0,
  //   presentCount: 0,
  //   leaveCount: 0,
  //   absentCount: 0,
  //   listOutputModels: [],
  // };
  const [outputModal, setoutputModal] =
    React.useState<any>([]);
  const [selectedDateOutputModal, setselectedDateOutputModal] =
    React.useState("");
  const [selectedStatus, setselectedStatus] = React.useState("0");
  const showOulputModalDaywise = async (date: string, sts: string = "0") => {
    await getDateReportTable(date, sts);
    setselectedDateOutputModal(dayjs(date).format("MMM D, YYYY"));
    setselectedStatus(sts);
  };
  const getDateReportTable = async (day: string, sts: string) => {
    debugger
    var hrReportFilter: HrReportFilterNew = {
      department: departmentHook,
      resourceId: resourceHook,
      loginType: 0,
      status: sts,
      isLessThanEightHour: false,
      atttendaceDate: day,
    };
    await reportService
      .getPortalAndTrackerReport(hrReportFilter)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          debugger 
          console.log(response.data, "New report data");
          setoutputModal(response.data);
          var element = document.getElementById("divider");
          // console.log("element",element);
          element?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      });
  };

  React.useEffect(() => {
    if (_permission.page_ManageAttendenceReport_Access !== true) {
      navigation("/dashboard");
    }
    const departmentsData = async () => {
      if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
        if (
          _permission.action_AttendenceReport_ReportListing_AllDepartments_Access ==
          false
        ) {
          setdisabledDepartments(true);
        }
        setDepartmentHook(_authUser.employeeInfo.departmentId);
        setDepartmentId(_authUser.employeeInfo.departmentId);
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setresources(
          await clsResourcesList.getResourcesDropdownList(
            _authUser.employeeInfo.departmentId
          )
        );
        await getReport(
          _authUser.employeeInfo.departmentId,
          resourceHook,
          fromDate,
          toDate
        );
      } else {
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setresources(await clsResourcesList.getResourcesDropdownList(""));
        await setDepartmentHook("");
        await getReport("", "", fromDate, toDate);
      }
    };
    departmentsData();
  }, []);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>

        <Typography color="text.primary"> Report Management</Typography>
        <Typography color="text.primary"> Attendance Report</Typography>
      </Breadcrumbs>
      {toggleLoader && (
        <Box sx={Loader}>
          <FontAwesomeIcon icon={faSpinner} spinPulse size={"3x"} />
        </Box>
      )}
      <Paper elevation={0} sx={{ p: 3, maxWidth: '100%' }}>
      <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
            <InfoPopover  onInput={"Reports"}/>
            </Stack>
                <Box sx={{ flexGrow: 1 }} />
        {/* <Notes NoteText="Here, admin will see the various reports like Attendance report, Milestone report, Project report, Daily Attendance report, Work Diary and HR report." /> */}
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
          <Grid item xs={12} md={5} >
            <Typography
              variant="h2"
              sx={{
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "36px",
                color: theme.palette.primary.dark,
              }}
            >
              Attendance Report
            </Typography>

            
          </Grid>
          <Grid item xs={12} md={7} >
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1} alignItems={{ xs: "strech", sm: "center" }} >
              <Box>
                <Autocomplete
                  disabled={disabledDepartments}
                  disableClearable={
                    departmentHook && departmentHook != "" ? false : true
                  }
                  value={
                    departments?.find((option) => option.id == departmentId) ??
                    null
                  }
                  sx={{ ml: { xs: '0px', md: "15px" } }}
                  id="outlined-basic"
                  options={departments}
                  getOptionLabel={(option) => option.name}
                  onChange={async (event, value) => {
                    await setDepartmentHook(value?.id ?? "");
                    await setresources(
                      await clsResourcesList.getResourcesDropdownList(
                        value?.id ?? ""
                      )
                    );
                    setresourceHook("");
                    setResourceId("");
                    DepartmentId = value?.id ?? "";
                    await getReport(value?.id ?? "", "", fromDate, toDate);
                    setfiltersSelected(true);
                    setDepartmentId(value?.id ?? "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="outlined-basic"
                      name="element.name"
                      variant="outlined"
                      value={DepartmentId}
                      sx={{ minWidth: 220 }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  disabled={!departmentHook || departmentHook === ""}
                  value={
                    resources?.find(
                      (option) => option.userId == resourceId ?? ""
                    ) ?? null
                  }
                  disableClearable={
                    resourceId && resourceId != "" ? false : true
                  }
                  sx={{ ml: { xs: '0px', md: "15px" } }}
                  id="outlined-basic"
                  options={resources}
                  getOptionLabel={(option) => option.userName}
                  onChange={async (event, value) => {
                    setfiltersSelected(true);
                    setoutputModal([]);
                    setResourceId(value?.userId ?? "");
                    await setresourceHook(value?.userId ?? "");
                    ResourceId = value?.userId ?? "";
                    await getReport(
                      departmentHook,
                      value?.userId ?? "",
                      fromDate,
                      toDate
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="outlined-basic"
                      name="element.name"
                      variant="outlined"
                      value={DepartmentId}
                      sx={{ minWidth: 220 }}
                    />
                  )}
                />
              </Box>

              {filtersSelected && <Button
                onClick={async () => {
                  debugger
                  setfiltersSelected(false);
                  setResourceId("");
                  if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                    await setDepartmentId(_authUser.employeeInfo.departmentId);
                    await setDepartmentHook(_authUser.employeeInfo.departmentId);
                    await setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));

                  }
                  else {
                    setDepartmentId("");
                    await setDepartmentHook("");
                    await setresources(await clsResourcesList.getResourcesDropdownList(""));

                  }
                  setresourceHook("");
                  setreportStatusesValueHook("0");
                  setreportStatusesHook("All Status");
                  setfromDate(
                    dayjs(
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      )
                    )
                  );
                  settoDate(dayjs(today));
                  const api: any = calendar.current;
                  api?.getApi().today();
                  if(_authUser.typeOfUser != eTypeOfUser.Admin){
                    await getReport(
                      _authUser.employeeInfo.departmentId,
                      ResourceId,
                      dayjs(
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        )
                      ),
                      dayjs(today)
                    );
                  }else {
                    await getReport(
                      "",
                      ResourceId,
                      dayjs(
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        )
                      ),
                      dayjs(today)
                    );
                  }
                 
                  setoutputModal([]);
                }}
                color="warning"
                variant="contained"
                sx={{ ml: 0.5 }}
              >
                Clear
              </Button>
              }
            </Stack>
          </Grid>
        </Grid>
        {/* --------------------------------------------------------------------- */}

        {reports.length > 0 && (
          <>
            <Box sx={{
              "& .fc-header-toolbar": {
                flexDirection: { xs: 'column', md: 'row' },
                my: 2,
              }
            }}>
              <FullCalendar
                ref={calendar}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev today next",
                  center: "title",
                  right: "dayGridMonth timeGridWeek timeGridDay",
                }}
                initialView="dayGridMonth"
                editable={false}
                selectable={true}
                selectMirror={false}
                dayMaxEvents={true}
                events={events}
                eventClick={(arg) => {
                  if (
                    arg.event._def.title.includes("Present") ||
                    arg.event._def.title.includes("Leave") ||
                    arg.event._def.title.includes("Absent") ||
                    arg.event._def.title.includes("Total")
                  ) {
                    let sts = arg.event._def.title.includes("Present")
                      ? "1"
                      : arg.event._def.title.includes("Leave")
                        ? "3"
                        : arg.event._def.title.includes("Absent")
                          ? "2"
                          : "0";
                          showOulputModalDaywise(
                            arg.event._instance?.range.start
                              ? moment(arg.event._instance.range.start).format("YYYY-MM-DD") + "T00:00:00.000Z"
                              : moment(today).format("YYYY-MM-DD") + "T00:00:00.000Z",
                            sts
                          );
                          
                  }
                }}
                dateClick={(arg) => {
                  return false;
                  // showOulputModalDaywise(arg.dateStr);
                }}
                customButtons={{
                  today: {
                    text: "Today",
                    click: async function (arg) {
                      const api: any = calendar.current;
                      api?.getApi().today();
                      var dateRange: any =
                        api._calendarApi.currentDataManager.data.dateProfile
                          .currentRange;
                      if (dateRange.start <= dayjs()) {
                        await BindReport(
                          dateRange.start,
                          dateRange.end > dayjs()
                            ? dayjs()
                            : dayjs(dateRange.end).add(-1, "day")
                        );
                      }
                    },
                  },
                  prev: {
                    text: "Prev",
                    click: async function (arg) {
                      const api: any = calendar.current;
                      api?.getApi().prev();
                      var dateRange: any =
                        api._calendarApi.currentDataManager.data.dateProfile
                          .currentRange;
                      if (dateRange.start <= dayjs()) {
                        await BindReport(
                          dateRange.start,
                          dateRange.end > dayjs()
                            ? dayjs()
                            : dayjs(dateRange.end).add(-1, "day")
                        );
                      }
                    },
                  },
                  next: {
                    text: "Next",
                    click: async function (arg) {
                      const api: any = calendar.current;
                      api?.getApi().next();
                      var dateRange: any =
                        api._calendarApi.currentDataManager.data.dateProfile
                          .currentRange;
                      if (dateRange.start <= dayjs()) {
                        await BindReport(
                          dateRange.start,
                          dateRange.end > dayjs()
                            ? dayjs()
                            : dayjs(dateRange.end).add(-1, "day")
                        );
                      }
                    },
                  },
                  dayGridMonth: {
                    text: "Month",
                    click: async function (arg) {
                      const api: any = calendar.current;
                      api?.getApi().changeView("dayGridMonth");
                      var dateRange: any =
                        api._calendarApi.currentDataManager.data.dateProfile
                          .currentRange;
                          if (dateRange.start <= dayjs()) {
                            await BindReport(
                              dateRange.start,
                              dateRange.end > dayjs() ? dayjs() : dateRange.end
                            );
                          }
                      // await BindReport(
                      //   dateRange.start,
                      //   dateRange.end > dayjs() ? dayjs() : dateRange.end
                      // );
                    },
                  },
                  timeGridWeek: {
                    text: "Week",
                    click: async function (arg) {
                      debugger
                      const api: any = calendar.current;
                      api?.getApi().changeView("timeGridWeek");
                      var dateRange: any =
                        api._calendarApi.currentDataManager.data.dateProfile
                          .currentRange;
                          if (dateRange.start <= dayjs()) {
                            await BindReport(
                              dateRange.start,
                              dateRange.end > dayjs() ? dayjs() : dateRange.end
                            );
                          }
                      // await BindReport(dateRange.start, dateRange.end > dayjs() ? dayjs() : dateRange.end
                      // );
                    },
                  },
                  timeGridDay: {
                    text: "Day",
                    click: async function (arg) {
                      const api: any = calendar.current;
                      api?.getApi().changeView("timeGridDay");
                      var dateRange: any =
                        api._calendarApi.currentDataManager.data.dateProfile
                          .currentRange;
                          if (dateRange.start <= dayjs()) {
                            await BindReport(dateRange.start, dateRange.end);
                          }
                   
                    },
                  },
                }}
              />
            </Box>

            <div id="divider"></div>
            {outputModal &&
              outputModal?.length > 0 && (
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: 500,
                      fontSize: "22px",
                      lineHeight: "36px",
                      color: theme.palette.primary.dark,
                      mt: 3,
                    }}
                  >
                    Attendance Detail (
                    {selectedStatus === "0"
                      ? "Total"
                      : selectedStatus === "1"
                        ? "Present"
                        : selectedStatus === "2"
                          ? "Absent"
                          : "Leaves"}
                    ) on {selectedDateOutputModal}
                  </Typography>

                  <AttendanceReportTable
                    tableData={outputModal}
                  ></AttendanceReportTable>
                </>
              )}
          </>
        )}
      </Paper>
    </>
  );
}
