import React from 'react';
// import AddNewRole from './AddNewRole';
import AllRole from './AllRole'

export default function RoleManagement() {
    return (
        <>
            <AllRole />
        </>
    );
}




