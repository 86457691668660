import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Tab,
  Tabs,
  Box,
  Paper,
  TableSortLabel,
  styled,
  Chip,
  ChipProps,
  TablePagination,
  Autocomplete,
  Checkbox,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import theme from "../../theme/theme";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import AppConstants from "../../config/AppConstants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { leaveService } from "../../service/Leave/LeaveService";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { visuallyHidden } from "@mui/utils";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { date } from "yup";
import moment from "moment";
import * as Yup from "yup";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RequestUpdate from "./RequestUpdate";
import AcceptReject from "./AcceptReject";
import RequestUpdateAR from "./RequestUpdateAR";
import ViewReject from "./ViewReject";
import HolidayList from "./HolidayList";
import LeavesRequest from "./LeavesRequest";
import ViewHistory from "./ViewHistory";
import dayjs, { Dayjs } from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import AddLeaveRequest from "./AddLeaveRequest";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Close";
import { Divider, makeStyles } from "@material-ui/core";
import { clsResourcesList } from "../../service/common-services/resources";
import { Department } from "../../Interfaces/IDepartment";
import { IResource } from "../../Interfaces/IResource";
import { HrReportFilter, IHrReportResponse } from "../../Interfaces/IReport";
import { reportService } from "../../service/Reports/reports";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import { clsDepartmentList } from "../../service/common-services/department";
import { eLeaveTabs } from "../../Enum/eLeaveTabs";
import { eLeaveStatus, eLeaveType } from "../../Enum/eLeaveStatus";
import Leavepagestyle from "./Leavemanagementstyle";
import InfoPopover from "../../components/CommonInfoComponent";
import NotFound from "../../components/styledComponent/NotFound";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import { AbcOutlined } from "@mui/icons-material";


const useStyles = makeStyles({
  customTableContainer: {
    overflowX: "initial",
  },
});
const headcellcolor = {
  cellcolor: {
    background: 'rgb(189, 189, 189)',
    borderBottom: "none"
  },
}

interface Leave {
  type: string;
  durationType: string;
  duration: string;
  startDate: any;
  endDate: any;
  reason: string;
  startTime: string;
  endTime: string;
  compOffFor: string;
  isEarnedAdvance:boolean
}

interface LeaveStatus {
  id: string;
  status: string;
}

interface Data {
  employeeId: string;
  employeeName: string;
  type: string;
  durationType: string;
  duration: string;
  startdate: string;
  enddate: string;
  submittedOn: string;
  approvedOn: string;
  reason: string;
  id: string;
  status: string;
  srNo: string;
  ispast: boolean;
  updateStartDate:string;
  updateEndDate:string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingComparator1<T>(a: T, b: T, orderBy1: keyof T) {
  if (b[orderBy1] < a[orderBy1]) {
    return -1;
  }
  if (b[orderBy1] > a[orderBy1]) {
    return 1;
  }
  return 0;
}

const styles: any = {
  cardLeaves: {
    borderRadius: "10px",
    padding: "22px 18px",
    height: '100%',
  },
  titleLeave: {
    color: "#1F944E",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    marginBottom: "17px",
  },
  annualLeaves: {
    color: "#1F944E",
  },
  sickLeaves: {
    color: "#B38906",
  },
  casualLeaves: {
    color: "#16B5BF",
  },
  compOff: {
    color: "#1DA7FF",
  },
  otherLeaves: {
    color: "#74C225",
  },
  total: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#424242",
  },
  values: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: " #424242",
  },
  taken: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: " 18px",
  },
  takenvalues: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: " #757575",
  },
  applyLeave: {
    backgroundColor: "#F0F9FF",
    borderRadius: "10px",
    padding: "22px 18px",
    maxWidth: { xs: '100%', md: "412px" },
    marginLeft: "auto",
  },
  applyLeaveHeading: {
    color: "#232360",
    fontWeight: 500,
    fontSize: " 26px",
  },
  selectType: {
    backgroundColor: "#fff",
    marginTop: "10px",
  },
  selectlabel: {
    fontWeight: 400,
    fontSize: " 14px",
    lineHeight: "21px",
    marginLeft: 0,
    marginBottom: "6px",
    color: "#212121",
  },
  spaceTop: {
    marginTop: "24px",
  },
  AnnualLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: "linear-gradient(90deg, #2DD36F 50%, #FFBB0B 78.73%)",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  SickLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: " linear-gradient(90deg, #B38906 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  CasualLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: " linear-gradient(90deg, #15B5BF 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  CompLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: "linear-gradient(90deg, #1DA7FF 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  OtherLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: "linear-gradient(90deg, #74C225 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
    labelLeft: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: " 21px",
      /* Primary Color/Primary_dark shade */
      color: theme.palette.primary.dark,
      width: "150px",
      minWidth: "130px",
    },
    valueRight: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: " 21px",
      /* Primary Color/Primary_dark shade */
      color: " #424242",
      flexGrow: 1,
    },
  },
};

const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
  color: theme.palette.success.dark,
  backgroundColor: theme.palette.success.light,
  fontWeight: 400,
  fontSize: 14,
}));

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface HeadCell1 {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "startdate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "enddate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "submittedOn",
    numeric: false,
    disablePadding: false,
    label: "Submitted On",
  },
  {
    id: "approvedOn",
    numeric: false,
    disablePadding: false,
    label: "Approved On",
  },
];
const headCells1: readonly HeadCell1[] = [
  {
    id: "employeeId",
    numeric: false,
    disablePadding: false,
    label: "Emp ID",
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "startdate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "enddate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
  },
  {
    id: "duration",
    numeric: false,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "submittedOn",
    numeric: false,
    disablePadding: false,
    label: "Submitted On",
  },
  {
    id: "approvedOn",
    numeric: false,
    disablePadding: false,
    label: "Approved On",
  },
  {
    id: "reason",
    numeric: false,
    disablePadding: false,
    label: "Reason",
  },
];

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any | any }, b: { [key in Key]: any | any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function getComparator1<Key extends keyof any>(
//   order1: Order,
//   orderBy1: Key
// ): (a: { [key in Key]: any | any }, b: { [key in Key]: any | any }) => number {
//   return order1 === "desc"
//     ? (a, b) => descendingComparator1(a, b, orderBy1)
//     : (a, b) => -descendingComparator1(a, b, orderBy1);
// }



// function getComparator1<Key extends keyof any>(
//   order1: Order,
//   orderBy1: Key
// ): (a: { [key in Key]: any | any }, b: { [key in Key]: any | any }) => number {
//   const ascendingComparator = (a : any, b : any) => a[orderBy1].localeCompare(b[orderBy1], undefined, { sensitivity: 'base' });
//   const descendingComparator = (a : any, b : any) => b[orderBy1].localeCompare(a[orderBy1], undefined, { sensitivity: 'base' });

//   if (orderBy1 === 'employeeName' || orderBy1 === 'employeeId') {
//     return order1 === 'desc' ? descendingComparator : ascendingComparator;
//   }

//   return order1 === 'desc'
//     ? (a, b) => descendingComparator1(a, b, orderBy1)
//     : (a, b) => -descendingComparator1(a, b, orderBy1);
// }

function getComparator1<Key extends keyof any>(
  order1: Order,
  orderBy1: Key
): (a: { [key in Key]: any | any }, b: { [key in Key]: any | any }) => number {
  const ascendingComparator = (a: any, b: any) => {
    if (!isNaN(Number(a[orderBy1])) && !isNaN(Number(b[orderBy1]))) {
      return Number(a[orderBy1]) - Number(b[orderBy1]);
    }
    return a[orderBy1].localeCompare(b[orderBy1], undefined, { sensitivity: 'base' });
  };

  const descendingComparator = (a: any, b: any) => {
    if (!isNaN(Number(a[orderBy1])) && !isNaN(Number(b[orderBy1]))) {
      return Number(b[orderBy1]) - Number(a[orderBy1]);
    }
    return b[orderBy1].localeCompare(a[orderBy1], undefined, { sensitivity: 'base' });
  };

  if (orderBy1 === 'employeeName' || orderBy1 === 'employeeId') {
    return order1 === 'desc' ? descendingComparator : ascendingComparator;
  }

  return order1 === 'desc'
    ? (a, b) => descendingComparator1(a, b, orderBy1)
    : (a, b) => -descendingComparator1(a, b, orderBy1);
}




interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface EnhancedTableProps1 {
  numSelected: number;
  onRequestSort1: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order1: Order;
  orderBy1: string;
  rowCount: number;
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function stableSort1<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order1 = comparator(a[0], b[0]);
    if (order1 !== 0) {
      return order1;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const getTimeZone = (dateObject: Date) => {
    let date = new Date(dateObject).toISOString();
    return date = date.slice(0, 10) + 'T00:00:00Z';
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        {/* {localStorage.getItem("statushide") === "0" && ( */}
        <TableCell>Status</TableCell>
        {/* )} */}
        {/* {(localStorage.getItem("statushide") === "1" && */}
        <TableCell>Action</TableCell>
        {/* )} */}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead1(props: EnhancedTableProps1) {
  const { order1, orderBy1, numSelected, rowCount, onRequestSort1 } = props;
  const createSortHandler1 =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort1(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells1.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy1 === headCell.id ? order1 : false}
            sx={{
              ...(headCell.id === "employeeId" && {
                position: "sticky",
                left: 0,
                zIndex: 999,
                background: "#F5F5F5"

              }),
              ...(headCell.id === "employeeName" && {
                position: "sticky",
                left: '102px',
                zIndex: 999,
                background: "#F5F5F5"

              }),
            }}
          >
            <TableSortLabel
              active={orderBy1 === headCell.id}
              direction={orderBy1 === headCell.id ? order1 : "asc"}
              onClick={createSortHandler1(headCell.id)}
            >
              {headCell.label}
              {orderBy1 === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order1 === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        {/* {localStorage.getItem("statushide") === "0" && ( */}
        <TableCell>Status</TableCell>
        {/* )} */}
        {/* {(localStorage.getItem("statushide") === "1" && */}
        <TableCell>Action</TableCell>
        {/* )} */}
      </TableRow>
    </TableHead>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.5 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Leaves() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.LeavePermissionApp.LeavePermission);
  const navigate = useNavigate();
  let obj: Leave = {
    type: "",
    durationType: "",
    duration: "",
    startDate: "",
    endDate: "",
    reason: "",
    startTime: "",
    endTime: "",
    compOffFor: "",
    isEarnedAdvance:false
  };
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState<Leave>(obj);
  const [durationType, setDurationType] = React.useState(
    formValues.durationType
  );
  const [type, setType] = React.useState(formValues?.type);
  const [typeFilter, setTypeFilter] = React.useState("Select Leave Type");
  const [statusFilter, setStatusFilter] = React.useState("All Leaves");
  const [duration, setDuration] = React.useState("Short Leave");
  const [startDate, setStartDate] = React.useState("");
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [startDateFilter, setStartDateFilter] = React.useState<any>(
    null
  );
  const [endDate, setEndDate] = React.useState("");
  const [reason, setReason] = React.useState(formValues?.reason);
  const [value, setValue] = React.useState(0);
  const [disableform, setState] = useState(3);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const [order1, setOrder1] = React.useState<Order>("asc");
  const [orderBy1, setOrderBy1] = React.useState<keyof Data>("srNo");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [leaveList, setLeaves] = useState<Data[]>([]);
  const pageCount = Math.ceil(leaveList.length / rowsPerPage);
  const pageCount1 = Math.ceil(leaveList.length / rowsPerPage1);
  const [buttonDisable1, setButtonDisable1] = useState(false);
  const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
  const [endTime, setEndTime] = React.useState<Dayjs | null>(null);
  const [futureLeaveCheckBox, setFutureLeaveCheckBox] = useState(false);

  const [openRequest, setOpenRequest] = React.useState(false);
  const [leaveCount, setLeaveCount] = useState<any>();
  const [reports, setreports] = React.useState<IHrReportResponse>()

  const eleaveStatus = Object.keys(eLeaveStatus);
  const eleaveType = Object.keys(eLeaveType);
  var IsLeaveRequest = false;
  const handleORClose = () => {
    //getAllLeaveManagementDetails( mtype, s, t,startDateFilter,0,100,"","");
    setOpenRequest(false);
  };

  const handleClickOpenOR = () => {
    setOpenRequest(true);
  };

  const handleCheckBoxChange = (value: boolean) => {
    setFutureLeaveCheckBox(prevValue => !prevValue);
};

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  const [showHelpText, setshowHelpText] = React.useState(false);
  let DepartmentId = "";
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [departmentsId, setDepartmentId] = React.useState("");
  const [resourceId, setresourceId] = React.useState("");
  const [selectedDepartmentIndex, setselectedDepartmentIndex] = React.useState(0);
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const [departmentHook, setDepartmentHook] = React.useState("");
  const [reportDepartmentsEl, setreportDepartmentsEl] = React.useState<null | HTMLElement>(null);
  const openReportDepartmentsEl = Boolean(reportDepartmentsEl);
  const handleReportDepartmentsClose = () => {
    setreportDepartmentsEl(null);
  };

  let ResourceId = "";
  const [resources, setresources] = React.useState<IResource[]>([]);
  const [resourceHook, setresourceHook] = React.useState("");
  const [selectedDepartmentId, setselectedDepartmentId] = React.useState("");

  const { tabValue } = useParams();
  const mindate = new Date().getFullYear().toString();

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  console.log(tabValue, "tabValue")
  const emptyRows =
    page > 10 ? Math.max(0, (1 + page) * rowsPerPage - leaveList.length) : 0;

  const emptyRows1 =
    page1 > 10 ? Math.max(0, (1 + page1) * rowsPerPage1 - leaveList.length) : 0;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRequestSort1 = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy1 === property && order1 === "asc";
    setOrder1(isAsc ? "desc" : "asc");
    setOrderBy1(property);
  };

  function getDate(dateObject: any): string {
    if (!dateObject || !(dateObject instanceof Date) || isNaN(dateObject.getTime())) {
      return '';
    }
    const year = dateObject.getFullYear();
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObject.getDate()).slice(-2);
    return year + '-' + month + '-' + day + 'T00:00:00Z';
  }



  //  const handleRequestSort1 = (

  //     event: React.MouseEvent<unknown>,
  //     property: keyof Data
  //   ) => {
  //     debugger;
  //     const isAsc = orderBy1 === property && order1 === "asc";
  //     const compare = (a: Data, b: Data) => {

  //       const aValue = typeof a[property] === "string" ? (a[property] as string).toLowerCase() : a[property];
  //       const bValue = typeof b[property] === "string" ? (b[property] as string).toLowerCase() : b[property];

  //       if (aValue < bValue) {
  //         return isAsc ? -1 : 1;
  //       }
  //       if (aValue > bValue) {
  //         return isAsc ? 1 : -1;
  //       }
  //       return 0;
  //     };


  //     const sortedData = stableSort1(leaveList, compare);
  //    console.log("sortedData ==", sortedData)

  //     setLeaves(sortedData);
  //     setOrder1(isAsc ? "desc" : "asc");
  //     setOrderBy1(property);
  //   };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleDurationType = (value: string) => {
    setDurationType(value);
  };

  const handleChangeLeaveType = (value: string) => {
    setFutureLeaveCheckBox(false)
    setType(value);
  };

  const handleChangeLeaveTypeFilter = (value: string) => {
    setTypeFilter(value);
    console.log(value);
    // getAllLeaveManagementDetails("manager",statusFilter, value, startDateFilter, resourceId, departmentHook);
  };

  const handleClearFilter = () => {
    setPage(0);
    setPage1(0);
    setfiltersSelected(false);
    setStatusFilter("All Leaves");
    setTypeFilter("Select Leave Type");
    var userID = _authUser.id;
    setStartDateFilter(null);
    setresourceHook("");
    let departmentId = "";
    // if (_authUser.typeOfUser != eTypeOfUser.Admin) {
    //   departmentId = _authUser.employeeInfo.departmentId;
    // }
    // getAllLeaveManagementDetails("user", statusFilter, typeFilter, null, userID, departmentHook);
  };

  const handleChangeLeaveStatusFilter = (value: any) => {
    setStatusFilter(value);
    console.log(value)
    // getAllLeaveManagementDetails("manager",value, typeFilter ,startDateFilter,resourceHook,departmentHook); 
  };

  const handleChangeLeaveStartDateFilter = (value: any) => {
    setStartDateFilter(value);
    // getAllLeaveManagementDetails("manager",statusFilter, typeFilter ,value,resourceHook,departmentHook); 
  };

  const handleClearForAddLeave = () => {
    setStartDate("");
    setEndDate("");
    setReason("");
    setStartTime(null);
    setEndTime(null);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };


  // const getDate = (dateObject: any) => {
  //   let date = new Date(dateObject).toISOString();
  //   return date?.slice(0, 10) + 'T00:00:00Z';
  // }

  const handleFeildDisable = (value: any) => {
    handleClearForAddLeave();
    setshowHelpText(false);
    if (value === "Half Day") {
      setState(0);
      //setDurationType('2nd half')
      setDuration(value);
      var date = new Date();
      // setStartDate(date)
      //setStartDate(date)
    } else if (value === "Full Day") {
      setState(1);
      //setDurationType('1st half')
      setDuration(value);
    } else if (value === "Short Leave") {
      setState(3);
      setType("Short Leave");
      //setDurationType('1st half')
      setDuration(value);
    }
  };

  const handleCancel = () => {
    window.location.reload();
  };


  const getAllLeaveManagementDetails = async (
    type: any,
    status: any,
    leaveType: any,
    startDate: any,
    resourceId: string,
    departmentsId: string
  ) => {

    let indexOfStatusKey = Object.keys(eLeaveStatus).indexOf(status);
    let valueOfStatus = Object.values(eLeaveStatus)[indexOfStatusKey];

    let indexOfLeaveTypeKey = Object.keys(eLeaveType).indexOf(leaveType);
    let valueOfLeaveType = Object.values(eLeaveType)[indexOfLeaveTypeKey];
    console.log("IsLeaveRequest", IsLeaveRequest);
    let values = {
      Type: type,
      Status: valueOfStatus,
      Skip: 0,
      Take: 1000,
      LeaveType: valueOfLeaveType,
      Resource: resourceId,
      StartDate: startDate ? dayjs(startDate).format("MM/DD/YYYY") : "",
      DepartmentId: departmentsId
    };
    setLeaves([]);
    await leaveService
      .getAllLeaveManagementDetails(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setLeaves(response.data);
        }
      });
  };

  const GetLeaveAvailedByUserId = async () => {
    await leaveService.GetLeaveAvailedByUserId().then((response: any) => {
      if (response.data.isError) {
      } else {
        setLeaveCount(response.data);
      }
    });
  };

  const addleave = async (LeaveInfo: any) => {
    try {
      var starttimef = "";
      var endtimef = "";

      let obj: Leave = {
        durationType: durationType,
        type: type,
        duration: duration,
        startDate: startDate,
        endDate: endDate,
        reason: reason,
        startTime: starttimef,
        endTime: endtimef,
        compOffFor: "",
        isEarnedAdvance:false
      };
      if (obj.type === "Comp Off Leave") {
        debugger
        obj.compOffFor = getDate(obj.startDate);
        obj.endDate = endDate;
        obj.startTime = "";
        obj.endTime = "";
        obj.startDate = endDate;
      }
      if (obj.duration === "Half Day") {
        let date = new Date();
        let strDate: string = date.toUTCString();
        obj.startDate = startDate;
        obj.endDate = startDate;
        obj.startTime = "";
        obj.endTime = "";
      }
      if (obj.duration === "Short Leave") {
        let date = new Date();
        obj.type = "Casual Leaves";
        let strDate: string = date.toUTCString();
        obj.startDate = startDate;
        obj.endDate = startDate;
        obj.endTime = dayjs(endTime).format("hh:mm A");
        obj.startTime = dayjs(startTime).format("hh:mm A");
      }

      if (type === "Marriage Leave") {
        obj.startDate = getDate(obj.startDate);
      } else {
        obj.startDate = getDate(obj.startDate);
        obj.endDate = getDate(obj.endDate);
      }

      //obj.endDate = getDate(obj.endDate);


      let selectedDays;
      if (duration === "Half Day") {
        selectedDays = 0.5;
    } else if (duration === "Short Leave") {
        selectedDays = moment(startDate).add(1, 'days').diff(startDate, 'days');
    } else {
        selectedDays = moment(endDate).add(1, 'days').diff(startDate, 'days');
    }

      // const selectedDays = moment(endDate).add(1, 'days').diff(startDate, 'days');

      if (type === 'Sick Leave' || type === 'Casual Leaves' || type === 'Earned Leave') {
        if (
          (type == 'Sick Leave' || type == 'Casual Leaves') && selectedDays <= leaveCount?.leaveBalance ||
          type == 'Earned Leave' && selectedDays <= leaveCount?.earnedLeaveBalance ||
          (type === 'Earned Leave' && selectedDays > leaveCount?.earnedLeaveBalance && futureLeaveCheckBox))
         {
          if(type === 'Earned Leave' && selectedDays > leaveCount?.earnedLeaveBalance && futureLeaveCheckBox){
            obj.isEarnedAdvance = true;
          }
          await leaveService.addleave(obj).then(async (response: any) => {
            if (response.data.isError) {
              toast.error("Something went wrong...");
            } else {
              toast.success("Leave added successfully.", {
                onOpen: () => setButtonDisable1(true),

                onClose: () => setButtonDisable1(false),
              });
              setTimeout(handleCancel, 270);
            }
          });

        } else {
          toast.warning(`You don't have enough balance leaves to apply`)
        }

      }
      else {
        await leaveService.addleave(obj).then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            toast.success("Leave added successfully.", {
              onOpen: () => setButtonDisable1(true),

              onClose: () => setButtonDisable1(false),
            });
            setTimeout(handleCancel, 270);
          }
        });
      }

    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        //toast.warning(err);
        if(type === "Comp Off Leave" && err=="Leave already applied for this start date and end date.")
        {
          toast.warning("Leave Already exist for this day.", {
            onOpen: () => setButtonDisable1(true),
  
            onClose: () => setButtonDisable1(false),
          });
        }
        else{
        toast.warning(err, {
          onOpen: () => setButtonDisable1(true),

          onClose: () => setButtonDisable1(false),
        });}
      });
    }
  };

  const updateLeaveWithdrwanStatus = async (id: any) => {
    try {
      await leaveService.deletewithdrawn(id).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          toast.success("Leave withdrawn successfully.");
          var userID = _authUser.id;
          //getAllLeaveManagementDetails(userID, "", "", "",startDateFilter,0,100,resourceId,departmentsId);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const getReport = async (
    department: string = "", resource: string = "", status: string = ''
  ) => {
    var hrReportFilter: HrReportFilter = {
      department: department, resource: resource, status: status == '' ? "0" : resource,
      day: null
    };
    await reportService.GetDailyAttendance(hrReportFilter)
      .then((response: any) => {
        if (response.data.isError) {
        }
        else {
          setreports(response.data);
        }
      });
  }

  useEffect(() => {
    if (_permission.page_Leave_Management_Access !== true) {
      navigate("/dashboard");
    }
    if (tabValue) {
      debugger
      if(_authUser.typeOfUser === 1 || _authUser.typeOfUser === 2)
        {
          getAllLeaveManagementDetails("manager", "", "", "", "", _authUser.employeeInfo.departmentId);
          setValue(2)
        }
        else {
          getAllLeaveManagementDetails("user", "", "", "", "", _authUser.employeeInfo.departmentId);
          setValue(1)
        }
    }
    GetLeaveAvailedByUserId();
    var curreUserRole = _authUser.typeOfUser;
    const departmentsData = async () => {
      if (_authUser.typeOfUser != eTypeOfUser.Admin) {
        if (_permission.action_ProjectReport_ReportListing_AllDepartments_Access == true) {
          setdisabledDepartments(false);
        } else {
          setdisabledDepartments(true);
        }
        setselectedDepartmentId(_authUser.employeeInfo.departmentId);
        setDepartmentHook(_authUser.employeeInfo.departmentId);
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
      } else {
        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
        setresources(await clsResourcesList.getResourcesDropdownList(""));
        //getReport(departmentHook, resourceHook, reportStatusesValueHook);
      }
    };
    departmentsData();
  }, []);

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const [openRU, setOpenRU] = React.useState(false);
  const [openRUS, setOpenRUS] = React.useState(false);
  const [openAR, setOpenAR] = React.useState(false);
  const [openVR, setOpenVR] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [leaveId, setLeaveId] = React.useState("");

  const handleClickOpenRU = (id: any) => {
    setOpenRU(true);
    setLeaveId(id);
  };

  const handleCloseRU = () => {
    setOpenRU(false);
    var userID = _authUser.id;
    getAllLeaveManagementDetails("manager", statusFilter, typeFilter, startDateFilter, userID, departmentHook);
  };

  const handleClickOpenRUS = (id: any) => {
    setOpenRUS(true);
    setLeaveId(id);
  };
  const handleCloseRUS = () => {
    setOpenRUS(false);
    getAllLeaveManagementDetails("manager", statusFilter, typeFilter, startDateFilter, resourceHook, departmentHook);
  };

  const handleCloseVR = () => {
    setOpenVR(false);
    //getAllLeaveManagementDetails("manager",statusFilter, typeFilter ,startDateFilter,resourceHook,departmentHook);
  };

  const handleClickOpenVR = (id: any) => {
    setOpenVR(true);
    setLeaveId(id);
  };

  const handleClickOpenHistory = (id: any) => {
    setOpenHistory(true);
    setLeaveId(id);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
    var userID = _authUser.id;
    getAllLeaveManagementDetails("manager", statusFilter, typeFilter, startDateFilter, userID, departmentHook);
  };

  const handleClickOpenAR = (id: any) => {
    setOpenAR(true);
    setLeaveId(id);
  };
  const handleCloseAR = () => {
    setOpenAR(false);
    getAllLeaveManagementDetails("manager", statusFilter, typeFilter, startDateFilter, resourceHook, departmentHook);
  };

  const handlehistory = () => {
    setStartDate("");
    setEndDate("");
    setReason("");
    setStartTime(null);
    setEndTime(null);
    var userID = _authUser.id;
    handleClearFilter();
    IsLeaveRequest = false;
    getAllLeaveManagementDetails("user", "", "", null, userID, departmentHook);
    setState(3);
  };

  const handleApprove = async () => {
    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
      setDepartmentId(_authUser.employeeInfo.departmentId);
      setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
    } else {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setresources(await clsResourcesList.getResourcesDropdownList(""));
    }
    setfiltersSelected(false)
    setStartDate("");
    setEndDate("");
    setReason("");
    setStartTime(null);
    handleClearFilter();
    setEndTime(null);
    IsLeaveRequest = true;
    getAllLeaveManagementDetails("manager", "", "", "", "", departmentHook);
  };

  const handleBalance = () => {
    setStartDate("");
    setEndDate("");
    setReason("");
    setStartTime(null);
    setEndTime(null);
  };

  ////////Pagination Programming
  const [pageLC, setPageLC] = React.useState(0);
  const [rowsPerPageLC, setRowsPerPageLC] = React.useState(10);

  const handleChangePageLC = (event: unknown, newPage: number) => {
    setPageLC(newPage);
  };
  const handleChangeRowsPerPageLC = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageLC(+event.target.value);
    setPageLC(0);
  };
  ////////over

  const [dptmentWiseLeaveBalance, setDptmentWiseLeaveBalance] = useState<any[]>([]);
  const pageCount2 = Math.ceil(dptmentWiseLeaveBalance.length / rowsPerPageLC);
  let date = new Date();
  let year = date.getFullYear();

  const GetDeaprtmentWiseLeaveBalance = async (depId: any, resId: any) => {
    let abc = {
      year: year,
      departmentId: depId,
      resource: resId,
    }
    try {
      await leaveService.GetDeaprtmentWiseLeaveBalance(abc).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          setDptmentWiseLeaveBalance(response?.data);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const handleTeamLeaveCard = async () => {
    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
      setDepartmentId(_authUser.employeeInfo.departmentId);
      setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
      GetDeaprtmentWiseLeaveBalance(_authUser.employeeInfo.departmentId, '');
    } else {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      setresources(await clsResourcesList.getResourcesDropdownList(""));
    }
  }


  const fieldValidationSchema = Yup.object().shape({
    type:
      disableform == 3
        ? Yup.string()
        : Yup.string().required("Leave Type is required"),
    durationType:
      duration == "Half Day"
        ? Yup.string().required("Duration half is required")
        : Yup.string(),
    // duration: (duration == "Half Day" ? Yup.string().required('Duration half is required') : Yup.string()),
    reason: Yup.string()
      .required("Reason is required"),
    //.max(100, "Reason must be at most 50 words"),
    endDate:
      duration == "Full Day"
        ? Yup.string().required("End date is required")
        : Yup.string(),
    startDate:
      duration == "Full Day" || disableform == 3 || disableform == 0
        ? Yup.string().required("Start date is required")
        : Yup.string(),
    startTime:
      duration == "Short Leave" || disableform == 3
        ? Yup.string().required("Start time is required")
        : Yup.string(),
  });
  return (
    <Formik
      initialValues={{
        durationType: "",
        type: "",
        duration: "",
        startDate: "",
        endDate: "",
        reason: "",
        startTime: "",
      }}
      validationSchema={fieldValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          debugger
          if (dayjs(values.endDate).isBefore(dayjs(values.startDate))) {
            if (values.type === "Comp Off Leave") {
              toast.warning("Worked on date must be less than Leave date");
            }
            else {
              toast.warning("Start date must be less than end date");
            }
            return false;
          }
          // if (values.endDate === "") {
          //     setSubmit(false)
          //     return false;
          // }

          await addleave(values);
        } catch (err: any) {
          console?.error(err);
          err = JSON.parse(JSON.stringify(err));
          if (err.status == 400) {
            {
              err.data.Error.map((error: any, idx: any) =>
                console.log(error)
              );
            }
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleReset,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/* <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
            <Link color="inherit" to="/">
              DashBoard
            </Link>
            <Typography color="text.primary">Leave management</Typography>
            <Typography color="text.primary">Leave management</Typography>
          </Breadcrumbs> */}

          <Paper elevation={0} sx={{ p: 3, pb: 2 }}>


            <Stack sx={Leavepagestyle.topbar}>
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600, }}>
                Leaves
              </Typography>
              <Box>
                <InfoPopover onInput={"Setup"} />
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              {_permission.page_Emergency_Leave_Request_Access === true && (
                <Button
                  // disabled={_authUser.typeOfUser=== 2 ? false:true}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpenOR}
                  sx={Leavepagestyle.emergencybtn}
                >
                  Emergency Leave
                </Button>
              )}
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 1.5 }} justifyContent="space-between" >
              {/* <Typography variant="h5" component="h2" sx={{ fontWeight: 600, }}>
                Leaves
              </Typography> */}
              {[0, (_permission.page_Leave_Request_Access === true ? 3 : 2),
                (_permission.page_Leave_Request_Access === true ? 4 : _permission.page_Leave_Card_Access === true ? 3 : 2)].includes(value) || (
                  <Stack
                    sx={Leavepagestyle.requesttopbar}>

                    <Stack direction={{ xs: 'column', lg: "column" }} spacing={2} justifyContent="space-between"
                      sx={{ mb: 2, width: '100%' }}>
                      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={value === eLeaveTabs.LeavesRequest ? 'space-between' : 'left'} spacing={2}>
                        {_permission.page_Leave_Request_Access === true && (value === eLeaveTabs.LeavesRequest) && (
                          <Box sx={[styles.filter]}>
                            <Autocomplete
                              value={
                                departments?.find(
                                  (option) => option.id == selectedDepartmentId
                                ) ?? null
                              }
                              //fullWidth

                              id="outlined-basic"
                              options={departments}
                              getOptionLabel={(option) => option.name}
                              disabled={disabledDepartments}
                              onClose={() => {
                                handleReportDepartmentsClose();
                              }}
                              onChange={async (event, value) => {
                                setselectedDepartmentId(value?.id ?? "");
                                setfiltersSelected(true);
                                setDepartmentHook(value?.id ?? "");
                                setresources(
                                  await clsResourcesList.getResourcesDropdownList(
                                    value?.id ?? ""
                                  )
                                );
                                DepartmentId = value?.id ?? "";
                                //  getAllLeaveManagementDetails("manager",statusFilter,typeFilter,startDateFilter,"",value?.id ?? "")
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  name="element.name"
                                  variant="outlined"
                                  value={selectedDepartmentId}
                                  fullWidth
                                  sx={{ minWidth: 220 }}
                                />
                              )}
                            />
                          </Box >)}
                        {/* <Box sx={{ flexGrow: {xs:'0', sm:"1"} }}></Box> */}
                        {_permission.page_Leave_Request_Access === true && (value === eLeaveTabs.LeavesRequest) && (
                          <Box sx={[styles.filter]}>
                            <Autocomplete
                              disabled={!departmentHook || departmentHook === ""}
                              value={
                                resources?.find(
                                  (option) => option.userId == resourceHook
                                ) ?? null
                              }

                              id="outlined-basic"
                              options={resources}
                              getOptionLabel={(option) => option.userName}
                              onChange={async (event, value) => {
                                setfiltersSelected(true);
                                setresourceHook(value?.userId ?? "");
                                //getAllLeaveManagementDetails("manager",statusFilter,typeFilter,startDateFilter,value?.userId ?? "",departmentHook)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  name="element.name"
                                  variant="outlined"
                                  value={resourceHook}
                                  fullWidth
                                  sx={{ minWidth: 220 }}
                                />
                              )}
                            />
                          </Box>)}
                        <Box>

                          <TextField
                            id="select-leave-type"
                            variant="outlined"
                            value={typeFilter}
                            select
                            fullWidth
                          >
                            {eleaveType.map((key, idx) => (
                              <MenuItem
                                key={key}
                                value={key}
                                onClick={(event) => {
                                  setfiltersSelected(true);
                                  handleChangeLeaveTypeFilter(key);
                                }}
                              >
                                {key}
                              </MenuItem>
                            ))}
                          </TextField>

                        </Box>

                        <Box>
                          <TextField
                            id="Statusfilter"
                            variant="outlined"
                            value={statusFilter}
                            select
                            fullWidth
                          >
                            {eleaveStatus.map((key, idx) => (
                              <MenuItem
                                key={key}
                                value={key}
                                onClick={(event) => {
                                  setfiltersSelected(true);
                                  handleChangeLeaveStatusFilter(key)
                                }}
                              >
                                {key}
                              </MenuItem>
                            ))}
                          </TextField>
                          {/* <TextField
                        id="Statusfilter"
                        placeholder="Select Type"
                        select
                        //  label="Select Type"
                        variant="outlined"
                        name="Statusfilter"
                        value={statusFilter}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={{
                          "& .MuiSelect-select": {
                            fontSize: "16px",
                            //paddingLeft: 0,
                            fontWeight: 600,
                            minWidth: 130,
                            color: theme.palette.primary.dark,
                            lineHeight: 1,
                            cursor: "pointer",
                            whiteSpace: "normal",
                            maxWidth: 260,
                            "&:hover": {
                              backgroundColor: theme.palette.primary.light,
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                            // pl: 0,
                          },
                        }}
                      >
                        {AppConstants.LeaveStatus.map((issue: any) => (
                          <MenuItem
                            key={issue.value}
                            value={issue.value}
                            onClick={() =>
                              handleChangeLeaveStatusFilter(issue.value)
                            }
                          >
                            {issue.label}
                          </MenuItem>
                        ))}
                      </TextField> */}
                        </Box>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // className="error"
                              borderRadius="semi-square"
                              placeholder="Select Start Date"
                              labelAlignment="left"
                              // autoComplete='off'
                              // disableFuture
                              // label="Select Start Date"
                              // openTo="year"
                              // views={["year", "month", "day"]}
                              value={startDateFilter}
                              minDate={new Date(mindate)}
                              maxDate={
                                new Date(new Date().getFullYear() + 1, 11, 31)
                              }
                              onChange={(newValue: any) => {
                                handleChangeLeaveStartDateFilter(newValue);
                                setfiltersSelected(true);
                                setStartDateFilter(newValue);
                                setfiltersSelected(true);

                              }}
                             // error={showHelpText && !values.startDate ? "Start Date is required" : ""}
                            // renderInput={(params: any) => (
                            //   <TextField
                            //     {...params}
                            //     // autoComplete="off"
                            //     // onBlur={handleBlur}
                            //     value={startDateFilter}
                            //     onKeyDown={(e: any) => {
                            //       e.preventDefault();
                            //     }}
                            //     error={false}
                            //     //helperText="Your error message"
                            //     // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                            //     sx={{
                            //       width: '100%',
                            //       "& .MuiSvgIcon-root": {
                            //         fill: theme.palette.primary.main,
                            //       },
                            //     }}
                            //   />
                            // )}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Stack>
                      <Stack direction={'row'} spacing={2}>
                        <Button
                          onClick={() => {
                            if (value === eLeaveTabs.LeavesRequest) {
                              setfiltersSelected(true);
                              getAllLeaveManagementDetails("manager", statusFilter, typeFilter, startDateFilter, resourceHook, departmentHook)
                              setPage(0);
                              setPage1(0);
                            }
                            else { getAllLeaveManagementDetails("user", statusFilter, typeFilter, startDateFilter, _authUser.id, departmentHook); }
                          }}
                          color="primary"
                          variant="contained"
                          sx={{ ml: 0.5 }}
                        >
                          Apply
                        </Button>
                        {/* {clearState === false && ( */}
                        {filtersSelected && (
                          <Box>
                            <Button
                              onClick={async () => {
                                setfiltersSelected(false);

                                if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                                  setselectedDepartmentId(
                                    _authUser.employeeInfo.departmentId
                                  );
                                  setresourceHook("");
                                  setTypeFilter("Select Leave Type");
                                  setStatusFilter("All Leaves");
                                  setDepartmentHook(_authUser.employeeInfo.departmentId);
                                  setStartDateFilter(null);
                                  // await getAllLeaveManagementDetails("manager", "", "", "", "", departmentHook)
                                  if (value === eLeaveTabs.LeavesRequest) {
                                    await getAllLeaveManagementDetails("manager", "", "", "", "", departmentHook)
                                  }
                                  else {
                                    getAllLeaveManagementDetails("user", "", "", "", _authUser.id, departmentHook);
                                  }

                                } else {
                                  if (value === eLeaveTabs.LeavesApplied) {
                                    handleClearFilter();
                                    await getAllLeaveManagementDetails("user", "", "", "", _authUser.id, departmentHook)

                                  }
                                  else {
                                    setselectedDepartmentId("");
                                    setDepartmentHook("");
                                    setresourceHook("");
                                    setTypeFilter("Select Leave Type");
                                    setStatusFilter("All Leaves");
                                    setStartDateFilter(null);
                                    await getAllLeaveManagementDetails("manager", "", "", "", "", "")
                                  }
                                }

                              }}
                              // disabled={_authUser.typeOfUser=== 2 ? false:true}
                              variant="contained"
                              color="primary"
                            >
                              Clear
                            </Button>
                          </Box>)}
                        {/* )} */}
                      </Stack>

                    </Stack>
                  </Stack>

                )}
            </Stack>
            {/* For Team Leave card */}
            {((_permission.page_Leave_Request_Access === true && _permission.page_Leave_Card_Access === true && value === 3) ||
              (_permission.page_Leave_Request_Access === false && _permission.page_Leave_Card_Access === true && value === 2)) && (
                <Grid item xs={12} lg={10}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "strech", md: "center" }}
                    justifyContent={{ xs: "flex-start", md: "space-between", lg: "flex-start" }}
                    spacing={2}
                    sx={{ pb: 1 }}
                  >
                    <Box >
                      <Autocomplete
                        value={
                          departments?.find(
                            (option) => option.id == departmentsId
                          ) ?? null
                        }
                        fullWidth
                        disabled={_authUser.typeOfUser != eTypeOfUser.Admin}
                        // sx={{ ml: "15px" }}
                        id="department-drop"
                        options={departments}
                        getOptionLabel={(option) => option.name}
                        onChange={async (event, value) => {
                          if (!value) {
                            setDptmentWiseLeaveBalance([])
                            setfiltersSelected(false);
                            setresourceId("");
                            setDepartmentId("");
                          }
                          else {
                            setfiltersSelected(true);
                            setDepartmentId(value?.id ?? "")
                            setresourceId("");
                            setresources(
                              await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                            );
                            GetDeaprtmentWiseLeaveBalance(value?.id ?? "", "")
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="department-drop"
                            name="departmentsId"
                            variant="outlined"
                            value={departmentsId}
                            fullWidth
                            sx={{ minWidth: 220 }}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ mr: "20px" }}>
                      <Autocomplete
                        disabled={!departmentsId || departmentsId === ""}
                        value={
                          resources?.find(
                            (option) => option.userId == resourceId
                          ) ?? null
                        }
                        sx={{ mr: "20px" }}
                        fullWidth
                        id="resources-drop"
                        options={resources}
                        getOptionLabel={(option) => option.userName}
                        onChange={async (event, value) => {
                          setfiltersSelected(true);
                          setresourceId(value?.userId ?? "")
                          GetDeaprtmentWiseLeaveBalance(departmentsId, value?.userId ?? "")
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="resources-drop"
                            name="resourceId"
                            //label="Departments"
                            variant="outlined"
                            value={resourceId}
                            fullWidth
                            sx={{ minWidth: 220 }}
                          />
                        )}
                      />
                    </Box>

                    {filtersSelected && <Button
                      onClick={async () => {
                        setfiltersSelected(false);
                        setresourceId("");
                        if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                          setDepartmentId(_authUser.employeeInfo.departmentId);
                          setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                          GetDeaprtmentWiseLeaveBalance(_authUser.employeeInfo.departmentId, '');
                        } else {
                          setDepartmentId("");
                          setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                          setresources(await clsResourcesList.getResourcesDropdownList(""));
                          setDptmentWiseLeaveBalance([]);
                        }
                      }}
                      color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}

                  </Stack>
                </Grid>)}
            <Box sx={{ width: "100%" }}>
              <Box sx={Leavepagestyle.toptabs}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons
                  aria-label="basic tabs example"
                >
                  <Tab label="Leave Card" {...a11yProps(eLeaveTabs.LeaveCard)} onClick={() => handleBalance()} />
                  <Tab label="Leaves Applied" {...a11yProps(eLeaveTabs.LeavesApplied)} onClick={() => { handlehistory(); }} />
                  {_permission.page_Leave_Request_Access === true && (
                    <Tab label="Leaves Request" onClick={() => handleApprove()} {...a11yProps(eLeaveTabs.LeavesRequest)} />
                  )}
                  {_permission.page_Leave_Card_Access === true && (
                    <Tab label="Team Leave Card" onClick={() => { handleTeamLeaveCard() }} />
                  )}
                  <Tab label="List of Holidays" {...a11yProps(eLeaveTabs.ListofHolidays)} onClick={() => handleBalance()}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item xs={12} md={7} xl={8}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} md={6} lg={4}>
                        <Paper variant="outlined" sx={[styles.cardLeaves]}>
                          <Typography
                            variant="h5"
                            sx={[styles.titleLeave, styles.annualLeaves]}
                          >
                            Earned Leaves
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={[styles.total]}
                                gutterBottom
                              >
                                Total Leaves
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={[styles.values]}
                                gutterBottom
                              >
                                13 Days
                              </Typography>
                            </Box>
                            {/* <Box>
                                                            <Typography variant="body1" sx={[styles.total,]} gutterBottom>
                                                                Taken Leaves
                                                            </Typography>
                                                            <Typography variant="body1" sx={[styles.takenvalues,]} gutterBottom>
                                                                2 Days
                                                            </Typography>
                                                        </Box> */}
                          </Stack>
                          {/* <Box sx={{ mt: 2 }}>
                                                        <LinearProgress variant="determinate" value={progress} sx={[styles.AnnualLeavesProgress]} />
                                                    </Box> */}
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Paper variant="outlined" sx={[styles.cardLeaves]}>
                          <Typography
                            variant="h5"
                            sx={[styles.titleLeave, styles.annualLeaves]}
                          >
                            Sick Leaves
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={[styles.total]}
                                gutterBottom
                              >
                                Total Leaves
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={[styles.values]}
                                gutterBottom
                              >
                                7 Days
                              </Typography>
                            </Box>

                          </Stack>
                          {/* <Box sx={{ mt: 2 }}>
                                                        <LinearProgress variant="determinate" value={progress} sx={[styles.SickLeavesProgress]} />
                                                    </Box> */}
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Paper variant="outlined" sx={[styles.cardLeaves]}>
                          <Typography
                            variant="h5"
                            sx={[styles.titleLeave, styles.annualLeaves]}
                          >
                            Casual Leaves
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={[styles.total]}
                                gutterBottom
                              >
                                Total Leaves
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={[styles.values]}
                                gutterBottom
                              >
                                7 Days
                              </Typography>
                            </Box>
                            <Box>
                              {/* <Typography variant="body1" sx={[styles.total,]} gutterBottom>
                                                                Taken Leaves
                                                            </Typography>
                                                            <Typography variant="body1" sx={[styles.takenvalues,]} gutterBottom>
                                                                1 Days
                                                            </Typography> */}
                            </Box>
                          </Stack>
                          {/* <Box sx={{ mt: 2 }}>
                                                        <LinearProgress variant="determinate" value={progress} sx={[styles.CasualLeavesProgress]} />
                                                    </Box> */}
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Paper variant="outlined" sx={[styles.cardLeaves]}>
                          <Typography
                            variant="h5"
                            sx={[styles.titleLeave, styles.otherLeaves]}
                          >
                            Maternity Leaves
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={[styles.total]}
                                gutterBottom
                              >
                                Total Leaves
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={[styles.values]}
                                gutterBottom
                              >
                                180 Days
                              </Typography>
                            </Box>
                            <Box>
                              {/* <Typography variant="body1" sx={[styles.total,]} gutterBottom>
                                                                Taken Leaves
                                                            </Typography>
                                                            <Typography variant="body1" sx={[styles.takenvalues,]} gutterBottom>
                                                                2 Days
                                                            </Typography> */}
                            </Box>
                          </Stack>
                          {/* <Box sx={{ mt: 2 }}>
                                                        <LinearProgress variant="determinate" value={progress} sx={[styles.OtherLeavesProgress]} />
                                                    </Box> */}
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Paper variant="outlined" sx={[styles.cardLeaves]}>
                          <Typography
                            variant="h5"
                            sx={[styles.titleLeave, styles.otherLeaves]}
                          >
                            Paternity Leaves
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                sx={[styles.total]}
                                gutterBottom
                              >
                                Total Leaves
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={[styles.values]}
                                gutterBottom
                              >
                                05 Days
                              </Typography>
                            </Box>


                            <Box>
                              {/* <Typography variant="body1" sx={[styles.total,]} gutterBottom>
                                                                Taken Leaves
                                                            </Typography>
                                                            <Typography variant="body1" sx={[styles.takenvalues,]} gutterBottom>
                                                                2 Days
                                                            </Typography> */}
                            </Box>
                          </Stack>
                          {/* <Box sx={{ mt: 2 }}>
                                                        <LinearProgress variant="determinate" value={progress} sx={[styles.OtherLeavesProgress]} />
                                                    </Box> */}
                        </Paper>
                      </Grid>

                      <Divider />
                    </Grid>
                    <Stack direction="row" alignItems="flex-end" sx={{ gap: "10px" }}>
                      <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", width: "100%" }}>
                        <Typography variant="h5" sx={[styles.titleLeave, styles.sickLeaves]}>
                          Availed
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: .5 }}>
                          <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                              <Typography
                                variant="h6"
                                sx={[styles.titleLeave, styles.casualLeaves]}

                              >
                                Casual + Sick Leaves
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Box>

                                  <Typography variant="h6">
                                    {leaveCount?.leaveTaken} days{" "}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                              <Typography
                                variant="h5"
                                sx={[styles.titleLeave, styles.casualLeaves]}
                              >
                                Earned Leaves
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Box>

                                  <Typography variant="h6">
                                    {leaveCount?.earnedLeaveTaken} days{" "}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                              <Typography
                                variant="h5"
                                sx={[styles.titleLeave, styles.casualLeaves]}
                              >
                                Loss Of Pay (LOP)
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Box>

                                  <Typography variant="h6">
                                    {leaveCount?.lopLeaveCount || 0} days{" "}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Paper>
                      {/* <Box  sx={{ height: "250px", width: "32%"}}>
                      <Paper variant="outlined" sx={[styles.cardLeaves]}>
                        <Typography
                          variant="h6"
                          sx={[styles.titleLeave, styles.sickLeaves]}
                        >
                          Balance Leaves
                        </Typography>
                        
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                          sx={{mt: 4}}
                        >
                          <Box>

                          <Typography variant="h6">
                            {leaveCount?.leaveBalance} days{" "}
                          </Typography>
                          </Box>
                        </Stack>
                      </Paper>
                      </Box> */}
                    </Stack>
                    <Stack direction="row" alignItems="flex-end" sx={{ gap: "10px" }}>
                      <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", mt: 2, width: "100%" }}>
                        <Typography variant="h5" sx={[styles.titleLeave, styles.sickLeaves]}>
                          Balance Leaves
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: .5 }}>
                          <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                              <Typography
                                variant="h6"
                                sx={[styles.titleLeave, styles.casualLeaves]}

                              >
                                Casual + Sick Leaves
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Box>

                                  <Typography variant="h6">
                                    {leaveCount?.leaveBalance} days{" "}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                              <Typography
                                variant="h5"
                                sx={[styles.titleLeave, styles.casualLeaves]}
                              >
                                Earned Leaves
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={2}
                              >
                                <Box>

                                  <Typography variant="h6">
                                    {leaveCount?.earnedLeaveBalance} days{" "}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Paper>
                          </Grid>

                        </Grid>
                      </Paper>
                      {/* <Box  sx={{ height: "250px", width: "32%"}}>
                      <Paper variant="outlined" sx={[styles.cardLeaves]}>
                        <Typography
                          variant="h6"
                          sx={[styles.titleLeave, styles.sickLeaves]}
                        >
                          Balance Leaves
                        </Typography>
                        
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                          sx={{mt: 4}}
                        >
                          <Box>

                          <Typography variant="h6">
                            {leaveCount?.leaveBalance} days{" "}
                          </Typography>
                          </Box>
                        </Stack>
                      </Paper>
                      </Box> */}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={5} xl={4}>
                    <Box sx={[styles.applyLeave]}>
                      <Typography variant="h5" sx={[styles.applyLeaveHeading]}>
                        Apply Leave
                      </Typography>
                      <Box>
                        {" "}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <FormControl sx={{ mt: 3, mb: 3 }}>
                            <FormHelperText sx={[styles.selectlabel]}>
                              Duration
                            </FormHelperText>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              defaultValue="Short-Leave"
                            >
                              <FormControlLabel
                                value="Short-Leave"
                                control={<Radio />}
                                onClick={() => {
                                  handleFeildDisable("Short Leave");
                                  handleReset();
                                  values.duration = "";
                                  values.durationType = "";
                                  values.endDate = "";
                                  values.reason = "";
                                  values.startDate = "";
                                  values.type = "";
                                  values.startTime = "";
                                }}
                                label="Short Leave"
                              />
                              <FormControlLabel
                                value="Half-Day"
                                control={<Radio />}
                                onClick={() => {
                                  handleReset();
                                  handleFeildDisable("Half Day")
                                  values.duration = "";
                                  values.durationType = "";
                                  values.endDate = "";
                                  values.reason = "";
                                  values.startDate = "";
                                  values.type = "";
                                }}
                                label="Half Day"
                              />
                              <FormControlLabel
                                value="Full-Day"
                                control={<Radio />}
                                onClick={() => {
                                  handleReset();
                                  handleFeildDisable("Full Day")
                                  values.duration = "";
                                  values.durationType = "";
                                  values.endDate = "";
                                  values.reason = "";
                                  values.startDate = "";
                                  values.type = "";
                                }}
                                label="Full Day"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <FormControl sx={{ mt: 3, width: "100%" }}>
                            <FormHelperText sx={[styles.selectlabel]}>
                              Leave Type
                            </FormHelperText>
                            {disableform == 3 && (
                              <TextField
                                id="outlined-basic"
                                label="Select Type"
                                variant="outlined"
                                value={"Short Leave"}
                                name="type"
                                disabled={true}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                sx={[styles.selectType]}
                              // error={Boolean(
                              //     getIn(touched, "type") && getIn(errors, "type")
                              // )}
                              // helperText={
                              //     getIn(touched, "type") && getIn(errors, "type")
                              // }
                              //value={duration}
                              //required
                              >
                                {/* {AppConstants.MasterData.Issue.LeaveTypehalf.map(
                                  (issue: any) => (
                                    <MenuItem
                                      key={issue.value}
                                      value={issue.value}
                                      onClick={() =>
                                        handleChangeLeaveType(issue.value)
                                      }
                                    >
                                      {issue.label}
                                    </MenuItem>
                                  )
                                )} */}

                              </TextField>
                            )}
                            {disableform == 0 && (
                              <TextField
                                id="outlined-basic"
                                placeholder="Select Type"
                                select
                                label="Select Type"
                                variant="outlined"
                                name="type"
                                //disabled={true}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                sx={[styles.selectType]}
                                error={Boolean(
                                  getIn(touched, "type") &&
                                  getIn(errors, "type")
                                )}
                                helperText={
                                  getIn(touched, "type") &&
                                  getIn(errors, "type")
                                }
                              //value={duration}
                              //required
                              >
                                {AppConstants.MasterData.Issue.LeaveTypehalf.map(
                                  (issue: any) => (
                                    <MenuItem
                                      key={issue.value}
                                      value={issue.value}
                                      onClick={() =>
                                        handleChangeLeaveType(issue.value)
                                      }
                                    >
                                      {issue.label}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            )}
                            {disableform == 1 && (
                              <TextField
                                id="outlined-basic"
                                placeholder="Select Type"
                                select
                                label="Select Type"
                                variant="outlined"
                                name="type"
                                //disabled={true}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleChange(event);
                                  setStartDate('');
                                  setEndDate('');
                                }}
                                sx={[styles.selectType]}
                                error={Boolean(
                                  getIn(touched, "type") &&
                                  getIn(errors, "type")
                                )}
                                helperText={
                                  getIn(touched, "type") &&
                                  getIn(errors, "type")
                                }
                              //value={duration}
                              //required
                              >
                                {AppConstants.MasterData.Issue.LeaveType.map(
                                  (issue: any) => (
                                    <MenuItem
                                      key={issue.value}
                                      value={issue.value}
                                      onClick={() =>
                                        handleChangeLeaveType(issue.value)
                                      }
                                    >
                                      {issue.label}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            )}
                            {values.type =="Earned Leave" &&(
                                 <FormControlLabel sx={{mt:"7px"}} control={
                                 <Checkbox checked={futureLeaveCheckBox} onChange={(e)=>{
                                  handleCheckBoxChange(e.target.checked)}} />}
                                 label="Take Leave from Future" />
                            )}
                          </FormControl>
                        </Stack>
                        <br></br>
                        <Stack direction="row" spacing={1} alignItems="center">
                          {disableform == 3 && (
                            <FormControl sx={{ width: "100%" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  // className="error"
                                  //  disablePast
                                  // autoComplete='off'
                                  // disableFuture
                                  borderRadius="semi-square"
                                  placeholder="Select Date"
                                  labelAlignment="left"
                                  label="Select Date"
                                  // openTo="year"
                                  // views={["year", "month", "day"]}
                                  value={startDate}
                                  minDate={moment().subtract(0, "years").toDate()}

                                  maxDate={
                                    new Date(
                                      new Date().getFullYear() + 1,
                                      11,
                                      31
                                    )
                                  }
                                  onChange={(newValue: any) => {
                                    values.startDate = getDate(newValue);
                                    values.endDate = getDate(newValue);
                                    setStartDate(newValue);
                                    // setSubmit(true);
                                    setshowHelpText(false);

                                  }}
                                  error={showHelpText && !values.startDate ? "Start Date is required" : ""}
                                // error={Boolean(
                                //     getIn(touched, "type") && getIn(errors, "type")
                                // )}

                                // renderInput={(params: any) => (
                                //   <TextField
                                //     {...params}
                                //     autoComplete="off" // Disable suggestions
                                //     readOnly={true} // Disable user input
                                //     onBlur={handleBlur}
                                //     onKeyDown={onKeyDown}
                                //     //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                                //     error={
                                //       getIn(touched, "startDate") &&
                                //       getIn(errors, "startDate")
                                //     }
                                //     helperText={
                                //       getIn(touched, "startDate") &&
                                //       getIn(errors, "startDate")
                                //     }
                                //     // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                                //     sx={{
                                //       "& .MuiSvgIcon-root": {
                                //         fill: theme.palette.primary.main,
                                //       },
                                //     }}
                                //   />
                                // )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          )}
                          {disableform == 0 && (
                            <FormControl sx={{ width: "100%" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  // className="error"
                                  // disablePast
                                  // autoComplete='off'
                                  // disableFuture
                                  label="Select Date"
                                  placeholder="Select Date"
                                  labelAlignment="left"
                                  borderRadius="semi-square"

                                  //openTo="year"
                                  // views={["year", "month", "day"]}
                                  value={startDate}
                                  minDate={values.type === "Sick Leave" ? moment().subtract(2, 'days').toDate() : moment().subtract(0, "years").toDate()}

                                  maxDate={
                                    new Date(
                                      new Date().getFullYear() + 1,
                                      11,
                                      31
                                    )
                                  }
                                  onChange={(newValue: any) => {
                                    values.startDate = getDate(newValue);
                                    values.endDate = getDate(newValue);
                                    setStartDate(newValue);
                                    // setSubmit(true);
                                    setshowHelpText(false);

                                  }}
                                  error={showHelpText && !values.startDate ? "Start Date is required" : ""}
                                // error={Boolean(
                                //     getIn(touched, "type") && getIn(errors, "type")
                                // )}

                                // renderInput={(params: any) => (
                                //   <TextField
                                //     {...params}
                                //     autoComplete="off" // Disable suggestions
                                //     readOnly={true} // Disable user input
                                //     onBlur={handleBlur}
                                //     onKeyDown={onKeyDown}
                                //     //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                                //     error={
                                //       getIn(touched, "startDate") &&
                                //       getIn(errors, "startDate")
                                //     }
                                //     helperText={
                                //       getIn(touched, "startDate") &&
                                //       getIn(errors, "startDate")
                                //     }
                                //     // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                                //     sx={{
                                //       "& .MuiSvgIcon-root": {
                                //         fill: theme.palette.primary.main,
                                //       },
                                //     }}
                                //   />
                                // )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          )}

                          {disableform == 1 && (
                            <FormControl sx={{ width: "50%" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  placeholder={values.type === "Comp Off Leave" ? "Worked on" : "From"}
                                  labelAlignment="left"
                                  // className="error"
                                  // disablePast
                                  // autoComplete='off'
                                  // disableFuture
                                  label={values.type === "Comp Off Leave" ? "Worked on" : "From"}

                                  borderRadius="semi-square"
                                  // openTo="year"
                                  // views={["year", "month", "day"]}
                                  value={startDate}
                                  minDate={values.type === "Sick Leave" ? moment().subtract(2, 'days').toDate() :
                                  values.type === "Comp Off Leave" ? moment().startOf('year').toDate()
                                      : moment().subtract(0, "years").toDate()}
                                  maxDate={values.type=="Comp Off Leave"? moment().subtract(1, 'days').toDate() :
                                    new Date(
                                      new Date().getFullYear() + 1,
                                      11,
                                      31
                                    )
                                  }
                                  // onChange={(newValue: any) => {
                                  //   values.startDate = getDate(newValue);
                                  //   setStartDate(newValue);
                                  //   // setSubmit(true);
                                  //   setshowHelpText(false);

                                  // }}

                                  onChange={(newValue: any) => {
                                    values.startDate = getDate(newValue);
                                    setStartDate(newValue);
                                    setshowHelpText(false);
                                   
                                    if (values.type === "Marriage Leave") {
                                      const newEndDate = moment(newValue).add(9, "days").toDate();
                                      values.endDate = getDate(newEndDate);
                                      setEndDate(getDate(newEndDate));
                                    }
                                  }}
                                error = {(showHelpText && !values.startDate && values.type === "Comp Off Leave"
                                  ? "Worked On is Required" : showHelpText && !values.startDate
                                    ? "Start Date is required"
                                    : "")}
                               
                                />
                              </LocalizationProvider>
                            </FormControl>
                          )}
                          {disableform == 1 && (
                            <FormControl sx={{ width: "50%" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  // disablePast
                                  // disableFuture
                                  label={values.type === "Comp Off Leave" ? "Leave Date" : "Till"}
                                  placeholder={values.type === "Comp Off Leave" ? "Leave Date" : "Till"}
                                  labelAlignment="left"
                                  borderRadius="semi-square"
                                  //openTo="year"
                                  // views={["year", "month", "day"]}
                                  value={endDate}
                                  // minDate={moment().subtract(0, "years").toDate()}
                                  minDate={values.type === "Sick Leave" ? moment().subtract(2, 'days').toDate() : moment().subtract(0, "years").toDate()}
                                  maxDate={
                                    new Date(
                                      new Date().getFullYear() + 1,
                                      11,
                                      31
                                    )
                                  }
                                  disabled={values.type === "Marriage Leave"}
                                  onChange={(newValue: any) => {
                                    values.endDate = getDate(newValue);
                                    setEndDate(newValue);
                                    setshowHelpText(false);
                                  }}
                                  error={showHelpText && !values.endDate && values.type === "Comp Off Leave" ? "Leave Date is required" :
                                    showHelpText && !values.endDate ? "End Date is required"
                                      : ""}

                                />
                              </LocalizationProvider>
                            </FormControl>
                          )}
                        </Stack>
                        <br></br>
                        {disableform == 3 && (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <FormControl sx={{ width: "50%" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  key="starttime"
                                  // disableMaskedInput={true}
                                  //ampm={false}
                                  //inputFormat="hh:mm"
                                  disableMaskedInput
                                  mask="__:__"
                                  label="From"
                                  value={startTime}
                                  showToolbar={false}
                                  // autoComplete='off'
                                  // ampm={true}
                                  // ampmInClock={true}
                                  //className={styles.selectType}
                                  // readOnly={true}

                                  onChange={(newValue) => {
                                    setStartTime(newValue);
                                    values.startTime = "1";
                                    const a = dayjs(newValue);
                                    const b = a.add(2, "hour");
                                    // var a= dayjs(newValue).(120);
                                    setEndTime(b);
                                  }}
                                  PopperProps={{
                                    popperOptions: {
                                      modifiers: [
                                        {
                                          name: 'flip',
                                          enabled: true,
                                          options: {
                                            altBoundary: true,
                                            flipVariations: true,
                                            altAxis: true,
                                            fallbackPlacements: ['bottom'],
                                            padding: 50  // Set to ['bottom'] if you want it to always open at the bottom
                                          },
                                        },]
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      autoComplete="off"
                                      {...params}
                                      onKeyDown={onKeyDown}
                                      onBlur={handleBlur}
                                      //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                                      error={
                                        getIn(touched, "startTime") &&
                                        getIn(errors, "startTime")
                                      }
                                      helperText={
                                        getIn(touched, "startTime") &&
                                        getIn(errors, "startTime")
                                      }
                                      // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fill: theme.palette.primary.main,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                            <FormControl sx={{ width: "50%" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  disabled
                                  key="starttime"
                                  label="Till"
                                  value={endTime}
                                  onChange={(newValue) => {
                                    setEndTime(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Stack>
                        )}
                        {disableform == 0 && (
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              id="outlined-basic"
                              select
                              label="Select Duration"
                              variant="outlined"
                              name="Duration Type"
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              sx={[styles.selectType]}
                              error={Boolean(
                                getIn(touched, "durationType") &&
                                getIn(errors, "durationType")
                              )}
                              helperText={
                                getIn(touched, "durationType") &&
                                getIn(errors, "durationType")
                              }
                              // value={durationType}
                              required
                            >
                              {AppConstants.MasterData.Issue.LeaveDurationType.map(
                                (issue: any) => (
                                  <MenuItem
                                    key={issue.value}
                                    value={issue.value}
                                    onClick={() => {
                                      handleDurationType(issue.value);
                                      values.durationType = issue.value;
                                    }
                                    }
                                  >
                                    {issue.label}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </FormControl>
                        )}
                        <FormControl
                          sx={{ mt: 3, mb: 3, width: "100%" }}
                          variant="outlined"
                        >
                          <FormHelperText sx={[styles.selectlabel]}>
                            Reason*
                          </FormHelperText>
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            multiline
                            value={reason}
                            variant="outlined"
                            required
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(touched, "reason") &&
                              getIn(errors, "reason")
                            )}
                            helperText={
                              getIn(touched, "reason") &&
                              getIn(errors, "reason")
                            }
                            onChange={(e) => {
                              let abc = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart().substring(0, 250);
                              values.reason = abc;
                              setReason(abc);
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                minHeight: "100px",
                                backgroundColor: "#fff",
                                lineHeight: "1.5",
                              },
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#BDBDBD", textAlign: "right" }}
                          >
                            Max 250 Characters
                          </Typography>
                        </FormControl>
                        <Box sx={{ textAlign: "right" }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mr: 1.5 }}
                            onClick={() => handleCancel()}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={buttonDisable1}
                            onClick={() => {
                              setshowHelpText(!endDate || !startDate)
                            }}
                          >
                            Apply
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <TableContainer> */}
                <TableContainer sx={{ maxHeight: "calc(100vh - 340px)" }}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={leaveList.length}
                    />
                    <TableBody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                      rows.slice().sort(getComparator(order, orderBy)) */}
                      {stableSort(leaveList, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              //  onClick={(event) => handleClick1(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {/* <StyledTableCell align="left">{row.employeeId}</StyledTableCell> */}
                              <StyledTableCell align="left">
                                {row.employeeName}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId}>
                                {" "}
                                {/* {`${moment(row.startdate)
                                  .utc()
                                  .format("DD.MMM.YYYY")}`}{" "} */}
                                  {row?.updateStartDate !=null && row.status=="Approved"  ? moment.utc(row?.updateStartDate).format("DD-MMM-YYYY")
                                  : moment.utc(row?.startdate).format("DD-MMM-YYYY")} {" "}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId}>
                                {" "}
                                {/* {`${moment(row.enddate)
                                  .utc()
                                  .format("DD.MMM.YYYY")}`}{" "} */}
                                  {row?.updateEndDate && row.status=="Approved" ?moment.utc(row?.updateEndDate).format("DD-MMM-YYYY"):
                                  moment.utc(row?.enddate).format("DD-MMM-YYYY")} {" "}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.duration}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.type}
                              </StyledTableCell>
                              <StyledTableCell component="th" id={labelId}>
                                {" "}
                                {/* {`${moment(row?.submittedOn)
                                  .utc()
                                  .format("DD.MMM.YYYY")}`}{" "} */}
                                {`${moment(row?.submittedOn).format("DD-MMM-YYYY")}`}{" "}
                              </StyledTableCell>

                              {AppConstants.Status.Approved === row.status && (
                                <StyledTableCell component="th" id={labelId}>
                                {`${moment.utc(row.approvedOn, "M/D/YYYY h:mm:ss A").format("DD-MMM-YYYY")}`}
                                </StyledTableCell>
                              )}
                              {AppConstants.Status.UpdateRequest ===
                                row.status && (
                                  <StyledTableCell component="th" id={labelId}>
                                    -
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Pending === row.status && (
                                <StyledTableCell component="th" id={labelId}>
                                  -
                                </StyledTableCell>
                              )}
                              {AppConstants.Status.Rejected === row.status && (
                                <StyledTableCell component="th" id={labelId}>
                                  -
                                </StyledTableCell>
                              )}
                              {AppConstants.Status.WrongStatus ===
                                row.status && (
                                  <StyledTableCell component="th" id={labelId}>
                                    -
                                  </StyledTableCell>
                                )}

                              {/* <StyledTableCell align="left">{(row.reason.length > 15) ? row.reason.slice(0, 15 - 1) + '...' : row.reason}</StyledTableCell> */}

                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Half Day" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    Availed
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Half Day" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    Approved
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Full Day" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    Approved
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Full Day" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    Availed
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Short Leave" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    Approved
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Short Leave" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    Availed
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Rejected === row.status && (
                                <StyledTableCell align="left">
                                  Rejected
                                </StyledTableCell>
                              )}
                              {AppConstants.Status.Pending === row.status && (
                                <StyledTableCell align="left">
                                  Pending
                                </StyledTableCell>
                              )}
                              {AppConstants.Status.WrongStatus ===
                                row.status && (
                                  <StyledTableCell align="left">
                                    Wrong Status
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.UpdateRequest ===
                                row.status && (
                                  <StyledTableCell align="left">
                                    Update Request
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Pending === row.status &&
                                row.duration === "Full Day" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      Withdraw
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Pending === row.status &&
                                row.duration === "Full Day" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                      disabled={row.ispast}
                                    >
                                      Withdraw
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Full Day" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleClickOpenRU(row.id)}
                                    >
                                      Request Update
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {/* {(AppConstants.Status.Approved === row.status && row.duration === "Full Day" && row.ispast === true &&
                                                                <StyledTableCell align="left">
                                                                    <Button variant="contained" color="primary" size="small" disabled={true}>Approved</Button>
                                                                </StyledTableCell>
                                                            )} */}
                              {AppConstants.Status.Pending === row.status &&
                                row.duration === "Half Day" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      Withdraw
                                    </Button>
                                  </StyledTableCell>
                                )}

                              {AppConstants.Status.Rejected === row.status && (
                                <StyledTableCell align="left">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleClickOpenVR(row.id)}
                                  >
                                    View
                                  </Button>
                                </StyledTableCell>
                              )}
                              {/* {(AppConstants.Status.Approved === row.status &&
                                                                <StyledTableCell align="left">
                                                                    <Button variant="contained" color="primary" size="small" onClick={() => handleClickOpenVR(row.id)}>View</Button>
                                                                </StyledTableCell>
                                                            )} */}
                              {AppConstants.Status.Pending === row.status &&
                                row.duration === "Half Day" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      Withdraw
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.UpdateRequest ===
                                row.status && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      disabled={true}
                                    >
                                      Update Request
                                    </Button>
                                  </StyledTableCell>
                                )}

                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Half Day" && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      View
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Full Day" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      View
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Short Leave" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      View
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Approved === row.status &&
                                row.duration === "Short Leave" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      View
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Pending === row.status &&
                                row.duration === "Short Leave" &&
                                row.ispast === false && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      Withdraw
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {AppConstants.Status.Pending === row.status &&
                                row.duration === "Short Leave" &&
                                row.ispast === true && (
                                  <StyledTableCell align="left">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleClickOpenHistory(row.id)
                                      }
                                    >
                                      Withdraw
                                    </Button>
                                  </StyledTableCell>
                                )}
                              {/* {(AppConstants.Status.Approved === row.status && row.duration === "Short Leave" && row.ispast === false &&
                                                                <StyledTableCell align="left">
                                                                    <Button variant="contained" color="primary" size="small" onClick={() => handleClickOpenRU(row.id)}>Request Update</Button>
                                                                </StyledTableCell>
                                                            )} */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25]}
                  component="div"
                  count={leaveList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}

                  labelDisplayedRows={() => `Total: ${leaveList?.length}  Page ${page + 1} of ${pageCount}`}
                />
              </TabPanel>
              {_permission.page_Leave_Request_Access === true && (
                <TabPanel value={value} index={2}>

                  <TableContainer sx={{ maxHeight: "calc(100vh - 340px)" }}>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                      stickyHeader
                    >
                      <EnhancedTableHead1
                        numSelected={selected.length}
                        order1={order1}
                        orderBy1={orderBy1}
                        onRequestSort1={handleRequestSort1}
                        rowCount={leaveList.length}
                      />
                      <TableBody>
                        {stableSort1(leaveList, getComparator1(order1, orderBy1)
                        )
                          .slice(
                            page1 * rowsPerPage1,
                            page1 * rowsPerPage1 + rowsPerPage1
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <StyledTableCell align="left" sx={{ position: "sticky",
                left: 0,
                zIndex: 99,
                background: "#fff"
                // background: "#F5F5F5"
                }}>
                                  {row.employeeId}
                                </StyledTableCell>
                                <StyledTableCell align="left" sx={{ position: "sticky",
                left: '102px',
                zIndex: 99,
                background: "#fff"
                // background: "#F5F5F5"
                }}>
                                  {row.employeeName}
                                </StyledTableCell>
                                <StyledTableCell component="th" id={labelId}>
                                  {" "}
                                      {row?.updateStartDate !=null && row.status== "Approved"  ? moment(row.updateStartDate).utc().format("DD-MMM-YYYY")
                                  : moment(row.startdate).utc().format("DD-MMM-YYYY")} {" "}
                                </StyledTableCell>
                                <StyledTableCell component="th" id={labelId}>
                                      {" "}
                                      {row?.updateEndDate !=null && row.status== "Approved"  ? moment(row.updateEndDate).utc().format("DD-MMM-YYYY")
                                  : moment(row.enddate).utc().format("DD-MMM-YYYY")} {" "}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.duration}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.type}
                                </StyledTableCell>
                                {/* <StyledTableCell component="th" id={labelId}>
                                  {" "}
                                  {`${moment(row.submittedOn)
                                    .utc()
                                    .format("DD-MMM-YYYY")}`}{" "}
                                </StyledTableCell> */}
                                <StyledTableCell component="th" id={labelId}>
                                  {`${moment
                                    .utc(row.submittedOn)
                                    .local()
                                    .format("DD-MMM-YYYY")}`}
                                </StyledTableCell>
                                {row.approvedOn === null && (
                                  <StyledTableCell component="th" id={labelId}>
                                    -{" "}
                                  </StyledTableCell>
                                )}
                                {row.approvedOn != null && (
                                  <StyledTableCell component="th" id={labelId}>
                                    {" "}
                                    {`${moment.utc(row.approvedOn, "M/D/YYYY h:mm:ss A").format("DD-MMM-YYYY")}`}{" "}
                                  </StyledTableCell>
                                )}
                                {row.reason !== null && (
                                  <StyledTableCell align="left">
                                    {row.reason.length > 15
                                      ? row.reason.slice(0, 15 - 1) + "..."
                                      : row.reason}
                                  </StyledTableCell>
                                )}
                                {row.reason === null && (
                                  <StyledTableCell align="left">
                                    -
                                  </StyledTableCell>
                                )}
                                <StyledTableCell align="left">
                                  {row.status}
                                </StyledTableCell>
                                {AppConstants.Status.Pending === row.status && (
                                  <StyledTableCell align="left">
                                    <Stack direction="row" spacing={2}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() =>
                                          handleClickOpenAR(row.id)
                                        }
                                      >
                                        View
                                      </Button>
                                    </Stack>
                                  </StyledTableCell>
                                )}
                                {AppConstants.Status.UpdateRequest ===
                                  row.status && (
                                    <StyledTableCell align="left">
                                      <Stack direction="row" spacing={2}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          onClick={() =>
                                            handleClickOpenRUS(row.id)
                                          }
                                        >
                                          Update Request
                                        </Button>
                                        {/* <Button variant="contained" color="primary" size="small" onClick={() => updateLeaveStatus(row.id, AppConstants.StatusType.Approved)}>Accept</Button> */}
                                      </Stack>
                                    </StyledTableCell>
                                  )}
                                {AppConstants.Status.Rejected ===
                                  row.status && (
                                    <StyledTableCell align="left">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleClickOpenVR(row.id)}
                                      >
                                        View
                                      </Button>
                                    </StyledTableCell>
                                  )}
                                {AppConstants.Status.Approved ===
                                  row.status && (
                                    <StyledTableCell align="left">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleClickOpenVR(row.id)}
                                      >
                                        View
                                      </Button>
                                    </StyledTableCell>
                                  )}
                              </TableRow>
                            );
                          })}
                        {emptyRows1 > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows1,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    component="div"
                    count={leaveList.length}
                    rowsPerPage={rowsPerPage1}
                    page={page1}
                    onPageChange={handleChangePage1}
                    onRowsPerPageChange={handleChangeRowsPerPage1}

                    labelDisplayedRows={() => `Total: ${leaveList?.length}  Page ${page1 + 1} of ${pageCount1}`}
                  />

                </TabPanel>
              )}
              {_permission.page_Leave_Card_Access === true && (
                <TabPanel value={value} index={_permission.page_Leave_Request_Access === true ? 3 : 2}>

                  {dptmentWiseLeaveBalance && dptmentWiseLeaveBalance.length > 0 ? (
                    <TableContainer component={Paper} sx={{ mt: 0, height: "380px", overflow: "auto" }}>
                      <Table stickyHeader aria-label="simple table" size="small">
                        <TableHead sx={headcellcolor.cellcolor}>
                          <TableRow>
                            <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', Width: '90px', background: 'rgb(189, 189, 189)', }} >Emp ID</StyledTableCell>
                            <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: 'rgb(189, 189, 189)', }} >Name</StyledTableCell>
                            <StyledTableCell align="left" >Total Leaves</StyledTableCell>
                            <StyledTableCell align="left" >(Casual + Sick)Availed</StyledTableCell>
                            <StyledTableCell align="left">(Casual + Sick)Balance</StyledTableCell>
                            <StyledTableCell align="left">Earned Availed</StyledTableCell>
                            <StyledTableCell align="left">Earned Balance</StyledTableCell>
                            <StyledTableCell align="left">Comp Off</StyledTableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {dptmentWiseLeaveBalance.slice(pageLC * rowsPerPageLC, pageLC * rowsPerPageLC + rowsPerPageLC)
                            .map((event: any) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                }}
                              >
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff', }}>{event?.employeeId}</StyledTableCell>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: '#fff', }} >{event?.name}</StyledTableCell>
                                <StyledTableCell align="left">{event?.totalleaves || 0}</StyledTableCell>
                                <StyledTableCell align="left">{event?.leaveTaken || 0}</StyledTableCell>
                                <StyledTableCell align="left">{event?.leaveBalance || 0}</StyledTableCell>
                                <StyledTableCell align="left">{event?.earnedLeave || 0}</StyledTableCell>
                                <StyledTableCell align="left">{event?.earnedLeaveBalance || 0}</StyledTableCell>
                                <StyledTableCell align="left">{event?.compOffLeaveCount || 0}</StyledTableCell>
                              </TableRow>

                            ))}</TableBody>  </Table>
                    </TableContainer>) : (
                    <NotFound NotfoundText="No Record Found" />
                  )}
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    component="div"
                    count={dptmentWiseLeaveBalance.length}
                    rowsPerPage={rowsPerPageLC}
                    page={pageLC}
                    onPageChange={handleChangePageLC}
                    onRowsPerPageChange={handleChangeRowsPerPageLC}
                    labelDisplayedRows={() => `Total: ${dptmentWiseLeaveBalance?.length}  Page ${page + 1} of ${pageCount2}`}
                  />
                </TabPanel>)}
              <TabPanel value={value} index={
                _permission.page_Leave_Request_Access && _permission.page_Leave_Card_Access
                  ? 4 // Both permissions are true, set index to 4
                  : _permission.page_Leave_Card_Access == true || _permission.page_Leave_Request_Access == true
                    ? 3 //if Only one  is true, set index to 3
                    : 2 //if none of them arae true then we will set the default index = 2
              }
              >
                <HolidayList></HolidayList>
              </TabPanel>

            </Box>
          </Paper>

          <Dialog
            open={openRU}
            onClose={setOpenRU}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            scroll="body"
            fullWidth
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              Request Update
            </DialogTitle>

            <RequestUpdate
              leaveId={leaveId}
              handleCloseRU={() => handleCloseRU()}
              check={true}
            />
          </Dialog>

          <Dialog
            open={openAR}
            onClose={setOpenAR}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            scroll="body"
            fullWidth
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              View Application
            </DialogTitle>

            <AcceptReject
              leaveId={leaveId}
              handleCloseAR={() => handleCloseAR()}
              check={true}
            />
          </Dialog>

          <Dialog
            open={openRUS}
            onClose={setOpenRU}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            scroll="body"
            fullWidth
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              Accept Request
            </DialogTitle>

            <RequestUpdateAR
              leaveId={leaveId}
              handleCloseRUS={() => handleCloseRUS()}
              check={true}
            />
          </Dialog>

          <Dialog
            open={openVR}
            onClose={Button}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            scroll="body"
            fullWidth
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              View Application
            </DialogTitle>

            <ViewReject
              leaveId={leaveId}
              handleCloseVR={() => handleCloseVR()}
              check={true}
            />
          </Dialog>

          <Dialog
            open={openHistory}
            onClose={setOpenHistory}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            scroll="body"
            fullWidth
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              View Application
              <IconButton
                aria-label="delete"
                sx={Leavepagestyle.styleCross}
                onClick={handleCloseHistory}
              >
                <ClearIcon />
              </IconButton>
            </DialogTitle>

            <ViewHistory
              leaveId={leaveId}
              handleCloseHistory={() => handleCloseHistory()}
              check={true}
            />
          </Dialog>

          <Dialog
            open={openRequest}
            onClose={setOpenRequest}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
            scroll="body"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
              >
                Add Leave Request
              </Typography>
              <Typography variant="subtitle1">
                Create Leave Request on Behalf of an Employee
              </Typography>

              <IconButton
                aria-label="delete"
                sx={Leavepagestyle.styleCross}
                onClick={handleORClose}
              >
                <ClearIcon />
              </IconButton>
            </DialogTitle>
            <AddLeaveRequest handleORClose={() => handleORClose()} />
          </Dialog>
        </form>
      )}
    </Formik>
  );
}
