import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, Navigate, useParams, Link, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import Notes from "../../../components/styledComponent/Note";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import theme from "../../../theme/theme";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as Icons from "../../../assests/icons/icons";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Chip,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { IEmployeeBrowniePointsDetail } from "../../../Interfaces/IBrowniePoints";
import StyledTableCell from "../../../components/styledComponent/CustomTableCell";
import { toast } from "material-react-toastify";
import moment from "moment";
import { clsDepartmentList } from "../../../service/common-services/department";
import { masterreasonServices } from "../../../service/BrowniePoints/masterReason-services";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Avatar, Divider } from "@mui/material";
import InfoPopover from "../../../components/CommonInfoComponent";
import { Monts1 } from "../../../Enum/eMonths";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
 import { DatePicker } from "react-rainbow-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { getDate } from "date-fns";
import { devNull } from "os";

const options = [
  {
    value: "",
    label: "All Months",
  },
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "February",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];
var n = "";
const Status = ["Approved", "Reject"];

const styleCross = {
  position: "absolute" as "absolute",
  right: "15px",
  top: "15px",
};

const styles = {
  view: {
    label: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#454545",
    },
    value: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      color: theme.palette.primary.dark,
    },
  },
};

export default function BrowniePoint() {
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { navigateYear } = location.state || {};
  console.log("navigateYear ===", navigateYear)

  // const [emplyeeDetails, setEmplyeeDetails] = useState<any>([]);
  const [emplyeeDetails, setEmplyeeDetails] =
    React.useState<IEmployeeBrowniePointsDetail>();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  // -----------------------------
  const [openView, setOpenView] = React.useState(false);
  const [valueDate, setValueDate] = React.useState<Dayjs | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorStatusEl, setAnchorStatusEl] =
    React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedIndex1, setSelectedIndex1] = React.useState(1);
  const [selectedIndex2, setSelectedIndex2] = React.useState(1);
  const open = Boolean(anchorEl);
  const openItem = Boolean(anchorStatusEl);
  const [order, setOrder] = React.useState<Order>("asc");
  const [order1, setOrder1] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("srNo");
  const [orderBy1, setOrderBy1] = React.useState<keyof Data1>("srNo");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [dense1, setDense1] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [rewardList, setRewardList] = useState<Data[]>([]);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [rewardAddOnList, setRewardAddOnList] = useState<Data1[]>([]);
  const [departmentsEl, setDepartmentsEl] = React.useState<null | HTMLElement>(
    null
  );
  const pageCount = Math.ceil(rewardList.length / rowsPerPage);
  const pageCount1 = Math.ceil(rewardAddOnList.length / rowsPerPage1);
  const openDepartmentsEl = Boolean(departmentsEl);
  const [selectedDepartmentIndex, setselectedDepartmentIndex] =
    React.useState(1);
  const [departmentHook, setDepartmentHook] = React.useState("");

  const currentDate = new Date();
  var monthName = currentDate.toLocaleString("default", { month: "long" });
  const abc = currentDate.getMonth() + 1;
  const [fromDate, setfromDate] = React.useState<any>(null);
  const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);
  const currentMonthNo = abc.toString();
  console.log(monthName, "monthName")


  const [selectedMonth, setSelectedMonth] = React.useState(currentMonthNo);
  const [monthset, setMonth] = React.useState(monthName);
  const [toggle, setToggle] = React.useState(false);
  //const [monthset, setMonth] = React.useState<any>("");
  let DepartmentId = "";
  const [selectedDepartmentId, setSelectedDepartmentId] = React.useState("");
  const departmentsList = new clsDepartmentList();
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  console.log(fromDate, "fromDate")

  const handleDepartmentsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDepartmentsEl(event.currentTarget);
  };
  const handleDepartmentsClose = () => {
    setDepartmentsEl(null);
  };

  const handleClickOpenView = async (id: any) => {
    getEmployeeDetailsById(id);
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };
  useEffect(() => {
    if (_permission.page_Admin_Brownie_Point_Access !== true) {
      navigate("/dashboard");
    }
    getAllMonthlyReward( "", monthset, navigateYear ? navigateYear : currentYearString);
    const departmentsData = async () => {
      setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
    };
    //setfromDate(currentYearString);
    setfromDate(navigateYear ? navigateYear : currentYearString);
    departmentsData();
  }, []);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [dynamicRowId, setdynamicRowId] = React.useState("");
  const handleClickStatusListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorStatusEl(event.currentTarget);
    setdynamicRowId(event.currentTarget.id);
  };

  const handleMenuItemClick = (
    value: any
  ) => {
    DepartmentId = departmentHook;
    setMonth(value);
    if (value === "All Months") {
      if (toggle == false) {
        getAllMonthlyReward(DepartmentId, "", fromDate);
      } else {
        getAllAddOnRequest(DepartmentId, "", "", fromDate);
      }
    } else {
      if (toggle == false) {
        getAllMonthlyReward(DepartmentId, value, fromDate);
      } else {
        getAllAddOnRequest(DepartmentId, "", value, fromDate);
      }
    }
  };

  const handleSatusItemClick = (
    event: React.MouseEvent<HTMLElement>,
    id: any
  ) => {
    setAnchorStatusEl(null);
    if ("Approved" === event.currentTarget.innerText) {
      approveAddOnRequest(dynamicRowId, 1);
    } else if ("Reject" === event.currentTarget.innerText) {
      approveAddOnRequest(dynamicRowId, 2);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusClose = () => {
    setAnchorStatusEl(null);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  type Order = "asc" | "desc";

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingComparator1<T>(a: T, b: T, orderBy1: keyof T) {
    if (b[orderBy1] < a[orderBy1]) {
      return -1;
    }
    if (b[orderBy1] > a[orderBy1]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | number },
    b: { [key in Key]: string | number }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function getComparator1<Key extends keyof any>(
    order1: Order,
    orderBy1: Key
  ): (
    a: { [key in Key]: string | number },
    b: { [key in Key]: string | number }
  ) => number {
    return order1 === "desc"
      ? (a, b) => descendingComparator1(a, b, orderBy1)
      : (a, b) => -descendingComparator1(a, b, orderBy1);
  }

  interface Data {
    id: string;
    departmentName: string;
    departmentId: string;
    managerId: string;
    managerName: string;
    resourcesCount: number;
    monthlyAllotment: number;
    month: string;
    addOnPoint: number;
    totalPoint: number;
    pointAssignedToTeam: number;
    srNo: string;
  }

  interface Department {
    id: string;
    name: string;
  }

  interface Data1 {
    addOnRequestId: string;
    department: string;
    managerName: string;
    resourceCount: string;
    requestedPoint: string;
    reason: string;
    requestDate: string;
    rejectReason: string;
    status: string;
    srNo: string;
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  interface HeadCell1 {
    disablePadding: boolean;
    id: keyof Data1;
    label: string;
    numeric: boolean;
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rewardList.length) : 0;
  const emptyRows1 =
 
    page1 > 0
      ? Math.max(0, (1 + page1) * rowsPerPage1 - rewardAddOnList.length)
      : 0;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRequestSort1 = (
    event: React.MouseEvent<unknown>,
    property: keyof Data1
  ) => {
    const isAsc = orderBy1 === property && order1 === "asc";
    setOrder1(isAsc ? "desc" : "asc");
    setOrderBy1(property);
  };

  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await masterreasonServices.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        toast.error(response.data.isError);
      } else {
        setDepartmentList(response.data);
      }
    });
  };

  const getAllMonthlyReward = async (
    department: any,
    months: any,
    year: any
  ) => {

    let values = {
      department: department,
      managers: "",
      months: months,
      year: year,
      take: 1000,
      skip: 0,
    };
    setRewardList([]);
    await masterreasonServices
      .getAllMonthlyReward(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setRewardList([]);
          setRewardList(response?.data);
        }
      });
  };

  const getEmployeeDetailsById = async (empId: any) => {
    await masterreasonServices
      .GetAllEmployeeDetailRewardPoints(empId)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setEmplyeeDetails(response?.data);
        }
      });
  };

  const approveAddOnRequest = async (id: any, status: any) => {
    try {
      let values = {
        id: id,
        rejectReason: "",
        status: status,
      };
      await masterreasonServices
        .approveAddOnRequest(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else if (status === 1) {
            toast.success("Approved successfully.");
            getAllAddOnRequest("", "", selectedMonth, "");
          } else if (status === 2) {
            toast.success("Rejected successfully.");
            getAllAddOnRequest("", "", selectedMonth, "");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getAllAddOnRequest = async (
    department: any,
    managers: any,
    months: any,
    year: any
  ) => {
    let values = {
      department: department,
      managers: managers,
      months: months,
      year: year,
      startDate: "",
      endDate: "",
      take: 1000,
      skip: 0,
    };
    await masterreasonServices
      .getAllAddOnRequest(values)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setRewardAddOnList([]);
          setRewardAddOnList(response?.data);
        }
      });
  };

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function stableSort1<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order1 = comparator(a[0], b[0]);
      if (order1 !== 0) {
        return order1;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleAddOnRequest = () => {
    setDepartmentHook("");
    setSelectedDepartmentId("");
    setSelectedIndex1(0);
    setMonth(monthName);
    setSelectedMonth(currentMonthNo);
    setToggle(true);
    getAllAddOnRequest("", "", currentMonthNo, currentYearString);
    setfromDate(currentYearString);
  };

  const handleMonthlyAllotment = () => {
    setDepartmentHook("");
    setSelectedDepartmentId("");
    setSelectedIndex1(0);
    setMonth(monthName);
    setSelectedMonth(currentMonthNo);
    setAnchorEl(null);
    setToggle(false);
    getAllMonthlyReward("",  monthset,currentYearString);
    setfromDate(currentYearString);
  };

  const handleChangePage1 = (event: unknown, newPage: number) => {
    setPage1(newPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  console.log(rewardList, "rewardList");

  // const handleChangeRowsPerPage1 = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage1(parseInt(event.target.value, 10));
  //   setPage1(0);
  // };

  const handleChangeRowsPerPage1  = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const headCells1: readonly HeadCell1[] = [
    {
      id: "department",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },
    {
      id: "managerName",
      numeric: false,
      disablePadding: false,
      label: "Manager",
    },
    {
      id: "resourceCount",
      numeric: false,
      disablePadding: false,
      label: "Resources",
    },
    {
      id: "requestedPoint",
      numeric: false,
      disablePadding: false,
      label: "Requested Point",
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "requestDate",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Action",
    },
  ];

  interface EnhancedTableProps1 {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data1
    ) => void;
    order1: Order;
    orderBy1: string;
    rowCount: number;
  }

  function EnhancedTableHead1(props: EnhancedTableProps1) {
    const { order1, orderBy1, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data1) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
    return (
      <TableHead>
        <TableRow>
          {headCells1.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy1 === headCell.id ? order1 : false}
            >
              <TableSortLabel
                active={orderBy1 === headCell.id}
                direction={orderBy1 === headCell.id ? order1 : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy1 === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order1 === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

          {/* <TableCell
          >
            Action
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "departmentName",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },
    {
      id: "managerName",
      numeric: false,
      disablePadding: false,
      label: "Manager",
    },
    {
      id: "resourcesCount",
      numeric: false,
      disablePadding: false,
      label: "Resources",
    },
    {
      id: "monthlyAllotment",
      numeric: false,
      disablePadding: false,
      label: "Monthly Allotment",
    },
    {
      id: "month",
      numeric: false,
      disablePadding: false,
      label: "Month",
    },
    {
      id: "addOnPoint",
      numeric: false,
      disablePadding: false,
      label: "Add-On-Points",
    },
    {
      id: "totalPoint",
      numeric: false,
      disablePadding: false,
      label: "Total Points Allotted",
    },
    {
      id: "pointAssignedToTeam",
      numeric: false,
      disablePadding: false,
      label: "Points Assigined To Team",
    },
  ];

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ whiteSpace: "nowrap" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  let currentYear = new Date().getFullYear();
  var currentYearString = currentYear.toString();
const Years = [];
for (let i = 0; i < 4; i++) {
  Years.push({
    id: currentYear - i,
    label: (currentYear - i).toString()
  });
}

console.log(currentYearString,"currentYearstring")

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Points</Typography>
        <Typography color="text.primary">Admin Brownie Points</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>



        <Box

          sx={{ pb: 3, mt: 2 }}
        >

          <Typography variant="h5" component="h2" sx={{ fontWeight: 600, display: "flex", mb: 2 }}>
          Admin Brownie Point
            <InfoPopover onInput={"Brownie Point"} />
          </Typography>
          <Box>



            <Stack direction="row" alignItems="center" spacing={2}>
              <Box>
               
                <Autocomplete
                  value={
                    departmentList?.find(
                      (option) => option.id == selectedDepartmentId
                    ) ?? null
                  }
                  sx={{
                    width: "220px",
                    mr: 1,
                    lineHeight: "0.80em",
                  }}
                  fullWidth
                  id="selectedUserId"
                  options={departmentList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setDepartmentsEl(null);
                    setfiltersSelected(true)
                    setDepartmentHook(value?.id ?? "");
                    DepartmentId = value?.id ?? "";
                    setRewardList([]);
                    if (monthset === "All Months") {
                      getAllMonthlyReward(DepartmentId, "", fromDate);
                      getAllAddOnRequest(DepartmentId, "", "", fromDate);
                    } else {
                      getAllMonthlyReward(DepartmentId, monthset, fromDate);
                      getAllAddOnRequest(DepartmentId, "", selectedMonth, fromDate);
                    }
                    setSelectedDepartmentId(value?.id ?? "");
                  }} 
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedDepartmentId}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box>
                {/* <TextField
      id="outlined-basic"
      variant="outlined"
      value={monthset}
      select
      fullWidth
      InputProps={{
        endAdornment: monthset != monthName && (
          <IconButton
            onClick={handleClearClickMonth}
            sx={{ marginRight: "15px"  }}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    >
      {options.map((key: any, index: any) => (
        <MenuItem
          key={key?.value}
          value={key?.label}
          onClick={(event) => {
            handleMenuItemClick(event, index, key.value);
            setSelectedMonth(key.value ?? "");
          }}
        >
          {key?.label}
        </MenuItem>
      ))}
    </TextField> */}
                <Autocomplete
                  value={
                    Monts1?.find(
                      (option: any) => option.id == selectedMonth
                    ) ?? null
                  }
                  sx={{
                    mr: 1,
                    lineHeight: "0.80em",
                    width: "170px",
                  }}
                  fullWidth
                  id="outlined-basic"
                  options={Monts1}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    setPage(0);
                    handleMenuItemClick(value?.id ?? currentMonthNo);
                    setSelectedMonth(value?.id ?? currentMonthNo);
                    setfiltersSelected(true);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedMonth}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Autocomplete
                  value={
                    Years?.find(
                      (option: any) => option.id == fromDate
                    ) ?? null
                  }
                  sx={{
                    mr: 1,
                    lineHeight: "0.80em",
                    width: "170px",
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  fullWidth
                  id="outlined-basic"
                  options={Years}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    setfromDate(value?.label ?? currentYearString)
                    getAllMonthlyReward(DepartmentId, monthset, value?.label ?? currentYearString);
                    getAllAddOnRequest(DepartmentId,"", selectedMonth,value?.label ?? currentYearString);
                    setPage(0);
                    setfiltersSelected(true);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedMonth}
                      fullWidth
                    />
                  )}
                />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}  >
                <DatePicker
                  // disableFuture
                  label="Year"
                  style={{ width: '20%' }}                  
                  placeholder="Year"
                  labelAlignment="left"
                  formatStyle="medium"
                  borderRadius="semi-square"
                  value={fromDate}
                  onChange={(newValue: any) => {
                    debugger
                    let abc = new Date(newValue)
                    setfromDate(abc);
                    setfiltersSelected(true)
                  }}
                />         
              </LocalizationProvider> */}

              {filtersSelected && (
                <Button
                  onClick={() => {
                    setfiltersSelected(false);
                    setfromDate(currentYearString);
                    setMonth(monthName)
                    getAllMonthlyReward("", monthName, currentYearString);
                    getAllAddOnRequest("", "", currentMonthNo, currentYearString);
                    setSelectedDepartmentId("");
                    setSelectedMonth(currentMonthNo)
                  }}
                  color="warning"
                  variant="contained"
                  sx={{ ml: 0.5 }}
                >
                    Clear
                  </Button>
              )}
            </Stack>
          </Box>
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Monthly Allotment"
                  value="1"
                  onClick={() => handleMonthlyAllotment()}
                />
                <Tab
                  label="Add-on Request"
                  value="2"
                  onClick={() => handleAddOnRequest()}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ px: 0, pt: 0 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rewardList.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rewardList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.departmentName);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            //  onClick={(event) => handleClick1(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.departmentName}
                            selected={isItemSelected}
                          >
                            <StyledTableCell align="left">
                              {row.departmentName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.managerName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.resourcesCount}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.monthlyAllotment}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.month}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.addOnPoint}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.totalPoint}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.pointAssignedToTeam}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="view"
                                  color="error"
                                  // onClick={() => handleClickOpenView(row.id)}
                                  // component={Link}
                                  //     to={`/admin-brownie-view/${row?.departmentId}/${row?.managerId}`}
                                  //component={Link}
                                  //to={`/admin-brownie-view/${row?.departmentId}/${row?.managerId}`}

                                  onClick={() => {
                                    debugger;
                                       const additionalVariable = fromDate;
                                       const selectedMonth = row?.month;
                                       navigate(`/admin-brownie-view/${row?.departmentId}/${row?.managerId}`, { state: { additionalVar:  additionalVariable?additionalVariable : navigateYear, selectedMonth } })
                                   }}
                                >
                                  <Icons.View />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={rewardList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}

                labelDisplayedRows={() => `Total: ${rewardList?.length}  Page ${page + 1} of ${pageCount}`}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ px: 0, pt: 0 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense1 ? "small" : "medium"}
                >
                  <EnhancedTableHead1
                    numSelected={selected.length}
                    order1={order1}
                    orderBy1={orderBy1}
                    onRequestSort={handleRequestSort1}
                    rowCount={rewardAddOnList.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort1(
                      rewardAddOnList,
                      getComparator1(order1, orderBy1)
                    )
                      .slice(
                        page1 * rowsPerPage1,
                          
                        page1 * rowsPerPage1 + rowsPerPage1
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.addOnRequestId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            //  onClick={(event) => handleClick1(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.addOnRequestId}
                            selected={isItemSelected}
                          >
                            <StyledTableCell align="left">
                              {row.department}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.managerName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.resourceCount}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.requestedPoint}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.reason}
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              {`${moment(row.requestDate).format(
                                "DD.MMM.YYYY"
                              )}`}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Box>
                                {row.status == "3" && (
                                  <>
                                    <Button
                                      id={row.addOnRequestId}
                                      aria-label="when device is locked"
                                      aria-controls={
                                        openItem ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        openItem ? "true" : undefined
                                      }
                                      onClick={handleClickStatusListItem}
                                      sx={{ justifyContent: "space-between" }}
                                      variant="selectSmall"
                                    >
                                      Pending
                                      <ArrowDropDownIcon />
                                    </Button>
                                    <Menu
                                      anchorEl={anchorStatusEl}
                                      open={openItem}
                                      onClose={handleStatusClose}
                                      MenuListProps={{
                                        "aria-labelledby": "lock-button",
                                        role: "listbox",
                                      }}
                                    >
                                      {Status.map((option, index) => (
                                        <MenuItem
                                          key={option}
                                          id={option}
                                          onClick={(event) => {
                                            handleSatusItemClick(
                                              event,
                                              `${row.addOnRequestId}`
                                            );
                                          }}
                                        >
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Menu>
                                  </>
                                )}
                                {row.status == "1" && (
                                  <Chip variant="approved" label="Approved" />
                                )}
                                {row.status == "2" && (
                                  <Chip variant="hold" label="Rejected" />
                                )}
                              </Box>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows1 > 0 && (
                      <TableRow
                        style={{
                          height: (dense1 ? 33 : 53) * emptyRows1,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={rewardAddOnList.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}

                labelDisplayedRows={() => `Total: ${rewardAddOnList?.length}  Page ${page1 + 1} of ${pageCount1}`}
              
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
      <Dialog
        open={openView}
        onClose={setOpenView}
        maxWidth="md"
        scroll="body"
        fullWidth
      >
        <IconButton sx={styleCross} onClick={handleCloseView}>
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: "600", fontSize: "26px" }}
        >
          Recommend Points
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs sm={2}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 92, height: 92 }}
              />
            </Grid>
            <Grid item xs>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Emp Id {emplyeeDetails?.userName}
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    {emplyeeDetails?.userName}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Total Earned Points
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    {emplyeeDetails?.totalEarnedPoint} points
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Value
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    Rs. {emplyeeDetails?.valueOfPointToRedeem}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={[styles.view.label]}>
                    Points Redeemed
                  </Typography>
                  <Typography variant="h5" sx={[styles.view.value]}>
                    {emplyeeDetails?.pointToRedeem} Points
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Point Type </TableCell>
                  <TableCell> Points Earned</TableCell>
                  <TableCell>Points Value</TableCell>
                  <TableCell> Referred By Date</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
