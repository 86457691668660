import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  Stack,
  TextField,
  Box,
  MenuItem,
  Button,
  Grid,
  Autocomplete,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  TablePagination,
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import theme from "../../theme/theme";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { masterDataService } from "../../service/master-service/master-data-service";
import { toast } from "material-react-toastify";
import { reportService } from "../../service/Reports/reports";
import {
  ProjectReport,
  ProjectReportFilter,
  ProjectReportResponse,
} from "../../Interfaces/IReport";
import { clsResourcesList } from "../../service/common-services/resources";
import { clsDepartmentList } from "../../service/common-services/department";
import { useSelector } from "react-redux";
import { Iproject } from "../../Interfaces/IProject";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import InfoPopover from "../../components/CommonInfoComponent";
import { settingManagementServive } from "../../service/setting-board-service";
import { DatePicker } from "react-rainbow-components";
import getDate from "../../components/GetDateWithSimpletime";
import FileSaver, { saveAs } from "file-saver";
// import XLSX from "sheetjs-style";
import { CSVLink, CSVDownload } from "react-csv";
import * as Icons from "../../assests/icons/icons";
import * as XLSX from "xlsx";

import moment from "moment";
import HrReporttop from "./HrReportsstyle";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { NullLogger } from "@microsoft/signalr";
const styles = {
  filter: {
    minWidth: "32%",
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down("xl")]: {
      mb: 0.5,
    },
    [theme.breakpoints.up("xl")]: {
      mb: 0.5,
      minWidth: "18%",
    },
  },
  dateFilter: {
    lineHeight: "0.80em",
    "& .MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
    },
  },
};

const calculateData = (timeTaken: any) => {
  if (timeTaken == "0" || timeTaken=="" || timeTaken== null) {
    return `00:00 hrs`;
  }
  else {
    // const hours = Math.floor(timeTaken);
    // const minutes = Math.round((timeTaken - hours) * 60);
    const [hours,minutes]=timeTaken?.split('.')?.map(String);
    const abc = parseInt(timeTaken) > 1 ? "   " : " hrs"
    if (minutes > 0 && hours != 0) {
      return `${hours}${abc} ${minutes} hrs`;
    } else {
      if(hours == 0){
        return `${hours} : ${minutes} hrs`;
      }
      else{
        return `${hours} hrs`;
      }
      
    }
  }

};

const calculateOfflineData = (timeTaken: any) => {
  
  if(timeTaken == null){
    return `00:00 hrs`;
  } else {
    const [hours,minutes]=timeTaken?.split('.')?.map(String);
    const abc = parseInt(timeTaken) >= 1 ? "   " : " hrs"
    if (minutes > 0 && hours != 0) {
      return `${hours}${abc} ${minutes} hrs`;
    } else {
      if(hours == 0){
        return `${hours} : ${minutes} hrs`;
      }
      else{
        return `${hours} hrs`;
      }
      
    }
  }
  // const hours = Math.floor(timeTaken);
  // const minutes = Math.round((timeTaken - hours) * 60);
  
};






interface Department {
  id: string;
  name: string;
}
interface Resource {
  userId: string;
  userName: string;
}
enum TaskStatus {
  "Select Status" = "",
  "Product Backlog" = "Product Backlog",
  "Sprint Backlog" = "Sprint Backlog",
  "In Progress" = "In Progress",
  "Review" = "Review",
  "Resolved" = "Resolved",
  "QA" = "QA",
  "QA Failed" = "QA Failed",
  "Hold" = "Hold",
  "Blocked" = "Blocked",
  "Closed" = "Closed",
}


export default function ReportManagement() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ReportPermissionApp.ReportPermission
  );
  const taskStatus = Object.keys(TaskStatus);
  const optionsStatus = ["Active", "In-Active"];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dummyTotalTime, setDummyTotalTime] = React.useState("09:06 hrs");


  // All status
  const [reportPeriodHook, setreportperiodHook] = React.useState("Select");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [projectReportData, setProjectReportData] = React.useState<any>(null);
  const [projectReportArray, setProjectReportArray] = React.useState([]);
  const pageCount = Math.ceil(projectReportArray.length / rowsPerPage);

  const [totalAssignedHours, setTotalAssignedHours] = React.useState("");
  const [totalConsumedHours, setTotalConsumedHours] = React.useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [reportStatusesHook, setreportStatusesHook] =
    React.useState("Select Status");
  const [reportStatusesValueHook, setreportStatusesValueHook] =
    React.useState("");

  const [reportDepartmentsEl, setreportDepartmentsEl] =
    React.useState<null | HTMLElement>(null);
  const openReportDepartmentsEl = Boolean(reportDepartmentsEl);
  const handleReportDepartmentsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportDepartmentsEl(event.currentTarget);
  };
  const handleReportDepartmentsClose = () => {
    setreportDepartmentsEl(null);
  };
  const [reportResourcesEl, setreportResourcesEl] =
    React.useState<null | HTMLElement>(null);
  const openReportResourcesEl = Boolean(reportResourcesEl);
  const handleReportResourcesClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setreportResourcesEl(event.currentTarget);
  };
  const handleReportResourcesClose = () => {
    setreportResourcesEl(null);
  };
  ////Radio button Programming
  const [selectedRadioValue, setSelectedRadioValue] = React.useState("Project");

  const [expanded, setExpanded] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  ////
  let DepartmentId = "";
  var departmentData = { id: "", name: "All Departments" };
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [selectedDepartmentId, setselectedDepartmentId] = React.useState("");
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const [departmentHook, setDepartmentHook] = React.useState("");
  var resourceData = { userId: "", userName: "Resources" };
  const [resources, setresources] = React.useState<Resource[]>([resourceData]);
  const [resourcesListByProjectId, setResourcesListByProjectId] =
    React.useState<any[]>([]);
  const [selectedResourcesId, setSelectedResourcesId] = React.useState("");
  const [ResourcesHook, setResourcesHook] = React.useState("");
  const [fromDate, setfromDate] = React.useState<any>(null);
  const [toDate, settoDate] = React.useState<any>(null);
  const setdatePeriod = (fdate: any, tdate: any) => {
    if (fdate && tdate) {
      if (
        dayjs(fdate).format("DD-MMM-YYYY") == dayjs(tdate).format("DD-MMM-YYYY")
      ) {
        setreportperiodHook("Daily");
      } else if (
        dayjs(fdate).format("DD-MMM-YYYY") ==
        dayjs(tdate).subtract(7, "day").format("DD-MMM-YYYY")
      ) {
        setreportperiodHook("Weekly");
      } else if (
        dayjs(fdate).format("DD-MMM-YYYY") ==
        dayjs(tdate).subtract(1, "month").format("DD-MMM-YYYY")
      ) {
        setreportperiodHook("Monthly");
      } else {
        setreportperiodHook("Select");
      }
    } else {
      setreportperiodHook("Select");
    }
  };

  // accordian
  let projectId = "";
  const [projectHook, setProjectHook] = React.useState("");
  const [projects, setProjects] = React.useState<Iproject[]>([]);
  const [byProjectsList, setPyProjectsList] = React.useState<any[]>([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState("");
  const [selectedProjectName, setSelectedProjectName] = React.useState("");
  const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);
  const [projectList, setprojectList] = React.useState<any>([]);
  const [milestoneHook, setMilestoneHook] = React.useState("");
  const [milestoneDropData, setMilestoneDropData] = React.useState<any[]>([]);
  const [milestoneID, setMilestoneID] = React.useState("");

  var projectData = { id: "0", projectName: "Select Projects" };
  var projectData1 = { id: "0", name: "Select Projects" };
  var resource = { id: "0", name: "Select Resource" };
  const getActiveProjects = async (id: any) => {
    await masterDataService
      .getProjectByDeptartmentId(id)
      .then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          let data = [];
          data.push(projectData);
          response.data.map((dep: any) => {
            data.push(dep);
          });
          setProjects(data);
        }
      });
  };
  const getProjDetails = async () => {
    await settingManagementServive
      .getAllProjectSecure(0)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          let data = [];
          data.push(projectData1);
          response.data.map((dep: any) => {
            data.push(dep);
          });

          setPyProjectsList(response?.data);
        }
      });
  };
  const getProjDetailsOnRolebase = async () => {
    await settingManagementServive
      .getAllProjectSecureOnRoleBase()
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          let data = [];
          data.push(projectData);
          response.data.map((dep: any) => {
            // Replace "name" key with "projectname"
            const updatedDep = { ...dep, projectName: dep.name };
            delete updatedDep.name; //  Remove the old "name" key if needed
            data.push(updatedDep);
          });
          setProjects(data);
        }
      });
  };

  var reportData: ProjectReport = {
    resource: "",
    task: "",
    status: "",
    loggedHours: "",
    startedOn: "",
    completedOn: "",
    taskTAT: "",
    totalAllocatedHours: "",
    actualHours: "",
    taskId: "",
    projectName: "",
  };
  var reportResponseData: ProjectReportResponse = {
    date: "",
    totalConsumedHours: "",
    totalAssignedHours: "",
    totalConsumedDays: "",
    listRecords: [],
  };
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  //const [reports, setreports] = React.useState<ProjectReportResponse[]>([]);
  const [reports, setreports] = React.useState<any[]>([]);


  const [isDataSearched, setisDataSearched] = React.useState(false);

  const formatTime = (timeString: any) => {
    const [hours, minutes] = timeString.split(":");
    const formattedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes}`;
  };

  const getReport = async (
    skip: number = 0,
    take: number = 1000,
    departmentId: string = "",
    projectId: string = "",
    resourceId: string = "",
    status: string = "",
    type: string = "",
    startDate: any = "",
    endDate: any = ""
  ) => {
    
    try {
      debugger
      setToggleLoader(true);
      var projectReportFilter: ProjectReportFilter = {
        skip: skip,
        take: take,
        departmentId: departmentId,
        projectId: projectId == "0" ? "" : projectId,
        resourceId: resourceId,
        milestoneId: milestoneID,
        status: status,
        type: type,
        startDate: startDate ? dayjs(startDate).format("DD-MMM-YYYY") : "",
        endDate: endDate ? dayjs(endDate).format("DD-MMM-YYYY") : "",
      };
      // TotalAllocatedHrs = 0;
      // TotalActualHrs = 0;

      await reportService
        .getReport(projectReportFilter)
        .then((response: any) => {
          if (response.data.isError) {
            setToggleLoader(false);
          } else {
            
            setisDataSearched(true);
            setProjectReportData(response.data);
            setProjectReportArray(response.data.records);
            if (response.data?.records?.length > 0) {              

              var tempGrandTotalAssignedHours = response.data.allTotalAssignedHours;
              var tempGrandTotalConsumedHours = response.data.allTotalConsumedHours;

              setTotalAssignedHours(response.data.allTotalAssignedHours);
              setTotalConsumedHours(response.data.allTotalConsumedHours);
              setToggleLoader(false);

            }
          }
          setToggleLoader(false);
          setExpanded(true);
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectsId = params.get("projectsId") || "";
  const userId = params.get("userId") || "";
  const startDate = params.get("startDate") || "";
  const endDate = params.get("endDate") || "";
  useEffect(() => {
      if (_permission.page_ManageReportReport_Access !== true) {
          navigate("/dashboard");
      }
      const departmentsData = async () => {
          if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
              if (_permission.action_ReportReport_ReportListing_AllDepartments_Access) {
                  setdisabledDepartments(false);
              } else {
                  setdisabledDepartments(true);
              }
              if (selectedRadioValue === "Project") {
                  if (projectsId && userId || startDate || endDate) {
                      setfiltersSelected(true);
                      setProjectHook(projectsId);
                      setResourcesListByProjectId(await clsResourcesList.getResourcesByProjectId(projectsId));
                      setSelectedProjectId(projectsId);
                      setSelectedResourcesId(userId);
                      setResourcesHook(userId);
                      setfromDate(startDate);
                      settoDate(endDate);
                      getReport(0, 1000, "", projectsId, userId, "", "", startDate || null, endDate || null);
                  } else {
                      setDepartmentHook("");
                      setselectedDepartmentId(_authUser.employeeInfo.departmentId);
                  }
              } else {
                  setselectedDepartmentId(_authUser.employeeInfo.departmentId);
                  setDepartmentHook(_authUser.employeeInfo.departmentId);
              }
              if (_permission.action_ReportReport_ReportListing_AllProjects_Access) {
                  getProjDetailsOnRolebase();
              } else {
                  getActiveProjects(_authUser.employeeInfo.departmentId);
              }
              setDepartments(await clsDepartmentList.getDepartmentDropdownList());
              setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
          } else {
              setDepartments(await clsDepartmentList.getDepartmentDropdownList());
              await getProjDetails();
              if (projectsId && userId || startDate || endDate) {
                  setfiltersSelected(true);
                  setSelectedProjectId(projectsId);
                  setProjectHook(projectsId);
                  setResourcesListByProjectId(await clsResourcesList.getResourcesByProjectId(projectsId));
                  setSelectedResourcesId(userId);
                  setResourcesHook(userId);
                  setfromDate(startDate);
                  settoDate(endDate);
                  getReport(0, 1000, "", projectsId, userId, "", "", startDate || null, endDate || null);
              }
          }
      };
      departmentsData();
  }, [navigate, _authUser, _permission, projectsId, userId, startDate, endDate]);




  
  const handleFromDateChange = (newValue: any) => {
    if (newValue) {
      const newFromDate = dayjs(newValue).toDate(); // Ensure newValue is valid
      setfromDate(newFromDate);
      setfromDateOrgl(newFromDate); 
      if (dayjs(toDate).isBefore(newFromDate)) {
        settoDate(dayjs(newFromDate).add(1, 'day').toDate());
      }
      setfiltersSelected(true);
    }
  };
  const handleToDateChange = (newValue: any) => {
    if (newValue) {
      const newToDate = dayjs(newValue).toDate(); // Ensure newValue is valid
      settoDate(newToDate);
      setfiltersSelected(true);
    }
  };
  const getMilestoneDropdownData = async (id: string) => {
    
    await reportService.getMilestoneData(id).then((response: any) => {
      if (response.data.isError) {

      } else {
        console.log("Heeee", response.data);
        setMilestoneDropData(response.data);
      }
    })
  }

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const getProjectCSVReport = async () => {
    
    var req = {
      projectId: selectedProjectId,
      resourceId: selectedResourcesId,
      status: reportStatusesValueHook ? reportStatusesValueHook : "",
      type: reportPeriodHook ? reportPeriodHook : "",
      startDate: fromDate ? dayjs(fromDate).format("YYYY-MMM-DD") : "",
      endDate: toDate ? dayjs(toDate).format("YYYY-MMM-DD") : "",
      milestoneId: milestoneID,
      departmentId: selectedDepartmentId,
    };
    try {
      await reportService.getDownloadCSVReport(req).then((response: any) => {
        if (response.data.isError) {
          // Handle error if needed
        } else {
          // console.log(response?.data, "response?.data");
          const dataWithSums = calculateSums(response?.data);
          ExportCSV(dataWithSums);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const calculateSums = (data: any[]) => {
    

    const parseTime = (time: any) => {
      if (!time || time === "0") return 0;
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + (minutes || 0); // Convert hours and minutes to total minutes
    };

    const totalMinutes = data.reduce((acc, row) => {
      if (Array.isArray(row.hour)) {
        row.hour.forEach((h: any, index: any) => {
          const minutes = parseTime(h);
          acc[index] = (acc[index] || 0) + minutes;
        });
      }
      return acc;
    }, []);

    const totalMinutesSum = totalMinutes.reduce(
      (sum: any, value: any) => sum + value,
      0
    );

    const convertMinutesToTime = (minutes: any) => {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`; // Format to HH:MM
    };

    const totalHours = totalMinutes.map(convertMinutesToTime);
    const totalSum = convertMinutesToTime(totalMinutesSum);

    const sums = {
      date: "Total Working Hours Of Resource",
      hour: totalHours,
    };

    const teamTotal = {
      date: "Total Working Hours Of Team",
      hour: [totalSum], // Wrap totalSum in an array
    };

    const emptyRowsAfterTotal = { date: "", hour: [] };
    const result = [...data, sums, emptyRowsAfterTotal, teamTotal];
    return result;
  };

  const ExportCSV = (csvData: any) => {
    
    const fileType = "application/csv;charset-UTF-8";
    const csv = convertArrayOfObjectsToCSV(csvData);

    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, "Billing Report.csv");
  };

  const convertArrayOfObjectsToCSV = (data: any[]) => {
    
    let isFirstEmptyRow = true;

    const csv = data.flatMap((row, index) => {
      const formattedDate =
        index === data?.length - 1 ? row.date : `"${row.date}"`;
      let formattedHour = "";

      if (Array.isArray(row.hour)) {
        formattedHour = row?.hour
          ?.map((h: any) => (typeof h === "string" ? `"${h}"` : h))
          .join(",");
      }

      // Check if it's the first empty row and add resource-wise tasks
      if (index < data?.length - 1 && isFirstEmptyRow) {
        isFirstEmptyRow = false;
        return [
          "Resource-Wise Working Hours",
          `${formattedDate},${formattedHour}`,
        ];
      }

      return [`${formattedDate},${formattedHour}`];
    });

    return csv.join("\n");
  };

  return (
    <>

      <Accordion sx={{ margin: "0" }} expanded={!expanded}>
        <AccordionSummary

          expandIcon={<KeyboardArrowUpIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ px: 2, pb: 0, position: "sticky", top: "64px ", zIndex: 99 }}
          onClick={handleToggle}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                gap: "12px",
                flexDirection: "row",
              }}
            >
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                Project Report
              </Typography>
              <InfoPopover onInput={"Reports"} />



            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={3}
              alignItems={"center"}
            >
              {projectReportData !== null && (
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={3}
                  alignItems={{ xs: "flex-start", md: "center" }}
                  justifyContent={{ xs: "flex-start", md: "flex-end" }}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    justifyContent="flex-start"

                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FiberManualRecordIcon
                        sx={{ color: theme.palette.success.main }}
                      />{" "}
                      Online
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        mx: 2,
                      }}
                    >
                      <FiberManualRecordIcon
                        sx={{ color: theme.palette.error.main }}
                      />{" "}
                      Offline
                    </Typography>
                    {/* <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                     
                    </Typography> */}
                  </Stack>

                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={1}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    justifyContent={{ xs: "flex-start", md: "flex-end" }}

                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                        mr: 1,
                      }}
                    >
                      Alloc. Hrs {totalAssignedHours}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                      }}
                    >
                      Act. Hrs {totalConsumedHours}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              {/* {selectedRadioValue === "Project" && ( */}
                <IconButton
                  sx={{ mr: "20px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    getProjectCSVReport();
                  }}
                  title="Download Billing Report"
                  aria-label="Download Billing Report"
                >
                  <Icons.xlsxicon />
                </IconButton>
              {/* )} */}
            </Stack>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Paper
            elevation={0}
            sx={{ py: 0, mb: 2, position: "sticky", top: "64px ", zIndex: 99, marginTop: "-32px" }}
          >
            <Box sx={{ flexGrow: 1 }} />

            <Grid
              container
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
            >
              <Grid item xs={12} md={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Project"
                  name="radio-buttons-group"
                  onChange={(event) =>
                    setSelectedRadioValue(event.target.value)
                  }
                >
                  <FormControlLabel
                    value="Project"
                    control={<Radio />}
                    label="By Project"
                    onClick={() => {
                      setfiltersSelected(false);
                      if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                        setselectedDepartmentId(
                          _authUser.employeeInfo.departmentId
                        );
                        setDepartmentHook("");
                      } else {
                        setselectedDepartmentId("");
                        setDepartmentHook("");
                      }
                      setProjectReportData(null);
                      setSelectedResourcesId("");
                      setSelectedProjectId("");
                      setResourcesHook("");
                      setreportperiodHook("Select");
                      setreportStatusesHook("Select Status");
                      setreportStatusesValueHook("");
                      setfromDate(null);
                      settoDate(null);
                      setProjectHook("");
                      setreports([]);
                    }}
                  />
                  <FormControlLabel
                    value="Department"
                    control={<Radio />}
                    label="By Department"
                    onClick={async () => {
                      
                      window.history.replaceState({}, '')
                      setfiltersSelected(false);
                      if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                        setselectedDepartmentId(
                          _authUser.employeeInfo.departmentId
                        );
                        setDepartmentHook(_authUser.employeeInfo.departmentId);
                      } else {
                        setselectedDepartmentId("");
                        setDepartmentHook("");
                      }
                      setProjectReportData(null);
                      setSelectedResourcesId("");
                      setSelectedProjectId("");
                      setResourcesHook("");
                      setreportperiodHook("Select");
                      setreportStatusesHook("Select Status");
                      setreportStatusesValueHook("");
                      setfromDate(null);
                      settoDate(null);
                      setreports([]);
                      setProjectHook("");
                      setresources(
                        await clsResourcesList.getResourcesDropdownList(
                          _authUser.employeeInfo.departmentId ?? ""
                        )
                      );
                    }}
                  />
                </RadioGroup>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "strech", sm: "center" }}
                  sx={{ flexWrap: "wrap" }}
                >
                  {selectedRadioValue === "Department" && (
                    <Box sx={[styles.filter]}>
                      <Autocomplete
                        value={
                          departments?.find(
                            (option) => option.id == selectedDepartmentId
                          ) ?? null
                        }
                        //fullWidth
                        id="outlined-basic"
                        options={departments}
                        getOptionLabel={(option) => option.name}
                        disabled={
                          selectedRadioValue != "Department" ||
                          disabledDepartments
                        }
                        onClose={() => {
                          handleReportDepartmentsClose();
                          setResourcesHook("");
                        }}
                        onChange={async (event, value) => {
                          
                          if (!value) {
                            setProjectReportData(null);
                          }
                          setselectedDepartmentId(value?.id ?? "");
                          setfiltersSelected(true);
                          //handleMenuItemClick( id)
                          // await getUsersDetails(value?.id, searchValue);
                          setDepartmentHook(value?.id ?? "");
                          setResourcesHook("");
                          setresources(
                            await clsResourcesList.getResourcesDropdownList(
                              value?.id ?? ""
                            )
                          );
                          DepartmentId = value?.id ?? "";
                        }}
                        componentsProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "flip",
                                enabled: true,
                                options: {
                                  altBoundary: true,
                                  flipVariations: true,
                                  altAxis: true,
                                  padding: 8,
                                  fallbackPlacements: ["bottom"],
                                },
                              },
                            ],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            name="element.name"
                            variant="outlined"
                            value={selectedDepartmentId}
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  )}
                  {selectedRadioValue === "Project" && (
                    <Box sx={[styles.filter]}>
                      {_authUser.typeOfUser != eTypeOfUser.Admin && (
                        <Autocomplete
                          disabled={selectedRadioValue != "Project"}
                          value={
                            projects?.find(
                              (option) => option.id == selectedProjectId
                            ) ?? projectData
                          }
                          //fullWidth
                          sx={{
                            lineHeight: "0.80em",
                          }}
                          id="outlined-basic"
                          options={projects}
                          getOptionLabel={(option: any) => option.projectName}
                          onChange={async (event, value) => {
                            if (!value) {
                              setProjectReportData(null);
                            }
                            if (value) {
                              let abc =
                                await clsResourcesList.getResourcesByProjectId(
                                  value?.id ?? ""
                                );
                              setResourcesListByProjectId(
                                abc !== undefined ? abc : []
                              );
                              getMilestoneDropdownData(value?.id ?? "");
                            }
                            setSelectedProjectId(value?.id ?? "");
                            setSelectedProjectName(value?.projectName ?? "");
                            setProjectHook(value?.id ?? "");
                            setfiltersSelected(true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              //fullWidth
                              id="outlined-basic"
                              name="element.name"
                              variant="outlined"
                              value={selectedProjectId}
                              fullWidth
                            />
                          )}
                        />
                      )}
                      {_authUser.typeOfUser === eTypeOfUser.Admin && (
                        <Autocomplete
                          disabled={selectedRadioValue != "Project"}
                          value={
                            byProjectsList?.find(
                              (option) => option.id == selectedProjectId
                            ) ?? projectData1
                          }
                          //fullWidth
                          sx={{
                            lineHeight: "0.80em",
                          }}
                          id="outlined-basic"
                          options={byProjectsList}
                          getOptionLabel={(option: any) => option.name}
                          onChange={async (event, value) => {
                            
                            if (!value) {
                              setProjectReportData(null);
                            }
                            setSelectedProjectId(value?.id ?? "");
                            setSelectedProjectName(value?.name ?? "");
                            setProjectHook(value?.id ?? "");
                            setfiltersSelected(true);

                            if (value) {
                              setResourcesListByProjectId(
                                await clsResourcesList.getResourcesByProjectId(
                                  value?.id ?? ""
                                )
                              );
                              getMilestoneDropdownData(value?.id ?? "");
                            }
                            setSelectedResourcesId("");
                            setResourcesHook("");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              //fullWidth
                              id="outlined-basic"
                              name="element.name"
                              variant="outlined"
                              value={selectedProjectId}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Box>
                  )}
                  <Box sx={[styles.filter]}>
                    {selectedRadioValue == "Department" && (
                      <Autocomplete
                        disabled={!departmentHook || departmentHook === ""}
                        value={
                          resources?.find(
                            (option) => option.userId == selectedResourcesId
                          ) ?? null
                        }
                        fullWidth
                        id="outlined-basic"
                        options={resources}
                        getOptionLabel={(option) => option.userName}
                        // onClick={handleClickListItem}
                        onChange={async (event, value) => {
                          setSelectedResourcesId(value?.userId ?? "");
                          //handleMenuItemClick( id)
                          setfiltersSelected(true);
                          setResourcesHook(value?.userId ?? "");
                          // setselectedResourcesIndex(selectedResourcesId);
                        }}
                        componentsProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "flip",
                                enabled: true,
                                options: {
                                  altBoundary: true,
                                  flipVariations: true,
                                  altAxis: true,
                                  padding: 8,
                                  fallbackPlacements: ["bottom"],
                                },
                              },
                            ],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            name="element.name"
                            variant="outlined"
                            value={selectedResourcesId}
                            fullWidth
                          />
                        )}
                      />
                    )}
                    {selectedRadioValue == "Project" && (
                      <Autocomplete
                        disabled={!selectedProjectId || selectedProjectId == ""}
                        value={
                          resourcesListByProjectId?.find(
                            (option) => option?.id == selectedResourcesId
                          ) ?? resource
                        }
                        fullWidth
                        id="outlined-basic"
                        options={resourcesListByProjectId}
                        getOptionLabel={(option) => option?.name}
                        // onClick={handleClickListItem}
                        onChange={async (event, value) => {
                          setSelectedResourcesId(value?.id ?? "");
                          //handleMenuItemClick( id)
                          setfiltersSelected(true);
                          setResourcesHook(value?.id ?? "");
                          // setselectedResourcesIndex(selectedResourcesId);
                        }}
                        componentsProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "flip",
                                enabled: true,
                                options: {
                                  altBoundary: true,
                                  flipVariations: true,
                                  altAxis: true,
                                  padding: 8,
                                  fallbackPlacements: ["bottom"],
                                },
                              },
                            ],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            name="element.name"
                            variant="outlined"
                            value={selectedResourcesId}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Box>

                  {selectedRadioValue == "Project" && (
                    <Box sx={[styles.filter]}>
                      <Autocomplete
                        disabled={!selectedProjectId}
                        value={
                          milestoneDropData?.find(
                            (option) => option.id === milestoneID
                          ) ?? null
                        }
                        id="outlined-basic"
                        //options={mileStonesList}
                        options={milestoneDropData}
                        getOptionLabel={(option) => option.milestoneName}

                        onChange={async (event, value) => {
                          
                          if (!value) {
                            console.log("HEEHHEHE")
                          }
                          setMilestoneID(value?.id ?? "");
                          setfiltersSelected(true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // disabled={disabledDepartments}
                            id="outlined-basic"
                            name="element.name"
                            variant="outlined"
                            value={milestoneID}
                            placeholder="Select Milestones"
                            fullWidth
                            sx={{ minWidth: 160 }}
                          />
                        )}
                      />
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "strech", sm: "flex-end" }}
                  justifyContent={{ xs: "flex-start", md: "flex-end" }}
                  spacing={1}
                  sx={{ position: "relative", bottom: "-40px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // disableFuture

                      placeholder="From"
                      labelAlignment="left"
                      borderRadius="semi-square"
                      // openTo="year"
                      // views={["year", "month", "day"]}
                      // inputFormat="DD-MMM-YYYY"
                      maxDate={dayjs().toDate()}
                      value={fromDate}
                      onChange={(newValue: any) => {
                        setfromDateOrgl(newValue);
                        let abc = dayjs(newValue).toDate();
                        setfromDate(abc);
                        setdatePeriod(abc, toDate);
                        setfiltersSelected(true);
                        if (dayjs(toDate) < dayjs(newValue)) {
                          settoDate(
                            dayjs(newValue) < dayjs()
                              ? dayjs(newValue?.add(1, "day"))
                              : newValue
                          );
                        }
                      }}

                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={!fromDate}
                      // disableFuture

                      placeholder="To"
                      labelAlignment="left"
                      borderRadius="semi-square"
                      // openTo="year"
                      // views={["year", "month", "day"]}
                      // inputFormat="DD-MMM-YYYY"
                      maxDate={dayjs().toDate()}
                      minDate={fromDateOrgl}
                      value={toDate}
                      onChange={(newValue) => {
                        let abc = dayjs(newValue).toDate();
                        settoDate(abc);
                        setdatePeriod(fromDate, abc);
                        setfiltersSelected(true);
                      }}

                    />
                  </LocalizationProvider>
                  <Button
                    onClick={() => {
                      if (selectedRadioValue == "Project" && !projectHook) {
                        toast.warning("Please select project first.");
                      } else if (
                        selectedRadioValue == "Department" &&
                        !departmentHook
                      ) {
                        toast.warning("Please select department first.");
                      } else {
                        getReport(
                          0,
                          1000,
                          departmentHook,
                          projectHook,
                          ResourcesHook,
                          reportStatusesValueHook,
                          "",
                          fromDate,
                          toDate
                        );
                        setPage(0);
                      }
                    }}
                    color="primary"
                    variant="contained"
                    sx={{ ml: 0.5 }}
                  >
                    Apply
                  </Button>
                  {filtersSelected && (
                    <Button
                      onClick={() => {
                        setfiltersSelected(false);
                        if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                          if (selectedRadioValue == "Project") {
                            setDepartmentHook("");
                          } else {
                            setselectedDepartmentId(
                              _authUser.employeeInfo.departmentId
                            );
                            setDepartmentHook(
                              _authUser.employeeInfo.departmentId
                            );
                          }
                        } else {
                          setselectedDepartmentId("");
                          setDepartmentHook("");
                        }
                        setSelectedResourcesId("");
                        setSelectedProjectId("");
                        setResourcesHook("");
                        setreportperiodHook("Select");
                        setreportStatusesHook("Select Status");
                        setreportStatusesValueHook("");
                        setfromDate(null);
                        setfromDateOrgl(null);
                        settoDate(null);
                        setreports([]);
                        setisDataSearched(false);
                        setProjectHook("");
                        setMilestoneID("");
                        setProjectReportData(null);
                        window.history.replaceState({}, '')
                        setToggleLoader(false);
                      }}
                      color="warning"
                      variant="contained"
                      sx={{ ml: 0.5 }}
                    >
                      Clear
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
            {toggleLoader && (
              <CircularProgress sx={HrReporttop.toggleloaders} />
            )}

          </Paper>
        </AccordionDetails>
      </Accordion>

      {projectReportData?.records?.length > 0 && (
        <>
          <Paper elevation={0} sx={{ mt: 2 }}>
            <Grid container justifyContent="space-between" sx={{ mb: 0.5 }}>
              <Grid item xs={12} sm>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={1}
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  justifyContent="space-between"
                  sx={{ mt: 1, px: 2 }}
                >
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    sx={{ mr: 2 }}
                  >
                    {/* <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 500, color: theme.palette.primary.dark }}
                  >
                    {row.date}
                  </Typography> */}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>


            <TableContainer component={Paper} elevation={0}>
              <Table
                aria-label="collapsible table"
                size="small"
                sx={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child th": { whiteSpace: "nowrap" },
                      "&:last-child td,": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell sx={{ width: "200px", position: "sticky", left: 0, background: "#fff", zIndex: 1 }}>Project Name</TableCell>
                    <TableCell sx={{ width: "200px", position: "sticky", left: "200px", background: "#fff", zIndex: 1 }}>Task Name</TableCell>
                    <TableCell sx={{ width: "130px", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>Resource</TableCell>
                    <TableCell sx={{ width: "130px", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>Date</TableCell>
                    {/* <TableCell sx={{ width: "130px", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>Planned Hours</TableCell> */}
                  
                    <TableCell sx={{ width: "200px", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>
                        Hours Logged
                     {/* / </Typography> */}
                      <Typography variant="body2" component="div">
                        (Online + Offline)
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "150px", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>Total Hrs Logged</TableCell>
                    
                    
                    <TableCell sx={{ width: "150px", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectReportArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user: any, idx: any) => (
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                         <StyledTableCell align="left" sx={{position: "sticky", zIndex: "99", left:"0", background: "#fff"}}>
                          <Tooltip title={user?.projectName || "--"} placement="top" arrow>                           
                            <span>
                              {user?.task
                                ? user.projectName.substring(0, 20) + (user.projectName.length > 20 ? "..." : "")
                                : "--" }
                            </span>
                          </Tooltip>
                          
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{position: "sticky", zIndex: "99", left:"200px", background: "#fff"}} >
                          <Tooltip title={user?.task || ""} placement="top" arrow>
                            <span>
                              {user?.task
                                ? user.task.substring(0, 20) + (user.task.length > 20 ? "..." : "")
                                : "--"}
                            </span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Tooltip title={user?.resource || "--"}>
                            <span>{user?.resource || "--"}</span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {moment(user?.workingDay).format("DD-MMM-yy") || "--"}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left">
                        {user?.totalAllocatedHours != null ? `${user.totalAllocatedHours} Hrs` : "--"}
                        </StyledTableCell> */}
                        <StyledTableCell align="left" style={{ wordBreak: 'break-all' }}>
                          <span style={{ color: '#2DD36F' }}>
                            &nbsp;{calculateData(user?.actualHours)}
                          </span> +
                          &nbsp;
                          <span style={{ color: 'red' }}>
                            {calculateOfflineData(user?.offlineLoggedHours)}
                          </span>
                          &nbsp;
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{color:"#1DA7FF"}}>
                        {user?.totalConsumedHours != null ? calculateData(user.totalConsumedHours) : calculateData(user?.actualHours) }
                        </StyledTableCell>
                        
                       
                        <StyledTableCell align="left"><Chip variant={user?.status === "Product Backlog"
                          ? "hold"
                          : user?.status === "Sprint Backlog"
                            ? "hold"
                            : user?.status === "In Progress"
                              ? "progress"
                              : user?.status === "Review"
                                ? "review"
                                : user?.status === "QA"
                                  ? "submited"
                                  : user?.status === "QA Failed"
                                    ? "hold"
                                    : user?.status === "Hold"
                                      ? "hold"
                                      : user?.status === "Blocked"
                                        ? "hold"
                                        : user?.status === "Closed"
                                          ? "approved"
                                          : "new"} label={user?.status} /></StyledTableCell>

                      </TableRow>
                    ))}
                </TableBody>
              </Table>         
            </TableContainer>
          </Paper>
          <Box sx={{backgroundColor:"#fff"}}>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={projectReportArray?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}

            labelDisplayedRows={() => `Total: ${projectReportArray?.length}  Page ${page + 1} of ${pageCount}`}
          />
          </Box>
      
    </>

  )
}

{
  projectReportData?.records?.length === 0 && (
    <Paper elevation={0} sx={{ mb: 2 }}>
      <Grid container justifyContent="space-between" sx={{ mb: 0.5 }}>
        <Grid item xs={12} sm>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="space-between"
            sx={{ mt: 1, px: 2 }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              sx={{ mr: 2 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 500, color: theme.palette.primary.dark }}
              >
                No Record Found
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}
    </>
  );
}
