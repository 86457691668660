import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import theme from "../../theme/theme";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import * as Icons from "../../assests/icons/icons";
import { userManagementServive } from "../../service/user-management-service";
import { toast } from "material-react-toastify";
import Chip from "@mui/material/Chip";
import Brightness1Icon from "@mui/icons-material/Brightness1";

import {
  userModelDetail,
  userModel,
  employeeInfo,
  personalInfo,
  insuranceCard,
  documents,
  pastExperince,
  badges,
  achivements,
  skillsets,
  othersDocument,
} from "./userModel";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, CircularProgress, MenuItem } from "@mui/material";
import ItemPaper from "../../components/styledComponent/ItemPaper";

const styles = {
  chipSkill: {
    "&.MuiChip-root": {
      borderRadius: "4px",
      marginRight: "8px",
      marginBottom: "8px",
      "& .MuiChip-label": {
        color: theme.palette.grey[700],
      },
    },
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

interface TSkillSets {
  id: string;
  name: string;
}

export default function SkillSet(props: {
  obj: any;
  setValue: any;
  callBackFunction: any;
}) {
  //const { id } = useParams();
  //let uId = id ?? ""; //"632da2f9fa37260b70bf4e1f";
  setTimeout(() => {
    if (!props.obj && props.obj == "") {
      props.setValue(0);
    }
  }, 200);

  const [userIdHook, setUserIdHook] = React.useState(props.obj);
  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    props.obj,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],
    [_othersDocument],
    [_skillsets]
  );
  const [userDataHook, setUserDataHook] = React.useState(usermodel);

  var tskillsetsData = { id: "", name: "All Skillsets" };
  const [tskillsetsHook, setTskillsetsHook] = useState(tskillsetsData);
  const [tskillsets, setTskillsets] = useState<TSkillSets[]>([]);
  const [tskillsetsIndex, setTskillsetsIndex] = React.useState(1);
  const [DisabledButton, setDisabledButton] = useState(true);
  const [DisabledButton1, setDisableButton1]= useState(false);

  enum ChipColors {
    warning = "warning",
    success = "success",
    primary = "primary",
  }
  enum SkillLevel {
    Beginner = "Beginner",
    Intermediate = "Intermediate",
    Expert = "Expert",
  }
  let Formobj = { skillId: "", name: "", level: "" };
  const [formValues, setFormValues] = useState<any[]>([]);
  const [skillName, setskillName] = useState("");
  const [skillId, setskillId] = useState("");
  const [skillLevel, setskillLevel] = useState("beginner");

  let addFormFields = () => {
    if (
      skillName != null &&
      skillName.length > 0 &&
      skillId != null &&
      skillId.length > 0 &&
      skillLevel != null &&
      skillLevel.length > 0
    ) {
      Formobj.skillId = skillId;
      Formobj.name = skillName;
      Formobj.level = skillLevel;
      let fObj = [...formValues];
      fObj.push(Formobj);
      setFormValues(fObj);
      setskillId("");
      setskillName("");
      setskillLevel("beginner");
      setDisabledButton(false);
      setDisableButton1(false);
    } else {
      //setDisabledButton(true);
      setDisableButton1(true);
      if (
        skillName == null ||
        skillName.length == 0 ||
        skillId == null ||
        skillId.length == 0
      ) {
        toast.warning("Select Skill",{
          onOpen: () => setDisableButton1(true),
          onClose: () => setDisableButton1(false)
        });
      }
      if (skillLevel == null || skillLevel.length == 0) {
        toast.warning("Select Skill Level");
      }
    }
  };
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = (index: any) => {
    console.info("You clicked the delete icon.");
  };
  let removeFormFields = (i: any) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setDisabledButton(false);
  };

  let handleChange = (i: any, e: any) => {
    let newFormValues = [...formValues];
    //newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let handleSubmit = (event: any) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };

  const getUserDetails = async () => {
    try {
      if (props.obj != "") {
        await userManagementServive
          .getUserById(props.obj)
          .then((response: any) => {
            if (response.data.isError) {
            } else {
              setUserDataHook(response.data);
              if (response.data.skillsets.length > 0) {
                setFormValues(response.data.skillsets);
              }
            }
          });
      }
    } catch {
      //props.setValue(0);
    }
  };

  const getSkillsets = async () => {
    let prm = { module: 2, status: "active" };
    await userManagementServive.getDropdownData(prm).then((response: any) => {
      if (response.data.isError) {
        setTskillsets([tskillsetsData]);
      } else {
        setTskillsets(response.data);
      }
    });
  };

  useEffect(() => {
    const getUserDetail = async () => {
      getSkillsets();
      await getUserDetails();
    };
    getUserDetail();
  }, []);

  const updateformValue = (value: any, type: string, index: number) => {
    let newFormValues = [...formValues];
    if (type == "Name") {
      newFormValues[index].name = value;
    } else if (type == "skillId") {
      newFormValues[index].skillId = value;
    } else {
      newFormValues[index].level = value;
    }
    setFormValues(newFormValues);
  };


  const updateUser = async () => {
    setDisabledButton(true);
    let data = userDataHook;
    data.skillsets = formValues;
    try {
      await userManagementServive.updateUser(data).then((response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          if (response.data) {
            props.callBackFunction();
            toast.success("Skill sets updated successfully.",{
              onClose:()=>setDisabledButton(false),

            });
            
          }
        }
      });
    } catch (ex: any) {
      // setDisabledButton(false);
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err,{
          onClose:()=>setDisabledButton(false),
        });
      });
    }
  };

  return (
    <>
      <ItemPaper elevation={0} sx={{ mb: 1 }}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            <Stack direction="row">
              <Autocomplete
                value={tskillsets?.find(option => option.id == skillId) ?? null}
                fullWidth
                id="outlined-basic"
                options={tskillsets}
                getOptionLabel={option => option.name}
                getOptionDisabled={(option)=>formValues.find((el: any) => el.skillId === option.id)?true:false}
                onChange={(event, value) => {
                  setskillId(value?.id ?? "");
                  setskillName(value?.name ?? "");

                }}
                renderInput={(params) => <TextField
                  {...params}
                  id="outlined-basic"
                  name="element.name"
                  label="Skill Sets"
                  variant="outlined"
                  value={skillId}
                  fullWidth
                  sx={{ minWidth: 260 }}
                />}
              />

            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Skill Level
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={skillLevel}
                name="radio-buttons-group"
                value={skillLevel}
              >
                <FormControlLabel
                  onClick={() => {
                    setskillLevel("beginner");
                  }}
                  value="beginner"
                  control={<Radio color="warning" />}
                  label={SkillLevel.Beginner}
                />
                <FormControlLabel
                  onClick={() => {
                    setskillLevel("intermediate");
                  }}
                  value="intermediate"
                  control={<Radio color="success" />}
                  label={SkillLevel.Intermediate}
                />
                <FormControlLabel
                  onClick={() => {
                    setskillLevel("expert");
                  }}
                  value="expert"
                  control={<Radio />}
                  label={SkillLevel.Expert}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              variant="contained"
              onClick={() => addFormFields()}
              sx={{ ml: 2 }}
              disabled={DisabledButton1}
            >
              Add
            </Button>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Box sx={{ mt: 3 }}>
              {formValues.map((element: any, index: any) => (
                <Chip
                  icon={<Brightness1Icon />}
                  label={element.name}
                  variant="outlined"
                  onClick={handleClick}
                  onDelete={() => removeFormFields(index)}
                  color={
                    element.level == SkillLevel.Beginner.toLowerCase()
                      ? "warning"
                      : element.level == SkillLevel.Intermediate.toLowerCase()
                        ? "success"
                        : "primary"
                  }
                  sx={[styles.chipSkill]}
                  size="medium"
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </ItemPaper>
      <Divider light />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 3 }}
      >
        <Button
          type="button"
          onClick={() => updateUser()}
          variant="contained"
          disabled={DisabledButton}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => props.setValue(2)}
        >
          Continue{" "}
        </Button>
      </Stack>
    </>
  );
}
