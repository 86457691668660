
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { CircularProgress, IconButton, Switch, SwitchProps, TablePagination, Tooltip, Typography, styled } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from "material-react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { votepollingService } from "../../service/Votepolling-service";
import * as Icons from "../../assests/icons/icons";
import { useSelector } from "react-redux";
import LongText from "../../components/longtext";
import { date } from "yup";

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function PollingSystemUser() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector(
        (state: any) => state.SeasiagalaPermissionApp.SeasiagalaPermission
    );
    const [selectedCatId, setSelectedCatId] = React.useState<any>("");
    const [ItemList, setItemList] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any>([]);
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState<any>("");
    const [description, setDescription] = React.useState<any>("");
    const [votedUserId, setVotedUserId] = React.useState<any>("");
    const [userbio, setUserbio] = React.useState<any>("");

    React.useEffect(() => {
        if (_permission.page_Poll_Access !== true) {
            navigate("/dashboard");
        }
        getAllcategory();
    }, []);
    React.useEffect(() => {
        // Check if selectedCatId is not already set
        if (!selectedCatId && categories.length > 0) {
          // Set selectedCatId to the ID of the first category
          setSelectedCatId(categories[0]?.id ?? "");
          // Optionally, you can also perform other actions related to the first category here
          setPage(0);
          getCategoryDetailsById(categories[0]?.id);
          getVotedUserById(categories[0]?.id);
        }
      }, [categories, selectedCatId]);
    const navigate = useNavigate();
    //Get All Inventory Items --Api call
    const getAllcategory = async () => {
        try {
            votepollingService.getAllEventCategory().then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    setToggleLoader(false);
                    setCategories(response?.data);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }
    const uploadVideoimageLink = async (flag: any) => {
        debugger
        let input = {
            eventCategoryId: selectedCatId,
            imageOrVideo: flag === 1 ? "" : imageUrl,
        }
        try {
            votepollingService.uploadImage(input).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    if (flag === 2) {
                        toast.success("Link Added Successfully")
                    }
                    setImageUrl("");
                    getCategoryDetailsById(selectedCatId)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }
 
    const targetDate = new Date('December 14, 2023 13:59:00').getTime();
    console.log(targetDate,"targetDate")
    console.log(new Date().getTime(),"nowdate")
    const getCategoryDetailsById = async (catId: any) => {
        try {
            await votepollingService.getEventCategoryById(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setItemList(response?.data?.pollingUsers)
                        setDescription(response?.data?.description)
                        // getAllInventoryItems(catId, [], []);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    };

    const getVotedUserById = async (catId: any) => {
        try {
            await votepollingService.getVotedUserById(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        setVotedUserId(response?.data)
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    };

    const voteForuserInCategory = async (id: any, flag:boolean) => {
        debugger
        let input = {
            categoryId: selectedCatId,
            isVoted: true,
            participantUserId: id,
        }
        try {
            votepollingService.voteUserInCategory(input).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    if(flag){
                        toast.success("Voted Successfully")
                    }
                    getVotedUserById(selectedCatId);
                    getCategoryDetailsById(selectedCatId)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }

    const addUserBioInList = async (flag: any) => {
        let input = {
            EventCategoryId: selectedCatId,
            UserBio: flag ===2 ?userbio:"",
        }
        try {
            votepollingService.addUserBioInList(input).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    if (response.data.isError) {
                        setToggleLoader(false);
                    } else {
                        if (flag === 2) {
                            toast.success("Bio Added Successfully")
                        }
                        setUserbio("");
                        getCategoryDetailsById(selectedCatId)
                    }
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const handleChangeRowsPerPage = (event: any) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRowsPerPage(selectedValue);
        setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const pageCount = Math.ceil(ItemList.length / rowsPerPage);

    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Link color="inherit" to="/">
                        Digital Voting
                    </Link>

                </Breadcrumbs>
                <Box sx={{ mb: "12px" }}>{description}</Box>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box height={'auto'}>
                            <Paper sx={{
                                height: '100%', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Stack p={2} justifyContent={'space-between'} alignItems={'start'} sx={{ borderBottom: "1px solid #EEEEEE", borderRadius: '4px' }}>
                                    <Typography sx={{ margin: "auto", fontWeight: "600", textDecoration: 'none', }}>
                                        Categories  List
                                    </Typography>
                                    {categories.length > 0 && categories?.map((item: any, idx: any) => (
                                       <Box
                                       sx={{
                                         alignItems: "center",
                                         width: "100%",
                                         my: '3',
                                         padding: "6px 10px",
                                         borderRadius: "6px",
                                         color: selectedCatId === item?.id ? "#1DA7FF" : "",
                                         display: "flex",
                                         justifyContent: "space-between",
                                         backgroundColor: selectedCatId === item?.id ? "#E0F2FE" : "#f1f1f1",
                                         transition: "all 0.3s, all 0.3s", // Add transition for a smoother effect
                                        //  ":hover": {
                                        // //    background: "#E0F2FE", // Change background color on hover
                                        //    color: "#007BFF", // Change text color on hover
                                        //  },
                                       }}
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                         setPage(0);
                                         getCategoryDetailsById(item?.id);
                                         getVotedUserById(item?.id);
                                         setSelectedCatId(item?.id ?? "");
                                       }}
                                     >
                                       {item?.categoryName}
                                       {selectedCatId === item?.id && <Icons.ArrowBlue />}
                                     </Box>
                                     
                                    ))}
                                </Stack>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'100%'} >
                            <Paper sx={{
                                height: '100%', padding: '16px', paddingBottom: '0', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Box>
                                    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 540 }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={headcellcolor.cellcolor}>Name</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Department</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>User's Bio</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Image Or Video</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', background: 'rgb(189, 189, 189)' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ItemList?.map((item: any, index: any) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row"> {item.userName} </TableCell>
                                                        <TableCell>{item.departmentName || "--"}</TableCell>
                                                        <TableCell>{item?.userBio ?
                                                            (item?.userBio && (<>
                                                                {<LongText content={item?.userBio} limit={10} />}{item?.userId === _authUser.id && (<IconButton onClick={() => { addUserBioInList(1) }}> <Icons.Delete /></IconButton>)}</>)) :
                                                            (item?.userId === _authUser.id ? (<><TextField placeholder="Add your Bio here.." multiline value={userbio} onChange={(e) => {  let value = e.currentTarget.value;
                                                            value = value.replace(/\s{2,}$/g, "").trimStart(); setUserbio(value)}}></TextField>
                                                                {userbio && (<> <Tooltip title={"Delete"}><IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => {setUserbio(""); addUserBioInList(1)}}
                                                                >
                                                                    <Icons.Delete /></IconButton></Tooltip>
                                                                    <Tooltip title={"Upload"}><IconButton onClick={() => addUserBioInList(2)}><Icons.UploadSave /></IconButton></Tooltip>
                                                                </>)}
                                                            </>) : ("--")
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{item?.imageOrVideo ?
                                                            (item?.imageOrVideo && (<><IconButton
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => window.open(item?.imageOrVideo, '_blank')}
                                                            >
                                                                <Icons.ImageOrVideo />
                                                            </IconButton> {item?.userId === _authUser.id && (<IconButton onClick={() => { uploadVideoimageLink(1) }}> <Icons.Delete /></IconButton>)}</>)) :
                                                            (item?.userId === _authUser.id ? (<><TextField placeholder="Public onedrive Link here" value={imageUrl} onChange={(e) => (setImageUrl(e.currentTarget.value))}></TextField>
                                                                {imageUrl && (<> <Tooltip title={"Delete"}><IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => setImageUrl("")}
                                                                >
                                                                    <Icons.Delete /></IconButton></Tooltip>
                                                                    <Tooltip title={"Upload"}><IconButton onClick={() => uploadVideoimageLink(2)}><Icons.UploadSave /></IconButton></Tooltip>
                                                                </>)}
                                                            </>) : ("--")
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <IOSSwitch
                                                             disabled={ new Date().getTime() >= targetDate}
                                                                checked={item?.userId === votedUserId}
                                                                onChange={(e) => {
                                                                    let abc =e.target.checked;
                                                                    debugger
                                                                    if (item?.departmentId === _authUser.employeeInfo?.departmentId) {
                                                                        toast.warn("You Can't vote for your Department & Yourself")
                                                                    }
                                                                    else {
                                                                        voteForuserInCategory(item?.userId,abc)
                                                                    }
                                                                }}
                                                                sx={{ m: 1 }}
                                                            /></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* <TablePagination
                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        component="div"
                                        count={ItemList.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={() => `Total: ${ItemList?.length}  Page ${page + 1} of ${pageCount}`}
                                    /> */}
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >
                </Grid >
            </Box >
        </>
    )
}
