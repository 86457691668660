import * as React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { inventoryService } from "../../service/Inventory/inventoryService";
import { toast } from "material-react-toastify";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, IconButton, Stack, TablePagination, TextField, Typography } from "@mui/material";
import NotFound from "../../components/styledComponent/NotFound";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchBar from "../../components/styledComponent/SearchBar";
import dayjs, { Dayjs } from "dayjs";
import theme from "../../theme/theme";
import { Link } from "react-router-dom";
import * as Icons from "../../assests/icons/icons";
import { DatePicker } from 'react-rainbow-components';
import { helpDeskReport } from "../../service/hepldeskreport-service";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
// import { getDate } from "date-fns";

const statusOption = [
    { label: 'Status', id: "" },
    { label: 'ToDo', id: "ToDo" },
    { label: 'On Going', id: "On Going" },
    { label: 'Verification Pending', id: "Verification Pending" },
    { label: 'Resolved', id: "Resolved" },
    { label: 'Escalated', id: "Escalated" },
    { label: 'On-Hold', id: "On-Hold" },
    { label: 'Rejected', id: "Rejected" },
    { label: 'Reassign', id: "Reassign" },
]

const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function HelpDeskreport() {
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toDate, setToDate] = React.useState<any>(null);
    const [selectedStatusValue, setSelectedStatusValue] = React.useState("");
    const [selectedCategory, setSelectedCategory] = React.useState("");
    const [category, setCategory] = React.useState<any>([]);
    const [fromDate, setFromDate] = React.useState<any>(null);
    const [helpDeskReportDetail, setHelpDeskReportDetail] = React.useState<any>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(helpDeskReportDetail.length / rowsPerPage);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    React.useEffect(() => {
        getCategoryDropdownData();
        getHelpDeskReport("", "", "", "", "")
    }, [])
    const [searchValue, setSearchValue] = React.useState("");
    const triggerSearch = async (searchvalue: any) => {
        setSearchValue(searchvalue);
        getHelpDeskReport(selectedCategory, selectedStatusValue, fromDate, toDate, searchvalue);
    };
    let abc = { name: 'Select Category', code: "" }
    const getCategoryDropdownData = async () => {
        let abc = { name: 'Select Category', code: "" }
        try {
            helpDeskReport.getCategoryDropdown().then((response: any) => {
                if (response.data.isError) {
                } else {
                    if (response.data == null || response.data.length === 0 || !response) {
                        setCategory(abc);
                    }
                    else {
                        response.data.unshift(abc);
                        setCategory(response?.data)
                    }
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                return toast.warning(err);
            });
        }
    }
    const getDate = (dateObject: Date) => {
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00Z`;
    };

    const getHelpDeskReport = async (selectedCategory: any, selectedStatusValue: any, fromDate: any, toDate: any, search: any) => {

        if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
            toast.warning("Start date must be less than End date");
            return; 
        }

        let abc: any = {
            "code": selectedCategory,
            "status": selectedStatusValue,
            "text": search
        };

        if (fromDate && toDate) {
            const fromDateMilliseconds = new Date(fromDate);
            fromDateMilliseconds.setHours(0, 0, 0, 0);
            const fromDateMillisecondsValue = fromDateMilliseconds.getTime();

            const toDateMilliseconds = new Date(toDate);
            toDateMilliseconds.setHours(23, 59, 59, 999);
            const toDateMillisecondsValue = toDateMilliseconds.getTime();

            abc.start = fromDateMillisecondsValue;
            abc.end = toDateMillisecondsValue;
        }
        try {
            setToggleLoader(true);
            helpDeskReport.getReportDetails(abc).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    setToggleLoader(false);
                    setHelpDeskReportDetail(response?.data)
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                return toast.warning(err);
            });
        }
    }
    const handleDownload = () => {
        
        if (!helpDeskReportDetail || helpDeskReportDetail.length === 0) {
            toast.info("No data available to download.");
            return;
        }

        
        const formattedData = helpDeskReportDetail.map((item: any) => ({
            "ID": item.id,
            "Title": item.title,
            "Status": item.status,
            "Category Code": item.categoryCode,
            "Created Date": new Date(item.createdDate).toLocaleDateString(), // Convert timestamp to readable date
            "Priority": item.priority ?? "N/A", // Handle null values
            "Reporter Name": item.reporterName,
            "Handlers": item.handlers.join(", "), // Convert array to string
        }));

        // Create a worksheet
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Task Report");

        // Write Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // Convert to Blob and trigger download
        const file = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(file, "Help_Desk_Report.xlsx");
    };

    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            {/* <Typography sx={{ fontWeight: "500" }}>HelpDesk Report</Typography> */}
            <Box>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                    HelpDesk Report
                </Typography>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ mt: 1.5 }}
                >
                    <Grid item xs={12} lg={12}>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            alignItems="flex-end"
                            justifyContent="space-between"
                            spacing={2}
                            sx={{ pb: 3 }}
                        >
                            <Box >
                                <Autocomplete
                                    value={
                                        category?.find(
                                            (option: any) => option.code == selectedCategory
                                        ) ?? abc
                                    }
                                    fullWidth
                                    id="department-drop"
                                    options={category}
                                    getOptionLabel={(option: any) => option?.name}
                                    onChange={async (event, value) => {
                                        setSelectedCategory(value?.code ?? "")
                                        setfiltersSelected(true);

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="department-drop"
                                            name="departmentsId"
                                            variant="outlined"
                                            //value={departmentsId}
                                            fullWidth
                                            sx={{ minWidth: 180 }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box >
                                <Autocomplete
                                    value={
                                        statusOption?.find(
                                            (option) => option.id == selectedStatusValue
                                        ) ?? null
                                    }
                                    sx={{ mr: "20px" }}
                                    fullWidth
                                    id="resources-drop"
                                    options={statusOption}
                                    getOptionLabel={(option) => option.label}
                                    onChange={async (event, value) => {
                                        setSelectedStatusValue(value?.id ?? "")
                                        setfiltersSelected(true);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="resources-drop"
                                            name="resourceId"
                                            variant="outlined"
                                            //  value={resourceId}
                                            fullWidth
                                            sx={{ minWidth: 120 }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box >
                                <SearchBar
                                    value={searchValue}
                                    callBackFunction={triggerSearch} />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "flex-end", gap: "16px", marginBottom: "16px" }}>
                                <Box >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                        <DatePicker
                                            // disableFuture
                                            label="From"
                                            placeholder="From"
                                            labelAlignment="left"
                                            borderRadius="semi-square"

                                            maxDate={dayjs().toDate()}
                                            // openTo="year"
                                            // views={["year", "month", "day"]}
                                            value={fromDate}
                                            onChange={(newValue: any) => {
                                                let abc = getDate(newValue);
                                                setFromDate(abc ?? null)
                                                setfiltersSelected(true);
                                            }}
                                        // renderInput={(params: any) => <TextField {...params}
                                        //     autoComplete="off" // Disable suggestions
                                        //     readOnly={true}
                                        //     fullWidth
                                        //     sx={{
                                        //         "& .MuiSvgIcon-root": {
                                        //             fill: theme.palette.primary.main,
                                        //         },
                                        //     }}
                                        // />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                        <DatePicker
                                            // disableFuture
                                            label="To"
                                            borderRadius="semi-square"
                                            placeholder="To"
                                            labelAlignment="left"
                                            // openTo="year"
                                            // views={["year", "month", "day"]}
                                            value={toDate}
                                            maxDate={dayjs().toDate()}
                                            onChange={(newValue: any) => {
                                                let abc = getDate(newValue);
                                                setToDate(abc ?? null)
                                                setfiltersSelected(true);
                                            }}
                                        // renderInput={(params: any) => <TextField {...params}
                                        //     autoComplete="off" // Disable suggestions
                                        //     readOnly={true} // Disable user input
                                        //     fullWidth
                                        //     sx={{
                                        //         "& .MuiSvgIcon-root": {
                                        //             fill: theme.palette.primary.main,
                                        //         },
                                        //     }}
                                        // />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Button
                                    onClick={() => {
                                        setfiltersSelected(true);
                                        setPage(0);
                                        getHelpDeskReport(selectedCategory,
                                            selectedStatusValue,
                                            fromDate,
                                            toDate,
                                            searchValue
                                        )
                                    }}
                                    color="primary"
                                    variant="contained"
                                    sx={{ ml: 0.5 }}
                                >
                                    Go
                                </Button>
                                {filtersSelected && (<Button
                                    onClick={async () => {
                                        setfiltersSelected(false);
                                        setToggleLoader(false);
                                        setSelectedStatusValue("");
                                        setSelectedCategory("");
                                        setFromDate(null);
                                        setToDate(null);
                                        setSearchValue("");
                                        getHelpDeskReport("", "", "", "", "")
                                    }}
                                    color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>)}
                                    <IconButton
                                    sx={{ mr: "20px" }}
                                    onClick={(e) => {

                                        handleDownload();
                                    }}
                                    title="Download Billing Report"
                                    aria-label="Download Billing Report"
                                >
                                    <Icons.xlsxicon />
                                </IconButton>
                            </Box>

                        </Stack>

                    </Grid>
                </Grid>

            </Box>
            <TableContainer component={Paper} sx={{ mt: 1 }}>
                {helpDeskReportDetail && helpDeskReportDetail?.length > 0 ? (
                    <Table aria-label="simple table" size="small" sx={{ position: 'relative', overflowX: 'scroll' }}>
                        <TableHead sx={headcellcolor.cellcolor}>
                            <TableRow>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', Width: '90px', background: 'rgb(189, 189, 189)', }} >Ticket No</StyledTableCell>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: 'rgb(189, 189, 189)', }} >Incidents</StyledTableCell>
                                <StyledTableCell align="left" >Date</StyledTableCell>
                                <StyledTableCell align="left" >Status</StyledTableCell>
                                <StyledTableCell align="left">Reporter</StyledTableCell>
                                <StyledTableCell align="left">Handler</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {helpDeskReportDetail?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user: any, idx: any) => (
                                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff' }}>
                                            {user?.id}</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: '#fff' }}>
                                            {user?.title}</StyledTableCell>
                                        <StyledTableCell align="left">{moment(user.createdDate).format("DD-MMM-yy") || "--"}</StyledTableCell>
                                        <StyledTableCell align="left">{user?.status}</StyledTableCell>
                                        <StyledTableCell align="left">{user?.reporterName}</StyledTableCell>
                                        <StyledTableCell align="left"> {user?.handlers.map((item: any, index: any) => (
                                            // Use the conditional (index > 0) to add a comma before items (except for the first one)
                                            <span key={index}>
                                                {index > 0 && ', '}
                                                {item}
                                            </span>
                                        ))}</StyledTableCell>
                                        <StyledTableCell align="left"><IconButton
                                            aria-label="view"
                                            component={Link}
                                            to={`/helpdesk-reportView/${user?.id}`}
                                            size="medium"
                                            target="_blank"
                                        >
                                            <Icons.View /></IconButton></StyledTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>) : (<NotFound NotfoundText="No Record Found" />)}
                <Divider sx={{ my: 0.5 }} />
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={helpDeskReportDetail?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    labelDisplayedRows={() => `Total: ${helpDeskReportDetail?.length}  Page ${page + 1} of ${pageCount}`}
                />
            </TableContainer>
        </>
    );
}
