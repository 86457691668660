import { combineReducers } from 'redux';
const Fill_Project_Permission = 'Fill_Project_Permission';
const Empty_Project_Permission = 'Empty_Project_Permission';

const defaultPermissions =
{
    access: false,
    module_ManageProjects_Access: false,

    page_ManageProjects_Access: false,
    tab_ManageProjectsListing_Access: false,
    action_ManageProjectsListing_Add_Access: false,
    action_ManageProjectsListing_Edit_Access: false,
    action_ManageProjectsListing_Delete_Access: false,
    action_ManageProjectsListing_View_Access: false,
    action_ManageProjectsListing_AllDepartments_Access: false,
    tab_ClientDetails_Access: false,
    action_ClientDetails_Add_Access: false,
    action_ClientDetails_Edit_Access: false,
    action_ClientDetails_View_Access: false,
    tab_ManageMilestones_Access: false,
    action_ManageMilestones_UpdateStatus_Access: false,
    action_ManageMilestones_UpdatePaymentStatus_Access: false,

    page_MyProjects_Access: false,
    tab_MyProjectsListing_Access: false,
    action_MyProjectsListing_AllocateResource_Access: false,
    action_MyProjectsListing_LogSheet_Access: false,
    action_MyProjectsListing_ProjectSettings_Access: false,

    page_Board_Access: false,
    tab_Sprint_Access: false,
    action_Sprint_Add_Access: false,
    action_Sprint_Edit_Access: false,
    action_Sprint_Delete_Access: false,
    action_Sprint_Start_Access: false,
    action_Sprint_Complete_Access: false,
    tab_Milestone_Access: false,
    action_Milestone_Add_Access: false,
    action_Milestone_Edit_Access: false,
    action_Milestone_Delete_Access: false,
    action_Milestone_EndDateEdit_Access: false,
    tab_Issue_Access: false,
    tab_Setting_Access: false,
    action_Issue_Add_Access: false,
    action_Issue_Edit_Access: false,
    action_Issue_Delete_Access: false,
    tab_Project_Access: false,
    action_Project_Edit_Access: false,
    action_Project_InviteClient_Access: false,
    action_Project_PaymentDetails_Access: false,
}

export function FillProjectPermission(module) {
    return {
        type: Fill_Project_Permission,
        module,
    }
}
export function EmptyProjectPermission() {
    return {
        type: Empty_Project_Permission, defaultPermissions
    }
}

function ProjectPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_Project_Permission:
            var data = action.module;
            var permissions = defaultPermissions;
            if (data) {
                permissions.access = true;
                permissions.module_ManageProjects_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                    data.listPageMaster.forEach((page) => {
                        if (page.pageName === "ManageProjects") {
                            permissions.page_ManageProjects_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                                page.listTabMaster.forEach((tabs) => {
                                    if (tabs.tabName === "Manage Projects Listing") {
                                        permissions.tab_ManageProjectsListing_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Add") {
                                                    permissions.action_ManageProjectsListing_Add_Access = true;
                                                }
                                                if (actions.actionName === "Edit") {
                                                    permissions.action_ManageProjectsListing_Edit_Access = true;
                                                }
                                                if (actions.actionName === "View") {
                                                    permissions.action_ManageProjectsListing_View_Access = true;
                                                }
                                                if (actions.actionName === "Delete") {
                                                    permissions.action_ManageProjectsListing_Delete_Access = true;
                                                }
                                                if (actions.actionName === "All Departments") {
                                                    permissions.action_ManageProjectsListing_AllDepartments_Access = true;
                                                  }
                                            });
                                        }
                                    }
                                    if (tabs.tabName === "Client Details") {
                                        permissions.tab_ClientDetails_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Add") {
                                                    permissions.action_ClientDetails_Add_Access = true;
                                                }
                                                if (actions.actionName === "Edit") {
                                                    permissions.action_ClientDetails_Edit_Access = true;
                                                }
                                                if (actions.actionName === "View") {
                                                    permissions.action_ClientDetails_View_Access = true;
                                                }
                                            });
                                        }
                                    }
                                    if (tabs.tabName === "Manage Milestones") {
                                        permissions.tab_ManageMilestones_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Milestone Status") {
                                                    permissions.action_ManageMilestones_UpdateStatus_Access = true;
                                                }
                                                if (actions.actionName === "Payment Status") {
                                                    permissions.action_ManageMilestones_UpdatePaymentStatus_Access = true;
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                        if (page.pageName === "My Projects") {
                            permissions.page_MyProjects_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                                page.listTabMaster.forEach((tabs) => {
                                    if (tabs.tabName === "My Projects Listing") {
                                        permissions.tab_MyProjectsListing_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Allocate Resource") {
                                                    permissions.action_MyProjectsListing_AllocateResource_Access =
                                                        true;
                                                }
                                                if (actions.actionName === "Log Sheet") {
                                                    permissions.action_MyProjectsListing_LogSheet_Access = true;
                                                }
                                                if (actions.actionName === "Project Settings") {
                                                    permissions.action_MyProjectsListing_ProjectSettings_Access =
                                                        true;
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                        if (page.pageName === "Board") {
                            permissions.page_Board_Access = true;
                            if (page.listTabMaster && page.listTabMaster.length > 0) {
                                page.listTabMaster.forEach((tabs) => {
                                    if (tabs.tabName === "Sprint") {
                                        permissions.tab_Sprint_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Add") {
                                                    permissions.action_Sprint_Add_Access = true;
                                                }
                                                if (actions.actionName === "Edit") {
                                                    permissions.action_Sprint_Edit_Access = true;
                                                }
                                                if (actions.actionName === "Delete") {
                                                    permissions.action_Sprint_Delete_Access = true;
                                                }
                                                if (actions.actionName === "Start") {
                                                    permissions.action_Sprint_Start_Access = true;
                                                }
                                                if (actions.actionName === "Complete") {
                                                    permissions.action_Sprint_Complete_Access = true;
                                                }
                                            });
                                        }
                                    }
                                    if (tabs.tabName === "Milestone") {
                                        permissions.tab_Milestone_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Add") {
                                                    permissions.action_Milestone_Add_Access = true;
                                                }
                                                if (actions.actionName === "Edit") {
                                                    permissions.action_Milestone_Edit_Access = true;
                                                }
                                                if (actions.actionName === "Delete") {
                                                    permissions.action_Milestone_Delete_Access = true;
                                                }
                                                if (actions.actionName === "Milestone EndDate Update") {
                                                    permissions.action_Milestone_EndDateEdit_Access = true;
                                                }
                                            });
                                        }
                                    }
                                    if (tabs.tabName === "Project") {
                                        permissions.tab_Project_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Edit") {
                                                    permissions.action_Project_Edit_Access = true;
                                                }
                                                if (actions.actionName === "Invitation Link") {
                                                    permissions.action_Project_InviteClient_Access = true;
                                                }
                                                if (actions.actionName === "Payment Details") {
                                                    permissions.action_Project_PaymentDetails_Access = true;
                                                }
                                            });
                                        }
                                    }
                                    if (tabs.tabName === "Issue") {
                                        permissions.tab_Issue_Access = true;
                                        if (
                                            tabs.listActionMaster &&
                                            tabs.listActionMaster.length > 0
                                        ) {
                                            tabs.listActionMaster.forEach((actions) => {
                                                if (actions.actionName === "Add") {
                                                    permissions.action_Issue_Add_Access = true;
                                                }
                                                if (actions.actionName === "Edit") {
                                                    permissions.action_Issue_Edit_Access = true;
                                                }
                                                if (actions.actionName === "Delete") {
                                                    permissions.action_Issue_Delete_Access = true;
                                                }
                                            });
                                        }
                                    }
                                    if (tabs.tabName === "Setting") {
                                        permissions.tab_Setting_Access = true;
                                    }
                                });
                            }
                        }
                    });
                }
                return state = permissions;
            }
            else {
                return state = defaultPermissions;
            }
            
        case Empty_Project_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const ProjectPermissionApp = combineReducers({
    ProjectPermission
});

export default ProjectPermissionApp;