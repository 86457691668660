import React from "react";
import * as Icons from '../../../assests/icons/icons';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { achievementService } from "../../../service/master-service/achievement-service";
import StyledTableCell from '@mui/material/TableCell';
import Chip, { ChipProps } from '@mui/material/Chip';
import StyledTableRow from '@mui/material/TableRow';
import { Box, Breadcrumbs, Divider, Modal } from "@mui/material";
import SearchBar from "../../../components/styledComponent/SearchBar";
import AddIcon from '@mui/icons-material/Add';
import TablePagination from '@mui/material/TablePagination';
import { toast } from "material-react-toastify";
import Masteralltop from "../mastersetupstyle";
import { allowanceService } from "../../../service/master-service/allowance-service";
const StatusButtonActive = styled(Button)({
  backgroundColor: "#E7FFF0",
  borderColor: "#E7FFF0",
  color: "#2DD36F",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#1F944E",
    borderColor: "#1F944E",
    boxShadow: "none",
    color: "#fff",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
const StatusButtonDeactive = styled(Button)({
  backgroundColor: "#FBDADE",
  borderColor: "#FBDADE",
  color: "#A5303F",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#A5303F",
    borderColor: "#A5303F",
    boxShadow: "none",
    color: "#fff",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "none",
  },
});
const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
  color: theme.palette.success.dark,
  backgroundColor: theme.palette.success.light,
  fontWeight: 400,
  fontSize: 14,
}));

interface Acheive {
  id: number;
  name: string;
}
function ManageAllowance() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = useState<Acheive[]>([]);
  const pageCount = Math.ceil(users.length / rowsPerPage);
  var channelSearch = { id: "", name: "All Channel" };
  const [channelHook, setChannelHook] = useState(channelSearch);
  const [statusDataHook, setStatusDataHook] = useState("Active");
  const [searchValue, setSearchValue] = useState("");
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getAllowanceDetails();
  }, []);
  const getAllowanceDetails = async () => {
    var input = {
      "status": "",
      "search": ""
    };
    await allowanceService.getAllowance(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setUsers(response?.data);
      }
    });
  };

  const [currentId, setCurrentId] = React.useState(false);
  const deleteAllowanceData = async (id: any) => {
    setOpend(true);
    setCurrentId(id);
  };

  const deleteAllowance = async () => {
    try {
      await allowanceService
        .deleteAllowance(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Allowance Deleted Successfully");
            setOpend(false);
            getAllowanceDetails();
          }
        });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  
  const [opend, setOpend] = React.useState(false);
  const handleClose = () => {
    setOpend(false);
  };

  const getSearchDetails = async (newValue: string) => {
    setPage(0)
    let values = {
      status: statusDataHook,
      search: newValue,
      take: 1000,
      skip: 0
    }
    await allowanceService.getAllowance(values)
      .then((response: any) => {
        if (response.data.isError) {
        }
        else {
          setUsers(response?.data);
          if (response.data.length == 0) {
            toast.warning('No result found');
          }
        }
      });
  }
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link color="inherit" to="/">
          Master Data
        </Link>
        <Typography color="text.primary">Manage Allowance</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ pb: 3 }}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            All Allowance
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <SearchBar setSearchValue={setSearchValue} callBackFunction={getSearchDetails} />
          <Button variant="contained" size="large" component={Link} to='/add-allowance' color="primary" startIcon={<AddIcon />}>
            Add Allowance
          </Button>
        </Stack>
        <Divider />
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Allowance</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, idx) => (
                  <>
                    <StyledTableRow key={idx + 1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell align="left">{user.name}</StyledTableCell>
                      <StyledTableCell align="left">
                        <StatusChip label={<span><Icons.CircleFilled /> Active</span>} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                          <IconButton aria-label="edit" size="medium" component={Link} to={`/edit-allowance/${user.id}`}>
                            <Icons.Edit />
                          </IconButton>
                          <IconButton aria-label="delete" onClick={() => deleteAllowanceData(user.id)} >
                            <Icons.Delete />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        
          labelDisplayedRows={() => `Total: ${users?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>
      <Modal
        open={opend}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Masteralltop.actionpopup}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Allowance
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Are you sure you want to delete Allowance ?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={deleteAllowance}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}
export default ManageAllowance;