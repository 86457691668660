import { Class } from "@mui/icons-material";
import { Observable } from "@reduxjs/toolkit";
import UserManagement from "../../pages/users/UserManagement";
import { userModel } from "../../pages/users/userModel";
import { http } from "../http-common";
class CommonServive{
   
    getDropdownData = async (data : any): Promise<any> => {
        return await http.post<any>(
            "/v1/Master/DropdownData/GetByName", data
        );
    };
    getManagerName = async (data : any): Promise<any> => {
        return await http.post<any>(
            `/v1/Master/ManagerName/GetByDepartmentId?departmentId=` +data
        );
    }; 
    getResourcesByDepartment = async (data: any): Promise<any> => {
        return await http.post<any>(
            `/UserManagement/GetResourcesbyDepartmentId?departmentId=${data}`
        );
      }; 
      getCategoryDropdown = async (): Promise<any> => {
        let data= {
            "search": "",
            "take": 1000,
            "skip": 0
          }
        return await http.post<any>(
            "/Inventory/GetAllCatogaries/GetAllCatogaries",data
        );
      }; 
}
export const commonServive = new CommonServive()

