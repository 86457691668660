
import { http } from "./http-common";

class BrownieReportService {

    getBrownieReport = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Reports/BrowniePointsReport", data);
    };
    
}

export const brownieReportService = new BrownieReportService();