import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Autocomplete, Chip, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "../../theme/theme";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { IFiles } from "../users/userModel";
import { Formik, getIn } from "formik";
import { apiUrl } from "../../service/common-services/apiURL";
import dayjs, { Dayjs } from "dayjs";
import { inventoryService } from "../../service/Inventory/inventoryService";
import { toast } from "material-react-toastify";
import { clsCategoryList } from "../../service/common-services/category";
import { makeStyles } from '@mui/styles';
import * as Yup from "yup";
import CustomCheckbox from "../../components/CustomCheckBox";
import FileUploadPics from "../../components/styledComponent/FileUploadPics";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";

const additem = {
    Uploaddetail: {
        width: "100%",
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',

        heading: {
            color: '#232360',
            fontSize: '14px',
            fontWeight: '400',
            margin: '10px 0',
            wordWrap: 'break-word',
        },

        Button: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '18px',
            paddingLeft: '18px',
        }
    },

    dateOFPurchase: {
        maxWidth: 'auto',
        " & .css-9l522a-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
            color: '#757575'
        },
        "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main,
        },
        "& .css-1ig1lwk-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: '#EEE',
            color: '#757575'
        }
    },

    Warantydetail: {
        Fromto: {
            maxWidth: '160px',
            "& .MuiSvgIcon-root": {
                fill: theme.palette.primary.main,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: '#EEE',
                color: '#757575'
            },
        },
    }
}

const useStyles = makeStyles((theme) => ({
    anchor: {
        wordWrap: 'break-word',
    },
}));


interface additem {
    keyboard: string;
}
interface brandname {
    id: string;
    displayname: string;
}
interface ICategory {
    id: string,
    category: string
}
interface IBrands {
    brandName: string;
}
interface IConfiguration {
    attribute: string,
    value: string[];
}
const year = [
    {
        id: "1",
        label: "1 Year"
    },
    {
        id: "2",
        label: "2 Year"
    },
    {
        id: "3",
        label: "3 Year"
    },
    {
        id: "4",
        label: "4 Year"
    },
    {
        id: "5",
        label: "5 Year"
    }
];
const Monts = [
    {
        id: "1",
        label: "1 Month"
    },
    {
        id: "2",
        label: "2 month"
    },
    {
        id: "3",
        label: "3 month"
    },
    {
        id: "4",
        label: "4 month"
    },
    {
        id: "5",
        label: "5 month"
    },
    {
        id: "6",
        label: "6 month"
    },
    {
        id: "7",
        label: "7 month"
    },
    {
        id: "8",
        label: "8 month"
    },
    {
        id: "9",
        label: "9 month"
    },
    {
        id: "10",
        label: "10 month"
    },
    {
        id: "11",
        label: "11 month"
    },
];


export default function Inventoryadditems() {
    const classes = useStyles();
    let IfileObj: IFiles = {
        itemType: "",
        name: "",
        fileURL: "",
        fileName: "",
        file: null,
    };
    const { itemid, catId } = useParams();
    let brand = [];
    // let itemid = "64c79ba4fc6ed6eefc7b4688";
    const [itemname, setitemname] = React.useState("");
    const [categorys, setcategory] = useState<ICategory[]>([]);
    const [selectedCategory, setSelectedCategory] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [brands, setBrands] = useState<IBrands[]>([]);
    const [brandName, setbrandName] = React.useState<any>("");
    const [configurations, setConfigurations] = useState<IConfiguration[]>([]);
    const [selectedbrands, setSelectedBrands] = React.useState("");
    const [SKU, setSku] = React.useState("");
    const [modelName, setmodelName] = React.useState("");
    const [updateButtonDisable, setUpdateButtonDisable] = useState(true);

    // puschase information //
    const [seller, setseller] = React.useState("");
    const [costprice, setCostprice] = React.useState("");
    const [categoryId, setCategoryId] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [dateOFPurchase, setPurchasedate] = React.useState<any>(null);
    const [purchaseComment, setPurchaseComment] = React.useState("");
    const [warrantyComment, setwarrantyComment] = React.useState("");

    //warrenty //
    const [startDate, setstartDate] = React.useState<any>(null);
    const [endDate, setendDate] = React.useState<any>(null);
    // const [monthInfo, setmonthInfo] = React.useState("");
    // const [yearInfo, setYearInfo] = React.useState("");
    const [isApplicable, setisApplicable] = useState(false);
    const [warrantyMonth, setWarrantyMonth] = React.useState<any>("");
    const [warrantyYear, setWarrantyYear] = React.useState<any>("");
 
    const calculateDate = async () => {
        debugger;
        const yearToMonth = warrantyYear * 12;
        // Calculate the total months based on the presence of yearToMonth or warrantyMonth
        let totalMonths;
        const monthValue =parseInt(warrantyMonth)
        if (yearToMonth && monthValue) {
            totalMonths = yearToMonth + monthValue;
        } else if (yearToMonth) {
            totalMonths = yearToMonth;
        } else if (monthValue) {
            totalMonths = monthValue;
        } else {
            console.error('Both yearToMonth and warrantyMonth are missing');
            return;
        }
        if (!dateOFPurchase || !dayjs(dateOFPurchase).isValid()) {
            console.error('Invalid dateOFPurchase');
            return;
        }
        const jan312009 = dayjs(dateOFPurchase);
        const finalDate = jan312009.add(totalMonths, 'month');
        const formattedDate = finalDate.format('DD-MM-YYYY');
       await setendDate(formattedDate);
        console.log((dayjs(formattedDate)), "finalDate");
        return formattedDate;
    }

    const calculateDate1 = async () => {
        debugger;
        const yearToMonth = warrantyYear * 12;
        let totalMonths;
        const monthValue = parseInt(warrantyMonth);
        if (yearToMonth && monthValue) {
            totalMonths = yearToMonth + monthValue;
        } else if (yearToMonth) {
            totalMonths = yearToMonth;
        } else if (monthValue) {
            totalMonths = monthValue;
        } else {
            console.error('Both yearToMonth and warrantyMonth are missing');
            return;
        }
        if (!dateOFPurchase || !dayjs(dateOFPurchase).isValid()) {
            console.error('Invalid dateOFPurchase');
            return;
        }
        const jan312009 = dayjs(dateOFPurchase);
        const finalDate = jan312009.add(totalMonths, 'month');
        // Format the date in ISO 8601 format
        const isoFormattedDate = finalDate.toISOString();
        setendDate(isoFormattedDate);
        console.log(finalDate, "finalDate");
        return isoFormattedDate;
    }
    
    

    const handleRadioChange = (event: any) => {
        const newValue = event.target.value;
        setisApplicable(newValue === 'Applicable');
    };
    const navigate = useNavigate();


    const handleClearPagereload = async () => {
        setConfigurations([]);
        setSelectedCategory("")
        setSelectedBrands("")
        setBrands([]);
        setDescription("");
        setitemname("");
        setSku("");
        setseller("");
        setCostprice("");
        setPurchasedate(null);
        setAddress("");
        setPurchaseComment("");
        setstartDate(null);
        setendDate(null);
        setCategoryId("");
        setFormValues([]);
        setWarrantyMonth("");
        setWarrantyMonth("");
        setmodelName("");
    }

    // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     
    //     const value = event.target.value === 'Applicable';`
    //     setisApplicable(value);
    // };
    const [showHelpText , setshowHelpText]= React.useState(false);
    

    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const handleOptionChange = (attribute: any, option: any) => () => {
        const updatedSelectedData = [...selectedOptions];
        const attributeIndex = updatedSelectedData.findIndex(data => data.attribute === attribute);

        if (attributeIndex == -1) {
            // If the attribute doesn't exist in selectedOptions, add it with the selected option
            updatedSelectedData.push({ attribute, value: [option] });
        } else {
            const optionIndex = updatedSelectedData[attributeIndex].value.indexOf(option);

            if (optionIndex == -1) {
                // If the option is not already selected, add it to the attribute's value
                updatedSelectedData[attributeIndex].value.push(option);
            } else {
                // If the option is already selected, remove it from the attribute's value
                updatedSelectedData[attributeIndex].value.splice(optionIndex, 1);

                // If the attribute has no more selected options, remove the attribute from selectedOptions
                if (updatedSelectedData[attributeIndex].value.length === 0) {
                    updatedSelectedData.splice(attributeIndex, 1);
                }
            }
        }

        setSelectedOptions(updatedSelectedData);
    };
    console.log(selectedOptions, "selectedOptions")

    const [documentFile, setDocumentFile] = React.useState<any>(null);
    const [tempDocumentFileURL, setTempDocumentFileURL] = React.useState<any>("");
    const cancelDocumentFile = () => {
        setTempDocumentFileURL("");
        setDocumentFile(null);
    };
    const addItem = async (values: any) => {
        debugger;
        var response = await multiplefileupload(values);
        let abc  =response;
        if(isApplicable == false){
        const result1 = await calculateDate1();
        response.endDate=result1;}
        await inventoryService.addInventoryItem(abc).then((response: any) => {
            if (response.data.isError) {
            } else {
                toast.success("Item Added successfully")
                navigate("/inventory-list");
                handleClear();
            }
        })
            .catch((error:any) => {
                error.data.Error.map((ex:any)=>{
                    toast.warn(ex)})
            });
    };

    const getCategorybyId = async (id: any) => {
        await inventoryService.getCategorybyId(id).then((response: any) => {
            if (response.data.isError) {
            } else {
                setConfigurations(response?.data?.setConfiguration);
                // setBrands(response?.data?.brands);
                // setTempDocumentFileURL(response?.data?.uploadProductDetails);
                // setcategory(response?.data?.categorys);

            }
        })
    }
    const getBrandCategorybId = async (id: any) => {

        await inventoryService.getBrandCategorybId(id).then((response: any) => {
            if (response.data.isError) {
            } else {
                setBrands([]);
                console.log(response?.data)
                setBrands(response?.data);
            }
        })
    }
   

    const handleClear = async () => {
        setDescription("");
        setitemname("");
        setSku("");
        setseller("");
        setCostprice("");
        setPurchasedate(null);
        setAddress("");
        setPurchaseComment("");
        setwarrantyComment("");
        setstartDate(null);
        setendDate(null);
        setmodelName("");
    }


    const cancelbutton = async () => {
        navigate("/inventory-list");
    };

    const getItemDetailById = async (id: any) => {
        await inventoryService.getOverviewListById(id).then((response: any) => {
            if (response.data.isError) {
            } else {
                getCategorybyId(response?.data?.categoryId);
                setCategoryId(response?.data?.categoryId);
                setSelectedCategory(response?.data?.category);
                setSelectedBrands(response?.data?.brand[0]?.brandName);
                if (response.data?.upload?.length > 0) {
                    let documentData = response.data?.upload;
                    // console.log(documentData, "documentData")
                    var document_array: any[] = [];
                    documentData.map((item: any) => {
                        document_array.push({
                            fileURL: item,
                            file: null,
                            itemType: "",
                        });
                    });

                    setFormValues(document_array);
                }
                setDescription(response?.data?.description);
                setitemname(response?.data?.itemName);
                setSku(response?.data?.sku);
                setseller(response?.data?.seller);
                setCostprice(response?.data?.costPrice);
                setPurchasedate(response?.data?.dateOFPurchase);
                setAddress(response?.data?.address);
                setPurchaseComment(response?.data?.purchaseComment);
                setwarrantyComment(response?.data?.warrantyComment);
                setstartDate(response?.data?.startDate);
                // setendDate(response?.data?.endDate);
                setisApplicable(response?.data?.isApplicable);
                // debugger;
                // setConfigurations(response?.data?.setConfiguration);
                // console.log("selectedOptions",configurations);
                setWarrantyYear(response?.data?.year)
                setWarrantyMonth(response?.data?.month)
                setSelectedOptions(response?.data?.setConfiguration);
                setmodelName(response?.data?.modelName);
                console.log("selectedOptions", selectedOptions);
            }
        })

    };
    async function updateItemDetails(data: any) {
        debugger
        try {
            let dataabc = data;
            dataabc.upload = [];
            await Promise.all(formValues.map(async function (item: any) {
                let abc = item?.fileURL.substring(0, 9);
                if (abc == "blob:http") {
                    let formData = new FormData();
                    formData.append("File", item.file);
                    formData.append("FileFor", itemname);
                    try {
                        const response = await inventoryService.uploadMultipleImages(formData);
                        if (response.data.isError) {
                            toast.error("Facing issue while Document Upload " + item.name);
                        } else {
                            dataabc.upload?.push(response?.data[0]);
                        }
                    } catch (ex: any) {
                        toast.warn(ex);
                    }
                } else {
                    dataabc.upload?.push(item?.fileURL);
                    // dataabc.id.push(itemid);
                }
                if(isApplicable == false){
                const result = await calculateDate1();
                dataabc.endDate = result;}
                dataabc.id = itemid;
            }));
            try {
                await inventoryService.Updateitem(dataabc).then((response: any) => {
                    if (response.data.isError) {
                        toast.warn(response.data.isError);
                    }
                    else {
                        toast.success("Item Updated  successfully")
                        handleClear();
                        navigate("/inventory-list");
                    }
                });
            } catch (ex: any) {
                toast.warn(ex.data.Error[0]);
            }

        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    }


    async function multiplefileupload(data: any) {
        try {
            let itemDetails = data;
            itemDetails.upload = [];
            // Create a single FormData object for all files
            let formData = new FormData();
            // Append all files to the FormData object
            formValues.forEach((item) => {
                if (item.file !== null) {
                    formData.append("File", item.file);

                }
            });
            formData.append("FileFor", itemname)
            // Upload all files at once
            const response = await inventoryService.uploadMultipleImages(formData);
            // Check for errors in the response
            if (response.data.isError) {
                toast.error("Facing issue while Document Upload");
            } else {
                // Push entries for all files in uploadProductDetails
                response?.data.forEach((item: any) => {
                    itemDetails.upload.push(item);
                });
                return itemDetails;
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warn(ex);
            });
        }
    }

    useEffect(() => {
        if ((itemid != null && itemid !== "") && (catId != null && catId !== "")) {
            getItemDetailById(itemid);
            getBrandCategorybId(catId);
        }
        else {
            handleClearPagereload();
        }
        categoryData();
    }, [itemid, catId]); // Add itemid and catId to the dependencies array



    const categoryData = async () => {
        setcategory(await clsCategoryList.getCategoryDropdownList())
    }

    let objDocument: IFiles[] = [];
    // const [documentName, setDocumentName] = React.useState("");
    const [documentFileURL, setDocumentFileURL] = React.useState<any>("");
    const [formValues, setFormValues] = useState<IFiles[]>(objDocument);

    let addDocumentValues = () => {
        setUpdateButtonDisable(false)
        IfileObj.itemType = "";
        IfileObj.name = "";
        IfileObj.fileURL = "";
        IfileObj.file = null;
        if (documentFile != null) {
            IfileObj.fileURL = documentFileURL;
            IfileObj.file = documentFile;
            setDocumentFile(null);
            setDocumentFileURL("");
            setTempDocumentFileURL("");
            let fObj = [...formValues];
            fObj.push(IfileObj);
            setFormValues([...formValues, IfileObj]);
        } else {
            if (documentFile == null) {
                toast.warning("Please choose Document file.");
                return;
            }
        }
    };

    let removeFormFields = (i: any) => {
        setUpdateButtonDisable(false)
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const triggerDocumentFile = (fileList: FileList, index: number) => {
        setDocumentFile(fileList[0]);
        setDocumentFileURL(URL.createObjectURL(fileList[0]));
        setTempDocumentFileURL(URL.createObjectURL(fileList[0]));
    };


    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const handleSubmit1 = (e: any) => {
        e.preventDefault();
        setFormSubmitted(true);
    };

   

    
    const fieldValidationSchema = Yup.object().shape({
        category: Yup.string()
            .required("Category is required"),
        // itemname: Yup.string()
        //     .required("Item Name is required"),
        SKU: Yup.string()
            .required("SKU Name is required"),
        // description: Yup.string()
        //     .required("Description is required"),
        seller: Yup.string()
            .required("Seller Name is required"),
        costprice: Yup.string()
            .required("Costprice  is required"),
        dateOFPurchase: Yup.string()
            .required("DateOFPurchase  is required").nullable(),
        // address: Yup.string()
        //     .required("Address  is required"),
        // purchaseComment: Yup.string()
        //     .required("Comment  is required").nullable(),
        // warrantyComment: Yup.string()
        //     .required("Comment  is required"),

        // startDate:
        //     isApplicable == false
        //         ? Yup?.string().required("From Date is required").nullable()
        //         : Yup?.string().nullable(),

        // endDate:
        //     isApplicable == false
        //         ? Yup?.string().required(" To Date is required").nullable()
        //         : Yup?.string().nullable(),

        month:
            (isApplicable == false && warrantyYear == "") || (isApplicable == false && warrantyYear == null)
                ? Yup?.string().required("Warranty Month is Required").nullable()
                : Yup?.string().nullable(),


        year:
            (isApplicable == false && warrantyMonth == "") || (isApplicable == false && warrantyMonth == null)
                ? Yup?.string().required("Warranty Year is Required").nullable()
                : Yup?.string().nullable(),
    });

    return (
        <Formik
            enableReinitialize={true}
            validateOnMount={true}
            initialValues={{
                categoryId: categoryId,
                category: selectedCategory,
                brand: [
                    {
                        brandName: selectedbrands
                    }
                ],

                itemname: itemname,
                description: description,
                seller: seller,
                costprice: costprice,
                SKU: SKU,
                upload: [],
                address: address,
                purchaseComment: purchaseComment,
                dateOFPurchase: dateOFPurchase,
                startDate: startDate,
                endDate: endDate,
                isApplicable: isApplicable,
                warrantyComment: warrantyComment,
                setConfiguration: selectedOptions,
                month: warrantyMonth,
                year: warrantyYear,
                modelName : modelName
            }
            }
            validationSchema={fieldValidationSchema}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {

                    if (itemid == null || itemid == "") {
                        if (formValues.length == 0) {
                            toast.warn("Please upload Image First")
                        }
                        else {
                            await addItem(values);
                        }
                    }
                    else {
                        if (formValues.length == 0) {
                            toast.warn("Please upload Image First")
                        }
                        else {
                            await updateItemDetails(values)
                        }
                    }
                }
                catch (err) {
                    console.error(err);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleReset,
                touched,
                values,
            }) => (

                <form noValidate
                    onSubmit={(e) => { handleSubmit(); handleSubmit1(e); }} >
                    <Box sx={{ width: '100%', typography: 'body1', }}>
                        <Typography sx={{ padding: "0 0 15px 0" }}>
                            {itemid ? "Update Item" : "Add Item"}
                        </Typography>
                        <Paper sx={{ padding: '24px 16px' }}>
                            <Stack direction="row" pb={'19px'} justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: "1px solid #EEEEEE" }} >
                                <Typography variant="h6" sx={{ fontWeight: 500, color: '#232360' }} component="h2">
                                    New Item
                                </Typography>
                            </Stack>
                            <Box paddingY={2} marginRight={'14px'} >
                                <Grid container spacing={3} >

                                    <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            value={
                                                categorys?.find(
                                                    (option: any) => option.category == selectedCategory
                                                ) ?? null
                                            }
                                            options={categorys}
                                            getOptionLabel={(option) => option.category}
                                            onChange={async (e, value) => {
                                                values.category = value?.category ?? "";
                                                setCategoryId(value?.id ?? "");
                                                setSelectedCategory(value?.category ?? "");
                                                if (value?.id != null ?? value?.id != "") {
                                                    await getCategorybyId(value?.id ?? "");
                                                    await getBrandCategorybId(value?.id ?? "");
                                                }

                                            }}
                                            componentsProps={{
                                                popper: {
                                                  modifiers: [
                                                    {
                                                      name: 'flip',
                                                      enabled: true,
                                                      options: {
                                                        altBoundary: true,
                                                        flipVariations: true,
                                                        altAxis: true,
                                                        padding: 8,
                                                        fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                      },
                                                    },
                                                  ],
                                                },
                                              }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    label="Category"
                                                    variant="outlined"
                                                    fullWidth
                                                    // disabled={disableForm}
                                                    name="Category"
                                                    value={selectedCategory}
                                                    onBlur={handleBlur}
                                                    sx={{ mb: '24px' }}
                                                    error={Boolean(
                                                        getIn(touched, "category") &&
                                                        getIn(errors, "category")
                                                    )}
                                                    helperText={
                                                        getIn(touched, "category") &&
                                                        getIn(errors, "category")
                                                    }

                                                />
                                            )}
                                        />
                                        <TextField
                                            label="Item Name"
                                            variant="outlined"
                                            // required
                                            fullWidth
                                            name="Item Name"
                                            value={values.itemname}
                                            // error={Boolean(
                                            //     getIn(touched, "itemname") &&
                                            //     getIn(errors, "itemname")
                                            // )}
                                            // helperText={
                                            //     getIn(touched, "itemname") &&
                                            //     getIn(errors, "itemname")
                                            // }
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                 .trimStart()
                                                values.itemname = value;
                                                setitemname(value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Autocomplete
                                            value={
                                                brands?.find(
                                                    (option) => option.brandName == selectedbrands
                                                ) ?? null
                                            }
                                            options={brands}
                                            getOptionLabel={(option) => option.brandName}
                                            onChange={(event, value) => {
                                                setFormSubmitted(false)
                                                setSelectedBrands(value?.brandName ?? "")
                                                values.brand = [{ brandName: brandName ?? "" }];
                                            }}
                                            componentsProps={{
                                                popper: {
                                                  modifiers: [
                                                    {
                                                      name: 'flip',
                                                      enabled: true,
                                                      options: {
                                                        altBoundary: true,
                                                        flipVariations: true,
                                                        altAxis: true,
                                                        padding: 8,
                                                        fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                      },
                                                    },
                                                  ],
                                                },
                                              }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    label="Brand"
                                                    variant="outlined"
                                                    fullWidth
                                                    type="text"
                                                    name="Brand"
                                                    value={brandName}
                                                    onBlur={handleBlur}
                                                    sx={{ mb: '24px' }}
                                                    error={formSubmitted && selectedbrands == ""}
                                                    helperText={
                                                        formSubmitted && selectedbrands == ""
                                                            ? "Brand is Required."
                                                            : ""
                                                    }

                                                />
                                            )}
                                        />

                                        <TextField
                                            label="SKU"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={values.SKU}
                                            error={Boolean(
                                                getIn(touched, "SKU") &&
                                                getIn(errors, "SKU")
                                            )}
                                            helperText={
                                                getIn(touched, "SKU") &&
                                                getIn(errors, "SKU")
                                            }
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.SKU = value;
                                                setSku(value);
                                            }}
                                        
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={3}>                               
                                         
                                        <TextField
                                            label="Model Name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={values.modelName}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.modelName = value;
                                                setmodelName(value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Description"
                                            id="description"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            // required
                                            // error={Boolean(
                                            //     getIn(touched, "description") &&
                                            //     getIn(errors, "description")
                                            // )}
                                            // helperText={
                                            //     getIn(touched, "description") &&
                                            //     getIn(errors, "description")
                                            // }
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: '100%',
                                                    lineHeight: '18px'
                                                },
                                                height: '100%'
                                            }}

                                            value={values.description}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.description = value;
                                                setDescription(value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={additem.Uploaddetail}>
                                            <Typography sx={additem.Uploaddetail.heading}>Upload Product Images</Typography>
                                            <Box sx={additem.Uploaddetail.Button}>
                                                {documentFile == null && <FileUploadPics
                                                    selectFile={triggerDocumentFile}
                                                    index={1}
                                                />}
                                            </Box>
                                        </Box>
                                        <Stack sx={{ mt: 2, mb: 4 }} direction={'row'} flexWrap={'wrap'} justifyContent={'start'} alignItems={'center'}>
                                            {formValues?.map((element: any, i: any) => (
                                                <Box key={i} sx={{
                                                    '&:hover': {
                                                        border: '1px solid #1DA7FF',
                                                    },
                                                    width: '100px', height: '100px', margin: '3px 10px', position: 'relative', border: '1px solid #EEE', borderRadius: '5px'
                                                }}>
                                                    {element?.file ? (
                                                        <img
                                                            width={'100%'} height={'100%'}
                                                            src={element?.fileURL}
                                                            alt="Image"
                                                            onClick={() => window.open(element.fileURL, '_blank')}
                                                        />
                                                    ) : (
                                                        <img
                                                            width={'100%'} height={'100%'}
                                                            src={apiUrl.getImageURL() + "/" + element?.fileURL}
                                                            alt="Image"
                                                            onClick={() => {
                                                                const generatedURL = apiUrl.getImageURL() + "/" + element?.fileURL;
                                                                window.open(generatedURL, '_blank');
                                                            }}
                                                        />
                                                    )}
                                                    <IconButton
                                                        onClick={() => removeFormFields(i)}
                                                        size="small"
                                                        // style={{ position: 'absolute', top: 0, right: 0,color: 'black' }}
                                                        style={{ justifyContent: 'right', position: 'absolute', top: '-6px', right: '-6px', background: '#fff', filter: 'blur', opacity: '0.5' }}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                        </Stack>


                                        {tempDocumentFileURL && (
                                            <Stack sx={{ width: '100%' }} direction='row' alignItems="center" justifyContent="start" spacing={2} margin={'20px 0'}>
                                                <Box sx={{ width: '100px', height: '100px' }}>
                                                    <a className={classes.anchor} href={tempDocumentFileURL} target="_blank" rel="noopener noreferrer">
                                                        <img width={'100%'} height={'100%'} style={{ border: '' }} src={tempDocumentFileURL} alt="Document" />

                                                    </a>
                                                </Box>

                                                {documentFile !== null && (
                                                    <IconButton onClick={() => cancelDocumentFile()}><ClearIcon /></IconButton>)}
                                                {tempDocumentFileURL && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => addDocumentValues()}
                                                        // size="small"
                                                        sx={{ mx: 2 }}
                                                        disabled={!documentFile}
                                                    >
                                                        Add{" "}
                                                    </Button>)}
                                            </Stack>
                                        )}

                                    </Grid>
                                </Grid>
                            </Box>
                            <Box paddingY={'13px'} marginRight={'14px'} sx={{ borderBottom: "1px solid #EEEEEE", borderTop: "1px solid #EEEEEE" }}>
                                <Stack direction="row" pb={'19px'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant="h6" sx={{ fontWeight: 500, color: '#232360' }} component="h2">
                                        Purchase Information
                                    </Typography>

                                </Stack>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Seller"
                                            id="seller"
                                            variant="outlined"
                                            fullWidth
                                            value={seller}
                                            onBlur={handleBlur}
                                            error={Boolean(
                                                getIn(touched, "seller") &&
                                                getIn(errors, "seller")
                                            )}
                                            helperText={
                                                getIn(touched, "seller") &&
                                                getIn(errors, "seller")
                                            }
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.seller = value;
                                                setseller(value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Cost Price"
                                            id="costprice"
                                            variant="outlined"
                                            fullWidth
                                            value={values.costprice}
                                            error={Boolean(
                                                getIn(touched, "costprice") &&
                                                getIn(errors, "costprice")
                                            )}
                                            helperText={
                                                getIn(touched, "costprice") &&
                                                getIn(errors, "costprice")
                                            }
                                            onChange={(e) => {
                                              
                                                let abbc = e.currentTarget.value
                                                //.trimStart().replace(/[^0-9]\s{0,}$/g, "");
                                                 // .replace(/[^0-9!@#\$%\^\&*\)\(+=._-]/g, "")
                                                .replace(/[^0-9]/g, '')                                              
                                                 .trimStart()
                                                values.costprice = abbc;
                                                setCostprice(abbc);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} sx={{mt: '-21px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                placeholder="Date of Purchase"
                                                borderRadius="semi-square"                                             
                                                label = "Date of Purchase "
                                                labelAlignment="left"
                                               
                                                // inputFormat="DD-MMM-YYYY"
                                                // openTo="year"
                                                className="error"
                                                maxDate={dayjs().toDate()}
                                                // views={["year", "month", "day"]}
                                                value={values?.dateOFPurchase}
                                                onChange={(newValue : any ) => {
                                                    let abc = getDate(newValue)
                                                    setPurchasedate(abc);
                                                    values.dateOFPurchase = abc;
                                                    setstartDate(abc);
                                                    values.startDate = abc;

                                                setshowHelpText(false);

                            }}
                            error={showHelpText ? "Date of Purchase is required" : ""}
                                                // renderInput={(params: any) => (
                                                //     <TextField
                                                //         onBlur={handleBlur}
                                                //         onKeyDown={(e: any) => {
                                                //             e.preventDefault();
                                                //         }}
                                                //         {...params}
                                                //         autoComplete="off" // Disable suggestions
                                                //         readOnly={true} // Disable user input
                                                //         error={
                                                //             getIn(touched, "dateOFPurchase") &&
                                                //             getIn(errors, "dateOFPurchase")
                                                //         }
                                                //         helperText={
                                                //             getIn(touched, "dateOFPurchase") &&
                                                //             getIn(errors, "dateOFPurchase")
                                                //         }
                                                //         fullWidth
                                                //         sx={{
                                                //             "& .MuiSvgIcon-root": {
                                                //                 fill: theme.palette.primary.main,
                                                //             },
                                                //         }}
                                                //     />
                                                // )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Address"
                                            id="outlined-basic"
                                            fullWidth
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            // required
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    // height: '100%',
                                                    lineHeight: '18px'
                                                },
                                            }}
                                            // error={Boolean(
                                            //     getIn(touched, "address") &&
                                            //     getIn(errors, "address")
                                            // )}
                                            // helperText={
                                            //     getIn(touched, "address") &&
                                            //     getIn(errors, "address")
                                            // }
                                            value={values.address}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value                                             
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.address = value;
                                                setAddress(value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Comment"
                                            id="outlined-basic"
                                            fullWidth
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            // required
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    lineHeight: '18px'
                                                },
                                            }}
                                            // error={Boolean(
                                            //     getIn(touched, "purchaseComment") &&
                                            //     getIn(errors, "purchaseComment")
                                            // )}
                                            // helperText={
                                            //     getIn(touched, "purchaseComment") &&
                                            //     getIn(errors, "purchaseComment")
                                            // }
                                            value={values.purchaseComment}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.purchaseComment = value;
                                                setPurchaseComment(value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box paddingY={'13px'} marginRight={'14px'} sx={{ borderBottom: "1px solid #EEEEEE", borderTop: "1px solid #EEEEEE" }}>
                                <Stack direction="row" pb={'19px'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant="h6" sx={{ fontWeight: 500, color: '#232360' }} component="h2">
                                        Warranty Details
                                    </Typography>
                                </Stack>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={7}>
                                        <Stack direction={'row'} flexWrap={'wrap'} spacing={5}>
                                            <FormControl >
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={isApplicable ? 'Non-Applicable' : 'Applicable'} // Set value based on state
                                                    // onChange={handleRadioChange}
                                                    defaultValue="single"
                                                    name="personalInfo.maritalStatus"
                                                >
                                                    <FormControlLabel
                                                        value="Applicable"
                                                        control={<Radio />}
                                                        label="Applicable"
                                                        sx={{ color: '#757575' }}
                                                        onClick={() => {
                                                            setisApplicable(false);
                                                            setstartDate(dateOFPurchase);
                                                        }}
                                                    />
                                                    <FormControlLabel
                                                        value="Non-Applicable"
                                                        control={<Radio />}
                                                        label="Non-Applicable"
                                                        sx={{ color: '#757575', ml: '40px' }}
                                                        onClick={() => {
                                                            setisApplicable(true);
                                                            setstartDate(null);
                                                            setendDate(null);
                                                            setWarrantyMonth("");
                                                            setWarrantyYear("");
                                                            values.month = "";
                                                            values.year = "";
                                                            values.startDate = null;
                                                            values.endDate = null;
                                                        }}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                        {!isApplicable &&(
                                        <Stack direction={'row'} spacing={2} marginY={2}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    //  disableFuture
                                                    placeholder="From"
                                                    borderRadius="semi-square"
                                                    style={{ width: '30%'}}
                                                    // inputFormat="DD-MMM-YYYY"
                                                    // openTo="year"
                                                    className="error"
                                                    // maxDate={dayjs()}
                                                    // views={["year", "month", "day"]}
                                                    value={values.startDate}
                                                    disabled={!!isApplicable}
                                                    readOnly={true}
                                                    onChange={(newValue : any) => {
                                                    }}
                                                    // renderInput={(params: any) => (
                                                    //     <TextField
                                                    //         onKeyDown={(e: any) => {
                                                    //             e.preventDefault();
                                                    //         }}
                                                    //         onChange={(newValue: any) => {
                                                    //         }}
                                                    //         {...params}
                                                    //         autoComplete="off" // Disable suggestions
                                                    //         readOnly={true} // Disable user input
                                                    //         error={
                                                    //             getIn(touched, "startDate") &&
                                                    //             getIn(errors, "startDate")
                                                    //         }
                                                    //         helperText={
                                                    //             getIn(touched, "startDate") &&
                                                    //             getIn(errors, "startDate")
                                                    //         }
                                                    //         // fullWidth
                                                    //         sx={additem.Warantydetail.Fromto}
                                                    //     />
                                                    // )}
                                                />
                                            </LocalizationProvider>

                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker
                                                    // disableFuture
                                                    label="To"
                                                    inputFormat="DD-MMM-YYYY"
                                                    openTo="year"
                                                    className="error"
                                                    minDate={dayjs()}
                                                    views={["year", "month", "day"]}
                                                    value={values.endDate}
                                                    disabled={!!isApplicable}
                                                    onChange={(newValue) => {
                                                        setendDate(newValue);
                                                        values.endDate = newValue;
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            onKeyDown={(e: any) => {
                                                                e.preventDefault();
                                                            }}
                                                            {...params}
                                                            autoComplete="off" // Disable suggestions
                                                            readOnly={true} // Disable user input
                                                            error={
                                                                getIn(touched, "endDate") &&
                                                                getIn(errors, "endDate")
                                                            }
                                                            helperText={
                                                                getIn(touched, "endDate") &&
                                                                getIn(errors, "endDate")
                                                            }
                                                            // fullWidth

                                                            sx={additem.Warantydetail.Fromto}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider> */}
                                            <Autocomplete
                                                value={
                                                    year?.find(
                                                        (option: any) => option.id == warrantyYear
                                                    ) ?? null
                                                }
                                                sx={{ width: 160 }}
                                                id="outlined-basic"
                                                options={year}
                                                getOptionLabel={(option) => option.id}
                                                onChange={(event, value) => {
                                                    setWarrantyYear(value?.id ?? "")
                                                    values.year = value?.label;
                                                }}
                                                componentsProps={{
                                                    popper: {
                                                      modifiers: [
                                                        {
                                                          name: 'flip',
                                                          enabled: true,
                                                          options: {
                                                            altBoundary: true,
                                                            flipVariations: true,
                                                            altAxis: true,
                                                            padding: 8,
                                                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        id="outlined-basic"
                                                        name="selectedResourceId"
                                                        label="Select Year"
                                                        variant="outlined"
                                                        value={warrantyYear}
                                                        error={
                                                            getIn(touched, "year") &&
                                                            getIn(errors, "year")
                                                        }
                                                        helperText={
                                                            getIn(touched, "year") &&
                                                            getIn(errors, "year")
                                                        }

                                                    />
                                                )}
                                            />
                                            <Autocomplete
                                                value={
                                                    Monts?.find(
                                                        (option: any) => option.id == warrantyMonth
                                                    ) ?? null
                                                }
                                                sx={{ width: 160 }}
                                                id="outlined-basic"
                                                options={Monts}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, value) => {
                                                    setWarrantyMonth(value?.id ?? "");
                                                    values.month = value?.label;
                                                }}
                                                componentsProps={{
                                                    popper: {
                                                      modifiers: [
                                                        {
                                                          name: 'flip',
                                                          enabled: true,
                                                          options: {
                                                            altBoundary: true,
                                                            flipVariations: true,
                                                            altAxis: true,
                                                            padding: 8,
                                                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Month"
                                                        id="outlined-basic"
                                                        name="selectedResourceId"
                                                        variant="outlined"
                                                        value={warrantyMonth}
                                                        error={
                                                            getIn(touched, "month") &&
                                                            getIn(errors, "month")
                                                        }
                                                        helperText={
                                                            getIn(touched, "month") &&
                                                            getIn(errors, "month")
                                                        }
                                                    />
                                                )}
                                            />
                                        </Stack>)}

                                    </Grid>
                                    {/* <Grid item xs={12} md={3}>

                                        <Stack direction={'row'} flexWrap='wrap' spacing={{lg: 0, xl: 2 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    //  disableFuture
                                                    label="From"
                                                    inputFormat="DD-MMM-YYYY"
                                                    openTo="year"
                                                    className="error"
                                                    // maxDate={dayjs()}
                                                    views={["year", "month", "day"]}
                                                    value={fromDate}
                                                    disabled={!!isApplicable}

                                                    // onChange={(newValue) => {
                                                    //     setfromDate(newValue);
                                                    //     setdatePeriod(newValue, toDate);
                                                    //     setfiltersSelected(true);
                                                    //     if (dayjs(toDate) < dayjs(newValue)) {
                                                    //       settoDate(
                                                    //         dayjs(newValue) < dayjs()
                                              e      //           ? dayjs(newValue?.add(1, "day"))
                                                    //           : newValue
                                                    //       );
                                                    //     }
                                                    //   }}
                                                    onChange={(newValue) => {
                                                        setfromDate(newValue);
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            onKeyDown={(e: any) => {
                                                                e.preventDefault();
                                                            }}
                                                            onChange={(newValue: any) => {
                                                            }}
                                                            {...params}

                                                            fullWidth
                                                            sx={additem.Warantydetail.Fromto}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker
                                                    // disableFuture
                                                    label="To"
                                                    inputFormat="DD-MMM-YYYY"
                                                    openTo="year"
                                                    className="error"
                                                    // maxDate={dayjs()}
                                                    views={["year", "month", "day"]}
                                                    value={toDate}
                                                    disabled={!!isApplicable}
                                                    onChange={(newValue) => {
                                                        settoDate(newValue);
                                                    }}
                                                    renderInput={(params: any) => (
                                                        <TextField

                                                            onKeyDown={(e: any) => {
                                                                e.preventDefault();
                                                            }}
                                                            onChange={(newValue: any) => {

                                                            }}
                                                            {...params}
                                                            fullWidth
                                                            sx={{
                                                                maxWidth: '160px',
                                                                paddingTop: { xs: "10px", xl: "0px" },
                                                                " & .css-9l522a-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                                                                    color: '#757575'
                                                                },
                                                                "& .MuiSvgIcon-root": {
                                                                    fill: theme.palette.primary.main,
                                                                },
                                                                "& .css-1ig1lwk-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                    borderColor: '#EEE',
                                                                    color: '#757575'
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Stack>

                                    </Grid> */}
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            label="Comment"
                                            id="outlined-basic"
                                            fullWidth
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            // required
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    lineHeight: '18px'
                                                },
                                            }}
                                            // error={Boolean(
                                            //     getIn(touched, "warrantyComment") &&
                                            //     getIn(errors, "warrantyComment")
                                            // )}
                                            // helperText={
                                            //     getIn(touched, "warrantyComment") &&
                                            //     getIn(errors, "warrantyComment")
                                            // }
                                            value={values.warrantyComment}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value
                                                .replace(/[^a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+| +/g, " ")
                                                .trimStart()
                                                values.warrantyComment = value;
                                                setwarrantyComment(value);
                                            }}
                                        />
                                        {/* <TextField
                                            label="Comment"
                                            id="outlined-basic"
                                            fullWidth
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            // required
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: '100%',
                                                    lineHeight: '18px'
                                                },
                                            }}
                                            onBlur={handleBlur}
                                        /> */}
                                    </Grid>
                                </Grid>



                            </Box>

                            <Box paddingY={'13px'} marginRight={'14px'}>
                                <Typography variant="h6" sx={{ fontWeight: 500, color: '#232360' }} component="h2">
                                    Set Configuration
                                </Typography>
                                <Box sx={{ marginTop: '14px' }}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ border: "1px solid #EEEEEE", borderRadius: '4px', padding: '34px 9px 34px 23px', flexWrap: 'wrap' }}>
                                        {configurations && configurations.length > 0 && configurations.map((config, index) => (
                                            <Grid container spacing={0} key={index} sx={{ display: "flex", marginBottom: '5px' }}>
                                                <Grid item xs={12} md={3}>
                                                    <Typography sx={{
                                                        fontSize: '18px', fontWeight: '500', color: '#232360', whiteSpace: 'nowrap', // Prevent attribute text from wrapping
                                                        maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
                                                    }}>
                                                        {config?.attribute}
                                                    </Typography>
                                                </Grid>
                                                {config?.value.map((option, optionIndex) => (
                                                    <Grid item xs={12} md={3} key={optionIndex}>
                                                        <CustomCheckbox label={option}
                                                            checked={selectedOptions?.some(data => data.attribute === config.attribute && data.value.includes(option))}
                                                            onChange={handleOptionChange(config.attribute, option)} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))}
                                    </Stack>
                                </Box>
                            </Box>

                            <Box paddingTop={'38px'} marginRight={'14px'} textAlign={'end'}>
                                <Button
                                    variant="outlined"
                                    onClick={() => cancelbutton()}
                                    color="primary"
                                    sx={{ margin: " 0px 22px" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => {
                                        setshowHelpText(!dateOFPurchase);

                                    }}
                                >
                                    {itemid ? "Update Item" : "Add Item"}
                                </Button>

                            </Box>
                        </Paper>
                    </Box>
                </form>
            )}
        </Formik>
    )
}
