import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Divider from "@mui/material/Divider";
import * as Icons from "../../assests/icons/icons";
import { userManagementServive } from "../../service/user-management-service";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import {
  userModelDetail,
  userModel,
  employeeInfo,
  personalInfo,
  insuranceCard,
  documents,
  pastExperince,
  badges,
  achivements,
  skillsets,
  othersDocument,
} from "./userModel";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import { Typography } from "@material-ui/core";
import { elementAcceptingRef } from "@mui/utils";
import moment from "moment";
import AppConstants from "../../config/AppConstants";
import { areIntervalsOverlapping } from "date-fns";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import InputLabel from "@mui/material/InputLabel/InputLabel";

interface Item {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

interface User {
  pastExperince: {
    organizationName: string;
    designation: string;
    workedFrom: string;
    workedTo: string;
    workType: string;
  };
}

export default function PastWorkExperience(props: {
  obj: any;
  setValue: any;
  callBackFunction: any;
}) {
  // const { id } = useParams();
  // let uId=id??"";//"632da2f9fa37260b70bf4e1f";
  setTimeout(() => {
    if (!props.obj && props.obj == "") {
      props.setValue(0);
    }
  }, 200);

  const navigate = useNavigate();
  const [userIdHook, setUserIdHook] = React.useState(props.obj);
  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    props.obj,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],
    [_othersDocument],
    [_skillsets]
  );
  const [userDataHook, setUserDataHook] = React.useState(usermodel);
  const [isSubmit, setSubmit] = useState(false);
  let Formobj = {
    organizationName: "",
    designation: "",
    workedFrom: "",
    workedTo: "",
    workType: "full",
  };
  const [formValues, setFormValues] = useState([Formobj]);

  console.log("formValues",formValues);
  const [DisabledButton, setDisabledButton] = useState(true);
  const [value, setValue] = React.useState(0);
  let handleChange1 = (i: any, e: any) => {
    let newFormValues = [...formValues];
    // newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  const mindate = new Date().getFullYear().toString();
  let addFormFields = () => {
    setFormValues([...formValues, Formobj]);

    // setDisabledButton(false);
  };

  let removeFormFields = (i: any) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setDisabledButton(false);
  };

  const updateformValue = (value: any, type: string, index: number) => {
    let newFormValues = [...formValues];

    if (type == "organizationName") {
      newFormValues[index].organizationName = value;
    }
    if (type == "designation") {
      newFormValues[index].designation = value;
    }
    if (type == "workedFrom") {
      newFormValues[index].workedFrom = value;
    }
    if (type == "workedTo") {
      newFormValues[index].workedTo = value;
    }
    if (type == "workType") {
      newFormValues[index].workType = value;
    }
    setFormValues(newFormValues);
    setDisabledButton(false);
  };

  let handleSubmit = (event: any) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());
  const onChange = (event: React.SyntheticEvent, newValue: null) => {
    setDateValue(newValue);
  };

  const getUserDetails = async () => {
    try {
      if (props.obj != "") {
        await userManagementServive
          .getUserById(props.obj)
          .then((response: any) => {
            if (response.data.isError) {
            } else {
              setUserDataHook(response.data);
              if (response.data.pastExperince.length > 0) {
                setFormValues(response.data.pastExperince);
              }
            }
          });
      }
    } catch {
      props.setValue(0);
    }
  };
  useEffect(() => {
    const getUserDetail = async () => {
      await getUserDetails();
    };
    getUserDetail();
  }, []);

  const setTimeToZero = (date: Date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
  const [showHelpText , setshowHelpText]= React.useState(false);
const[fromDate , setFromDate]= React.useState<any>("");
const[toDate , setToDate]= React.useState<any>("");


  const getArray = (arr: any[], indexToSkip: number) => {
    let arr2 = [];
    for (let index = 0; index < arr.length; index++) {
      if (index != indexToSkip) {
        arr2.push(arr[index]);
      }
    }
    return arr2;
  }

  const updateUser = async () => {
    try {
      let data = userDataHook;

      // if (DisabledButton == true) {
      data.pastExperince = formValues;

      let error = false;
      for (let index = 0; index < formValues.length - 1; index++) {
        const current = formValues[index];
        const fromDate1 = setTimeToZero(new Date(current.workedFrom));
        const toDate1 = setTimeToZero(new Date(current.workedTo));



        const isOverlap = getArray(formValues, index).map(x=> {
          
          const fromDate2 = setTimeToZero(new Date(x.workedFrom));
          const toDate2 = setTimeToZero(new Date(x.workedTo));
          if ((fromDate2 <= toDate1 && toDate2 >= fromDate1) || (fromDate2 <= toDate1 && toDate2 >= toDate1)) {
            
            return true;
          } else {
            return false;
          }
        }).some(x=>x)       
        if(isOverlap) {

          error = true;
          break;
        }
      } 
      if(error) {
        toast.warn("Please check past work experience");
        
        return false;
      }
      // }
      // if (updated && isUserFresher == true) {
      //   data.pastExperince = [];
      // }
      // if (data.pastExperince == formValues) {
      //   setDisabledButton(false);
      // }
      await userManagementServive.updateUser(data).then((response: any) => {
        if (response.data.isError) {
          toast.error("Facing issue while uploading data.");
        } else {
          if (response.data) {
            props.callBackFunction();
            toast.success("Past work experience added successfully.");
            setDisabledButton(true);
          }
        }
      });
    } catch (ex: any) {
      setDisabledButton(false);
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  return (
    <Formik
      initialValues={{
        formValues: formValues,
      }}
      validationSchema={Yup.object().shape({
        formValues: Yup.array().of(
          Yup.object().shape({
            organizationName: Yup.string()
              .max(50, "Organization Name should not greater than 50")
              .required("Organization Name is required")
              .matches(/^([A-Za-z\s]*)$/g,
                'Organization Name can only contain alphabets,.')
            ,
            designation: Yup.string().required("Designation is required")
              .matches(/^([A-Za-z\s]*)$/g,
                'Designation can only contain alphabets,.'),
            workedFrom: Yup.string().required("Worked From date is Required"),
            workedTo: Yup.string().required("Worked To date is Required"),
          })
        ),
      })}
      enableReinitialize
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (
            !formValues.some(
              (i) =>
                new Date(i?.workedFrom).getTime() >=
                new Date(i?.workedTo).getTime()
            )
          ) {
            updateUser();
          } else {
            toast.warning(
              "Worked From Date should be less than Worked To Date"
            );
          }
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          {formValues.map((element, index) => (
            <ItemPaper elevation={0} square sx={{ mb: 0.5 }}>
              <Grid
                container
                spacing={2}
                onChange={(e) => handleChange1(index, e)}
              >
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      error={Boolean(
                        getIn(touched, `formValues.${index}.organizationName`) &&
                        getIn(errors, `formValues.${index}.organizationName`)
                      )}
                      helperText={
                        getIn(touched, `formValues.${index}.organizationName`) &&
                        getIn(errors, `formValues.${index}.organizationName`)
                      }
                      name={`formValues.${index}.organizationName`}
                      onBlur={handleBlur}
                      required
                      label="Organization name"
                      variant="outlined"
                      fullWidth
                      value={element.organizationName || ""}
                      onChange={(event) => {
                        let value = event.currentTarget.value.replace(/[^a-zA-Z\s]/g, "")
                        value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 30);
                        updateformValue(
                          value,
                          "organizationName",
                          index
                        );
                      }}

                    />
                    <TextField
                      id="outlined-basic"
                      sx={{ width: { xs: "750px", sm: "100%" } }}
                      error={Boolean(
                        getIn(touched, `formValues.${index}.designation`) &&
                        getIn(errors, `formValues.${index}.designation`)
                      )}
                      helperText={
                        getIn(touched, `formValues.${index}.designation`) &&
                        getIn(errors, `formValues.${index}.designation`)
                      }
                      name={`formValues.${index}.designation`}
                      value={element.designation || ""}
                      required
                      onBlur={handleBlur}
                      label="Designation"
                      variant="outlined"
                      onChange={(event) => {
                        let value = event.currentTarget.value.replace(/[^a-zA-Z\s]/g, "")
                        value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 30);
                        updateformValue(
                          value,
                          "designation",
                          index
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Work type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="full"
                        name="radio-buttons-group"
                        value={element.workType}
                      >
                        <FormControlLabel
                          value="full"
                          onClick={(event) => {
                            updateformValue("full", "workType", index);
                          }}
                          control={<Radio />}
                          label="Full time"
                          sx={{ mr: 8 }}
                        />
                        <FormControlLabel
                          value="part"
                          onClick={(event) => {
                            updateformValue("part", "workType", index);
                          }}
                          control={<Radio />}
                          label="Part time "
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // shouldDisableDate={(date: any) =>
                        //   element.workedTo
                        //     ? new Date(element.workedTo).setHours(
                        //       0,
                        //       0,
                        //       0,
                        //       0
                        //     ) === new Date(date).getTime()
                        //     : false
                        // }
                        label="Worked from"
                        placeholder="Worked from"
                        labelAlignment="left"
                        borderRadius="semi-square"
                        
                       
                        minDate={moment().subtract(53, "years").toDate()}
                        maxDate={moment().add(0, "years").toDate()}
                        value={element.workedFrom}
                        onChange={(newValue: any) => {
                          let abc = getDate(newValue);   
                          element.workedFrom = abc;                      
                          setFromDate(abc);                                          
                          updateformValue(newValue, "workedFrom", index);
                          setSubmit(true);
                          setshowHelpText(false);
                        }}
                        error={showHelpText && !element.workedFrom ? "Worked From date is Required" : ""} />

                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        borderRadius="semi-square"
                        
                        // shouldDisableDate={(date: any) =>
                        //   element.workedFrom
                        //     ? new Date(element.workedFrom).setHours(
                        //       0,
                        //       0,
                        //       0,
                        //       0
                        //     ) === new Date(date).getTime()
                        //     : false
                        // }
                        minDate={new Date(element.workedFrom) ? new Date(element.workedFrom) : new Date("")}
                        maxDate={new Date()}
                        label="Worked to"
                        placeholder="Worked to"
                        labelAlignment="left"
                        // views={["year", "month", "day"]}
                        // openTo="year"
                        value={element.workedTo}
                        // inputFormat="DD-MMM-YYYY"
                        onChange={(newValue: any) => {  
                         
                          let abc = getDate(newValue);   
                          element.workedTo = abc;                                         
                          setToDate(abc);                                             
                          updateformValue(newValue, "workedTo", index);
                          setSubmit(true);
                          setshowHelpText(false);
                        }}
                    
                        error={showHelpText && !element.workedTo ? "Worked To date is Required" : ""} />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                {index ? (
                  <Button
                    endIcon={<Icons.Delete />}
                    color="error"
                    onClick={() => removeFormFields(index)}
                  ></Button>
                ) : null}
              </Stack>
            </ItemPaper>
          ))}

          <Button
            endIcon={<AddIcon />}
            sx={{ my: 2 }}
            onClick={() => {addFormFields()
              setshowHelpText(false)
            
            }}
          >
            Add More
          </Button>
          <Divider light />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 3 }}
          >
            <Button
             type="submit"
              variant="contained"
               disabled={DisabledButton}
               onClick={()=>{
                setshowHelpText(!toDate || !fromDate);

               }}
               >
              Save
            </Button>
            <Button variant="outlined" onClick={() => props.setValue(4)}>
              Continue{" "}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
