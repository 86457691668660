import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import * as Icons from '../../assests/icons/icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import theme from '../../theme/theme'
const styles = {
    disable: {
        color: theme.palette.grey[400]
    },

}
export default function RoleView() {
    return (
        <>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ pb: 3 }}>
                    <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                        All Role
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />

                    <Button variant="contained" size="large" component={Link} to='/role-management/add-new-role' color="primary">
                        Add Role
                    </Button>
                </Stack>
                <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
                    <Stack direction="row" spacing={2} alignItems="center" >

                        <Avatar sx={{ width: 52, height: 52 }}>H</Avatar>
                        <Stack spacing={0}>
                            <Typography variant="subtitle1" component="h5"  >
                                Role
                            </Typography>
                            <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 600 }}>
                                Design Manager
                            </Typography>
                        </Stack>
                    </Stack>
                </Paper>


                <Grid container spacing={2}>
                    <Grid item xs>
                        <Paper variant='outlined' sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }} gutterBottom>
                                    User Management
                                </Typography>
                                <IconButton aria-label="delete">
                                    <Icons.Edit />
                                </IconButton>
                            </Stack>

                            <Table size="small" aria-label="simple table">
                                <TableBody>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} ></TableCell>
                                    </TableRow>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} >Read</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>

                        <Paper variant='outlined'  sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }} gutterBottom>
                                    Project Management
                                </Typography>
                                <IconButton aria-label="delete">
                                    <Icons.Edit />
                                </IconButton>
                            </Stack>

                            <Table size="small" aria-label="simple table">
                                <TableBody>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} ></TableCell>
                                    </TableRow>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} >Read</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper variant='outlined'  sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }} gutterBottom>
                                    Role Management
                                </Typography>
                                <IconButton aria-label="delete">
                                    <Icons.Edit />
                                </IconButton>
                            </Stack>

                            <Table size="small" aria-label="simple table">
                                <TableBody>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} ></TableCell>
                                    </TableRow>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} >Read</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                        <Paper variant='outlined'  sx={{ mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }} gutterBottom>
                                    Role Management
                                </Typography>
                                <IconButton aria-label="delete">
                                    <Icons.Edit />
                                </IconButton>
                            </Stack>

                            <Table size="small" aria-label="simple table">
                                <TableBody>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} ></TableCell>
                                    </TableRow>
                                    <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            Tab Dummy Heading 2
                                        </TableCell>
                                        <TableCell align="right" sx={styles.disable} >Edit</TableCell>
                                        <TableCell align="right" sx={styles.disable} >View</TableCell>
                                        <TableCell align="right" sx={styles.disable} >Read</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}




