import { http } from "../http-common";

class ProjectGrowthRevenueService {
   
    getProjectRevenueGrowth = async (req: any): Promise<any> => {
        return await http.post<any>("/v1/Reports/GetRevenueGrowthReport",req);
    };
 
    getProjectRevenueMonthReport = async (req: any): Promise<any> => {
        return await http.post<any>("/v1/Project/GetrevenueGrowthProjectReportMonthWise",req);
    };
 
    getProjectRevenueYearlyReport = async (req: any): Promise<any> => {
        return await http.post<any>("/v1/Project/GetrevenueGrowthProjectReportyearWise",req);
    };
    getProjectRevenueGraphdata = async (req: any): Promise<any> => {
        return await http.post<any>("/v1/Project/GetrevenueGrowthProjectReport",req);
    };
}

export const projectGrowthRevenueService = new ProjectGrowthRevenueService();