import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import logoImage from "../../assests/images/logo.svg";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "material-react-toastify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Icons from "../../assests/icons/icons";
import FileUpload from "../../components/styledComponent/FileUpload";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import { commonKeys } from "../../service/common-keys";
import { userManagementServive } from "../../service/user-management-service";
import theme from "../../theme/theme";
import {
  achivements,
  badges,
  documents,
  insuranceCard,
  employeeInfo,
  pastExperince,
  personalInfo,
  skillsets,
  userModel,
  IFiles,
  othersDocument,
} from "./userModel";
import AppConstants from "../../config/AppConstants";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { apiUrl } from "../../service/common-services/apiURL";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { projectManagementServive } from "../../service/project-management-service";
import ClearIcon from "@mui/icons-material/Clear";
const styleCross = {
  position: "absolute" as "absolute",
  right: "5px",
  top: "5px",
};
const toggleloaders = {
  position: "fixed",
  left: "50%",
  right: "50%",
  top: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)"
}
export default function Documents(props: {
  obj: any;
  setValue: any;
  callBackFunction: any;
}) {
  const navigate = useNavigate();
  //let connonkeys = new commonKeys();----
  // const { id } = useParams();
  // let uId=id??"";//"632da2f9fa37260b70bf4e1f";
  setTimeout(() => {
    if (!props.obj && props.obj == "") {
      props.setValue(0);
    }
  }, 200);

  const [userIdHook, setUserIdHook] = React.useState(props.obj);
  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    props.obj,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],
    [_othersDocument],
    [_skillsets]
  );
  const [userDataHook, setUserDataHook] = React.useState(usermodel);
  const FileExtension = [
    "pdf",
    "docx",
    "doc",
    "docm",
    "xls",
    "xls",
    "xlsx",
    "xlsm",
    "xlsb",
    "xltx",
  ];

  let obj: IFiles[] = [
    {
      itemType: "fixed",
      name: "10th Certificate",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "12th Certificate",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Graduation",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Post Graduation",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Additional Diploma Course",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Permanent Residence Proof",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Pan Card",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Aadhaar Card",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Relieving/Resignation letter from Last Employer",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Last Employer Salary Slips (3 months)",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Passport size Photographs",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Appointment Letter",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "fixed",
      name: "Confirmation Letter",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
    {
      itemType: "new",
      name: "Other Document",
      fileURL: "",
      fileName: "",
      file: null,
      ext: "",
    },
  ];

  const _permission = useSelector(
    (state: any) => state.UserPermissionApp.UserPermission
  );
  const [formValues, setFormValues] = useState<IFiles[]>(obj);
  const [toggleLoader, setToggleLoader] = useState(false);
  // console.log("formValues kjbjbjkjbkjbkjbkj", formValues);
  const [openAlert, setAlertOpen] = React.useState<any>({});
  const [selected, setSelected] = useState(0);
  const handleCloseAlert = (idx: any) => {
    setAlertOpen({ [idx]: false });
  };
  const deleteLeaveData = async (index: any, e: any) => {
    //console.log(" indexdeleteLeaveData", index);
    if (index != null || index === undefined) {
      setSelected(index);
      setAlertOpen({ [index]: true });
      e.stopPropagation();
    } else {
      setAlertOpen({ [index]: false });
    }
  };
  let handleChange = (i: any, e: any) => {
    let newFormValues = [...formValues];
    //newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };
  

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        itemType: "new",
        name: "Other Document",
        fileURL: "",
        fileName: "",
        file: null,
        ext: "",
      },
    ]);
    setTempValue("");
  };
  console.log(formValues, "formValues")
  let removeFormFields = (i: any) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setDisabledButton(true);
  };

  let handleSubmit = (event: any) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };

  useEffect(() => {
    const getUserDetail = async () => {
      await getUserDetails();
    };
    getUserDetail();
  }, []);

  const getUserDetails = async () => {
    try {
      if (props.obj != "") {
        await userManagementServive
          .getUserById(props.obj)
          .then((response: any) => {
            if (response.data.isError) {
            } else {
              setUserDataHook(response.data);
              if (response.data.documents.length > 0) {
                setFormValues([]);
                let frmV = [...formValues];
                response.data.documents.map((item: any, idx: any) => {
                  frmV.map((item1: any, index: any) => {
                    if (item.documentType == item1.name) {
                      item1.fileName = item.fileName ?? "";
                      item1.fileURL = item.fileName

                      item1.ext =
                        item1.fileName
                          .split(".")
                        [
                          item1.fileName.split(".").length - 1
                        ].toLowerCase() ?? "";
                    }
                    frmV[index] = item1;
                  });
                  if (idx > 12) {
                    let o = {
                      itemType: "fixed",
                      name: "10th Certificate",
                      fileURL: "",
                      fileName: "",
                      file: null,
                      ext: "",
                    };
                    o.itemType = "new";
                    o.name = item.documentType;
                    o.fileURL = item.fileName

                    o.fileName = item.fileName ?? "";
                    o.ext =
                      item.fileName
                        .split(".")
                      [item.fileName.split(".").length - 1].toLowerCase() ??
                      "";
                    if (idx == 13) {
                      frmV[idx] = o;
                    } else {
                      frmV.push(o);
                    }
                  }
                });

                setFormValues(frmV);
              }
            }
          });
      }
    } catch {
      props.setValue(0);
    }
  };
  const [DisabledButton, setDisabledButton] = useState(true);
  //console.log("DisabledButton", DisabledButton);
  const updateUser = async () => {
    setToggleLoader(true);
    try {
      let data = userDataHook;
      let arrayofFilesData: any = [];

      await Promise.all(
        formValues.filter((a) => a.name != "").map(async (item) => {
          arrayofFilesData.push({ fileName: "", documentType: item.name });

          if (item.file != null) {
            let formData = new FormData();
            formData.append("File", item.file);
            formData.append("FileFor", item.name);

            await userManagementServive.UploadUserDocument(formData).then((response: any) => {
              if (response.data.isError) {
                toast.error("Facing issue while uploading document.");
              } else {
                arrayofFilesData
                  .filter((b: any) => b.documentType == item.name)
                  .map((obj: any) => {
                    obj.fileName = response.data;
                  });
              }
            });
          } else {
            arrayofFilesData
              .filter((b: any) => b.documentType == item.name)
              .map((obj: any) => {
                if (item.itemType == "new" && item.fileName != "") {
                  obj.fileName = item.fileName;
                } else {
                  obj.fileName = item.fileName;
                }
              });
          }
        })
      );
      data.documents = arrayofFilesData;
      const response = await userManagementServive.updateUser(data);
      if (response.data.isError) {
        setToggleLoader(false);
        toast.error("Facing issue while uploading data.");
      } else {
        toast.success("Documents uploaded successfully.", {

          onOpen: () => setDisabledButton(true),
          onClose: () => setDisabledButton(false),
        });
        setToggleLoader(false);
      }

      props.callBackFunction();
    } catch (ex: any) {
      setDisabledButton(false);
      setToggleLoader(false);
      Object.values(ex.data.errors).flat().map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getThumbnail = (extension: any) => {
    //console.log("extension", extension);
    switch (extension) {
      case "pdf":
        return "https://cdn-icons-png.flaticon.com/512/2889/2889358.png";

      case "docx":
        return "https://cdn-icons-png.flaticon.com/512/2266/2266784.png";

      case "doc":
        return "https://cdn-icons-png.flaticon.com/512/2266/2266784.png";

      case "docm":
        return "https://cdn-icons-png.flaticon.com/512/2266/2266784.png";

      case "xls":
        return "https://cdn-icons-png.flaticon.com/512/732/732220.png";

      case "xlsx":
        return "https://cdn-icons-png.flaticon.com/512/732/732220.png";

      case "xlsm":
        return "https://cdn-icons-png.flaticon.com/512/732/732220.png";

      case "xlsb":
        return "https://cdn-icons-png.flaticon.com/512/732/732220.png";

      case "xltx":
        return "https://cdn-icons-png.flaticon.com/512/732/732220.png";

      default:
        return "https://cdn-icons-png.flaticon.com/512/10020/10020158.png";
    }
  };

  const [selectedFiles, setSelectedFiles] = useState<IFiles[]>([]);
  const [filetypeHook, setFiletypeHook] = useState("");
  const [fileHook, setFileHook] = useState<any>(null);
  const [fileUrlHook, setFileUrlHook] = useState<any>(null);

  const [tempValue, setTempValue] = useState("");
  const updateformValue = (
    value: any,
    fileList: FileList,
    type: string,
    index: number
  ) => {
    let newFormValues = [...formValues];
    if (type == "file") {
      newFormValues[index].file = fileList[0];
      newFormValues[index].fileURL = URL.createObjectURL(fileList[0]);
      newFormValues[index].fileName = fileList[0].name;
      newFormValues[index].ext = fileList[0]?.name
        .split(".")
      [fileList[0].name.split(".").length - 1].toLowerCase();
    }
    if (type == "name") {
      newFormValues[index].name = value;
    }
    setFormValues(newFormValues);
  };
  const triggerEvent = (fileList: FileList, index: number) => {
    setDisabledButton(false);
    updateformValue("", fileList, "file", index);
  };
  let removeFile = (i: any) => {
    let newFormValues = [...formValues];
    if (i == 13)
    {
    newFormValues[i].file = null;
    newFormValues[i].fileName = "";
    newFormValues[i].fileURL = "";
    newFormValues[i].ext = "";
    newFormValues[i].name="Other Document";
    newFormValues[i].itemType="new";
    setFormValues(newFormValues);
  }
    else{
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    }
    
    setDisabledButton(false);
  };

  const [openDocumentAlert, setDocumentAlertOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const handleClickDocumentOpenAlert = () => {
    setDocumentAlertOpen(true);
  };
  const handleCloseDocumentAlert = () => {
    setDocumentAlertOpen(false);
    if (fileUrl) {
        URL.revokeObjectURL(fileUrl); // Clean up blob URL
        setFileUrl(null);
    }
};



useEffect(() => {
  if (fileUrl) {
    setDocumentAlertOpen(true);
  }
}, [fileUrl]);
  const getUnEncrypedFiles = async (fileName: any) => {
    try {
      await projectManagementServive
        .getUnEncrypetDocuments(fileName)
        .then(async (response: any) => {
          if (response.data.type === "application/json") {
            const data = await response.data.text();
            toast.warning(JSON.parse(data).listErrors[0]);
          }
          else {
            const fileUrl = URL.createObjectURL(response.data);
            setFileUrl(fileUrl);
           // handleClickDocumentOpenAlert();
            // var a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style.display = "none";
            // a.href = URL.createObjectURL(response.data);
            // a.download = fileName;
            // a.click();
            // window.URL.revokeObjectURL(a.href);
          }
        });
    } catch (ex: any) {
      (ex.data?.Error || ex.Error).map((err: any, idx: any) => {
        toast.warning(err);
      });
    };
  }
  
  return (
    <>
      <Grid container spacing={2} sx={{ mb: 5 }}>
        {toggleLoader && (<Box sx={toggleloaders}>
          <FontAwesomeIcon icon={faSpinner} spinPulse size={"3x"} />
        </Box>)}
        {formValues.map((element, index) => (
          <>
            {/* {index == 13 && <Grid item xs lg={3}></Grid>} */}

            <Grid item xs lg={3}>
              <ItemPaper
                elevation={0}
                sx={{
                  p: 2,
                  height: "100%",
                  // display: 'flex',
                  // msFlexDirection: 'column',
                  // justifyContent: 'space-between',
                  // flexDirection: 'column',
                }}
              >
                {index <= 12 && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.primary.dark,
                      mb: 2,
                      fontWeight: 400,
                    }}
                  >
                    <label>{element.name}</label>
                  </Typography>
                )}

                {index > 12 && (
                  <Box sx={{ maxWidth: 400 }}>
                    <TextField
                      label="Document Type"
                      variant="outlined"
                      name="DocumentType"
                      id={"fileType_" + index}
                      value={element.name ?? tempValue}
                      onChange={(event) => {
                        element.name = event.currentTarget.value;
                        setTempValue(event.currentTarget.value.substring(0,35));
                        updateformValue(
                          event.currentTarget.value,
                          new FileList(),
                          "name",
                          index
                        );
                      }}
                      fullWidth
                      sx={{ mt: 2, mb: 2 }}
                    />
                  </Box>
                )}
                {element.fileName != null && element.fileName != "" && (
                  <div>
                    <Stack direction="row" alignItems="center">
                      {/* <ImageListItem sx={{ width: 30, height: 30, mr: 2 }}>
                        <img
                          src={
                            FileExtension.includes(element?.ext)
                              ? getThumbnail(element?.ext)
                              : element.fileURL
                          } //'https://images.unsplash.com/photo-1567306301408-9b74779a11af?w=164&h=164&fit=crop&auto=format'
                          loading="lazy"
                        />
                      </ImageListItem> */}
                      <Typography
                        // component={Link}
                        // // target="_blank"
                        // href={element.fileURL}
                        // variant="body1"
                        // onClick={()=>{
                        //   getUnEncrypedFiles(element?.fileName)}}
                        onClick={() => {
                          if (element?.file != null) {
                            window.open(element.fileURL, '_blank');
                          } else {
                            getUnEncrypedFiles(element?.fileName);
                          }
                        }}
                        style={{ cursor: 'pointer', }}
                        sx={{
                          color: theme.palette.primary.main,
                          textDecoration: "underline",
                          wordBreak: "break-all",
                        }}
                      >
                        {element.fileName}
                      </Typography>
                    </Stack>

                    <Box sx={{ textAlign: "right", my: 1 }}>
                      <IconButton
                        onClick={(e) => deleteLeaveData(index, e)}
                        aria-label="delete"
                        size="large"
                      >
                        <Icons.Delete />
                      </IconButton>
                      <Dialog
                        open={openAlert?.[index]}
                        onClose={() => {
                          setAlertOpen({ [index]: true });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="sm"
                      >
                        <IconButton
                          aria-label="delete"
                          sx={styleCross}
                          onClick={() => {
                            handleCloseAlert(index);
                          }}
                        >
                          {/* <ClearIcon /> */}
                        </IconButton>
                        <DialogTitle
                          id="alert-dialog-title"
                          sx={{
                            textAlign: "center",
                            fontWeight: 700,
                            fontSize: "26px",
                            lineHeight: "39px",
                            color: "#212121",
                          }}
                        >
                          Delete
                        </DialogTitle>
                        <DialogContent
                          sx={{ pt: 1, fontSize: "24px", color: "#424242" }}
                        >
                          Are you sure you want to delete this
                        </DialogContent>
                        <DialogActions
                          sx={{
                            p: 3,
                            pt: 0,
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button variant="outlined" onClick={handleCloseAlert}>
                            No
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              removeFile(selected);
                              setAlertOpen(false);
                            }}
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </div>
                )}
                {element.fileName == "" && (
                  <>
                    {_permission.action_ManageDocuments_Upload_Access && (
                      <FileUpload selectFile={triggerEvent} index={index} />)}
                  </>
                )}
                {/* {index > 10 ? (
                  <Box onClick={() => removeFormFields(index)} sx={{ mt: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.warning.main,
                        cursor: "pointer",
                      }}
                    >
                      Remove Document
                    </Typography>
                  </Box>
                ) : null} */}
              </ItemPaper>
            </Grid>
          </>
        ))}
      </Grid>
      {formValues.length <= 15 && (
        <Box>
          <Button endIcon={<AddIcon />} onClick={() =>{ 
            if(tempValue=="" || formValues.at(-1)?.fileURL==""){
              toast.warn("Please Add the Document Name and upload it first")
            }
            else{
            addFormFields()}
            }}>
            Add More{" "}
          </Button>
        </Box>
      )}
      {/* <Grid container spacing={2} >
                <Grid item xs={12} sm={12}>
                    {formValues.map((element, index) => (
                        <ItemPaper elevation={0} square sx={{ mb: 0.5 }} >

                            <Box sx={{ maxWidth: 400 }}>
                                {index > 3 && <TextField
                                    label="Document Type"
                                    variant="outlined"
                                    name="DocumentType"
                                    id={"fileType_" + index}
                                    value={element.name ?? tempValue}
                                    onChange={(event) => {
                                        element.name = event.currentTarget.value;
                                        setTempValue(event.currentTarget.value);
                                        updateformValue(event.currentTarget.value, new FileList, "name", index);
                                    }}
                                    fullWidth

                                />}

                            </Box>

                            {index <= 3 && <label>{element.name}</label>}

                            <Stack direction='row' alignItems='center'>

                                {element.fileURL != "a" &&
                                    <Box sx={{ mt: 2 }}>
                                        <Avatar 
                                            src={element.fileURL}
                                            sx={{ width: 72, height: 72 }}
                                        >
                                            <CameraAltOutlinedIcon />
                                        </Avatar>
                                    </Box>
                                }

                                <Box sx={{ mt: 2, ml: 4 }}>
                                    <Button component="label" size="small" sx={{ height: 'auto' }}>
                                        Upload Document
                                        <input hidden
                                            onChange={(event) => {
                                                var files = event.target.files ?? new FileList;
                                                updateformValue("", files, "file", index);
                                            }}
                                            accept="*"
                                            type="file"
                                            name={"file_" + index}
                                            id={"file_" + index}
                                        />
                                    </Button>
                                </Box>
                                {element.fileURL != "" &&
                                    <Box>
                                        <a href={element.fileURL} target="_blank">View File</a>
                                    </Box>
                                }

                                {index > 3 ? (
                                    <Button endIcon={< Icons.Delete />} sx={{ my: 2 }} color="error" onClick={() => removeFormFields(index)}>Remove </Button>
                                ) : null}
                            </Stack>
                        </ItemPaper>
                    ))}
                    <Button endIcon={<AddIcon />} sx={{ my: 2 }} onClick={() => addFormFields()}>Add More  </Button>
                </Grid>
            </Grid> */}

      {/* <ItemPaper elevation={0} sx={{ mb: 0, display: 'none' }} >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6}>

                        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>Documents to be submitted by experienced candidate</Typography>

                        <NestedList documentInfo='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                        <NestedList documentInfo='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                        <NestedList documentInfo='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />


                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>Documents to be submitted by experienced candidate</Typography>

                        <NestedList documentInfo='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                        <NestedList documentInfo='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                        <NestedList documentInfo='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />


                    </Grid>
                </Grid>
            </ItemPaper> */}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 3 }}
      >
        <Button
          type="button"
          onClick={() => updateUser()}
          disabled={DisabledButton}
          variant="contained"
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            if (
              userDataHook &&
              userDataHook.employeeInfo.isUserFresher == false
            ) {
              props.setValue(5);
            } else {
              props.setValue(4);
            }
          }}
        >
          Continue{" "}
        </Button>
      </Stack>


      <Dialog
        open={openDocumentAlert}
        onClose={setDocumentAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       // maxWidth="md"
        fullScreen
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseDocumentAlert}
        >
          <ClearIcon />
        </IconButton>
        {fileUrl ? (
      <iframe
        src={fileUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    ) : (
      <div style={{ padding: "20px", textAlign: "center" }}>
        No document to preview
      </div>
    )}
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
          
        >
          <Button variant="outlined" onClick={handleCloseDocumentAlert}>
            Go Back
          </Button>
          <IconButton
          aria-label="delete"
        //  onClick={handleClearClick}
          sx={{
            marginLeft: 'auto',
            // "& .css-i4bv87-MuiSvgIcon-root":{
            //   width:''
            // } 
          }}
        >
          <Icons.DownloadIcon style={{ color: 'blue' }} />
        </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
