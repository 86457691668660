import React from 'react';
import UserList from './UserList'


export default function UserManagement() {
    return (
        <>
           <UserList />
        </>
    );
}