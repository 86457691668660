import { http } from "../http-common";

class LeaveService {

  addleave = async (param: any): Promise<any> => {
    return await http.post<any>(`/v1/LeaveManagement/Add`, param);
  };

  

  updateRequestDetails = async (param: any): Promise<any> => {
    return await http.post<any>(`/v1/LeaveManagement/UpdateRequestDetails`, param);
  };

  approvreRequest = async (param: any): Promise<any> => {
    return await http.post<any>(`/v1/LeaveManagement/ApproveLeave`, param);
  };

  getAllLeaveManagementDetails = async (req: any): Promise<any> => {

    return await http.post<any>("/v1/LeaveManagement/GetAllLeaveManagementDetails", req);
  };

  getAllCalenderDetails = async (req: any): Promise<any> => {
    
    return await http.post<any>("/v1/LeaveManagement/GetAllYearlyLeaves",req);
  };

  
  GetCalenderWorkById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/LeaveManagement/GetYearlyLeavesById=${id}`);
  };

  deleteCalender = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/LeaveManagement/DeleteYearlyLeaves?id=${id}`);
}

  UpdateLeavemanagementStatusAsync = async (req: any): Promise<any> => {

    return await http.post<any>("/v1/LeaveManagement/UpdateLeavemanagementStatusAsync", req);
  };

  deletewithdrawn = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/LeaveManagement/DeleteLeaveDetails?id=${id}`);
  }

  getLeaveDetailsById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/LeaveManagement/getLeaveDetailsById?id=${id}`);
  }

  GetLeaveAvailedByUserId = async (): Promise<any> => {

    return await http.get<any>("/v1/LeaveManagement/GetLeaveAvailedByUserId");
  };

  GetDeaprtmentWiseLeaveBalance = async (req: any): Promise<any> => {

    return await http.post<any>("/v1/LeaveManagement/GetDeaprtmentWiseLeaveBalance", req);
  };
  getBalancedLeaveBasedOnUserID = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/LeaveManagement/GetBalancedLeaveBasedOnUserID?userId=${id}`); 
  }
}

export const leaveService = new LeaveService();