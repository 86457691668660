import {} from "../store/menu";
import { useDispatch, useSelector } from "react-redux";

import { EmptyMasterPermission, FillMasterPermission } from "../store/permissions/master-permission";
import { EmptyUserPermission, FillUserPermission } from "../store/permissions/user-permission";
import { EmptyReportPermission, FillReportPermission } from "../store/permissions/report-permission";
import { EmptyLeavePermission, FillLeavePermission } from "../store/permissions/leave-permission";
import { EmptyNotificationPermission, FillNotificationPermission } from "../store/permissions/notification-permission";
import { EmptyBrowniePermission, FillBrowniePermission } from "../store/permissions/brownie-permission";
import { EmptyEscalationPermission, FillEscalationPermission } from "../store/permissions/escalation-permission";
import { EmptyRiskAlarmPermission, FillRiskAlarmPermission } from "../store/permissions/riskAlarm-permission";
import { EmptyProjectPermission, FillProjectPermission } from "../store/permissions/project-permission";
import { EmptyInventoryPermission,FillInventoryPermission } from "../store/permissions/inventory-permission";
import { EmptySeasiagalaPermission, FillSeasiagalaPermission } from "../store/permissions/seasiagala-permission";
import { EmptyPrismPermission, FillPrismPermission } from "../store/permissions/prism-permission";
import { EmptyMeetingsPermission, FillMeetingsPermission } from "../store/permissions/meetings-permission";
import { FillProjectPortfolioPermission } from "../store/permissions/projectPortfoli-permission";
import { EmptyLearningSessionPermission, FillLearningSessionPermission } from "../store/permissions/LearningSession-permission";
import { EmptyDomainPortfolioPermission, FillDomainPortfolioPermission } from "../store/permissions/domainPortfolio-permission";
export class UpdateMenuPermissionService {
  dispatch=useDispatch();
  constructor(menu:any) {
    if(menu.moduleName==='Master Configuration'){
      this.dispatch(EmptyMasterPermission());
      this.dispatch(FillMasterPermission(menu));
    }
    if(menu.moduleName==='User Management'){
      this.dispatch(EmptyUserPermission());
        this.dispatch(FillUserPermission(menu));
    }
    if(menu.moduleName==='Project Management'){
      this.dispatch(EmptyProjectPermission());
        this.dispatch(FillProjectPermission(menu));
    }
    if(menu.moduleName==='Report Management'){
      this.dispatch(EmptyReportPermission());
        this.dispatch(FillReportPermission(menu));
    }
    if(menu.moduleName==='Leave Management'){
      this.dispatch(EmptyLeavePermission());
        this.dispatch(FillLeavePermission(menu));
    }
    if(menu.moduleName==='Notifications'){
      this.dispatch(EmptyNotificationPermission());
        this.dispatch(FillNotificationPermission(menu));
    }
    if(menu.moduleName==='Brownie Point'){
      this.dispatch(EmptyBrowniePermission());
        this.dispatch(FillBrowniePermission(menu));
    }
    if(menu.moduleName==='Escalation'){
      this.dispatch(EmptyEscalationPermission());
        this.dispatch(FillEscalationPermission(menu));
    }
    if(menu.moduleName==='Risk Alarm'){
      this.dispatch(EmptyRiskAlarmPermission());
      this.dispatch(FillRiskAlarmPermission(menu));
    } 
    if(menu.moduleName==='Inventory Management'){
      this.dispatch(EmptyInventoryPermission());
      this.dispatch(FillInventoryPermission(menu));
    } 
    if(menu.moduleName==='Seasia Event'){
      this.dispatch(EmptySeasiagalaPermission());
      this.dispatch(FillSeasiagalaPermission(menu));
    }
    if(menu.moduleName==='Prism'){
      this.dispatch(EmptyPrismPermission());
      this.dispatch(FillPrismPermission(menu));
    }
    if(menu.moduleName==='Meetings'){
      this.dispatch(EmptyMeetingsPermission());
      this.dispatch(FillMeetingsPermission(menu));
    }
    if(menu.moduleName==='ProjectPortfolio'){
      this.dispatch(EmptyProjectPermission());
      this.dispatch(FillProjectPortfolioPermission(menu));
    }
    if(menu.moduleName==='Learning & Session'){
      this.dispatch(EmptyLearningSessionPermission());
      this.dispatch(FillLearningSessionPermission(menu));
    }
    if(menu.moduleName==='DomainPortfolio'){
      this.dispatch(EmptyDomainPortfolioPermission());
      this.dispatch(FillDomainPortfolioPermission(menu));
    }
  }
}