import React, { useState, useEffect } from "react";
import { Stack, Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "material-react-toastify";
import { boardDashboardServive } from "../../service/BoardDashboardServive";
import { Formik, getIn } from "formik";
import { Autocomplete, Button, DialogActions } from "@mui/material";
import * as Yup from "yup";
import AppConstants from "../../config/AppConstants";
import moment from "moment";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import { projectManagementServive } from "../../service/project-management-service";

interface Sprint {
  sprintName: string;
  goal: string;
  duration: string;
  startDate: any;
  endDate: any;
  projectId: string;
  id: string;
  milestoneId:any
}

export default function CreateSprint(props: {
  selectedProjectId: any;
  handleSprintClose: any;
  modalOpen: any;
  bindSprint: any;
  selectedSprintId: any;
  SelectSprint: any;
  spritList: any;
  setSpritList: any;
  projectDetail:any
  // getSprintDataById(id)
}) {
  const [disableContinue, setDisableContinue] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [userIdHook, setUserIdHook] = React.useState();
  const [currency, setCurrency] = React.useState("EUR");

  //  let _sprintInfo = new sprintInfo();
  // let usermodel = new sprintModel(_sprintInfo)
  // const [userDataHook, setUserDataHook] = React.useState(usermodel);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setCurrency(event.target.value);
  // };

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());
  const onChange = (event: React.SyntheticEvent, newValue: null) => {
    setDateValue(newValue);
  };

  const [sprintOpen, setsprintOpen] = React.useState(false);
  const [MinDate, setMinDate] = React.useState(moment());
  useEffect(() => {
    setErrorMessage("");
    setsprintOpen(props.modalOpen);
    getMileStoneData(props.selectedProjectId);
    setDuration("1 day");
    if (props.selectedSprintId) {
      getSprintDataById(props.selectedSprintId);
    }
  }, []);


  const mindate = new Date().getFullYear().toString();

  // const getDate = (dateObject: any) => {
  //   let date = new Date(dateObject).toISOString();
  //   return  date?.slice(0, 10) + 'T00:00:00Z';
  // }

  const addSprint = async (sprintInfo: any) => {
    try {
      let obj: Sprint = {
        id: id,
        sprintName: sprintName,
        goal: goal,
        duration: duration,
        startDate: startDate,
        endDate: endDate,
        projectId: props.selectedProjectId,
        milestoneId:selectedMilestoneId,
      };
      obj.endDate = getDate1(obj.endDate);
      obj.startDate = getDate1(obj.startDate);
      await boardDashboardServive.addSprint(obj).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          setsprintOpen(false);
          toast.success("Sprint added successfully.", {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });
          props.handleSprintClose();
          await boardDashboardServive.getSprintById(response.data).then((response1: any) => {
            if (response1.data.isError) {
            } else {
              let ObjSprintData = response1?.data;
              var sprints = [...props.spritList];
              sprints.push(ObjSprintData)
              props.setSpritList(sprints);
            }
          });
          //props.bindSprint(props.selectedProjectId);
        }
      });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),

        });
      });
    }
  };


  const getSprintDataById = async (id: any,) => {
    await boardDashboardServive.getSprintById(id).then((response: any) => {
      if (response.data.isError) {
      } else {
        setId(response?.data.id);
        setSprintName(response?.data.sprintName);
        setGoal(response?.data.goal);
        setEndDate(response?.data.endDate);
        setStartDate(response.data.startDate)
        setDuration(response.data.duration)
        setsprintOpen(true);
        setMinDate(moment(response.data.startDate));
        setSelectedMilestoneId(response?.data.milestoneId)
        let SelectedMileDetail= props.projectDetail?.lstMilestoneinfo.filter((mile:any)=>(mile.milstoneId == response?.data.milestoneId))
        setSelectedMilestoneEndDate(SelectedMileDetail[0].endDate)
        setSelectedMilestoneStartDate(SelectedMileDetail[0].startDate)
        // console.log("moment(response.data.startDate)",moment(response.data.startDate));
        // props.selectedSprintId(id);
      }
    });
  };

  const getMileStoneData = async (id: any,) => {
    try{
    await projectManagementServive
      .getMilestonesWithoutSprint(id).then((response: any) => {
        if (response.data.isError) {
        } else {
          const filteredMilestones = response.data.filter((item: any) => item.status !== 3 && item.status !== 4);
          setMileStonesList(filteredMilestones)
        
        }
      });}
      catch (ex: any) {
        ex.data.Error.map((err: any, idx: any) => {
          toast.warning(err, {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
  
          });
        });
      }
  };
  const getDate1 = (dateObject: any) => {
    
    let date = new Date(dateObject);
    // Adjust for timezone offset
    let timezoneOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    date = new Date(date.getTime() - timezoneOffset);
    let abc =date.toISOString().slice(0, 10) + 'T00:00:00Z';
    return abc
  };

  const editSprintDetails = async (values: any) => {
    try {
      let obj: Sprint = {
        id: id,
        sprintName: values.sprintName,
        goal: goal,
        duration: duration,
        startDate: startDate,
        endDate: endDate,
        projectId: props.selectedProjectId,
        milestoneId:"",
      }
      
      obj.endDate = getDate1(obj.endDate);
      obj.startDate = getDate1(obj.startDate);
 
      await boardDashboardServive.updateSprint(obj).then(async (response: any) => {
        if (response.data.isError) {
        } else toast.success("Sprint Updated successfully", {
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),
        });
        props.handleSprintClose();
        //props.bindSprint(props.selectedProjectId,props.selectedSprintId);
        setId("");
        setsprintOpen(false);
        await boardDashboardServive.getSprintById(id).then((response1: any) => {
          if (response1.data.isError) {
          } else {
            let ObjSprintData = response1?.data;
            let sprints = [...props.spritList];
            let obj = sprints.find((s: any) => s.id === id);
            //console.log("Obj", obj);
           // console.log("sprints", sprints);
            //console.log("ObjSprintData", ObjSprintData);
            obj.duration = ObjSprintData.duration;
            obj.endDate = ObjSprintData.endDate;
            obj.goal = ObjSprintData.goal;
            obj.sprintName = ObjSprintData.sprintName;
            obj.startDate = ObjSprintData.startDate;
            obj.status = ObjSprintData.status;
            props.setSpritList(sprints);
          }
        });
      });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err,
          {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });
      });
    }
  };




  let obj: Sprint = {
    id: "",
    sprintName: "",
    goal: "",
    duration: "",
    startDate: "",
    endDate: "",
    projectId: "",
    milestoneId:""
  };
  const [formValues, setFormValues] = React.useState<Sprint>(obj);
  const [sprintName, setSprintName] = React.useState(formValues.sprintName);
  const [goal, setGoal] = React.useState(formValues?.goal);
  const [duration, setDuration] = React.useState("");
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [id, setId] = useState("");
  const [mileStonesList, setMileStonesList] = useState<any>([]);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState("");
  const [projectId, setprojectId] = React.useState(formValues?.projectId);
  const [selectedMilestoneStartDate, setSelectedMilestoneStartDate] = React.useState<any>();
  const [selectedMilestoneEndDate, setSelectedMilestoneEndDate] = React.useState<any>();
  const handledateduration = (value: any) => {
    if (value === "1 week") {
      var result = new Date(startDate);
      result.setDate(result.getDate() + 7);
      setEndDate(result);
    } else if (value === "2 weeks") {
      var result1 = new Date(startDate);
      result1.setDate(result1.getDate() + 14);
      setEndDate(result1);
    } else if (value === "3 weeks") {
      var result2 = new Date(startDate);
      result2.setDate(result2.getDate() + 21);
      setEndDate(result2);
    } else if (value === "4 weeks") {
      var result3 = new Date(startDate);
      result3.setDate(result3.getDate() + 28);
      setEndDate(result3);
    }
    setDuration(value);
  };

  const SetDateAccordingto = (value: any) => {

    if (new Date(value) >= new Date(endDate)) {
      var result = new Date(value);
      result.setDate(result.getDate() + 1);
      setEndDate(result);
      //getSprintByDate(sDate,result);
    }
  }

  const handledateDifference = (value: any) => {
    if (duration === "1 week") {
      var result = new Date(value);
      result.setDate(result.getDate() + 7);
      setEndDate(result);
    } else if (duration === "2 weeks") {
      var result1 = new Date(value);
      result1.setDate(result1.getDate() + 14);
      setEndDate(result1);
    } else if (duration === "3 weeks") {
      var result2 = new Date(value);
      result2.setDate(result2.getDate() + 21);
      setEndDate(result2);
    } else if (duration === "4 weeks") {
      var result3 = new Date(value);
      result3.setDate(result3.getDate() + 28);
      setEndDate(result3);
    }
  };

  function dateDiffInDays(a: any, b: any) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  const handledateDifferencestr = (value: any) => {
    const stdate = new Date(value);
    const edate = new Date(endDate);

    const diffTime = dateDiffInDays(stdate, edate);
    if (diffTime === 0) {
      const diff = 1 + " day";
      setDuration(diff);
    } else if (diffTime === 1) {
      const diff = diffTime + 1 + " day";
      setDuration(diff);
    } else {
      if (diffTime == 6) {
        const diff = diffTime + 1 + " days(1 Week)";
        setDuration(diff);
      } else if (diffTime == 13) {
        const diff = diffTime + 1 + " days(2 Weeks)";
        setDuration(diff);
      } else if (diffTime == 20) {
        const diff = diffTime + 1 + " days(3 Weeks)";
        setDuration(diff);
      } else if (diffTime == 27) {
        const diff = diffTime + 1 + " days(4 Weeks)";
        setDuration(diff);
      } else {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      }
    }
  };

  const handledateDifferenceend = (value: any) => {
    const stdate = new Date(startDate);
    const edate = new Date(value);
    const diffTime = dateDiffInDays(stdate, edate);
    if (diffTime === 0) {
      const diff = 1 + " day";
      setDuration(diff);
    } else if (diffTime === 1) {
      const diff = diffTime + 1 + " day";
      setDuration(diff);
    } else {
      if (diffTime == 6) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else if (diffTime == 13) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else if (diffTime == 20) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else if (diffTime == 27) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      }
    }
  };
  const handledateDifferenceStart = (value: any,value2:any) => {
    if(!value&& !value2)
    {setDuration("0 days")
    return;
  }
    const stdate = new Date(value);
    const edate = value2 ?new Date(value2):new Date(endDate);
    const diffTime = dateDiffInDays(stdate, edate);
    if (diffTime === 0) {
      const diff = 1 + " day";
      setDuration(diff);
    } else if (diffTime === 1) {
      const diff = diffTime + 1 + " day";
      setDuration(diff);
    } else {
      if (diffTime == 6) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else if (diffTime == 13) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else if (diffTime == 20) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else if (diffTime == 27) {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      } else {
        const diff = diffTime + 1 + " days";
        setDuration(diff);
      }
    }
  };

  const [buttonDisable, setButtonDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // /^[0-9a-z\s\-_?]+$/
  const fieldValidationSchema = Yup.object().shape({
    sprintName: Yup.string()
      .matches(
        AppConstants.MatchRegExp.alphaNumericWithSpace,
        "Sprint name can only contain alphanumeric."
      )
      .required("Sprint Name is required"),
    goal: Yup.string()
      .max(20, "Goal can only contain 20 Alphabets")
      .matches(
        AppConstants.MatchRegExp.alphaNumeric,
        "Goal can only contain alphabets."
      ),
    //duration: Yup.string().required("Duration is required"),
    // duration: Yup.string()
    //   .matches(
    //     AppConstants.MatchRegExp.alphaNumericWithSpace,
    //     // "Durationcan can only contain alphanumeric."
    //   ),

    milestoneId: Yup.string()
      .required("Sprint Name is required"),

  });

  return (
    <Formik
      initialValues={{
        sprintName: sprintName,
        goal: goal,
        duration: duration,
        startDate: startDate,
        endDate: endDate,
        projectId: projectId,
        id: id,
        milestoneId: selectedMilestoneId
      }}
      enableReinitialize
      validationSchema={fieldValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (values.endDate <= values.startDate) {
            toast.warning(
              "Start date must be less than end date or should not be equal"
              , {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
              });
            return false;
          }
          setErrorMessage("");

          if (id && id.length > 0) {
            // values.noticeId=noticeId
            await editSprintDetails(values);
          } else {
            await addSprint(values);
          }

        } catch (ex: any) {
          setButtonDisable(false)
          if (ex.status == 400) {
            console.error("ex:", ex);
            ex.data.Error.map((error: any, idx: any) => {
              setErrorMessage(error);
              toast.error(error);
            });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ my: 3 }}>
            <TextField
              id="outlined-basic"
              error={Boolean(
                //getIn(touched, 'sprintName') &&
                getIn(errors, "sprintName")
              )}
              helperText={
                getIn(touched, "sprintName") && getIn(errors, "sprintName")
              }
              required
              label="Sprint Name"
              variant="outlined"
              fullWidth
              name="sprintName"
              value={sprintName}
              onBlur={handleBlur}
              onChange={(e) => {
                values.sprintName = e.currentTarget.value;
                setSprintName(e.currentTarget.value);
              }}
            />
            <TextField
              id="outlined-basic"
              //  error={Boolean(touched.goal && errors.goal)}
              //  helperText={touched.goal && errors.goal}
              error={Boolean(getIn(touched, "goal") && getIn(errors, "goal"))}
              helperText={getIn(touched, "goal") && getIn(errors, "goal")}
              label="Goal"
              variant="outlined"
              fullWidth
              name="goal"
              value={goal}
              type="text"
              onBlur={handleBlur}
              // onChange={handleChange}
              onChange={(e) => {
                values.goal = e.currentTarget.value;
                setGoal(e.currentTarget.value);
              }}
            />

            <TextField
              id="outlined-basic"
              //  error={Boolean(touched.goal && errors.goal)}
              //  helperText={touched.goal && errors.goal}
              // error={Boolean(
              //   getIn(touched, "duration") && getIn(errors, "duration")
              // )}
              // helperText={
              //   getIn(touched, "duration") && getIn(errors, "duration")
              // }
              // required
              label="Duration"
              variant="outlined"
              fullWidth
              name="duration"
              value={duration}
              type="text"
              onBlur={handleBlur}
              // onChange={handleChange}
              onChange={(e) => {
                values.duration = e.currentTarget.value;

                setDuration(e.currentTarget.value);
              }}
              onKeyDown={(e: any) => {
                e.preventDefault();
              }}
            />

          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              value={mileStonesList?.find((option: any) => option.id == selectedMilestoneId) ?? null}
              fullWidth
              id="milestoneId"
              sx={{ marginTop: "18px !important" }}
              options={mileStonesList}
              getOptionLabel={(option: any) => option?.milestoneName}
              disabled={!!id}
              onChange={(event, value) => {
                setSelectedMilestoneId(value?.id ?? "");
                setSelectedMilestoneEndDate(value?.updatedEndDate?value?.updatedEndDate:value?.endDate ??"");
                setSelectedMilestoneStartDate(value?.startDate ?? "");
                  setStartDate(value?.startDate ??"")
                  setEndDate(value?.endDate ??"")
                  //console.log(new Date(value?.endDate))
                  //console.log(new Date(value?.startDate))
                  handledateDifferenceStart(value?.startDate??"",value?.endDate??"")
                  
              }}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: true,
                      options: {
                        altBoundary: true,
                        flipVariations: true,
                        altAxis: true,
                        padding: 8,
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ],
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="milestoneId"
                  error={Boolean(
                    getIn(touched, "milestoneId") &&
                    getIn(errors, "milestoneId")
                  )}
                  helperText={
                    getIn(touched, "milestoneId") &&
                    getIn(errors, "milestoneId")
                  }
                  required
                  value={selectedMilestoneId}
                  name="milestoneId"
                  label="Select Milestone"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                borderRadius="semi-square"
                style={{ width: '40%' }}
                minDate={new Date(selectedMilestoneStartDate)}
                maxDate={new Date(new Date(endDate).getTime() - 24 * 60 * 60 * 1000)}
                disabled={!startDate || !!id}
                label="Start Date"
                value={startDate}
                onChange={(newValue: any) => {
                  
                  handledateDifferenceStart(newValue,"")
                  values.startDate = getDate(newValue);
                  setStartDate(newValue);
                  //handledateDifferencestr(newValue);
                  setErrorMessage("");
                  handledateDifference(newValue);
                  SetDateAccordingto(newValue);
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                borderRadius="semi-square"
                style={{ width: '40%' }}
                minDate={new Date(selectedMilestoneStartDate)}
                maxDate={
                  new Date(selectedMilestoneEndDate)
                }
                disabled={!endDate}
                label="End Date"
                value={endDate}
                onChange={(newValue: any) => {
                  values.endDate = getDate(newValue);
                  setEndDate(newValue);
                  handledateDifferenceend(newValue);
                }}
              />
            </LocalizationProvider>
          </Stack>
          <Box sx={{ mt: 3 }}>
            {/* <Button type="submit" variant="text" size="small" sx={{textTransform: 'capitalize'}}>
              Create another sprint{" "}
            </Button> */}
          </Box>
          <Stack direction="row" justifyContent="flex-end">
            <DialogActions sx={{ p: 0, mt: 3 }}>
              <Button onClick={props.handleSprintClose} variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={buttonDisable} >
                {props.selectedSprintId ? "Update" : "Submit"}
              </Button>
            </DialogActions>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
