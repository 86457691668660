import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { TablePagination, Typography, } from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import NotFound from "../../components/styledComponent/NotFound";
import { useSelector } from "react-redux";
import InfoPopover from "../../../src/components/CommonInfoComponent";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { chessBoardService } from "../../service/ChessBoard/ChessBoardService";



const Notificationstyle = {
    topbar: {
        flexDirection: "row",
        flexWrap: 'wrap',
        justifyContent: "space-between",
        alignItems: "center",
        pb: 3, mt: 2
    },
    topright: {
        width: { xs: '100%', sm: 'auto' },
        alignItems: { xs: 'strech', sm: "center" },
        justifyContent: { xs: 'strech', sm: "center" },
    },
    searchbarstyle: {
        "& .searcg-field": {
            width: { xs: "100%", md: "auto" },
        },
        width: { xs: "100%", md: "auto" },
    }


}
const styles: any = {
    EventInfoCard: {
        border: "1px solid #EEEEEE",
        padding: "18px 14px",
        bordeRadius: "5px",
        cursor: "pointer",
        height: "100%",
        "&:hover": {
            boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
        },
    },
    innerSpace: {
        // padding: theme.spacing(2),
        fontWeight: 500,
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
    },

    EventName: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#1da7ff",
        background: "#f0f9ff",
        display:" inline-block",
        padding:" 6px 10px",
        borderRadius: "8px",
    },
    EventId: {
        letterSpacing: "0.1px",
        textTransform: "capitalize",
        fontWeight: "500",
        fontSize: "14px",
        color: "#232360",
    },
    EventDesc: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: " #616161",
        marginTop: "8px",
    },
    EventDate: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: " #616161",
        marginLeft: "10px",
    },
    LineClamp: {
        marginTop: "15px",
        whiteSpace: "nowrap",
        width: "90%",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    DialogTitle: {
        fontWeight: 600,
        fontSize: "26px",
        lineHeight: " 39px",
        color: theme.palette.primary.dark,
    },
    Schedulefor: {
        fontWeight: 400,
        fontSize: "16.2314px",
        lineHeight: "19px",
        color: " #212121",
        margin: 0,
    },
    InnerHtml: {
        marginBottom: "12px",
        "& p": {
            margin: 0,
            textAlign: "center",
            fontSize: "24px",
            color: theme.palette.primary.dark,
        },
    },
    TitleLabel: {
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#232360",
    },
    TitleValue: {
        fontWeight: 600,
        fontSize: " 18px",
        lineHeight: "21px",
        color: " #424242",
        marginLeft: "8px",
    },

};


export default function ChessParticipants(modalOpen: any) {
    const [ChessParticipants, setChessParticipants] = useState<any>([]);
 
    ////////Pagination Programming
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const pageCount = Math.ceil(ChessParticipants.length / rowsPerPage);
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    ////////over

    
 
    useEffect(() => {          
        getChessParticipantsList();
    }, []);


    const getChessParticipantsList = async () => {
      
        await chessBoardService.getChessParticipants().then((response: any) => {
           
            if (response.data.isError) {
            } else {
                setChessParticipants(response?.data);
            }
        });
    };


    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
                <Link color="inherit" to="/">
                    Dashboard
                </Link>
               
            </Breadcrumbs>
            <Paper elevation={0} sx={{ p: 3 }}>

                <Stack
                    sx={Notificationstyle.topbar}
                    spacing={{ xs: 0, sm: 2 }}
                >
                    <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                        Chess Participants List
                    </Typography>
                    <Box>

                        <InfoPopover onInput={"Events"} />
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />
                    <Stack direction={{ xs: 'column', sm: "row" }} sx={Notificationstyle.topright} spacing={2} >
                        <Stack direction="row" alignItems="center" spacing={2}>

                        </Stack>

                        <Button
                            variant="contained"
                            component={Link}
                            to="/ChessBoard"
                            color="primary"
                        >
                            ♔ Matches And Results ♕
                        </Button>                       

                    </Stack>
                </Stack>
             

{ChessParticipants && ChessParticipants.length > 0 ? (
    <Grid container spacing={2}>
       {(rowsPerPage > 0
                                ? ChessParticipants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : ChessParticipants
                            ).map((ChessParticipant:any, idx:any) => (
            <Grid item xs={12} lg={3} key={ChessParticipant.groupName}>
                <Paper
                    elevation={0}
                    sx={[styles.EventInfoCard]}
                   
                >
                    <Typography
                        variant="h5"
                        sx={[styles.EventName]}
                    >
                        Group{" "}{ChessParticipant?.groupName}
                    </Typography>

                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Department</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {ChessParticipant.member && ChessParticipant.member.map((data: any, index: any) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{data?.userName}</TableCell>
                                    <TableCell component="th" scope="row">{data?.department}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        ))}
    </Grid>
) : (
    <NotFound NotfoundText="No Result Found" />
)}

                <TablePagination
                    labelRowsPerPage="Participants per page"
                    rowsPerPageOptions={[8, 16, 24, 32, 40, 48]}
                    component="div"
                    count={ChessParticipants?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    labelDisplayedRows={() => `Total: ${ChessParticipants?.length}  Page ${page + 1} of ${pageCount}`}
                />
            </Paper>

        </>
    );
}
