import React, { useState, useEffect } from "react";
import {useParams } from "react-router-dom";
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Modal,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { milestoneManagementService } from "../../service/milestone-management-service";
import { toast } from "material-react-toastify";
import * as Yup from "yup";
import { Formik, getIn } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { projectManagementServive } from "../../service/project-management-service";
import AppConstants from "../../config/AppConstants";
import { boardDashboardServive } from "../../service/BoardDashboardServive";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import Masteralltop from "../MasterData/mastersetupstyle";
import moment from "moment";

interface Milestone {
  name: string;
  costing: string;
  effortsInhours: string;
  endDate: Date;
  startDate: Date;
}

interface IprojectDetail {
  id: string;
  projectName: string;
  projectDetails: {
    pin: string;
    keyWords: string;
    channelId: string;
    costing: string;
    pinEfforts: string;
    pinAmount: string;
    projectDescription: string;
    wbsPath: string;
  };
  colGetClientModel: {
    id: string;
    email: string;
    skypeId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    country: string;
    companyName: string;
    mobileNumber: string;
    code: string;
    alterNateNumber: string;
    companyDescription: string;
  };
  pointOfContacts: [
    {
      email: string;
      skypeId: string;
      firstName: string;
      lastName: string;
      middleName: string;
      mobileNumber: string;
      code: string;
      alterNateNumber: string;
    }
  ];
  projectOwnerId: string;
  projectDepartment: {
    departmentId: string;
  };
  paymentDetails: {
    costingType: string;
    upfrontPayment: string;
    currency: string;
    billingType: string;
    ratePerHour: string;
    departmentRatePerHours: string;
  };
  skillSets: [
    {
      skillId: string;
      name: string;
      level: string;
    }
  ];
  status: boolean;
  createdDate: Date;
}

const dummy = [
  {
    value: "INR",
    label: "₹",
    retePerHour: "+91",
    country: "India",
  },
  {
    value: "USD",
    label: "$",
    retePerHour: "+1",
    country: "USA",
  },
  {
    value: "NZD",
    label: "$",
    retePerHour: "+64",
    country: "New Zealand",
  },
  {
    value: "AUD",
    label: "A$",
    retePerHour: "+61",
    country: "Australia",
  },
  {
    value: "Pound Sterling",
    label: "£",
    retePerHour: "+44",
    country: "UK",
  },
  {
    value: "Euro",
    label: "€",
    retePerHour: "+33",
    country: "France",
  },
];

export default function CreateMilestone(props: any) {
  const { handleMileClose, projectId, spritList, getProjectDetails } = props;
  const { id } = useParams();
  const [milestoneForm, setMilestoneForm] = React.useState({
    name: "",
    costing: "",
    effortsInhours: "",
    startDate: null,
    endDate: null,
    sprintIds: [],
  });

  const [opend, setOpend] = React.useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [milestoneData, setMilestoneData] = React.useState<any>(null);
  const [sprintName, setsprintname] = React.useState("");
  const [sprintIds, setSprintIds] = React.useState("");
  // const [projectDetailHook, setProjectDetailHook] = useState<[]>();
  const [projectDetailHook, setProjectDetailHook] = useState<IprojectDetail>();
  const handleChange = (sprintName: any, sprintIds: any) => {

    setsprintname(sprintName);
    setSprintIds(sprintIds);

    setMilestoneName((name) => ({
      ...sprintIds,
      [name]: sprintName,
    }));

  };
  const handleClose = () => {
    setOpend(false);
  };
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());
  const onChange = (event: React.SyntheticEvent, newValue: null) => {
    setDateValue(newValue);
  };

  const handleDelete = (id: string) => {

    setMilestoneForm((prevState) => ({
      ...prevState,
      sprintIds: milestoneForm?.sprintIds.filter((i: any) => i?.id !== id),
    }));
  };

  const handleMileSubmit = (values: any) => {
    if (projectId !== "") {
      setButtonDisable(true);
      let array: any[] = [];
      if (values.selectSprint.length > 0) {
        values.selectSprint.map((a: any) => {
          array.push({ id: a.id });
          setButtonDisable(false);
        });
      }
      milestoneManagementService
        .createMilestone({
          sprintIds: array,
          milestoneName: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
          efforts: values.effortsInhours,
          costing: values.costing,
          projectId: projectId,
          // currency: currency,
        })
        .then((response: any) => {
          if (response?.data?.isError) {
            toast.error("Something Went Wrong, Please try again.");
          } else {
            toast.success("Milestone create successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            handleMileClose();
            getProjectDetails(projectId);
            setMilestoneData(null);
          }
        })
        .catch((error) => {
          if (error.data.Error.length > 0)
            toast.error(error.data.Error[0], {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
          return error;
        });

    }
  };

  let obj: Milestone = {
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    costing: "",
    effortsInhours: "",
  };
  const [formValues, setFormValues] = React.useState<Milestone>(obj);
  const [name, setMilestoneName] = React.useState("");
  const [selectSprint, setSelectSprint] = useState<any>([]);

  const [costing, setCosting] = React.useState("");
  const [projectCosting, setprojectCosting] = React.useState("");

  const [currency, setcurrency] = React.useState("");
  const [startDate, setStartDate] = React.useState<any>(getDate(new Date()));
  let today = new Date();
  let tomorrow = today.setDate(today.getDate() + 1);
  const [endDate, setEndDate] = React.useState<any>(getDate(new Date(tomorrow)));
  const [effortsInhours, setEffortsInHours] = React.useState(
    formValues?.effortsInhours
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [skillsInters, setskillsInters] = useState<skillsInter[]>([]);
  const [skillset, setskillset] = React.useState([]);
  const setSkillSetsfromDropdown = (e: any) => {
    var myArray: any = [];
    e.forEach((elem: any) => {
      let obj = { skillId: elem.id ?? elem.skillId, name: elem.name };
      myArray.push(obj);
    });
    setskillset(myArray);
  };

  const getSkillSetsDetails = async () => {
    var input = {
      status: "",
      search: "",
    };
    await projectManagementServive
      .getSkillSetAll(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setskillsInters(response?.data);
        }
      });
  };
  const [spritListByDate, setspritListByDate] = useState([]);
  const getSprintByDate = async (startDate: any, endDate: any) => {
    await boardDashboardServive
      .getAllSprintByProjectId({
        projectId: projectId,
        startDate: startDate,
        endDate: endDate
      })
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setspritListByDate(response?.data ?? []);
        }
      });
  };

  useEffect(() => {
    getSkillSetsDetails();
    getProjectsDetails();
    let today = new Date(); let tomorrow = new Date();
    getSprintByDate(today, new Date(tomorrow.setDate(today.getDate() + 1)));
  }, []);

  interface skillsInter {
    id: string;
    name: string;
  }

  var dropdown_syle = {
    searchBox: {
      lineHeight: "30px",
      borderColor: "#EEEEEE",
    },
    chip: {
      marginBottom: 0,
    },
  };
  const getProjectsDetails = async () => {
    try {
      await projectManagementServive
        .getProjectDetailsById(id)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            debugger
            setprojectCosting(response.data.paymentDetails.currency);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handledateDifference = (sDate: any, eDate: any, type: any) => {
    if (type == "startDate") {
      if (new Date(sDate) >= new Date(eDate)) {
        var result = new Date(sDate);
        result.setDate(result.getDate() + 1);
        // setEndDate(result); 
        getSprintByDate(sDate, result);
      }
      else {
        getSprintByDate(sDate, eDate);
      }
    }
    else {
      if (new Date(sDate) >= new Date(eDate)) {
        var now = new Date();
        var result = new Date(eDate);
        if (new Date(now) >= new Date(result)) {
          setStartDate(new Date());
          var eDate1 = now.setDate(now.getDate() + 1)
          setEndDate(new Date());
          getSprintByDate(new Date(), new Date(eDate1));
        }
        else {
          result.setDate(result.getDate() - 1);
          // setStartDate(result);
          // setEndDate(result);
          getSprintByDate(result, eDate);
        }
      }
      else {
        getSprintByDate(sDate, eDate);
      }
    }
  };

  const fieldValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Milestone Name is Required")
      //.matches(/^([A-Za-z\s]*)$/g, "Milestone Name can only contain Alphabets ")
    ,
    costing: Yup.string()
      // .matches(
      //   AppConstants.MatchRegExp.intRegExp,
      //   "Costing must be digits only"
      //  )
      .required("Costing is required"),
    //  currency: Yup.string().required("Currency is required"),

    effortsInhours: Yup.string()
      .matches(
        AppConstants.MatchRegExp.intRegExp,
        "Efforts must be digits only"
      ).required("Efforts in hours is required"),
    selectSprint: Yup.array(),
    //   .required("Efforts in hours is required"),
    // selectSprint: Yup.array().min(1, "Select at least one sprint."),
    //selectSprint: Yup.array().test({message:"abc",test:selectSprint=>selectSprint?.length==0}).required("abc required")
  });
  return (
    <><Formik
      initialValues={{
        name: name,
        effortsInhours: effortsInhours,
        costing: costing,
        startDate: startDate,
        endDate: endDate,
        selectSprint: selectSprint,
        // currency: currency,
      }}
      validationSchema={fieldValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          debugger
          if (values.endDate &&values.startDate &&values.endDate <= values.startDate) 
            {
            toast.warning("Start date can't be equal to and greater than the end date", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            return false;
          }
          setErrorMessage("");
          setMilestoneData(values)
          setOpend(true);
          
        } catch (ex: any) {
          if (ex.status == 400) {

            ex.data.Error.map((error: any, idx: any) => {
              setErrorMessage(error);
              toast.error(error);
            });
          }
        }
      } }
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 0 }}>
            {/* <Typography
              variant="h5"
              gutterBottom
              component="h5"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              Create Milestone
            </Typography> */}

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ my: 3 }}>
              <TextField
                // error={Boolean(getIn(errors, "name"))}
                // helperText={getIn(touched, "name") && getIn(errors, "name")}
                error={Boolean(getIn(errors, "name") && getIn(touched, "name"))}
                helperText={getIn(touched, "name") && getIn(errors, "name")}
                required
                id="outlined-basic"
                label="Milestone Name"
                name="name"
                type="text"

                value={name}
                onBlur={handleBlur}
                onChange={(e) => {
                  values.name = e.currentTarget.value;
                  setMilestoneName(e.currentTarget.value);
                } } />
              <TextField
                error={Boolean(
                  getIn(errors, "effortsInhours") &&
                  getIn(touched, "effortsInhours")
                )}
                helperText={getIn(touched, "effortsInhours") &&
                  getIn(errors, "effortsInhours")}
                required
                id="outlined-basic"
                name="effortsInhours"

                label="Efforts in hours"
                type="text"
                value={effortsInhours}
                onBlur={handleBlur}
                onChange={(e) => {
                  let abc = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").substring(0, 7);
                  values.effortsInhours = abc;
                  setEffortsInHours(abc);
                } } />
              <Stack direction='row'>
                <TextField
                  // required
                  disabled
                  id="outlined-basic"
                  name="currency"
                  sx={{ maxWidth: '140px', "& fieldset": { borderRight: 'none' }, }}

                  value={projectCosting}
                  onBlur={handleBlur} />
                <TextField
                  required
                  error={Boolean(
                    getIn(errors, "costing") && getIn(touched, "costing")
                  )}
                  helperText={getIn(touched, "costing") && getIn(errors, "costing")}
                  id="outlined-basic"
                  label="Costing"
                  name="costing"
                  type="text"
                  sx={{ border: 'none' }}

                  value={costing}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    let abc = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").substring(0, 8);
                    values.costing = abc;
                    setCosting(abc);
                  } } />
              </Stack>

            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {/* { <Grid item xs lg={6}>
              <Multiselect
                selectedValues={skillset}
                displayValue="name"
                onKeyPressFn={function setSkillSetsfromDropdown() { }}
                onRemove={setSkillSetsfromDropdown}
                onSearch={function setSkillSetsfromDropdown() { }}
                onSelect={setSkillSetsfromDropdown}
                placeholder="Skill Set"
                options={skillsInters}
                style={dropdown_syle}
              />
            </Grid> } */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // disablePast
                  style={{ width: '40%' }}
                  borderRadius="semi-square"
                  minDate={moment().subtract(0, "years").toDate()}
                  maxDate={new Date(
                    new Date().getFullYear() + 2,
                    11,
                    31
                  )}
                  //  minDate={moment().subtract(0, "years",)}
                  //  maxDate={moment().add(2,"years")}
                  label="Start Date"
                  // openTo="year"
                  // views={["year", "month", "day"]}
                  // inputFormat="DD-MMM-YYYY"
                  value={startDate}
                  onChange={(newValue: any) => {
                    values.startDate = getDate(newValue);
                    setStartDate(newValue);
                    handledateDifference(newValue, endDate, "startDate");
                  } } />
              </LocalizationProvider>{" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // disablePast
                  style={{ width: '40%' }}
                  borderRadius="semi-square"
                  minDate={moment().subtract(0, "years").toDate()}
                  maxDate={new Date(
                    new Date().getFullYear() + 2,
                    11,
                    31
                  )}
                  // minDate={moment().subtract(0, "years",)}
                  // maxDate={moment().add(2,"years")}
                  label="End Date"
                  // openTo="year"
                  // views={["year", "month", "day"]}
                  // inputFormat="DD-MMM-YYYY"
                  value={endDate}
                  onChange={(newValue: any) => {
                    values.endDate = getDate(newValue);
                    setEndDate(newValue);
                    handledateDifference(startDate, newValue, "endDate");
                  } } />
              </LocalizationProvider>
              {/* <TextField
                error={Boolean(
                  getIn(touched, "currency") &&
                  getIn(errors, "currency")
                )}
                helperText={
                  getIn(touched, "currency") &&
                  getIn(errors, "currency")
                }
                required
                id="outlined-basic"
                label="Select Currency"
                value={currency}
                name="currency"
               // disabled={costing != "fixed"}
                variant="outlined"
                select
                fullWidth
              >
                {dummy.map((option, idx) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={(event) => {
                      values.currency = dummy[idx].value;
                      setcurrency(dummy[idx].value);
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>  */}

            </Stack>
          </DialogContent>
          <DialogActions sx={{ pb: 3, px: 3 }}>
            <Button variant="outlined" onClick={handleMileClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={buttonDisable}>
              Create{" "}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
    
    <Modal
        open={opend}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Masteralltop.actionpopup}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            {/* <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
             
            </Typography> */}
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2,  fontWeight:550 }}
            >
              Make sure a milestone cannot be amended later because once it is Created, it cannot be changed. 
              Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={()=>{setOpend(false)}}>
                Cancel
              </Button>
              <Button variant="contained" onClick={()=>{handleMileSubmit(milestoneData)}} >
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal></>
  );
}
