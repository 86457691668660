import { http } from "./http-common";
class BoardDashboardServive {
  getProjectBoardWorkById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetProjectBoardWorkById?Id=${id}`);
  };

  getAllTicketForBoardFiltersAsync = async (param: any): Promise<any> => {
    return await http.post<any>(`/v1/Ticket/GetAllTicketForBoardFiltersAsync`, param);
  };

  getAllTicketUpdateStatus = async (param: any): Promise<any> => {
    return await http.put<any>(`/v1/Ticket/UpdateStatus`, param);
  };

  getAllSprintByProjectId = async (param: any): Promise<any> => {
    return await http.post<any>(`/Sprint/GetAllSprint/Sprint/GetAll`, param);
  };

  getAllProjectId = async (): Promise<any> => {
    return await http.get<any>(`/v1/Project/GetAllProjectBasedOnResourceId`);
  };
  addSprint = async (param: any): Promise<any> => {
    return await http.post<any>(`/Sprint/AddSprint/Sprint/Add`, param);
  };
  deleteSprint = async (id: any): Promise<any> => {
    return await http.delete<any>(`/Sprint/DeleteSprintById/Sprint/Delete?id=${id}`);
 };
  getSprintById = async (id: any): Promise<any> => {
   return await http.get<any>(`/Sprint/GetSprintById/Sprint/GetSprintById?id=${id}`);
 };
  updateSprint= async (leave: any): Promise<any> => {
   return await http.put<any>("/Sprint/UpdateSprint/Sprint/Update", leave);
 };
 sprintStatus = async (param: any): Promise<any> => {
  return await http.post<any>(`/Sprint/UpdateSprintStatus/Sprint/UpdateSprintStatus`, param);
};

getPendingTicketofSprint = async (id: any): Promise<any> => {
  return await http.post<any>(`/Sprint/GetSprintTicketsCountById/Sprint/GetSprintTicketsCountById?Id=${id}`);
};
addRetrocomment = async (param: any): Promise<any> => {
  return await http.post<any>("/Sprint/AddCommentBeforeCompleteSprint/AddCommentBeforeCompleteSprint",param);
};

}

export const boardDashboardServive = new BoardDashboardServive();
