
import * as React from "react";
import { useEffect, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ActivityPanel from "./activity/ActivityPanel";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "material-react-toastify";
import { issueManagementService } from "../../service/issues-management-service";
import { masterDataService } from "../../service/master-service/master-data-service";
import { AppConstants } from "../../config/AppConstants";
import { useParams } from "react-router-dom";
import { ticketManagementService } from "../../service/ticket-management-service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { userManagementServive } from "../../service/user-management-service";
import ClearIcon from "@mui/icons-material/Clear";
import Avatar from "@mui/material/Avatar";
import { commonFunctions } from "../../service/common-services/common-functions";
import { Autocomplete, Tooltip } from "@mui/material";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import LinkedIssue from "./LinkedIssue";
import { useSelector } from "react-redux";
import ChildIssue from "./ChildIssue";
import { eSprintStatus } from "../../Enum/eSprint";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from 'react-rainbow-components';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { labelService } from "../../service/master-service/label-services";
import getDate from "../../components/GetDateWithSimpletime";
import { platformEnum, typeOfBugenum } from "../../Enum/eCreateIssue";
import getSprintDetailBySprintId from "../../service/common-services/sprintDetailBysprintId";


// const modules = {
//     toolbar: [
//       [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
//       [{size: []}],
//       ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//       [{'list': 'ordered'}, {'list': 'bullet'},
//        {'indent': '-1'}, {'indent': '+1'}],
//       ['link', 'image', 'video'],
//       ['clean']
//     ],
//     clipboard: {
//       // toggle to add extra line breaks when pasting HTML:
//       matchVisual: false,
//     }
//   }

interface ChipData {
  key: number;
  label: string;
}
const styleCross = {
  position: "absolute" as "absolute",
  right: "12px",
  top: "16px",
};

const TicketLabelLink = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  color: theme.palette.primary.main,
  transition: "all 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.dark,
  },
};
const hours = ["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00"];
const styles = {
  labelText: {
    width: 300,
    color: theme.palette.grey[700],
    [theme.breakpoints.down("sm")]: {
      width: 190,
    },
  },
  Inprogress: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main
  },
  Hold: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main
  },
  Review: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main
  },
  Resolved: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.warning.main
  },
  QA: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main
  },
  Blocked: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main
  },
  Closed: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main
  },
  customField: {
    " & .MuiSelect-select": {
      paddingTop: "8px",
      paddingBottom: "8px",
    }
  },
  AssigneeLabel: {
    fontSize: "19px",
    width: 300,
    color: theme.palette.grey[700],
    [theme.breakpoints.down("sm")]: {
      width: 190,
    },
  },
  lightBackground: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 500,
    marginTop: "0.6rem",
    textTransform: "capitalize",
  },

  reporter: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontWeight: 500,
    fontFamily: "sans-serif",
  },
};

const ItemTicket = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 500,
  fontSize: "14px",


}));


export default function TicketDetails({
  TicketData,
  projectWiseAssigneeUsers,
  spritList,
  onDrawerClose,
  projectId,
  sprintId,
  ticketId,
  refreshFunction,
  refreshData,
  BacklogRefresh,
  elements,
  setElements,
  currentSprint,
  selectedUserId,
  selectedSprintData

}: any) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ProjectPermissionApp.ProjectPermission
  );
  console.log(currentSprint, "currentSprint ")
  const { id } = useParams();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [issueStatus, setIssueStatus] = React.useState("");
  const [IssueStoryPoint, setIssueStoryPoint] = React.useState<any>("");
  const [estimatedHours, setEstimatedHours] = React.useState("");
  const [IssueAssignee, setIssueAssignee] = React.useState("");
  const [IssueAssigneeId, setIssueAssigneeId] = React.useState("");
  const [IssuereporterName, setIssuereporterName] = React.useState("");
  const [IssuereporterId, setIssuereporterId] = React.useState("");
  const [users, setUsers] = useState<any>(projectWiseAssigneeUsers);
  const [taskDetail, setTicketData] = useState<any>([]);
  const [QaEstimatedHours, setQaEstimatedHours] = React.useState("");
 // console.log(taskDetail, "taskDetail,taskDetail,taskDetail");
  const [labels, setLabels] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);
  const [Issuelabel, setIssuelabel] = React.useState("");
  const [IssuelabelId, setIssuelabelId] = React.useState("");
  const [textfieldVisible, setTextfieldVisible] = React.useState(false);
  const [addLabel, setAddLabel] = useState("");
  const [Issuesummary, setIssuesummary] = React.useState("");
  const [cancelSummary, setCancelSummary] = React.useState("");
  const [IssuePriority, setIssuePriority] = useState<any>([]);
  const [IssueSeverity, setIssueSeverity] = useState<any>([]);
  const [workLogList, setWorkLogList] = useState<any>([]);
  const [workDiscription, setWorkLogDesc] = useState<any>("");
  const [cancelDescription, setCancelDescription] = React.useState("");
  const [IssueSprint, setIssueSprint] = React.useState("");
  const [IssueSprintId, setIssueSprintId] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const[issuePlatform, setIssuePlatform] = React.useState("");
  const[issueRCA, setIssueRCA] = React.useState("");
  const[issueBugType, setIssueBugType] = React.useState("");
  const[RCAName, setRCAName] = React.useState<any>("");
  const [rcaList, setRcaList] = useState<any>([]);
  
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  const [hasInteracted, setHasInteracted] = useState(false);
  const [isSprintCompletes, setIsSprintCompletes] = useState(false);
  const [epicissueList, setepicIssues] = React.useState<any[]>([]);
  const [epicissueListOfBug, setepicissueListOfBug] = React.useState<any[]>([]);
  const [EpicIssue, setEpicIssue] = React.useState<any>();

  const [showError, setshowerror] = React.useState("Work Description is Required.");
  //////Link Issue Programming/////////////
  const [fieldVisible, setFieldVisible] = React.useState(false);
  const ref = useRef<any>();
  /////over\\\\\\\\

  /////Child Issue Programming/////////
  const [childFieldVisible, setChildFieldVisible] = React.useState(false);
  const [epicissueVisible, setepicissueVisible] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const GetTicketsByEpicIdss = async () => {
    await issueManagementService.GetTicketsByEpicIds(TicketData?.id).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setepicissueListOfBug(response.data);
      }
    });
  };


  const getEpicIssues = async (values?: any) => {
    var req = {
      ProjectId: projectId,
      // SprintId: sprintId,
    };
    await issueManagementService.GetAllEpic(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setepicIssues(response.data);
      }
    });
  };

  const getEpicByTicketId = async (values?: any) => {

    await issueManagementService.GetEpicByTicketId(TicketData?.id).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setEpicIssue(response.data);
      }
    });
  };

  const addEpicIssues = async (epicId?: any) => {
    var req = {
      epicId: epicId,
      ticketId: TicketData.id,

    };
    await issueManagementService.UpdateEpicticketById(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      }
      else {
        setepicissueVisible(false);

        toast.success("Epic Issue Added successfully");
        if (refreshFunction) {
          refreshFunction();
        }

      }
    });
  }

  var store = new Date();
  const mindate = store.setDate(store.getDate() - 30);
  const [startDate, setStartDate] = React.useState<any>(new Date());

  const updateBoardArray = (type: string, value: string) => {
   debugger
    let arr = [...elements];
    var objBoardStatus = arr.find((a: any) => a.status === taskDetail.status);
    var ModifiedTicket = objBoardStatus.tickets?.find((t: any) => t.id == taskDetail.id) ?? taskDetail;
    switch (type) {
      case "storyPoint":
        ModifiedTicket.storyPoint = value;
        break;
      case "estimatedHours":
        ModifiedTicket.estimatedHours = value;
        break;
      case "estimatedHoursQA":
          ModifiedTicket.estimatedHoursQA = value;
          break;
      case "bugType":
          ModifiedTicket.bugType = value;
          break;
      case "platform":
          ModifiedTicket.platform = value;
          break;
      case "rca":
          ModifiedTicket.rca = value;
          break;
      case "severity":
        ModifiedTicket.severity = value;
        break;
      case "priority":
        ModifiedTicket.priority = value;
        break;
      case "labelName":
        ModifiedTicket.labelName = value;
        break;
      case "userId":
        ModifiedTicket.userId = value;
        ModifiedTicket.assignUsername = users.find((x: any) => x.id == value)?.name;
        ModifiedTicket.assigneeUserName = users.find((x: any) => x.id == value)?.name;
        if (selectedUserId.length > 0 && !selectedUserId.find((x: any) => x == value)) {
          const index_user = objBoardStatus.tickets?.indexOf(ModifiedTicket);
          objBoardStatus.tickets?.splice(index_user, 1);
        }

        break;
      case "status":
        if (currentSprint == taskDetail.sprintId && selectedUserId.length > 0 && selectedUserId.find((x: any) => x == ModifiedTicket.userId)) {
          var AddInNewStatus = arr.find((a: any) => a.status === value);
          AddInNewStatus.tickets.push(ModifiedTicket)
        }
        const index1 = objBoardStatus.tickets?.indexOf(ModifiedTicket);
        objBoardStatus.tickets?.splice(index1, 1);
        break;
      case "updateSprint":
      case "delete":
        //console.log("ModifiedTicket", ModifiedTicket);
        const index = objBoardStatus.tickets?.indexOf(ModifiedTicket);
        if (index > -1) {
          objBoardStatus.tickets?.splice(index, 1);
        }

        if (currentSprint === value) {
          var AddInNewStatus = arr.find((a: any) => a.status == taskDetail.status);
          AddInNewStatus.tickets.push(taskDetail)
        }
        break;
      default:
        //console.log("switch case not found");
        break;
    }
    //console.log("arr", arr);
    setElements(arr);
  }
  const handleStatusChange = (event: any) => {
    debugger
    var OldStatus = taskDetail.status;
    var req = {
      ticketId: taskDetail.id,
      status: event.target.value,
    };
    issueManagementService.UpdateIssueStatus(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        debugger
        setIssueStatus(event.target.value);
        let obj = { ...taskDetail };
        obj.status = event.target.value;
        setTicketData(obj);
        updateBoardArray("status", event.target.value);
        debugger
      }
    }).catch((error) => {
      debugger
      setIssueStatus(OldStatus);
      debugger
      error.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    });
  };

  // console.log("taskDetail?.milestoneName", taskDetail?.milestoneName);
  const handleLabelChange = (id: any) => {
    setIssuelabel(labels.find((x: any) => x.id == id)?.name);
    setIssuelabelId(id);
    taskDetail.labelName = Issuelabel;
    setTicketData(taskDetail);
    var req = {
      ticketId: taskDetail.id,
      label: id,
    };
    issueManagementService.UpdateLabel(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let obj = { ...taskDetail };
        obj.label = id;
        obj.labelName = labels.find((x: any) => x.id == id)?.name;
        setTicketData(obj);
        updateBoardArray("labelName", labels.find((x: any) => x.id == id)?.name);
      }
    });
  };

  const abc = async () => {
    try {
      if (textfieldVisible && addLabel != "") {
        const json = {
          name: addLabel,
        };
        await labelService.createLabel(json).then(async (response: any) => {
          if (!response.data.isError) {
            setAddLabel("");
            setTextfieldVisible(false);
            let req = response.data;
            await getAllActiveLabels();
            await refreshData(taskDetail);
            await handleLabelChange(req);
            setIssuelabel(addLabel);

          }
        });
      } else {
        return false;
      }
    } catch (ex: any) {
      toast.warning("Error while updating data, check console.");
    }

  };

  const getRCANameById = (id : string , RCAList : any[]) => {
    const rca = RCAList.filter((item)=>(item?.id == id))
    setRCAName(rca ? rca[0].name : "None");
  }


  const handleSummaryChange = (event: any) => {
    setIssuesummary(event.target.value);
    TicketData.summary = event.target.value;
    setTicketData(taskDetail);
    var req = {
      ticketId: taskDetail.id,
      summary: event.target.value,
    };
    issueManagementService.UpdateSummary(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
      }
    });
  };

  const handleUpdateDescription = () => {
    if (editorValue != "<p><br></p>") {
      var req = {
        ticketId: taskDetail.id,
        description: editorValue,
      };
      issueManagementService.UpdateDescription(req).then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          toast.success("Description Updated Successfully");
          setDescription(editorValue);
        }
      });
    } else {
      setDescription("Nothing added into description, click here to add.");
    }
    setIsDescriptionFocused(false);
  };

  const handleUpdateSummary = async () => {
    try {
      if (editorValue != "<p><br></p>") {

        var req = {
          ticketId: taskDetail.id,
          summary: Issuesummary,
        };
        await issueManagementService
          .UpdateSummary(req)
          .then(async (response: any) => {
            if (response.data.isError) {
              toast.error("Something went wrong...");
            } else {
              setIsSummaryFocused(false);
              toast.success("Summary Updated Successfully");
              setSummary(editorValue);
              setCancelSummary(Issuesummary);
              let arr = [...elements];
              var objBoardStatus = arr.find((a: any) => a.status === taskDetail.status);
              var ModifiedTicket = objBoardStatus.tickets?.find((t: any) => t.id == taskDetail.id);
              ModifiedTicket.summary = Issuesummary;
              setElements(arr);
            }
          }).catch((error) => {
            toast.warning(error);
          });
      } else {
        setSummary(" ");
      }
      setIsSummaryFocused(false);
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleUpdatePriority = (event: any) => {
    setIssuePriority(event.target.value);
    taskDetail.priority = event.target.value;
    setTicketData(taskDetail);
    var req = {
      ticketId: taskDetail.id,
      priority: event.target.value,
    };
    issueManagementService.UpdatePriority(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let obj = { ...taskDetail };
        obj.priority = event.target.value;
        setTicketData(obj);
        updateBoardArray("priority", event.target.value)
      }
    });
  };

  const handleUpdateSprint = (sprintId: any) => {
    setIssueSprint(users.find((x: any) => x.id == sprintId)?.name);
    // setIssueSprint(sprintId);
    taskDetail.sprintName = IssueSprint;
    let data = TicketData;
    data.sprintId = sprintId;
    setTicketData(data);
    var req = {
      ticketId: taskDetail.id,
      sprintId: sprintId,
    };
    issueManagementService.UpdateSprint(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let obj = { ...taskDetail };
        obj.sprintId = sprintId;
        setTicketData(obj);
        setIsSprintFocused(false);
        updateBoardArray("updateSprint", sprintId)
      }
    }).catch((error) => {
      error.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
        setIssueSprintId("None");
      });
    });
  };

  const handleUpdateSeverity = (event: any) => {
    setIssueSeverity(event.target.value);
    taskDetail.severity = event.target.value;
    setTicketData(taskDetail);
    var req = {
      ticketId: taskDetail.id,
      severity: event.target.value,
    };
    issueManagementService.UpdateSeverity(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        updateBoardArray("severity", event.target.value)
        let obj = { ...taskDetail };
        obj.severity = event.target.value;
        setTicketData(obj);
      }
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setWorkHour("1:00");
    setOpen(true);
    //onDrawerClose();
  };

  // ticketManagementService
  const getAllTicketWorkLog = useCallback(() => {
    if (TicketData?.id) {
      ticketManagementService
        .getOfflineWorkHours({
          ticketId: TicketData?.id,
        })
        .then((response: any) => {
          setWorkLogList(response?.data || []);
        });
    }
  }, [TicketData]);

  //console.log("setshowerror", showError);
  // console.log("setWorkLogDesc", workDiscription);
  const handleSaveWorkHour = useCallback(async (values:any) => {
    setButtonDisable(true)
    // console.log("values of handleSaveWorkHour", values.workDiscription);
    if (TicketData?.id && values.workDiscription != "<p><br></p>") {
      // values.consumedHours = moment(new Date(values.consumedHours)).format("HH:mm");        
      ticketManagementService
        .createOfflineWorkHours(values)
        .then(async (response: any) => {
          if (response?.data?.isError) {
            toast.error("Something Went Wrong, Please try again.");
          } else {
            await getAllTicketWorkLog();
            toast.success("Hours added successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            setOpen(false);
            setWorkLogDesc("");
            setshowerror("");
          }
        })
        .catch((error) => {
          error.data.Error.map((err: any, idx: any) => {
            toast.warning(err, {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
          });
        });
    } else {
      setWorkLogDesc("");
      setshowerror("Description in Required");
    }
  }, [buttonDisable]);

  const handleClose = () => {
    setWorkLogDesc("");
    setOpen(false);
    onDrawerClose();
  };

 // console.log(taskDetail.issueType, "taskDetail.issueType");
 // console.log(TicketData?.parentIssues, "TicketData?.parentIssues");

  useEffect(() => {
    if (TicketData.issueType === "Epic") {
      GetTicketsByEpicIdss();
    }
    if(TicketData.issueType === "Bug"){
      getAllRCA();
    }
    setTicketData(TicketData);
    getEpicIssueList(TicketData);
    //getAllActiveUsers();
    getAllActiveLabels();
    getEpicIssues();
    getEpicByTicketId();
   // getAllActiveSummary(TicketData?.id);
    setIssueStatus(TicketData.status);
    setIssueStoryPoint(TicketData.storyPoint);
    setIssueAssignee(TicketData.assignUsername);
    setEstimatedHours(TicketData.estimatedHours);
    setQaEstimatedHours(TicketData.estimatedHoursQA);
    setIssuereporterId(TicketData.reporter);
    setIssuereporterName(TicketData.reporterName);
    setIssueAssigneeId(
      TicketData.userId && TicketData.userId != null
        ? TicketData.userId
        : "Select"
    );
    setEditorValue(TicketData.description);
    setCancelDescription(TicketData.description);
    if (TicketData.description == "<p><br></p>") {
      setDescription("Nothing added into description, click here to add.");
    } else {
      setDescription(TicketData.description);
    }
    getAllTicketWorkLog();
    setIssueSprint(TicketData.sprintName);
    setIssueSprintId(
      TicketData.sprintId && TicketData.sprintId != null
        ? TicketData.sprintId
        : "None"
    );

    setIssuePlatform(TicketData?.platform);
    setIssueRCA(TicketData?.rca);
    setIssueBugType(TicketData?.bugType);
    setIssuesummary(TicketData.summary);
    setCancelSummary(TicketData.summary);
    // setIssueSeverity(TicketData.severity);
    setIssueSeverity(
      TicketData.severity && TicketData.severity != null
        ? TicketData.severity
        : "None"
    );
    setIssuePriority(TicketData.priority);
    setIssuelabel(TicketData.labelName);
    setIssuelabelId(
      TicketData.label && TicketData.label != null ? TicketData.label : "None"
    );
    if (
      projectWiseAssigneeUsers != null ||
      projectWiseAssigneeUsers.length == 0
    ) {
      getAllUserListByProjectId(TicketData.projectId);
    }
    const sprintOver= async ()=>{
      let abc  = await getSprintDetailBySprintId(TicketData?.sprintId) == 3
      setIsSprintCompletes(abc);
    }
    sprintOver();
  }, [TicketData]);

  const getAllActiveLabels = async () => {
    await masterDataService.getAllActiveLabels().then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setLabels(response.data);
      }
    });
  };

  const DeleteTicket = async () => {
    setIsDisabled(true);
    try {
      await issueManagementService
        .deleteTicket(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            //BacklogRefresh();
            toast.success("Ticket Deleted successfully.");
            setOpenDelete(false);
            setOpen(false);
            onDrawerClose();
            updateBoardArray("delete", currentId);
            //     let arr = [...elements];
            //     var RemoveFromOldStatus=arr.find((a:any)=>a.status===taskDetail.status);
            //     var ModifiedTicket=RemoveFromOldStatus.tickets?.find((t:any)=>t.id==taskDetail.id);
            //     const index = RemoveFromOldStatus.tickets?.indexOf(ModifiedTicket);
            // RemoveFromOldStatus.tickets?.splice(index, 1);
            // setElements(arr);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getAllActiveSummary = async (id: any) => {
    await masterDataService.getAllActiveSummary(id).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setSummary(response.data);
      }
    });
  };

  

  const onEditorChange = (event: any) => {
    setEditorValue(event);
  };

  const onofflineEditorChange = (event: any) => {
    // console.log("onEditorChange event", event);
    setWorkLogDesc(event);
    if (event === "<p><br></p>") {
      setWorkLogDesc("");
      setshowerror("Description in Required");
    }
    // setWorkLogDesc(event);
    // setshowerror("");
  };

  const getAllUserListByProjectId = async (projectId: any) => {
    userManagementServive
      .getAllUsersByProjectId({
        projectId: projectId,
      })
      .then((response: any) => {
        setUsers(response?.data || []);
      });
  };

  const getAllActiveUsers = async () => {
    await masterDataService.getAllActiveUsers().then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setUsers(response.data);
      }
    });
  };

  const updateTicketRCAArtifacts = async () => {
    taskDetail.rca = issueRCA;
    setIssueRCA(issueRCA);
    setTicketData(taskDetail);

    var payload = {
      ticketId : TicketData.id,
      rca: issueRCA
    }
    await issueManagementService.updateBugArtifacts(payload).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
       console.log("Hello");
       let obj = {...taskDetail};
       obj.rca = issueRCA;
       setTicketData(obj);
       updateBoardArray("rca",issueRCA);
      }
    });
  };

  const updateTicketBugArtifacts = async () => {
    taskDetail.bugType = issueBugType;
    setIssueBugType(issueBugType);
    setTicketData(taskDetail);

    var payload = {
      ticketId : TicketData.id,
      bugType: issueBugType
    }
    await issueManagementService.updateBugArtifacts(payload).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
       console.log("Hello");
       let obj = {...taskDetail};
       obj.bugType = issueBugType;
       setTicketData(obj);
       updateBoardArray("bugType",issueBugType);
      }
    });
  };

  const updateTicketPlatformArtifacts = async () => {
    taskDetail.platform = issuePlatform;
    setIssuePlatform(issuePlatform);
    setTicketData(taskDetail);

    var payload = {
      ticketId : TicketData.id,
      platform: issuePlatform
    }

    await issueManagementService.updateBugArtifacts(payload).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
       console.log("Hello");
       let obj = {...taskDetail};
       obj.platform = issuePlatform;
       setTicketData(obj);
       updateBoardArray("platform",issuePlatform);
      }
    });
  };

  
  const [workHour, setWorkHour] = React.useState<any>("1:00");

  //console.log("workHour", workHour);
  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkHour(event.target.value);
  };
 
  const handleUserChange = (id: any) => {
    setIssueAssignee(users.find((x: any) => x.id == id)?.name);
    setIssueAssigneeId(id);
    taskDetail.assignUsername = IssueAssignee;
    setTicketData(taskDetail);
    var req = {
      ticketId: TicketData.id,
      userId: id,
    };
    issueManagementService.UpdateResourceId(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        updateBoardArray("userId", id)
        let obj = { ...taskDetail };
        obj.userId = id;
        setTicketData(obj);
      }
    });
  };
  const handleStoryPointChange = (event: any) => {
    let value = event;
    setIssueStoryPoint(value);
    taskDetail.storyPoint = value;
    setTicketData(taskDetail);
    var req = {
      ticketId: TicketData.id,
      storyPoint: value,
    };
    issueManagementService.UpdateStoryPoint(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        updateBoardArray("storyPoint", value);
        let obj = { ...taskDetail };
        obj.storyPoint = value;
        setTicketData(obj);
      }
    });
  };

  const handleEstimatedHoursChange = (event: any) => {
    let value = event.target.value.replace(/[^0-9]/g, "");
    taskDetail.estimatedHours = value;
    setEstimatedHours(value);
    setTicketData(taskDetail);

    var req = {
      ticketId: TicketData.id,
      estimatedHours: value,
      estimatedHoursQA: QaEstimatedHours
    };
    issueManagementService.UpdateHours(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let obj = { ...taskDetail };
        obj.estimatedHours = value;
        setTicketData(obj);
        updateBoardArray("estimatedHours", value)
      }
    });
  };

  const handleQAEstimatedHoursChange = (event: any) => {
    let value = event.target.value.replace(/[^0-9]/g, "");
    taskDetail.estimatedHoursQA = value;
    setQaEstimatedHours(value);
    setTicketData(taskDetail);

    var req = {
      ticketId: TicketData.id,
      estimatedHours: estimatedHours,
      estimatedHoursQA: value
    };
    issueManagementService.UpdateHours(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let obj = { ...taskDetail };
        obj.estimatedHoursQA = value;
        setTicketData(obj);
        updateBoardArray("estimatedHoursQA", value)
      }
    });
  };

  const handleIssueStatus = (event: any) => {
    taskDetail.status = event.target.value;
    setTicketData(taskDetail);
    var req = {
      ticketId: TicketData.id,
      status: event.target.value,
    };
    issueManagementService.UpdateIssueStatus(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        //  toast.success("Status Updated Successfully");
      }
    });
  };
 
  // React.useEffect(() => {
  //     const timer = setInterval(() => {
  //       setProgress((oldProgress) => {
  //         if (oldProgress === 100) {
  //           return 0;
  //         }
  //         const diff = Math.random() * 10;
  //         return Math.min(oldProgress + diff, 100);
  //       });
  //     }, 500);

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, []);

  const [isNameFocused, setIsNamedFocused] = React.useState(false);
  const [isSummaryFocused, setIsSummaryFocused] = React.useState(false);
  const [isLabelFocused, setIsLabelFocused] = React.useState(false);
  const [isStoryPointFocused, setIsStoryPointFocused] = React.useState(false);
  const [isEpicFocused, setIsEpicFocused] = React.useState(false);
  const [isEstimatedHoursFocused, setEstimatedHoursFocused] =
    React.useState(false);

  const [isQAEstimatedHoursFocused, setQAEstimatedHoursFocused] = React.useState(false);  

  //console.log("isEstimatedHoursFocused", isEstimatedHoursFocused);
  const [isSprintFocused, setIsSprintFocused] = React.useState(false);
  const [isPriorityFocused, setIsPriorityFocused] = React.useState(false);
  const [isSeverityFocused, setIsSeverityFocused] = React.useState(false);

  const [isPlatformFocused, setIsPlatformFocused] = React.useState(false);
  const [isBugTypeFocused, setIsBugTypeFocused] = React.useState(false);
  const [isRCAFocused, setIsRCAFocused] = React.useState(false);


  // const [linkedissue, setAge] = React.useState("");

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };

  const [chipData, setChipData] = React.useState<readonly ChipData[]>([]);

  const handleDelete = () => {
    setIssueAssignee("");
  };

  const [description, setDescription] = React.useState("");
  const [isDescriptionFocused, setIsDescriptionFocused] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [issueList, setIssues] = React.useState<any[]>([]);
  const deleteTicketData = async (id: any, e: any) => {
    // e.stopPropagation();
    setOpenDelete(true);
    setCurrentId(id);
  };


  // const getDate = (dateObject: Date) => {
  //   let date = new Date(dateObject).toISOString();
  //   return date?.slice(0, 10) + 'T00:00:00Z';
  // }
  const getEpicIssueList = async (values?: any) => {
    var req = {
      ProjectId: values.projectId,
      // SprintId: values.sprintId,
    };
    await issueManagementService.GetAllEpic(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setIssues(response.data);
      }
    });
  };

  const getAllRCA = async () => {
    let param = {
      search: ""
    }
    try {
      await masterDataService
        .getAllRCAs(param)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("facing issue while uploading document.");
          } else {
            setRcaList(response.data);
            getRCANameById(TicketData?.rca , response.data);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  }

  //work log hours

  const convertTimeToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };
  
  const convertMinutesToTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hours ${minutes} minutes`;
  };

  const devHoursInMinutes = convertTimeToMinutes(TicketData.consumedHoursByDev || "0:00");
const qaHoursInMinutes = convertTimeToMinutes(TicketData.consumedHoursByQa || "0:00");

const totalMinutes = devHoursInMinutes + qaHoursInMinutes;
const totalTimeString = convertMinutesToTime(totalMinutes);

  return (
    <>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          sx={{ mb: 2 }}
        >
          <Stack direction='row' justifyContent='start' spacing={1}>
            <Box sx={{ textAlign: "left" }}>
              {EpicIssue?.ticketNumber != null && (
                <Box
                  component={Link}
                  to={`/issue-detail/${taskDetail.epicId}`}
                  sx={TicketLabelLink}
                >
                  <Tooltip

                    title={
                      <>
                        {"Epic"} {EpicIssue?.ticketNumber}
                      </>
                    }
                  >
                    <Box
                      sx={{
                        color: theme.palette.primary.main,
                        display: "flex",
                        alignItems: "center",
                        lineHeight: 1,
                      }}
                    >
                      <>

                        <>
                          <Icons.Epic />
                        </>


                        &nbsp; {EpicIssue?.ticketNumber}
                      </>
                    </Box>
                  </Tooltip>
                </Box>)}
            </Box>
            <Box sx={{ textAlign: "left" }}>

              {TicketData.parentIssues != null && TicketData.parentIssues.length > 0 && (
                <Box
                  component={Link}
                  to={`/issue-detail/${TicketData.parentIssues[0]?.id}`}
                  sx={TicketLabelLink}
                >
                  <Tooltip
                    title={
                      <>
                        {TicketData.parentIssues[0]?.issueType} {TicketData.parentIssues[0]?.ticketNumber}
                      </>
                    }
                  >
                    <Box
                      sx={{
                        color: theme.palette.primary.main,
                        display: "flex",
                        alignItems: "center",
                        lineHeight: 1,
                      //  "margin-left": "-122px",
                      }}
                    >
                      <>
                        {TicketData.parentIssues[0]?.issueType === "Bug" && (
                          <>
                            <Icons.Bug />
                          </>
                        )}
                        {TicketData.parentIssues[0]?.issueType === "Story" && (
                          <>
                            <Icons.Story />
                          </>
                        )}
                        {TicketData.parentIssues[0]?.issueType === "Epic" && (
                          <>
                            <Icons.Epic />
                          </>
                        )}
                        {TicketData.parentIssues[0]?.issueType === "Task" && (
                          <>
                            <Icons.Task />
                          </>
                        )}
                        {TicketData.parentIssues[0]?.issueType == "Child" && (
                          <>
                            <Icons.copy />
                          </>
                        )}
                        &nbsp; {TicketData.parentIssues[0]?.ticketNumber}
                      </>
                    </Box>
                  </Tooltip>
                </Box>)}
            </Box>
            <Box sx={{ textAlign: "left" }}>
            <Box
              component={Link}
              to={`/issue-detail/${taskDetail.id}`}
              sx={TicketLabelLink}
            >
              <Tooltip

                title={
                  <>
                    {taskDetail.issueType} {TicketData.ticketNumber}
                  </>
                }
              >
                <Box
                  sx={{
                    color: theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    lineHeight: 1,
                  }}
                >
                  <>
                    {taskDetail.issueType === "Bug" && (
                      <>
                        <Icons.Bug />
                      </>
                    )}
                    {taskDetail.issueType === "Story" && (
                      <>
                        <Icons.Story />
                      </>
                    )}
                    {taskDetail.issueType === "Epic" && (
                      <>
                        <Icons.Epic />
                      </>
                    )}
                    {taskDetail.issueType === "Task" && (
                      <>
                        <Icons.Task />
                      </>
                    )}
                    {taskDetail.issueType == "Child" && (
                      <>
                        <Icons.copy />
                      </>
                    )}
                    &nbsp; {TicketData.ticketNumber}
                  </>
                </Box>
              </Tooltip>
            </Box>
          </Box>
          </Stack>

          <Stack alignItems="center" direction="row" spacing={1}>
            {_permission.action_Issue_Delete_Access == true && (
              <Tooltip title="Delete">
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    deleteTicketData(TicketData?.id, e);
                  }}
                >
                  <Icons.Delete />
                </IconButton>
              </Tooltip>
            )}
            {/* <Tooltip title="Attachment">
              <IconButton color="primary">
                <Icons.Attachment />
              </IconButton>
            </Tooltip> */}

            {/* <Tooltip title="Subtask List">
              <IconButton color="primary">
                <Icons.SubtaskList />
              </IconButton>
            </Tooltip> */}

            <Tooltip title="Child Issue">
              <IconButton
                color="primary"
                onClick={(e) => {
                  setChildFieldVisible(true);
                }}
              >
                <Icons.ChildIssue />
              </IconButton>
            </Tooltip>

            {/* <Tooltip title="Epic Issue">
              <IconButton
                color="primary"
                onClick={(e) => {
                  setepicissueVisible(true);
                }}
              >
                <Icons.Epic />
              </IconButton>
            </Tooltip> */}

            <Tooltip title="Link Issue">
              <IconButton
                color="primary"
                onClick={(e) => {
                  setFieldVisible(true);
                  ref.current.linkedIssueFunction();
                }}
              >
                <Icons.LinkIssue />
              </IconButton>
            </Tooltip>

            {!id && (
              <Tooltip title="Expand">
                <IconButton color="primary">
                  <Box
                    component={Link}
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/issue-detail/${taskDetail.id}`}
                  >
                    <Icons.Expand />
                  </Box>
                </IconButton>
              </Tooltip>
            )}

            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <Select
                name="status"
                value={issueStatus}
                disabled={isSprintCompletes}
                onChange={handleStatusChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "& .MuiSelect-select": {
                    paddingTop: "14px",
                    paddingBottom: "14px",
                  },
                }}
              >
                {AppConstants.MasterData.Issue.IssueStatus.map(
                  (status: any, idx: any) => (
                    <MenuItem key={"status_" + idx} value={status.label} disabled={isSprintCompletes}>
                      {status.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <Box
              onClick={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              }}
            >
              <Icons.SlideRight onClick={handleClose} />
            </Box>
          </Stack>
        </Stack>

        <Box>
          <Box sx={{ mt: 1, fontWeight: 500 }}>
            {!isSummaryFocused ? (
              <Typography
                component="div"
                color={theme.palette.primary.dark}
                sx={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: " 36px",
                  wordBreak: "break-all"
                }}
                onDoubleClick={() => {
                  setIsSummaryFocused(true);
                }}
              >
                {Issuesummary}
              </Typography>
            ) : (
              <TextField
                id="outlined-basic"
                fullWidth
                multiline
                variant="outlined"
                required

                value={Issuesummary}
                onChange={(e) => {
                  // e.target.value;
                  setIssuesummary(e.currentTarget.value);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    minHeight: "100px",
                    backgroundColor: "#fff",
                    lineHeight: "1.5",
                  },
                }}
              />
            )}

            {isSummaryFocused && (
              <DialogActions>
                <Button
                  size="small"
                  onClick={handleUpdateSummary}
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                  onClick={(event) => {
                    setIsSummaryFocused(false);
                    setIssuesummary(cancelSummary);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Box>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.AssigneeLabel}>Assignee</Box>
              <Box
                sx={{
                  width: "240px",
                }}
              >
                {!isNameFocused ? (
                  <Chip
                    label={IssueAssignee ?? "Un-Assignee"}
                    disabled={isSprintCompletes}
                    onClick={() => {
                      setIsNamedFocused(true);
                    }}
                    sx={{
                      borderRadius: "4px",
                      backgroundColor: theme.palette.primary.light,
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  />
                ) : (
                  <Autocomplete
                    value={
                      users?.find(
                        (option: any) => option.id == IssueAssigneeId
                      ) ?? null
                    }
                    fullWidth
                    id="outlined-basic"
                    options={users}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      setIssueAssigneeId(value?.id ?? "");
                      handleUserChange(value?.id ?? "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        id="selectedAssigneId"
                        variant="outlined"
                        fullWidth
                        name="IssueAssigneeId"
                        value={IssueAssigneeId}
                        onBlur={(event) => setIsNamedFocused(false)}
                        required
                      />
                    )}
                  />
                )}
              </Box>
            </Stack>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Reporter</Typography>
              </Box>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {/* <Avatar
                  alt={TicketData?.reporterName}
                  src={`${process.env.REACT_APP_SERVER_URL}${TicketData?.reporterName}`}
                  sx={[styles.reporter]}
                /> */}
                <Avatar
                  {...commonFunctions.stringAvatar(TicketData?.reporterName)}
                  sx={[styles.reporter]}
                  src={`${process.env.REACT_APP_SERVER_URL}${TicketData?.reporterName}`}
                />

                <Typography
                  variant="subtitle1"
                  sx={{ textTransform: "capitalize" }}
                >
                  {IssuereporterName}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Label</Typography>
              </Box>
              <Box>
                {/* {!isLabelFocused ? (
                  <Typography
                    onClick={() => {
                      setIsLabelFocused(true);
                    }}
                  >
                    {Issuelabel ?? "None"}
                  </Typography>
                ) : (
                      <Autocomplete
                      autoHighlight
                      value={labels?.find(
                        (option: any) => option.id == IssuelabelId
                      ) ?? null}
                      fullWidth
                      id="outlined-SprintId"
                      options={[{ id: 'create-new', name: 'Add New Label' },
                      ...labels.sort((a: any, b: any) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)]}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, value) => {
                        if (value?.id === 'create-new') {
                          // setTextfieldVisible(true);
                        } else {
                          setIssuelabelId(value?.id ?? "");
                           handleLabelChange(value?.id ?? "");
                          // setTextfieldVisible(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="IssuelabelId"
                          variant="outlined"
                          fullWidth
                          onBlur={() => setIsLabelFocused(false)}
                          value={IssuelabelId}
                          autoFocus
                          id="IssueAssigneeId"
                           />
                      )}
                    />
                )} */}
                {
                  !isLabelFocused ? (
                    <Typography
                      onClick={() => {
                        setIsLabelFocused(true);
                      }}
                    >
                      {Issuelabel ?? "None"}
                    </Typography>
                  ) : textfieldVisible === true ? (
                    <>
                      <TextField
                        name="addLabel"
                        label="Add Label"
                        value={addLabel}
                        onBlur={abc}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^a-zA-Z\s]/g, "");
                          value = value.replace(/\s{2,}$/g, "").trimStart();
                          setAddLabel(value);
                        }}
                      ></TextField>{" "}
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setTextfieldVisible(false);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Autocomplete
                      autoHighlight
                      value={labels?.find(
                        (option: any) => option.id == IssuelabelId
                      ) ?? null}
                      // fullWidth
                      sx={{ width: '220px' }}
                      id="IssueAssigneeId"
                      options={[{ id: 'create-new', name: 'Add New Label' },
                      ...labels.sort((a: any, b: any) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)]}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, value) => {
                        if (value?.id === 'create-new') {
                          setTextfieldVisible(true);
                        } else {
                          setIssuelabelId(value?.id ?? "");
                          handleLabelChange(value?.id ?? "");
                          setTextfieldVisible(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="IssuelabelId"
                          variant="outlined"
                          fullWidth
                           onBlur={() => setIsLabelFocused(false)}
                          value={IssuelabelId}
                          autoFocus
                          id="IssueAssigneeId"
                        />
                      )}
                    />
                  )
                }
              </Box>
            </Stack>
          </Box>
          {/* <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Story Points</Typography>
              </Box>
              <Box>
                {!isStoryPointFocused ? (
                  <Typography
                    onClick={() => {
                      setIsStoryPointFocused(true);
                    }}
                  >
                    {IssueStoryPoint != null && IssueStoryPoint != "" ? IssueStoryPoint : "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    name="StoryPoints"
                    value={IssueStoryPoint}
                    variant="outlined"
                    fullWidth
                    onBlur={(event) => {
                      setIsStoryPointFocused(false);
                    }}
                    select
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "10px 10px 10px 10px",
                      },
                    }}
                  >
                    {NumberEnum.map((key, idx) => (
                      <MenuItem
                        key={key}
                        value={key}
                        onClick={(event) => {
                          setIssueStoryPoint(key);
                          handleStoryPointChange(key);
                        }}
                      >
                        {key}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box> */}
          {TicketData.issueType === "Task"&&(
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Dev Estimated Hours</Typography>
              </Box>
              <Box>
                {!isEstimatedHoursFocused ? (
                  <Typography
                    onClick={() => {
                      if(!estimatedHours){
                        if(!TicketData?.isQAUser){
                          setEstimatedHoursFocused(true);
                        }
                        else if(TicketData?.isQAUser && !TicketData?.isQAApplicble){
                          setEstimatedHoursFocused(true);
                        }
                        else{
                          setEstimatedHoursFocused(false);
                        }
                        
                      }
                    }}
                  >
                    {estimatedHours != null && estimatedHours != "" ? estimatedHours : "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    name="StoryPoints"
                    value={estimatedHours}
                    onBlur={(event) => {
                      handleEstimatedHoursChange(event);
                      setEstimatedHoursFocused(false);
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "10px 10px 10px 10px",
                      },
                    }}
                    onChange={(e) => {
                      let value = e.currentTarget.value
                        .replace(/[^0-9]\s{0,}$/g, "")
                        .trimStart()
                        .substring(0, 2);
                      setEstimatedHours(value);
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Box>)}
          {TicketData.issueType === "Task"&&(
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">QA Estimated Hours</Typography>
              </Box>
              <Box>
                {!isQAEstimatedHoursFocused ? (
                  <Typography
                    onClick={() => {
                      if(!QaEstimatedHours){
                         if(!TicketData?.isQAUser &&!TicketData?.isQAApplicble){
                          setQAEstimatedHoursFocused(true);
                        }
                        else if(!TicketData?.isQAUser){
                          setQAEstimatedHoursFocused(false);
                        }
                        else{
                          setQAEstimatedHoursFocused(true);
                        }
                      }
                    }}
                  >
                    {QaEstimatedHours != null && QaEstimatedHours != "" ? QaEstimatedHours : "None"}
                    {/* {"If QA is not applicable to this project, you may enter 0"} */}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    name="StoryPoints"
                    value={QaEstimatedHours}
                    onBlur={(event) => {
                      handleQAEstimatedHoursChange(event);
                      setQAEstimatedHoursFocused(false);
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "10px 10px 10px 10px",
                      },
                    }}
                    onChange={(e) => {
                      let value = e.currentTarget.value
                        .replace(/[^0-9]\s{0,}$/g, "")
                        .trimStart()
                        .substring(0, 2);
                        setQaEstimatedHours(value);
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Box>)}

          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Sprint</Typography>
              </Box>
              <Box>
                {!isSprintFocused && IssueSprint != null ? (
                  <Typography
                    onClick={() => {
                      if(!isSprintCompletes){
                        setIsSprintFocused(true);
                      }
                     
                    }}
                  >
                    {IssueSprint ?? "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    select
                    variant="outlined"
                    fullWidth
                    name="IssueSprint"
                    value={IssueSprintId}
                    disabled={isSprintCompletes}
                    // onChange={handleUpdateSprint}
                    onBlur={(event) => setIsSprintFocused(false)}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10px 40px 10px 10px",
                      },
                    }}
                  >
                    <MenuItem
                      key="SelectAssignee"
                      value="None"
                      selected={IssueSprintId == "None" ? true : false}
                      onClick={() => {
                        setIssueSprintId("None");
                        handleUpdateSprint("");
                      }}
                    >
                      Backlog
                    </MenuItem>
                    {spritList.filter((sprint: any) => sprint.status !== eSprintStatus.Completed && sprint.id).map((sprint: any) => (
                      <MenuItem
                        key={sprint.sprintName}
                        value={sprint.id}
                        onClick={() => {
                          setIssueSprintId(sprint.id);
                          handleUpdateSprint(sprint.id);
                        }}
                      >
                        {sprint.sprintName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box>

          {TicketData.issueType === "Bug" && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">RCA</Typography>
              </Box>
              <Box>
                {!isRCAFocused  ? (
                  <Typography
                    onClick={() => {
                      setIsRCAFocused(true);
                    }}
                  >
                    
                    {RCAName != null && RCAName != "" ? RCAName : "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    select
                    variant="outlined"
                    fullWidth
                    name="RCA"
                    value={issueRCA}
                     onChange={(event : any) => {
                      const selectedRCA = event.target.value;
                      setIssueRCA(selectedRCA);
                      getRCANameById(selectedRCA,rcaList);
                     }}
                    onBlur={(event) => {
                      setIsRCAFocused(false)
                      updateTicketRCAArtifacts();
                    }
                    }
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10px 40px 10px 10px",
                      },
                    }}
                  >
                    {rcaList.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box> )}

          {TicketData.issueType === "Bug" && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Platform</Typography>
              </Box>
              <Box>
                {!isPlatformFocused  ? (
                  <Typography
                    onClick={() => {
                      setIsPlatformFocused(true);
                    }}
                  >
                    {issuePlatform != null && issuePlatform != "" ? issuePlatform : "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    select
                    variant="outlined"
                    fullWidth
                    name="Platform"
                    value={issuePlatform}
                    onChange={(event:any) => {
                      const selectedPlatform = event.target.value;
                      setIssuePlatform(selectedPlatform);

                      console.log("Hello")
                    } }
                    onBlur={(event) => {
                      setIsPlatformFocused(false)
                      updateTicketPlatformArtifacts();
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10px 40px 10px 10px",
                      },
                    }}
                  >
                    {platformEnum.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box> )}

          {TicketData.issueType === "Bug" && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Bug Type</Typography>
              </Box>
              <Box>
                {!isBugTypeFocused  ? (
                  <Typography
                    onClick={() => {
                      setIsBugTypeFocused(true);
                    }}
                  >
                    {issueBugType != null && issueBugType != "" ? issueBugType : "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    select
                    variant="outlined"
                    fullWidth
                    name="BugType"
                    value={issueBugType}
                    onChange={(event : any) => {
                      const selectedBugType = event.target.value;
                      setIssueBugType(selectedBugType);
                    }}
                    onBlur={(event) => {
                      setIsBugTypeFocused(false)
                      updateTicketBugArtifacts();
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10px 40px 10px 10px",
                      },
                    }}
                  >
                    {typeOfBugenum.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box> )}

          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Priority</Typography>
              </Box>
              <Box>
                {!isPriorityFocused &&
                  IssuePriority != null &&
                  IssuePriority.length > 0 ? (
                  <Typography
                    onClick={() => {
                      setIsPriorityFocused(true);
                    }}
                  >
                    {IssuePriority}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    select
                    variant="outlined"
                    fullWidth
                    name="priority"
                    onChange={handleUpdatePriority}
                    onBlur={(event) => setIsPriorityFocused(false)}
                    value={IssuePriority}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10px 40px 10px 10px",
                      },
                    }}
                  >
                    {AppConstants.MasterData.Issue.Priority.map(
                      (priority: any) => (
                        <MenuItem key={priority.value} value={priority.value}>
                          {priority.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Severity</Typography>
              </Box>
              <Box>
                {!isSeverityFocused && IssueSeverity != null ? (
                  <Typography
                    onClick={() => {
                      setIsSeverityFocused(true);
                    }}
                  >
                    {IssueSeverity.length > 0 ? IssueSeverity : "None"}
                  </Typography>
                ) : (
                  <TextField
                    autoFocus
                    id="outlined-basic"
                    select
                    variant="outlined"
                    fullWidth
                    name="Severity"
                    onChange={handleUpdateSeverity}
                    onBlur={(event) => setIsSeverityFocused(false)}
                    value={IssueSeverity}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10px 40px 10px 10px",
                      },
                    }}
                  >
                    <MenuItem
                      key="SelectAssignee"
                      value="None"
                      disabled
                      selected={IssueSeverity == "None" ? true : false}
                    >
                      None
                    </MenuItem>
                    {AppConstants.MasterData.Issue.Severity.map(
                      (severity: any) => (
                        <MenuItem key={severity.label} value={severity.value}>
                          {severity.label}
                        </MenuItem>
                      )   
                    )}
                  </TextField>
                )}
              </Box>
            </Stack>
          </Box>
          {TicketData.issueType === "Task"&&(
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Dev Consumed Hour's</Typography>
              </Box>
              <Box>
                  <Typography>
                    {TicketData?.consumedHoursByDev|| "0"}
                  </Typography>
              </Box>
            </Stack>
          </Box>)}

          {TicketData.issueType === "Task"&&(
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">QA Consumed Hour's</Typography>
              </Box>
              <Box>
                  <Typography>
                    {TicketData?.consumedHoursByQa|| "0"}
                  </Typography>
              </Box>
            </Stack>
          </Box>)}

        </Box>

        <Box sx={{ mt: 2 }}>
        {taskDetail.issueType != "Epic" && (
              <ChildIssue
                childFieldVisible={childFieldVisible}
                setChildFieldVisible={setChildFieldVisible}
                ticketId={taskDetail.id}
                childIssueDetails={TicketData.childIssues}
                projectId={TicketData.projectId}
                refreshData={refreshData}
                ticketData={TicketData}
                projectWiseAssigneeUsers={projectWiseAssigneeUsers}
              />
        )}             
        </Box>


        <Box sx={{ mt: 2 }}>
        {taskDetail.issueType != "Epic" && TicketData.issueType != "Bug" && (
              <LinkedIssue
                ref={ref}
                fieldVisible={fieldVisible}
                setFieldVisible={setFieldVisible}
                projectId={TicketData.projectId}
                linkIssueDetails={TicketData.linkIssues}
                ticketId={taskDetail.id}
                refreshData={refreshData}
                ticketData={TicketData}
                projectWiseAssigneeUsers={projectWiseAssigneeUsers}
              />
        )}  
        </Box>

        <Box sx={{ mt: 2 }}>
          {epicissueListOfBug != null && (<Typography variant="h6" color={theme.palette.primary.dark} sx={{ fontSize: "1rem" }}>Epic Issues</Typography>)}
          {epicissueListOfBug != null &&
            epicissueListOfBug.map((issue: any, index: any) => (
              <>
                <Paper variant="outlined" sx={{ mb: 0.5, padding: "4px 12px 4px 12px" }}>
                  <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between">
                    <Box component={Link}
                      to={`/issue-detail/${issue?.id}`}
                      target="_blank"
                      sx={{ textDecoration: "none", color: theme.palette.primary.main }}>
                      <ItemTicket>
                        {(issue?.issueType == "Bug" &&
                          <Tooltip title={issue?.issueType}>
                            <span><Icons.BackLog /></span></Tooltip>
                        )}
                        {(issue?.issueType == "Story" &&
                          <Tooltip title={issue?.issueType}>
                            <span><Icons.Story /></span></Tooltip>
                        )}
                        {(issue?.issueType == "Epic" &&
                          <Tooltip title={issue?.issueType}>
                            <span><Icons.Epic /></span></Tooltip>
                        )}
                        {(issue?.issueType == "Task" &&
                          <Tooltip title={issue?.issueType}>
                            <span><Icons.Task /></span></Tooltip>
                        )}
                        &nbsp;<span>{issue?.ticketNumber}</span>
                      </ItemTicket>
                    </Box>


                    <Stack direction='row' alignItems='center' sx={{ ml: 2 }}>
                      {(issue?.priority == "Highest" &&
                        <Tooltip title={issue?.priority}>
                          <span><Icons.Highest /></span></Tooltip>
                      )}
                      {(issue?.priority == "High" &&
                        <Tooltip title={issue?.priority}>
                          <span><Icons.High /></span></Tooltip>
                      )}
                      {(issue?.priority == "Low" &&
                        <Tooltip title={issue?.priority}>
                          <span><Icons.Low /></span></Tooltip>
                      )}
                      {(issue?.priority == "Lowest" &&
                        <Tooltip title={issue?.priority}>
                          <span><Icons.Lowest /></span></Tooltip>
                      )}
                      {(issue?.priority == "Medium" &&
                        <Tooltip title={issue?.priority}>
                          <span><Icons.Medium /></span></Tooltip>
                      )}
                      <Typography component='p' sx={{ fontSize: 14, display: 'flex', alignItems: 'center', ml: 1 }}>

                        <Stack alignItems='center' direction='row' component='div'  >
                          <Typography component={Link}
                            to={`/issue-detail/${issue?.id}`}
                            target="_blank"
                            sx={{ textDecoration: "none", color: theme.palette.primary.main }}> {issue?.summary.slice(0, 44) + (issue?.summary.length > 20 ? "..." : "")}</Typography>
                        </Stack>
                      </Typography>
                    </Stack>

                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Stack direction='row' alignItems="center">
                      <Box>

                        {/* <Tooltip title={issue?.assignUsername}>
                                            AssigneeCode
                                                <Avatar
                                                    {...commonFunctions.stringAvatar(issue?.assignUsername)}
                                                    sx={[styles.reporter]}
                                                    src={`${process.env.REACT_APP_SERVER_URL}${issue?.assignUsername}`}
                                                     /></Tooltip> */}

                      </Box>
                    </Stack>
                  </Stack>
                </Paper>
              </>
            ))
          }
        </Box>



        {TicketData.parentIssues != null && TicketData.parentIssues.length > 0 &&
          <>
            <Typography
              variant="body1"
              fontWeight={600}
              gutterBottom
              sx={{ mt: 3 }}
            >
              Parent Issues
            </Typography>

            <Box sx={{ mt: 1 }}>
              <Box>
                <Stack direction="row" alignItems="center">
                  <Box sx={styles.labelText}>
                    <Typography variant="body1">Issue No</Typography>
                  </Box>
                  <Box>Summary</Box>
                </Stack>
              </Box>
              {TicketData.parentIssues.map((issue: any, index: any) => (
                <Box>
                  <Stack direction="row" alignItems="center"
                    sx={{ textDecoration: "none", color: theme.palette.primary.main }}
                    component={Link} to={`/issue-detail/${issue.id}`}
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box sx={styles.labelText}>
                      <Typography variant="body1">{issue?.ticketNumber}</Typography>
                    </Box>
                    <Box>{issue?.summary}</Box>
                  </Stack>
                </Box>
              ))}
            </Box>
          </>
        }

        <Typography
          variant="body1"
          fontWeight={600}
          gutterBottom
          sx={{ mt: 3 }}
        >
          More Fields
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Box>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Work Log</Typography>
              </Box>
              <Box> {totalMinutes > 0 ? totalTimeString : "No Time Logged"}</Box>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                variant="text"
                size="small"
                sx={{
                  padding: " 2px 8px",
                  height: "auto",
                  textTransform: "inherit",
                  textDecoration: "underline",
                }}
                onClick={handleClickOpen}
                disabled={TicketData?.status === "Hold" || TicketData?.status === "Closed" 
                  || TicketData?.status === "Product Backlog" || _authUser?.id !=TicketData?.userId 
                  ||selectedSprintData?.status ==3}

              >
                Offline work
              </Button>
            </Stack>
          </Box>


          <Box sx={{ mt: 1 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Epic Link</Typography>
              </Box>

              <Box sx={{ minWidth: 300 }}>

                {!isEpicFocused ?
                  (
                    <Typography
                      onClick={() => {
                        setIsEpicFocused(true);
                      }}
                    >
                      {EpicIssue?.ticketNumber != "" && EpicIssue?.ticketNumber != null ? EpicIssue?.ticketNumber + " " + EpicIssue?.summary : "None"}
                    </Typography>
                  ) :
                  (
                    <Autocomplete
                      value={issueList?.find(
                        (option: any) => option.id == EpicIssue?.id
                      ) ?? null}
                      fullWidth
                      id="Epic-issueList"

                      options={issueList}
                      getOptionDisabled={(option) => {
                        
                        return (TicketData.id == option.id || TicketData.id  == EpicIssue?.id)? true : false;
                        }
                      }
                      getOptionLabel={(option) =>
                        option.ticketNumber +
                        " " +
                        option.summary.slice(0, 20) +
                        (option.summary.length > 20 ? "..." : "")}
                      renderOption={(props, option: any) => (
                        <Box component="li"  {...props}>
                          <Box sx={{ color: "#90EE90", margin: "4px" }}><Icons.EpicIcon /></Box>
                          {option.ticketNumber + " "}
                          {option.summary.slice(0, 20) +
                            (option.summary.length > 20 ? "..." : "")}
                        </Box>

                      )}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      onBlur={() => {
                        setIsEpicFocused(false);
                      }}
                      // onBlur={()=>{
                      //   setIsEpicFocused(false);
                      // }}
                      onChange={async (event, value) => {
                        
                        var req = {
                          epicId: value?.id,
                          ticketId: TicketData?.id,

                        };
                        //console.log(value?.id,"value?.id,value?.id")

                        await issueManagementService.UpdateEpicticketById(req).then((response: any) => {
                          if (response.data.isError) {
                            toast.error("Something Went Wrong, Please try again.");
                          }
                          else {
                            getEpicByTicketId();
                          }
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          id="Epic-issueList"
                          //error={Boolean(touched.EpicId && errors.EpicId)}                  
                          name="EpicId"
                          placeholder="None"
                          variant="outlined"
                          value={EpicIssue?.id}
                          onBlur={(event) => setIsNamedFocused(false)}
                          fullWidth />
                      )} />
                  )

                }

              </Box>
            </Stack>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Stack direction="row" alignItems="center">
              {/* <Box sx={styles.labelText}>
                <Typography variant="body1">Assignee Link</Typography>
              </Box>
             {[EpicIssue].map((ticket: any, index: any) => (              
             <Stack direction="row" alignItems="center">
              {ticket?.id !=null ?(
             <Box
             sx={{ textDecoration: "none", color: theme.palette.primary.main }}
             component={Link}
             to={`/issue-detail/${ticket?.id}`}
             color="primary"
             target="_blank"
           >
      <Box
    sx={styles.labelText}>
      {EpicIssue   &&(
<ItemTicket sx={{ mr: 4 }} >
<Icons.Epic />&nbsp;<span>{ticket?.ticketNumber}&nbsp;<span></span>{ticket?.summary}</span>
</ItemTicket>)}
</Box>    
     {!EpicIssue &&
      <Box
      sx={styles.labelText}>
     <Typography variant="body1">None</Typography>
     </Box>
    }            
     </Box> ):("None")}  
     </Stack>
     
              ))}
               */}


            </Stack>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={styles.labelText}>
                <Typography variant="body1">Milestones</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {taskDetail?.milestoneName ?? "None"}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>

        {/* <Button variant="outlined" startIcon={<Icons.ListCheck />} size="small" sx={{ my: 2 }}>
                    Create Subtask
                </Button> */}
        {/* <Typography
          variant="body1"
          fontWeight={500}
          gutterBottom
          sx={{ mt: 2 }}
        >
          Attachment
        </Typography> */}

        {/* <Box sx={{ mt: 2 }}>
          {TicketData?.attachment !== "" && TicketData.attachment ? (
            <ImageListItem>
              <img
                src={apiUrl.getImageURL() + `/${TicketData?.attachment}`}
              />
              <img src={`https://stg.appsndevs.com/seasiaconnectapi/FileUploads/${TicketData?.attachment}`} />
            </ImageListItem>
          ) : (
            <Typography
              variant="body1"
              fontWeight={500}
              gutterBottom
              sx={{ mt: 2 }}
            >
              No Attachment
            </Typography>
          )}
          <CardMedia
                        component="img"
                        height="140"
                        image={
                            TicketData?.attachment !== "" && TicketData.attachment
                                ? `https://stg.appsndevs.com/seasiaconnectapi/FileUploads/${TicketData?.attachment}`
                                : 'https://stg.appsndevs.com/seasiaconnectapi/FileUploads/Screenshot_2_20220929114950101.png'
                        }
                        alt="green iguana"
                    />
        </Box> */}
        <Box sx={{ mt: 1, height: 20 }}></Box>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mt: 1, fontWeight: 600 }}
        >
          Description
        </Typography>
        <Box sx={{ mt: 1, fontWeight: 500, }}>
          {/* {TicketData.description} */}
          {/* onChange={onEditorChange} */}
          {!isDescriptionFocused ? (
            <Typography
            sx={{wordWrap:'break-word'}}
              onDoubleClick={() => {
                setIsDescriptionFocused(true);
              }}
              dangerouslySetInnerHTML={{
                __html:
                  description?.length > 0
                    ? description
                    : ("Nothing added into description, click here to add." as string),
              }}
            ></Typography>
          ) : (
            <ReactQuill
              modules={AppConstants.EditorSetting}
              theme="snow"
              value={editorValue !== null ? editorValue.replace(/<p>(\s|(&nbsp))*<\/p>/gim, "") : ""}
              onChange={onEditorChange}
            />
          )}

          {isDescriptionFocused && (
            <DialogActions>
              <Button
                size="small"
                onClick={handleUpdateDescription}
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
              >
                Save
              </Button>
              <Button
                size="small"
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
                onClick={(event) => {
                  setIsDescriptionFocused(false);
                  setEditorValue(cancelDescription);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          )}
        </Box>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mt: 1, fontWeight: 500 }}
        >
          Activity
        </Typography>

        <ActivityPanel
          TicketcreatedBy={TicketData.TicketcreatedBy}
          TicketId={TicketData.id}
          workLogList={workLogList}
          getAllTicketWorkLog={getAllTicketWorkLog}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={Button}
        fullWidth
        maxWidth="sm"
        sx={{ p: 4 }}
      >
        <Formik
          initialValues={{
            ticketId: TicketData?.id,                    
            consumedHours: workHour || "",
            workDiscription: workDiscription || "",
            workingDay: getDate(startDate),
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            workDiscription: Yup.string().required("Description is Required"),
            consumedHours: Yup.string()
            .required("Consumed Hours is Required.")

            .matches(/^([0-3]?[0-9]|4[0]):[0-5][0-9]$/,
              "Enter a valid time format hrs less than 41 and minutes is not greater than 60'"
            )
            .test('maxHours', 'Consumed Hours cannot exceed 15 hours  ', value => {
              if (!value) return true;
              const [hours, minutes] = value.split(':').map(Number);
              const totalMinutes = hours * 60 + minutes;
              return totalMinutes <= 40 * 60;
            })
            .test('maxHours', 'Value must be greater than 0', value => {
              if (!value) return false;
              const [hours, minutes] = value.split(':').map(Number);
              const totalMinutes = hours * 60 + minutes;
              return totalMinutes > 0;
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);
              await handleSaveWorkHour(values);
            } catch (err: any) {
              //console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) =>
                    setErrorMessage(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <DialogTitle>
                  Log Offline work
                  <IconButton
                    aria-label="delete"
                    sx={styleCross}
                    onClick={handleClose}
                  >
                    <ClearIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>

                  </DialogContentText>

                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.primary.dark, mt: 3 }}
                  >
                    Offline logs{" "}
                  </Typography>
                  <Stack
                    direction="row"
                    flexDirection="column"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <div>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ mt: 1, fontWeight: 500 }}
                      >
                        Hours :

                        <Typography
                          sx={{ mt: 1, fontWeight: 500, fontSize: '12px' }}
                        >
                          (eg : 12:00 / 1:00)
                        </Typography>
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        <TextField
                          required
                          onBlur={handleBlur}
                          error={
                            getIn(touched, "consumedHours") &&
                            getIn(errors, "consumedHours")
                          }
                          helperText={
                            getIn(touched, "consumedHours") &&
                            getIn(errors, "consumedHours")
                          }
                          id="consumedHours"    
                          name="consumedHours"                    
                          value={workHour.trimEnd()
                          } 
                          onChange={handleChangeHours}
                          fullWidth
                        >
                        </TextField>


                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>  
          <TimePicker          
          value={value}          
          onChange={(newValue : any ) => {  
            
            setTimeValue(newValue);  
          }} 
        
          ampm={false} 
          renderInput={(parameters : any ) => <TextField {...parameters} 
          
          />}  
        />  
      </LocalizationProvider>   */}
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>  
      <Stack spacing={3}>  
        <TimePicker  
          renderInput={(parameters) => <TextField {...parameters} />}        
         
            value={value}                 
            onChange={(newValue :any ) => {             
              setValue(newValue );
          
            }}              
           ampm={false}                   
          />   
              
      </Stack>  
    </LocalizationProvider>  */}
    
                        <LocalizationProvider dateAdapter={AdapterDayjs}>    
                                           
                          <DatePicker
                            // className="error"
                            // autoComplete='off'
                            // disableFuture
                            borderRadius="semi-square"                         
                            style={{ width: '40%'}}
                            error={hasInteracted && !startDate ? "Please set a date" : ''}
                            value={startDate}
                           // minDate={new Date (selectedSprintData.startDate)}
                            minDate={new Date(mindate)}
                            maxDate={
                              new Date ()
                            }
                            onChange={(newValue: any) => {
                              values.workingDay = getDate(newValue);
                              setHasInteracted(true);
                              setStartDate(newValue);
                              // setSubmit(true);
                            }}
                            // error={Boolean(
                            //     getIn(touched, "type") && getIn(errors, "type")
                            // )}

                            // renderInput={(params: any) => (
                            //   <TextField
                            //     {...params}
                            //     autoComplete="off" // Disable suggestions
                            //     readOnly={true} // Disable user input
                            //     onBlur={handleBlur}
                            //     onKeyDown={onkeydown}
                            //     //  error={isSubmit && !values.endDate ? "End date is Required." : ""}
                            //     error={
                            //       getIn(touched, "startDate") &&
                            //       getIn(errors, "startDate")
                            //     }
                            //     helperText={
                            //       getIn(touched, "startDate") &&
                            //       getIn(errors, "startDate")
                            //     }
                            //     // error={isSubmit && !values.startDate ? "Start date is Required." : ""}
                            //     sx={{
                            //       "& .MuiSvgIcon-root": {
                            //         fill: theme.palette.primary.main,
                            //       },
                            //     }}
                            //   />
                            // )}
                          />
                         
                        </LocalizationProvider>
                        {/* <TextField
                  id="outlined-select-currency"
                  select
                  value={workHour}
                  onChange={handleChangeHours}
                  fullWidth
                >
                  {hours.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option} Hours
                    </MenuItem>
                  ))}
                </TextField> */}
                
                      </Stack>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ mt: 1, fontWeight: 500 }}
                      >
                        Work Description
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        sx={{ mt: 1, fontWeight: 500 }}
                      >
                        <Paper
                          variant="outlined"
                          sx={{
                            position: "relative",
                            height: "260px",
                            p: 1,
                            overflowY: "scroll",
                          }}
                        >
                          {/* <MUIRichTextEditor label="Type here" onChange={(e: any) => onEditorWorkLogChange(e)} /> */}
                          <ReactQuill
                            modules={AppConstants.EditorSetting}
                            theme="snow"
                            value={workDiscription.replace(
                              /<p>(\s|(&nbsp))*<\/p>/gim,
                              "") ? workDiscription
                              : ""
                                .trimStart().substring(0, 100)}
                            onChange={onofflineEditorChange}
                          // onChange={
                          //   (e: any, newValue: any) => {
                          //     values.workDiscription = e.currentTarget.value
                          //       .replace(/\s/g, "")
                          //       .trimStart();
                          //   }
                          // preserveWhitespace ="true"
                          // }
                          />
                          <p className="Error" style={{ color: "#EB445A", fontSize: "0.75rem" }}>
                            {errors.workDiscription && `${showError}`}
                          </p>
                        </Paper>
                      </Typography>
                    </div>

                    {/* <TextField
                            fullWidth
                            id="outlined-select-currency"
                            value={currency}
                            onChange={handleChangeHours}

                        >handleClose1
                        </TextField> */}
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                  >
                    <Button onClick={handleClose} variant="outlined">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      // onClick={() => {
                      //   setSubmit(true);
                      // }}
                      variant="contained"
                      disabled={buttonDisable}
                    >
                      Add
                    </Button>
                  </Stack>
                </DialogContent>
              </>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={setOpenDelete}
        aria-labelledby="alert-dialog-tixtle"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 1 }}>
          Are you sure you want to delete this {TicketData?.ticketNumber}{" "}
          {TicketData?.issueType}?
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
            pt: 0,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Button variant="outlined" onClick={handleCloseDelete}>
            No
          </Button>
          <Button
            variant="contained"
            disabled={isDisabled}
            onClick={DeleteTicket}

          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
