import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import StyledTableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hodServices } from "../../../service/master-service/hod-service";
import Chip, { ChipProps } from "@mui/material/Chip";
import * as Icons from "../../../assests/icons/icons";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Notes from "../../../components/styledComponent/Note";
import SearchBar from "../../../components/styledComponent/SearchBar";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
// import { Transition } from '@react-spring/web';
import {
  Autocomplete,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { departmentService } from "../../../service/master-service/department-service";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotFound from "../../../components/styledComponent/NotFound";
import AppConstants from "../../../config/AppConstants";
import { masterDataService } from "../../../service/master-service/master-data-service";
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import RoleView from "../../role/RoleView";
import { Error } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Masteralltop from "../mastersetupstyle";
import InfoPopover from "../../../components/CommonInfoComponent";
import Multiselect from "multiselect-react-dropdown";
import { coHodServices } from "../../../service/master-service/cohod-service";

// const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
//   color: theme.palette.success.dark,
//   backgroundColor: theme.palette.success.light,
//   fontWeight: 400,
//   fontSize: 14,
// }));

var dropdown_syle = {
  searchBox: {
    lineHeight: "31px",
    borderColor: "#EEEEEE",
    minWidth: "320px",
    maxWidth: "100%",
    height: "44px",
    
  },
};

interface Skills {
  id: string;
  departmentName: string;
  departmentId: string;
  coHodId: string;
  coHodName: string;
  srNo: string;
  assignedUsers: AssigneeUsers[]; // Update this line
}

interface  AssigneeUsers{
  userId: string;
  name: string;
  assignedDate: string;
}

interface User {
  userId: string;
  userName: string;
  id: string;
  name: string;
  DepartmentId: string;
}

const initialvalues = {
  departmentId: "",
  departmentName: "",
  coHodId: "",
  assignedUsers: [
    {
      userId: "",
      name: "",
      assignedDate: ""
    }
  ]
};

const optionsStatus = ["Active", "De-Active", "Null", ,];

function AddCoHod() {
  const _permission = useSelector((state: any) => state.MasterPermissionApp.MasterPermission);
  const navigate = useNavigate();
  const [actionAllowed, setAllowdAction] = React.useState<any>([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const [project, setProject] = useState<any>();
  const pageCount = Math.ceil(project?.length / rowsPerPage);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedstatusIndex, setStatusSelectedIndex] = React.useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const statushandleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setStatusSelectedIndex(index);
    setAnchorEl(null);
  };


  const isValidUser = async () => {
    await masterDataService
      .GetActionbypageId(
        AppConstants.ScreenInfo.Master_Configuration.Manage_HOD.pageId
      )
      .then((response: any) => {
        if (response.data.isError) {
          navigate("/login");
        } else {
          var data = response?.data;
          var screenInfo = masterDataService.isAuthorizeUser(
            data,
            AppConstants.ScreenInfo.Master_Configuration.Manage_HOD
          );
          var allowedAction = {
            edit: masterDataService.isActionAlowed(
              screenInfo,
              AppConstants.ScreenInfo.Master_Configuration.Manage_HOD.actionIds
                .edit
            ),
          };
          setAllowdAction(allowedAction);
        }
      })
      .catch((error) => {
        navigate(AppConstants.Redirection.Unauthorize_Access);
      });
  };

  /////search programming
  const [searchValue, setSearchValue] = useState("");

  //////department data
  const [department, setdepartment] = useState(initialvalues);
  const { id } = useParams();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [fetchRecordId, setfetchRecordId] = React.useState("");
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [managerId, setManagerId] = React.useState("");
  const handleSubmit1 = (e: any,values:any) => {
    console.log(values,"valuesvalues")
    e.preventDefault();
    if (selectedUsers?.length == 0 && values?.coHodId !="" ) {
      setFormSubmitted(true);
    }
    else {
      setFormSubmitted(false);
    }
  };

  const handleMultiselectSelect = async (selectedList : any, selectedItem : any) => {
    const newSelectedUsers = selectedList.map((user : any) => ({
      userId: user.userId,
      name: user.userName,
      assignedDate: new Date().toISOString(), 
    }));
    await setSelectedUsers(newSelectedUsers);
  };

  const handleMultiselectRemove = (selectedList: any, removedItem: any) => {
    const newSelectedUsers = selectedList.map((user: any) => ({
      userId: user.userId,
      name: user.userName,
      assignedDate: new Date().toISOString(), 
    }));
    setSelectedUsers(newSelectedUsers);
    if(newSelectedUsers.length == 0){
    }
  
  };



  const updateCoHOD = async (values: any) => {
    values.assignedUsers =selectedUsers.length == 0 ?null :selectedUsers;
    values.coHodId = values?.coHodId == ""?null :values?.coHodId;
    setButtonDisable(true);
    try {
      await coHodServices.updateCoHod(values).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Co Head of Department updated successfully.",{
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });
          getCoHodDetails();
          setOpen(false);
          setFormSubmitted(false);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setFormSubmitted(false);
        toast.warning(err);
      });
    }
  };


  /////over
  const [users, setUser] = useState<User[]>([]);
 
  var hodData = {
    id: "",
    name: "All Roles",
    userId: "",
    DepartmentId: "",
    userName: "",
  };

  const getManagerDetails = async (departID : any) => {
    
    await coHodServices.getManagerDetails(departID).then((response: any) => {
      if(response.data.isError){

      }
      else{
        setManagerId(response.data.hodId);
      }
    })
  }

  const getUsersdrop = async (departmentId: any) => {
    await hodServices.getDropdownData(departmentId).then((response: any) => {
      if (response.data.isError) {
        setUser([hodData]);
      } else {
        debugger
        let filteredData = response.data;
       let filter2 =  filteredData.filter((index: any) => index.userId !== managerId)
        setUser(filter2);
      }
    });
  };


  //////Dropdown over

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // , HodId:any, UserId: string, UserName: any
  const handleClickOpen = (department: any) => {
    
    setOpen(true);
    setdepartment({
      ...department,
      departmentId: department.departmentId,
      departmentName: department.departmentName,
      coHodId: department.coHodId ?? "",
    });

  
   const assignedUsersFormatted = department.assignedUsers.map((user: any) => ({
    userId: user.userId,
    name: user.name,
    assignedDate: user.assignedDate,
   }));
    console.log(assignedUsersFormatted, "values from response");

    getManagerDetails(department.departmentId);

    setSelectedUsers(assignedUsersFormatted);
    getUsersdrop(department.departmentId);
    setfetchRecordId(department.id);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if(_permission.page_ManageCoHOD_Access !==true ){
      navigate("/dashboard");
    }
 
    getCoHodDetails();
  }, []);

  const getCoHodDetails = async () => {
    var input = {
      search: "",
    };

    try{
      await coHodServices.getCoHod(input).then((response: any) => {
        if(response.data.isError){
        } else {
          console.log(response.data);
          setProject(response?.data)
        }
      })

    }catch (error : any) {
      if (error.status === 400){
        toast.warning(error.data.Error[0]);
      } else {
        toast.warning("An error occurred. Please try again later.");
    }
  }
  };

  const triggerSearch = async (newValue: string) => {
    getSearchDetails(newValue);
    setPage(0);
  };
  /// search programming
  const getSearchDetails = async (newValue: string) => {
    let values = {
      search: newValue,
    };

    await coHodServices.getCoHod(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        setProject(response?.data);
        if (response.data.length == 0) {
          // setNoUser(true);
        }
      }
    });
  };

  //Sorting

  const [dense, setDense] = React.useState(false);
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Skills;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "departmentName",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },

    {
      id: "coHodName",
      numeric: false,
      disablePadding: false,
      label: "Co - HOD",
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Skills>("srNo");

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Skills
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Skills) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align-items="center" align="center">Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Skills
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 1 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
  const [noUser, setNoUser] = useState(false);

  ///////page change and dropdown programming over

  const handleChangeRowsPerPage1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fieldValidationSchema = Yup.object().shape({
    coHodId: selectedUsers?.length == 0
    ? Yup.string()
    : Yup.string().required("Please delete resources or choose Co-Hod.")
    //Yup.string().required("Department Co-Head is required.")
    // .test(
    //   "len",
    //   "Phone number must be exactly 10 digits",
    //   (val) => !department.hodId
    // ),
    // durationType: (duration=="Half Day" ? Yup.string().required('Duration half is required'):Yup.string()),
    // reason: Yup.string().required('Reason is required'),
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Master Setup</Typography>
        <Typography color="text.primary"> Department Head</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
      {/* <Notes
          NoteText="Here, Admin will create and manage Head of Department of departments."
        /> */}
        <Stack
        sx={Masteralltop.topbar}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Department Co Head
          </Typography>
          <Box>
            
          <InfoPopover  onInput={"Manage HOD"}/>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          {/* <Box>
                <Button
                  fullWidth
                  aria-controls={stopen ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={stopen ? "true" : undefined}
                  onClick={handleClickDropdown}
                  sx={{ justifyContent: "space-between" }}
                  variant="selectSmall"
                >
                  {optionsStatus[selectedstatusIndex]} <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={aanchorEl}
                  open={stopen}
                  onClose={statushandleClose}
                >
                  {optionsStatus.map((optionsStatus, index) => (
                    <MenuItem
                      key={optionsStatus}
                      // disabled={index === 0}
                      selected={index === selectedstatusIndex}
                      onClick={(event) =>
                        statushandleMenuItemClick(event, index)
                      }
                    >
                      {optionsStatus}
                    </MenuItem>
                  ))}
                </Menu>
              </Box> */}
                 <Box   sx={Masteralltop.searchbar}>
          <SearchBar
            setSearchValue={setSearchValue}
            callBackFunction={triggerSearch}
          /> </Box>
        </Stack>

        <Divider />

        <TableContainer sx={{ mt: 1 }}>
          <Table aria-label="simple table" size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={project?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(project, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.coHodName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick1(event, row.coHodName)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                    
                    >
                      <StyledTableCell align="left">{row.departmentName}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.coHodName}
                      </StyledTableCell>
                     
                      <StyledTableCell align="left" width={100}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          // spacing={2}
                        >
                          {/* {(actionAllowed.edit && */}
                         
                            <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              size="medium"
                              onClick={() => handleClickOpen(row)}
                            >
                              {/* , user.hodId, user.UserName,user.DepartmentId */}
                              <Icons.Edit />
                            </IconButton>
                            </Tooltip>
                          
                      
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: dense ? 33 : 53,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {project?.length == 0 && <NotFound NotfoundText="No Result Found" />}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={project?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
       
          labelDisplayedRows={() => `Total: ${project?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>

      <Dialog sx={{ "& .MuiDialog-paper": { overflow: "visible", height: "unset" } }}  open={open} fullWidth maxWidth="sm">
        <Formik
          initialValues={{
            id: fetchRecordId ?? "",
            departmentId: department.departmentId,
            coHodId: department.coHodId ?? "",
            assignedUsers: selectedUsers,
          }}
          validationSchema={fieldValidationSchema}
          onSubmit={async (values) => {
            try {
              setErrorMessage("");
              if (!formSubmitted) {
              await updateCoHOD(values);}
            } catch (ex: any) {
              ex.data.errors.map((err: any, idx: any) => {
                toast.warning(err);
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={(e) => { handleSubmit(); handleSubmit1(e,values); }}>
              <DialogTitle>Assign Co Department Head</DialogTitle>
              <DialogContent   sx={{overflow: "visible !important"}}>
                <TextField
                  autoFocus
                  disabled
                  margin="dense"
                  id="department-name"
                  //onChange={(e) =>values.DepartmentId = e.target.value }
                  name="name"
                  // label="Department Name"
                  type="name"
                  fullWidth
                  value={department.departmentName}
                  variant="outlined"
                 
                />

                {/* <TextField
                  autoFocus
                  sx={{ mt: 3 }}
                  margin="dense"
                  select
                  error={Boolean(
                    getIn(touched, "hodId") && getIn(errors, "hodId")
                  )}
                  helperText={getIn(touched, "hodId") && getIn(errors, "hodId")}
                  required
                  name="hodId"
                  label="Select HOD"
                  variant="outlined"
                  fullWidth
                  value={department.hodId??""}
                >
                  {users.map((option, index) => (
                    <MenuItem
                      key={option.userId}
                      value={option.userId}
                      onClick={(event) => {
                        values.hodId=option.userId;
                        setdepartment({ ...department, hodId: option.userId });
                      }}
                    >
                      {option.userName}
                    </MenuItem>
                  ))}
                </TextField> */}

                <Autocomplete
                  value={
                    users?.find(
                      (option) => option.userId == department.coHodId
                    ) ?? null
                  }
                  fullWidth
                  // id="outlined-basic"
                  options={users}
                  getOptionLabel={(option) => option.userName}
                  // getOptionDisabled={(option) =>
                  //   formValues.find(
                  //     (el: any) => el.department.userId === option.userId
                  //   )
                  // }
                  onChange={(event, value) => {
                    values.coHodId = value?.userId || "";
                    setdepartment({
                      ...department,
                      coHodId: value?.userId || "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="coHodId"
                      label="Select Co-HOD"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      value={department.coHodId ?? ""}
                      error={Boolean(
                        getIn(touched, "coHodId") && getIn(errors, "coHodId")
                      )}
                      helperText={
                        getIn(touched, "coHodId") && getIn(errors, "coHodId")
                      }
                      required
                      sx={{ mt: 3 }}
                    />
                  )}
                />
                <Box mt={3} > {/* Add spacing here */}
                  <Multiselect
                    displayValue="userName"
                    onKeyPressFn={() => {}}
                    onRemove={handleMultiselectRemove}
                    onSearch={() => {}}
                    onSelect={handleMultiselectSelect}
                    placeholder="Resources"
                    options={users.filter((user) => user.userId !== department.coHodId)}
                    selectedValues={selectedUsers.map((user : any) => ({ ...user, userName: user.name }))}
                    style={dropdown_syle}
                    key={id}
                  />
                    {(formSubmitted && selectedUsers.length == 0 && department.coHodId ) && (
        <div style={{ color: 'red', fontSize: '13px' }}>{"Resource is Required"}</div>
      )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary"
                //  disabled={buttonDisable}
                >
                  Submit
                </Button>
                {/* <Button
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button> */}
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default AddCoHod;
