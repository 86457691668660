export enum Monts {
    "Select Month"="",
    'January' = "1",
    'February' = "2",
    'March' = "3",
    'April' = "4",
    'May' = "5",
    'June' = "6",
    'July' = "7",
    'August' = "8",
    'September' = "9",
    'October' = "10",
    'November' = "11",
    'December' = "12",
}

export const Monts1 = [
    {
      id: "",
      label: "All Month"
    },
    {
      id: "1",
      label: "January"
    },
    {
      id: "2",
      label: "February"
    },
    {
      id: "3",
      label: "March"
    },
    {
      id: "4",
        label: "April"
      },
      {
        id: "5",
        label: "May"
      },
      {
        id: "6",
        label: "June"
      },
      {
        id: "7",
        label: "July"
      },
      {
        id: "8",
        label: "August"
      },
      {
        id: "9",
        label: "September"
      },
      {
        id: "10",
        label: "October"
      },
      {
        id: "11",
        label: "November"
      },
      {
        id: "12",
        label: "December"
      }
  ];

  export const MontsInt = [
    {
      id: 1,
      label: "January"
    },
    {
      id: 2,
      label: "February"
    },
    {
      id: 3,
      label: "March"
    },
    {
      id: 4,
        label: "April"
      },
      {
        id: 5,
        label: "May"
      },
      {
        id: 6,
        label: "June"
      },
      {
        id: 7,
        label: "July"
      },
      {
        id: 8,
        label: "August"
      },
      {
        id: 9,
        label: "September"
      },
      {
        id: 10,
        label: "October"
      },
      {
        id: 11,
        label: "November"
      },
      {
        id: 12,
        label: "December"
      }
  ];