
import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box, Divider, Grid, Icon, ListItemButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, styled,
} from "@mui/material";
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import LinearProgress from '@mui/material/LinearProgress';
import { userdashboardservices } from '../service/userdashboard-service';
import moment from 'moment';
import { brownieemployeeservice } from '../service/master-service/brownieEmployee-service';
import { id } from 'date-fns/locale';
import NotFound from '../components/styledComponent/NotFound';
import * as Icons from "../assests/icons/icons";
import ReactApexChart from 'react-apexcharts';
import Achievers from "../assests/images/achievers.png";
import { leaveService } from '../service/Leave/LeaveService';
import { reportService } from '../service/Reports/reports';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';




const styles = {
    cardLeaves: {
        borderRadius: "10px",
        padding: "22px 18px",
        height: '100%',
    },
    titleLeave: {
        color: "#1F944E",
        fontWeight: 500,
        fontSize: "20px",
        marginBottom: "0",
    },
    annualLeaves: {
        color: "#1F944E",
    },
    sickLeaves: {
        color: "#B38906",
    },
    casualLeaves: {
        color: "#16B5BF",
        fontSize: "16px"
    },
    compOff: {
        color: "#1DA7FF",
    },
    otherLeaves: {
        color: "#74C225",
    },
    total: {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#424242",
    },
    values: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #424242",
    },
    taken: {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: " 18px",
    },
    takenvalues: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #757575",
    },
    applyLeave: {
        backgroundColor: "#F0F9FF",
        borderRadius: "10px",
        padding: "22px 18px",
        maxWidth: { xs: '100%', md: "412px" },
        marginLeft: "auto",
    },
    applyLeaveHeading: {
        color: "#232360",
        fontWeight: 500,
        fontSize: " 26px",
    },
    selectType: {
        backgroundColor: "#fff",
        marginTop: "10px",
    },
    selectlabel: {
        fontWeight: 400,
        fontSize: " 14px",
        lineHeight: "21px",
        marginLeft: 0,
        marginBottom: "6px",
        color: "#212121",
    },
    spaceTop: {
        marginTop: "24px",
    },
    AnnualLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #2DD36F 50%, #FFBB0B 78.73%)",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    SickLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: " linear-gradient(90deg, #B38906 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    CasualLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: " linear-gradient(90deg, #15B5BF 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    CompLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #1DA7FF 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    OtherLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #74C225 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },

        valueRight: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: " 21px",
            /* Primary Color/Primary_dark shade */
            color: " #424242",
            flexGrow: 1,
        },
    },
    cardStyleProject: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#212121",
        marginBottom: "5px",
    },
    cardStyleCompany: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#232360",
        marginBottom: "5px",
    },
    cardStyleTask: {
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#616161",
    },
    taskTitle: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#232360",

    },
    //     bRadius: {
    //         borderRadius: '16px',
    //         border: '1px solid #EEEEEE',
    //         cursor:"pointer",
    //         "&:hover":{
    //             backgroundColor:"#fbfbfb"
    //         }

    //     },
    //     selected:{
    //    backgroundColor:"#fbfbfb"
    //     },
    tableTH: {
        borderBottom: 'transparent',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#232360",

    },
    table: {
        marginTop: '10px',
    },
    calenderTitle: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#232360",
    },
    timeOne: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#616161",
    },
    mettingOne: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #212121",
    },
    mettingTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#616161",
    },
    timeTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000000",
        marginTop: '3px',
    },
    listNone: {
        listStyle: 'none',
        marginBottom: '18px',
    },
    bRight: {
        borderLeft: '5px solid #1DA7FF'
    },
    bNone: {
        borderRadius: '0',
    },
    borderNone: {
        border: 'none'
    },
    brownieBox: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#232360",
    },
    brownieOuter: {
        marginTop: '16px',
        borderRadius: '16px',
        border: '1px solid #EEEEEE',

    },
    p15: {
        paddingBottom: '15px',
    },
    point: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#424242",
    }
}
interface Iproject {
    projectId: string;
    projectName: string;
}

function createData(
    name: string,
    sprintName: string,
    task: number
) {
    return { name, sprintName, task };
}

const rows = [
    createData('Frozen yoghurt', "Sprint 1", 4),
    createData('Ice cream sandwich', "Sprint 2", 10),
    createData('Eclair', "Sprint 1", 4),
    createData('Cupcake', "Sprint 2", 2),
    createData('Gingerbread', "Sprint 1", 8),
];

const ItemBoxWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: "10px",
    rowGap: "10px",
    marginTop: "10px"
}));

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h6,
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: " 0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
}));


export default function ClientDashboard() {

   
    return (
        <>
            {/* <Chat /> */}
            {/* <Box sx={styles.NotificatioTitle}>
            <h1>Welcome ddd {_authUser.employeeInfo.currentUserName}</h1>
     </Box> */}
            <Paper elevation={0} sx={{ p: 3 }}>
                <Grid container spacing={3}>
                   <>Coming Soon</>
                </Grid>
            </Paper>

        </>
    );
}
