import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack, Typography, Autocomplete, TextField, TablePagination, MenuItem, Rating, Avatar, Button } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import * as Icons from "../../assests/icons/icons";
import { HrReportFilter, IHrReportResponse } from "../../Interfaces/IReport";
import { Iproject } from '../../Interfaces/IProject';
import { masterDataService } from '../../service/master-service/master-data-service';
import { toast } from 'material-react-toastify';
import { Department } from '../../Interfaces/IDepartment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { eTypeOfUser } from '../../Enum/eTypeOfUser';
import { clsDepartmentList } from '../../service/common-services/department';
import { notificationDetailsServices } from '../../service/Notification/notification-service';
import moment from 'moment';
import { commonFunctions } from '../../service/common-services/common-functions';
import theme from '../../theme/theme';
import NotFound from '../../components/styledComponent/NotFound';

export default function ProjectReportTwo() {
  const options1 = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];
  const [reports, setreports] = React.useState<IHrReportResponse>()
  const [feedback, setFeedBack] = React.useState<any>([]);
  const [projects, setProjects] = React.useState<Iproject[]>([]);
  const [selectedDepartmentId, setDepartmentId] = useState("");
  const [selectedProjectId, setProjectId] = useState("");
  const [departmentList, setDepartmentList] = React.useState<Department[]>([]);
  const [disabledDepartments, setdisabledDepartments] = React.useState(false);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ReportPermissionApp.ReportPermission
  );
  const currentDate = new Date();
  var monthName = currentDate.toLocaleString("default", { month: "long" });
  const abc = currentDate.getMonth() + 1;
  const [fromDate, setfromDate] = React.useState<any>(null);
  const currentMonthNo = abc.toString();
  console.log(monthName, "monthName")
  console.log(currentMonthNo , "abc");
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonthNo);
  const [monthset, setMonth] = React.useState(monthName);
  console.log(monthset , "monthset");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const pageCount = Math.ceil(feedback.length / rowsPerPage);
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const getCurrentMonth = () => {
    const currentMonth = new Date().getMonth() + 1;
    return currentMonth;
  };
  const [monthStatus, setMonthStatus] = useState<number | undefined>(getCurrentMonth());
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const navigation = useNavigate();
  useEffect(() => {
    if (_permission.page_ManageMilestoneReport_Access !== true) {
      navigation("/dashboard");
    }

    setfromDate(currentYearString);
    GetAllClientFeedback("", "");
    GetClientFeedBackAverageReport("", "", currentYearString, monthStatus);
    const departmentsData = async () => {
      if (_authUser.typeOfUser != eTypeOfUser.Admin) {
        if (
          _permission.action_MilestoneReport_ReportListing_AllDepartments_Access ==
          true
        ) {
          setdisabledDepartments(false);
          getActiveProjects(_authUser.employeeInfo.departmentId)
        } else {
          setdisabledDepartments(true);
          getActiveProjects(_authUser.employeeInfo.departmentId)
        }
        setDepartmentId(_authUser.employeeInfo.departmentId);
        setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());
      } else {
        setDepartmentList(await clsDepartmentList.getDepartmentDropdownList());

      }
    };
    departmentsData();
  }, []);

  var projectData = { id: "0", projectName: "Select Projects" };
  const getActiveProjects = async (id: any) => {
    await masterDataService.getProjectByDeptartmentId(id).then(async (response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        let data = [];
        data.push(projectData);
        response.data.map((dep: any) => {
          data.push(dep);
        });
        setProjects(data);
      }
    });
  };

  console.log(selectedDepartmentId, "selectedDepartmentId");
  console.log(selectedProjectId, "selectedProjectId");
  const GetAllClientFeedback = async (month: any, year: any) => {
    if (month === "") month = new Date().getMonth() + 1;
    if (year === "") year = new Date().getFullYear();
    var req = {
      clientId: _authUser.id,
      month: 2,
      year: 2023
    }
    try {
      const response = await notificationDetailsServices.GetAllClientFeedback(req);
      if (response.data.isError) {
        toast.error("Something went wrong while fetching data");
      } else {
        setreports(response?.data);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {

      });
    }
  };

  const GetClientFeedBackAverageReport = async (ProjectId: any, deptId: any, year: any, months: any) => {
    setPage(0);
    var req = {
      projectId: ProjectId,
      departmentId: deptId,
      year: year,
      month: months
    }
    try {
      const response = await notificationDetailsServices.GetClientFeedBackAverageReport(req);
      if (response.data.isError) {
        toast.error("Something went wrong while fetching data");
      } else {
        setFeedBack(response?.data);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
      });
    }
  };

  const handleMonthChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    setfiltersSelected(true)
    const selectedStatus = event.target.value as number;
    setMonthStatus(selectedStatus);
    GetClientFeedBackAverageReport(selectedProjectId, selectedDepartmentId, fromDate, selectedStatus);
  };
  let currentYear = new Date().getFullYear();
  var currentYearString = currentYear.toString();
  const Years = [];
  for (let i = 0; i < 4; i++) {
    Years.push({
      id: currentYear - i,
      label: (currentYear - i).toString()
    });
  }
  console.log(feedback, "feedback");
  const questions = [
    {
    value : 1 ,
    label :  "How would you rank our company's overall satisfaction?"
    },
    {
    value : 2 ,
    label :"How would you rate our customer service?"
  },
  {
  value : 3 ,
  label :"How satisfied are you with our product quality?"
},
{
  value : 4 ,
  label : "How likely are you to recommend us to others?"
},
{
  value : 5,
  label : "How responsive were we to your inquiries?"
    }
  ];
  return (
    <Paper elevation={0} sx={{ p: 3, pb: 0 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" paddingTop={0} paddingBottom={3} >
              <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "18px" }} component="h2">
                Client Feedback Report
              </Typography>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Autocomplete
                  sx={{ width: "190px" }}
                  fullWidth
                  value={
                    departmentList?.find(
                      (option) => option.id == selectedDepartmentId
                    ) ?? null
                  }
                  id="department-autocomplete"
                  options={departmentList}
                  getOptionLabel={(option) => option.name}
                  disabled={disabledDepartments}
                  onChange={async (event, value) => {
                    setfiltersSelected(true)
                    setDepartmentId(value?.id ?? "");
                    await getActiveProjects(value?.id ?? "");
                    GetClientFeedBackAverageReport(selectedProjectId, value?.id ?? "", fromDate, monthStatus);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="department-textfield"
                      name="element.name"
                      variant="outlined"
                      value={selectedDepartmentId}
                    />
                  )}
                />
                <Autocomplete
                  sx={{ width: "190px" }}
                  disabled={!selectedDepartmentId}
                  fullWidth
                  value={
                    projects?.find((option) => option.id == selectedProjectId) ??
                    projectData
                  }
                  id="project-autocomplete"
                  options={projects}
                  getOptionLabel={(option) => option.projectName}
                  onChange={async (event, value) => {
                    setProjectId(value?.id ?? "");
                    setfiltersSelected(true)
                    GetClientFeedBackAverageReport(value?.id ?? "", selectedDepartmentId, currentYearString, monthStatus);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="project-textfield"
                      name="element.name"
                      variant="outlined"
                      value={selectedProjectId}
                    />
                  )}
                />
                <Box>
                  <TextField
                    sx={{ minWidth: 150 }}
                    id="outlined-basic"
                    select
                    value={monthStatus}
                    variant="outlined"
                    onChange={
                      handleMonthChange
                    }
                  >
                    {options1.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Autocomplete
                  value={
                    Years?.find(
                      (option: any) => option.id == fromDate
                    ) ?? null
                  }
                  sx={{
                    mr: 1,
                    lineHeight: "0.80em",
                    width: "120px",
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  fullWidth
                  id="outlined-basic"
                  options={Years}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    setfiltersSelected(true)
                    setfromDate(value?.label ?? currentYearString)
                    GetClientFeedBackAverageReport("", "", value?.label ?? currentYearString, monthStatus);
                    setPage(0);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      name="selectedResourceId"
                      variant="outlined"
                      value={selectedMonth}
                      fullWidth
                    />
                  )}
                />
                {filtersSelected && (
                  <Button
                  onClick={() => {
                    setfiltersSelected(false);
                    setfromDate(currentYearString);
                    setMonthStatus(getCurrentMonth());
                    setMonth(monthName); 
                    GetClientFeedBackAverageReport("", "", currentYearString, currentMonthNo);
                    setDepartmentId("");
                    setProjectId("");
                    setSelectedMonth(currentMonthNo);
                  }}
                  color="warning"
                  variant="contained"
                  sx={{ ml: 0.5 }}
                >
                  Clear
                </Button>
                
                )}
              </Box>
            </Stack>
            <Grid container spacing={2}>
              {feedback.map((feedbackItem: any) => {
                const sortedClientFeedback = feedbackItem.clientFeedback.sort(
                  (a: any, b: any) => parseFloat(a.ratingValue) - parseFloat(b.ratingValue)
                );
                return (
                  <>
                    <Box sx={{ marginBlock: "25px", width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                      <React.Fragment key={feedbackItem.id}>
                        {sortedClientFeedback.map((item: any, index: number) => (
                          <React.Fragment key={index}>
                            <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "32px" }}>
                              <Box><TableCell sx={{ border: "0" }}>{item.clientFeedbackType}.</TableCell>
                                <TableCell sx={{ border: "0" }}>
                                  {/* {questions[index]} */}
                                  {questions.find(question => question.value === item.clientFeedbackType)?.label}
                                  </TableCell>
                                  </Box>
                              <Box sx={{ backgroundColor: '#E7FFF0', borderRadius: '5px', padding: '5px 10px', width: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                {item.ratingValue ? `${parseFloat(item.ratingValue).toFixed(1)}` : '0'}
                                <Icons.StarFilled />
                                {/* {rating ? <Icons.StarFilled /> : null} */}
                              </Box>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    </Box>
                  </>
                );
              
            })}           
             {feedback.length === 0 && (
                  <Box sx={{textAlign: "center", width: "100%"}}>
                    <NotFound NotfoundText="No Result Found" />
                  </Box>
              )}                                
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
