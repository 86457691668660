import React, { useState, useEffect, useCallback, useRef } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import SearchBar from "../components/styledComponent/SearchBar";
import { Autocomplete, InputAdornment, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import * as Icons from "../assests/icons/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddMore from "../assests/icons/AddMore.svg";
import ListItemIcon from "@mui/material/ListItemIcon";
import { IssueTypeIcon } from "./boardscreens/IconsLabel";
import AppConstants from "../config/AppConstants";
import BubbleArrow from "../assests/icons/BubbleArrow.png";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Fab, { FabProps } from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import ProjectDetails from "../pages/boardscreens/ProjectDetails";
import Modal from "@mui/material/Modal";
import CreateIssue from "./boardscreens/CreateIssue";
import KanbanBoard from "./boardscreens/KanbanBoard";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CreateSprint from "./boardscreens/CreateSprint";
import CreateMilestone from "./boardscreens/CreateMilestone";
import TicketDetails from "./boardscreens/TicketDetails";
import theme from "../theme/theme";
import { toast } from "material-react-toastify";
import { issueManagementService } from "../service/issues-management-service";
import { masterDataService } from "../service/master-service/master-data-service";
import { projectManagementServive } from "../service/project-management-service";
import { userManagementServive } from "../service/user-management-service";
import { boardDashboardServive } from "../service/BoardDashboardServive";
import NotFound from "../components/styledComponent/NotFound";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { faL } from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { commonFunctions } from "../service/common-services/common-functions";
import { useSelector } from "react-redux";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import Backlogs from "../pages/boardscreens/BackLogs";
import Notes from "../components/styledComponent/Note";
import { eSprintStatus } from "../Enum/eSprint";
import { backlogService } from "../service/Backlog-services";
import { eMasterDropdown } from "../Enum/eMasterDropdown";
import { commonServive } from "../service/common-services/service";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import InfoPopover from "../components/CommonInfoComponent";
import ListComponent from "../components/ListComponent";
import { settingManagementServive } from "../service/setting-board-service";
import projecttrailor, {ProjectTrailerArrayObject } from "../Enum/ProjectTrailor";
import { projectTrailorService } from "../service/ProjectTrailor-service";
const Boardstop = {
  alltypes: {
    "& .MuiSelect-select": {
      fontSize: "16px",
      //paddingLeft: 0,
      fontWeight: 500,
      minWidth: 130,
      // width:{xs:'100%', md:'130px'},
      color: theme.palette.primary.dark,
      lineHeight: "inherit",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      // pl: 0,
    },
    width: { xs: "100%", sm: "130px" },
  },
  searchbartop: {
    "& .searcg-field": {
      width: { xs: "100%", md: "auto" },
      // mt:2,
    },
    pr: 0.5,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //  fontFamily: "sans-serif",
    width: 42,
    height: 42,

    "&.MuiButtonBase-root": {
      borderRadius: "50%",
      marginLeft: "-5px",
      fontSize: "16px",
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.grey[50],
      },
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
      color: theme.palette.grey[50],
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {},
    "&:first-of-type": {
      marginLeft: "0px",
    },
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
};

// const styles = {
//   userAvatar: {
//     justifyContent: 'flex-end',
//     "& .MuiAvatar-root": {
//       textTransform: "Uppercase",
//       fontFamily: 'sans-serif',
//       backgroundColor: theme.palette.primary.light,
//       color: theme.palette.primary.main,
//       borderColor: '#fff',
//       fontWeight: 500,
//       width: '36px',
//       height: '36px',
//       cursor: 'pointer',
//       '&:first-child': {
//         backgroundColor: 'transparent',
//         color: theme.palette.primary.dark,
//       }
//     }
//   },

// }

// const drawerWidth = {
//   maxWidth: "800px",
// };
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const selectHeading = {
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

const FabButton = styled(Fab)<FabProps>(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  padding: 0,
  position: "absolute",
  left: 0,
  justifyContent: " flex-start",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

const styleCross = {
  position: "absolute" as "absolute",
  right: "12px",
  top: "16px",
};

const Modalstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1060,
  width: "100%",
  maxHeight: "98%",
  overflowX: "auto",
};
const options = ["All Departments"];

interface IprojectDetail {
  id: string;
  projectName: string;
  isTailoringProcessed:boolean;
  projectDetails: {
    pin: string;
    keyWords: string;
    channelId: string;
    costing: string;
    pinEfforts: string;
    pinAmount: string;
    projectDescription: string;
    wbsPath: string;
  };
  colGetClientModel: {
    id: string;
    email: string;
    skypeId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    country: string;
    companyName: string;
    mobileNumber: string;
    code: string;
    alterNateNumber: string;
    companyDescription: string;
  };
  pointOfContacts: [
    {
      email: string;
      skypeId: string;
      firstName: string;
      lastName: string;
      middleName: string;
      mobileNumber: string;
      code: string;
      alterNateNumber: string;
    }
  ];
  projectOwnerId: string;
  projectDepartment: {
    departmentId: string;
  };
  paymentDetails: {
    costingType: string;
    upfrontPayment: string;
    currency: string;
    billingType: string;
    ratePerHour: string;
    departmentRatePerHours: string;
  };
  skillSets: [
    {
      skillId: string;
      name: string;
      level: string;
    }
  ];
  status: boolean;
  createdDate: Date;
  sprintId: string;
  sprintName: string;
  startDate: Date;
  endDate: Date;
}

const dummy = [
  {
    value: "Story",
    label: "Story",
  },
  {
    value: "Bug",
    label: "Bug",
  },
  {
    value: "Epic",
    label: "Epic",
  },
  {
    value: "Task",
    label: "Task",
  },
];

enum StatusValue {
  Todo = 1,
  InProgress = 2,
  Completed = 3,
}

enum SprintStatus {
  Todo = 1,
  InProgress = 2,
  Completed = 3,
}
export default function Board(props: Props, { modalCloseFunction }: any) {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector(
    (state: any) => state.ProjectPermissionApp.ProjectPermission
  );

  const [buttonDisable1, setButtonDisable1] = useState(false);
  const { id, id1 } = useParams();
  console.log(id1, "boolean")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [modalCreateIssueOpen, setModalCreateIssue] = React.useState(false);
  const [projects, setProject] = useState<any>([]);
  const [projectType, setProjectType] = React.useState<any>("");
  const [selectedProjectId, setProjectId] = useState(id ?? "");
  const [selectedProjectName, setProjectName] = useState("");
  const open = Boolean(anchorEl);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectDetailHook, setProjectDetailHook] = useState<IprojectDetail>();
  const [isMilestoneOver, setIsMilestoneOver] = useState(false);
  const [sprintId, setsprintId] = useState<string>("");
  const [showBacklogPage, setshowBacklogPage] = useState<boolean>(true);
  const [userId, setUserId] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTicket, setSearchTicket] = useState<string>("");
  const [searchGlobalHook, setSearchGlobalHook] = React.useState("");
  const childRef = useRef<any>(null);
  const BackchildRef = useRef<any>(null);
  const [spritList, setSpritList] = useState<any[]>([]);

  const [searchResult, setsearchResult] = useState<any[]>([]);
  const [isListOpen, setIsListOpen] = useState(false);
  const [IsSearchValue, setIsSearchValue] = useState(false);
  const [issueDetail, setIssues] = useState<any>([]);
  const [Isunassignee, setIsunassignee] = React.useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [showfiveUsers, setshowfiveUsers] = useState<any>([]);
  const [greaterThanFiveUsers, setgreaterThanFiveUsers] = useState<any>([]);
  const [kanbanstatus, setKanbanstatus] = React.useState(false);
  const [currentId, setCurrentId] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [searchvalue, setsearchvalue] = useState("");

  const [toggleLoader, setToggleLoader] = useState(false);
  const [elements, setElements] = useState<any>(null);
  const [elementsLength, setelEmentsLength] = useState<any>(0);
  const [globalSearchResult, setglobalSearchResult] = useState<any>([]);
  const [spilloverText, setspilloverText] = React.useState("");
  const [validationMessage, setValidationMessage] = useState<any>("");

  const ArtifactsRequestStatus = [
           
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Approved",
    },
    {
      value: 3,
      label: "Rejected",
    },
    {
        value: 4,
        label: "ApprovedAndRejected",
      }
  ];

  const getAllTicketForBoardFilters = useCallback(
    (projectId: string) => {
      setToggleLoader(true);
      boardDashboardServive
        .getAllTicketForBoardFiltersAsync({
          projectId: projectId,
          sprintId: sprintId,
          search: searchTicket,
          issueType: projectType,
          userIds: userId,
          isUnassignee: Isunassignee,
        })
        .then((response: any) => {
          if (response?.data?.isError) {
            setToggleLoader(false);
          } else {
            var arr: [] = response?.data?.ticketOutputModels;
            setElements(arr);
            setToggleLoader(false);
          }
        });
    },
    [sprintId, userId, Isunassignee, searchTicket, projectType]
  );
  const GetAllTicketForBacklogFiltersAsync = useCallback(
    (projectId: string, searchvalue: string) => {
      setToggleLoader(true);
      backlogService
        .GetAllTicketForBacklogFiltersAsync({
          projectId: projectId,
          sprintId: sprintId,
          search: showBacklogPage === true ? searchvalue : searchTicket,
          issueType: projectType,
          userIds: userId,
          isUnassignee: Isunassignee,
        })
        .then((response: any) => {
          if (response?.data?.isError) {
            setToggleLoader(false);
          } else {
            var arr: [] = response?.data?.ticketOutputModels;
            setElements(arr);
            setToggleLoader(false);
          }
        });
    },
    [sprintId, userId, Isunassignee, searchTicket, projectType]
  );

  const backlogRefrest = () => {
    if (BackchildRef != null && BackchildRef.current != null) {
      BackchildRef.current.doRefresh();
    }
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  let objSelectSprint = {
    id: "",
    sprintName: "",
    projectId: "",
    goal: "",
    duration: "",
    endDate: "",
    startDate: "",
    status: 0,
  };

  const [pendingTicket, setPendingTickets] = React.useState<any>("");
  const [completedTicket, setCompletedTicket] = React.useState<any>("");
  const getSearchDetails = async (id: any) => {
    await boardDashboardServive
      .getPendingTicketofSprint(id)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setCompletedTicket(response?.data);
          let data = response?.data;
          delete data.closed;
          let result = Object?.values(data)?.reduce(
            (a: any, b: any = 0) => a + b
          );
          setPendingTickets(result);
        }
      });
  };
  const [openSprintAlert, setopenSprintAlert] = React.useState(false);
  const handleClickOpenAlert = () => {
    setopenSprintAlert(true);
    getSearchDetails(sprintId);
  };

  const handleClickCloseAlert = () => {
    setopenSprintAlert(false);
    if (sprintId != null && sprintId != "") {
      updateSelectedSprint(
        spritList.find((sprint: any) => sprint.id === sprintId)
      );
    }
  };
  const [SelectSprint, setSelectSprint] = React.useState(objSelectSprint);
  const updateSelectedSprint = (obj: any) => {
    if (obj && obj.id != "") {
      objSelectSprint.id = obj.id;
      objSelectSprint.sprintName = obj.sprintName;
      objSelectSprint.projectId = obj.projectId;
      objSelectSprint.goal = obj.goal;
      objSelectSprint.duration = obj.duration;
      objSelectSprint.endDate = obj.endDate;
      objSelectSprint.startDate = obj.startDate;
      objSelectSprint.status = obj.status;
      setSelectSprint(objSelectSprint);
    }
  };

  const getAllSprintByProjectId = useCallback(
    (projectId: string, sId: string = "") => {
      if (id !== "") {
        setSpritList([]);
        boardDashboardServive
          .getAllSprintByProjectId({
            projectId: projectId,
          })
          .then((response: any) => {
            const staticOption = [
              {
                id: "",
                sprintName: "Backlog",
              },
            ];
            setSpritList([...staticOption, ...response?.data] || []);
            if (sId && sId != null && sId != "") {
              let objSprint = response?.data.find(
                (sprint: any) => sprint.id === sId
              );

              updateSelectedSprint(objSprint);
              setshowBacklogPage(false);
            } else {
              setshowBacklogPage(true);
            }
          });
      }
    },
    [selectedProjectId]
  );

  const getAllUserListByProjectId = useCallback(() => {
    setshowfiveUsers([]);
    setgreaterThanFiveUsers([]);
    setIsunassignee(false);
    if (selectedProjectId !== "") {
      userManagementServive
        .getAllUsersByProjectId({
          projectId: selectedProjectId,
        })
        .then((response: any) => {
          setUserList(response?.data || []);
          setUserId([]);
          if (response?.data.length > 0) {
            let sortedData = response.data.sort((a: any, b: any) =>
              a.name < b.name ? -1 : 1
            );
            let fiveUserArray: any = [];
            let greaterfiveUserArray: any = [];
            sortedData.map((user: any, index: number) => {
              if (index < 4) {
                fiveUserArray.push(user);
              } else {
                greaterfiveUserArray.push(user);
              }
            });
            setshowfiveUsers(fiveUserArray);
            setgreaterThanFiveUsers(greaterfiveUserArray);
          }
        });
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (id1) {
      const fetchData = async () => {
        await getActiveProjects();
        if (id1) {
          setOpen(true);
        }
      };

      fetchData();
    }
    else {
    //  handleNewProjectDialogOpen()
      getActiveProjects();
    }

  }, []);
  useEffect(() => {
    if (_permission.page_Board_Access !== true) {
      navigation("/dashboard");
    }
    getAllPredefinedArtifacts();
    if (id && id != "") {
      setProjectId(id);
    }
    getAllUserListByProjectId();
    //getProjectBoardWork();
    //getAllTicketForBoardFilters();
  }, [id, selectedProjectId]);

  useEffect(() => {
    if (sprintId === "") {
      GetAllTicketForBacklogFiltersAsync(id ?? selectedProjectId, "");
    } else {
      getAllTicketForBoardFilters(id ?? selectedProjectId);
    }
  }, [
    id,
    selectedProjectId,
    sprintId,
    userId,
    Isunassignee,
    searchTicket,
    projectType,
  ]);

  const getActiveProjects = async () => {
    let prm = { module: eMasterDropdown.Project, status: "active" };
    await commonServive.getDropdownData(prm).then(async (response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setProject(response.data);
        var projectId = selectedProjectId;
        if (id && id != "") {
          projectId = id;
          setProjectId(id);
          setSearchTicket("");
          setProjectType("");
          setVisible(false);
        } else {
          projectId = response.data[0].id;
        }
        setProjectId(projectId);
        setVisible(false);
        setProjectType("");
        // GetIssueDetailByProject(projectId);
        setSelectedProject(projectId);
        if (id1) {
          navigation("/board/" + projectId + "/1");
        }
        else {
          navigation("/board/" + projectId);
        }
        await 
        await getProjectDetails(projectId);
      }
    });
  };
  const getProjectDetails = async (id: any) => {
    try {
      await projectManagementServive
        .getProjectDetailsById(id)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            debugger
            getProjectArtifactsDetailsById(id)
            setProjectDetailHook(response.data);
            setsprintId(response.data.sprintId ?? "");
            console.log(response?.data?.lstMilestoneinfo, "response?.data?.lstMilestoneinfo")
            let mileresult = response?.data?.lstMilestoneinfo.filter((item: any) => item.status == 2)
            if (mileresult.length > 0) {
              if (mileresult[0]?.updatedEndDate != null && mileresult[0]?.updatedEndDate != undefined) {
                let xyz = new Date(dayjs(mileresult[0]?.updatedEndDate).format("MM/DD/YYYY")) < new Date(dayjs().format("MM/DD/YYYY"))
                setIsMilestoneOver(xyz);
                console.log(xyz, "milestatus", isMilestoneOver)
              }
              else {
                let xyz = new Date(dayjs(mileresult[0]?.endDate).format("MM/DD/YYYY")) < new Date(dayjs().format("MM/DD/YYYY"))
                setIsMilestoneOver(xyz);
                console.log(xyz, "milestatus", isMilestoneOver)
              }
            }
            else { setIsMilestoneOver(false); }
            if(!response?.data?.isTailoringProcessed && response?.data?.status ==2){
              handleNewProjectDialogOpen()

            }
            await getAllSprintByProjectId(id, response.data.sprintId);
            setSelectSprint(objSelectSprint);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setErrorMessage(err);
        toast.warning(err, {
          onOpen: () => setButtonDisable1(true),
          onClose: () => setButtonDisable1(false),
        });
      });
    }
  };

  const getProjectArtifactsDetailsById = async (id: any) => {
    debugger
    try {
      await projectTrailorService
        .getRaisedProjectArtifactsRequestByProId(id,1)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            if(response?.data){
              setProjectTailorData(response?.data?.artifacts)
            }
             setIsProjectTrailorRaised(true)
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setIsProjectTrailorRaised(false)
        setErrorMessage(err);
       // toast.warning(err);
      });
    }
  };
  const AddProjectArtifactsRequest= async (data: any) => {
    try {
      debugger
      let param ={
        projectId:selectedProjectId,
        artifacts:projectTailorData
      }
      await projectTrailorService
        .addProjectArtifactsRequest(param)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            toast.success("Request raised successfully")
            setIsProjectTrailorRaised(true);
          
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setErrorMessage(err);
        toast.warning(err);
      });
    }
  };

  const getAllPredefinedArtifacts= async () => {
    try {
      await projectTrailorService
        .getAllPreDefinedArtifacts()
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            debugger
            let artiArray: any = [];
            response?.data.forEach((item: any) => {
              debugger
              let obj = { artifactId: item?.id, artifactName: item?.name, isFollowed: false, reason: "" }
              artiArray.push(obj)
            })
            console.log(artiArray, "response?.data.foreach")
            setProjectTailorData(artiArray);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setErrorMessage(err);
        toast.warning(err);
      });
    }
  };


  const handleCancel = () => {
    setUserId([]);
    setProjectType("");
  };

  const handlekanbanstatus = async () => {
    debugger
    setTicketOpen(false);
    setOpen(false);
   // getProjectDetails(id ?? selectedProjectId)
    if (childRef != null && childRef.current != null) {
      childRef.current.doRefresh();
    }
    if (BackchildRef != null && BackchildRef.current != null) {
      BackchildRef.current.doRefresh();
    }
  };

  const [visible, setVisible] = useState(false);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Drawer

  const [drawerOpen, setOpen] = useState(false);

  const [ticketdrawerOpen, setTicketOpen] = useState(false);
  // Modal

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  // Create Sprint
  const [sprintOpen, setsprintOpen] = React.useState(false);
  const [editSprintById, setEditSprintId] = React.useState(false);

  //Retro Open
  const [sprintRetroOpen, setSprintRetroOpen] = React.useState(false);
  const [sprintFutureOpen, setSprintFutureOpen] = React.useState(false);
  const [retroComment, setRetroComment] = React.useState("");

  
//// New project changes///
const [projectTailorData, setProjectTailorData] = useState<any[]>([]);
const [infoDialogOpen, setInfoDialogOpen]=useState(false)
const [isProjectTrailorRaised, setIsProjectTrailorRaised] = useState(false);
const handleNewProjectDialogClose=()=>{
  setInfoDialogOpen(false)
}
const handleNewProjectDialogOpen=()=>{
  setInfoDialogOpen(true)
}


console.log(projectTailorData,"projectTailorData")

const [projectTrailorvalidationerror, setProjectTrailorvalidationerror] = useState<{ [key: string]: boolean }>({});

const handleSubmitTrailor = () => {
  const newErrors: { [key: string]: boolean } = {};
  let isValid = true;

  // Validate each checkbox
  projectTailorData.forEach((label: any) => {
    debugger
    const checkboxChecked = label?.isFollowed;
    const reason = label?.reason || '';

    if (!checkboxChecked) {
      // Checkbox is not checked, so reason is required
      if (!reason.trim()) {
        newErrors[label?.artifactId] = true; // Mark error for missing reason
        isValid = false;
      } else {
        newErrors[label?.artifactId] = false; // Clear errors for filled reason
      }
    } else {
      newErrors[label?.artifactId] = false; // Clear errors for checked checkbox
    }
  });

  setProjectTrailorvalidationerror(newErrors);
  if (isValid) {
   // toast.success("All Clear dude ......")
     AddProjectArtifactsRequest("");
  }
};
console.log(projectTrailorvalidationerror,"projectTrailorvalidationerrorprojectTrailorvalidationerror")

////////


  const handleFutureSprintClose = () => {
    setSprintFutureOpen(false)
  }

  const handleFutureSprintOpen = () => {
    setSprintFutureOpen(true)
  }
  const addCommentstoSprintRetro = async (iscontinue: any) => {
    let param = {
      id: sprintId,
      comment: retroComment,
      isContinue: iscontinue
    }
    try {
      await boardDashboardServive
        .addRetrocomment(param)
        .then((response: any) => {
          if (response.data.isError) {
            handleFutureSprintOpen()
            handleRetroClose()
          } else {
            updateSprintStatus(
              SelectSprint.status == 1 ? 2 : SelectSprint.status == 2 ? 3 : 3
            );
            handleClickCloseAlert();
          }
        });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        handleFutureSprintOpen()
        handleRetroClose()
        // toast.warning(err);
      });
    }
  };
  console.log(SelectSprint.status, "SelectSprint.status")

  const handleRetroClose = () => {
    setSprintRetroOpen(false);
    setValidationMessage("");
  }
  const handleRetroOpen = () => {
    setSprintRetroOpen(true)
  }

  let state = { sprintId: sprintId ?? "" };
  let pageTitle = "Create Sprint";
  if (editSprintById === true) {
    pageTitle = "Update Sprint";
  }

  const handleClickOpen = (autofill: boolean) => {
    if (!autofill) {
      objSelectSprint.id = "";
      objSelectSprint.sprintName = "";
      objSelectSprint.projectId = "";
      objSelectSprint.goal = "";
      objSelectSprint.duration = "";
      objSelectSprint.endDate = "";
      objSelectSprint.startDate = "";
      objSelectSprint.status = 0;
      setSelectSprint(objSelectSprint);
    }
    setsprintOpen(true);
  };

  const handleProjectClose = () => {
    setOpen(false);
  };
  const handleSprintClose = () => {
    setsprintOpen(false);
    if (sprintId != null && sprintId != "") {
      updateSelectedSprint(
        spritList.find((sprint: any) => sprint.id === sprintId)
      );
      setEditSprintId(false);
    }
    getAllSprintByProjectId(selectedProjectId, sprintId);
  };

  // Create Mile
  const [mileOpen, setMileOpen] = React.useState(false);

  const handleMileClickOpen = () => {
    setMileOpen(true);
  };

  const handleMileClose = () => {
    setMileOpen(false);
  };

  const handleCreateIssueModalOpen = () => {
    if (isMilestoneOver && !showBacklogPage) {
      toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
    }
    else {
      setModalCreateIssue(true);
    }
  };
  const handleCreateIssueModalClose = () => {
    //getActiveProjects();
    setModalCreateIssue(false);
    // if(childRef!=null && childRef.current!=null){
    //   childRef.current.doRefresh();
    // }
    // if(BackchildRef!=null && BackchildRef.current!=null){
    //   BackchildRef.current.doRefresh();
    // }
  };
  const handleCreateIssueModalCloseOpen = () => {
    setModalCreateIssue(false);
    setModalCreateIssue(true);
  };
  const handleTicketDetail = async (value: any) => {
    setIsListOpen(false);
    setSearchGlobalHook("");
    setToggleLoader(true);
    await masterDataService
      .getAllActiveSummaryWithProjectId(value.ticketNumber, value?.projectId)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          if (isMilestoneOver && !showBacklogPage) {
            setToggleLoader(false);
            toast.warn("Oops! The Milestone date has arrived. Please create a new milestone or extend the current one.")
          }
          else {
            setIssues(response.data);
            setTicketOpen(true);
            setToggleLoader(false);
            if (value.isAdded) {
              const existing = [...elements];
              const currentStatus = existing.find(
                (a: any) => a.status === value.status
              );
              currentStatus.tickets.push(
                response.data.childIssues[response.data.childIssues.length - 1]
              );
              setElements(existing);
            }
          }
        }
      });
  };

  const setProductId = async (e: any) => {
    setProject(e.currentTarget.value);
    await getProjectDetails(e.currentTarget.value);
  };

  // -----------------------------

  const openSprint = Boolean(anchorEl);
  const handleClickSprint = (event: React.MouseEvent<HTMLButtonElement>) => {
    //  if(isMilestoneOver){
    // toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")}
    // else{
    setAnchorEl(event.currentTarget);
  }
  // };
  const handleCloseSprint = () => {
    setAnchorEl(null);
    setEditSprintId(false);
  };

  // -----Delete Sprint  Programming---------------------------------
  // let state = { id: id ?? "" };
  // let pageTitle = "Add Leave";
  // if (state.id.length > 0) {
  //   pageTitle = "Update Leave";
  // }

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpen = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const deleteSprintData = async (id: any) => {
    // setOpenDelete(true);
    setCurrentId(sprintId);
    handleOpen();
  };

  const DeleteSprint = async () => {
    setOpenDelete(false);
    try {
      await boardDashboardServive
        .deleteSprint(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Sprint Deleted successfully.");
            getAllSprintByProjectId(selectedProjectId);
            handleCancel();
            setSelectSprint(objSelectSprint);
            if (childRef != null && childRef.current != null) {
              childRef.current.doRefresh();
            }
            if (BackchildRef != null && BackchildRef.current != null) {
              BackchildRef.current.doRefresh();
            }
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const updateSprintStatus = async (status: any) => {
    setButtonDisable1(true);
    try {
      var abc = {
        sprintId: sprintId,
        status: status,
      };
      await boardDashboardServive.sprintStatus(abc).then((response: any) => {
        if (response.data.isError) {
        } else toast.success("Status Updated  successfully.");
        setButtonDisable1(false);
        getAllSprintByProjectId(selectedProjectId, sprintId);
        childRef.current.doRefresh();
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {
          onOpen: () => setButtonDisable1(true),
          onClose: () => setButtonDisable1(false),
        });
      });
    }
  };

  // ------------------------------------------------- Select User
  const [anchorUserEl, setAnchorUserEl] = React.useState<null | HTMLElement>(
    null
  );
  const openUserList = Boolean(anchorUserEl);
  const handleClickUserList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserEl(event.currentTarget);
  };
  const handleCloseList = () => {
    setAnchorUserEl(null);
  };

  const [alignment, setAlignment] = React.useState("left");
  const [formats, setFormats] = React.useState(() => ["italic"]);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    setFormats(newFormats);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  const [show, globalsearchpopup] = useState(false);
  const [isBacklog, setisBacklog] = useState(false);
  // help button hide and show
  const [isVisible, setIsVisible] = useState(false);

  const handleClickOpenHelp = () => {
    setIsVisible(!isVisible);
  };
  const handleClickCloseHelp = () => {
    setIsVisible(false);
  };
  const globalSearchInProject = (searchTicket: string, projectId: any) => {
    try {
      issueManagementService
        .globalSearchInProject({
          issueType: "",
          projectId: projectId,
          status: "",
          userId: "",
          reporter: "",
          search: searchTicket,
        })
        .then((response: any) => {
          if (response?.data?.isError) {
          } else {
            setglobalSearchResult(response?.data);
            setIsListOpen(true);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const triggerSearch = async (newValue: string) => {
    GetAllTicketForBacklogFiltersAsync(selectedProjectId, newValue);
  };

  const triggerGlobalSearch = async (newValue: string) => {
    if (!newValue) {
      setIsListOpen(false);
      return false;
    } else {
      setSearchGlobalHook(newValue);
      globalSearchInProject(newValue, selectedProjectId);
    }
  };
  const [searchInput, setSearchInput] = useState("");

  const getSpillOverValue = async () => {
    await settingManagementServive
      .GetProjectBoardWorkById(id)
      .then((response: any) => {

        if (response?.data.spillOver == null) {
          toast.warning("Please Select SpillOver");
        }
        else if (response?.data.spillOver === 'Yes') {
          let text = 'These tickets will be moved to the next sprint as per spillover settings.';
          setspilloverText(text);
        }
        else if (response?.data.spillOver === 'No') {
          let text = 'These tickets will not be moved to the next sprint as per spillover settings.'
          setspilloverText(text);
        }
        else {
        }
      });
  };
  return (
    <Box
      sx={{
        [theme.breakpoints.up("sm")]: {
          height: "calc(100vh - 100px)",
        },
      }}
    >
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Projects</Typography>
        <Typography color="text.primary">Board</Typography>
      </Breadcrumbs>
      {projects && projects.length > 0 ? (
        <>
          <Paper elevation={0} sx={{ p: 2, pb: 1, position: "relative" }}>
            <Stack
              justifyContent={"flex-start"}
              alignItems={"self-start"}
              sx={{ width: "100%", position: "relative" }}
            >
              <InfoPopover onInput={"Board"} />
            </Stack>
            {selectedProjectId != null &&
              selectedProjectId != "" &&
              (["left", "right", "top", "bottom"] as const).map(
                (anchor, idx) => (
                  <FabButton
                    key={"Bubble_" + idx}
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                  >
                    <img src={BubbleArrow} />
                  </FabButton>
                )
              )}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              sx={{ pl: 2, mt: 2 }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "start", sm: "center" }}
                  spacing={0.5}
                >
                  <Box sx={{ minWidth: 220, mr: 1 }}>
                    <Autocomplete
                      disableClearable={true}
                      value={
                        projects?.find(
                          (option: any) => option.id == selectedProjectId
                        ) ?? ""
                      }
                      fullWidth
                      sx={{
                        lineHeight: "0.80em",
                      }}
                      id="outlined-basic"
                      options={projects}
                      getOptionLabel={(option) => option.name}
                      onChange={async (event, value) => {
                        setVisible(false);
                        navigation("/board/" + value?.id);
                        setProjectId(value?.id);
                        getProjectDetails(value?.id);
                        setSearchTicket("");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          name="element.name"
                          variant="outlined"
                          value={selectedProjectId}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <TextField
                      id="outlined-basic"
                      select
                      //     label="Type"
                      value={projectType !== "" ? projectType : " "}
                      variant="outlined"
                      sx={{
                        "& .MuiSelect-select": {
                          fontSize: "16px",
                          //paddingLeft: 0,
                          fontWeight: 500,
                          minWidth: 130,
                          color: theme.palette.primary.dark,
                          lineHeight: "inherit",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          // pl: 0,
                        },
                      }}
                    >
                      <MenuItem
                        key="0"
                        value=" "
                        onClick={(event) => {
                          setProjectType("");
                        }}
                      >
                        All Types
                      </MenuItem>
                      {/* {dummy.map((option, index) => ( */}
                      {AppConstants.MasterData.Issue.IssueType.map(
                        (option, index) => (
                          <MenuItem
                            key={option.value}
                            value={option.label}
                            onClick={(event) => {
                              // setspillOverSetting(dummy[index].label);
                              setVisible(true);
                              setProjectType(dummy[index].label);
                            }}
                          >
                            <>
                              <IssueTypeIcon issueIconType={option.icon} />
                              &nbsp;
                              {option.label}
                            </>
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Box>

                  <Stack direction="row" alignItems="center">
                    <StyledToggleButtonGroup
                      size="small"
                      value={formats}
                      onChange={handleFormat}
                      aria-label="text formatting"
                    // sx={{"& .MuiToggleButtonGroup-grouped":{
                    //   backgroundColor: "red",
                    //   color: theme.palette.grey[50]
                    // }}}
                    >
                      {showfiveUsers.length > 0 && (
                        <Tooltip title="UnAssignee">
                          <ToggleButton
                            className={
                              Isunassignee == true ? "Mui-selected" : ""
                            }
                            onClick={() => {
                              setIsunassignee(!Isunassignee);
                              setVisible(true);
                            }}
                            value="UnAssignee"
                            aria-label="UnAssignee"
                          >
                            {commonFunctions.getFirstTwoLettersFromString(
                              "UnAssignee"
                            )}
                          </ToggleButton>
                        </Tooltip>
                      )}

                      {showfiveUsers.map((user: any, index: number) => (
                        <Tooltip title={user.name}>
                          <ToggleButton
                            className={
                              userId.filter((uId) => uId == user.id).length > 0
                                ? "Mui-selected"
                                : ""
                            }
                            onClick={() => {
                              let uIds = [...userId];
                              if (
                                uIds.filter((uId) => uId == user.id).length ===
                                0
                              ) {
                                uIds.push(user.id);
                              } else {
                                uIds = uIds.filter((uId) => uId !== user.id);
                              }
                              if (uIds.length > 0) {
                                setVisible(true);
                              }
                              setUserId(uIds);
                              if (uIds.length > 0) {
                                setVisible(true);
                              }
                            }}
                            value={user.id}
                            aria-label={user.id}
                          >
                            {commonFunctions.getFirstTwoLettersFromString(
                              user.name
                            )}
                          </ToggleButton>
                        </Tooltip>
                      ))}
                    </StyledToggleButtonGroup>

                    {greaterThanFiveUsers.length > 0 && (
                      <Avatar
                        component={Button}
                        aria-controls={openUserList ? "menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openUserList ? "true" : undefined}
                        onClick={handleClickUserList}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          minWidth: "inherit",
                          "&:hover": {
                            bgcolor: theme.palette.primary.dark,
                          },
                        }}
                      >
                        <span>+{greaterThanFiveUsers.length}</span>
                      </Avatar>
                    )}
                    <Menu
                      id="menu"
                      anchorEl={anchorUserEl}
                      open={openUserList}
                      onClose={handleCloseList}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {greaterThanFiveUsers.map((user: any) => (
                        <MenuItem
                          key={user.id}
                          value={user.id}
                          onClick={(event) => {
                            let uIds = [...userId];
                            if (
                              uIds.filter((uId) => uId == user.id).length === 0
                            ) {
                              uIds.push(user.id);
                            } else {
                              uIds = uIds.filter((uId) => uId !== user.id);
                            }
                            setUserId(uIds);
                            if (uIds.length > 0) {
                              setVisible(true);
                            }
                          }}
                        >
                          <Checkbox
                            checked={
                              userId.filter((uId) => uId == user.id).length > 0
                                ? true
                                : false
                            }
                          />
                          {user.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>

                  {visible && (
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      sx={{
                        height: " fit-content",
                      }}
                      onClick={(event) => {
                        handleCancel();
                        setVisible(false);
                        setIsunassignee(false);
                        setUserId([]);
                        //setsprintId(projectDetailHook?.sprintId??"")
                        //setSelectSprint(objSelectSprint);
                        // if(childRef!=null && childRef.current!=null){
                        //   childRef.current.doRefresh();
                        // }
                        // if(BackchildRef!=null && BackchildRef.current!=null){
                        //   BackchildRef.current.doRefresh();
                        // }
                      }}
                    >
                      Clear
                    </Button>
                  )}
                  <Box sx={{ flexGrow: 1 }} />
                  {isListOpen}
                  <Box sx={Boardstop.searchbartop}>
                    <SearchBar
                      placeholderText="Global search..."
                      value={searchGlobalHook}
                      callBackFunction={triggerGlobalSearch}
                    />
                    {isListOpen && (
                      <ListComponent
                        setIsListOpen={setIsListOpen}
                        setSearchGlobalHook={setSearchGlobalHook}
                        setIssueList={globalSearchResult}
                        selectedProjectid={selectedProjectId}
                        selectedSprintId={sprintId}
                      />
                    )}
                  </Box>

                  {/* <Box sx={{ flexGrow: 1 }} />
                  {isListOpen}
                  <Box
                    sx={Boardstop.searchbartop}
                    onClick={() => globalsearchpopup(prev => !prev)}
                  >
                    {showBacklogPage === false && (
                      <div>
                        <SearchBar
                          setSearchTicket={setSearchTicket}
                          value={searchTicket}
                        // setIsListOpen={setIsListOpen} // Pass the setIsListOpen function to the SearchBar
                        />
                        {isListOpen && <ListComponent
                          searchTicket={searchResult}
                          setIsListOpen={setIsListOpen}
                          setSearchTicket={setSearchTicket}
                          setIssueList={issueList}
                          selectedProjectId={selectedProjectId}



                        />}
                      </div>

                    )}
                    {showBacklogPage === true && (
                      <div>
                        <SearchBar
                          setsearchvalue={setsearchvalue}
                          callBackFunction={triggerSearch}
                        // setIsListOpen={setIsListOpen} // Pass the setIsListOpen function to the SearchBar
                        />
                        {isListOpen && <ListComponent
                          searchTicket={searchResult}
                          setIsListOpen={setIsListOpen}
                          setSearchTicket={setSearchTicket}
                          setIssueList={issueList}
                          selectedProjectId={selectedProjectId}



                        />}
                      </div>
                    )}
                  </Box> */}
                </Stack>
              </Grid>
              <Grid sx={{ mt: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"start"}
                >
                  <Box sx={{ minWidth: 220, mr: 1 }}>
                    <Autocomplete
                      disableClearable={true}
                      value={
                        spritList?.find(
                          (option: any) => option.id == sprintId
                        ) ?? ""
                      }
                      fullWidth
                      sx={{
                        lineHeight: "0.80em",
                      }}
                      id="outlined-basic"
                      options={spritList}
                      getOptionLabel={(option) => option.sprintName}
                      onChange={async (event, value) => {
                        if (value?.id == "") {
                          setsprintId("");
                          setSelectSprint(objSelectSprint);
                          setshowBacklogPage(true);
                          <Button>TEst</Button>;
                        } else {
                          setsprintId(value?.id);
                          updateSelectedSprint(value);
                          setshowBacklogPage(false);
                        }
                        // navigation("/board/" + value?.id);
                        // setProjectId(value?.id);
                        // getProjectDetails(value?.id);
                        // setSearchTicket("");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="outlined-basic"
                          name="element.name"
                          variant="outlined"
                          value={sprintId}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    {elements && elements[0]?.tickets.length != 0 && (
                      <Typography sx={{ width: "100%" }} color="primary">
                        Backlog ({elements && elements[0]?.tickets.length}{" "}
                        Issue)
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={Boardstop.searchbartop}
                    onClick={() => globalsearchpopup((prev) => !prev)}
                  >
                    {showBacklogPage === false && (
                      <SearchBar
                        setSearchTicket={setSearchTicket}
                        value={searchTicket}
                      />
                    )}
                    {showBacklogPage === true && (
                      <SearchBar
                        setsearchvalue={setsearchvalue}
                        callBackFunction={triggerSearch}
                      />
                    )}
                  </Box>

                  {/* <Box>
                    <TextField
                      id="outlined-basic"
                      select
                      name="sprintId"
                      variant="outlined"
                      value={sprintId !== "" ? sprintId : " "}
                      sx={{
                        "& .MuiSelect-select": {
                          fontSize: "16px",
                          fontWeight: 500,
                          minWidth: 130,
                          color: theme.palette.primary.dark,
                          lineHeight: "inherit",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                          },
                        },
                        width: '100%'
                      }}
                    >
                      <MenuItem
                        key="0"
                        value=" "
                        onClick={(event) => {
                          setsprintId("");
                          setSelectSprint(objSelectSprint);
                          setshowBacklogPage(true);
                        }}
                      >
                        Backlog
                      </MenuItem>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openUserList}
                        onClose={handleCloseList}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      ></Menu>
                      {spritList.map((sprint: any) => (
                        <MenuItem
                          key={sprint.id}
                          value={sprint.id}
                          onClick={(event) => {
                            setsprintId(sprint.id);
                            updateSelectedSprint(sprint);
                            setshowBacklogPage(false);
                          }}
                        >
                          {sprint.sprintName}
                        </MenuItem>
                      ))}
                    </TextField>

                  </Box> */}

                  {/* <Box
                    component="form"
                    sx={{ p: '2px 4px', marginLeft: '20px', borderRadius: "4px", display: 'flex', background: '#F5F5F5', alignItems: 'center', width: 350 }}
                  >
                    <IconButton type="button" sx={{ p: '2px 5px', color: '#1DA7FF' }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Backlogs"
                      inputProps={{ 'aria-label': 'Search Backlogs' }}
                    />
                    
                 
                  </Box> */}
                </Stack>
              </Grid>

              <Grid item xs={12} sx={{ mt: 0.5 }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "start", sm: "center" }}
                  justifyContent={{ xs: "flex-start", sm: "space-between" }}
                >
                  <Box>
                    {SelectSprint.startDate !== "" && (
                      <Typography variant="subtitle2">
                        {SelectSprint.sprintName.toUpperCase() + " : ("}
                        {moment(SelectSprint.startDate).format(
                          "DD-MMM-YYYY"
                        )}{" "}
                        To {moment(SelectSprint?.endDate).format("DD-MMM-YYYY")}
                        {")"}
                      </Typography>
                    )}
                  </Box>
                 {projectDetailHook?.isTailoringProcessed == true &&(
                  <Stack
                    direction="row"
                    alignItems={{ xs: "start", sm: "center" }}
                    justifyContent={{ xs: "flex-start", sm: "space-between" }}
                    spacing={{ xs: 0, sm: 2 }}
                    sx={{
                      ml: "auto",
                      [theme.breakpoints.down("sm")]: {
                        ml: "0",
                        mt: 1,
                        flexWrap: "wrap",
                      },
                    }}
                  >
                    {/* <Button
                      color="primary"
                      variant="selectSmall"
                      startIcon={<Icons.SortBy />}
                      // onClick={handleCreateIssueModalOpen}
                    >
                      Sort By
                    </Button> */}
                    {_permission.action_Issue_Add_Access && (
                      <Button
                        color="primary"
                        variant="selectSmall"
                        startIcon={<Icons.CreateIssues />}
                        onClick={handleCreateIssueModalOpen}
                        disabled={SelectSprint.status === 3}
                      >
                        Create Issues
                      </Button>
                    )}
                    {_permission.action_Sprint_Add_Access && (
                      <Button
                        color="primary"
                        variant="selectSmall"
                        startIcon={<Icons.CreateSprint />}
                        onClick={() => {
                          if (isMilestoneOver && !showBacklogPage) {
                            toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                          }
                          else {
                            handleClickOpen(false);
                          }
                        }}
                      >
                        Create Sprint
                      </Button>
                    )}
                    {_permission.action_Milestone_Add_Access && (
                      <Button
                        color="primary"
                        variant="selectSmall"
                        startIcon={<Icons.CreateMilestone />}
                        onClick={handleMileClickOpen}
                      >
                        Create Milestone
                      </Button>
                    )}
                    {_permission.tab_Setting_Access && (
                      <Button
                        color="primary"
                        variant="selectSmall"
                        //   onClick={handleSelectProject}
                        component={Link}
                        disabled={SelectSprint.sprintName === ''}
                        to={`/column-management/${selectedProjectId}`}
                      >
                        <Icons.ProjectSetting />
                      </Button>
                    )}
                    {console.log(_permission.action_Sprint_Complete_Access, _permission.action_Sprint_Edit_Access, _permission.action_Sprint_Delete_Access, _permission.action_Sprint_Start_Access)}
                    {(SelectSprint.sprintName !== "" && (_permission.action_Sprint_Complete_Access
                      || _permission.action_Sprint_Edit_Access || _permission.action_Sprint_Delete_Access
                      || _permission.action_Sprint_Start_Access)) && (
                        <Button
                          color="primary"
                          variant="selectSmall"
                          //   onClick={handleSelectProject}
                          aria-controls={openSprint ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openSprint ? "true" : undefined}
                          onClick={handleClickSprint}
                        >
                          <MoreHorizIcon />
                        </Button>
                      )}

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openSprint}
                      onClose={handleCloseSprint}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {new Date(
                        dayjs(SelectSprint.endDate).format("MM/DD/YYYY")
                      ) >= new Date(dayjs().format("MM/DD/YYYY")) && (
                          <>
                            {_permission.action_Sprint_Edit_Access &&
                              SelectSprint.status != 3 && (
                                <MenuItem
                                  onClick={() => {
                                    handleCloseSprint();
                                    setEditSprintId(true);
                                    handleClickOpen(true);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                              )}
                          </>
                        )}

                      {_permission.action_Sprint_Delete_Access &&
                        SelectSprint.status == 1 && (
                          <>
                            <MenuItem
                              onClick={() => {
                                handleCloseSprint();
                                deleteSprintData(id);
                              }}
                            >
                              Delete
                            </MenuItem>
                          </>
                        )}
                      {/* <MenuItem onClick={handleCloseSprint}> Start Sprit</MenuItem> */}

                      {SelectSprint.status <= 1 &&
                        _permission.action_Sprint_Start_Access == true && (
                          <MenuItem
                            onClick={() => {
                              handleCloseSprint();
                              handleClickOpenAlert();
                            }}
                          >
                            Start Sprint
                          </MenuItem>
                        )}

                      {SelectSprint.status === 2 &&
                        _permission.action_Sprint_Complete_Access == true && (
                          <MenuItem
                            onClick={() => {
                              handleCloseSprint();
                              handleClickOpenAlert();
                              getSpillOverValue();
                            }}
                          >
                            Complete Sprint
                          </MenuItem>
                        )}
                      {SelectSprint.status === 3 && (
                        <MenuItem>Sprint is Already over</MenuItem>
                      )}
                    </Menu>

                    {/* <SearchBar /> */}
                  </Stack>)}

                </Stack>
              </Grid>
            </Grid>
          </Paper>

          {showBacklogPage === false && (
            <KanbanBoard
              getAllUserListByProjectId={getAllUserListByProjectId}
              isMilestoneOver={isMilestoneOver}
              projectId={selectedProjectId}
              sprintId={sprintId}
              searchTicket={searchTicket}
              ref={childRef}
              userId={userId}
              kanboardstatus={kanbanstatus}
              projectType={projectType}
              Isunassignee={Isunassignee}
              TicketInfo={handleTicketDetail}
              // boardWorkList={boardWorkList}
              elements={elements}
              setElements={setElements}
              elementsLength={elementsLength}
              toggleLoader={toggleLoader}
            />
          )}
          {showBacklogPage === true && (
            <Backlogs
              getAllUserListByProjectId={getAllUserListByProjectId}
              projectId={selectedProjectId}
              sprintId={sprintId}
              searchTicket={searchTicket}
              ref={BackchildRef}
              userId={userId}
              kanboardstatus={kanbanstatus}
              projectType={projectType}
              TicketInfo={handleTicketDetail}
              projectWiseAssigneeUsers={userList}
              Isunassignee={Isunassignee}
              elements={elements}
              setElements={setElements}
              spritList={spritList}
              TicketData={TicketDetails}
              toggleLoader={toggleLoader}
            />
          )}
        </>
      ) : (
        <NotFound NotfoundText="No Project Assigned" />
      )}
      { }

      {/* <KanbanBoard issueList={issueList} TicketInfo={handleTicketDetail} /> */}

      <Drawer
        open={drawerOpen}
        onClose={() => handlekanbanstatus()}
        sx={{
          zIndex: "1201",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            maxWidth: 360,
            [theme.breakpoints.up("sm")]: {
              maxWidth: 800,
            },
          },
        }}
      >
        <IconButton
          onClick={() => handlekanbanstatus()}
          sx={{ position: "absolute", right: "10px", top: "20px" }}
        >
          <CloseIcon />
        </IconButton>

        {selectedProjectId != null && selectedProjectId != "" && (
          <ProjectDetails
            isMilestoneOver={isMilestoneOver}
            projectDetail={projectDetailHook}
            getProjectDetails={getProjectDetails}
            setOpen={setOpen}
            ref={childRef}
            setsprintOpen={setsprintOpen}
            setMileOpen={setMileOpen}
            afterSave={getActiveProjects}
          />
        )}
      </Drawer>

      <Drawer
        open={ticketdrawerOpen}
        anchor="right"
        onClose={() => handlekanbanstatus()}
        sx={{
          zIndex: "1201",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            maxWidth: 360,
            [theme.breakpoints.up("sm")]: {
              maxWidth: 800,
            },
          },
        }}
      >
        <TicketDetails
          BacklogRefresh={backlogRefrest}
          TicketData={issueDetail}
          projectWiseAssigneeUsers={userList}
          spritList={spritList}
          onDrawerClose={() => {
            handlekanbanstatus();
          }}
          refreshData={handleTicketDetail}
          elements={elements}
          setElements={setElements}
          currentSprint={sprintId}
          selectedUserId={userId}
          selectedSprintData={SelectSprint}
        />
      </Drawer>
      <Dialog
        open={modalCreateIssueOpen}
        onClose={Button}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        fullWidth
        scroll="body"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          Create Issue 
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleCreateIssueModalClose}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateIssue
            sprintId={sprintId}
            selectedProjectId={selectedProjectId}
            modalCloseFunction={handleCreateIssueModalClose}
            projectWiseAssigneeUsers={userList}
            elements={elements}
            setElements={setElements}
            showBacklogPage={showBacklogPage}
          />
        </DialogContent>
        {/* <Box sx={Modalstyle}>
          <Paper variant="outlined" sx={{ p: 4 }}>
         
          </Paper>
        </Box> */}
      </Dialog>

      {/* Create SPrint  */}
      <Dialog
        open={openSprintAlert}
        onClose={handleClickCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        sx={{
          textAlign: "center",
        }}
      >
        {SelectSprint.status === 2 && (
          <Box sx={{ width: "100%", mx: "auto", mt: "4px" }}>
            <Stack
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  {/* {completedTicket?.closed} Were Done */}
                  {completedTicket?.closed
                    ? `Were Done: ${completedTicket?.closed}`
                    : ""}
                </Typography>
              </Stack>
            </Stack>
            <Stack

              sx={{ width: "auto" }}
            >
              <Stack

              >
                <Typography sx={{ fontSize: "18px", fontWeight: "500", mt: 2, mb: 0, textAlign: "start", paddingInline: "24px" }}>
                  {/* {pendingTicket} Were Incomplete */}
                  {pendingTicket
                    ? `${pendingTicket} tickets are still not completed in this sprint and, ${spilloverText}`
                    : ""}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        )}
        <DialogContent sx={{ fontSize: "18px", color: "#424242", textAlign: "start" }}>

          Are you sure you want to{" "}
          <b>
            {SelectSprint.status == 2 ? "Complete This Sprint?" : "Start This Sprint?"}{" "}
          </b>
        </DialogContent>
        <DialogActions
          sx={{ px: 3, pb: 3, textAlign: "end", justifyContent: "flex-end" }}
        >
          <Button variant="outlined" onClick={handleClickCloseAlert}>
            No
          </Button>
          <Button
            variant="contained"
            disabled={buttonDisable1}
            onClick={() => {
              if (SelectSprint.status == 1) {
                debugger
                updateSprintStatus(
                  SelectSprint.status == 1 ? 2 : SelectSprint.status == 2 ? 3 : 3
                );
                handleClickCloseAlert();
              }
              else {
                debugger
                setopenSprintAlert(false);
                handleRetroOpen()
              }
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={sprintOpen}
        onClose={Button}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          {/* Create Sprint */}
          {pageTitle}
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleSprintClose}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CreateSprint
            selectedProjectId={selectedProjectId}
            handleSprintClose={handleSprintClose}
            modalOpen={sprintOpen}
            bindSprint={getAllSprintByProjectId}
            selectedSprintId={SelectSprint.id}
            SelectSprint={SelectSprint}
            spritList={spritList}
            setSpritList={setSpritList}
            projectDetail={projectDetailHook}
          ></CreateSprint>
        </DialogContent>
      </Dialog>

      <Dialog open={mileOpen} onClose={Button} maxWidth="xl" scroll="body">
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          Create Milestone
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleMileClose}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <CreateMilestone
          spritList={spritList}
          handleMileClose={() => handleMileClose()}
          projectId={selectedProjectId}
          getProjectDetails={getProjectDetails}
        />
      </Dialog>

      <Modal
        open={openDelete}
        onClose={Button}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Sprint
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Only Product backlog sprint can be deleted.Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteSprint}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>


      <Dialog
        open={sprintRetroOpen}
        onClose={Button}
        maxWidth="md"
        fullWidth
        scroll="body"
        sx={{
          textAlign: "center", // Centering content horizontally
          "& .MuiDialog-paper": {
            verticalAlign: "middle" // Centering the dialog vertically
          },
        }}

      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600 }}
        >
          Sprint Retrospective
          <IconButton
            aria-label="delete"
            sx={styleCross}
            onClick={handleRetroClose}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Before ending the sprint,
            please add some retro points below detailing what went well, what needs to be improved, and what didn't go well.
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <TextField
              id="outlined-basic"
              placeholder="Please add your comment here"
              variant="outlined"
              label="Comment"
              multiline
              fullWidth
              name="title"
              sx={{
                "word-break": "break-all",
                "line-height": "1.5",
                "padding": "10px",
                "& .MuiInputBase-root": {
                  "line-height": "1.5",
                },
                "& .MuiOutlinedInput-root": {
                  "line-height": "1.5",
                  "padding": "10px",
                }
              }}
              value={retroComment}
              onChange={(e) => {
                debugger
                let value = e.currentTarget.value;
                if (value.length > 500) {
                  setValidationMessage("The comment must be 500 characters or less.");
                } else {
                  setValidationMessage("");
                }

                setRetroComment(value.slice(0, 500));
              }}

              error={!!validationMessage}
              helperText={validationMessage}

            />

          </Box>


          {/* <Box sx={{ mt: 2 }}>
            <TextField
              id="outlined-basic"
              placeholder="Please add your comment here"
              variant="outlined"
              label="Comment"
              multiline
              fullWidth
              name="title"
              // value={title}
              onChange={(e) => {
                let value = e.currentTarget.value
                value = value.replace(/\s{3,}$/g, "").trimStart().slice(0, 350);
                setRetroComment(value);

              }}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
            <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "right" }}
                      >
                        Max 350 words
                      </Typography>
          </Box> */}
          <DialogActions
            sx={{ p: 3, pb: 3, justifyContent: "end" }}
          >
            <Button variant="outlined" onClick={handleRetroClose}>
              Go Back
            </Button>
            <Button
              variant="contained"
              disabled={buttonDisable1}
              onClick={() => {
                handleRetroClose()
                addCommentstoSprintRetro(false)
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={sprintFutureOpen}
        onClose={Button}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        sx={{
          textAlign: "center",
        }}
      >
        <DialogContent sx={{ fontSize: "24px", color: "#424242", pt: 2 }}>
          Oops There is no future sprint found,
          Are you sure you want to Continue ?{" "}

        </DialogContent>
        <DialogActions
          sx={{ p: 3, pb: 3, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleFutureSprintClose}>
            No
          </Button>
          <Button
            variant="contained"
            disabled={buttonDisable1}
            onClick={() => {
              addCommentstoSprintRetro(true);
              handleFutureSprintClose()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={infoDialogOpen}
        onClose={Button}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        fullWidth
        scroll="paper"
        PaperProps={{
          sx: {
            p: 2,
            borderRadius: 3,
            boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "24px", fontWeight: 600, position: 'relative', p: 3 }}
        >
          Project Execution Process
          {/* <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: 'grey.500',
            }}
            onClick={handleNewProjectDialogClose}
          >
            <ClearIcon />
          </IconButton> */}
        </DialogTitle>
        <Box sx={{ ml: "22px" }}>{isProjectTrailorRaised?"You have to wait until your request is approved":"Please fill out the project details as needed until you are unable to perform any action."}

        </Box>
        <DialogContent dividers sx={{ p: 3 }}>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {projectTailorData.map((label: any, index: any) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 0.5,
                  borderRadius: 1,
                  bgcolor: '#f9f9f9',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                }}
              >
                <Box sx={{ minWidth: 250 }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {label?.artifactName}
                  </Typography>
                </Box>
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#1976d2',
                    },
                  }}
                  disabled={isProjectTrailorRaised}
                 // checked={projectTailorData[index]?.isFollowed === true}
                 checked={label?.isFollowed ==true}
                  onChange={(e) => {
                    let abc = [...projectTailorData]
                    abc[index].reason="";
                    abc[index].isFollowed=e.currentTarget.checked;
                    setProjectTailorData(abc);
                 
                      setProjectTrailorvalidationerror(prevErrors => ({
                        ...prevErrors,
                        [label?.artifactId]: false
                      }));
                  
                  }}
                />

                <TextField
                 // label={`Field ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  disabled={isProjectTrailorRaised ||label?.isFollowed ==true} 
                 // value={projectTailorData?.[label?.value]?.reason}
                  value={label?.reason}
                  error={!!projectTrailorvalidationerror[label?.artifactId]}
                  helperText={projectTrailorvalidationerror[label?.artifactId] ? 'Reason is required' : ''} 
                  onChange={(e) => {
                    let abc = [...projectTailorData]
                    abc[index].reason=e.currentTarget.value;
                    setProjectTailorData(abc);
                      setProjectTrailorvalidationerror(prevErrors => ({
                        ...prevErrors,
                        [label?.artifactId]: false
                      }));
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1976d2',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1565c0',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#0d47a1',
                      },
                    },
                  }}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 3, justifyContent: "end" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleNewProjectDialogClose}
            sx={{ mr: 2 }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isProjectTrailorRaised}
            onClick={handleSubmitTrailor}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}
function callBackFunction() {
  throw new Error("Function not implemented.");
}
