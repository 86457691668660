import { http } from "../http-common";
class AllowanceService {
    createAllowance = async (allowance: any): Promise<any> => {
        return await http.post<any>("/v1/Master/Allowance/AllowanceAdd", allowance);
    };

    updateAllowance = async (allowance: any): Promise<any> => {
        return await http.post<any>("/v1/Master/Allowance/Edit", allowance);
    };

    deleteAllowance = async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/Master/Allowance/DeleteById?id=${id}`);
    }

    getAllowance = async (allowance: any): Promise<any> => {
        return await http.post<any>("/v1/Master/Allowance/GetAllAllowance", allowance);
    };
    getAllowanceById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/Master/Allowance/GetbyId?id=${id}`);
    };
    generateSalarySlip = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/PayRoll/GenerateSalarySlip", data);
    };
}
export const allowanceService = new AllowanceService();