import React from "react";
import Manager from "./Manager";
import { useEffect, useState } from "react";
import AppConstants from "../../config/AppConstants";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
export default function MyProjectsManager() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const [currentLoggedInRole, setLoggedInRole] = useState("");

  useEffect(() => {
    if (_permission.page_MyProjects_Access !== true) {
      navigation('/dashboard');
    }
    setLoggedInRole(_authUser.typeOfUser);
  }, []);

  return (
    <>
      <Manager />
    </>
  );
}
