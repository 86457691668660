import * as React from "react";
import Stack from "@mui/material/Stack";
import { Autocomplete, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import dayjs, { Dayjs } from "dayjs";
import Button from "@mui/material/Button";
import * as Icons from "../../assests/icons/icons";
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from "@mui/material/ToggleButtonGroup";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { toast } from "material-react-toastify";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { masterDataService } from "../../service/master-service/master-data-service";
import { AppConstants } from "../../config/AppConstants";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { issueManagementService } from "../../service/issues-management-service";
import { createTheme } from "@mui/material/styles";
import Quill from "quill";
import ReactQuill from "react-quill";
import MagicUrl from "quill-magic-url";
import DialogActions from "@mui/material/DialogActions";
import { IssueTypeIcon } from "./IconsLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import { labelService } from "../../service/master-service/label-services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { eSprintStatus } from "../../Enum/eSprint";
import { linkedIssueServices } from "../../service/LinkedIssue-Services";
import { platformEnum, typeOfBugenum } from "../../Enum/eCreateIssue";
Quill.register("modules/magicUrl", MagicUrl);


const styleCross = {
  position: "absolute" as "absolute",
  right: "-15px",
  top: "-26px",
};
const textFieldStyle = {
  lineHeight: '1.2  ', // Adjust the line height as needed
};
interface Country {
  id: string;
  name: string;
}


const dummyCountry: Country[] = [
  {
    id: "India",
    name: "India",
  },
];
const CustomToggleButtonGroup = styled(
  ToggleButtonGroup
)<ToggleButtonGroupProps>(({ theme }) => ({
  top: "15px",
  position: "absolute",
  left: "15px",
}));


interface Files {
  type: string;
  file: Blob;
}
export default function CreateIssue({
  selectedProjectId,
  modalCloseFunction,
  projectWiseAssigneeUsers,
  sprintId,
  ticketId,
  elements,
  setElements,
  refreshFunction,
  showBacklogPage
}: any) {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [issueDescription, setIssueDescription] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const issueFrom = [

    {
      value: 1,
      label: "QA",
    },
    {
      value: 2,
      label: "Client",
    },

  ];
 
  const [StaticStatusId, setStaticStatusId] = useState<number | undefined>(0);
  console.log(StaticStatusId, "StaticStatusId")
  const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    debugger
    const selectedStatus = event.target.value as number;
    setStaticStatusId(selectedStatus);
  };
  const navigate = useNavigate();
  const [projects, setProject] = useState<any>([]);
  const [sprints, setSprint] = useState<any>([]);
  const [users, setUsers] = useState<any>(projectWiseAssigneeUsers);
  const [issueList, setIssues] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [AssineeList, setAssignee] = useState<any>([]);
  const [projectId, setProjectId] = useState("");
  const [attachedFileName, setAttachedFile] = useState("");

  const [selectedFiles, setSelectedFiles] = useState<Files[]>([]);
  const [fileHook, setFileHook] = useState<any>(null);
  const [epicissue, setepicissue] = useState<any[]>([]);

  const [fileUrlHook, setFileUrlHook] = useState<any>(null);
  const [currentLabelId, setcurrentLabelId] = useState<any>(null);
  const [currentSprintId, setCurrentSprintId] = useState("");
  const [currentEpicId, setCurrentEpicId] = useState("");
  const [currentLinkId, setCurrentLinkId] = useState("");
  const [issueTypeName, setIssueTypeName] = React.useState("");

  const [addLabel, setAddLabel] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [epicFieldHide, setepicFieldHide] = useState<any>("");
  const [clientFieldHide, setclientFieldHide] = useState<any>("");
  const [rcaList, setRcaList] = useState<any>([]);
  console.log(epicFieldHide, "epicFieldHide");
  async function handleChange1(e: any) {
    setFileHook(e.target.files[0]);
    setFileUrlHook(URL.createObjectURL(e.target.files[0]));
    var filese_array = [...selectedFiles];
    filese_array = [
      ...selectedFiles,
      { type: "Test", file: e.target.files[0] },
    ];
    setSelectedFiles(filese_array);
  }

  let removeFormFields = (i: any) => {
    var filese_array = [...selectedFiles];
    filese_array.splice(i, 1);
    setSelectedFiles(filese_array);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    setProjectId(selectedProjectId);
    setCurrentSprintId(sprintId);
    //getAllActiveUsers();
    // getActiveProjects();
    getEpicIssues();
    GetSprintDetailByProject(selectedProjectId);
    getAllActiveLabels();
  }, []);


  const getAllRCA = async () => {
    let param = {
      search: ""
    }
    try {
      await masterDataService
        .getAllRCAs(param)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("facing issue while uploading document.");
          } else {
            setRcaList(response.data);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }

  };


  const onEditorChange = (event: any) => {
    setIssueDescription(event);
  };
  const getAllActiveUsers = async () => {
    await masterDataService.getAllActiveUsers().then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setUsers(response.data);
      }
    });
  };
  const getActiveProjects = async () => {
    await masterDataService.getActiveProjects().then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setProject(response.data);
      }
    });
  };
  const getAllActiveLabels = async () => {
    await masterDataService.getAllActiveLabels().then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setLabels(response.data);
      }
    });
  };
  const GetIssueDetailByProject = async (projectId: any, issueType : string) => {
   
    var linkedDropData = '';
    if(issueType == "Task") {
        linkedDropData = "Bug"
    }
    else if(issueType == "Story") {
        linkedDropData = "Task"
    } 
    else {
        linkedDropData = 'Task';
    }
    await masterDataService
      .GetIssuesByProject(projectId , linkedDropData)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          setIssues(response.data);
        }
      });
  };
  const GetSprintDetailByProject = async (selectedProjectId: any) => {
    setProjectId(selectedProjectId);
    await masterDataService
      .GetSprintDetailByProject(selectedProjectId)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          var result = response.data;
          setSprint(result.filter((s: any) => s.status != eSprintStatus.Completed));
        }
      });

   // GetIssueDetailByProject(selectedProjectId);
  };

  let handleFileChange = (i: any, e: any) => {
    let newFormValues = [...formValues];
    //newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: "" }]);
  };

  let handleSubmit = (event: any) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };
  const [formValues, setFormValues] = useState([{ name: "" }]);


  const getEpicIssues = async (values?: any) => {
    var req = {
      ProjectId: selectedProjectId,
      SprintId: sprintId,
    };
    await issueManagementService.GetAllEpic(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        setepicissue(response.data);
      }
    });
  };
  const addEpicIssues = async (epicId?: any) => {
    var req = {
      epicId: epicId,
      ticketId: ticketId,

    };
    await issueManagementService.UpdateEpicticketById(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      }
      else {

        if (refreshFunction) {
          refreshFunction();
        }

      }
    });
  }




  const uploadAttachment = async (data: any) => {
    let arrayofFilesData: any = [];
    await selectedFiles.map(async (item, i, arr) => {
      try {
        let formData = new FormData();
        formData.append("File", item.file);
        formData.append("FileFor", "User Documents");
        await masterDataService
          .UploadUserDocument(formData)
          .then(async (response: any) => {
            if (response.data.isError) {
              toast.error("facing issue while uploading document.");
            } else {
              setAttachedFile(response.data);
              data.attachment = response.data;
              createIssue(data);
            }
          });
      } catch (ex: any) {
        toast.warning("Error while updating data, check console.");
      }
    });
  };

  const addIssue = async (data: any) => {
    try {
      if (selectedFiles.length > 0) {
        uploadAttachment(data);
      } else {
        createIssue(data);
      }
    } catch (ex: any) {
      toast.warning("Error while updating data, check console.");
    }
  };

  const createIssue = async (data: any) => {
    setButtonDisable(true);
    const createIssueRequest = (input: any) => {
      return issueManagementService
        .createIssue(input)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("facing issue while create Issue.");
          } else {
            if (response.data) {
              toast("You've created" + " " + response.data.ticketNumber + " " + "issue.",
                {
                  onOpen: () => setButtonDisable(true),
                  onClose: () => setButtonDisable(false),
                  onClick: () => navigate(`/issue-detail/${response.data.id}`),
                  position: "bottom-left",
                  autoClose: 5000,
                  className: "cutomToast",
                  hideProgressBar: false,
                }
              );
              modalCloseFunction(false);
              await masterDataService.getAllActiveSummary(response.data.id).then((response: any) => {
                if (response.data.isError) {
                  toast.error("Something Went Wrong, Please try again.");
                } else {

                  var IssueData = {
                    ...response.data,
                    assigneeUserName: response.data.assignUsername
                  };
                  let arr = [...elements];
                  console.log("data.IssueSprintId", data.IssueSprintId);
                  console.log("showBacklogPage", showBacklogPage);
                  if (data.IssueSprintId != null && data.IssueSprintId != "" && sprintId === data.IssueSprintId) {
                    var objBoardStatus = arr.find((a: any) => a.status === "Sprint Backlog");
                    objBoardStatus.tickets.push(IssueData)
                  }
                  if (showBacklogPage === true && (!data.IssueSprintId || data.IssueSprintId === "" || data.IssueType === "Epic")) {
                    debugger
                    var objBoardStatus = arr.find((a: any) => a.status === "Product Backlog");
                    objBoardStatus.tickets.push(IssueData)
                  }
                  setElements(arr);
                }
              });
              let obj = {
                ticketId: data.LinkedIssueId,
                linkIssueId: response.data.id,
              }
              let obj1 = {
                ticketId: response.data.id,
                blockedBy: data.LinkedIssue,
              }
              if (data.LinkedIssueId != null && data.LinkedIssueId != "") {
                await
                  linkedIssueServices.createBlockedBy(obj1).then(async (response: any) => {
                    if (response.data.isError) {
                    }
                    else {
                      await linkedIssueServices.createLinkedIssue(obj)
                    }
                  })
                    .catch((error) => {
                      if (error.data.Error.length > 0)
                        toast.error(error.data.Error[0]);
                      return error;
                    });
              }
            }
          }
        })
        .catch((error) => {
          // if (error.data.errors.length > 0)
          toast.error(error.data.Error[0], {
            onOpen: () => setButtonDisable(true),

            onClose: () => setButtonDisable(false),
          });

          return error;
        });
    };

    const req = {
      ProjectId: selectedProjectId,
      IssueType: data.IssueType,
      Priority: data.Priority,
      Description: issueDescription,
      Severity: data.Severity,
      Summary: data.Summary,
      Reporter: _authUser.id,
      attachment: data.attachment,
      Label: data.Label,
      Version: data.Version,
      BlockedIssue: data.LinkedIssueId,
      BlockedBy: "",
      EpicId: data.EpicId,
      UserId: data.UserId,
      Status: data.IssueSprintId != null && data.IssueSprintId != "" ? "Sprint Backlog" : "Product Backlog",
      LinkedIssueId: data.LinkedIssueId,
      // LinkedIssue: data.LinkedIssue,
      SprintId: data.IssueSprintId ?? "",
      IssueFrom: data.IssueFrom || 0,
      Platform: data.Platform,
      BugType: data.BugType,
      RCA: data.RCA,
      EstimatedHours:data.EstimatedHours ||null,
      EstimatedHoursQA:data.EstimatedHoursQA ||null,
    };

    try {
      if (textfieldVisible && data.addLabel) {
        const json = {
          name: data.addLabel,
        };
        await labelService.createLabel(json).then((response: any) => {
          if (!response.data.isError) {
            setTextfieldVisible(false);
            req.Label = response.data;
            createIssueRequest(req);
          }
        });
      } else {
        createIssueRequest(req);
      }
    } catch (ex: any) {
      toast.warning("Error while updating data, check console.");
    }

  };
  ///////////AddLabel ////////

  const [textfieldVisible, setTextfieldVisible] = React.useState(false);

  return (
    <>
      <Formik
        initialValues={{
          ProjectId: "",
          IssueType: "",
          Priority: "Medium",
          Severity: "",
          Summary: "",
          Description: "",
          Reporter: "",
          Label: null,
          Version: "",
          BlockedIssue: null,
          BlockedBy: null,
          EpicId: null,
          UserId: null,
          Status: "",
          LinkedIssue: null,
          LinkedIssueId: null,
          IssueSprintId: sprintId ?? "",
          addLabel: "",
          IssueFrom: "",
          Platform: "",
          BugType: "",
          RCA: "",
          EstimatedHours: "",
          EstimatedHoursQA: "",
        }}
        validationSchema={Yup.object().shape({
          IssueType: Yup.string().required("IssueType is required"),
          Priority: Yup.string().max(30).required("Priority is required"),
          // Description: Yup.string().max(20).required('Description is required'),
          Summary: Yup.string().trim().required("Summary is required"),
          //UserId: Yup.string().required('Assignee is required'),
          // Reporter: Yup.string().max(20).required('Reporter is required'),
          IssueFrom: epicFieldHide == "Bug" ? Yup.string().required('Reported By is required') : Yup.string(),
          Platform: (epicFieldHide == "Bug" && clientFieldHide!="Client") ? Yup.string().required('Platform  is required') : Yup.string(),
          BugType:(epicFieldHide == "Bug" && clientFieldHide!="Client") ? Yup.string().required('Type Of Bug is required') : Yup.string(),
          RCA: (epicFieldHide == "Bug" && clientFieldHide!="Client") ? Yup.string().required('RCA is required') : Yup.string(),
          LinkedIssueId: epicFieldHide == "Bug" ? Yup.string().required('Please select a issue').nullable() : Yup.string().nullable(),
          LinkedIssue: epicFieldHide == "Bug" ? Yup.string().required('This field is Required').nullable() : Yup.string().nullable(),
          EstimatedHoursQA: (epicFieldHide == "Task" && currentSprintId )? Yup.string().required('QA Estimation is required') : Yup.string(),
          EstimatedHours: (epicFieldHide == "Task"&&currentSprintId) ? Yup.string().required('Dev Estimation is required') : Yup.string(),
          Severity: (epicFieldHide == "Bug" && clientFieldHide!="Client") ? Yup.string().required('Severity is required') : Yup.string(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (values.IssueType === "Epic") {
              debugger
              values.IssueSprintId = "";
              await addIssue(values);
            }
            else {
              if( (values.EstimatedHours == "0") && (values.EstimatedHoursQA == "0")){
                toast.warning("Both Dev and QA hours can't be zero.")
              }
              else {
                 await addIssue(values);
              }
            }

            
            // useEffect(() => {
            //     setValue(1);setValue(0);
            // }, [])
          } catch (ex: any) {
            if (ex.status == 400) {
              console.error("ex:", ex);
              ex.data.Error.map((error: any, idx: any) => {
                toast.error(error);
              });
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container sx={{ mt: 0.5 }} spacing={2}>
              <Grid item md={2} xs>
                <TextField
                  id="outlined-basic"
                  select
                  label="Issue Type"
                  variant="outlined"
                  name="IssueType"
                  required
                  fullWidth
                  error={Boolean(touched.IssueType && errors.IssueType)}
                  helperText={touched.IssueType && errors.IssueType}
                  //onChange={handleChange}
                  onChange={(e) => {
                    handleChange(e);
                    const label = e.target.value;
                    if(label != "Epic") {
                      GetIssueDetailByProject(projectId, label);
                    }
                  }}
                  value={values.IssueType}
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                    },
                  }}
                >
                  {AppConstants.MasterData.Issue.IssueType.map((issue: any) => (
                    <MenuItem key={issue.value} value={issue.value}
                      onClick={() => {
                        setepicFieldHide(issue?.label)
                        values.EstimatedHoursQA = "";
                        values.EstimatedHours = "";
                        if (issue?.label == "Bug") {
                          getAllRCA();
                        }
                      }}>
                      <ListItemIcon
                        sx={{
                          "&.MuiListItemIcon-root": {
                            minWidth: "20px",
                          },
                        }}
                      >
                        <IssueTypeIcon
                          issueIconType={issue.icon}
                        ></IssueTypeIcon>
                      </ListItemIcon>
                      <Typography variant="inherit"> {issue.label}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {epicFieldHide == "Bug" && (
                <>
                  <Grid item md={2} xs>
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      select
                      required
                      label="Reported By"
                      value={values.IssueFrom}
                      name="IssueFrom"
                      error={Boolean(touched.IssueFrom && errors.IssueFrom)}
                      helperText={touched.IssueFrom && errors.IssueFrom}
                      variant="outlined"
                      onChange={handleChange}
                    >
                      {issueFrom.map((option) => (
                        <MenuItem key={option.value} value={option.value}  onClick={() => {
                          setclientFieldHide(option.label)
                        }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item md={2} xs>
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      select
                      required
                      label="Type of Bug"
                      value={values.BugType}
                      name="BugType"
                      error={Boolean(touched.BugType && errors.BugType)}
                      helperText={touched.BugType && errors.BugType}
                      variant="outlined"
                      onChange={handleChange}
                    >
                      {typeOfBugenum.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>


                  <Grid item md={2} xs>
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-basic"
                      select
                      required
                      label="Platform"
                      value={values.Platform}
                      name="Platform"
                      error={Boolean(touched.Platform && errors.Platform)}
                      helperText={touched.Platform && errors.Platform}
                      variant="outlined"
                      onChange={
                        handleChange
                      }
                    >
                      {platformEnum.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs>
                    <TextField
                      sx={{ width: "370px" }}
                      id="outlined-basic"
                      select
                      required
                      label="RCA"
                      value={values.RCA}
                      name="RCA"
                      error={Boolean(touched.RCA && errors.RCA)}
                      helperText={touched.RCA && errors.RCA}
                      variant="outlined"
                      onChange={
                        handleChange
                      }
                    >
                      {rcaList.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid></>
              )}
              <Grid item md={epicFieldHide == "Bug" ? 12 : 8} xs>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Summary"
                  name="Summary"
                  multiline
                  maxRows={2}
                  error={Boolean(touched.Summary && errors.Summary)}
                  helperText={touched.Summary && errors.Summary}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Summary.trimStart()}
                  required
                  variant="outlined"
                  fullWidth
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  InputProps={{
                    style: textFieldStyle,
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, mb: 4 }}>
              <ReactQuill
                modules={AppConstants.EditorSetting}
                theme="snow"
                value={issueDescription}
                onChange={onEditorChange}
              />
            </Box>
            {epicFieldHide != "Epic" && (
              <><Grid
                container
                spacing={2}
                sx={{ position: "relative", zIndex: "0" }}
              >
                {/* <Grid item xs={12} md={3}>
      <TextField
        id="outlined-basic"
        select
        label="Assignee"
        variant="outlined"
        fullWidth
        name="UserId"
        error={Boolean(touched.UserId && errors.UserId)}
        helperText={touched.UserId && errors.UserId}
        onChange={handleChange}
        value={values.UserId}
      >
        {users.map((user: any) => (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        ))}
      </TextField>
    </Grid> */}
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    value={users?.find(
                      (option: any) => option.id == currentUserId
                    ) ?? null}
                    fullWidth
                    id="outlined-SprintId"
                    options={users}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, value) => {
                      values.UserId = value?.id ?? "";
                      setCurrentUserId(value?.id ?? "");
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'flip',
                            enabled: true,
                            options: {
                              altBoundary: true,
                              flipVariations: true,
                              altAxis: true,
                              padding: 8,
                              fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                            },
                          },
                        ],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="outlined-basic"
                        label="Assignee"
                        variant="outlined"
                        fullWidth
                        name="UserId"
                        error={Boolean(touched.UserId && errors.UserId)}
                        helperText={touched.UserId && errors.UserId}
                        onChange={handleChange}
                        value={currentUserId} />
                    )} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-basic"
                    select
                    label="Priority"
                    variant="outlined"
                    fullWidth
                    name="Priority"
                    error={Boolean(touched.Priority && errors.Priority)}
                    helperText={touched.Priority && errors.Priority}
                    onChange={handleChange}
                    value={values.Priority}
                    required
                    sx={{
                      "& .MuiSelect-select": {
                        display: "flex",
                      },
                    }}
                  >
                    {AppConstants.MasterData.Issue.Priority.map(
                      (priority: any) => (
                        <MenuItem key={priority.value} value={priority.value}>
                          {/* {priority.label} */}
                          <ListItemIcon
                            sx={{
                              "&.MuiListItemIcon-root": {
                                minWidth: "20px",
                              },
                            }}
                          >
                            <IssueTypeIcon
                              issueIconType={priority.icon}
                            ></IssueTypeIcon>
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {" "}
                            {priority.label}
                          </Typography>
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-basic"
                    select
                    label="Severity"
                    variant="outlined"
                    fullWidth
                    name="Severity"
                    error={Boolean(touched.Severity && errors.Severity)}
                    helperText={touched.Severity && errors.Severity}
                    onChange={handleChange}
                    value={values.Severity}
                  >
                    {AppConstants.MasterData.Issue.Severity.map(
                      (severity: any) => (
                        <MenuItem key={severity.value} value={severity.value}>
                          {severity.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={3}>
                  {!textfieldVisible ? (
                    <Autocomplete
                      autoHighlight
                      value={labels?.find(
                        (option: any) => option.id == currentLabelId
                      ) ?? null}
                      fullWidth
                      id="outlined-SprintId"
                      options={[{ id: 'create-new', name: 'Add New Label' },
                      ...labels.sort((a: any, b: any) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)]}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, value) => {
                        if (value?.id === 'create-new') {
                          values.addLabel = "";
                          setTextfieldVisible(true);
                        } else {
                          values.Label = value?.id ?? "";
                          setcurrentLabelId(value?.id ?? "");
                          setTextfieldVisible(false);
                        }
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="addLabel"
                          label="Add Label"
                          variant="outlined"
                          fullWidth
                          // error={Boolean(touched.UserId && errors.UserId)}
                          // helperText={touched.UserId && errors.UserId}
                          onChange={handleChange}
                          value={currentLabelId} />
                      )}
                    />
                  ) : (
                    <>
                      <TextField
                        name="addLabel"
                        label="Add Label"
                        value={values.addLabel}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^a-zA-Z\s]/g, "");
                          value = value.replace(/\s{2,}$/g, "").trimStart();
                          values.addLabel = value;
                          setAddLabel(value);
                        }}
                      ></TextField>{" "}
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setTextfieldVisible(false);
                          values.addLabel = "";
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
                {/* <Grid item xs={12} md={3}>
                  {!textfieldVisible ? (
                    <TextField
                      id="outlined-basic"
                      select
                      label="Labels"
                      variant="outlined"
                      fullWidth
                      name="Label"
                      // error={Boolean(touched.Label && errors.Label)}
                      // helperText={touched.Label && errors.Label}
                      onChange={handleChange}
                      value={currentLabelId}
                    >
                      <Button
                        onClick={() => {
                          values.addLabel = "";
                          setTextfieldVisible(true);
                        } }
                      >
                        Add Label
                      </Button>
                      {labels.map((label: any) => (
                        <MenuItem
                          key={label.id}
                          value={label.id}
                          onClick={() => {
                            setTextfieldVisible(false);
                            setcurrentLabelId(label.id);
                          } }
                        >
                          {label.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <>
                      <TextField
                        name="addLabel"
                        label="Add Label"
                        value={values.addLabel}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^a-zA-Z\s]/g, "");
                          value = value.replace(/\s{2,}$/g, "").trimStart();
                          values.addLabel = value;
                          setAddLabel(value);
                        } }
                      ></TextField>{" "}
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setTextfieldVisible(false);
                          values.addLabel = "";
                        } }
                      >
                        <ClearIcon />
                      </IconButton>
                    </>
                  )}
                </Grid> */}
              </Grid><Grid
                container
                spacing={2}
                sx={{ mt: 2, position: "relative", zIndex: "0" }}
              >
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Linked Issue"
                      variant="outlined"
                      fullWidth
                      name="LinkedIssue"
                      onBlur={handleBlur}
                      error={Boolean(touched.LinkedIssue && errors.LinkedIssue)}
                      helperText={touched.LinkedIssue && errors.LinkedIssue}
                      onChange={handleChange}
                      value={values.LinkedIssue}
                    >
                      {AppConstants.MasterData.Issue.LinkedIssue.map(
                        (issue: any) => (
                          <MenuItem key={issue.value} value={issue.value}>
                            {issue.label}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    {/* <TextField
      id="outlined-basic"
      select
      label="Select Issue"
      variant="outlined"
      fullWidth
      name="EpicId"
      error={Boolean(touched.EpicId && errors.EpicId)}
      helperText={touched.EpicId && errors.EpicId}
      onChange={handleChange}
      value={values.EpicId}
    >
      {issueList.map((issue: any) => (
        <MenuItem key={issue.id} value={issue.id}>
          {issue.ticketNumber +
            " " +
            issue.summary.slice(0, 20) +
            (issue.summary.length > 20 ? "..." : "")}
        </MenuItem>
      ))}
    </TextField> */}
                    <Autocomplete
                      value={issueList?.find(
                        (option: any) => option.id == currentLinkId
                      ) ?? null}
                      fullWidth
                      id="Link-issueList"
                      options={issueList}
                      getOptionLabel={(option) => option.ticketNumber +
                        " " +
                        option.summary.slice(0, 20) +
                        (option.summary.length > 20 ? "..." : "")}
                      onChange={(event, value) => {
                        values.LinkedIssueId = value?.id ?? null;
                        setCurrentLinkId(value?.id ?? null);
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onBlur={handleBlur}
                          id="Epic-issueList"
                          error={Boolean(touched.LinkedIssueId && errors.LinkedIssueId)}
                          helperText={touched.LinkedIssueId && errors.LinkedIssueId}
                          name="LinkedIssueId"
                          label="Select Issue"
                          variant="outlined"
                          value={values.LinkedIssueId}
                          fullWidth />
                      )} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      value={epicissue?.find(
                        (option: any) => option.id == currentEpicId
                      ) ?? null}
                      fullWidth
                      id="Epic-issueList"
                      options={epicissue}
                      getOptionLabel={(option) => option.ticketNumber +
                        " " +
                        option.summary.slice(0, 20) +
                        (option.summary.length > 20 ? "..." : "")}
                      onChange={(event, value) => {
                        values.EpicId = value?.id ?? null;
                        setCurrentEpicId(value?.id ?? null);
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="Epic-issueList"
                          error={Boolean(touched.EpicId && errors.EpicId)}
                          //   helperText={touched.EpicId && errors.EpicId}
                          name="EpicId"
                          label="Epic Link"
                          variant="outlined"
                          helperText="Choose an epic to assign this issue to"
                          value={values.EpicId}
                          fullWidth />
                      )} />
                  </Grid>

                  {/* <Grid item xs={12} md={3}>
                    <TextField
                      id="outlined-basic"
                      select
                      label="Epic Link"
                      variant="outlined"
                      fullWidth
                      name="EpicId"
                      //error={Boolean(touched.SprintId && errors.SprintId)}
                      helperText="Choose an epic to assign this issue to"
                      onChange={handleChange}
                      value={values.EpicId}
                      >
                      {epicissue.map((epicIssue: any) => (
                          <MenuItem key ={epicIssue.id} value={epicIssue.id}
                          onClick={() => {
                            addEpicIssues(epicIssue.id);
                        }}>                                                   
                            {epicIssue.ticketNumber}&nbsp;<span>  {epicIssue.summary}
                            </span>                      
                          </MenuItem>
                      ))} 
                       {epicissue.length == 0 && <MenuItem >No Epic Found</MenuItem>}                      
                    </TextField>
                  </Grid> */}
                  <Grid item xs={12} md={3}>
                    {/* <TextField
      id="outlined-basic"
      select
      label="Select Sprint"
      variant="outlined"
      fullWidth
      name="SprintId"
      error={Boolean(touched.EpicId && errors.EpicId)}
      helperText={touched.EpicId && errors.EpicId}
      onChange={handleChange}
      // value={values.EpicId}
      value={values.SprintId}
    >
      {/* {issueList.map((issue: any) => (
                            <MenuItem key={issue.id} value={issue.id}>
                                {issue.summary}
                            </MenuItem>
                        ))} */}
                    {/* {sprints.map((sprint: any) => (
        <MenuItem key={sprint.id} value={sprint.id}>
          {sprint.sprintName}
        </MenuItem>
      ))}
    </TextField> */}
                    <Autocomplete
                      value={sprints?.find((option: any) => option.id === values.IssueSprintId) ?? null}
                      fullWidth
                      id="outlined-SprintId"
                      options={sprints}
                      getOptionLabel={(option) => option.sprintName}
                      onChange={(event, value) => {
                        values.IssueSprintId = value?.id ?? null;
                        setCurrentSprintId(value?.id ?? "");
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-SprintId"
                          label="Select Sprint"
                          variant="outlined"
                          fullWidth
                          name="IssueSprintId"
                          error={Boolean(touched.EpicId && errors.EpicId)}
                          helperText={touched.EpicId && errors.EpicId}
                          value={values.IssueSprintId} />
                      )} />
                  </Grid>
        {(epicFieldHide == "Task" &&values?.IssueSprintId)&&(         
<>
                  <Grid item xs={12} md={3}>
                    <TextField
                    required
                      fullWidth
                      inputProps={{ min: 1, max: 2 }}
                      name="EstimatedHours"
                      label="Dev Efforts"
                      value={values.EstimatedHours}
                      error={Boolean(touched.EstimatedHours && errors.EstimatedHours)}
                      helperText={touched.EstimatedHours && errors.EstimatedHours}
                      onChange={(e: any) => {
                        debugger;
                        let value = e.currentTarget.value
                          .replace(/[^0-9]\s{0,}$/g, "")
                          .trimStart()
                          .substring(0, 2);
                        values.EstimatedHours = value;
                        setFieldValue("EstimatedHours",value)
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      required
                      name="EstimatedHoursQA"
                      label="QA Efforts"
                      value={values.EstimatedHoursQA}
                      error={Boolean(touched.EstimatedHoursQA && errors.EstimatedHoursQA)}
                      helperText={touched.EstimatedHoursQA && errors.EstimatedHoursQA}
                      onChange={(e: any) => {
                        debugger;
                        let value = e.target.value
                          .replace(/[^0-9]\s{0,}$/g, "")
                          .trimStart()
                          .substring(0, 2);
                        values.EstimatedHoursQA = value;
                        setFieldValue("EstimatedHoursQA",value)
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} md={12}>Note: Once an estimation is added, it can't be changed in the future. If QA is not applicable to this project, you can enter 0.</Grid></>)} 
                  
                </Grid><Grid container spacing={2} alignItems="center" sx={{ mt: 3 }}>
                  <Grid item xs={12} md={4}>
                    <Button
                      component="label"
                      variant="outlined"
                      fullWidth
                      startIcon={<Icons.Upload />}
                    >
                      Attachment
                      <input
                        hidden
                        onChange={handleChange1}
                        accept="image/*"
                        type="file"
                        name={"file_"}
                        id={"file_"} />
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    {selectedFiles.length > 0 ? (
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="body1" color="success.main">
                          File Attached Successfully
                        </Typography>
                        <Tooltip title="Remove" arrow>
                          <IconButton
                            key={"btnRemove_" + 0}
                            sx={{ my: 2 }}
                            color="error"
                            onClick={() => removeFormFields(0)}
                          >
                            <Icons.Delete />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    ) : null}
                  </Grid>
                </Grid></>)}

            <DialogActions sx={{ px: 0 }}>
              <Button
                variant="outlined"
                onClick={() => modalCloseFunction(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={buttonDisable}
              >
                Create Issue
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  );
}
