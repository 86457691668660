import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AddEmployee from '../assests/iconComponents/AddEmployee'
import AddProject from '../assests/iconComponents/AddProject'
import AddRole from '../assests/iconComponents/AddRole'
import Typography from '@mui/material/Typography';
import { Link, useNavigate, } from "react-router-dom";
import Box from '@mui/material/Box';
import theme from '../theme/darkTheme';
import ItemPaper from '../components/styledComponent/ItemPaper';
import * as Icons from '../assests/icons/icons';
import { toast } from 'material-react-toastify';
import { AppConstants } from '../config/AppConstants'
import NotFound from "../components/styledComponent/NotFound";
import { useSelector } from 'react-redux';
import Dashboardstyle from './Dashboardallstyle';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import navigation from '../config/navigation';
import React from 'react';
import { leaveService } from '../service/Leave/LeaveService';
import { reportService } from '../service/Reports/reports';
import dayjs from 'dayjs';

const styles = {
    cardLeaves: {
        borderRadius: "10px",
        padding: "22px 18px",
        height: '100%',
    },
    titleLeave: {
        color: "#1F944E",
        fontWeight: 500,
        fontSize: "20px",
        marginBottom: "0",
    },
    annualLeaves: {
        color: "#1F944E",
    },
    sickLeaves: {
        color: "#B38906",
    },
    casualLeaves: {
        color: "#16B5BF",
        fontSize: "16px"
    },
    compOff: {
        color: "#1DA7FF",
    },
    otherLeaves: {
        color: "#74C225",
    },
    total: {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#424242",
    },
    values: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #424242",
    },
    taken: {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: " 18px",
    },
    takenvalues: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #757575",
    },
    applyLeave: {
        backgroundColor: "#F0F9FF",
        borderRadius: "10px",
        padding: "22px 18px",
        maxWidth: { xs: '100%', md: "412px" },
        marginLeft: "auto",
    },
    applyLeaveHeading: {
        color: "#232360",
        fontWeight: 500,
        fontSize: " 26px",
    },
    selectType: {
        backgroundColor: "#fff",
        marginTop: "10px",
    },
    selectlabel: {
        fontWeight: 400,
        fontSize: " 14px",
        lineHeight: "21px",
        marginLeft: 0,
        marginBottom: "6px",
        color: "#212121",
    },
    spaceTop: {
        marginTop: "24px",
    },
    AnnualLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #2DD36F 50%, #FFBB0B 78.73%)",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    SickLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: " linear-gradient(90deg, #B38906 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    CasualLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: " linear-gradient(90deg, #15B5BF 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    CompLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #1DA7FF 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    OtherLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #74C225 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },

        valueRight: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: " 21px",
            /* Primary Color/Primary_dark shade */
            color: " #424242",
            flexGrow: 1,
        },
    },
    cardStyleProject: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#212121",
        marginBottom: "5px",
    },
    cardStyleCompany: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#232360",
        marginBottom: "5px",
    },
    cardStyleTask: {
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#616161",
    },
    taskTitle: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#232360",

    },
    //     bRadius: {
    //         borderRadius: '16px',
    //         border: '1px solid #EEEEEE',
    //         cursor:"pointer",
    //         "&:hover":{
    //             backgroundColor:"#fbfbfb"
    //         }

    //     },
    //     selected:{
    //    backgroundColor:"#fbfbfb"
    //     },
    tableTH: {
        borderBottom: 'transparent',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#232360",

    },
    table: {
        marginTop: '10px',
    },
    calenderTitle: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#232360",
    },
    timeOne: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#616161",
    },
    mettingOne: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #212121",
    },
    mettingTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#616161",
    },
    timeTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000000",
        marginTop: '3px',
    },
    listNone: {
        listStyle: 'none',
        marginBottom: '18px',
    },
    bRight: {
        borderLeft: '5px solid #1DA7FF'
    },
    bNone: {
        borderRadius: '0',
    },
    borderNone: {
        border: 'none'
    },
    brownieBox: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#232360",
    },
    brownieOuter: {
        marginTop: '16px',
        borderRadius: '16px',
        border: '1px solid #EEEEEE',

    },
    p15: {
        paddingBottom: '15px',
    },
    point: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#424242",
    }
}

const ItemBoxWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: "10px",
    rowGap: "10px",
    marginTop: "10px"
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    margin: '0.02rem',
    padding: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 0,
    transition: '0.3s all ease',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    },
    '&:active': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    }
}));

const LongText = (props: {
    content?: any;
    limit?: any;
}) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    if (props.content.length <= props.limit) {
        // there is nothing more to show
        return <div>{props.content}</div>
    }
    if (showAll) {
        // We show the extended text and a link to reduce it
        return <Box sx={{ whiteSpace: "break-spaces" }}>
            {props.content}

            <Box onClick={showLess}>less...</Box>
        </Box>
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = props.content ? props.content.substring(0, props.limit) : "";
    return <Box sx={Dashboardstyle.Readmore}>
        {toShow} ...

        {/* <Box   onClick={showMore}></Box>  */}
    </Box>
}


const reportManagement = async () => {
    toast.warn('Coming Soon')
};

const leaveManagement = async () => {
    toast.warn('Coming Soon')
};




export default function Dashboard() {
    const [currentLoggedInRole, setLoggedInRole] = useState('');
    const [userData, setUserData] = React.useState<any>([]);
    const [leaveCount, setLeaveCount] = useState<any>();
    const [StaticStatusId, setStaticStatusId] = useState<number | undefined>(0);
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const [userName, setUsername] = useState("");
    const navigate = useNavigate();

    let totalleaveCasualEarnedSick = leaveCount?.totalleaves + leaveCount?.totalEarnedLeave;
    let leaveTakenCasualEarnedSick = leaveCount?.leaveTaken + leaveCount?.earnedLeaveTaken;
    let leaveBalanceEarnedCasual = leaveCount?.leaveBalance + leaveCount?.earnedLeaveBalance;
    var options: any = {
        series: [totalleaveCasualEarnedSick || 0, leaveTakenCasualEarnedSick || 0, leaveBalanceEarnedCasual || 0],
        colors: ['#2DD36F', '#EB445A', '#FFC409'],
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Total Leaves', "Leave Taken", "Balance"],
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            offsetY: 10,
                        },
                        // total: {
                        //     show: true,
                        //     showAlways: true,
                        //     fontSize: '11px',
                        //     fontFamily: 'Helvetica, Arial, sans-serif',
                        //     fontWeight: 220,
                        //     label: "Total Leaves",
                        //     color: '#040404',
                        // }
                    }
                }
            }
        },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex]
            },
            style: {
                colors: ['#000000'] // Set data label color to black
            }
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'bottom',
            offsetY: 10,
            formatter: function (val: any, opts: any) {
                return val + "  " + opts.w.globals.series[opts.seriesIndex]
            }
        },
    };


    const optionsForMonthlyReportBarChart: any = {
        series: [{
            name: 'Total Hours',
            data: userData?.map((item: any) => item?.totalHours || 0)
        }],
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            },
            events: {
                click: function () {
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        yaxis: {
            title: {
                text: 'Total Hours'
            }
        },
        xaxis: {

            title: {
                text: "",
                style: {
                    transform: 'translate(-25px, -33px) !important',
                    // cssClass: 'apexcharts-xaxis-label',
                    cssClass: 'apexcharts-xaxis-title',
                },
            },
            categories: userData?.map((item: any) => item?.date),
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
    };


    useEffect(() => {
        if (_authUser.typeOfUser == AppConstants.UserType.User) {
            navigate("/userdashboard");
        }
        if(_authUser.employeeInfo.roleId == "660d22ac5b3aca0a4f105c79"){
            navigate("/work-dairy");
        }
        if(_authUser.employeeInfo.roleId == "66727cc67014cdf72114239e"){
            navigate("/board");
        }
        
        if (_authUser.typeOfUser) {
            let curreUserRole = _authUser.typeOfUser;
            curreUserRole = curreUserRole ? curreUserRole : '';
            setLoggedInRole(curreUserRole);
        }
        const currentLoggedInUserName = _authUser.employeeInfo.currentUserName;
        setUsername(currentLoggedInUserName);
        GetLeaveAvailedByUserId();
        getUsersSpecificAttendance();
    }, []);

    //User Attendance Report
    const getUsersSpecificAttendance = async () => {

        const fromDate = dayjs().subtract(6, 'days').format("DD-MMM-YYYY");
        const toDate = dayjs().format("DD-MMM-YYYY");
        await reportService
            .getUserSpecificAttendance({
                startDate: fromDate,
                endDate: toDate,
                status: StaticStatusId !== undefined ? StaticStatusId : 0,
            })
            .then((response: any) => {
                if (response.data.isError) {

                } else {
                    setUserData(
                        response?.data
                    );
                }
            });
    };



    //Leave Management
    const GetLeaveAvailedByUserId = async () => {
        await leaveService.GetLeaveAvailedByUserId().then((response: any) => {
            if (response.data.isError) {
            } else {
                setLeaveCount(response.data);
            }
        });
    };



    return (
        <>
            {currentLoggedInRole != AppConstants.UserType.Admin && (
                <Stack direction="row"
                    justifyContent="flex-start"
                    alignItems="center" >
                    <Typography>
                        Welcome {userName}
                    </Typography>
                </Stack>
            )}
            {currentLoggedInRole == AppConstants.UserType.Admin ? (
                <Stack direction="row"
                    justifyContent="flex-start"
                    alignItems="center" >
                    <Grid container spacing={2} alignItems="stretch">

                        <Grid item xs={12} md={4} xl={3}>
                            <ItemPaper elevation={0} sx={Dashboardstyle.Fullheight}>
                                <Box component={Link} to='/project/projectlist' sx={Dashboardstyle.Tdnone}>
                                    <Stack direction='row' alignItems='center'>
                                        <Icons.AddProject />
                                        <Typography variant="h6" sx={Dashboardstyle.link}>
                                            Project Management
                                        </Typography>
                                    </Stack>
                                </Box>
                                <LongText content="As an admin you can create a new project, assign a project owner, allocate resources from your team and ask resources from other departments, see board and follow agile methodology. You can view complete milestones and sprints details of the project.Accept and reject offline hours posted by an employee." limit={100} />

                                <Box component={Link}
                                    to='/project/projectlist'
                                    sx={Dashboardstyle.Gopage}
                                >
                                    Go to page
                                </Box>
                            </ItemPaper>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <ItemPaper elevation={0} sx={Dashboardstyle.Fullheight}>
                                <Box component={Link} to='/role-management/add-new-role' sx={Dashboardstyle.Tdnone}>
                                    <Stack direction='row' alignItems='center'>
                                        <Icons.AddRole />
                                        <Typography variant="h6" sx={Dashboardstyle.link}>
                                            Add Role
                                        </Typography>
                                    </Stack>
                                </Box>
                                <LongText content='As an admin, in this section you create a new role, copy from an existing role, view list of all the roles added, perform CRED and provide screen based permissions to that role.' limit={100} />

                                <Box component={Link}
                                    to='/role-management/add-new-role'
                                    sx={Dashboardstyle.Gopage}
                                >
                                    Go to page
                                </Box>
                            </ItemPaper>
                        </Grid>

                        <Grid item xs={12} md={4} xl={3}>
                            <ItemPaper elevation={0} sx={Dashboardstyle.Fullheight}>
                                <Box component={Link} to='/users/add-user' sx={Dashboardstyle.Tdnone}>
                                    <Stack direction='row' alignItems='center'>
                                        <Icons.AddUser />
                                        <Typography variant="h6" sx={Dashboardstyle.link}>
                                            Add User
                                        </Typography>
                                    </Stack>

                                </Box>

                                <LongText content="As an admin, you can add a user by filling all the related details like user employee details, past experience, personal details, skill sets, achievements & certifications that the user has done.
                                    Leave management- As an admin you can set up leaves for the organization like the total number of casual leaves, sick leave, earned leaves to be provided to an employee." limit={100} />

                                <Box component={Link}
                                    to='/users/add-user'
                                    sx={Dashboardstyle.Gopage}
                                >
                                    Go to page
                                </Box>
                            </ItemPaper>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <ItemPaper elevation={0} sx={Dashboardstyle.Fullheight}>
                                <Box component={Link} to='/leave-Management' sx={Dashboardstyle.Tdnone}>
                                    <Stack direction='row' alignItems='center'>
                                        <Icons.LeaveManagement />
                                        <Typography variant="h6" sx={Dashboardstyle.link}>
                                            Leave Management
                                        </Typography>
                                    </Stack>

                                </Box>
                                <Typography variant="body1">

                                </Typography>
                                <LongText content=" As an admin you can set up leaves for the organization like the total number of casual leaves, sick leave, earned leaves to be provided to an employee." limit={100} />

                                <Box component={Link}
                                    to='/leave-Management'
                                    sx={Dashboardstyle.Gopage}
                                >
                                    Go to page
                                </Box>
                            </ItemPaper>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <ItemPaper elevation={0} sx={Dashboardstyle.Fullheight}>
                                <Box component={Link} to='/report-management' sx={Dashboardstyle.Tdnone}>
                                    <Stack direction='row' alignItems='center'>
                                        <Icons.ReportManagement />
                                        <Typography variant="h6" sx={Dashboardstyle.link}>
                                            Report Management
                                        </Typography>
                                    </Stack>

                                </Box>


                                <LongText content="As an admin, you can view all the reports related to project management, daily attendance, milestones reports,etc." limit={100} />
                                <Box component={Link}
                                    to='/report-management'
                                    sx={Dashboardstyle.Gopage}
                                >
                                    Go to page
                                </Box>
                            </ItemPaper>
                        </Grid>
                    </Grid>
                </Stack>
            ) : (
                <Box sx={{ mt: '18px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Box sx={{ background: '#fff', borderRadius: '10px', py: '14px', px: '20px' }} style={{ cursor: "pointer" }} onClick={() => { navigate("/userspecific-attendance") }}>

                                <Stack direction={'row'} justifyContent={"start"} alignItems={'center'} sx={{ borderBottom: '1px solid #EEE', pb: '12px' }}>
                                    <Box sx={{ width: "44px", height: "44px", background: "#F0F9FF", borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Icons.Userdashboardattendenceicon />
                                    </Box>
                                    <Box sx={{ ml: '20px' }}>
                                        <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                            Attendance Details
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Grid container>
                                    <Grid item xs={12} lg={7}>
                                        <TableContainer component={Paper} elevation={0} sx={{ border: '0' }}>
                                            <Table aria-label="simple table" size='small' border={0}>
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell sx={[styles.tableTH, { p: 1 }]}>Date</TableCell>
                                                        <TableCell sx={[styles.tableTH, { p: 1 }]}>Login Time</TableCell>
                                                        <TableCell sx={[styles.tableTH, { p: 1 }]}>Logout Time</TableCell>
                                                        <TableCell sx={[styles.tableTH, { p: 1 }]}>Actual hours</TableCell>
                                                        <TableCell sx={[styles.tableTH, { p: 1 }]}>Offline hours</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {userData.map((row: any, index: any) => (
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 500 }}>{row.date || "--"}</TableCell>
                                                            <TableCell sx={{ fontWeight: 500 }}>{row.loginTime || "--"}</TableCell>
                                                            <TableCell sx={{ fontWeight: 500 }}>{row.logoutTime || "--"}</TableCell>
                                                            <TableCell sx={{ fontWeight: 500 }}>{row.activeHours || "--"}</TableCell>
                                                            <TableCell sx={{ fontWeight: 500 }}>{row.offlineHours || "--"}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                    <Grid item sm={4}>
                                        <Item sx={{ height: "100%", boxShadow: "unset !important" }} style={{ cursor: "pointer", padding: "0" }}>

                                            <ItemBoxWrapper elevation={0} style={{ padding: "16px" }}>
                                                <ReactApexChart options={optionsForMonthlyReportBarChart} series={optionsForMonthlyReportBarChart?.series} type="bar" width={350} />
                                            </ItemBoxWrapper>
                                            <Box  sx={{transform: "translate(-20px, -59px)", fontWeight: "700"}} >
                                                Date
                                            </Box>
                                        </Item>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Box sx={{ background: '#fff', borderRadius: '10px', py: '14px', px: '20px' }} style={{ cursor: "pointer" }} onClick={() => { navigate("/leave-management") }}>
                                <Stack direction={'row'} justifyContent={"start"} alignItems={'center'} sx={{ borderBottom: '1px solid #EEE', pb: '12px' }}>
                                    <Box sx={{ width: "44px", height: "44px", background: "#F0F9FF", borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: '20px' }}>
                                        <Icons.Leaves />
                                    </Box>
                                    <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                        Leaves Details
                                    </Typography>
                                </Stack>
                                <Box sx={{ pt: '18px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", justifyContent: "space-around", gap: "16px" }}>
                                                <Stack direction="row" alignItems="center">
                                                    <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", width: "100%" }}>
                                                        <Typography variant="h5" sx={[styles.titleLeave, styles.sickLeaves]}>
                                                            Availed
                                                        </Typography>
                                                        <Grid container spacing={2} sx={{ mt: .5 }}>
                                                            <Grid item xs={12} md={6}>
                                                                <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={[styles.titleLeave, styles.casualLeaves]}

                                                                    >
                                                                        Casual + Sick Leaves
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Box>

                                                                            <Typography variant="h6">
                                                                                {leaveCount?.leaveTaken} days{" "}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={[styles.titleLeave, styles.casualLeaves]}
                                                                    >
                                                                        Earned Leaves
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Box>

                                                                            <Typography variant="h6">
                                                                                {leaveCount?.earnedLeaveTaken} days{" "}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>

                                                </Stack>
                                                <Stack direction="row" alignItems="center">
                                                    <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", width: "100%" }}>
                                                        <Typography variant="h5" sx={[styles.titleLeave, styles.sickLeaves]}>
                                                            Balance Leaves

                                                        </Typography>
                                                        <Grid container spacing={2} sx={{ mt: .5 }}>
                                                            <Grid item xs={12} md={6}>
                                                                <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={[styles.titleLeave, styles.casualLeaves]}

                                                                    >
                                                                        Casual + Sick Leaves
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Box>

                                                                            <Typography variant="h6">
                                                                                {leaveCount?.leaveBalance} days{" "}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={[styles.titleLeave, styles.casualLeaves]}
                                                                    >
                                                                        Earned Leaves
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Box>

                                                                            <Typography variant="h6">
                                                                                {leaveCount?.earnedLeaveBalance} days{" "}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>

                                                </Stack>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ReactApexChart options={options} series={options?.series} type="donut" width={280} />
                                                </Box>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>



                    </Grid>
                </Box>
                // <NotFound NotfoundText="Work In Progress" />
            )}


        </>
    );
}