import { http1 } from "./http-commom1";
class HelpDeskReport {
    getCategoryDropdown = async (): Promise<any> => {
        return await http1.get<any>("/category/");
    };
    getReportDetails = async (data: any): Promise<any> => {
        let url = "/elasticsearch/records?";
      
        // Loop through the properties in data
        for (const key in data) {
          if (data.hasOwnProperty(key) && data[key]) {
            // Append key-value pairs to the URL
            url += `${key}=${data[key]}&`;
          }
        }
      
        url = url.slice(0, -1);
      
        return await http1.get<any>(url);
      };
    getCommentHistory = async (data:any): Promise<any> => {
        return await http1.get<any>('/comment/'+ data);
    };
    getReportDetailsById = async (data:any): Promise<any> => {
        return await http1.get<any>('/incident/'+ data);
    };
    getHelpDeskCount = async (data: any): Promise<any> => {
      let url = "/elasticsearch/bucketcount?";
      // Loop through the properties in data
      for (const key in data) {
        if (data.hasOwnProperty(key) && data[key]) {
          // Append key-value pairs to the URL
          url += `${key}=${data[key]}&`;
        }
      }
      url = url.slice(0, -1);
      return await http1.get<any>(url);
    };
}

export const helpDeskReport = new HelpDeskReport();