import { http } from "./http-common";
class kraratingService {
 
addKRA = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/KRA/CreateKRA", data);
    };
   
    UpdateKRA = async (req: any): Promise<any> => {
        return await http.put<any>("/v1/KRA/UpdateKRA", req);
    };
    getallKraByUserId = async (id: any): Promise<any> => {
        return await http.post<any>("/v1/KRA/GetAllKRAByUserId", id);
    };
    deleteKraById = async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/KRA/DeleteKRA?id=${id}`);
      };
   getKRAReport = async (data: any): Promise<any> => {
    return await http.post<any>(
      `/v1/KRA/GetKRAReport`,data
    );
  };
  addComment = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/KRA/AddKraComment", data);
    };
    getAllKraCommentByKraId = async (id: any): Promise<any> => {
      return await http.post<any>(`/v1/KRA/GetAllKraCommentByKraId?KRAId=${id}`);
  };
  deleteCommentById = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/KRA/DeleteKraComment?CommentId=${id}`);
  };
  getKRAUserDetailsById = async (id: any): Promise<any> => {
    return await http.get<any>(`/UserManagement/GetUserDetailById/` +id);
}
 
getKRARatingDetailsForReport = async (data: any): Promise<any> => {
  return await http.post<any>(
    `/v1/KRA/KRADetailedReportByUser`,data
  );
};
getAllPredefinedKraNotAddedForUser= async (data: any): Promise<any> => {
  return await http.post<any>("/v1/KRA/GetAllNotFixedKra", data);
};

UploadKRADocument = async (data : any): Promise<any> => {
  return await http.post<any>("/v1/KRA/KraUploadFile", data);
};
}
export const kraratingServices = new kraratingService();