import { combineReducers } from "redux";
const Fill_LearningSession_Permission = 'Fill_LearningSession_Permission';
const Empty_LearningSession_Permission = 'Empty_LearningSession_Permission';

const defaultPermissions = {
    access: false,
    module_ManageLearningSession_Access:false,
    page_ManageLearningSessionListing_Access:false,
    tab_ManageLearningSessionEdit_Access:false,
    tab_ManageLearningSessionAdd_Access:false,
    tab_ManageLearningSessionDelete_Access:false,

}
export function FillLearningSessionPermission(module) {
    return {
        type: Fill_LearningSession_Permission,
        module,
    }
}
export function EmptyLearningSessionPermission() {
    return {
        type: Empty_LearningSession_Permission, defaultPermissions
    }
}
function LearningSessionPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_LearningSession_Permission:
            var data = action.module;
            var permissions = defaultPermissions;        
            if (data) {
                permissions.access=true;
                permissions.module_ManageLearningSession_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                  data.listPageMaster.forEach((page) => {
                    if (page.pageName === "LearningSessionListing") {
                      permissions.page_ManageLearningSessionListing_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Add") {
                            permissions.tab_ManageLearningSessionAdd_Access = true;
                          }
                          if (tabs.tabName === "Edit") {
                            permissions.tab_ManageLearningSessionEdit_Access = true;
                          }
                          if (tabs.tabName === "Delete") {
                            permissions.tab_ManageLearningSessionDelete_Access = true;
                          }
                        });
                      }
                    }
                  });
                }
                return state = permissions;
            }
            else {
                return state = defaultPermissions;
            }
        case Empty_LearningSession_Permission:
            return state = defaultPermissions;
        default:
            return state;
    }
}
const LearningSessionApp = combineReducers({
    LearningSessionPermission
})
export default LearningSessionApp;