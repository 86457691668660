import { combineReducers } from "redux";
const Fill_DomainPortfolio_Permission = 'Fill_DomainPortfolio_Permission';
const Empty_DomainPortfolio_Permission = 'Empty_DomainPortfolio_Permission';

const defaultPermissions = {
    access: false,
    module_DomainPortfolio_Access:false,
    page_ManageDomainPortfolioListing_Access:false,
    tab_ManageDomainPortfolioEdit_Access:false,
    tab_ManageDomainPortfolioView_Access:false,
    tab_ManageDomainPortfolioUploadDocument_Access:false,
    tab_ManageDomainPortfolioDeleteDocument_Access : false,
    tab_ManageDomainPortfolioAdd_Access : false,

   

}
export function FillDomainPortfolioPermission(module) {
    return {
        type: Fill_DomainPortfolio_Permission,
        module,
    }
}
export function EmptyDomainPortfolioPermission() {
    return {
        type: Empty_DomainPortfolio_Permission, defaultPermissions
    }
}
function DomainPortfolioPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_DomainPortfolio_Permission:
            var data = action.module;
            var permissions = defaultPermissions;        
            if (data) {
                permissions.access=true;
                permissions.module_DomainPortfolio_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                  data.listPageMaster.forEach((page) => {
                    if (page.pageName === "DomainPortfolioListing") {
                      permissions.page_ManageDomainPortfolioListing_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Add") {
                            permissions.tab_ManageDomainPortfolioAdd_Access = true;
                          }
                          if (tabs.tabName === "Edit") {
                            permissions.tab_ManageDomainPortfolioEdit_Access = true;
                          }
                          if (tabs.tabName === "View") {
                            permissions.tab_ManageDomainPortfolioView_Access = true;
                          }
                          if (tabs.tabName === "Upload Document") {
                            permissions.tab_ManageDomainPortfolioUploadDocument_Access = true;
                          }
                          if (tabs.tabName === "Delete Document") {
                            permissions.tab_ManageDomainPortfolioDeleteDocument_Access = true;
                          }
                         
                        });
                      }
                    }
                  });
                }


               
                return state = permissions;
            }
            else {
                return state = defaultPermissions;
            }
        case Empty_DomainPortfolio_Permission:
            return state = defaultPermissions;
        default:
            return state;
    }
}
const DomainPortfolioApp = combineReducers({
    DomainPortfolioPermission
})
export default DomainPortfolioApp;