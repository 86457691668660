import { Class } from "@mui/icons-material";
import { http } from "./http-common";
class TicketManagementService {
  createOfflineWorkHours = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/OfflineWorkHours/Add", data);
  };
  getOfflineWorkHours = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/GetWorklogsForticket/Get", data);
  };

  updateOfflineWorkHoursApprove = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Ticket/ApproveOfflineWorkHours/Approve", data);
  };

//   deleteOfflineHours = async (data: any): Promise<any> => {
//     return await http.delete<any>("/v1/Ticket/DeleteOfflineHours", data) ;
// }

deleteOfflineHours = async (ticketId: any,offlineHoursId: any): Promise<any> => {
  return await http.delete<any>(`/v1/Ticket/DeleteOfflineHours?TicketId=${ticketId}&OfflineHoursId=${offlineHoursId}`);
};

 }

export const ticketManagementService = new TicketManagementService();
