import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Typography, Tab, Tabs, Box, Paper, TableSortLabel, styled, Chip, ChipProps, TablePagination } from "@mui/material";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import theme from "../../theme/theme";
import Avatar from '@mui/material/Avatar';
import * as Icons from '../../assests/icons/icons';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import AppConstants from '../../config/AppConstants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Formik, getIn } from 'formik';
import { toast } from 'material-react-toastify';
import { leaveService } from '../../service/Leave/LeaveService';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { visuallyHidden } from '@mui/utils';
import StyledTableCell from '../../components/styledComponent/CustomTableCell';
import { date } from 'yup';
import moment from 'moment';
import * as Yup from 'yup';
import { InputAdornment } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import LongText from '../../components/longtext';


const styles: any = {
    cardLeaves: {
        borderRadius: '10px',
        padding: '22px 18px'
    },
    titleLeave: {
        color: '#1F944E',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: '25px'
    },
    annualLeaves: {
        color: '#1F944E',
    },
    sickLeaves: {
        color: '#B38906',
    },
    casualLeaves: {
        color: '#16B5BF',
    },
    compOff: {
        color: '#1DA7FF',
    },
    otherLeaves: {
        color: '#74C225',
    },
    total: {
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: ' 18px',
    },
    values: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: ' #424242',
    },
    taken: {
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: ' 18px',
    },
    takenvalues: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: ' #757575',
    },
    applyLeave: {
        backgroundColor: '#F0F9FF',
        borderRadius: '10px',
        padding: '22px 18px',
        maxWidth: '412px',
        marginLeft: 'auto',

    },
    applyLeaveHeading: {
        color: '#232360',
        fontWeight: 500,
        fontSize: ' 26px',
    },
    selectType: {
        backgroundColor: '#fff',
        marginTop: '10px'

    },
    selectlabel: {
        fontWeight: 400,
        fontSize: ' 14px',
        lineHeight: '21px',
        marginLeft: 0,
        marginBottom: '6px',
        color: '#212121',
    },
    spaceTop: {
        marginTop: '24px',
    },
    AnnualLeavesProgress: {
        '&.MuiLinearProgress-root': {
            height: '6px',
            backgroundColor: '#D9D9D9',
            borderRadius: '20px',
            '& .MuiLinearProgress-barColorPrimary': {
                background: 'linear-gradient(90deg, #2DD36F 50%, #FFBB0B 78.73%)',
                borderRadius: ' 20px',
                backgroundColor: 'unset'
            }
        },

    },
    SickLeavesProgress: {
        '&.MuiLinearProgress-root': {
            height: '6px',
            backgroundColor: '#D9D9D9',
            borderRadius: '20px',
            '& .MuiLinearProgress-barColorPrimary': {
                background: ' linear-gradient(90deg, #B38906 50%, #FFBB0B 78.73%);',
                borderRadius: ' 20px',
                backgroundColor: 'unset'
            }
        },

    },
    CasualLeavesProgress: {
        '&.MuiLinearProgress-root': {
            height: '6px',
            backgroundColor: '#D9D9D9',
            borderRadius: '20px',
            '& .MuiLinearProgress-barColorPrimary': {
                background: ' linear-gradient(90deg, #15B5BF 50%, #FFBB0B 78.73%);',
                borderRadius: ' 20px',
                backgroundColor: 'unset'
            }
        },

    },
    CompLeavesProgress: {
        '&.MuiLinearProgress-root': {
            height: '6px',
            backgroundColor: '#D9D9D9',
            borderRadius: '20px',
            '& .MuiLinearProgress-barColorPrimary': {
                background: 'linear-gradient(90deg, #1DA7FF 50%, #FFBB0B 78.73%);',
                borderRadius: ' 20px',
                backgroundColor: 'unset'
            }
        },

    },
    OtherLeavesProgress: {
        '&.MuiLinearProgress-root': {
            height: '6px',
            backgroundColor: '#D9D9D9',
            borderRadius: '20px',
            '& .MuiLinearProgress-barColorPrimary': {
                background: 'linear-gradient(90deg, #74C225 50%, #FFBB0B 78.73%);',
                borderRadius: ' 20px',
                backgroundColor: 'unset'
            }
        },
        labelLeft: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: ' 21px',
            /* Primary Color/Primary_dark shade */
            color: theme.palette.primary.dark,
            width: '150px',
            minWidth: '130px'
        },
        valueRight: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: ' 21px',
            /* Primary Color/Primary_dark shade */
            color: ' #424242',
            flexGrow: 1,
        }
    },
}





export default function ViewReject(props: any) {
    
    const { leaveId, handleCloseVR, check } = props;
   // const [formValues, setFormValues] = React.useState<updateleave>(obj);
    const [leavedetail, setLeaves] = useState<any>();
    const [errorMessage, setErrorMessage] = useState(false);
    
    const [disable, setDisable] = React.useState(false);

   

    const getLeaveDetailsById = async (id: any) => {
        try {

            await leaveService.getLeaveDetailsById(id)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.error('Something went wrong...');
                    }
                    else {

                        setLeaves(response.data);
                    }
                });
        }
        catch (ex: any) {
          
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    }

    

   

    React.useEffect(() => {
        if (leaveId != "") {
            getLeaveDetailsById(leaveId);

        }
    }, []);
    const fieldValidationSchema = Yup.object().shape({
       
    })


    return (

        <Formik
            initialValues={
                {
                    id: "",
                    status:"",
                    managerComment:""
                }}
            validationSchema={fieldValidationSchema}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                try {
                    setErrorMessage(true);

                    
                } catch (err: any) {
                    console.error(err);
                    err = JSON.parse(JSON.stringify(err));
                    if (err.status == 400) {
                       
                        {
                            err.data.Error.map((error: any, idx: any) => (
                                setErrorMessage(error)
                            ))
                        }
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}>
                    <DialogContent sx={{ mt: 3 }}>
                        <TableContainer component={Paper} elevation={0}>

                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                    Name
                                </Box>
                                <Box sx={[styles.valueRight]}>
                                    {leavedetail?.employeeName}
                                </Box>
                            </Stack>
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                    Leave Type
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {leavedetail?.type}
                                </Box>
                            </Stack>
                            {(leavedetail?.compOffFor!= null &&
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                  Worked on 
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {`${moment(leavedetail?.compOffFor).utc().format(
                                        "DD MMM, YYYY"
                                    )}`}
                                </Box>
                            </Stack>)}
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                   {leavedetail?.updateStartDate!= null ? "Previous Start Date" : "From"} 
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {`${moment(leavedetail?.startdate).utc().format(
                                        "DD MMM, YYYY"
                                    )}`}
                                </Box>
                            </Stack>
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                {leavedetail?.updateStartDate!= null ? "Previous End Date" : "Till"}
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {`${moment(leavedetail?.enddate).utc().format(
                                        "DD MMM, YYYY"
                                    )}`}
                                </Box>
                            </Stack>
                            {(leavedetail?.updateStartDate!= null &&
                                <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                   From
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {`${moment(leavedetail?.updateStartDate).utc().format(
                                        "DD MMM, YYYY"
                                    )}`}
                                </Box>
                            </Stack>)}
                            {(leavedetail?.updateEndDate!= null &&
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                  Till
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {`${moment(leavedetail?.updateEndDate).utc().format(
                                        "DD MMM, YYYY"
                                    )}`}
                                </Box>
                            </Stack>)}
                          
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                    Reason
                                </Box>
                                {(leavedetail?.reason !== null &&
                                    <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {/* {leavedetail?.reason} */}
                                    <LongText content={leavedetail?.reason} limit={30}/>
                                </Box>)}
                                {(leavedetail?.reason === null &&
                                    <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    -
                                </Box>)}
                            </Stack>
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                    Applied On
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {`${moment(leavedetail?.submittedOn).utc().format(
                                        "DD MMM, YYYY"
                                    )}`}
                                </Box>
                            </Stack>
                            <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                   Duration
                                </Box>
                                {(leavedetail?.duration !== null &&
                                    <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    {leavedetail?.duration}
                                    {leavedetail?.durationType ? ` (${leavedetail?.durationType})` : ""}
                                    {leavedetail?.startTime && leavedetail?.endTime ? ` (${leavedetail?.startTime} - ${leavedetail?.endTime})` : ""}
                                </Box>)}
                                {(leavedetail?.duration === null &&
                                    <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    -
                                </Box>)}
                            </Stack>

                            <Stack direction='row' alignItems='center' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                    Status
                                </Box>  {(AppConstants.Status.Rejected === leavedetail?.status &&
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    Rejected
                                </Box>)}
                                {(AppConstants.Status.Approved === leavedetail?.status &&
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                    Approved
                                </Box>)}
                            </Stack>
                            {(AppConstants.Status.Rejected === leavedetail?.status &&
                             <Stack direction='row' sx={{ mb: 1 }}>
                                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                                    Rejected Reason
                                </Box>
                                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                                {leavedetail?.comment}
                                </Box>
                            </Stack>
                            )}
                            
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 3 }}>
                        <Button onClick={handleCloseVR} variant='outlined' >close</Button>
                        
                    </DialogActions>
                    


                </form>
            )}
        </Formik>

    );
}

// RequestUpdate;
