import { http} from "./http-common";

class SlabgenrationService {
    AddPayRoll = async (data: any): Promise<any> => {
        return await http.post<any>("v1/PayRoll/AddPayRoll", data);
    }

    getpayrollslabById= async (id:any): Promise<any> => {
        return await http.get<any>(`/v1/PayRoll/GetPayRollSlabById?id=${id}`);
    }
    
    getAllPayrollSlab = async (data: any): Promise<any> => {
        debugger;
        return await http.post<any>(`/v1/PayRoll/GetAllIPayRollSlab`, data);
    };
    getallallow = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Master/Allowance/GetAllAllowance", data);
    }
    Updateslabpay = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/PayRoll/UpdatePayRoll", data);
    };
    deletePayrollSlab= async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/PayRoll/DeleteById?id=${id}`);
    }
    
}
export const slabgenrationService = new SlabgenrationService();