import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import Typography from '@mui/material/Typography';
import { Box, Breadcrumbs, Divider, Modal, Grid, Button, TablePagination, Checkbox } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Department } from '../../Interfaces/IDepartment';
import { IResource } from '../../Interfaces/IResource';
import { clsResourcesList } from '../../service/common-services/resources';
import { clsDepartmentList } from '../../service/common-services/department';
import { reserveServices } from '../../service/Reserve-services';
import InfoPopover from '../../components/CommonInfoComponent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { eTypeOfUser } from '../../Enum/eTypeOfUser';

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

interface ReserveInter {
    totalProjectResources: string;
    totalResources: string;
    reserveBrigadeReportListOutputModels: [
        {
            empId: string,
            duration: string,
            department: string,
            experience: string,
            name: string,
            projectsList: string,
            skillSets: string
            userId: string
        }
    ]
}
let Perioddata = [
    {
        id: 1,
        label: "Less then 2 yrs"
    },
    {
        id: 2,
        label: "Less then 5 yrs"
    },
    {
        id: 3,
        label: "Greater then 5 yrs"
    }
]

export default function Reserve() {
    const { depId } = useParams();
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const navigate = useNavigate();
    const [reserve, setReserve] = useState<ReserveInter>({} as ReserveInter);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = useState("");
    const [departmentsId, setdepartmentsId] = useState("");
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);

    const getReserveHistory = async (
        departmentId: any,
        resourceId: any,
        checked: any,
        selectedPeriod: any
    ) => {
        let values = {
            department: departmentId,
            resources: resourceId,
            isOnlyReserveBrigade: checked,
            experience: selectedPeriod,
            skip: 0,
            take: 1000
        };
        await reserveServices
            .getReserveDetails(values)
            .then((response: any) => {
                if (response.data.isError) {
                } else {
                    //response.data
                    setPage(0)
                    setReserve(response?.data);
                }
            });
    };

    React.useEffect(() => {
        if (_permission.tab_ReserveBrigade_ReserveBrigadeListing_Access !== true) {
            navigate("/dashboard");
        }

        const departmentsData = async () => {
            if (depId) {
                setdepartmentsId(depId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(depId));
                getReserveHistory(depId, "", false, 0);
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                    if (_permission.action_ReserveBrigade_ReserveBrigadeListing_AllDepartments_Access == true) {
                        setdisabledDepartments(false);
                    } else {
                        setdisabledDepartments(true);
                    }
                    setdepartmentsId(_authUser.employeeInfo?.departmentId);
                    setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo?.departmentId))
                    getReserveHistory(_authUser.employeeInfo?.departmentId, "", false, 0);
                }
                else {
                    setresources(await clsResourcesList.getResourcesDropdownList(""));
                    getReserveHistory("", "", false, 0);
                }
            }
        };
        departmentsData();
    }, []);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(reserve?.reserveBrigadeReportListOutputModels?.length / rowsPerPage);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        getReserveHistory(departmentsId, resourceId, event.target.checked, selectedPeriod)
    };

    return (
        <>
            <Paper
                elevation={0}
                sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99, background: 'white', height: 'auto' }}
            >
                <Box>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        sx={{ mt: 1.5 }}
                    >
                        <Grid sx={{ display: "flex", alignItems: "center", gap: "8px" }} item xs={12} lg={12}>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                                Reserve Brigade
                            </Typography>
                            <InfoPopover onInput={"Reserve Brigade"} />
                            <Box sx={{ml: 4}}>
                            Only Brigade 
                            </Box>
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                alignItems={{ xs: "strech", md: "center" }}
                                justifyContent={{ xs: "flex-start", md: "space-between", lg: "flex-start" }}
                                spacing={2}
                                sx={{ pb: 3 }}
                            >
                                <Box >
                                    <Autocomplete
                                        value={
                                            Perioddata?.find(
                                                (option) => option.id == selectedPeriod
                                            ) ?? null
                                        }
                                        fullWidth
                                        // sx={{ ml: "15px" }}
                                        id="department-drop"
                                        options={Perioddata}
                                        getOptionLabel={(option) => option.label}
                                        onChange={async (event, value) => {
                                            setfiltersSelected(true);
                                            setSelectedPeriod(value?.id ?? 0)

                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="department-drop"
                                                name="departmentsId"
                                                variant="outlined"
                                                value={selectedPeriod}
                                                placeholder="Select Period"
                                                fullWidth
                                                sx={{ minWidth: 220 }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box >
                                    <Autocomplete
                                        value={
                                            departments?.find(
                                                (option) => option.id == departmentsId
                                            ) ?? null
                                        }
                                        fullWidth
                                        // sx={{ ml: "15px" }}
                                        id="department-drop"
                                        options={departments}
                                        disabled={disabledDepartments}
                                        getOptionLabel={(option) => option.name}
                                        onChange={async (event, value) => {
                                            setfiltersSelected(true);
                                            setdepartmentsId(value?.id ?? "")
                                            setResourceId("");
                                            setresources(
                                                await clsResourcesList.getResourcesDropdownList(value?.id ?? "")
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="department-drop"
                                                name="departmentsId"
                                                variant="outlined"
                                                value={departmentsId}
                                                disabled={disabledDepartments}
                                                fullWidth
                                                sx={{ minWidth: 220 }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ mr: "20px" }}>
                                    <Autocomplete
                                        disabled={!departmentsId || departmentsId === ""}
                                        value={
                                            resources?.find(
                                                (option) => option.userId == resourceId
                                            ) ?? null
                                        }
                                        sx={{ mr: "20px" }}
                                        fullWidth
                                        id="resources-drop"
                                        options={resources}
                                        getOptionLabel={(option) => option.userName}
                                        onChange={async (event, value) => {
                                            setfiltersSelected(true);
                                            setResourceId(value?.userId ?? "")
                                            // ResourceId = value?.userId ?? "";
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="resources-drop"
                                                name="resourceId"
                                                //label="Departments"
                                                variant="outlined"
                                                value={resourceId}
                                                fullWidth
                                                sx={{ minWidth: 220 }}
                                            />
                                        )}
                                    />

                                </Box>
                                <Button
                                    onClick={() => {
                                        getReserveHistory(departmentsId, resourceId, checked, selectedPeriod)
                                        setPage(0);
                                    }}
                                    color="primary"
                                    variant="contained"
                                    sx={{ ml: 0.5 }}
                                >
                                    Go
                                </Button>
                                {filtersSelected && <Button
                                    onClick={async () => {
                                        setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                                        setfiltersSelected(false);
                                        setChecked(false);
                                        setSelectedPeriod(0)
                                        if (_authUser.typeOfUser !== eTypeOfUser.Admin) {
                                            setdepartmentsId(_authUser.employeeInfo?.departmentId);
                                            setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo?.departmentId))
                                            getReserveHistory(_authUser.employeeInfo?.departmentId, "", false, 0);
                                            setResourceId("");
                                        }
                                        else {
                                            setresources(await clsResourcesList.getResourcesDropdownList(""));
                                            getReserveHistory("", "", false, 0);
                                            setResourceId("");
                                        }
                                    }}
                                    color="warning" variant='contained' sx={{ ml: 0.5 }}>Clear</Button>}
                                <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                                    <Typography component="h2" sx={{ fontSize: '16px' }}>
                                        Reserve Brigade
                                    </Typography>
                                    <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#2DD36F' }}>
                                        {reserve?.totalProjectResources}
                                    </Typography>
                                </Box>
                                <Box sx={{ px: "18px", py: "5px", textAlign: 'start', border: 1, borderColor: '#EEEEEE', borderRadius: '12px' }}>
                                    <Typography component="h2" sx={{ fontSize: '16px' }}>
                                        Total Resource
                                    </Typography>
                                    <Typography variant="h4" component="h2" sx={{ fontWeight: 700, color: '#1DA7FF' }}>
                                        {reserve?.totalResources}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

                <TableContainer component={Paper} sx={{ mt: 1 }}>
                    <Table aria-label="simple table" size="small" sx={{ position: 'relative', overflowX: 'scroll' }}>
                        <TableHead sx={headcellcolor.cellcolor}>
                            <TableRow>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', Width: '90px', background: 'rgb(189, 189, 189)', }} >Emp ID</StyledTableCell>
                                <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: 'rgb(189, 189, 189)', }} >Name</StyledTableCell>
                                <StyledTableCell align="left" >Dept</StyledTableCell>
                                <StyledTableCell align="left" >Experience</StyledTableCell>
                                <StyledTableCell align="left">Skill Set</StyledTableCell>
                                <StyledTableCell align="left">Duration</StyledTableCell>
                                <StyledTableCell align="left">Projects</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* <TableRow>
                                <StyledTableCell align="left">3110</StyledTableCell>
                                <StyledTableCell align="left">John Richard</StyledTableCell>
                                <StyledTableCell align="left">Designing</StyledTableCell>
                                <StyledTableCell align="left">4.5year</StyledTableCell>
                                <StyledTableCell align="left">Web & Mobile UI Expert</StyledTableCell>
                                <StyledTableCell align="left">1Month</StyledTableCell>
                                <StyledTableCell align="left">Seasia Connect</StyledTableCell>
                            </TableRow> */}
                            {reserve?.reserveBrigadeReportListOutputModels?.length > 0 &&
                                reserve?.reserveBrigadeReportListOutputModels?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    // {reserve?.reserveBrigadeReportListOutputModels?
                                    .map((user, idx) => (
                                        <TableRow
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <StyledTableCell align="left" sx={{ position: 'sticky', left: '0', width: '90px', background: '#fff', }}>{user?.empId}</StyledTableCell>
                                            <StyledTableCell align="left" sx={{ position: 'sticky', left: '77px', width: 'auto', background: '#fff', }} >{user?.name}</StyledTableCell>
                                            <StyledTableCell align="left">{user?.department}</StyledTableCell>
                                            <StyledTableCell align="left">{user?.experience}</StyledTableCell>
                                            <StyledTableCell align="left">{user?.skillSets}</StyledTableCell>
                                            <StyledTableCell align="left">{user?.duration}</StyledTableCell>
                                            <StyledTableCell align="left">{user?.projectsList}</StyledTableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                    component="div"
                    count={reserve?.reserveBrigadeReportListOutputModels?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    labelDisplayedRows={() => `Total: ${reserve?.reserveBrigadeReportListOutputModels?.length}  Page ${page + 1} of ${pageCount}`}
                />
            </Paper>
        </>
    );
}
