import React, { useEffect, useRef, useState } from "react";
import theme from "../../theme/theme";
import * as Icons from "../../assests/icons/icons";
import CardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import { DatePicker } from 'react-rainbow-components';
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import AppConstants from "../../config/AppConstants";
import { projectManagementServive } from "../../service/project-management-service";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import moment from "moment";
import { Formik, getIn } from "formik";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IFiles } from "../users/userModel";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Typography,
  Button,
  MenuItem,
  TextField,
  Grid,
  Chip,
  Box,
  Paper,
  IconButton,
  Stack,
  Divider,
  Tooltip,
} from "@mui/material";
import { boardDashboardServive } from "../../service/BoardDashboardServive";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import FileUpload2 from "../../components/styledComponent/FileUpload2";
import Multiselect from "multiselect-react-dropdown";
import { milestoneManagementService } from "../../service/milestone-management-service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from '@material-ui/icons/Close';
import { Link, useNavigate } from "react-router-dom";

const styleCross = {
  position: "absolute" as "absolute",
  right: "5px",
  top: "5px",
};
// Accordions Const
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#1DA7FF" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// -----------------
const styles: any = {
  EventInfoCard: {
    border: "1px solid #EEEEEE",
    padding: "18px 14px",
    bordeRadius: "5px",
    cursor: "pointer",
    minHeight: '229px',
    display: "flex",
    flexDirection: "column",
    "word-break": "break-all",

    justifyContent: "space-between",
    "&:hover": {
      boxShadow: "0px 0px 40px rgba(135, 135, 135, 0.25)",
    },

    word:
    {
      "word-break": "break-all",
    }
  },
  innerSpace: {
    // padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",

  },
  EventName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: theme.palette.primary.dark,
  },
  EventId: {
    letterSpacing: "0.1px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    color: "#232360",
  },
  EventDesc: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginTop: "15px",
  },
  EventDate: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #616161",
    marginLeft: "10px",
  },
  LineClamp: {
    marginTop: "15px",
    whiteSpace: "nowrap",
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  DialogTitle: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: " 39px",
    color: theme.palette.primary.dark,
  },
  Schedulefor: {
    fontWeight: 400,
    fontSize: "16.2314px",
    lineHeight: "19px",
    color: " #212121",
    margin: 0,
  },
  InnerHtml: {
    marginBottom: "12px",
    "& p": {
      margin: 0,
      textAlign: "center",
      fontSize: "24px",
      color: theme.palette.primary.dark,
    },
  },
  TitleLabel: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#232360",

  },
  TitleValue: {
    fontWeight: 600,
    fontSize: " 18px",
    lineHeight: "21px",
    color: " #424242",
    marginLeft: "8px",
    "word-break": "break-all",
  },
  Active: {
    fontWeight: 400,
    color: theme.palette.success.dark,
    m: 0,
  },

  Schedule: {
    fontWeight: 400,
    color: "#5F6388",
    m: 0,
  },

  Expired: {
    fontWeight: 400,
    color: theme.palette.error.dark,
    m: 0,
  },
};
const CustomCardHeader = styled(CardHeader)<CardHeaderProps>(({ theme }) => ({
  padding: "0px",
  paddingLeft: "0",
  alignItems: "center",
  // width: 300,
  // color: theme.palette.success.main,
  "& .MuiCardHeader-title": {
    fontSize: "0.875rem",
    fontWeight: "300",
    marginBottom: "4px",
    color: theme.palette.grey[800],
    //   '&.Mui-active': {
    //     boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    //   },
  },

  "& .MuiCardHeader-subheader": {
    fontSize: "0.875rem",
    fontWeight: "500",
    "word-break": "break-all",
    color: theme.palette.grey[900],
    "& span": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "8px",
      },
    },
  },
}));


const milestonecompleted = {
  fontSize: "0.75rem",
  color: theme.palette.success.main,
  fontWeight: 500,
};

const milestonename = {
  fontSize: "0.875rem",
  fontWeight: "500",
  // color: theme.palette.grey[900],
  color: "#1DA7FF",
  textDecoration: "underline",
};

interface Channel {
  id: string;
  name: string;
  status: string;
}

interface MilestoneExtendDate {
  milestoneId: string;
  reason: string;
  newMilestoneDate: Date;


}

const DummyBillingType = [
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
  { id: "quarterly", name: "Quarterly" },
  { id: "half-yearly", name: "Half Yearly" },
];

const dummy = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export default function ProjectDetails(props: {
  projectDetail: any;
  getProjectDetails: any;
  setsprintOpen: any;
  setOpen: any;
  setMileOpen: any;
  ref: any;
  afterSave: any;
  isMilestoneOver: boolean;
}) {

  //const [projectDetailHook, setProjectDetailHook]=useState<IprojectDetail>();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [updateProjectDetail, setUpdateProjectDetail] =
    useState<boolean>(false);
  const [updateClientInfo, setUpdateClientInfo] = useState<boolean>(false);
  const [updatePaymentInfo, setUpdatePaymentInfo] = useState<boolean>(false);
  const [novisible, setNoVisible] = useState(true);
  const [channel, setChannel] = useState<Channel[]>([]);
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [Status, setStatus] = React.useState("");
  const [openAlert, setAlertOpen] = React.useState(false);
  const [openAlertDelete, setAlertOpenDelete] = React.useState(false);
  const [openAlerts, setAlertOpens] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState("");
  const [currentMilestone, setCurrentMilestone] = React.useState("");
  const [projectDetailHook, setProjectDetailHook] = useState<any>(
    props.projectDetail
  );
  const _permission = useSelector(
    (state: any) => state.ProjectPermissionApp.ProjectPermission
  );
  const [mileStoneId, setMileStoneId] = useState<any>("");
  const [selectedSprints, setselectedSprints] = useState<any[]>([]);
  const [projectRiskData, setProjectRiskData] = useState<any>("");
  const navigate = useNavigate();

  const handleCloseDeleteAlert = () => {
    setAlertOpenDelete(false);
  };

  let IfileObj: IFiles = {
    itemType: "",
    name: "",
    fileURL: "",
    fileName: "",
    file: null,
  };
  let objDocument: IFiles[] = [];
  const [documentName, setDocumentName] = React.useState("");
  const [documentFile, setDocumentFile] = React.useState<any>(null);
  const [documentFileURL, setDocumentFileURL] = React.useState<any>("");
  const [tempDocumentFileURL, setTempDocumentFileURL] = React.useState<any>("");
  const [documentFileName, setDocumentFileName] = React.useState<any>("");
  const [documentFormValues, setDocumentFormValues] = useState<IFiles[]>(objDocument);
  const [formValues, setFormValues] = useState<IFiles[]>(objDocument);
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [value, setValue] = React.useState(0);
  const [billedHour, setBilledHour] = React.useState("");
  const [selectedIdxFordelete, setSelectedIdxFordelete] = React.useState(null);
  let obj: MilestoneExtendDate = {
    milestoneId: "",
    reason: "",
    newMilestoneDate: new Date(),

  };
  const [addMilestoneExtendDate, setAddMilestoneExtendDate] = React.useState<MilestoneExtendDate>(obj);
  const [milestoneExtendDateRequestData, setMilestoneExtendDateRequestData] = useState<any>("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [extendDate, setExtendDate] = React.useState<any>(new Date());
  const [reason, setReason] = React.useState(addMilestoneExtendDate?.reason);
  const [showHelpText, setshowHelpText] = React.useState(false);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    if (newValue === 2) {
      getProjectDetails(props?.projectDetail?.id)
    }
  };

  let removeFile = (i: any) => {
    setUpdateButtonDisable(false)
    let badgesformValues = [...documentFormValues];
    badgesformValues.splice(i, 1);
    setDocumentFormValues(badgesformValues);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setDocumentFormValues(newFormValues)
    updateProject1(newFormValues)
    setSelectedIdxFordelete(null)
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleEditClick = () => {
    setOpenDialog(true);
    getAllMilestoneExtendDateRequest();
    setExtendDate("");
    setReason("");
    setshowHelpText(false);
  };

  const handlesubmit1 = (e: any, values: any) => {
    debugger;
    e.preventDefault();
    if (!values?.newMilestoneDate) {
      setshowHelpText(true);
    }
    else {
      setshowHelpText(false);
    }
    //setOpenDialog(true);
  };

  const getDate = (dateObject: Date) => {
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00Z`;
  }

  const selectedRequestedId = useRef("");

  const handleEditRequestedClick = (data: any) => {
    debugger
    setExtendDate(data?.newMileStoneDate);
    setReason(data?.reason);
    selectedRequestedId.current = data?.id;
  };

  console.log("selectedRequestedId.current ==", selectedRequestedId.current)

  const getAllMilestoneExtendDateRequest = async () => {
    debugger
    var req = {
      projectId: projectDetailHook?.id,
      status: 0
    }
    await projectManagementServive.GetAllMilestoneExtendDateRequest(req).then((response: any) => {
      if (response.data.isError) {
      } else {
        setMilestoneExtendDateRequestData(response?.data);
      }
    })
  };

  console.log("milestoneExtendDateRequestData ===", milestoneExtendDateRequestData);
  console.log(" props.projectDetail", props.projectDetail);

  const updateMilestoneDate = async (values: any) => {
    debugger
    try {
      let milestoneData = values;

      const response = await projectManagementServive.extendMilestoneDate(milestoneData);
      if (response.data.isError) {

      } else {
        toast.success("Milestone Date updated successfully"

        );
        setExtendDate("");
        setReason("");
        getAllMilestoneExtendDateRequest();
      }
    } catch (error: any) {
      toast.warn(error.data.Error[0], {
        onOpen: () => setButtonDisable(true),
        onClose: () => setButtonDisable(false),
      });
    }
  };



  const updateMilestoneRequestedDate = async (data: any) => {
    debugger
    try {
      data = {
        id: selectedRequestedId.current,
        status: 1,
        comment: "",
        reason: reason,
        newMilestoneDate: extendDate,

      }

      const response = await projectManagementServive.updateMilestoneRequestedDate(data);
      if (response.data.isError) {

      } else {
        toast.success("Milestone Requested date updated successfully"
        );
        selectedRequestedId.current = "";
        setExtendDate("");
        setReason("");
        getAllMilestoneExtendDateRequest();
      }
    } catch (error: any) {
      toast.warn(error.data.Error[0], {
        onOpen: () => setButtonDisable(true),
        onClose: () => setButtonDisable(false),
      });
    }
  };

  const handleCloseDialog = () => {
    debugger
    setOpenDialog(false);
    selectedRequestedId.current = "";
    setExtendDate("");
    setReason("");
  };

  const handleClearfield = () => {
    debugger
    setOpenDialog(true);
    selectedRequestedId.current = "";
    setExtendDate("");
    setReason("");
  };


  let addDocumentValues = () => {
    setUpdateButtonDisable(false)
    IfileObj.itemType = "";
    IfileObj.name = "";
    IfileObj.fileURL = "";
    IfileObj.fileName = "";
    IfileObj.file = null;
    if (
      documentName != null &&
      documentName != "" &&
      documentName.length > 0 &&
      documentFile != null
    ) {
      IfileObj.name = documentName;
      IfileObj.fileURL = documentFileURL;
      IfileObj.fileName = documentFileName;
      IfileObj.file = documentFile;
      setDocumentName("");
      setDocumentFile(null);
      setDocumentFileURL("");
      setTempDocumentFileURL("");
      setDocumentFileName("")
      let fObj = [...formValues];
      fObj.push(IfileObj);
      setFormValues([...formValues, IfileObj]);
    } else {
      if (documentName == null || documentName == "" || documentName.length == 0) {
        setUpdateButtonDisable(true);
        toast.warning("Please add Document name.");
        return;
      }
      if (documentFile == null) {
        setUpdateButtonDisable(true);
        toast.warning("Please choose Document file.");
        return;
      }
    }
  };
  let removeFormFields = (i: any) => {
    setUpdateButtonDisable(false)
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const cancelDocumentFile = () => {
    setUpdateButtonDisable(true)
    setTempDocumentFileURL("");
    setDocumentFile(null);
    setDocumentFileURL("");
    setDocumentFileName("")
  };

  const triggerDocumentFile = (fileList: FileList, index: number) => {
    debugger
    setDocumentFile(fileList[0]);
    setDocumentFileURL(URL.createObjectURL(fileList[0]));
    setTempDocumentFileURL(URL.createObjectURL(fileList[0]));
    setDocumentFileName(fileList[0].name);
    setUpdateButtonDisable(false)
  };
  function getFileExtension(url: string): any {
    const splitUrl = url?.split(".");
    return splitUrl?.at(-1) || "";
  }
  async function UpdateProjectDetail(data: any) {

    try {
      await projectManagementServive
        .UpdateProjectDetail(data)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            getProjectDetails(props?.projectDetail?.id);
            setToggleLoader(false);
            setAlertOpenDelete(false)
            toast.success("Updated successfully.", {
              onClose: () => setUpdateButtonDisable(true),
            });
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        setAlertOpenDelete(false)
        toast.warning(err, {
          onOpen: () => setUpdateButtonDisable(true),
          // onClose: () => setUpdateButtonDisable(false),
        });
      });
    }
  }
  const updateProject = async () => {
    if (documentName == null || documentName == "" || documentName.length == 0) {
      setUpdateButtonDisable(true);
      toast.warning("Please add Document name.", {
        onClose: () => setUpdateButtonDisable(false),
      });
      return;
    }
    else if (documentFile == null) {
      setUpdateButtonDisable(true);
      toast.warning("Please choose Document file.");
      return;
    }
    else {
      debugger
      setToggleLoader(true);
      setUpdateButtonDisable(true)
      try {
        let data = projectDetailHook;
        data.projectDetails.projectDocument = [];
        let formData = new FormData();
        formData.append("File", documentFile);
        formData.append("FileFor", documentFileName);
        const response = await projectManagementServive
          .uploadProfilePic(formData)
          .then(async (response: any) => {
            if (response.data.isError) {
              toast.error(
                "facing issue while Document Upload" + documentFileName
              );
            } else {
              if (documentFormValues.length > 0) {
                documentFormValues.forEach(async function (item, index, array) {
                  data.projectDetails.projectDocument.push({
                    fileName: item.name,
                    wbsPath: item?.fileURL,
                    uploadedBy: item?.uploadedBy,
                  });
                })
              }
              data.projectDetails.projectDocument.push({
                fileName: documentFileName,
                wbsPath: response.data,
                uploadedBy: _authUser.id,
              });
              setDocumentName("");
              setDocumentFile(null);
              setDocumentFileURL("");
              setTempDocumentFileURL("");
              setDocumentFileName("")
            }
          });
        await Promise.all([response]);
        await UpdateProjectDetail(data);

      } catch (ex: any) {
        toast.warning("Error while updating data, check console.");
      }
    }
  };
  const updateProject1 = async (newFormValues: any) => {
    try {
      let data = projectDetailHook;
      data.projectDetails.projectDocument = [];
      if (newFormValues.length > 0) {
        newFormValues.forEach(async function (item: any) {
          data.projectDetails.projectDocument.push({
            fileName: item.name,
            wbsPath: item?.fileURL,
            uploadedBy: item?.uploadedBy,
          });
        })
      }
      else {
        data.projectDetails.projectDocument = [];
      }
      await UpdateProjectDetail(data);

    } catch (ex: any) {
      toast.warning("Error while updating data, check console.");
    }
  }

  const getProjectRiskData = async (id: string) => {

    try {
      const response = await projectManagementServive.getProjectRiskDataById(id);
      if (!response.data.isError) {
        setProjectRiskData(response.data);
      } else {
        // Handle error
      }
    } catch (error) {
      console.error("Error while updating data:", error);
      toast.warning("Error while updating data, check console.");
    }

  };

  const acordianChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      // setNoVisible(true)
    };

  useEffect(() => {
    debugger
    getChannelDetails();
    // props?.getProjectDetails(props?.projectDetail?.id);
    getProjectDetails(props?.projectDetail?.id);
    getProjectRiskData(props?.projectDetail?.id);
    props.getProjectDetails(props?.projectDetail?.id)
    // getProjectRiskData(props?.projectDetail?.id);
    // getMileStoneStatusName(mileStoneStatus);
    // updateRequestDetails();
  }, []);

  useEffect(() => { setProjectDetailHook(props.projectDetail) }, [props.projectDetail])

  const getProjectDetails = async (id: any) => {
    debugger
    try {
      await projectManagementServive
        .getProjectDetailsById(id)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            // setProjectDetailHook(response.data);
            // console.log(response.data, "GetDetail")
            setProjectDetailHook(response.data);
            let documentData = response.data?.projectDetails?.projectDocument;
            var document_array: any[] = [];
            documentData.map((item: any) => {
              document_array.push({
                name: item?.fileName,
                fileURL: item?.wbsPath,
                fileName: item?.fileName,
                file: null,
                itemType: "",
                uploadedBy: item?.uploadedBy,
              });
            });
            setDocumentFormValues(document_array);
            setFormValues(document_array);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const getUnEncrypedFiles = async (fileName: any) => {
    try {
      await projectManagementServive
        .getUnEncrypetProjectDocuments(fileName, props?.projectDetail?.id)
        .then(async (response) => {
          if (response.data.type === "application/json") {
            const data = await response.data.text();
            toast.warning(JSON.parse(data).listErrors[0]);
          } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            if (fileName.endsWith(".docx")) {
              a.href = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })
              );
            } else if (fileName.endsWith(".pdf")) {
              a.href = URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );
            } else {
              a.href = URL.createObjectURL(new Blob([response.data]));
            }
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(a.href);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  const getChannelDetails = async () => {
    var input = {
      module: 5,
      status: "",
      search: "",
    };
    await projectManagementServive.getalllistingsecure(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setChannel(response?.data);
      }
    });
  };

  const handleChanges = (name: any, value: any, object?: any) => {
    if (object) {
      setProjectDetailHook((prevState: any) => ({
        ...prevState,
        [object]: {
          ...prevState[object],
          [name]: value,
        },
      }));
    } else {
      setProjectDetailHook((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const categoryOptions = [
    { label: "Technical", value: 1 },
    { label: "Cost", value: 2 },
    { label: "Schedule", value: 3 },
    { label: "Client", value: 4 },
    { label: "Contractual", value: 5 },
    { label: "Weather", value: 6 },
    { label: "Financial", value: 7 },
    { label: "Political", value: 8 },
    { label: "Environmental", value: 9 },
    { label: "People", value: 10 }
  ];

  const impactOptions = [
    { label: "High", value: 1 },
    { label: "Low", value: 2 }
  ];

  const getCategoryLabel = (value: number) => {
    const category = categoryOptions.find(option => option.value === value);
    return category?.label;
  };
  const getImpactLabel = (value: number) => {
    const impact = impactOptions.find(option => option.value === value);
    return impact?.label;
  };

  const handleStatusChange = (value: any, milstoneId: any) => {

    var req = {
      milestoneId: milstoneId,
      status: value,
      billedHour: billedHour
    };
    milestoneManagementService.updateMilestoneStatus(req).then((response: any) => {
      if (response.data.isError) {
        //toast.error("Something Went Wrong, Please try again.");
      } else {

        setStatus(response?.data)
        // console.log(response.data, "setStatus")
        getProjectDetails(props?.projectDetail?.id);
        if (value == "3") {
          setBilledHour("");
          handleCloseAlerts();
          toast.success("Milestone completed successfully");
        }
        //toast.success("Status Updated successfully");
      }
    }).catch((error: any) => {
      error.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    });
  };

  const handleSelectSprint = (milstoneId: any, sprintid: any) => {

    var req = {
      milestoneId: milstoneId,
      sptintId: sprintid,
      //status: value,
    };
    milestoneManagementService.SelectSprint(req).then((response: any) => {
      if (response.data.isError) {
        //toast.error("Something Went Wrong, Please try again.");
      } else {

        // setStatus(response?.data)
        // console.log(response.data, "setStatus")
        getProjectDetails(props?.projectDetail?.id);
        //toast.success("Status Updated successfully");
      }
    }).catch((error: any) => {
      error.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    });
  };


  const handleChangePointOfContacts = (
    name: any,
    value: any,
    object?: any,
    index?: any
  ) => {
    setProjectDetailHook((prevState: any) => ({
      ...prevState,
      [object]: projectDetailHook[object].map((a: any, idx: number) => {
        let returnValue = { ...a };
        if (idx == index) {
          returnValue[name] = value;
        }
        return returnValue;
      }),
    }));
  };

  const handleProjectSubmit = () => {

    projectManagementServive
      .updateProjectId({
        id: projectDetailHook?.id,
        projectName: projectDetailHook?.projectName,
        projectDetails: projectDetailHook?.projectDetails,
        colUpdateClientModel: projectDetailHook?.colAddClientModel,
        pointOfContacts: projectDetailHook?.pointOfContacts,
        projectOwnerId: projectDetailHook?.projectOwnerId,
        projectDepartment: projectDetailHook?.projectDepartment,
        paymentDetails: projectDetailHook?.paymentDetails,
        skillSets: projectDetailHook?.skillSets,
        lstMilestoneinfo: projectDetailHook?.lstMilestoneinfo,
        lstResourceinfo: projectDetailHook?.lstResourceinfo,
        status: 1,
      })
      .then((response: any) => {
        if (response?.data?.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          toast.success("Updated successfully.");
          getProjectDetails(props?.projectDetail?.id);
          if (props.afterSave) {
            props.afterSave()
          }
        }
      });
  };

  const DeleteSprint = async (id: any) => {

    const confirmBox = window.confirm("Do you really want to delete this ?");
    try {
      if (confirmBox === true) {
        await boardDashboardServive.deleteSprint(id).then((response: any) => {
          if (response.data.isError) {
          } else {
            getProjectDetails(props?.projectDetail?.id);
            getChannelDetails();
          }
        });
      }
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const handleCloseAlert = () => {

    setAlertOpen(false);
  };
  const handleCloseAlerts = () => {

    setAlertOpens(false);
  };
  const AddSprintAPI = () => {

    setAlertOpen(false);
  };
  const handleClickOpenAlert = () => {
    setAlertOpen(true);

  };
  const handleClickOpenAlerts = () => {
    setAlertOpens(true);

  };
  const updateRequestDetails = async (
    mileStoneInputId: any,
    mileStoneInputStatus: any
  ) => {
    try {
      var input = {
        milestoneId: mileStoneInputId,
        status: mileStoneInputStatus === 1 ? 2 : 3,
      };
      await projectManagementServive
        .updateMileStatusDetails(input)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            getProjectDetails(props?.projectDetail?.id);
            setNoVisible(true);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClicks = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [spritListByDate, setspritListByDate] = useState([]);
  const getSprintByDate = async (startDate: any, endDate: any, id: any, milestone: any, currentRow: any) => {
    await boardDashboardServive
      .getAllSprintByProjectId({
        projectId: id,
        startDate: startDate,
        endDate: endDate
      })
      .then((response: any) => {
        if (response.data.isError) {
        } else {

          const currentMileStone = projectDetailHook?.lstMilestoneinfo?.find((x: any) => x?.milstoneId == milestone).sprints?.map((x: any) => x.sptintId) || [];
          setspritListByDate(response?.data ?? []);
          setselectedSprints(response?.data?.filter((x: any) => currentMileStone.includes(x.id) ?? []));
          const project = projectDetailHook;
          const mileStone = project.lstMilestoneinfo.find((x: any) => x?.milstoneId == mileStoneId);
          mileStone.selectedSprints = response?.data?.filter((x: any) => currentMileStone.includes(x.id) ?? []);
          setProjectDetailHook(project);
        }
      });
  };

  const fieldValidationSchema = Yup.object().shape({

    projectName: Yup.string()
      .max(50, "Project Name must be at most 50 characters")
      .required("Project Name is required")
      .matches(/^[A-Za-z0-9 ]+$/, 'Project Name is not Valid'),


    keyWords:
      Yup.string()
        .max(50, "KeyWords must be at most 50 characters")
        .required("Keyword is required"),

    pinEfforts: Yup.string()
      .matches(
        AppConstants.MatchRegExp.intRegExp,
        "Pin Efforts must be digits only"
      )
      .max(10, "Pin Efforts must be at most 10 characters"),

    pinAmount: Yup.string()
      .matches(
        /^-{0,1}\d*\.{0,1}\d+$/,
        "Pin Amount must be digits only"
      )
      .max(10, "Pin Amount must be at most 10 characters"),

  });
  const fieldValidationSchemaa = Yup.object().shape({

    email:
      Yup.string()
        .required("Please Enter Email.")
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please Enter Vaild Email "),

    mobileNumber:
      Yup.string()
        .required("Please Enter Mobile Number")
        .min(1, "Please Enter Valid Mobile Number")
        .max(10, "Please Enter Valid Mobile Number")
        .matches(/^[0-9\b]+$/, "Mobile Number can only contain Numbers"),

    alterNateNumber:
      Yup.string()
        .min(1, "Please Enter Valid Alternate Mobile Number")
        .max(10, "Please Enter Valid Alternate Mobile Number")
        .required("Please Enter Alternate Mobile Number")
        .matches(/^[0-9\b]+$/, "Alternate Number can only contain Numbers"),

    firstName:
      Yup.string()
        .required("Please Enter FirstName")
        .matches(/^([A-Za-z\s]*)$/g, "First Name can only contain Alphabets "),

    middleName:
      Yup.string()
        .matches(/^([A-Za-z\s]*)$/g, "Middle Name can only contain Alphabets"),

    lastName:
      Yup.string()
        .matches(/^([A-Za-z\s]*)$/g, "LastName can only contains Alphabets ")


  });

  const milestoneFieldValidationSchema = Yup.object().shape({
    reason: Yup.string()

      .max(150, "Reason can only contain 150 Alphabets")
      .required("Reason is required"),


    // newMilestoneDate: Yup.string().required("Extend date is Required"),
  });

  const handleInviteClientClick = async () => {
    var req = {
      projectId: props.projectDetail.id,
    };
    await projectManagementServive.sendAppLink(req).then((response: any) => {
      if (response.data.isError) {
      } else {
        toast.success("Link sended successfully")
      }
    }).catch((error: any) => {
      error.data.Error.map((err: any) => {
        toast.warning(err);
      });
    });
  };

  return (
    <>

      <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
          >
            {projectDetailHook?.projectName}
          </Typography>
        </Stack>
        {/* Code Start */}
        <Formik
          initialValues={{
            projectName: projectDetailHook?.projectName,
            keyWords: projectDetailHook?.projectDetails.keyWords,
            pinAmount: projectDetailHook?.projectDetails.pinAmount,
            pinEfforts: projectDetailHook?.projectDetails.pinEfforts
          }}
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

            setUpdateProjectDetail(false);
            handleProjectSubmit();
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            values
          }) => (
            <form noValidate onSubmit={handleSubmit}>

              <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 3 }}
                >
                  <Typography
                    justifyContent="space-between"
                    variant="h6"
                    component="h6"
                    sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                  >
                    Project Details
                  </Typography>
                  {!updateProjectDetail && _permission.action_Project_Edit_Access && (
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        // if(props.isMilestoneOver){
                        //   toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                        //   }
                        //   else{
                        setUpdateProjectDetail(true);
                        //   setProjectDetailHook(props?.projectDetail);
                        setProjectDetailHook(projectDetailHook
                          || props?.projectDetail);
                        setUpdatePaymentInfo(false);
                        setUpdateClientInfo(false);
                      }
                        // }
                      }
                    >
                      <Icons.editblue />
                    </IconButton>
                  )}
                </Stack>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3}>
                    {/* <TextField
                  id="outlined-basic"
                  fullWidth
                  label="PIN"
                  value={projectDetailHook?.projectDetails.pin || ""}
                  onChange={(e) =>
                    handleChanges("pin", e?.target?.value, "projectDetails")
                  }
                  variant="outlined"
                /> */}

                    <CustomCardHeader
                      title="PIN"
                      subheader={projectDetailHook?.projectDetails.pin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {updateProjectDetail ? (
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Project Name"
                        type="text"
                        name="projectName"
                        value={projectDetailHook?.projectName || ""}
                        onBlur={handleBlur}

                        error={Boolean(
                          getIn(errors, "projectName") &&
                          getIn(touched, "projectName")
                        )}
                        helperText={
                          getIn(touched, "projectName") &&
                          getIn(errors, "projectName")
                        }
                        onChange={(e) => {
                          values.projectName = e.currentTarget.value;
                          handleChanges("projectName", e?.target?.value.substring(0, 50));
                          handleChange(e);
                        }
                        }
                        // onChange={(e) => {
                        //   values.effortsInhours = e.currentTarget.value.substring(0,5);
                        //   setEffortsInHours(e.currentTarget.value.substring(0,5));
                        // }}
                        variant="outlined"
                      />
                    ) : (
                      <CustomCardHeader
                        title="Project Name"
                        subheader={projectDetailHook?.projectName}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {updateProjectDetail ? (
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Keyword"

                        name="keyWords"
                        onBlur={handleBlur}
                        value={projectDetailHook?.projectDetails.keyWords || ""}

                        error={Boolean(
                          getIn(errors, "keyWords") &&
                          getIn(touched, "keyWords")
                        )}
                        helperText={
                          getIn(touched, "keyWords") &&
                          getIn(errors, "keyWords")
                        }

                        onChange={(e) => {
                          values.keyWords = e.currentTarget.value;
                          handleChanges("keyWords", e?.target?.value, "projectDetails");
                          handleChange(e);
                        }
                        }
                        variant="outlined"
                      />
                    ) : (
                      <CustomCardHeader
                        title="Keyword"
                        subheader={projectDetailHook?.projectDetails.keyWords}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {updateProjectDetail ? (
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Channel"
                        SelectProps={{
                          multiple: false,
                          value: projectDetailHook?.projectDetails.channelId,
                          onChange: (e: any) =>
                            handleChanges(
                              "channelId",
                              e?.target?.value,
                              "projectDetails"
                            ),
                        }}
                        //   value={milestoneForm.currency}
                        //   onChange={(e) => handleChanges("currency", e?.target?.value)}
                        fullWidth
                        sx={{ mb: 0.5 }}
                      >
                        {channel.map((sprit: any) => (
                          <MenuItem key={sprit.id} value={sprit?.id}>
                            {sprit.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <CustomCardHeader
                        title="Channel"
                        subheader={projectDetailHook?.channelName}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {/* {updateProjectDetail ? (
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="Costing Type"
                  value={projectDetailHook?.projectDetails.costing || ""}
                  onChange={(e) =>
                    handleChanges("costing", e?.target?.value, "projectDetails")
                  }
                  variant="outlined"
                />
              ) : ( */}
                    <CustomCardHeader
                      title="Costing Type"
                      subheader={projectDetailHook?.projectDetails.costing}
                    />
                    {/* )} */}
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {/* <CustomCardHeader
                title="Project Description"
                subheader="Blockchain solutions offer alternative ways for businesses to address known challenges involving data sharing and workflows, but buyer beware of giving up too much control over the business currencies of data, technology, access, and contracts"
              /> */}
                    <Grid item xs={12} sm={12}>
                      {updateProjectDetail ? (
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Project Description"
                          value={
                            projectDetailHook?.projectDetails?.projectDescription
                          }
                          onChange={(e) =>
                            handleChanges(
                              "projectDescription",
                              e?.target?.value,
                              "projectDetails"
                            )
                          }
                          variant="outlined"
                        />
                      ) : (
                        <CustomCardHeader
                          title="Project Description"
                          subheader={
                            projectDetailHook?.projectDetails?.projectDescription
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  {_permission.action_Project_PaymentDetails_Access && (
                    <><Grid item xs={12} sm={3}>
                      {updateProjectDetail ? (
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="PIN Efforts"
                          name="pinEfforts"
                          onBlur={handleBlur}
                          error={Boolean(
                            getIn(errors, "pinEfforts") &&
                            getIn(touched, "pinEfforts")
                          )}
                          helperText={getIn(touched, "pinEfforts") &&
                            getIn(errors, "pinEfforts")}
                          value={projectDetailHook?.projectDetails.pinEfforts || ""}
                          onChange={(e) => {
                            values.pinEfforts = e.currentTarget.value;
                            handleChanges("pinEfforts", e?.target?.value,
                              "projectDetails"
                            );
                            handleChange(e);
                          }}
                          // onChange={(e) =>
                          //   handleChanges(
                          //     "pinEfforts",
                          //     e?.target?.value.replace(/[^0-9]/g, ""),
                          //     "projectDetails"
                          //   )
                          // }
                          variant="outlined" />
                      ) : (
                        <CustomCardHeader
                          title="PIN Efforts"
                          subheader={projectDetailHook?.projectDetails.pinEfforts} />
                      )}
                    </Grid><Grid item xs={12} sm={3}>
                        {updateProjectDetail ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="PIN Amount"
                            name="pinAmount"
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "pinAmount") &&
                              getIn(touched, "pinAmount")
                            )}
                            helperText={getIn(touched, "pinAmount") &&
                              getIn(errors, "pinAmount")}
                            value={projectDetailHook?.projectDetails.pinAmount || ""}
                            // onChange={(e) =>
                            //   handleChanges(
                            //     "pinAmount",
                            //     e?.target?.value.replace(/[^0-9]/g, ""),
                            //     "projectDetails"
                            //   )
                            // }
                            onChange={(e) => {
                              values.pinAmount = e.currentTarget.value;
                              handleChanges("pinAmount", e?.target?.value,
                                "projectDetails"
                              );
                              handleChange(e);
                            }}
                            variant="outlined" />
                        ) : (
                          <CustomCardHeader
                            title="PIN Amount"
                            subheader={projectDetailHook?.projectDetails.pinAmount} />
                        )}
                      </Grid></>)}

                  <Grid item xs={12} sm={3}>
                    <CustomCardHeader
                      title="Project Owner"
                      subheader={projectDetailHook?.projectOwnerName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {updateProjectDetail && (
                      <>
                        <Button
                          aria-label="edit"
                          onClick={() => {
                            setProjectDetailHook(props?.projectDetail);
                            setUpdateProjectDetail(false);
                          }}
                          variant="text"
                          color="error"
                          size="small"
                        >
                          Cancel
                        </Button>
                        <Button
                          aria-label="edit"

                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        </Formik>

        <Formik
          initialValues={{

            mobileNumber: projectDetailHook?.colAddClientModel?.mobileNumber,
            alterNateNumber: projectDetailHook?.colAddClientModel?.alterNateNumber,
            email: projectDetailHook?.colAddClientModel?.email,
            firstName: projectDetailHook?.colAddClientModel?.firstName,
            middleName: projectDetailHook?.colAddClientModel?.middleName,
            lastName: projectDetailHook?.colAddClientModel?.lastName

            //keyWords:projectDetailHook?.projectDetails.keyWords
          }}
          validationSchema={fieldValidationSchemaa}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

            // setUpdateProjectDetail(false);
            //handleProjectSubmit();
            setUpdateClientInfo(false);
            handleProjectSubmit();
          }}>
          {
            ({
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              values
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Paper variant="outlined" sx={{ mb: 4 }}>
                  <Stack sx={{ p: 2 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mb: 3 }}
                    >
                      <Typography
                        justifyContent="space-between"
                        variant="h6"
                        component="h6"
                        sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                      >
                        Client information
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      > {_permission.action_Project_InviteClient_Access && (
                        <Button color="primary" endIcon={<AddIcon />} onClick={handleInviteClientClick}>
                          Invite client
                        </Button>)}
                        {_permission.action_Project_Edit_Access && (
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              // if(props.isMilestoneOver){
                              //   toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                              //   }
                              //   else{
                              setUpdateClientInfo(true);
                              setUpdateProjectDetail(false);
                              setProjectDetailHook(projectDetailHook
                                || props?.projectDetail);
                              setUpdatePaymentInfo(false);
                            }
                              // }
                            }
                          >
                            <Icons.editblue />
                          </IconButton>
                        )}
                      </Stack>
                    </Stack>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            required
                            label="Email"
                            name="email"
                            value={projectDetailHook?.colAddClientModel?.email || ""}
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "email") &&
                              getIn(touched, "email")
                            )}
                            helperText={
                              getIn(touched, "email") &&
                              getIn(errors, "email")
                            }
                            onChange={(e) => {
                              values.email = e.currentTarget.value;
                              handleChanges(
                                "email",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }}
                            //    onChange={(e) =>
                            //   handleChanges(
                            //     "pinEfforts",
                            //     e?.target?.value.replace(/[^0-9]/g, ""),
                            //     "projectDetails"
                            //   )
                            // }
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Email"
                            subheader={projectDetailHook?.colAddClientModel?.email}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Skype Id"
                            value={projectDetailHook?.colAddClientModel?.skypeId || ""}
                            onChange={(e) =>
                              handleChanges(
                                "skypeId",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Skype Id"
                            subheader={projectDetailHook?.colAddClientModel?.skypeId}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <CustomCardHeader title="WBS" subheader="Filename.jpg" />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            required
                            fullWidth
                            name="firstName"
                            type="text"
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "firstName") &&
                              getIn(touched, "firstName")
                            )}
                            helperText={
                              getIn(touched, "firstName") &&
                              getIn(errors, "firstName")
                            }
                            label="First Name"
                            //             onKeyDown={(e)=>{
                            //               
                            //               var keyCode = e.which;     

                            //               if(((keyCode)<=65 && (keyCode)!=32 
                            //             &&(keyCode)!=8 &&(keyCode)!=37
                            //             &&(keyCode)!=38&&(keyCode)!=39
                            //             &&(keyCode)!=40)|| (keyCode)>=90) 
                            // {
                            //   e.preventDefault();
                            // }
                            // //Caps Alphabets

                            // return false;

                            //             }}
                            value={
                              projectDetailHook?.colAddClientModel?.firstName || ""
                            }
                            onChange={(e) => {
                              values.firstName = e.target.value;
                              handleChanges(
                                "firstName",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }}
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="First Name"
                            subheader={projectDetailHook?.colAddClientModel?.firstName}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Middle Name"
                            name="middleName"
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "middleName") &&
                              getIn(touched, "middleName")
                            )}
                            helperText={
                              getIn(touched, "middleName") &&
                              getIn(errors, "middleName")
                            }
                            //             onKeyDown={(e)=>{
                            //               
                            //               var keyCode = e.which;     

                            //               if(((keyCode)<=65 && (keyCode)!=32 
                            //               &&(keyCode)!=8 &&(keyCode)!=37
                            //               &&(keyCode)!=38&&(keyCode)!=39
                            //               &&(keyCode)!=40)|| (keyCode)>=90) 
                            // {
                            //   e.preventDefault();
                            // }
                            // //Caps Alphabets

                            // return false;

                            //             }}
                            value={
                              projectDetailHook?.colAddClientModel?.middleName || ""
                            }
                            onChange={(e) => {
                              values.middleName = e.target.value;
                              handleChanges(
                                "middleName",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }}
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Middle Name"
                            subheader={projectDetailHook?.colAddClientModel?.middleName}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "lastName") &&
                              getIn(touched, "lastName")
                            )}
                            helperText={
                              getIn(touched, "lastName") &&
                              getIn(errors, "lastName")
                            }
                            value={projectDetailHook?.colAddClientModel?.lastName || ""}
                            onChange={(e) => {
                              values.lastName = e.target.value;
                              handleChanges(
                                "lastName",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }}
                            //             onKeyDown={(e)=>{
                            //               
                            //               var keyCode = e.which;     

                            //               if(((keyCode)<=65 && (keyCode)!=32 
                            //               &&(keyCode)!=8 &&(keyCode)!=37
                            //               &&(keyCode)!=38&&(keyCode)!=39
                            //               &&(keyCode)!=40)|| (keyCode)>=90)  
                            // {
                            //   e.preventDefault();
                            // }
                            // //Caps Alphabets

                            // return false;

                            //             }}
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Last Name"
                            subheader={projectDetailHook?.colAddClientModel?.lastName}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Country"

                            SelectProps={{
                              multiple: false,
                              value: projectDetailHook?.colAddClientModel?.country,
                              onChange: (e: any) =>
                                handleChanges(
                                  "country",
                                  e?.target?.value,
                                  "colAddClientModel"
                                ),
                            }}
                            //   value={milestoneForm.currency}
                            //   onChange={(e) => handleChanges("currency", e?.target?.value)}
                            fullWidth
                            sx={{ mb: 0.5 }}
                          >
                      {["India", "USA","New Zealand","Australia","UK","France","South Africa","UAE"].map((res: any) => (
                              <MenuItem key={res} value={res}>
                                {res}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <CustomCardHeader
                            title="Country"
                            subheader={projectDetailHook?.colAddClientModel?.country}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField

                            id="outlined-basic"
                            fullWidth
                            required
                            type="text"
                            name="mobileNumber"
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "mobileNumber") &&
                              getIn(touched, "mobileNumber")
                            )}
                            helperText={
                              getIn(touched, "mobileNumber") &&
                              getIn(errors, "mobileNumber")
                            }

                            label="Mobile Number"
                            value={
                              projectDetailHook?.colAddClientModel?.mobileNumber || ""
                            }
                            onChange={(e) => {
                              values.mobileNumber = e.target.value;
                              //  values.email=e.target.value;
                              handleChanges(
                                "mobileNumber",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }}
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Mobile"
                            subheader={
                              projectDetailHook?.colAddClientModel?.mobileNumber
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Alternate Number"
                            required
                            name="alterNateNumber"
                            type="text"
                            onBlur={handleBlur}
                            error={Boolean(
                              getIn(errors, "alterNateNumber") &&
                              getIn(touched, "alterNateNumber")
                            )}
                            helperText={
                              getIn(touched, "alterNateNumber") &&
                              getIn(errors, "alterNateNumber")
                            }
                            value={
                              projectDetailHook?.colAddClientModel?.alterNateNumber
                            }
                            onChange={(e) => {
                              values.alterNateNumber = e.target.value;
                              handleChanges(
                                "alterNateNumber",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }}
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Alternate Number"
                            subheader={
                              projectDetailHook?.colAddClientModel?.alterNateNumber
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        {updateClientInfo ? (
                          <TextField
                            id="outlined-basic"
                            fullWidth
                            label="Company Name"
                            value={
                              projectDetailHook?.colAddClientModel?.companyName || ""
                            }
                            onChange={(e) =>
                              handleChanges(
                                "companyName",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Company Name"
                            subheader={
                              projectDetailHook?.colAddClientModel?.companyName
                            }
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        {updateClientInfo ? (
                          <TextField
                            sx={[styles.TitleValue]}
                            id="outlined-basic"
                            fullWidth
                            label="Company Description"
                            value={
                              projectDetailHook?.colAddClientModel?.companyDescription
                            }
                            onChange={(e) =>
                              handleChanges(
                                "companyDescription",
                                e?.target?.value,
                                "colAddClientModel"
                              )
                            }
                            variant="outlined"
                          />
                        ) : (
                          <CustomCardHeader
                            title="Company Description"

                            subheader={
                              projectDetailHook?.colAddClientModel?.companyDescription
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack sx={{ p: 2, background: theme.palette.primary.light }}>
                    <Grid container spacing={4}>
                      <>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            justifyContent="space-between"
                            component="p"
                            sx={{ fontWeight: 400, color: theme.palette.grey[800] }}
                          >
                            Point of Contact
                          </Typography>
                        </Grid>
                        {projectDetailHook?.pointOfContacts?.map(
                          (contact: any, index: number) => (
                            <>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="First Name"
                                    value={contact.firstName}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "firstName",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="First Name"
                                    subheader={contact.firstName}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="Middle Name"
                                    value={contact.middleName}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "middleName",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="Middle Name"
                                    subheader={contact.middleName}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="Last Name"
                                    value={contact.lastName}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "lastName",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="Last Name"
                                    subheader={contact.lastName}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="Email"
                                    value={contact.email}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "email",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="Email"
                                    subheader={contact.email}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="Skype Id"
                                    value={contact.skypeId}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "skypeId",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="Skype Id"
                                    subheader={contact.skypeId}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="Mobile"
                                    value={contact.mobileNumber}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "mobileNumber",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="Mobile"
                                    subheader={contact.mobileNumber}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {updateClientInfo ? (
                                  <TextField
                                    id="outlined-basic"
                                    fullWidth
                                    label="Alternate Number"
                                    value={contact.alterNateNumber}
                                    onChange={(e) =>
                                      handleChangePointOfContacts(
                                        "alterNateNumber",
                                        e?.target?.value,
                                        "pointOfContacts",
                                        index
                                      )
                                    }
                                    variant="outlined"
                                  />
                                ) : (
                                  <CustomCardHeader
                                    title="Alternate Number"
                                    subheader={contact.alterNateNumber}
                                  />
                                )}
                              </Grid>
                            </>
                          )
                        )}
                      </>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid item xs={12} sm={12}>
                      {updateClientInfo && (
                        <>
                          <Button
                            aria-label="edit"
                            onClick={() => {
                              setProjectDetailHook(props?.projectDetail);
                              setUpdateClientInfo(false);
                            }}
                            variant="text"
                            color="error"
                            size="small"
                          >
                            Cancel
                          </Button>
                          <Button
                            aria-label="edit"
                            onClick={() => {
                              //setUpdateClientInfo(false);
                              //handleProjectSubmit();
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Stack>
                </Paper>
              </form>
            )}

        </Formik>

        {_permission.action_Project_PaymentDetails_Access && (
          <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 3 }}
            >
              <Typography
                justifyContent="space-between"
                variant="h6"
                component="h6"
                sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
              >
                Payment Details
              </Typography>
              {/* <IconButton
              aria-label="delete"
              onClick={() => {
                setUpdatePaymentInfo(true);
                setUpdateProjectDetail(false);
                setProjectDetailHook(props?.projectDetail);
                setUpdateClientInfo(false);
              }}
            >
              <Icons.editblue />
            </IconButton> */}
            </Stack>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>

                <CustomCardHeader
                  title="Costing Type"
                  subheader={projectDetailHook?.projectDetails?.costing}
                />
                {/* )} */}
              </Grid>
              {projectDetailHook?.paymentDetails?.costingType === "fixed" && (
                <Grid item xs={12} sm={3}>
                  {updatePaymentInfo ? (
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Billing Type"
                      disabled={
                        projectDetailHook?.paymentDetails?.costingType === "fixed"
                      }
                      SelectProps={{
                        multiple: false,
                        value: projectDetailHook?.paymentDetails.billingType,
                        onChange: (e: any) =>
                          handleChanges(
                            "billingType",
                            e?.target?.value,
                            "paymentDetails"
                          ),
                      }}
                      fullWidth
                      sx={{ mb: 0.5 }}
                    >
                      {DummyBillingType.map((option: any) => (
                        <MenuItem key={option.id} value={option?.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <CustomCardHeader
                      title="Billing Type"
                      subheader={
                        // projectDetailHook?.paymentDetails?.billingType
                        AppConstants.DummyBillingType.find(x => x.id == projectDetailHook?.paymentDetails?.billingType)?.name
                      }
                    />
                  )}
                </Grid>
              )}
              {projectDetailHook?.paymentDetails?.costingType === "fixed" && (
                <Grid item xs={12} sm={3}>
                  {updatePaymentInfo ? (
                    <TextField
                      disabled={
                        projectDetailHook?.paymentDetails?.costingType === "fixed"
                      }
                      id="outlined-basic"
                      fullWidth
                      label="Rate Per Hour"
                      value={projectDetailHook?.paymentDetails.ratePerHour || ""}
                      onChange={(e) =>
                        handleChanges(
                          "ratePerHour",
                          e?.target?.value,
                          "paymentDetails"
                        )
                      }
                      variant="outlined"
                    />
                  ) : (
                    <CustomCardHeader
                      title="Rate Per Hour"
                      subheader={projectDetailHook?.paymentDetails?.ratePerHour}
                    />
                  )}
                </Grid>
              )}

              {projectDetailHook?.paymentDetails?.costingType === "billing" && (
                <Grid item xs={12} sm={3}>
                  {updatePaymentInfo ? (
                    <TextField
                      disabled={
                        projectDetailHook?.paymentDetails?.costingType === "fixed"
                      }
                      id="outlined-basic"
                      fullWidth
                      label="Upfront Payment"
                      value={
                        projectDetailHook?.paymentDetails.upfrontPayment || ""
                      }
                      onChange={(e) =>
                        handleChanges(
                          "upfrontPayment",
                          e?.target?.value,
                          "paymentDetails"
                        )
                      }
                      variant="outlined"
                    />
                  ) : (
                    <CustomCardHeader
                      title="Upfront Payment"
                      subheader={
                        projectDetailHook?.paymentDetails?.upfrontPayment
                      }
                    />
                  )}
                </Grid>
              )}

              <Grid item xs={12} sm={3}>
                {updatePaymentInfo ? (
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Currency"
                    SelectProps={{
                      multiple: false,
                      value: projectDetailHook?.paymentDetails.currency || "",
                      onChange: (e: any) =>
                        handleChanges(
                          "currency",
                          e?.target?.value,
                          "paymentDetails"
                        ),
                    }}
                    fullWidth
                    sx={{ mb: 0.5 }}
                  >
                    {dummy.map((option: any) => (
                      <MenuItem key={option.value} value={option?.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (

                  <CustomCardHeader
                    title="Currency"
                    subheader={projectDetailHook?.paymentDetails?.currency}
                  />
                )}
              </Grid>
            </Grid>

          </Paper>)}

        <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>

          <Stack
            direction="row"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Typography
              justifyContent="space-between"
              variant="h6"
              component="h6"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              Document
            </Typography>

            <Typography
              component={Link}
              to={`/project-process/${props?.projectDetail?.id}`}
              target="_blank"
              sx={{ marginLeft: "410px", cursor: 'pointer', textDecoration: 'underline', color: '#00a1e4' }}

            >Click to Review Documents</Typography>
          </Stack>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                {documentFormValues.length > 0 && (
                  <TableRow>
                    <><TableCell>Sr. No</TableCell>
                      <TableCell>File Name</TableCell>
                      {/* <TableCell>File Link</TableCell> */}
                      <TableCell>File Type</TableCell>
                      <TableCell align="center">Action</TableCell></>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {documentFormValues.map((row: any, i: any) => (
                  <TableRow
                    key={`${row.name}-${i}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell onClick={() => { getUnEncrypedFiles(row?.fileURL) }} style={{
                      cursor: 'pointer', textDecoration: 'underline',
                      color: '#00a1e4',
                    }}>{row.name}</TableCell>
                    {/* <TableCell><a onClick={() => { getUnEncrypedFiles(row?.fileURL) }} style={{ cursor: 'pointer' }}>{row.fileURL}</a></TableCell> */}
                    <TableCell  >
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        {getFileExtension(row?.fileURL) == "pdf" && (
                          <Icons.Pdfdocs />
                        )}
                        {getFileExtension(row?.fileURL) == "docx" && (
                          <Icons.docxicon />
                        )}
                        {getFileExtension(row?.fileURL) == "doc" && (
                          <Icons.docxicon />
                        )}
                        {getFileExtension(row?.fileURL) == "png" && (
                          <Icons.pngicon />
                        )}
                        {getFileExtension(row?.fileURL) == "jpeg" && (
                          <Icons.jpegicon />
                        )}
                        {getFileExtension(row?.fileURL) == "txt" && (
                          <Icons.txticon />
                        )}
                        {getFileExtension(row?.fileURL) == "xlsm" && (
                          <Icons.xlsmicon />
                        )}
                        {getFileExtension(row?.fileURL) == "xlsx" && (
                          <Icons.xlsxicon />
                        )}
                        {getFileExtension(row?.fileURL) == "xls" && (
                          <Icons.xlsicon />
                        )}
                        {getFileExtension(row?.fileURL) == "docm" && (
                          <Icons.docxicon />
                        )}
                        <Box sx={{ ml: 1 }}>
                          {getFileExtension(row?.fileURL)}
                        </Box>

                      </Box>

                    </TableCell>
                    <TableCell align="center" sx={{ color: "#EB445A" }}>
                      {row?.uploadedBy === _authUser.id && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => { setAlertOpenDelete(true); setSelectedIdxFordelete(i) }}
                        >
                          <Icons.Delete />
                        </IconButton>)}  </TableCell>


                  </TableRow>

                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {_authUser.typeOfUser != 3 && (
            <Grid container spacing={4} sx={{ marginTop: "1px" }}>
              <Grid item xs={12} sm={3}>
                <Typography
                >
                  File Name
                </Typography>
                <TextField
                  required
                  value={documentName}
                  placeholder="Type File Name"
                  sx={{ width: "100%", background: "#fff" }}
                  onChange={(event) => {
                    let value = event.currentTarget.value.replace(/[^a-zA-Z\.\s]/g, "")
                    value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 50);
                    setDocumentName(value);
                  }}
                />

              </Grid>

              <Grid item xs={12} sm={8} >
                <Typography
                >
                  File Browser
                </Typography>
                <Stack direction='row' justifyContent="space-between" alignItems="normal" sx={{ marginTop: "0px" }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {documentFile == null && <FileUpload2
                      selectFile={triggerDocumentFile}
                      index={1}
                    />}

                    <a href={tempDocumentFileURL} target="_blank" rel="noopener noreferrer">{tempDocumentFileURL}</a>
                    {documentFile !== null && (
                      <IconButton onClick={() => cancelDocumentFile()}><ClearIcon /></IconButton>)}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ float: "right" }}
                      disabled={updateButtonDisable}
                      onClick={() => updateProject()}
                    >
                      Save
                    </Button>

                  </Box>
                </Stack>
              </Grid>
            </Grid>)}


        </Paper>

        <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Typography
              justifyContent="space-between"
              variant="h6"
              component="h6"
              sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
            >
              Resources
            </Typography>
            {/* <Icons.editblue /> */}

          </Stack>
          <Grid container spacing={4}>
            {projectDetailHook?.lstResourceinfo?.map(
              (resource: any, index: number) => (
                <Grid item xs={12} sm={3}>
                  <CustomCardHeader
                    title={resource.departmentname}
                    subheader={resource?.resources.filter((r: any) => !r.isDeleted).map((r: any) => {
                      return (
                        <span>
                          <Typography
                            variant="caption"
                            component="span"
                            sx={milestonename}
                          >
                            {r.name}
                          </Typography>{" "}
                        </span>
                      );
                    })}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Paper>




        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Typography
            justifyContent="space-between"
            variant="h6"
            component="h6"
            sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
          >
            Milestones
          </Typography>

          <Box>
          </Box>
        </Stack>

        {projectDetailHook?.lstMilestoneinfo?.map((data: any, i: any) => {
          return (
            <div>
              <Box>

                <Accordion
                  expanded={expanded === "panel" + i}
                  onChange={acordianChange("panel" + i)}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "transparent" }}
                    aria-controls={"panel" + i + "d-content"}
                    id={"panel" + i + "d-header"}
                  >
                    <Grid sx={{ alignItems: "start" }} container spacing={2}>
                      <Grid item xs={4}>
                        <Box>

                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "300",
                              lineHeight: "21px",
                              color: "#424242",
                              display: "flex",
                              gap: "8px"
                            }}
                          >
                            {data?.milstonename}
                            <Box
                              sx={{ marginLeft: "5px", display: "inline-block" }}
                            >

                              <Chip label={(AppConstants.MasterData.Issue.Status.find(x => x.value == data.status)?.label)} variant="review" />

                            </Box>


                          </Typography>
                          {/* <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "21px",
                              color: "#000",
                              marginTop: "5px",
                            }}
                          >
                            {`${moment(data?.startDate.toString().slice(0, 10)).format(
                              "DD-MMM-YYYY"
                            )}`}{" "}
                            -{" "}
                            {`${moment(data?.endDate.toString().slice(0, 10)).format(
                              "DD-MMM-YYYY"
                            )}`}

                          </Typography> */}
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          sx={{ display: "inline-flex", alignItems: 'center' }}
                        >
                          <Typography>Planned Hrs:</Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "21px",
                              color: "#000",
                              marginLeft: "8px"
                            }}
                          >


                            {data?.plannedEfforts}

                          </Typography>

                        </Box>
                      </Grid>
                      <Grid item xs={4} >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box
                            sx={{ display: "inline-flex", alignItems: 'center' }}
                          >
                            <Typography>Actual Hrs:</Typography>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "21px",
                                color: "#000",
                                marginLeft: "8px"
                              }}
                            >
                              {/* {data?.actualEfforts} */}
                              {data?.actualEfforts ? parseFloat(data?.actualEfforts).toFixed(2) : "--"}
                            </Typography>
                          </Box>
                          {(data?.costing && _permission.action_Project_PaymentDetails_Access) && (
                            <Box
                              sx={{ display: "inline-flex", alignItems: 'center' }}
                            >
                              <Typography>{data?.paymentStatus == 2 ? "Payment Received:" : "Costing:"}</Typography>

                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px",
                                  color: "#000",
                                  marginLeft: "8px"
                                }}
                              >
                                {data?.costing ? parseFloat(data?.costing).toFixed(2) : "--"}
                                {/* {data?.costing} */}
                              </Typography>
                            </Box>)}
                        </Box>
                      </Grid>
                      <Grid item xs={1}>

                        {(data?.status === 2 && (((data?.endDate <= getDate(new Date())) && (data?.updatedEndDate == null || data?.updatedEndDate == undefined)) || ((data?.endDate <= getDate(new Date())) && (data?.updatedEndDate <= getDate(new Date()))))
                          && (_authUser?.id === props.projectDetail?.projectOwnerId || _permission.action_Milestone_EndDateEdit_Access)) && (

                            <Tooltip title={"Click on icon to Extend Milestone Date"} arrow>
                              <IconButton onClick={handleEditClick}>
                                <Icons.editblue />
                              </IconButton></Tooltip>
                          )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "21px",
                            color: "#000",
                            marginTop: "5px",
                          }}
                        >
                          {`${moment(data?.startDate.toString().slice(0, 10)).format(
                            "DD-MMM-YYYY"
                          )}`}{" "}
                          -{" "}
                          {`${data?.updatedEndDate != null ? moment(data?.updatedEndDate.toString().slice(0, 10)).format(
                            "DD-MMM-YYYY"
                          ) : moment(data?.endDate.toString().slice(0, 10)).format(
                            "DD-MMM-YYYY"
                          )}`}

                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {_permission.action_Milestone_Edit_Access && (
                          <Box>
                            <TextField
                              id="outlined-basic"
                              select
                              //     label="Type"
                              value={data.status}
                              variant="outlined"
                              disabled={data.status === 3}
                              sx={{
                                "& .MuiSelect-select": {
                                  fontSize: "16px",
                                  padding: '0',
                                  //paddingLeft: 0,
                                  fontWeight: 500,
                                  minWidth: 80,
                                  color: theme.palette.primary.dark,
                                  lineHeight: "inherit",
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: 'transparent',
                                  },
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                  // pl: 0,
                                },
                              }}
                            >

                              {AppConstants.MasterData.Issue.Status.map(
                                (option, index) => (
                                  <MenuItem
                                    disabled={option.disableStatus.includes(data.status?.toString()) || (data.status === 3)}
                                    key={option.value}
                                    value={option.value}
                                    // disabled={data.status===}
                                    onClick={(e) => {
                                      setCurrentStatus(option?.value)
                                      setCurrentMilestone(data?.milstoneId)
                                      // handleStatusChange(option?.value, data?.milstoneId);
                                      if (option?.value == "3") {
                                        setCurrentStatus(option?.value)
                                        setCurrentMilestone(data?.milstoneId)
                                        setAlertOpens(true)
                                      }
                                      else {
                                        handleStatusChange(option?.value, data?.milstoneId);
                                      }
                                      // onClick={()}
                                    }}
                                  >
                                    {option.label}

                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </Box>)}
                      </Grid>

                      <Grid item xs={5}>
                        <Typography sx={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                          Payment Status: <Box sx={{
                            display: "inline-block", backgroundColor:
                              data?.paymentStatus === null || data?.paymentStatus === 0 ? "#f5f5f5" : data?.paymentStatus === 1 ? "#ffcc00"
                                : "#00cc00",
                            padding: "3px 8px", border: "1.5px solid #cccccc", borderRadius: "3px", fontSize: "14px", ml: 'auto'
                          }}>
                            {data?.paymentStatus === null || data?.paymentStatus === 0 ? "NA" : data?.paymentStatus === 1 ? "Invoice raised" : "Payment Received"}
                          </Box>

                        </Typography>
                      </Grid>

                    </Grid>

                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                              Sprint
                            </TableCell>
                            <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                              Start Date
                            </TableCell>
                            <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                              End Date
                            </TableCell>
                            <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                              Estimated Hrs
                            </TableCell>
                            <TableCell sx={{ backgroundColor: "#F0F9FF;" }}>
                              Actual Hrs
                            </TableCell>
                            <TableCell
                              sx={{ backgroundColor: "#F0F9FF;" }}
                            >Status</TableCell>
                            <TableCell
                              sx={{ backgroundColor: "#F0F9FF;" }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {data?.sprints?.map((sprint: any) => {
                            return (
                              <TableRow>
                                <TableCell sx={{ fontSize: 14 }}>
                                  {sprint?.sprintName}
                                </TableCell>
                                <StyledTableCell component="th">
                                  {" "}
                                  {`${moment(sprint?.startDate).format(
                                    "DD-MMM-YYYY"
                                  )}`}{" "}
                                </StyledTableCell>
                                <StyledTableCell component="th">
                                  {" "}
                                  {`${moment(
                                    sprint?.endDate.toString().slice(0, 10)
                                  ).format("DD-MMM-YYYY")}`}{" "}
                                </StyledTableCell>
                                <TableCell sx={{ fontSize: 14 }}>
                                  {sprint?.estimatedHours}
                                </TableCell>
                                <TableCell sx={{ fontSize: 14 }}>
                                  {sprint?.actualEfforts}
                                </TableCell>
                                <TableCell sx={{ fontSize: 14 }} colSpan={2}>
                                  {/* {sprint?.status}  */}
                                  {sprint?.status == "1" && (
                                    <Chip variant="review" label="To Do" />
                                  )}
                                  {sprint?.status == "2" && (
                                    <Chip
                                      variant="progress"
                                      label="In Progress "
                                    />
                                  )}
                                  {sprint?.status == "3" && (
                                    <Chip variant="approved" label="Completed" />
                                  )}
                                </TableCell>
                                {_permission.action_Sprint_Delete_Access && (
                                  <TableCell>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        if (props.isMilestoneOver) {
                                          toast.warn("Oops! The Milsetone date has arrived. Please create a new milestone or extend the current one.")
                                        }
                                        else { DeleteSprint(sprint.sptintId) }
                                      }}
                                    >
                                      <Icons.Delete />
                                    </IconButton>
                                  </TableCell>)}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Box>


            </div>
          );
        })}
        <Dialog maxWidth="md" fullWidth open={openDialog} onClose={Button}>
          <Formik
            initialValues={{

              milestoneId: projectDetailHook?.lstMilestoneinfo
                .filter((milestone: any) => milestone.status == 2)[0]?.milstoneId,
              reason: reason,
              newMilestoneDate: extendDate,

            }}
            enableReinitialize
            validationSchema={milestoneFieldValidationSchema}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

              debugger
              console.log("Form values:", values);
              try {

                if (values.newMilestoneDate &&
                  values.newMilestoneDate <
                  projectDetailHook?.lstMilestoneinfo
                    .filter((milestone: any) => milestone.status == 2)[0]?.endDate
                ) {
                  toast.warning("Extend end date must be greater than previous end date", {
                    onOpen: () => setButtonDisable(true),
                    onClose: () => setButtonDisable(false),
                  });
                  return false;
                }

                if (!values.newMilestoneDate) {
                  setshowHelpText(true);
                }
                else {
                  if (selectedRequestedId.current && selectedRequestedId.current.length > 0) {

                    await updateMilestoneRequestedDate(values);
                  } else {
                    await updateMilestoneDate(values);
                  }

                }


              } catch (err: any) {
                console.error(err);
                err = JSON.parse(JSON.stringify(err));
                if (err.status == 400) {

                  {
                    err.data.Error.map((error: any, idx: any) =>
                      toast.warn(error)
                    );
                  }
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate
                onSubmit={(e) => { handleSubmit(); handlesubmit1(e, values); }} >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    {projectDetailHook?.lstMilestoneinfo
                      .filter((milestone: any) => milestone.status == 2)[0]?.milstonename}
                    <Box sx={{ fontSize: "16px" }}>
                      Previous End Date :  {`${moment(
                        projectDetailHook?.lstMilestoneinfo
                          .filter((milestone: any) => milestone.status == 2)[0]?.endDate
                      ).format("DD MMM YYYY")}`}

                    </Box>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleCloseDialog}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
                <DialogContent>

                  <TableContainer component={Paper}>
                    {milestoneExtendDateRequestData?.length > 0 && (
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Extended Date</TableCell>
                            <TableCell align="right">Comment</TableCell>
                            <TableCell align="right">Status</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {milestoneExtendDateRequestData?.length > 0 ? (
                            milestoneExtendDateRequestData?.map((item: any, index: any) => (
                              <TableRow
                                key={item.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">

                                  {`${moment(item?.newMileStoneDate).format("DD-MMM-YYYY")}`}
                                </TableCell>
                                <TableCell align="right">{item.reason || "--"}</TableCell>

                                <TableCell align="right">
                                  {item.status == 1 ? (
                                    <>
                                      <Tooltip title="Edit">
                                        <IconButton onClick={() => handleEditRequestedClick(item)}>
                                          <Icons.editblue />
                                        </IconButton>
                                      </Tooltip>
                                      <span >Pending</span>



                                    </>
                                  ) : item.status === 2 ? (
                                    <span >Rejected</span>
                                  ) : item.status === 3 ? (
                                    <span >Approved</span>
                                  ) : (
                                    "--"
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={10} align="center">
                                No records found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>

                  <Paper sx={{ p: 3, mt: 4 }}>
                    <Box >
                      <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              placeholder="Extend Date"

                              borderRadius="semi-square"
                              minDate={moment().subtract(0, "years").toDate()}
                              maxDate={new Date(
                                new Date().getFullYear() + 2,
                                11,
                                31
                              )}


                              value={extendDate}
                              onChange={(newValue: any) => {
                                let dateT = getDate(newValue);
                                values.newMilestoneDate = dateT;
                                setExtendDate(dateT);
                                setshowHelpText(false);

                              }}
                              error={showHelpText && !values.newMilestoneDate ? "Extend Date is required" : ""}
                            />
                          </LocalizationProvider>
                        </Grid>


                        <Grid item xs={6}>

                          <TextField
                            id="outlined-basic"
                            //placeholder="Reason"
                            variant="outlined"
                            sx={{
                              "line-height": "1.5",
                              "& .MuiInputBase-root": {
                                "line-height": "1.5",
                              },
                              "& .MuiOutlinedInput-root": {
                                "line-height": "1.5",
                                "padding": "10px",
                              }
                            }}
                            multiline
                            error={Boolean(
                              getIn(touched, "reason") && getIn(errors, "reason")
                            )}
                            fullWidth
                            helperText={
                              getIn(touched, "reason") && getIn(errors, "reason")
                            }
                            label="Reason"
                            name="Reason"
                            value={reason}
                            onBlur={handleBlur}

                            onChange={(e) => {

                              let value = e.currentTarget.value
                              value = value.replace(/\s{3,}$/g, "").trimStart().slice(0, 149);
                              values.reason = value
                              setReason(value);

                            }}

                          />
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ textAlign: "right" }}
                          >
                            Max 150 characters
                          </Typography>

                        </Grid>

                      </Grid>

                    </Box>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>

                    </DialogActions>
                  </Paper>
                </DialogContent>

              </form>
            )}
          </Formik>
        </Dialog>


        {/* Accordion Ends */}
        {projectDetailHook?.lstMilestone?.map((i: any, index: number) => (
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <CustomCardHeader
                  title={i?.milestoneName}
                  subheader={`${moment(i?.startDate).format(
                    "Do MMM"
                  )} - ${moment(i?.endDate).format("Do MMM")}`}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomCardHeader
                  title="Sprint 1"
                  subheader="20th July- 05th Aug"
                />
                <Typography
                  justifyContent="space-between"
                  component="p"
                  sx={milestonecompleted}
                >
                  Completed
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomCardHeader
                  title="Sprint 2"
                  subheader="05th Aug- 20th Aug"
                />
                <Typography
                  justifyContent="space-between"
                  component="p"
                  sx={milestonecompleted}
                >
                  Completed
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}

        {projectRiskData.length > 0 && (
          <>
            <Paper variant="outlined" sx={{ p: 2, mb: 4, mt: 4 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 3 }}
              >
                <Typography
                  justifyContent="space-between"
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: 600, color: theme.palette.primary.dark }}
                >
                  Project Risk
                </Typography>
              </Stack>
              {projectRiskData.map((riskData: any, index: any) => (
                <React.Fragment key={index}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>

                      <CustomCardHeader
                        title="Category"
                        subheader={getCategoryLabel(riskData.category)}
                      />
                      {/* )} */}
                    </Grid>

                    <Grid item xs={12} sm={3}>

                      <CustomCardHeader
                        title="Impact"
                        subheader={getImpactLabel(riskData.impact)}

                      />
                    </Grid>


                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3} sx={{ mt: 2, mb: 3, ml: 4 }}>


                        <CustomCardHeader
                          title="Description"
                          subheader={riskData.description}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {index !== projectRiskData.length - 1 && <Divider sx={{ my: 2 }} />}
                </React.Fragment>
              ))}
            </Paper>
          </>
        )
        }


      </Paper >

      <Dialog
        open={openAlert}
        onClose={setAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl" scroll="body"

      >
        <Box sx={{ width: '500px', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box>
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "left" }}>
              Select Sprint
            </DialogTitle>
            <DialogContent sx={{ minHeight: 140, overflow: 'visible' }}>
              <Multiselect style={
                {
                  optionContainer: { // To change css for option container 
                    "max-height": "100px",
                    pb: 2,
                    // POSITION:'absolute',
                    // top:'100%',
                  }
                }

              }
                displayValue="sprintName"

                onRemove={(e, x) => {

                  const project = projectDetailHook;
                  const currentMileStone = project?.lstMilestoneinfo?.find((x: any) => x?.milstoneId == mileStoneId);
                  currentMileStone.selectedSprints =
                    currentMileStone.selectedSprints?.filter((z: any) => z.id != x.id);
                  setProjectDetailHook(project);

                }}
                onSelect={(e) => {
                  const project = projectDetailHook;
                  const currentMileStone = project?.lstMilestoneinfo?.find((x: any) => x?.milstoneId == mileStoneId);
                  currentMileStone.selectedSprints = e;
                  setProjectDetailHook(project);

                }}
                placeholder="Sprints"
                // options={spritListByDate}
                options={spritListByDate.map((x: any) => {
                  return {
                    ...x,
                    sprintName: `${x.sprintName} 
                  ( ${moment(x.startDate).format("DD MMM YYYY")} -
                  ${moment(x.endDate).format("DD MMM YYYY")})`
                  }
                  // {`${moment(event?.createdDate).format(
                  //   "DD MMM, YYYY"
                  // )}`}
                })}
                selectedValues={selectedSprints.map((x: any) => {
                  return {
                    ...x,
                    sprintName: `${x.sprintName} 
                  ( ${moment(x.startDate).format("DD MMM YYYY")} -
                  ${moment(x.endDate).format("DD MMM YYYY")})`
                  }
                  // {`${moment(event?.createdDate).format(
                  //   "DD MMM, YYYY"
                  // )}`}
                })}
              />
            </DialogContent>
          </Box>

          <DialogActions
            sx={{
              p: 3,
              pt: '70px',
              textAlign: "center",
              justifyContent: "end",
            }}
          >
            <Button variant="outlined" onClick={handleCloseAlert}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => {
              const currentMileStone = projectDetailHook?.lstMilestoneinfo?.find((x: any) => x?.milstoneId == mileStoneId);
              let sprintIds: any[] = currentMileStone.selectedSprints?.map((x: any) => x.id) || [];
              // sprintIds = sprintIds.concat(currentMileStone.sprints.map((x: any) => x.sptintId));
              let dataa = {
                "milestoneId": mileStoneId,
                "sprintIds": sprintIds.map((x: any) => {
                  return { id: x }
                })
              }
              milestoneManagementService.AddsprintsToMilestone(dataa)
                .then(() => {
                  //alert('done');
                  getProjectDetails(props?.projectDetail?.id);
                  handleCloseAlert();
                  toast.success("Sprints Updated Successfully.");
                })

            }}>
              SAVE
            </Button>
          </DialogActions>
        </Box>

      </Dialog>

      <Dialog
        open={openAlerts}
        onClose={setAlertOpens}
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            billedHour: billedHour
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            billedHour: Yup.string()
              .required("Hours are required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              handleStatusChange(currentStatus, currentMilestone);
            } catch (ex: any) {
              if (ex.status == 400) {

                ex.data.Error.map((error: any, idx: any) => {
                  toast.error(error);
                });
              }
            }
          }}
        >
          {({
            errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box sx={{ width: '500px', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>

                  <DialogTitle id="alert-dialog-title"
                    sx={{
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: "26px",
                      lineHeight: " 39px",
                      color: theme.palette.primary.dark,
                    }}>
                    Confirmation
                  </DialogTitle>

                  <DialogContent >
                    <Stack spacing={4}>
                      <Box sx={{ pt: 1, textAlign: "center", fontSize: "20px" }}>
                        Are you sure you want to Complete this?
                      </Box>

                      <Box>
                        <TextField
                          required
                          error={Boolean(
                            getIn(errors, "billedHour") && getIn(touched, "billedHour")
                          )}
                          helperText={getIn(touched, "billedHour") && getIn(errors, "billedHour")}
                          id="outlined-basic"
                          label="Billed Hours"
                          name="billedHour"
                          type="text"
                          sx={{ border: 'none' }}
                          value={billedHour}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            let abc = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").substring(0, 4);
                            setBilledHour(abc);
                            values.billedHour = abc;
                          }} />
                      </Box>
                    </Stack>
                  </DialogContent>
                </Box>

                <DialogActions
                  sx={{
                    p: 3,
                    pt: '10px',
                    textAlign: "center",
                    justifyContent: "center",

                  }}
                >
                  <Button variant="outlined" onClick={handleCloseAlerts}>
                    No
                  </Button>
                  <Button type="submit" variant="contained" >
                    Yes
                  </Button>
                </DialogActions>
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openAlertDelete}
        onClose={setAlertOpenDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseDeleteAlert}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "26px",
            lineHeight: "39px",
            color: "#212121",
          }}
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Are you sure you want to delete this ,The file will be permanently deleted. Continue ?
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseDeleteAlert}>
            No
          </Button>
          <Button variant="contained" onClick={() => { removeFile(selectedIdxFordelete) }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
