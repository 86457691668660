import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack } from "@mui/material";
import { TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MenuItem from "@mui/material/MenuItem";
import SourceIcon from "@mui/icons-material/Source";
import AttachmentIcon from "@mui/icons-material/Attachment";
import * as Icons from "../../assests/icons/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import { toast } from "material-react-toastify";
import { Domainservice } from "../../service/master-service/domain-master-service";
import Multiselect from "multiselect-react-dropdown";
import { projectManagementServive } from "../../service/project-management-service";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "react-rainbow-components";
import getDate from "../../components/GetDateWithSimpletime";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { projectPortfolioService } from "../../service/project-portfolio-service";
import { meetingService } from "../../service/Meeting-service";
import { useSelector } from "react-redux";
import CustomField from "../boardscreens/CustomField";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import countryList from 'react-select-country-list';
import countryToCurrency from 'country-to-currency';
import getSymbolFromCurrency from 'currency-symbol-map';


const engagementTypeDropdown = [
  {
    value: 1,
    label: "Direct",
  },
  {
    value: 2,
    label: "Via-Vendor",
  },
  {
    value: 3,
    label: "Via-Middleman",
  },
]

const projectStatusDropdown = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "In-Progress ",
  },
  {
    value: 3,
    label: "Completed",
  },
  {
    value: 4,
    label: "On-Hold",
  },
]

const projectTypeDropdown = [
  {
    value: 1,
    label: "Time And Material",
  },
  {
    value: 2,
    label: "FixedCost",
  }
]

interface IskillSets {
  skillId: string;
  name: string;
  level: string;
}
interface skillsInter {
  id: string;
  name: string;
}
var dropdown_syle = {
  searchBox: {
    lineHeight: "30px",
    position: "relative",
    zIndex: "999",
    borderColor: "#EEEEEE",
  },
  chip: {
    marginBottom: 0,
  },

};

const styleCross = {
  position: "absolute" as "absolute",
  right: "5px",
  top: "5px",
};

const iconMap: any = {
  pdf: <Icons.Pdfdocs />,
  docx: <Icons.docxicon />,
  png: <Icons.pngicon />,
  mp4: <Icons.videoicon />,
  mp3: <Icons.videoicon />,
  jpeg: <Icons.jpegicon />,
  jpg: <Icons.jpegicon />,
  txt: <Icons.txticon />,
  xlsm: <Icons.xlsmicon />,
  xlsx: <Icons.xlsxicon />,
  xls: <Icons.xlsicon />,
  docm: <Icons.docxicon />,
};
interface CountryOption {
  label: string;
  value: string;
}

const dummy = [
  {
    value: "Rs",
    label: "$",
    retePerHour: "+91",
    country: "India",
  },
  {
    value: "USD",
    label: "$",
    retePerHour: "+1",
    country: "USA",
  },
  {
    value: "NZD",
    label: "$",
    retePerHour: "+64",
    country: "New Zealand",
  },
  {
    value: "AUD",
    label: "A$",
    retePerHour: "+61",
    country: "Australia",
  },
  {
    value: "Pound Sterling",
    label: "£",
    retePerHour: "+44",
    country: "UK",
  },
  {
    value: "Euro",
    label: "€",
    retePerHour: "+33",
    country: "France",
  },
];
const PortfolioEdit = () => {
  const _permission = useSelector((state: any) => state.ProjectPortfolioApp.ProjectPortfolioPermission);
  const [selectedCaseStudyFileID, setSelectedCaseStudyFileID] = useState("");
  const [projectName, setprojectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [clientName, setClientName] = useState("");
  const [selectedprojectType, setSelectedProjectType] = useState<number>();
  const [selectedDomain, setSelectedDomain] = useState("");
  const [allDomain, setAllDomain] = useState<any>([]);
  const [engagementType, setEngagementType] = useState<number>();
  const [plannedCost, setPlannedCost] = useState("");
  const [receivedCosting, setReceivedCosting] = useState("");
  const [projectStatus, setProjectStatus] = useState<number>();
  const [webUrl, setWebUrl] = useState("");
  const [projectObjective, setProjectObjective] = useState("");
  const [projectChallanges, setProjectChallanges] = useState("");
  const [projectKeyword, setProjectKeyword] = useState("");
  const [clientFeedBack, setClientFeedBack] = useState("");
  const [skillset, setskillset] = useState<IskillSets[]>([]);
  const [skillOptions, setskillsOptions] = useState<skillsInter[]>([]);
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [resourcesList, setResourcesList] = useState<any>([]);
  const [roleList, setRoleList] = useState<any>([]);
  const [complacenceFollowed, setComplacenceFollowed] = useState<any>("");
  const [oneDriveFolderList, setOneDriveFolderList] = useState<any>([]);
  const [oneDriveFolderItemOfCaseStudy, setOneDriveFolderItemOfCaseStudy] = useState<any>(null);
  const [oneDriveItemOfFolders, setOneDriveItemOfFolders] = useState<any>([]);
  const [selectedCaseStudy, setSelectedCaseStudy] = useState<any>([]);
  const [selectedCaseStudyFolderId, setSelectedCaseStudyFolderId] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [toggleLoaderForCaseStudy, setToggleLoaderCaseStudy] = useState(false);
  const { projId } = useParams();
  const [projectIdAfterAdd, setProjectIdAfterAdd] = useState("");
  const [selectedIdForDelete, setSelectedIdForDelete] = useState("");
  const [caseStudyFieldEnabledForUpload, setCaseStudyFieldEnabledForUpload] = useState(false);
  const [folderFieldEnabledForUpload, setFolderFieldEnabledForUpload] = useState(false);
  const [caseStudyAttachmentLink, setCaseStudyAttachmentLink] = useState<any>([]);
  const [foldersAttachmentLink, setFoldersAttachmentLink] = useState<any>([]);
  const [selectedIdToDeleteAttachment, setSelectedIdToDeleteAttachment] = useState("");
  const [openAlert, setAlertOpen] = React.useState(false);
  const [uplodDialogAfterAdd, setUplodDialogAfterAdd] = React.useState(false);
  const [contactPerson, setContactPerson] = useState("");
  const [currency, setCurrency] = useState("");
  const [selectedNDA, setSelectedNDA] = useState(true);
  const [countryValues, setCountryValues] = useState<CountryOption | null>(null);
  const options = useMemo(() => countryList().getData(), []);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  //console.log(options, "options")
  const navigate = useNavigate();

  const handleClickOpenDeleteAlert = () => {
    setAlertOpen(true);
    //GetOneDriveFolderDataByFolderIdDelete(selectedCaseStudyFolderId);
  };

  const validVideoTypes = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/avi",
    "video/mpeg",
    "video/quicktime",
    "video/x-ms-wmv",
    "video/x-msvideo",
    "video/x-flv"
  ];

  const handleCloseDeleteAlert = () => {
    setAlertOpen(false);
    setSelectedIdForDelete("");
    setSelectedCaseStudyFileID("");
  };

  let objTeamMember = {
    roleId: "",
    userId: "",
    name: ""
  }
  console.log(oneDriveFolderList, "oneDriveFolderList")
  const [teamMemberList, setTeamMemberList] = useState<any[]>([objTeamMember]);
  const setSkillSetsfromDropdown = (e: any) => {
    var myArray: any = [];
    e.forEach((elem: any) => {
      let obj = { skillId: elem.id ?? elem.skillId, name: elem.name, level: "" };
      myArray.push(obj);
    });
    setskillset(myArray);
  };

  const getAllDomain = async () => {
    var input = {
      domainName: "",
    };
    try {
      await Domainservice.GetAllDomainData(input).then((response: any) => {
        if (response.data.isError) {
        } else {
          setAllDomain(response?.data)
          console.log(projId?.length , "PROJ LENGTH");
        }
      })
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getSkillSetsDetails = async () => {
    var input = {
      module: 2,
      status: "",
      search: "",
    };
    try {
      await projectManagementServive
        .getalllistingsecure(input)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            setskillsOptions(response?.data);
          }
        });
    }
    catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const getUsersdrop = async () => {
    try {
      var payload = {
        department: "",
        workLocationStatus: 0,
        status: "",
        search: "",
        take: 50000,
        skip: 0,
      };

      await meetingService
        .getUserForMeeting(payload)
        .then((response: any) => {
          if (response.data.isError) {
            //setUser([hodData]);
          } else {
            setResourcesList(response.data.result);
            console.log(response);
          }
        });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  const getAllRolesOfUser = async () => {
    try {
      await projectPortfolioService
        .getAllRoleForUser()
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            setRoleList(response?.data)
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const getportfolioDetailsByProjectId = async (pid: any) => {
    try {
      await projectPortfolioService
        .getPortfolioDetailsByProjectId(pid)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            setprojectName(response?.data?.projectName)
            setProjectDescription(response?.data?.projectDescription)
            setEngagementType(response?.data?.engagementType)
            console.log("{engagementType?.toString()", response?.data?.engagementType.toString());
            setClientFeedBack(response?.data?.clientFeedback)
            setStartDate(response?.data?.startDate == "0001-01-01T00:00:00Z" ? "" : response?.data?.startDate)
            setEndDate(response?.data?.endDate == "0001-01-01T00:00:00Z" ? "" : response?.data?.endDate)
            setComplacenceFollowed(response?.data?.complacenceFollowed)
            setClientName(response?.data?.clientName)
            setskillset(response?.data?.skillSets)
            setPlannedCost(response?.data?.totalCost)
            setReceivedCosting(response?.data?.totalReceivedCost)
            setProjectObjective(response?.data?.objectives)
            setProjectChallanges(response?.data?.challenges)
            setProjectKeyword(response?.data?.keyWords)
            setSelectedProjectType(response?.data?.projectType);
            console.log("projectType ===", response?.data?.projectType);
            setProjectStatus(response?.data?.status)
            setWebUrl(response?.data?.webUrl)
            console.log("status ===", response?.data?.status);
            setSelectedDomain(response?.data?.domainId)
            let tmember: any = [];
            response?.data?.teamMembers.map((item: any) => {
              let obj = { userId: item?.userId, roleId: item?.roleId }
              tmember.push(obj)
            })
            setTeamMemberList(tmember)
            setCountryValues(response?.data?.country)
            setCurrency(response?.data?.currency)
            setContactPerson(response?.data?.contactPerson)
            setSelectedNDA(response?.data?.canWeShowPubliclyOrUnderNDA)
          }
        });
    }
    catch (ex: any) {
      console.error("An error occurred:", ex);
      if (ex?.response?.data?.Error) {
        ex.response.data.Error.map((err: any) => {
          toast.warning(err);
        });
      }
      //  else {
      //   toast.warning("An unexpected error occurred.");
      // }
    }

  };

  // Ensure this outputs 'Sanjay'



  const GetOneDriveFolderByProjectId = async (pid: any) => {
    try {
      await projectPortfolioService
        .getOneDriveFolderByProjectId(pid)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            let abc = response?.data?.value.find((item: any) => (item.name == "Case Study"))
            console.log(abc, "abcabcabcabcabc")
            if (abc) {
              GetOneDriveFolderDataByFolderId(abc.id, abc.name)
              setSelectedCaseStudyFolderId(abc.id)
              console.log("ABC ID --", abc.id)
            }
            let filteredData = response?.data?.value.filter((item: any) => (item.name != "Case Study"))
            console.log(filteredData, "filteredData")
            setOneDriveFolderList(filteredData)
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const GetOneDriveFolderDataByFolderId = async (fid: any, name: any) => {
    setToggleLoader(true)
    try {
      await projectPortfolioService
        .getOneDriveFolderDataByProjectId(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
            setToggleLoader(false);
            toast.warning("Error while fetching data");
          } else {
            if (name) {
              if (response?.data?.value.length > 0) {
                GetOneDriveFileViewByItemIdForCaseStudy(response?.data?.value[0]?.id)
                console.log("id value ==", response?.data?.value[0]?.id);
                setSelectedCaseStudyFileID(response?.data?.value[0]?.id);
              }
            }
            else {
              setOneDriveItemOfFolders(response?.data?.value)
            }
          }
          setToggleLoader(false)
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false)
        toast.warning(err);
      });
    }
  };

  const GetOneDriveFileIDByFolderId = async (fid: any) => {
   
    try {
      await projectPortfolioService
        .getOneDriveFolderDataByProjectId(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
            setToggleLoader(false);
            toast.warning("Error while fetching data");
          } else {
            console.log(response.data.value[0].id, "file ID is ===");
            setSelectedCaseStudyFileID(response.data.value[0].id);
          }
         
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false)
        toast.warning(err);
      });
    }
  };

  // const GetOneDriveFolderDataByFolderIdDelete = async (fid: any) => {
  //   debugger
  //   try {
  //     await projectPortfolioService
  //       .getOneDriveFolderDataByProjectId(fid)
  //       .then(async (response: any) => {
  //         if (response.data.isError) {
  //           toast.warning("Error while fetching data");
  //         } else {

  //           console.log(response?.data?.value[0].id , "Delete returned id");
  //           setSelectedIdForDelete(response?.data?.value[0]?.id);
          
  //         }
  //       });
  //   } catch (ex: any) {
  //     ex.data.Error.map((err: any, idx: any) => {
  //       setToggleLoader(false)
  //       toast.warning(err);
  //     });
  //   }
  // };

  const GetOneDriveFileViewByItemId = async (fid: any) => {
    setToggleLoader(true);
    try {
      await projectPortfolioService
        .getOneDriveFileViewByItemId(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            // const byteCharacters = atob(response?.data?.result?.content)
            // const byteNumbers = new Array(byteCharacters.length);
            // for (let i = 0; i < byteCharacters.length; i++) {
            //   byteNumbers[i] = byteCharacters?.charCodeAt(i);
            // }
            // const byteArray = new Uint8Array(byteNumbers);
            // const blob = new Blob([byteArray], { type: response?.data?.result?.contentType });
            // const fileURL = URL.createObjectURL(blob);
            setToggleLoader(false);
            window.open(response?.data?.result, '_blank');
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err);
      });
    }
  };
  const GetOneDriveFileViewByItemIdForCaseStudy = async (fid: any) => {
    try {
      setToggleLoaderCaseStudy(true);
      await projectPortfolioService
        .getOneDriveFileViewByItemIdForCaseStudy(fid)
        .then(async (response: any) => {
          if (response.data.isError) {
            setToggleLoaderCaseStudy(false);
            toast.warning("Error while fetching data");
          } else {
            const byteCharacters = atob(response?.data?.result?.content)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters?.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: response?.data?.result?.contentType });
            const fileURL = URL.createObjectURL(blob);
            console.log(fileURL, "GetOneDriveFileViewByItemId")
            setOneDriveFolderItemOfCaseStudy(fileURL)
            setToggleLoaderCaseStudy(false);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoaderCaseStudy(false);
        toast.warning(err);
      });
    }
  };
  useEffect(() => {
    if (projId) {
      if (_permission.tab_ManagePortfolioEdit_Access !== true) {
        navigate("/dashboard");
      }
      GetOneDriveFolderByProjectId(projId)
      getportfolioDetailsByProjectId(projId);
      getPorfolioAttachmentLinkForSelectedFolderName("Case Study")
    }
    else {
      if (_permission.tab_ManagePortfolioAdd_Access !== true) {
        navigate("/dashboard");
      }
    }
    getAllRolesOfUser()
    getAllDomain()
    getSkillSetsDetails()
    getUsersdrop();

  }, []);


  const getPorfolioAttachmentLinkForSelectedFolderName = async (fName: any) => {
    try {
      let param = {
        projectId: projId,
        folderName: fName
      }
      await projectPortfolioService
        .getProjectPortfolioAttachmentByFolder(param)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            if (fName == "Case Study") {
              setCaseStudyAttachmentLink(response?.data)
            }
            else {
              setFoldersAttachmentLink(response?.data)
            }
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        if (err == "Links not found." && fName == "Case Study") {
          setCaseStudyAttachmentLink([])
        }
        else {
          setFoldersAttachmentLink([])
        }
      });
    }
  };

  const addOrRemoveMember = (idx: any) => {
    let abc = [...teamMemberList];
    if (idx) {
      abc.splice(idx, 1);
    }
    else {
      abc.push(objTeamMember);
    }
    setTeamMemberList(abc);
  };
  const UpdatePorfolioProject = async (values: any) => {
    values.endDate = values.endDate ? values.endDate : "0001-01-01T00:00:00Z";
    values.startDate = values.startDate ? values.startDate : "0001-01-01T00:00:00Z";
    try {
      await projectPortfolioService
        .updatePorfolioProject(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            toast.success("Data Updated successfully")
            navigate("/project-portfolio")
            
            console.log(projId?.length , "PROJ LENGTH");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const addPorfolioProject = async (values: any) => {
    values.endDate = values.endDate ? values.endDate : "0001-01-01T00:00:00Z";
    values.startDate = values.startDate ? values.startDate : "0001-01-01T00:00:00Z";
    try {
      await projectPortfolioService
        .addPorfolioProject(values)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            if (response?.data) {
              await setProjectIdAfterAdd(response?.data)
              createProjectfolderOnOneDrive(response?.data)
            }
            toast.success("Data Added successfully")
            setUplodDialogAfterAdd(true);
            
            console.log(projId?.length , "PROJ LENGTH");
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const createProjectfolderOnOneDrive = async (pId: string) => {
    let param = {
      projectId: pId,
      folderName: projectName
    }
    try {
      await projectPortfolioService
        .createProjectFolderByProjId(param)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {

          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const UploadDocumentOnOneDriveBasisOfFolderId = async (values: File[]) => {
    setToggleLoader(true);
    let formData = new FormData();
    values.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('folderId', selectedFolderId);
    console.log(formData, "formData to be saved ---")
    try {
      await projectPortfolioService
        .uploadDocumentOnOneDriveBasisOfFolderId(formData)
        .then(async (response: any) => {
          if (response.data.isError) {
            if (response.data.listErrors && response.data.listErrors.length > 0) {
              response.data.listErrors.forEach((error: string) => {
                setToggleLoader(false);
                const errorMessage = error.split(":")[0];
                toast.warning(errorMessage);
              });
            } else {
              toast.warning("Error while uploading Documents");
            };
          } else {
            GetOneDriveFolderDataByFolderId(selectedFolderId, "")
            toast.success("Files Uploaded successfully")
          }
        });
    } catch (ex: any) {
      setToggleLoader(false);
      if (ex?.response?.data?.listErrors) {
        ex.response.data.listErrors.forEach((err: any) => {
          const errorMessage = err.split(":")[0];
          toast.warning(errorMessage);
        });
      } else {
        toast.warning("An unexpected error occurred.");
      }
      // else {
      //   toast.warning("An unexpected error occurred.");
      // }
    }
  };

  const DeleteDocumentOnOneDriveForCaseStudyBasisOfFolderId = async (values: any) => {
   
  };

  const UploadDocumentOnOneDriveForCaseStudyBasisOfFolderId = async (values: any) => {
    setToggleLoaderCaseStudy(true);
    let formData = new FormData();
    formData.append('file', values?.[0]);
    formData.append('folderId', selectedCaseStudyFolderId);
    try {
      await projectPortfolioService
        .uploadDocumentOnOneDriveForCaseStudyBasisOfFolderId(formData)
        .then(async (response: any) => {
          if (response.data.isError) {
            setToggleLoaderCaseStudy(false);
            toast.warning("Error while uploading Documents");
          } else {
            // GetOneDriveFolderDataByFolderId(selectedFolderId, "")
            setToggleLoaderCaseStudy(false);
            setSelectedCaseStudy([]);
            toast.success("Files Uploaded successfully")
            handleClose()
          }
        });
    } catch (ex: any) {
      setToggleLoaderCaseStudy(false);
      if(ex.data == undefined){
        toast.warning("File size is too large. Please upload a smaller file.")
      }
      else {
        ex.data.Error.map((err: any, idx: any) => {
          setToggleLoaderCaseStudy(false);
          toast.warning(err);
        });
      }
    }
  };

  const DeleteOneDriveItemByItemId = async () => {
    debugger
    setToggleLoader(true);
    try {
      if(selectedCaseStudyFileID){
        setToggleLoader(true);
        let formData = new FormData();
        formData.append('FileId', selectedCaseStudyFileID );
        formData.append('FolderId',selectedCaseStudyFolderId);
        try {
          await projectPortfolioService
            .uploadDocumentOnOneDriveForCaseStudyBasisOfFolderId(formData)
            .then(async (response: any) => {
              debugger
              if (response.data.isError) {
                setToggleLoader(false);
                toast.warning("Error while uploading Documents");
                setToggleLoader(false);
              } else {
                //GetOneDriveFolderByProjectId(projId);
                setSelectedCaseStudy([]);
                setOneDriveFolderItemOfCaseStudy(null);
               toast.success(response.data.result);
               setAlertOpen(false);
               setToggleLoader(false);
              }
            });
        } catch (ex: any) {
          ex.data.Error.map((err: any, idx: any) => {
            setToggleLoader(false);
            toast.warning(err);
          });
        }
      }
      else{
      if (selectedIdForDelete.length < 2) {
        handleCloseDeleteAlert();
        let abc = [...oneDriveItemOfFolders];
        abc.splice(parseInt(selectedIdForDelete), 1);
        console.log(abc, "abc")
        setOneDriveItemOfFolders(abc);
        setSelectedIdForDelete("")
        toast.success("Item deleted successfully")
        setToggleLoader(false);
      } else {
        await projectPortfolioService
          .deleteOneDriveItemByItemId(selectedIdForDelete)
          .then(async (response: any) => {
            if (response.data.isError) {
              setToggleLoader(false);
              toast.warning("Error while deleting Documents");
            } else {
              GetOneDriveFolderDataByFolderId(selectedFolderId, "")
              handleCloseDeleteAlert();
              setSelectedIdForDelete("")
              toast.success("Item deleted successfully")
            }
          });
      }}
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        setToggleLoader(false);
        toast.warning(err);
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const [selectedFolderId, setSelectedFolderId] = React.useState("");
  const [selectedFolderName, setSelectedFolderName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedFolderName("")
  };

  const fieldValidationSchema = Yup.object().shape({
    projectName: Yup.string().required("Name is required").nullable(),
    domainId: Yup.string().required("Domain is required").nullable(),
    status: Yup.string().required("Status is required").nullable(),
    engagementType: Yup.string().required("Type of Engagement is required").nullable(),
    country: Yup.string().required("Country is required").nullable(),
    projectType: Yup.string().required("Project Type is required"),
    startDate: projectStatus !=1 && projectStatus!=undefined ?  Yup.string().required("Start Date is required"):Yup.string(),
    endDate: projectStatus == 3 ?Yup.string().required("End Date is required"):Yup.string(),
    teamMembers: Yup.array().of(
      Yup.object().shape({
        userId: teamMemberList.length > 1 ? Yup.string().required("Resource is required").nullable() : Yup.string(),
        roleId: teamMemberList.length > 1 ? Yup.string().required("Role is required").nullable() : Yup.string()
      })
    ),

  });

  function getFileExtension(url: string): any {
    const splitUrl = url?.split(".");
    return splitUrl?.at(-1) || "";
  }

  const closeSelectedLinkfield = () => {
    setCaseStudyFieldEnabledForUpload(false)
  };
  const closeSelectedFolderLinkfield = () => {
    setFolderFieldEnabledForUpload(false)
  };

  const addPortfolioAttachemtLink = async (val: any) => {
    if (selectedIdToDeleteAttachment) {
      deleteAttachemntByLinkId(selectedIdToDeleteAttachment, "")
    }
    let parm = {
      projectId: projId,
      folderName: val?.ticketId,
      link: val?.summary
    }
    try {
      await projectPortfolioService
        .addProjectPortfolioLink(parm)
        .then(async (response: any) => {
          if (response.data.isError) {

            toast.warning("Error while uploading Documents");
          } else {
            if (val?.ticketId == "Case Study") {
              setCaseStudyFieldEnabledForUpload(false);
              getPorfolioAttachmentLinkForSelectedFolderName("Case Study")
            }
            else {
              setFolderFieldEnabledForUpload(false)
              getPorfolioAttachmentLinkForSelectedFolderName(val?.ticketId)
            }

            toast.success("Link Added successfully")
            setSelectedIdToDeleteAttachment("")
          }
        });
    } catch (ex: any) {
      ex?.data?.Error?.map((err: any, idx: any) => {

        toast.warning(err);
      });
    }
  }
  const deleteAttachemntByLinkId = async (id: string, fname: any) => {
    debugger
    try {
      await projectPortfolioService
        .deletePortFolioLinkById(id)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.warning("Error while fetching data");
          } else {
            if (fname) {
              getPorfolioAttachmentLinkForSelectedFolderName(fname)
            }
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  function validURL(str: any) {
    debugger
    var pattern = new RegExp('^(https?:\\/\\/)', 'i'); // check for http or https at the start
    return pattern.test(str);
  }
  const save = async (val: any) => {
    let isvalidURL = validURL(val?.summary)
    if (isvalidURL) {
      addPortfolioAttachemtLink(val)
    }
    else {
      toast.warn("Please Attach a valid link")
    }
  };

  return (
    <>
      <Box sx={{ padding: "24px", background: "#fff" }}>
        <Box
          sx={{
            display: 'flex', justifyContent: 'space-between', // Space between the heading and TextField
            alignItems: 'center', width: '100%', marginTop: '0'
          }}>
          <h3
            style={{
              marginTop: '0', color: '#4B4B5A', fontSize: '24px', fontWeight: '500',
            }}>
            {projId && projId.length > 13 ? 'Edit Project Details' : 'Add Project Portfolio'}
          </h3>
          {caseStudyFieldEnabledForUpload && (
            <CustomField
              defaultValue={caseStudyAttachmentLink[0]?.link}
              saveText={save}
              cancelEdit={closeSelectedLinkfield}
              ticketId={"Case Study"}
              width={"400px"}
            />)}
        </Box>
        <Formik
          initialValues={{
            id: projId,
            projectName: projectName,
            projectDescription: projectDescription,
            clientName: clientName,
            projectType: selectedprojectType,
            domainId: selectedDomain,
            engagementType: engagementType,
            status: projectStatus,
            objectives: projectObjective,
            challenges: projectChallanges,
            keyWords: projectKeyword,
            clientFeedback: clientFeedBack,
            startDate: startDate,
            endDate: endDate,
            complacenceFollowed: complacenceFollowed,
            totalCost: plannedCost,
            totalReceivedCost: receivedCosting,
            skillSets: skillset,
            teamMembers: teamMemberList,
            webUrl: webUrl,
            canWeShowPubliclyOrUnderNDA: selectedNDA,
            contactPerson: contactPerson,
            currency: currency,
            country: countryValues,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              debugger
              if (values.endDate && values.startDate && values.endDate <= values.startDate) {
                debugger
                toast.warning("Start date can't be equal to and greater than the end date");
              }
              else {
                if (projId && projId?.length > 23) {
                  UpdatePorfolioProject(values)
                }
                else {
                  delete values?.id;
                  addPorfolioProject(values)
                }
              }

            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status === 400) {
                {
                  err.data.Error.map((error: any, idx: any) => toast.warn(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3} >
                <Grid item md={6}>
                  <Box
                    sx={{ display: "flex",  paddingTop: projId && projId?.length > 23 ? "20px" : "0px" , flexDirection: "column", gap: "22px" }}
                  >
                    <TextField
                      id="projectName"
                      error={Boolean(touched.projectName && errors.projectName)}
                      helperText={touched.projectName && errors.projectName}
                      required
                      label="Project Name"
                      variant="outlined"
                      fullWidth
                      name="projectName"
                      value={projectName}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.projectName = value;
                        setprojectName(value);
                      }} />
                    <TextField label="Project Description"
                      sx={{
                        '& .MuiInputBase-input': {
                          lineHeight: '1.5', // Adjust line height for spacing between lines
                        }
                      }}
                      multiline rows={6}
                      onBlur={handleBlur}
                      id="projectDescription"
                      value={projectDescription}
                      onChange={(e) => {
                        let value = e.currentTarget.value;
                        values.projectName = value;
                        setProjectDescription(value);
                      }} />
                    <TextField label="Client Name"
                      id="clientName"
                      error={Boolean(touched.clientName && errors.clientName)}
                      helperText={touched.clientName && errors.clientName}
                      name="clientName"
                      value={clientName}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.clientName = value;
                        setClientName(value);
                      }} />
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <Autocomplete
                          value={allDomain?.find(
                            (option: any) => option.id == selectedDomain
                          ) ?? null}
                          id="outlined-projectOwnerId"
                          options={allDomain}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            const selectedId = value?.id ?? "";
                            values.domainId = selectedId;
                            setSelectedDomain(selectedId);
                          }}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: true,
                                  options: {
                                    altBoundary: true,
                                    flipVariations: true,
                                    altAxis: true,
                                    padding: 8,
                                    fallbackPlacements: ['bottom'],
                                  },
                                },
                              ],
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={handleBlur}
                              id="outlined-projectOwnerId"
                              label="Domain"
                              name="domainId"
                              value={selectedDomain}
                              error={Boolean(touched.domainId && errors.domainId)}
                              helperText={touched.domainId && errors.domainId}
                              variant="outlined"
                              required
                              fullWidth />
                          )} />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          label="Project Type"
                          error={Boolean(touched.projectType && errors.projectType)}
                          helperText={touched.projectType && errors.projectType}
                          id="outlined-basic"
                          name="projectType"
                          value={selectedprojectType !== undefined ? selectedprojectType?.toString() : ''}
                          variant="outlined"
                          required
                          select
                          fullWidth
                        >
                          {projectTypeDropdown?.map((option: any, idx) => (
                            <MenuItem
                              key={option?.value}
                              value={String(option?.value)}
                              onClick={(event) => {
                                values.projectType = option?.value;
                                setSelectedProjectType(option?.value);
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <TextField
                          onBlur={handleBlur}
                          label="Engagement Type"
                          error={Boolean(touched.engagementType && errors.engagementType)}
                          helperText={touched.engagementType && errors.engagementType}
                          id="outlined-basic"
                          required
                          name="engagementType"
                          value={engagementType !== undefined ? engagementType.toString() : ''}
                          variant="outlined"
                          select
                          fullWidth
                        >
                          {engagementTypeDropdown?.map((option: any, idx) => (
                            <MenuItem
                              key={option?.value}
                              value={String(option?.value)}
                              onClick={() => {
                                values.engagementType = option?.value;
                                setEngagementType(option?.value);
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField></Grid>
                      <Grid item md={6}>
                        <TextField
                          required
                          error={Boolean(touched.status && errors.status)}
                          helperText={touched.status && errors.status}
                          id="outlined-basic"
                          label="Project Status"
                          value={projectStatus !== undefined ? projectStatus?.toString() : ''}
                          name="status"
                          variant="outlined"
                          select
                          fullWidth
                          onBlur={handleBlur}
                        >
                          {projectStatusDropdown?.map((option: any, idx) => (
                            <MenuItem
                              key={option?.value}
                              value={String(option?.value)}
                              onClick={() => {
                                debugger
                                values.status = option?.value;
                                setProjectStatus(option?.value);
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Stack direction={'row'} sx={{ gap: "24px" }} alignItems={'center'} >
                      <TextField label="Cost"
                        value={plannedCost}
                        name="totalCost"
                        fullWidth
                         autoComplete="off"
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^0-9]/g, "").trimStart();
                          values.totalCost = value;
                          setPlannedCost(value);
                        }} />
                      <TextField label="Received"
                        value={receivedCosting}
                        fullWidth
                        name="totalReceivedCost"
                         autoComplete="off"
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^0-9]/g, "").trimStart();
                          values.totalReceivedCost = value;
                          setReceivedCosting(value);
                        }} />
                    </Stack>
                    <Stack direction={'row'} sx={{ gap: "24px" }} alignItems={'center'} >
                      <TextField label="Contact Person"
                        value={contactPerson}
                        name="contactPerson"
                        fullWidth
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                          values.contactPerson = value;   //change
                          setContactPerson(value);
                        }} />
                      <TextField
                        label="Currency"
                        value={currency}
                        name="currency"
                        disabled
                        fullWidth >
                      </TextField>
                    </Stack>

                    <Grid container spacing={3}>
                    <Grid item md={6} sx={{display: "flex", gap: "12px", alignItems: "center"}}>
                      <FormLabel >NDA</FormLabel>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={selectedNDA ? "Yes" : "No"}
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                            onClick={(event) => {
                              setSelectedNDA(true);
                              values.canWeShowPubliclyOrUnderNDA = true;
                            }}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                            onClick={(event) => {
                              setSelectedNDA(false);
                              values.canWeShowPubliclyOrUnderNDA = false;
                            }}
                          />
                        </RadioGroup>
                      </FormControl></Grid>
                    <Grid item md={6}>
                    <Autocomplete
                        options={options}
                        value={countryValues}
                        onChange={(event: any, value: any) => {
                          debugger
                          const countryCode = value?.value as keyof typeof countryToCurrency;
                          const currency = countryToCurrency[countryCode];
                          if (currency) {
                            console.log(`Currency Code: ${currency}`);
                            setCurrency(currency);
                            console.log(`Currency Symbol: ${getSymbolFromCurrency(currency)}`);
                          }
                          setCountryValues(value?.label);
                        }}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={handleBlur}
                              label={"Select Country"}
                              id="outlined-basic"
                              value={countryValues}
                              variant="outlined"
                              required
                              fullWidth
                              error={Boolean(touched.country && errors.country)}
                              helperText={touched.country && errors.country}
                            />
                          )}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: true,
                                  options: {
                                    altBoundary: true,
                                    flipVariations: true,
                                    altAxis: true,
                                    padding: 8,
                                    fallbackPlacements: ['bottom'],
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      // error={Boolean(touched.status && errors.status)}
                      // helperText={touched.status && errors.status}
                      id="outlined-basic"
                      label="Live URL"
                      value={webUrl}
                      name="liveUrl"
                      variant="outlined"
                      fullWidth
                      onBlur={(e) => {
                        let isvalidURL = validURL(e.target.value);
                        if(isvalidURL){
                          handleBlur(e);
                          setDisableSaveButton(false);
                        }else{
                          toast.warning("Please attach a valid URL.");
                          setDisableSaveButton(true);
                        }
                      }}
                      onChange={(e) => {
                        let value = e.currentTarget.value;
                        values.webUrl = value;
                        setWebUrl(value);
                      }}
                    />



                    <TextField
                      label="Project Objective"
                      multiline
                      rows={2}
                      value={projectObjective}
                      name="projectObjective"
                      sx={{
                        '& .MuiInputBase-input': {
                          lineHeight: '1.5', // Adjust line height for spacing between lines
                        }
                      }}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.objectives = value;
                        setProjectObjective(value);
                      }} />
                    <TextField label="Challenges" multiline rows={2}
                      value={projectChallanges}
                      name="projectChallanges"
                      sx={{
                        '& .MuiInputBase-input': {
                          lineHeight: '1.5', // Adjust line height for spacing between lines
                        }
                      }}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.challenges = value;
                        setProjectChallanges(value);
                      }} />
                    <TextField label="Keywords" multiline rows={2}
                      value={projectKeyword}
                      name="projectKeyword"
                      sx={{
                        '& .MuiInputBase-input': {
                          lineHeight: '1.5', // Adjust line height for spacing between lines
                        }
                      }}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.keyWords = value;
                        setProjectKeyword(value);
                      }} />
                    <TextField
                      label="Client Feedback"
                      value={clientFeedBack}
                      name="clientFeedBack"
                      sx={{
                        '& .MuiInputBase-input': {
                          lineHeight: '1.5', // Adjust line height for spacing between lines
                        }
                      }}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.clientFeedback = value;
                        setClientFeedBack(value);
                      }}
                      multiline
                      rows={2} />
                  </Box>
                  <Button sx={{ marginTop: "40px" }} disabled={disableSaveButton}  variant="contained" type="submit">
                    Save
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: "14px" }}
                  >
                    {projId && projId?.length > 23 && (
                      <><Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                            alignItems:"center"
                          }}
                        >
                          <Box sx={{ color: "#666666", fontSize: "14px", flexDirection: "row", display: "flex", gap: "16px" }}>
                            <Typography sx={{fontSize:"14px"}}>Case Study</Typography>
                            {selectedCaseStudy.length > 0 && (
                              
                              <Typography sx={{ ml: "170px", color: toggleLoaderForCaseStudy ? "grey" : "#068DF2", cursor: "pointer" }} onClick={() => {
                                if(!toggleLoaderForCaseStudy){UploadDocumentOnOneDriveForCaseStudyBasisOfFolderId(selectedCaseStudy);}
                                
                              }}>Upload to One Drive</Typography>) }
                          </Box>
                          <Box
                            sx={{
                              color: "#068DF2",
                              fontSize: "14px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                            onClick={() => {
                              caseStudyAttachmentLink.length > 0 ? window.open(caseStudyAttachmentLink[0]?.link, '_blank') :
                                setCaseStudyFieldEnabledForUpload(true)
                            }}
                          >
                            <AttachmentIcon /> {caseStudyAttachmentLink.length > 0 ? "View Attachment" : "Attach a Link"}
                            {caseStudyAttachmentLink.length > 0 && <><IconButton onClick={(e) => {
                              e.stopPropagation();
                              setCaseStudyFieldEnabledForUpload(true);
                              setSelectedIdToDeleteAttachment(caseStudyAttachmentLink[0]?.id);
                            }}><Icons.editblue />
                            </IconButton><DeleteIcon sx={{ color: "#ff5858" }} onClick={(e: any) => {
                              e.stopPropagation();
                              setCaseStudyFieldEnabledForUpload(false)
                              deleteAttachemntByLinkId(caseStudyAttachmentLink[0]?.id, "Case Study")
                              setCaseStudyAttachmentLink([]);
                            }} /></>}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            border: "1px solid #e0e0e0",
                            padding: "16px 24px",
                            textAlign: "center",
                            width: "100%",
                            height: "40vh",
                            margin: "auto",
                            position: "relative"
                          }}
                        >

{toggleLoader && (<CircularProgress size={120} sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}

                          {toggleLoaderForCaseStudy && (
                            <Box sx={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <CircularProgress size={50} color="secondary" sx={{}} />
                            </Box>
                          )}

                          {oneDriveFolderItemOfCaseStudy ? (
                            <>
                              <video
                                src={oneDriveFolderItemOfCaseStudy}
                                poster={oneDriveFolderItemOfCaseStudy}
                                width='100%'
                                height='100%'
                                controls />
                              {_permission.tab_ManagePortfolioDeleteDocument_Access && (
                                !toggleLoaderForCaseStudy && (
                                <Box sx={{ position: "absolute", inset: "15px 25px auto auto", cursor: "pointer" }}>
                                  <IconButton onClick={() => {
                                    debugger;
                                    if (selectedCaseStudy.length > 0) {
                                      console.log(selectedCaseStudyFolderId, "Folder ID")
                                      setSelectedCaseStudy([]);
                                      setOneDriveFolderItemOfCaseStudy(null);
                                      GetOneDriveFileIDByFolderId(selectedCaseStudyFolderId);
                                    }
                                    else {
                                      console.log(selectedCaseStudyFolderId, "Folder ID");
                                      GetOneDriveFileIDByFolderId(selectedCaseStudyFolderId);
                                      handleClickOpenDeleteAlert();
                                    }
                                  }}><CloseIcon /></IconButton></Box>))}
                            </>
                          ) : (

                            <>
                              {_permission.tab_ManagePortfolioUploadDocument_Access && (
                                <>
                                  <input
                                    accept="video/*"
                                    style={{ display: "none" }}
                                    id="upload-video"
                                    type="file"
                                    onChange={(e : any) => {
                                      debugger
                                      if(e?.target?.files[0] && validVideoTypes.includes(e.target.files[0].type)){
                                        if (e?.target?.files && e.target.files.length > 0) {
                                          console.log(URL.createObjectURL(e?.target?.files[0]), "selectedFiles dff rfg f fg  ");
                                          setOneDriveFolderItemOfCaseStudy(URL.createObjectURL(e?.target?.files[0]));
                                        }
                                        setSelectedCaseStudy(e.target.files);
                                      } else {
                                        toast.warning("Please provide a video format");
                                      }
                                      
                                    }} /><label htmlFor="upload-video">
                                    <IconButton
                                      color="primary"
                                      aria-label="upload video"
                                      component="span"
                                    >
                                      <CloudUploadIcon
                                        sx={{ fontSize: 40, color: "#03A9F4" }} />
                                    </IconButton>
                                  </label><Typography variant="h6" sx={{ fontWeight: "600", fontSize: "14px", color: "#4B4B5A" }}>Upload Video</Typography><Typography
                                    variant="body2"
                                    sx={{ color: "#9e9e9e", marginTop: "4px" }}
                                  >
                                    attached Link
                                  </Typography>
                                </>
                              )}
                            </>
                          )}

                        </Box>
                      </Box>
                      <Box>
                          <Box
                            sx={{
                              paddingBottom: "14px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#666666",
                              }}
                            >
                              Document 
                            </span>
                          </Box>
                          <Box
                            sx={{
                              padding: "20px",
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              border: "1px solid #e9e9ea",

                              "& > :first-of-type": {
                                paddingRight: "20px",
                              },
                              "& > :last-of-type": {
                                paddingLeft: "20px",
                              },
                              "& > :not(:first-of-type):not(:last-of-type)": {
                                padding: "0 20px",
                              },
                              "& > :not(:last-child)": {
                                borderRight: "1px solid #C8C8C8",
                              },
                            }}
                          >
                            {oneDriveFolderList.map((item: any, idx: any) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setOneDriveItemOfFolders([]);
                                  GetOneDriveFolderDataByFolderId(item?.id, "");
                                  //openFileInNewTab()
                                  handleClickOpen();
                                  setSelectedFolderId(item.id);
                                  setSelectedFolderName(item?.name);
                                  getPorfolioAttachmentLinkForSelectedFolderName(item?.name);
                                }}
                              >
                                <SourceIcon
                                  sx={{ fontSize: "34px", color: selectedFolderId === item.id ? "#d9d9d9" : "#FCD462" }} />
                                <Box
                                  sx={{
                                    color: "#4B4B5A",
                                    fontSize: "14px",
                                    display: "inline-block",
                                    marginTop: "4px",
                                  }}
                                >
                                  {item?.name}
                                </Box>
                              </Box>))}

                          </Box>
                        </Box>
                        </>)}
                    <Multiselect
                      showArrow
                      selectedValues={skillset}
                      displayValue="name"
                      closeOnSelect
                      onKeyPressFn={function setSkillSetsfromDropdown() { }}
                      onRemove={setSkillSetsfromDropdown}
                      onSearch={function setSkillSetsfromDropdown() { }}
                      onSelect={setSkillSetsfromDropdown}
                      placeholder="Technology"
                      options={skillOptions}
                      style={dropdown_syle} />
                    <TextField label="Complacence Followed" name="complacenceFollowed"
                      value={complacenceFollowed}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                        values.complacenceFollowed = value;
                        setComplacenceFollowed(value);
                      }} />
                         <Grid container spacing={1} sx={{ mt: "1px" }}>
                         <Grid item xs={12} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        borderRadius="semi-square"
                        style={{ width: '90%', position: "relative", zIndex: 1 }}
                        value={startDate}
                        label="Start Date"
                        // minDate={new Date(mindate)}
                        onBlur={handleBlur}
                        error={typeof errors.startDate === "string" ? errors.startDate : ""}
                        maxDate={new Date()}
                        onChange={(newValue: any) => {
                          let selectedDate =getDate(newValue)
                          values.startDate =selectedDate ;
                          setStartDate(selectedDate);
                        }}

                      />
                    </LocalizationProvider></Grid>
                  
                    <Grid item xs={12} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        borderRadius="semi-square"
                        style={{ width: '90%', position: "relative", zIndex: 1 }}
                        value={endDate}
                        label="End Date"
                        // minDate={new Date(mindate)}
                        onBlur={handleBlur}
                        maxDate={new Date()}
                        onChange={(newValue: any) => {
                          let selectedDate =getDate(newValue)
                          values.endDate = selectedDate;
                          setEndDate(selectedDate);
                        }}
                       // error={ startDate? "End Date is required" : ""}
                       error={typeof errors.endDate === "string" ? errors.endDate : ""}
                      />
                    </LocalizationProvider>
                    </Grid>
                    </Grid>
                    <Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <Box sx={{ color: "#666666", fontSize: "14px" }}>
                          Team Member
                        </Box>
                        <Box sx={{ color: "#068DF2", fontSize: "14px", cursor: "pointer" }} onClick={() => addOrRemoveMember("")}>
                          Add More
                        </Box>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        {teamMemberList.map((item: any, idx: any) => (
                          <Grid container spacing={2} sx={{ mt: "1px" }}>
                            <Grid item xs={12} lg={5}>
                              <Autocomplete
                                value={resourcesList?.find(
                                  (option: any) => option.id == item?.userId
                                ) ?? null}
                                id="outlined-basic"
                                options={resourcesList}
                                getOptionLabel={(option) => option.name}
                                onChange={async (event, value) => {
                                  let existing = [...teamMemberList];
                                  existing[idx].userId = value?.id ?? "";
                                  setTeamMemberList(existing);
                                }}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select Resource"}
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    onBlur={handleBlur}

                                    name={`teamMembers.${idx}.userId`}
                                    error={Boolean(
                                      getIn(touched, `teamMembers.${idx}.userId`) &&
                                      getIn(errors, `teamMembers.${idx}.userId`)
                                    )}
                                    helperText={
                                      getIn(touched, `teamMembers.${idx}.userId`) &&
                                      getIn(errors, `teamMembers.${idx}.userId`)
                                    }
                                  />
                                )}
                                componentsProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: 'flip',
                                        enabled: true,
                                        options: {
                                          altBoundary: true,
                                          flipVariations: true,
                                          altAxis: true,
                                          padding: 8,
                                          fallbackPlacements: ['bottom'],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                              <Autocomplete
                                value={roleList?.find(
                                  (option: any) => option.id == item?.roleId
                                ) ?? null}
                                id="outlined-basic"
                                options={roleList}
                                getOptionLabel={(option) => option.name}
                                onChange={async (event, value) => {
                                  let existing = [...teamMemberList];
                                  existing[idx].roleId = value?.id ?? "";
                                  setTeamMemberList(existing);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onBlur={handleBlur}
                                    label={"Select Role"}
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    name={`teamMembers.${idx}.roleId`}
                                    error={Boolean(
                                      getIn(touched, `teamMembers.${idx}.roleId`) &&
                                      getIn(errors, `teamMembers.${idx}.roleId`)
                                    )}
                                    helperText={
                                      getIn(touched, `teamMembers.${idx}.roleId`) &&
                                      getIn(errors, `teamMembers.${idx}.roleId`)
                                    }
                                  />
                                )}
                                componentsProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: 'flip',
                                        enabled: true,
                                        options: {
                                          altBoundary: true,
                                          flipVariations: true,
                                          altAxis: true,
                                          padding: 8,
                                          fallbackPlacements: ['bottom'],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              />
                            </Grid>
                            <Grid lg={2} sx={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "end" }}>
                              {(idx != 0) && (
                                <DeleteIcon sx={{ color: "#ff5858" }} onClick={() => addOrRemoveMember(idx)} />)}
                            </Grid>
                          </Grid>))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid></form>
          )}
        </Formik>
      </Box>


      <Dialog onClose={Button} open={open} maxWidth="sm"
        fullWidth>
        {toggleLoader && (<CircularProgress size={120} sx={{ position: "fixed", left: "50%", right: "50%", top: "50%", bottom: "50%", transform: "translate(-50%, -50%)" }} />)}
        <DialogTitle id="customized-dialog-title" >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 0 }}>
            <Typography sx={{ fontSize: '24px', color: '#232360', fontWeight: '500' }}>
              {selectedFolderName}          </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

        </DialogTitle>
        <DialogContent dividers>
          <React.Fragment>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {folderFieldEnabledForUpload && (
                  <CustomField
                    defaultValue={""}
                    saveText={save}
                    cancelEdit={closeSelectedFolderLinkfield}
                    ticketId={selectedFolderName}
                    width={"350px"}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "12px",
                      color: "#3A3A49",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      _permission.tab_ManagePortfolioUploadDocument_Access == true ?
                        setFolderFieldEnabledForUpload(true) : setFolderFieldEnabledForUpload(false)
                    }}
                  >
                    <AttachmentIcon sx={{ color: "#068DF2" }} />
                    Attach a Link
                  </Box>
                </Box>


              </Box>

              <TableContainer >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "385px" }}>Document</TableCell>
                      <TableCell >Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {foldersAttachmentLink.map((item: any) => (
                      <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <p
                            style={{
                              textDecoration: 'underline',
                              color: '#00a1e4',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              window.open(item?.link, '_blank')
                            }}
                          >
                            {/* {item?.link} */}
                            {item?.link?.length > 30
                              ? item?.link?.slice(0, 30 - 1) + "..."
                              : item?.link}
                          </p>
                          {/* <Box>{item?.link} </Box> */}

                        </TableCell>
                        {_permission.tab_ManagePortfolioDeleteDocument_Access && (
                          <TableCell sx={{ cursor: "pointer" }}><DeleteIcon sx={{ color: "#ff5858" }} onClick={(e: any) => {
                            deleteAttachemntByLinkId(item?.id, selectedFolderName)
                            e.stopPropagation();
                          }} /></TableCell>)}
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <TableContainer >
                <Table aria-label="simple table">

                  <TableBody>
                    {oneDriveItemOfFolders.map((row: any, idx: any) => (
                      <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        
                      >
                        <TableCell sx={{ width: "385px" }} component="th" scope="row">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",

                        }}
                      >
                        {iconMap[getFileExtension(row?.name ? row?.name : row[0]?.name)]}
                        <Box sx={{ ml: 1, cursor: "pointer" }}>
                          <p
                            style={{
                              textDecoration: 'underline',
                              color: '#00a1e4',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if(row?.id !== undefined){
                              GetOneDriveFileViewByItemId(row?.id)
                              }
                              else {
                                const fileBlob = new Blob([row], {type: row.type});
                                const fileUrl = URL.createObjectURL(fileBlob);
                                window.open(fileUrl, '_blank');
                              }
                            }}
                          >{row?.name ? row?.name : row[0].name}
                          </p>
                        </Box>
                      
                      </Box></TableCell>
                      {_permission.tab_ManagePortfolioDeleteDocument_Access && (
                      <TableCell>  
                          <DeleteIcon sx={{ color: "#ff5858", cursor: "pointer" }} onClick={() => {
                            handleClickOpenDeleteAlert()
                            setSelectedIdForDelete(row?.id ? row?.id : idx.toString())
                          }} /></TableCell>)}
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
              {_permission.tab_ManagePortfolioUploadDocument_Access && (
                <Box
                  sx={{
                    border: "1px solid #e0e0e0",
                    padding: "16px",
                    textAlign: "center",
                    width: "150px",
                    margin: "20px auto 0 auto",
                    borderRadius: "14px",
                    backgroundColor: "#FBFBFB"
                  }}
                >
                  <input
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.txt,.rtf,.html,.htm"
                    style={{ display: "none" }}
                    id="upload-document"
                    type="file"
                    disabled={toggleLoader}
                    onChange={(e) => {
                      if (e?.target?.files && e.target.files.length > 0) {
                        const selectedFile = e.target.files[0];
                        const validFileTypes = [
                          "application/pdf", 
                          "application/msword", 
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "application/vnd.ms-excel",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          "application/vnd.ms-powerpoint",
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                          "application/vnd.oasis.opendocument.text",
                          "text/plain",
                          "application/rtf",
                          "text/html"
                        ];
                  
                        if (validFileTypes.includes(selectedFile.type)) {
                          const fileURL = URL.createObjectURL(selectedFile);          
                          window.open(fileURL, '_blank');

                         const updatedFiles = [...oneDriveItemOfFolders, selectedFile];
                        setOneDriveItemOfFolders(updatedFiles);

                        } else {
                          toast.warn("Invalid file type. Please upload only document files.");
                        }
                      }
                    }}

                    // onChange={(e) => {
                    //   if (e?.target?.files && e.target.files.length > 0) {
                    //     debugger
                    //     console.log(URL.createObjectURL(e?.target?.files[0]), "selectedFiles dff rfg f fg  ");
                    //     const updatedFiles = [...oneDriveItemOfFolders, e?.target?.files[0]];
                    //     console.log(updatedFiles, "selectedFiles dff rfg f fg  ");
                    //     debugger
                    //     setOneDriveItemOfFolders(updatedFiles)
                    //   }
                    // }}


                  />
                  <label htmlFor="upload-document">
                    <IconButton
                      color="primary"
                      aria-label="upload document"
                      component="span"
                    >
                      <CloudUploadIcon
                        sx={{ fontSize: 40, color: "#03A9F4" }}
                      />
                    </IconButton>
                  </label>
                  <Typography sx={{ fontWeight: "600", fontSize: "14px", color: "#4B4B5A" }} variant="h6">
                    Upload Document
                  </Typography>
                </Box>)}
            </Box>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            // handleClose()
            let bnm = oneDriveItemOfFolders.filter((item: any) => !item?.id)
            UploadDocumentOnOneDriveBasisOfFolderId(bnm);
          }} >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openAlert}
        onClose={setAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseDeleteAlert}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "26px",
            lineHeight: "39px",
            color: "#212121",
          }}
        >
          Delete
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Are you sure you want to delete this ,The item will be permanently deleted. Continue ?
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseDeleteAlert}>
            No
          </Button>
          <Button variant="contained" onClick={DeleteOneDriveItemByItemId}
         >
            Yes
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={uplodDialogAfterAdd}
        onClose={Button}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        {/* <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseDeleteAlert}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "26px",
            lineHeight: "39px",
            color: "#212121",
          }}
        >
          Upload Document
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Do you want to Upload Document for Project ?
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" component={Link}
            to="/project-portfolio">
            No
          </Button>
          <Button variant="contained" component={Link}
            to={`/portfolio-edit/${projectIdAfterAdd}`}
            onClick={() => { setUplodDialogAfterAdd(false); GetOneDriveFolderByProjectId(projectIdAfterAdd); }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PortfolioEdit;
