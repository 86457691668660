import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, TablePagination, Tooltip, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import * as Icons from "../../assests/icons/icons";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/styledComponent/SearchBar";
import { slabgenrationService } from '../../service/SlabgenrationService';
import PayRollStyle from "./PayRollStyle";
import { toast } from 'material-react-toastify';


interface payrollLst {
    id: "",
    slabName: "",
    slabRangeFrom: any,
    slabRangeTo: any,
    slabRange: "",
};


export default function PayRollList() {
    const [payroll, setPayRoll] = useState<payrollLst[]>([]);
    const [currentId, setCurrentId] = React.useState(false);
    const [openAlert, setAlertOpen] = React.useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = React.useState<any>(null);
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);

    };

    

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    useEffect(() => {
        debugger;
        getPayRollDetails(searchValue, 0);
    }, [])

    const triggerSearch = async (newValue: string) => {
        getPayRollDetails(newValue , 0 );

    };

    

    const getPayRollDetails = async (searchValue: any, page: number) => {
        debugger;
        var input = {
            "search": searchValue,
            page: page,
        };
        await slabgenrationService.getAllPayrollSlab(input).then((response: any) => {
            if (response.data.isError) {
            }
            else {
                setPage(currentPage);
                setPayRoll(response?.data);
            }
        });
    };

    const deletePayrollData = async (id: any, e: any) => {
        e.stopPropagation();
        setAlertOpen(true);
        setCurrentId(id);
    };

    const DeletePayroll = async () => {
        setAlertOpen(false);
        try {
            await slabgenrationService.deletePayrollSlab(currentId).then((response: any) => {
                if (response.data.isError) {
                } else {
                    toast.success("Payroll slab deleted successfully.");

                    getPayRollDetails("", currentPage);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

  
    const pageCount = Math.ceil(payroll.length / rowsPerPage);
  

    return (
        <>
            <Box  sx={{ width: '100%', typography: 'body1' }}>


                <Paper elevation={0} sx={{padding: "16px 16px"}}>
                    <Grid    container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={6}>
                            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }} component="h2">
                                Slab List
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                                <Box sx={{
                                    mr: 1, "& .css-17vbkzs-MuiFormControl-root-MuiTextField-root ": {
                                        m: '0'
                                    }
                                }}>
                                    {/* <TextField
                                        label="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                        variant="outlined" margin="normal" fullWidth /> */}
                                    <SearchBar
                                        setSearchValue={setSearchValue}
                                        callBackFunction={triggerSearch}
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    component={Link}
                                    to="/add-slab"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box >
                        <TableContainer  component={Paper} sx={{ mt: 1, maxHeight: 400 }}>
                            <Table sx={{ minWidth: 650, boxShadow: "none" }}  stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S.No</TableCell>
                                        <TableCell>Slab Name</TableCell>
                                        <TableCell align="left">Slab Range</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>


                                <TableBody>

                                    {payroll.length > 0 && payroll.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, idx) => (

                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                {page * rowsPerPage + idx + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.slabName ? item?.slabName : "--"}
                                                </TableCell>
                                                <TableCell align="left">{item?.slabRangeFrom != 0 || item?.slabRangeTo != 0 ? `${item?.slabRangeFrom || 0} - ${item?.slabRangeTo || 0}` : '--'}</TableCell>
                                                <TableCell align="center">
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        spacing={2}
                                                    >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                aria-label="edit"
                                                                component={Link}
                                                                to={`/edit-slab/${item.id}`}
                                                                size="medium"
                                                            >
                                                                <Icons.Edit />
                                                            </IconButton>

                                                        </Tooltip>
                                                        
                                                        <Tooltip title="View">
                                                        <IconButton
                                                                aria-label="view"
                                                                component={Link}
                                                                to={`/view-slab/${item.id}`}
                                                                size="medium"
                                                            >
                                                                <Icons.View />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                size="small"
                                                                onClick={(e) => deletePayrollData(item.id, e)}
                                                            >
                                                                <Icons.Delete />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>

                            </Table>

                            <TablePagination
                                component="div"
                                count={payroll.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={() => `Total: ${payroll?.length}  Page ${page + 1} of ${pageCount}`}

                            />
                        </TableContainer>
                    </Box>
                </Paper>

            </Box>

            <Dialog
                open={openAlert}
                onClose={setAlertOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <IconButton
                    aria-label="delete"
                    sx={PayRollStyle.styleCrossPayroll}
                    onClick={handleCloseAlert}
                >
                    <ClearIcon />
                </IconButton>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={PayRollStyle.deletepopupheading}
                >
                    Delete
                </DialogTitle>
                <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
                    Are you sure you want to delete ?
                </DialogContent>
                <DialogActions
                    sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
                >
                    <Button variant="outlined" onClick={handleCloseAlert}>
                        No
                    </Button>
                    <Button variant="contained" onClick={DeletePayroll}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>

    );
}