
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import theme from '../../theme/theme';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },

}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 24,
    },
    '& .QontoStepIcon-circle': {
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },

  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (

        <CheckCircleIcon className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ['Select campaign settings', 'Create an ad group'];

export default function StepsRoles(props: any) {
  return (
    <Stack sx={{ maxWidth: 500, mx: 'auto', mb: 4 }} spacing={4}>
      <Stepper alternativeLabel activeStep={props.currentStep} connector={<QontoConnector />}>
        {props.steps.map((label: any) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon} sx={{
              '& .MuiStepLabel-label': {
                marginTop: '4px !important',
              },
              '& .Mui-completed': {
                color: theme.palette.primary.main,
              },
            }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

    </Stack>
  );
}



// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';

// const steps = [
//   'Create Role',
//   'Grant Permissions',
// ];

// export default function StepsRoles(props:any) {
//   return (
//     <Box sx={{ maxWidth: 600, ml: 'auto', mr: 'auto', mb: 3 }}>
//       <Stepper activeStep={props.currentStep} alternativeLabel>
//         {props.steps.map((label:any) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// }
