import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Paper from "@mui/material/Paper";
import { Stack, Typography, Autocomplete, TextField, TableRow, TableCell } from '@mui/material';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import { feedbackService } from '../../service/Feedback/EmployeeFeedbackService';
import { useSelector } from 'react-redux';
import theme from '../../theme/theme';
import userAvatar from "../../assests/icons/userAvatar.svg"
export default function EmployeeFeedback() {
  const navigate = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const empId = _authUser.id;
  const userName = _authUser.userName;
  const deptId = _authUser.employeeInfo.departmentId;
  const _permission = useSelector(
    (state: any) => state.UserPermissionApp.UserPermission
  );

  const feedbackData = [{
    feedback: "How would you rank our company's overall satisfaction?",
    feedbackId: 1
  },
  {
    feedback: "How well does the company support work-life balance? ",
    feedbackId: 2
  },
  {
    feedback: "How would you rate the company's commitment to diversity and inclusion?",
    feedbackId: 3
  },
  {
    feedback: "How likely are you to recommend the company as a great place to work to others? ",
    feedbackId: 4
  },
  {
    feedback: "How satisfied are you with the benefits package offered by the company?",
    feedbackId: 5
  },
  {
    feedback: "Rate the company's commitment to corporate social responsibility and sustainability.",
    feedbackId: 6
  },
  {
    feedback: "How satisfied are you with the company's leadership and management?",
    feedbackId: 7
  },
  {
    feedback: "How would you rate the company's approach to employee recognition and rewards? ",
    feedbackId: 8
  }
  ]

  const feedbackDataObj = [{
    ratingValue: "0",
    userFeedbackType: 1
  },
  {
    ratingValue: "0",
    userFeedbackType: 2
  },
  {
    ratingValue: "0",
    userFeedbackType: 3
  },
  {
    ratingValue: "0",
    userFeedbackType: 4
  },
  {
    ratingValue: "0",
    userFeedbackType: 5
  },
  {
    ratingValue: "0",
    userFeedbackType: 6
  },
  {
    ratingValue: "0",
    userFeedbackType: 7
  },
  {
    ratingValue: "0",
    userFeedbackType: 8
  }
  ]

  const [description, setDescription] = useState<any>('');
  const [feedback, setFeedback] = useState<any>(feedbackDataObj);
  const [userFeedback, setUserFeedback] = useState<any>([]);

  const [submittedDescription, setSubmittedDescription] = useState('');
  const [filterSelected, setFilterSelected] = useState(false);
  const [selectedYear, setSelectedYear] = React.useState<any>();

  const handleFeedbackRating = (userFeedbackType: any, ratingValue: any) => {

    const index = feedback.findIndex((item: any) => item.userFeedbackType === userFeedbackType);
    if (index !== -1) {
      const newData = [...feedback];
      newData[index].ratingValue = ratingValue;
      setFeedback(newData);
    } else {
      setFeedback([...feedback, { userFeedbackType, ratingValue }]);
    }
  }
  console.log(feedback, "feedbackfeedbackfeedback")
  const currentDate = new Date();
  const currentMonthNo = (currentDate.getMonth() + 1).toString();

  console.log(userFeedback.some((monthno: any) => monthno.month === currentMonthNo), "userFeedback");
  useEffect(() => {
    if (_permission.page_UserFeedback_Access !== true) {
      navigate("/dashboard");
    }
    setSelectedYear(currentYear);
    getAllUserFeedback({ userId: empId }, currentYear)
  }, []);


  const getAllUserFeedback = async (userId: any, year: any) => {
    let value = {
      userId: empId,
      year: year

    }
    await feedbackService.getAllUserFeedback(value).then((response: any) => {
      if (response.data.isError) {
      }
      else {
        setUserFeedback(response.data)

      }
    })
  }

  const addUserFeedback = async () => {
    try {
      let input = {
        userId: empId,
        departmentId: deptId,
        userFeedback: feedback,
        description: description
      };

      await feedbackService.addFeedback(input).then((response: any) => {
        if (response.data.isError) {

        } else {
          toast.success("Feedback added successfully.");
          setSubmittedDescription(description);
          navigate("/EmployeeFeedback");
          getAllUserFeedback({ userId: empId }, selectedYear);
          setFeedback([]);
          setDescription('');
        }
      });
    } catch (error: any) {
      if (error.data && error.data.Error.length > 0) {
        toast.error(error.data.Error[0]);
      }
      console.error("Error occurred while adding feedback:", error);
    }
  };


  // const addUserFeedback = async () => {
  //   try {
  //     let input = {
  //       userId: empId,
  //       departmentId: deptId,
  //       userFeedback: feedback,
  //       description: description
  //     }

  //     await feedbackService.addFeedback(input).then((response: any) => {

  //       if (response.data.isError) {
  //       } else {

  //         toast.success("Feedback added successfully.");
  //         setSubmittedDescription(description);     
  //         navigate("/EmployeeFeedback");
  //         getAllUserFeedback({ userId: empId }, selectedYear)
  //         setFeedback([]);
  //       setDescription('');
  //       }
  //     })
  //   }

  //   catch (error: any) {
  //     if (error.data && error.data.Error.length > 0) {
  //       toast.error(error.data.Error[0]);
  //     }
  //     console.error("Error occur while adding feedback:", error);

  //   }
  // };

  let currentYear = new Date().getFullYear();
  var currentYearString = currentYear;
  const Years = [];
  for (let i = 0; i < 4; i++) {
    Years.push({
      id: currentYear - i,
      label: (currentYear - i)
    });
  }


  return (
    <Paper elevation={0} sx={{ p: 3, pb: 0 }}>

      <Box sx={{ flexGrow: 1 }}>


        <Grid container spacing={2}>



          {
            userFeedback?.length > 0 && (
              <>

                <Grid xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
                      User Feedback
                    </Typography>
                    <Autocomplete
                      value={Years?.find((option) => option.id == selectedYear) ?? null}
                      id="Years"
                      options={Years}
                      getOptionLabel={(option: any) => option.label}
                      onChange={async (event, value) => {
                        setSelectedYear(value?.label ?? currentYearString);
                        setFilterSelected(true);
                        await getAllUserFeedback('', value?.label ?? currentYearString);
                      }}
                      sx={{ width: 150 }}
                      renderInput={(params) => <TextField {...params} value={selectedYear} />}
                    />
                  </Box>


                  <Box sx={{ height: "500px", overflowY: "auto" }}>
                    {userFeedback.map((dataItem: any, index: number) => (
                      <Box sx={{ borderTop: "1px solid #f1f1f1" }}>
                        <React.Fragment key={index}>
                          {dataItem.outputList.map((outputItem: any, i: number) => (
                            <React.Fragment key={i}>
                              {outputItem.userFeedback.map((feedback: any, j: number) => (
                                <Stack direction="row" justifyContent="space-between" paddingTop={1} paddingBottom={1} spacing={3} key={j}>
                                  <Box>
                                    {feedbackData.map((item) => (
                                      <Typography> {item.feedbackId == feedback.userFeedbackType ? item.feedback : ""}</Typography>
                                    ))}
                                  </Box>
                                  <Box>
                                    <Rating
                                      name="rating"
                                      value={feedback.ratingValue}
                                      sx={{ marginRight: "18px", display: "flex", gap: "4px" }}
                                    // onChange={(event, value) => handleFeedbackRating(index, value)}
                                    />
                                  </Box>
                                </Stack>
                              ))}
                              <TableRow sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid #f1f1f1", paddingBottom: "20px" }} key={i}>
                                <TableCell sx={{ border: 'none', width: '100%' }}>
                                  <Typography mb={3}>Suggestions or Comments</Typography>
                                  <Box>
                                    <Stack direction="row" justifyContent="start" alignItems="center" sx={{ m: '0 0 16px 0' }}>
                                      <Stack justifyContent="center" alignItems="center" sx={{ width: '38px', height: '38px', borderRadius: '50%', mr: '16px' }}>
                                        <img src={userAvatar} alt="avatar" />
                                      </Stack>
                                      <Box>
                                        <Typography sx={{ color: theme.palette.primary.main }}>
                                          {userName}
                                        </Typography>
                                        <Typography>

                                          <Typography>
                                            {new Date(outputItem.createdDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
                                          </Typography>

                                        </Typography>
                                      </Box>
                                    </Stack>
                                    <Typography>{outputItem.description}</Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </>
            )}

          {_permission.tab_AddUserFeedback_Access && selectedYear === currentYearString && !userFeedback.some((monthno: any) => monthno.month === currentMonthNo) && (
            <>
              <Grid xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
                  Add Your Feedback
                </Typography>
                {feedbackData?.map((item, index) => (
                  <Stack direction="row" justifyContent={'space-between'} paddingTop={1} paddingBottom={1} spacing={3} key={index}>
                    <Typography>{item.feedback}</Typography>
                    <Box>
                      <Rating
                        name="rating"
                        // value={feedback.map((item1:any) => item1.userFeedbackType === item.feedbackId ?parseInt(item1.ratingValue):0)}
                        value={feedback.find((item1: any) => item1.userFeedbackType === item.feedbackId)?.ratingValue || 0}
                        sx={{ marginRight: "18px", display: "flex", gap: "4px" }}
                        onChange={(e: any) => {
                          let value = e.target.value
                          handleFeedbackRating(item.feedbackId, value)

                        }}
                      />
                    </Box>
                  </Stack>
                ))}

                <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TableCell sx={{ border: 'none', width: '100%' }}>
                    <Typography>Suggestions or Comments</Typography>
                    <Box>
                      <TextField label="Type something" variant="outlined" sx={{ width: "100%", marginBlock: "20px" }} value={description} id="3" onChange={(e: any) => {
                        setDescription(e.target.value)
                      }} />
                      {_permission.tab_AddUserFeedback_Access && (
                        <Button variant="contained" onClick={addUserFeedback}>Submit</Button>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </Grid>
            </>

          )}
          {(selectedYear !== currentYearString && userFeedback?.length == 0 && _permission.tab_AddUserFeedback_Access) &&
            (
              <Box sx={{width: "100%"}}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px", borderBottom: "1px solid #f1f1f1", paddingBottom: "30px" }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }} component="h2">
                    User Feedback
                  </Typography>
                  <Autocomplete
                    value={Years?.find((option) => option.id == selectedYear) ?? null}
                    id="Years"
                    options={Years}
                    getOptionLabel={(option: any) => option.label}
                    onChange={async (event, value) => {
                      setSelectedYear(value?.label ?? currentYearString);
                      setFilterSelected(true);
                      await getAllUserFeedback('', value?.label ?? currentYearString);
                    }}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} value={selectedYear} />}
                  />
                </Box>
                <Typography sx={{padding: "20px", textAlign: "center", fontWeight: "700"}}>Record not found</Typography>
              </Box>

            )}
        </Grid>
      </Box>
    </Paper>
  );
}
