import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
  Typography,
  Tab,
  Tabs,
  Box,
  Paper,
  TableSortLabel,
  styled,
  Chip,
  ChipProps,
  TablePagination,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import theme from "../../theme/theme";
import Avatar from "@mui/material/Avatar";
import * as Icons from "../../assests/icons/icons";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import AppConstants from "../../config/AppConstants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { leaveService } from "../../service/Leave/LeaveService";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { visuallyHidden } from "@mui/utils";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { date } from "yup";
import moment from "moment";
import * as Yup from "yup";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ILeaveDetail } from "../../Interfaces/ILeaveDetail";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";


const styles: any = {
  cardLeaves: {
    borderRadius: "10px",
    padding: "22px 18px",
  },
  titleLeave: {
    color: "#1F944E",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    marginBottom: "25px",
  },
  annualLeaves: {
    color: "#1F944E",
  },
  sickLeaves: {
    color: "#B38906",
  },
  casualLeaves: {
    color: "#16B5BF",
  },
  compOff: {
    color: "#1DA7FF",
  },
  otherLeaves: {
    color: "#74C225",
  },
  total: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: " 18px",
  },
  values: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: " #424242",
  },
  taken: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: " 18px",
  },
  takenvalues: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: " #757575",
  },
  applyLeave: {
    backgroundColor: "#F0F9FF",
    borderRadius: "10px",
    padding: "22px 18px",
    maxWidth: "412px",
    marginLeft: "auto",
  },
  applyLeaveHeading: {
    color: "#232360",
    fontWeight: 500,
    fontSize: " 26px",
  },
  selectType: {
    backgroundColor: "#fff",
    marginTop: "10px",
  },
  selectlabel: {
    fontWeight: 400,
    fontSize: " 14px",
    lineHeight: "21px",
    marginLeft: 0,
    marginBottom: "6px",
    color: "#212121",
  },
  spaceTop: {
    marginTop: "24px",
  },
  AnnualLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: "linear-gradient(90deg, #2DD36F 50%, #FFBB0B 78.73%)",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  SickLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: " linear-gradient(90deg, #B38906 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  CasualLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: " linear-gradient(90deg, #15B5BF 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  CompLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: "linear-gradient(90deg, #1DA7FF 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
  },
  OtherLeavesProgress: {
    "&.MuiLinearProgress-root": {
      height: "6px",
      backgroundColor: "#D9D9D9",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        background: "linear-gradient(90deg, #74C225 50%, #FFBB0B 78.73%);",
        borderRadius: " 20px",
        backgroundColor: "unset",
      },
    },
    labelLeft: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: " 21px",
      /* Primary Color/Primary_dark shade */
      color: theme.palette.primary.dark,
      width: "150px",
      minWidth: "130px",
    },
    valueRight: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: " 21px",
      /* Primary Color/Primary_dark shade */
      color: " #424242",
      flexGrow: 1,
    },
  },
};

interface Data {
  employeeName: string;
  type: string;
  durationType: string;
  duration: string;
  startdate: string;
  enddate: string;
  submittedOn: string;
  approvedOn: string;
  reason: string;
  id: string;
  status: string;
  srNo: string;
  ispast: boolean;
  approvedBy: string;
  updateEndDate: string;
  updateStartDate: string;
  userId: string;
}

interface updateleave {
  id: string;
  newEnddate: any;
  newStartdate: any;
  reason: string;
  compOffFor: any
}

export default function RequestUpdate(props: any) {
  let obj: updateleave = {
    id: "",
    newStartdate: "",
    newEnddate: "",
    reason: "",
    compOffFor: ""
  };
  const { leaveId, handleCloseRU, check } = props;
  const [formValues, setFormValues] = React.useState<updateleave>(obj);
  const [leavedetail, setLeaves] = useState<ILeaveDetail>();
  const [startDate, setStartDate] = React.useState("");
  const [newEndDate, setNewEndDate] = React.useState(formValues.newStartdate);
  const [newStartDate, setNewStartDate] = React.useState(formValues.newEnddate);
  const [endDate, setEndDate] = React.useState("");
  const [reason, setReason] = React.useState(formValues?.reason);
  const [errorMessage, setErrorMessage] = useState(false);
  const [leaveHook, setLeaveHook] = useState<any>(leavedetail);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const handleChange = (name: any, value: any, object?: any) => {
    if (object) {
      setReason(value);

      setLeaveHook((prevState: any) => ({
        ...prevState,
        [object]: {
          ...prevState[object],
          [name]: value,
        },
      }));
    }
  };

  const getLeaveDetailsById = async (id: any) => {
    try {
      await leaveService.getLeaveDetailsById(id).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          setLeaves(response.data);
          setReason(response.data?.reason);
          setLeaveHook(response.data);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const updateRequestDetails = async () => {
    try {
      let obj: updateleave = {
        id: leaveId,
        newStartdate: newStartDate,
        newEnddate: leavedetail?.type == "Comp Off Leave" ? newStartDate : newEndDate,
        reason: reason,
        compOffFor: leavedetail?.type == "Comp Off Leave" ? newEndDate : ""
      };
      await leaveService
        .updateRequestDetails(obj)
        .then(async (response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            toast.success("Leave updated successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            handleCloseRU();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        debugger
        if (leavedetail?.type === "Comp Off Leave" && err == "Leave alredy exist between start date and end date.") {
          toast.warning("Leave Already exist for this day.")
        }
        else {
          toast.warning(err);
        }
      });
    }
  };

  React.useEffect(() => {
    if (leaveId != "") {
      getLeaveDetailsById(leaveId);
    }
    setSubmit(false)
  }, []);
  const fieldValidationSchema = Yup.object().shape({
  //reason: Yup.string().required('Reason is required'),
    reason: Yup.string()
      .required("Reason is required")
      .max(1000, "Reason must be at most 100 words"),
  });

  return (
    <Formik
      initialValues={{
        id: leavedetail?.id,
        newEndDate:leavedetail?.type === "Marriage Leave"?newEndDate: leavedetail?.newEndDate,
        newStartDate: leavedetail?.newStartDate,
        reason: leavedetail?.reason,
      }}
      enableReinitialize
      validationSchema={fieldValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          debugger
          setErrorMessage(true);
          if (leavedetail?.type != "Comp Off Leave" && values.newEndDate &&
            values.newStartDate &&
            values.newEndDate < values.newStartDate) {
            toast.warning("New Start date must be less than New End Date", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            return false;
          }
          else if (!values?.newEndDate || !values?.newStartDate) {
            setSubmit(true);
          }
          else {

            

            await updateRequestDetails();
          }

        } catch (err: any) {
          console.error(err);
          err = JSON.parse(JSON.stringify(err));
          if (err.status == 400) {
            {
              err.data.Error.map((error: any, idx: any) =>
                setErrorMessage(error)
              );
            }
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent sx={{ mt: 3 }}>
            <TableContainer component={Paper} elevation={0}>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>Name</Box>
                <Box sx={[styles.valueRight]}>{leavedetail?.employeeName}</Box>
              </Stack>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                  Leave Type
                </Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  {leavedetail?.type}
                </Box>
              </Stack>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                  {leavedetail?.type == "Comp Off Leave" && leavedetail?.updateStartDate== null ? "Leave Date"
                  : leavedetail?.type == "Comp Off Leave" && leavedetail?.updateStartDate!= null ? "Previous Leave Date" 
                  : "Previous Start Date"}
                </Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  {`${moment(leavedetail?.startdate)
                    .utc()
                    .format("DD MMM, YYYY")}`}
                </Box>
              </Stack>
              {(leavedetail?.type == "Comp Off Leave" && leavedetail?.updateStartDate != null) && (
                <Stack direction="row" sx={{ mb: 1 }}>
                  <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                    Leave date
                  </Box>
                  <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                    {`${moment(leavedetail?.updateStartDate)
                      .utc()
                      .format("DD MMM, YYYY")}`}
                  </Box>
                </Stack>)}
              {leavedetail?.type != "Comp Off Leave" && (
                <Stack direction="row" sx={{ mb: 1 }}>
                  <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                    Previous End Date
                  </Box>
                  <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                    {`${moment(leavedetail?.enddate)
                      .utc()
                      .format("DD MMM, YYYY")}`}
                  </Box>
                </Stack>)}
              {leavedetail?.type == "Comp Off Leave" && (
                <Stack direction="row" sx={{ mb: 1 }}>
                  <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                    Worked On
                  </Box>
                  <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                    {`${moment(leavedetail?.compOffFor).format("DD MMM, YYYY")}`}
                  </Box>
                </Stack>)}
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>{leavedetail?.type == "Comp Off Leave" ? "Leave Date" : "From"}</Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // maxDate={new Date()}
                      // disablePast
                      borderRadius="semi-square"
                      minDate={moment().subtract(0, "years").toDate()}
                      maxDate={moment().add(1, "years").toDate()}
                      //label="Marriage Anniversary"
                      // openTo="year"
                      // views={["year", "month", "day"]}
                      value={newStartDate}
                      onChange={(newValue: any) => {
                        debugger
                        setSubmit(false);
                        let abc = getDate(newValue)
                        values.newStartDate = abc;
                        if (leavedetail != null) {
                          leavedetail.newStartDate = abc ?? "";
                        }
                        if (leavedetail?.type === "Marriage Leave") {
                          const updateEndDate = moment(newValue).add(9, "days").toDate();
                          let xyz = getDate(updateEndDate)
                          values.newEndDate = xyz;
                          setNewEndDate(xyz);

                          console.log("xyz ===", xyz )
                        }
                        setNewStartDate(abc);
                       
                      }}
                      //error={ isSubmit && !values.newStartDate ? "Start Date is required" : ""}
                      error={
                        (isSubmit && (leavedetail?.type === "Comp Off Leave" || !values.newStartDate)) ? "Leave date is required" :
                          isSubmit && !values.newStartDate ? "Start Date is required" :
                            ""
                      }
                    // renderInput={(params: any) => (
                    //   <TextField
                    //     {...params}
                    //     id="newStartDate"
                    //     name="newEndDate"
                    //     onKeyDown={(e: any) => {
                    //       e.preventDefault();
                    //     }}
                    //     onChange={(newValue: any) => {
                    //       values.newStartDate = newValue;
                    //       setNewStartDate(newValue);
                    //       setErrorMessage(true);

                    //     }}
                    //     {...params}
                    //     error={errorMessage && !values.newStartDate}
                    //     helperText={
                    //       isSubmit && !values.newStartDate
                    //         ? "New Start Date is  Required."
                    //         : ""
                    //     }
                    //     sx={{
                    //       "& .MuiSvgIcon-root": {
                    //         fill: theme.palette.primary.main,
                    //       },
                    //     }}
                    //   />
                    // )}
                    />
                  </LocalizationProvider>
                </Box>
              </Stack>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>{leavedetail?.type == "Comp Off Leave" ? "Worked On" : "Till"}</Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      borderRadius="semi-square"
                      minDate={leavedetail?.type == "Comp Off Leave" ? moment().startOf('year').toDate() : moment().subtract(0, "years").toDate()}
                      maxDate={leavedetail?.type == "Comp Off Leave" ? moment().subtract(1, 'days').toDate() : moment().add(1, "years").toDate()}
                      value={newEndDate}
                    
                      disabled={leavedetail?.type === "Marriage Leave"}

                 
                      onChange={(newValue: any) => {
                        values.newEndDate = getDate(newValue);
                        setNewEndDate(newValue);
                        setErrorMessage(false);
                        setSubmit(false);
                      }}

                      // onChange={(newValue: any) => {
                      //   debugger
                      //   let abc = getDate(newValue);
                      //   values.newEndDate = abc;
                      //   if (leavedetail != null) {
                      //     leavedetail.newEndDate = abc ?? "";
                      //   }
                      //   setNewEndDate(abc);
                      //   setErrorMessage(false);
                      //   setSubmit(false);
                      // }}
                    

                      error={
                        // (isSubmit && (leavedetail?.type === "Comp Off Leave" || !values.newEndDate)) ? "Worked on is required" :
                        (isSubmit && (leavedetail?.type === "Comp Off Leave")) ? "Worked on is required" :
                          isSubmit && !values.newEndDate ? "End Date is required" :
                            ""
                      }
                    // renderInput={(params: any) => (
                    //   <TextField
                    //     {...params}
                    //     id="newEndDate"
                    //     name="newEndDate"
                    //     error={errorMessage && !values.newEndDate}
                    //     helperText={
                    //       isSubmit && !values.newEndDate
                    //         ? "New End Date is  Required."
                    //         : ""
                    //     }
                    //     sx={{
                    //       "& .MuiSvgIcon-root": {
                    //         fill: theme.palette.primary.main,
                    //       },
                    //     }}
                    //   />
                    // )}
                    />
                  </LocalizationProvider>
                </Box>
              </Stack>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>Reason</Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  <TextField
                    id="outlined-basic1"
                    fullWidth
                    variant="outlined"
                    name="reason"
                    required
                    value={leavedetail?.reason}
                    error={Boolean(
                      getIn(touched, "reason") &&
                      getIn(errors, "reason")
                    )}
                    helperText={
                      getIn(touched, "reason") &&
                      getIn(errors, "reason")
                    }
                    onChange={(e) => {
                      values.reason = e.currentTarget.value;
                      if (leavedetail != null) {
                        leavedetail.reason = e.target.value ?? "";
                      }
                      setReason(e.currentTarget.value);
                      setErrorMessage(true);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        minHeight: "50px",
                        backgroundColor: "#fff",
                        lineHeight: "1.5",
                      },
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#BDBDBD", textAlign: "right" }}
                  >
                    maximum 100 words
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>
                  Applied On
                </Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  {`${moment(leavedetail?.submittedOn)
                    .utc()
                    .format("DD MMM, YYYY")}`}
                </Box>
              </Stack>

              {/* <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Box sx={[styles.OtherLeavesProgress.labelLeft]}>Action</Box>
                <Box sx={[styles.OtherLeavesProgress.valueRight]}>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      disabled
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={[styles.selectType]}
                    >
                      <MenuItem selected>Approved</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack> */}
            </TableContainer>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleCloseRU} variant="outlined">
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                setErrorMessage(true);
                setSubmit(!values?.newEndDate || !values.newStartDate);
              }}
              type="submit"
              disabled={buttonDisable}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
}

