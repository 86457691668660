
import React, { useEffect } from "react";
import { Box, Paper, } from "@mui/material";
import Gala1 from "../../assests/images/Gala1.png";
import Gala2 from "../../assests/images/Gala2 .png";
import Gala3 from "../../assests/images/Gala3.png";
import partyone from "../../assests/images/partyone.png";
import DjSameer from "../../assests/images/DjSameer.png";

export default function GalaEventImg() {
    useEffect(() => {

    }, []);

    return (
        <>
            <Paper elevation={0} sx={{ p: 3 }}>
               <Box sx={{ textAlign: 'center', borderBottom: '1px solid #EEE', paddingY: '16px', width: '700px', mx: 'auto' }}>
                    <img style={{ maxWidth: '100%', height: 'auto' }} src={DjSameer} alt="" />
                </Box>
                <Box sx={{ textAlign: 'center', borderBottom: '1px solid #EEE', paddingY: '16px', width: '700px', mx: 'auto' }}>
                    <img style={{ maxWidth: '100%', height: 'auto' }} src={partyone} alt="" />
                </Box>
                <Box sx={{ textAlign: 'center', borderBottom: '1px solid #EEE', paddingY: '16px', width: '700px', mx: 'auto' }}>
                    <img style={{ maxWidth: '100%', height: 'auto' }} src={Gala1} alt="" />
                </Box>
                <Box sx={{ textAlign: 'center', borderBottom: '1px solid #EEE', paddingY: '16px', width: '700px', mx: 'auto' }}>
                    <img style={{ maxWidth: '100%', height: 'auto' }} src={Gala2} alt="" />
                </Box>
                <Box sx={{ textAlign: 'center', borderBottom: '1px solid #EEE', paddingY: '16px', width: '700px', mx: 'auto' }}>
                    <img style={{ maxWidth: '100%', height: 'auto' }} src={Gala3} alt="" />
                </Box>
            </Paper>
        </>

    )
}
