import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, AvatarGroup, Box, Breadcrumbs, Button, Chip, Grid, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import { InputAdornment, Popper, Select, TextField } from "@material-ui/core";
import { Link, useNavigate } from 'react-router-dom';
import * as Icons from "../assests/icons/icons";
import SearchIcon from '@mui/icons-material/Search';
import theme from '../theme/theme';
import { commonFunctions } from '../service/common-services/common-functions';
import { deepOrange } from '@mui/material/colors';
const TicketLabel = styled(Chip)({
  border: "none",
  background: "none",
  padding: 0,
  color: theme.palette.primary.main,
  "& .MuiChip-label": {
    fontWeight: 500,
    fontSize: "15px",
  },
});

const styles: any = {
  userAvatar: {
    justifyContent: "flex-end",
    ml: 'auto',
    "& .MuiAvatar-root": {
      textTransform: "Uppercase",
      // fontFamily: "sans-serif",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text,
      borderColor: "#fff",
      fontWeight: 500,
      width: "32px",
      height: "32px",
      cursor: "pointer",
      fontSize: 13,
      lineHeight: " 16px",
      ml: 'auto'
    },
  },
}
interface ListComponentProps {
  setIsListOpen: any,
  setIssueList: [],
  selectedProjectid: any,
  setSearchGlobalHook: any;
  selectedSprintId: any;
}
const ListComponent: React.FC<ListComponentProps> = ({ setIsListOpen, setIssueList, selectedProjectid, setSearchGlobalHook, selectedSprintId }) => {
  console.log(selectedSprintId, "selectedSprintId")
  const navigation = useNavigate();
  const handleClearClick = () => {
    setIsListOpen(false);
    setSearchGlobalHook("");
  };
  const handleSearchClick = () => {
    setIsListOpen(true);
    navigation(`/advancesearch?pId=${selectedProjectid}&sID=${selectedSprintId}`);
  };
  return (
    <Box component={Paper} sx={{
      position: 'absolute', top: '50px', right: '0', zIndex: '9999', maxHeight: '300px', maxWidth: '600px',
    }} >
      <Stack justifyContent={'end'}>
        <IconButton
          aria-label="delete"
          onClick={handleClearClick}
          sx={{
            marginLeft: 'auto',
            // "& .css-i4bv87-MuiSvgIcon-root":{
            //   width:''
            // } 
          }}
        >
          <ClearIcon />
        </IconButton>
      </Stack>
      <TableContainer component={Paper} sx={{ maxHeight: '260px', overflow: 'scroll' }}>
        <Table sx={{ minWidth: 550, }} size="small" aria-label="a dense table" >
          <TableHead>
          </TableHead>
          <TableBody>
            {setIssueList && setIssueList.length > 0 && setIssueList.map((item: any) => (
              <Paper
                key={item.id}
                component="li"
                variant="outlined"
                sx={{
                  p: 1,
                  listStyleType: 'none',
                  "&:hover": {
                    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
                  },
                }}
              >

                <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
                  <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} spacing={1}>
                    <Box component={Link}
                      to={`/issue-detail/${item?.id}`}
                      target="_blank"
                      sx={{ textDecoration: "none", color: '#1da7ff' }}>
                      {item.issueType === "Bug" && <Icons.Bug />}
                      {item.issueType === "Story" && <Icons.Story />}
                      {item.issueType === "Epic" && <Icons.Epic />}
                      {item.issueType === "Task" && <Icons.Task />}
                      &nbsp; {item?.ticketNumber}
                    </Box>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                      <Typography key="3" color="text.primary">
                        {item.summary?.length > 52
                          ? item.summary.slice(0, 52) + "..."
                          : item.summary}
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                  
                  {/* <Stack>{item?.status}</Stack> */}

                  <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                  <Stack>{item?.status}</Stack>
                    <div>
                      {item.priority === "Highest" && (
                        <TicketLabel
                          icon={<Icons.Highest />}
                          label={"Highest"}
                        />
                      )}
                      {item.priority === "High" && (
                        <TicketLabel
                          icon={<Icons.High />}
                          label={"High"}
                        />
                      )}
                      {item.priority === "Medium" && (
                        <TicketLabel
                          icon={<Icons.Medium />}
                          label={item?.storyPoint}
                        />
                      )}
                      {item.priority === "Low" && (
                        <TicketLabel
                          icon={<Icons.Low />}
                          label={item?.storyPoint}
                        />
                      )}
                      {item.priority === "Lowest" && (
                        <TicketLabel
                          icon={<Icons.Lowest />}
                          label={item?.storyPoint}
                        />
                      )}
                    </div>

                    <AvatarGroup sx={[styles.userAvatar]}>

                      <Tooltip
                        title={
                          item?.assigneeUserName ?? "Un-Assigned"
                        }
                      >
                        <Avatar
                          {...commonFunctions.stringAvatar(item?.assigneeUserName
                          )}
                          // sx={[styles.reporter]}
                          sx={{ bgcolor: deepOrange[500], margin: "0 28px 0 0" }}
                          src={`${process.env.REACT_APP_SERVER_URL}${item?.assigneeUserName}`}
                        /></Tooltip></AvatarGroup>
                  </Stack>
                </Stack>
              </Paper>
            ))}
            <TableRow>
              <TableCell colSpan={3}>
                <Box sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: '6px'
                  },
                  "& .MuiFormControl-root": {
                    width: '100%',
                  }
                }} >
                  <TextField
                    placeholder="Advanced Search"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)', }} />
                        </InputAdornment>
                      ),
                      onClick: handleSearchClick,
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleSearchClick();
                      }
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ListComponent;