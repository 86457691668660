import { http } from "./http-common";
class ReserveServices {
    getReserveDetails = async (leave: any): Promise<any> => {
        return await http.post<any>("/v1/Reports/ReserveBridageReport", leave);
    };
    getBillingResourceReport = async (DepartmentId: any): Promise<any> => {
        return await http.get<any>(`/v1/Project/GetResourceOccupancyIndex?DepartmentId=${DepartmentId}`);
    
    };
    
}
export const reserveServices = new ReserveServices ();