import { http } from "./http-common";
class NoticeService {
    getNotice = async (achieve: any): Promise<any> => {
        return await http.post<any>("/v1/NoticeBoard/GetAllNotice", achieve);
    };
    getNoticeById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/NoticeBoard/SearchNoticebyId?id=${id}`);
    };
    createNotice = async (achieve: any): Promise<any> => {
        return await http.post<any>("/v1/NoticeBoard/AddNotice", achieve);
    };

    updateNotice = async (achieve: any): Promise<any> => {
        return await http.put<any>("/v1/NoticeBoard/EditNotice", achieve);
    };

    deleteNotice = async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/NoticeBoard/DeleteNoticeById?id=${id}`);
    }
    getNoticeViewId = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/NoticeBoard/SearchNoticebyId?id=${id}`);
    }
   
    getSearch = async (userse : any): Promise<any> => {
        return await http.post<any>("/v1/NoticeBoard/GetAllNotice", userse);
    }; 
}
export const noticeService = new NoticeService();