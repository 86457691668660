export const typeOfBugenum = [

    {
      value: 1,
      label: "Functional",
    },
    {
      value: 2,
      label: "UI",
    },
    {
      value: 3,
      label: "Validation",
    },

  ];
  export const platformEnum = [

    {
      value: 1,
      label: "Web",
    },
    {
      value: 2,
      label: "Android ",
    },
    {
      value: 3,
      label: "iOS",
    },
    {
      value: 4,
      label: "MAC",
    },
  ];