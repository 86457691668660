import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { Formik } from "formik";
import { Autocomplete, Typography } from "@mui/material";
import { styled } from "@material-ui/core";
import { roleManagementServive } from "../../service/role-management-service";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import StepsRoles from "./StepsRoles";
import { toast } from 'material-react-toastify';
import { useSelector } from "react-redux";
import InputAdornment from '@mui/material/InputAdornment';
import { eTypeOfUser } from "../../Enum/eTypeOfUser";

// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface Role {
  id: number;
  name: string;
}
const steps = [
  'Create Role',
  'Grant Permissions',
];
export default function AddRole() {
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [users, setUsers] = useState<Role[]>([]);
  const { id } = useParams();
  const [selected, setSelected] = useState("");
  const [role, setRole] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [formikName, setFormikName] = useState("");
  const [formikId, setFormikId] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    if (_authUser.typeOfUser != eTypeOfUser.Admin) {
      navigate("/dashboard");
    }
    setActiveStep(0);
    getRoleDetails();
  }, []);

  const _handleErrorInputChange = (event: any) => {
    setSelected(event.target.value);
  };

  const getRoleDetails = async () => {
    let roleDataArr: any = [];
    await roleManagementServive.getRole().then((response: any) => {
      roleDataArr.push(response);
      if (response.data.isError) {
      } else {
        setUsers(response?.data);
        setRole(response?.data);
      }
    });
   
  };

  const addRole = async (values: any) => {
    if (visible && selected == "") {
      toast.warning('Please Select existing role, which you want to copy');
      return false;
    }
    await roleManagementServive.createRole(values).then((response: any) => {
      if (response.data.isError) {
      } else {
        if (selected != "") {
          copyRoleManagementDetails(response.data);
        }
        setActiveStep(1);
        toast.success('Role Created Successfully');
        navigate("/role-management/update-permission/" + response.data);
      }
    }) .catch((error) => {
      if (error.data.Error.length > 0)
      
        toast.warning(error.data.Error[0], {
          onOpen: () => setButtonDisable(true),
          
          onClose: () => setButtonDisable(false),
        });

      return error;
    });
  };

  const copyRoleManagementDetails = async (FromTo: any) => {

    var req = {
      copyFromRoleId: selected,
      copyToRoleId: FromTo,
    };

    await roleManagementServive.copyFromToRole(req).then((response: any) => {
      if (response.data.isError) {
      } else navigate("/role-management/update-permission/" + FromTo);
    });
  };

  <Paper elevation={0} sx={{ p: 3, height: "calc(100vh - 142px )" }}></Paper>;

  return (
    <>
      {/* <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>

        <Typography color="text.primary">Role Management</Typography>
      </Breadcrumbs> */}
      {/* {JSON.stringify(selected, null, 2)}
      {JSON.stringify(formikId, null, 2)} */}

      <Paper elevation={0} sx={{ p: 3 }}>
      <Typography variant="h6" component="h2" sx={{ fontWeight: 400, width:'30%',mb:"3px", ml:"5px" }}>
            {"Add Role"}
          </Typography>        <Grid item xs={12} sx={{ mb: 5 , mt:5}}>
          <StepsRoles currentStep={0} steps={steps} />
        </Grid>

        <Formik
          initialValues={{
            id: formikId,
            name: formikName ,
          }}
          // initialValues1={{
          //   copyFromRoleId: selected,
          //   copyToRoleId: formikId,
          // }}
          enableReinitialize
          validationSchema={Yup.object().shape({
             name: Yup.string().required("Name is required")
          })}
        //   validationSchema={Yup.object().shape({
        //     name: (!visible ? Yup.string()
        //      .required("Name is required")
        //      : Yup.string()),
        //  })}
          onSubmit={async (values) => {
            try {
              await addRole(values);
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >

                <Grid item xs={5}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={
                      touched.name && errors.name}
                      id="outlined-basic"
                    placeholder="Enter Role Name"
                    //   label="Enter Role Name"
                    variant="outlined"
                    margin="normal"
                    name="name"
                    // onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name.replace(/\s{2,}$/g, "").trimStart()}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                    //       <Icons.User />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                  <FormGroup sx={{display: "inline-block"}}>
                    <FormControlLabel
                      control={<Checkbox onClick={() => setVisible(!visible)} />}
                      label="Copy from existing role"
                    />
                  </FormGroup>

                  <Box sx={{ maxWidth: 200, mt: 3 }}>
                    {visible && (
                      <Autocomplete
                        value={
                          role?.find(
                            (option: any) => option?.id == selected
                          ) ?? null
                        }
                        // fullWidth
                        sx={{ ml: "15px" }}
                        id="outlined-basic"
                        options={role}
                        getOptionLabel={(option) => option.name}
                        onChange={async (event, value) => {
                          values.id = value?.id ?? "";
                          setSelected(value?.id ?? "");
                        }}
                        componentsProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'flip',
                                enabled: true,
                                options: {
                                  altBoundary: true,
                                  flipVariations: true,
                                  altAxis: true,
                                  padding: 8,
                                  fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                },
                              },
                            ],
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic-values.name"
                            name="element.name"
                            variant="outlined"
                            label ="Select Role"
                            value={values.id}
                            fullWidth
                            sx={{ minWidth: 220 }}
                          />
                        )}
                      />
                    )}
                  </Box>
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3 }}
                      disabled={buttonDisable}
                    >
                      Next
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
