import React, {
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as Icons from "../../assests/icons/icons";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import EpicLinkbtn from "../../components/styledComponent/EpicLinkbtn";
import {
  Paper,
  Tooltip,
  Modal,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import MenuItem from "@mui/material/MenuItem";
import theme from "../../theme/theme";
import { DragDropContext } from "react-beautiful-dnd";
import { backlogService } from "../../service/Backlog-services";
import { commonFunctions } from "../../service/common-services/common-functions";
import { issueManagementService } from "../../service/issues-management-service";
import { toast } from "material-react-toastify";
import NotFound from "../../components/styledComponent/NotFound";
import { eSprintStatus } from "../../Enum/eSprint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


const Loader = {
  position: "fixed",
  width: " 100%",
  height: " 100%",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.dark,
  zIndex: 9999,
  marginRight: "-50px",
};


const removeFromList = (list: any, index: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list: any, index: any, element: any) => {
  const result = Array.from(list || []);
  result.splice(index, 0, element);
  return result;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const ItemTicket = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 500,
  fontSize: "14px",

  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));

const TicketLabel = styled(Chip)({
  border: "none",
  background: "none",
  padding: 0,
  color: theme.palette.primary.main,
  "& .MuiChip-label": {
    fontWeight: 500,
    fontSize: "15px",
  },
});


interface ChildProps {
  getAllUserListByProjectId: Function;
  TicketInfo: Function;
  TicketData: any;
  spritList: any;
  projectId: any;
  projectType: any;
  sprintId: any;
  searchTicket: any;
  userId: any;
  kanboardstatus: boolean;
  projectWiseAssigneeUsers: any;
  Isunassignee: boolean;
  elements: any;
  setElements: any;
  toggleLoader:boolean
}
const BackLogs = React.forwardRef((props: ChildProps, ref: any) => {

  const [isHovering, setIsHovering] = React.useState(false);


  const [isShowingIndex, setisShowingIndex] = React.useState(-1);
  const handleOver1 = (index: any) => {
    setisShowingIndex(index);
  };
  const handleOut1 = () => {
    setisShowingIndex(-1);
  };

  const [isHoveringIndex, setisHoveringIndex] = React.useState(-1);
  const handleMouseOver = (index: any) => {
    // setIsHovering(true);
    setisHoveringIndex(index);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setisHoveringIndex(-1);
  };
  const {
    projectId,
    sprintId,
    userId,
    searchTicket,
    projectType,
    getAllUserListByProjectId,
    kanboardstatus,
    projectWiseAssigneeUsers,
    Isunassignee,
    elements,
    TicketData,
    spritList,
    setElements,
    toggleLoader

  } = props;
  const [users, setUsers] = useState<any>(projectWiseAssigneeUsers);
  const [isSprintFocused, setIsSprintFocused] = React.useState(false);
  const [IssueSprint, setIssueSprint] = React.useState("");
  const [selectedProjectId, setProjectId] = useState("");
  const [buttonDisable1, setButtonDisable1] = useState(false);
 
  const [issueDetail, setIssuesDetail] = useState<any>({});

  useEffect(() => {

    //GetAllTicketForBacklogFiltersAsync();
    setUsers(projectWiseAssigneeUsers);
    getEpicIssues();
    // GetIssueDetailById(id);

    //orderOfTicket();
  }, []);

  const GetIssueDetailById = async (id: any) => {
    await issueManagementService
      .GetTicketDetailById(id)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setIssuesDetail(response.data);
          if (
            response.data.projectId != null &&
            response.data.projectId != ""
          ) {
            setProjectId(response.data.projectId);
            getAllUserListByProjectId();
          }
        }
      });
  };




  const handleUpdateSprint = (sprintId: any, ticketId: any, sprintName: any) => {
    debugger
      setIssueSprint(spritList.find((x: any) => x.id == sprintId)?.name);
      var req = {
        ticketId: ticketId,
        sprintId: sprintId,
      };
    
      issueManagementService.UpdateSprint(req).then((response: any) => {
        if (response.data.isError) {
          toast.error("Something Went Wrong, Please try again.");
        } else {
          refresh();
          toast.success(`Ticket moved to ${sprintName}.`, {
            onOpen: () => setButtonDisable1(true),
            onClose: () => setButtonDisable1(false),
          });
          // setIssueSprint("");
        }
      })
      .catch((error) => {
        error.data.Error.map((err: any, idx: any) => {
          toast.warning(err);
        });
      });
    };


  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const listCopy: any = { ...elements };

    const sourceList = listCopy[result.source.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );
    listCopy[result.source.droppableId] = newSourceList;
    const destinationList = listCopy[result.destination.droppableId];
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );
    const styleCommentEdit = {
      color: theme.palette.primary.main,
      fontWeight: 300,
      padding: "0px",
      fontSize: "0.875rem",
      "& .MuiButton-startIcon": {
        marginRight: "4px",
      },
    };

    setElements(listCopy);
    backlogService
      .GetAllTicketForBacklogFiltersAsync({
        projectId: projectId,
        sprintId: sprintId,
        search: searchTicket,
        issueType: projectType,
        userIds: userId,
      })
      .then((response: any) => {
        if (response?.data?.isError) {
        } else {
          // setElements(response?.data?.ticketOutputModels);
          //GetAllTicketForBacklogFiltersAsync();
        }
      });
    // getAllTicketUpdateStatus
  };

  const refresh = () => {
    backlogService
      .GetAllTicketForBacklogFiltersAsync({
        projectId: projectId,
        sprintId: "",
        search: searchTicket,
        issueType: projectType,
        userIds: userId,
      })
      .then((response: any) => {
        if (response?.data?.isError) {
          //setToggleLoader(false);
        } else {
          //  setBacklogList(response?.data?.ticketOutputModels);
          setElements(response?.data?.ticketOutputModels);
        }
      });
  };

  useImperativeHandle(ref, () => ({
    doRefresh() {
      refresh();
    },
  }));

 



  const deleteTicketData = async (id: any, e: any) => {
    e.stopPropagation();
    setOpenDelete(true);
    setCurrentId(id);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const DeleteTicket = async () => {
    setOpenDelete(false);
    try {
      await issueManagementService
        .deleteTicket(currentId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Ticket Deleted successfully.");
            //props.getAllUserListByProjectId();
            //GetAllTicketForBacklogFiltersAsync();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  const handleUserChange = (TicketId: any, id: any) => {
    var req = {
      ticketId: TicketId,
      userId: id,
    };
    issueManagementService.UpdateResourceId(req).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something Went Wrong, Please try again.");
      } else {
        //GetAllTicketForBacklogFiltersAsync();
      }
    });
  };
  const [show, dotspopup] = useState(false);

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      Board
    </Typography>,
    <Typography key="2" color="text.primary">
      Issue Detail Page
    </Typography>,
    <Typography key="3" color="text.primary">
      Add child issue option is missing
    </Typography>,
  ];

  const [epicList, setEpicList] = useState([]);


  const getEpicIssues = async (values?: any) => {
    var req = {
      ProjectId: projectId
    };
    await issueManagementService.GetAllEpic(req).then((response: any) => {
      if (!response.data.isError) {
        setEpicList(response.data);
      }
    });
  };

  const orderOfTicket = async (ticketId: any, order: any) => {
    debugger
    var req = {
      Ticketid: ticketId,
      order: order
    };
    await issueManagementService.PinTicketOnTopOrBottom(req).then((response: any) => {
      if (!response.data.isError) {
        refresh();
        toast.success(order == 1 ? "Moved to top Successfully" : "Moved to bottom Successfully");
      }

    });
  };


  const getEpicSummary = ((epicId: any) => {
    const epic: any = epicList.find((e: any) => e.id == epicId);
    return epic?.summary || "";
  })
  const boxStyles = {
    fontSize: '14px',
    border: '2px  ',
    backgroundColor: '#cdcdcd',
    padding: ' 0 8px',
    fontWeight: 'bold',
    borderRadius: '10px',
    margin: '0 10px',
  };
  return (
    <>
      {toggleLoader && (
              <Box sx={Loader}>
                <FontAwesomeIcon icon={faSpinner} spinPulse size={"3x"} />
                </Box>
            )}
      <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0, mt: 2 }}>
        {elements && elements.length > 0 && elements.filter((a: any) => a.tickets != null && a.tickets.length > 0).length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            {elements[0]?.tickets?.map((event: any, item: any, i: any) => (
              <Paper
                component="li"
                variant="outlined"
                onMouseOver={() => handleMouseOver(item)}
                onMouseOut={handleMouseOut}
                sx={{
                  px: 1.5,
                  py: 1,
                  "&:hover": {
                    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
                  },
                  mb: 0.5,
                }}
              >
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={6}

                    sx={{ cursor: "grab" }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Button
                        variant="text"
                        component={Link}
                        size="small"
                        sx={{ mr: 3 }}
                        onClick={() => props.TicketInfo(event)}
                      >
                        {event.issueType === "Bug" && <Icons.Bug />}
                        {event.issueType === "Story" && <Icons.Story />}
                        {event.issueType === "Epic" && <Icons.Epic />}
                        {event.issueType === "Task" && <Icons.Task />}
                        {event.issueType === "Child" && < ItemTicket >
                          <Icons.copy />

                        </ItemTicket>}


                        &nbsp; {event?.ticketNumber}
                        {/* <Icons.BackLog /> */}
                        {/* &nbsp; {event?.ticketno} */}
                      </Button>

                      <Breadcrumbs separator="›" aria-label="breadcrumb">

                        <Typography key="3" color="text.primary">
                          {/* Add child issue option is missing */}
                          {event.summary?.length > 52
                            ? event.summary.slice(0, 90 - 1) + "..."
                            : event.summary}
                        </Typography>

                      </Breadcrumbs>
                      {/* <Box>                 
                    <EpicLinkbtn contentText="Epic" />
                    </Box> */}
                      {isHoveringIndex == item && event.issueType != "Epic" && !event.epicId && (
                        <Box>
                          <EpicLinkbtn contentText="Epic" projectId={projectId} ticketId={event.id} refreshFunction={refresh} />

                        </Box>
                      )}
                      {

                        event.epicId &&
                        <Box style={boxStyles}>
                          {getEpicSummary(event.epicId)}
                        </Box>
                      }


                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {event.isParentTicket == true && (
                        <Box
                          sx={{
                            marginRight: "22px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Icons.ChildIssue />
                        </Box>
                      )}

                      {event.priority === "Highest" && (
                        <Tooltip
                          title={
                            item?.storyPoint &&
                              item?.storyPoint != null &&
                              item?.storyPoint.length > 0
                              ? "Priority: Highest / Story Points: " +
                              item?.storyPoint
                              : "Priority: Highest"
                          }
                        >
                          <TicketLabel
                            icon={<Icons.Highest />}
                            label={event?.storyPoint}
                          />
                        </Tooltip>
                      )}
                      {event.priority === "High" && (
                        <Tooltip
                          title={
                            event?.storyPoint &&
                              event?.storyPoint != null &&
                              event?.storyPoint.length > 0
                              ? "Priority: High / Story Points:" +
                              event?.storyPoint
                              : "Priority: High"
                          }
                        >
                          <TicketLabel
                            icon={<Icons.High />}
                            label={event?.storyPoint}
                          />
                        </Tooltip>
                      )}
                      {event.priority === "Medium" && (
                        <Tooltip
                          title={
                            event?.storyPoint &&
                              event?.storyPoint != null &&
                              event?.storyPoint.length > 0
                              ? "Priority: Medium / Story Points: " +
                              event?.storyPoint
                              : "Priority: Medium"
                          }
                        >
                          <TicketLabel
                            icon={<Icons.Medium />}
                            label={event?.storyPoint}
                          />
                        </Tooltip>
                      )}
                      {event.priority === "Low" && (
                        <Tooltip
                          title={
                            event?.storyPoint &&
                              event?.storyPoint != null &&
                              event?.storyPoint.length > 0
                              ? "Priority: Low / Story Points: " +
                              item?.storyPoint
                              : "Priority: Low"
                          }
                        >
                          <TicketLabel
                            icon={<Icons.Low />}
                            label={event?.storyPoint}
                          />
                        </Tooltip>
                      )}
                      {event.priority === "Lowest" && (
                        <Tooltip
                          title={
                            event?.storyPoint &&
                              event?.storyPoint != null &&
                              event?.storyPoint.length > 0
                              ? "Priority: Lowest / Story Points: " +
                              event?.storyPoint
                              : "Priority: Lowest"
                          }
                        >
                          <TicketLabel
                            icon={<Icons.Lowest />}
                            label={event?.storyPoint}
                          />
                        </Tooltip>
                      )}
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                        {/* <AvatarGroup  >
                          <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/300" sx={{ width: 24, height: 24 }} />                        
                        </AvatarGroup> */}
                      </Stack>
                      <Avatar

                        {...commonFunctions.stringAvatar(event?.assigneeUserName)}
                        // sx={[styles.reporter]}
                        sx={{ bgcolor: deepOrange[500], margin: "0 28px 0 0" }}
                        src={`${process.env.REACT_APP_SERVER_URL}${event?.reporterName}`}
                      />

                      {/* <Grid item xs={12} md={4}>
                        <Autocomplete
                          value={
                            users?.find(
                              (option: any) => option.id == event.userId
                            ) ?? null
                          }
                          fullWidth
                          id="outlined-basic"
                          options={users}
                          getOptionLabel={(option) => option.name}
                          onChange={(evt, value) => {
                            handleUserChange(event.id, value?.id ?? "");
                            const current = elements;
                            current[0].tickets.find((x:any)=>x.id == event.id).userId = value?.id ?? "";
                            setElements(current);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                            autoFocus
                              id="selectedAssigneId"
                            variant="outlined"
                            fullWidth
                              name="IssueAssigneeId"
                              size="small"
                              value={assigneeUser}
                              onBlur={(evt) => setIsNamedFocused(false)}
                              required
                            sx={{
                              "& .MuiInputBase-root": {
                                backgroundColor: "#FFF3CE",
                                border: "0",
                              },
                            }}
                            />
                          )}
                        />
          </Grid> */}
                      <Grid item xs={12} md={4}>

                        <Stack direction="row" alignItems="center">

                          {isSprintFocused && IssueSprint != null ? (
                            <Typography
                              onClick={() => {
                                setIsSprintFocused(true);
                              }}
                            >
                              {IssueSprint ?? "None"}
                            </Typography>
                          ) : (
                            <TextField
                              autoFocus
                              id="outlined-basic"
                              select
                              variant="outlined"
                              fullWidth
                              name="IssueSprint"
                              value={event.sprintName || (spritList.length > 0 ? spritList[0].sprintName : '')}
                              onBlur={(event) => setIsSprintFocused(false)}
                              sx={{
                                "& .MuiInputBase-root": {
                                  backgroundColor: "#FFF3CE",
                                  border: "0",
                                },
                              }}
                            >
                              {spritList.filter((sprint: any) => sprint.status !== eSprintStatus.Completed).map((sprint: any) => (
                                <MenuItem
                                  key={sprint.id}
                                  value={sprint.id || spritList[0].sprintName}
                                  onClick={() => {
                                    event.sprint = sprint.id;
                                    handleUpdateSprint(sprint.id, event.id, sprint?.sprintName);
                                  }}
                                >
                                  {sprint.sprintName}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}

                        </Stack>

                      </Grid>
                      <Box
                        sx={{
                          marginLeft: "22px",
                          display: "flex",
                          alignItems: "center",
                          position: "relative"
                        }}
                      >
                        <Link sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}

                          onClick={() => dotspopup(prev => !prev)}
                          onMouseOver={() => handleOver1(item)}
                          onMouseOut={handleOut1}

                        >
                          <MoreHorizIcon />
                        </Link>
                        {isShowingIndex == item && (

                          <Box
                            sx={{ position: "absolute", top: "20px", width: "200px", backgroundColor: "white", border: "1px solid #ddd", padding: "10px", right: "0", zIndex: "99" }}
                            onMouseOver={() => handleOver1(item)}
                            onMouseOut={handleOut1}
                          >
                            <MenuItem disabled={true} style={{ 'border': '1px solid' }}>Move To </MenuItem>
                            <MenuItem
                              onClick={() => {
                                orderOfTicket(event.id, "1");
                                handleOut1();
                              }
                              }
                            >
                              Top of Backlog
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                {
                                  orderOfTicket(event.id, "3");
                                  handleOut1();

                                }
                              }}
                            >
                              Bottom of Backlog
                            </MenuItem>



                          </Box>
                        )}

                      </Box>

                      {/* <IconButton
color="default"
component="label"
sx={{ ml: 2 }}
onClick={(e) => {
deleteTicketData(event.id, e);
}}
>
<CloseIcon />
</IconButton> */}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </DragDropContext>
        ) : (
          <NotFound NotfoundText="No Ticket Found" />
        )}
      </Box>
      <Modal
        open={openDelete}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Delete Ticket
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Do you really want to Delete this, Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDelete}>
                Cancel
              </Button>
              <Button variant="contained" onClick={DeleteTicket}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>

      {/* ------------------------ */}





    </>
  );
});

export default BackLogs;
