import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Autocomplete, FormControl, Tab, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import * as Icons from "../../assests/icons/icons";
import StyledTableCell from "../../components/styledComponent/CustomTableCell";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "material-react-toastify";
import { inventoryService } from "../../service/Inventory/inventoryService";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import NotFound from "../../components/styledComponent/NotFound";
import moment from "moment";
import InfoPopover from "../../components/CommonInfoComponent";
import LongText from "../../components/longtext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


export default function Inventoryreturndevice() {
    const _permission = useSelector(
        (state: any) => state.InventoryPermissionApp.InventoryPermission
    );
    const navigate = useNavigate();
    const [openReturnDialog, setOpenReturnDialog] = React.useState(false);
    const [userEmpId, setUserEmpId] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [userAssetDetail, setUserAssetDetail] = React.useState([]);
    const [userDetails, setUserDetails] = React.useState<any>("");
    const [pendingReqCount, setPendingReqCount] = React.useState<any>("");
    const [pendingReq, setRendingReq] = React.useState<any>([]);
    const [statusOption, setStatusOption] = React.useState<any>("Pending");
    const [itemId, setItemId] = React.useState("");
    const [userComments, setUserComments] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(pendingReq.length / rowsPerPage);
    const [buttonDisable, setButtonDisable] = React.useState(false);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClear = () => {
        setUserDetails("");
        setUserAssetDetail([]);
        setUserEmpId("")
        setStatusOption("Pending");
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleCloseReturn = () => {
        setOpenReturnDialog(false);
    };

    const statusList = [
        { label: 'Approved', statusId: "1" },
        { label: 'Pending', statusId: "3" },]


    useEffect(() => {
        if (_permission.page_ReturnDevice_Access != true) {
            navigate("/dashboard")
        }

        getPendingReq(3)
    }, []);

    const getUserDetails = async (value: any, status: any) => {

        let abc = {
            userId: "",
            empId: value,
            status: status
        }
        let input = {
            employeeId: value
        }

        try {
            await inventoryService
                .getEmployeeDetails(input)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        await inventoryService.getAssignedDevicedetailByEmpId(value).then((response: any) => {

                            setUserAssetDetail(response.data);
                        })

                        setUserDetails(response?.data)

                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err,
                    {
                        onOpen: () => setButtonDisable(true),
                        onClose: () => setButtonDisable(false),
                    });
            });
        }
    };
    const getPendingReq = async (status: any) => {

        let abc = {
            userId: "",
            empId: "",
            status: status
        }
        try {
            await inventoryService
                .getHistoryInventory(abc)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {

                        setRendingReq(response?.data)
                        setPendingReqCount(response?.data?.length)
                        setPage(0);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const fieldValidationSchema = Yup.object().shape({
        comment: Yup.string()
            .max(150, "Comment can only contain 150 Alphabets")
            .required("Comment is required"),
    });

    const createReturnRequest = async (values: any) => {

        setOpenReturnDialog(false);
        let abc = {
            id: itemId,
            itComment: values?.comment,
            userComment: userComments,
            status: 1
        }
        try {
            await inventoryService.returnRequestOfAsset(abc).then((response: any) => {
                if (response.data.isError) {
                } else {
                    toast.success("Item Added to stock Successfully");
                    setItemId("")
                    setUserComments("");
                    getPendingReq(3);
                    getUserDetails(userEmpId, null)
                }
            });
        } catch (ex: any) {

            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', height: 'auto' }}>
                <Typography sx={{ padding: "0 0 15px 0", color: '#757575' }}>
                    {'Return Item'}
                </Typography>
                <Paper sx={{ padding: '24px 16px', height: '100%' }} >
                    <Stack direction="row" pb={'24px'} justifyContent={'start'} alignItems={'center'} >

                        <Typography variant="h5" sx={{ fontWeight: 500, color: '#232360', }} component="h2">
                            Return Item
                        </Typography>
                        <InfoPopover onInput={"Brownie Points"} />
                    </Stack>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative', }}>
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile

                        >{_permission.tab_ManageReturnDevice_Access == true && (
                            <Tab label="Return" {...a11yProps(0)} onClick={() => handleClear()} />)}
                            {_permission.tab_ManageReturnRequests_Access == true && (
                                <Tab label={`Return Requests (${pendingReqCount})`} {...a11yProps(1)} onClick={() => {
                                        if (statusOption === "Approved") {
                                            getPendingReq(1)
                                        } else {
                                            getPendingReq(3)
                                        }
                                    }}
                                />
                            )}

                        </Tabs>
                        {value == 1 && (
                            <Box sx={{ position: "absolute", right: '0', top: '0' }}>
                                <Autocomplete
                                    disableClearable={statusOption && statusOption != "Pending" ? false : true}
                                    value={
                                        statusList?.find(
                                            (option) => option.label == statusOption
                                        ) ?? statusOption
                                    }
                                    sx={{
                                        width: "160px",
                                        mr: 1,
                                        lineHeight: "0.80em",
                                    }}
                                    fullWidth
                                    id="reports-period"
                                    options={statusList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                        if (value?.statusId == "" || value?.statusId == undefined) {

                                            setStatusOption("Pending")
                                            getPendingReq(3);
                                        }
                                        else {
                                            setStatusOption(value?.label ?? "");
                                            getPendingReq(value?.statusId ?? "");
                                        }

                                        // handleMenuItemClick1(value?.statusId ?? "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="outlined-basic"
                                            name="selectedResourceId"
                                            variant="outlined"
                                            // value={statusOption}
                                            fullWidth
                                            sx={{ "& fieldset": { border: 'none' }, }}
                                        />
                                    )}
                                />
                            </Box>)}</Box>
                            {_permission.tab_ManageReturnDevice_Access == true && (
                    <TabPanel value={value} index={0}>
                        <Box sx={{ background: 'rgba(240, 249, 255, 1)', borderRadius: '5px', padding: '14px 36px 22px 27px', mt: "15px;" }}>
                            <Typography sx={{ mb: '20px', color: '#232360', fontSize: '20px', fontWeight: '400' }}>
                                Employee Details
                            </Typography>
                            <Grid container spacing={3} sx={{ display: "flex" }}>
                                <Grid item xs={12} md={3}>
                                    <Box >
                                        <TextField
                                            label="Emp ID"
                                            fullWidth
                                            value={userEmpId}
                                            onChange={(e) => {
                                                let value = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").trimStart();
                                                setUserEmpId(value);
                                            }}
                                            InputProps={{
                                                endAdornment: userEmpId != "" && (
                                                    <Tooltip title="Click here for search">
                                                        <div style={{ marginRight: "1px" }}>
                                                            <IconButton onClick={() => getUserDetails(userEmpId, 2)} disabled={buttonDisable}>
                                                                <Icons.Search />
                                                            </IconButton>
                                                        </div></Tooltip>
                                                ),
                                            }}
                                            sx={{
                                                background: '#fff',
                                                "& fieldset": {
                                                    border: '1px solid white',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box >
                                        <TextField
                                            label="Name"
                                            fullWidth
                                            value={userDetails?.name ?? ""}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            sx={{
                                                background: '#fff',
                                                "& fieldset": {
                                                    border: '1px solid white',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box >
                                        <TextField
                                            label="Department"
                                            value={userDetails?.department ?? ""}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                            sx={{
                                                background: '#fff',
                                                "& fieldset": {
                                                    border: '1px solid white',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>
                                        <TextField
                                            label="Designation"
                                            value={userDetails?.position ?? ""}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                            sx={{
                                                background: '#fff',
                                                "& fieldset": {
                                                    border: '1px solid white',
                                                }
                                            }} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <TableContainer sx={{ mt: 1, maxHeight: 150 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{
                                "& .css-6asl74-MuiTableCell-root.MuiTableCell-head": {
                                    background: 'none',
                                },
                                "& .css-6asl74-MuiTableCell-root ": {
                                    borderBottom: 'none'
                                }
                            }}>{userAssetDetail.length > 0 && (
                                <TableHead >
                                    <TableRow>
                                        <StyledTableCell align="left" >Sr. No</StyledTableCell>
                                        <StyledTableCell align="left" >Category</StyledTableCell>
                                        <StyledTableCell align="left" >Item </StyledTableCell>
                                        <StyledTableCell align="left" >Brand </StyledTableCell>
                                        <StyledTableCell align="left" >SKU</StyledTableCell>
                                        <StyledTableCell align="left">Date of Issuance</StyledTableCell>
                                        {/* <StyledTableCell align="left">Date of Return</StyledTableCell> */}
                                        <StyledTableCell align="left">Status</StyledTableCell>
                                        <StyledTableCell align="left">Comment</StyledTableCell>
                                        <StyledTableCell align="left">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>)}
                                {userAssetDetail.length > 0 && userAssetDetail.map((item: any, index: any) => (
                                    <TableBody>

                                        <TableRow>
                                            <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                            <StyledTableCell align="left">{item?.category}</StyledTableCell>
                                            <StyledTableCell align="left">{item?.itemName}</StyledTableCell>
                                            {item.brand.map((value: any) => (
                                                <StyledTableCell align="left">{value?.brandName !== "" ? value?.brandName : "--"}</StyledTableCell>
                                            ))}
                                            <StyledTableCell align="left">{item?.sku !== "" ? item?.sku : "--"}</StyledTableCell>
                                            <StyledTableCell align="left">{item?.issueDate ? moment(item.issueDate).format("DD-MMM-YYYY") : '--'}</StyledTableCell>
                                            {/* <StyledTableCell align="left">{item?.returnedOn ? moment(item.returnedOn).format("DD MMM, YYYY") : '--'}</StyledTableCell> */}

                                            <StyledTableCell align="left">{item?.status == 1 ? "In Stock" : item?.status == 2 ? "Assigned" : "Return Request"}</StyledTableCell>
                                            <StyledTableCell align="left">{item?.itComment ? (<LongText content={item.itComment} limit={30} />)
                                                : ('--')}</StyledTableCell>
                                                {_permission.action_ReceiveDevice_Access == true &&(
                                            <StyledTableCell align="left">

                                                <Button size="small" variant="outlined"
                                                    color="primary" sx={{ padding: '8px 20px', }} onClick={() => {
                                                        setOpenReturnDialog(true);
                                                        setItemId(item?.id); setUserComments(item?.userComment)
                                                    }}>Receive</Button>
                                            </StyledTableCell>)}
                                        </TableRow>
                                    </TableBody>))}
                            </Table>
                        </TableContainer>
                        {userAssetDetail.length == 0 && <NotFound NotfoundText="No Device Assigned Yet" />}
                    </TabPanel>)}

                    <TabPanel value={value} index={_permission.tab_ManageReturnDevice_Access == true ?  1: 0}>
                        <TableContainer sx={{ mt: "15px", maxHeight: 290 }}>
                            <Table stickyHeader aria-label="sticky table" size="small" sx={{
                                "& .css-6asl74-MuiTableCell-root.MuiTableCell-head": {
                                    background: 'none',
                                },
                                "& .css-6asl74-MuiTableCell-root ": {
                                    borderBottom: 'none'
                                }
                            }}>
                                <TableHead >
                                    <TableRow>
                                        <StyledTableCell align="left" >Sr. No</StyledTableCell>
                                        <StyledTableCell align="left" >Emp ID</StyledTableCell>
                                        <StyledTableCell align="left" >Name</StyledTableCell>
                                        <StyledTableCell align="left" >Category </StyledTableCell>
                                        <StyledTableCell align="left" >Items </StyledTableCell>
                                        <StyledTableCell align="left">Date of Issuance</StyledTableCell>
                                        {statusOption == "Pending" && (
                                            <StyledTableCell align="left">Return Request</StyledTableCell>)}
                                        {statusOption == "Approved" && (
                                            <StyledTableCell align="left">Return On</StyledTableCell>)}
                                        <StyledTableCell align="left">Status</StyledTableCell>
                                        <StyledTableCell align="left">Comment</StyledTableCell>
                                        {pendingReq[0]?.status == 3 && (
                                            <StyledTableCell align="left">Action</StyledTableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pendingReq.length > 0 && pendingReq.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index: any) => {
                                        const serialNumber = index + 1 + page * rowsPerPage;
                                        return (
                                            <TableRow>
                                                <StyledTableCell align="left">{serialNumber}</StyledTableCell>
                                                <StyledTableCell align="left">{item?.employeeId || "--"}</StyledTableCell>
                                                <StyledTableCell align="left">{item?.userName || "--"}</StyledTableCell>
                                                <StyledTableCell align="left">{item?.category}</StyledTableCell>
                                                <StyledTableCell align="left">{item?.itemName}</StyledTableCell>
                                                {/* {item.brand.map((value: any) => (
                                                    <StyledTableCell align="left">{value?.brandName}</StyledTableCell>
                                                ))} */}
                                                <StyledTableCell align="left">{item?.issueDate ? moment(item.issueDate).format("DD-MMM-YYYY") : '--'}</StyledTableCell>
                                                <StyledTableCell align="left"> {item?.status === 3
                                                    ? (item.requestedOn ? moment(item.requestedOn).format("DD-MMM-YYYY") : "--")
                                                    : item?.status === 1
                                                        ? (item.returnedOn ? moment(item.returnedOn).format("DD-MMM-YYYY") : "--")
                                                        : "--"}</StyledTableCell>
                                                <StyledTableCell align="left">{item?.status == 1 ? "Approved" : item?.status == 2 ? "Assigned" : "Pending"}</StyledTableCell>
                                                <StyledTableCell align="left">{item?.itComment || item?.userComment ? (<LongText content={(item?.status == 3 ? item?.userComment : item?.itComment)} limit={30} />)
                                                    : ('--')}</StyledTableCell>
                                                    {_permission.action_ReturnRequestApprove_Access === true &&(
                                                <StyledTableCell align="left">
                                                    {item?.status == 3 && (
                                                        <Button size="small" variant="outlined"
                                                            color="primary" sx={{ padding: '8px 20px', }} onClick={() => {
                                                                setOpenReturnDialog(true); setItemId(item?.id);
                                                                setUserComments(item?.userComment)
                                                            }}>Return</Button>)}
                                                </StyledTableCell>)}
                                            </TableRow>);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20, 25, 30]}
                            component="div"
                            count={pendingReq.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}                           
                            labelDisplayedRows={() => `Total: ${pendingReq?.length}  Page ${page + 1} of ${pageCount}`}
                        />
                    </TabPanel>
                </Paper>
            </Box>

            <Dialog
                open={openReturnDialog}
                onClose={Button}
                aria-labelledby="responsive-dialog-title"
                maxWidth="sm"
                fullWidth
                scroll="body"
                sx={{
                    "& .css-23vkr6-MuiPaper-root-MuiDialog-paper": {
                        minWidth: '763px',
                        minHeight: '350px',
                    }
                }}

            >  <Formik
                initialValues={{
                    userComment: "",
                    comment: "",
                    id: "",
                }}
                enableReinitialize
                validationSchema={fieldValidationSchema}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        await createReturnRequest(values);
                        handleCloseReturn();

                    } catch (err: any) {
                        console.error(err);
                        err = JSON.parse(JSON.stringify(err));
                        if (err.status == 400) {
                            {
                                err.data.Error.map((error: any, idx: any) =>
                                    toast.warn(error)
                                );
                            }
                        }
                    }
                }}
            >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <>
                                <Box>
                                    <DialogTitle
                                        id="responsive-dialog-title"
                                        sx={{ padding: '28px 40px 32px 40px' }}
                                    >
                                        <Stack direction={'row'} justifyContent={'space-between'} sx={{ position: 'relative', overflow: 'hidden' }}>
                                            <Typography sx={{ fontSize: "26px", fontWeight: 600, color: '#232360' }}> Return</Typography>

                                            <IconButton
                                                aria-label="delete"
                                                onClick={handleCloseReturn}
                                                sx={{ marginLeft: 'auto', position: 'absolute', top: '0', right: '0' }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Stack>
                                    </DialogTitle>

                                    <DialogContent sx={{ padding: '10px 40px 30px 40px' }}>
                                        <Grid container spacing={2} sx={{ flexGrow: 1, margin: '0px', maxWidth: '100%' }}>
                                            <Grid xs={12}>

                                                <Typography variant="body1" fontSize={'14px'} color={'#212121'} gutterBottom>
                                                    Add Comment
                                                </Typography>
                                                <FormControl
                                                    sx={{ mt: '10px', width: "100%" }}
                                                    variant="outlined">
                                                    <TextField
                                                        minRows={3}
                                                        sx={{
                                                            "& .css-r2i9ug-MuiInputBase-input-MuiOutlinedInput-input": {
                                                                padding: '16px 10px',
                                                                minHeight: '90px'
                                                            }
                                                        }}
                                                        placeholder=""
                                                        variant="outlined"
                                                        name="comment"
                                                        id={"comment"}
                                                        size="small"
                                                        fullWidth
                                                        value={values?.comment}
                                                        onChange={handleChange}
                                                        error={Boolean(
                                                            getIn(touched, "comment") && getIn(errors, "comment")
                                                        )}
                                                        helperText={
                                                            getIn(touched, "comment") && getIn(errors, "comment")
                                                        }
                                                    />
                                                </FormControl>

                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </Box>

                                <DialogActions sx={{ padding: '0px 40px 38px 40px', marginTop: 'auto' }}>
                                 
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                              {value == (_permission.tab_ManageReturnRequests_Access == true ? 1 : 0) ? "Approve Return Request" : "Receive item"}
                                            </Button>
                                     
                                </DialogActions></>
                        </form>
                    )}
                </Formik>
            </Dialog>

        </>
    )
}