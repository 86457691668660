import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import { projectTrailorService } from "../service/ProjectTrailor-service";
import { toast } from "material-react-toastify";
import FileUpload2 from "../components/styledComponent/FileUpload2";
import moment from "moment";
import { masterDataService } from "../service/master-service/master-data-service";
import getDate from "../components/GetDateWithSimpletime";
import { projectProcessService } from "../service/project-process-service";
import { CircularProgress, Dialog, IconButton, Tooltip } from "@mui/material";
import * as Icons from "../assests/icons/icons";
import HrReporttop from "./reports/HrReportsstyle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Leavepagestyle from "./leavemanagement/Leavemanagementstyle";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import { projectManagementServive } from "../service/project-management-service";
import { ProjectTrailerArrayObject } from "../Enum/ProjectTrailor";


interface FileIntra {
    file : string;
    uploadedDate: string;
}

interface DocumentsStructIntra {
    documentName : string;
    documentDetails : FileIntra[];
}

interface DocumentUploadIntra {
    projectId : string;
    documents : DocumentsStructIntra[];
}


 
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#fff",
            color: "#232360",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
 
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#F0F9FF",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const UploadButton = styled(Button)(({ theme }) => ({
        border: '1px dashed #1DA7FF',
        borderRadius: '8px',
        color: '#00aaff',
        padding: '8px 32px',
        position: "relative",
        zIndex: "99999",
        '&:hover': {
            borderColor: '#1DA7FF',
            backgroundColor: 'rgba(0, 170, 255, 0.1)',
        },
    }));
 
    const FileInput = styled('input')({
        display: 'none',
    });
 
    const ProjectDocument = (propsId : any) => { 
        
        const { pId } = useParams();
        const [projectArtiData, setProjectArtiData] = React.useState<any>([]);
        const [newPldStructure, setNewPldStructure] = React.useState<DocumentUploadIntra[]>([{projectId: pId ? pId : propsId.Id, documents : []}]);
        const [toggleLoader, setToggleLoader] = React.useState(false);
        const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);
        const [documentNameForDeletion , setDocumentNameForDeletion] = React.useState("");
        const [documentToBeDeleted, setDocumentToBeDeleted] = React.useState<any | null>(null);
        const [documentIndexToBeDeleted, setDocumentIndexToBeDeleted] = React.useState<number | null>(null);
        

        const ArtifactsRequestStatus = [
           
            {
              value: 1,
              label: "Pending",
            },
            {
              value: 2,
              label: "Approved",
            },
            {
              value: 3,
              label: "Rejected",
            },
            {
                value: 4,
                label: "ApprovedAndRejected",
              }
          ];
      

        const handleFileChange = (event: any) => {
            const file = event.target.files[0];
        };

        const handleCloseAlert = () => {
            setDeleteAlertOpen(false);
          };
          

        useEffect(() => {
            getArtifactsData();
            getDocumentsfromUserID();

           
        },[]);


        const triggerDocumentFile = async(fileList: FileList, index: number , projName : string) => {
            debugger
            const fileArray = Array.from(fileList);
            const fileName = await getDocumentNameFromServerForUpload(fileArray);
            const uploadDate = getDate(new Date());

            setNewPldStructure((prevItems : DocumentUploadIntra[] ) => {
                debugger
                let newList = [...prevItems];

                if(newList[0]?.documents.length == 0){  
                    const newDocument : DocumentsStructIntra = {
                        documentName : projName,
                        documentDetails : [
                            {file : fileName, uploadedDate : uploadDate}
                        ]
                    }
                    newList[0]?.documents.push(newDocument);

                }else {
                    const RecordExistIndex = newList[0]?.documents.findIndex((item : DocumentsStructIntra) => item?.documentName === projName);

                    if(RecordExistIndex !== -1){
                     const existingDocument = newList[0]?.documents[RecordExistIndex];
                     if(existingDocument){
                        existingDocument.documentDetails = [
                            ...existingDocument.documentDetails,
                            {file : fileName, uploadedDate : uploadDate}
                        ];
                     }
                    } else {
                        const newDocument : DocumentsStructIntra = {
                            documentName : projName,
                            documentDetails : [
                                {file : fileName, uploadedDate : uploadDate}
                            ]
                        }
                        newList[0]?.documents.push(newDocument);
                    }
                }

               
                return newList;
            })

            saveUploadDocuments();
          };

          const saveUploadDocuments = async () => {
            debugger
            const pld = newPldStructure.pop();
            setToggleLoader(true);

            try {
                await projectProcessService.addProjectDocument(pld).then((response : any) => {
                    if(response.data.isError){setToggleLoader(false);}
                    else{
                        if(deleteAlertOpen){
                            toast.success("Documents deleted successfully");
                        }else {
                            toast.success("Documents uploaded successfully");
                        }
                        
                        setToggleLoader(false);
                        setDeleteAlertOpen(false);
                        getDocumentsfromUserID();
                        setDocumentNameForDeletion("");
                        setDocumentToBeDeleted(null);
                        setDocumentIndexToBeDeleted(null);
                    }
                })
            }
            catch (ex : any) {
                ex.data.Error.map((err: any, idx:any) => {
                    toast.warning(err);
                    setDeleteAlertOpen(false);
                    setToggleLoader(false);
                })
            }
          }

          
        const handleDeleteIconClick = (artifact : any , file : any , fIndex : any) => {
            debugger
            console.log(artifact, file, fIndex , "huty");

            setNewPldStructure((prevItems : DocumentUploadIntra[]) => {
                const newList = [...prevItems];

                const filteredItem = newList[0].documents.filter((item: any) => item.documentName == artifact);
                const IndexOfSelectedItem = newList[0].documents.findIndex((item : any) => item.documentName == artifact);

                filteredItem[0].documentDetails.splice(fIndex,1);

                if(newList[0].documents.length == 0){
                    console.log("HEHE");
                } 
                else{
                    newList[0].documents.splice(IndexOfSelectedItem,1,...filteredItem);
                }

                return newList;
            })  
            saveUploadDocuments();          
        }

        
          

          const getDocumentNameFromServerForUpload = async(values : File[]) => {
            let formData = new FormData();
            values.forEach((file) => {
                formData.append('File', file);
            });
            formData.append('FileFor', 'xyz');

            try {
                const response = await masterDataService.UploadUserDocument(formData);
                if (response.data.isError) {
                    return null; 
                } else {
                    return response.data; 
                }
            }
            catch (ex : any){
                ex.data.Error.map((err: any, idx:any) => {
                    toast.warning(err);
                })
            }



          }

     


        const getArtifactsData = async () => {
            debugger
            try{
                setToggleLoader(true);
                await projectTrailorService.getRaisedProjectArtifactsRequestByProId(pId ? pId : propsId.Id ,2 ).then((response: any) => {
                    if(response.data.isError){setToggleLoader(false);}
                    else{
                        debugger
                        setProjectArtiData(response.data);

                        const newArtifact = {"artifactId" : "4354", "artifactName" : "Other Documents", "isFollowed": true , "reason" : ""};
                        const addedArtifact = [...response.data.artifacts, newArtifact];

                        const updatedProjectData = {...response.data, artifacts: addedArtifact};

                        setProjectArtiData(updatedProjectData);
                        setToggleLoader(false);
                    }
                })
            }
            catch (ex: any){
                ex.data.Error.map((err: any, idx:any) => {
                    toast.warning(err);
                    setToggleLoader(false);
                })
            }
        }



        const getDocumentsfromUserID = async () => {
            try {
                setToggleLoader(true);
                await projectProcessService.getProjectDocuments(pId ? pId : propsId.Id).then((response : any) => {
                    if(response.data.isError){setToggleLoader(false);}
                    else {
                        debugger
                        console.log(response.data , "Data from user");
                        if(response.data.projectId == null && response.data.documents == null){
                            console.log("Empty");
                        }else{
                            setNewPldStructure([response?.data]);
                        }
                        setToggleLoader(false);
                    }
                })
            }
            catch (ex : any){
                ex.data.Error.map((err: any, idx:any) => {
                    toast.warning(err);
                    setToggleLoader(false);
                })
            }
           

        }

        const getUnEncrypedFiles = async (fileName: any) => {
            try {
              await projectManagementServive
                .getUnEncrypetDocuments(fileName)
                .then(async (response: any) => {
                  if (response.data.type === "application/json") {
                    const data = await response.data.text();
                    toast.warning(JSON.parse(data).listErrors[0]);
                  }
                  else {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = URL.createObjectURL(response.data);
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(a.href);
                  }
                });
            } catch (ex: any) {
              (ex.data?.Error || ex.Error).map((err: any, idx: any) => {
                toast.warning(err);
              });
            }
        
          }

        return (
            <div> {toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
                <Paper sx={{ background: "#fff", p: 3 }}> 
                    <Typography>
                        {projectArtiData?.artifacts?.length > 0 ? "Project Documents" : "No Records Found"}  
                    </Typography>
                    <Box sx={{ maxWidth: "1000px", margin: "42px auto" }}>
                        {projectArtiData?.artifacts?.map((item : any , idx : any) => (
                            item?.reason == "" && item?.isFollowed && (
                                <>
                                <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ flexDirection: "row-reverse" }}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingInline: "16px" }}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                         {item?.artifactName}
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "120px" }}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                            <span style={{ color: "#232360", fontWeight: "500" }}>
                                                {ProjectTrailerArrayObject?.find((option: any) =>
                                                     option.label.trim().toLowerCase() == item?.artifactName.trim().toLowerCase())?.value ?? "--"}</span> 
                                        </Typography>
                                        <Box>
                                            <FileInput
                                                accept="image/*"
                                                id="upload-file"
                                                type="file"
                                                onChange={handleFileChange}
                                            />
 
                                            <label htmlFor="upload-file">
                                                {/* <UploadButton
                                                    startIcon={<UploadIcon />}
                                                    variant="outlined"
                                                >
                                                    Upload
                                                </UploadButton> */}
                                                <FileUpload2 
                                                    selectFile = {(e: any) =>{triggerDocumentFile(e, idx , item?.artifactName)}}
                                                    index={idx}
                                                />
                                            </label>
                                        </Box>
                                    </Box>
                                </Box>
 
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Sr. No.</StyledTableCell>
                                                <StyledTableCell align="left">File</StyledTableCell>
                                                <StyledTableCell align="left">Date</StyledTableCell>
                                                <StyledTableCell align="left">Delete</StyledTableCell>
 
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {newPldStructure[0]?.documents?.filter((item2: any) => item2?.documentName === item?.artifactName).map((item: any, idx: any) => {
                                         
                                         const files = item?.documentDetails;

                                         return files.map((file: any, fileIdx: any) => (
                                             <StyledTableRow key={fileIdx}>
                                                 <StyledTableCell component="th" scope="row">
                                                     {fileIdx + 1} 
                                                 </StyledTableCell>
                                                 <StyledTableCell align="left" sx={{cursor:"pointer"}}   onClick={() => { getUnEncrypedFiles(file?.file) }}>
                                                     {file?.file || "Unknown File"} 
                                                 </StyledTableCell>
                                                 <StyledTableCell align="left">
                                                     {moment(file?.uploadedDate).format("DD-MM-YYYY")} 
                                                 </StyledTableCell>
                                                 <StyledTableCell align="left">
                                                 <Tooltip title="Delete">
                                                        <IconButton
                                                          aria-label="delete"
                                                          onClick={() => 
                                                            {  
                                                                setDeleteAlertOpen(true);
                                                                setDocumentNameForDeletion(item?.documentName);
                                                                setDocumentToBeDeleted(file);
                                                                setDocumentIndexToBeDeleted(fileIdx);
                                                                //handleDeleteIconClick(item.documentName , file , fileIdx);
                                                            }
                                                          }
                                                        >
                                                          <Icons.Delete />
                                                        </IconButton>
                                                      </Tooltip>
                                                 </StyledTableCell>
                                             </StyledTableRow>
                                         ));
                                     })}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        </>
                            )
                        ))}
                      
                    </Box>

                    <Dialog
                     open={deleteAlertOpen}
                     onClose={setDeleteAlertOpen}
                     aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description"
                     maxWidth="sm"
                   >
                     <IconButton
                       aria-label="delete"
                       sx={Leavepagestyle.styleCrosssetupleaves}
                       onClick={handleCloseAlert}
                     >
                       <ClearIcon />
                     </IconButton>
                     <DialogTitle
                       id="alert-dialog-title"
                       sx={Leavepagestyle.deletepopupheading}
                     >
                       Delete
                     </DialogTitle>
                     <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
                       Are you sure you want to delete this ?
                     </DialogContent>
                     <DialogActions
                       sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
                     >
                       <Button variant="outlined" onClick={handleCloseAlert}>
                         No
                       </Button>
                       <Button variant="contained" onClick={() => {handleDeleteIconClick(documentNameForDeletion,documentToBeDeleted,documentIndexToBeDeleted)}}>
                         Yes
                       </Button>
                     </DialogActions>
                   </Dialog>
 
                </Paper>
            </div>
        );
    }
 
    export default ProjectDocument;