import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { FormHelperText, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import * as Icons from "../../assests/icons/icons";
import FileUpload from "../../components/styledComponent/FileUpload";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import closeicon from '../../assests/icons/close-icon-inventory.svg';
import redcloseicon from '../../assests/icons/red-close-icon.svg';
import keyboardimage from '../../assests/images/keyboard-inventory-image.png';

export default function Inventorynewcategory() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', }}>
                <Typography sx={{ padding: "0 0 15px 0" }}>
                    Inventory Management
                </Typography>
                <Paper sx={{ padding: '30px 16px' }}>
                    <Stack direction="row" pb={'19px'} justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: "1px solid #EEEEEE" }}>
                        <Typography variant="h6" sx={{ fontWeight: 500, color: '#232360' }} component="h2">
                            New Category
                        </Typography>

                    </Stack>

                    <Box paddingY={2} >
                        <Grid container spacing={2} sx={{ display: "flex" }}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Keyboard"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mb: '24px' }}
                                />
                                <TextField
                                    label="Dell"
                                    variant="outlined"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    label="Description"
                                    id="outlined-basic"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    required
                                    defaultValue="It is a long established fact that a reader will be distracted by the readable content 
                                    of a page when looking at its layout"
                                    sx={{
                    
                                        "& .MuiInputBase-root": {
                                            height: '100%'
                                        },
                                        height: '100%'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Stack direction="row" alignContent='center' justifyContent='center' sx={{ height: '100%' }}>
                                    <Box sx={{ width: "50%", display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <Typography>Upload Product Details</Typography>
                                    </Box>
                                    <Box sx={{ width: "50%", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start' }}>
                                        <Box sx={{ borderRadius: '4px',position: 'relative', overflow: 'visible', display: 'inline-block' }}>
                                            <Box sx={{  display: 'inline-block' }}>
                                                <img src={keyboardimage} alt="keyboard" />
                                            </Box>
                                            <Box sx={{ position: 'absolute', top: '-8px', right: '-8px', cursor:'pointer' }}>
                                                <img src={redcloseicon} alt="red-close-icon" />
                                            </Box>
                                        </Box>
                                        <Typography sx={{ color: '#1DA7FF', fontSize: '14px', fontWeight: '400',cursor:'pointer' }}>
                                            Change Image
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Stack direction={'row'} padding={'12px 0'} spacing={2}>
                            <Box sx={{ background: '#EFEFEF', padding: '0 8px', position: 'relative', fontSize: '16px', fontWeight: 500,cursor:'pointer' }}>
                                HP
                                <Box sx={{ position: 'absolute', top: "-7px", right: "-7px", }}>
                                    <img src={closeicon} />
                                </Box>
                            </Box>
                            <Box sx={{ background: '#EFEFEF', padding: '0 8px', position: 'relative', fontSize: '16px', fontWeight: 500,cursor:'pointer' }}>
                                Logitech
                                <Box sx={{ position: 'absolute', top: "-7px", right: "-7px", }}>
                                    <img src={closeicon} />
                                </Box>
                            </Box>
                            <Box sx={{ background: '#EFEFEF', padding: '0 8px', position: 'relative', fontSize: '16px', fontWeight: 500,cursor:'pointer' }}>
                                Apple
                                <Box sx={{ position: 'absolute', top: "-7px", right: "-7px", }}>
                                    <img src={closeicon} />
                                </Box>
                            </Box>

                        </Stack>
                        <Box sx={{marginY:'16px'}}>
                        <Button
                                variant="contained"
                                color="primary"
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>
                    <Box paddingY={'30px'} sx={{ borderBottom: "1px solid #EEEEEE", borderTop: "1px solid #EEEEEE" }}>
                        <Stack direction="row" pb={'24px'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant="h6" sx={{ fontWeight: 500, }} component="h2">
                                Set Configuration
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                            >
                                Add Attribute
                            </Button>

                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ border: "1px solid #EEEEEE", borderRadius: '4px', padding: '34px 9px 15px 23px' }}>
                            <Grid container spacing={2} sx={{ display: "flex" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', paddingTop: '12px' }}>
                                        Compatibility
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Window 7"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ mb: '24px' }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Window 8"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ mb: '24px' }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Mac OS X Leopard, 10.5.8"
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <Box>
                                        <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#1DA7FF', display: 'flex', justifyContent: 'start', alignItems: "center", paddingTop: '11px', paddingRight: '10px', cursor:'pointer' }}>
                                            Add Value
                                            <AddIcon />
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>




                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ border: "1px solid #EEEEEE", borderRadius: '4px', padding: '34px 9px 15px 23px', margin: '14px 0 0 0' }}>
                            <Grid container spacing={2} sx={{ display: "flex" }}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#232360', paddingTop: '12px' }}>
                                        Connectivity
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Wireless Keyboard"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ mb: '24px' }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Wired Keyboard"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ mb: '24px' }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="USB Keyboard "
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <Box>
                                        <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#1DA7FF', display: 'flex', justifyContent: 'start', alignItems: "center", paddingTop: '11px', paddingRight: '10px', cursor:'pointer' }}>
                                            Add Value
                                            <AddIcon />
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>




                        </Stack>
                    </Box>
                    <Box paddingY={'30px'} sx={{ borderTop: "1px solid #EEEEEE" }} textAlign={'end'}>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ margin: " 0px 22px" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>

                    </Box>
                </Paper>
            </Box>
        </>
    )
}