import { combineReducers } from 'redux';
const Fill_User_Permission = 'Fill_User_Permission';
const Empty_User_Permission = 'Empty_User_Permission';

const defaultPermissions = 
  {
    access: false,
    module_UserList_Access:false,

  page_ManageUsers_Access:false,
  tab_ManageUsersListing_Access:false,
  tab_InsuranceDetailsDownload_Access:false,
  action_ManageUsersListing_Add_Access:false,
  action_ManageUsersListing_Edit_Access:false,
  action_ManageUsersListing_View_Access:false,
  action_ManageUsersListing_Delete_Access:false,
  action_ManageUsersListing_AllDepartments_Access:false,
  action_ManageUsersListing_AddKra_Access:false,
  action_ManageUsersListing_ViewKra_Access:false,
  action_ManageUsersListing_DeleteKra_Access:false,
  tab_BulkImport_Access:false,
  action_UploadFile_Access:false,
  action_ViewStatus_Access:false,
  action_ManageDocuments_Upload_Access: false,
  action_ManageAchievementsAndBadges_Upload_Access: false,
  page_UserFeedback_Access:false,
  tab_AddUserFeedback_Access:false,
  tab_ViewUserFeedback_Access:false,
  action_ManageUsersListing_UploadCheckpoint_Access:false,
  action_ManageUsersListing_DeleteCheckpoint_Access:false,
  action_ManageUsersListing_AddComment_Access:false,
  }

export function FillUserPermission(module) {
  return {
    type: Fill_User_Permission,
    module,
  }
}
export function EmptyUserPermission() {
  return {
    type: Empty_User_Permission,defaultPermissions
  }
}

function UserPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_User_Permission:
            var data = action.module;
            var permissions=defaultPermissions;
            if (data) {
              permissions.module_UserList_Access = true;
              if (data.listPageMaster && data.listPageMaster.length > 0) {
                data.listPageMaster.forEach((page) => {
                  if (page.pageName === "Manage Users") {
                    permissions.page_ManageUsers_Access = true;
                    if (page.listTabMaster && page.listTabMaster.length > 0) {
                      page.listTabMaster.forEach((tabs) => {
                        if (tabs.tabName === "Users Listing") {
                          permissions.tab_ManageUsersListing_Access = true;
                          if (
                            tabs.listActionMaster &&
                            tabs.listActionMaster.length > 0
                          ) {
                            tabs.listActionMaster.forEach((actions) => {
                              if (actions.actionName === "Add") {
                                permissions.action_ManageUsersListing_Add_Access = true;
                              }
                              if (actions.actionName === "Edit") {
                                permissions.action_ManageUsersListing_Edit_Access = true;
                              }
                              if (actions.actionName === "View") {
                                permissions.action_ManageUsersListing_View_Access = true;
                              }
                              if (actions.actionName === "Delete") {
                                permissions.action_ManageUsersListing_Delete_Access = true;
                              }
                              if (actions.actionName === "All Departments") {
                                permissions.action_ManageUsersListing_AllDepartments_Access = true;
                              }
                              if (actions.actionName === "Add Kra") {
                                permissions.action_ManageUsersListing_AddKra_Access = true;
                              }
                              if (actions.actionName === "View Kra") {
                                permissions.action_ManageUsersListing_ViewKra_Access = true;
                              }
                              if (actions.actionName === "Delete Kra") {
                                permissions.action_ManageUsersListing_DeleteKra_Access = true;
                              }
                              if (actions.actionName === "Upload Checkpoint") {
                                permissions.action_ManageUsersListing_UploadCheckpoint_Access = true;
                              }
                              if (actions.actionName === "Delete Checkpoint") {
                                permissions.action_ManageUsersListing_DeleteCheckpoint_Access = true;
                              }
                              if (actions.actionName === "Comment") {
                                permissions.action_ManageUsersListing_AddComment_Access = true;
                              }
                            });
                          }
                        }
                        if (tabs.tabName === "Bulk Import") {
                          permissions.tab_BulkImport_Access = true;
                          if (
                            tabs.listActionMaster &&
                            tabs.listActionMaster.length > 0
                          ) {
                            tabs.listActionMaster.forEach((actions) => {
                              if (actions.actionName === "Upload File") {
                                permissions.action_UploadFile_Access = true;
                              }
                              if (actions.actionName === "View Status") {
                                permissions.action_ViewStatus_Access = true;
                              }
                            });
                          }
                        }
                        if (tabs.tabName === "Documents") {
                          permissions.tab_ManageUsersListing_Access = true;
                          if (
                            tabs.listActionMaster &&
                            tabs.listActionMaster.length > 0
                          ) {
                            tabs.listActionMaster.forEach((actions) => {
                              if (actions.actionName === "Upload") {
                                permissions.action_ManageDocuments_Upload_Access = true;
                              }
                            });
                          }
                        }
                        if (tabs.tabName === "Achievements And Badges") {
                          permissions.tab_ManageUsersListing_Access = true;
                          if (
                            tabs.listActionMaster &&
                            tabs.listActionMaster.length > 0
                          ) {
                            tabs.listActionMaster.forEach((actions) => {
                              if (actions.actionName === "Upload") {
                                permissions.action_ManageAchievementsAndBadges_Upload_Access = true;
                              }
                            });
                          }
                        }
                        if (tabs.tabName === "Insurance Details Download") {
                          permissions.tab_InsuranceDetailsDownload_Access = true;
                          
                        }
                      });
                    }
                  }
                  if (page.pageName === "User Feedback") {
                    permissions.page_UserFeedback_Access = true;
                    if (page.listTabMaster && page.listTabMaster.length > 0) {
                      page.listTabMaster.forEach((tabs) => {
                        if (tabs.tabName === "Add Feedback") {
                          permissions.tab_AddUserFeedback_Access = true;
                        }
                      });
                    }
                  }
                });
              }
            }
            else{
                return state = defaultPermissions;
            }
            
        case Empty_User_Permission:
            return state = defaultPermissions;
            
        default:
            return state;
            
    }
}

const UserPermissionApp = combineReducers({
    UserPermission
});

export default UserPermissionApp;