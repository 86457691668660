
import { http } from "./http-common";

class MeetingService {

    getMyMeetings = async (data: any): Promise<any> => {
        return await http.post<any>("/Meeting/GetMyMeetingsByUserId/GetMyMeetingsByUserId", data);
    };
    getInvitedMeetigs = async (data: any): Promise<any> => {
        return await http.post<any>("/Meeting/GetAllInvitedMeetingsByUserId/GetAllInvitedMeetingsByUserId", data);
    };
  
    getUserForMeeting = async (data:any): Promise<any> => {
        return await http.post<any>("/UserManagement/GetAllUsersForMeeting",data);
    }

    createMeeting = async (data:any): Promise<any> => {
        return await http.post<any>("/Meeting/CreateMeeting/CreateMeeting",data);
    }
  
    updateMeeting = async (data:any): Promise<any> => {
        return await http.post<any>("/Meeting/UpdateMeeting/UpdateMeeting",data);
    }
    
}

export const meetingService = new MeetingService();