import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Notes from "../../../components/styledComponent/Note";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import { SelectChangeEvent } from "@mui/material/Select";
import { Formik, getIn } from "formik";
import { masterreasonServices } from "../../../service/BrowniePoints/masterReason-services";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import InfoPopover from "../../../components/CommonInfoComponent";

const initialvalues = {
  id: "",
  perUserAssignment: "",
  perPointRs: "",
  minimumPointLimitForRedeemption: "",
  isAuthRedeem: "",
  redeemCycle: "",
  maximumLimitAssignByManagerToUser: "",
};

interface Reward {
  id: string;
  perUserAssignment: string;
  perPointRs: string;
  minimumPointLimitForRedeemption: string;
  isAuthRedeem: boolean;
  redeemCycle: any;
  maximumLimitAssignByManagerToUser: string;
}

let obj: Reward = {
  id: "",
  perUserAssignment: "",
  perPointRs: "",
  minimumPointLimitForRedeemption: "",
  isAuthRedeem: false,
  redeemCycle: "",
  maximumLimitAssignByManagerToUser: "",
};

enum redeemCycleEnum {
  Monthly = 1,
  Quatrly = 2,
  HalfYearly = 3,
  Yearly = 4,
}

export default function BrowniePoint() {
  const _permission = useSelector(
    (state: any) => state.BrowniePermissionApp.BrowniePermission
  );
  const navigate = useNavigate();
  const [cycle, setcycle] = React.useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [formValues, setFormValues] = React.useState<Reward>(obj);
  const [id, setId] = useState("");
  const [perUserAssignment, setperUserAssignment] = React.useState(
    formValues?.perUserAssignment
  );
  const [perPointRs, setperPointRs] = React.useState(formValues?.perPointRs);
  const [minimumPointLimitForRedeemption, setminimumPointLimitForRedeemption] =
    React.useState(formValues?.minimumPointLimitForRedeemption);
  const [isAuthRedeem, setisAuthRedeem] = React.useState(
    formValues?.isAuthRedeem
  );
  const [redeemCycle, setredeemCycle] = React.useState(formValues?.redeemCycle);
  const [
    maximumLimitAssignByManagerToUser,
    setmaximumLimitAssignByManagerToUser,
  ] = React.useState(formValues?.maximumLimitAssignByManagerToUser);
  const [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    if (_permission.page_Reward_Setting_Access !== true) {
      navigate("/dashboard");
    }
    getBrownieSetting();
  }, []);

  const getBrownieSetting = async () => {
    await masterreasonServices.getBrownieSetting().then((response: any) => {
      if (response.data.isError) {
      } else {
        setId(response?.data.id);
        setperUserAssignment(response?.data.perUserAssignment);
        setperPointRs(response?.data.perPointRs);
        setminimumPointLimitForRedeemption(
          response?.data.minimumPointLimitForRedeemption
        );
        setisAuthRedeem(response?.data.isAuthRedeem);
        setredeemCycle(response?.data.redeemCycle);
        setmaximumLimitAssignByManagerToUser(
          response?.data.maximumLimitAssignByManagerToUser
        );
      }
    });
  };

  const handleClickOpen = () => {
    setSubmit(false);
    setErrorMessage(false);
    setperUserAssignment("");
    setperPointRs("");
    setminimumPointLimitForRedeemption("");
    setisAuthRedeem(false);
    setredeemCycle("");
    setmaximumLimitAssignByManagerToUser("");
  };
  const [buttonDisable, setButtonDisable] = useState(false);
  const addUpdateBrownieSetting = async (values: any) => {
    try {
      await masterreasonServices
        .createUpdateBrownieSetting(values)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("Reward updated successfully.", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
            getBrownieSetting();
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setcycle(event.target.value as string);
  };

  const fieldValidationSchema = Yup.object().shape({
    perUserAssignment: Yup.string()
      .matches(/^[0-9]+$/, "Brownie Points can only contains Numbers ")
      .required("Brownie Points are required"),
    perPointRs: Yup.string()
      .matches(/^[0-9]+$/, "Brownie Point can only contains Numbers ")
      .required("Brownie Point is  required"),
    minimumPointLimitForRedeemption: Yup.string()
      .matches(/^[0-9]+$/, "Redeemption can only contains number")
      .required("Reedeemption is required"),
    maximumLimitAssignByManagerToUser: Yup.string()
      .matches(/^[0-9]+$/, "It can only contains Numbers ")
      .required("It's required to set Maximum Points"),
  });

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Brownie Point</Typography>
        <Typography color="text.primary">Reward Setting</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3 }}>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 3, mt: 2 }}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            Reward Setting
          </Typography>
          <InfoPopover  onInput={"Reward Setting"}/>
          
          <Box sx={{ flexGrow: 1 }} />
        </Stack>

        <Formik
          initialValues={{
            id: id,
            perUserAssignment: perUserAssignment,
            perPointRs: perPointRs,
            minimumPointLimitForRedeemption: minimumPointLimitForRedeemption,
            isAuthRedeem: isAuthRedeem,
            redeemCycle: redeemCycle,
            maximumLimitAssignByManagerToUser:
              maximumLimitAssignByManagerToUser,
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(true);

              await addUpdateBrownieSetting(values);
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {
                {
                  err.data.Error.map((error: any, idx: any) => {
                    setErrorMessage(error);
                  });
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <Box
                  sx={{
                    maxWidth: "760px",
                    margin: " 0 auto",
                  }}
                >
                  <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">
                        Brownie Points Assignment
                      </Typography>
                      <Typography variant="subtitle1">
                        (Per month/user)
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        error={Boolean(
                          getIn(touched, "perUserAssignment") &&
                            getIn(errors, "perUserAssignment")
                        )}
                        fullWidth
                        helperText={
                          getIn(touched, "perUserAssignment") &&
                          getIn(errors, "perUserAssignment")
                        }
                        name="perUserAssignment"
                        value={perUserAssignment}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          values.perUserAssignment = e.currentTarget.value
                          setperUserAssignment(e.currentTarget.value);
                        }}
                        inputProps={{ maxLength: 4 }}
                      />
                    </Box>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ textAlign: "right", color: "#757575" }}
                    ></Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">1 Brownie Point</Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        name="perPointRs"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CurrencyRupeeIcon />
                            </InputAdornment>
                          ),
                        }}
                        onBlur={handleBlur}
                        value={perPointRs}
                        error={Boolean(
                          getIn(touched, "perPointRs") &&
                            getIn(errors, "perPointRs")
                        )}
                        helperText={
                          getIn(touched, "perPointRs") &&
                          getIn(errors, "perPointRs")
                        }
                        onChange={(e) => {
                          values.perPointRs = e.currentTarget.value;
                          setperPointRs(e.currentTarget.value);
                          setErrorMessage(false);
                        }}
                      />
                    </Box>
                  </Stack>

                   <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">
                        Set minimum limit of Brownie Point for redemption
                      </Typography>
                      <Typography variant="subtitle1">
                        (for employee)
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        error={Boolean(
                          getIn(touched, "minimumPointLimitForRedeemption") &&
                            getIn(errors, "minimumPointLimitForRedeemption")
                        )}
                        fullWidth
                        helperText={
                          getIn(touched, "minimumPointLimitForRedeemption") &&
                          getIn(errors, "minimumPointLimitForRedeemption")
                        }
                        onBlur={handleBlur}
                        name="minimumPointLimitForRedeemption"
                        value={minimumPointLimitForRedeemption}
                        onChange={(e) => {
                          values.minimumPointLimitForRedeemption =
                            e.currentTarget.value;
                          setminimumPointLimitForRedeemption(
                            e.currentTarget.value
                          );
                          //  setErrorMessage(false);
                        }}
                      />
                    </Box>
                  </Stack> 

                  {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">
                        Is auto redemption applicable
                      </Typography>
                      <Typography variant="subtitle1">(for HR)</Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          defaultValue="no"
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                            onChange={(e) => {
                              values.isAuthRedeem = true;
                              setisAuthRedeem(true);
                              setErrorMessage(false);
                            }}
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                            onChange={(e) => {
                              values.isAuthRedeem = false;
                              setisAuthRedeem(false);
                              setErrorMessage(false);
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Stack> */}

                  {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">
                        Define redemption cycle
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <FormControl fullWidth>
                        <TextField
                          label="Select Redeem Cycle"
                          id="demo-simple-select"
                          select
                          variant="outlined"
                          value={values.redeemCycle}
                          name="redeemCycle"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={(e) => {
                            values.redeemCycle = e.target.value;
                            setredeemCycle(e.target.value);
                          }}
                          error={Boolean(
                            getIn(touched, "redeemCycle") &&
                              getIn(errors, "redeemCycle")
                          )}
                          helperText={
                            getIn(touched, "redeemCycle") &&
                            getIn(errors, "redeemCycle")
                          }
                        >
                          <MenuItem value={1}>Monthly</MenuItem>
                          <MenuItem value={2}>Quarterly</MenuItem>
                          <MenuItem value={3}>Half Yearly</MenuItem>
                          <MenuItem value={4}>Yearly</MenuItem>
                        </TextField>
                      </FormControl>
                    </Box>
                  </Stack> */}

                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">
                        Set the maximum brownie points limit manager can assign
                        to an employee in a month
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        error={Boolean(
                          getIn(touched, "maximumLimitAssignByManagerToUser") &&
                            getIn(errors, "maximumLimitAssignByManagerToUser")
                        )}
                        fullWidth
                        helperText={
                          getIn(touched, "maximumLimitAssignByManagerToUser") &&
                          getIn(errors, "maximumLimitAssignByManagerToUser")
                        }
                        onBlur={handleBlur}
                        name="maximumLimitAssignByManagerToUser"
                        value={values.maximumLimitAssignByManagerToUser}
                        onChange={(e) => {
                          values.maximumLimitAssignByManagerToUser =
                            e.currentTarget.value;
                          setmaximumLimitAssignByManagerToUser(
                            e.currentTarget.value
                          );
                          setErrorMessage(false);
                        }}
                      />
                    </Box>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{ mb: 3 }}
                  >
                    <Box sx={{ minWidth: "60%", textAlign: "right" }}>
                      <Typography variant="h6">&nbsp;</Typography>
                    </Box>
                    <Box sx={{ minWidth: "40%" }}>
                      <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={handleClickOpen}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={buttonDisable}
                        onClick={() => {
                          setErrorMessage(true);
                          setSubmit(true);
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </>
            </form>
          )}
        </Formik>
      </Paper>
    </>
  );
}
