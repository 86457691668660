import { http } from "../http-common";
class PayRewardServices {
    getAllPendingRewardPointsOfUser = async (parmas: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/GetAllPendingRewardPointsOfUser", parmas);
      };
      ApprovePaid = async (parmas: any): Promise<any> => {
        return await http.post<any>("/v1/BrownieReason/AddRedeemPointsByHR", parmas);
      };
  }
export const payRewardServices = new PayRewardServices();
