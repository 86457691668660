import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, IconButton, Menu, MenuItem, TablePagination, Tooltip, Typography } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as Icons from "../../assests/icons/icons";
import { inventoryService } from "../../service/Inventory/inventoryService";
import { toast } from "material-react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import { attributeenum } from "../users/categorymodel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaginationControls from "../../components/PaginationComponent";
import { clsCategoryList } from "../../service/common-services/category";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import SearchBar from "../../components/styledComponent/SearchBar";
import { useState } from "react";

const toggleloaders = {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
}

const headcellcolor = {
    cellcolor: {
        background: 'rgb(189, 189, 189)',
        borderBottom: "none"
    },
}
export default function InventoryList() {
    const _permission = useSelector(
        (state: any) => state.InventoryPermissionApp.InventoryPermission
    );
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');

    }
    const navigate = useNavigate();
    const { Id } = useParams();
    const [catId, setcatId] = React.useState<any>(Id || "");
    const [selectedCatId, setSelectedCatId] = React.useState<any>("");
    const [ItemList, SetItemList] = React.useState<any[]>([]);
    let itemCount = ItemList.length;
    const [categories, Setcategories] = React.useState<any>([]);
    const [brands, Setbrands] = React.useState<any[]>([]);
    const [attributes, setattributes] = React.useState<attributeenum[]>([]);
    const [expanded, setExpanded] = React.useState(false);
    const [expandedBrand, setExpandedBrand] = React.useState<number[]>([]);
    const [statusDataHook, setStatusDataHook] = React.useState("All");
    const [itemStatus, setItemStatus] = React.useState(null);
    const [toggleLoader, setToggleLoader] = React.useState(false);
    const [searchValue, setSearchValue] = useState("");
    const filterItemByStatus = (status: any, statusValue: any) => {
        // statusValue = status;
        setAnchorEl(null);
        setStatusDataHook(status);
        setItemStatus(statusValue);
        getAllInventoryItems(catId, selectedBrands, setConfiguration, statusValue, searchValue);
    };
    interface StatusEnum {
        [key: number]: string;
    }
    const [selectedBrands, setSelectedBrands] = React.useState<any[]>([]);
    const [setConfiguration, setSetConfiguration] = React.useState<attributeenum[]>([]);
    console.log(setConfiguration, "setConfigurationsetConfiguration")
    const statusEnum: StatusEnum = {
        1: 'In Stock',
        2: 'Issued',
        3: 'Request to Return'
    };

    function getStatusName(status: number) {
        return statusEnum[status] || '';
    }

    const handleBrandCheckboxChange = (brandName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSelectedBrands = event.target.checked
            ? [...selectedBrands, brandName]
            : selectedBrands.filter((name) => name !== brandName);

        setSelectedBrands(updatedSelectedBrands);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    // const handleValueCheckboxChange = (attribute: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => {
    //     const updatedSelectedAttributes = [...setConfiguration]; // Create a copy of the selectedAttributes array

    //     // Check if the selected attribute is already in the array
    //     const selectedAttributeIndex = updatedSelectedAttributes.findIndex(attr => attr.attribute === attribute);

    //     if (selectedAttributeIndex === -1) {
    //         // If the attribute is not in the array, add it with the selected value
    //         updatedSelectedAttributes.push({ attribute, value: [value] });
    //     } else {
    //         // If the attribute is already in the array, update its values
    //         const selectedAttribute = updatedSelectedAttributes[selectedAttributeIndex];
    //         if (event.target.checked) {
    //             selectedAttribute.value.push(value);
    //         } else {
    //             selectedAttribute.value = selectedAttribute.value.filter((val: any) => val !== value);
    //         }
    //     }
    //     setSetConfiguration(updatedSelectedAttributes);
    //     getAllInventoryItems(catId, selectedBrands, setConfiguration);
    // };

    const handleValueCheckboxChange = (attribute: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        debugger
        const updatedSelectedAttributes = [...setConfiguration]; // Create a copy of the selectedAttributes array
        // Check if the selected attribute is already in the array
        const selectedAttributeIndex = updatedSelectedAttributes.findIndex(attr => attr.attribute === attribute);

        if (selectedAttributeIndex === -1) {
            // If the attribute is not in the array, add it with the selected value
            updatedSelectedAttributes.push({ attribute, value: [value] });
        } else {
            // If the attribute is already in the array, update its values
            const selectedAttribute = updatedSelectedAttributes[selectedAttributeIndex];
            if (event.target.checked) {
                selectedAttribute.value.push(value);
            } else {
                selectedAttribute.value = selectedAttribute.value.filter((val: any) => val !== value);
                // Check if there are no selected values left for this attribute, and if so, remove the attribute
                if (selectedAttribute.value.length === 0) {
                    updatedSelectedAttributes.splice(selectedAttributeIndex, 1);
                }
            }
        }
        setSetConfiguration(updatedSelectedAttributes);
        getAllInventoryItems(catId, selectedBrands, updatedSelectedAttributes, itemStatus,searchValue); // Pass the updatedSelectedAttributes instead of setConfiguration
    };

    React.useEffect(() => {
        if (_permission.page_ManageItemListing_Access != true) {
            navigate("/dashboard");
        }

        if (Id != null && Id !== "") {
            getCategoryDetailsById(Id);
            setSelectedCatId(Id);
            setcatId(Id);
            // getAllInventoryItems(Id, [], [], itemStatus);
        }
        categoryData();
        // getAllInventoryItems(Id?Id:catId, selectedBrands, setConfiguration, itemStatus);

    }, []);
    React.useEffect(() => {
        debugger
        getAllInventoryItems(catId, selectedBrands, setConfiguration, itemStatus,searchValue);
    }, [catId, selectedBrands, setConfiguration, itemStatus,searchValue]);

    const categoryData = async () => {
        Setcategories(await clsCategoryList.getCategoryDropdownList())
    }

    //Get All Inventory Items --Api call
    const getAllInventoryItems = async (categoryId: any, brand: any[], setSetConfiguration: any[], status: any,search:any) => {
        try {
            setToggleLoader(true);
            const brandObject = brand.map((brandName) => ({ brandName }));
            let input = {
                categoryId: categoryId,
                brand: brandObject,
                setConfiguration: setSetConfiguration,
                status: status,
                search:search
            }
            inventoryService.getAllInventoryItems(input).then((response: any) => {
                if (response.data.isError) {
                    setToggleLoader(false);
                } else {
                    setToggleLoader(false);
                    SetItemList(response?.data);
                    setPage(0);
                    // console.log(ItemList);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false);
                toast.warning(err);
            });
        }
    }

    const deleteItembyId = async (id: any) => {
        await inventoryService.deleteItembyId(id).then((response: any) => {
            if (response.data.isError) {
            }
            else {
                toast.success('Item Deleted successfully.');
                getAllInventoryItems(catId, [], [], itemStatus,searchValue);
            }
        })
    }

    const getCategoryDetailsById = async (catId: any) => {
        try {
            await inventoryService.getCategorybyId(catId)
                .then(async (response: any) => {
                    if (response.data.isError) {
                        toast.warning("Error while fetching data");
                    } else {
                        Setbrands([]);
                        Setbrands(response.data.brand);
                        setattributes(response.data.setConfiguration);
                        setcatId(catId);
                        // getAllInventoryItems(catId, [], []);
                    }
                });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
            });
        }
    };

    const dummy = [
        { value: 10 },
        { value: 15 },
        { value: 20 },
        { value: 25 },
        { value: 30 },
        // Add more options if needed
    ];
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const pageCount = Math.ceil(ItemList.length / rowsPerPage);

    const [page, setPage] = React.useState(0);
    const handleChangeRowsPerPage = (event: any) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRowsPerPage(selectedValue);
        setPage(0);
    };

    const handleDecrement = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleIncrement = () => {
        setPage((prevPage) => Math.min(prevPage + 1, numCount - 1));
    };
    const numCount = Math.ceil(ItemList.length / rowsPerPage);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageData = ItemList.slice(startIndex, endIndex);

    const clearAll = () => {
        setSelectedBrands([]); setSetConfiguration([]); setcatId("");
        setSelectedCatId(""); setExpanded(false); setattributes([]);
        setItemStatus(null); setStatusDataHook("All"); setExpandedBrand([]); Setbrands([]);
    }

    const handleAccordionChange = (index: number, attribute: string) => {
        if (expandedBrand.includes(index)) {
            // If the index is already in the array, remove it.
            setExpandedBrand(expandedBrand.filter((item) => item !== index));

            // Remove the associated attribute from updatedSelectedAttributes
            const updatedSelectedAttributes = setConfiguration.filter(attr => attr.attribute !== attribute);
            debugger
            setSetConfiguration(updatedSelectedAttributes);
        } else {
            // If the index is not in the array, add it.
            setExpandedBrand([...expandedBrand, index]);
        }
    };
    const triggerSearch = async (newValue: string) => {
        setSearchValue(newValue)
        //getNoticeDetails(statusDataHook, newValue);
        setPage(0);
    };

    return (
        <>
            {toggleLoader && (<CircularProgress sx={toggleloaders} />)}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '10px' }}>
                    <Link color="inherit" to="/">
                        Manage Items
                    </Link>

                </Breadcrumbs>
                <Stack  alignItems={'center'} justifyContent={'right'} direction={'row'} >
                 <Box  sx={{ mb: 2, mr: 2 }}>
                 <SearchBar
                        value={searchValue}
                        setSearchValue={setSearchValue}
                        callBackFunction={triggerSearch}
                    />
                 </Box>
                    {_permission.action_ManageItemListing_Add_Access == true && (
                        <Button
                            variant="contained"
                            size="large"
                            component={Link}
                            to="/inventory-additem"
                            color="primary"
                            sx={{ mb: 2 }}
                            startIcon={<AddIcon />}
                        >
                            Add Item
                        </Button>
                    )}

                </Stack>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box height={'auto'}>
                            <Paper sx={{
                                height: '100%', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Stack p={2} justifyContent={'space-between'} alignItems={'start'} sx={{ borderBottom: "1px solid #EEEEEE", background: '#F0F9FF', borderRadius: '4px' }}>

                                    <Autocomplete
                                        disablePortal
                                        value={categories?.find(
                                            (option: any) => option.id == selectedCatId
                                        ) ?? null}
                                        id="combo-box-demo"
                                        options={categories}
                                        getOptionLabel={(option: any) => option.category}
                                        onChange={async (event, value) => {
                                            if (!value?.id) {
                                                clearAll();
                                            }
                                            else {
                                                getCategoryDetailsById(value?.id);
                                                setSelectedCatId(value?.id ?? "")
                                                getAllInventoryItems(value?.id, [], [], itemStatus,searchValue);
                                            }
                                        }}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Select Category" />}
                                    />
                                    <Typography sx={{ margin: ' 0 0 0 16px', fontSize: '14px', fontWeight: '300' }}>
                                        {itemCount} Items
                                    </Typography>
                                    <Box>
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick1}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            {statusDataHook}
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => { filterItemByStatus("All", null); }}>All</MenuItem>
                                            <MenuItem onClick={() => { filterItemByStatus("Assigned", "2"); }}>Assigned</MenuItem>
                                            <MenuItem onClick={() => { filterItemByStatus("In Stock", "1"); }}>In Stock</MenuItem>
                                        </Menu>
                                        <Button sx={{ ml: "20px" }} onClick={() => {
                                            setSelectedBrands([]); setSetConfiguration([]); setcatId("");
                                            setSelectedCatId(""); setExpanded(false); setattributes([]);
                                            setItemStatus(null); setStatusDataHook("All"); setExpandedBrand([]); Setbrands([]);
                                            setSearchValue("");
                                        }}>Clear All</Button>
                                    </Box>
                                </Stack>
                                <Box sx={{ padding: '18px' }}>
                                    <Typography sx={{ marginBottom: "10px", display: "flex", fontWeight: '500' }}>
                                        <FilterAltOutlinedIcon color="primary" /> Filter
                                    </Typography>
                                    <Accordion expanded={expanded} sx={{ boxShadow: "none" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            onClick={() => setExpanded(!expanded)}
                                        >
                                            <Typography fontWeight="500">Brand</Typography>
                                        </AccordionSummary>
                                        {brands.map((brand) => (
                                            <Stack key={brand.id} paddingLeft={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedBrands.includes(brand.brandName)}
                                                            onChange={handleBrandCheckboxChange(brand.brandName)}
                                                        />
                                                    }
                                                    label={brand.brandName}
                                                />
                                            </Stack>
                                        ))}
                                    </Accordion>
                                    {attributes.map((att, index) => (
                                        <Accordion
                                            expanded={expandedBrand.includes(index)}
                                            key={index}
                                            sx={{ boxShadow: "none" }}
                                            onChange={() => handleAccordionChange(index, att.attribute)}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${index}a-content`}
                                                id={`panel${index}a-header`}
                                            >
                                                <Typography fontWeight="500">{att.attribute}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {att.value.map((value, valueIndex) => (
                                                    <Stack key={value} paddingLeft={3}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={setConfiguration.some((item: any) => item.value.includes(value))}
                                                                    onChange={(event) =>
                                                                        handleValueCheckboxChange(att.attribute, value, event)
                                                                    }
                                                                    value={valueIndex}
                                                                />
                                                            }
                                                            label={value}
                                                        />
                                                    </Stack>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'100%'} >
                            <Paper sx={{
                                height: '100%', padding: '16px', paddingBottom: '0', "& .css-1ps6pg7-MuiPaper-root": {
                                    height: '100%'
                                }
                            }} >
                                <Box>
                                    <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 400 }}>
                                        <Table sx={{ minWidth: 650 }}  >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={headcellcolor.cellcolor}>S.No</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Item Type</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>SKU</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Brand</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Model</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Department</TableCell>
                                                    {/* <TableCell sx={headcellcolor.cellcolor}>Date of Purchase</TableCell> */}
                                                    <TableCell sx={headcellcolor.cellcolor}>Status</TableCell>
                                                    <TableCell sx={headcellcolor.cellcolor}>Assigned To</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', background: 'rgb(189, 189, 189)' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentPageData.map((item: any, index: any) => (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row"> {index + 1} </TableCell>
                                                        <TableCell component="th" scope="row"> {item.itemName} </TableCell>
                                                        <TableCell>{item.sku}</TableCell>
                                                        <TableCell key={index}>
                                                            {item.brand.map((brandItem: any, brandIndex: any) => (
                                                                <span key={brandIndex}>
                                                                    {brandItem.brandName}
                                                                    {brandIndex < item.brand.length - 1 ? ', ' : ''}
                                                                </span>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>{item.modelName ? item?.modelName : "--"} </TableCell>
                                                        <TableCell>{item.department || "--"}</TableCell>
                                                        {/* <TableCell >{item.dateOFPurchase ? moment(item.dateOFPurchase).format("DD-MMM-YYYY") : "--"}</TableCell> */}
                                                        <TableCell>{getStatusName(item.status)}</TableCell>
                                                        <TableCell>{item?.assignToName ? item?.assignToName : "--"}</TableCell>
                                                        <TableCell >
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="start"
                                                                spacing={1}
                                                            >
                                                                {_permission.action_ManageItemListing_Edit_Access == true && (
                                                                    <Tooltip title="Edit">
                                                                        < IconButton
                                                                            aria-label="edit"
                                                                            component={Link}
                                                                            to={`/inventory-edititem/${item.id}/${item.categoryId}`}
                                                                            size="medium"
                                                                        >
                                                                            <Icons.Edit />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}

                                                                {_permission.action_ManageItemListing_View_Access == true && (
                                                                    <IconButton
                                                                        aria-label="view"
                                                                        component={Link}
                                                                        to={`/inventory-viewitem/${item.id}`}
                                                                        size="medium"
                                                                    >
                                                                        <Icons.View />
                                                                    </IconButton>
                                                                )}
                                                                {_permission.action_ManageItemListing_Delete_Access == true && (
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            deleteItembyId(item.id);
                                                                        }}
                                                                    >     <Icons.Delete />
                                                                    </IconButton>
                                                                )}
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* <PaginationControls
                                        rowsPerPage={rowsPerPage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        dummy={dummy}
                                        page={page}
                                        numCount={numCount}
                                        handleDecrement={handleDecrement}
                                        handleIncrement={handleIncrement}
                                    /> */}
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                        component="div"
                                        count={ItemList.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelDisplayedRows={() => `Total: ${ItemList?.length}  Page ${page + 1} of ${pageCount}`}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </Grid >

                </Grid >
            </Box >
        </>
    )
}
