import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../../theme/theme";
import ProjectImg from "../../assests/images/new_project.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const styles = {
  PaperBox: {
    height: "calc(100vh - 150px)",
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
  },
};

export default function CreateProject() {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const cancelbutton = async () => {
    navigate("/project-management");
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        {/* <Link color="inherit" to="/project-management">
        Project Management
        </Link>      */}
      </Breadcrumbs>
      <Paper elevation={0} sx={[styles.PaperBox]}>
        <Box sx={{ textAlign: "center" }}>
          <img src={ProjectImg} />

          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              component={Link}
              to="/project/add-project"
              color="primary"
              startIcon={<AddIcon />}
            >
              Create new project
            </Button>
          </Box>
          {/* <Box sx={{ mt: 3 }}>
            <Button
              onClick={() => cancelbutton()}
              color="primary"
            >
              Cancel
            </Button>
          </Box> */}
        </Box>
      </Paper>
    </>
  );
}
