import { http } from "../http-common";
class CoHODservices {


    getCoHod = async (hod:any): Promise<any> => {
        return await http.post<any>("/v1/Master/CoHod/GetAll", hod);
    };

    updateCoHod = async (desig: any): Promise<any> => {
        return await http.post<any>("/v1/Master/UpdateDepartmentCoHod", desig);
    };

    getManagerDetails = async(id: any): Promise<any> => {
        return await http.post<any>(`/v1/Master/ManagerName/GetByDepartmentId?departmentId=${id}`);
    }
   
}

export const coHodServices = new CoHODservices();