
import { http } from "./http-common";

class LearningsessionServices {

    getAllSession = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Learning/GetAllLearningSession", data);
    };
    addSession = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Learning/AddLearningSession", data);
    };
    deleteSession = async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/Learning/DeleteLearningSession?id=${id}`);
    };
    updateSessionById = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Learning/UpdateLearningSession",data);
    };
    uploadSessionImage = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Learning/UploadSessionImage",data);
    };
}

export const learningsessionServices = new LearningsessionServices();