
import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, MenuItem, Modal, Paper, Radio, Stack, TextField, Typography } from "@mui/material";
import { toast } from "material-react-toastify";
import { Formik } from "formik";
import DialogActions from "@mui/material/DialogActions";
import { votepollingService } from "../../service/Votepolling-service";
import { Wheel } from "react-custom-roulette";
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';

export default function SecretSantaParticipate() {
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
    };
    const [isProfitMakingYes, setIsProfitMakingYes] = useState(false);
    const [isRegister, setIsRegister] = useState("");
    const [isProfitMakingNo, setIsProfitMakingNo] = useState(false);
    const [StaticStatusId, setStaticStatusId] = useState("");
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const [title, setTitle] = React.useState("");
    const staticStatus = [
        {
            value: "1",
            label: "Ground Floor",
        },
        {
            value: "2",
            label: "First Floor",
        },
        {
            value: "3",
            label: "Moogles Floor",
        },
    ];

    useEffect(() => {
        getSecretSantaDetail();
    }, []);

    const addSecretSantaDetail = async () => {
        setOpenDelete(false);
        var req = {
            description: title,
            floors: StaticStatusId ? StaticStatusId : 0,
            participant: isProfitMakingYes,
        };
        try {
            await votepollingService.addSantaDetail(req).then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong while fetching data");

                } else {
                    toast.success("Your Response has been saved successfully.");
                    setIsProfitMakingNo(false);
                    getSecretSantaDetail();
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    }


    const getSecretSantaDetail = async () => {
        try {
            const response = await votepollingService.getSantaDetail("");
            if (response.data.isError) {
                toast.error("Something went wrong while fetching data");
            } else {
                if (response.data.participant == true) {
                    setIsRegister("1")
                    setIsProfitMakingYes(false);
                }
                else {
                    setIsRegister("2");
                    setIsProfitMakingYes(false);
                }
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setIsRegister("")
            });
        }
    };
    const getSecretSenataForYourSelf = async () => {
        debugger
        try {
            const response = await votepollingService.getSecretSenataForYourSelf("");
            if (response.data.isError) {
                setToggleLoader(false)
                toast.error("Something went wrong while fetching data");
            } 
            else if (response.status == 204){
                setToggleLoader(false)
                toast.warn("You are the only one registered and have no one to exchange gifts with; please try after sometime or contact your manager for assistance.")
            }
            
            else {
                setTimeout(() => {
                    setSecretSentaInfo(response?.data);
                    setToggleLoader(false)
                }, 3500)
            }
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                setToggleLoader(false)
                toast.warn(err)
            });
        }
    };
    const handleYesCheckboxChange = (event: any) => {
        setIsProfitMakingYes(event.target.checked);
        setIsProfitMakingNo(!event.target.checked);
    };
    const handleNoCheckboxChange = (event: any) => {
        setIsProfitMakingNo(event.target.checked);
        setIsProfitMakingYes(!event.target.checked);
        setStaticStatusId("");
        setTitle("");
    };
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setIsProfitMakingYes(false)

    };
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const data = [
        { option: 'Ground Floor', style: { backgroundColor: '#ff2e2e', textColor: 'white' } },
        { option: 'First Floor', style: { backgroundColor: 'white' } },
        { option: 'Moogle Floor', style: { backgroundColor: '#1cd36b', textColor: 'white' } },
    ]
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [secretSentaInfo, setSecretSentaInfo] = useState<any>("");


    console.log(secretSentaInfo, "secretSentaInfo")

    const handleSpinClick = () => {
        debugger
        setIsProfitMakingYes(false);
        setToggleLoader(true)
        if (!mustSpin) {
            getSecretSenataForYourSelf();
            // const newPrizeNumber = Math.floor(Math.random() * data.length);
            // setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }
    }
    const [toggleLoader, setToggleLoader] = React.useState(false);

    const handleEdit = (e:any) => {
        setIsProfitMakingYes((prev) => {
            const newValue = !prev;
            if (newValue) {
                getChristmasUserDetailByUserId();
            }
            return newValue;
        });
    };

    const getChristmasUserDetailByUserId = async () => {
        try {
            await votepollingService.getSantaDetail("").then(async (response: any) => {

                if (response.data.isError) {
                    toast.error("Something went wrong while fetching  data ...");
                } else {
                   // await setIsProfitMakingYes(response?.data?.participant)
                    await setStaticStatusId(response?.data?.floors);
                    await setTitle(response?.data?.description);
                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    };
    const targetDate = new Date('December 18, 2024 01:00:00 PM').getTime();
    console.log(targetDate,"targetDate")
    console.log(new Date().getTime(),"new Date().getTime()")
    const fieldValidationSchema = Yup.object().shape({
        title: Yup
            .string()
            .required('Bio is required'),
        floor: Yup
            .string()
            .required('Floor is required'),
    })
    return (
        <>
            <Formik
                initialValues={{
                    title: title,
                    floor: StaticStatusId,

                }}
                validationSchema={fieldValidationSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (isRegister =="1"){
                            addSecretSantaDetail()
                        }
                        else{
                        handleOpenDelete();}
                    } catch (ex: any) {
                        if (ex.status == 400) {
                            console.error("ex:", ex);
                            ex.data.Error.map((error: any, idx: any) => {
                                toast.error(error);
                            })
                        }
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    handleReset,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Paper elevation={0} sx={{ p: 3, height: 'auto' }} >
                            <Paper elevation={0} sx={{ p: 3, maxWidth: "600px", margin: "0 auto" }}>
                                {
                                    isRegister === "" ? (
                                        <Stack direction="row" flexDirection='column' alignItems="center" justifyContent="start" sx={{ pb: 3, borderBottom: '1px solid #EEE', }}>
                                            {/* <Typography variant="subtitle1" sx={{ fontSize: "24px", fontWeight: "500" }}>
                                                Do You Want To Participate In Secret Santa... ?
                                            </Typography> */}
                                            {new Date().getTime() >= targetDate ? (<span style={{ fontWeight: "bold", color: "#FF6347" }}>Sorry You are late, Try Next Year!</span>) : (
                                                <>
                                                    <Stack direction="row" justifyContent="center" sx={{ mb: 1 }}>
                                                        <Box sx={{ display: "inlineBlock", background: "#fdf0f0", fontSize: "24px", textAlign: "center", fontWeight: "600", mt: 1, color: "#ff2e2e", padding: "6px 12px", borderRadius: "6px", }}>
                                                            Do You Want To Participate In Secret Santa ? 🎄
                                                        </Box>
                                                    </Stack>
                                                    <Box sx={{ width: 'auto' }}>
                                                        <Stack direction={'row'} flexWrap={'wrap'}>
                                                            <Box>
                                                                <Radio
                                                                    checked={isProfitMakingYes}
                                                                    onChange={handleYesCheckboxChange}
                                                                    name="isProfitMakingYes"
                                                                    id="isProfitMakingYes"
                                                                />
                                                                Yes
                                                            </Box>
                                                            <Box>
                                                                <Radio
                                                                    checked={isProfitMakingNo}
                                                                    onChange={(e: any) => { handleNoCheckboxChange(e); values.floor = ""; values.title = ""; handleReset(); }}
                                                                    name="isProfitMakingNo"
                                                                    id="isProfitMakingNo"
                                                                />
                                                                No
                                                            </Box>
                                                        </Stack>
                                                    </Box></>)}
                                        </Stack>
                                    ) : (
                                        isRegister === "1" ? (
                                            <Box>
                                                <Typography sx={{ padding: "6px 12px", borderRadius: "6px", fontSize: "20px", background: "#e5f9ff", textAlign: "center", color: "#1DA7FF", fontWeight: "500", fontStyle: "italic", position: 'relative' }}>
                                                    Congratulations! You have successfully chosen to participate in Secret Santa
                                                    {(!secretSentaInfo &&  !toggleLoader) && (
                                                        <IconButton
                                                            sx={{ fontSize: "16px", ml: 1, position: 'absolute', bottom: '0', right: '0' }} onClick={handleEdit}>
                                                                <EditIcon sx={{ fontSize: "16px" }} /></IconButton>
                                                            )}
                                                </Typography>
                                                <Stack direction="row" justifyContent="center" >
                                                    <Box sx={{ display: "inlineBlock", background: "#fdf0f0", fontSize: "24px", textAlign: "center", fontWeight: "600", mt: 1, color: "#ff2e2e", padding: "6px 12px", borderRadius: "6px", fontStyle: "italic" }}>
                                                        🎅 On 24 Dec 🎅
                                                    </Box>

                                                </Stack>
                                                {!secretSentaInfo ? (
                                                    <Stack direction="row" justifyContent="center" flexDirection="column" alignItems="center">
                                                        {new Date().getTime() >= new Date('December 17, 2024 03:00:00 PM').getTime() && (
                                                            <Button
                                                                variant="contained"
                                                                sx={{ mt: 4 }}
                                                                onClick={handleSpinClick}
                                                            >
                                                                Whose Secret Santa Are You?
                                                            </Button>
                                                  )}
                                                        <Box sx={{
                                                            display: 'flex', justifyContent: 'center', alignItems: 'center', "& .bhdLno": {
                                                                width: '50vw', maxWidth: "300px", maxHeight: '300px', marginTop: "12px"
                                                            }
                                                        }}>
                                                            {toggleLoader && (
                                                                <Wheel
                                                                    mustStartSpinning={mustSpin}
                                                                    prizeNumber={prizeNumber}
                                                                    data={data}
                                                                    outerBorderWidth={1.5}
                                                                    radiusLineWidth={1.5}
                                                                    onStopSpinning={() => {
                                                                        setMustSpin(false);
                                                                        setToggleLoader(false);
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Stack>
                                                ) : (
                                                    <><Typography sx={{
                                                        mt: 2, padding: "6px 12px", borderRadius: "6px", fontSize: "20px",
                                                        background: "#e5f9ff", textAlign: "center", color: "#1DA7FF", fontWeight: "500", fontStyle: "italic", position: 'relative'
                                                    }}>
                                                        {secretSentaInfo ? (
                                                            <>
                                                                You are the secret Santa of{" "}
                                                                <span style={{ fontWeight: "bold", color: "#FF6347" }}>
                                                                    {secretSentaInfo?.AssignedSantato || "--"}
                                                                </span>{" "}
                                                                of Dept  <span style={{ fontWeight: "bold", color: "#FF6347" }}>
                                                                    {secretSentaInfo?.Department || "--"}
                                                                </span>{" "}
                                                            </>
                                                        ) : ""}
                                                    </Typography>
                                                        {secretSentaInfo?.Description && (
                                                            <Typography sx={{ mt: 2, padding: "6px 12px", borderRadius: "6px", fontSize: "20px", background: "#e5f9ff", textAlign: "center", color: "#1DA7FF", fontWeight: "500", fontStyle: "italic", position: 'relative' }}>
                                                                <>
                                                                    Something about{" "}
                                                                    <span style={{ fontWeight: "bold", color: "#FF6347" }}>
                                                                        {secretSentaInfo?.AssignedSantato || "--"}
                                                                    </span>
                                                                    {" "}:{" "} {secretSentaInfo?.Description || "--"}
                                                                </>
                                                            </Typography>)}</>
                                                )}
                                            </Box>
                                        ) : (
                                            isRegister === "2" ? (
                                                <Box>
                                                    <Typography sx={{ padding: "6px 12px", borderRadius: "6px", fontSize: "20px", background: "#e5f9ff", textAlign: "center", color: "#1DA7FF", fontWeight: "500", fontStyle: "italic" }}>
                                                        You Haven't Participated In Secret Santa
                                                    </Typography>
                                                    <Stack direction="row" justifyContent="center">
                                                        <Box sx={{ display: "inlineBlock", background: "#fdf0f0", fontSize: "24px", textAlign: "center", fontWeight: "600", mt: 1, color: "#ff2e2e", padding: "6px 12px", borderRadius: "6px", fontStyle: "italic" }}>
                                                            🎅 On 24 Dec 🎅
                                                        </Box>
                                                    </Stack>

                                                </Box>
                                            ) : null
                                        )
                                    )
                                }

                                {isProfitMakingNo && (
                                    <>
                                        <Typography sx={{ padding: "6px 12px", borderRadius: "6px", fontSize: "24px", textAlign: "center", color: "#1DA7FF", fontWeight: "500", fontStyle: "italic" }}>
                                            Bye Bye...! 👋

                                        </Typography>
                                        <DialogActions sx={{ px: 0, pt: 3 }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                onClick={handleOpenDelete}
                                            >
                                                Submit Your Response
                                            </Button>

                                            <Modal
                                                open={openDelete}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <Paper variant="outlined" sx={{ p: 4 }}>
                                                        <Typography
                                                            id="modal-modal-title"
                                                            align="center"
                                                            variant="h6"
                                                            component="h2"
                                                            sx={{ fontWeight: 700 }}
                                                        >
                                                            Confirmation
                                                        </Typography>
                                                        <Typography
                                                            id="modal-modal-description"
                                                            align="center"
                                                            sx={{ mt: 2 }}
                                                        >
                                                            Are You Sure To Not Participate In Secret Santa ?

                                                        </Typography>
                                                        <Typography
                                                            id="modal-modal-description"
                                                            align="center"
                                                        >
                                                            You can't change your response later.
                                                        </Typography>

                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            sx={{ mt: 3 }}
                                                        >
                                                            <Button variant="outlined" onClick={handleCloseDelete}>
                                                                Cancel
                                                            </Button>
                                                            <Button variant="contained" onClick={addSecretSantaDetail}>
                                                                Confirm
                                                            </Button>
                                                        </Stack>
                                                    </Paper>
                                                </Box>
                                            </Modal>

                                        </DialogActions></>

                                )}

                                {isProfitMakingYes && (
                                    <><>

                                        <TextField
                                            fullWidth
                                            label="Floor Selection"
                                            id="outlined-basic"
                                            select
                                            value={StaticStatusId}
                                            variant="outlined"
                                            error={Boolean(touched.floor && errors.floor)}
                                            helperText={touched.floor && errors.floor}
                                            onBlur={handleBlur}
                                            disabled={isRegister!=""}
                                        >

                                            {staticStatus.map((option, idx) => (
                                                <MenuItem
                                                    key={option.value}
                                                   // disabled={StaticStatusId!=""}
                                                    value={option.value}
                                                    onClick={(event) => {
                                                        values.floor = option.value;
                                                        setStaticStatusId(option.value)

                                                    }}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </><Box sx={{ mt: 4 }}>
                                            <TextField
                                                id="outlined-basic"
                                                placeholder="Something about your Hobbies, Interest etc ..."
                                                error={Boolean(touched.title && errors.title)}
                                                fullWidth
                                                helperText={touched.title && errors.title}
                                                label="About YourSelf"
                                                name="title"
                                                value={title}
                                                onBlur={handleBlur}
                                                multiline
                                                onChange={(e) => {
                                                    let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,-\s]$/g, "").trimStart().substring(0, 500);
                                                    value = value.replace(/\s{2,}$/g, "");
                                                    values.title = value;
                                                    setTitle(value);
                                                }}
                                                // inputProps={{ maxLength: 150 }}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        minHeight: "100px",
                                                        backgroundColor: "#fff",
                                                        lineHeight: "1.5",
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <DialogActions sx={{ px: 0, pt: 3 }}>
                                            {/* {isRegister !== "1" && ( */}
                                                <Button
                                                    autoFocus
                                                    onClick={handleClose}
                                                    variant="outlined"
                                                >
                                                    Cancel
                                                </Button>
                                            {/* )} */}

                                            <Button
                                                type="submit"
                                                variant="contained"
                                            >
                                                Submit Your Response
                                            </Button>

                                            <Modal
                                                open={openDelete}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <Paper variant="outlined" sx={{ p: 4 }}>
                                                        <Typography
                                                            id="modal-modal-title"
                                                            align="center"
                                                            variant="h6"
                                                            component="h2"
                                                            sx={{ fontWeight: 700 }}
                                                        >
                                                            Confirmation
                                                        </Typography>
                                                        <Typography
                                                            id="modal-modal-description"
                                                            align="center"
                                                            sx={{ mt: 2 }}
                                                        >
                                                            Are You Sure To Participate In Secret Santa ?
                                                            You can't change your response later
                                                        </Typography>

                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            sx={{ mt: 3 }}
                                                        >
                                                            <Button variant="outlined" onClick={handleCloseDelete}>
                                                                Cancel
                                                            </Button>
                                                            <Button variant="contained" onClick={addSecretSantaDetail}>
                                                                Confirm
                                                            </Button>
                                                        </Stack>
                                                    </Paper>
                                                </Box>
                                            </Modal>
                                        </DialogActions>
                                    </>
                                )}
                            </Paper>
                        </Paper>
                    </form>
                )}
            </Formik>
        </>
    )
}
