import React, { useEffect, useState } from "react";
import * as Icons from "../assests/icons/icons";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme from "../theme/theme";
import Drawer from "@mui/material/Drawer";
import NotificationManagement from "../pages/NotificationManagement";
import { CloseNotify } from "../assests/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { EmptyMenu } from "../store/menu";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { toast } from "material-react-toastify";
import { masterAttendanceServices } from "../service/master-service/attendance-servics";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { EmptyUser } from "../store/authUser";
import secureLocalStorage from "react-secure-storage";
import Popover from '@mui/material/Popover';
import { notificationDetailsServices } from "../service/Notification/notification-service";
import moment from "moment";
import useSignalR from "./websocketComponent";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ItemsHeaderNav = styled(Box)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const styleCross = {
  position: "absolute" as "absolute",
  right: "5px",
  top: "5px",
};

const ButtonsHeader = styled(IconButton)((props) => ({
  marginRight: "2rem",
  color: theme.palette.primary.dark,
  width: 36,
  alignItems: "baseline",
  height: 36,
  "& svg": {
    width: 20,
    height: 20,
  },
}));

export default function HeaderNav() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notification, setNotification] = React.useState([]);
  const [showMore, setShowMore] = useState("Show More");
  const [toggleHide, setToggleHide] = useState(false);
  const newNotifications = notification.slice(0, 5);
  const unreadMessageCount = notification.filter((item: any) => item.isRead === false).length;

  const readNotificationbyId = async (notificationId: any) => {
    let data = {
      notificationId: notificationId,
      userId: _authUser.id,
    }
    await notificationDetailsServices.readNotificationbyId(data).then((response: any) => {
      if (response.data.isError) {
        toast.warn(response.data.Error)
      }
      else {
        getNotificationDetails();
      }
    });
  };

  const getNotificationDetails = async () => {
    await notificationDetailsServices.getNotificationDetails().then((response: any) => {
      if (response.data.isError) {
      }
      else {
        setNotification(response?.data);
      }
    });
  };

  function handleOnClick() {
    if (!toggleHide) {
      setShowMore("Show Less");
    } else {
      setShowMore("Show More");
    }
    setToggleHide(!toggleHide);
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setToggleHide(false);
    setShowMore("Show More");
  };

  const open = Boolean(anchorEl);

  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [localUserId, setLocalUserId] = useState("");
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const useAuth = () => {
    const user = secureLocalStorage.getItem("session");
    if (user) {
      return true;
    } else {
      return false;
    }
  };
  const isTrackerRequired = _authUser.employeeInfo.isTrackerRequired;
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [localUserName, setLocalUserName] = useState(
    _authUser.employeeInfo.currentUserName
  );
  const [localDesignation, setlocalDesignation] = useState(
    _authUser.designation
  );
  //alert(localStorage.getItem("userId"));
  const logout = () => {
    secureLocalStorage.clear();
    dispatch(EmptyMenu());
    dispatch(EmptyUser());
    window.location.reload();
  };

  const settings = [
    {
      name: "Profile",
      path: `/users/user-profile/${localUserId}`,
      isShow: true,
    },
    { name: "Change Password", path: "/change-password", isShow: true },
    { name: "Account", path: "", isShow: false },
  ];
  const styleCommentIconOrange = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: theme.palette.warning.light,
    color: theme.palette.warning.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    marginRight: "0.5rem",
  };

  const { connection } = useSignalR();
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("ReceiveNotification", (message) => {
            console.log('message', message);
            if (message != null) {
              playNotificationSound();
              getNotificationDetails();
            }
            else {
              return null
            }
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  // useEffect(() => {
  //   if (connection) {
  //     connection
  //       .start()
  //       .then(() => {
  //         connection.on("ReceiveLogoutNotification", (message) => {
  //           console.log('message', message);
  //           if (message != null) {
  //            logout();
  //           }
  //           else {
  //             return null
  //           }
  //         });
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, [connection]);

  const checkTimeDifference = (loginTym: any) => {
    if (loginTym) {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();
      const timeInMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
      console.log("Current time in milliseconds:", timeInMilliseconds);
      const thirteenHoursInMilliseconds = 12 * 60 * 60 * 1000;
      console.log(thirteenHoursInMilliseconds, "thirteenHoursInMilliseconds")
      // if (timeInMilliseconds >= thirteenHoursInMilliseconds) {
      //   return () =>{ clearInterval(intervalId);  logout();}

      // }
    }
  };

  useEffect(() => {
    getAttendanceTime();
    getNotificationDetails();
    setLocalUserId(_authUser.id);
  }, []);

  const [drawerOpen, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loginTime, setLoginTime] = useState("");
  const [logoutTime, setLogoutTime] = useState("");
  const [isUserOnLeave, setIsUserOnLeave] = useState(false);

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      loginTimeData();
    } else {
      alertLogout();
    }
  };

  const [openAlert, setAlertOpen] = React.useState(false);

  const handleClickOpenAlert = () => {
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setIsChecked(true);
  };

  const loginTimeData = async () => {
    try {
      var req = {
        loginType: 2,
      };
      await masterAttendanceServices
        .getloginDetails(req)
        .then((response: any) => {
          toast.success("Login Successfully ");
          getAttendanceTime();
        });
    } catch (ex: any) {
      ex.data.Error?.map((err: any, idx: any) => {
        toast.warning(err);
        return err;
      });
    }
  };

  const alertLogout = async () => {
    // e.stopPropagation();
    setAlertOpen(true);
  };

  const logOutTimeData = async () => {
    try {
      var req = {
        loginType: 2,
      };
      await masterAttendanceServices
        .getLogoutDetails(req)
        .then((response: any) => {
          toast.success("Log Out successfully");
          setAlertOpen(false);
          getAttendanceTime();
        });
    } catch (ex: any) {
      ex.data.Error?.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getAttendanceTime = async () => {
    try {
      await masterAttendanceServices
        .getTodaysAttendance(isTrackerRequired === false ? "2" : "1")
        .then((response: any) => {
          if (!response.data.isError) {
            //checkTimeDifference(response?.data.loginTime)
            setLoginTime(response?.data.loginTime);
            setLogoutTime(response?.data.logoutTime);
            setIsUserOnLeave(response?.data.isUserOnLeave)
            setIsChecked(
              !!response?.data.loginTime && !response?.data.logoutTime
            );
          }
        });
    } catch (ex: any) {

      // if (ex.status===401){
      //   logout()
      // }
      // else{
      ex.data.Error?.map((err: any, idx: any) => {
        toast.warning(err);
      });
      // }
    }
  };
  //  setTimeout(async () => {
  //     await getAttendanceTime();
  //   }, 1 * 60 * 1000);
  // const intervalId = setInterval(() => {
  //   getAttendanceTime();
  // }, 5 * 60 * 1000);

  const playNotificationSound = () => {
    if (_authUser.employeeInfo.roleId != "660d22ac5b3aca0a4f105c79" &&_authUser.employeeInfo.roleId != "66727cc67014cdf72114239e") {
      const audio = new Audio('https://stgn.appsndevs.com/seasiaconnectsound/facebook_messenger.mp3');
      audio.play();
    }
  };



  const readAllUserNotifications = async () => {
    // let data = {
    //   notificationId: notificationId,
    //   userId: _authUser.id,
    // }
    await notificationDetailsServices.readAllUserNotifications().then((response: any) => {
      if (response.data.isError) {
        toast.warn(response.data.Error)
      }
      else {
        getNotificationDetails();
      }
    });
  };

  const handleSave = () => {
    readAllUserNotifications();
  };

  const DisabledMarkAllAsRead = () => {
    const hasType5678 = notification.filter((item:any) =>
       (item.type === 5 || item.type === 6 || item.type === 7 || item.type === 8) && item.isRead ===false).length > 0;

    return hasType5678;
};
  
  return (
    <>

      <ItemsHeaderNav>
        {/* <ButtonsHeader sx={{ marginRight: '50px' }}>
          <Icons.Search />
        </ButtonsHeader> */}
        {(_authUser.employeeInfo.roleId != "660d22ac5b3aca0a4f105c79" &&_authUser.employeeInfo.roleId != "66727cc67014cdf72114239e") && (

          <ButtonsHeader
            aria-label="show 17 new notifications"
            color="inherit"
            aria-describedby={"pop"}
            onClick={handleClick}
            sx={{
              color: theme.palette.primary.main,
              marginRight: '50px'
            }}
          >
            <Badge badgeContent={unreadMessageCount} color="error" >
              <Icons.Bell />
            </Badge>
          </ButtonsHeader>)}

        <Popover
          id={"pop"}
          // open={drawerOpen}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} >
          <Typography sx={{ p: 2, fontSize: '20px', fontWeight: '500', color: '#232360' }}>
            Notifications
          </Typography>
          {!toggleHide ? (
            newNotifications?.map((item: any, index: any) => (
              <Box onClick={() => (() => {
                localStorage.setItem("PageName","Ping");
                switch (item.type) {
                  case 1:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 2:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 3:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 4:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 5:
                    /// for project/board
                    handleClose();
                    readNotificationbyId(item?.id)
                    return navigate(`/board/${item?.projectId}`);
                  case 6:
                    ///for leaves
                    handleClose();
                    readNotificationbyId(item?.id)
                    return navigate(`/leave-management/${2}`);
                  case 7:
                    // for ticketdetails
                    handleClose();
                    readNotificationbyId(item?.id)
                    return navigate(`/issue-detail/${item?.ticketId}`);
                  case 8:
                    // for offline work 
                    handleClose();
                    readNotificationbyId(item?.id)
                    return (_authUser.typeOfUser=== 1 || _authUser.typeOfUser ===2)? navigate(`/project/logsheet/${item?.projectId}/${item?.id}`)
                    :navigate(`/issue-detail/${item?.ticketId}`);
                  case 9:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                    case 10:
                      handleClose();
                      readNotificationbyId(item?.id)
                      return navigate(`meetingsView/${item?.meetingId}/${1}`);
                      case 11:
                        handleClose();
                        readNotificationbyId(item?.id)
                        return navigate(`meetingsView/${item?.meetingId}/${1}`);
                        case 12:
                          handleClose();
                          readNotificationbyId(item?.id)
                          return navigate(`attendancereport-sheet`);
                        case 13:
                          handleClose();
                          readNotificationbyId(item?.id)
                          return navigate(`board/${item?.projectId}/1`);
                  default:
                    return null
                }
              })()}
                sx={{
                  p: '10px 10px 10px 22px',
                  background: (item?.isRead === false && [5, 6, 7, 8,10].includes(item?.type))
                    ? '#F0F9FF'
                    : (item?.isRead === false && [1, 2, 3, 4, 9].includes(item?.type))
                      ? '#EDFEED'
                      : '#fff',
                  borderTop: '1px solid rgba(224, 224, 224, 1)'
                }}
                style={{ cursor: "pointer" }}
              >
                <div key={index}>
                  <Typography sx={{ color: '#616161', fontSize: '16px', fontWeight: '500' }}>
                    {item.title}
                  </Typography>
                  <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>
                    <Icons.Clock />
                    <Typography sx={{ color: '#757575', fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                      {moment(item?.createdDate).add(2, 'minute').fromNow() || "--"}
                    </Typography>
                  </Stack>
                </div>
              </Box>
            ))
          ) : (
            notification?.map((item: any, index: any) => (
              <Box style={{ cursor: "pointer" }} onClick={() => (() => {
                localStorage.setItem("PageName","Ping");
                switch (item.type) {
                  case 1:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 2:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 3:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 4:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                  case 5:
                    /// for project/board
                    handleClose();
                    readNotificationbyId(item?.id)
                    return navigate(`/board/${item?.projectId}`);
                  case 6:
                    ///for leaves
                    handleClose();
                    readNotificationbyId(item?.id)
                    return navigate(`/leave-management/${2}`);
                  case 7:
                    // for ticketdetails
                    handleClose();
                    readNotificationbyId(item?.id)
                    return navigate(`/issue-detail/${item?.ticketId}`);
                  case 8:
                    // for offline work 
                    handleClose();
                    readNotificationbyId(item?.id)
                    return (_authUser.typeOfUser=== 1 || _authUser.typeOfUser ===2)? navigate(`/project/logsheet/${item?.projectId}/${item?.id}`)
                    :navigate(`/issue-detail/${item?.ticketId}`);
                  case 9:
                    handleClose();
                    return navigate(`hr-wishing/${item?.id}`);
                    case 10:
                      handleClose();
                      readNotificationbyId(item?.id)
                      return navigate(`meetingsView/${item?.meetingId}/${1}`);
                      case 11:
                        handleClose();
                        readNotificationbyId(item?.id)
                        return navigate(`meetingsView/${item?.meetingId}/${1}`);
                        case 12:
                          handleClose();
                          readNotificationbyId(item?.id)
                          return navigate(`attendancereport-sheet`);
                        case 13:
                          handleClose();
                          readNotificationbyId(item?.id)
                          return navigate(`board/${item?.projectId}/1`);
                  default:
                    return null
                }
              })()}
                sx={{
                  p: '10px 10px 10px 22px',
                  background: (item?.isRead === false && [5, 6, 7, 8,10].includes(item?.type))
                    ? '#F0F9FF'
                    : (item?.isRead === false && [1, 2, 3, 4, 9].includes(item?.type))
                      ? '#EDFEED'
                      : '#fff',
                  borderTop: '1px solid rgba(224, 224, 224, 1)'
                }}>
                <div key={index}>
                  <Typography sx={{ color: '#616161', fontSize: '16px', fontWeight: '500' }}>
                    {item.title}
                  </Typography>
                  <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>
                    <Icons.Clock />
                    <Typography sx={{ color: '#757575', fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                      {moment(item?.createdDate).add(2, 'minute').fromNow() || "--"}
                    </Typography>
                  </Stack>
                </div>
              </Box>
            ))
          )}
          <Box sx={{ position: "sticky", bottom: "0", p: '15px 0px', textAlign: 'center', background: '#F8F8F8', display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
            <Typography
              sx={{ color: '#1DA7FF', fontSize: '16px', fontWeight: '500', cursor: 'pointer' }}

              onClick={() => handleOnClick()}
            >
              {notification.length > 4 && (
                showMore)}
              {/* {showMore ? "Show less" : "Show more"} */}
            </Typography>

            <Tooltip title="Common Notification can't be readout like Birthday, Anniversary etc." arrow>
              <Button
                onClick={handleSave}
                variant="contained"
                size="large"
                color="primary"
                sx={{ p: '6px 30px', borderRadius: "100px", cursorPoiner: "pointer" }}
                disabled = {!DisabledMarkAllAsRead()}
            >
                Mark all as Read
              </Button>
              </Tooltip>
          </Box>
        </Popover>
        {(_authUser.employeeInfo.roleId != "660d22ac5b3aca0a4f105c79" &&_authUser.employeeInfo.roleId != "66727cc67014cdf72114239e") && (
          <Box component={Link}
            target="blank"
            to='https://trackyc.appsndevs.com/apps.html'
            sx={{ textDecoration: "none", color: theme.palette.primary.main, mr: 3, display: "block" }}
          >
            Download Tracker
          </Box>)}
        {isTrackerRequired === false && (
          isUserOnLeave == false && _authUser.employeeInfo.roleId !== "660d22ac5b3aca0a4f105c79" && _authUser.employeeInfo.roleId != "66727cc67014cdf72114239e" &&
          (
            <Box>
              {
                <FormControlLabel
                  control={
                    <IOSSwitch
                      disabled={!!logoutTime}
                      checked={isChecked}
                      onChange={handleChange}
                      sx={{ m: 1 }}
                    />
                  }
                  label={
                    <Box>
                      {" "}
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: 1, mb: 0 }}
                      >
                        {loginTime ? `Login: ${loginTime}` : ""}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: 1, mb: 0 }}
                      >
                        {" "}
                        {logoutTime ? `Logout: ${logoutTime}` : ""}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    "&.MuiFormControlLabel-root": {
                      color: theme.palette.primary.main,
                    },
                    mr: 3,
                    [theme.breakpoints.down("sm")]: {
                      mr: 0.5,
                    },
                  }}
                />
              }
            </Box>
          )
        )}

        {isTrackerRequired === true && (
          isUserOnLeave == false &&
          (
            <Box sx={{ color: theme.palette.primary.main, mr: 3, }}>
              {" "}
              <Typography
                variant="subtitle2"
                sx={{ lineHeight: 1, mb: 0 }}
              >
                {loginTime ? `Login: ${loginTime}` : ""}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ lineHeight: 1, mb: 0 }}
              >
                {" "}
                {logoutTime ? `Logout: ${logoutTime}` : ""}
              </Typography>
            </Box>
          )
        )}
        <Stack
          direction="row"
          alignItems="center"
          onClick={handleOpenUserMenu}
          sx={{ cursor: "pointer" }}
        >
          <Stack sx={styleCommentIconOrange}>
            {localUserName.charAt(0).toUpperCase()}
          </Stack>

          <Box
            sx={{
              mr: 2,
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "20px",
                textTransform: "capitalize",
              }}
            >
              {localUserName}
            </Typography>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {/* Master */}
              {localDesignation}
            </Typography>
          </Box>
          <KeyboardArrowDownIcon />
        </Stack>

        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings
            .filter((a) => a.isShow)
            ?.map((setting) => (
              <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                <Typography
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.grey[700],
                    width: '100%',
                  }}
                  component={Link}
                  to={setting.path}
                >
                  {setting.name}
                </Typography>
              </MenuItem>
            ))}
          <MenuItem onClick={handleCloseUserMenu}>
            {location.pathname !== "/login" && (
              <Typography
                sx={{ textDecoration: "none", color: theme.palette.grey[700], width: '100%', }}
                component={Link}
                to="/"
                onClick={logout}
              >
                Logout
              </Typography>
            )}
          </MenuItem>
        </Menu>
      </ItemsHeaderNav>

      {(_authUser.employeeInfo.roleId != "660d22ac5b3aca0a4f105c79"  
      && _authUser.employeeInfo.roleId != "66727cc67014cdf72114239e") && (
        <Box
          sx={{
            position: "fixed",
            right: "-5px",
            top: "10%",
            display: drawerOpen ? "none" : "",
            cursor: "pointer",
          }}
          onClick={() => setOpen(true)}
        >
          <Icons.RigthNotify />
        </Box>)}

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiBackdrop-root ": {
            backgroundColor: "transparent",
            overflow: "initial",
          },

          "& .MuiPaper-root": {
            overflow: "initial",
          },
        }}
      >
        <Box
          sx={{
            position: " absolute",
            left: " -47px",
            top: " 70px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        >
          <CloseNotify />
        </Box>
        <Box sx={{ height: "64px", padding: "32px" }}></Box>
        <NotificationManagement />
      </Drawer>

      <Dialog
        open={openAlert}
        onClose={setAlertOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="delete"
          sx={styleCross}
          onClick={handleCloseAlert}
        >
          <ClearIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "26px",
            lineHeight: "39px",
            color: "#212121",
          }}
        >
          Log Out
        </DialogTitle>
        <DialogContent sx={{ pt: 1, fontSize: "24px", color: "#424242" }}>
          Are you sure you want to Log Out ?
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0, textAlign: "center", justifyContent: "center" }}
        >
          <Button variant="outlined" onClick={handleCloseAlert}>
            No
          </Button>
          <Button variant="contained" onClick={logOutTimeData}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
