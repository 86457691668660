const PayRollStyle ={
    styleCrossPayroll: {
        position: "absolute" as "absolute",
        right: "5px",
        top: "5px",
    },

    deletepopupheading:{
        textAlign: "center",
        fontWeight: 700,
        fontSize: "26px",
        lineHeight: "39px",
        color: "#212121",
      }
}
export default PayRollStyle;