import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Formik, getIn } from "formik";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import List from "@mui/material/List";
import Notes from "../.././components/styledComponent/Note";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import theme from "../../theme/darkTheme";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import * as Icons from "../../assests/icons/icons";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
// import { leaveService } from "../../service/Leave/LeaveService";
import { calenderService } from "../../service/Calender/CalenderService";
import { toast } from "material-react-toastify";
import NotFound from "../../components/styledComponent/NotFound";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoPopover from "../../../src/components/CommonInfoComponent";

import ListItem from '@mui/material/ListItem';
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";





const options = ['Option 1', 'Option 2', 'Option 3'];

// const monthsname = [
//   { label: 'january', year: 1994 },
//   { label: 'febrary', year: 1972 },
//   { label: 'march', year: 1974 },
//   { label: 'april', year: 2008 },
//   { label: 'may', year: 1957 },
// ]

const styles = {
  MonthList: {
    "&.MuiListItemButton-root": {
      borderBottom: "1px solid #e1e1e1",

      "&:hover": {
        background: theme.palette.primary.light,
        "& .MuiTypography-root": {
          color: theme.palette.primary.main,
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "&.Mui-selected": {
      background: theme.palette.primary.light,
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "42px",
      opacity: 0.6,
    },
  },
};

const dummy = [
  {
    id: "0",

    calender: "Holidays",
  },

  {
    id: "1",

    calender: "January",
  },

  {
    id: "2",

    calender: "February",
  },

  {
    id: "3",

    calender: "March",
  },
  {
    id: "4",

    calender: "April",
  },
  {
    id: "5",

    calender: "May",
  },
  {
    id: "6",

    calender: "June",
  },
  {
    id: "7",

    calender: "July",
  },
  {
    id: "8",

    calender: "August",
  },
  {
    id: "9",

    calender: "September",
  },
  {
    id: "10",

    calender: "October",
  },
  {
    id: "11",

    calender: "November",
  },
  {
    id: "12",

    calender: "December",
  },
];

const Yeardummy = [
  {
    id: "0",
    value: "2025",
  },

  {
    id: "1",
    value: "2024",
  },
  {
    id: "2",
    value: "2023",
  },
];

interface calender {
  holidayDate: string;
  occassion: string;
}

interface getcalender {
  id: string;
  holidayDate: string;
  occassion: string;
  day: string;
  date: string;
}

export default function HolidayCalender() {
  const _permission = useSelector(
    (state: any) => state.LeavePermissionApp.LeavePermission
  );
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    getCalenderDetails(selectedIndex, event.currentTarget);
    // setAnchorEl(event.currentTarget);
  };
  const [isSubmit, setSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };
  // --------------------------------
  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const [projectStatus, setProjectStatus] = React.useState("Select Year");
  const [calenderStatus, setCalenderStatus] = React.useState("Holidays");
  const [selectYear, setSelectYear] = React.useState<any>(
    dayjs(new Date())
  );

  let today = new Date();
  let today1 = new Date();
  let futureDates = new Date(today.setFullYear(today.getFullYear()));
  let futureDates1 = new Date(today1.setFullYear(today1.getFullYear() + 1));
  // let pastDate = new Date(today.setFullYear(today.getFullYear() - 54));
  // let pastDate = new Date("1970");
  // let pastDate = new Date(today.setFullYear(today.getFullYear() - 1));
 let pastDate = new Date(today.setFullYear(2023));
  const convertSelectYear = moment(selectYear?.toDate()).format("YYYY");


  const mindate = new Date().getFullYear().toString();

  const handleListItemClick = async (event: any, index: any) => {
    setSelectedIndex(index);
    await getCalenderDetails(index, convertSelectYear);
  };
  //  -----------------------------------------

  const [openLeave, setLeaveOpen] = React.useState(false);

  const handleClickOpen = () => {
    setLeaveOpen(true);
    // setErrorMessage(false);
    setHolidayDate("");
    setOccassion("");
  };

  const handleCloseLeave = () => {
    setLeaveOpen(false);
  };

  const [openAlert, setAlertOpen] = React.useState(false);

  const handleClickOpenAlert = (id: any) => {
    setAlertOpen(true);
    setCurrentId(id);
    getCalenderDetails(selectedIndex, convertSelectYear);
  };

  const handleCloseAlert = () => {
    getCalenderDetails(selectedIndex, convertSelectYear);
    setAlertOpen(false);
  };
  const [value, setValue] = React.useState<Dayjs | null>(null);

  let obj: calender = {
    holidayDate: "",
    occassion: "",
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  // const [users1, setUsers1] = useState<Depart[]>([]);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  // const [formValues, setFormValues] = React.useState<calender[]>([obj]);
  const [formValues, setFormValues] = React.useState<calender>(obj);

  // const [occassion, setOccassion] = React.useState(formValues[0]?.occassion);
  const [occassion, setOccassion] = React.useState(formValues?.occassion);
  // const [holidayDate, setHolidayDate] = React.useState<any>(new Date());
  const [holidayDate, setHolidayDate] = React.useState(formValues?.holidayDate);
  const [getCalenders, setGetCalenders] = useState<getcalender[]>([]);
  const [currentId, setCurrentId] = React.useState("");
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const isItemSelected = isSelected("");
  const [noOfMonthYear, setNoOfMonthYear] = React.useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);


  useEffect(() => {
    if (_permission.page_Holidays_Calendar_Access !== true) {
      navigate("/dashboard");
    }
    getCalenderDetails(selectedIndex, convertSelectYear);
  }, []);

  const addCalenderr = async (CalenderInfo: any) => {

    setLeaveOpen(false);
    try {
      let obj: calender = {
        holidayDate: holidayDate,
        occassion: occassion,
      };
      await calenderService.addCalender(obj).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          toast.success("Calendar added successfully.", {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });
          getCalenderDetails(selectedIndex, convertSelectYear);
          //setTimeout(window.location.reload.bind(window.location), 1000);
          setHolidayDate("");
          setOccassion("");
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any) => {
        toast.warning(err);
      });
    }
  };

  const getProjectStatus = (currentStatus: any) => {
    switch (currentStatus) {
      case "1":
        return "2025";
      case "2":
        return "2024";
      case "3":
        return "2023";
      default:
        return "2023";
    }
  };

  const [showHelpText , setshowHelpText]= React.useState(false);

  const getCalenderDetails = async (setMonth: any, setYearDirect: any) => {
    let input = {
      occassion: "",
      holidayDate: "",
      day: "",
      month: setMonth,
      year:
        typeof setYearDirect == "string"
          ? setYearDirect
          : moment(setYearDirect?.toDate()).format("YYYY"),
    };
    await calenderService.getAllCalenderDetails(input).then((response: any) => {
      if (response.data.isError) {
      } else {

        setGetCalenders(response?.data);
        // getComparator(order, orderBy);
      }
    });
  };

  const DeleteCalenderRow = async () => {
    setAlertOpen(false);
    try {
      await calenderService.deleteCalender(currentId).then((response: any) => {
        if (response.data.isError) {
        } else {
          toast.success("Calendar Date Deleted successfully.", {
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
          });

          getCalenderDetails(selectedIndex, convertSelectYear);
          // getCalenderDetails(selectedIndex, projectStatus);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  // ----------------------------------------------------------------------------------------------------
  // type Order = "asc" | "desc";
  // const [order, setOrder] = React.useState<Order>("desc");
  // const [orderBy, setOrderBy] =
  //   React.useState<keyof getcalender>("holidayDate");

  // function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  //   if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
  //     return -1;
  //   }
  //   if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // function getComparator<Key extends keyof any>(
  //   order: Order,
  //   orderBy: Key
  // ): (
  //   a: { [key in Key]: string | string },
  //   b: { [key in Key]: string | string }
  // ) => number {
  //   return order === "desc"
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // }

  // function stableSort<T>(
  //   array: readonly T[],
  //   comparator: (a: T, b: T) => number
  // ) {
  //   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  //   stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) {
  //       return order;
  //     }
  //     return a[1] - b[1];
  //   });
  //   return stabilizedThis.map((el) => el[0]);
  // }

  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof getcalender
  // ) => {
  //   const isAsc = orderBy === property && order === "desc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const [monthAanchorEl, setmonthAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMonth = Boolean(monthAanchorEl);
  const handleClickMonth = (event: React.MouseEvent<HTMLElement>) => {
    setmonthAnchorEl(event.currentTarget);
  };
  const handleCloseMonth = () => {
    setmonthAnchorEl(null);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Leave Management</Typography>
        <Typography color="text.primary">Holidays Calendar</Typography>

       
      </Breadcrumbs>
      <>
        <form>
          <Paper elevation={0} sx={{ p: 3 }}>
            {/* <Notes
              NoteText="Admin will add and manage the yearly holiday calendar of the organization."
            /> */}
            <Stack
              direction={{ xs: 'column', sm: "row" }}
              spacing={2}
              justifyContent="space-between"
              alignItems={{ xs: 'strech', sm: "center" }}
              sx={{ pb: 3, mt: 2 }}
            >
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                Holidays Calendar
              </Typography>
              <Box>
                
              <InfoPopover  onInput={"Holidays Calendar"}/>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              <Stack
                direction={{ xs: 'column', sm: "row" }}
                alignItems={{ xs: 'strech', sm: "center" }}
                spacing={2}>
                {/* <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  value={projectStatus}
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="selectSmall"
                >
                  {projectStatus}
                  <ArrowDropDownIcon />
                </Button> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                   borderRadius="semi-square"
                   placeholder="Select Year"
                   labelAlignment="left"
                  
                    minDate={pastDate}
                    maxDate={futureDates}
                    label="Select Year"                  
                    // openTo="year"
                    // views={["year"]}
                    value={selectYear}
                    onChange={(newValue: any) => {
                      let normaldate = dayjs(newValue)
                      let abc = getDate(newValue)
                      setSelectYear(normaldate);
                      getCalenderDetails(selectedIndex, moment(abc).format('YYYY'));
                      // getCalenderDetails(selectedIndex, yearValue);
                    }}
                    // renderInput={(params: any) => (
                    //   <TextField
                    //     aria-readonly
                    //     onKeyDown={onKeyDown}
                    //     {...params}
                    //   />
                    // )}
                  />
                </LocalizationProvider>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {Yeardummy.map((option, idx) => (
                    <MenuItem
                      key={idx}
                      value={option.value}
                      onClick={(event) => {
                        setAnchorEl(null);

                        setProjectStatus(Yeardummy[idx].value);
                      }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Menu>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                >
                  Add Holiday
                </Button>
              </Stack>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>

                {/* <Button
                
                  id="demo-positioned-button"
                  aria-controls={openMonth ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMonth ? 'true' : undefined}
                  onClick={handleClickMonth}
                  sx={{display:{xs:'block',sm:'none' }}}
                >
                 All Months
                </Button> */}

                {/* //const [monthAanchorEl, setmonthAnchorEl] = React.useState<null | HTMLElement>(null); */}
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={monthAanchorEl}
                  open={openMonth}
                  onClose={handleCloseMonth}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{
                    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
                      maxHeight: "calc(100% - 100px)",
                    },
                  }}
                >

                  {dummy.map((option, idx) => (
                    <ListItemButton
                      key={idx}
                      selected={selectedIndex === option.id}
                      onClick={(event) => {
                        handleListItemClick(event, option.id);
                        setCalenderStatus(dummy[idx].calender);
                      }}
                      sx={[styles.MonthList]}
                    >

                      <MenuItem onClick={handleCloseMonth}>{option.calender}</MenuItem>
                    </ListItemButton>
                  ))}
                </Menu>

                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={monthsname}
                  sx={{ width: 200 }}
                  renderInput={(params) => 
                  <TextField {...params} label="All Months" />}
                /> */}

                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  sx={{ pt: 0, display: { xs: 'none', sm: 'block' } }}
                >
                  {dummy.map((option, idx) => (
                    <ListItemButton
                      key={idx}
                      selected={selectedIndex === option.id}
                      onClick={(event) => {
                        handleListItemClick(event, option.id);
                        setCalenderStatus(dummy[idx].calender);
                      }}
                      sx={[styles.MonthList]}
                    >
                      <ListItemText primary={option.calender} />
                      {/* <KeyboardArrowRightIcon /> */}
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
              <>
                <Grid item xs={12} sm={9}>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        fontSize: '16px',
                      }}
                    >
                      <Icons.HolidayCalender /> {calenderStatus}
                    </Typography>
                    <Button

                      id="demo-positioned-button"
                      aria-controls={openMonth ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMonth ? 'true' : undefined}
                      onClick={handleClickMonth}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{ display: { xs: 'block', sm: 'none' },
                      "& .css-1gnd1fd-MuiButton-endIcon":{
                        display: 'inline-block',
                      }
                     }}
                    >
                      All Months
                    </Button>
                  </Stack>

                  <TableContainer component={Paper} variant="outlined">
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr No.</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Occasion</TableCell>
                          <TableCell>Day</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {/* {stableSort(
                          getCalenders,
                          getComparator(order, orderBy)
                        ).map((calender, idx) => { */}

                        {getCalenders?.map((calender, idx) => (
                          // return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            // onClick={(event) => handleClick1(event, calender.occassion)}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={calender.id}
                            selected={isItemSelected}
                          >
                            {/* <TableCell> {idx + 1} </TableCell> */}
                            <TableCell>
                              {" "}
                              {("0" + (idx + 1)).slice(-2)}{" "}
                            </TableCell>
                            <TableCell>
                              {""}
                              {`${moment(calender.date)

                                .format("DD-MMM-YYYY")}`}{" "}
                            </TableCell>
                            <TableCell> {calender.occassion}</TableCell>
                            <TableCell> {calender.day}</TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() =>
                                  handleClickOpenAlert(calender.id)
                                }
                              >
                                {/* {JSON.stringify(calender.id, null, 2)} */}
                                {/* onClick={() => deleteLabelData(row.id) */}
                                <Icons.Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          // );
                          // })}
                        ))}
                      </TableBody>
                    </Table>
                    {getCalenders.length == 0 && (
                      <NotFound NotfoundText="No Holidays Found" />
                    )}
                  </TableContainer>
                </Grid>
              </>
            </Grid>
          </Paper>

          <Dialog
            open={openLeave}
            onClose={setLeaveOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Add Holiday</DialogTitle>
            <>
              <Formik
                // enableReinitialize={true}
                initialValues={{
                  holidayDate: holidayDate,
                  occassion: occassion,
                }}
                validationSchema={Yup.object().shape({
                  holidayDate: Yup.string().required(
                    "Date of holiday is required"
                  ),
                  occassion: Yup.string().required("Occasion is required")
                    .matches(
                      /^[A-Za-z0-9 ]+$/,
                      "Occassion can only contain Alphabets"
                    ),
                })}
                onSubmit={async (values, { setStatus, setSubmitting }) => {
                  try {
                    await addCalenderr(values);


                    // setErrorMessage(true);
                    // setSubmit(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <DialogContent sx={{ pt: 1 }}>
                      {/* {formValues.map((element, index) => ( */}
                      <ItemPaper elevation={0} square sx={{ mb: 0.5 }}>
                        <Grid
                          container
                          spacing={2}
                        // onChange={(e) => handleChange1(index, e)}
                        >
                          <Grid item xs md={6} sx={{ mb: -2.5 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Select Date"
                                borderRadius="semi-square"
                               
                                placeholder="Select Date"
                                labelAlignment="left"
                                // openTo="year"
                                minDate={new Date(mindate)}
                                maxDate={
                                  new Date(new Date().getFullYear() +1, 11, 31)
                                }
                                // views={["year", "month", "day"]}
                                // value={element.holidayDate}
                                value={holidayDate || ""}
                                onChange={(newValue: any) => {
                                  let abc = getDate(newValue)
                                  values.holidayDate = abc
                                  setHolidayDate(abc);
                                  setshowHelpText(false);

                                  // updateformValue(
                                  //   newValue,
                                  //   "holidayDate",
                                  //   index
                                  // );
                                }}
                                error={showHelpText ? "Date of holiday is required" : ""}

                                // renderInput={(params: any) => (
                                //   <TextField
                                //     {...params}
                                //     autoComplete="off" // Disable suggestions
                                //     readOnly={true} // Disable user input
                                //     onKeyDown={(e: any) => {
                                //       e.preventDefault();
                                //     }}
                                //     onBlur={handleBlur}
                                //     error={
                                //       getIn(touched, "holidayDate") &&
                                //       getIn(errors, "holidayDate")
                                //     }
                                //     helperText={
                                //       getIn(touched, "holidayDate") &&
                                //       getIn(errors, "holidayDate")
                                //     }
                                //     onChange={(newValue: any) => {
                                //       values.holidayDate = newValue;
                                //       setHolidayDate(newValue);
                                //       // updateformValue(
                                //       //   newValue,
                                //       //   "holidayDate",
                                //       //   index
                                //       // );
                                //       // setErrorMessage(false);
                                //       // setSubmit(true);
                                //     }}
                                //     // {...params}
                                //     // error={errorMessage && !values.holidayDate}
                                //     sx={{
                                //       "& .MuiSvgIcon-root": {
                                //         fill: theme.palette.primary.main,
                                //       },
                                //     }}
                                //   />
                                // )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs md={6}>
                            <TextField
                              id="outlined-basic"
                              label="Occasion"
                              name="Occasion"
                              fullWidth
                              variant="outlined"
                              value={occassion || ""}
                              type="text"
                              required
                              error={Boolean(
                                getIn(touched, "occassion") &&
                                getIn(errors, "occassion")
                              )}
                              helperText={
                                getIn(touched, "occassion") &&
                                getIn(errors, "occassion")
                              }
                              onBlur={handleBlur}
                              onChange={(e: any) => {
                                let value = e.currentTarget.value.replace(/[^a-zA-Z\s]/g, "");
                                value = value.replace(/\s{2,}$/g, "").trimStart();
                                values.occassion = value;
                                setOccassion(value);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </ItemPaper>
                    </DialogContent>
                    <DialogActions sx={{ p: 3, pt: 0 }}>
                      <Button variant="outlined" onClick={handleCloseLeave}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" onClick={()=> setshowHelpText(!holidayDate)}>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </>
          </Dialog>

          <Dialog
            open={openAlert}
            onClose={setAlertOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
          >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
              Delete
            </DialogTitle>
            <DialogContent sx={{ pt: 1 }}>
              Are you sure you want to delete this ?
            </DialogContent>
            <DialogActions
              sx={{
                p: 3,
                pt: 0,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Button variant="outlined" onClick={handleCloseAlert}>
                No
              </Button>
              <Button variant="contained" onClick={DeleteCalenderRow} disabled={buttonDisable}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        {/* )} */}
        {/* </Formik> */}
      </>
    </>
  );
}
