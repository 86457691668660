import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box, Divider, Grid, Icon, ListItemButton, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, styled,
} from "@mui/material";
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import LinearProgress from '@mui/material/LinearProgress';
import { userdashboardservices } from '../service/userdashboard-service';
import moment from 'moment';
import { brownieemployeeservice } from '../service/master-service/brownieEmployee-service';
import { id } from 'date-fns/locale';
import NotFound from '../components/styledComponent/NotFound';
import * as Icons from "../assests/icons/icons";
import ReactApexChart from 'react-apexcharts';
import Achievers from "../assests/images/achievers.png";
import { leaveService } from '../service/Leave/LeaveService';
import { reportService } from '../service/Reports/reports';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';




const styles = {
    cardLeaves: {
        borderRadius: "10px",
        padding: "22px 18px",
        height: '100%',
    },
    titleLeave: {
        color: "#1F944E",
        fontWeight: 500,
        fontSize: "20px",
        marginBottom: "0",
    },
    annualLeaves: {
        color: "#1F944E",
    },
    sickLeaves: {
        color: "#B38906",
    },
    casualLeaves: {
        color: "#16B5BF",
        fontSize: "16px"
    },
    compOff: {
        color: "#1DA7FF",
    },
    otherLeaves: {
        color: "#74C225",
    },
    total: {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#424242",
    },
    values: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #424242",
    },
    taken: {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: " 18px",
    },
    takenvalues: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #757575",
    },
    applyLeave: {
        backgroundColor: "#F0F9FF",
        borderRadius: "10px",
        padding: "22px 18px",
        maxWidth: { xs: '100%', md: "412px" },
        marginLeft: "auto",
    },
    applyLeaveHeading: {
        color: "#232360",
        fontWeight: 500,
        fontSize: " 26px",
    },
    selectType: {
        backgroundColor: "#fff",
        marginTop: "10px",
    },
    selectlabel: {
        fontWeight: 400,
        fontSize: " 14px",
        lineHeight: "21px",
        marginLeft: 0,
        marginBottom: "6px",
        color: "#212121",
    },
    spaceTop: {
        marginTop: "24px",
    },
    AnnualLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #2DD36F 50%, #FFBB0B 78.73%)",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    SickLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: " linear-gradient(90deg, #B38906 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    CasualLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: " linear-gradient(90deg, #15B5BF 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    CompLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #1DA7FF 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },
    },
    OtherLeavesProgress: {
        "&.MuiLinearProgress-root": {
            height: "6px",
            backgroundColor: "#D9D9D9",
            borderRadius: "20px",
            "& .MuiLinearProgress-barColorPrimary": {
                background: "linear-gradient(90deg, #74C225 50%, #FFBB0B 78.73%);",
                borderRadius: " 20px",
                backgroundColor: "unset",
            },
        },

        valueRight: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: " 21px",
            /* Primary Color/Primary_dark shade */
            color: " #424242",
            flexGrow: 1,
        },
    },
    cardStyleProject: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#212121",
        marginBottom: "5px",
    },
    cardStyleCompany: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#232360",
        marginBottom: "5px",
    },
    cardStyleTask: {
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#616161",
    },
    taskTitle: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#232360",

    },
    //     bRadius: {
    //         borderRadius: '16px',
    //         border: '1px solid #EEEEEE',
    //         cursor:"pointer",
    //         "&:hover":{
    //             backgroundColor:"#fbfbfb"
    //         }

    //     },
    //     selected:{
    //    backgroundColor:"#fbfbfb"
    //     },
    tableTH: {
        borderBottom: 'transparent',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#232360",

    },
    table: {
        marginTop: '10px',
    },
    calenderTitle: {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        color: "#232360",
    },
    timeOne: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#616161",
    },
    mettingOne: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: " #212121",
    },
    mettingTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#616161",
    },
    timeTwo: {
        fontWeight: 300,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000000",
        marginTop: '3px',
    },
    listNone: {
        listStyle: 'none',
        marginBottom: '18px',
    },
    bRight: {
        borderLeft: '5px solid #1DA7FF'
    },
    bNone: {
        borderRadius: '0',
    },
    borderNone: {
        border: 'none'
    },
    brownieBox: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#232360",
    },
    brownieOuter: {
        marginTop: '16px',
        borderRadius: '16px',
        border: '1px solid #EEEEEE',

    },
    p15: {
        paddingBottom: '15px',
    },
    point: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#424242",
    }
}
interface Iproject {
    projectId: string;
    projectName: string;
}

function createData(
    name: string,
    sprintName: string,
    task: number
) {
    return { name, sprintName, task };
}

const rows = [
    createData('Frozen yoghurt', "Sprint 1", 4),
    createData('Ice cream sandwich', "Sprint 2", 10),
    createData('Eclair', "Sprint 1", 4),
    createData('Cupcake', "Sprint 2", 2),
    createData('Gingerbread', "Sprint 1", 8),
];

const ItemBoxWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: "10px",
    rowGap: "10px",
    marginTop: "10px"
}));

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h6,
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: " 0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
}));


export default function UserDashboard() {

    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);

    const [user, setUser] = React.useState<any>([]);
    const [selectedProjectId, setSelectedProjectId] = React.useState("");
    const [projectList, setProjectList] = React.useState<Iproject[]>([]);
    const [taskList, setTaskList] = React.useState<any>([]);
    const projectData = { projectId: "0", projectName: "Select Project" };
    const [brownie, setBrownie] = React.useState<any>([]);
    const [leaveCount, setLeaveCount] = useState<any>();
    const [userData, setUserData] = React.useState<any>([]);
    const [StaticStatusId, setStaticStatusId] = useState<number | undefined>(0);
    const [selectedIndex, setSelectedIndex] = React.useState<any>(null);
    const navigation = useNavigate();
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);

    };
    // let totalleaveCasualEarnedSick = leaveCount?.totalleaves + leaveCount?.totalEarnedLeave;
    // let leaveTakenCasualEarnedSick = leaveCount?.leaveTaken + leaveCount?.earnedLeaveTaken;
    // let leaveBalanceEarnedCasual = leaveCount?.leaveBalance + leaveCount?.earnedLeaveBalance;
    let totalLeaves = leaveCount?.totalleaves + leaveCount?.totalEarnedLeave;
    let leaveTaken = leaveCount?.leaveTaken + leaveCount?.earnedLeaveTaken;
    let balance = leaveCount?.leaveBalance + leaveCount?.earnedLeaveBalance;

    var options: any = {
        series: [totalLeaves || 0, leaveTaken || 0, balance || 0],
        colors: ['#2DD36F', '#EB445A', '#FFC409'],
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Total Leaves', "Leave Taken", "Balance"],
        // plotOptions: {
        //     pie: {
        //         donut: {
        //             labels: {
        //                 show: true,
        //                 name: {
        //                     offsetY: 10,
        //                 },
        //                 total: {
        //                     show: true,
        //                     showAlways: true,
        //                     fontSize: '11px',
        //                     fontFamily: 'Helvetica, Arial, sans-serif',
        //                     fontWeight: 220,
        //                     label: 'Total Leaves', // Display only the label without count
        //                     color: '#040404',
        //                 }
        //             }
        //         }
        //     }
        // },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex]
            },
            style: {
                colors: ['#000000'] // Set data label color to black
            }
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'bottom',
            offsetY: 10,
            formatter: function (val: any, opts: any) {
                return val + "  " + opts.w.globals.series[opts.seriesIndex]
            }
        },
    };
    const timeInMinutes = (time1: any) => {
        if (time1) {
            debugger;
            const [hours, minutes] = time1.split(':').map(Number);
            const hoursToMinutes = hours * 60;
            let total = hoursToMinutes;

            if (minutes != "00" && minutes !== null && minutes !== undefined) {
                total += minutes;
            }
            console.log(total, "total");
            return total.toString();
        } else {
            return "0";
        }
    };

    const optionsForMonthlyReportBarChart: any = {
        series: [{
            name: 'Total Hours',
            data: userData?.map((item: any) => item?.totalHours || 0)
        }],
        colors: userData?.map((item: any) => timeInMinutes(item?.totalHours) >= "480" ? '#2DD36F' : '#EB445A'),
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            },
            events: {
                click: function () {
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        yaxis: {
            title: {
                text: 'Total Hours'
            }
        },
        xaxis: {
            title: {
                text: "",
                style: {
                    marginTop: "-10px"
                }
            },
            categories: userData?.map((item: any) => item?.date),
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
    };


    ////////Pagination Programming
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);
    const pageCount = Math.ceil(rows.length / rowsPerPage);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setSelectedIndex(null);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    ////////over

    useEffect(() => {
        getProjectDetails();
        getPointDetails();
        GetLeaveAvailedByUserId();
        getUsersSpecificAttendance();
    }, [])

    const getProjectDetails = async () => {
        await userdashboardservices.getUserDetails(_authUser.id).then((response: any) => {
            if (response.data.isError) {
            } else {
                let data = [];
                data.push(projectData);
                response.data.map((dep: any) => {
                    data.push(dep);
                })
                setUser(response?.data);
                setProjectList(data)
            }
        });
    };

    const getPointDetails = async () => {
        var input = {
            pointType: 1,
            isUser: true,
        };
        await brownieemployeeservice
            .getAllRewardPointWithStatus(input)
            .then((response: any) => {
                if (response.data.isError) {
                } else {
                    setBrownie(response.data)
                }
            });
    };

    const total = brownie.reduce((sum: any, item: any) => sum + item.pointAssigned, 0);

    const getUserDatabyId = async (id: any, userId: any) => {
        var req = {
            projectId: id,
            userId: _authUser.id,

        }
        await userdashboardservices.getDataById(req).then((response: any) => {
            if (response.data.isError) {
            } else {
                setTaskList(response.data)
            }
        });
    };

    const calculateData = (estimatedHours: any, timeTaken: any) => {
        return timeTaken != null ? (parseInt(estimatedHours || 0) / parseInt(timeTaken)) * 100 : 0;
    };

    const calculateDataInHrs = (timeTaken: any) => {
        const hours = Math.floor(timeTaken);
        const minutes = Math.round((timeTaken - hours) * 60);
        const abc = parseInt(timeTaken) > 1 ? "hrs" : "hr"
        if (minutes > 0) {
            return `${hours}${abc} ${minutes}mins`;
        } else {
            return `${hours}${abc}`;
        }
    };

    //User Attendance Report
    const getUsersSpecificAttendance = async () => {
        debugger;
        const fromDate = dayjs().subtract(6, 'days').format("DD-MMM-YYYY");
        const toDate = dayjs().format("DD-MMM-YYYY");
        await reportService
            .getUserSpecificAttendance({
                startDate: fromDate,
                endDate: toDate,
                status: StaticStatusId !== undefined ? StaticStatusId : 0,
            })
            .then((response: any) => {
                if (response.data.isError) {

                } else {
                    setUserData(
                        response?.data
                    );
                }
            });
    };



    //Leave Management
    const GetLeaveAvailedByUserId = async () => {
        await leaveService.GetLeaveAvailedByUserId().then((response: any) => {
            if (response.data.isError) {
            } else {
                setLeaveCount(response.data);
            }
        });
    };

    return (
        <>
            {/* <Chat /> */}
            {/* <Box sx={styles.NotificatioTitle}>
            <h1>Welcome ddd {_authUser.employeeInfo.currentUserName}</h1>
     </Box> */}
            <Paper elevation={0} sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={2}>
                            {user?.length > 0 && user?.filter((x: any) => x != null && (x.projectName != null ?? "")).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item: any, index: any) => (
                                    <Grid item lg={4}>
                                        <ListItemButton
                                            selected={index === selectedIndex}
                                            // onClick={(event) => handleMenuItemClick(event, index)}
                                            onClick={(e) => {
                                                getUserDatabyId(item?.projectId, id); setSelectedProjectId(item?.projectId);
                                                handleMenuItemClick(e, index);
                                            }}
                                            dense
                                            sx={[{
                                                borderRadius: '10px', width: '100%', border: '1px solid #EEE', background: '#FFF', height: '100%', p: 0, overflow: 'hidden', "&:hover": {
                                                    borderColor: 'transparent', background: '#fff', boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.06)'
                                                }
                                            }]}
                                        >
                                            <Box onClick={(e) => { getUserDatabyId(item?.projectId, id); setSelectedProjectId(item?.projectId); }} sx={{ width: '100%', display: "flex", flexDirection: "column", justifyContent: "space-between", height:"100%" }}>
                                                <Box sx={{ px: '14px', py: '18px', width: '100%' }}>
                                                    <Typography sx={[styles.cardStyleProject, { color: "#1DA7FF", fontWeight: "bold" }]} variant="h2" component="h2" >
                                                        Project
                                                    </Typography>
                                                    <Typography sx={[styles.cardStyleProject, { mb: 0 }]} variant="h2" component="h2">
                                                        {item?.projectName}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ background: '#F0F9FF', px: '8px', py: '8px', width: '100%', height: "132px" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={4}>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: "600" }}>
                                                                    Milestone
                                                                </Typography>
                                                                <Typography>

                                                                    {item?.milestoneName || "--"}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={4}>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: "600" }}>
                                                                    Sprint
                                                                </Typography>
                                                                <Typography>
                                                                    {item?.sprintName || "--"}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={4}>
                                                            <Box>
                                                                <Typography sx={{ fontWeight: "600" }}>
                                                                    My Tasks
                                                                </Typography>
                                                                <Typography>
                                                                    {item?.numberOfTask || "--"}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>


                                                </Box>

                                            </Box>
                                        </ListItemButton>
                                    </Grid>
                                ))}
                        </Grid>
                        {(user.length > 4 &&
                            <TablePagination
                                labelRowsPerPage="Cards per page"
                                rowsPerPageOptions={[4]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={() => `Total: ${rows?.length}  Page ${page + 1} of ${pageCount}`}

                            />)}
                    </Grid>
                </Grid>
            </Paper>
            <Box sx={{ mt: '18px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        <Box sx={{ background: '#fff', borderRadius: '10px', py: '14px', px: '20px' }} style={{ cursor: "pointer" }} onClick={() => { navigation("/userspecific-attendance") }}>

                            <Stack direction={'row'} justifyContent={"start"} alignItems={'center'} sx={{ borderBottom: '1px solid #EEE', pb: '12px' }}>
                                <Box sx={{ width: "44px", height: "44px", background: "#F0F9FF", borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icons.Userdashboardattendenceicon />
                                </Box>
                                <Box sx={{ ml: '20px' }}>
                                    <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                        Attendance Details
                                    </Typography>
                                </Box>
                            </Stack>
                            <Grid container>
                                <Grid item xs={12} lg={7}>
                                    <TableContainer component={Paper} elevation={0} sx={{ border: '0' }}>
                                        <Table aria-label="simple table" size='small' border={0}>
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={[styles.tableTH, { p: 1 }]}>Date</TableCell>
                                                    <TableCell sx={[styles.tableTH, { p: 1 }]}>Login Time</TableCell>
                                                    <TableCell sx={[styles.tableTH, { p: 1 }]}>Logout Time</TableCell>
                                                    <TableCell sx={[styles.tableTH, { p: 1 }]}>Actual hours</TableCell>
                                                    <TableCell sx={[styles.tableTH, { p: 1 }]}>Offline hours</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userData.map((row: any, index: any) => (
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 500 }}>{row.date || "--"}</TableCell>
                                                        <TableCell sx={{ fontWeight: 500 }}>{row.loginTime || "--"}</TableCell>
                                                        <TableCell sx={{ fontWeight: 500 }}>{row.logoutTime || "--"}</TableCell>
                                                        <TableCell sx={{ fontWeight: 500 }}>{row.activeHours || "--"}</TableCell>
                                                        <TableCell sx={{ fontWeight: 500 }}>{row.offlineHours || "--"}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item sm={4}>
                                    <Item sx={{ height: "100%", boxShadow: "unset !important" }} style={{ cursor: "pointer", padding: "0" }}>

                                        <ItemBoxWrapper elevation={0} style={{ padding: "16px" }}>
                                            <ReactApexChart options={optionsForMonthlyReportBarChart} series={optionsForMonthlyReportBarChart?.series} type="bar" width={350} />
                                        </ItemBoxWrapper>
                                        <Box sx={{ transform: "translate(-20px, -59px)", fontWeight: "700", textAlign: "center", fontSize: "16px" }} >
                                            Date
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ background: '#fff', borderRadius: '10px', py: '14px', px: '20px' }} style={{ cursor: "pointer" }} onClick={() => { navigation("/leave-management") }}>
                            <Stack direction={'row'} justifyContent={"start"} alignItems={'center'} sx={{ borderBottom: '1px solid #EEE', pb: '12px' }}>
                                <Box sx={{ width: "44px", height: "44px", background: "#F0F9FF", borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: '20px' }}>
                                    <Icons.Leaves />
                                </Box>
                                <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                    Leaves Details
                                </Typography>
                            </Stack>
                            <Box sx={{ pt: '18px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Stack direction="row" alignItems="center">
                                                <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", width: "100%" }}>
                                                    <Typography variant="h5" sx={[styles.titleLeave, styles.sickLeaves]}>
                                                        Availed
                                                    </Typography>
                                                    <Grid container spacing={2} sx={{ mt: .5 }}>
                                                        <Grid item xs={12} md={6}>
                                                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={[styles.titleLeave, styles.casualLeaves]}

                                                                >
                                                                    Casual + Sick Leaves
                                                                </Typography>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    spacing={2}
                                                                >
                                                                    <Box>

                                                                        <Typography variant="h6">
                                                                            {leaveCount?.leaveTaken} days{" "}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={[styles.titleLeave, styles.casualLeaves]}
                                                                >
                                                                    Earned Leaves
                                                                </Typography>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    spacing={2}
                                                                >
                                                                    <Box>

                                                                        <Typography variant="h6">
                                                                            {leaveCount?.earnedLeaveTaken} days{" "}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <ReactApexChart options={options} series={options?.series} type="donut" width={280} />
                                                </Box>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                                <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", width: "100%" }}>
                                                    <Typography variant="h5" sx={[styles.titleLeave, styles.sickLeaves]}>
                                                        Balance Leaves

                                                    </Typography>
                                                    <Grid container spacing={2} sx={{ mt: .5 }}>
                                                        <Grid item xs={12} md={6}>
                                                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={[styles.titleLeave, styles.casualLeaves]}

                                                                >
                                                                    Casual + Sick Leaves
                                                                </Typography>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    spacing={2}
                                                                >
                                                                    <Box>

                                                                        <Typography variant="h6">
                                                                            {leaveCount?.leaveBalance} days{" "}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Paper variant="outlined" sx={[styles.cardLeaves]}>
                                                                <Typography
                                                                    variant="h5"
                                                                    sx={[styles.titleLeave, styles.casualLeaves]}
                                                                >
                                                                    Earned Leaves
                                                                </Typography>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    spacing={2}
                                                                >
                                                                    <Box>

                                                                        <Typography variant="h6">
                                                                            {leaveCount?.earnedLeaveBalance} days{" "}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>

                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ background: '#fff', borderRadius: '10px', py: '14px', px: '20px' }}>

                            <Stack direction={'row'} justifyContent={"space-between"} alignItems={'center'} sx={{ borderBottom: '1px solid #EEE', pb: '12px' }}>

                                <Stack direction={'row'} alignItems={'center'}>
                                    <Box sx={{ width: "44px", height: "44px", background: "#F0F9FF", borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Icons.BrowniePoint />
                                    </Box>
                                    <Box sx={{ ml: '20px' }}>
                                        <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                            Brownie Points
                                        </Typography>
                                        <Typography variant="h3" component="h2" sx={styles.brownieBox}>
                                            Total Brownie Points
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box sx={{ mr: '10px' }}>
                                    <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                        {total}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Box sx={{ pt: '6px' }}>
                                <TableContainer component={Paper} elevation={0} sx={{ border: '0' }}>
                                    <Table aria-label="simple table" size='small' border={0}>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell sx={[styles.tableTH, { p: 1 }]}>Date</TableCell>
                                                <TableCell sx={[styles.tableTH, { p: 1 }]}>Points Earned</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {brownie.slice(0, 5).map((item1: any) => (
                                                <><TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={[{ p: 1 }, styles.borderNone]}>
                                                        {`${moment(item1?.allocatedPointDate).format("DDMMM, YYYY")}`}</TableCell>
                                                    <TableCell sx={[{ p: 1 }, styles.borderNone]}>{item1?.pointAssigned}</TableCell>
                                                </TableRow></>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Box sx={{ background: '#fff', borderRadius: '10px', py: '14px', px: '20px' }}>

                            <Stack direction={'row'} justifyContent={"start"} alignItems={'center'} sx={{ borderBottom: '1px solid #EEE', pb: '12px' }}>
                                <Box sx={{ width: "44px", height: "44px", background: "#F0F9FF", borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icons.Badge />
                                </Box>
                                <Box sx={{ ml: '20px' }}>
                                    <Typography variant="h2" component="h2" sx={styles.calenderTitle}>
                                        Badge
                                    </Typography>
                                    <Typography variant="h3" component="h2" sx={styles.brownieBox}>
                                        You got Achievers
                                    </Typography>
                                </Box>

                            </Stack>
                            <Box sx={{ pt: '6px' }}>
                                <img src={Achievers} />
                            </Box>
                        </Box>
                    </Grid> */}

                </Grid>
            </Box>

        </>
    );
}
