import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Stack, Box, Typography, Button, Divider, Tooltip } from '@mui/material';
import { PartyPopper } from '../assests/icons/icons';
import { Birthday } from '../assests/icons/icons';
import { NoticeBoard } from '../assests/icons/icons';
import { Event } from '../assests/icons/icons';
import theme from '../theme/darkTheme';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { noticeBoard } from '../service/left-menu-service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
const styles = {
    NotificatioTitle: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: ' 23px',
        color: theme.palette.primary.dark,
        marginLeft: '15px',
    },
    TimeStamp: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#616161',
        marginLeft: '8px',
    },
    EventType: {
        fontWight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#212121',
        margin: '0'
    },
    EventText: {
        fontWight: '300',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#616161',
    },
    ListItems: {
        borderBottom: '1px solid #e1e1e1',
        padding: 0,
        paddingBottom: '20px',
        marginBottom: '20px',
    }
}


const LongText = (props: {
    content?: any;
    limit?: any;
    }) => {
    const [showAll, setShowAll] = useState(false);
  
    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);
    if (props.content.length <= props.limit) {
      // there is nothing more to show
      return <div>{props.content}</div>
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return <Box  sx={{ whiteSpace: "break-spaces" }}> 
        {props.content}
        <Tooltip title="Show Less">
        <Button variant="text" size="small" onClick={showLess}>less...</Button></Tooltip>
      </Box>
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = props.content ? props.content.substring(0, props.limit) :"";
    return <Box sx={{ whiteSpace: "break-spaces" , width: "200px"}}>
      {toShow}
      <Tooltip title="Show More">
      <Button variant="text" size="small" onClick={showMore}>more...</Button></Tooltip>
    </Box>
  }
  

export default function NotificationManagement() {

    const navigate = useNavigate();
    const navigateToEvents = () => {
        navigate('/event-only-view');
    };
    const navigateToNoticeBoard = () => {
        navigate('/noticeview');
    };

    const notify = () => {
        navigate('/hr-wishing')

    }

    useEffect(() => {
        getEventDetails();
        getNoticeDetails();
        getBirthdayDetails();
    }, [])

    const [Events, setEvents] = useState<any>([]);

    const getEventDetails = async () => {
        var input = {
            "status": "",
            "search": ""
        };
        await noticeBoard.getEvent(input)
            .then((response: any) => {
                if (response.data.isError) {

                }
                else {
                    setEvents(response?.data);
                }
            });
    }

    const [Notices, setNotices] = useState<any>([]);

    const getNoticeDetails = async () => {
        var input = {
            "status": "",
            "search": ""
        };
        await noticeBoard.getNotice(input)
            .then((response: any) => {
                if (response.data.isError) {

                }
                else {
                    setNotices(response?.data);
                }
            });
    }

    const [Birthdays, setBirthdays] = useState<any>([]);

    const getBirthdayDetails = async () => {
        var input = {
            "status": "",
            "search": ""
        };

        await noticeBoard.getBirthday(input)
            .then((response: any) => {
                if (response.data.isError) {

                }
                else {
                    setBirthdays(response?.data);
                }
            });
    }

    return (
        <>

            <Box sx={{
                p: 2, maxWidth: '400px', overflowY: 'auto',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '320px'
                },
            }}>
                <Box>
                    <Stack direction="row" justifyContent='space-between' spacing={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Birthday />
                            <Typography component='p' sx={[styles.NotificatioTitle]}>
                                Birthday & Anniversary!
                            </Typography>
                        </Box>
                        <Button variant="text" size="small" onClick={notify}>View All</Button>
                    </Stack>
                    <List sx={{ mt: 1, px: 3 }}>
                        {Birthdays.map((Wish: any) => (
                            <ListItem sx={[styles.ListItems]} >
                                <Box>
                                    <Typography component='p' sx={[styles.EventText]} >
                                        <Box component='span' sx={{ fontWeight: 600 }}>{"Happy " + Wish.wishType + " "}{Wish.name} </Box>
                                        <Box component='div' sx={{ textTransform: 'capitalize', mt: 0.5 }} > {Wish.departmentName} </Box>
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                <Box>
                    <Stack direction="row" justifyContent='space-between' spacing={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PartyPopper />
                            <Typography component='p' sx={[styles.NotificatioTitle]}>
                                Events
                            </Typography>
                        </Box>
                        <Button variant="text" size="small" onClick={navigateToEvents}>View All</Button>
                    </Stack>
                  
                    <List sx={{ mt: 1, px: 3 }}>
                        {Events.map((Event: any) => (
                            <ListItem sx={[styles.ListItems]} >
                               
                                <Box>
                                    <Typography variant="body1" component='p' sx={[styles.EventType]}
                                    >   <LongText content={Event?.title} limit={30}/>                                                                 
                                         {/* {Event?.title?.length > 30
                        ? Event?.title.slice(0, 30 - 1) + "..."
                        : Event?.title} */}
                                        {/* {Event.title} */}
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent='space-between' spacing={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <NoticeBoard />
                            <Typography component='p' sx={[styles.NotificatioTitle]}>
                                Notice Board
                            </Typography>
                        </Box>
                        <Button variant="text" size="small" onClick={navigateToNoticeBoard}>View All</Button>
                    </Stack>
                    <List sx={{ mt: 1, px: 3 }}>
                        {Notices.map((Notice: any) => (
                            <ListItem sx={[styles.ListItems]} >
                           
                                <Box>
                                    <Typography component='p' sx={[styles.EventType]} >
                                    <LongText content={Notice?.title} limit={30}/>
                                    {/* {Notice?.title?.length > 30
                        ? Notice?.title.slice(0, 30 - 1) + "..."
                        : Notice?.title} */}
                        
                                        {/* {Notice.title} */}
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </>
    );
}