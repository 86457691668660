import { http } from "./http-common";


class VotePollingService {

    getAllEventCategory = async (): Promise<any> => {
        return await http.get<any>("/v1/VotePolling/GetAllEventCategory");
    }
    getEventCategoryById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/VotePolling/GetEventCategoryById?id=${id}`);
    }
    addUserInCategory = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/VotePolling/Add", data);
    }
    deleteCatById = async (id: any): Promise<any> => {
        return await http.delete<any>(`/v1/VotePolling/DeleteById?id=${id.id}&UserId=${id.userId}`);
    }
    uploadImage = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/VotePolling/AddImageOrVideoURL", data);
    }
    userResult = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/VotePolling/GetEventResultByCategoryBId?id=${id}`);
    }
    voteUserInCategory = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/VotePolling/VoteUser", data);
    }
    getVotedUserById = async (id: any): Promise<any> => {
        return await http.post<any>(`/v1/VotePolling/GetVotedUserById?categoryId=${id}`);
    }
    addUserBioInList = async (data: any): Promise<any> => {
        return await http.post<any>(`/v1/VotePolling/AddUserBio?EventCategoryId=${data.EventCategoryId}&UserBio=${data.UserBio}`);
    }
    getNonVoterlistById = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/VotePolling/GetVoteCountByDepartmentUser?departmentId=${id}`);
    }
    addSantaDetail = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/VotePolling/ParticipationForChristmas", data);
    }

    getSantaDetail = async (id: any): Promise<any> => {
        return await http.get<any>(`/v1/VotePolling/GetChristmasUserDetailByUserId`);
    }

    getSecretSenataForYourSelf = async (id: any): Promise<any> => {
        return await http.get<any>("/v1/VotePolling/AssigningSecretSanta");
    }
}

export const votepollingService = new VotePollingService();