import { combineReducers } from "redux";
const Fill_ProjectPortfolio_Permission = 'Fill_ProjectPortfolio_Permission';
const Empty_ProjectPortfolio_Permission = 'Empty_ProjectPortfolio_Permission';

const defaultPermissions = {
    access: false,
    module_ManagePortfolio_Access:false,
    page_ManagePortfolioListing_Access:false,
    tab_ManagePortfolioEdit_Access:false,
    tab_ManagePortfolioView_Access:false,
    tab_ManagePortfolioUploadDocument_Access:false,
    tab_ManagePortfolioDeleteDocument_Access : false,
    tab_ManagePortfolioAdd_Access : false,

   

}
export function FillProjectPortfolioPermission(module) {
    return {
        type: Fill_ProjectPortfolio_Permission,
        module,
    }
}
export function EmptyProjectPortfolioPermission() {
    return {
        type: Empty_ProjectPortfolio_Permission, defaultPermissions
    }
}
function ProjectPortfolioPermission(state = defaultPermissions, action) {
    switch (action.type) {
        case Fill_ProjectPortfolio_Permission:
            var data = action.module;
            var permissions = defaultPermissions;        
            if (data) {
                permissions.access=true;
                permissions.module_ManagePortfolio_Access = true;
                if (data.listPageMaster && data.listPageMaster.length > 0) {
                  data.listPageMaster.forEach((page) => {
                    if (page.pageName === "PortfolioListing") {
                      permissions.page_ManagePortfolioListing_Access = true;
                      if (page.listTabMaster && page.listTabMaster.length > 0) {
                        page.listTabMaster.forEach((tabs) => {
                          if (tabs.tabName === "Edit") {
                            permissions.tab_ManagePortfolioEdit_Access = true;
                          }
                          if (tabs.tabName === "View") {
                            permissions.tab_ManagePortfolioView_Access = true;
                          }
                          if (tabs.tabName === "Upload Document") {
                            permissions.tab_ManagePortfolioUploadDocument_Access = true;
                          }
                          if (tabs.tabName === "Delete Document") {
                            permissions.tab_ManagePortfolioDeleteDocument_Access = true;
                          }
                          if (tabs.tabName === "Add") {
                            permissions.tab_ManagePortfolioAdd_Access = true;
                          }
                        });
                      }
                    }
                  });
                }


               
                return state = permissions;
            }
            else {
                return state = defaultPermissions;
            }
        case Empty_ProjectPortfolio_Permission:
            return state = defaultPermissions;
        default:
            return state;
    }
}
const ProjectPortfolioApp = combineReducers({
    ProjectPortfolioPermission
})
export default ProjectPortfolioApp;