import React, { useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useParams, } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import theme from "../../theme/theme";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CircularProgress } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as Icons from "../../assests/icons/icons";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClearIcon from "@mui/icons-material/Clear";
import { clsResourcesList } from "../../service/common-services/resources";
import { Department } from "../../Interfaces/IDepartment";
import { IResource } from "../../Interfaces/IResource";
import { useSelector } from "react-redux";
import { HrReportFilter, IEmployeeWorkDiaryReportResponse, IHrReportResponse, WorkDiaryReportFilter } from "../../Interfaces/IReport";
import { reportService } from "../../service/Reports/reports";
import { clsDepartmentList } from "../../service/common-services/department";
import { toast } from "material-react-toastify";
import { Autocomplete, Menu } from "@mui/material";
import { eTypeOfUser } from "../../Enum/eTypeOfUser";
import InfoPopover from "../../components/CommonInfoComponent";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import moment from "moment";
console.log(Dayjs,"Dayjs")
const styleCross = {
    position: "absolute" as "absolute",
    right: "12px",
    top: "16px",
};

const style = {
    coverImage: {
        width: "100%",
        display: "flex",
        height: "150px",
        overflow: "hidden",
        border: "1px dashed #EEEEEE",
        padding: "10px",
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        "& img": {
            maxWidth: "100%",
            width: '100%',
            objectFit: 'cover',
            height: "100%",
        },

    },
    toggleloaders:{
        position: "fixed",
         left: "50%",
         right: "50%",
         top: "50%", 
        bottom: "50%", 
        transform: "translate(-50%, -50%)" 
       },
    timeStamp: {
        fontSize: "12px",
        lineHeight: "14px",
        color: " #757575",
        textAlign:{xs:'center', md:'start'},
    },
    date: {
        fontSize: " 18px",
        lineHeight: '21px',
        fontWeight: "600",
        /* identical to box height */


        /* Primary Color/Primary_dark shade */

        color: ' #232360',
    },
    keys: {

    }
};


export default function WorkDairy() {
    const navigation = useNavigate();
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const _permission = useSelector((state: any) => state.ReportPermissionApp.ReportPermission);
    const [reports, setreports] = React.useState<IEmployeeWorkDiaryReportResponse[]>([])
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [toggleLoader, setToggleLoader] = useState(false);
    const [refreshDialog, setRefreshDialog] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openPopupImgPath, setopenPopupImgPath] = React.useState("");
    const [openPopupImgIndex, setopenPopupImgIndex] = React.useState(0);
    console.log(openPopupImgIndex,"openPopupImgIndex")
    const handleScreenShotChange = (flag: any) => {
        debugger
        if (flag === "decremenet") {
            let targetedIndex = openPopupImgIndex - 1
            setopenPopupImgIndex(targetedIndex)
            let targetedImage = reports[targetedIndex]
            setopenPopupImgPath(targetedImage.directory)
            console.log(targetedImage, "targetedImage")
        }
        else if (flag === "increment") {
            let targetedIndex = openPopupImgIndex +1
            setopenPopupImgIndex(targetedIndex)
            let targetedImage=  reports[targetedIndex] 
            setopenPopupImgPath(targetedImage.directory)
            console.log(targetedImage,"targetedImage")
        }
        else
            return "";
    };


    const handleClickOpen = (imgPath: string) => {
        setOpen(true);
        setopenPopupImgPath(imgPath);
    };

    const handleClose = () => {
        setOpen(false);
        setopenPopupImgPath('');
    };

    let DepartmentId = "";
    const [departments, setDepartments] = React.useState<Department[]>([]);
    const [departmentId, setDepartmentId] = React.useState("");
    const [disabledDepartments, setdisabledDepartments] = React.useState(false);
    const [departmentHook, setDepartmentHook] = React.useState("");
   
    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    //   };
    //   const handleChangeRowsPerPage = (
    //     event: React.ChangeEvent<HTMLInputElement>
    //   ) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    //   };
    let ResourceId = "";
    const [resources, setresources] = React.useState<IResource[]>([]);
    const [resourceId, setResourceId] = React.useState("");
    const [buttonDisable, setButtonDisable] = useState(false);
    const [resourceHook, setresourceHook] = React.useState("");
    let today = dayjs();
    const [fromDate, setfromDate] = React.useState<any>(setDateWithoutTime(today));
    const [toDate, settoDate] = React.useState<Dayjs | null>(setIncreaseDateWithoutTime(today));
    function setDateWithoutTime(fdate: any) {
        return dayjs(fdate.format('YYYY-MM-DD'));
    }
    function setIncreaseDateWithoutTime(fdate: any) {
        let date: any = fdate.format('YYYY-MM-DD');
        let tdate: any = dayjs(date).add(1, 'day')
        return tdate;
    }
    const setdatePeriod = (fdate: any) => {
        let date: any = fdate.format('YYYY-MM-DD');
        setfromDate(dayjs(date));
        let tdate: any = dayjs(date).add(1, 'day')
        settoDate(tdate);
    }
    
    const getReport = async (
        userId: string = "", startdate: any = fromDate, endDate: any = fromDate
    ) => {
        console.log("show");
        setToggleLoader(true);
        if (userId && userId != "") {
            var workDiaryReportFilter: WorkDiaryReportFilter = {
                userId: userId,
                startdate: startdate.format('YYYY-MM-DD') + " 00:00:00",//moment(startdate).format('YYYY-MM-DD h:mm:ss'), 
                endDate: endDate.format('YYYY-MM-DD') + " 23:59:59",//moment(endDate).format('YYYY-MM-DD h:mm:ss')
            };
            await reportService.getTrackerScreenshotReport(workDiaryReportFilter)
                .then((response: any) => {
                    if (response.data.isError) {
                        // if(response.data.listErrors && response.data.listErrors.length>0){
                        //     response.data.listErrors.map((errors:any,index:any)=>{
                        //         //toast.error(errors);
                        //     })
                        // }
                        setreports([]);
                    }
                    else {
                        setreports(response.data.result,);
                        console.log("hide");
                        
                    }
                    setToggleLoader(false);
                });
        }
        else {
            debugger
            setToggleLoader(false);
            let abc: any = dayjs().toISOString();
            let date: any = dayjs(abc).format('YYYY-MM-DD');
            let tdate: any = dayjs(date).add(1, 'day');
            setfromDate(setDateWithoutTime(today));
            settoDate(setDateWithoutTime(tdate));
            toast.warning("Please select any resource!", {
              onOpen: () => setButtonDisable(true),
              onClose: () => setButtonDisable(false),
            });
        }

    }
    React.useEffect(() => {
        if (_permission.page_ManageWorkDairyReport_Access !== true) {
            navigation('/dashboard');
        }
        const departmentsData = async () => {
            if (_authUser.typeOfUser != eTypeOfUser.Admin) {
                setRefreshDialog(true);
                if (_permission.action_ManageWorkDairyReport_ReportListing_AllDepartments_Access == false) {
                    setdisabledDepartments(true);
                }
                setDepartmentHook(_authUser.employeeInfo.departmentId);
                setDepartmentId(_authUser.employeeInfo.departmentId);
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(_authUser.employeeInfo.departmentId));
                if (eTypeOfUser.User) {
                    setresourceHook(_authUser.id);
                    getReport(_authUser.id)
                }
            }
            else {
                setDepartments(await clsDepartmentList.getDepartmentDropdownList());
                setresources(await clsResourcesList.getResourcesDropdownList(''));
                setDepartmentId("");
            }
        };
        departmentsData();

    }, []);

    useEffect(() => {
        if (refreshDialog) {
            const timer = setTimeout(() => {
                setRefreshDialog(false);
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [refreshDialog]);
    return (
        <>
        {_authUser.typeOfUser === eTypeOfUser.User && (
                <Dialog
                    open={refreshDialog}
                    onClose={() => setRefreshDialog(false)}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Important Information
                        </Typography>
                        <Typography variant="body1">
                            Please toggle offline or logout from the tracker to get the latest time update.
                        </Typography>
                        <Button onClick={() => setRefreshDialog(false)} color="primary" sx={{ mt: 2 }}>
                            OK
                        </Button>
                    </DialogContent>
                    <IconButton
                        aria-label="close"
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={() => setRefreshDialog(false)}
                    >
                        <ClearIcon />
                    </IconButton>
                </Dialog>
            )}
        {toggleLoader && (<CircularProgress sx={style.toggleloaders} />)}
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
                <Link color="inherit" to="/">
                    Dashboard
                </Link>

                <Typography color="text.primary">Report Management</Typography>
                <Typography color="text.primary">Work Diary</Typography>
            </Breadcrumbs>
            <Paper elevation={0} sx={{ p: 3 }}>
                {/* <Notes
                    NoteText="Here, admin will see the various reports like Attendance report, Milestone report, Project report, Daily Attendance report, Work Diary and HR report."
                /> */}
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    justifyContent="space-between"
                    alignItems={{ xs: 'strech', md: 'center' }}
                    sx={{ mt: 2 }} >
                    <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                        Work Diary
                    </Typography>
                    <Box>
                
                <InfoPopover  onInput={"Reports"}/>
                </Box>
  
                <Box sx={{ flexGrow: 1 }} />
                    <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'strech', md: 'flex-end' }} spacing={2}>
                        {((_authUser.typeOfUser === eTypeOfUser.Admin) ||
                            (_authUser.typeOfUser === eTypeOfUser.Manager || _authUser.typeOfUser === eTypeOfUser.User
                                 && _permission.action_ManageWorkDairyReport_ReportListing_AllDepartments_Access === true||
                                _permission.action_ManageWorkDairyReport_ReportListing_Departmentspecific_Access === true)
                        )
                            &&
                            <>
                                <Box>
                                    <Autocomplete
                                        disabled={disabledDepartments}
                                        value={
                                            departments.find(
                                                (option: any) => option.id == departmentId
                                            ) ?? null
                                        }
                                        sx={{ ml: { xs: '0px', md: "15px" } }}
                                        id="outlined-basic"
                                        options={departments}
                                        getOptionLabel={(option) => option.name}
                                        onChange={async (event, value) => {
                                            setDepartmentHook(value?.id ?? "");
                                            setDepartmentId(value?.id ?? "")
                                            setresources(await clsResourcesList.getResourcesDropdownList(value?.id ?? ""));
                                            setresourceHook("");
                                            setResourceId("");
                                            setfiltersSelected(true);
                                            DepartmentId = value?.id ?? "";
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="outlined-basic-departmentsId"
                                                name="element.name"
                                                variant="outlined"
                                                value={departmentId}
                                                fullWidth
                                                sx={{ minWidth: 220 }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box>
                                    <Autocomplete
                                        disabled={!departmentHook || departmentHook === ""}
                                        value={
                                            resources.find(
                                                (option: any) => option.userId == resourceId ?? ""
                                            ) ?? null
                                        }
                                        sx={{ ml: { xs: '0px', md: "15px" } }}
                                        id="outlined-basic"
                                        options={resources}
                                        getOptionLabel={(option) => option.userName}
                                        disableClearable={resourceId && resourceId != "" ? false : true}
                                        onChange={async (event, value) => {

                                            setfiltersSelected(true);
                                            setresourceHook(value?.userId ?? "");
                                            setResourceId(value?.userId ?? "");
                                            ResourceId = value?.userId ?? "";
                                            getReport(value?.userId ?? "");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="outlined-basic-departmentsId"
                                                name="element.name"
                                                variant="outlined"
                                                value={resourceId}
                                                fullWidth
                                                sx={{ minWidth: 220 }}
                                            />
                                        )}
                                    />
                                </Box>
                            </>}
                        <Box >
                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                <DatePicker
                                    label="Date"
                                    placeholder="Date"
                                    labelAlignment="left"    
                                    maxDate={dayjs().toDate()}
                                    minDate={moment().subtract(1, "years").toDate()}
                                    borderRadius="semi-square"
                                    
                                    value={fromDate}
                                    onChange={(newValue : any ) => {    
                                        debugger     
                                        let abc= dayjs(newValue)   
                                        setfromDate(abc);
                                        setdatePeriod(abc);
                                        setfiltersSelected(true);

                                        let tdate: any = dayjs(abc);
                                        let tdate1: any = tdate.add(1, 'day')
                                        setdatePeriod(tdate);
                                        getReport(resourceHook, tdate, tdate);
                                    }}
                                    // renderInput={(params: any) => <TextField {...params}
                                    // autoComplete="off" // Disable suggestions
                                    // readOnly={true} // Disable user input
                                    //     fullWidth
                                    //     sx={{
                                    //         width: {xs:'100%',md:'150px'}, mr: 1, lineHeight: '0.80em',
                                    //         "& .MuiSvgIcon-root": {
                                    //             fill: theme.palette.primary.main
                                    //         },
                                    //         [theme.breakpoints.down('sm')]: {
                                    //             width: "100%",
                                    //             margin: "0"
                                    //         },
                                    //     }}

                                    // />}
                                />
                            </LocalizationProvider>
                        </Box>

                    </Stack>
                </Stack>

                <Stack direction={{  xs: 'column', md: 'row' }} alignItems={{ xs: 'strech', md: 'center' }} justifyContent='space-between' sx={{ my: 2 }} >
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent:'center' }}>
                        <IconButton aria-label="delete" disabled={buttonDisable} onClick={() => {
                            let tdate: any = dayjs(fromDate).subtract(1, 'day')
                            let tdate1: any = tdate.add(1, 'day')
                            setdatePeriod(tdate);
                            getReport(resourceHook, tdate, tdate);
                        }}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <Typography sx={style.date}>{fromDate?.format("ddd, MMM D, YYYY")}</Typography>
                        <IconButton aria-label="delete"
                            disabled={fromDate?.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")}
                            onClick={() => {
                                let tdate: any = dayjs(fromDate).add(1, 'day')
                                let tdate1: any = tdate.add(1, 'day')
                                setdatePeriod(tdate);
                                getReport(resourceHook, tdate, tdate);
                            }}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                        <Button variant="text" size="small"
                            disabled={fromDate?.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")}
                            onClick={() => {

                                setdatePeriod(dayjs());
                                let date: any = dayjs(dayjs().format('YYYY-MM-DD'));
                                let tdate: any = dayjs(date).add(1, 'day')
                                getReport(resourceHook, date, tdate);
                            }}
                        >Today Activity</Button>
                    </Box>
                  
                    {reports && reports.length > 0 && <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="right"
                        textAlign="right"
                        alignContent="right"
                        spacing={5}

                    >
                        <Typography component="h5" sx={{ fontWeight: 500 }}> <span>(Active + Offline hours)</span>   Total Time: {reports && reports.length > 0 && <>{reports[0].totalTime}</>}</Typography>
                        <Typography component="h5" sx={{ fontWeight: 500 }}> Active Time: {reports && reports.length > 0 && <>{reports[0].activeTime}</>}</Typography>
                        <Typography component="h5" sx={{ fontWeight: 500 }}> Offline Time: {reports && reports.length > 0 && <>{reports[0].offlineHour}</>}</Typography>
                        <Typography component="h5" sx={{ fontWeight: 500 }}> Idle Time: {reports && reports.length > 0 && <>{reports[0].idleTime}</>}</Typography>
                    </Stack>}
                </Stack>
                {reports && reports.length > 0 &&
                    <Grid container spacing={2}>
                        {reports.map((row, index) => (
                            <Grid item xs md={3}>
                                <Box sx={style.coverImage} onClick={() => { handleClickOpen(row.directory) }}>
                                    {row.directory == "" && row?.directory==null ? <Icons.IconImage /> : <img src={row.directory} />}
                                </Box>
                                <Typography variant="subtitle2" sx={style.timeStamp}>{dayjs(row.date).format("h:mm A")}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                }
                {!reports || reports.length <= 0 &&
                    <Grid container spacing={2}>
                        <Grid item xs >
                            <Box  >
                            {resourceHook != "" && <Typography variant="subtitle2" sx={style.timeStamp}>
                                Not found any screenshots for Resource: <b>{resources.find(r => r.userId == resourceHook)?.userName}</b> on date: <b>{fromDate?.format("ddd, MMM D, YYYY")}</b>
                            </Typography>}
                            {resourceHook === "" && <Typography  variant="subtitle2" sx={style.timeStamp}>
                                Select any resource to view work diary.
                            </Typography>}
                            </Box>
                        </Grid>
                    </Grid>
                }

                {/* <Stack sx={{ pt: 3, pb: 3 }}>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25, 30]}
                component="div"
                count={resourceHook.length}
                rowsPerPage={rowsPerPage}
                page={!resourceHook.length || resourceHook.length <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack> */}
            </Paper>


            <Dialog open={open} onClose={handleClose}

                fullWidth
                maxWidth="md"

            >


                <IconButton
                    aria-label="delete"
                    sx={styleCross}
                    onClick={handleClose}
                >
                    <ClearIcon />
                </IconButton>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                    <IconButton aria-label="delete"
                        disabled={openPopupImgIndex == 0}
                        onClick={() => {
                            handleScreenShotChange("decremenet")
                        }}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton aria-label="delete"
                        disabled={reports?.length-1  == openPopupImgIndex}
                        onClick={() => {
                            handleScreenShotChange("increment")
                        }}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{
                    pt: 5, "& img": {
                        maxWidth: '100%'
                    }
                }}>
                    <img src={openPopupImgPath} />
                </DialogContent>

            </Dialog>
        </>
    );
}