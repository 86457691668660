import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import * as Icons from "../../assests/icons/icons";
import SearchBar from "../../components/styledComponent/SearchBar";
import Modal from "@mui/material/Modal";
import Userlisttop from "../users/userliststyle";
import {
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik, getIn } from "formik";
import { toast } from "material-react-toastify";
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import NotFound from "../../components/styledComponent/NotFound";
import Masteralltop from "./mastersetupstyle";
import InfoPopover from "../../components/CommonInfoComponent";
import AppConstants from "../../config/AppConstants";
import { masterDataService } from "../../service/master-service/master-data-service";
import { masterKRAServices } from "../../service/master-service/master-kra-service";


interface Skills {
  id: string;
  atrributeName: string;
  departmentId: string;
  coHodId: string;
  coHodName: string;
  srNo: string;
  assignedUsers: AssigneeUsers[]; // Update this line
}

interface  AssigneeUsers{
  userId: string;
  name: string;
  assignedDate: string;
}

interface User {
  userId: string;
  userName: string;
  id: string;
  name: string;
  DepartmentId: string;
}

const initialvalues = {
  departmentId: "",
  departmentName: "",
  coHodId: "",
  assignedUsers: [
    {
      userId: "",
      name: "",
      assignedDate: ""
    }
  ]
};


function KraData() {
  const _permission = useSelector((state: any) => state.MasterPermissionApp.MasterPermission);
  const navigate = useNavigate();
  const [actionAllowed, setAllowdAction] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [krarecords, setKraRecords] = useState<any>();
  const [openModal, setModalOpen] = React.useState(false);
  
  const pageCount = Math.ceil(krarecords?.length / rowsPerPage);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorMessage, setErrorMessage] = useState("");

 


  const isValidUser = async () => {
    await masterDataService
      .GetActionbypageId(
        AppConstants.ScreenInfo.Master_Configuration.Manage_HOD.pageId
      )
      .then((response: any) => {
        if (response.data.isError) {
          navigate("/login");
        } else {
          var data = response?.data;
          var screenInfo = masterDataService.isAuthorizeUser(
            data,
            AppConstants.ScreenInfo.Master_Configuration.Manage_HOD
          );
          var allowedAction = {
            edit: masterDataService.isActionAlowed(
              screenInfo,
              AppConstants.ScreenInfo.Master_Configuration.Manage_HOD.actionIds
                .edit
            ),
          };
          setAllowdAction(allowedAction);
        }
      })
      .catch((error) => {
        navigate(AppConstants.Redirection.Unauthorize_Access);
      });
  };

  const getAllKraRecords = async() => {

    await masterKRAServices.getAllKRA("").then((response:any) => {
      if(response.data.isError){
      }else{
        console.log(response.data);
        setKraRecords(response?.data);
      }
    })
  }

  /////search programming
  const [searchValue, setSearchValue] = useState("");

  //////department data
  const [buttonDisable, setButtonDisable] = useState(false);

  const [fetchUserId, setFetchUserId] = React.useState("");
  const[atrributeName, setAtrributeName] = React.useState("");

  
  /////over
  const [users, setUser] = useState<User[]>([]);

  //////Dropdown over

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // , HodId:any, UserId: string, UserName: any
  const handleClickOpen = (department: any) => {
    setOpen(true);

    
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if(_permission.page_ManagePredefinedKRA_Access !==true ){
      navigate("/dashboard");
    }

    getAllKraRecords();
 
  }, []);

  //Sorting

  const [dense, setDense] = React.useState(false);
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const deleteUserDetaildata = async (id: any) => {
    setModalOpen(true);
    setFetchUserId(id);
   
  };

  const deleteKRARecord = async () => {
    await masterKRAServices.deleteKRA(fetchUserId).then((response:any) => {
      if(response.data.isError){
      }else{
        toast.success("KRA Deleted Successfully");
        setModalOpen(false);
        getAllKraRecords();
      }
    })
  }

  const handleCloseModal = async () => {
    setModalOpen(false);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Skills;
    label: string;
    numeric: boolean;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "S No.",
    },

    {
      id: "atrributeName",
      numeric: false,
      disablePadding: false,
      label: "KRA",
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Skills>("srNo");

  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Skills
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Skills) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align-items="center" align="center">Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Skills
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const triggerSearch = async (newValue: string) => {
    getSearchDetails(newValue);
    setPage(0);
  };

  const getSearchDetails = async (newValue: string) => {

    await masterKRAServices.getAllKRA(newValue).then((response:any) => {
      if(response.data.isError){
      }else{
        setKraRecords(response?.data);
        if (response.data.length == 0) {
        
        }
      }
    })
  };

  const handleClick1 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 1 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  ///////page change and dropdown programming over

 

  const createKRARecord = async (values: any) => {
    try{
      setButtonDisable(true);
      await masterKRAServices.createKRA(values).then((response:any) => {
        if(response.data.isError){
        }else{
          toast.success("KRA Added Successfully",{
            onOpen: () => setButtonDisable(true),
            onClose: () => setButtonDisable(false),
        });
          handleClose();
          getAllKraRecords();
        }
      })

    }catch (error : any) {
      if (error.status === 400){
        toast.warning(error.data.Error[0],{
          onOpen: () => setButtonDisable(true),
          onClose: () => setButtonDisable(false),
      });
      } else {
        debugger
        toast.warning("An error occurred. Please try again later.",{
        onOpen: () => setButtonDisable(true),
        onClose: () => setButtonDisable(false),
    });
    }
  }
  }


  const fieldValidationSchema = Yup.object().shape({
    //atrributeName: Yup.string().required("This field is required.")
    atrributeName: Yup
    .string()
    .max(90,'KRA can contain only 90 Alphabets').matches(/^([A-Za-z\s\-.]*)$/g,
    'KRA can only contain alphabets.')
    .required('KRA is required'),
  });

  

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Master Setup</Typography>
        <Typography color="text.primary"> Department Head</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
      {/* <Notes
          NoteText="Here, Admin will create and manage Head of Department of departments."
        /> */}
        <Stack
        sx={Masteralltop.topbar}
        >
          <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
            KRA
          </Typography>
          <Box>
            
          <InfoPopover  onInput={"Manage HOD"}/>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
                 <Box   sx={Masteralltop.searchbar}>
          <SearchBar
            setSearchValue={setSearchValue}
            callBackFunction={triggerSearch}
          /> </Box>

          <Button variant="contained" size="large" onClick={() => handleClickOpen("sd")} color="primary" startIcon={<AddIcon />}>
              Add KRA
            </Button>

        </Stack>

        <Divider />

        <TableContainer sx={{ mt: 1 }}>
          <Table aria-label="simple table" size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={krarecords?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(krarecords, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.coHodName);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const serialNumber = page * rowsPerPage + index + 1; 

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick1(event, row.coHodName)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                    
                    >
                      <StyledTableCell align="left">{serialNumber}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.atrributeName}
                      </StyledTableCell>
                     
                      <StyledTableCell align="left" width={100}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          // spacing={2}
                        >
                          {/* {(actionAllowed.edit && */}
                          
                            <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={() => deleteUserDetaildata(row.id)}
                            >
                              <Icons.Delete />
                            </IconButton>
                          </Tooltip>
                          
                          
                      
                        </Stack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: dense ? 33 : 53,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {krarecords?.length == 0 && <NotFound NotfoundText="No Result Found" />}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={krarecords?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
       
          labelDisplayedRows={() => `Total: ${krarecords?.length}  Page ${page + 1} of ${pageCount}`}
        />
      </Paper>
      <Modal
            open={openModal}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Userlisttop.actionpopup}>
              <Paper variant="outlined" sx={{ p: 4 }}>
                <Typography
                  id="modal-modal-title"
                  align="center"
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: 700 }}
                >
                  Delete KRA
                </Typography>
                <Typography
                  id="modal-modal-description"
                  align="center"
                  sx={{ mt: 2 }}
                >
                  Are you sure, you want to delete this KRA. Continue?
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 3 }}
                >
                  <Button variant="outlined" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={deleteKRARecord}>
                    Confirm
                  </Button>
                </Stack>
              </Paper>
            </Box>
          </Modal>

      <Dialog sx={{ "& .MuiDialog-paper": { overflow: "visible", height: "unset" } }}  open={open} fullWidth maxWidth="sm">
        <Formik
          initialValues={{
            atrributeName: ""
          }}
          validationSchema={fieldValidationSchema}
          onSubmit={async (values) => {
            try {
              debugger
              createKRARecord(values);
            } catch (ex: any) {
              ex.data.errors.map((err: any, idx: any) => {
                debugger
                toast.warning(err,{
                  onOpen: () => setButtonDisable(true),
                  onClose: () => setButtonDisable(false),
              });
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Please add predefined KRA for user</DialogTitle>
              <DialogContent   sx={{overflow: "visible !important"}}>
                <TextField
                  margin="dense"
                  id="department-name"
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g,"")
                    value = value.replace(/\s{2,}$/g, "").trimStart().substring(0,90);
                    values.atrributeName = value
                    setAtrributeName(value)
                   }}
                  name="atrributeName"
                  type="text"
                  fullWidth
                  value={values?.atrributeName}
                  variant="outlined"
                  onBlur={handleBlur}
                  error={Boolean(
                      getIn(touched, "atrributeName") && getIn(errors, "atrributeName")
                    )}
                    helperText={
                      getIn(touched, "atrributeName") && getIn(errors, "atrributeName")
                    }
                />

                
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary"
                 disabled={buttonDisable}
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default KraData;
