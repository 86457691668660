export enum eLeaveStatus
{
    "All Leaves"="0",
    "Approved" = "3",
    "Rejected" = "2",
    "Pending" = "1",
    "Update Request" = "4",
}
export enum eLeaveType{
    "Select Leave Type"="",
    "Casual Leave"="Casual Leaves",
    "Comp Off Leave"="Comp Off Leave",
    "Earned Leave"="Earned Leave",
    "Maternity Leave"="Maternity Leave",
    "Paternity Leave"="Paternity Leave",
    "Sick Leave"="Sick Leave"
}
export enum logStatusFilter
{
    "All"="",
    "Approved" = "5",   
    "Rejected" = "7"
   
}
export enum AttendanceStatusFilter
{
    "All"="0",
    "Approved" = "2",   
    "Reject" = "3"
}