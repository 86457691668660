import React, { useReducer, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import theme from "../../../theme/theme";
import { Typography } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import * as Icons from "../../../assests/icons/icons";
import TextField from "@mui/material/TextField";
import CommentBox from "../../../components/styledComponent/CommentBox";
import Chip from "@mui/material/Chip";
import { toast } from "material-react-toastify";
import { issueManagementService } from "../../../service/issues-management-service";
import MUIRichTextEditor from "mui-rte";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import { commonFunctions } from "../../../service/common-services/common-functions";

const styleCommentIconOrange = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  background: theme.palette.warning.light,
  color: theme.palette.warning.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
};

const styles: any = {
  userAvatar: {
    justifyContent: "flex-end",
    "& .MuiAvatar-root": {
      textTransform: "Uppercase",
      // fontFamily: "sans-serif",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text,
      borderColor: "#fff",
      fontWeight: 500,
      width: "32px",
      height: "32px",
      cursor: "pointer",
      fontSize: 13,
      lineHeight: " 16px",
    },
  },
};

const styleCommentIconGreen = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  background: theme.palette.success.light,
  color: theme.palette.success.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
};

const styleCommentTitle = {
  color: theme.palette.primary.dark,
  fontWeight: 500,
  margin: 0,
};

const styleCommentdate = {
  color: theme.palette.grey[400],
  fontWeight: 500,
  fontSize: "0.75rem",
  margin: 0,
};

const styleCommentdesc = {
  width: "calc(100% - 40px)",
};

const styleCommenttext = {
  color: theme.palette.grey[600],
  fontWeight: 400,
  fontSize: "0.875rem",
  margin: 0,
};

const CustomIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  fontWeight: 300,
  fontSize: "0.875rem",
  color: theme.palette.primary.dark,
  "& svg": {
    marginRight: "8px",
    maxWidth: "15px",
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
}));

const customchip = {
  background: theme.palette.primary.light,
  color: theme.palette.primary.dark,
};

const styleHistoryhead = {
  color: theme.palette.primary.dark,
  fontWeight: 400,
  fontSize: "0.875rem",
  margin: 0,
};

export default function History({ TicketId }: any) {
  const [editorValue, setEditorValue] = useState("");
  const [history, setHistory] = useState<any>([]);
 
  useEffect(() => {
    GetTicketHistoryById();
  }, []);

  const GetTicketHistoryById = async () => {
    await issueManagementService
      .GetTicketHistoryById(TicketId)
      .then((response: any) => {
        if (response.data.isError) {
          toast.error("Unable to load history, Please try again.");
        } else {
          setHistory(response.data);
        }
      });
  };
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  function removeTagsAndAmp(str: any) {
    // Remove HTML tags
    str = str.replace(/<[^>]+>/g, "");
    // Replace ampersands
    str = str.replace(/&amp;/g, '&');
    return str;
  }
  return (
    <>
      <Paper elevation={0} sx={{ p: 3, pb: 0, pl: 0, position: "relative" }}>
        {history && history.length > 0 ? (
          <section>
            <Typography variant="body2">
              <>
                {history.map((value: any, idx: any) => {
                  return (
                    <>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        sx={{ mb: 4 }}
                      >
                        {value.description != "Description are updated" && (
                          <>
                            {/* <Stack sx={styleCommentIconOrange}>
                              {value.createdBy.charAt(0).toUpperCase() +
                                value.createdBy.charAt(6).toUpperCase()}
                            </Stack> */}
                            <AvatarGroup sx={[styles.userAvatar]}>
                              <Avatar
                                {...commonFunctions.stringAvatar(
                                  value.createdBy
                                )}
                              />
                            </AvatarGroup>
                            <Stack sx={styleCommentdesc}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                justifyContent="space-between"
                              >
                                {/* {value?.newValue == null ||
                                  (value?.previousValue == null && ( */}
                                <Typography sx={styleCommentTitle}>
                                  {/* {() => {
                                    if (
                                      value[idx]?.newValue == null ||
                                      value[idx]?.previousValue == null
                                    ) {
                                      <>
                                        <b>{}</b>
                                      </>;
                                    } else {
                                      <>
                                        <b>{value.createdBy}</b> :-{" "}
                                      </>;
                                    }
                                  }} */}
                                  {value?.newValue != null ? (
                                    <b>{value.createdBy} :- </b>
                                  ) : (
                                    <b>{""}</b>
                                  )}
                                  {value.description}
                                </Typography>
                                {/* ))} */}
                                <Typography sx={styleCommentdate}>
                                  {value.createdDate}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={2}
                                sx={{ mt: 1 }}
                                alignItems="center"
                              >
                                {value.previousValue != null ? (
                                  <Chip
                                    label={removeTagsAndAmp(
                                      value.previousValue
                                        ? value.previousValue
                                        : "None")
                                    }
                                    variant="progress"
                                    onClick={handleClick}
                                    sx={customchip}
                                  />
                                ) : (
                                  <></>
                                )}
                                {/* {!value.previousValue && (
                                 
                                    <Chip
                                      label="None"
                                      variant="progress"
                                      onClick={handleClick}
                                      sx={customchip}
                                    />
                                  )} */}
                                {value?.newValue != null ? (
                                  <Icons.ArrowGreen />
                                ) : (
                                  <b>{""}</b>
                                )}

                                {/* {JSON.stringify(value.newValue, null, 2)} */}
                                {value.newValue != null && (
                                  <Chip
                                    label={removeTagsAndAmp(value.newValue)}
                                    onClick={handleClick}
                                    variant="approved"
                                    sx={customchip}
                                  />
                                )}
                              </Stack>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </>
                  );
                })}
              </>
            </Typography>
          </section>
        ) : (
          <>No History found! </>
        )}
      </Paper>
    </>
  );
}
