import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, Box, Button, CircularProgress, TablePagination } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { TextField, InputAdornment } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { projectPortfolioService } from "../../service/project-portfolio-service";
import NotFound from "../../components/styledComponent/NotFound";
import { Link } from "react-router-dom";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { projectManagementServive } from "../../service/project-management-service";
import { toast } from "material-react-toastify";
import HrReporttop from "../reports/HrReportsstyle";

export default function ProjectPortfolio() {
  let skillObj = {
    skill: "",
  };

  let domainObj = {
    domainId: "",
  };
  const navigate = useNavigate();
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (orderBy === "employeeId") {
      return Number(b[orderBy]) < Number(a[orderBy])
        ? -1
        : Number(b[orderBy]) > Number(a[orderBy])
        ? 1
        : 0;
    }
    if (String(b[orderBy]).toLowerCase() < String(a[orderBy]).toLowerCase()) {
      return -1;
    }
    if (String(b[orderBy]).toLowerCase() > String(a[orderBy]).toLowerCase()) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: string | string },
    b: { [key in Key]: string | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortRequest = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const _permission = useSelector((state: any) => state.ProjectPortfolioApp.ProjectPortfolioPermission);
  const [portfolioData, setPortfolioData] = React.useState<any>([]);
  const [technoSkills, setTechnoSkills] = React.useState<any>([]);
  const [skillOptions, setskillsOptions] = React.useState<any[]>([]);
  const [domainChecked, setDomainChecked] = React.useState<any>([]);
  const [domainNames, setDomainNames] = React.useState<any>([]);
  const [searchData, setSearchData] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<any>("projectName");
  const pageCount = Math.ceil(portfolioData?.length / rowsPerPage);
  const [hoveredColumn, setHoveredColumn] = React.useState<string | null>(null);
  const [clientNameSuggestions , setClientNameSuggestions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);

  useEffect(() => {
    if(_permission.page_ManagePortfolioListing_Access !== true){
      navigate("/dashboard");
    }
    getPortfolioProjects(domainChecked, technoSkills);
    getDomainData();
    getTechnologiesData();
  }, [technoSkills, domainChecked, searchData]);

  const getPortfolioProjects = async (domainsChecked: any, skillArray: any) => {
    setToggleLoader(true);
    var payload = {
      search: searchData,
      domains: domainChecked.length > 0 ? domainChecked : [domainObj],
      skills: technoSkills.length > 0 ? technoSkills : [skillObj],
    };
    try {
      await projectPortfolioService
        .getPortfolioProjectsData(payload)
        .then((response: any) => {
          if (response.data.isError) {
            setToggleLoader(false);
          } else {
            setPortfolioData(response.data);
            setToggleLoader(false);
          }
        });
    }
    catch (ex: any) {
      ex.data.Error.map((err: any) => {
        toast.warning(err);
        setToggleLoader(false);
      });
    }
  };

  const getDomainData = async () => {
    const payload = {
      domainName: "",
    };

    await projectPortfolioService
      .getDomainNameData(payload)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          console.log(response.data, "dtata");
          setDomainNames(response.data);
        }
      });
  };

  const getTechnologiesData = async () => {
    var input = {
      module: 2,
      status: "",
      search: "",
    };
    try {
      await projectManagementServive
        .getalllistingsecure(input)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            setskillsOptions(response?.data);
            console.log(response?.data , "data of technologies.")
          }
        });
    }
    catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  }

  const handleCheckboxChange = (e: any) => {
    debugger
    const { checked, value } = e.target;
    setTechnoSkills((prevState: any) => {
      if (checked) {
        return [...prevState, { skill: value }];
      } else {
        return prevState.filter((item: any) => item.skill != value);
      }
    });
  };
  const handleOptionSelect = (event: any, newValue: any) => {
    if (newValue) {
      setSearchData(newValue); 
      setOpen(false); 
      setPage(0);
    }
  };

  const isChecked = (skill: string) => {
    debugger
    return technoSkills.some((item: any) => item.skill === skill);
  };

  const isChecked2 = (domain : string) => {
    debugger
    return domainChecked.some((item: any) => item.domainId === domain); 
  }


  console.log("Checkbox status ---", technoSkills);

  const handleDomainCheckboxChange = (e: any) => {
    debugger
    const { checked, value } = e.target;

    setDomainChecked((prevState: any) => {
      if (checked) {
        return [...prevState, { domainId: value }];
      } else {
        return prevState.filter((item: any) => item.domainId != value);
      }
    });
  };

  console.log(domainChecked.length, "length of domain checked")

  console.log("Checkbox status ---", domainChecked);

  return (
    <div>
    <Box>{toggleLoader && (<CircularProgress sx={HrReporttop.toggleloaders} />)}
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div
            style={{
              background: "#fff",
              top: "104px",

            }}
          >
            <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Technology</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{
                height: "55vh",
                overflowY: "auto",
              }}>
                {skillOptions.map((item: any) => {
                  return (
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox onChange={handleCheckboxChange} checked={isChecked(item?.name)} />
                      }
                        label={item?.name}
                        value={item?.name}
                      />
                    </FormGroup>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Domain</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{
                height: "55vh",
                overflowY: "auto",
              }}>
                {domainNames.map((item: any) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={handleDomainCheckboxChange} checked={isChecked2(item?.id)} />
                        }
                        label={item?.name}
                        value={item?.id}
                      />
                    </FormGroup>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            {(technoSkills.length > 0 || domainChecked.length > 0) && (
              <Button sx={{ ml: "20px" }} onClick={() => {
                setTechnoSkills([]);
                setDomainChecked([]);
              }}>Clear All</Button>
            )}
              
                                 
              
              {/* <Button sx={{ ml: "20px" }} onClick={() => {
                        console.log("gigiew");
                        setDomainChecked([]);
                        setTechnoSkills([])
                                        }}>Clear All</Button> */}
              {/* <Accordion sx={{boxShadow: "none !important" , margin: "0"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Contact </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Environment</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: "none !important", margin: "0" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Department</Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion> */}
            </div>
          </Grid>
          <Grid item xs={10}>
            <Box>
              <Box sx={{ marginBottom: "24px",display:"flex" ,justifyContent:"space-between",mr:"15px"}}>
              <Autocomplete
                  freeSolo
                  open={open}
                  options={clientNameSuggestions} 
                  inputValue={searchData}
                  onInputChange={(event, newInputValue) => {
                    debugger
                    setSearchData(newInputValue); 
                    if(newInputValue.length >= 3) {
                      const updatedSuggestions = portfolioData.filter((item: any) =>
                        item.clientName.toLowerCase().startsWith(newInputValue.toLowerCase())
                      );
                      const clientNamesForSuggestion = updatedSuggestions.map((item: any) => item.clientName);
                      setClientNameSuggestions(clientNamesForSuggestion); 
                      setOpen(true);
                      setPage(0);
                    } else{
                      setOpen(false);
                    }

                  }}
                  onChange={handleOptionSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Search project name"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "100px",
                          padding: "151px",
                          background: "#fff",
                          paddingLeft: "32px",
                          paddingRight: "130px",
                          paddingTop: "14px",
                          paddingBottom: "10px",
                        },
                      }}
                      style={{

                        width: "400px",
                      }}
                    />
                  )}
                />

                {/* <TextField
                  variant="outlined"
                  placeholder="Search project name"
                  value={searchData}
                  onChange={(e) => {
                    debugger
                    setSearchData(e.target.value);
                    console.log(e.target.value, "Text-written");
                    const updatedSuggestions = portfolioData.filter((item :any) => item.clientName.toLowerCase().startsWith(e.target.value.toLowerCase()));
                    const clientNamesForSuggestion = updatedSuggestions.map((item : any) => item.clientName);
                    setClientNameSuggestions(clientNamesForSuggestion);
                    console.log(updatedSuggestions  ,clientNamesForSuggestion ,"Suggestions box data");
                    
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "100px",
                      padding: "10px",
                      background: "#fff",
                      paddingLeft: "20px",
                      paddingRight: "10px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                    },
                  }}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                  }}
                /> */}
                {_permission.tab_ManagePortfolioAdd_Access &&(
                <Button variant="contained"  color="primary"  component={Link}
              to="/add-portfolio">Add Portfolio</Button>)}
              </Box>
              <Paper elevation={0} sx={{ p: 2, height: "80%" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 750 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{cursor: "pointer"}} >  
                        <TableCell
                          onMouseEnter={() => setHoveredColumn("projectName")}
                          onMouseLeave={() => setHoveredColumn(null)}
                          onClick={() => handleSortRequest("projectName")}
                        >
                          Project Name
                          {hoveredColumn === "projectName" ? (
                            order === "asc" ? (
                              <ArrowUpward fontSize="small" />
                            ) : (
                              <ArrowDownward fontSize="small" />
                            )
                          ) : null}
                        </TableCell>

                        <TableCell
                          onMouseEnter={() => setHoveredColumn("clientName")}
                          onMouseLeave={() => setHoveredColumn(null)}
                          onClick={() => handleSortRequest("clientName")}
                        >
                          Client Name
                          {hoveredColumn === "clientName" ? (
                            order === "asc" ? (
                              <ArrowUpward fontSize="small" />
                            ) : (
                              <ArrowDownward fontSize="small" />
                            )
                          ) : null}
                        </TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Contact Person</TableCell>
                        <TableCell>Technology</TableCell>
                        <TableCell
                          onMouseEnter={() => setHoveredColumn("domainName")}
                          onMouseLeave={() => setHoveredColumn(null)}
                          onClick={() => handleSortRequest("domainName")}
                        >
                          Domain
                          {hoveredColumn === "domainName" ? (
                            order === "asc" ? (
                              <ArrowUpward fontSize="small" />
                            ) : (
                              <ArrowDownward fontSize="small" />
                            )
                          ) : null}
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(portfolioData, getComparator(order, orderBy))
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, idx: any) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer"
                              }}
                              onClick={() => {navigate(`/portfolio-view/${item?.projectId}`)}}
                              key={item?.projectId}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                {item?.projectName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                <Box sx={{ display: "flex", gap: "8px" }}
                                 onClick={(e) => {
                                  debugger
                                  e.stopPropagation();
                                  console.log(item?.clientName);
                                  setSearchData(item?.clientName);
                                  setPage(0);
                                  }}
                                  >
                                  <AccountCircleIcon />
                                  {item?.clientName}
                                </Box>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                 {item?.country}

                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                {item?.contactPerson}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                  }}
                                >
                                  {item?.skillSets.length > 0 && (
                                    <Box 
                                    key={item.skillId}
                                    sx={{
                                      border: "1px solid #F6F6F6",
                                      padding: "6px 8px",
                                      borderRadius: "6px",
                                      background: "#F9F9F9",
                                    }}
                                    >
                                      {item?.skillSets.map((skill : any) => 
                                      skill.name || "--"
                                      ).join(", ")}
                                      </Box>
                                  )}
                                  {/* {item?.skillSets.map((item: any) => (
                                    <Box
                                      key={item.skillId}
                                      sx={{
                                        border: "1px solid #F6F6F6",
                                        padding: "6px 8px",
                                        borderRadius: "6px",
                                        background: "#F9F9F9",
                                      }}
                                    >
                                      {item?.name || "--"}
                                    </Box>
                                  ))} */}
                                </Box>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderRight: "1px solid #E9E9EA !important",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      border: "1px solid #F6F6F6",
                                      padding: "6px 8px",
                                      borderRadius: "6px",
                                      background: "#F9F9F9",
                                    }}
                                  >
                                    {item?.domainName || "--"}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="right"
                                component="th"
                                scope="row"
                              >
                                <Box sx={{ display: "flex", gap: "16px" }}>
                                  {_permission.tab_ManagePortfolioView_Access && (
                                  <Box
                                    sx={{
                                      background: "#4B4B5A",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      }}
                                  >
                                    {" "}
                                    <Link
                                      to={`/portfolio-view/${item?.projectId}`}
                                      target="_blank"
                                    >
                                      <RemoveRedEyeIcon
                                        sx={{ color: "#fff" }}
                                      />
                                    </Link>
                                  </Box>
                                  )}
                                  {_permission.tab_ManagePortfolioEdit_Access && (
                                  <Box
                                    sx={{
                                      background: "#4B4B5A",
                                      padding: "8px",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      
                                      }}
                                  >
                                    {" "}
                                    <BorderColorIcon
                                      sx={{ color: "#fff" }}
                                      onClick={() => {
                                        navigate(
                                          `/portfolio-edit/${item?.projectId}`
                                        );
                                      }}
                                    />
                                  </Box>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {portfolioData.length == 0 && (
                    <NotFound NotfoundText="No Result Found" />
                  )}
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25, 30]}
                  component="div"
                  count={portfolioData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={() =>
                    `Total: ${portfolioData?.length}  Page ${
                      page + 1
                    } of ${pageCount}`
                  }
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
