import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Icon, Typography } from '@mui/material';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate, Navigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'material-react-toastify';
import { allowanceService } from '../../../service/master-service/allowance-service';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
interface Files {
    type: string;
    file: Blob;
}
function AddAllowance() {
    const [errorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [buttonDisable, setButtonDisable] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<Files[]>([])
    const [fileHook, setFileHook] = useState<any>(null);
    const [formValues, setFormValues] = useState([{ name: "" }]);
    const [fileUrlHook, setFileUrlHook] = useState<any>(null)
    console.log(name, "name");
    async function handleChange1(e: any) {
        setFileHook(e.target.files[0]);
        setFileUrlHook(URL.createObjectURL(e.target.files[0]));
        var filese_array = [...selectedFiles];
        filese_array = [...selectedFiles, { type: "Test", file: e.target.files[0] }];
        setSelectedFiles(filese_array);      
    }
    let removeFormFields = (i: any) => {
        var filese_array = [...selectedFiles];
        filese_array.splice(i, 1);
        setSelectedFiles(filese_array);
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };
    const addAllowanceDetails = async (values: any) => {
        try {
            await allowanceService.createAllowance(values)
                .then((response: any) => {
                    if (response.data.isError) {
                    }
                    else {
                        toast.success('Allowance added successfully.');
                        navigate('/manage-allowance')
                    }
                });
        }
        catch (ex: any) {         
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    }
    const cancelbutton = async () => {
        navigate("/manage-allowance");
    };

    const fieldValidationSchema = Yup.object().shape({
        name: Yup
            .string()
            .max(55, 'Allowance can only contain 55 Alphabets').matches(/^([A-Za-z\s]*)$/g,
                'Allowance can only contain alphabets.')
            .required('Allowance is required'),
    })
    return (
        <Formik
            initialValues={{
                name: '',
            }}
            validationSchema={fieldValidationSchema}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting,
            }) => {
                try {
                    setErrorMessage("");
                    await addAllowanceDetails(values);
                } catch (err: any) {
                    console.error(err);
                    err = JSON.parse(JSON.stringify(err));
                    if (err.status == 400) {                       
                        {
                            err.data.Error.map((error: any, idx: any) => (
                                setErrorMessage(error)
                            ))
                        }
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Item elevation={0}>
                                <Typography variant="h6" align="left" noWrap component="div" sx={{ fontWeight: 500 }}>
                                    Add Allowances
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name}
                                        label="Name"
                                        margin="normal"
                                        name="name"
                                        onBlur={handleBlur}                                        
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        onChange={(e) => {
                                            let value = e.currentTarget.value.replace(/[^a-zA-Z-.\s]/g,"")
                                            value = value.replace(/\s{2,}$/g, "").trimStart().substring(0,30);                                          
                                           values.name= value
                                          setName(value)
                                          }}
                                        />                     
                                        <Stack
                                          spacing={2}
                                          direction="row"
                                          justifyContent="flex-end"
                                          alignItems="center"
                                          sx={{ mt: 3 }}
                                        >
                                            <Button  onClick={() => cancelbutton()} variant="outlined" color="primary">Cancel</Button>
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={buttonDisable}
                                          >
                                            Submit
                                          </Button>                                        
                                        </Stack>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                    {/* </> */}
                </form>
            )}
        </Formik>
    );
}
export default AddAllowance;