import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Formik, getIn, useFormikContext } from "formik";
import * as Yup from "yup";
import * as Icons from "../../assests/icons/icons";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  responsiveFontSizes,
  Tooltip,
} from "@mui/material";
import { userManagementServive } from "../../service/user-management-service";
import {
  userModelDetail,
  userModel,
  employeeInfo,
  insuranceCard,
  personalInfo,
  documents,
  pastExperince,
  badges,
  achivements,
  skillsets,
  othersDocument,
  kids,
} from "./userModel";
import { toast } from "material-react-toastify";
import { commonKeys } from "../../service/common-keys";
import theme from "../../theme/theme";
import moment from "moment";
import * as icons from "../../assests/icons/icons";
import AppConstants from "../../config/AppConstants";
import { apiUrl } from "../../service/common-services/apiURL";
import { projectManagementServive } from "../../service/project-management-service";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import { inventoryService } from "../../service/Inventory/inventoryService";

export default function PersonalInfo(props: {
  obj: any;
  setValue: any;
  callBackFunction: any;
}) {
  const [isSubmit, setSubmit] = useState(false);
  //use state
  //const { id } = useParams();
  //let uId=id??"";//"632da2f9fa37260b70bf4e1f";
  setTimeout(() => {
    if (!props.obj && props.obj == "") {
      props.setValue(0);
    }
  }, 200);
  const [DisabledButton, setDisabledButton] = useState(true);
  const navigate = useNavigate();
  const bloodGroupsArray = [
    {
      id: "A+",
      name: "A+",
    },
    {
      id: "A-",
      name: "A-",
    },
    {
      id: "B+",
      name: "B+",
    },
    {
      id: "B-",
      name: "B-",
    },
    {
      id: "AB+",
      name: "AB+",
    },
    {
      id: "AB-",
      name: "AB-",
    },
    {
      id: "O+",
      name: "O+",
    },
    {
      id: "O-",
      name: "O-",
    },
  ];
  const dummyRelation = [
    {
      id: "Mother",
      relation: "Mother",
    },
    {
      id: "Father",
      relation: "Father",
    },
    {
      id: "Daughter",
      relation: "Daughter",
    },
    {
      id: "Son",
      relation: "Son",
    },
    {
      id: "Sister",
      relation: "Sister",
    },
    {
      id: "Brother",
      relation: "Brother",
    },
    {
      id: "Aunt",
      relation: "Aunt",
    },
    {
      id: "Uncle",
      relation: "Uncle",
    },
    {
      id: "Wife",
      relation: "Wife",
    },
    {
      id: "Husband",
      relation: "Husband",
    }
  ];
  let _employeeInfo = new employeeInfo();
  let _personalInfo = new personalInfo();
  let _documents = new documents();
  let _pastExperince = new pastExperince();
  let _badges = new badges();
  let _achivements = new achivements();
  let _othersDocument = new othersDocument();
  let _skillsets = new skillsets();
  let _insuranceCard = new insuranceCard();
  let usermodel = new userModel(
    props.obj,
    _employeeInfo,
    _personalInfo,
    _insuranceCard,
    [_documents],
    [_pastExperince],
    [_badges],
    [_achivements],
    [_othersDocument],

    [_skillsets]
  );
  let connonkeys = new commonKeys();
  //let um=new userModelDetail(uId);
  const [userDataHook, setUserDataHook] = React.useState(usermodel);

  const [selectedImage, setSelectedImage] = useState<File>();
  const [value, setValue] = useState(0);
  const [fileHook, setFileHook] = useState<any>(null);
  const [fileUrlHook, setFileUrlHook] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function subtractYears(numOfYears: any, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);
    return date;
  }

  const [dobValue, setDobValue] = useState<Dayjs>(dayjs());
  const [domValue, setDomValue] = useState<Dayjs>(dayjs());

  const onChangeDob = (newValue: Dayjs) => {
    setDobValue(newValue);
  };
  const onChangeDom = (newValue: Dayjs) => {
    setDomValue(newValue);
  };
  const [wmoValue, setWmoValue] = useState<Dayjs>(dayjs());
  const onChangeWmo = (newValue: Dayjs) => {
    setWmoValue(newValue);
  };
  useEffect(() => {
    const getUserDetail = async () => {
      await getUserDetails();
    };
    getUserDetail();
  }, []);

  let emptyKidsObj: kids = {
    name: "",
    dob: null,
  };
  let addMoreKidsFields = () => {
    setkidsArray([...kidsArray, emptyKidsObj]);
    setDisabledButton(false);
  };
  let removeKidsFields = (i: any) => {
    let KidsArray = [...kidsArray];
    KidsArray.splice(i, 1);
    setkidsArray(KidsArray);
    setDisabledButton(true);
  };

  const [dateOfBirth, setDateOfBirth] = useState(
    userDataHook.personalInfo.dateOfBirth ?? null // dobValue
  );
  const [wishMeOn, setWishMeOn] = useState(
    userDataHook.personalInfo.wishMeOn ?? null
  );
  const [nomineeName, setnomineeName] = useState(
    userDataHook.insuranceCard?.nominee ?? " "
  );
  const [relationship, setrelationship] = useState(
    userDataHook.insuranceCard?.relationship ?? " "
  );
  //console.log("wishMeOn", wishMeOn);
  const [spouseName, setspouseName] = useState(
    userDataHook.insuranceCard?.spouseName ?? ""
  );
  const [spouseDOB, setSpouseDOB] = useState(
    userDataHook.insuranceCard?.dob ?? null
  );
  const [kidsArray, setkidsArray] = useState(
    userDataHook.insuranceCard?.childs && userDataHook.insuranceCard?.childs.length > 0
      ? userDataHook.insuranceCard?.childs
      : [emptyKidsObj]
  );
  const [name, setname] = useState(userDataHook.personalInfo.name ?? "");
  const [dob, setdob] = useState(userDataHook.personalInfo.dob ?? null);
  const [gender, setGender] = useState(
    userDataHook.personalInfo.gender ?? "male"
  );
  const [bloodGroup, setBloodGroup] = useState(
    userDataHook.personalInfo.bloodGroup ?? ""
  );

  const [tendateDisable, settenDateDisable] = useState(false);
  // const [childDisable, setChildDisable] = React.useState<any>({});
  const [childDisable, setChildDisable] = useState(true);


  //setAlertOpen({ [index]: true });

  const [maritalStatus, setMaritalStatus] = useState(
    userDataHook.personalInfo.maritalStatus ?? "single"
  );
  const [personalEmailId, setPersonalEmailId] = useState(
    userDataHook.personalInfo.personalEmailId ?? ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    userDataHook.personalInfo.phoneNumber ?? ""
  );
  const [emergencyNumber, setEmergencyNumber] = useState(
    userDataHook.personalInfo.emergencyNumber ?? ""
  );
  const [profileImagePath, setProfileImagePath] = useState(
    userDataHook.personalInfo.profileImagePath ?? ""
  );
  const [address1, setAddress1] = useState(
    userDataHook.personalInfo.address1 ?? ""
  );
  const [address2, setAddress2] = useState(
    userDataHook.personalInfo.address2 ?? ""
  );
  const [permanentAddress1, setPermanentAddress1] = useState(
    userDataHook.personalInfo.permanentAddress1 ?? ""
  );
  const [permanentAddress2, setPermanentAddress2] = useState(
    userDataHook.personalInfo.permanentAddress2 ?? ""
  );
  const [anniversaryDate, setAnniversaryDate] = useState(
    userDataHook.personalInfo.anniversaryDate ?? null
  );
  const [sameAsAddress, setSameAsAddress] = useState(false);

  const [fileNameHook, setfileNameHook] = useState<any>(null);

  let today = new Date();
  let futureDates = new Date(today.setFullYear(today.getFullYear() + 0));

  let dateofAnniversary = new Date(dateOfBirth?.$d);
  dateofAnniversary.setFullYear(dateofAnniversary.getFullYear() + 18);

  const [showHelpText, setshowHelpText] = React.useState(false);


  async function handleChangefile(e: any) {
    setFileHook(e.target.files[0]);

    if (e.target.files.length > 0) {
      var files = e.target.files[0];

      let extension = files?.name
        .split(".")
      [files.name.split(".").length - 1].toLowerCase();

      if (!["jpg", "png", "gif", "jpeg", "JPG", "JPEG"].includes(extension)) {
        // alert("Please select accurate format");
        toast.warning("Please Select jpg, png, gif, jpeg Image Format.");
      } else {
        setFileUrlHook(URL.createObjectURL(e.target.files[0]));
        setDisabledButton(false);
        //setfileNameHook(e.target.files[0].name);
        // toast.success("Image Added Successfully");
      }
    } else {
      setfileNameHook("");
    }
  }

  // async function handleChange1(e: any) {

  //   setFileHook(e.target.files[0]);
  //   setFileUrlHook(URL.createObjectURL(e.target.files[0]));
  //   if (e.target.files.length > 0) {
  //     var files = e.target.files[0];

  //     let extension = files?.name
  //       .split(".")
  //       [files.name.split(".").length - 1].toLowerCase();

  //     if (
  //       ![
  //         "xlsx",
  //         "doc",
  //         "docm",
  //         "docx",
  //         "pdf",
  //         "docx",
  //         "csv",
  //         "xls",
  //         "xlsm",
  //         "xlsb",
  //         "xlsb",
  //         "xltx",
  //       ].includes(extension)
  //     ) {
  //       // alert("Please select accurate format");
  //       toast.warning("Please Select excel,doc or pdf File.");
  //     } else {
  //       setfileNameHook(e.target.files[0].name);
  //     }
  //   } else {
  //     setfileNameHook("");
  //   }
  // }

  const updateUser = async (employeeDetail: any) => {
    setDisabledButton(true);
    // let filterDate = `${moment(wishMeOn).format("MMM DD h:mmA")}`;
    // console.log("filterDate", filterDate);
    try {
      let data = userDataHook;
      let personalInfo = {
        dateOfBirth: dateOfBirth,
        wishMeOn: wishMeOn,
        spouseDOB: spouseDOB,
        name: name,
        dob: dob,
        gender: gender,
        bloodGroup: bloodGroup,
        maritalStatus: maritalStatus,
        relationship: relationship,
        nomineeName: nomineeName,
        spouseName: spouseName,
        personalEmailId: personalEmailId,
        phoneNumber: phoneNumber,
        emergencyNumber: emergencyNumber,
        profileImagePath: profileImagePath,
        address1: address1,
        address2: address2,
        permanentAddress1: permanentAddress1,
        permanentAddress2: permanentAddress2,
        anniversaryDate: anniversaryDate,
        sameAsAddress: sameAsAddress,
        kids: kidsArray.filter((a) => a.name && a.name.length > 0),
      };
      let insuranceCard = {
        relationship: relationship,
        nominee: nomineeName,
        spouseName: spouseName,
        dob: spouseDOB,
        childs: kidsArray.filter((a) => a.name && a.name.length > 0),
      };
      setMaritalStatus(personalInfo.maritalStatus ?? "single");
      if (
        personalInfo.maritalStatus != null &&
        personalInfo.maritalStatus == "married"
      ) {
        setVisible(true);
      } else setVisible(false);
      if (sameAsAddress) {
        personalInfo.permanentAddress1 = personalInfo.address1;
        personalInfo.permanentAddress2 = personalInfo.address2;
      }
      data.personalInfo = personalInfo;
      data.insuranceCard = insuranceCard;
      if (
        profileImagePath != null &&
        profileImagePath != "" &&
        fileHook == null
      ) {
        //data.personalInfo = employeeDetail.personalInfo;
        data.personalInfo.profileImagePath = profileImagePath;

        await updateUserData(data);
      } else {
        if (fileHook == null) {
          // toast.error("Please select a profile picture.")
          // return
          await updateUserData(data);
          return;
        }
        let formData = new FormData();
        formData.append("File", fileHook);
        formData.append("FileFor", "Profile Pic");
        await inventoryService.UploadCategoryImage(formData)
          .then(async (response: any) => {
            if (response.data.isError) {
              toast.error("facing issue while uploading image.");
            } else {
              data.personalInfo.profileImagePath = response.data;

              await updateUserData(data);
            }
          });
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  async function updateUserData(data: any) {
    await userManagementServive.updateUser(data).then((response: any) => {
      if (response.data.isError) {
        toast.error("Something went wrong...");
      } else {
        props.callBackFunction();
        toast.success("Personal info added successfully.",);
        setDisabledButton(true);
      }
    });
  }

  const getUnEncryptedImage = async (fileName: any) => {
    try {
      const response = await inventoryService.uploadMultipleImages(fileName);
      if (response.data.type === "application/json") {
        const data = await response.data.text();
        toast.warning(JSON.parse(data).listErrors[0]);
      } else {
        const imageUrl = URL.createObjectURL(response.data);
        setFileUrlHook(imageUrl);
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const getUserDetails = async () => {
    try {
      if (props.obj != "") {
        await userManagementServive
          .getUserById(props.obj)
          .then((response: any) => {
            if (response.data.isError) {
            } else {
              if (response?.data?.personalInfo == null) {
                let obj = response?.data;
                obj.personalInfo = _personalInfo;
                setUserDataHook(obj);
                return;
              }
              if (response?.data?.insuranceCard == null) {
                let obj = response?.data;
                obj.insuranceCard = insuranceCard;
                setUserDataHook(obj);
              //  return;
              }
              setUserDataHook(response?.data);
              setDateOfBirth(response?.data?.personalInfo?.dateOfBirth ?? null);
              setWishMeOn(response?.data?.personalInfo?.wishMeOn ?? null);
              setnomineeName(response?.data?.insuranceCard?.nominee ?? "");
              setrelationship(response?.data?.insuranceCard?.relationship);
              setspouseName(response?.data?.insuranceCard?.spouseName);
              setSpouseDOB(response?.data?.insuranceCard?.dob ?? null);
              setname(response?.data?.insuranceCard?.name);
              setdob(response?.data?.insuranceCard?.dob ?? null);
              setGender(response?.data?.personalInfo?.gender ?? "male");
              setBloodGroup(response?.data?.personalInfo?.bloodGroup ?? "");
              setMaritalStatus(
                response?.data?.personalInfo?.maritalStatus ?? "single"
              );
              if (
                response?.data?.personalInfo?.maritalStatus != null &&
                response?.data?.personalInfo?.maritalStatus == "married"
              ) {
                setVisible(true);
              } else setVisible(false);
              setPersonalEmailId(
                response?.data?.personalInfo?.personalEmailId ?? ""
              );
              setPhoneNumber(response?.data?.personalInfo?.phoneNumber ?? "");
              setEmergencyNumber(
                response?.data?.personalInfo?.emergencyNumber ?? ""
              );
              setProfileImagePath(
                response?.data?.personalInfo?.profileImagePath ?? ""
              );
              if (response?.data?.personalInfo?.profileImagePath != "" && response?.data?.personalInfo?.profileImagePath != null) {
                setFileUrlHook(
                  apiUrl.getImageURL() +
                  "/" +
                  response?.data?.personalInfo?.profileImagePath ?? ""
                );
              }
              setAddress1(response?.data?.personalInfo?.address1 ?? "");
              setAddress2(response.data.personalInfo.address2 ?? "");
              setPermanentAddress1(
                response?.data?.personalInfo?.permanentAddress1 ?? ""
              );
              setPermanentAddress2(
                response?.data?.personalInfo?.permanentAddress2 ?? ""
              );
              setChildDisable(false);
              setDobValue(response?.data?.personalInfo?.dateOfBirth ?? null);
              setWmoValue(response?.data?.personalInfo?.wishMeOn ?? null);
              setAnniversaryDate(
                response?.data?.personalInfo?.anniversaryDate ?? null
              );
              setSameAsAddress(
                response?.data?.personalInfo?.sameAsAddress ?? false
              );
              setkidsArray(
                response?.data?.insuranceCard?.childs &&
                  response?.data?.insuranceCard?.childs.length > 0
                  ? response?.data?.insuranceCard?.childs
                  : []
              );
            }
          });
      }
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });

      props.setValue(0);
    }
  };
  async function handleChange1(e: any) {
    setFileHook(e.target.files[0]);
    setFileUrlHook(URL.createObjectURL(e.target.files[0]));
  }
  enum Gender {
    male,
    female,
  }
  const getDateString = (dateObject: Date) => {
    if (dateObject == null) {
      return null;
    }
    let date = new Date(dateObject);
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  const getTimeZone = (dateObject: Date) => {
    let date = new Date(dateObject).toISOString();
    return date = date.slice(0, 10) + 'T00:00:00Z';
  }

  const mindate = new Date().getFullYear().toString();


  return (
    <Formik
      initialValues={{
        personalInfo: {
          dateOfBirth: dateOfBirth,
          wishMeOn: wishMeOn,
          //relationship: relationship,
          //nomineeName: nomineeName,
          //spouseName: spouseName,
          // spouseDOB: spouseDOB,
          name: name,
          dob: dob,
          gender: gender,
          bloodGroup: bloodGroup,
          maritalStatus: maritalStatus,
          personalEmailId: personalEmailId,
          phoneNumber: phoneNumber,
          emergencyNumber: emergencyNumber,
          profileImagePath: profileImagePath,
          address1: address1,
          address2: address2,
          permanentAddress1: permanentAddress1,
          permanentAddress2: permanentAddress2,
          anniversaryDate: anniversaryDate,
          sameAsAddress: sameAsAddress,
          // kidsArray: kidsArray,
        },
        insuranceCard: {
          relationship: relationship,
          nominee: nomineeName,
          spouseName: spouseName,
          dob: spouseDOB,
          // name: name,
          // dob: dob,
          childs: kidsArray,
        },
      }}
      validationSchema={Yup.object().shape({
        personalInfo: Yup.object().shape({
          //dateOfBirth: Yup.date().max(new Date()).required(),
          //gender: Yup.string().max(20).required('Gender is required'),
          // bloodGroup: Yup.string().max(20).required('Blood group is required'),
          //maritalStatus: Yup.string().max(20).required('Marital status is required'),
          // personalEmailId: Yup.string().max(50, "Email-id can have a maximum length of 50 characters.").email("Please enter valid email-id."),
          personalEmailId: Yup.string()
            .max(50, "Email must be at most 50 characters")
            .email("Please enter valid email-id"),
          // phoneNumber: Yup.number().typeError("Phone number must be 10 digits."),
          phoneNumber:
            phoneNumber.length > 0
              ? Yup.string()
                .nullable()
                .typeError("Phone number must be digits.")
                .test(
                  "len",
                  "Phone number must be exactly 10 digits",
                  (val) => phoneNumber.length === 10
                )

                .matches(
                  AppConstants.MatchRegExp.MobileNumber,
                  "Phone number is not valid"
                )
              : Yup.string(),
          emergencyNumber:
            emergencyNumber.length > 0
              ? Yup.string()
                .nullable()
                .typeError("Alternate number must be digits.")
                .test(
                  "len",
                  "Alternate number must be exactly 10 digits",
                  (val) => emergencyNumber.length === 10
                )
                .matches(
                  AppConstants.MatchRegExp.MobileNumber,
                  "Alternate number is not valid"
                )
              : Yup.number(),
          bloodGroup: Yup.string().required("Blood Group is required"),
          // address1: Yup.string().matches(
          //   /^[0-9a-zA-Z ]+$/,
          //    "Address must contain Alphanumeric"
          // ),
          // address2: Yup.string().matches(
          //   /^[0-9a-zA-Z ]+$/,
          //   "Address must contain Alphanumeric"
          // ),

          dateOfBirth: Yup.date()
            .typeError("Age must be 18+")
            .required("Date of birth is required"),

          // name: Yup.string()
          // .max(20, "Kid name must be at most 20 characters")
          // .matches(
          //   /^([A-Za-z\s]*)$/g,
          //   "Kid name  can only contain alphabets."
          // ),
          // wishMeOn: Yup.date()
          // .typeError("Age must be 18+"),
          // spouseDOB: Yup.date()
          // .typeError("Age must be 18+"),
          //.max(10,"Phone number can have a maximum length of 10 digits."),
          //profileImagePath: Yup.string().max(20).required('Please upload a profile pic'),
          // address1: Yup.string().max(50).required(),
          // address2: Yup.string().max(50).required(),
          // permanentAddress1: Yup.string().max(50).required(),
          // permanentAddress2: Yup.string().max(50).required(),
          //anniversaryDate: Yup.string().max(50).required()
        }),
        // insuranceCard: Yup.object().shape({
        //   relationship:
        //     maritalStatus == "single"
        //       ? Yup.string()
        //         .required("Relationship Name is required")
        //       : Yup.string().nullable(),
        //   nomineeName:
        //     maritalStatus == "single"
        //       ? Yup.string()
        //         .max(25, "Nominee Name  must be at most 25 characters")
        //         .matches(
        //           /^([A-Za-z\s]*)$/g,
        //           "Nominee Name can only contain alphabets."
        //         )
        //         .required("Nominee Name is required")
        //       : Yup.string().nullable(),

        //   spouseName:
        //     maritalStatus == "married"
        //       ? Yup.string()
        //         .max(25, "Spouse Name must be at most 25 characters")
        //         .matches(
        //           /^([A-Za-z\s]*)$/g,
        //           "Spouse Name can only contain alphabets."
        //         )
        //         .required("Spouse Name is required")
        //       : Yup.string().nullable(),
        //   // spouseDOB:
        //   //   maritalStatus == "married"
        //   //     ? Yup.date()
        //   //       .typeError("Age must be 18+")
        //   //       .required("DOB date is required")
        //   //     : Yup.date().nullable(),
        //   kidsArray: Yup.array().of(
        //     Yup.object().shape({
        //       name:
        //         visible == true
        //           ? Yup.string()
        //             .max(25, "Name must be at most 25 characters")
        //             .matches(
        //               /^([A-Za-z\s]*)$/g,
        //               "Name can only contain alphabets."
        //             )
        //           : Yup.string(),
        //     })
        //   ),
        // }),
      })}
      enableReinitialize
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setErrorMessage(true);
          await updateUser(values);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Left section */}
            <Grid item xs sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ItemPaper elevation={0}>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={2}
                      justifyContent={{ xs: 'center', md: 'start' }}
                      alignItems="center"
                      sx={{ p: 0.5 }}
                    >
                      <Avatar
                        src={fileUrlHook}
                        sx={{
                          width: 132,
                          height: 132,
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        <CameraAltOutlinedIcon

                          sx={{
                            "&.MuiSvgIcon-root": {
                              width: 32,
                              height: 32,
                              fill: theme.palette.primary.main,

                            },
                          }}
                        />
                        {/*                      
                    {gender == "female" &&
                      <icons.Male />
                    }
                    {gender == "male" &&
                      <icons.FeMale />
                    } */}
                      </Avatar>
                      <Box sx={{ width: 300, textAlign: { xs: 'center', md: 'start' } }}>
                        <Button component="label">
                          Upload Profile Picture
                          <input
                            hidden
                            src={fileUrlHook}
                            onChange={handleChangefile}
                            accept="image/*"
                            type="file"
                          />
                          {fileNameHook}
                        </Button>
                      </Box>
                    </Stack>
                  </ItemPaper>
                </Grid>

                {/* ------------------------ */}

                <Grid item xs>
                  <ItemPaper elevation={0}>
                    <Typography variant="body1" gutterBottom>
                      Gender
                    </Typography>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs sm={6}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="male"
                            name="personalInfo.gender"
                            value={gender}
                          >
                            <FormControlLabel
                              value="male"
                              control={<Radio />}
                              label="Male"
                              onClick={(event) => {
                                values.personalInfo.gender = "male";
                                setGender("male");
                                setDisabledButton(false);
                              }}
                            />

                            <FormControlLabel
                              value="female"
                              control={<Radio />}
                              label="Female"
                              onClick={(event) => {
                                setGender("female");
                                values.personalInfo.gender = "female";
                                setDisabledButton(false);
                              }}
                            />
                            <FormControlLabel
                              value="male"
                              control={<Radio />}
                              label="Male"
                              sx={{ visibility: "hidden" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs sm={6}>
                        <TextField
                          id="personalInfo.bloodGroup"
                          select
                          sx={{ "&.MuiTextField-root": {} }}
                          error={Boolean(
                            getIn(touched, "personalInfo.bloodGroup") &&
                            getIn(errors, "personalInfo.bloodGroup")
                          )}
                          helperText={
                            getIn(touched, "personalInfo.bloodGroup") &&
                            getIn(errors, "personalInfo.bloodGroup")
                          }
                          required
                          name="personalInfo.bloodGroup"
                          label="Blood Group"
                          variant="outlined"
                          value={bloodGroup}
                          fullWidth
                        >
                          {bloodGroupsArray.map((option, index) => (
                            <MenuItem
                              key={option.name}
                              value={option.id}
                              onClick={(event) => {
                                values.personalInfo.bloodGroup =
                                  bloodGroupsArray[index].id;
                                setBloodGroup(bloodGroupsArray[index].id);
                                setDisabledButton(false);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </ItemPaper>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ItemPaper elevation={0}>
                    <Stack direction="row" spacing={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          borderRadius="semi-square"
                          minDate={moment().subtract(83, "years").toDate()}
                          maxDate={moment().subtract(18, "years").toDate()}
                          // disableFuture
                          label="Date of Birth"
                          placeholder="Date of Birth"
                          labelAlignment="left"

                          // inputFormat="DD-MMM-YYYY"
                          // openTo="year"
                          className="error"
                          // views={["year", "month", "day"]}
                          value={dateOfBirth}
                          onChange={(newValue: any) => {
                            let abc = getDate(newValue)
                            values.personalInfo.dateOfBirth = abc;
                            setDateOfBirth(abc);
                            setErrorMessage(false);
                            setChildDisable(false);
                            setSubmit(false);
                            setDisabledButton(false);
                            setshowHelpText(false);
                          }}
                          error={showHelpText && !values.personalInfo.dateOfBirth ? "DOB is required" : ""}
                        // renderInput={(params: any) => (
                        //   <TextField
                        //   autoComplete="off" // Disable suggestions
                        //   readOnly={true} // Disable user input
                        //     required
                        //     onKeyDown={(e: any) => {
                        //       e.preventDefault();
                        //     }}
                        //     onChange={(newValue: any) => {
                        //       let dateT = new Date(newValue).toISOString();
                        //       dateT = dateT.slice(0, 10) + 'T00:00:00Z';
                        //       values.personalInfo.dateOfBirth = dateT;
                        //       setDateOfBirth(dateT);
                        //       setErrorMessage(true);
                        //       setSubmit(true);
                        //       setChildDisable(false);
                        //       setDisabledButton(false);
                        //     }}
                        //     {...params}
                        //     error={
                        //       errorMessage && !values.personalInfo.dateOfBirth
                        //     }
                        //     helperText={
                        //       isSubmit && !values.personalInfo.dateOfBirth
                        //         ? "DOB is Required."
                        //         : ""
                        //     }
                        //     fullWidth
                        //     sx={{
                        //       "& .MuiSvgIcon-root": {
                        //         fill: theme.palette.primary.main,
                        //       },
                        //     }}
                        //   />
                        // )}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Wish Me On"
                          borderRadius="semi-square"
                          placeholder="Wish Me On"
                          labelAlignment="left"
                          value={wishMeOn}
                          minDate={new Date(mindate)}
                          maxDate={new Date(new Date().getFullYear() + 0, 11, 31)}
                          onChange={(newValue: any) => {    
                            let abc = getDate(newValue)                     
                           // setWishMeOn(moment(abc).format('yyyy-MM-DD'));
                            setWishMeOn(abc)
                            setErrorMessage(false);
                            setDisabledButton(false);
                            setSubmit(false);
                          }}
                        />
                      </LocalizationProvider>


                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Wish Me On"
                          borderRadius="semi-square"
                          placeholder="Wish Me On"
                          labelAlignment="left"


                          value={wishMeOn}
                          minDate={new Date(mindate)}
                          maxDate={
                            new Date(new Date().getFullYear() + 0, 11, 31)
                          }
                          onChange={(newValue: any) => {

                            // let dateT = new Date(newValue).toISOString();
                            // dateT = dateT.slice(0, 10) + 'T00:00:00Z';
                            // values.personalInfo.wishMeOn = dateT;
                            // setWishMeOn(dateT);
                            const selectedDate = newValue.toISOString().split('T')[0];
                            values.personalInfo.wishMeOn = selectedDate;
                            setWishMeOn(selectedDate);
                            setErrorMessage(false);
                            setDisabledButton(false);
                            setSubmit(false);
                          }}
                        // renderInput={(params: any) => (
                        //   <TextField
                        //     fullWidth
                        //     onKeyDown={(e: any) => {
                        //       e.preventDefault();
                        //     }}
                        //     {...params}
                        //     autoComplete="off" // Disable suggestions
                        //     readOnly={true} // Disable user input
                        //     onBlur={handleBlur}
                        //     onChange={(newValue: any) => {
                        //       values.personalInfo.wishMeOn = newValue
                        //         .toISOString()
                        //         .split("T")[0];
                        //       let previous = new Date(newValue.getTime());
                        //       setWishMeOn(
                        //         newValue.toISOString().split("T")[0]
                        //       );
                        //       setErrorMessage(false);
                        //       setDisabledButton(false);
                        //       setSubmit(false);
                        //     }}
                        //     error={
                        //       getIn(touched, "wishMeOn") &&
                        //       getIn(errors, "wishMeOn")
                        //     }
                        //     helperText={
                        //       getIn(touched, "wishMeOn") &&
                        //       getIn(errors, "wishMeOn")
                        //     }
                        //     sx={{
                        //       "& .MuiSvgIcon-root": {
                        //         fill: theme.palette.primary.main,
                        //       },
                        //     }}
                        //   />
                        // )}
                        />
                      </LocalizationProvider> */}
                    </Stack>
                  </ItemPaper>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ItemPaper elevation={0}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TextField
                        id="personalInfo.personalEmailId"
                        error={Boolean(
                          getIn(touched, "personalInfo.personalEmailId") &&
                          getIn(errors, "personalInfo.personalEmailId")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.personalEmailId") &&
                          getIn(errors, "personalInfo.personalEmailId")
                        }
                        //   required
                        label="Email ID"
                        variant="outlined"
                        fullWidth
                        name="personalInfo.personalEmailId"
                        value={personalEmailId}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          values.personalInfo.personalEmailId =
                            event.currentTarget.value.trimStart();
                          setPersonalEmailId(
                            event.currentTarget.value.trimStart()
                          );
                          setDisabledButton(false);
                        }}
                      />

                    </Stack>
                  </ItemPaper>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <ItemPaper elevation={0}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TextField
                        id="personalInfo.phoneNumber"
                        error={Boolean(
                          getIn(touched, "personalInfo.phoneNumber") &&
                          getIn(errors, "personalInfo.phoneNumber")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.phoneNumber") &&
                          getIn(errors, "personalInfo.phoneNumber")
                        }
                        // required
                        label="Phone Number"
                        variant="outlined"
                        fullWidth

                        name="personalInfo.phoneNumber"
                        value={phoneNumber}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          let value = event.currentTarget.value
                            .replace(/\s{0,}$/g, "")
                            .trimStart()
                            .substring(0, 10);
                          values.personalInfo.phoneNumber = value;
                          setPhoneNumber(value);
                          setDisabledButton(false);
                        }}
                      />
                      <TextField
                        id="personalInfo.emergencyNumber"
                        error={Boolean(
                          getIn(touched, "personalInfo.emergencyNumber") &&
                          getIn(errors, "personalInfo.emergencyNumber")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.emergencyNumber") &&
                          getIn(errors, "personalInfo.emergencyNumber")
                        }
                        // required
                        label="Alternate Mobile Number"
                        variant="outlined"
                        fullWidth
                        name="personalInfo.emergencyNumber"
                        value={emergencyNumber}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          let value = event.currentTarget.value
                            .replace(/\s{0,}$/g, "")
                            .trimStart()
                            .substring(0, 10);
                          values.personalInfo.emergencyNumber = value;
                          setEmergencyNumber(value);
                          setDisabledButton(false);
                        }}
                      />
                    </Stack>
                  </ItemPaper>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Section */}
            <Grid item xs sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ItemPaper elevation={0}>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        id="personalInfo.address1"
                        error={Boolean(
                          getIn(touched, "personalInfo.address1") &&
                          getIn(errors, "personalInfo.address1")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.address1") &&
                          getIn(errors, "personalInfo.address1")
                        }
                        //  required
                        label="Address Line 1"
                        variant="outlined"
                        fullWidth
                        name="personalInfo.address1"
                        value={address1}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          values.personalInfo.address1 =
                            e.currentTarget.value.trimStart();
                          if (
                            e.currentTarget.value.trimStart().length <= 0 &&
                            permanentAddress2.length <= 0
                          ) {
                            setSameAsAddress(false);
                          }
                          setAddress1(e.currentTarget.value.trimStart().substring(0, 150));
                          if (sameAsAddress) {
                            setPermanentAddress1(
                              e.currentTarget.value
                                .trimStart()
                                .substring(0, 150)
                            );
                          }
                        }}
                      />
                      <TextField
                        id="personalInfo.address2"
                        error={Boolean(
                          getIn(touched, "personalInfo.address2") &&
                          getIn(errors, "personalInfo.address2")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.address2") &&
                          getIn(errors, "personalInfo.address2")
                        }
                        // required
                        label="Address Line 2"
                        variant="outlined"
                        fullWidth
                        name="personalInfo.address2"
                        value={address2}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          values.personalInfo.address2 =
                            e.currentTarget.value.trimStart();
                          if (
                            e.currentTarget.value.trimStart().length <= 0 &&
                            permanentAddress1.length <= 0
                          ) {
                            setSameAsAddress(false);
                          }
                          setAddress2(e.currentTarget.value.trimStart().substring(0, 150));
                          setDisabledButton(false);
                          if (sameAsAddress) {
                            setPermanentAddress2(
                              e.currentTarget.value
                                .trimStart()
                                .substring(0, 150)
                            );
                          }
                        }}
                      />
                    </Stack>
                  </ItemPaper>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="personalInfo.sameAsAddress"
                          onClick={(event) => {
                            setDisabledButton(false);
                            let a = !sameAsAddress;
                            if (a == true) {
                              setPermanentAddress1(address1);
                              setPermanentAddress2(address2);
                            } else {
                              setPermanentAddress1("");
                              setPermanentAddress2("");
                            }
                            setSameAsAddress(a);
                          }}
                          checked={sameAsAddress}
                        />
                      }
                      label="Same copy address"
                    />
                  </FormGroup>
                  <ItemPaper elevation={0} sx={{ mb: 1 }}>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        id="personalInfo.permanentAddress1"
                        error={Boolean(
                          getIn(touched, "personalInfo.permanentAddress1") &&
                          getIn(errors, "personalInfo.permanentAddress1")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.permanentAddress1") &&
                          getIn(errors, "personalInfo.permanentAddress1")
                        }
                        //  required
                        label="Permanent Address 1"
                        variant="outlined"
                        fullWidth
                        name="personalInfo.permanentAddress1"
                        value={permanentAddress1}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          values.personalInfo.permanentAddress1 =
                            e.currentTarget.value.trimStart();
                          if (
                            values.personalInfo.permanentAddress1.length <= 0
                          ) {
                            setSameAsAddress(false);
                          }
                          setPermanentAddress1(
                            e.currentTarget.value.trimStart().substring(0, 150)
                          );
                          setDisabledButton(false);
                        }}
                      />
                      <TextField
                        id="opersonalInfo.permanentAddress2"
                        error={Boolean(
                          getIn(touched, "personalInfo.permanentAddress2") &&
                          getIn(errors, "personalInfo.permanentAddress2")
                        )}
                        helperText={
                          getIn(touched, "personalInfo.permanentAddress2") &&
                          getIn(errors, "personalInfo.permanentAddress2")
                        }
                        //   required
                        label="Permanent Address 2"
                        variant="outlined"
                        fullWidth
                        name="personalInfo.permanentAddress2"
                        value={permanentAddress2}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          values.personalInfo.permanentAddress2 =
                            e.currentTarget.value.trimStart();
                          if (
                            values.personalInfo.permanentAddress2.length <= 0
                          ) {
                            setSameAsAddress(false);
                          }
                          setPermanentAddress2(
                            e.currentTarget.value.trimStart().substring(0, 150)
                          );
                          setDisabledButton(false);
                        }}
                      />
                    </Stack>
                  </ItemPaper>
                </Grid>

                <Grid item xs={12}>
                  <ItemPaper elevation={0}>
                    <Typography variant="body1" gutterBottom>
                      Marital status
                    </Typography>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs>
                        <FormControl sx={{ minWidth: "322px" }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="single"
                            name="personalInfo.maritalStatus"
                            value={maritalStatus}
                          >
                            <FormControlLabel
                              onClick={(event) => {
                                setVisible(false);
                                setMaritalStatus("single");
                                setAnniversaryDate(null);
                                setspouseName("");
                                setSpouseDOB(null);
                                setnomineeName("");
                                setrelationship("");
                                setname("");
                                setkidsArray([]);
                                setdob(null);
                                values.personalInfo.maritalStatus = "single";
                                setDisabledButton(false);
                              }}
                              value="single"
                              control={<Radio />}
                              label="Single"
                            />
                            <FormControlLabel
                              onClick={(event) => {
                                setVisible(true);
                                setMaritalStatus("married");
                                setnomineeName("");
                                setrelationship("");
                                setDisabledButton(false);
                                setkidsArray([]);
                                values.personalInfo.maritalStatus = "married";
                              }}
                              value="married"
                              control={<Radio />}
                              label="Married"
                            />
                            <FormControlLabel
                              onClick={(event) => {
                                setVisible(false);
                                setMaritalStatus("divorced");
                                setAnniversaryDate(null);
                                setSpouseDOB(null);
                                setnomineeName("");
                                setrelationship("");
                                setspouseName("");
                                setname("");
                                setkidsArray([]);
                                setdob(null);
                                setDisabledButton(false);
                                values.personalInfo.maritalStatus = "divorced";
                              }}
                              value="divorced"
                              control={<Radio />}
                              label="Divorced"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>


                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          {visible && (
                            <DatePicker
                              // maxDate={new Date()}
                              // minDate={dateofAnniversary}
                              // maxDate={futureDates}
                              // disableFuture
                              label="Marriage Anniversary"
                              borderRadius="semi-square"
                              placeholder="Marriage Anniversary"
                              labelAlignment="left"

                              // openTo="day"
                              // views={["month", "day"]}
                              // inputFormat="MMMM DD"
                              value={anniversaryDate}
                              onChange={(newValue: any) => {
                                let abc = getDate(newValue);
                                values.personalInfo.anniversaryDate = abc;
                                setAnniversaryDate(abc);
                                setErrorMessage(false);
                                setDisabledButton(false);
                                setSubmit(false);
                              }}
                            // renderInput={(params: any) => (
                            //   <TextField
                            //     {...params}
                            //     onKeyDown={(e: any) => {
                            //       e.preventDefault();
                            //     }}
                            //     onChange={(newValue: any) => {
                            //       values.personalInfo.anniversaryDate =
                            //         newValue;
                            //       setAnniversaryDate(newValue);
                            //       setDisabledButton(false);
                            //       // setErrorMessage(true);
                            //       setSubmit(true);
                            //     }}
                            //     {...params}
                            //     fullWidth
                            //     sx={{
                            //       "& .MuiSvgIcon-root": {
                            //         fill: theme.palette.primary.main,
                            //       },
                            //     }}
                            //   />
                            // )}
                            />
                          )}
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </ItemPaper>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
                    This information will be used for Insurance purposes
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ItemPaper elevation={0}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TextField
                        id="insuranceCard.nominee"
                        // error={Boolean(
                        //   getIn(touched, "personalInfo.nomineeName") &&
                        //   getIn(errors, "personalInfo.nomineeName")
                        // )}
                        helperText={
                          getIn(touched, "insuranceCard.nominee") &&
                          getIn(errors, "insuranceCard.nominee")
                        }
                        label="Nominee Name"
                        variant="outlined"
                        fullWidth
                        name="insuranceCard.nominee"
                        value={values.insuranceCard.nominee}
                        onChange={(e) => {
                          values.insuranceCard.nominee =
                            e.currentTarget.value;
                          setnomineeName(e.currentTarget.value);
                          setDisabledButton(false);

                        }}
                      />

                      <TextField
                        id="insuranceCard.relationship"
                        select
                        // error={Boolean(
                        //   getIn(touched, "personalInfo.relationship") &&
                        //   getIn(errors, "personalInfo.relationship")
                        // )}
                        helperText={
                          getIn(touched, "insuranceCard.relationship") &&
                          getIn(errors, "insuranceCard.relationship")
                        }
                        label="Relationship"
                        variant="outlined"
                        fullWidth
                        value={relationship}
                      // name="insuranceCard.relationship"

                      >
                        {dummyRelation.map((option, idx) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            onClick={(event) => {
                              values.insuranceCard.relationship = option.id;
                              setrelationship(option.relation);
                              setDisabledButton(false);
                              setErrorMessage(true);
                              setSubmit(false);
                            }}
                          >
                            {option.relation}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                  </ItemPaper>
                </Grid>
                {visible && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <ItemPaper elevation={0}>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <TextField
                            id="insuranceCard.spouseName"
                            error={Boolean(
                              getIn(touched, "insuranceCard.spouseName") &&
                              getIn(errors, "insuranceCard.spouseName")
                            )}
                            helperText={
                              getIn(touched, "insuranceCard.spouseName") &&
                              getIn(errors, "insuranceCard.spouseName")
                            }
                            label="Spouse Name"
                            variant="outlined"
                            fullWidth
                            name="insuranceCard.spouseName"
                            value={values.insuranceCard.spouseName}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              values.insuranceCard.spouseName =
                                e.currentTarget.value;
                              setspouseName(e.currentTarget.value);
                              setDisabledButton(false);
                            }}
                          />

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // disableFuture
                              // maxDate={new Date()}
                              borderRadius="semi-square"

                              minDate={moment().subtract(83, "years").toDate()}
                              maxDate={moment().subtract(18, "years").toDate()}
                              label="Date of Birth"
                              placeholder="Date of Birth"
                              labelAlignment="left"
                              // inputFormat="DD-MMM-YYYY"
                              className="error"
                              // openTo="year"
                              // views={["year", "month", "day"]}
                              value={values.insuranceCard.dob}
                              onChange={(newValue: any) => {
                                let abc = getDate(newValue);
                                values.insuranceCard.dob = abc;
                                setSpouseDOB(abc);
                                setErrorMessage(false);
                                setSubmit(false);
                                setDisabledButton(false);
                                setshowHelpText(false);
                              }}
                           //   error={showHelpText && !values.insuranceCard.dob ? "DOB is required" : ""}
                            // renderInput={(params: any) => (
                            //   <TextField
                            //     fullWidth
                            //     onKeyDown={(e: any) => {
                            //       e.preventDefault();
                            //     }}
                            //     {...params}
                            //     autoComplete="off" // Disable suggestions
                            //     readOnly={true} // Disable user input
                            //     error={
                            //       errorMessage &&
                            //       !values.insuranceCard.dob
                            //     }
                            //     helperText={
                            //       isSubmit && !values.insuranceCard.dob
                            //         ? "DOB is Required."
                            //         : ""
                            //     }
                            //     sx={{
                            //       "& .MuiSvgIcon-root": {
                            //         fill: theme.palette.primary.main,
                            //       },
                            //     }}
                            //   />
                            // )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </ItemPaper>
                    </Grid>
                  </>
                )}

                {visible && (
                  <>
                    {kidsArray.map((kid, index) => (
                      <Grid item xs={12} sm={12}>
                        <ItemPaper elevation={0}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <TextField
                              fullWidth
                              error={Boolean(
                                getIn(
                                  touched,
                                  `insuranceCard.childs.${index}.name`
                                ) &&
                                getIn(
                                  errors,
                                  `insuranceCard.childs.${index}.name`
                                )
                              )}
                              helperText={
                                getIn(
                                  touched,
                                  `insuranceCard.childs.${index}.name`
                                ) &&
                                getIn(
                                  errors,
                                  `insuranceCard.childs.${index}.name`
                                )
                              }
                              name={`insuranceCard.childs.${index}.name`}
                              onBlur={handleBlur}
                              id={"name_" + index}
                              label="Child Name"
                              value={kid.name || ""}
                              variant="outlined"
                              disabled={!visible}
                              onChange={(event) => {
                                let obj = [...kidsArray];
                                obj[index].name = event.currentTarget.value;
                                setkidsArray(obj);
                                setDisabledButton(false);
                              }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                maxDate={new Date()}
                                // disableFuture
                                label="Date of Birth"
                                placeholder="Date of Birth"
                                labelAlignment="left"
                                borderRadius="semi-square"



                                // openTo="year"
                                // inputFormat="DD-MMM-YYYY"
                                // views={["year", "month", "day"]}
                                value={kid.dob || ""}
                                minDate={new Date(dateOfBirth)}
                                disabled=
                                {childDisable}
                                onChange={(newValue: any) => {
                                  let dayjsdate = getDate(newValue)
                                  let obj = [...kidsArray];
                                  obj[index].dob = dayjsdate;
                                  setkidsArray(obj);
                                  setDisabledButton(false);
                                  setChildDisable(false);
                                  setshowHelpText(false);
                                }}
                              //  error={showHelpText && !kid.dob ? "DOB is Required" : ""}
                              // renderInput={(params: any) => (
                              //   <TextField
                              //     fullWidth
                              //     onKeyDown={(e: any) => {
                              //       e.preventDefault();
                              //     }}
                              //     {...params}
                              //     autoComplete="off" // Disable suggestions
                              //     readOnly={true} // Disable user input
                              //     onBlur={handleBlur}
                              //     error={Boolean(
                              //       getIn(
                              //         touched,
                              //         `personalInfo.kidsArray.${index}.dob`
                              //       ) &&
                              //       getIn(
                              //         errors,
                              //         `personalInfo.kidsArray.${index}.dob`
                              //       )
                              //     )}
                              //     helperText={
                              //       getIn(
                              //         touched,
                              //         `personalInfo.kidsArray.${index}.dob`
                              //       ) &&
                              //       getIn(
                              //         errors,
                              //         `personalInfo.kidsArray.${index}.dob`
                              //       )
                              //     }
                              //     sx={{
                              //       "& .MuiSvgIcon-root": {
                              //         fill: theme.palette.primary.main,
                              //       },
                              //     }}
                              //   />
                              // )}
                              />
                            </LocalizationProvider>

                            <Tooltip title="Remove">
                              <IconButton
                                color="secondary"
                                onClick={(e) => {
                                  removeKidsFields(index);
                                  setDisabledButton(false);
                                }}
                              >
                                <Icons.Delete />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </ItemPaper>
                      </Grid>
                    ))}
                    <Box>
                      <Tooltip title="Click here to add more childs.">
                        <Button
                          endIcon={<AddIcon />}
                          onClick={() => addMoreKidsFields()}
                        >
                          Add More{" "}
                        </Button>
                      </Tooltip>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 3 }} />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 3 }}
          >
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setErrorMessage(true);
                setSubmit(true);
                setshowHelpText(!dateOfBirth || !values.insuranceCard.dob);
              }}
              disabled={DisabledButton}

            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                props.setValue(3);
              }}
            >
              Continue{" "}
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
}
