import React from 'react';



export default function ScreenManagement() {
    return (
        <>
            <h1>ScreenManagement ff</h1>
        </>
    );
}