
import { http } from "./http-common";

class BannerUploadServices {

    addUpdateBannerImages = async (data: any): Promise<any> => {
        return await http.post<any>("/v1/Dashboard/AddUpdateBannerForAll", data);
    };
    getBannerImage = async (data: any): Promise<any> => {
        return await http.post<any>(`/v1/Dashboard/GetBannerCompanyName?companyName=${data}`);
    };
    
}

export const bannerUploadServices = new BannerUploadServices();