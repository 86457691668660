import { http } from "./http-common";

class projectPortfolioServices {

  getPortfolioProjectsData = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Project/GetAllProjectsByMultiSelectFilter", data);
  };

  getDomainNameData = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/Master/GetAllDomainList", data);
  };

  getAllRoleForUser = async (): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/GetAllPortfolioRoles");
  };

  getPortfolioDetailsByProjectId = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/GetPortfolioDetailById?Id=${id}`);
  };

  getOneDriveFolderByProjectId = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/GetOneDriveItems?projectId=${id}`);
  };
  getOneDriveFolderDataByProjectId = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/GetOneDriveItems?folderId=${id}`);
  };
  getOneDriveFileViewByItemId = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/ViewFileFromOneDrive?fileId=${id}`);
  };

  getTeamMembersRoles = async (): Promise<any> => {
    return await http.post<any>('/v1/Project/GetAllPortfolioRoles');
  };
  
  getOneDriveFileViewByItemIdForCaseStudy = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/ViewCaseStudyFromOneDrive?fileId=${id}`);
  };

  updatePorfolioProject = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/Project/UpdatePortflioDetails", data);
  };
  uploadDocumentOnOneDriveBasisOfFolderId = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/UploadFilesToOneDrive", data);
  };
  uploadDocumentOnOneDriveForCaseStudyBasisOfFolderId = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/UploadCaseStudyFileToOneDrive", data);
  };
  deleteOneDriveItemByItemId = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/ProjectPortfolio/DeleteFileFromOneDrive?fileId=${id}`);
  };
  addPorfolioProject = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/CreateProjectPortfolio", data);
  };
  createProjectFolderByProjId = async (data: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/CreateOneDriveFolderForProject?projectId=${data.projectId}&folderName=${data.folderName}`);
  };
  addProjectPortfolioLink = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/AddPortfolioLink", data);
  };
  getProjectPortfolioAttachmentByFolder = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/GetPortfolioLink", data);
  };
  deletePortFolioLinkById = async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/ProjectPortfolio/DeletePortfolioLink?id=${id}`);
  };
  getDomainPortfolioList = async (data:any): Promise<any> => {
    return await http.post<any>(`/v1/ProjectPortfolio/GetAllDomainPortfolio?search=${data}`);
  };
  getDomainPortfolioDocumentsById = async (id: any): Promise<any> => {
    return await http.get<any>(`/v1/ProjectPortfolio/DomainPortfolioDocumentsById?id=${id}`);
  };
  addDomainPortfolioDocuments = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/AddDomainPortfolioDocuments", data);
  };
  updateDomainPorfolio = async (data: any): Promise<any> => {
    return await http.post<any>("/v1/ProjectPortfolio/UpdateDomainPortflioDetails", data);
  };
  deleteDomainPortFolio= async (id: any): Promise<any> => {
    return await http.delete<any>(`/v1/ProjectPortfolio/DeleteDomainPortfolioById?id=${id}`);
  };
}

export const projectPortfolioService = new projectPortfolioServices();
