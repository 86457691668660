import React, { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Autocomplete, CircularProgress, FormControl, Modal, Select, TextField, Typography } from "@mui/material";
import SearchBar from "../../components/styledComponent/SearchBar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import * as Icons from "../../assests/icons/icons";
import theme from "../../theme/theme";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import { projectManagementServive } from "../../service/project-management-service";
import AssignResource from "./Controls/AssignResources";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import NotFound from "../../components/styledComponent/NotFound";
import Notes from "../../components/styledComponent/Note";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Department } from "../../Interfaces/IDepartment";
import { clsDepartmentList } from "../../service/common-services/department";
import { commonFunctions } from "../../service/common-services/common-functions";
import { useSelector } from "react-redux";
import Badge from '@mui/material/Badge';
import Myprojectttop from "./Myprojectstyle";
import InfoPopover from "../../../src/components/CommonInfoComponent";
import { toast } from "material-react-toastify";
import * as Yup from "yup";
import { Formik, getIn, useFormik } from "formik";
import { AxiosError } from "axios";

const styles = {
  toggleloaders: {
    position: "fixed",
    left: "50%",
    right: "50%",
    top: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)"
  },
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};
const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  height: "100%",
  transition: "all 0.3s ease ",
  "&:hover": {
    boxShadow: " 0px 0px 40px rgba(135, 135, 135, 0.25)",
    border: "none",
    "& .MuiPaper-root": {
      borderColor: "transparent",
    },
  },



  // paddingLeft: theme.spacing(0.5),
  // paddingRight: theme.spacing(0.5),
  // paddingBottom: theme.spacing(0.5),

  // marginRight: theme.spacing(2),
  // marginBottom: theme.spacing(2),
  // maxWidth: 312,
  // flex: 1,
  // [theme.breakpoints.up("lg")]: {

  // },
}));

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  alignItems: "stretch",
  height: "100%",
  // maxWidth: 408,
}));

const StyledToggleButton = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      display: "flex",
      mr: { xs: 0, md: 3 },
    },
  },
  "& .MuiToggleButtonGroup-grouped": {
    marginRight: "15px",
    border: 0,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.dark,
    textTransform: "capitalize",
    padding: "4px 6px",
    height: "28px",
    [theme.breakpoints.down('md')]: {
      padding: "4px 2px",
      marginRight: "5px",

    },

    "&.Mui-selected": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
    "&:last-of-type": {
      marginRight: theme.spacing(0),
    },
  },
}));

interface Data {
  projectDescription: string;
  projectName: string;
  projectOwnerName: string;
  keyWords: string;
  clientName: string;
  departmentId: string;
  departmentName: string;
  costingType: string;
  status: string;
  id: string;
  pin: string;
  createdDate: string;
  projectKey: string;
  projectOwnerId: string;
  srNo: string;
}

const ProjectStatus =
{
  "1": "New",
  "2": "In Progress",
  "3": "Completed",
  "4": "On Hold"
}
enum ProjectStatus1 {
  "New" = "1",
  "InProgress" = "2",
  "Completed" = "3",
  "OnHold" = "4"
}
export default function Manager() {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const eleaveStatus = Object.values(ProjectStatus1);
  const [projects, setProjects] = useState<any[]>([]);
  // const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  // const [selectedDepartmentIndex, setselectedDepartmentIndex] =
  //   React.useState(0);
  const [departmentHook, setDepartmentHook] = React.useState("");
  const [selectedProjectId, setSelectedProjectId] = React.useState("");

  // const [projectId, setProjectId] = React.useState("");
  // const [alignment, setAlignment] = React.useState("0");
  const [projectStatus, setProjectStatus] = React.useState({});
  const [statusDataHook, setStatusDataHook] = useState("2");
  // const [localUserId, setLocalUserId] = useState(_authUser.id);

  const [currentLoggedInRole, setLoggedInRole] = useState(0);
  const [currentLoggedInDepartmentId, setLoggedInDepartmentId] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProjectId("");
  };
  useEffect(() => {
    if (_authUser.typeOfUser) {
      let curreUserRole = _authUser.typeOfUser;
      let curreUserDepartMentId = _authUser.employeeInfo.departmentId;
      curreUserRole = curreUserRole ? curreUserRole : "";
      curreUserDepartMentId = curreUserDepartMentId
        ? curreUserDepartMentId
        : "";
      setLoggedInDepartmentId(curreUserDepartMentId);
      setLoggedInRole(curreUserRole);
    }

    if (_authUser.employeeInfo.departmentId) {
      let curreUserDepartMentId = _authUser.employeeInfo.departmentId;
      curreUserDepartMentId = curreUserDepartMentId
        ? curreUserDepartMentId
        : "";
      setLoggedInDepartmentId(curreUserDepartMentId);
    }
    const LoadMasterData = async () => {
      setDepartments(await clsDepartmentList.getDepartmentDropdownList());
      getProjectDetails("");
    };
    LoadMasterData()
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const handleChange = async (
    event: any,
    // newAlignment: string,
    index: any
  ) => {

    setStatusDataHook(event.target.value);

    setProjectStatus(getProjectStatus(event.target.value));
    setDepartmentHook(departments[event.target.value].id);
    // await setSelectedIndex("index");
    await setAnchorEl(null);
    await getProjectDetails(searchValue, event.target.value);
  };

  // var departmentData = { id: "", name: "All Projects" };
  // const getDepartments = async () => {
  //   let prm = { module: 1, status: "active" };
  //   await projectManagementServive
  //     .getDropdownData(prm)
  //     .then((response: any) => {
  //       if (response.data.isError) {
  //       } else {
  //         let data = []; //response.data;//[departmentData];
  //         data.push(departmentData);
  //         response.data.map((dep: any) => {
  //           data.push(dep);
  //         });
  //         setDepartments(data);
  //       }
  //     });
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  // const handleClose = (id: any) => {
  //   setAnchorEl(null);
  // };
  //console.log("departmentHook", departmentHook);
  // useEffect(() => {
  //   getProjectDetails("");
  //   //getDepartments();
  // }, []);

  const getProjectDetails = async (search: any, status: any = 2) => {
    setToggleLoader(true);
    let values = {
      // department: departmentId ?? "",
      status: status,
      search: search ?? "",
      take: 1000,
      skip: 0,
      isManageProject: false,
      isMyProject: true,
    };
try{
    await projectManagementServive
      .getMyProjects(values)
      .then(async (response: any) => {
        if (response.data.isError) {
          setToggleLoader(false);
        } else {
        setProjects(response.data);
        setToggleLoader(false);
       let response1= await  projectManagementServive.geOthersProjectsLogsheetCount(values)
        const updatedProjects = response?.data?.map((project: any) => {
          const matchingProject = response1?.data?.find((p: any) => p?.projectId === project?.id);
          if (matchingProject) {
            // If a matching project is found, update unApproveStatusCount
            project.unApproveStatusCount = matchingProject.count;
          }
          console.log(project, "response1response1")
          return project;
        });
        setProjects(updatedProjects);
      }
      });}
      catch (ex: any) {
        ex.data.Error.map((err: any, idx: any) => {
          setToggleLoader(false);
          toast.warning(err);
        });
      }
  };

  const updateProjectstatus = async (status: any, id: any) => {
    debugger
    let prm = { status: status, projectId: id };
    try {
      const response = await projectManagementServive.UpdateProjectStatus(prm);
      if (response.data.isError) {
          
      }
      else {
          getProjectDetails("", statusDataHook);
          setOpenDialog(false);
          setSelectedProjectId("");
          toast.success("Status Updated Successfully");
          console.log(projectStatus)
      }
  } catch (error : any) {
    if (error.status === 400){
      toast.warning(error.data.Error[0]);
    } else {
      toast.warning("An error occurred. Please try again later.");
  }
          setOpenDialog(false);
          setSelectedProjectId("");
  }
  };


  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const triggerSearch1 = async (obj: any, search: any) => {
    getProjectDetails(search,statusDataHook);
    setSearchValue(search);
  };
  const openDropdown = Boolean(anchorEl);
  const handleCloseStatus = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {

    setAnchorEl(event.currentTarget);
  };
  const getProjectStatus = (currentStatus: any) => {
    switch (currentStatus) {
      case "1":
        return Myprojectttop.New;
      case "2":
        return Myprojectttop.Inprogress;
      case "3":
        return Myprojectttop.Completed;
      case "4":
        return Myprojectttop.Onhold;
      default:
        return Myprojectttop.New;
    }
  };
  const [openManModal, setModalManOpen] = React.useState(false);
  const [projectIdForModel, setprojectIdForModel] = useState("");
  const [projectOwnerId, setProjectOwnerId] = useState("");
  const [projectOwnerDepartMentId, setProjectOwnerDepartMentId] = useState("");
  const [projectName, setProjectName] = useState("");
  const handleManModalOpen = (
    id: any,
    projectOwnerDepartMentId: string,
    projectOwnerId: string,
    projectName: string
  ) => {
    setModalManOpen(true);
    setprojectIdForModel(id);
    setProjectOwnerDepartMentId(projectOwnerDepartMentId);
    setProjectOwnerId(projectOwnerId);
    setProjectName(projectName);
  };

  const handleManModalClose = () => setModalManOpen(false);
  const [openRiskDialog, setOpenRiskDialog] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<number | null>(null);
  const [selectedImpact, setSelectedImpact] = React.useState<number | null>(null);
  const [description, setDescription] = React.useState('');

  const handleRiskDialogOpen = (id:any) => {
    setprojectIdForModel(id);
    setOpenRiskDialog(true);
    setDescription('');
  };

  const handleRiskDialogClose = () => {
    setOpenRiskDialog(false);
    setprojectIdForModel("");
  };


  const categoryOptions = [
    { label: "Technical", value: 1 },
    { label: "Cost", value: 2 },
    { label: "Schedule", value: 3 },
    { label: "Client", value: 4 },
    { label: "Contractual", value: 5 },
    { label: "Weather", value: 6 },
    { label: "Financial", value: 7 },
    { label: "Political", value: 8 },
    { label: "Environmental", value: 9 },
    { label: "People", value: 10 }
  ];

  const impactOptions = [
    { label: "High", value: 1 },
    { label: "Low", value: 2 }
  ];


  const fieldValidationSchema = Yup.object().shape({
    category: Yup.string().nullable()?.required("Category is required"),
    impact: Yup.string().nullable()?.required("Impact is Required"),
    description: Yup.string().required("Description is Required"),
  });

  const addProjectRisk = async (values:any) => {
    
    let prm = { category: selectedCategory,
       impact:selectedImpact,
       projectId: projectIdForModel,
       description:description };
    await projectManagementServive
      .raiseProjectRisk(values)
      .then((response: any) => {
        if (response.data.isError) {
        }
        else {
          getProjectDetails("", statusDataHook);
          setOpenDialog(false);
          setSelectedProjectId("");
          toast.success("Risk raised successfully");
          handleRiskDialogClose();
          setSelectedCategory(null);
          setSelectedImpact(null);
          setDescription("");
        }
      });
  };

  // const handleParentClick = () => {
  //   console.log('handleParentClick');
  // };
  // const handleChildClick = (e: any) => {
  //    e.stopPropagation();
  //    //enable to stop the click event from bubbling up(up abive all the parent elements).
  //   console.log('handleChildClick');
  // };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        <Typography color="text.primary">Projects</Typography>
        <Typography color="text.primary">My Projects</Typography>

      </Breadcrumbs>
      {toggleLoader && (<CircularProgress sx={styles.toggleloaders} />)}
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Stack justifyContent={'flex-start'} alignItems={'self-start'} sx={{ width: '100%', position: 'relative' }} >
          <InfoPopover onInput={"My Project"} />
        </Stack>
        <Stack

          sx={Myprojectttop.topbar}
        >

          <Button
            id="basic-button"
            aria-controls={openDropdown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDropdown ? "true" : undefined}
            onClick={handleClick}
            sx={Myprojectttop.heading}
            variant="selectSmall"
          >
            {"My Projects"} <ArrowDropDownIcon />
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDropdown}
            onClose={handleCloseStatus}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleCloseStatus();
                navigation("/otherscreenmanager");
              }}
            >
              Others Projects
            </MenuItem>
          </Menu>

          <Box></Box>

          <StyledToggleButton
            color="primary"
            value={statusDataHook}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={Myprojectttop.navtab}
          >
            <ToggleButton value="0">All </ToggleButton>
            <ToggleButton value="1">New</ToggleButton>
            <ToggleButton value="2">In Progress</ToggleButton>
            <ToggleButton value="3">Completed</ToggleButton>
            <ToggleButton value="4">On Hold</ToggleButton>
          </StyledToggleButton>

          <Box sx={{ flexGrow: { xs: '0', sm: '0', md: '1' }, order: 2 }} />

          <Box sx={Myprojectttop.searchtab}>
            <SearchBar
//setSearchValue={setSearchValue}
              obj={{ departmentId: departmentHook }}
              callBackFunction={triggerSearch1}
            // setSearchValue={setSearchValue}
            // callBackFunction={() => getProjectDetails(0)}
            />
          </Box>
        </Stack>
      </Paper >
      <Grid
        container
        spacing={2}
      // direction={{ xs: "column", sm: "row" }}
      // justifyContent="flex-start"
      // sx={{ flexWrap: "wrap" }}
      >
        {/* {Array.from(Array(4)).map((_, index) => ( */}
        {projects.map((project, idx) => (

          <Grid item xs={12} md={4} xl={3}>
            <StyledBox key={"peoject_" + idx}>
              <StyledCard variant="outlined">
                <CardContent sx={Myprojectttop.cardstyle}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={projectStatus}
                  >
                    <Typography justifyContent="space-between" component="p">
                      {(() => {
                        switch (project.status) {
                          case 1:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Myprojectttop.New}
                                style={{ color: "#16B5BF" }}
                              >
                                <Icons.dots /> New
                              </Typography>
                            );
                          case 2:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Myprojectttop.Inprogress}
                                style={{ color: theme.palette.primary.main }}
                              >
                                <Icons.dots /> In Progress
                              </Typography>
                            );
                          case 3:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Myprojectttop.Completed}
                                style={{ color: theme.palette.success.dark }}
                              >
                                <Icons.CompletedPro /> Completed
                              </Typography>
                            );
                          case 4:
                            return (
                              <Typography
                                justifyContent="space-between"
                                component="p"
                                sx={Myprojectttop.Onhold}
                                style={{ color: theme.palette.error.dark }}
                              >
                                <Icons.dots
                                  style={{ verticalAlign: "middle" }}
                                />{" "}
                                On Hold
                              </Typography>
                            );
                          default:
                            return " Testing";
                        }
                      })()}
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                      <Select
                        name="status"
                        value={project.status}
                        disabled={
                          project.status == 3

                        }
                        //onChange={updateProjectstatus}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          "& .MuiSelect-select": {
                            paddingTop: "14px",
                            paddingBottom: "14px",
                          },
                        }}
                      >
                        {Object.entries(ProjectStatus).map(
                          (ps: any, idx: any) => (
                            <MenuItem
                              key={"status_" + idx}
                              value={ps[0]}
                              onClick={() => {
                                if (ps[1] == "Completed") {
                                  setOpenDialog(true);
                                  setSelectedProjectId(project?.id)
                                }
                                else {
                                  updateProjectstatus(ps[0], project?.id)
                                }
                              }}
                              disabled={project.status === 3 || (project.status === 2 && ps[0] === "1") || (project.status === 4 && ps[0] === '1')}
                            >
                              {ps[1]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                    {/* <IconButton
                      key={"elipses_" + idx}
                      aria-label=""
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      id={project.id}
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <MoreHorizIcon />
                    </IconButton> */}
                    {/* {idx == 0 && (
                      <Menu
                        key={"menu_" + idx}
                        id={"basic-menu_" + idx}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => editProject()}>Edit</MenuItem>
                        <MenuItem onClick={handleClose}>View</MenuItem>
                      </Menu>
                    )} */}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: 1, }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ flexWrap: "wrap", width: '70%' }}
                    >
                      {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ mr: 1, mb: 0 }}
                      /> */}
                      <Tooltip title={project.projectOwnerName}>
                        <Avatar
                          {...commonFunctions.stringAvatar(
                            project.projectOwnerName
                          )}
                          sx={{ mr: 1, mb: 0 }}
                        />
                      </Tooltip>
                      <Typography component="p" sx={{ fontWeight: 500, width: "60%" }}>
                        {project.projectName}
                      </Typography>
                    </Stack>
                    <Box sx={{ width: '25%' }}>
                      <Typography variant="caption" display="block">
                        Start Date
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ fontWeight: 500, fontSize: 12 }}
                      >
                        {project.createdDate}
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      mt: 2,
                      color: alpha(theme.palette.grey[700], 0.7),
                      minHeight: "60px",
                    }}
                  >
                    {project.projectDescription}
                  </Typography>
                  {[1, 2, 3].includes(currentLoggedInRole) ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: 4, }}
                    >
                      <Stack direction="row" alignItems="center">
                        <AvatarGroup max={3}>
                          {project?.resources?.length > 0 &&
                            project?.resources.map((y: any) => (
                              <Tooltip title={y.name}>
                                <Avatar
                                  {...commonFunctions.stringAvatar(y.name)}
                                />
                              </Tooltip>
                            ))}
                        </AvatarGroup>
                        {_permission.action_MyProjectsListing_AllocateResource_Access && (
                          <Tooltip arrow title="Allocate Resource">
                            <span>
                              <IconButton
                                color="primary"
                                disabled={project?.status === 3}
                                onClick={(event) => {
                                  handleManModalOpen(
                                    project.id,
                                    project?.departmentId,
                                    project.projectOwnerId,
                                    project.projectName
                                  );
                                }} 
                                sx={{ ml: -2 }}
                              >
                                <Icons.AllocateResource />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </Stack>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing="1"
                      >
                        <Tooltip arrow title="Register Risk">
                            <span>
                              <IconButton
                                color="primary"
                                disabled={project?.status === 3}
                                onClick={()=>(handleRiskDialogOpen(project?.id))}
                              >
                                    <Icons.RiskRegister />
                              </IconButton>
                            </span>
                          </Tooltip>

                        {(_permission.action_MyProjectsListing_LogSheet_Access && project.status !=1) && ( 
                          <Tooltip arrow title="Logsheet">
                            <span>
                              <IconButton
                                color="primary"
                                disabled={project?.status === 3}
                                //  startIcon={<AddCircleIcon />}
                                component={Link}
                                to={`/project/logsheet/${project?.id}`}
                              >
                                <Stack >
                                  <Badge color="secondary" badgeContent={project.unApproveStatusCount}>
                                    <Icons.logisticks />
                                  </Badge>
                                </Stack>
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}

                        {(() => {
                          switch (project.status) {
                            // case 1:
                            //   return (
                            //     <>
                            //       {_permission.action_MyProjectsListing_ProjectSettings_Access && (
                            //         <Tooltip arrow title="Set up">
                            //           <span>
                            //             <IconButton
                            //               color="primary"
                            //               //  startIcon={<AddCircleIcon />}
                            //               component={Link}
                            //               to={`/board/` + project.id}
                            //             >
                            //               <AddCircleOutlineOutlinedIcon />
                            //             </IconButton>
                            //           </span>
                            //         </Tooltip>
                            //       )}
                            //     </>
                            //   );

                            case 2:
                              return (
                                <>
                                  {_permission.action_MyProjectsListing_ProjectSettings_Access && (
                                    <Tooltip arrow title="Board">
                                      <span>
                                        <IconButton
                                          color="primary"
                                          //  startIcon={<AddCircleIcon />}
                                          component={Link}
                                          to={`/board/` + project.id}
                                        >
                                          <Icons.BoardProject />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  )
                                  }
                                </>
                              );
                          }
                        })()}
                      </Stack>

                    </Stack>
                  ) : (
                    ""
                  )}
                </CardContent>
              </StyledCard>
            </StyledBox>
          </Grid>
        ))}
      </Grid>
      {projects.length === 0 && <NotFound NotfoundText="No Project Found" />}

      <Dialog
        open={openManModal}
        onClose={handleManModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll="body"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ mb: 3 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Typography
              id="modal-modal-title"
              align="left"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 600 }}
            >
              Allocate Resource
            </Typography>
            {projectName && (
              <Typography sx={{ fontWeight: 500, fontSize: 19 }}>Project Name: {projectName}</Typography>)}

            <IconButton
              aria-label="delete"
              color="primary"
              sx={Myprojectttop.styleCross}
              onClick={handleManModalClose}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <AssignResource
            open={setModalManOpen}
            getProjectDetails={getProjectDetails}
            projectId={projectIdForModel}
            projectOwnerDepartMentId={projectOwnerDepartMentId}
            projectOwnerId={projectOwnerId}
            loginDepartmentId={currentLoggedInDepartmentId}
            currentLoggedInRole={currentLoggedInRole}
            handleManModalClose={handleManModalClose}
            statusOfFilter={statusDataHook}
          />
        </DialogContent>
      </Dialog>

      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper variant="outlined" sx={{ p: 4 }}>
            <Typography
              id="modal-modal-title"
              align="center"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 700 }}
            >
              Complete Project
            </Typography>
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ mt: 2 }}
            >
              Do you really want to Complete  this, Continue?
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button variant="contained" onClick={() => {
                updateProjectstatus("3", selectedProjectId)
              }}>
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>

      <Dialog
         open={openRiskDialog}
         onClose={handleRiskDialogClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         scroll="body"
         maxWidth="md"
         fullWidth
       >
        <Formik
          initialValues={{
            category: selectedCategory,
            description: description,
            impact: selectedImpact,
            projectId:projectIdForModel
        
          }}
          enableReinitialize
          validationSchema={fieldValidationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              
                await addProjectRisk(values);
            
            } catch (err: any) {
              console.error(err);
              err = JSON.parse(JSON.stringify(err));
              if (err.status == 400) {

                {
                  err.data.Error.map((error: any, idx: any) =>
                    toast.warn(error)
                  );
                }
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
       <DialogTitle id="alert-dialog-title" sx={{ mb: 3 }}>
         <Stack
           direction="row"
           spacing={2}
           justifyContent="flex-start"
           alignItems="center"
           sx={{ position: "relative" }}
         >
           <Typography
             id="modal-modal-title"
             align="left"
             variant="h6"
             component="h2"
             sx={{ fontWeight: 600 }}
           >
               Register Risk
             </Typography>

              <IconButton
                aria-label="delete"
                color="primary"
                sx={Myprojectttop.styleCross}
                onClick={handleRiskDialogClose}
              >
              <ClearIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
       
    <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ my: 1.5 }}>
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        {/* Category Dropdown */}
        <Box sx={{ width: "200px" }}>
          <Autocomplete
            fullWidth
            id="category-dropdown"
            options={categoryOptions}
            onBlur={handleBlur}
            onChange={(event, newValue) => {
              values.category = newValue?.value || null;
              setSelectedCategory(newValue?.value || null)
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params}
             label="Category"
             error={Boolean(
              getIn(touched, "category") && getIn(errors, "category")
            )}
            fullWidth
            helperText={
              getIn(touched, "category") && getIn(errors, "category")
            }
            />} 
          />
        </Box>
        {/* Impact Dropdown */}
        <Box sx={{ width: "200px" }}>
          <Autocomplete
            fullWidth
            id="impact-dropdown"
            options={impactOptions}
            onBlur={handleBlur}
            onChange={(event, newValue) => {
              values.impact = newValue?.value || null;
              setSelectedImpact(newValue?.value || null)
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} 
            label="Impact"
            error={Boolean(
              getIn(touched, "impact") && getIn(errors, "impact")
            )}
            fullWidth
            helperText={
              getIn(touched, "impact") && getIn(errors, "impact")
            }
             />} 
          />
        </Box>
      </Stack>
      {/* Description Input Box */}
      
    </Stack>
    <Stack direction="row" spacing={2}
              justifyContent="flex-start" alignItems="center"><TextField
              sx={{
                '& .MuiInputBase-input':{
                  lineHeight:'1.5',
                },
                maxWidth:"800px"}}
        id="description"
        label="Description"
        multiline
        fullWidth
        rows={4}
        value={description}
        onBlur={handleBlur}
        error={Boolean(
          getIn(touched, "description") && getIn(errors, "description")
        )}
        helperText={
          getIn(touched, "description") && getIn(errors, "description")
        }
        onChange={(e) => {
          let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,\s]$/g, "").trimStart();
          value = value.replace(/\s{3,}$/g, "");
          values.description = value;
          setDescription(value);
      }}
      inputProps={{ maxLength: 200 }}
      /></Stack>
    <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button variant="outlined" type="button" onClick={handleRiskDialogClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" > 
                Confirm
              </Button>
            </Stack>
           
  </DialogContent>
  </>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
