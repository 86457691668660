import * as React from "react";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Chip,
  IconButton,
  Input,
  InputLabel,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ItemPaper from "../../components/styledComponent/ItemPaper";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import * as Icons from "../../assests/icons/icons";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../theme/theme";
import Divider from "@mui/material/Divider";
import {
  useNavigate,
  Navigate,
  useParams,
  Link as link,
} from "react-router-dom";
import { projectService } from "../../service/project-service/add-projectForm";
import { projectManagementServive } from "../../service/project-management-service";
import Multiselect from "multiselect-react-dropdown";
import { Formik, getIn } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';

import {
  projectDetails,
  colAddClientModel,
  pointOfContacts,
  paymentDetails,
  skillSets,
  projectDepartment,
  projectModel,
  departmentRatePerHours,
} from "./models/projectNewModal";
import { toast } from "material-react-toastify";
import { channel } from "diagnostics_channel";
import { number } from "yup/lib/locale";
import { AlternateEmail, Upload } from "@mui/icons-material";
import { borderBottom } from "@mui/system";
import AppConstants from "../../config/AppConstants";
import { useSelector } from "react-redux";
import { apiUrl } from "../../service/common-services/apiURL";
import { Domainservice } from "../../service/master-service/domain-master-service";

const styles = {
  SpaceTop: {
    marginTop: theme.spacing(0.5),
  },
  lightBlueWrapper: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
};
const dummy = [
  {
    value: "Rs",
    label: "$",
    retePerHour: "+91",
    country: "India",
  },
  {
    value: "USD",
    label: "$",
    retePerHour: "+1",
    country: "USA",
  },
  {
    value: "NZD",
    label: "$",
    retePerHour: "+64",
    country: "New Zealand",
  },
  {
    value: "AUD",
    label: "A$",
    retePerHour: "+61",
    country: "Australia",
  },
  {
    value: "Pound Sterling",
    label: "£",
    retePerHour: "+44",
    country: "UK",
  },
  {
    value: "Euro",
    label: "€",
    retePerHour: "+33",
    country: "France",
  },
  {
    value: "ZAR",
    label: "$",
    retePerHour: "+27",
    country: "South Africa",
  },
  {
    value: "AED",
    label: "$",
    retePerHour: "+971",
    country: "UAE",
  },
  // {
  //   value: "Euro",
  //   label: "€",
  //   retePerHour: "+39",
  //   country: "Italy",
  // },
  // {
  //   value: "the Euro",
  //   label: "€",
  //   retePerHour: "+49",
  //   country: "Germany",
  // },
];

const clientDummy = [
  {
    id: 1,
    value: "New",
    label: "New",
  },
  {
    id: 2,
    value: "Existing",
    label: "Existing",
  },
];

interface Manager {
  hodId: string;
  managerName: string;
}

interface Department {
  id: string;
  name: string;
}

interface DepartmentT {
  id: string;
  name: string;
}

interface hodName {
  id: string;
  name: string;
  hodName: string;
  hodId: string;
  status: string;
}
interface projectOwner {
  userName: string;
  userId: string;
}

interface skillsInter {
  id: string;
  name: string;
}
interface channell {
  id: string;
  name: string;
}

interface client {
  id: string;
  email: string;
  skypeId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  country: string;
  companyName: string;
  mobileNumber: string;
  code: string;
  alterNateNumber: string;
  companyDescription: string;
  name: string;
}

interface IskillSets {
  skillId: string;
  name: string;
  level: string;
}
// interface clientInfor {
//   id: string;
//   projectName: string;
//   paymentDetails: {
//     costingType: string;
//     upfrontPayment: string;
//     currency: string;
//     billingType: string;
//     ratePerHour: string;
//     departmentRatePerHours: [
//       {
//         departmentId: string;
//         ratePerHour: string;
//       }
//     ];
//   };
// }

export default function AddProjectPage(props: { setShowMileStone: any, pName: any }) {
  const navigation = useNavigate();
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const _permission = useSelector((state: any) => state.ProjectPermissionApp.ProjectPermission);
  const { id } = useParams();
  let _id = "";
  let _projectName = "";
  let _isQAApplicable = null;
  let _domainId = "";
  let _clientId = "";
  let _clientType = "New";
  let _projectOwnerId = "";
  let _status = true;
  let _isPointOfContact = false;
  let _projectDetails = new projectDetails();
  let _colAddClientModel = new colAddClientModel();
  let _pointOfContacts = new pointOfContacts();
  let _paymentDetails = new paymentDetails();
  let _projectDepartment = new projectDepartment();
  let _departmentRatePerHours = new departmentRatePerHours();
  let _skillSets = new skillSets();
  let projectmodel = new projectModel(
    _id,
    _projectName,
    _isQAApplicable,
    _domainId,
    _clientId,
    _clientType,
    _projectOwnerId,
    _status,
    _isPointOfContact,
    _projectDetails,
    _colAddClientModel,
    _colAddClientModel,
    [_pointOfContacts],
    _paymentDetails,
    _projectDepartment,
    [_skillSets]
  );

  const [IsUpdate, setIsUpdate] = useState(false);
  const [updateButtonDisable, setUpdateButtonDisable] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  // const [cclientInfort, setclientInfor] = useState<clientInfor[]>([]);
  const [channelL, setchannelL] = useState<channell[]>([]);
  const [clients, setClients] = useState<client[]>([]);
  const [skillsInters, setskillsInters] = useState<skillsInter[]>([]);
  const [hodNameT, sethodNameT] = useState<projectOwner[]>([]);
  const [DepartmentTT, setDepartmentTT] = useState<DepartmentT[]>([]);
  const [departmentsName, setdepartmentsName] = useState("");
  const [projectDataHook, setProjectDataHook] = React.useState(projectmodel);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [Pid, setPId] = useState(projectDataHook?.id ?? "");
  const [projectName, setprojectName] = useState(projectDataHook.projectName);
  const [allDomain, setAllDomain] = useState<any>([]);
  const [domainDescription, setDomainDescription] = useState<any>();
  const [domainId, setDomainId] = useState(projectDataHook.domainId);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);

  const [qaApplicable, setQaApplicable] = useState(projectDataHook.isQAApplicable ?? false);
  const [projectType, setProjectType] = useState(projectDataHook.projectType ?? 0);
  const [clientId, setClientId] = useState(projectDataHook?.clientId ?? "");
  const [clientType, setClientType] = useState(projectDataHook?.clientType);
  const [projectOwnerId, setProjectOwnerId] = useState(
    projectDataHook?.projectOwnerId ?? ""
  );
  const [status, setStatus] = useState(projectDataHook?.status ?? "");
  const [isPointOfContact, setisPointOfContact] = useState(
    projectDataHook?.isPointOfContact ?? false
  );

  const [pin, setpin] = useState(projectDataHook?.projectDetails.pin ?? "");
  const [keyWords, setkeyWords] = useState(
    projectDataHook?.projectDetails.keyWords ?? ""
  );
  const [channelId, setchannelId] = useState(
    projectDataHook?.projectDetails?.channelId ?? ""
  );
  const [costing, setcosting] = useState(
    projectDataHook?.projectDetails?.costing ?? ""
  );
  const [pinEfforts, setpinEffort] = useState(
    projectDataHook.projectDetails?.pinEfforts ?? ""
  );
  const [pinAmount, setpinAmount] = useState(
    projectDataHook.projectDetails.pinAmount ?? ""
  );
  const [projectDescription, setprojectDescription] = useState(
    projectDataHook?.projectDetails?.projectDescription ?? ""
  );
  const [wbsPath, setwbsPath] = useState(
    projectDataHook?.projectDetails?.wbsPath ?? ""
  );
  const [projectDocument, setProjectDocument] = useState(
    projectDataHook?.projectDetails?.projectDocument ?? []
  );
  const [email, setemail] = useState(
    projectDataHook.colAddClientModel?.email ?? ""
  );
  const [skypeId, setskypeId] = useState(
    projectDataHook.colAddClientModel?.skypeId ?? ""
  );
  const [firstName, setfirstName] = useState(
    projectDataHook.colAddClientModel?.firstName ?? ""
  );
  const [lastName, setlastName] = useState(
    projectDataHook.colAddClientModel?.lastName ?? ""
  );
  const [middleName, setmiddleName] = useState(
    projectDataHook.colAddClientModel?.middleName ?? ""
  );
  const [country, setcountry] = useState(
    projectDataHook.colAddClientModel?.country ?? ""
  );
  const [companyName, setcompanyName] = useState(
    projectDataHook.colAddClientModel?.companyName ?? ""
  );
  const [mobileNumber, setmobileNumber] = useState(
    projectDataHook.colAddClientModel?.mobileNumber ?? ""
  );
  const [code, setcode] = useState(
    projectDataHook.colAddClientModel?.code ?? ""
  );
  const [alterNateNumber, setalterNateNumber] = useState(
    projectDataHook.colAddClientModel?.alterNateNumber ?? ""
  );
  const [companyDescription, setcompanyDescription] = useState(
    projectDataHook.colAddClientModel?.companyDescription ?? ""
  );

  let objPointOfContact = {
    email: "",
    skypeId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    code: "",
    alterNateNumber: "",
  };
  const [pointOfContact, setpointOfContact] = useState([objPointOfContact]);

  let objprojectDepartments = { departmentId: "" };
  const [projectDepartments, setprojectDepartments] = useState(
    objprojectDepartments
  );

  const [costingType, setcostingType] = useState(
    projectDataHook.paymentDetails.costingType ?? ""
  );
  const [upfrontPayment, setupfrontPayment] = useState(
    projectDataHook.paymentDetails.upfrontPayment ?? ""
  );
  const [currency, setcurrency] = useState(
    projectDataHook.paymentDetails.currency ?? ""
  );
  const [billingType, setbillingType] = useState(
    projectDataHook.paymentDetails.billingType ?? ""
  );
  const [ratePerHour, setratePerHour] = useState(
    projectDataHook.paymentDetails.ratePerHour ?? ""
  );

  let objskillsets: IskillSets = {
    skillId: "",
    name: "",
    level: "",
  };
  const [skillset, setskillset] = useState<IskillSets[]>([]);
  const setSkillSetsfromDropdown = (e: any) => {
    var myArray: any = [];
    e.forEach((elem: any) => {
      let obj = { skillId: elem.id ?? elem.skillId, name: elem.name };
      myArray.push(obj);
    });
    setskillset(myArray);
  };

  function fillUpClientDetail(obj: any) {
    if (obj) {
      setemail(obj.email);
      setskypeId(obj.skypeId);
      setfirstName(obj.firstName);
      setlastName(obj.lastName);
      setmiddleName(obj.middleName);
      setcountry(obj.country);
      setcompanyName(obj.companyName);
      setmobileNumber(obj.mobileNumber);
      setcode(obj.code);
      setalterNateNumber(obj.alterNateNumber);
      setcompanyDescription(obj.companyDescription);
      setClientId(obj.id);
    } else {
      setemail("");
      setskypeId("");
      setfirstName("");
      setlastName("");
      setmiddleName("");
      setcountry("");
      setcompanyName("");
      setmobileNumber("");
      setcode("");
      setalterNateNumber("");
      setcompanyDescription("");
      setClientId("");
    }
  }
  // const DummyBillingType = [
  //   { id: "weekly", name: "Weekly" },
  //   { id: "monthly", name: "Monthly" },
  //   { id: "bimonthly", name: "Bi-monthly" },
  //   { id: "quarterly", name: "Quarterly" },
  //   { id: "half-yearly", name: "Half Yearly" },
  // ];

  const [managerHook, setManagerHook] = useState<Manager>({
    hodId: "",
    managerName: "",
  });

  let Formobj = { departmentId: "04", ratePerHour: "3" };
  const [formValues, setFormValues] = useState([Formobj]);

  let pointOfContactobj = {
    email: "",
    skypeId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    code: "12",
    alterNateNumber: "",
  };
  interface IfileObj {
    file: any,
    filePath: any,
    fileName: any
  }
  const [fileArr, setFileArr] = useState<any>([]);
  const [fileHook, setFileHook] = useState<any>(null);
  const [fileUrlHook, setFileUrlHook] = useState<any>(null);
  const [fileNameHook, setfileNameHook] = useState<any>(null);

  async function handleChange1(e: any) {
    const files = e.target.files;
    if (files != null && files.length > 0) {
      let arr = [];
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)
        const fileObj = {
          file: file,
          filePath: URL.createObjectURL(file),
          fileName: file.name
        }
        arr.push(fileObj);
        if (i === files.length - 1) {
          setFileArr(arr);
        }
      }
    }
  }

  function handleDelete(index: any) {
    const newArr = [...fileArr];
    newArr.splice(index, 1);
    setFileArr(newArr);
  }

  const [pointOfContactValues, setpointOfContactValues] = useState([
    pointOfContactobj,
  ]);

  let handlePaymentDepartChange = (i: any, e: any) => {
    let newFormValues = [...formValues];
    //newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let handlepointOfContactValues = (i: any, e: any) => {
    let pointOfcontactValues = [...pointOfContactValues];
    //newFormValues[i][e.target.name] = e.target.value;
    setpointOfContactValues(pointOfcontactValues);
  };

  let addepointOfContactFields = () => {
    setpointOfContact([...pointOfContact, objPointOfContact]);
  };
  let removeEpointOfContactFields = (i: any) => {
    let newepointOfContactFields = [...pointOfContact];
    newepointOfContactFields.splice(i, 1);
    setpointOfContact(newepointOfContactFields);
  };

  const getChannelDetails = async () => {
    var input = {
      module: 5,
      status: "",
      search: "",
    };
    await projectManagementServive.getalllistingsecure(input).then((response: any) => {
      if (response.data.isError) {
      } else {
        setchannelL(response?.data);
      }
    });
  };

  const getSkillSetsDetails = async () => {
    var input = {
      module: 2,
      status: "",
      search: "",
    };
    await projectManagementServive
      .getalllistingsecure(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          setskillsInters(response?.data);
        }
      });
  };
  const getPaymentDpart = async () => {
    var input = {
      skip: 0,
      take: 0,
      status: 2,
    };
    await projectManagementServive
      .getProjectCurr(input)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          // setclientInfor(response?.data);
        }
      });
  };

  const getClientDetails = async () => {
    await projectManagementServive
      .getProjectClientName()
      .then((response: any) => {
        if (response.data.isError) {
          // setclientNameT(clientNameT);
        } else {
          setClients(response?.data);

        }
      });
  };

  const GetResourcesbyDepartmentId = async (id: any, ownerId: any) => {
    await projectManagementServive
      .GetResourcesbyDepartmentId(id)
      .then((response: any) => {
        if (response.data.isError) {
        } else {
          sethodNameT(response?.data);
          if (ownerId.length > 0) {
            setProjectOwnerId(ownerId);
          }
        }
      });
  };

  const GetProjectPinNumber = async () => {
    try {
      await projectManagementServive
        .GetProjectPinNumber(id)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            setpin(response.data)
          }
        });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };


  const getAllDomain = async () => {
    var input = {
      domainName: "",
    };
    try {
      await Domainservice.GetAllDomainData(input).then((response: any) => {
        if (response.data.isError) {
        } else {
          setAllDomain(response?.data)
        }
      })
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  useEffect(() => {
    getAllDomain()
    getDepartments();
    getChannelDetails();
    getSkillSetsDetails();
    getClientDetails();
    getDepartmentsT();
    getProjectDetails();

  }, []);

  const getProjectDetails = async () => {
    try {
      if (id && id.length > 0) {
        await projectManagementServive
          .getProjectDetailsById(id)
          .then((getresponse: any) => {

            if (getresponse.data.isError) {
            } else {
              props.setShowMileStone(true);
              setIsUpdate(true);
              let response = getresponse.data;
              setPId(response.id ?? "");
              setprojectName(response.projectName ?? "");
              setQaApplicable(response?.isQAApplicable ?? false)
              setDomainId(response?.domainId ?? "")
              setProjectType(response?.projectType ?? 0)
              if (props.pName) {
                props.pName(response.projectName)
              }
              setClientId(response.colAddClientModel?.id ?? "");
              setClientType(
                response.colAddClientModel?.id ? "Existing" : "New"
              );
              setStatus(response?.status ?? "");
              setisPointOfContact(false);
              setisPointOfContact(response.pointOfContacts.isPointOfContact ?? null);

              if (response.data?.projectDetails?.projectDocument.length > 0) {
                let documentData = response.data?.projectDetails?.projectDocument;
                console.log(documentData, "documentData")
                var document_array: any[] = [];
                documentData?.map((item: any) => {
                  document_array.push({
                    wbsPath: item?.wbsPath,
                    fileName: item?.fileName,
                    uploadedBy: item?.uploadedBy,
                  });
                  setFileArr(document_array);
                });
                console.log(fileArr, "fileArr")
              } else {
                setFileArr([]);
              }
              setpin(response?.projectDetails.pin ?? "");
              setkeyWords(response?.projectDetails.keyWords ?? "");
              setchannelId(response?.projectDetails?.channelId ?? "");
              //  alert(response?.projectDetails?.costing);
              setcosting(response?.projectDetails?.costing ?? "");
              setpinEffort(response.projectDetails?.pinEfforts ?? "");
              setpinAmount(response.projectDetails.pinAmount ?? "");
              setprojectDescription(
                response?.projectDetails?.projectDescription ?? ""
              );
              setwbsPath(response?.projectDetails?.wbsPath ?? "");
              setFileUrlHook(
                response?.projectDetails?.wbsPath

                  ? apiUrl.getImageURL() +
                  "/" +
                  response?.projectDetails?.wbsPath
                  : ""
              );
              setProjectDocument(response?.projectDetails?.projectDocument ?? [])
              setfileNameHook(response?.projectDetails?.wbsPath);
              setemail(response.colAddClientModel?.email ?? "");
              setskypeId(response.colAddClientModel?.skypeId ?? "");
              setfirstName(response.colAddClientModel?.firstName ?? "");
              setlastName(response.colAddClientModel?.lastName ?? "");
              setmiddleName(response.colAddClientModel?.middleName ?? "");
              setcountry(response.colAddClientModel?.country ?? "");
              setcompanyName(response.colAddClientModel?.companyName ?? "");
              setmobileNumber(response.colAddClientModel?.mobileNumber ?? "");
              setcode(response.colAddClientModel?.code ?? "");
              setalterNateNumber(
                response.colAddClientModel?.alterNateNumber ?? ""
              );
              setcompanyDescription(
                response.colAddClientModel?.companyDescription ?? ""
              );

              if (response?.pointOfContacts.length > 0) {
                setisPointOfContact(true);
                let arrPointofContact: any[] = [];
                response?.pointOfContacts.map((item: any, index: any) => {
                  arrPointofContact.push(item);
                });
                setpointOfContact(arrPointofContact);
              } else {
                setisPointOfContact(false);
                let objPointOfContact = {
                  email: "",
                  skypeId: "",
                  firstName: "",
                  lastName: "",
                  middleName: "",
                  mobileNumber: "",
                  code: "",
                  alterNateNumber: "",
                };
                setpointOfContact([objPointOfContact]);
              }
              let objprojectDepartments = {
                departmentId: response.projectDepartment?.departmentId ?? "",
              };
              setprojectDepartments(objprojectDepartments);
              if (response.projectDepartment?.departmentId.length > 0) {
                GetResourcesbyDepartmentId(
                  response.projectDepartment?.departmentId,
                  response.projectOwnerId
                );
              }

              setcostingType(response.paymentDetails?.costingType ?? "fixed");
              setupfrontPayment(response.paymentDetails?.upfrontPayment ?? "");
              setcurrency(response.paymentDetails?.currency ?? "");
              setbillingType(response.paymentDetails?.billingType ?? "Weekly");
              setratePerHour(response.paymentDetails?.ratePerHour ?? "");

              if (response?.skillSets.length > 0) {
                let arrskillSets: any[] = [];
                response?.skillSets.map((item: any, index: any) => {
                  arrskillSets.push(item);
                });
                setskillset(arrskillSets);
              } else {
                setskillset([]);
              }

            }
          });
      }
      else {
        GetProjectPinNumber()
      }
    } catch (ex: any) {

      ex.data.errors.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  var departmentData = { id: "", name: "All Departments" };

  const navigate = useNavigate();

  const getDepartments = async () => {
    let prm = { module: 1, status: "active" };
    await projectManagementServive
      .getDropdownData(prm)
      .then((response: any) => {
        if (response.data.isError) {
          setDepartments([departmentData]);
        } else {
          setDepartments(response.data);
        }
      });
  };

  const getDepartmentsT = async () => {
    var input = {
      module: 1,
      status: "",
      search: "",
    };
    await projectManagementServive
      .getalllistingsecure(input)
      .then((response: any) => {
        if (response.data.isError) {
          setDepartmentTT([departmentData]);
        } else {
          setDepartmentTT(response.data);
        }
      });
  };
  const submitProject = async (values: any) => {
    try {
      let data = projectDataHook;
      data.projectName = projectName.replace(/^\s+|\s+$/gm, '');
      data.isQAApplicable = qaApplicable;
      data.domainId = domainId;
      data.projectType = projectType;
      let projectDetails = {
        pin: pin,
        keyWords: keyWords,
        channelId: channelId,
        costing: costing,
        pinEfforts: pinEfforts,
        pinAmount: pinAmount,
        projectDescription: projectDescription,
        wbsPath: wbsPath,
        projectDocument: projectDocument,
      };
      data.projectDetails = projectDetails;
      let colAddClientModel = {
        id: clientId ?? null,
        email: email,
        skypeId: skypeId,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        country: country ?? "India",
        companyName: companyName,
        mobileNumber: mobileNumber,
        code: code,
        alterNateNumber: alterNateNumber,
        companyDescription: companyDescription,
      };
      data.clientId = clientType == "New" ? null : clientId ?? null;
      data.clientType = clientType;
      data.colAddClientModel = colAddClientModel;
      data.colUpdateClientModel = colAddClientModel;

      data.pointOfContacts =
        pointOfContact.filter(
          (x: { email: string; firstName: string; lastName: string; }) => x.email != "" && x.firstName != "" && x.lastName != ""
        ).length > 0
          ? pointOfContact
          : [];
      data.projectOwnerId = projectOwnerId;
      data.projectDepartment = projectDepartments;
      let paymentDetails = {
        costingType: costingType ?? "",
        upfrontPayment: upfrontPayment ?? "",
        currency: currency ?? "",
        billingType: billingType ?? "Weekly",
        ratePerHour: ratePerHour ?? "",
      };
      data.paymentDetails = paymentDetails;
      data.skillSets = skillset;

      if (IsUpdate) {
        data.id = Pid;
        if (fileArr === null || fileArr.length === 0) {
          data.projectDetails.projectDocument = [];
          await UpdateProjectDetail(data);
        } else {
          // console.log("fileArr",fileArr);
          // await fileArr.filter((a:any) => a.fileName != null).map(async(item:any, i:any) => {
          //   let formData = new FormData();
          //   formData.append("File", item.file);
          //   formData.append("FileFor",item.fileName);
          //   await projectManagementServive
          //   .uploadProfilePic(formData)
          //   .then(async (response: any) => {
          //     if (response.data.isError) {
          //       toast.error("facing issue while Document Upload");
          //     } else {
          //       let arrrayOfProjectDocuments=[...data.projectDetails.projectDocument];
          //       arrrayOfProjectDocuments.push({fileName:item.fileName,wbsPath:response.data});
          //       data.projectDetails.projectDocument=arrrayOfProjectDocuments;
          //     }

          //   });
          // })
          data.projectDetails.projectDocument = fileArr;
          await UpdateProjectDetail(data);
        }
      }
      else {
        // if (wbsPath != null && wbsPath != "" && fileArr == null) {
        //   data.projectDetails.wbsPath = wbsPath;
        //   await AddProjectData(data);
        // } 
        // else {
        //   if (fileArr == null) {
        //     await AddProjectData(data);
        //     return;
        //   }
        //   let formData = new FormData();
        //   formData.append("File", fileArr);
        //   formData.append("FileFor", "WBS file");
        //   await projectManagementServive
        //     .uploadProfilePic(formData)
        //     .then(async (response: any) => {
        //       if (response.data.isError) {
        //         toast.error("facing issue while Document Upload");
        //       } else {
        //         data.projectDetails.wbsPath = response.data;
        //       }
        //       await AddProjectData(data);
        //     });
        // }
        await AddProjectData(data);
      }
    } catch (ex: any) {

      for (var prop in ex.data.errors) {
        toast.warning(prop);
      }
    }
  };

  async function AddProjectData(data: any) {
    setUpdateButtonDisable(true)
    try {
      await projectManagementServive
        .createProject(data)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            toast.success("Project added successfully.", {
              onClose: () => setUpdateButtonDisable(false),

            });
            navigate("/project/projectlist");
          }
        });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {
          onOpen: () => setUpdateButtonDisable(true),
          onClose: () => setUpdateButtonDisable(false),

        });
      });
    }
  }
  async function UpdateProjectDetail(data: any) {
    setUpdateButtonDisable(true)
    try {

      await projectManagementServive
        .UpdateProjectDetail(data)
        .then((response: any) => {
          if (response.data.isError) {
            toast.error("Something went wrong...");
          } else {
            toast.success("Project updated successfully.", {
              onClose: () => setUpdateButtonDisable(false),

            });

            navigate("/project/projectlist");
          }
        });
    } catch (ex: any) {

      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err, {
          onOpen: () => setUpdateButtonDisable(true),
          onClose: () => setUpdateButtonDisable(false),

        });

      });
    }
  }



  const getDomainDetailsById = async (id: any) => {
    debugger
    try {
      await Domainservice.getDomainById(id).then(async (response: any) => {
        if (response.data.isError) {
          toast.error("Something went wrong...");
        } else {
          setDomainDescription(response.data);
        }
      });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };
  console.log("setDomainDescription ===", domainDescription)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  var dropdown_syle = {
    searchBox: {
      lineHeight: "30px",
      borderColor: "#EEEEEE",
    },
    chip: {
      marginBottom: 0,
    },
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      initialValues={{
        id: id,
        projectName: projectName,
        isQAApplicable: qaApplicable,
        domainId: domainId,
        projectType: projectType,
        clientId: clientId,
        clientType: clientType,
        projectOwnerId: projectOwnerId,
        projectDetails: {
          pin: pin,
          keyWords: keyWords,
          channelId: channelId,
          costing: costing,
          pinEfforts: pinEfforts,
          pinAmount: pinAmount,
          projectDescription: projectDescription,
          wbsPath: wbsPath,
          projectDocument: projectDocument,
        },
        colAddClientModel: {
          email: email,
          skypeId: skypeId,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          country: country,
          companyName: companyName,
          mobileNumber: mobileNumber,
          code: code,
          alterNateNumber: alterNateNumber,
          companyDescription: companyDescription,
        },
        isPointOfContact: false,
        pointOfContacts: pointOfContact,
        projectDepartment: projectDepartments,
        paymentDetails: {
          costingType: costingType,
          upfrontPayment: upfrontPayment,
          currency: currency,
          billingType: billingType,
          ratePerHour: ratePerHour,
        },
        skillSets: skillset,
        status: 1,
      }}
      validationSchema={Yup.object().shape({
        projectName: Yup.string()
          .max(50, "Project Name must be at most 50 characters")
          .required("Project Name is required")
          .matches(/^[A-Za-z0-9 ]+$/, 'Project Name is not Valid'),
        projectOwnerId: Yup.string().required("Project Owner is required"),
        clientId:
          clientType == "Existing"
            ? Yup.string().required("Client is required")
            : Yup.string(),
        projectDetails: Yup.object().shape({
          keyWords: Yup.string()
            .max(50, "KeyWords must be at most 50 characters")
            .required("Keyword is required"),
          channelId: Yup.string().required("Channel Id is required"),
          pinEfforts: Yup.string()
            .matches(
              AppConstants.MatchRegExp.intRegExp,
              "Pin Efforts must be digits only"
            )
            .max(10, "Pin Efforts must be at most 10 characters"),
          pinAmount: Yup.string()
            .matches(
              /^-{0,1}\d*\.{0,1}\d+$/,
              "Pin Amount must be digits only"
            )
            .max(10, "Pin Amount must be at most 10 characters"),
          costing: Yup.string().required("Costing is required"),
          // country: Yup.string().required("Country is required"),

          projectDescription: Yup.string().max(
            250,
            "Project Description must be at most 250 characters"
          ),
        }),
        colAddClientModel: Yup.object().shape({
          skypeId: Yup.string()
            .max(50, "Skype Id must be at most 50 characters")
            .matches(
              /^(?:[a-zA-Z0-9]+|live:[\w.]+|\S+)$/,
              'Invalid Skype Id'
            ),
          //  .matches(/^[A-Za-z0-9 ]+$/, 'Skype Id can only be in Alphabets and Numbers'),
          country: Yup.string().required("Country is required"),
          email: Yup.string()
            .max(50, "Email must be at most 50 characters")
            .matches(
              /^[a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
              ///^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
              "Please Enter Valid Email "
            )
            .email("Please enter valid email-id")
            .required("Email is required")
          ,
          // firstName: (clientType != "Existing" ? Yup.string().max(25, "First Name must be at most 25 characters").required('First name is required') : Yup.string()),
          firstName: Yup.string()
            .max(25, "First Name must be at most 25 characters")
            .matches(
              /^([A-Za-z\s]*)$/g,
              "First Name  can only contain alphabets."
            )
            .required("First name is required")
          ,
          middleName:
            Yup.string()
              .matches(
                AppConstants.MatchRegExp.alphaNumeric,
                "Middle Name can only contain alphabets."
              )
              .max(25, "Middle Name must be at most 25 characters")
          ,
          lastName:
            Yup.string()
              .max(25, "Last Name must be at most 25 characters")
              .matches(
                /^([A-Za-z\s]*)$/g,
                "Last Name can only contain alphabets."
              )
          // .required("Last Name is required")
          ,

          alterNateNumber:
            // alterNateNumber.length > 0
            Yup.string()
              // .matches(
              //   AppConstants.MatchRegExp.MobileNumber,
              //   "Must contain only 10 digits which starts from 6 to 9 "
              // )

              // .min(10, "Alternate number must be 10 digits.")
          //  .required("Alternate Number is Required")
          ,
          //  : Yup.string(),

          // lastName: (clientType != "Existing" ? Yup.string().max(25, "Last Name must be at most 25 characters").required('Last name is required') : Yup.string()),
          //mobileNumber: (clientType != "Existing" && mobileNumber.length>0 ? Yup.number().typeError("Mobile number must be 10 digits.").test('len', 'Must be exactly 10 digits', val => mobileNumber.length === 10).integer("A phone number can't include a decimal point"): Yup.string()),
          mobileNumber:
            //  clientType != "Existing" && 

            //  mobileNumber.length > 0
            Yup.string()
              // .matches(
              //   AppConstants.MatchRegExp.MobileNumber,
              //   "Must contain only 10 digits which starts from 6 to 9"
              // )
              // .min(10, "Must contain only 10 digits which starts from 6 to 9.")
              // .max(10, "Must contain only 10 digits which starts from 6 to 9.")
              .required("Mobile Number is Required")
          ,
          code:
            Yup.string()
              // .matches(
              //   AppConstants.MatchRegExp.MobileNumber,
              //   "only contain 10 digits and Must Starts from 6 to 9"
              // )
              // .min(10, "Mobile number must be 10 digits.")
              // .max(10, "Mobile number must be 10 digits.")
              .required("Country Code is Required")
          ,
          // : Yup.string(),
          companyDescription: clientType != "Existing"
            ? Yup.string().max(
              250,
              "Company description must be at most 250 characters"
            )
            : Yup.string(),
        }),
        projectDepartment: Yup.object().shape({
          departmentId: Yup.string().required("Department is required"),
        }),
        pointOfContacts: Yup.array().of(
          Yup.object().shape({
            skypeId: isPointOfContact
              ? Yup.string()
                .max(50, "Skype Id must be at most 50 characters")
                .matches(
                  /^(?:(?:[a-zA-Z0-9]+)|(?:live:[\w.]+)|(?:\S+))$/,
                  'Invalid Skype ID'
                )
              : Yup.string(),
            email: isPointOfContact
              ? Yup.string()
                .max(50, "Email must be at most 50 characters")
                .email("Please enter valid email-id")
                .required("Email is required")
                .matches(
                  /^[a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
                  ///^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
                  "Please Enter Valid Email"
                )
              : Yup.string(),
            firstName: isPointOfContact
              ? Yup.string()
                .max(25, "First Name must be at most 25 characters")
                .matches(
                  /^([A-Za-z\s]*)$/g,
                  "First Name can only contain alphabets."
                )
                .required("First Name is required")
              : Yup.string(),

            middleName: isPointOfContact
              ? Yup.string()
                .max(25, "Middle Name must be at most 25 characters")
                .matches(
                  /^([A-Za-z\s]*)$/g,
                  "Middle Name can only contain alphabets."
                )
              : Yup.string(),


            lastName: isPointOfContact
              ? Yup.string()
                .max(25, "Last Name must be at most 25 characters")
                .matches(
                  /^([A-Za-z\s]*)$/g,
                  "Last Name can only contain alphabets."
                )
              // .required("Last Name is required")
              : // ? Yup.string().required("Last name is required")
              Yup.string(),
            mobileNumber: isPointOfContact
              ? Yup.string()
                .matches(
                  AppConstants.MatchRegExp.MobileNumber,
                  'Must contain only 10 digits which starts from 6 to 9'
                )
                // .matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain white space and special character')
                // .matches(/^[0-9]+$/, "Mobile can only contains Numbers ")
                .min(10, "Must contain only 10 digits which starts from 6 to 9.")
                .max(10, "Must contain only 10 digits which starts from 6 to 9.")
                .required("Mobile Number is Required")
              : Yup.string(),
            alterNateNumber: isPointOfContact
              ? Yup.string()
                .matches(
                  AppConstants.MatchRegExp.MobileNumber,
                  "Must contain only 10 digits which starts from 6 to 9 "
                )
                .matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain white space and special character')
                .matches(/^[0-9]+$/, "Mobile can only contains Numbers ")
                .min(10, "Must contain only 10 digits which starts from 6 to 9.")
                .max(10, "Must contain only 10 digits which starts from 6 to 9.")
                .required("Alternate Number is Required")
              : Yup.string(),
          })
        ),

        paymentDetails: Yup.object().shape({
          upfrontPayment: costing === "fixed" ? Yup.string()
            .matches(
              /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
              "Payment should be Valid"
            ).required("Payment Detail is Required") : Yup.string(),
          ratePerHour: costing !== "fixed" ? Yup.string()
            .matches(/^[0-9]+$/, "This field can only contains Numbers ").required("Rate per hour is Required") : Yup.string(),
          currency: Yup.string().required("Currency is required"),
        }),

      })}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (values.projectType === 0) {
            toast.warn("Type of Project is Required",
              {
                onOpen: () => setButtonDisable(true),
                onClose: () => setButtonDisable(false),
              }
            )
          }
          else {
            submitProject(values);
          }
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Paper
            elevation={0}
            sx={{ p: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          >
            <Stack direction="row" spacing={2} flexDirection="column">
              <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
                {IsUpdate ? "Update Project" : "Add Project"}
              </Typography>
            </Stack>
          </Paper>

          <ItemPaper elevation={0} square sx={{ p: 2, pt: 0 }}>
            <Typography variant="body1" component="h2" sx={{ fontWeight: 500 }}>
              Project Details
            </Typography>
            <Grid container spacing={3} sx={styles.SpaceTop}>
              <Grid item xs lg={4}>
                <TextField
                  error={Boolean(
                    getIn(touched, "projectDetails.pin") &&
                    getIn(errors, "projectDetails.pin")
                  )}
                  helperText={
                    getIn(touched, "projectDetails.pin") &&
                    getIn(errors, "projectDetails.pin")
                  }
                  onBlur={handleBlur}
                  fullWidth
                  disabled
                  label="PIN"
                  value={pin}
                  variant="outlined"
                  name="projectDetails.pin"
                  onChange={(e) => {
                    values.projectDetails.pin = e.currentTarget.value;
                    setpin(e.currentTarget.value);
                  }}
                />
              </Grid>
              <Grid item xs lg={4}>
                <TextField
                  id="projectName"
                  error={Boolean(touched.projectName && errors.projectName)}
                  helperText={touched.projectName && errors.projectName}
                  required
                  label="Project Name"
                  variant="outlined"
                  fullWidth
                  name="projectName"
                  value={projectName}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                    //handleChange("projectName", e?.target?.value.substring(0,50))

                    values.projectName = value;
                    setprojectName(value);
                  }}
                />
              </Grid>
              <Grid item xs lg={4}>
                <TextField
                  sx={{ width: { xs: "750px", sm: "100%" } }}
                  error={Boolean(
                    getIn(touched, "projectDetails.keyWords") &&
                    getIn(errors, "projectDetails.keyWords")
                  )}
                  helperText={
                    getIn(touched, "projectDetails.keyWords") &&
                    getIn(errors, "projectDetails.keyWords")
                  }
                  name="projectDetails.keyWords"
                  required
                  onBlur={handleBlur}
                  id="values.projectDetails.keyWords"
                  label="Keyword"
                  variant="outlined"
                  value={keyWords}
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart().substring(0, 50);
                    values.projectDetails.keyWords = value;
                    setkeyWords(value);
                  }}
                />
              </Grid>
              {/* span grid */}

              <Grid item xs lg={8}>
                <Grid container spacing={3}>
                  <Grid item xs lg={6}>
                    {/* <TextField
                      error={Boolean(
                        getIn(touched, "projectDetails.channelId") &&
                        getIn(errors, "projectDetails.channelId")
                      )}
                      helperText={
                        getIn(touched, "projectDetails.channelId") &&
                        getIn(errors, "projectDetails.channelId")
                      }
                      name="projectDetails.channelId"
                      required
                      onBlur={handleBlur}
                      id="outlined-basic"
                      label="Channel"
                      value={channelId}
                      variant="outlined"
                      select
                      fullWidth
                    >
                      {channelL.map((option, index) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                          onClick={(event) => {
                            values.projectDetails.channelId = option.id;
                            setchannelId(option.id);
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <Autocomplete
                      value={
                        channelL?.find(
                          (option: any) => option.id == values.projectDetails.channelId
                        ) ?? null
                      }
                      fullWidth
                      id="outlined-SprintId"
                      options={channelL}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        values.projectDetails.channelId = value?.id ?? "";
                        setchannelId(value?.id ?? "");
                      }}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                              },
                            },
                          ],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            getIn(touched, "projectDetails.channelId") &&
                            getIn(errors, "projectDetails.channelId")
                          )}
                          helperText={
                            getIn(touched, "projectDetails.channelId") &&
                            getIn(errors, "projectDetails.channelId")
                          }
                          name="projectDetails.channelId"
                          required
                          onBlur={handleBlur}
                          id="outlined-basic"
                          label="Channel"
                          value={channelId}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs lg={6}>
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="body1"
                        component="h6"
                        sx={{ fontWeight: 400 }}
                      >
                        Costing Type
                      </Typography>
                      <FormControl sx={{ ml: 2 }}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="fixed"
                          name="radio-buttons-group"
                          value={costing}
                        >
                          {/* {JSON.stringify(costing, null, 2)} */}
                          <FormControlLabel
                            value="fixed"
                            control={<Radio disabled={true} />}
                            label="Fixed"
                            disabled={IsUpdate}
                            onClick={(event) => {
                              if (!IsUpdate) {
                                values.projectDetails.costing = "fixed";
                                setcosting("fixed");
                              }
                            }}
                          />
                          <FormControlLabel
                            value="billing"
                            control={<Radio disabled={true} />}
                            label="Billing"
                            disabled={IsUpdate}
                            onClick={(event) => {
                              if (!IsUpdate) {
                                values.projectDetails.costing = "billing";
                                setcosting("billing");
                              }
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs lg={6}>
                    <TextField
                      error={Boolean(
                        getIn(touched, "projectDetails.pinEfforts") &&
                        getIn(errors, "projectDetails.pinEfforts")
                      )}
                      helperText={
                        getIn(touched, "projectDetails.pinEfforts") &&
                        getIn(errors, "projectDetails.pinEfforts")
                      }
                      name="projectDetails.pinEfforts"
                      onBlur={handleBlur}
                      fullWidth
                      id="txtEfforts"
                      label="Pin Efforts"
                      value={pinEfforts}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").trimStart();
                        values.projectDetails.pinEfforts = value;
                        setpinEffort(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs lg={6}>
                    <TextField
                      error={Boolean(
                        getIn(touched, "projectDetails.pinAmount") &&
                        getIn(errors, "projectDetails.pinAmount")
                      )}
                      helperText={
                        getIn(touched, "projectDetails.pinAmount") &&
                        getIn(errors, "projectDetails.pinAmount")
                      }
                      name="projectDetails.pinAmount"
                      onBlur={handleBlur}
                      fullWidth
                      id="txtAmount"
                      label="Pin Amount"
                      value={pinAmount}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").trimStart();
                        values.projectDetails.pinAmount = value;
                        setpinAmount(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* span grid end */}
              <Grid item lg={4}>
                <TextField
                  error={Boolean(
                    getIn(touched, "projectDetails.projectDescription") &&
                    getIn(errors, "projectDetails.projectDescription")
                  )}
                  helperText={
                    getIn(touched, "projectDetails.projectDescription") &&
                    getIn(errors, "projectDetails.projectDescription")
                  }
                  name="projectDetails.projectDescription"
                  //   required
                  onBlur={handleBlur}
                  fullWidth
                  id="outlined-multiline-static"
                  label="Project Description"
                  multiline
                  value={projectDescription}
                  rows={5}
                  defaultValue=""
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                    values.projectDetails.projectDescription = value;
                    setprojectDescription(value);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      minHeight: "111px",
                      lineHeight: 1.2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs lg={6}>
                <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      component="h6"
                      sx={{ fontWeight: 400 }}
                    >
                      Qa Applicable
                    </Typography>
                    <FormControl sx={{ ml: 2 }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="false"
                        name="radio-buttons-group"
                        value={qaApplicable}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                          // disabled={IsUpdate}
                          onClick={(event) => {
                            // if (!IsUpdate) {
                            values.isQAApplicable = true;
                            setQaApplicable(true);
                            // }
                          }}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                          //disabled={IsUpdate}
                          onClick={(event) => {
                            // if (!IsUpdate) {
                            values.isQAApplicable = false;
                            setQaApplicable(false);
                            // }
                          }}
                        />
                      </RadioGroup>
                    </FormControl></Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>  <Typography
                    variant="body1"
                    component="h6"
                    sx={{ fontWeight: 400 }}
                  >
                    Project Type
                  </Typography>
                    <FormControl sx={{ ml: 2 }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="false"
                        name="radio-buttons-group"
                        value={projectType}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="In House"
                          onClick={(event) => {
                            values.projectType = 1;
                            setProjectType(1);
                          }}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Client"
                          onClick={(event) => {
                            values.projectType = 2;
                            setProjectType(2);
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                </Stack>
              </Grid>
              <Grid sx={{ alignSelf: "center", display: "flex", gap: "12px" }} item xs lg={6}>

                <Autocomplete
                  value={
                    allDomain?.find(
                      (option: any) => option.id == domainId
                    ) ?? null
                  }
                  // fullWidth
                  sx={{ width: "220px" }}
                  id="outlined-projectOwnerId"
                  options={allDomain}
                  getOptionLabel={(option) => option.domain}
                  onChange={async (event, value) => {
                    const selectedId = value?.id ?? "";
                    values.domainId = selectedId;
                    setDomainId(selectedId);
                    if (selectedId) {
                      await getDomainDetailsById(selectedId);
                    }
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onBlur={handleBlur}
                      id="outlined-projectOwnerId"
                      label="Domain"
                      value={domainId}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                {domainId && ( // Show icon only when domainId is selected
                  <span
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={handlePopoverOpen}
                    style={{ cursor: 'pointer', display: "flex", alignItems: "center" }}
                  >
                    <InfoOutlinedIcon sx={{ color: '#ffb046' }} />
                  </span>
                )}
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  elevation={0}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  {domainDescription && (
                    <Typography sx={{ p: 1, background: '#fff9ec' }}>{domainDescription.description}</Typography>
                  )}
                </Popover>
              </Grid>



            </Grid>
            <Divider light sx={{ my: 3 }} />

            <Typography variant="body1" component="h2" sx={{ fontWeight: 500 }}>
              Client Information
            </Typography>
            {_permission.tab_ClientDetails_Access && (
              <Grid container spacing={3} sx={styles.SpaceTop}>
                <Grid item xs lg={3}>
                  <FormControl
                    style={{ display: IsUpdate ? "none" : "inline-block" }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="New"
                      name="radio-buttons-group"
                      value={clientType}
                      sx={{display: "block !important"}}
                    >
                      <FormControlLabel
                        value="New"
                        control={<Radio />}
                        label="New Client"
                        onClick={(event) => {
                          values.clientType = "New";
                          setClientType("New");
                          if (clientType != "New") {
                            fillUpClientDetail(null);
                            errors.clientId = "";
                          }
                        }}
                      />
                      <FormControlLabel
                        value="Existing"
                        control={<Radio />}
                        label="Existing Client"
                        onClick={(event) => {
                          values.clientType = "Existing";
                          setClientType("Existing");
                          if (clientType != "Existing") {
                            fillUpClientDetail(null);
                            errors.colAddClientModel = {
                              ...errors.colAddClientModel
                              ,
                              //  lastName: "",
                              firstName: "",
                              country: "",
                              email: "",
                              mobileNumber: "",
                              alterNateNumber: "",
                            }
                          }
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs lg={4}>
                  {/* <TextField
                  style={{ display: IsUpdate ? "none" : "inline-block" }}
                  disabled={clientType == "New"}
                  id="outlined-basic-client"
                  label="Select Client"
                  variant="outlined"
                  select
                  value={clientId}
                  fullWidth
                  error={Boolean(
                    getIn(touched, "clientId") && getIn(errors, "clientId")
                  )}
                  helperText={
                    getIn(touched, "clientId") && getIn(errors, "clientId")
                  }
                  name="clientId"
                  required
                  onBlur={handleBlur}
                >
                  {clients.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      onClick={(event) => {
                        values.clientId = option.id;
                        setClientId(option.id);
                        fillUpClientDetail(option);
                      }}
                    >
                      {option.firstName + " (" + option.email + ")"}
                    </MenuItem>
                  ))}
                </TextField> */}
                  <Autocomplete
                    disabled={clientType == "New"}
                    value={
                      clients?.find(
                        (option) => option.id == clientId
                      ) ?? null
                    }
                    // fullWidth
                    sx={{ ml: "15px" }}
                    id="outlined-basic-client"
                    options={clients}
                    getOptionLabel={(option) => option.firstName + " (" + option.email + ")"}
                    onChange={async (event, value) => {
                      values.clientId = value?.id ?? "";
                      setClientId(value?.id ?? "");
                      fillUpClientDetail(value);
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'flip',
                            enabled: true,
                            options: {
                              altBoundary: true,
                              flipVariations: true,
                              altAxis: true,
                              padding: 8,
                              fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                            },
                          },
                        ],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ display: IsUpdate ? "none" : "inline-block" }}
                        id="outlined-basic-client"
                        label="Select Client"
                        variant="outlined"
                        value={clientId}
                        fullWidth
                        error={Boolean(
                          getIn(touched, "clientId") && getIn(errors, "clientId")
                        )}
                        helperText={
                          getIn(touched, "clientId") && getIn(errors, "clientId")
                        }
                        name="clientId"
                        required
                        onBlur={handleBlur}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs lg={5}>

                  {/* <Stack direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    component="h2"
                    sx={{ fontWeight: 500 }}
                  >
                    Upload WBS
                  </Typography>
                  <Tooltip title="Allowed inputs xlsx, doc, docm, docx, pdf, docx, csv, xls, xlsm, xlsb, xlsb, xltx" arrow>
                    <Button
                      component="label"
                      startIcon={<Upload />}
                      sx={{ ml: 2 }}
                    >
                      Attachments
                      <input
                      multiple={true}
                        hidden
                        // src={fileUrlHook}
                        onChange={handleChange1}
                        accept="application/pdf,application/vnd.ms-excel"
                        type="file"
                      />
                    </Button>
                  </Tooltip>
                  {fileArr && fileArr.length > 0 && fileArr.map((f: any, i: any) => (
                    <><Typography
                      component={Link}
                      target="_blank"
                      href={f.filePath}
                      variant="body1"
                      sx={{
                        color: theme.palette.primary.main,
                        textDecoration: "underline",
                        wordBreak: "break-all",
                      }}
                    >
                      {f?.fileName?.length > 20
                        ? f?.fileName.slice(0, 20 - 1) + "..."
                        : f?.fileName}
                    </Typography>
                      <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(i)}
                    >
                      <Icons.Delete />
                    </IconButton> </>
                  ))}

                </Stack> */}
                </Grid>              <Grid item xs lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs lg={4}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          getIn(touched, "colAddClientModel.firstName") &&
                          getIn(errors, "colAddClientModel.firstName")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.firstName") &&
                          getIn(errors, "colAddClientModel.firstName")
                        }
                        // {...(clientType=="newClient"? {require}:"")}
                        required
                        name="colAddClientModel.firstName"
                        onBlur={handleBlur}
                        id="values.colAddClientModel.firstName"
                        label="First Name"
                        value={firstName}
                        variant="outlined"
                        disabled={clientType != "New" && !IsUpdate}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^a-zA-Z\-\s]/g, "")
                          value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 20);
                          setfirstName(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs lg={4}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          getIn(touched, "colAddClientModel.middleName") &&
                          getIn(errors, "colAddClientModel.middleName")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.middleName") &&
                          getIn(errors, "colAddClientModel.middleName")
                        }
                        id="values.colAddClientModel.middleName"
                        name="colAddClientModel.middleName"
                        onBlur={handleBlur}
                        value={middleName}
                        disabled={clientType != "New" && !IsUpdate}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^a-zA-Z\-\s]/g, "")
                          value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 20);
                          setmiddleName(value);
                        }}
                        label="Middle Name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs lg={4}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          getIn(touched, "colAddClientModel.lastName") &&
                          getIn(errors, "colAddClientModel.lastName")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.lastName") &&
                          getIn(errors, "colAddClientModel.lastName")
                        }
                        // required
                        name="colAddClientModel.lastName"
                        onBlur={handleBlur}
                        id="values.colAddClientModel.lastName"
                        value={lastName}
                        disabled={clientType != "New" && !IsUpdate}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^a-zA-Z\-\s]/g, "")
                          value = value.replace(/\s{2,}$/g, "").trimStart().substring(0, 20);
                          setlastName(value);
                        }}
                        label="Last Name"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs lg={5}>
                  <Grid container spacing={3}>
                    <Grid item xs lg={6}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          getIn(touched, "colAddClientModel.email") &&
                          getIn(errors, "colAddClientModel.email")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.email") &&
                          getIn(errors, "colAddClientModel.email")
                        }
                        required
                        name="colAddClientModel.email"
                        onBlur={handleBlur}
                        id="values.colAddClientModel.email"
                        value={email}
                        disabled={clientType != "New" && !IsUpdate}
                        onChange={(e) => {
                          values.colAddClientModel.email = e.currentTarget.value.trimStart();
                          setemail(e.currentTarget.value.trimStart());

                          // let value =  e.currentTarget.value.replace(/[^a-zA-Z\s]/g, "");
                          // value = value.replace(/^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/g, "").trimStart();
                          // values.colAddClientModel.email = value;
                          // setemail(value);

                        }}
                        label="Email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs lg={6}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          getIn(touched, "colAddClientModel.skypeId") &&
                          getIn(errors, "colAddClientModel.skypeId")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.skypeId") &&
                          getIn(errors, "colAddClientModel.skypeId")
                        }
                        name="colAddClientModel.skypeId"
                        onBlur={handleBlur}
                        id="values.colAddClientModel.skypeId"
                        value={skypeId}
                        disabled={clientType != "New" && !IsUpdate}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                          values.colAddClientModel.skypeId = value;
                          setskypeId(value);

                        }}
                        label="Skype ID"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* --------------------- */}

                <Grid item xs lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs lg={6}>
                      <TextField
                        error={Boolean(
                          getIn(touched, "colAddClientModel.country") &&
                          getIn(errors, "colAddClientModel.country")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.country") &&
                          getIn(errors, "colAddClientModel.country")
                        }
                        name="Country"
                        required
                        onBlur={handleBlur}
                        id="outlined-basic"
                        label="Country"
                        disabled={clientType != "New" && !IsUpdate}
                        value={country}
                        variant="outlined"
                        select
                        fullWidth
                      >
                        {dummy.map((option, index) => (
                          <MenuItem
                            key={option.country}
                            value={option.country}
                            onClick={(event) => {
                              values.colAddClientModel.country =
                                dummy[index].country;
                              setcountry(dummy[index].country);
                            }}
                          >
                            {option.country}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs lg={6}>
                      <TextField
                        fullWidth
                        id="values.colAddClientModel.companyName#"
                        value={companyName}
                        disabled={clientType != "New" && !IsUpdate}
                        label="Company Name"
                        variant="outlined"
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                          value = value.substring(0, 100);
                          values.colAddClientModel.companyName = value;
                          setcompanyName(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs lg={5}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          getIn(touched, "colAddClientModel.mobileNumber") &&
                          getIn(errors, "colAddClientModel.mobileNumber")
                        )}
                        helperText={
                          getIn(touched, "colAddClientModel.mobileNumber") &&
                          getIn(errors, "colAddClientModel.mobileNumber")
                        }
                        name="colAddClientModel.mobileNumber"
                        required
                        onBlur={handleBlur}
                        id="values.colAddClientModel.mobileNumber"
                        label="Mobile Number"
                        value={mobileNumber}
                        disabled={clientType != "New" && !IsUpdate}
                        onChange={(e) => {
                          let value = e.currentTarget.value.replace(/[^0-9]/g, "").trimStart(); 
                        
                          if (/^[6-9]/.test(value)) {
                            value = value.substring(0, 10); // If it starts with 6-9, limit to 10 digits
                          } else if (/^[0-5]/.test(value)) {
                            value = value.substring(0, 12); // If it starts with 0-5, limit to 12 digits
                          }
                        
                          values.colAddClientModel.mobileNumber = value;
                          setmobileNumber(value);
                        }}
                        
                        variant="outlined"
                      />

                    </Grid>
                    <Grid item xs lg={7}>
                      <Stack direction={'row'}>
                        <TextField
                          id="outlined-basic"
                          label="Code"
                          error={Boolean(
                            getIn(touched, "colAddClientModel.code") &&
                            getIn(errors, "colAddClientModel.code")
                          )}
                          helperText={
                            getIn(touched, "colAddClientModel.code") &&
                            getIn(errors, "colAddClientModel.code")
                          }
                          onBlur={handleBlur}
                          value={code}
                          required
                          disabled={clientType != "New" && !IsUpdate}
                          variant="outlined"
                          select
                          sx={{ width: '130px' }}

                        >
                          {dummy.map((option, index) => (
                            <MenuItem
                              key={option.value}
                              value={option.retePerHour}
                              onClick={(event) => {
                                values.colAddClientModel.code =
                                  dummy[index].retePerHour;
                                setcode(dummy[index].retePerHour);
                              }}
                            >
                              {option.retePerHour}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 'auto', marginLeft: "auto" }}
                          error={Boolean(
                            getIn(touched, "colAddClientModel.alterNateNumber") &&
                            getIn(errors, "colAddClientModel.alterNateNumber")
                          )}
                          helperText={
                            getIn(touched, "colAddClientModel.alterNateNumber") &&
                            getIn(errors, "colAddClientModel.alterNateNumber")
                          }
                          name="colAddClientModel.alterNateNumber"
                          onBlur={handleBlur}
                          id="values.colAddClientModel.alterNateNumber"
                          value={alterNateNumber}
                          disabled={clientType != "New" && !IsUpdate}
                          label="Alternate Number"
                          variant="outlined"
                          onChange={(e) => {
                            let value = e.currentTarget.value.replace(/[^0-9]/g, "").trimStart(); 
                        
                            if (/^[6-9]/.test(value)) {
                              value = value.substring(0, 10); // If it starts with 6-9, limit to 10 digits
                            } else if (/^[0-5]/.test(value)) {
                              value = value.substring(0, 12); // If it starts with 0-5, limit to 12 digits
                            }
                          
                            values.colAddClientModel.alterNateNumber = value;
                            setalterNateNumber(value);
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs lg={5}>
                  <TextField
                    error={Boolean(
                      getIn(touched, "colAddClientModel.companyDescription") &&
                      getIn(errors, "colAddClientModel.companyDescription")
                    )}
                    helperText={
                      getIn(touched, "colAddClientModel.companyDescription") &&
                      getIn(errors, "colAddClientModel.companyDescription")
                    }
                    id="colAddClientModel.companyDescription"
                    label="Company Description"
                    value={companyDescription}
                    disabled={clientType != "New" && !IsUpdate}
                    multiline
                    rows={4}
                    fullWidth
                    defaultValue=""
                    onChange={(e) => {
                      let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart().substring(0, 250);
                      values.colAddClientModel.companyDescription = value;
                      setcompanyDescription(value);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        minHeight: "111px",
                        lineHeight: 1.2,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Box sx={{ mt: 1 }}>
              <Typography>
                <FormControlLabel
                  sx={{ fontWeight: 600, m: 0 }}
                  control={
                    <Checkbox
                      value={isPointOfContact}
                      checked={isPointOfContact}

                      onClick={(e) => {

                        values.isPointOfContact = !values.isPointOfContact;
                        setisPointOfContact(!isPointOfContact);

                        if (isPointOfContact == true) {
                          pointOfContact.pop();
                          pointOfContact.push(objPointOfContact);
                        }
                      }}
                    />
                  }
                  label="Point of Contact"
                />
              </Typography>
              {/* <Typography
                variant="body1"
                component="h6"
                sx={{ fontWeight: 300, ml: 5, mr: 4 }}
              >
                Max 2 point of contact
              </Typography> */}
            </Box>
            {pointOfContact.map((pointElement, pointIndex) => (
              <Box sx={styles.lightBlueWrapper}>

                <Grid
                  container
                  spacing={3}
                  key={"index1" + pointIndex}
                  onChange={(e) => handlepointOfContactValues(pointIndex, e)}
                  sx={{ mt: 0.5, mb: 2 }}
                >
                  <Grid item xs lg={3}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        getIn(
                          touched,
                          `pointOfContacts.${pointIndex}.firstName`
                        ) &&
                        getIn(
                          errors,
                          `pointOfContacts.${pointIndex}.firstName`
                        )
                      )}
                      helperText={
                        getIn(
                          touched,
                          `pointOfContacts.${pointIndex}.firstName`
                        ) &&
                        getIn(errors, `pointOfContacts.${pointIndex}.firstName`)
                      }
                      required
                      name={`pointOfContacts.${pointIndex}.firstName`}
                      onBlur={handleBlur}
                      id={"firstName_" + pointIndex}
                      label="First Name"
                      value={pointElement.firstName || ""}
                      variant="outlined"
                      disabled={!isPointOfContact}
                      onChange={(event) => {
                        //values.pointOfContacts[pointIndex].firstName = event.currentTarget.value;
                        let obj = [...pointOfContact];
                        obj[pointIndex].firstName = event.currentTarget.value.replace(/[^a-zA-Z\-\s]/g, "").trimStart().substring(0, 20);
                        setpointOfContact(obj);
                      }}
                    />
                  </Grid>
                  <Grid item xs lg={3}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        getIn(
                          touched,
                          `pointOfContacts.${pointIndex}.middleName`
                        ) &&
                        getIn(
                          errors,
                          `pointOfContacts.${pointIndex}.middleName`
                        )
                      )}
                      helperText={
                        getIn(
                          touched,
                          `pointOfContacts.${pointIndex}.middleName`
                        ) &&
                        getIn(
                          errors,
                          `pointOfContacts.${pointIndex}.middleName`
                        )
                      }
                      name={`pointOfContacts.${pointIndex}.middleName`}
                      onBlur={handleBlur}
                      id={"middleName_" + pointIndex}
                      value={pointElement.middleName || ""}
                      label="Middle Name"
                      variant="outlined"
                      disabled={!isPointOfContact}
                      onChange={(event) => {
                        //values.pointOfContacts[pointIndex].middleName = event.currentTarget.value;
                        let obj = [...pointOfContact];
                        obj[pointIndex].middleName = event.currentTarget.value.replace(/[^a-zA-Z\-\s]/g, "").trimStart().substring(0, 20);
                        setpointOfContact(obj);
                      }}
                    />
                  </Grid>

                  <Grid item xs lg={3}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        getIn(
                          touched,
                          `pointOfContacts.${pointIndex}.lastName`
                        ) &&
                        getIn(
                          errors,
                          `pointOfContacts.${pointIndex}.lastName`
                        )
                      )}
                      helperText={
                        getIn(
                          touched,
                          `pointOfContacts.${pointIndex}.lastName`
                        ) &&
                        getIn(errors, `pointOfContacts.${pointIndex}.lastName`)
                      }
                      // required
                      name={`pointOfContacts.${pointIndex}.lastName`}
                      onBlur={handleBlur}
                      id={"lastName" + pointIndex}
                      value={pointElement.lastName || ""}
                      label="Last Name"
                      variant="outlined"
                      disabled={!isPointOfContact}
                      onChange={(event) => {
                        //values.pointOfContacts[pointIndex].lastName = event.currentTarget.value;
                        let obj = [...pointOfContact];
                        obj[pointIndex].lastName = event.currentTarget.value.replace(/[^a-zA-Z\-\s]/g, "").trimStart().substring(0, 20);
                        setpointOfContact(obj);
                      }}
                    />
                  </Grid>
                  <Grid item xs lg={3}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        getIn(touched, `pointOfContacts.${pointIndex}.email`) &&
                        getIn(errors, `pointOfContacts.${pointIndex}.email`)
                      )}
                      helperText={
                        getIn(touched, `pointOfContacts.${pointIndex}.email`) &&
                        getIn(errors, `pointOfContacts.${pointIndex}.email`)
                      }
                      required
                      name={`pointOfContacts.${pointIndex}.email`}
                      onBlur={handleBlur}
                      id={"email_" + pointIndex}
                      value={pointElement.email || ""}
                      label="Email"
                      variant="outlined"
                      disabled={!isPointOfContact}
                      onChange={(event) => {
                        //values.pointOfContacts[pointIndex].email = event.currentTarget.value;
                        let obj = [...pointOfContact];
                        obj[pointIndex].email = event.currentTarget.value.trimStart();
                        setpointOfContact(obj);
                      }}
                    />
                  </Grid>
                  <Grid item xs lg={10}>
                    <Stack
                      key={"index2"}
                      onChange={(e) =>
                        handlepointOfContactValues(pointIndex, e)
                      }
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      sx={{ mt: 3 }}
                    >
                      <TextField

                        error={Boolean(
                          getIn(touched, `pointOfContacts.${pointIndex}.skypeId`) &&
                          getIn(errors, `pointOfContacts.${pointIndex}.skypeId`)
                        )}
                        helperText={
                          getIn(touched, `pointOfContacts.${pointIndex}.skypeId`) &&
                          getIn(errors, `pointOfContacts.${pointIndex}.skypeId`)
                        }

                        name={`pointOfContacts.${pointIndex}.skypeId`}
                        onBlur={handleBlur}
                        id={"skypeId" + pointIndex}
                        label="Skype Id"
                        value={pointElement.skypeId || ""}
                        variant="outlined"
                        disabled={!isPointOfContact}
                        onChange={(event) => {
                          //values.pointOfContacts[pointIndex].skypeId = event.currentTarget.value;
                          let obj = [...pointOfContact];
                          obj[pointIndex].skypeId = event.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                          setpointOfContact(obj);
                        }}
                      />
                      <TextField
                        error={Boolean(
                          getIn(
                            touched,
                            `pointOfContacts.${pointIndex}.mobileNumber`
                          ) &&
                          getIn(
                            errors,
                            `pointOfContacts.${pointIndex}.mobileNumber`
                          )
                        )}
                        helperText={
                          getIn(
                            touched,
                            `pointOfContacts.${pointIndex}.mobileNumber`
                          ) &&
                          getIn(
                            errors,
                            `pointOfContacts.${pointIndex}.mobileNumber`
                          )
                        }
                        required
                        name={`pointOfContacts.${pointIndex}.mobileNumber`}
                        onBlur={handleBlur}
                        id={"mobileNumber_" + pointIndex}
                        label="Mobile Number"
                        variant="outlined"
                        disabled={!isPointOfContact}
                        value={pointElement.mobileNumber || ""}
                        onChange={(event) => {
                          //values.pointOfContacts[pointIndex].mobileNumber = event.currentTarget.value;
                          let obj = [...pointOfContact];
                          obj[pointIndex].mobileNumber =
                            event.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").trimStart().substring(0, 10);

                          setpointOfContact(obj);
                        }}
                      />

                      {/* <TextField
                        id={"code_" + pointIndex}
                        name={"code_" + pointIndex}
                        label="Code"
                        variant="outlined"
                        value={pointOfContact[pointIndex].code}
                        disabled={!isPointOfContact}
                        select
                        sx={{ width: 200 }}
                      >
                        {dummy.map((option, index) => (
                          <MenuItem
                            key={option.value}
                            value={option.retePerHour}
                            onClick={(event) => { 
                              //values.pointOfContacts[index].code=option.value;
                              let obj = [...pointOfContact]
                              obj[pointIndex].code = option.value;
                              setpointOfContact(obj);
                            }}
                          >
                            {option.retePerHour}
                          </MenuItem>
                        ))}
                      </TextField> */}

                      <TextField
                        error={Boolean(
                          getIn(
                            touched,
                            `pointOfContacts.${pointIndex}.alterNateNumber`
                          ) &&
                          getIn(
                            errors,
                            `pointOfContacts.${pointIndex}.alterNateNumber`
                          )
                        )}
                        helperText={
                          getIn(
                            touched,
                            `pointOfContacts.${pointIndex}.alterNateNumber`
                          ) &&
                          getIn(
                            errors,
                            `pointOfContacts.${pointIndex}.alterNateNumber`
                          )
                        }
                        required
                        name={`pointOfContacts.${pointIndex}.alterNateNumber`}
                        onBlur={handleBlur}
                        id={"alterNateNumber_" + pointIndex}
                        label="Alternate Number"
                        variant="outlined"
                        disabled={!isPointOfContact}
                        value={pointElement.alterNateNumber || ""}
                        onChange={(event) => {
                          //values.pointOfContacts[pointIndex].alterNateNumber = event.currentTarget.value;
                          let obj = [...pointOfContact];
                          obj[pointIndex].alterNateNumber =
                            event.currentTarget.value.replace(/[^0-9]\s{0,}$/g, "").trimStart().substring(0, 10);
                          setpointOfContact(obj);
                        }}
                      />



                    </Stack>
                    {isPointOfContact && pointIndex ? (
                      <Button
                        endIcon={<Icons.Delete />}
                        sx={{ my: 2 }}
                        color="error"
                        onClick={() => removeEpointOfContactFields(pointIndex)}
                      >
                        Remove{" "}
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>

              </Box>
            ))}
            <Box sx={{ mb: 3 }}>
              {/* {pointOfContact.length < 2 && isPointOfContact && ( */}
              {isPointOfContact && (
                <Button
                  size="small"
                  endIcon={<AddIcon />}
                  onClick={() => {
                    addepointOfContactFields();
                  }}
                  sx={{ mt: 2 }}
                >
                  Add More{" "}
                </Button>
              )}
            </Box>

            <Typography variant="body1" component="h2" sx={{ fontWeight: 500 }}>
              Payment Details
            </Typography>
            <Grid container spacing={3} sx={styles.SpaceTop}>
              <Grid
                item
                xs
                lg={3}
                style={{
                  display: costing == "fixed" ? "inline-block" : "none",
                }}
              >
                <TextField
                  fullWidth
                  error={Boolean(
                    getIn(touched, "paymentDetails.upfrontPayment") &&
                    getIn(errors, "paymentDetails.upfrontPayment")
                  )}
                  helperText={
                    getIn(touched, "paymentDetails.upfrontPayment") &&
                    getIn(errors, "paymentDetails.upfrontPayment")
                  }
                  required
                  id="values.paymentDetails.upfrontPayment"
                  value={upfrontPayment}
                  disabled={costing != "fixed"}
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(/\s{2,}$/g, "").trimStart();
                    values.paymentDetails.upfrontPayment = value;
                    setupfrontPayment(value);
                  }}
                  label="Upfront Payment"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs
                lg={3}
                style={{
                  display: costing == "fixed" ? "inline-block" : "none",
                }}
              >
                <TextField
                  error={Boolean(
                    getIn(touched, "paymentDetails.currency") &&
                    getIn(errors, "paymentDetails.currency")
                  )}
                  helperText={
                    getIn(touched, "paymentDetails.currency") &&
                    getIn(errors, "paymentDetails.currency")
                  }
                  required
                  id="outlined-basic"
                  label="Select Currency"
                  value={currency}
                  disabled={costing != "fixed"}
                  variant="outlined"
                  select
                  fullWidth
                >
                  {dummy.map((option, idx) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      onClick={(event) => {
                        values.paymentDetails.currency = dummy[idx].value;
                        setcurrency(dummy[idx].value);
                      }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs
                lg={3}
                style={{
                  display: costing != "fixed" ? "inline-block" : "none",
                }}
              >
                <TextField
                  required
                  id="outlined-basic"
                  disabled={costing == "fixed"}
                  select
                  name="billingType"
                  label="Billing Type"
                  variant="outlined"
                  value={billingType}
                  fullWidth
                >
                  {AppConstants.DummyBillingType.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      value={option.id}
                      onClick={(event) => {
                        setbillingType(option.id);
                        values.paymentDetails.billingType = option.id;
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs
                lg={3}
                style={{
                  display: costing != "fixed" ? "inline-block" : "none",
                }}
              >
                <TextField
                  fullWidth
                  error={Boolean(
                    getIn(touched, "paymentDetails.ratePerHour") &&
                    getIn(errors, "paymentDetails.ratePerHour")
                  )}
                  helperText={
                    getIn(touched, "paymentDetails.ratePerHour") &&
                    getIn(errors, "paymentDetails.ratePerHour")
                  }
                  required
                  name="paymentDetails.ratePerHour"
                  onBlur={handleBlur}
                  id="values.paymentDetails.ratePerHour"
                  label="Rate Per Hour"
                  value={ratePerHour}
                  disabled={costing == "fixed"}
                  onChange={(e) => {
                    values.paymentDetails.ratePerHour = e.currentTarget.value;
                    setratePerHour(e.currentTarget.value.substring(0, 4));
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs
                lg={3}
                style={{
                  display: costing != "fixed" ? "inline-block" : "none",
                }}
              >
                <TextField
                  error={Boolean(
                    getIn(touched, "paymentDetails.currency") &&
                    getIn(errors, "paymentDetails.currency")
                  )}
                  helperText={
                    getIn(touched, "paymentDetails.currency") &&
                    getIn(errors, "paymentDetails.currency")
                  }
                  required

                  disabled={costing == "fixed"}
                  id="outlined-basic"
                  label="Currency"
                  variant="outlined"
                  value={currency}
                  select
                  fullWidth
                >
                  {dummy.map((option, idx) => (
                    <MenuItem
                      key={idx}
                      value={option.value}
                      onClick={(event) => {
                        values.paymentDetails.currency = dummy[idx].value;
                        setcurrency(dummy[idx].value);
                      }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {costing == "fixed" && (
                <>
                  <Grid item xs lg={3}></Grid>
                  <Grid item xs lg={3}></Grid>
                </>
              )}
              {costing != "fixed" && <Grid item xs lg={3}></Grid>}
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  component="h2"
                  sx={{ fontWeight: 500 }}
                >
                  Other
                </Typography>
              </Grid>
              <Grid item xs lg={3}>
                {/* <TextField
                  error={Boolean(
                    getIn(touched, "projectDepartment.departmentId") &&
                    getIn(errors, "projectDepartment.departmentId")
                  )}
                  helperText={
                    getIn(touched, "projectDepartment.departmentId") &&
                    getIn(errors, "projectDepartment.departmentId")
                  }
                  name="projectDepartment.departmentId"
                  required
                  onBlur={handleBlur}
                  id="outlined-basic"
                  label="Department"
                  value={projectDepartments.departmentId}
                  variant="outlined"
                  select
                  fullWidth
                >
                  {DepartmentTT.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      value={option.id}
                      onClick={(event) => {
                        values.projectDepartment.departmentId = option.id;
                        // setDepartments(DepartmentTT[index].id);
                        GetResourcesbyDepartmentId(option.id, "");
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField> */}
                <Autocomplete
                  value={
                    DepartmentTT?.find(
                      (option) => option.id == projectDepartments.departmentId
                    ) ?? null
                  }
                  // fullWidth
                  sx={{ ml: "15px" }}
                  id="outlined-departmentId"
                  options={DepartmentTT}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    // setdepartmentsId();
                    values.projectDepartment.departmentId = value?.id ?? "";
                    setprojectDepartments({ departmentId: value?.id ?? "" })
                    setProjectOwnerId("");
                    setdepartmentsName(value?.name ?? "");
                    GetResourcesbyDepartmentId(value?.id, "");
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-departmentId"
                      name="projectDepartment.departmentId"
                      variant="outlined"
                      value={projectDepartments.departmentId}
                      label="Department"
                      fullWidth
                      sx={{ minWidth: 220 }}
                      error={Boolean(
                        getIn(touched, "projectDepartment.departmentId") &&
                        getIn(errors, "projectDepartment.departmentId")
                      )}
                      helperText={
                        getIn(touched, "projectDepartment.departmentId") &&
                        getIn(errors, "projectDepartment.departmentId")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs lg={3}>

                <Autocomplete
                  disabled={!projectDepartments.departmentId && projectDepartments.departmentId === ""}
                  value={
                    hodNameT?.find(
                      (option) => option.userId == projectOwnerId
                    ) ?? null
                  }
                  // fullWidth
                  sx={{ ml: "15px" }}
                  id="outlined-projectOwnerId"
                  options={hodNameT}
                  getOptionLabel={(option) => option.userName}
                  onChange={(event, value) => {
                    // setdepartmentsId();
                    values.projectOwnerId = value?.userId ?? "";
                    setProjectOwnerId(value?.userId ?? "");
                  }}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          options: {
                            altBoundary: true,
                            flipVariations: true,
                            altAxis: true,
                            padding: 8,
                            fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                          },
                        },
                      ],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        getIn(touched, "projectOwnerId") &&
                        getIn(errors, "projectOwnerId")
                      )}
                      helperText={
                        getIn(touched, "projectOwnerId") &&
                        getIn(errors, "projectOwnerId")
                      }
                      required
                      onBlur={handleBlur}
                      id="outlined-projectOwnerId"
                      label="Project Owner"
                      value={projectOwnerId}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs lg={6}>
                <Multiselect
                  showArrow
                  selectedValues={skillset}
                  displayValue="name"
                  onKeyPressFn={function setSkillSetsfromDropdown() { }}
                  onRemove={setSkillSetsfromDropdown}
                  onSearch={function setSkillSetsfromDropdown() { }}
                  onSelect={setSkillSetsfromDropdown}
                  placeholder="Skill Set"
                  options={skillsInters}
                  style={dropdown_syle}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />
            <Stack
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 3 }}
            >
              <Button
                variant="outlined"
                component={link}
                to="/project/projectlist"
                color="primary"
              >
                Cancel
              </Button>

              <Button variant="contained" type="submit" disabled={updateButtonDisable || buttonDisable}>
                {!IsUpdate ? "Add Project" : "Update Project"}
              </Button>
            </Stack>
          </ItemPaper>
        </form>
      )}
    </Formik>
  );
}

