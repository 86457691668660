import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Grid';
import AvatarGroup from "@mui/material/AvatarGroup";
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import * as Icons from '../../../src/assests//icons/icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { commonFunctions } from '../../service/common-services/common-functions';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useRef, useState } from 'react';
import { Chip, FormControl, Modal, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'material-react-toastify';
import { meetingServices } from '../../service/Meetings/Meeting-service';
import FileUpload from "../../components/styledComponent/FileUpload";
import { apiUrl } from "../../service/common-services/apiURL";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IconButton from "@mui/material/IconButton";
import Userlisttop from '../users/userliststyle';
import { userManagementServive } from '../../service/user-management-service';
import NotFound from '../../components/styledComponent/NotFound';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { DatePicker } from 'react-rainbow-components';
import getDate from '../../components/GetDateWithSimpletime';
import Checkbox from "@mui/material/Checkbox";
import theme from '../../theme/theme';

const styles: any = {
  userAvatar: {
    justifyContent: "flex-end",
    "& .MuiAvatar-root": {
      textTransform: "Uppercase",
      // fontFamily: "sans-serif",

      borderColor: "#fff",
      fontWeight: 500,
      width: "32px",
      height: "32px",
      cursor: "pointer",
      fontSize: 13,
      lineHeight: " 16px",
    },
  },
};



const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface MOMValue {
  MOM: string;
  Assignee: string;
  Date: any;
  isLast: boolean
}

let objMoM =
{
  MOM: "",
  Assignee: "",
  Date: "",
  isLast: false,
}
const status = [
  {
    id: 0,
    label: "All",
  },
  {
    id: 1,
    label: "Pending",
  },
  {
    id: 2,
    label: "Inprogress",
  },
  {
    id: 3,
    label: "Complete",
  },

];

interface IFiles {
  itemType: string;
  name: string;
  file?: any;
  fileURL: string;
  uploadedBy?: any;
  fileName: string;
  ext?: any;
}

export default function BasicTable() {
  const _permission = useSelector((state: any) => state.MeetingsPermissionApp.MeetingsPermission);
  // const [anchorEl, setAnchorEl] = React.useState<Array<null | HTMLElement>>([]);
  const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
  const [MoMValue, setMoMValue] = useState<MOMValue[]>([]);
  const [validationErrors, setValidationErrors] = useState<any>([]);
  const [momList, setMoMList] = useState<any>([]);
  const [assigneeList, setAssigneeList] = useState<any[]>([]);
  const [assigneeId, setAssigneeId] = useState("");
  const [filtersSelected, setfiltersSelected] = React.useState(false);
  const [selectedStatus, setSelectedStatus]: any = useState<number | undefined>(0);
  const [updateStatus, setUpdateStatus] = useState<(number | undefined)[]>([]);
  const [anchorEl, setAnchorEl] = useState<(HTMLElement | null)[]>([]);
  const [open, setOpen] = useState<boolean[]>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedMomId, setSelectedMomId] = useState("");
  const [dialogData, setDialogData] = React.useState<any>(null);
  const [meeting, setMeeting] = useState<any>("");
  const [openModal, setModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [minMeetingtime, setMinMeetingtime] = React.useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [actionItemsEditing, setActionItemEditing] = React.useState(false);
  const [momId, setMomId] = React.useState("");
  const [editedMomSubject, setEditedMomSubject] = useState("");
  const [editedActionItem, setEditedActionItem] = useState("");
  const [isEditingId, setIsEditingId] = useState("");
  const [dueTask, setDueTask] = useState(false);

  const [anchorUserEl, setAnchorUserEl] = React.useState<null | HTMLElement>(null);
  const openUserList = Boolean(anchorUserEl);
  const handleClickUserList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserEl(event.currentTarget);
  };
  const handleCloseList = () => { setAnchorUserEl(null); };
  const [showHelpText, setshowHelpText] = React.useState(true);

  const currentvalueofAssignee = useRef("");
  const currentvalueofAssigneeForUploadDetails = useRef("");
  console.log("Updated value:", currentvalueofAssignee.current);

  const { tabId, id } = useParams();
  console.log(id, "id===")
  let IfileObj: IFiles = {
    itemType: "",
    name: "",
    fileURL: "",
    fileName: "",
    file: null,
  };
  let objDocument: IFiles[] = [];
  const [uploadFile, setUploadFile] = useState<IFiles[]>(objDocument);
  console.log(uploadFile, "uploadFileuploadFile")


  useEffect(() => {
    if (_permission.page_ManageMeeting_Listing_Access !== true) {
      toast.warn("You Dont have access of this Page")
      navigate("/dashboard");
    }
    debugger

    const fetchData = async () => {
      try {
        let abc = await getMeetingById(id);
        if (abc?.createdBy == _authUser?.id || _permission.action_ManageMeeting_Listing_Add_MOM_Access) {
          addMoMFields(abc?.createdBy == _authUser?.id ? true : false)
        }
        else {

        }
        // Do something with abc if needed
        getAssigneeList(id);
        getMoMList(id, "", 0,dueTask);
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    fetchData();


  }, [id])




  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl((prev) => {
      const newState = [...prev];
      newState[index] = event.currentTarget;
      return newState;
    });
    setOpen((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleDateClick = (idx: any) => {
    debugger
    setIsEditing(true);
    setIsEditingId(idx)
  };

  const handleClose = (index: number) => {
    setAnchorEl((prev) => {
      const newState = [...prev];
      newState[index] = null;
      return newState;
    });
    setOpen((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };
  const addMoMFields = (isOwner: boolean) => {
    setMoMValue(prevMoMValue => {
      const updatedMoMValue = [...prevMoMValue, objMoM];
      let abc = { ...objMoM, Assignee: _authUser.id };
      const updatedMomValue1 = [...prevMoMValue, abc];
      setShowSaveButton(true);
      setTimeout(() => {
        var element = document.getElementById("scrollDown");
        element?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }, 0);
      return (isOwner ? updatedMoMValue : updatedMomValue1);
    });
  };

  const handleChange = async (
    event: React.ChangeEvent<{}>,
    value: { id: number; label: string } | null
  ) => {
    if (value) {
      setfiltersSelected(true);
      setSelectedStatus(value.id);
      getMoMList(id, assigneeId, value.id,dueTask);
    } else {
      setfiltersSelected(true);
      setSelectedStatus(0);
      getMoMList(id, assigneeId, 0,dueTask);
    }
  };

  const handleDateChange = async (value: any, momdata: any) => {

    let param = {
      momId: momdata?.momId,
      momSubject: momdata?.momSubject,
      assignedTo: momdata?.assignedTo ?? "",
      status: momdata?.status,
      etaDate: value ?? ""
    }

    await meetingServices.updateMomDetails(param).then((response: any) => {
      if (response.data.isError) {
      }
      else {
        getMoMList(id, assigneeId, selectedStatus,dueTask);
        toast.success("ETA Date Changed Successfully")
        setIsEditing(false);
        setIsEditingId("")
      }
    });

  };

  const handleActionItemChange = async (momdata: any) => {
    debugger

    if (editedMomSubject == "") {
      toast.warning("Please add action item .");
    }
    else {


      let param = {
        momId: momdata?.momId,
        momSubject: formatContent(editedMomSubject),
        assignedTo: momdata?.assignedTo ?? "",
        status: momdata?.status,
        etaDate: momdata?.etaDate ?? ""
      }

      await meetingServices.updateMomDetails(param).then((response: any) => {
        if (response.data.isError) {
        }
        else {
          getMoMList(id, assigneeId, selectedStatus,dueTask);
          toast.success("Action Item Changed Successfully")
          setIsEditing(false);
        }
      });
    }




  };

  const handleAssigneeChange = async (value: any, momdata: any) => {
    let param = {
      momId: momdata?.momId,
      momSubject: momdata?.momSubject,
      assignedTo: value?.userId ?? "",
      status: momdata?.status
    }

    await meetingServices.updateMomDetails(param).then((response: any) => {
      if (response.data.isError) {
      }
      else {
        getMoMList(id, assigneeId, selectedStatus,dueTask);
        toast.success("Assignee Changed Successfully")
      }
    });

  };

  const handleMoMStatusChange = async (value: any, momdata: any) => {
    let param = {
      momId: momdata?.momId,
      momSubject: momdata?.momSubject,
      assignedTo: momdata?.assignedTo,
      status: value
    }

    await meetingServices.updateMomDetails(param).then((response: any) => {
      if (response.data.isError) {
      }
      else {
        getMoMList(id, assigneeId, selectedStatus,dueTask);
        toast.success("Status Changed Successfully")
      }
    });

  };



  const getAssigneeList = async (meetingId: any) => {

    await meetingServices.getAssigneByMeetingId(meetingId).then((response: any) => {
      if (response.data.isError) {
      }
      else {

        setAssigneeList(response?.data);
      }
    });
  };

  const getMeetingById = async (meetingId: any) => {
    try {
      const response = await meetingServices.getMeetingById(meetingId);
      if (response.data.isError) {
        // Handle the error case
      } else {
        setMeeting(response.data);
        setMinMeetingtime(response.data.meetingDate);
        console.log(response.data.time, "HIIIHIIIHII");
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching meeting data:", error);
      // Handle the error
    }
  };

  console.log("meeting===", meeting)


  const getMoMList = async (meetingId: any, assignee: any, status: any, dueTask: boolean) => {
    var input = {
      meetingId: meetingId,
      assignedTo: assignee,
      status: status,
      dueTask: dueTask

    };
    await meetingServices.getAllMoMList(input).then((response: any) => {
      if (response.data.isError) {
      }
      else {

        setMoMList(response?.data);

      }
    });
  };

  console.log("momList===", momList)
  console.log("assigneeList===", assigneeList)

  const boxColors = ['#A5303F', '#1F944E', '#1DA7FF', '#212121', '#E59324'];
  const boxBackgroundColors = ['#FBDADE', '#E7FFF0', '#F0F9FF', '#BDBDBD', '#FFEFD9'];

  const categorizeMeetingsByAttendees = (assigneeList: any[]) => {
    let fiveUserArray: any = [];
    let greaterfiveUserArray: any = [];

    const allUsers = assigneeList.flatMap(item => item.assignes.map((user: any) => user.userName));

    const sortedData = allUsers.sort((a: string, b: string) => a < b ? -1 : 1);
    sortedData.forEach((user: string, index: number) => {
      if (index < 20) {
        fiveUserArray.push(user);
      } else {
        greaterfiveUserArray.push(user);
      }
    });

    return { fiveUserArray, greaterfiveUserArray };
  }

  const userList = categorizeMeetingsByAttendees(assigneeList);

  const handleClickOpen = (row: any) => {
    setOpenDialog(true);
    setDialogData(row);
    setDescription(row?.comment)
    currentvalueofAssigneeForUploadDetails.current = row?.assignedTo;
    if (row?.file) {
      let documentData = row?.file;
      console.log(documentData, "documentData")
      var document_array: any[] = [];
      document_array.push({
        name: row?.file,
        fileURL: row?.file,
        fileName: row?.file,
        file: null,
        itemType: "",
      });
      setUploadFile(document_array)
    }
  };

  const handleCloseOpen = () => {
    setOpenDialog(false);
    setUploadFile([]);
    currentvalueofAssigneeForUploadDetails.current = "";
  };


  // let removeMOMFields = (i: any) => {
  //   let newMOMValues = [...MoMValue];
  //   newMOMValues.splice(i, 1);
  //   setMoMValue(newMOMValues);
  // };


  const removeMOMFields = (index: any) => {
    const updatedMoMValue = MoMValue.filter((_, i) => i !== index);
    setMoMValue(updatedMoMValue);
    const updatedValidationErrors = validationErrors.filter((_: any, i: any) => i !== index);
    setValidationErrors(updatedValidationErrors);
  };

  const isMoMSubjectEmpty = (index: any) => {
    return MoMValue[index].MOM.trim() === '';
  };

  const isAssigneeEmpty = (index: any) => {
    return MoMValue[index].Assignee.trim() === '';
  };

  const isDateEmpty = (index: any) => {
    debugger
    return MoMValue[index].Date.trim() === '';
  }

  const validateFields = () => {
    debugger
    const errors = MoMValue.map((item, index) => {
      return {
        subjectError: isMoMSubjectEmpty(index),
        assigneeError: isAssigneeEmpty(index),
        dateError: isDateEmpty(index)
      };

    });
    console.log(errors, "errors ---");
    setValidationErrors(errors);
    return !errors.some((error) => error.subjectError || error.assigneeError || error.dateError);
  };

  const addMOM = async (meetingId: any, subject: any, assignee: any, etaDate: any,lastelem:boolean) => {
    debugger
    try {
      const data = {
        meetingId: meetingId,
        momSubject: subject,
        assignedTo: assignee,
        etaDate: etaDate,
        isLast:lastelem
      };
      const response = await meetingServices.addMOM(data);
      if (response.data.isError) {

      } else {
        clearFields();
        getMoMList(id, assigneeId, selectedStatus,dueTask);

      }
    } catch (error: any) {
      error.data.Error.map((err: any) => {
        toast.warn(err)

      });

    }
  };

  const clearFields = () => {
    setMoMValue([]);

  };

  const formatContent = (content: any) => {
    debugger
    const formatted = content
      ?.split('\n')
      ?.map((line: any, index: any) => {
        debugger
        if (index === 0) {
          debugger
          return `<p>${line}</p>`;
        } else {
          debugger
          return `<p>${line?.replace(/^\d+\./, (match: any) => `<strong>${match}</strong>`)}</p>`;
        }
      })
      ?.join('');
    console.log(formatted, "formatted")
    return formatted;
  };

  const handleSave = async () => {
    debugger
    console.log(MoMValue, "MOM VALUE --- ");
    console.log(MoMValue[0].Date, "MOM Date ---");
    const isValid = validateFields();
    if (isValid) {
      try {
        debugger
        if (MoMValue && MoMValue.length > 0) {
          if (MoMValue.length > 1) {
            MoMValue[MoMValue.length - 1].isLast = true;
          } else {
            MoMValue[0].isLast = true;
          }
        }
        debugger
        await Promise.all(
          MoMValue.map(mom => {
            return addMOM(id, formatContent(mom.MOM), mom.Assignee, mom.Date, mom.isLast);
          })
        );
        setShowSaveButton(false);
        toast.success("MOM added successfully");
      } catch (error) {
        // Handle error
      }
    } else {
      // Handle invalid fields
    }
  };



  const triggerDocumentFile = (fileList: FileList, index: number) => {
    IfileObj.itemType = "";
    IfileObj.name = "";
    IfileObj.fileURL = "";
    IfileObj.file = null;
    IfileObj.fileURL = (URL.createObjectURL(fileList[0]));
    IfileObj.file = (fileList[0]);;
    let fObj = [...uploadFile];
    fObj.push(IfileObj);
    setUploadFile([IfileObj]);
  };


  console.log("userList.greaterfiveUserArray ===", userList.greaterfiveUserArray)

  async function uploadFileItems(description: string, momId: string) {
    try {
      let dataabc: any = "";
      await Promise.all(uploadFile.map(async function (item: any) {
        let abc = item?.fileURL.substring(0, 9);

        let formData = new FormData();
        if (abc == "blob:http") {
          formData.append("File", item.file);
        }
        formData.append("MomId", momId);
        formData.append("Comment", description ? description : "");
        try {
          const response = await meetingServices.UploadMoMDocument(formData);
          if (response.data.isError) {
            toast.error("Facing issue while Document Upload " + item.name);
          } else {
            dataabc += response.data + " ";
            toast.success("Uploaded Successfully");
          }
        } catch (ex: any) {
          toast.warn(ex);
        }

      }));
      getMoMList(id, assigneeId, selectedStatus,dueTask);
      handleCloseOpen();

    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        return (
          toast.warn(err)
        )
      });
    }
  }
  const handleUploadFile = async () => {
    uploadFileItems(description, selectedMomId);
    setUploadFile([]);
    currentvalueofAssigneeForUploadDetails.current = "";
  };

  const handleMomClick = (momId: string) => {
    setSelectedMomId(momId);
  };



  // const formatDate = (dateString: string): string => {
  //   const date = new Date(dateString);
  //   setSelectedDate(dateString);
  //   return date.toLocaleDateString();
  // };

  const getDownloadedFiles = async (fileName: any, momId: any) => {
    try {
      await meetingServices
        .downloadFile(fileName, momId)
        .then(async (response) => {
          if (response.data.type === "application/json") {
            const data = await response.data.text();
            toast.warning(JSON.parse(data).listErrors[0]);
          } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            if (fileName.endsWith(".docx")) {
              a.href = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })
              );
            } else if (fileName.endsWith(".pdf")) {
              a.href = URL.createObjectURL(
                new Blob([response.data], { type: "application/pdf" })
              );
            } else {
              a.href = URL.createObjectURL(new Blob([response.data]));
            }
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(a.href);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleDownloadFile = () => {
    getDownloadedFiles(dialogData.file, dialogData.momId);

  }

  console.log("dialogData====", dialogData)


  const deleteUserDetaildata = async (momId: any) => {
    setModalOpen(true);
    setSelectedMomId(momId);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    //setAnchorEl(null);
  };

  const deleteUserDetail = async () => {
    try {
      await meetingServices
        .deleteMoM(selectedMomId)
        .then((response: any) => {
          if (response.data.isError) {
          } else {
            toast.success("MOM Deleted Successfully");
            setModalOpen(false);
            getMoMList(id, assigneeId, selectedStatus,dueTask);
          }
        });
    } catch (ex: any) {
      ex.data.Error.map((err: any, idx: any) => {
        toast.warning(err);
      });
    }
  };

  const handleDescriptionChange = (e: any) => {
    const value = e.target.value;
    if (value.length > 250) {
      setValidationMessage("The description must be 250 characters or less.");
    } else {
      setValidationMessage("");
    }
    setDescription(value.slice(0, 250));
  };
  const parseHtmlToText = (html: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const getTextFromNode = (node: Node): string => {
      let text = '';

      if (node.nodeType === Node.TEXT_NODE) {
        text += (node as Text).textContent || '';
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;

        if (element.tagName === 'P') {
          text += getTextFromChildren(element) + '\n\n';
        } else if (element.tagName === 'BR') {
          text += '\n';
        } else if (element.tagName === 'UL') {
          text += getTextFromChildren(element).split('\n').map(line => `• ${line}`).join('\n') + '\n';
        } else if (element.tagName === 'LI') {
          text += getTextFromChildren(element) + '\n';
        } else if (element.tagName === 'STRONG') {
          text += (element.textContent || '').toUpperCase();
        } else {
          text += getTextFromChildren(element);
        }
      }

      return text;
    };

    const getTextFromChildren = (element: HTMLElement): string => {
      return Array.from(element.childNodes).map(getTextFromNode).join('');
    };

    return getTextFromChildren(tempDiv).trim();
  };



  // const handleDueDateChange = async (isChecked: boolean) => {
  //   const dueTask = isChecked;
  //   await getMoMList(id, assigneeId, selectedStatus, dueTask);
  // };

  const handleDueDateChange = async (isChecked: boolean) => {
    setDueTask(isChecked);
    await getMoMList(id, assigneeId, selectedStatus, isChecked);
  };


  return (
    <Box>
      <p
        style={{
          textDecoration: 'underline',
          color: '#00a1e4',
          cursor: 'pointer',
        }}
        onClick={() => navigate(`/mymeetings/${tabId}`)}
      >
        {tabId == "0" ? "Go Back to My-Meetings" : "Go Back to Other-Meetings"}
      </p>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Stack>

          <Typography variant="h6" component="h2" sx={{ fontWeight: 600, color: '#232360' }}>

            {meeting.subject}
          </Typography>
          <Typography variant="body2" component="h2">

            {meeting.description}

          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={'baseline'}>
            <Stack direction={'row'} sx={{ mt: 2 }} alignItems={'center'} spacing={1}>
              {userList.fiveUserArray.length !== 0 && userList.fiveUserArray.map((item: string, itemIdx: number) => {
                const backgroundColor = boxBackgroundColors[itemIdx % boxBackgroundColors.length];
                const textColor = boxColors[itemIdx % boxColors.length];
                return (
                  <Tooltip title={item} arrow key={itemIdx}>
                    <Box sx={{
                      borderRadius: '50%',
                      background: backgroundColor,
                      color: textColor,
                      width: '36px', height: '36px',
                      display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                      {commonFunctions.getFirstTwoLettersFromString(item)}
                    </Box>
                  </Tooltip>
                )
              })}
              {userList.greaterfiveUserArray.length !== 0 && (
                <Box>
                  <Stack direction={'row'} alignItems={'center'}>
                    {/* <Icons.AddIcon />
                    <Typography sx={{ color: '#232360', marginLeft: '4px' }}>
                      {userList.greaterfiveUserArray.length}
                    </Typography> */}

                    <Avatar
                      component={Button}
                      aria-controls={openUserList ? "menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openUserList ? "true" : undefined}
                      onClick={handleClickUserList}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        minWidth: "inherit",
                        "&:hover": {
                          bgcolor: theme.palette.primary.dark,
                        },
                      }}
                    >
                      <Icons.AddIcon />
                      <Typography sx={{ color: '#fff', marginLeft: '4px' }}>
                        <span>+{userList.greaterfiveUserArray.length}</span>
                      </Typography>
                    </Avatar>
                  </Stack>
                </Box>
              )}

              <Menu
                id="menu"
                anchorEl={anchorUserEl}
                open={openUserList}
                onClose={handleCloseList}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {userList.greaterfiveUserArray.map((user: any, index: any) => (
                  <MenuItem
                    key={index}
                    value={user}

                  >

                    {user}
                  </MenuItem>
                ))}
              </Menu>

            </Stack>

          </Stack>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 4 }}>
            <Typography variant="body2" component="h2">

              Meeting Owner:
              <Box component="span" sx={{ ml: 1 }}>
                <Chip variant="approved" label={meeting.meetingOwnerName} />
              </Box>
            </Typography>
          </Box>
        </Stack>
      </Paper>



      <Paper  >
        <Grid sx={{ background: "#fff", zIndex: "9999", padding: "16px", width: "100%", marginLeft: "0" }} container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="h6" component="h2" sx={{ fontWeight: 600, color: '#232360' }}>
              Minutes of Meeting
            </Typography>
          </Grid>
          <Grid item xs={10}>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
              <Stack direction={'row'} alignItems={'center'} sx={{ paddingRight: '30px' }}>
                <Checkbox
                  color="primary"
                  inputProps={{
                    "aria-label": "select all desserts",
                  }} 
                  onChange={(event) => handleDueDateChange(event.target.checked)} 
                  
                />
                <Typography sx={{ color: '#232360', fontWeight: 600 }}>Due Date</Typography>
              </Stack>

              <Typography sx={{ color: '#232360', fontWeight: 600, }}>Filter By</Typography>

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  assigneeList
                    ?.flatMap((assignee) => assignee.assignes)
                    .find((assignee) => assignee.userId === assigneeId) ?? null
                }
                options={assigneeList.flatMap((assignee) => assignee.assignes)}
                getOptionLabel={(option) => option.userName}
                onChange={async (event, value) => {
                  setfiltersSelected(true);
                  setAssigneeId(value?.userId ?? "");
                  await getMoMList(id, value?.userId ?? "", selectedStatus,dueTask);
                  if (value?.userId) {
                    currentvalueofAssignee.current = value?.userId;
                  }
                }}
                renderInput={(params) => (
                  <TextField sx={{ minWidth: 220 }} {...params} label="Assigned To" />
                )}
              />

              <Autocomplete
                disablePortal
                value={
                  status?.find(
                    (option) => option.id === selectedStatus
                  ) ?? null
                }
                id="combo-box-demo"
                options={status}
                getOptionLabel={(option) => option.label}
                onChange={handleChange}

                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} label="Status" />}
              />


            </Stack>

          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: "500px", overflowY: "auto" }}  >

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {momList && momList.meetingData && (
              <TableHead sx={{ position: "sticky", top: "0", backgroundColor: "#ffffff", zIndex: "999" }}>
                <TableRow>
                  <TableCell >Action Items</TableCell>
                  <TableCell >Assigned To</TableCell>
                  <TableCell sx={{ width: "15%" }} >Status</TableCell>
                  <TableCell sx={{ width: "15%" }}>ETA Date</TableCell>
                  <TableCell >

                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>

                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {momList && momList.meetingData && momList.meetingData.map((row: any, index: any) => {
                {
                  console.log("assigneeID===", row.assignedTo)
                  console.log("Auth User ID:", _authUser?.id);
                  console.log("Meeting Owner ID:", momList?.meetingDetails?.meetingOwnerId);
                  const condition1 = (_authUser?.id !== momList?.meetingDetails?.meetingOwnerId);
                  const condition2 = (row?.assignedTo !== _authUser?.id);
                  console.log("Condition 1:", condition1);
                  console.log("Condition 2:", condition2);
                }

                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => handleMomClick(row.momId)}
                  >
                    <TableCell component="th" scope="row" sx={{ width: "500px" }}>

                      {/* <Typography>{row.momSubject}</Typography> */}
                      <Box>
                        {actionItemsEditing && selectedMomId === row.momId ? (
                          <TextField
                            id="outlined-basic"
                            name="StoryPoints"
                            value={parseHtmlToText(editedMomSubject)}
                            multiline
                            autoFocus
                            onBlur={() => {
                              setActionItemEditing(false);
                              handleActionItemChange(row);

                            }}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              style: { lineHeight: "1.1" }
                            }}
                            sx={{
                              "& .MuiInputBase-input": {
                                padding: "10px 10px 10px 10px",
                              },
                            }}
                            onChange={(e) => {
                              let value = e.currentTarget.value;

                              setEditedMomSubject(value);

                            }}
                          />
                        ) : (
                          <Typography
                            sx={{ padding: "12px", backgroundColor: "#1DA7FF15", borderRadius: "8px" }}
                            onClick={(e) => {
                              debugger
                              e.stopPropagation(); // Prevent triggering the row click event
                              setSelectedMomId(row.momId);
                              setEditedMomSubject(row.momSubject);
                              setActionItemEditing(true);
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                row.momSubject != null && row.momSubject !== "" ? row.momSubject : "None"
                            }}
                          >

                            {/* {row.momSubject != null && row.momSubject !== "" ? row.momSubject : "None"} */}
                          </Typography>
                        )}
                      </Box>

                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ width: "250px" }}>

                        <Autocomplete
                          fullWidth
                          blurOnSelect
                          id={`combo-box-demo-${row.id}`}
                          disabled={_authUser.id != momList?.meetingDetails?.meetingOwnerId || row?.status != 1}
                          value={assigneeList?.find(
                            (option: any) => option.id == row?.assignedTo
                          ) ?? null}
                          options={assigneeList.flatMap((assignee) => assignee.assignes)}
                          getOptionLabel={(option) => option.userName}
                          onChange={(event, value) => {
                            handleAssigneeChange(value, row);
                          }}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'flip',
                                  enabled: true,
                                  options: {
                                    altBoundary: true,
                                    flipVariations: true,
                                    altAxis: true,
                                    padding: 8,
                                    fallbackPlacements: ['bottom'],
                                  },
                                },
                              ],
                            },
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <AvatarGroup sx={[styles.userAvatar]}>
                                <Avatar
                                  {...commonFunctions.stringAvatar(option.userName)}

                                  sx={{ bgcolor: deepOrange[500], margin: "0 28px 0 0", width: 30, height: 30 }}
                                  src={`${process.env.REACT_APP_SERVER_URL}${"test"}`}
                                />
                              </AvatarGroup>
                              {option.userName}
                            </Box>
                          )}
                          renderInput={(params) => <TextField sx={{ Width: 230 }} {...params} label="Assignee"
                            disabled={_authUser.id != momList?.meetingDetails?.meetingOwnerId || row?.status != 1}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                  <Avatar
                                    {...commonFunctions.stringAvatar(row.assignedName)}
                                    sx={{ bgcolor: deepOrange[500], margin: "0 10px 0 0" }}
                                    style={{ width: "30px", height: "30px", fontSize: "14px" }}
                                    src={`${process.env.REACT_APP_SERVER_URL}${"test"}`}
                                  />
                                  {row.assignedName}
                                </Box>
                              ),
                            }}
                          />}
                        />
                      </Box>
                    </TableCell>


                    <TableCell>
                      <Button
                        id={`basic-button-${index}`}
                        aria-controls={open[index] ? `basic-menu-${index}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open[index] ? "true" : undefined}
                        onClick={(event) => handleClick(event, index)
                        }
                        variant="outlined"
                        endIcon={<KeyboardArrowDownIcon />}
                        disabled={row?.assignedTo != _authUser.id || row.status == 3}
                      >

                        {status.find((option) => option.id === updateStatus[index])?.label ||
                          status.find((option) => option.id === row.status)?.label}
                      </Button>
                      <Menu
                        id={`basic-menu-${index}`}
                        anchorEl={anchorEl[index]}
                        open={open[index]}
                        onClose={() => handleClose(index)}
                        MenuListProps={{
                          "aria-labelledby": `basic-button-${index}`,
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        sx={{
                          "& .MuiMenu-paper": {
                            boxShadow: 3,
                          }, color: "red"
                        }}
                      >
                        {status
                          .filter((option) => option.id !== 0)
                          .map((option) => (
                            <MenuItem key={option.id}
                              onClick={() => { handleMoMStatusChange(option.id, row); handleClose(index) }} >
                              {option.label}
                            </MenuItem>
                          ))}
                      </Menu>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {isEditingId === index && row.status === 1 && row?.assignedTo === _authUser.id ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            borderRadius="semi-square"
                            placeholder="Select Date"
                            name="meetingDate"
                            labelAlignment="left"
                           // disabled={row.status != 1 || row?.assignedTo !== _authUser.id}
                            value={selectedDate}
                            //minDate={moment().subtract(0, "years").toDate()}
                            minDate={new Date(minMeetingtime)}
                            maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                            onChange={(date) => {
                              debugger
                              const trimmedStartDate = getDate(date);
                              setSelectedDate(trimmedStartDate);
                              handleDateChange(trimmedStartDate, row);
                            }}


                          />
                        </LocalizationProvider>
                      ) : (
                        <Typography onClick={() => handleDateClick(index)}>
                          {moment(row.etaDate).format('YYYY-MM-DD')}
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell colSpan={2} align="right">
                      <Stack direction={'row'} alignItems={'center'} >
                        {row.file ? (
                          <Button
                            disabled={_authUser?.id != momList?.meetingDetails?.meetingOwnerId && row?.assignedTo != _authUser?.id}
                            variant="contained"
                            onClick={() => handleClickOpen(row)}
                          //onClick={() => handleDownloadFile(row.file)}
                          >
                            View Details
                          </Button>
                        ) : (
                          <Button
                            disabled={row?.assignedTo != _authUser.id}

                            variant="contained"
                            onClick={() => handleClickOpen(row)}>
                            Upload Details
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                    {(_permission.action_ManageMeeting_Listing_Delete_MoM_Access || row?.assignedTo == _authUser.id || 
                    _authUser?.id == momList?.meetingDetails?.meetingOwnerId) && (
                      <TableCell >
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                           // disabled={}
                            onClick={() =>

                              deleteUserDetaildata(row.momId)
                            }
                          >
                            <Icons.Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>)}

                  </TableRow>
                )
              })}
              {MoMValue.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>

                    <TextField
                      id={`mom-${index}`}
                      fullWidth
                      label="Task"
                      variant="outlined"
                      multiline
                      type="text"
                      InputProps={{
                        style: { lineHeight: "1.2" }
                      }}
                      value={item.MOM}
                      onChange={(e) => {
                        debugger
                        const updatedValidationErrors = [...validationErrors];
                        updatedValidationErrors[index] = {
                          ...updatedValidationErrors[index],
                          subjectError: false
                        };
                        setValidationErrors(updatedValidationErrors);
                        const updatedMoMValue = [...MoMValue];
                        updatedMoMValue[index] = { ...updatedMoMValue[index], MOM: e.target.value };
                        setMoMValue(updatedMoMValue);
                      }}
                      error={validationErrors[index]?.subjectError}
                      helperText={validationErrors[index]?.subjectError && 'Subject is required'}
                    />

                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    <FormControl >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          borderRadius="semi-square"
                          placeholder="ETA Date"
                          name="meetingDate"
                          value={item.Date}
                          minDate={new Date(minMeetingtime)}
                          maxDate={
                            new Date(
                              new Date().getFullYear() + 1,
                              11,
                              31
                            )
                          }
                          onChange={(newValue: any) => {
                            debugger
                            const updatedValidationErrors = [...validationErrors];
                            updatedValidationErrors[index] = {
                              ...updatedValidationErrors[index],
                              dateError: false
                            };

                            setValidationErrors(updatedValidationErrors);
                            const trimmedStartDate = getDate(newValue)
                            const updatedMoMValue = [...MoMValue];
                            updatedMoMValue[index] = { ...updatedMoMValue[index], Date: trimmedStartDate };
                            setMoMValue(updatedMoMValue);
                          }}
                          error={showHelpText && validationErrors[index]?.dateError && 'ETA Date is required'}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      disabled={_authUser.id != meeting?.createdBy}
                      disablePortal
                      id={`combo-box-demo-${index}`}
                      value={
                        assigneeList
                          ?.flatMap((assignee) => assignee.assignes)
                          .find((assignee) => assignee.userId === item.Assignee) ?? null
                      }
                      options={assigneeList.flatMap((assignee) => assignee.assignes)}
                      getOptionLabel={(option) => option.userName}
                      onChange={async (event, value) => {
                        const updatedValidationErrors = [...validationErrors];
                        updatedValidationErrors[index] = {
                          ...updatedValidationErrors[index],
                          assigneeError: false
                        };
                        setValidationErrors(updatedValidationErrors);
                        const updatedMoMValue = [...MoMValue];
                        updatedMoMValue[index] = { ...updatedMoMValue[index], Assignee: value?.userId };
                        setMoMValue(updatedMoMValue);
                      }}
                      renderInput={(params) => <TextField {...params} label="Assigned To"
                        error={validationErrors[index]?.assigneeError}
                        helperText={validationErrors[index]?.assigneeError && 'Assignee is required'} />}
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: true,
                              options: {
                                altBoundary: true,
                                flipVariations: true,
                                altAxis: true,
                                padding: 8,
                                fallbackPlacements: ['bottom'],
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell colSpan={4}>
                    <Box>
                      <Box sx={{ cursor: "pointer" }} onClick={() => removeMOMFields(index)}>
                        <DeleteIcon sx={{ color: "#ff5858" }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <div id="scrollDown"></div>
            </TableBody>
          </Table>

        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginRight: "18px", marginTop: "16px", color: "#1DA7FF" }}>
          <Typography variant="body1" sx={{fontWeight: "700", fontSize: "18px" }}>
            {momList && momList.meetingData && `Total Count: ${momList.meetingData.length}`}
          </Typography>
          </Box>

        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ mt: '10px' }}>
          <Button
            variant="text"
            endIcon={<AddIcon />}
            onClick={() => addMoMFields(_authUser.id != meeting?.createdBy ? false : true)}
            sx={{ my: 2 }}
          >
            Add More{" "}
          </Button>

          {MoMValue.length > 0 && showSaveButton && (
            <Button
              onClick={handleSave}
              variant="contained"
              size="large"
              color="primary"
            >
              Save
            </Button>
          )}
        </Stack>


        <Dialog
          open={openDialog}
          onClose={handleCloseOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <DialogTitle id="alert-dialog-title">
              {dialogData && dialogData.file ? 'View Details' : 'Upload details'}
            </DialogTitle>
            <Button onClick={handleCloseOpen}>X</Button>
          </Box>
          <DialogContent>
            <Box>
              <>
                {currentvalueofAssigneeForUploadDetails.current == _authUser?.id && (
                  <FileUpload
                    selectFile={triggerDocumentFile}
                    index={1}
                  />)}
                <Stack sx={{ mt: 2, mb: 4, gap: "8px", justifyContent: "space-between" }} direction={'row'} alignItems={'center'}>
                  {uploadFile?.map((element: any, i: any) => (
                    <>
                      {element?.file ? (
                        <>
                          {element?.fileURL && /\.(jpg|jpeg|png|gif)$/i.test(element.file?.name) ? (
                            <Box key={i} sx={{
                              '&:hover': {
                                border: '1px solid #1DA7FF',
                              },
                              width: '100px', height: '100px', margin: '3px 10px', position: 'relative', border: '1px solid #EEE', borderRadius: '5px'
                            }}>

                              <img
                                width={'100%'} height={'100%'}
                                src={element?.fileURL}
                                alt=""
                                onClick={() => window.open(element.fileURL, '_blank')}
                              />

                            </Box>)
                            : (<a href={element.fileURL} target="_blank">{element.file?.name}</a>)}</>
                      ) : (
                        <a style={{
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          transition: 'color 0.2s ease',
                        }} onClick={handleDownloadFile}>{element.fileURL}</a>
                      )}</>
                  ))}

                  {dialogData && dialogData.file && (
                    <Button variant="contained" onClick={handleDownloadFile}>
                      Download File
                    </Button>
                  )}
                </Stack>

                <DialogContentText id="alert-dialog-description">
                  Description
                </DialogContentText>

                <TextField
                  label="Enter something"
                  variant="outlined"
                  sx={{
                    display: "block",
                    mt: 2,
                    "word-break": "break-all",
                    "line-height": "1.5",
                    "padding": "10px",
                    '& .MuiInputBase-root': { height: 100 },
                    "& .MuiOutlinedInput-root": {
                      "line-height": "1.5",
                      "padding": "10px",
                    }
                  }}
                  multiline
                  value={description}
                  disabled={currentvalueofAssigneeForUploadDetails.current !== _authUser?.id}
                  fullWidth
                  onChange={handleDescriptionChange}
                  error={!!validationMessage}
                  helperText={validationMessage}
                // onChange={(e) =>
                //   setDescription(e.target.value.slice(0, 500))
                // }

                />
              </>
            </Box>

            {currentvalueofAssigneeForUploadDetails.current == _authUser?.id && (
              <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                <Button
                  onClick={handleUploadFile}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Save
                </Button>
              </Box>)}
          </DialogContent>
        </Dialog>

        <Modal
          open={openModal}

          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleModalClose();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Userlisttop.actionpopup}>
            <Paper variant="outlined" sx={{ p: 4 }}>
              <Typography
                id="modal-modal-title"
                align="center"
                variant="h6"
                component="h2"
                sx={{ fontWeight: 700 }}
              >
                Delete MOM
              </Typography>
              <Typography
                id="modal-modal-description"
                align="center"
                sx={{ mt: 2 }}
              >
                Are you sure, You sure you want to delete it. Continue?
              </Typography>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 3 }}
              >
                <Button variant="outlined" onClick={handleModalClose} >
                  Cancel
                </Button>
                <Button variant="contained" onClick={deleteUserDetail}>
                  Confirm
                </Button>
              </Stack>
            </Paper>
          </Box>
        </Modal>

      </Paper>
    </Box>
  );
}
