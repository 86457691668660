import { Class, NestCamWiredStandTwoTone } from "@mui/icons-material";
import { projectManagementServive } from "../../../service/project-management-service";
// export class projectModelDetail {
//   _projectDetailsInfo = new projectDetailsInfo();
//   _colAddClientModel = new colAddClientModel();
//   _pointOfContacts = Array<pointOfContacts>;
//   _paymentDetails = new paymentDetails();
//   _skillsets = Array<skillSets>;
//   projectModel = new projectModel("",this._projectDetailsInfo, this._colAddClientModel, new this._pointOfContacts,this._paymentDetails,new this._skillsets)

//   constructor(id: string) {
//     // this.getUserDetails(id);
//   }

// getUserDetails = async (id:string): Promise<any> => {
//     if (id && id != "") {
//         await userManagementServive.getUserById(id)
//             .then((response: any) => {
//                 if (response.data.isError) {

//                 }
//                 else {
//                     this.usermodel= response.data;
//                     this._employeeInfo=this.usermodel.employeeInfo;
//                     this._personalInfo=this.usermodel.personalInfo;
//                     //this._documents=this.usermodel.documents;
//                     //setUsers(response?.data);
//                 }
//             });
//     };
// }
// }
export class projectModel {
  public id:string = "";
  public projectName:string = "";
  public isQAApplicable:any = false;
  public domainId:string = "";
  public projectType:any = null;
  public clientId: any = null;
  public clientType:string = "New";
  public projectOwnerId: string = "";
  public status: boolean = true;
  public isPointOfContact:boolean=false;
  public projectDetails: projectDetails = {
    pin: "",
    keyWords: "",
    channelId: "",
    costing: "",
    pinEfforts: "",
    pinAmount: "",
    projectDescription: "",
    wbsPath: "",
    projectDocument:[],
  };
  public colAddClientModel: colAddClientModel = {
    id:null,
    email: "",
    skypeId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    country: "",
    companyName: "",
    mobileNumber: "",
    code: "",
    alterNateNumber: "",
    companyDescription: "",
  };
  public colUpdateClientModel: colAddClientModel = {
    id:"",
    email: "",
    skypeId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    country: "",
    companyName: "",
    mobileNumber: "",
    code: "",
    alterNateNumber: "",
    companyDescription: "",
  };
  public pointOfContacts: Array<pointOfContacts> = [];
  public projectDepartment: projectDepartment = {
    departmentId: "",
  };

  public paymentDetails: paymentDetails = {
    costingType: "",
    upfrontPayment: "",
    currency: "",
    billingType: "",
    ratePerHour: "",
  };
  public skillSets: Array<skillSets> = [];
  static paymentDetails: any;

  constructor(
    _id:string,
    _projectName:string,
    _isQAApplicable:boolean | null,
    _domainId:string,
    _clientId:string,
    _clientType:string,
    _projectOwnerId:string,
    _status:boolean,
  _isPointOfContact:boolean,
    _projectDetails: projectDetails,
    _colAddClientModel: colAddClientModel,
    _colUpdateClientModel: colAddClientModel,
    _pointOfContacts: Array<pointOfContacts>,
    _paymentDetails: paymentDetails,
    _projectDepartment: projectDepartment,
    _skillSets: Array<skillSets>
  ) {
    this.id = _id;
    this.projectName = _projectName;
    this.clientId = _clientId;
    this.clientType = _clientType;
    this.projectOwnerId = _projectOwnerId;
    this.status = _status;
    this.isPointOfContact=_isPointOfContact;
    this.projectDetails = _projectDetails;
    this.colAddClientModel = _colAddClientModel;
    this.colUpdateClientModel = _colUpdateClientModel;
    this.pointOfContacts = _pointOfContacts;
    this.paymentDetails = _paymentDetails;
    this.skillSets = _skillSets;
  }
}

export class projectDetails {
  public pin: string = "";
  public keyWords: string = "";
  public channelId: string = "";
  public costing: string = "fixed";
  public pinEfforts: string = "";
  public pinAmount: string = "";
  public projectDescription: string = "";
  public wbsPath: string = "";
  public projectDocument:Array<ProjectDocument>=[];
}
export class ProjectDocument {
  public fileName: string = "";
  public wbsPath: string = "";
}
export class colAddClientModel {
  public id:any=null;
  public email: string = "";
  public skypeId: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public middleName: string = "";
  public country: string = "";
  public companyName: string = "";
  public mobileNumber: string = "";
  public code: string = "";
  public alterNateNumber: string = "";
  public companyDescription: string = "";
}

export class pointOfContacts {
  public email: string = "";
  public skypeId: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public middleName: string = "";
  public mobileNumber: string = "";
  public code: string = "";
  public alterNateNumber: string = "";
}

export class projectDepartment {
  public departmentId: string = "";
}

export class paymentDetails {
  public costingType: string = "fixed";
  public upfrontPayment: string = "";
  public currency: string = "";
  public billingType: string = "";
  public ratePerHour: string = "";

}

export class departmentRatePerHours {
  public departmentId: string = "";
  public ratePerHour: string = "";
}

export class skillSets {
  public skillId: string = "";
  public name: string = "";
  public level: string = "";
}
