import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import theme from "../../theme/theme";
import {
    Typography,
    Paper,
    Stack,
    TextField,
    Box,
    Modal,
    MenuItem,
    Button,
    Grid,
    Autocomplete,
    Tooltip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { DatePicker } from 'react-rainbow-components';
import getDate from "../../components/GetDateWithSimpletime";
import { reportService } from "../../service/Reports/reports";
import DialogActions from "@mui/material/DialogActions";
import { Formik, getIn } from "formik";
import NotFound from "../../components/styledComponent/NotFound";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import * as Yup from "yup";
import moment from "moment";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
};


export default function UserSpecificAttendance() {
    const _authUser = useSelector((state: any) => state.AuthUserApp.AuthUser);
    const [fromDate, setfromDate] = React.useState<any>(null);
    const [AbsentDate, setAbsentDate] = React.useState<any>(null);
    const [fromDateOrgl, setfromDateOrgl] = React.useState<any>(null);
    const [toDate, settoDate] = React.useState<any>(null);
    const [userData, setUserData] = React.useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [filtersSelected, setfiltersSelected] = React.useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const mindate = new Date().getFullYear().toString();
    const [currentComment, setcurrentComment] = React.useState("");
    const [selectedReason, setSelectedReason] = React.useState("");
    const [StaticStatusId, setStaticStatusId] = useState<number | undefined>(0);
    const [showHelpText, setshowHelpText] = React.useState(false);
    const [validationMessage, setValidationMessage] = useState<any>("");

    const AttendanceStatus = [
        {
            value: 0,
            label: "All",
        },
        {
            value: 1,
            label: "Present",
        },
        {
            value: 2,
            label: "Absent",
        },
        {
            value: 3,
            label: "On Leave",
        },
    ];

    const ReasonList = [
      
        {
            value: 1,
            label: "Tracker Issue",
        },
        {
            value: 2,
            label: "Prodacker Issue",
        },
        {
            value: 2,
            label: "Forget to start Tracker",
        },

    ];

    const handleOpenAttendance = () => {
        setOpenDelete(true);
        setfromDate("");
        setfromDateOrgl("");
        setAbsentDate("");
    };
    const handleCloseAttendance = () => {
        setOpenDelete(false);
        setshowHelpText(false);
        setfromDate("");
        setfromDateOrgl("");
        setAbsentDate("")
        setcurrentComment("")
        setSelectedReason("")
    };
    const getUsersSpecificAttendance = () => {
        setLoading(true);
        if (!fromDate) {
            toast.warning("From Date is required");
            return;
        }
        if (!toDate) {
            toast.warning(" To Date is required");
            return;
        }
        reportService
            .getUserSpecificAttendance({
                startDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : "",
                endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : "",
                status: StaticStatusId !== undefined ? StaticStatusId : 0,
            })
            .then(async (response: any) => {
                if (response.data.isError) {
                    toast.error("Something Went Wrong, Please try again.");
                } else {
                    const sortedUserData = response.data.sort((a: any, b: any) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);


                        if (dateA.getFullYear() !== dateB.getFullYear()) {
                            return dateA.getFullYear() - dateB.getFullYear();
                        }


                        if (dateA.getMonth() !== dateB.getMonth()) {
                            return dateA.getMonth() - dateB.getMonth();
                        }

                        return dateA.getDate() - dateB.getDate();
                    });

                    setUserData(sortedUserData);
                    setLoading(false);
                }
            });
    };
    console.log(userData, "userData");
    const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        debugger;
        setfiltersSelected(true);
        const selectedStatus = event.target.value as number;
        setStaticStatusId(selectedStatus);
    };
    const addRequestDetail = async (values: any) => {
        setOpenDelete(false);
        try {
            await reportService.addAttendanceRequest(values).then((response: any) => {
                if (response.data.isError) {
                    toast.error("Something went wrong while fetching data");

                } else {
                    toast.success("Request added successfully.");

                }
            });
        } catch (ex: any) {
            ex.data.Error.map((err: any, idx: any) => {
                toast.warning(err);
            });
        }
    }

    const isLoggedInTracker = _authUser.employeeInfo.isTrackerRequired;
    const convertTotalHours = (totalHours: any, status: any) => {
        const [hours, minutes] = totalHours.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) {
            return '';
        }
        const totalInMinutes = hours * 60 + minutes;
        console.log({ totalHours, status, hours, minutes, totalInMinutes });
        debugger
        if (status == "On Leave" || status == "On Holiday") {
            return '#D0D3D4';
        } else if (status === "Absent" || !totalHours) {
            return "#EB445A";
        }
        else if (status === "Present + Half Day" && (totalInMinutes >= 240)) {
            
            debugger
            return "#2DD36F";
        }
         else {
            // Convert totalHours to string if it's not already
            //totalHours = typeof totalHours === 'number' ? totalHours.toString() : totalHours;

            // If totalHours is in the format of a single number, convert it to HH:MM format
            if (totalHours?.length == 2) {
                totalHours = totalHours + ':00';
            }

            if (typeof totalHours !== 'string' || totalHours === null) {
                return '';
            }
           
            let abc = totalInMinutes >= 360 && totalInMinutes < 480 ? "#FF8C00" // Orange( if value is less than 8 shortleave)
                : totalInMinutes < 240 ? '#EB445A' // Red(If value is less than 4 hrs  Absent)
                    : totalInMinutes >= 240 && totalInMinutes < 360 ? '#ffc107' // Yellow(If value is greater than 4 hrs and less than 6 hrs halfday) 
                        : totalInMinutes >= 480 ? '#2DD36F' : ""; // Green Present
            return abc;
        }
    };

    const fieldValidationSchema = Yup.object().shape({
        reason: Yup.string()
            .required("Reason is required"),
        absentDate: Yup.string()
            .required("Date is Required"),
    });

    const getDate1 = (dateObject: any) => {
        debugger;
        if (dateObject) {
            let date = new Date(dateObject);
            // Adjust for timezone offset
            let timezoneOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
            date = new Date(date.getTime() - timezoneOffset);
            let abc = date.toISOString().slice(0, 10) + 'T00:00:00Z';
            return abc
        }
        else {
            return "";
        }
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1, display: "none" }}>
                <Link color="inherit" to="/">
                    Dashboard
                </Link>
                <Typography color="text.primary">Attendance Report</Typography>
                <Typography color="text.primary">User Attendance</Typography>
            </Breadcrumbs>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 600, mr: 3 }}>
                User Attendance
            </Typography>
            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    mb: 2,
                    position: "sticky",
                    top: "64px ",
                    zIndex: 99,
                    gap: 0.5,
                }}
            >
                <Box sx={{ flexGrow: 1 }} />
                <Stack>
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", mt: 2 }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                                gap: 1,
                                width: "100%",
                                [theme.breakpoints.down("md")]: {
                                    width: "100%",
                                },
                                mt: { xs: 2, md: 0 }
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
                                <TextField
                                    sx={{ minWidth: 220 }}
                                    //label="Select Location"
                                    id="outlined-basic"
                                    select
                                    value={StaticStatusId}
                                    variant="outlined"
                                    onChange={
                                        handleStatusChange

                                    } // Use onChange instead of onClick
                                >
                                    {AttendanceStatus.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        style={{ width: '25%' }}
                                        // label="From"
                                        placeholder="From"
                                        labelAlignment="left"
                                        borderRadius="semi-square"
                                        value={fromDate}
                                        maxDate={dayjs().subtract(1, 'day').toDate()}
                                        onChange={(newValue) => {
                                            setfromDateOrgl(newValue)
                                            let abc = dayjs(newValue)
                                            // let abc = getDate(newValue)

                                            setfromDate(abc);
                                            setfiltersSelected(true);
                                            if (dayjs(toDate) < dayjs(abc)) {
                                                settoDate(
                                                    dayjs(newValue).add(1, "day").toDate()
                                                );
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        style={{ width: '25%' }}
                                        disabled={!fromDate}
                                        // label="To"
                                        placeholder="To"
                                        labelAlignment="left"
                                        borderRadius="semi-square"
                                        // minDate={fromDateOrgl}
                                        maxDate={dayjs().subtract(1, 'day').toDate()}
                                        value={toDate}
                                        onChange={(newValue: any) => {
                                            let nValue = dayjs(newValue);
                                            settoDate(nValue);
                                            setfiltersSelected(true);
                                        }}
                                    />
                                </LocalizationProvider>
                                <Button
                                    color="primary"
                                    sx={{ ml: 0.5 }}
                                    onClick={() => {
                                        getUsersSpecificAttendance();
                                    }}
                                    disabled={!StaticStatusId && !fromDate && !toDate}
                                    variant="contained"
                                >
                                    Apply
                                </Button>
                                {filtersSelected && (
                                    <Button
                                        onClick={() => {
                                            setfiltersSelected(false);
                                            setfromDate(null);
                                            setfromDateOrgl(null)
                                            settoDate(null);
                                            setUserData([]);
                                            setStaticStatusId(0);
                                        }}
                                        color="warning"
                                        variant="contained"
                                        sx={{ ml: 0.5 }}
                                    >
                                        Clear
                                    </Button>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: 'wrap' }}>
                                <Button
                                    color="primary"
                                    sx={{ ml: 0.5 }}
                                    onClick={handleOpenAttendance}
                                    variant="contained"
                                >
                                    Apply For Attendance
                                </Button>
                            </Box>
                        </Box>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={1}
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            justifyContent="flex-start"
                            sx={{ mt: 1, ml: 'auto' }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.success.main }}
                                />{" "}
                                Present
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: '#FF8C00' }}
                                />{" "}
                                Short Leave
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.warning.main }}
                                />{" "}
                                Half Day
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    mx: 2,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{ color: theme.palette.error.main }}
                                />{" "}
                                Absent
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Paper>
            {userData?.length > 0 && (
                <Paper
                    elevation={0}
                    sx={{ p: 2, mb: 2, position: "sticky", top: "64px ", zIndex: 99 }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <TableContainer
                            component={Paper}
                            elevation={0}
                            sx={{
                                mt: 0.5,
                                maxHeight: "calc(100vh - 280px)",
                                [theme.breakpoints.down("sm")]: {
                                    maxWidth: "400px",
                                },
                            }}
                        >
                            <Table aria-label="collapsible table" size="small" stickyHeader>
                                <TableHead>

                                    <TableRow sx={{ "& th, & td": { whiteSpace: "nowrap" } }}>
                                        <TableCell sx={{ width: "200px" }}>Sr No.</TableCell>
                                        <TableCell sx={{ width: "200px" }}>Date</TableCell>
                                        {isLoggedInTracker === true ? (
                                            // Render these cells if isTrackerRequired is true
                                            <>
                                                <TableCell sx={{ width: "200px" }}>Active Hours</TableCell>
                                                <TableCell sx={{ width: "200px" }}>Offline Hours</TableCell>
                                                <TableCell sx={{ width: "200px" }}>Total Hours</TableCell>
                                                <TableCell sx={{ width: "200px" }}>Status</TableCell>
                                            </>
                                        ) : (
                                            // Render these cells if isTrackerRequired is false
                                            <>
                                                <TableCell sx={{ width: "200px" }}>Login Time</TableCell>
                                                <TableCell sx={{ width: "200px" }}>Logout Time</TableCell>
                                                <TableCell sx={{ width: "200px" }}>Total Hours</TableCell>
                                                <TableCell sx={{ width: "200px" }}>Status</TableCell>

                                            </>
                                        )}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userData.map((row: any, index: any) => (
                                        <TableRow sx={{ backgroundColor: row?.status == "Present + Half Day" ? 
                                            convertTotalHours(row?.totalHours, row?.status): row?.status !== "Present" ? "#EB445A" 
                                        : convertTotalHours(row?.totalHours, row?.status) }}>
                                            <TableCell >{index + 1}</TableCell>
                                            <TableCell sx={{ fontWeight: 500 }}>{row.date || "--"}</TableCell>
                                            {isLoggedInTracker === false ? (
                                                // Render these cells if isTrackerRequired is true
                                                <>
                                                    <TableCell sx={{ fontWeight: 500 }}>{row.loginTime || "--"}</TableCell>
                                                    <TableCell sx={{ fontWeight: 500 }}>{row.logoutTime || "--"}</TableCell>
                                                    <TableCell>
                                                        {row?.totalHours}
                                                    </TableCell>
                                                    {row?.compOffFor ? (
                                                        <TableCell sx={{ cursor: "pointer" }}>
                                                            <Tooltip arrow title={`Worked On ${moment(row.compOffFor).format("DD-MMM-YYYY")}`}>
                                                                <span>{row?.status || "--"}</span>
                                                            </Tooltip>
                                                        </TableCell>

                                                    ) : (<TableCell>{row?.status || "--"}</TableCell>)}
                                                </>
                                            ) : (
                                                // Render these cells if isTrackerRequired is false
                                                <>

                                                    <TableCell sx={{ fontWeight: 500 }}>{row.activeHours || "--"}</TableCell>
                                                    <TableCell sx={{ fontWeight: 500 }}>{row.offlineHours || "--"}</TableCell>
                                                    <TableCell>
                                                        {row.totalHours !== null ? (
                                                            <Chip
                                                                variant={
                                                                    row.totalHours < 4
                                                                        ? "approved"
                                                                        : row.totalHours < 8
                                                                            ? "review"
                                                                            : "hold"
                                                                }
                                                                label={row.totalHours}
                                                            />
                                                        ) : (
                                                            "--"
                                                        )}
                                                    </TableCell>
                                                    {row?.compOffFor ? (
                                                        <TableCell sx={{ cursor: "pointer" }}>
                                                            <Tooltip arrow title={`Worked On ${moment(row.compOffFor).format("DD-MMM-YYYY")}`}>
                                                                <span>{row?.status || "--"}</span>
                                                            </Tooltip>
                                                        </TableCell>

                                                    ) : (<TableCell>{row?.status || "--"}</TableCell>)}
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
            )}
            {userData.length === 0 && (
                <NotFound NotfoundText="No Result Found" />
            )}

            <DialogActions sx={{ p: 0 }}>
                <Modal
                    open={openDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Formik
                        initialValues={{
                            comment: currentComment,
                            reason: selectedReason,
                            absentDate: AbsentDate,
                            attendanceRequest: 1
                        }}
                        validationSchema={fieldValidationSchema}
                        enableReinitialize={true}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                addRequestDetail(values);
                            } catch (ex: any) {
                                if (ex.status == 400) {
                                    console.error("ex:", ex);
                                    ex.data.Error.map((error: any, idx: any) => {
                                        toast.error(error);
                                    })
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            handleReset,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Box sx={style}>
                                    <Paper variant="outlined" sx={{ p: 4 }}>
                                        <Typography
                                            id="modal-modal-title"
                                            align="center"
                                            variant="h6"
                                            component="h2"
                                            sx={{ fontWeight: 700 }}
                                        >
                                            Add Attendance Request
                                        </Typography>
                                        <Box sx={{ mt: 2 }}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        required
                                                        style={{ width: "220px" }}
                                                        borderRadius="semi-square"
                                                        placeholder="Absent Date"
                                                        // label="Absent Date"
                                                        labelAlignment="left"
                                                        value={AbsentDate}
                                                        //minDate={new Date(mindate)}
                                                        maxDate={
                                                            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)
                                                        }
                                                        error={showHelpText && !values.absentDate ? "Date is Required" : ""}
                                                        onChange={(newValue: any) => {
                                                            let abc = getDate1(newValue)
                                                            setAbsentDate(abc);
                                                            setshowHelpText(false);
                                                        }}
                                                    />
                                                </LocalizationProvider>

                                            </Box>
                                            <Box sx={{ mt: "20px" }}>
                                                <Autocomplete

                                                    value={
                                                        ReasonList?.find(
                                                            (option) => option.label == selectedReason
                                                        ) ?? null
                                                    }
                                                    options={ReasonList}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, value) => {
                                                        values.reason = value?.label ?? "";
                                                        setSelectedReason(value?.label ?? "");
                                                    }}
                                                    componentsProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: 'flip',
                                                                    enabled: true,
                                                                    options: {
                                                                        altBoundary: true,
                                                                        flipVariations: true,
                                                                        altAxis: true,
                                                                        padding: 8,
                                                                        fallbackPlacements: ['bottom'],  // Set to ['bottom'] if you want it to always open at the bottom
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id="timezone"
                                                            error={Boolean(
                                                                getIn(touched, "reason") &&
                                                                getIn(errors, "reason")
                                                            )}
                                                            fullWidth
                                                            helperText={
                                                                getIn(touched, "reason") &&
                                                                getIn(errors, "reason")
                                                            }
                                                            name="reason"
                                                            label="Select Reason"
                                                            onBlur={handleBlur}
                                                            placeholder="Select Reason"
                                                            variant="outlined"
                                                            value={selectedReason}
                                                            required
                                                        >
                                                        </TextField>
                                                    )}
                                                /></Box>
                                            <TextField
                                                //  sx={{ mt: 2 }}

                                                sx={{

                                                    "word-break": "break-all",
                                                    "line-height": "1.5",
                                                    "padding": "10px",
                                                    "margin-top":"16px",
                                                    "& .MuiInputBase-root": {
                                                        "line-height": "1.5",
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "line-height": "1.5",
                                                        "padding": "10px",
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                id="outlined-basic"
                                                label="Comment"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                value={currentComment}
                                                onChange={(e) => {
                                                    let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,\s]$/g, "").trimStart();
                                                    value = value.replace(/\s{3,}$/g, "");
                                                    if (value.length > 200) {
                                                        setValidationMessage("The comment must be 200 characters or less.");
                                                    } else {
                                                        setValidationMessage("");
                                                    }
                                                    values.reason = value;
                                                    setcurrentComment(value.slice(0, 200));
                                                }}

                                                error={!!validationMessage}
                                                helperText={validationMessage}
                                                // onChange={(e) => {
                                                //     let value = e.currentTarget.value.replace(/[^a-zA-Z0-9#.,\s]$/g, "").trimStart();
                                                //     value = value.replace(/\s{3,}$/g, "");
                                                //     values.reason = value;
                                                //     setcurrentComment(value);
                                                // }}
                                              //  inputProps={{ maxLength: 150 }}
                                            />
                                        </Box>

                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            sx={{ mt: 3 }}
                                        >
                                            <Button variant="outlined"
                                                onClick={handleCloseAttendance}
                                            >
                                                Cancel
                                            </Button>
                                            <Button variant="contained"
                                                type="submit"
                                                onClick={() => {
                                                    setshowHelpText(!AbsentDate)
                                                }}
                                            >
                                                Confirm
                                            </Button>
                                        </Stack>
                                    </Paper>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Modal>
            </DialogActions>
        </>
    );
}