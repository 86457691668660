export enum eMasterDropdown
    {
        Department=1,
        TechnicalSkills = 2,
        Achievement = 3,
        Designation=4,
        Channel=5,
        Badge=6,
        HeadOfDepartment=7,
        Label=8,
        Role = 9,
        Country = 11,
        Priority= 12,
        Project = 13
    }